import ChooseEmployee from 'procedures/changeDependents/ChooseEmployee'
import ChooseAddOrRemove from 'procedures/changeDependents/ChooseAddOrRemove'
import add from 'procedures/changeDependents/add'
import remove from 'procedures/changeDependents/remove'

export default {
  ChooseEmployee,
  ChooseAddOrRemove,
  add,
  remove,
}
