import React, {useState} from 'react'
import useReactRouter from 'use-react-router'
import {
  TextField,
  TextAreaField,
  CheckboxField,
  RadioField,
  DateField,
  SelectField,
  Section,
} from 'jbc-front/components/Form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {reduxForm, formValueSelector} from 'redux-form'
import {DependentHealthInsurance} from 'reportForms/common'
import _ from 'lodash'
import {number} from 'validators'
import EmployeeName from 'procedures/EmployeeName'
import {recordDisplay, isAdminSelector, toBooleanProps} from 'utils'
import styles from 'reportForms/R150Report.scss'
import ReviewComments from 'components/ReviewComments'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R150Report'

const applicationReasonTypes = [
  {value: 'entrance', label: '被保険者の取得に伴う申請'},
  {value: 'marriage', label: '被保険者と婚姻に伴う申請'},
  {value: 'retirement', label: '認定対象者の退職に伴う申請'},
  {value: 'financial_change', label: '認定対象者の転職・雇用契約変更による収入減'},
  {value: 'financial_change_without_contract_change', label: '認定対象者の雇用契約変更を伴わない収入減少'},
  {value: 'unemployment_benefits_end', label: '認定対象者の失業給付受給終了に伴う申請'},
  {value: 'come_to_japan', label: '来日による申請'},
  {value: 'others', label: 'その他'},
]

const hasSpouseOptions = [
  {value: 'false', label: '配偶者なし'},
  {value: 'true', label: '配偶者あり'},
]

const hasUnemploymentBenefitsOptions = [
  {value: 'false', label: '受給なし'},
  {value: 'true', label: '受給あり及び受給予定'},
]

const unemploymentBenefitsStatuses = [
  {value: 'will_submit', label: 'これから申請する'},
  {value: 'submitted_or_started', label: '申請中又は受給中'},
  {value: 'postpone', label: '受給延長中'},
]

const noUnemploymentBenefitsReasons = [
  {value: 'no_rights', label: '権利なし'},
  {value: 'do_not_do_procedures', label: '手続きをしない'},
  {value: 'receiving_ended', label: '受給終了'},
]

const hasIncomeOptions = [
  {value: 'false', label: '収入なし'},
  {value: 'true', label: '収入あり'},
]

const hasClosedBenefitsOptions = [
  {value: 'false', label: '受給なし'},
  {value: 'true', label: '受給あり'},
]

const hasPensionOptions = [
  {value: 'false', label: '受給なし'},
  {value: 'true', label: '受給あり'},
]

const pensionTypes = [
  {value: 'old_age', label: '老齢'},
  {value: 'disability', label: '障害'},
  {value: 'survivor', label: '遺族'},
  {value: 'nothing', label: '受給なし'},
]

const mutualAidPensionTypes = [
  {value: 'retirement', label: '退職'},
  {value: 'disability', label: '障害'},
  {value: 'survivor', label: '遺族'},
  {value: 'nothing', label: '受給なし'},
]

const insuranceTypes = [
  {value: 'welfare', label: '厚生年金'},
  {value: 'national', label: '国民年金'},
  {value: 'farmer', label: '農業者年金'},
  {value: 'nothing', label: '受給なし'},
]

const UnemploymentBenefitsInformation = ({required, hasUnemploymentBenefits, unemploymentBenefitsStatus}) => (
  <Section title={`雇用保険（失業給付）の受給状況`}>
    <RadioField
      name="has_unemployment_benefits"
      {...toBooleanProps}
      options={hasUnemploymentBenefitsOptions}
      required={required}
      label="受給状況"
    />
    {hasUnemploymentBenefits && (
      <>
        <RadioField
          name="unemployment_benefits_status"
          options={unemploymentBenefitsStatuses}
          required={required}
          label="受給状況詳細"
        />
        {unemploymentBenefitsStatus === 'will_submit' && (
          <DateField name="unemployment_benefits_will_submit_on" label="申請予定" required={required} />
        )}
        {unemploymentBenefitsStatus === 'submitted_or_started' && (
          <>
            <DateField name="unemployment_benefits_submit_on" label="求職申込年月日" required={false} />
            <DateField name="unemployment_benefits_started_on" label="受給開始日" required={false} />
            <TextField validate={number} name="unemployment_benefits_daily" label="基本手当日額" yen required={false} />
          </>
        )}
      </>
    )}
    {hasUnemploymentBenefits === false && (
      <RadioField
        name="no_unemployment_benefits_reason"
        options={noUnemploymentBenefitsReasons}
        required={required}
        label="受給がない理由"
      />
    )}
  </Section>
)

const PensionTypeInformation = ({required, kind, label, pensionType}) => (
  <Section title={label}>
    {kind == 'mutual_aid' ? (
      <RadioField
        name={`${kind}_pension_type`}
        options={mutualAidPensionTypes}
        required={required}
        label={`${label}種類`}
      />
    ) : (
      <RadioField name={`${kind}_pension_type`} options={pensionTypes} required={required} label={`${label}種類`} />
    )}
    {pensionType && pensionType !== 'nothing' && (
      <TextField
        validate={number}
        name={`${kind}_pension_monthly`}
        label={`${label}受給額（月額）`}
        yen
        required={required}
      />
    )}
  </Section>
)

const PensionInformation = ({
  required,
  hasPension,
  welfarePensionType,
  nationalPensionType,
  mutualAidPensionType,
  insuranceType,
}) => (
  <Section title={`年金等の受給状況`}>
    <RadioField
      name="has_pension"
      {...toBooleanProps}
      options={hasPensionOptions}
      required={required}
      label="受給状況"
    />
    {hasPension && (
      <>
        <PensionTypeInformation required={required} kind="welfare" label="厚生年金" pensionType={welfarePensionType} />
        <PensionTypeInformation
          required={required}
          kind="national"
          label="国民年金"
          pensionType={nationalPensionType}
        />
        <PensionTypeInformation
          required={required}
          kind="mutual_aid"
          label="共済年金"
          pensionType={mutualAidPensionType}
        />
        <Section title="個人年金">
          <TextField
            validate={number}
            name="private_pension_monthly"
            label="個人年金受給額（月額）"
            yen
            required={false}
          />
        </Section>
        <Section title="企業年金">
          <TextField
            validate={number}
            name="company_pension_monthly"
            label="企業年金受給額（月額）"
            yen
            required={false}
          />
        </Section>
        <Section title="基金">
          <RadioField name="insurance_type" options={insuranceTypes} required={required} label="基金種類" />
          {insuranceType && insuranceType !== 'nothing' && (
            <TextField validate={number} name="insurance_monthly" label="基金受給額（月額）" yen required={required} />
          )}
        </Section>
        <Section title="その他年金">
          <TextField name="other_pension_detail" label="その他年金詳細" required={false} />
          <TextField
            validate={number}
            name="other_pension_monthly"
            label="その他年金受給額（月額）"
            yen
            required={false}
          />
        </Section>
      </>
    )}
  </Section>
)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({
    submitting,
    handleSubmit,
    submitText,
    name,
    relation_detail,
    applicationReasonType,
    isSpouse,
    hasSpouse,
    hasUnemploymentBenefits,
    unemploymentBenefitsStatus,
    hasIncome,
    hasClosedBenefits,
    hasPension,
    welfarePensionType,
    nationalPensionType,
    mutualAidPensionType,
    insuranceType,
    remittanceFromOthers,
    isAdmin,
    otherButtons,
    employee,
    comments,
    needUpgrade,
  }) => {
    const [openModal, setOpenModal] = useState(false)
    const branchSubmit = (needUpgrade) => {
      if (needUpgrade) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }
    const {pathname} = useReactRouter().location
    const isEmployeeInput = !isAdmin || pathname.includes('/mypage/')

    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">被扶養者現況表 ({relation_detail})</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <ReviewComments comments={comments} />
          <Section>
            <Section title="申請する理由">
              <SelectField
                name="application_reason_type"
                label="申請理由"
                options={applicationReasonTypes}
                required={isEmployeeInput}
              />
              {applicationReasonType === 'marriage' && (
                <DateField name="married_at" label="婚姻日" required={isEmployeeInput} />
              )}
              {applicationReasonType === 'retirement' && (
                <DateField name="retired_at" label="退職日" required={isEmployeeInput} />
              )}
              {applicationReasonType === 'financial_change' && (
                <DateField name="income_decrease_from" label="収入が減少した日" required={isEmployeeInput} />
              )}
              {applicationReasonType === 'unemployment_benefits_end' && (
                <DateField name="unemployment_benefits_end_on" label="受給終了日" required={isEmployeeInput} />
              )}
              {applicationReasonType === 'come_to_japan' && (
                <DateField name="came_to_japan_at" label="住民票の住定年月日" required={isEmployeeInput} />
              )}
              {applicationReasonType === 'others' && (
                <>
                  <TextField name="application_reason_other" label="理由" required={isEmployeeInput} />
                  <DateField
                    name="application_reason_other_at"
                    label="理由の根拠となる日付"
                    required={isEmployeeInput}
                  />
                </>
              )}
            </Section>
            <DependentHealthInsurance required={isEmployeeInput} formName={formName} name={name} />
            {!isSpouse && (
              <Section title={`${name}の配偶者の状況`}>
                <RadioField
                  name="has_spouse"
                  {...toBooleanProps}
                  options={hasSpouseOptions}
                  required={isEmployeeInput}
                  label="配偶者の状況"
                />
                {hasSpouse && (
                  <>
                    <TextField
                      validate={number}
                      name="spouse_last_year_income"
                      label="昨年の収入（年間）"
                      yen
                      required={false}
                    />
                    <TextField
                      validate={number}
                      name="spouse_monthly_income"
                      label="今年の収入（月額）"
                      yen
                      required={false}
                    />
                  </>
                )}
              </Section>
            )}
            <UnemploymentBenefitsInformation
              required={isEmployeeInput}
              hasUnemploymentBenefits={hasUnemploymentBenefits}
              unemploymentBenefitsStatus={unemploymentBenefitsStatus}
            />
            <Section title={`${name}の給与収入`}>
              <RadioField
                name="has_income"
                {...toBooleanProps}
                options={hasIncomeOptions}
                required={isEmployeeInput}
                label="収入状況"
              />
              {hasIncome && (
                <TextField
                  validate={number}
                  name="employment_income"
                  label="収入月額（パート・アルバイト含む）"
                  yen
                  required={false}
                />
              )}
            </Section>
            <Section title="休業給付金等の受給">
              <RadioField
                name="has_closed_benefits"
                {...toBooleanProps}
                options={hasClosedBenefitsOptions}
                required={isEmployeeInput}
                label="受給状況"
              />
              {hasClosedBenefits && (
                <>
                  <TextField name="closed_benefits_detail" label="受給内容詳細" required={false} />
                  <TextField
                    validate={number}
                    name="closed_benefits_daily"
                    label="基本手当日額"
                    yen
                    required={isEmployeeInput}
                  />
                </>
              )}
            </Section>
            <PensionInformation
              required={isEmployeeInput}
              hasPension={hasPension}
              welfarePensionType={welfarePensionType}
              nationalPensionType={nationalPensionType}
              mutualAidPensionType={mutualAidPensionType}
              insuranceType={insuranceType}
            />
            <Section title={`${name}の昨年の所得状況`}>
              <TextField validate={number} name="self_employed" label="自営業、フリーランス" yen required={false} />
              <TextField validate={number} name="interest" label="利子・配当金" yen required={false} />
              <TextField validate={number} name="rent" label="家賃・不動産" yen required={false} />
            </Section>
            <Section title="送金・援助">
              <TextField
                validate={number}
                name="remittance"
                label="被保険者からの送金額（月額）"
                yen
                required={false}
              />
              <TextField
                validate={number}
                name="remittance_from_others"
                label="被保険者以外からの援助額（月額）"
                yen
                required={false}
              />
              {remittanceFromOthers && (
                <TextField name="remittanfce_from_others_detail" label="援助者との間柄" required={isEmployeeInput} />
              )}
            </Section>
            <CheckboxField
              name="confirmed"
              label="裏面の注意事項を一読し、内容を確認しました。"
              required={isEmployeeInput}
            />
            <p className={styles.margin_link}>
              ※注意事項の確認は
              <a
                href="https://jobcan-lms.zendesk.com/hc/ja/articles/360031262351#Contents1"
                target="_blank"
                className="u-txt-link"
                rel="noopener noreferrer"
              >
                こちら
              </a>
            </p>
            <br />
            <br />
            <TextAreaField name="notes" label="備考" rows="3" />
            {!isEmployeeInput && <SubmitedOn />}
            <div className="u-ta-c u-mt30">
              {otherButtons}
              <Button primary disabled={submitting} onClick={() => branchSubmit(needUpgrade)}>
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => {
            setOpenModal(false)
            handleSubmit()
          }}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)

const selector = formValueSelector(formName)

const connectedForm = connectForm('r150', Form, {
  mapState: (state, ownProps) => {
    const {dependentId, name, relation_type, relation_other, comments} = ownProps
    const dependents = _.get(state, `procedureStatuses.current.data.employee.employee_dependents`)
    const dependent = dependents && dependents.find((dependent) => dependent.id == dependentId)
    const isSpouse = dependent ? dependent.relation_type == 'spouse' : relation_type == 'spouse'
    const relation_other_detail = dependent ? dependent.relation_other : relation_other
    const relation_detail = isSpouse ? '配偶者' : relation_other_detail
    return {
      employee: state.procedureStatuses.current.data.employee || {},
      applicationReasonType: selector(state, 'application_reason_type'),
      isSpouse,
      hasSpouse: selector(state, 'has_spouse'),
      hasUnemploymentBenefits: selector(state, 'has_unemployment_benefits'),
      unemploymentBenefitsStatus: selector(state, 'unemployment_benefits_status'),
      hasIncome: selector(state, 'has_income'),
      hasClosedBenefits: selector(state, 'has_closed_benefits'),
      hasPension: selector(state, 'has_pension'),
      welfarePensionType: selector(state, 'welfare_pension_type'),
      nationalPensionType: selector(state, 'national_pension_type'),
      mutualAidPensionType: selector(state, 'mutual_aid_pension_type'),
      insuranceType: selector(state, 'insurance_type'),
      remittanceFromOthers: selector(state, 'remittance_from_others'),
      name: name || (dependent && `${dependent.last_name} ${dependent.first_name}`) || recordDisplay(),
      relation_detail,
      isAdmin: isAdminSelector(state),
      comments,
      needUpgrade: selector(state, 'need_upgrade'),
    }
  },
})

export default connectedForm
