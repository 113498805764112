import React, {Component} from 'react'
import {connect} from 'react-redux'
import EmployeesList from 'procedures/EmployeesList'

class ChooseEmployeeDirect extends Component {
  render() {
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">従業員を選択してください</h1>
        </div>
        <div className="l-wrap-xl">
          <EmployeesList
            handleClick={(employeeId) => {
              this.props.history.push(`/change_dependents/choose_add_or_remove?employee_id=${employeeId}`)
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  selected: state.employees.selected,
}))(ChooseEmployeeDirect)
