import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Progress} from 'components/Progress'
import {actionCreators} from 'actions'
import api from 'api'
import {recordDisplay} from 'utils'
import moment from 'moment'
import _ from 'lodash'
import StarFlag from 'components/StarFlag'
import {Filter, Pager, PerPage, ListData, Tab, Search} from 'procedureStatuses/list/components'
import styles from 'procedureStatuses/List.scss'
import {DeleteSquare} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/Modal'
import {SelectProcedureModal} from 'pages/withAuth/adminPage/procedureStatuses/index/parts/SelectProcedureModal'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

export const procedureTypes = {
  enroll: '入社の手続き',
  resign: '退社の手続き',
  add_dependents: '扶養の追加',
  remove_dependents: '扶養の削除',
  change_address: '住所の変更',
  change_name: '氏名の変更',
  start_maternity: '産休の開始',
  extend_maternity: '産休の変更',
  finish_maternity: '産休の終了',
  // childcare_leave: '育休の手続き'
}

export const statuses = {
  all: 'すべて',
  draft: '途中保存',
  in_progress: '対応中',
  request: '依頼中',
  applying: '承認待ち',
  done: '完了',
}

export const dateNameMap = {
  enroll: '入社予定日',
  resign: '退社予定日',
  change_dependents: '被扶養者異動日',
  change_address: '引越の日',
  change_name: '氏名変更日',
  maternity_leave: '出産予定日',
}

const deleteMessages = {
  draft:
    'この手続きで入力された情報が従業員情報に反映されない場合があります。\n最新の従業員情報は従業員一覧よりご確認ください。',
  in_progress: (
    <>
      この手続きで入力された情報は既に従業員情報に反映されています。
      <br />
      手続き開始前の情報に戻したい場合は、従業員一覧より情報を入力し直してください。
      <br />
      <br />
      <span className={styles.warningText}>
        また、この手続きに関連する電子申請を行っている場合は、その電子申請の情報も全て削除されますのでご注意ください。
      </span>
    </>
  ),
  request: '手続きを削除すると、従業員はこの手続きを進めることができなくなります。',
  applying:
    'この手続きで入力された情報はまだ従業員情報に反映されていません。\n情報の反映が必要な場合は一度承認を行ってから削除してください。',
  done: (
    <>
      この手続きで入力された情報は既に従業員情報に反映されています。
      <br />
      手続き開始前の情報に戻したい場合は、再度従業員一覧より情報を変更してください。
      <br />
      <br />
      <span className={styles.warningText}>
        また、この手続きに関連する電子申請を行っている場合は、その電子申請の情報も全て削除されますのでご注意ください。
      </span>
    </>
  ),
}

const MAX_FLAG = 4

class Row extends Component {
  handleDeleteClick = (e, name) => {
    const {procedureStatus} = this.props
    this.props.onDeleteClick(name, procedureStatus)
    e.stopPropagation()
  }

  handleClick = () => {
    this.props.handleClick(this.props.procedureStatus.id)
  }

  handleStarClick = (e) => {
    this.props.handleStarClick(this.props.procedureStatus.id)
    e.stopPropagation()
  }

  render() {
    const {procedureStatus} = this.props
    const employee = _.get(procedureStatus, 'employee')
    return (
      <tr onClick={this.handleClick} className="table-hover">
        <td onClick={this.handleStarClick}>
          <StarFlag colorIndex={procedureStatus.flag} />
        </td>
        <td>{recordDisplay(procedureStatus.created_at && moment(procedureStatus.created_at).format('YYYY/MM/DD'))}</td>
        <td>{recordDisplay(_.get(procedureStatus, 'employee.staff_code'))}</td>
        <td>{recordDisplay(employee.display_last_name && employee.display_full_name)}</td>
        <td>{recordDisplay(procedureTypes[procedureStatus.procedure_type])}</td>
        <td>
          <div className={styles.progressDiv}>
            <Progress progress={procedureStatus.progress} className={styles.progressBar} />
            <span className={styles.progressCount}>
              {recordDisplay(
                procedureStatus.total_todos_count &&
                  `${procedureStatus.done_todos_count}/${procedureStatus.total_todos_count}`
              )}
            </span>
          </div>
        </td>
        <td>{statuses[procedureStatus.status]}</td>
        <td>{recordDisplay(procedureStatus.date_on && procedureStatus.date_on.replace(/-/g, '/'))}</td>
        <td onClick={(e) => this.handleDeleteClick(e, employee.display_full_name)} className={styles.delete}>
          <DeleteSquare />
        </td>
      </tr>
    )
  }
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: null,
      procedureId: null,
      procedureStatus: null,
      procedureType: null,
      showModal: false,
    }
  }

  handleRowClick = (id) => {
    const {procedureStatuses} = this.props
    const procedureStatus = _.find(procedureStatuses, (procedureStatus) => procedureStatus.id == id)
    if (procedureStatus && procedureStatus.link) {
      this.props.history.push(procedureStatus.link)
    }
  }

  handleStarClick = (id) => {
    const {setFlag, procedureStatuses, token} = this.props
    const procedureStatus = _.find(procedureStatuses, (procedureStatus) => procedureStatus.id == id)
    if (procedureStatus) {
      setFlag(token, id, (procedureStatus.flag + 1) % MAX_FLAG)
    }
  }

  handleDeleteClick = (name, procedureStatus) => {
    this.setState({
      name: name,
      procedureId: procedureStatus.id,
      procedureStatus: procedureStatus.status,
      procedureType: procedureStatus.procedure_type,
      showModal: true,
    })
  }

  handleModalSubmit = () => {
    const {token, query} = this.props
    this.props.deleteProcedureStatus(token, this.state.procedureId, query)
    this.hideConfirmModal()
  }

  hideConfirmModal = () => {
    this.setState({showModal: false})
  }

  render() {
    const {procedureStatuses, count, query} = this.props
    const csvDownloadable =
      procedureStatuses.length > 0 &&
      ['enroll', 'resign', 'change_dependents'].includes(query.procedure_type) &&
      ['all', 'in_progress', 'done'].includes(query.status)

    return (
      <div>
        <ListData />
        <div className={styles.headArea + ' l-main-title-wrap'}>
          <div className="l-wrap-xxl">
            <h1 className="m-title-main">進行中の手続き一覧</h1>
            <div className={styles.typeArea}>
              <div>
                <Tab column="procedure_type" filter="all" label="すべて" />
              </div>
              <div>
                <Tab column="procedure_type" filter="enroll" label="入社の手続き" />
              </div>
              <div>
                <Tab column="procedure_type" filter="resign" label="退社の手続き" />
              </div>
              <div>
                <Tab column="procedure_type" filter="change_address" label="住所の変更" />
              </div>
              <div>
                <Tab column="procedure_type" filter="change_name" label="氏名の変更" />
              </div>
              <div>
                <Tab column="procedure_type" filter="change_dependents" label="扶養の変更" />
              </div>
              <div>
                <Tab column="procedure_type" filter="maternity_leave" label="産休の手続き" />
              </div>
              {/*<div>
                <Tab column="procedure_type" filter="childcare_leave" label="育休の手続き" />
              </div>
              <div><Tab column='procedure_type' filter='' label='病気・ケガ' /></div>
              <div><Tab column='procedure_type' filter='' label='年末調整' /></div>*/}
            </div>
          </div>
        </div>
        <div className="l-contents-wrap">
          <div className="l-wrap-xxl">
            <div className={styles.topArea}>
              <div className={styles.refineArea}>
                {_.map(statuses, (filter, key) => (
                  <Filter key={key} column="status" filter={key} label={filter} />
                ))}
              </div>
              <div>
                <SelectProcedureModal csvDownloadable={csvDownloadable} />
              </div>

              <Search placeholder="スタッフコード、氏名、メール" />
            </div>
            <div className="l-overflow-scroll">
              <table className="m-table-list m-table-fixed">
                <thead>
                  <tr>
                    <th className={styles.flagColumn}>フラグ</th>
                    <th className="m-table-list-date">開始日</th>
                    <th className="m-table-list-staffcode">スタッフコード</th>
                    <th className={styles.nameColumn}>氏名</th>
                    <th className={styles.procedureColumn}>手続き</th>
                    <th className={styles.progressColumn}>進行状況</th>
                    <th className={styles.statusColumn}>ステータス</th>
                    <th className="m-table-list-date">{dateNameMap[query.procedure_type] || '関連日'}</th>
                    <th className={styles.deleteColumn}>削除</th>
                  </tr>
                </thead>
                <tbody>
                  {procedureStatuses.map((procedureStatus) => (
                    <Row
                      procedureStatus={procedureStatus}
                      key={procedureStatus.id}
                      handleClick={this.handleRowClick}
                      handleStarClick={this.handleStarClick}
                      onDeleteClick={this.handleDeleteClick}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="m-pager-area">
              <Pager totalResult={count} pageLinks={7} />
              <PerPage />
            </div>
          </div>
        </div>
        <Modal
          header="削除確認"
          body={
            <div>
              <p className="u-mb20">
                {this.state.name}さんの{procedureTypes[this.state.procedureType]}が「
                {statuses[this.state.procedureStatus]}」になっています。
              </p>
              <p className={styles.deleteMessage}>
                「{statuses[this.state.procedureStatus]}」の場合、{deleteMessages[this.state.procedureStatus]}
              </p>
              <p>本当に削除しますか？</p>
            </div>
          }
          cancel="いいえ"
          submit="はい"
          onSubmit={this.handleModalSubmit}
          isOpen={this.state.showModal}
          hideModal={() => this.setState({showModal: false})}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  procedureStatuses: state.procedureStatuses.list.data,
  count: state.procedureStatuses.list.count,
  query: state.procedureStatuses.query,
  token: state.auth.token,
})

export default connect(mapStateToProps, (dispatch) => {
  return {
    loadProcedures(token, query) {
      dispatch(
        actionCreators.procedureStatuses.list.fetchData(
          api.createWithAuth(token).procedureStatuses.list(query, ['employee'])
        )
      )
    },
    async setFlag(token, id, flag) {
      try {
        dispatch(actionCreators.procedureStatuses.setFlag({id, flag}))
        await api.createWithAuth(token).procedureStatuses.update(id, {flag})
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    async deleteProcedureStatus(token, id, query) {
      try {
        await api.createWithAuth(token).procedureStatuses.delete(id)
        this.loadProcedures(token, query)
        dispatch(notifySuccess('削除しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  }
})(List)
