import {actionTypes} from 'actions'

const initialState = {
  email: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTRATION.SET_EMAIL:
      return {
        email: action.payload,
      }
    default:
      return state
  }
}

export default reducer
