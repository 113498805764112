import {FC, useState} from 'react'
import {FloatingContainer} from 'components/layout/FloatingContainer'

import {RemindModal} from './RemindModal'
import {CancelModal} from './CancelModal'
import styles from './NoneOrRejectedButtons.scss'

import Button from 'jbc-front/components/Button'

type FloatingButtonsProps = {
  detailInputRequestId: number
  disabledRemind: boolean
}

export const NoneOrRejectedButtons: FC<FloatingButtonsProps> = ({detailInputRequestId, disabledRemind}) => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
  const [isRemindModalOpen, setIsRemindModalOpen] = useState<boolean>(false)

  return (
    <>
      <FloatingContainer>
        {disabledRemind && <p className={styles.disabledRemind}>再依頼できる項目が存在しません</p>}
        <Button onClick={() => setIsCancelModalOpen(true)}>取下げ</Button>
        <Button primary onClick={() => setIsRemindModalOpen(true)} disabled={disabledRemind}>
          再送信
        </Button>
      </FloatingContainer>

      <>
        <RemindModal id={detailInputRequestId} isOpen={isRemindModalOpen} onClose={() => setIsRemindModalOpen(false)} />
        <CancelModal id={detailInputRequestId} isOpen={isCancelModalOpen} onClose={() => setIsCancelModalOpen(false)} />
      </>
    </>
  )
}
