import {FC, ReactNode} from 'react'
import classnames from 'classnames'

type TitleContainerProps = JSX.IntrinsicElements['div'] & {
  subPage?: boolean
  children?: ReactNode
  className?: string
}

export const TitleContainer: FC<TitleContainerProps> = ({subPage = false, children, className, ...rest}) => {
  const containerClassNames = classnames(subPage ? 'l-title-wrap' : 'l-main-title-wrap', className)

  return (
    <div className={containerClassNames} {...rest}>
      {children}
    </div>
  )
}
