import {FC, useState} from 'react'
import {StarFlag, ColorType} from 'components/ui/StarFlag'
import {Document, UPDATE_DOCUMENT_FLAG} from '../../query'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface StarFlagColumnProps {
  document: Document
}

const MAX_FLAG = 2 as const
const FLAG_COLORS = ['gray', 'orange'] as const

export const StarFlagColumn: FC<StarFlagColumnProps> = ({document}) => {
  const [starColor, setStarColor] = useState<ColorType>(FLAG_COLORS[document.flag] || 'gray')
  const [updateDocumentFlag] = useMutation(UPDATE_DOCUMENT_FLAG)

  // 見た目重視で色を先に変えてから通信する. 失敗した場合色を戻す
  const onClickStar = async () => {
    const prevColor = starColor
    const newColor = starColor === 'gray' ? 'orange' : 'gray'
    setStarColor(newColor)

    try {
      await updateDocumentFlag({
        variables: {
          input: {
            id: document.id,
            flag: (document.flag + 1) % MAX_FLAG,
          },
        },
      })
    } catch {
      setStarColor(prevColor)
    }
  }

  return <StarFlag color={starColor} onClick={onClickStar} />
}
