import React from 'react'
import moment from 'moment'
import styles from 'components/DateOn.scss'
import {MdAccessTime} from 'jbc-front/components/react-icons'

const showDateShort = (date) => {
  const output = moment(date).format('LL')
  if (moment(date).year() === moment().year()) {
    return output.replace(/\d*年/, '')
  } else {
    return output.replace(/年/, '年\n')
  }
}

const DateOn = ({date}) => (
  <div className={styles.date}>
    <span>
      <MdAccessTime size={12} />
    </span>
    <time dateTime={date}>{date ? showDateShort(date) : '未定'}</time>
  </div>
)

export default DateOn
