import React from 'react'
import {
  Section,
  FileField,
  TextField,
  SelectField,
  DateField,
  RadioField,
  Label,
  BoxDouble,
  CheckboxField,
} from 'jbc-front/components/Form'
import {Name, Birthday, Sex, Email, RomajiName, NationalType, Phone, AddressWithCountry} from 'FormFields'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Description, getDiffFromOptions, getDiffFromFile, JAPAN_VALUE, withFormSelectors} from 'employees/form/common'
import {recordDisplay, toBooleanProps, isAdminSelector} from 'utils'
import {FormSection} from 'redux-form'
import ActionButton from 'jbc-front/components/ActionButton'
import {Link} from 'react-router-dom'
import moment from 'moment'
import styles from 'employees/form/BasicInfomation.scss'
import {noVisaUntilType} from 'employees/VisaHistory'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {maxLength, exactLength, visaCode} from '../../validators'
import {PreviewIcon} from './PreviewIcon'

const MAX_COUNTRY_VALUE = 990

export const addressNames = [
  'postcode_0',
  'postcode_1',
  'prefecture_id',
  'city',
  'house_number',
  'building',
  'address_kana',
]

const householderRelationshipOptions = [
  '本人',
  '夫',
  '妻',
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '叔父',
  '叔母',
].map((val) => ({value: val, label: val}))

const dispatchTypes = [
  {value: 'dispatch', label: '1. 派遣・請負労働者として主として当該事業所以外で就労する場合'},
  {value: 'other', label: '2. 1に該当しない場合'},
]

export const BusinessName = ({diff, isUseBusinessName}) => (
  <>
    <CheckboxField name="use_business_name" label="ビジネスネームを表示名、ジョブカン他サービスとの連携名に使用する" />
    <div className={styles.notes}>チェックがない場合は上記姓名がご利用中のジョブカン他サービスに自動反映されます。</div>
    {isUseBusinessName && (
      <BoxDouble>
        <TextField
          name="business_last_name"
          label="ビジネスネーム(姓)"
          diff={diff['business_last_name']}
          required
          validate={maxLength(32)}
        />
        <TextField
          name="business_first_name"
          label="ビジネスネーム(名)"
          diff={diff['business_first_name']}
          required
          validate={maxLength(32)}
        />
      </BoxDouble>
    )}
  </>
)

export const VisaInfos = ({
  nations,
  visaTypes,
  employeeId,
  noVisaHistory,
  isAdmin,
  diff,
  visaTypeId,
  hasVisaHistory,
}) => {
  const nationOptions = nations
    .filter((nation) => nation.value !== JAPAN_VALUE)
    .map((nation) => ({
      value: '' + nation.id,
      label: nation.name,
    }))
  const visaTypeOptions = visaTypes.map((visaType) => ({
    value: '' + visaType.id,
    label: visaType.name,
  }))
  const visaType = visaTypes?.find((visaType) => visaType.id == visaTypeId)
  return (
    <React.Fragment>
      <SelectField options={nationOptions} name="nation_id" label="国籍・地域" diff={diff.nation_id} />
      <LabelMapper label={'\u200b'} name="visa_history" />
      <FormSection name="visa_history">
        {noVisaHistory ? (
          <React.Fragment>
            <SelectField
              options={visaTypeOptions}
              name="visa_type_id"
              label="在留資格"
              diff={getDiffFromOptions(visaTypeOptions, _.toString(_.get(diff, 'visa_history.visa_type_id')))}
            />
            <DateField
              name="visa_until"
              label="在留期間満了日"
              diff={_.get(diff, 'visa_history.visa_until')}
              required={hasVisaHistory && !noVisaUntilType(visaType)}
            />
            <RadioField
              name="has_extra_action_permission"
              label="資格外活動許可の有無"
              {...toBooleanProps}
              diff={getDiffFromOptions(toBooleanProps.options, _.get(diff, 'visa_history.has_extra_action_permission'))}
            />
            <SelectField
              name="dispatch_type"
              label="派遣・請負就労区分"
              options={dispatchTypes}
              diff={_.get(diff, 'visa_history.dispatch_type')}
            />
            <TextField
              name="visa_code"
              label="在留カード番号"
              diff={_.get(diff, 'visa_history.visa_code')}
              validate={[visaCode, exactLength(12)]}
            />
            <FileField
              name="residence_card"
              label="在留カード"
              diff={getDiffFromFile(diff.visa_history?.residence_card)}
            />
            <div className={styles.notes}>
              すでにファイルが存在する場合は、ファイルを更新した時点で上書き更新されますのでご注意ください。
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <TextField
              name="visa_type_id"
              label="在留資格"
              format={(id) => recordDisplay.option(id, visaTypeOptions)}
              disabled
            />
            <TextField
              name="visa_until"
              label="在留期間満了日"
              format={(s) => (s ? moment(s).format('YYYY/MM/DD') : recordDisplay())}
              disabled
            />
            <TextField
              name="has_extra_action_permission"
              label="資格外活動許可の有無"
              format={(v) => recordDisplay.boolean(v)}
              disabled
            />
            <TextField
              name="dispatch_type"
              label="派遣・請負就労区分"
              format={(type) => recordDisplay.option(type, dispatchTypes)}
              disabled
            />
            <TextField name="visa_code" label="在留カード番号" format={recordDisplay} disabled />
            <FileField name="residence_card" label="在留カード" format={(v) => v || {name: recordDisplay()}} disabled />
          </React.Fragment>
        )}
      </FormSection>
      {employeeId && isAdmin && (
        <React.Fragment>
          <ActionButton as={Link} to={`/employees/${employeeId}/visa_history`} className="u-mb20">
            在留資格履歴へ
          </ActionButton>
          {!noVisaHistory && (
            <React.Fragment>
              <br />
              「在留資格」「在留期間満了日」「資格外活動許可の有無」「派遣・請負就労区分」「在留カード番号」「在留カード」は在留資格履歴の情報から自動で表示されます。
              <br />
              編集する場合は「在留資格履歴へ」ボタンを押してください。
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export const BasicInfomationForProfile = ({
  employee,
  diff,
  isAdmin,
  countryOptions,
  japanId,
  countryValue,
  description,
  description_color_by_rgb,
}) => (
  <Section title="基本情報">
    <Description {...{description, description_color_by_rgb}} />
    <FileField name="icon" label="プロフィール画像" accept="image/*" preview={PreviewIcon} disablePreview={false} />
    <div className={styles.notes}>
      すでにファイルが存在する場合は、ファイルを更新した時点で上書き更新されますのでご注意ください。
    </div>
    <LinkToProcedure label="氏名" value={recordDisplay.registeredFullName(employee)} to="/change_name/employee_input" />
    <AddressWithCountry required countryOptions={countryOptions} isForeign={japanId !== countryValue} diff={diff} />
    <Sex required diff={diff} />
    <Phone diff={diff} />
    <Email diff={diff} />
    {!isAdmin && (
      <div className={styles.notes}>管理者が変更を承認するまでは過去のメールアドレスでのみログインが可能です。</div>
    )}
    <SelectField
      name="householder_relationship"
      label="世帯主の続柄"
      creatable
      options={householderRelationshipOptions}
      diff={diff.householder_relationship}
    />
  </Section>
)

export const LinkToProcedure = ({label, value, to}) => (
  <>
    <Label text={label} />
    <div className={styles.procedureWrap}>
      <div>
        <p className={styles.procedureValue}>{value}</p>
        <p className={styles.procedureNotice}>{`${label}の変更を行う場合は別途手続きが必要になります`}</p>
      </div>
      <div className={styles.edit}>
        <ActionButton as={Link} to={to}>
          {`${label}変更の手続きへ`}
        </ActionButton>
      </div>
    </div>
  </>
)

const BasicInfomation = ({
  employee,
  nations,
  countryValue,
  householderRelationship,
  nationalTypeValue,
  visaTypes,
  employeeId,
  noVisaHistory,
  isAdmin,
  isInitialInput,
  description,
  description_color_by_rgb,
  visaTypeId,
  hasVisaHistory,
  isUseBusinessName,
  diff = {},
}) => {
  const countryOptions = nations
    .filter((nation) => nation.value <= MAX_COUNTRY_VALUE)
    .map((nation) => ({label: nation.name, value: '' + nation.id}))
  const japan = _.find(nations, (nation) => nation.value === JAPAN_VALUE)
  const japanId = japan ? '' + japan.id : null
  return isInitialInput || isAdmin ? (
    <Section title="基本情報">
      <Description {...{description, description_color_by_rgb}} />
      <FileField
        name="icon"
        label="プロフィール画像"
        accept="image/*"
        preview={PreviewIcon}
        disablePreview={false}
        diff={getDiffFromFile(diff.icon)}
      />
      <div className={styles.notes}>
        すでにファイルが存在する場合は、ファイルを更新した時点で上書き更新されますのでご注意ください。
      </div>
      <Name required diff={diff} />
      <div className={styles.notes}>姓名を変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。</div>
      {isAdmin && <BusinessName diff={diff} isUseBusinessName={isUseBusinessName} />}
      <TextField name="former_name" label="旧姓" diff={diff.former_name} />
      <Birthday required diff={diff} />
      <Sex required diff={diff} />
      <Email diff={diff} />
      <div className={styles.notes}>
        メールアドレスを変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。
      </div>
      <AddressWithCountry required countryOptions={countryOptions} isForeign={japanId !== countryValue} diff={diff} />
      <Phone diff={diff} />
      <SelectField
        name="householder_relationship"
        label="世帯主の続柄"
        creatable
        options={householderRelationshipOptions}
        diff={diff.householder_relationship}
      />
      {householderRelationship && householderRelationship !== '本人' && (
        <TextField name="householder_name" label="世帯主の氏名" diff={diff.householder_name} />
      )}
      <NationalType diff={diff} />
      {nationalTypeValue && nationalTypeValue === 'foreigner' && (
        <div>
          <RomajiName diff={diff} />
          <VisaInfos {...{nations, visaTypes, employeeId, noVisaHistory, isAdmin, visaTypeId, hasVisaHistory, diff}} />
        </div>
      )}
    </Section>
  ) : (
    <BasicInfomationForProfile
      employee={employee}
      countryOptions={countryOptions}
      japanId={japanId}
      countryValue={countryValue}
      diff={diff}
      description={description}
      description_color_by_rgb={description_color_by_rgb}
    />
  )
}

export const hasVisaHistory = (visaHistory) => _.some(visaHistory, (value) => value != null && value !== '')

export default BasicInfomation
  |> connect((state, {selector, isForceEmployee}) => ({
    token: state.auth.token,
    nations: state.master.nations || [],
    countryValue: selector(state, 'country'),
    householderRelationship: selector(state, 'householder_relationship'),
    nationalTypeValue: selector(state, 'national_type'),
    employeeId: selector(state, 'id'),
    visaTypes: state.master.visaTypes || [],
    noVisaHistory: selector(state, '_no_visa_history'),
    isAdmin: !isForceEmployee && isAdminSelector(state),
    visaTypeId: selector(state, 'visa_history.visa_type_id'),
    diff: selector(state, '_diff'),
    hasVisaHistory: hasVisaHistory(selector(state, 'visa_history')),
    isUseBusinessName: selector(state, 'use_business_name'),
  }))
  |> withFormSelectors
