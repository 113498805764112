import React from 'react'
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux'
import ListGroup from 'jbc-front/components/ListGroup'
import {connectPager} from 'components/paginators'
import {connectPerPage} from 'components/PerPage'
import connectListData from 'components/ListData'
import Loading from 'components/Loading'
import {EmptyListItem, ListItem} from 'tm/notifications/Sent'
import {getQueryDiff} from 'tm/notifications/reducers'
import {actionCreators} from 'actions'
import api from 'api'

const querySelector = (state) => state.tm.notifications.query
const queryActionSelector = actionCreators.tm.notifications.setQuery

const Pager = connectPager(querySelector, queryActionSelector)
const PerPage = connectPerPage(querySelector, queryActionSelector)
const loadList = (token, query) => api.createWithAuth(token).tm.notifications.sent(query)
const ListData = connectListData(querySelector, actionCreators.tm.notifications, loadList, getQueryDiff)

const SentAll = () => {
  const [loading, notifications, count] = useSelector((state) => [
    state.tm.notifications.list.loading,
    state.tm.notifications.list.data,
    state.tm.notifications.list.count,
  ])

  return (
    <div>
      <ListData />
      <div className="l-main-title-wrap u-pb0">
        <h1 className="m-title-main u-pb40">送信した通知一覧</h1>
      </div>
      <div className="l-wrap-xl l-contents-wrap">
        <div className="l-breadcrumb u-mb30">
          <Link to="/tm/notifications/sent" className="l-breadcrumb-link">
            従業員通知
          </Link>
          <span className="l-breadcrumb-here">送信した通知一覧</span>
        </div>
        <ListGroup>
          <ListGroup.Title>送信した通知</ListGroup.Title>
          {loading ? (
            <ListGroup.Item>
              <Loading />
            </ListGroup.Item>
          ) : notifications.length > 0 ? (
            notifications.map((notification) => <ListItem key={notification.id} notification={notification} />)
          ) : (
            <EmptyListItem />
          )}
        </ListGroup>
        <div className="m-pager-area">
          <Pager totalResult={count} pageLinks={7} />
          <PerPage />
        </div>
      </div>
    </div>
  )
}

export default SentAll
