import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import {updateEmployeeDetail} from 'employees/Update'
import NaviForm from 'employees/NaviForm'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {push} from 'connected-react-router'
import {Redirect} from 'react-router'
import {withFormSelectors, withFormSelectorsProvider} from 'employees/form/common'
import _ from 'lodash'
import CommentModal from 'procedures/CommentModal'
import {employeeDetailEmbed} from 'employees/Show'
import {isAdminSelector, getState} from 'libs/redux'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {eachFormFiles, isUpdateOrDeleteFile} from 'employees/eachFormFiles'

class Confirm extends Component {
  static defaultProps = {
    tmpData: {},
  }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {id} = this.props.match.params
    this.props.loadProcedureStatus(id, this.props.token)
    this.props.loadTmpData(id, this.props.token)
    this.props.loadComments(id, this.props.token)
    this.props.loadFieldGroup(this.props.token, this.props.client)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleFormSubmit = (values) => {
    const {
      updateEmployeeAndNext,
      token,
      match: {
        params: {id},
      },
      healthInsuranceType,
      fields,
    } = this.props
    return updateEmployeeAndNext(values, token, id, healthInsuranceType, fields)
  }

  render() {
    const {
      data,
      fieldGroups,
      employeeFieldGroups,
      submit,
      submitting,
      tmpData,
      comments,
      match: {
        params: {id},
      },
      tmpFiles,
      procedureStatus,
      selector,
      isAdmin,
    } = this.props
    if (procedureStatus.status && procedureStatus.status !== 'applying') {
      return <Redirect to={`/procedure_statuses/${id}`} />
    }
    const procedureType = 'enroll'
    return (
      <div>
        <CommentModal procedureStatusId={id} comments={comments || []} procedureType={procedureType}>
          {({showModal}) => (
            <NaviForm
              submitText="承認して次へ"
              data={data}
              fieldGroups={fieldGroups}
              employeeFieldGroups={employeeFieldGroups}
              tmpData={tmpData.data}
              onSubmit={this.handleFormSubmit}
              procedureType={procedureType}
              tmpFiles={tmpFiles}
              employee={procedureStatus.employee}
              comments={comments || {}}
              isAdmin={isAdmin}
              naviSubmit={
                <Button primary disabled={submitting} onClick={submit} widthWide>
                  承認して次へ
                </Button>
              }
              otherButtons={
                <Button disabled={submitting} onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
              selector={selector}
              withDiff
              withText="入力・修正箇所には承認待ちアイコンがついていますので、変更点を確認の上更新してください。"
            />
          )}
        </CommentModal>
      </div>
    )
  }
}

export default Confirm
  |> connect(
    (state, {isSubmitting}) => ({
      token: state.auth.token,
      data: state.procedureStatuses.current.data.employee,
      tmpData: state.procedureStatuses.tmpData.data.employee,
      tmpFiles: state.procedureStatuses.tmpData.tmpFiles,
      comments: fetchSelector(state, 'procedure_comments').data,
      healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
      procedureStatus: state.procedureStatuses.current.data,
      fields: fetchSelector(state, 'custom_fields').data,
      fieldGroups: fetchSelector(state, 'custom_field_groups').data,
      employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
      submitting: isSubmitting(state),
      isAdmin: isAdminSelector(state),
      client: state.client.current,
    }),
    (dispatch, {submit, getValues}) => ({
      loadProcedureStatus(id, token) {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api.createWithAuth(token).procedureStatuses.get(id, ['employee', ...employeeDetailEmbed(false)])
          )
        )
      },
      loadTmpData(id, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api.createWithAuth(token).procedureStatuses.tmpData.mapByName(id, 'application', 'employee'),
              api.createWithAuth(token).procedureStatuses.tmpFiles.list(id, 'application'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      loadFieldGroup(token, client) {
        dispatch(
          actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list())
        )
        dispatch(
          actionCreators.fetchData(
            'custom_field_groups',
            api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
          )
        )
        dispatch(
          actionCreators.fetchData(
            'custom_fields',
            api.createWithAuth(token).employeeCustom.fields.list(client.id, {
              embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
            })
          )
        )
      },
      destroy() {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
        dispatch(actionCreators.fetchDestroy('employee_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_fields'))
      },
      submit: () => {
        dispatch(submit)
      },
      async updateEmployeeAndNext(values, token, procedureStatusId, healthInsuranceType, fields) {
        try {
          const state = await getState(dispatch)
          const formValues = getValues(state)
          const rejectFiles = Array.from(eachFormFiles(formValues, fields))
            .filter(isUpdateOrDeleteFile)
            .map((f) => f.dbName)

          const employeeId = values.employee.id
          const {dependents} = await updateEmployeeDetail(employeeId, values, token, undefined, fields)
          const authedApi = api.createWithAuth(token)
          await authedApi.procedureStatuses.moveTmpFilesToDb(procedureStatusId, rejectFiles)
          await authedApi.procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            {
              detailFinished: true,
            },
            'draft',
            'flow_status'
          )
          if (healthInsuranceType === 'its' && _.get(values, 'healthInsurance.joined') && !_.isEmpty(dependents)) {
            await authedApi.procedureStatuses.tmpData.createOrUpdate(
              procedureStatusId,
              dependents,
              'draft',
              'dependents'
            )
            dispatch(push(`/enroll/confirm_dependents/${procedureStatusId}?dependent_index=0`))
          } else {
            await authedApi.procedureStatuses.accept(procedureStatusId)
            dispatch(push(`/enroll/flow/${procedureStatusId}`))
          }
          dispatch(notifySuccess('保存しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
  |> withFormSelectors
  |> withFormSelectorsProvider
