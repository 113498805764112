import React, {Component} from 'react'
import Form from 'myNumber/businessPartner/GuestForm'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import _ from 'lodash'
import Loading from 'components/Loading'
import {parse} from 'query-string'
import {asyncError} from 'store/actions/asyncError'

const ALREADY_INPUT = 400
const TOKEN_EXPIRED = 403
const OTHER_ERROR = -1

class GuestInput extends Component {
  state = {
    finished: false,
  }

  getToken() {
    const {
      location: {search},
    } = this.props
    const {token} = parse(search)
    return token
  }

  componentDidMount() {
    const {dispatch} = this.props
    dispatch(
      actionCreators.fetchData(
        'business_partner_guest_input',
        api.businessPartnerInput.get(this.getToken()).catch((err) => {
          if (_.get(err, 'response.status')) {
            return {error: err.response.status}
          } else {
            dispatch(asyncError(err))
            return {error: OTHER_ERROR}
          }
        })
      )
    )
  }

  componentWillUnmount() {
    const {dispatch} = this.props
    dispatch(actionCreators.fetchDestroy('business_partner_guest_input'))
  }

  handleSubmit = async (values) => {
    const {dispatch} = this.props
    try {
      await api.businessPartnerInput.create(values)
      this.setState({finished: true})
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  render() {
    const {data, error, loading} = this.props
    const {finished} = this.state
    if (finished) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">入力が完了しました</p>
          </div>
        </div>
      )
    }
    if (loading || (!error && !data)) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <Loading />
          </div>
        </div>
      )
    }
    if (error) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">
              {error == ALREADY_INPUT
                ? 'すでに入力済です'
                : error == TOKEN_EXPIRED
                ? '入力期限が終了しています'
                : 'エラーが発生しました'}
            </p>
          </div>
        </div>
      )
    }
    const {my_number_purpose_of_use: purposeOfUse} = data

    return <Form purposeOfUse={purposeOfUse} onSubmit={this.handleSubmit} initialValues={{token: this.getToken()}} />
  }
}

export default connect((state) => fetchSelector(state, 'business_partner_guest_input'))(GuestInput)
