import {useCallback, useState} from 'react'
import _ from 'lodash'
import {gql, useApolloClient} from '@apollo/client'
import {useCancellable} from 'hooks/useCancellable'
import {useNotify} from 'hooks/useNotify'

const VALIDATE_DYNAMIC_DOCUMENT = gql`
  query validateDynamicDocument($document: DocumentInput!) {
    client {
      id
      validateDynamicDocument(document: $document) {
        warnings {
          causePrefix
          cause
          causeSuffix
        }
      }
    }
  }
`

type Warning = {
  causePrefix: string
  cause: string
  causeSuffix: string
}

const VALIDATE_CHUNK_SIZE = 50

type ValidateDynamicDocument = {
  /** バリデーション実行中か？ */
  validating: boolean
  /** 進捗率 (0-100) */
  validatingProgress: number
  /** 検出された警告のリスト */
  warnings: Warning[]
  /**
   * バリデーションを開始する
   * @param documentTemplateId 使用するテンプレートのID
   * @param employeeIds 選択従業員のID
   */
  validate: (documentTemplateId: string, employeeIds: string[]) => void
}

/**
 * 非同期バリデーションの管理用Hook
 */
export const useValidateDynamicDocument = (): ValidateDynamicDocument => {
  const notify = useNotify()
  const client = useApolloClient()
  const [validating, setValidating] = useState(false)
  const [validatingProgress, setValidatingProgress] = useState(0)
  const [warnings, setWarnings] = useState<Warning[]>([])
  const runCancellable = useCancellable('validate_dynamic_document')
  const validate = useCallback((documentTemplateId, employeeIds) => {
    runCancellable(async (isCancelled) => {
      setValidating(true)
      setValidatingProgress(0)
      setWarnings([])

      try {
        if (_.isEmpty(employeeIds)) {
          if (isCancelled()) return
          const {
            data: {
              client: {
                validateDynamicDocument: {warnings: warns},
              },
            },
          } = await client.query({
            query: VALIDATE_DYNAMIC_DOCUMENT,
            fetchPolicy: 'network-only',
            variables: {
              document: {
                documentTemplateId,
                employeeIds: [],
              },
            },
          })

          if (isCancelled()) return
          setWarnings((warnings) => [...warnings, ...warns])
          setValidatingProgress(100)
        } else {
          let processedCount = 0
          for (const ids of _.chunk(employeeIds, VALIDATE_CHUNK_SIZE)) {
            if (isCancelled()) return
            const {
              data: {
                client: {
                  validateDynamicDocument: {warnings: warns},
                },
              },
            } = await client.query({
              query: VALIDATE_DYNAMIC_DOCUMENT,
              fetchPolicy: 'network-only',
              variables: {
                document: {
                  documentTemplateId,
                  employeeIds: ids,
                  skipGlobalVariablesCheck: processedCount !== 0,
                },
              },
            })
            processedCount += ids.length

            if (isCancelled()) return
            setWarnings((warnings) => [...warnings, ...warns])
            setValidatingProgress(Math.round((processedCount / employeeIds.length) * 100))
          }
        }
      } catch (error) {
        const msg = _.get(error, 'graphQLErrors[0].message') || _.get(error, 'networkError.result.error')
        notify(msg || error.message, 'error')
      }

      if (isCancelled()) return
      setValidating(false)
    })
  }, [])

  return {validating, validatingProgress, warnings, validate}
}
