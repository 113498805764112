import React, {useEffect} from 'react'
import Form, {autofill, makeInitialValuesFromRelatedProcedure} from 'procedures/maternityLeave/finish/Form'
import {alreadyExistSameDependent} from 'procedures/maternityLeave/Form'
import {useSelector, useDispatch} from 'react-redux'
import {SubmissionError} from 'redux-form'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import moment from 'moment'
import {asyncError} from 'store/actions/asyncError'

export default ({location: {search}}) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const employee = useSelector((state) => state.employees.current.data)
  const procedureStatus = useSelector((state) => state.procedureStatuses.current.data)
  const dependents = useSelector((state) => state.employees.current.data.employee_dependents)
  const {employee_id: employeeId, procedure_id: procedureId} = parse(search)
  const authedApi = api.createWithAuth(token)

  const loadData = () => {
    dispatch(
      actionCreators.employees.current.fetchData(authedApi.employees.get(employeeId, ['office', 'employee_dependents']))
    )
  }

  const loadProcedureStatus = () => {
    dispatch(
      actionCreators.procedureStatuses.current.fetchData(
        authedApi.procedureStatuses.get(procedureId, ['employee', 'procedure_maternity_datum'])
      )
    )
  }

  const destroy = () => {
    dispatch(actionCreators.employees.current.destroy())
    dispatch(actionCreators.procedureStatuses.current.destroy())
  }

  useEffect(() => {
    loadData()
    procedureId && loadProcedureStatus()
    return destroy
  }, [])

  const handleSubmit = async (values) => {
    if (alreadyExistSameDependent(values.procedure_maternity_child_data, dependents)) {
      throw new SubmissionError({'': 'すでに同じ被扶養者が登録されています'})
    } else {
      await createProcedure(values)
    }
  }

  const createProcedure = async (values) => {
    const formattedValues = {
      ...values,
      mother_child_note: values.maternity_status === 'after' ? values.mother_child_note : [],
    }
    const {
      procedure_maternity_child_data: procedureMaternityChildData,
      expected_birth_date: expectedBirthDate,
      started_at: startOn,
      expected_finished_at: endOnPrev,
      finished_at: endOn,
      maternity_status: maternityStatus,
    } = formattedValues
    let procedureStatusId = procedureId
    try {
      await authedApi.employees.leaveOfAbsences.updateOrCreateForMaternity(
        employee.id,
        startOn,
        endOn,
        startOn,
        endOnPrev
      )
      if (!procedureStatusId) {
        const {
          data: {id},
        } = await authedApi.procedureStatuses.start(employeeId, 'finish_maternity', expectedBirthDate, 'in_progress')
        procedureStatusId = id
      } else {
        await authedApi.procedureStatuses.update(procedureStatusId, {
          procedure_type: 'finish_maternity',
          date_on: expectedBirthDate,
          status: 'in_progress',
        })
      }
      await authedApi.procedureStatuses.reports.create(
        procedureStatusId,
        employee.office.health_insurance_type === 'its' ? 'r506' : 'r505',
        {submitOn: moment()}
      )
      dispatch(autofill('procedure_status_id', procedureStatusId))
      await authedApi.procedureStatuses.finishMaternityData.create(procedureStatusId, formattedValues)
      await authedApi.procedureStatuses.startProgress(procedureStatusId)
      if (
        maternityStatus === 'after' &&
        procedureMaternityChildData.some((childDatum) => childDatum.add_support && !childDatum.skip_add_support)
      ) {
        dispatch(
          push(`/add_dependents/detail?employee_id=${employeeId}&maternity_procedure_status_id=${procedureStatusId}`)
        )
      } else {
        dispatch(push(`/procedure_statuses/${procedureStatusId}`))
      }
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={makeInitialValuesFromRelatedProcedure(procedureStatus)}
      employee={employee}
    />
  )
}
