import React from 'react'
import {connect, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {bindActionCreators} from 'redux'
import {FormSection, reduxForm} from 'redux-form'
import {actionCreators} from 'actions'
import _ from 'lodash'
import {recordDisplay} from 'utils'
import SelectList from 'components/SelectList'
import {getTmGroups} from 'employees/form/EmploymentInfomation'
import {wrapArrowText} from 'tm/employees/Search'
import SearchFormWithCardList from 'tm/notifications/list/SearchFormWithCardList'
import {getSavedDisplayEmployeeLimit} from 'tm/employees/list/utils'
import PaginatorWithResult from 'tm/employees/list/PaginatorWithResult'
import SortableTh from 'tm/employees/list/SortableTh'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import Button from 'jbc-front/components/Button'
import styles from 'tm/notifications/Target.scss'
import classNames from 'classnames'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'tmNotificationCreateForm'

const additionalParams = {embed: ['personnel_history'], only_tm_user: true}

const isAllSelected = (employees, selected) =>
  _.some(selected) && employees.every((employee) => selected[employee] || false)

const ListTable = ({list, selected, changeMulti, employeeIds, handleSelectClick}) => {
  const handleSelectAllClick = () => {
    const allSelected = isAllSelected(employeeIds, selected)
    if (allSelected) {
      changeMulti(employeeIds.filter((id) => selected[id]).map((id) => ({[id]: false})))
    } else {
      changeMulti(employeeIds.map((id) => ({[id]: true})))
    }
  }

  const allSelected = isAllSelected(employeeIds, selected)
  const allSelectedClass = allSelected ? ' m-table-checkbox-on ' : ' m-table-checkbox-off '

  return (
    <div className="l-overflow-scroll">
      <table className={`m-table-list m-table-fixed`}>
        <thead>
          <tr className={styles.table}>
            <th className={styles.checkColumn + allSelectedClass} onClick={handleSelectAllClick}>
              <input type="checkbox" readOnly checked={allSelected} />
            </th>
            <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
              氏名
            </SortableTh>
            <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
              氏名カナ
            </SortableTh>
            <SortableTh field="group" globalClassName={styles.groupColumn}>
              グループ
            </SortableTh>
          </tr>
        </thead>
        <tbody>
          {list.map(({item: employee}) => {
            const id = employee.id
            const selectedClass = selected[id] ? ' m-table-checkbox-on ' : ' m-table-checkbox-off '
            return (
              <tr className={styles.table} key={id}>
                <td className={styles.checkColumn + selectedClass} onClick={() => handleSelectClick(id)}>
                  <input type="checkbox" readOnly checked={selected[id] || false} />
                </td>
                <td className={styles.nameColumn}>{recordDisplay.fullName(employee)}</td>
                <td className={styles.nameColumn}>
                  {(!_.get(employee, 'business_first_name') &&
                    !_.get(employee, 'business_last_name') &&
                    recordDisplay.fullName(employee, false, true)) ||
                    recordDisplay()}
                </td>
                <td className={styles.groupColumn}>
                  {recordDisplay(
                    !_.isEmpty(getTmGroups(employee)) && <span>{wrapArrowText(getTmGroups(employee)[0])}</span>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const Form =
  (({handleSubmit, user, selectedIds, including_myself, handleSelectClick, handleBack}) => {
    return (
      <form onSubmit={handleSubmit}>
        <FormErrors />
        <div className="u-ta-c u-mt20">
          <FormSection name="talent_notification">
            <div className={styles.includingMyself} onClick={() => handleSelectClick(user.employee.id)}>
              <input className="m-checkbox-input" type="checkbox" checked={including_myself} readOnly />
              <span className="m-checkbox-parts">回答者に自分自身を含める</span>
            </div>
          </FormSection>
          <span className={styles.button}>
            <Button onClick={handleBack}>新規通知作成に戻る</Button>
          </span>
          <span className={styles.button}>
            <Button primary onClick={handleSubmit} disabled={selectedIds.length < 1}>
              通知対象に設定
            </Button>
          </span>
          {selectedIds.length > 0 && (
            <div className={styles.selectedCount}>選択中の従業員：{`${selectedIds.length}`}人</div>
          )}
        </div>
      </form>
    )
  })
  |> reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail,
  })

const Target = ({
  employees,
  employeeIds,
  location,
  user,
  selected,
  including_myself,
  toogleSelected,
  changeMulti,
  handleSetStep,
}) => {
  const dispatch = useDispatch()
  const selectedIds = _.keys(_.pickBy(selected))

  const handleSelectClick = (id) => {
    toogleSelected(id)
  }
  const handleBack = () => {
    history.pushState(null, null, null)
    handleSetStep('create')
    window.scrollTo(0, 0)
  }

  return (
    <>
      <div className="l-breadcrumb">
        <Link to="/tm/notifications/" className="l-breadcrumb-link">
          従業員通知
        </Link>
        <span className="l-breadcrumb-link u-cur-pointer" onClick={handleBack}>
          新規通知作成
        </span>
        <span className="l-breadcrumb-here">通知対象選択</span>
      </div>
      <div className={classNames(styles.targetTitleWrap, 'l-title-wrap')}>
        <h1 className="m-title-main">通知対象選択</h1>
      </div>
      <SelectList list={employees}>
        {({list}) => {
          return (
            <div>
              <div className={styles.widePage}>
                <SearchFormWithCardList
                  additionalParams={additionalParams}
                  searchEmail={false}
                  displayListType={false}
                />
                <ListTable
                  {...{
                    list,
                    employeeIds,
                    selected,
                    changeMulti,
                    handleSelectClick,
                  }}
                />
                <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(location.pathname, {})} />
              </div>
            </div>
          )
        }}
      </SelectList>
      <Form
        {...{user, selectedIds, including_myself, handleSelectClick, handleBack}}
        onSubmit={() => {
          try {
            if (selectedIds.length < 1) {
              dispatch(notifyError('通知対象を1人以上選択してください'))
              return
            }
            handleSetStep('confirm')
            window.scrollTo(0, 0)
          } catch (err) {
            dispatch(asyncError(err))
          }
        }}
      />
    </>
  )
}

export default connect(
  (state) => ({
    employees: state.employees.list.data,
    employeeIds: state.employees.all.data,
    location: state.router.location,
  }),
  (dispatch) => ({
    ...bindActionCreators(_.pick(actionCreators.employees, ['toogleSelected', 'changeMulti']), dispatch),
  })
)(Target)
