import {useQuery} from 'hooks/graphql'
import {GOVERNMENT_OFFICES, GovernmentOfficesResult, GovernmentOfficesVariables, GovernmentOfficeType} from './query'

export const useGovernmentOffices = (
  prefectureId: string,
  governmentOfficeType: GovernmentOfficeType,
  skip = false
) => {
  return useQuery<GovernmentOfficesResult, GovernmentOfficesVariables>(GOVERNMENT_OFFICES, {
    variables: {
      prefectureId,
      governmentOfficeType,
    },
    skip,
  })
}
