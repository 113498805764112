import {FC, ReactNode} from 'react'

// @ts-ignore
import {Tabs} from 'documents/show/Tabs'

interface CommonTitleProps {
  children?: ReactNode
  pathname: string
  id: string
}

export const CommonTitle: FC<CommonTitleProps> = ({children, pathname, id}) => (
  <div className="l-main-title-wrap u-pb0">
    <h1 className="m-title-main">書類作成</h1>
    {children}
    <Tabs current={pathname} id={id} />
  </div>
)
