import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import api from 'api'
import EnrollEmployeesList from 'procedures/enroll/EmployeesList'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {push} from 'connected-react-router'
import Button from 'jbc-front/components/Button'
import Modal from 'jbc-front/components/Modal'

class ChooseEmployeeRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  handleRequestClick = () => {
    const selected = _.keys(_.pickBy(this.props.selected, (value) => value))
    if (selected.length === 0) {
      this.props.notifyNoSelect()
    } else {
      this.setState({showModal: true})
    }
  }

  handleModalSubmit = () => {
    const {token, selected} = this.props
    this.props.requestEnrollProcedure(token, _.keys(_.pickBy(selected, (value) => value)))
    return true
  }

  render() {
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">従業員を選択してください</h1>
        </div>
        <div className="l-wrap-xl">
          <EnrollEmployeesList isRequest isMultiple />
          <div className="u-ta-c u-mt60">
            <Button primary onClick={this.handleRequestClick}>
              依頼する
            </Button>
          </div>
        </div>
        <Modal
          header="本人に入力を依頼する"
          body={'選択された従業員宛に、入社手続きの入力依頼をメールします。'}
          cancel="キャンセル"
          submit="送信"
          onSubmit={this.handleModalSubmit}
          isOpen={this.state.showModal}
          hideModal={() => this.setState({showModal: false})}
        />
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    selected: state.employees.selected,
  }),
  (dispatch) => ({
    async requestEnrollProcedure(token, employeeIds) {
      try {
        for (let id of employeeIds) {
          await api.createWithAuth(token).requestProcedure(id, 'enroll')
        }
        dispatch(notifySuccess('依頼しました'))
        dispatch(push('/dashboard'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    notifyNoSelect() {
      dispatch(notifyError('従業員が選択されていません'))
    },
  })
)(ChooseEmployeeRequest)
