import React, {useState} from 'react'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Edit} from 'jbc-front/components/icons'
import {HashLink} from 'react-router-hash-link'
import {employmentStatuses, retireTypes} from 'employees/form/EmploymentInfomation'
import {Dl, Dd, Dt, Half, SubPanel, Grid} from 'employees/show/Parts'
import {isAdminSelector} from 'utils'
import styles from 'employees/show/Parts.scss'
import ShowMoreButton from 'components/ShowMoreButton'
import ReadMoreReadLess from 'components/ReadMoreReadLess'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'

const leaveReasonTypes = [
  {value: 'sickness', label: '私傷病休職'},
  {value: 'childbirth', label: '産前産後休業'},
  {value: 'childcare', label: '育児休業'},
  {value: 'at_birth', label: '出生時育児休業'},
  {value: 'nursing', label: '介護休業'},
  {value: 'others', label: 'その他'},
]
export const getGroupsWithCode = (personnelHistory) => {
  if (!personnelHistory) return []

  return [
    personnelHistory['group_0_full_name_with_code'],
    personnelHistory['group_1_full_name_with_code'],
    personnelHistory['group_2_full_name_with_code'],
  ].filter((group) => !!group)
}

const Periods = ({periods, yearStyle}) => {
  const [isExpand, setIsExpand] = useState(false)
  const onToggleClick = () => {
    setIsExpand(!isExpand)
  }
  const size = 3
  return _.isEmpty(periods) ? (
    <Dd>{recordDisplay()}</Dd>
  ) : (
    <Dd>
      {isExpand
        ? periods
            .map((p) => (
              <div key={p.id}>
                {recordDisplay.date(p.start_on, yearStyle)}～{recordDisplay.date(p.end_on, yearStyle)}
                {p.reason_type && <span>（{recordDisplay.option(p.reason_type, leaveReasonTypes)}）</span>}
              </div>
            ))
            .reverse()
        : periods
            .map((p) => (
              <div key={p.id}>
                {recordDisplay.date(p.start_on, yearStyle)}～{recordDisplay.date(p.end_on, yearStyle)}
                {p.reason_type && <span>（{recordDisplay.option(p.reason_type, leaveReasonTypes)}）</span>}
              </div>
            ))
            .reverse()
            .slice(0, size)}
      {periods.length > 3 && (
        <div className={styles.btnarea}>
          <ShowMoreButton isExpanded={isExpand} onClick={onToggleClick} />
        </div>
      )}
    </Dd>
  )
}

const EmploymentInformation = ({isAdmin, editLink, employee, yearStyle}) => {
  const hasBranchOffice = useHasBranchOffice()
  const resumes = employee.employee_resumes || []
  const personnelHistory = employee?.personnel_history

  return (
    <Panel>
      {isAdmin ? (
        <HashLink to={`${editLink}#EmploymentInfomation`}>
          <Headding icon={<Edit icon={16} />}>業務情報</Headding>
        </HashLink>
      ) : (
        <Headding>業務情報</Headding>
      )}
      <Item>
        <Grid>
          <Dl>
            <Dt>スタッフコード</Dt>
            <Dd>{recordDisplay(employee.staff_code)}</Dd>
          </Dl>
          {isAdmin && (
            <Dl>
              <Dt>在籍状況</Dt>
              <Dd>{recordDisplay(employmentStatuses[employee.employment_status])}</Dd>
            </Dl>
          )}
          {hasBranchOffice && (
            <Dl>
              <Dt>適用事業所</Dt>
              <Dd>{recordDisplay(_.get(employee, 'personnel_history.office_name'))}</Dd>
            </Dl>
          )}
          <Dl>
            <Dt>業務内容</Dt>
            <Dd>
              <ReadMoreReadLess key={employee.business_content}>
                {recordDisplay(_.get(employee, 'business_content'))}
              </ReadMoreReadLess>
            </Dd>
          </Dl>
          <Dl>
            <Dt>雇用形態</Dt>
            <Dd>{recordDisplay(personnelHistory?.employment_type_name_with_code)}</Dd>
          </Dl>
          <Dl>
            <Dt>役職</Dt>
            <Dd>{recordDisplay(personnelHistory?.position_name_with_code)}</Dd>
          </Dl>
          <Dl>
            <Dt>グループ</Dt>
            <Dd>
              {recordDisplay(
                getGroupsWithCode(personnelHistory).length > 0 &&
                  getGroupsWithCode(personnelHistory).map((group) => (
                    <span key={group.id}>
                      {group}
                      <br />
                    </span>
                  ))
              )}
            </Dd>
          </Dl>
          <Dl>
            <Dt>職種</Dt>
            <Dd>{recordDisplay(personnelHistory?.occupation_name_with_code)}</Dd>
          </Dl>
          <Dl>
            <Dt>入社日</Dt>
            <Dd>{recordDisplay.date(employee.joined_at, yearStyle)}</Dd>
          </Dl>
          <Dl>
            <Dt>勤続年数</Dt>
            <Dd>{recordDisplay.workingYears(employee)}</Dd>
          </Dl>
          <Dl>
            <Dt>休職期間</Dt>
            <Periods periods={employee.employee_leave_of_absences} yearStyle={yearStyle} />
          </Dl>
          <Dl>
            <Dt>契約期間</Dt>
            <Periods periods={employee.contract_periods} yearStyle={yearStyle} />
          </Dl>
          <Dl>
            <Dt>契約更新のためのアラート</Dt>
            <Dd>{recordDisplay.date(employee.contract_alert_on, yearStyle)}</Dd>
          </Dl>
          {employee.retired_at && (
            <>
              <Dl>
                <Dt>退職日</Dt>
                <Dd>{recordDisplay.date(employee.retired_at, yearStyle)}</Dd>
              </Dl>
              <Dl>
                <Dt>退職区分</Dt>
                <Dd>{recordDisplay.option(employee.retire_type, retireTypes)}</Dd>
              </Dl>
              <Dl>
                <Dt>退職理由</Dt>
                <Dd>{recordDisplay(employee.retire_reason)}</Dd>
              </Dl>
            </>
          )}
        </Grid>
        <Grid>
          <Half>
            {resumes.length > 0 && (
              <SubPanel>
                <HashLink to={`${editLink}#CurriculumVitae`}>
                  <SubPanel.Headding icon={<Edit icon={16} />}>履歴書・職務経歴書</SubPanel.Headding>
                </HashLink>
                <Grid className={styles.panelHalf}>
                  {resumes.map((resume, index) => (
                    <Dl key={index}>
                      <Dt>ファイル{index + 1}</Dt>
                      <Dd>{recordDisplay.file(resume.file)}</Dd>
                    </Dl>
                  ))}
                </Grid>
              </SubPanel>
            )}
          </Half>
        </Grid>
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
  isAdmin: isAdminSelector(state),
}))(EmploymentInformation)
