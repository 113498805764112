import {gql} from '@apollo/client'
import type {Status} from 'types/api/asyncTasks/asyncTask'

export const DYNAMIC_DOCUMENT_ASYNC_TASK_FIELDS_FRAGMENT = gql`
  fragment DynamicDocumentAsyncTaskFields on DynamicDocument {
    status
    startedAt
    generatedAt
    shouldUpdateVariableSnapshot
    previewable
    downloadable
  }
`

export const DOCUMENT_CONTRACT_REQUEST_ASYNC_TASK_FIELDS_FRAGMENT = gql`
  fragment DocumentContractRequestAsyncTaskFields on DocumentContractRequest {
    id
    status
    startedAt
    finishedAt
    confirmed
  }
`

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    title
    visibility
    documentEmployeesCount
    existContractTargets
    contractCreatable
    contractSending
    contractSent
    dynamicDocument {
      id
      ...DynamicDocumentAsyncTaskFields
    }
    documentContractRequest {
      ...DocumentContractRequestAsyncTaskFields
    }
  }
  ${DYNAMIC_DOCUMENT_ASYNC_TASK_FIELDS_FRAGMENT}
  ${DOCUMENT_CONTRACT_REQUEST_ASYNC_TASK_FIELDS_FRAGMENT}
`

export interface DynamicDocumentAsyncTaskFields {
  status: Status
  startedAt?: string
  generatedAt?: string
  shouldUpdateVariableSnapshot: boolean
  previewable: boolean
  downloadable: boolean
}

export interface DocumentContractRequestAsyncTaskFields {
  id: string
  status: Status
  startedAt?: string
  finishedAt?: string
  confirmed: boolean
}
