import {FC} from 'react'
import styles from './SearchResult.scss'

type SearchResultProps = {
  total: number
  page: number
  limit: number
  failureMessage?: string
}

export const SearchResult: FC<SearchResultProps> = ({total, page, limit, failureMessage = '結果がありません'}) => {
  const begin = limit * (page - 1) + 1
  const lastElementOfPage = limit * page
  const end = lastElementOfPage > total ? total : lastElementOfPage

  return total > 0 ? (
    <div className={styles.searchResultContainer}>
      <span className={styles.searchResultTotal}>{total}</span>件中
      <span className={styles.searchResultRange}>
        {begin}-{end}
      </span>
      を表示
    </div>
  ) : (
    <p className={styles.searchResultErrorMessage}>{failureMessage}</p>
  )
}
