import React from 'react'
import {reduxForm, Field} from 'redux-form'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {Error} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import {Download} from 'jbc-front/components/icons'
import {required} from 'jbc-front/utils/validators'
import styles from 'documents/CsvDownload.scss'
import {downloadFile} from 'libs/downloadFile'
import _ from 'lodash'
import {asyncError} from 'store/actions/asyncError'
import {useSelector} from 'hooks/redux'

const formName = 'csv_download'

const downloadOptions = [
  {value: 'UTF-8', label: 'UTF-8（推奨）'},
  {value: 'Windows-31J', label: 'Shift-JIS'},
]
const targetOptions = [
  {value: 'all', label: '全従業員'},
  {value: 'search', label: '検索絞込中の従業員'},
]

const renderRadioField = ({label, input, options, disabled, note, radioNote, meta}) => (
  <div className={meta.error && meta.touched ? styles.invalidField : styles.field}>
    <div className={styles.label}>{label}</div>
    <div className={styles.note}>{note}</div>
    <div>
      {options.map(({label, value}) => (
        <div style={{display: 'inline-block'}} className="input-label" key={value}>
          <label>
            <input
              {..._.omit(input, 'value')}
              type="radio"
              value={value}
              checked={value == input.value}
              disabled={disabled}
              className={`m-radio-input${disabled ? ' disabled' : ''}`}
            />
            <span className={`m-radio-parts${disabled ? ' disabled' : ''}`}>{label}</span>
          </label>
        </div>
      ))}
    </div>
    {radioNote && <div className={styles.note}>{radioNote}</div>}
    <Error meta={meta} label={label} />
  </div>
)

const Form =
  (({handleSubmit, submitting, hideModal = [], title}) => {
    return (
      <>
        <Modal.Header hideModal={hideModal}>{title}のダウンロード</Modal.Header>
        <Modal.Body className={styles.modalbody}>
          <form onSubmit={handleSubmit}>
            <Field
              component={renderRadioField}
              label="ファイル形式選択"
              name="encoding"
              options={downloadOptions}
              radioNote="Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。"
              validate={required}
            />
            <Field
              label="ダウンロード対象選択"
              component={renderRadioField}
              name="target"
              options={targetOptions}
              note={
                <React.Fragment>
                  全従業員：書類に登録された全従業員の{title}をダウンロードします。
                  <br />
                  検索絞込中の従業員：検索絞込した従業員の{title}をダウンロードします。
                </React.Fragment>
              }
              validate={required}
            />
            <br />
            <br />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={hideModal}>キャンセル</Button>
            <Button primary onClick={handleSubmit} disabled={submitting}>
              <Download />
              ダウンロード
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </>
    )
  })
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
  })

const initialValues = {
  encoding: 'UTF-8',
}

const CsvDownload = ({
  isModalOpen,
  showModal,
  hideModal = [],
  id,
  search,
  contract = false,
  disabled,
  disabledReason,
}) => {
  const endPoint = contract ? `/api/export_document_contracts_csv/${id}` : `/api/export_documents_csv/${id}`
  const title = contract ? '電子契約情報' : '書類情報'
  const selectedOffices = useSelector((state) => state.session.selectedOffices)

  return (
    <>
      <Button
        widthAuto
        onClick={showModal}
        className={styles.downloadButton}
        disabled={disabled}
        disabledReason={disabledReason}
      >
        {title}ダウンロード
      </Button>
      <Modal isOpen={isModalOpen} hideModal={hideModal}>
        {isModalOpen && (
          <Form
            {...{hideModal, title}}
            initialValues={{...initialValues, target: 'all'}}
            onSubmit={async ({...values}, dispatch, {hideModal}) => {
              const params = {...values, search: search}
              hideModal()
              await downloadFile({
                url: endPoint,
                asyncError: (err) => dispatch(asyncError(err)),
                params,
                usePost: false,
                selectedOffices,
              })
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default withModal(CsvDownload)
