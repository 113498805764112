import type {RouteParams} from 'types/routes'
import {RegistrationRoutes} from './registration'
import {RegistrationTrialRoutes} from './registrationTrial'
import {InvitationConfirmationRoutes} from './invitationConfirmation'
import {ChangeEmailConfirmationRoutes} from './changeEmailConfirmation'
import {ExprieTokenRoutes} from './expireToken'

export const withoutAuthRoutes: RouteParams[] = [
  ...RegistrationRoutes,
  ...RegistrationTrialRoutes,
  ...InvitationConfirmationRoutes,
  ...ChangeEmailConfirmationRoutes,
  ...ExprieTokenRoutes,
]
