import React from 'react'
import {DateField, Section} from 'jbc-front/components/Form'
import {
  reduxForm,
  autofill as autofillForm,
  formValueSelector,
  FormSection,
  isSubmitting as isSubmittingForm,
} from 'redux-form'
import {Name, RomajiName} from 'FormFields'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Edit} from 'jbc-front/components/icons'
import ActionButton from 'jbc-front/components/ActionButton'
import {bindActionCreators} from 'redux'
import _ from 'lodash'
import styles from 'procedures/changeAddress/changeAddress.scss'
import EmployeeName from 'procedures/EmployeeName'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import ReviewComments from 'components/ReviewComments'

const formName = 'changeName'
export const autofill = autofillForm.bind(null, formName)
export const isSubmitting = isSubmittingForm(formName)

const Form = ({
  submitting,
  handleSubmit,
  autofill,
  editOldName,
  oldName,
  otherButtons,
  submitText = '次へ',
  employee,
  isForeigner,
  comments,
}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">氏名情報</h1>
      <EmployeeName employee={employee} />
      <p className="u-mt15">口座情報の変更はダッシュボードのプロフィール編集から変更してください</p>
    </div>
    <div className="basic_information">
      <FormErrors />
      <ReviewComments comments={comments} />
      <Section title="氏名変更日">
        <DateField name="date_on" label="氏名変更日" required />
      </Section>
      <Section title="変更前氏名">
        {editOldName ? (
          <FormSection name="procedure_data">
            <Name />
            {isForeigner && <RomajiName />}
          </FormSection>
        ) : (
          <div className={styles.old}>
            <div>
              {recordDisplay.fullName(oldName, true)}
              {isForeigner && [<br key="br" />, recordDisplay(oldName.romaji_name)]}
            </div>
            <div className={styles.edit}>
              <ActionButton icon={<Edit />} onClick={() => autofill('_edit_procedure_data', true)} />
            </div>
          </div>
        )}
      </Section>
      <Section title="新しい氏名">
        <Name required />
        {isForeigner && <RomajiName />}
      </Section>
      <div className="u-ta-c u-mt30">
        {otherButtons}
        <AsyncTaskConsumer>
          {({taskRunningProps}) => (
            <Button primary onClick={handleSubmit} disabled={submitting} {...taskRunningProps}>
              {submitText}
            </Button>
          )}
        </AsyncTaskConsumer>
      </div>
    </div>
  </form>
)

const nameColumnNames = [
  'first_name',
  'last_name',
  'first_name_kana',
  'last_name_kana',
  'romaji_name',
  'romaji_name_kana',
]

export const makeInitialValuesFromTmpData = (tmpData) => {
  return tmpData || {}
}

export const makeInitialValues = (employee) => {
  if (!employee || !employee.id) {
    return {}
  }
  return {
    procedure_data: _.pick(employee, nameColumnNames),
    _is_foreigner: employee.national_type === 'foreigner',
  }
}

export const makeInitialValuesForUpdate = (procedureStatus, employee) => {
  if (!employee || !procedureStatus) {
    return {}
  }
  const {procedure_change_name_datum: changeNameData, date_on: dateOn} = procedureStatus
  return {
    date_on: dateOn,
    procedure_data: changeNameData,
    _is_foreigner: employee.national_type === 'foreigner',
    ..._.pick(employee, nameColumnNames),
  }
}

export const formatValues = (values) => {
  const {date_on: dateOn, procedure_data: procedureData, ...employee} = values
  return {
    dateOn,
    employee,
    procedureData,
  }
}

export const selector = formValueSelector(formName)

export default connect(
  (state) => ({
    editOldName: selector(state, '_edit_procedure_data'),
    oldName: selector(state, 'procedure_data'),
    isForeigner: selector(state, '_is_foreigner'),
  }),
  bindActionCreators.bind(null, {autofill})
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })(Form)
)
