import React, {Component} from 'react'
import {connect} from 'react-redux'
import SelectProcedure from 'components/SelectProcedure'
import _ from 'lodash'
import styles from 'ProcedureNew/ProcedureNew.scss'
import {
  Join,
  Leave,
  Dependent,
  Name,
  Adress,
  Maternity,
  // Birth
  // Adjustment,
  // Sick
} from 'jbc-front/components/icons'
import {isDevelopmentEnv, isAdminSelector} from 'utils'

export const procedureList = (isAdmin) =>
  (isDevelopmentEnv
    ? [
        ['入社の手続き', Join, 'enroll', '/enroll', '新しい従業員が入社するときの手続き'],
        ['退社の手続き', Leave, 'resign', '/resign', '従業員が退社するときの手続き'],
        [
          <>
            扶養の変更
            <wbr />
            （追加・削除）
          </>,
          Dependent,
          'change_dependents',
          isAdmin ? '/change_dependents' : '/mypage/change_dependents',
          '結婚や出産、子どもが就職するなどの手続き',
        ],
        ['産休の手続き', Maternity, 'maternity_leave', '/maternity_leave', '出産前後の休職の手続き'],
        // ['育休の手続き', Birth, 'childcare_leave', '/childcare_leave', '子供を養育するための休職の手続き'],
        ['住所の変更', Adress, 'change_address', '/change_address', '引越など住所が変わるときの手続き'],
        ['氏名の変更', Name, 'change_name', '/change_name', '結婚などで氏名が変わるときの手続き'],
      ]
    : [
        ['入社の手続き', Join, 'enroll', '/enroll', '新しい従業員が入社するときの手続き'],
        ['退社の手続き', Leave, 'resign', '/resign', '従業員が退社するときの手続き'],
        [
          <>
            扶養の変更
            <wbr />
            （追加・削除）
          </>,
          Dependent,
          'change_dependents',
          isAdmin ? '/change_dependents' : '/mypage/change_dependents',
          '結婚や出産、子どもが就職するなどの手続き',
        ],
        ['産休の手続き', Maternity, 'maternity_leave', '/maternity_leave', '出産前後の休職の手続き'],
        // ['育休の手続き', Birth, 'childcare_leave', '/childcare_leave', '子供を養育するための休職の手続き'],
        ['住所の変更', Adress, 'change_address', '/change_address', '引越など住所が変わるときの手続き'],
        ['氏名の変更', Name, 'change_name', '/change_name', '結婚などで氏名が変わるときの手続き'],
        // ['病気・ケガ', Sick, 'sick', '説明テキスト'],
        // ['年末調整', Adjustment, 'enroll', '説明テキスト']
      ]
  ).map(([name, icon, type, to, text]) => ({name, icon, type, to, text}))

class ProcedureNew extends Component {
  render() {
    const {currentUser, isAdmin} = this.props
    const procedures = procedureList(isAdmin).filter(
      ({type}) =>
        _.get(currentUser, `client_role.client_role_permissions.${type}.creatable`) &&
        (isAdmin || currentUser.employee.gender === 'woman' || type !== 'maternity_leave')
    )
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">手続きを開始する</h1>
        </div>
        <div className={styles.boxWrap}>
          {procedures.map((procedure) => {
            return (
              <SelectProcedure key={procedure.type} icon={procedure.icon} text={procedure.text} to={procedure.to}>
                {procedure.name}
              </SelectProcedure>
            )
          })}
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  currentUser: state.session.currentUser,
  isAdmin: isAdminSelector(state),
}))(ProcedureNew)
