import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field} from 'redux-form'
import styles from 'jbc-front/components/SearchForm.scss'
import {fetchSelector} from 'actions'
import {withNullOpt} from 'employees/list/addedFields/utils'
import {TagSelectBox} from 'jbc-front/components/SearchForm'

class Tags extends Component {
  static defaultProps = {
    tags: [],
  }

  render() {
    const {tags} = this.props
    return (
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>タグ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field name="tags" component={TagSelectBox} options={withNullOpt(tags)} />
          </div>
        </div>
      </div>
    )
  }
}
export default connect((state) => ({
  token: state.auth.token,
  tags: fetchSelector(state, 'selections').tags,
}))(Tags)
