import React, {useEffect} from 'react'
import {reduxForm} from 'redux-form'
import {onSubmitFail} from 'jbc-front/components/FormErrors'
import {Section, TextField, SelectField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import {Link} from 'react-router-dom'
import {maxLength} from 'validators'
import {FroalaPreview} from 'documentTemplates/froala/lazy'
import styles from 'documents/create/Form.scss'
import {useWizard} from 'components/Wizard'
import _ from 'lodash'
import {parseHtml} from 'documentTemplates/parseHtml'
import {documentSanitizeHtml} from 'documentTemplates/documentSanitizeHtml'

const formName = 'SelectTemplateStepForm'

const wrapperSectionStyle = {
  maxWidth: 'unset',
}

const SelectTemplateStep =
  (({initialValues, setInitialValues, templateOptions}) => {
    const {pushStep, draft, setDraft, discardStep, current} = useWizard()
    useEffect(() => {
      if (_.isEmpty(draft.title)) {
        setDraft({...draft, title: draft.templateObject?.title})
      }
    }, [draft.title])
    useEffect(() => {
      const style = draft.templateObject?.style
      if (style) {
        const element = document.createElement('style')
        element.textContent = style
        document.head.appendChild(element)
        return () => element.remove()
      }
    }, [draft.templateObject?.style])
    const handleChangeTemplate = (value) => {
      const templateObject = draft?.templateList?.find((c) => c.id === value)
      setDraft({
        ...draft,
        templateObject: templateObject,
        title: templateObject.title,
        customVariablesFile: null,
        documemtTemplateId: templateObject.id || null,
        checkEmployees: templateObject.hasCsvVariables || templateObject.hasEmployeeVariables,
      })
      setInitialValues({
        documentTemplateSelect: value,
      })
      discardStep(current)
    }
    return (
      <form>
        <div className={styles.variablesHelp}>
          <Link to="/document_templates">
            <Button widthAuto>テンプレート追加・編集</Button>
          </Link>
        </div>
        <Section title="書類作成" style={wrapperSectionStyle}>
          <SelectField
            name="documentTemplateSelect"
            label="利用するテンプレート"
            required
            searchable
            options={templateOptions}
            validate={maxLength(64)}
            onChange={handleChangeTemplate}
            placeholder="テンプレート選択"
          />
          <div className={styles.label}>カテゴリ</div>
          <div className={styles.text}>{draft.templateObject?.documentTemplateCategoryName || '―'}</div>
          <div className={styles.label}>説明</div>
          <div className={styles.text} dangerouslySetInnerHTML={parseHtml(draft.templateObject?.description || '―')} />
          <TextField
            name="title"
            label="書類名"
            validate={maxLength(128)}
            onBlur={(value) => {
              setDraft({...draft, title: value.target.defaultValue})
              if (value.target.defaultValue.length <= 128) {
                setInitialValues({...initialValues, title: value.target.defaultValue})
              }
            }}
          />
          {draft.templateObject?.body && <FroalaPreview body={documentSanitizeHtml(draft.templateObject?.body)} />}
        </Section>
        <div className="u-ta-c u-mt30">
          <Button
            className={styles.wizardNextButton}
            primary
            disabled={_.isEmpty(draft.templateObject?.title) || draft.title?.length > 128}
            onClick={() => {
              pushStep('SelectEmployees')
            }}
          >
            従業員を選択する
          </Button>
        </div>
      </form>
    )
  }) |> reduxForm({form: formName, enableReinitialize: true, onSubmitFail})

export default SelectTemplateStep
