import UsingPlan from 'settings/UsingPlan'
import Occupations from 'settings/Occupations'
import EmploymentTypes from 'settings/EmploymentTypes'
import Positions from 'settings/Positions'
import AllowedIpsEmployee from 'settings/AllowedIpsEmployee'
import AllowedIpsOther from 'settings/AllowedIpsOther'
import AllowedIpsTm from 'settings/AllowedIpsTm'
import Groups from 'settings/Groups'
import clientRoles from 'settings/clientRoles'
import auditLogs from 'settings/auditLogs'
import Notifications from 'settings/Notifications'
import laborConsultant from 'settings/laborConsultant'
import customMails from 'settings/customMails'
import employeeCustom from 'settings/employeeCustom'
import eGov from 'settings/eGov'
import ClientFiles from 'settings/ClientFiles'
import Coordination from 'settings/Coordination'
import TalentDisplay from 'settings/TalentDisplay'
import roles from 'settings/roles'

export default {
  UsingPlan,
  Occupations,
  EmploymentTypes,
  Positions,
  AllowedIpsEmployee,
  AllowedIpsOther,
  AllowedIpsTm,
  Groups,
  Notifications,
  auditLogs,
  clientRoles,
  laborConsultant,
  customMails,
  employeeCustom,
  eGov,
  ClientFiles,
  Coordination,
  TalentDisplay,
  roles,
}
