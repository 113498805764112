import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Document, Search, DELETE_DOCUMENT, EMPLOYEE_DOCUMENTS} from '../../../query'
import {Delete} from 'jbc-front/components/icons'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import {notifySuccess} from 'store/actions/notify'

import styles from './DeleteColumn.scss'

// @ts-ignore
import {useMutation} from 'components/Graphql'

export type DeleteColumnProps = {
  document: Document
  employeeId: number
  search: Search
}

export const DeleteColumn: FC<DeleteColumnProps> = ({document, employeeId, search}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const handleCloseModal = () => setIsModalOpen(false)
  const handleOpenModal = () => setIsModalOpen(true)

  const dispatch = useDispatch()
  const [deleteDocument, {loading}] = useMutation(DELETE_DOCUMENT)

  const handleDeleteClick = async () => {
    await deleteDocument({
      variables: {input: {id: document.id}},
      refetchQueries: [
        {
          query: EMPLOYEE_DOCUMENTS,
          variables: {id: Number(employeeId), search},
          fetchPolicy: 'cache-and-network',
        },
      ],
    })

    setIsModalOpen(false)
    dispatch(notifySuccess('削除しました'))
  }

  return (
    <>
      <Delete size={20} className={styles.icon} onClick={handleOpenModal} />

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Modal.Header onClose={handleCloseModal}>削除の確認</Modal.Header>
        <Modal.Body>
          削除された書類は他の従業員も閲覧できなくなり、復元することができません。
          <br />
          <span className={styles.attention}>本当に{document.title}を削除しますか</span>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={handleCloseModal}>いいえ</Button>
            <Button primary onClick={handleDeleteClick} disabled={loading}>
              はい
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </>
  )
}
