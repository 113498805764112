import React, {useState} from 'react'
import {SelectField, Section, RadioField, TextField} from 'jbc-front/components/Form'
import {number, maxLength} from 'validators'

import {reduxForm, formValueSelector} from 'redux-form'
import Button from 'jbc-front/components/Button'

import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'

import {toBooleanProps, toFormValues} from 'utils'
import {makeRowsData} from 'reportForms/r60Utils'
import _ from 'lodash'
import PaymentTable from 'reportForms/R60PaymentTable'
import styles from 'reportForms/R60Report.scss'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R60Report'

const retireTypes = [
  {value: 'bankruptcy', label: '1. 事業所の倒産等によるもの'},
  {value: 'age_limit', label: '2. 定年によるもの'},
  {value: 'expiration', label: '3. 労働契約期間満了等によるもの'},
  {value: 'by_company', label: '4. 事業主からの働きかけによるもの'},
  {value: 'by_employee', label: '5. 労働者の判断によるもの'},
  {value: 'other', label: '6. その他（1-5のいずれにも該当しない場合）'},
]

/* bankruptcy ここから */
const detailedRetireTypeOfBankruptcy = [
  {value: 'started_bankruptcy', label: '1. 倒産手続開始、手形取引停止による離職'},
  {value: 'closedown', label: '2. 事業所の廃止又は事業活動停止後事業再開の見込みがないため離職'},
]
/* bankruptcy ここまで */

/* age_limit ここから */
const wishedToContinueWorkings = [
  {value: 'true', label: 'を希望していた'},
  {value: 'false', label: 'を希望していなかった'},
]

const retireTypeOfAgeLimits = [
  {
    value: 'office_regulations',
    label:
      'a. 就業規則に定める解雇事由又は退職事由（年齢に係るものを除く。以下同じ。）に該当したため（解雇事由又は退職事由と同一の事由として就業規則又は労使協定に定める「継続雇用しないことができる事由」に該当して離職した場合も含む。）',
  },
  {
    value: 'not_match_seniors_regulation',
    label: 'b. 平成25年3月31日以前に労使協定により定めた継続雇用制度の対象となる高年齢者に係る基準に該当しなかったため',
  },
  {value: 'other', label: 'c. その他'},
]
/* age_limit ここまで */

/* expiration ここから */
const detailedRetireTypeOfExpirations = [
  {value: 'employment_term', label: '（1） 採用又は定年後の再雇用時等にあらかじめ定められた雇用期限到来による離職'},
  {value: 'contract_expiration', label: '（2） 労働契約期間満了による離職'},
  {value: 'early_retirement_incentive', label: '（3） 早期退職優遇制度、選択定年制度等により離職'},
  {value: 'transfer', label: '（4） 移籍出向'},
]

const workerTypes = [
  {value: 'other', label: '① 下記②以外の労働者'},
  {value: 'temporary', label: '② 労働者派遣事業に雇用される派遣労働者のうち常時雇用される労働者以外の者'},
]

const wishedContractRenewals = [
  {value: 'wished', label: 'を希望する旨の申出があった'},
  {value: 'answered_not_to_wish', label: 'を希望しない旨の申出があった'},
  {value: 'no_answer', label: 'の希望に関する申出はなかった'},
]

const retireTypeOfTempWorkers = [
  {value: 'employee_declined', label: 'a. 労働者が適用基準に該当する派遣就業の指示を拒否したことによる場合'},
  {
    value: 'company_did_not_order',
    label:
      'b. 事業主が適用基準に該当する派遣就業の指示を行わなかったことによる場合（指示した派遣就業が取りやめになったことによる場合を含む）',
  },
]
/* expiration ここまで */

/* by_company ここから */
const retireTypeByCompanys = [
  {value: 'normal_dismissal', label: '（1） 解雇（重責解雇を除く）'},
  {value: 'disgrace_dismissal', label: '（2） 重責解雇（労働者の責めに帰すべき重大な理由による解雇）'},
  {value: 'voluntary_resignation', label: '（3） 希望退職の募集又は退職勧奨'},
]

const voluntaryResignationTypes = [
  {value: 'cutback', label: '① 事業の縮小又は一部休廃止に伴う人員整理を行うためのもの'},
  {value: 'other', label: '② その他'},
]
/* by_company ここまで */

/* by_employee ここから */
const detailedRetireTypeOfOthers = [
  {value: 'working_environment', label: '（1） 職場における事情による離職'},
  {value: 'employee_personal_reason', label: '（2） 労働者の個人的な事情による離職（一身上の都合、転職希望等）'},
]

const retireTypeDetails = [
  {
    value: 'working_conditions',
    label: '① 労働条件に係る問題（賃金低下、賃金遅配、時間外労働、採用条件との相違等）があったと労働者が判断したため',
  },
  {
    value: 'obstracism',
    label:
      '② 事業主又は他の労働者から就業環境が著しく害されるような言動（故意の排斥、嫌がらせ等）を受けたと労働者が判断したため',
  },
  {
    value: 'maternity',
    label:
      '③ 妊娠、出産、育児休業、介護休業等に係る問題（休業等の申出拒否、妊娠、出産、休業等を理由とする不利益取扱い）があったと労働者が判断したため',
  },
  {value: 'large_scale_cutback', label: '④ 事業所での大規模な人員整理があったことを考慮した離職'},
  {value: 'unable_to_adapt', label: '⑤ 職種転換等に適応することが困難であったため'},
  {value: 'unable_to_commute', label: '⑥ 事業所移転により通勤困難となった（なる）ため'},
  {value: 'other', label: '⑦ その他'},
]
/* by_employee ここまで */

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({
    submitting,
    handleSubmit,
    submitText,
    retireType,
    wishedToContinueWorking,
    retireTypeOfAgeLimit,
    detailedRetireTypeOfExpiration,
    workerType,
    expireContractTermFiveYear,
    retireTypeOfTempWorker,
    detailedRetireTypeOfOther,
    retireTypeByCompany,
    voluntaryResignationType,
    employee,
    retireTypeDetail,
    needUpgrade,
  }) => {
    const [openModal, setOpenModal] = useState(false)
    const branchSubmit = (needUpgrade) => {
      if (needUpgrade) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">雇用保険被保険者離職証明書</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <Section className={styles.box}>
            <SelectField name="retire_type" label="離職理由" options={retireTypes} required />
            {retireType === 'bankruptcy' && (
              <div>
                <SelectField
                  name="detailed_retire_type_of_bankruptcy"
                  label="離職理由詳細 "
                  options={detailedRetireTypeOfBankruptcy}
                />
              </div>
            )}
            {retireType === 'age_limit' && (
              <div>
                <TextField name="limit_age" label="定年年齢" validate={[number, maxLength(2)]} />
                <SelectField
                  name="wished_to_continue_working"
                  label="定年後の継続雇用"
                  {...toBooleanProps}
                  options={wishedToContinueWorkings}
                />
                {wishedToContinueWorking && (
                  <div>
                    <SelectField name="retire_type_of_age_limit" label="離職理由 " options={retireTypeOfAgeLimits} />
                    {retireTypeOfAgeLimit && retireTypeOfAgeLimit === 'other' && (
                      <div>
                        <TextField name="retire_reason_of_age_limit" label="具体的理由" validate={maxLength(34)} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {retireType === 'expiration' && (
              <div>
                <SelectField
                  name="detailed_retire_type_of_expiration"
                  label="離職理由詳細 "
                  options={detailedRetireTypeOfExpirations}
                />
                {detailedRetireTypeOfExpiration === 'employment_term' && (
                  <div>
                    <TextField name="contract_period" label="1回の契約期間（ヶ月）" validate={[number, maxLength(3)]} />
                    <TextField
                      name="total_contract_months"
                      label="通算契約期間（ヶ月）"
                      validate={[number, maxLength(3)]}
                    />
                    <TextField
                      name="number_of_contract_renewal"
                      label="契約更新回数（回）"
                      validate={[number, maxLength(3)]}
                    />
                    <RadioField
                      name="expire_contract_term_compressed"
                      label="当初の契約締結後に契約期間や更新回数の上限を短縮し、その上限到来による離職に該当　する・しない"
                      {...toBooleanProps}
                      options={[
                        {value: 'true', label: 'する'},
                        {value: 'false', label: 'しない'},
                      ]}
                    />
                    <RadioField
                      name="expire_contract_term_forced"
                      label="当初の契約締結後に契約期間や更新回数の上限を設け、その上限到来による離職に該当　する・しない"
                      {...toBooleanProps}
                      options={[
                        {value: 'true', label: 'する'},
                        {value: 'false', label: 'しない'},
                      ]}
                    />
                    <RadioField
                      name="expire_contract_term_rehired"
                      label="定年後の再雇用時にあらかじめ定めれれた雇用期限到来による離職で　ある・ない"
                      {...toBooleanProps}
                      options={[
                        {value: 'true', label: 'ある'},
                        {value: 'false', label: 'ない'},
                      ]}
                    />
                    <RadioField
                      name="expire_contract_term_five_years"
                      label="４年６箇月以上５年以下の上限が定められ、この上限到来による離職で　ある・ない"
                      {...toBooleanProps}
                      options={[
                        {value: 'true', label: 'ある'},
                        {value: 'false', label: 'ない'},
                      ]}
                    />
                    {expireContractTermFiveYear && (
                      <div>
                        <RadioField
                          name="made_rules_five_years_ago"
                          label="同一事業所の有期雇用労働者に一様に4年6箇月以上5年以下の通算契約期間の上限が平成24年8月10日から定められて　いた・いなかった"
                          {...toBooleanProps}
                          options={[
                            {value: 'true', label: 'いた'},
                            {value: 'false', label: 'いなかった'},
                          ]}
                        />
                      </div>
                    )}
                  </div>
                )}
                {detailedRetireTypeOfExpiration === 'contract_expiration' && (
                  <div>
                    <SelectField name="worker_type" label="労働者" options={workerTypes} />
                    <TextField name="contract_period" label="1回の契約期間（ヶ月）" validate={[number, maxLength(3)]} />
                    <TextField
                      name="total_contract_months"
                      label="通算契約期間（ヶ月）"
                      validate={[number, maxLength(3)]}
                    />
                    <TextField
                      name="number_of_contract_renewal"
                      label="契約更新回数（回）"
                      validate={[number, maxLength(3)]}
                    />
                    <RadioField
                      name="promise_of_contract_renewal"
                      label="契約を更新又は延長することの確約・合意の有・無"
                      {...toBooleanProps}
                    />
                    <RadioField
                      name="no_contract_renewal"
                      label="契約を更新又は延長しない旨の明示の有・無"
                      {...toBooleanProps}
                    />
                    {workerType === 'other' && (
                      <div>
                        <RadioField
                          name="notice_of_contract_end"
                          label="直前の契約更新時に雇止め通知の有・無"
                          {...toBooleanProps}
                        />
                        <RadioField
                          name="add_rules_after_contract"
                          label="当初の契約締結後に不更新条項の追加が　ある・ない"
                          {...toBooleanProps}
                          options={[
                            {value: 'true', label: 'ある'},
                            {value: 'false', label: 'ない'},
                          ]}
                        />
                      </div>
                    )}
                    <SelectField
                      name="wished_contract_renewal"
                      label="労働者から契約の更新または延長 "
                      options={wishedContractRenewals}
                    />
                    {workerType === 'temporary' && (
                      <div>
                        <SelectField
                          name="retire_type_of_temp_worker"
                          label="離職理由詳細"
                          options={retireTypeOfTempWorkers}
                        />
                        {retireTypeOfTempWorker === 'employee_declined' && (
                          <div>
                            <SelectField
                              name="detailed_retire_type_of_other"
                              label="離職理由詳細1"
                              options={detailedRetireTypeOfOthers}
                            />
                            {detailedRetireTypeOfOther === 'working_environment' && (
                              <div>
                                <SelectField
                                  name="retire_type_detail"
                                  label="離職理由詳細 "
                                  options={retireTypeDetails}
                                />
                                {retireTypeDetail === 'unable_to_adapt' && (
                                  <div>
                                    <RadioField name="provided_training" label="教育訓練の有・無" {...toBooleanProps} />
                                  </div>
                                )}
                                {retireTypeDetail === 'unable_to_commute' && (
                                  <div>
                                    <TextField
                                      name="prev_or_next_working_place"
                                      label="旧（新）所在地"
                                      validate={maxLength(13)}
                                    />
                                  </div>
                                )}
                                {retireTypeDetail === 'other' && (
                                  <div>
                                    <TextField
                                      name="working_environment_detail"
                                      label="理由を具体的に"
                                      validate={maxLength(32)}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {retireType === 'by_company' && (
              <div>
                <SelectField name="retire_type_by_company" label="離職理由詳細 " options={retireTypeByCompanys} />
                {retireTypeByCompany === 'voluntary_resignation' && (
                  <div>
                    <SelectField
                      name="voluntary_resignation_type"
                      label="退職勧奨の理由 "
                      options={voluntaryResignationTypes}
                    />
                    {voluntaryResignationType === 'other' && (
                      <div>
                        <TextField
                          name="voluntary_resignation_reason"
                          label="理由を具体的に"
                          validate={maxLength(33)}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {retireType === 'by_employee' && (
              <div>
                <SelectField
                  name="detailed_retire_type_of_other"
                  label="離職理由詳細 "
                  options={detailedRetireTypeOfOthers}
                />
                {detailedRetireTypeOfOther === 'working_environment' && (
                  <div>
                    <SelectField name="retire_type_detail" label="離職理由詳細 " options={retireTypeDetails} />
                    {retireTypeDetail === 'unable_to_adapt' && (
                      <div>
                        <RadioField name="provided_training" label="教育訓練の有・無" {...toBooleanProps} />
                      </div>
                    )}
                    {retireTypeDetail === 'unable_to_commute' && (
                      <div>
                        <TextField name="prev_or_next_working_place" label="旧（新）所在地" validate={maxLength(13)} />
                      </div>
                    )}
                    {retireTypeDetail === 'other' && (
                      <div>
                        <TextField name="working_environment_detail" label="理由を具体的に" validate={maxLength(32)} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {retireType === 'other' && (
              <div>
                <TextField name="other_reason" label="理由を具体的に" validate={maxLength(37)} />
              </div>
            )}
            <TextField name="specific_reason" label="具体的事情記載欄" validate={maxLength(110)} required />
            <PaymentTable />
            <div className="u-ta-c u-mt30">
              <Button
                primary
                onClick={() => branchSubmit(needUpgrade)}
                disabled={submitting || employee.retired_at === null}
                disabledReason={employee.retired_at === null ? '退職日が設定されていないため保存できません' : null}
              >
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => handleSubmit()}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)

const makeInitialValues = ({data: reportValues, procedureStatus: {employee}}) => {
  if (!reportValues || !employee || !employee.office) {
    return {insured_person_type: 'normal'}
  }
  if (_.isEmpty(reportValues.r60_payment_param_sets)) {
    return {
      ...toFormValues(reportValues),
      insured_person_type: reportValues.insured_person_type || 'normal',
      r60_payment_param_sets: makeRowsData(employee, 13, reportValues.insured_person_type === 'short_time_employment'),
    }
  }
  return {
    ...toFormValues(reportValues),
    r60_payment_param_sets: reportValues.r60_payment_param_sets.map(toFormValues),
  }
}

const selector = formValueSelector(formName)
const connectedForm = connectForm('r60', Form, {
  mapState: (state) => ({
    retireType: selector(state, 'retire_type'),
    wishedToContinueWorking: selector(state, 'wished_to_continue_working'),
    retireTypeOfAgeLimit: selector(state, 'retire_type_of_age_limit'),
    detailedRetireTypeOfExpiration: selector(state, 'detailed_retire_type_of_expiration'),
    workerType: selector(state, 'worker_type'),
    retireTypeOfTempWorker: selector(state, 'retire_type_of_temp_worker'),
    detailedRetireTypeOfOther: selector(state, 'detailed_retire_type_of_other'),
    retireTypeByCompany: selector(state, 'retire_type_by_company'),
    voluntaryResignationType: selector(state, 'voluntary_resignation_type'),
    retireTypeDetail: selector(state, 'retire_type_detail'),
    expireContractTermFiveYear: selector(state, 'expire_contract_term_five_years'),
    procedureStatus: state.procedureStatuses.current.data,
    employee: state.procedureStatuses.current.data.employee || {},
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  makeInitialValues,
  createOnly: true,
})

export default connectedForm
