import React from 'react'
import Flow from 'procedures/Flow'
import _ from 'lodash'

export const makeFlow = function* (employee) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    yield 'R14'
  }
  if (healthInsuranceType === 'its' && healthInsuranceJoined) {
    yield 'R147'
  }
}

const ChangeAddressFlow = ({location, match}) => <Flow {...{makeFlow, location, match}} />

export default ChangeAddressFlow
