import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Dl, Dd, Dt} from 'components/SecondaryPanel'
import {Panel, Headding, Item} from 'components/PrimaryPanel'
import {Edit} from 'jbc-front/components/icons'
import {actionCreators} from 'actions'
import {Link} from 'react-router-dom'
import api from 'api'
import {recordDisplay, prefectures} from 'utils'
import {applicationTypes, agentTypes} from 'settings/laborConsultant/Form'

class Show extends Component {
  componentDidMount() {
    const {client, token, loadLaborConsultant} = this.props
    loadLaborConsultant(client.id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {laborConsultant} = this.props
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">社労士情報</h1>
          <p className="m-title-main-note">社会保険労務士の情報を設定できます</p>
        </div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <span className="l-breadcrumb-here">社労士情報</span>
          </div>
          <Panel>
            <Link to="/settings/labor_consultant/edit">
              <Headding icon={<Edit icon={16} />}>社労士情報</Headding>
            </Link>
            <Item>
              <Dl>
                <Dt>社労士名：</Dt>
                <Dd>{recordDisplay(laborConsultant.name)}</Dd>
                <Dt>社労士名（カナ）：</Dt>
                <Dd>{recordDisplay(laborConsultant.name_kana)}</Dd>
                <Dt>社労士コード：</Dt>
                <Dd>{recordDisplay(laborConsultant.labor_consultant_code)}</Dd>
                <Dt>提出元：</Dt>
                <Dd>{recordDisplay.option(laborConsultant.application_type, applicationTypes)}</Dd>
                <Dt>代理区分：</Dt>
                <Dd>{recordDisplay.option(laborConsultant.agent_type, agentTypes)}</Dd>
                <Dt>
                  社会保険労務士
                  <br />
                  都道府県会：
                </Dt>
                <Dd>
                  {recordDisplay(laborConsultant.prefecture_id && prefectures[laborConsultant.prefecture_id - 1])}
                </Dd>
                <Dt>社労士事務所名：</Dt>
                <Dd>{recordDisplay(laborConsultant.labor_consultant_office_name)}</Dd>
                <Dt>電話番号：</Dt>
                <Dd>{recordDisplay.numbers(laborConsultant, 'phone_number', 3)}</Dd>
              </Dl>
            </Item>
          </Panel>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    client: state.client.current,
    token: state.auth.token,
    laborConsultant: state.settings.laborConsultant.data,
  }),
  (dispatch) => ({
    loadLaborConsultant(clientId, token) {
      dispatch(
        actionCreators.settings.laborConsultant.fetchData(
          api.createWithAuth(token).clients.laborConsultant.get(clientId)
        )
      )
    },
    destroy() {
      dispatch(actionCreators.settings.laborConsultant.destroy())
    },
  })
)(Show)
