import _ from 'lodash'

const defaultSelectedOffices = ''
export const selectedOffices = {
  value: defaultSelectedOffices,
}

export const getMainOfficeContactInfo = (office) =>
  _.find(_.get(office, 'office_contact_infos'), {
    office_contact_info_type: 'main_office_social',
  }) || {}

export const getLaborContactInfo = (office) =>
  _.find(_.get(office, 'office_contact_infos'), {
    office_contact_info_type: 'labor',
  }) || {}

export const getOwnerContactInfo = (office) =>
  _.find(_.get(office, 'office_contact_infos'), {
    office_contact_info_type: 'owner',
  }) || {}
