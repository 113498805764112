import {FC} from 'react'
import {NoneOrRejectedButtons} from './NoneOrRejectedButtons'
import {ApplyingButton} from './ApplyingButton'
import {DetailInputRequest} from '../../query'

type FloatingButtonsProps = {
  detailInputRequest: DetailInputRequest
  disabledRemind: boolean
}

export const FloatingButtons: FC<FloatingButtonsProps> = ({detailInputRequest, disabledRemind}) => {
  switch (detailInputRequest.status) {
    case 'none':
    case 'rejected':
      return <NoneOrRejectedButtons detailInputRequestId={detailInputRequest.id} disabledRemind={disabledRemind} />
    case 'applying':
      return <ApplyingButton employeeId={detailInputRequest.employee.id} />
    default:
      return null
  }
}
