import {FC, useState} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import {Download} from 'jbc-front/components/icons'
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Radio, RadioContainer} from 'jbc-front/components/presenters/form/Radio'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Note} from 'jbc-front/components/presenters/ui/Note'

import {useDownloadDocumentsCsv, TargetType, EncodingType} from 'hooks/api/export_documents_csv/useDownloadDocumentsCsv'

type CsvDownloadModalProps = {
  documentId: string
  q?: string
}

type CsvDownloadSchema = {
  target: TargetType
  encoding: EncodingType
}

export const CsvDownloadModal: FC<CsvDownloadModalProps> = ({documentId, q}) => {
  const download = useDownloadDocumentsCsv()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const schema = yup.object({
    target: yup.mixed<TargetType>().required(),
    encoding: yup.mixed<EncodingType>().required(),
  })

  const {handleSubmit: handleReactHookFormSubmit, register} = useForm<CsvDownloadSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      encoding: 'UTF-8',
      target: 'all',
    },
  })

  const handleSubmit = async (data: CsvDownloadSchema) => {
    const params = {
      target: data.target,
      encoding: data.encoding,
      search: q,
    }

    await download(documentId, params)
    setIsOpen(false)
  }

  return (
    <>
      <Button onClick={handleOpen}>書類情報ダウンロード</Button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <form onSubmit={handleReactHookFormSubmit(handleSubmit)}>
          <Modal.Header onClose={handleClose}>書類情報のダウンロード</Modal.Header>
          <Modal.Body>
            <Label>ファイル形式選択</Label>
            <Spacer direction="y" size={20} />
            <RadioContainer>
              <Radio {...register('encoding')} value="UTF-8">
                UTF-8（推奨）
              </Radio>
              <Radio {...register('encoding')} value="Windows-31J">
                Shift-JIS
              </Radio>
            </RadioContainer>
            <Spacer direction="y" size={6} />
            <Note>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。</Note>

            <Spacer direction="y" size={60} />

            <Label>ダウンロード対象選択</Label>
            <Spacer direction="y" size={20} />
            <RadioContainer>
              <Radio {...register('target')} value="all" checked>
                全従業員
              </Radio>
              <Radio {...register('target')} value="search">
                検索絞込中の従業員
              </Radio>
            </RadioContainer>
            <Spacer direction="y" size={6} />
            <Note>
              全従業員：書類に登録された全従業員の書類情報をダウンロードします。
              <br />
              検索絞込中の従業員：検索絞込した従業員の書類情報をダウンロードします。
            </Note>
          </Modal.Body>
          <Modal.Footer>
            <ButtonRow>
              <Button>キャンセル</Button>
              <Button primary onClick={handleReactHookFormSubmit(handleSubmit)}>
                <Download />
                ダウンロード
              </Button>
            </ButtonRow>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
