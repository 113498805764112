import {FC} from 'react'
import useReactRouter from 'use-react-router'
import {parse} from 'query-string'
import {useCheckInvitationConfirmationToken} from 'hooks/api/InvitationConfirmation'
import {Presenter} from './Presenter'

export const Show: FC = () => {
  const {
    location: {search},
  } = useReactRouter<{search: string}>()
  const {token} = parse(search)
  const confirmationToken = (Array.isArray(token) ? token[0] : token) ?? undefined
  const {isLoading, isValid, errors} = useCheckInvitationConfirmationToken(confirmationToken)

  return <Presenter isLoading={isLoading} isValid={isValid} errors={errors} token={confirmationToken} />
}
