import React, {useState, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import classNames from 'classnames'
import Button from 'jbc-front/components/Button'
import {ArrowDownSquare} from 'jbc-front/components/icons'
import LoadingPage from 'components/LoadingPage'
import {Search} from 'documents/ListData'
import {parseHtml} from 'documentTemplates/parseHtml'
import {
  DocumentSharedTemplate,
  DocumentSharedTemplateCategory,
  DOCUMENT_SHARED_TEMPLATES,
  IMPORT_FROM_DOCUMENT_SHARED_TEMPLATE,
} from 'documentTemplates/sample/query'
import {useSearchQuery} from 'documentTemplates/sample/hooks'
import TemplatePreview from 'documentTemplates/sample/TemplatePreview'
import styles from 'documentTemplates/Sample.scss'
import uniq from 'lodash/uniq'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

// @ts-ignore
import {recordDisplay} from 'utils'
// @ts-ignore
import {useQuery, useMutation} from 'components/Graphql'

interface TemplateProps {
  template: DocumentSharedTemplate
  isSelected: boolean
  onClick: () => void
}

const Template: React.FC<TemplateProps> = ({template, isSelected, onClick}) => (
  <div className={styles.template}>
    <div
      className={classNames(styles.templateThumbnailWrap, {[styles.templateThumbnailWrapSelected]: isSelected})}
      onClick={onClick}
    >
      <div className={styles.templateThumbnail} style={{backgroundImage: `url("${template.thumbnail?.url}")`}} />
    </div>
    <div className={styles.templateSummaryWrap}>
      <div className={styles.templateSummary}>
        <p className={styles.templateTitle} title={template.title}>
          {template.title}
        </p>
        <p
          className={styles.templateDescription}
          title={template.description}
          dangerouslySetInnerHTML={parseHtml(recordDisplay(template.description))}
        />
      </div>
      <TemplatePreview template={template} />
    </div>
  </div>
)

const UnlabeledCheckbox: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = (props) => (
  <label>
    <input className="m-checkbox-input" type="checkbox" {...props} />
    <div className={classNames('m-checkbox-parts', styles.unlabeledCheckboxParts)} />
  </label>
)

interface CategoryProps {
  category: DocumentSharedTemplateCategory
  selectedTemplateIds: string[]
  addSelectedTemplateIds: (templateIds: string[]) => void
  removeSelectedTemplateIds: (templateIds: string[]) => void
}

const Category: React.FC<CategoryProps> = ({
  category,
  selectedTemplateIds,
  addSelectedTemplateIds,
  removeSelectedTemplateIds,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const selectedAll = category.publishedSharedTemplates.every((template) => selectedTemplateIds.includes(template.id))

  const handleChangeSelectAll = () => {
    if (selectedAll) {
      removeSelectedTemplateIds(category.publishedSharedTemplates.map((template) => template.id))
    } else {
      addSelectedTemplateIds(category.publishedSharedTemplates.map((template) => template.id))
    }
  }

  return (
    <>
      <div className={styles.categoryHeader} onClick={() => setIsOpen((v) => !v)}>
        <UnlabeledCheckbox checked={selectedAll} onChange={handleChangeSelectAll} />
        <h2 className={styles.categoryHeaderText}>{category.name}</h2>
        <ArrowDownSquare fill="#aaa" transform={isOpen ? 'rotate(0)' : 'rotate(-90)'} />
      </div>
      {isOpen &&
        category.publishedSharedTemplates.map((template) => {
          const isSelected = selectedTemplateIds.includes(template.id)
          const handleClick = () => {
            if (isSelected) {
              removeSelectedTemplateIds([template.id])
            } else {
              addSelectedTemplateIds([template.id])
            }
          }
          return <Template key={template.id} template={template} isSelected={isSelected} onClick={handleClick} />
        })}
    </>
  )
}

const filterTemplateCategories = (
  categories?: DocumentSharedTemplateCategory[],
  searchQuery?: string
): DocumentSharedTemplateCategory[] => {
  if (!categories || categories.length === 0) {
    return []
  }
  if (!searchQuery) {
    return categories
  }

  return categories
    .map((category) => ({
      ...category,
      publishedSharedTemplates: category.publishedSharedTemplates.filter((template) =>
        template.title.includes(searchQuery)
      ),
    }))
    .filter((category) => category.publishedSharedTemplates.length !== 0)
}

const Sample = () => {
  const dispatch = useDispatch()
  const {data, loading} = useQuery(DOCUMENT_SHARED_TEMPLATES, {fetchPolicy: 'cache-and-network'})
  const [importFromDocumentSharedTemplate] = useMutation(IMPORT_FROM_DOCUMENT_SHARED_TEMPLATE)
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([])
  const [submitting, setSubmitting] = useState(false)
  const {searchQuery, setSearchQuery} = useSearchQuery()

  const categories = useMemo(
    () => filterTemplateCategories(data?.documentSharedTemplateCategories, searchQuery),
    [data, searchQuery]
  )

  const addSelectedTemplateIds = (templateIds: string[]) =>
    setSelectedTemplateIds((ids) => uniq([...ids, ...templateIds]))
  const removeSelectedTemplateIds = (templateIds: string[]) =>
    setSelectedTemplateIds((ids) => ids.filter((v) => !templateIds.includes(v)))

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await importFromDocumentSharedTemplate({variables: {input: {ids: selectedTemplateIds}}})
      dispatch(push('/document_templates'))
      dispatch(notifySuccess('取得しました'))
    } catch (err) {
      dispatch(asyncError(err))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">サンプルテンプレート一覧</h1>
      </div>
      <div className={classNames('l-contents-wrap', styles.layout)}>
        <div className={styles.topArea}>
          <Search
            placeholder="テンプレート"
            defaultValue={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        {loading && categories.length === 0 ? (
          <LoadingPage />
        ) : categories.length ? (
          <div className={styles.gallery}>
            {categories.map((category: DocumentSharedTemplateCategory) => (
              <Category
                key={category.id}
                category={category}
                selectedTemplateIds={selectedTemplateIds}
                addSelectedTemplateIds={addSelectedTemplateIds}
                removeSelectedTemplateIds={removeSelectedTemplateIds}
              />
            ))}
          </div>
        ) : (
          <div className={styles.noData}>テンプレートがありません</div>
        )}
        <div className={styles.bottomButtonsArea}>
          <Button onClick={() => dispatch(push('/document_templates'))} className={styles.button}>
            キャンセル
          </Button>
          <Button
            primary
            disabled={selectedTemplateIds.length === 0 || submitting}
            disabledReason={selectedTemplateIds.length === 0 ? 'テンプレートを選択してください' : undefined}
            className={styles.button}
            onClick={handleSubmit}
          >
            取得する
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Sample
