import React, {FC, ReactElement} from 'react'
import useReactRouter from 'use-react-router'
import {parse} from 'query-string'
import moment from 'moment'
import fp from 'lodash/fp'
import {useSelector} from 'hooks/redux'
import {CsvDownloadModal} from 'pages/withAuth/adminPage/procedureStatuses/index/parts/CsvDownloadModal'
import {ProcedureStatus, Status} from 'types/api/procedureStatuses/procedureStatus'
import {ProcedureType} from 'types/api/procedureStatuses/procedureType'
import {ProcedureKind} from 'types/api/csvProcedureStatuses/procedureKind'
import {PROCEDURE_NAMES} from 'consts/egovProcedures'
import {StarFlagColumn} from './parts/StarFlagColumn'
import styles from './Presenter.scss'

// @ts-ignore
import {recordDisplay} from 'utils'
// @ts-ignore
import {Progress} from 'components/Progress'
// @ts-ignore
import SelectList from 'components/SelectList'
// @ts-ignore
import {Pager, PerPage, CsvListData, Search, OrderedTh} from 'procedureStatuses/list/components'
// @ts-ignore
import {procedureTypes, statuses, dateNameMap} from 'procedureStatuses/List'

type RowProps = {
  procedureStatus: ProcedureStatus
  td: ReactElement
}
type ListProps = {
  item: ProcedureStatus
  selected: boolean
  td: ReactElement
  toggle: () => void
}

type SelectListProps = {
  list: ListProps[]
  selected: {[key: string]: string}
  th: ReactElement
}

type ParseQuery = Partial<{
  procedure_kind: ProcedureKind
  status: Status
  procedure_type: ProcedureType
}>

export const Presenter: FC = () => {
  const {
    history,
    location: {search},
  } = useReactRouter()
  const loading = useSelector((state) => state.procedureStatuses.list.loading)
  const procedureStatuses = useSelector((state) => state.procedureStatuses.list.data)
  const count = useSelector((state) => state.procedureStatuses.list.count)
  const {procedure_kind, status, procedure_type}: ParseQuery = parse(search)
  const MAX_COUNT = 999

  if (!procedure_kind || !status || !procedure_type) {
    history.push('/procedure_statuses')
    return null
  }

  return (
    <SelectList
      list={procedureStatuses}
      maxCount={MAX_COUNT}
      overMaxCountMessage={`選択できる手続きは${MAX_COUNT}件までです`}
    >
      {({list, selected, th}: SelectListProps) => {
        const selectedCount = fp.reduce((sum, value) => (value ? sum + 1 : sum), 0, selected)

        return (
          <div>
            <CsvListData />
            <div className="l-contents-wrap">
              <div className="u-pb10">
                <h1 className="m-title-main">手続きを選択してください</h1>
                {procedure_kind && <p className="m-title-main-name">書類：{PROCEDURE_NAMES[procedure_kind]}</p>}
              </div>
              <div className="l-wrap-xxl">
                <div className={styles.chooseEmployee}>
                  <div>
                    {!loading && list.length === 0 ? (
                      <p className={styles.searchResultErrorMessage}>手続きが見つかりませんでした</p>
                    ) : (
                      <p className={styles.employeeCount}>選択した従業員：{selectedCount}名</p>
                    )}
                  </div>

                  <div className={styles.searchButton}>
                    <Search placeholder="スタッフコード、氏名、メール" />
                  </div>
                </div>
                <div className="l-overflow-scroll">
                  <table className="m-table-list m-table-fixed">
                    <thead>
                      <tr>
                        {th}
                        <th className={styles.flagColumn}>フラグ</th>
                        <th className="m-table-list-date">開始日</th>
                        <th className="m-table-list-staffcode">スタッフコード</th>
                        <th className={styles.nameColumn}>氏名</th>
                        <th className={styles.procedureColumn}>手続き</th>
                        <th className={styles.progressColumn}>進行状況</th>
                        <th className={styles.statusColumn}>ステータス</th>
                        <OrderedTh
                          addclass="m-table-list-date"
                          text={dateNameMap[procedure_type] || '関連日'}
                          column="date_on"
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {list.map(({item: procedureStatus, td}) => (
                        <Row procedureStatus={procedureStatus} key={procedureStatus.id} td={td} />
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="m-pager-area">
                  <Pager totalResult={count} pageLinks={7} />
                  <PerPage />
                </div>

                <div className="u-ta-c u-mt30">
                  {procedure_kind && (
                    <CsvDownloadModal
                      selected={selected}
                      procedureKind={procedure_kind}
                      procedureStatuses={procedureStatuses}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </SelectList>
  )
}

const Row: FC<RowProps> = ({procedureStatus, td}) => {
  const employee = procedureStatus.employee

  return (
    <tr className="table-hover">
      {td}
      <StarFlagColumn procedureStatus={procedureStatus} />
      <td>{recordDisplay(procedureStatus.created_at && moment(procedureStatus.created_at).format('YYYY/MM/DD'))}</td>
      <td>{recordDisplay(employee.staff_code)}</td>
      <td>{recordDisplay(employee.display_last_name && employee.display_full_name)}</td>
      <td>{recordDisplay(procedureTypes[procedureStatus.procedure_type])}</td>
      <td>
        <div className={styles.progressDiv}>
          <Progress progress={procedureStatus.progress} className={styles.progressBar} />
          <span className={styles.progressCount}>
            {recordDisplay(
              procedureStatus.total_todos_count &&
                `${procedureStatus.done_todos_count}/${procedureStatus.total_todos_count}`
            )}
          </span>
        </div>
      </td>
      <td>{statuses[procedureStatus.status]}</td>
      <td>{recordDisplay(procedureStatus.date_on && procedureStatus.date_on.replace(/-/g, '/'))}</td>
    </tr>
  )
}
