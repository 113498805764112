import {FC} from 'react'
import {EgovProcedure} from '../../query'
import {EGOV_PROCEDURE_STATUSES} from 'consts/egovProcedureStatuses'
import {WITH_AUTH_ADMIN_PATHS, generateDynamicPath} from 'consts/paths'
import useReactRouter from 'use-react-router'
import {DeleteTd} from '../DeleteTd'
import {displayFormat, dateFormat} from 'libs/formatter'
import styles from './EgovApplicationRow.scss'

interface EgovApplicationRowProps {
  egovProcedure: EgovProcedure
  employeeOrOfficeName: string
  onOpenDeleteModal: (egovProcedureId: string) => void
  isDeletable: boolean
}

export const EgovApplicationRow: FC<EgovApplicationRowProps> = ({
  egovProcedure,
  onOpenDeleteModal,
  isDeletable,
  employeeOrOfficeName,
}) => {
  const {history} = useReactRouter()
  const {egovApplication} = egovProcedure
  const hasOfficialDocuments = egovApplication?.officialDocuments && egovApplication.officialDocuments.length > 0
  const hasNotices = egovApplication?.notices && egovApplication?.notices.length > 0
  const path = generateDynamicPath(WITH_AUTH_ADMIN_PATHS.EGOV_PROCEDURES.SHOW, [
    {pattern: 'id', replacement: egovProcedure.id},
  ])

  const statusCol = () => {
    switch (egovApplication?.trackingLogStatus) {
      case 'INVALID_TOKEN':
        return (
          <td className={styles.errorText}>
            アカウント
            <br />
            連携失敗
          </td>
        )
      case 'ABORT':
        return <td className={styles.errorText}>トラッキング失敗</td>
      default:
        return <td>{EGOV_PROCEDURE_STATUSES[egovProcedure.status]}</td>
    }
  }

  return (
    <tr className="table-hover" onClick={() => history.push(path)}>
      {statusCol()}
      <td>{employeeOrOfficeName}</td>
      <td>{displayFormat(egovProcedure.procedureName)}</td>
      <td title={egovProcedure.formName} className={styles.overflowEllips}>
        {egovProcedure.formName}
      </td>
      <td>{hasOfficialDocuments || hasNotices ? '公文書または通知が届いています' : displayFormat()}</td>
      <td>{dateFormat(egovApplication?.appliedAt, 'LLL')}</td>
      <DeleteTd onClick={() => onOpenDeleteModal(egovProcedure.id)} disabled={!isDeletable} />
    </tr>
  )
}
