import axios, {AxiosInstance} from 'axios'
import {useMemo} from 'react'
import {useSelector} from '../redux'

const authAxios = (token: string, officeIds: string): AxiosInstance =>
  axios.create({
    headers: {
      Authentication: token,
      'X-Office-Ids': officeIds,
    },
  })

export const useAuthAxios = () => {
  const token = useSelector((state) => state.auth.token)
  const selectedOffices = useSelector((state) => state.session.selectedOffices)
  return useMemo(() => authAxios(token, selectedOffices), [token, selectedOffices])
}
