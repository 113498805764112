import {FC} from 'react'
import {Table} from 'components/ui/Table'
import {Row} from './Row'
import styles from './RequestList.scss'
import {DetailInputRequest, SortColumn} from '../../query'

type RequestListProps = {
  detailInputRequests: DetailInputRequest[]
  sortColumn: SortColumn
  sortOrder: 'asc' | 'desc'
  sort: (column: SortColumn) => void
}

export const RequestList: FC<RequestListProps> = ({detailInputRequests, sortColumn, sortOrder, sort}) => {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.SortableTh
            className={styles.updateRequestTh}
            order={sortOrder}
            active={sortColumn === 'status'}
            onClick={() => sort('status')}
          >
            ステータス
          </Table.SortableTh>

          <Table.SortableTh
            className={styles.updateRequestTh}
            order={sortOrder}
            active={sortColumn === 'staff_code'}
            onClick={() => sort('staff_code')}
          >
            スタッフコード
          </Table.SortableTh>

          <Table.SortableTh
            order={sortOrder}
            active={sortColumn === 'employee_name'}
            onClick={() => sort('employee_name')}
            className={styles.updateRequestTh}
          >
            氏名
          </Table.SortableTh>

          <Table.Th className={styles.updateRequestTh}>グループ</Table.Th>

          <Table.Th className={styles.updateRequestTh}>依頼者</Table.Th>

          <Table.SortableTh
            className={styles.updateRequestTh}
            order={sortOrder}
            active={sortColumn === 'created_at'}
            onClick={() => sort('created_at')}
          >
            依頼日時
          </Table.SortableTh>

          <Table.SortableTh
            className={styles.updateRequestTh}
            order={sortOrder}
            active={sortColumn === 'updated_at'}
            onClick={() => sort('updated_at')}
          >
            最終更新日時
          </Table.SortableTh>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {detailInputRequests.length > 0 ? (
          detailInputRequests.map((detailInputRequest) => (
            <Row key={`key-row-${detailInputRequest.id}`} detailInputRequest={detailInputRequest} />
          ))
        ) : (
          <Table.Tr>
            <Table.Td colSpan={7}>依頼がありません</Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  )
}
