import React from 'react'
import {connect} from 'react-redux'
import ListGroup from 'jbc-front/components/ListGroup'
import {Link} from 'react-router-dom'
import {recordDisplay} from 'utils'
import styles from 'notifications/List.scss'
import _ from 'lodash'
import PreviewPdf, {show, setPdf} from 'components/PreviewPdf'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import SearchForm from 'notifications/list/SearchForm'
import PaginatorWithResult from 'notifications/list/PaginatorWithResult'
import {getSavedDisplayNotificationLimit} from 'notifications/list/utils'

const DATE_WITH_YEAR_LENGTH = 9

const notificationTypes = [
  {id: 'all', name: 'すべて'},
  {id: 'MyNumberNotification', name: 'マイナンバーの提出'},
  {id: 'others', name: 'その他'},
]

export const showPreviewPdf = (dispatch) => async (content, token) => {
  try {
    const {
      procedure_status_id: procedureStatusId,
      report_name: num,
      employee_dependent_id: dependentId,
      title,
    } = content
    dispatch(
      show({
        num,
        title,
        dependentId,
        edit: null,
        url:
          `/api/procedure_statuses/${procedureStatusId}/pdf_to_be_uploaded?type=${num}` +
          (dependentId ? `&employee_dependent_id=${dependentId}` : ''),
      })
    )
    const pdf = await api
      .createWithAuth(token)
      .procedureStatuses.reports.getPdfToBeUpload(
        procedureStatusId,
        num,
        dependentId ? {employee_dependent_id: dependentId} : {}
      )
    dispatch(setPdf(pdf))
  } catch (err) {
    dispatch(asyncError(err))
  }
}

const Notification = ({notification, token, showPreviewPdf}) => {
  const dateOrTime = recordDisplay.dateOrTime(notification.notification_content.created_at)
  return (
    <ListGroup.Item
      className={styles.notificationlist}
      key={notification.id}
      {...(notification.notification_content.link
        ? {
            ...(notification.notification_content.link.startsWith('http')
              ? {href: notification.notification_content.link, as: 'a'}
              : {
                  to: notification.notification_content.link,
                  as: Link,
                }),
            target: notification.notification_content.target_blank ? '_blank' : undefined,
          }
        : notification.notification_content.type === 'FileUploadNotification'
        ? {
            as: 'a',
            onClick: () => {
              showPreviewPdf(notification.notification_content, token)
            },
          }
        : {})}
    >
      <span className={dateOrTime.length < DATE_WITH_YEAR_LENGTH ? styles.date : styles.dateWithYear}>
        {dateOrTime}
      </span>
      {notification.notification_content.title}
      {notification.notification_content.link ? <ListGroup.Icon /> : null}
    </ListGroup.Item>
  )
}

export const Notifications =
  (({notifications, token, showPreviewPdf, title, styles}) => {
    return (
      <ListGroup>
        <PreviewPdf />
        <ListGroup.Title>{title}</ListGroup.Title>
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              token={token}
              showPreviewPdf={showPreviewPdf}
            />
          ))
        ) : (
          <ListGroup.Item className={styles.empty}>{title}はまだありません</ListGroup.Item>
        )}
      </ListGroup>
    )
  })
  |> connect(
    (state) => ({
      token: state.auth.token,
    }),
    (dispatch) => ({showPreviewPdf: showPreviewPdf(dispatch)})
  )

const List = ({list, currentUser, location: {pathname}}) => {
  const title = ['full_admin', 'office_admin'].includes(_.get(currentUser, 'client_role.client_role_type'))
    ? '従業員の通知'
    : '管理者からの通知'

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">通知一覧</h1>
      </div>
      <div className="l-wrap-s">
        <SearchForm notificationTypes={notificationTypes} />
        {list.length > 0 && <Notifications notifications={list} title={title} styles={styles} />}
        <PaginatorWithResult limit={getSavedDisplayNotificationLimit(pathname, currentUser)} />
      </div>
    </div>
  )
}

export default connect((state) => ({
  currentUser: state.session.currentUser,
  list: state.notificationsData.list.data,
  location: state.router.location,
}))(List)
