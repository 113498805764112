import {gql} from '@apollo/client'
import {DOCUMENT_VISIVILITIES} from 'consts/document'

export type QueryVariables = {
  id: number
  search: Search
}

export type Order = 'asc' | 'desc'
export type SortColumn = 'visibility' | 'title' | 'created_at' | 'created_user'

export type Search = {
  sortOrder?: Order
  sortColumn?: SortColumn
}

export type QueryResult = {
  client: {
    id: number
    employeeDocuments: {
      employee: Employee
      documents: Document[]
    }
  }
}

export type Employee = {
  id: number
  displayLastName: string
  displayFirstName: string
}

export type Document = {
  id: number
  title: string
  visibility: keyof typeof DOCUMENT_VISIVILITIES
  type: 'dynamic_document' | 'uploaded_document'
  isSign: boolean
  createdAt: string
  documentEmployee: DocumentEmployee
  dynamicDocument: DynamicDocument
  uploadedDocument: UploadedDocument
  createdUser?: CreatedUser
}

export type DocumentEmployee = {
  id: number
  isPublished: boolean
  firstViewedAt: string
  contractInfo?: EmployeeContractInfo
}

export type EmployeeContractInfo = {
  status: 'signed' | 'unsigned' | 'canceled'
}

export type DynamicDocument = {
  id: number
  downloadable: boolean
}

export type UploadedDocument = {
  id: number
  mimeType: string | null
}

export type CreatedUser = {
  id: number
  email: string
  employee: Employee
}

export const EMPLOYEE_DOCUMENTS = gql`
  query employeeDocuments($id: ID!, $search: EmployeeDocumentSearchInput) {
    client {
      id
      employeeDocuments(id: $id, search: $search) {
        employee {
          id
          displayFirstName
          displayLastName
        }
        documents {
          id
          title
          visibility
          flag
          type
          isSign
          createdAt
          documentEmployee(employeeId: $id) {
            id
            isPublished
            firstViewedAt
            contractInfo {
              status
            }
          }
          dynamicDocument {
            id
            downloadable
          }
          uploadedDocument {
            id
            mimeType
          }
          createdUser {
            id
            email
            employee {
              id
              displayFirstName
              displayLastName
            }
          }
        }
      }
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      document {
        id
      }
    }
  }
`

export const CREATE_UPLOADED_DOCUMENT = gql`
  mutation createUploadedDocument($input: CreateUploadedDocumentInput!) {
    createUploadedDocument(input: $input) {
      document {
        id
      }
    }
  }
`

export const UPDATE_DOCUMENT_PUBLISHED = gql`
  mutation updateDocumentPublished($input: UpdateDocumentPublishedInput!) {
    updateDocumentPublished(input: $input) {
      document {
        id
      }
    }
  }
`

export const UPDATE_DOCUMENT_UNPUBLISHED = gql`
  mutation updateDocumentUnpublished($input: UpdateDocumentUnpublishedInput!) {
    updateDocumentUnpublished(input: $input) {
      document {
        id
      }
    }
  }
`
