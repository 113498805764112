import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'
import {ProcedureKind} from 'types/api/csvProcedureStatuses/procedureKind'

export type Params = {
  procedure_kind: ProcedureKind
  procedure_status_ids: string[]
  serial_number: string
  show_my_number: boolean
  submission_destination?: SubmissionDestination
}

export type SubmissionDestination = 'pension_office' | 'health_insurance_office'

export const useDownloadProcedureCsv = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()

  return async (params: Params) => {
    const path = '/api/export_procedure_csv/export_csv'
    const getter = () => authAxios.post(path, params, {responseType: 'arraybuffer'})

    download(getter)
  }
}
