import React from 'react'
import {reduxForm, autofill as autofillForm, formValueSelector} from 'redux-form'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {Section, TextField, FileField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import _ from 'lodash'
import {purposeOfUses} from 'myNumber/businessPartner/RequestForm'
import {PlusSquare} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

const formName = 'myNumberEmployeeForm'
const autofill = autofillForm.bind(null, formName)
const selector = formValueSelector(formName)

const renderUpload = ({purposeOfUse, autofill, hasSecondIdImage}) => (
  <React.Fragment>
    <Section title="マイナンバー">
      <TextField name="my_number" label="マイナンバー" required />
    </Section>
    {purposeOfUse.request_number_confirmation && (
      <Section title="番号確認書類">
        <FileField name="my_number_image" label="番号確認書類" required />
        個人番号カード、通知カード、個人番号が記載された住民票の写し、住民票記載事項証明書など
      </Section>
    )}
    {purposeOfUse.request_identity_confirmation && (
      <Section title="身元確認書類">
        <FileField name="id_image_0" label="身元確認書類" required />
        {!hasSecondIdImage && (
          <div className="u-ta-r u-mt20 u-mb20">
            <div onClick={() => autofill('_has_second_id_image', true)} className="u-txt-addinfo">
              <PlusSquare size={20} className="u-txt-addinfo-icon" />
              身元確認書類を追加
            </div>
          </div>
        )}
        {hasSecondIdImage && (
          <div>
            <FileField name="id_image_1" label="身元確認書類" />
          </div>
        )}
        各種運転免許証、パスポート、在留カード、外国人登録証明書など
      </Section>
    )}
    <Section title="マイナンバーの利用目的">
      <ul>
        {_.filter(purposeOfUses, (value, key) => purposeOfUse[key]).map((value, key) => (
          <li key={key}>{value}</li>
        ))}
      </ul>
    </Section>
  </React.Fragment>
)

const Upload = connect(
  (state) => ({
    hasSecondIdImage: selector(state, '_has_second_id_image'),
  }),
  bindActionCreators.bind(null, {autofill})
)(renderUpload)

const Form = ({handleSubmit, submitting, purposeOfUse}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">マイナンバー入力</h1>
      </div>
      <FormErrors />
      <Upload purposeOfUse={purposeOfUse} />
      <div className="u-ta-c u-mt30">
        <Button primary onClick={handleSubmit} disabled={submitting}>
          入力する
        </Button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(Form)
