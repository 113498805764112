import React, {Component} from 'react'
import {connect} from 'react-redux'
import OfficeForm from 'offices/OfficeForm'
import {push} from 'connected-react-router'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {toFormValues} from 'utils'
import _ from 'lodash'
import set from 'lodash/fp/set'
import {withApollo} from '@apollo/client/react/hoc'
import {HAS_BRANCH_OFFICE} from 'hooks/useHasBranchOffice'
import {REFETCH_QUERIES_ON_UPDATE_OFFICE} from 'features/offices/OfficeSelector'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const makeInitialValues = (office) => {
  office = set('office_name', office.name, office)
  const initialValues = toFormValues(office)
  const labor = toFormValues(
    _.find(_.get(office, 'office_contact_infos'), {
      office_contact_info_type: 'labor',
    })
  )
  const owner = toFormValues(
    _.find(_.get(office, 'office_contact_infos'), {
      office_contact_info_type: 'owner',
    })
  )

  initialValues.office_contact_infos = []
  initialValues.office_contact_infos.push(labor, owner)

  return initialValues
}

class Update extends Component {
  componentDidMount() {
    const {id} = this.props.match.params
    const {dispatch, token} = this.props
    dispatch(
      actionCreators.fetchData('office', api.createWithAuth(token).offices.get(id, {embed: 'office_contact_info'}))
    )
  }

  componentWillUnmount() {
    const {dispatch} = this.props
    dispatch(actionCreators.fetchDestroy('office'))
  }

  handleFormSubmit = async (values) => {
    const {dispatch, token, client} = this.props
    try {
      values = set('name', values.office_name, values)
      if (
        values.office_contact_infos[1].rep_position_name ||
        values.office_contact_infos[1].rep_last_name ||
        values.office_contact_infos[1].rep_first_name
      ) {
        values.office_contact_infos[0].rep_position_name = values.office_contact_infos[1].rep_position_name
        values.office_contact_infos[0].rep_last_name = values.office_contact_infos[1].rep_last_name
        values.office_contact_infos[0].rep_first_name = values.office_contact_infos[1].rep_first_name
      }
      await api.createWithAuth(token).offices.update(values.id, values)
      dispatch(push('/offices'))
      dispatch(notifySuccess('更新しました'))
      client.refetchQueries({include: [HAS_BRANCH_OFFICE, ...REFETCH_QUERIES_ON_UPDATE_OFFICE]})
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  render() {
    const {office} = this.props
    return office ? (
      <OfficeForm initialValues={makeInitialValues(office)} onSubmit={this.handleFormSubmit} mode="update" />
    ) : null
  }
}

export default connect((state) => ({
  office: fetchSelector(state, 'office').data,
  token: state.auth.token,
}))(withApollo(Update))
