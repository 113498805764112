import FieldGroups from 'settings/employeeCustom/FieldGroups'
import EmployeeFieldGroup from 'settings/employeeCustom/EmployeeFieldGroup'
import CustomEmployeeFieldGroup from 'settings/employeeCustom/CustomEmployeeFieldGroup'
import CustomEmployeeFieldGroupIcons from 'settings/employeeCustom/CustomEmployeeFieldGroupIcons'

export default {
  FieldGroups,
  FieldGroup: EmployeeFieldGroup,
  CustomFieldGroup: CustomEmployeeFieldGroup,
  CustomFieldGroupIcons: CustomEmployeeFieldGroupIcons,
}
