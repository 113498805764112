import {FC, useState, useEffect, useRef} from 'react'
import {DOCUMENTS, QueryResult, QueryVariables, Order, SortColumn} from './query'
import {Subject} from 'rxjs'
import {debounceTime} from 'rxjs/operators'
import {useLazyQuery} from 'hooks/graphql/'
import {MainTitle} from 'components/layout/MainTitle'
import {Content} from 'components/layout/Content'
import {PerPage} from 'components/ui/PerPage'
import {LoadingPage} from 'components/ui/LoadingPage'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Tag from 'jbc-front/components/Tag'
import Paginator from 'jbc-front/components/Paginator'
import styles from './Presenter.scss'

// import parts
import {KeywordSearchBox} from './parts/KeyworkSearchBox'
import {DocumentList} from './parts/DocumentList'

type FilterStatusKey = 'all' | 'star' | 'all_admins' | 'private'
type FilterStatuses = {key: FilterStatusKey; label: string}[]
const statuses: FilterStatuses = [
  {key: 'all', label: 'すべて'},
  {key: 'star', label: 'スター付き'},
  {key: 'all_admins', label: '管理者全体'},
  {key: 'private', label: '自分のみ'},
]

export const Presenter: FC = () => {
  const [activeFilter, setActiveFilter] = useState<FilterStatusKey>('all')
  const [sortOrder, setSortOrder] = useState<Order>('desc')
  const [sortColumn, setSortColumn] = useState<SortColumn>('created_at')
  const [variables, setVariables] = useState<QueryVariables>({
    per: 100,
    page: 1,
    search: {
      sortOrder,
      sortColumn,
    },
  })
  const [fetchDocuments, {called, loading, data}] = useLazyQuery<QueryResult, QueryVariables>(DOCUMENTS)

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      const nextOrder = inverseOrder()
      setVariables((prev) => ({
        ...prev,
        search: {
          ...prev.search,
          sortOrder: nextOrder,
        },
      }))
      setSortOrder(nextOrder)
    } else {
      setVariables((prev) => ({
        ...prev,
        search: {
          ...prev.search,
          sortOrder: 'desc',
          sortColumn: column,
        },
      }))
      setSortOrder('desc')
      setSortColumn(column)
    }
  }

  const inverseOrder = () => {
    switch (sortOrder) {
      case 'desc':
        return 'asc'
      case 'asc':
        return 'desc'
    }
  }

  const handleClickFilter = (key: FilterStatusKey) => {
    setActiveFilter(key)
    setVariables((prev) => ({...prev, search: {...prev.search, filter: key}}))
  }

  const handleInputKeyword = (value: string) => {
    setVariables((prev) => ({...prev, search: {...prev.search, q: value}}))
    return subscription.unsubscribe()
  }

  const keywordSubject = useRef(new Subject<string>())
  const subscription = keywordSubject.current.pipe(debounceTime(1000)).subscribe(handleInputKeyword)

  useEffect(() => {
    fetchDocuments({variables})
  }, [variables])

  useEffect(() => {
    return subscription.unsubscribe()
  }, [])

  return (
    <>
      <MainTitle title="書類一覧" />
      <Content size="xxl">
        <div className={styles.searchContainer}>
          <div className={styles.filterContainer}>
            {statuses.map((status) => (
              <Tag key={status.key} onClick={(_) => handleClickFilter(status.key)} active={activeFilter === status.key}>
                {status.label}
              </Tag>
            ))}
          </div>

          <KeywordSearchBox onChange={(value) => keywordSubject.current.next(value)} placeholder="書類名" />
        </div>

        <Spacer direction="y" size={20} />

        {called && loading ? (
          <LoadingPage />
        ) : (
          <>
            <DocumentList
              documents={data?.client?.documents?.list || []}
              onSort={handleSort}
              order={sortOrder}
              sortColumn={sortColumn}
            />

            <div className="m-pager-area">
              <Paginator
                current={variables.page}
                rowsPerPage={variables.per}
                onClick={(page) => setVariables((prev) => ({...prev, page}))}
                totalResult={data?.client?.documents?.totalCount}
              />
              <PerPage selected={variables.per} onChange={(per) => setVariables((prev) => ({...prev, per, page: 1}))} />
            </div>
          </>
        )}
      </Content>
    </>
  )
}
