import React from 'react'
import {Link} from 'react-router-dom'
import ListGroup from 'jbc-front/components/ListGroup'
import _ from 'lodash'

export const mailTypes = {
  request_to_input_enroll: '入社の手続き入力依頼メール',
  request_to_input_resign: '退職の手続き入力依頼メール',
  request_to_input_my_number: '取引先へのマイナンバー提出依頼メール',
}

const List = () => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">メールカスタマイズ</h1>
      <p className="m-title-main-note">メールの内容をカスタマイズできます</p>
    </div>
    <div className="l-wrap-xs l-contents-wrap">
      <div className="l-breadcrumb u-mb20">
        <Link to="/settings" className="l-breadcrumb-link">
          設定
        </Link>
        <span className="l-breadcrumb-here">メールカスタマイズ</span>
      </div>
      <ListGroup>
        <ListGroup.Title>メールカスタマイズ</ListGroup.Title>
        {_.map(mailTypes, (name, type) => (
          <ListGroup.Item as={Link} to={`/settings/custom_mails/${type}`} key={type}>
            {name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  </div>
)

export default List
