import {React, useState} from 'react'
import {SelectField, Section, TextField, Label, CheckboxField} from 'jbc-front/components/Form'
import {formValueSelector, reduxForm} from 'redux-form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {number, required, maxLengthNumber, maxLength} from 'validators'
import EmployeeName from 'procedures/EmployeeName'
import {LostType, Reached70Yo} from 'reportForms/R7Report'
import styles from 'reportForms/common.scss'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R8Report'
const selector = formValueSelector(formName)

const Notes = ({isTransferOrRehire, isOtherNotes}) => (
  <div className={styles.notes}>
    <Label text="備考" />
    <CheckboxField
      name="is_transfer_or_rehire"
      label="転籍・継続再雇用"
      note={
        <p>
          以下のいずれかに該当する時は、資格取得後の事業所記号と被保険者番号を入力してください。
          <br />
          ・関連会社間での転籍 <br />
          ・継続再雇用（60歳以上の者で1日の空白もなく引き続き再雇用されたとき）
        </p>
      }
    />
    {isTransferOrRehire && (
      <div>
        <TextField
          name="transfer_or_rehire_reference_code"
          label="資格取得後の事業所記号"
          validate={[required, maxLengthNumber(8)]}
        />
        <TextField
          name="transfer_or_rehire_insurance_number"
          label="資格取得後の被保険者番号"
          validate={[required, maxLength(7)]}
        />
      </div>
    )}
    <CheckboxField name="is_other_notes" label="その他" />
    {isOtherNotes && (
      <div className={styles.notesOther}>
        <TextField name="notes_other" label="備考" />
      </div>
    )}
  </div>
)

const collectionTypes = [
  {value: 'attatched', label: '1. 添付'},
  {value: 'unable_to_return', label: '2. 返不能'},
  {value: 'lost', label: '3. 滅失'},
]

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({
    submitting,
    handleSubmit,
    submitText,
    employee,
    lostType,
    isReached70Yo,
    isTransferOrRehire,
    isOtherNotes,
    needUpgrade,
  }) => {
    const [openModal, setOpenModal] = useState(false)
    const branchSubmit = (needUpgrade) => {
      if (needUpgrade) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">健康保険厚生年金保険被保険者資格喪失届</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <Section>
            <div className="u-mb30">
              <LostType lostType={lostType} />
              <Reached70Yo isReached70Yo={isReached70Yo} />
            </div>
            <div className="u-mb40">
              <Notes isTransferOrRehire={isTransferOrRehire} isOtherNotes={isOtherNotes} />
            </div>
            <div className="u-mb40">
              <SelectField
                name="collection_type"
                label="被保険者証回収区分"
                options={collectionTypes}
                note="従業員から健康保険証を回収できなかった場合は2または3を選択します。3を選んだ場合、協会けんぽから退職された方のご自宅へ保険証返却催促の文書が届きます。"
              />
              <TextField name="attached_count" label="添付枚数" validate={number} />
              <TextField name="unable_to_return_count" label="返不能枚数" validate={number} />
              <TextField name="lost_count" label="滅失枚数" validate={number} />
            </div>
            <SubmitedOn />
            <div className="u-mt40 u-mb40">
              <p>
                出力・ダウンロード前に「マイナンバー出力」ボタンを押すか、又は印刷後手でマイナンバー（個人番号）を記入してください。
                <br />
                この帳票にはマイナンバーの出力・記入が必要です。
              </p>
            </div>
            <div className="u-ta-c u-mt30">
              <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => {
            setOpenModal(false)
            handleSubmit()
          }}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)

const connectedForm = connectForm('r8', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    lostType: selector(state, 'lost_type'),
    isReached70Yo: selector(state, 'is_reached_70_yo'),
    isTransferOrRehire: selector(state, 'is_transfer_or_rehire'),
    isOtherNotes: selector(state, 'is_other_notes'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
})

export default connectedForm
