import React from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {reduxForm} from 'redux-form'
import {FileField, TextAreaField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import {Upload as UploadIcon} from 'jbc-front/components/icons'
import {asyncError} from 'store/actions/asyncError'
import {getState} from 'utils'
import api from 'api'
import styles from 'employeeFiles/Top.scss'

const formName = 'upload'

const Form = reduxForm({
  form: formName,
  onSubmit: async (values, dispatch, {hideModal, loadData}) => {
    try {
      const state = await getState(dispatch)
      await api.createWithAuth(state.auth.token).uploadedFiles.create(values)
      hideModal()
      loadData()
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
})(({handleSubmit, submitting, hideModal}) => (
  <div className={styles.modalWrap}>
    <Modal.Header hideModal={hideModal}>アップロードファイル選択</Modal.Header>
    <Modal.Body className={styles.modalBody}>
      <form onSubmit={handleSubmit}>
        <FileField
          name="file"
          label="ファイルアップロード"
          required
          note={
            <p>
              アップロードしたファイルは全従業員がダウンロードできます。
              <br />
              ファイルの最大容量は10MBまでになります。
            </p>
          }
        />
        <TextAreaField name="message_by_uploader" label="メッセージ" />
      </form>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <Button primary onClick={handleSubmit} disabled={submitting}>
          アップロード
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </div>
))

const Upload = ({isModalOpen, showModal, hideModal, loadData}) => (
  <React.Fragment>
    <ActionButton primary onClick={showModal} icon={<UploadIcon size={16} />} className={styles.uploadLink}>
      ファイルアップロード
    </ActionButton>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      {isModalOpen && <Form hideModal={hideModal} loadData={loadData} />}
    </Modal>
  </React.Fragment>
)

export default withModal(Upload)
