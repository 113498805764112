import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import moment from 'moment'
import classNames from 'classnames'
import {isNil} from 'lodash/fp'
import ListGroup from 'jbc-front/components/ListGroup'
import {actionCreators} from 'actions'
import api from 'api'
import {recordDisplay} from 'utils'
import Loading from 'components/Loading'
import {Tabs} from './Tabs'
import styles from './Inbox.scss'

const FETCH_LIMIT = 10

export const ListItem = ({notification}) => (
  <ListGroup.Item
    className={classNames(styles.listItem, {[styles.unanswered]: isNil(notification.answer?.selected_option_id)})}
    key={notification.id}
    as={Link}
    to={`/tm/notifications/${notification.id}/answer`}
  >
    <span className={styles.datetime}>
      {notification.reserved_option == 'immediate' && moment(notification.created_at).format('LLL')}
      {notification.reserved_option == 'reserved' && moment(notification.reserved_at).format('LLL')}
    </span>
    <span className={styles.subject}>{notification.subject}</span>
    <span className={styles.sender}>( {recordDisplay.fullName(notification.employee)} )</span>
    <ListGroup.Icon />
  </ListGroup.Item>
)

export const EmptyListItem = () => (
  <ListGroup.Item>
    <div className={styles.noNotificationRow}>通知はまだありません</div>
  </ListGroup.Item>
)

const Inbox = () => {
  const dispatch = useDispatch()
  const [token, loading, notifications, count, status, role] = useSelector((state) => [
    state.auth.token,
    state.tm.notifications.list.loading,
    state.tm.notifications.list.data,
    state.tm.notifications.list.count,
    state.tm.notifications.status.data,
    state.tm.roles.current.data,
  ])
  const canSend = role?.notification_permission == 'can_send'

  useEffect(() => {
    dispatch(actionCreators.tm.roles.current.fetchData(api.createWithAuth(token).tm.roles.get()))
    dispatch(
      actionCreators.tm.notifications.list.fetchData(
        api.createWithAuth(token).tm.notifications.inbox({per_page: FETCH_LIMIT})
      )
    )
    return () => {
      dispatch(actionCreators.tm.roles.current.destroy())
      dispatch(actionCreators.tm.notifications.list.destroy())
    }
  }, [])

  // ステータスチェックか一覧取得のどちらか応答の早い方で未回答を検出した時点で、メッセージを出す
  const hasUnanswered =
    (status?.unanswered_count && status.unanswered_count > 0) ||
    notifications?.some((n) => isNil(n.answer?.selected_option_id))

  return (
    <div>
      <div className="l-main-title-wrap u-pb0">
        <h1 className="m-title-main u-pc-pb40 u-sp-pb60">従業員通知一覧</h1>
        <Tabs current="/tm/notifications/inbox" canSend={canSend} />
      </div>
      <div className={'l-wrap-xl l-contents-wrap ' + styles.contentsWrapSp}>
        <ListGroup>
          <ListGroup.Title>
            <span>受信した通知</span>
            {hasUnanswered && <span className={styles.unansweredHeader}>未回答あり</span>}
          </ListGroup.Title>
          {loading ? (
            <ListGroup.Item>
              <Loading />
            </ListGroup.Item>
          ) : notifications.length > 0 ? (
            notifications.map((notification) => <ListItem key={notification.id} notification={notification} />)
          ) : (
            <EmptyListItem />
          )}
        </ListGroup>
        {count > FETCH_LIMIT && (
          <div className={styles.more}>
            <Link className={styles.moreLink} to="/tm/notifications/inbox/all">
              すべて見る
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Inbox
