import {FC, useState} from 'react'
import {dateFormat} from 'libs/formatter'
import {Memo} from 'components/ui/Memo'
import {Document} from '../../query'
import {useSelector} from 'hooks/redux'
import {useOutsideClick} from 'hooks/useOutSideClick'
import {MemoCreateForm} from './MemoCreateForm'
import {MemoUpdateForm} from './MemoUpdateForm'
import {MemoDeleteModal} from './MemoDeleteModal'

interface MemoColumnProps {
  document: Document
}

export const MemoColumn: FC<MemoColumnProps> = ({document}) => {
  const DELETED_USER = '削除済みユーザー'
  const [isMemoOpen, setIsMemoOpen] = useState<boolean>(false)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteTargetMemoId, setDeleteTargetMemoId] = useState<number>()

  const [memoMethodType, setMemoMethodType] = useState<'create' | 'update'>('create')
  const [selectedMemo, setSelectedMemo] = useState<{id: number; body: string}>()

  const currentUserId = useSelector((state) => state.session.currentUser.id)
  const memos = document.comments.map((comment) => {
    return {
      id: comment.id,
      author: comment.commenter.email || DELETED_USER,
      createdAt: dateFormat(comment.createdAt, 'LL'),
      editable: String(comment.commenter.id) == currentUserId,
      body: comment.body,
    }
  })

  const memoRef = useOutsideClick<HTMLDivElement>(() => {
    reset()
    setIsMemoOpen(false)
  })

  const reset = () => {
    setMemoMethodType('create')
    setSelectedMemo(undefined)
  }

  const handleClickDelete = (id: number) => {
    setDeleteTargetMemoId(id)
    setIsDeleteModalOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setDeleteTargetMemoId(undefined)
    setIsDeleteModalOpen(false)
  }

  const handleClickUpdate = (id: number, body: string) => {
    setMemoMethodType('update')
    setSelectedMemo({id, body})
  }

  return (
    <Memo ref={memoRef} onOpen={() => setIsMemoOpen(true)}>
      <Memo.Icon badge={memos.length > 0} fill={memos.length > 0} />
      {isMemoOpen && (
        <Memo.Modal>
          <Memo.Modal.Header onClose={() => setIsMemoOpen(false)}>メモ</Memo.Modal.Header>
          <Memo.Modal.Body>
            {memos.map((memo) => (
              <Memo.Item
                key={`memo-${memo.id}`}
                memo={memo}
                onClickDelete={handleClickDelete}
                onClickUpdate={handleClickUpdate}
              />
            ))}
          </Memo.Modal.Body>
          <Memo.Modal.Footer>
            {memoMethodType === 'create' && <MemoCreateForm documentId={document.id} reset={reset} />}
            {memoMethodType === 'update' && selectedMemo && (
              <MemoUpdateForm id={selectedMemo.id} body={selectedMemo.body} reset={reset} />
            )}
          </Memo.Modal.Footer>
        </Memo.Modal>
      )}
      {deleteTargetMemoId && (
        <MemoDeleteModal memoId={deleteTargetMemoId} isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal} />
      )}
    </Memo>
  )
}
