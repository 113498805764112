import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import ListGroup from 'jbc-front/components/ListGroup'
import Loading from 'components/Loading'
import {actionCreators} from 'actions'
import api from 'api'
import {recordDisplay} from 'utils'
import {Tabs} from './Tabs'
import styles from './Inbox.scss'

const FETCH_LIMIT = 10

export const ListItem = ({notification}) => (
  <ListGroup.Item
    className={styles.listItem}
    key={notification.id}
    as={Link}
    to={`/tm/notifications/${notification.id}`}
  >
    <span className={styles.datetime}>
      {notification.reserved_option == 'immediate' && moment(notification.created_at).format('LLL')}
      {notification.reserved_option == 'reserved' && moment(notification.reserved_at).format('LLL')}
    </span>
    <span className={styles.subject}>{notification.subject}</span>
    <span className={styles.sender}>( {recordDisplay.fullName(notification.employee)} )</span>
    <ListGroup.Icon />
  </ListGroup.Item>
)

export const EmptyListItem = () => (
  <ListGroup.Item>
    <div className={styles.noNotificationRow}>通知はまだありません</div>
  </ListGroup.Item>
)

const Sent = () => {
  const dispatch = useDispatch()
  const [token, loading, notifications, count, role] = useSelector((state) => [
    state.auth.token,
    state.tm.notifications.list.loading,
    state.tm.notifications.list.data,
    state.tm.notifications.list.count,
    state.tm.roles.current.data,
  ])
  const canSend = role?.notification_permission == 'can_send'

  useEffect(() => {
    dispatch(actionCreators.tm.roles.current.fetchData(api.createWithAuth(token).tm.roles.get()))
    dispatch(
      actionCreators.tm.notifications.list.fetchData(
        api.createWithAuth(token).tm.notifications.sent({per_page: FETCH_LIMIT})
      )
    )
    return () => {
      dispatch(actionCreators.tm.roles.current.destroy())
      dispatch(actionCreators.tm.notifications.list.destroy())
    }
  }, [])

  return (
    <div>
      <div className="l-main-title-wrap u-pb0">
        <h1 className="m-title-main u-pc-pb40 u-sp-pb60">従業員通知一覧</h1>
        <Tabs current="/tm/notifications/sent" canSend={canSend} />
      </div>
      <div className={'l-wrap-xl l-contents-wrap ' + styles.contentsWrapSp}>
        <ListGroup>
          <ListGroup.Title>送信した通知</ListGroup.Title>
          {loading ? (
            <ListGroup.Item>
              <Loading />
            </ListGroup.Item>
          ) : notifications.length > 0 ? (
            notifications.map((notification) => <ListItem key={notification.id} notification={notification} />)
          ) : (
            <EmptyListItem />
          )}
        </ListGroup>
        {count > FETCH_LIMIT && (
          <div className={styles.more}>
            <Link className={styles.moreLink} to="/tm/notifications/sent/all">
              すべて見る
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default Sent
