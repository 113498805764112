import {actionTypes} from 'actions'
import {combineReducers} from 'redux'
import {getQueryDiffFactory} from 'utils'
import queryReducer from 'reducers/queryReducer'

const defaultQuery = {
  page: 1,
  status: 'all',
  searchKeyword: null,
}

export default combineReducers({
  query: queryReducer(actionTypes.E_GOV_PROCEDURES.SET_QUERY, defaultQuery),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
