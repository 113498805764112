import React, {Component} from 'react'
import Form, {formatValues} from 'procedures/resign/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import _ from 'lodash'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const initialValues = {retire_type: 'others'}

class Detail extends Component {
  componentDidMount() {
    const {
      loadProcedureStatus,
      loadEmployee,
      token,
      match,
      location: {search},
    } = this.props
    const procedureId = _.get(match, 'params.id')
    const {employee_id: employeeId} = parse(search)
    if (procedureId) {
      loadProcedureStatus(token, procedureId)
    } else {
      loadEmployee(token, employeeId)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const employee = this.props.employee || {}
    return <Form onSubmit={this.handleSubmit} initialValues={initialValues} employee={employee} />
  }

  handleSubmit = (values) => {
    const {
      updateAndCreateProcedure,
      location: {search},
      token,
      procedureStatus,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    return updateAndCreateProcedure(employeeId, procedureStatus, values, token)
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    employee: state.employees.current.data,
    procedureStatus: state.procedureStatuses.current.data,
  }),
  (dispatch) => ({
    loadProcedureStatus: (token, id) => {
      dispatch(actionCreators.procedureStatuses.current.fetchData(api.createWithAuth(token).procedureStatuses.get(id)))
    },
    loadEmployee: (token, id) => {
      dispatch(actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.get(id, ['user'])))
    },
    destroy: () => {
      dispatch(actionCreators.procedureStatuses.current.destroy())
      dispatch(actionCreators.employees.current.destroy())
    },
    updateAndCreateProcedure: async (employeeId, procedureStatus, values, token) => {
      try {
        const {employee, dateOn} = formatValues(values)
        const authedApi = api.createWithAuth(token)
        let procedureStatusId = procedureStatus.id
        if (!procedureStatusId && !employeeId) {
          dispatch(notifyError('従業員が選択されていません'))
          return
        }
        await authedApi.employees.update(procedureStatus.employee_id || employeeId, employee, [])
        if (!procedureStatusId) {
          const {
            data: {id},
          } = await authedApi.procedureStatuses.start(employeeId, 'resign', dateOn)
          procedureStatusId = id
        }
        await api.createWithAuth(token).procedureStatuses.tmpData.createOrUpdate(
          procedureStatusId,
          {
            detailFinished: true,
          },
          'draft',
          'flow_status'
        )
        dispatch(push(`/resign/flow/${procedureStatusId}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Detail)
