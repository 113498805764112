import React, {Component} from 'react'
import Form, {autofill, isSubmitting, makeInitialValues} from 'procedures/maternityLeave/extend/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import Button from 'jbc-front/components/Button'
import checkEmployeeConfirm from 'components/checkEmployeeConfirm'
import compose from 'lodash/fp/compose'
import {asyncError} from 'store/actions/asyncError'

class Confirm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      loadTmpData,
      token,
      match: {
        params: {id},
      },
      loadComments,
      loadProcedureStatus,
    } = this.props
    loadTmpData(id, token)
    loadComments(id, token)
    loadProcedureStatus(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      submitting,
      match: {
        params: {id},
      },
      tmpData: {application_procedure_extend_maternity: maternityData},
      tmpFiles,
      comments,
      procedureStatus,
      updateAndAccept,
      token,
    } = this.props
    return (
      <div>
        <CommentModal procedureStatusId={id} comments={comments || []} procedureType="maternityLeave">
          {({showModal}) => (
            <Form
              onSubmit={(values) => updateAndAccept(id, procedureStatus.employee.id, values, token)}
              initialValues={makeInitialValues({}, maternityData, tmpFiles)}
              tmpFiles={tmpFiles}
              comments={comments || {}}
              submitText="承認して次へ"
              otherButtons={
                <Button disabled={submitting} onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
            />
          )}
        </CommentModal>
      </div>
    )
  }
}

export default compose(
  checkEmployeeConfirm({procedureType: 'extend_maternity'}),
  connect(
    (state) => ({
      tmpData: state.procedureStatuses.tmpData.data,
      tmpFiles: state.procedureStatuses.tmpData.tmpFiles,
      comments: fetchSelector(state, 'procedure_comments').data,
      procedureStatus: state.procedureStatuses.current.data,
      submitting: isSubmitting(state),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadTmpData(id, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api
                .createWithAuth(token)
                .procedureStatuses.tmpData.mapToJson(id, {type: 'application', name: 'procedure_extend_maternity'}),
              api.createWithAuth(token).procedureStatuses.tmpFiles.list(id, 'application', 'mother_child_note'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      loadProcedureStatus: (id, token) => {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api.createWithAuth(token).procedureStatuses.get(id, ['employee'])
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
      },
      updateAndAccept: async (procedureStatusId, employeeId, values, token) => {
        try {
          const {
            started_at_before: startOnPrev,
            expected_finished_at_before: endOnPrev,
            started_at_after: startOn,
            expected_finished_at_after: endOn,
          } = values
          const authedApi = api.createWithAuth(token)
          await authedApi.employees.leaveOfAbsences.updateOrCreateForMaternity(
            employeeId,
            startOn,
            endOn,
            startOnPrev,
            endOnPrev
          )
          await authedApi.procedureStatuses.update(procedureStatusId, {date_on: values.expected_birth_date})
          if (values.id) {
            await authedApi.procedureStatuses.extendMaternityData.update(procedureStatusId, values)
          } else {
            const {
              data: {id},
            } = await authedApi.procedureStatuses.extendMaternityData.create(procedureStatusId, values)
            dispatch(autofill('id', id))
          }
          const {data: procedureStatus} = await authedApi.procedureStatuses.get(procedureStatusId)
          await authedApi.procedureStatuses.accept(procedureStatus.id)
          await authedApi.procedureStatuses.startProgress(procedureStatusId)
          dispatch(push(`/procedure_statuses/${procedureStatusId}`))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(Confirm)
