import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Dl, Dd, Dt, Grid, Wrap, Half} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'

const EmployeeContactInformation = ({employee, editLink, canEdit, forProfiles}) => {
  const employeeContactInformation = employee.employee_contact_information || {}
  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeContactInformation`} title="社内連絡先" canEdit={canEdit} />
      <Item>
        {forProfiles ? (
          <Wrap forProfiles>
            <Half>
              <Dl>
                <Dt width={43}>内線番号</Dt>
                <Dd width={57}>{recordDisplay(employeeContactInformation.extension_number)}</Dd>
              </Dl>
            </Half>
            <Half>
              <Dl>
                <Dt width={43}>電話番号</Dt>
                <Dd width={57}>{recordDisplay.numbers(employeeContactInformation, 'phone_number', 3)}</Dd>
              </Dl>
            </Half>
            <Dl>
              <Dt width={20}>メールアドレス</Dt>
              <Dd width={80}>{recordDisplay(employeeContactInformation.email)}</Dd>
            </Dl>
          </Wrap>
        ) : (
          <Grid>
            <Dl>
              <Dt>内線番号</Dt>
              <Dd>{recordDisplay(employeeContactInformation.extension_number)}</Dd>
            </Dl>
            <Dl>
              <Dt>電話番号</Dt>
              <Dd>{recordDisplay.numbers(employeeContactInformation, 'phone_number', 3)}</Dd>
            </Dl>
            <Dl>
              <Dt>メールアドレス</Dt>
              <Dd>{recordDisplay(employeeContactInformation.email)}</Dd>
            </Dl>
          </Grid>
        )}
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmployeeContactInformation)
