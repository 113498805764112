import React from 'react'
import {Link} from 'react-router-dom'

import styles from 'components/SelectProcedure.scss'

export default ({icon, text, to, children}) => {
  const Icon = icon
  return (
    <Link to={to} className={styles.boxLink}>
      <span className={styles.icon}>
        <Icon size={40} />
      </span>
      <p className={styles.title}>{children}</p>
      <p className={styles.text}>{text}</p>
    </Link>
  )
}
