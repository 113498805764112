import React, {Component, useState} from 'react'
import Button from 'jbc-front/components/Button'
import {connect} from 'react-redux'
import api from 'api'
import stressCheckStartStyles from './StressCheckStartModal.scss'
import Modal from 'components/Modal'
import {Label} from 'jbc-front/components/Form'
import {StressCheck, BlankTarget} from 'jbc-front/components/icons'
import Sidebar from 'jbc-front/components/Sidebar'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {isFullAdminSelector} from 'utils'
import {notifyError} from 'store/actions/notify'

class StressCheckStartModal extends Component {
  state = {
    showLink: 'none',
  }

  loadStressCheckStatus = async () => {
    const {token, isFullAdmin} = this.props
    try {
      const {
        data: {status},
      } = await api.createWithAuth(token).StressCheck.showStressCheckStatus()
      if (status === 'NOT_STARTED' && isFullAdmin) {
        this.setState({showLink: 'start'})
      } else if (status === 'HAS_ADMIN') {
        this.setState({showLink: 'open'})
      }
    } catch (e) {
      // showLinkはnoneのまま
    }
  }

  componentDidMount() {
    this.loadStressCheckStatus()
  }

  render() {
    return (
      <Modal>
        {({hideModal, showModal, isOpen}) => {
          const {dispatch} = this.props
          const [roleType, setRoleType] = useState(null)
          if (this.state.showLink === 'none') {
            return <div />
          }
          return (
            <>
              <Sidebar.Item
                as={Link}
                icon={StressCheck}
                rightIcon={BlankTarget}
                onClick={() => {
                  if (this.state.showLink === 'start') {
                    showModal()
                  } else {
                    window.open('/stress_check_start', '_blank')
                  }
                  this.HideMenu
                }}
              >
                ストレスチェック
              </Sidebar.Item>
              {isOpen && (
                <Modal.Modal className={stressCheckStartStyles.box}>
                  <Modal.Header className={stressCheckStartStyles.obi_header} />
                  <Modal.Body>
                    <div className={stressCheckStartStyles.title}>
                      <div>ストレスチェック</div>
                    </div>
                    <div className={stressCheckStartStyles.body}>
                      <div className={stressCheckStartStyles.description}>
                        ストレスチェック機能の利用を開始します。
                        <br />
                        <br />
                        ストレスチェックは法律上閲覧できる管理者が制限されています。
                        <br />
                        ストレスチェックの結果等の閲覧権限がある方のみ、自身の役割を選択して、ストレスチェック機能の利用を開始してください。
                        <br />
                      </div>
                      <div className={stressCheckStartStyles.notice}>
                        ※追加料金は発生いたしません
                        <br />
                        ※二人目以降の管理者はストレスチェック画面内の権限管理画面から追加できます
                      </div>
                      <div className={stressCheckStartStyles.select}>
                        <Label text="あなたの役割" required />
                        <Select
                          name="RoleType"
                          simpleValue
                          required
                          clearable={false}
                          value={roleType}
                          placeholder="選択してください"
                          options={[
                            {label: '実施者（全従業員の個人結果を確認できる方、産業医等）', value: 'practitioner'},
                            {
                              label: '実務担当者（労務担当者等）',
                              value: 'person_in_charge',
                            },
                          ]}
                          onChange={(value) => {
                            setRoleType(value)
                          }}
                        />
                      </div>
                    </div>
                    <Modal.Buttons>
                      <Button
                        className={stressCheckStartStyles.button_color}
                        primary
                        onClick={() => {
                          if (roleType) {
                            hideModal()
                            window.open(`/stress_check_start?role_type=${roleType}`, '_blank')
                          } else {
                            dispatch(notifyError('あなたの役割を選択してください'))
                          }
                        }}
                      >
                        <p className={stressCheckStartStyles.button_title}>ストレスチェックを開始する</p>
                      </Button>
                    </Modal.Buttons>
                  </Modal.Body>
                </Modal.Modal>
              )}
            </>
          )
        }}
      </Modal>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  isFullAdmin: isFullAdminSelector(state),
}))(StressCheckStartModal)
