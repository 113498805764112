import React from 'react'
import {FormSection} from 'redux-form'
import {getDiffFromOptions, getDiffFromFile, withFormSelectors} from 'employees/form/common'
import {RadioField, TextField, FileField} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {number, maxLength} from 'validators'

export const dependentsInSingleTypes = [
  {value: 'none', label: '対象外'},
  {value: 'dependents_in_single', label: '単身児童扶養者'},
]

export const DependentsInSingle = ({dependentsInSingleType, diff = {}}) => (
  <>
    <RadioField
      name="dependents_in_single_type"
      label="単身児童扶養者（2020年以前）"
      options={dependentsInSingleTypes}
      note={
        <p>
          単身児童扶養者に該当する場合、合計所得金額が135万円以下であれば住民税が非課税となります。詳しくは
          <a
            href="https://jobcan-lms.zendesk.com/hc/ja/articles/360038539892"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
        </p>
      }
      diff={getDiffFromOptions(dependentsInSingleTypes, diff?.dependents_in_single_type)}
    />
    {dependentsInSingleType && dependentsInSingleType !== 'none' && (
      <FormSection name="employee_dependents_in_single">
        <LabelMapper name="employee_dependents_in_single" label="単身児童扶養の" />
        <TextField
          name="child_support_allowance_number"
          label="児童扶養手当証書の番号"
          validate={[number, maxLength(10)]}
          diff={diff.employee_dependents_in_single?.child_support_allowance_number}
          required
        />
        <FileField
          name="child_support_allowance_file_name"
          label="児童扶養手当証書の画像"
          diff={getDiffFromFile(diff.employee_dependents_in_single?.child_support_allowance_file_name)}
        />
        <TextField
          name="child_full_names"
          label="生計を一にする児童の氏名"
          diff={diff.employee_dependents_in_single?.child_full_names}
          required
        />
        <TextField
          name="child_estimated_incomes"
          label="対象児童の年間所得見積額"
          note="対象児童が複数いる場合はカンマ区切りで入力してください。"
          diff={diff.employee_dependents_in_single?.child_estimated_incomes}
          required
        />
        <TextField
          name="transferred_reasons"
          label="異動月日及び事由"
          diff={diff.employee_dependents_in_single?.transferred_reasons}
        />
      </FormSection>
    )}
  </>
)

export default DependentsInSingle |> withFormSelectors
