import {React, useState} from 'react'
import {Section} from 'jbc-front/components/Form'
import {
  reduxForm,
  isSubmitting as isSubmittingForm,
  autofill as autofillForm,
  stopSubmit as stopSubmitForm,
  formValueSelector,
  touch as touchForm,
  FieldArray,
} from 'redux-form'
import {bindActionCreators} from 'redux'
import moment from 'moment'
import {
  ExpectedBirthDateBefore,
  ExpectedBirthDateAfter,
  MaternityDivisionBefore,
  MaternityDivisionAfter,
  StartedAtBefore,
  StartedAtAfter,
  FinishedAtBefore,
  FinishedAtAfter,
} from 'procedures/maternityLeave/extend/MaternityForm'
import {normalizeExtendMaternityKeys, createInitialChildData} from 'procedures/maternityLeave/Form'
import {
  MaternityStatus,
  ReasonOfAbortion,
  DateOfAbortion,
  MotherChildNote,
  renderChildInfo,
} from 'procedures/maternityLeave/MaternityForm'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import {connect} from 'react-redux'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import ReviewComments from 'components/ReviewComments'
import _ from 'lodash'
import {ConfirmUpgradeModal} from 'components/modals/ConfirmUpgradeModal'

const formName = 'extendMaternity'
export const isSubmitting = isSubmittingForm(formName)
export const autofill = autofillForm.bind(null, formName)
export const stopSubmit = stopSubmitForm.bind(null, formName)
export const touch = touchForm.bind(null, formName)

const Form = ({
  submitting,
  maternity_status,
  expectedBirthDateBefore,
  expectedBirthDateAfter,
  maternityDivisionBefore,
  maternityDivisionAfter,
  startedAtBefore,
  expectedFinishedAtBefore,
  startedAtAfter,
  expectedFinishedAtAfter,
  handleSubmit,
  otherButtons,
  employee,
  initialValues,
  submitText = '完了',
  comments,
  needUpgrade,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }

  return (
    <form className="new_boarding_procedure" onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">産休変更の手続き</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <ReviewComments comments={comments} />
        <Section title="出産情報">
          <ExpectedBirthDateBefore
            maternityDivisionBefore={maternityDivisionBefore}
            withRelatedProcedure={initialValues.with_related_procedure}
            startedAtBefore={startedAtBefore}
            expectedFinishedAtBefore={expectedFinishedAtAfter}
          />
          <ExpectedBirthDateAfter
            maternityDivisionAfter={maternityDivisionAfter}
            startedAtAfter={startedAtAfter}
            expectedFinishedAtAfter={expectedFinishedAtAfter}
          />
          <MaternityDivisionBefore
            withRelatedProcedure={initialValues.with_related_procedure}
            expectedBirthDateBefore={expectedBirthDateBefore}
            startedAtBefore={startedAtBefore}
            expectedFinishedAtBefore={expectedFinishedAtBefore}
          />
          <MaternityDivisionAfter
            expectedBirthDateAfter={expectedBirthDateAfter}
            startedAtAfter={startedAtAfter}
            expectedFinishedAtAfter={expectedFinishedAtAfter}
          />
          <StartedAtBefore withRelatedProcedure={initialValues.with_related_procedure} />
          <StartedAtAfter />
          <FinishedAtBefore withRelatedProcedure={initialValues.with_related_procedure} />
          <FinishedAtAfter />
        </Section>
        <Section title="出生児情報">
          <MaternityStatus />
          {maternity_status === 'after' && (
            <>
              <FieldArray
                name="procedure_maternity_child_data"
                component={renderChildInfo}
                procedureType="start_maternity"
                formName={formName}
                selector={selector}
                autofill={autofill}
                canAdd
                canDelete
              />
              <MotherChildNote />
            </>
          )}
          {maternity_status === 'other' && (
            <>
              <ReasonOfAbortion required />
              <DateOfAbortion required />
            </>
          )}
        </Section>
        <div className="u-ta-c u-mt30">
          {otherButtons}
          <AsyncTaskConsumer>
            {({taskRunningProps}) => (
              <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting} {...taskRunningProps}>
                {submitText}
              </Button>
            )}
          </AsyncTaskConsumer>
        </div>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => {
          setOpenModal(false)
          handleSubmit()
        }}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
}

export const makeInitialValues = (relatedProcedure, maternityData, tmpFiles) => {
  if (maternityData) {
    return {
      ...maternityData,
      mother_child_note: tmpFiles.map((tmpFile) => ({id: tmpFile.id, ...tmpFile.file})),
      with_related_procedure: true,
    }
  } else {
    return makeInitialValuesFromRelatedProcedure(relatedProcedure)
  }
}

export const makeInitialValuesForUpdate = (procedureStatus, employee) => {
  if (!employee || !procedureStatus) {
    return {procedure_maternity_child_data: [{}]}
  }
  const {procedure_extend_maternity_datum: maternityData, date_on: dateOn} = procedureStatus
  if (maternityData && maternityData.procedure_maternity_child_data.length === 0) {
    return {date_on: dateOn, ...maternityData, procedure_maternity_child_data: [{}]}
  } else {
    const formattedMaternityChildData = maternityData.procedure_maternity_child_data.map((child) => {
      if (child.add_support) {
        return {...child, skip_add_support: true}
      } else {
        return child
      }
    })
    return {date_on: dateOn, ...maternityData, procedure_maternity_child_data: formattedMaternityChildData}
  }
}

export const makeInitialValuesFromRelatedProcedure = ({
  procedure_start_maternity_datum: maternityData,
  date_on: dateOn,
}) => {
  if (!maternityData) {
    return {procedure_maternity_child_data: [{}], mother_child_note: []}
  }
  const prevData = createPrevData(normalizeExtendMaternityKeys(maternityData))
  return {
    ...prevData,
    date_on: dateOn,
    procedure_maternity_child_data: createInitialChildData(prevData.procedure_maternity_child_data),
    mother_child_note: [],
    with_related_procedure: true,
  }
}

const createPrevData = (data) => ({
  expected_birth_date_before: data.expected_birth_date,
  maternity_division_before: data.maternity_division,
  started_at_before: data.started_at,
  expected_finished_at_before: data.expected_finished_at,
  ..._.omit(data, 'id', 'mother_child_note'),
})

export const selector = formValueSelector(formName)

const validate = ({started_at_before, started_at_after, expected_finished_at_before, expected_finished_at_after}) => {
  const error = {}
  if (
    started_at_before &&
    expected_finished_at_before &&
    moment(started_at_before).isAfter(moment(expected_finished_at_before))
  ) {
    error.expected_finished_at_before = 'を変更前の産前産後休業等開始年月日より前に設定することはできません。'
  }
  if (
    started_at_after &&
    expected_finished_at_after &&
    moment(started_at_after).isAfter(moment(expected_finished_at_after))
  ) {
    error.expected_finished_at_after = 'を変更後の産前産後休業等開始年月日より前に設定することはできません。'
  }
  return error
}

export default connect(
  (state) => ({
    maternity_status: selector(state, 'maternity_status'),
    expectedBirthDateBefore: selector(state, 'expected_birth_date_before'),
    expectedBirthDateAfter: selector(state, 'expected_birth_date_after'),
    maternityDivisionBefore: selector(state, 'maternity_division_before'),
    maternityDivisionAfter: selector(state, 'maternity_division_after'),
    startedAtBefore: selector(state, 'started_at_before'),
    expectedFinishedAtBefore: selector(state, 'expected_finished_at_before'),
    startedAtAfter: selector(state, 'started_at_after'),
    expectedFinishedAtAfter: selector(state, 'expected_finished_at_after'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  bindActionCreators.bind(null, {autofill})
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    validate,
  })(Form)
)
