import React, {Component} from 'react'
import styles from 'procedureStatuses/RelatedRecordsAlert.scss'
import ActionButton from 'jbc-front/components/ActionButton'
import {connect} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'

class RelatedRecordsAlertPdf extends Component {
  clearRelatedRecords = () => {
    const {
      clearRelatedRecords,
      token,
      procedureStatus: {id},
    } = this.props
    clearRelatedRecords(token, id)
  }

  render() {
    const {procedureStatus} = this.props
    if (procedureStatus.is_related_records_dirty) {
      return (
        <div className={styles.notice}>
          編集内容はまだ反映されていません
          <ActionButton onClick={this.clearRelatedRecords} className={styles.actionButton}>
            帳票に反映する
          </ActionButton>
        </div>
      )
    }
    return null
  }
}

export default connect(
  (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    token: state.auth.token,
  }),
  (dispatch, {loadProcedureStatus}) => ({
    async clearRelatedRecords(token, id) {
      try {
        await api.createWithAuth(token).procedureStatuses.clearRelatedRecords(id)
        loadProcedureStatus()
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(RelatedRecordsAlertPdf)
