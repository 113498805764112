import ChooseEmployee from 'procedures/maternityLeave/start/ChooseEmployee'
import Detail from 'procedures/maternityLeave/start/Detail'
import Update from 'procedures/maternityLeave/start/Update'
import EmployeeInput from 'procedures/maternityLeave/start/EmployeeInput'
import Confirm from 'procedures/maternityLeave/start/Confirm'

export default {
  ChooseEmployee,
  Detail,
  Update,
  EmployeeInput,
  Confirm,
}
