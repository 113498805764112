import React from 'react'
import Flow from 'procedures/Flow'
import _ from 'lodash'

export const makeFlow = function* (employee) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const employmentInsuranceJoined = _.get(employee, 'employment_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  if (healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') {
    if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
      yield 'R1'
    }
    if (welfarePensionInsuranceJoined && employee.national_type === 'foreigner') {
      yield 'R55'
    }
    if (employmentInsuranceJoined) {
      yield 'R57'
    }
    if (!_.isEmpty(employee.employee_dependents)) {
      if (healthInsuranceJoined) {
        yield 'R4'
      }
      const spouse = employee.employee_dependents.find((dependent) => dependent.relation_type === 'spouse')
      if (welfarePensionInsuranceJoined && spouse) {
        if (healthInsuranceType !== 'kyokai') {
          yield 'R6'
        }
        if (spouse.national_type === 'foreigner') {
          yield 'R56'
        }
      }
    }
    return
  }
  if (healthInsuranceType === 'its') {
    if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
      yield 'R2'
    }
    if (welfarePensionInsuranceJoined && employee.national_type === 'foreigner') {
      yield 'R55'
    }
    if (employmentInsuranceJoined) {
      yield 'R57'
    }
    if (!_.isEmpty(employee.employee_dependents)) {
      if (healthInsuranceJoined) {
        yield 'R5'
      }
      const spouse = employee.employee_dependents.find((dependent) => dependent.relation_type === 'spouse')
      if (spouse) {
        if (healthInsuranceJoined) {
          yield `R150_${spouse.id}`
        }
        if (welfarePensionInsuranceJoined) {
          yield 'R6'
          if (spouse.national_type === 'foreigner') {
            yield 'R56'
          }
        }
      }
      if (healthInsuranceJoined) {
        const others = employee.employee_dependents.filter((dependent) => dependent.relation_type !== 'spouse')
        yield* others.map((dependent) => `R150_${dependent.id}`)
      }
    }
  }
}

const EnrollFlow = () => <Flow {...{makeFlow}} />

export default EnrollFlow
