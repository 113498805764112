import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client'
import {onError} from '@apollo/client/link/error'
import {setContext} from '@apollo/client/link/context'
import {createUploadLink} from 'apollo-upload-client'

const applySelectedOfficesLink = (store) =>
  setContext((_, {headers}) => ({
    headers: {
      ...headers,
      'X-Office-Ids': store.getState().session.selectedOffices,
    },
  }))

export default (uri, store) =>
  new ApolloClient({
    link: ApolloLink.from([
      onError(({graphQLErrors, networkError}) => {
        if (graphQLErrors)
          graphQLErrors.map(({message, locations, path}) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          )
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      applySelectedOfficesLink(store),
      createUploadLink({
        uri,
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Client: {
          fields: {
            officesByIds: {
              read(_, {args, toReference, canRead}) {
                const refs = args.ids.map((id) => toReference({__typename: 'Office', id}))
                if (refs.find((ref) => !canRead(ref))) {
                  return undefined // 1つでもキャッシュヒットしなかったらundefinedにする必要がある https://github.com/apollographql/apollo-client/issues/9063#issuecomment-1000147426
                }
                return refs
              },
            },
          },
        },
      },
    }),
  })
