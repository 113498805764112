import React, {useEffect, useMemo, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc'
import {actionCreators} from 'actions'
import api from 'api'
import _ from 'lodash'
import fp from 'lodash/fp'

import styles from 'settings/ListEdit.scss'

import ActionButton from 'jbc-front/components/ActionButton'
import {Delete, Drugging, Edit, Strage, MinusCircle, PlusSquare} from 'jbc-front/components/icons'

const DragHandle = SortableHandle(() => (
  <span className={styles.handle}>
    <Drugging />
  </span>
))

const SortableItem = SortableElement(({editing, item, handleEdit, handleDelete, typeName}) =>
  editing ? (
    <div className={styles.organizationListLine}>
      <div className={styles.organizationDataCode}>
        <DragHandle />
        <input
          type="text"
          name="code"
          placeholder="コードを入力してください"
          value={item.code}
          onChange={(e) => handleEdit(item.localId, {code: e.target.value})}
          className={styles.inputForm}
          {...(item.initialCode !== item.code && {
            style: {
              backgroundColor: '#e1edfe',
              border: '1px dotted #3498db',
              borderRadius: '3px',
            },
          })}
        />
      </div>
      <div className={styles.organizationDataName}>
        <input
          type="text"
          name="name"
          placeholder={`${typeName}名を入力してください`}
          value={item.name}
          onChange={(e) => handleEdit(item.localId, {name: e.target.value})}
          className={styles.inputForm}
          {...(item.initialName !== item.name && {
            style: {
              backgroundColor: '#e1edfe',
              border: '1px dotted #3498db',
              borderRadius: '3px',
            },
          })}
        />
        {editing && item.employees_count === 0 ? (
          <div className={styles.organizationDelete}>
            <Delete size="20" onClick={() => handleDelete(item.localId)} />
          </div>
        ) : (
          <div className={styles.organizationDeleteNo}>
            <Delete size="20" />
            <div className={styles.noteWrap}>
              <div className={styles.note}>
                従業員に紐づいているので削除できません。
                <a
                  href="https://jobcan-lms.zendesk.com/hc/ja/articles/360003860592"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  詳しくはこちら
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className={styles.organizationList}>
      <div className={styles.itemCode}>{item.code}</div>
      <div className={styles.itemName}>{item.name}</div>
    </div>
  )
)

const SortableList = SortableContainer(({editing, list, handleEdit, handleDelete, typeName}) => {
  return (
    <div>
      {list.map((item, index) => (
        <SortableItem
          item={item}
          editing={editing}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          key={editing ? `edit_${item.localId}` : item.id}
          index={index}
          typeName={typeName}
        />
      ))}
    </div>
  )
})

export const checkAnyItemEditted = (list, otherList) =>
  !_.isEqual(
    list.map(fp.pickAll(['name', 'position', 'code'])),
    otherList.map(fp.pickAll(['name', 'position', 'code']))
  )

const typeNames = {
  positions: '役職',
  occupations: '職種',
  employmentTypes: '雇用形態',
}

const ListEdit = ({typeCode}) => {
  const token = useSelector((state) => state.auth.token)
  const editing = useSelector((state) => state.settings.listEdit.editing)
  const initialList = useSelector((state) => state.settings.listEdit.initialList)
  const list = useSelector((state) => state.settings.listEdit.list)
  const [submitting, setSubmitting] = useState(false)

  const dispatch = useDispatch()
  const {initializeList, startEdit, initialize, addList, editNode, moveList, deleteList} = useMemo(
    () => bindActionCreators(actionCreators.settings.listEdit, dispatch),
    [dispatch]
  )
  const {asyncError, notifySuccess} = useMemo(() => bindActionCreators(actionCreators, dispatch), [dispatch])

  const fetchData = async () => {
    try {
      const data = await api.createWithAuth(token)[typeCode].list()
      const list = data[typeCode]
      initializeList(list)
    } catch (err) {
      asyncError(err)
    }
  }
  const updateData = async (list) => {
    setSubmitting(true)
    try {
      await api.createWithAuth(token)[typeCode].updateAll(list)
      const data = await api.createWithAuth(token)[typeCode].list()
      initializeList(data[typeCode])
      notifySuccess('更新しました')
    } catch (err) {
      asyncError(err)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    fetchData()
    return initialize
  }, [])

  const handleCancel = () => {
    fetchData()
  }

  const handleUpdate = () => {
    const data = list
      .map((list, index) => ({
        id: list.id,
        name: list.name.trim(),
        position: index,
        code: list.code,
      }))
      .filter((list) => list.name != '')
    updateData(data)
  }

  const handleAddList = () => {
    addList()
  }

  const handleEdit = (localId, value) => {
    editNode({localId, value})
  }

  const handleDelete = (localId) => {
    deleteList({localId})
  }

  const handleStartEdit = () => {
    startEdit()
    if (list.length === 0) {
      addList()
    }
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    moveList({oldIndex, newIndex})
  }

  const formSubmit = (e) => {
    e.preventDefault()
  }

  const isAnyItemEditted = checkAnyItemEditted(initialList, list)

  return (
    <div>
      {editing ? (
        <div className={styles.buttonWrap}>
          <ActionButton
            primary={isAnyItemEditted}
            disabled={!isAnyItemEditted || submitting}
            disabledReason={submitting ? null : '編集してから保存してください'}
            onClick={handleUpdate}
            icon={<Strage size={15} />}
          >
            保存
          </ActionButton>
          <ActionButton onClick={handleCancel} icon={<MinusCircle size={15} />}>
            キャンセル
          </ActionButton>
          <ActionButton onClick={handleAddList} icon={<PlusSquare size={15} />}>
            行を追加
          </ActionButton>
        </div>
      ) : (
        <div>
          {list.length > 0 ? (
            <ActionButton onClick={handleStartEdit} icon={<Edit size={15} />}>
              編集
            </ActionButton>
          ) : (
            <ActionButton onClick={handleStartEdit} icon={<PlusSquare size={15} />}>
              追加
            </ActionButton>
          )}
        </div>
      )}
      <form name="data" onSubmit={formSubmit}>
        <div className={styles.organizationTable}>
          <div className={styles.organizationListHeader}>
            <div className={styles.code}>{typeNames[typeCode]}コード</div>
            <div className={styles.name}>{typeNames[typeCode]}名</div>
          </div>
          {list.length ? (
            <SortableList
              lockAxis="y"
              transitionDuration={0}
              lockToContainerEdges
              useDragHandle={true}
              list={list}
              editing={editing}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              onSortEnd={onSortEnd}
              typeName={typeNames[typeCode]}
            />
          ) : (
            !editing && (
              <div className={styles.organizationListNodata}>
                {typeNames[typeCode]}を登録してください。未設定の場合は従業員登録時に{typeNames[typeCode]}
                を選択できません。
              </div>
            )
          )}
          <input type="submit" name="submit" value="submit" className={styles.submitParts} />
        </div>
      </form>
    </div>
  )
}

export default ListEdit
