import React from 'react'
import styles from './AsyncTaskError.scss'
import {recordDisplay} from 'utils'
import _ from 'lodash'
import {Close} from 'jbc-front/components/icons'

const hasError = (task) => task.status === 'failed' || task.status === 'partial_success'

const title = {
  employee_csv_import: 'CSVで従業員データの登録エラー',
  employee_csv_update: 'CSVで従業員データの更新エラー',
  payroll_employee_import: 'ジョブカン給与計算からの従業員データインポートエラー',
  my_number_csv_import: 'CSVでマイナンバーデータのインポートエラー',
  yea_employees_import: '年末調整データ反映エラー',
  group_csv_import: 'CSVでグループ情報の登録エラー',
  group_csv_update: 'CSVでグループ情報の更新エラー',
}

const partialSuccessNotice = {
  employee_csv_import: 'なお、以下にエラー表示のない従業員は、正常に登録されています。',
  employee_csv_update: 'なお、以下にエラー表示のない従業員は、正常に更新されています。',
}

export const ErrorDisplay = ({onCloseClick, dates, title, list}) => (
  <div className={styles.errorWrap}>
    <div onClick={onCloseClick} className={styles.close} role="presentation">
      <Close size={20} />
    </div>
    <div className={styles.inner}>
      <p className={styles.title}>{title}</p>
      <div className={styles.dates}>{dates}</div>
      <ul className={styles.list}>{list}</ul>
    </div>
  </div>
)

const AsyncTaskError = ({task, confirm, displayTypes}) =>
  task && hasError(task) && (!displayTypes || displayTypes.includes(task.task_type)) ? (
    <ErrorDisplay
      onCloseClick={confirm}
      title={`${title[task.task_type]}が発生しました。${
        (task.status === 'partial_success' && partialSuccessNotice[task.task_type]) || ''
      }`}
      dates={
        <>
          <dl>
            <dt>開始日時：</dt>
            <dd>{recordDisplay.date(task.created_at, 'LLL')}</dd>
          </dl>
          <dl>
            <dt>エラー日時：</dt>
            <dd>{recordDisplay.date(task.ended_at, 'LLL')}</dd>
          </dl>
        </>
      }
      list={_.split(task.error, '\n').map((error) => (
        <li key={error.id}>{error}</li>
      ))}
    />
  ) : null

export default AsyncTaskError
