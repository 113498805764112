import React, {useState, useEffect, useRef, useMemo} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Button from 'jbc-front/components/Button'
import _ from 'lodash'
import {recordDisplay, isOfficeAdminSelector, makeBackUrl} from 'utils'
import {actionCreators} from 'actions'
import styles from 'employees/List.scss'
import {getGroups} from 'employees/form/EmploymentInfomation'
import {getSavedDisplayEmployeeLimit, getCurrentQueryFromLocation} from 'employees/list/utils'
import PaginatorWithResult from 'employees/list/PaginatorWithResult'
import SearchFormWithCardList from 'employees/list/SearchFormWithCardList'
import SortableTh from 'employees/list/SortableTh'
import RosterOfEmployees from 'employees/RosterOfEmployees'
import CsvDownload from 'employees/CsvDownload'
import Coordination from 'components/Coordination'
import {Pulldown, Alert, CheckCircle, MenuEllipsis, Copy, Edit} from 'jbc-front/components/icons'
import Hint from 'jbc-front/components/Hint'
import api from 'api'
import {fetchEmployees} from 'employees/list/utils'
import {withAsyncTask} from 'AsyncTask'
import compose from 'lodash/fp/compose'
import AsyncTaskError from 'AsyncTaskError'
import SelectList from 'components/SelectList'
import BulkDelete from 'employees/BulkDelete'
import {useEmployeeListType} from 'hooks/useEmployeeListType'
import {useDisplayType} from 'hooks/useDisplayType'
import DropDownMenu from 'components/DropDownMenu'
import useReactRouter from 'use-react-router'
import Balloon from 'components/Balloon'
import useRouter from 'use-react-router'
import classnames from 'classnames'
import {Content} from 'components/layout/Content'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'
import comments from 'components/Comments'

const MAX_DELETE_COUNT = 100

export const wrapArrowText = (source) => {
  return _.flatMap(source.split('->'), (text, index) =>
    index === 0
      ? text
      : [
          <span className={styles.arrowText} key={index}>
            -&gt;
          </span>,
          text,
        ]
  )
}

const TextEllipsis = ({line = 1, styles = {}, children}) => {
  const singleLine = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
  const multiLine = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    WebkitLineClamp: `${line}`,
  }
  const ellipsisStyle = line === 1 ? singleLine : multiLine
  return React.cloneElement(children, {style: {...styles, ...ellipsisStyle}})
}

const Td = ({children, to, title, tdIcon, ...rest}) => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false)
  const observer = useRef(
    new ResizeObserver((entries) => {
      for (const entry of entries) {
        const element = entry.target
        setIsEllipsisActive(element.scrollHeight > element.clientHeight)
      }
    })
  )
  const ref = useMemo(() => {
    let prevNode = null
    return (node) => {
      if (prevNode) {
        observer.current.unobserve(prevNode)
      }
      if (node) {
        observer.current.observe(node)
        prevNode = node
      }
    }
  }, [])

  const titles = isEllipsisActive && to ? {title: title} : {}

  return (
    <td style={{padding: 0}} {...rest}>
      <div className={styles.tdItemLink} {...titles}>
        {to ? (
          <Link to={to}>
            <div ref={ref} className={styles.forEllipsis}>
              {children}
            </div>
          </Link>
        ) : (
          <>
            {tdIcon ? (
              <span className={styles.tdIcon}>{children}</span>
            ) : (
              <div className={styles.notLink}>
                <div className={styles.forEllipsis}>{children}</div>
              </div>
            )}
          </>
        )}
      </div>
    </td>
  )
}

const renderToggle = ({onClick, deleteMode, isOpen}) => (
  <span
    className={classnames(styles.menuEllipsis, isOpen && styles.activeDropDown)}
    {...(!deleteMode ? (onClick = {onClick}) : undefined)}
  >
    <MenuEllipsis size={16} />
  </span>
)

const LinkIfNeeded = ({children, to, ...rest}) =>
  to ? (
    <Link className={styles.cardLink} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <div className={styles.cardLink} {...rest}>
      {children}
    </div>
  )

const createLink = ({id, location, employees, user, isApplying}) => {
  if (isApplying) {
    return `/employees/${id}/detail_input_confirm`
  }
  const query = getCurrentQueryFromLocation(location, user)
  const index = (+query._page - 1) * +query.limit + employees.findIndex((employee) => employee.id === id)
  return `/employees/${id}?query=${encodeURIComponent(
    JSON.stringify({
      ..._.omit(query, ['_page', 'limit']),
    })
  )}&index=${index}`
}

const additionalParams = {embed: ['personnel_history']}

const EmployeeList = ({listType, ...rest}) => {
  const Component = listType == 'list' ? ListTable : CardList
  return <Component {...rest} />
}

const UpdateAlert = () => {
  const [messageLeft, setMessageLeft] = useState(-50)
  const ref = useMemo(() => {
    let cleanup = null
    return (node) => {
      if (cleanup) {
        cleanup()
      }
      if (node) {
        const handleResize = () => {
          const {left} = node.getBoundingClientRect()
          setMessageLeft(-_.clamp(left - 50, 5, 50))
        }
        handleResize()

        window.addEventListener('resize', handleResize)
        cleanup = () => window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return (
    <div ref={ref} className={styles.updateAlertWrap}>
      <Hint
        text="従業員から情報更新依頼が届いています。"
        width={255}
        component={Alert}
        size={19}
        messageLeft={messageLeft}
      />
    </div>
  )
}

const ToggleDropDownMenu = ({employee}) => {
  const {history} = useReactRouter()
  const dispatch = useDispatch()
  const copyToClipBoard = async (value) => {
    try {
      await navigator.clipboard.writeText(value)
      dispatch(actionCreators.notifySuccess(`「${value}」をコピーしました。`))
    } catch (err) {
      dispatch(actionCreators.asyncError(err))
    }
  }
  const isStaffCodeAvailable = !!employee.staff_code
  const isFullNameAvailable = !!recordDisplay.fullName(employee)
  const menuItems = [
    {
      onClick: () => copyToClipBoard(employee.staff_code),
      label: (
        <span className={styles.textLabelToCopy}>
          <Copy size={15} />
          スタッフコードをコピー
        </span>
      ),
      disabled: !isStaffCodeAvailable,
      disabledReason: !isStaffCodeAvailable ? 'スタッフコードが未設定です' : null,
    },
    {
      onClick: () => copyToClipBoard(recordDisplay.fullName(employee)),
      label: (
        <span className={styles.textLabelToCopy}>
          <Copy size={15} />
          氏名をコピー
        </span>
      ),
      disabled: !isFullNameAvailable,
      disabledReason: !isFullNameAvailable ? '氏名が未設定です' : null,
    },
    {
      onClick: () => history.push(`/employees/${employee.id}/edit?back_to=${makeBackUrl(location)}`),
      label: (
        <span className={styles.textLabelToCopy}>
          <Edit icon={15} />
          編集画面へ
        </span>
      ),
    },
  ]
  return <DropDownMenu toggle={renderToggle} menuItems={menuItems} />
}

const Comments = connect(
  (state) => ({
    currentUserId: state.session.currentUser.id,
  }),
  (dispatch, {commentableId, token}) => ({
    loadComment() {
      return api
        .createWithAuth(token)
        .employees.comments.list(commentableId)
        .then(({data: comments}) => comments)
    },
    addComment: (body) =>
      api
        .createWithAuth(token)
        .employees.comments.create(commentableId, body)
        .then(({data: comment}) => comment),

    updateComment: ({id, body, color, alert_at}) =>
      api
        .createWithAuth(token)
        .employees.comments.update(commentableId, id, {body, color, alert_at})
        .then(({data: comment}) => comment),

    deleteComment: (id) => api.createWithAuth(token).employees.comments.delete(commentableId, id),
  })
)(comments('employee'))

const ListTable = ({employees, user, hasBranchOffice, list, th, deleteMode, displayType}) => {
  const token = useSelector((state) => state.auth.token)
  return (
    <div className="l-overflow-scroll">
      <table
        className={classnames('m-table-list', 'm-table-fixed', {
          [styles.hasBranchOffice]: hasBranchOffice,
          [styles.hascheckBox]: deleteMode,
        })}
      >
        <thead>
          <tr className={styles.table}>
            {deleteMode && th}
            <th className={styles.memoColumn}>メモ</th>
            <SortableTh field="staff_code" globalClassName={styles.staffcodeColumn}>
              スタッフコード
            </SortableTh>
            <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
              氏名
            </SortableTh>
            {hasBranchOffice && (
              <SortableTh field="office" globalClassName={styles.officeColumn}>
                適用事業所
              </SortableTh>
            )}
            <SortableTh field="employment_type" globalClassName={styles.employmentStatusColumn}>
              雇用形態
            </SortableTh>
            <SortableTh field="group" globalClassName={styles.groupColumn}>
              グループ
            </SortableTh>
            <SortableTh field="position" globalClassName={styles.positionColumn}>
              役職
            </SortableTh>
            <SortableTh field="joined_at" globalClassName={styles.dateColumn}>
              入社日
            </SortableTh>
            <th className={styles.dropDownMenuColumn}></th>
          </tr>
        </thead>
        <tbody>
          {list.map(({item: employee, toggle, td}) => {
            const id = employee.id
            const isApplying = _.get(employee, 'detail_input_request.status') === 'applying'
            const to = deleteMode ? undefined : createLink({id, employees, location, user, isApplying})
            const onClick = deleteMode ? toggle : undefined
            return (
              <tr
                className={classnames(
                  styles.table,
                  'table-hover',
                  displayType === 'default' ? styles.tableDefault : styles.compact,
                  {[styles.applying]: isApplying}
                )}
                key={id}
              >
                {isApplying && (
                  <td className={styles.updateAlertColumn}>
                    <UpdateAlert />
                  </td>
                )}
                {deleteMode && td}
                <Td onClick={onClick} tdIcon className={styles.memoColumn}>
                  <Comments token={token} commentableId={id} size={30} deleteMode={deleteMode} />
                </Td>
                <Td onClick={onClick} to={to} title={employee.staff_code} className={styles.staffcodeColumn}>
                  {recordDisplay(employee.staff_code)}
                </Td>
                <Td onClick={onClick} to={to} title={recordDisplay.fullName(employee)} className={styles.nameColumn}>
                  {recordDisplay.fullName(employee)}
                </Td>
                {hasBranchOffice && (
                  <Td
                    onClick={onClick}
                    to={to}
                    title={_.get(employee, 'personnel_history.office_name')}
                    className={styles.officeColumn}
                  >
                    {recordDisplay(_.get(employee, 'personnel_history.office_name'))}
                  </Td>
                )}
                <Td
                  onClick={onClick}
                  to={to}
                  title={_.get(employee, 'personnel_history.employment_type_name')}
                  className={styles.employmentStatusColumn}
                >
                  {recordDisplay(_.get(employee, 'personnel_history.employment_type_name'))}
                </Td>
                <Td
                  onClick={onClick}
                  to={to}
                  title={getGroups(employee)
                    .map((group) => group)
                    .join('\n')}
                  className={styles.groupColumn}
                >
                  {recordDisplay(
                    !_.isEmpty(getGroups(employee)) &&
                      getGroups(employee).map((group, index) => (
                        <span key={index}>
                          {wrapArrowText(group)}
                          {displayType === 'default' && <br />}
                        </span>
                      ))
                  )}
                </Td>
                <Td
                  onClick={onClick}
                  to={to}
                  title={_.get(employee, 'personnel_history.position_name')}
                  className={styles.positionColumn}
                >
                  {recordDisplay(_.get(employee, 'personnel_history.position_name'))}
                </Td>
                <Td onClick={onClick} to={to} className={styles.dateColumn}>
                  {recordDisplay(employee.joined_at && employee.joined_at.replace(/-/g, '/'))}
                </Td>
                <Td onClick={onClick} tdIcon className={styles.dropDownMenuColumn}>
                  {!deleteMode ? <ToggleDropDownMenu employee={employee} /> : renderToggle({})}
                </Td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const CardList = ({employees, user, hasBranchOffice, list, deleteMode}) => {
  const token = useSelector((state) => state.auth.token)

  return (
    <div className={styles.cardList}>
      {list.map(({item: employee, selected, toggle}) => {
        const id = employee.id
        const isApplying = _.get(employee, 'detail_input_request.status') === 'applying'
        const to = deleteMode ? undefined : createLink({id, employees, location, user, isApplying})
        return (
          <div
            key={id}
            className={`${selected && deleteMode ? styles.cardSelected : styles.card} ${
              isApplying ? styles.applying : ''
            }`}
          >
            {isApplying && (
              <div className={styles.updateAlertCardWrap}>
                <Hint
                  text="従業員から情報更新依頼が届いています。"
                  width={255}
                  component={Alert}
                  size={19}
                  messageLeft={-170}
                />
              </div>
            )}
            {!deleteMode && (
              <div className={styles.cardDropMenuWrap}>
                <ToggleDropDownMenu employee={employee} />
              </div>
            )}
            <div className={styles.cardComment}>
              <Comments
                contentClassName={styles.forCardComment}
                token={token}
                commentableId={id}
                size={30}
                deleteMode={deleteMode}
              />
            </div>
            <LinkIfNeeded to={to} onClick={deleteMode ? toggle : undefined}>
              {deleteMode &&
                (selected ? <CheckCircle className={styles.cardChecked} /> : <div className={styles.cardUnChecked} />)}
              <div className={styles.cardBody}>
                <div className={styles.cardBodyImageWrap}>
                  {employee.icon?.thumb?.url ? (
                    <div
                      style={{backgroundImage: `url("${employee.icon.thumb.url}")`}}
                      className={styles.cardBodyImage}
                    />
                  ) : employee.icon && employee.icon.url ? (
                    <div style={{backgroundImage: `url("${employee.icon.url}")`}} className={styles.cardBodyImage} />
                  ) : (
                    <div style={{backgroundImage: 'url(/images/card_default.png)'}} className={styles.cardBodyImage} />
                  )}
                </div>
                <TextEllipsis>
                  <h5 className={styles.cardBodyName}>{recordDisplay.fullName(employee)}</h5>
                </TextEllipsis>
                {employee.staff_code && (
                  <TextEllipsis>
                    <p className={styles.cardBodyStaffcode}>{employee.staff_code}</p>
                  </TextEllipsis>
                )}
                <div className={styles.cardBodyDescription}>
                  {_.get(employee, 'personnel_history.employment_type_name') && (
                    <TextEllipsis>
                      <p className={styles.cardBodyStatus}>{employee.personnel_history.employment_type_name}</p>
                    </TextEllipsis>
                  )}
                  {hasBranchOffice && _.get(employee, 'personnel_history.office_name') && (
                    <TextEllipsis>
                      <p className={styles.cardBodyOffice}>{employee.personnel_history.office_name}</p>
                    </TextEllipsis>
                  )}
                  {!_.isEmpty(getGroups(employee)) && (
                    <div className={styles.cardGroupName}>
                      <TextEllipsis line={2}>
                        <p className={styles.cardBodyGroup}>
                          {recordDisplay(
                            getGroups(employee).map((group, index) => (
                              <span key={index}>
                                {wrapArrowText(group)}
                                {index + 1 !== getGroups(employee).length && <small>&#47;</small>}
                              </span>
                            ))
                          )}
                        </p>
                      </TextEllipsis>
                    </div>
                  )}
                  {_.get(employee, 'personnel_history.position_name') && (
                    <TextEllipsis styles={{paddingRight: isApplying ? '10px' : '0px'}}>
                      <p>{recordDisplay(employee.personnel_history.position_name)}</p>
                    </TextEllipsis>
                  )}
                </div>
                <div className={`${styles.cardBodyDate} ${isApplying && 'u-mr20'}`}>
                  入社：{recordDisplay(employee.joined_at && employee.joined_at.replace(/-/g, '/'))}
                </div>
              </div>
            </LinkIfNeeded>
          </div>
        )
      })}
    </div>
  )
}

const List = ({
  token,
  dispatch,
  location,
  user,
  loadTasks,
  employees,
  task,
  confirm,
  taskRunningProps,
  client,
  isOfficeAdmin,
  count,
}) => {
  const hasBranchOffice = useHasBranchOffice()
  const handleImport = async () => {
    await api.createWithAuth(token).employees.payroll.import()
    dispatch(fetchEmployees(getCurrentQueryFromLocation(location, user), additionalParams))
    loadTasks()
  }
  const [deleteMode, setDeleteMode] = useState(false)
  const [listType, setListType] = useEmployeeListType(user.id, 'list')
  const [displayType, setDisplayType] = useDisplayType(user.id, 'default')
  const {history} = useRouter()

  const handleMoveCreate = () => history.push('/employees/create')
  const handleMoveCsvCreate = () => history.push('/employees/import')
  const handleMoveCsvUpdate = () => history.push('/employees/import_update')

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">従業員一覧</h1>
      </div>
      <AsyncTaskError
        task={task}
        confirm={confirm}
        displayTypes={['employee_csv_import', 'employee_csv_update', 'payroll_employee_import', 'yea_employees_import']}
      />
      <SelectList
        isTable={listType === 'list'}
        list={employees}
        selectedInfo={(employee) => ({
          name: (employee.staff_code ? employee.staff_code + ' ' : '') + employee.display_full_name,
        })}
        maxCount={MAX_DELETE_COUNT}
        overMaxCountMessage={`一度に削除できるのは${MAX_DELETE_COUNT}件までです`}
      >
        {({list, th, reset, selected}) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks -- 動くけどrender propで使うのはダメなので修正が望ましい
          useEffect(() => {
            if (!deleteMode) {
              reset()
            }
          }, [deleteMode])
          return (
            <Content size="xl-4">
              <div className={styles.buttonArea}>
                <div>
                  <CsvDownload />
                  <RosterOfEmployees />
                </div>
                <div className={styles.buttonCoordinations}>
                  {!isOfficeAdmin && (
                    <Coordination
                      others={[
                        <Link key="employee" to="/employees/coordination_targets">
                          <Pulldown transform="rotate(-90)" />
                          対象従業員選択（給与計算）
                        </Link>,
                        <Link key="yea" to="/employees/year_end_adj_coordination_targets/?employment_status=all">
                          <Pulldown transform="rotate(-90)" />
                          対象従業員選択（年末調整）
                        </Link>,
                      ]}
                      center
                      onImport={handleImport}
                      confirmUrl="https://jobcan-lms.zendesk.com/hc/ja/articles/360003477351"
                      notice={
                        <>
                          <p>
                            ジョブカン給与計算の「
                            <a
                              href="https://payroll.jobcan.jp/employees/choose_coordination"
                              className="u-txt-link"
                              target="_blank"
                              rel="noreferrer"
                            >
                              対象従業員選択(労務HR)
                            </a>
                            」で選択された従業員が取得されます。
                            <br />
                            従業員情報の更新をする場合は、「
                            <Link to="/employees/coordination_targets" className="u-txt-link">
                              従業員の給与連携設定
                            </Link>
                            」でも選択が必要です。
                          </p>
                          <p className="u-mt20">
                            取得を行うと、全ての
                            <a
                              href="https://jobcan-lms.zendesk.com/hc/ja/articles/360003477351"
                              className="u-txt-link"
                              target="_blank"
                              rel="noreferrer"
                            >
                              連携項目
                            </a>
                            が給与計算の情報で上書きされます。
                            <br />
                            給与計算で空欄の場合、空欄として上書きされますのでご注意ください。
                          </p>
                        </>
                      }
                      isFreePlan={client.plan_type === 'free_plan'}
                      importContent="従業員情報"
                      className="u-mr10"
                    />
                  )}
                  <Button primary widthAuto as={Link} to="/employees/invite">
                    従業員を招待
                  </Button>
                  {taskRunningProps && taskRunningProps.disabled ? (
                    <Button primary widthAuto {...taskRunningProps}>
                      従業員登録
                    </Button>
                  ) : (
                    <Balloon
                      center={true}
                      switchRender={(toggle, isOpen) => (
                        <Button
                          className={classnames(styles.button, {'ignore-react-onclickoutside': isOpen})}
                          primary
                          widthAuto
                          onClick={toggle}
                        >
                          従業員登録
                        </Button>
                      )}
                    >
                      {(hide) => (
                        <Balloon.Ul className={styles.links}>
                          <Balloon.Li>
                            <a
                              onClick={() => {
                                handleMoveCreate()
                                hide()
                              }}
                            >
                              <Pulldown transform="rotate(-90)" />
                              新規登録（直接入力）
                            </a>
                          </Balloon.Li>
                          <Balloon.Li>
                            <a
                              onClick={() => {
                                handleMoveCsvCreate()
                                hide()
                              }}
                            >
                              <Pulldown transform="rotate(-90)" />
                              新規登録（CSVインポート）
                            </a>
                          </Balloon.Li>
                          <Balloon.Li>
                            <a
                              onClick={() => {
                                handleMoveCsvUpdate()
                                hide()
                              }}
                            >
                              <Pulldown transform="rotate(-90)" />
                              更新（CSVインポート）
                            </a>
                          </Balloon.Li>
                        </Balloon.Ul>
                      )}
                    </Balloon>
                  )}
                </div>
              </div>
              <SearchFormWithCardList
                additionalParams={additionalParams}
                listType={listType}
                viewableToDisplayType={listType === 'list'}
                displayType={displayType}
                onChangeListType={setListType}
                onChangeDisplayTypes={setDisplayType}
              />
              <EmployeeList {...{listType, employees, user, hasBranchOffice, deleteMode, list, th, displayType}} />
              <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(location.pathname, user)} />
              {deleteMode ? (
                <div className={styles.bottomButtonsArea}>
                  <BulkDelete
                    selected={selected}
                    onSucess={() => {
                      dispatch(actionCreators.employees.list.destroy())
                      dispatch(fetchEmployees(getCurrentQueryFromLocation(location, user), additionalParams))
                      setDeleteMode(false)
                    }}
                  />
                  <Button onClick={() => setDeleteMode(false)}>キャンセル</Button>
                </div>
              ) : (
                count > 0 && (
                  <div className={styles.bulkDeleteLink}>
                    <a
                      className="u-txt-link"
                      onClick={() => {
                        setDeleteMode(!deleteMode)
                      }}
                    >
                      従業員を一括削除する
                    </a>
                  </div>
                )
              )}
            </Content>
          )
        }}
      </SelectList>
    </div>
  )
}

export default compose(
  connect((state) => ({
    employees: state.employees.list.data,
    count: state.employees.list.count,
    token: state.auth.token,
    query: state.employees.query,
    location: state.router.location,
    user: state.session.currentUser,
    client: state.client.current,
    isOfficeAdmin: isOfficeAdminSelector(state),
  })),
  withAsyncTask
)(List)
