import React from 'react'
import styles from 'employees/show/PartsEmployeeSide.scss'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import _ from 'lodash'
import classNames from 'classnames'

export const Dl = ({children}) => <dl className={styles.dl}>{children}</dl>
export const Dt = ({children, className, width}) => (
  <dt
    className={classNames([styles.dt, styles.EmployeeScreenSideParts, className].filter(_.identity).join(' '))}
    style={width && {width: `${width}%`}}
  >
    {children}
  </dt>
)
export const Dd = ({children, className, width}) => (
  <dd className={[styles.dd, className].filter(_.identity).join(' ')} style={width && {width: `${width}%`}}>
    {children}
  </dd>
)
export const Half = ({children}) => <div className={styles.half}>{children}</div>
export const Table = ({children}) => <table className={styles.infoTable}>{children}</table>
export const Pre = ({children}) => <span className={styles.pre}>{children}</span>

export const SubPanel = ({children}) => <Panel className={styles.subPanel}>{children}</Panel>
SubPanel.Headding = ({children, icon}) => (
  <Headding className={styles.subHeadding} icon={icon}>
    {children}
  </Headding>
)
SubPanel.Item = Item

export const ThirdPanel = ({children}) => <Panel className={styles.thirdPanel}>{children}</Panel>
ThirdPanel.Headding = ({children, icon}) => (
  <Headding className={styles.thirdHeadding} icon={icon}>
    {children}
  </Headding>
)
ThirdPanel.Item = Item

export const Wrap = ({children}) => <div className={styles.flex}>{children}</div>

export const Tag = ({children}) => <div className={styles.tag}>{children}</div>
