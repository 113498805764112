import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {push} from 'connected-react-router'
import {reduxForm, formValueSelector, reset, submit} from 'redux-form'
import {gql} from '@apollo/client'
import {useQuery} from 'components/Graphql'
import api from 'api'
import {actionCreators} from 'actions'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import {recordDisplay} from 'utils'
import {wrapArrowText} from 'tm/employees/Search'
import {parseHtml} from 'tm/notifications/utils'
import PerPageWithResult from 'tm/notifications/list/PerPageWithResult'
import Paginator from 'jbc-front/components/Paginator'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import Button from 'jbc-front/components/Button'
import styles from 'tm/notifications/Confirm.scss'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

import {
  formName as targetFormName,
  getSavedDisplayEmployeeLimit,
  saveDisplayEmployeeLimit,
} from 'tm/employees/list/utils'

const formName = 'tmNotificationCreateForm'
const selector = formValueSelector(formName)

export const NOTIFICATION_TARGET_EMPLOYEE_FRAGMENT = gql`
  fragment EmployeeFields on Employee {
    id
    displayFirstName
    displayLastName
    personnelHistory {
      group0Name
      group1Name
      group2Name
    }
  }
`

const NOTIFICATION_TARGET_EMPLOYEES = gql`
  query notificationTargetEmployees($ids: [ID!]!, $target: String!, $includingMyself: Boolean!, $per: Int, $page: Int) {
    client {
      id
      notificationTargetEmployees(
        ids: $ids
        target: $target
        includingMyself: $includingMyself
        per: $per
        page: $page
      ) {
        totalCount
        list {
          ...EmployeeFields
        }
      }
    }
  }
  ${NOTIFICATION_TARGET_EMPLOYEE_FRAGMENT}
`

const Title = ({notification, tmUser}) => (
  <div>
    {notification.reserved_option == 'reserved' && <div className={styles.reserved}>配信予約中</div>}
    {notification.reserved_option == 'immediate' && <div className={styles.immediate}>即時配信</div>}
    <p>
      <span className={styles.timestamp}>
        {notification.reserved_option == 'immediate' && moment(notification.created_at).format('LLL')}
        {notification.reserved_option == 'reserved' && moment(notification.reserved_at).format('LLL')}
        {notification.reserved_option == 'reserved' && <span>(配信予定)</span>}
      </span>
      <span className={styles.sender}>配信者：{recordDisplay.fullName(tmUser.employee)}</span>
    </p>
    <div className={styles.subjectWrapper}>
      <h2 className={styles.subject}>{notification.subject}</h2>
    </div>
  </div>
)

const Body = ({body, ...rest}) => {
  return (
    <div {...rest}>
      <p style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={parseHtml(body)} />
    </div>
  )
}

const Notification = ({notification, tmUser}) => {
  return (
    <>
      <Title {...{notification, tmUser}} />
      <div className={styles.box}>
        <Body className={styles.body} body={notification.body} />
        {notification?.file?.name && (
          <div className={styles.file}>
            添付ファイル{'　'}
            {notification.file.name}
          </div>
        )}
      </div>
    </>
  )
}

const NotificationAnswer = ({children}) => <div className={styles.answerBox}>{children}</div>

const ButtonArea = ({handleSubmit, submitting, handleBack, target}) => {
  return (
    <div className="u-ta-c u-mt10">
      {target === 'select' ? (
        <span className={styles.button}>
          <Button onClick={() => handleBack('target')}>通知対象選択に戻る</Button>
        </span>
      ) : (
        <span className={styles.button}>
          <Button onClick={() => handleBack('create')}>新規通知作成に戻る</Button>
        </span>
      )}
      <span className={styles.button}>
        <Button primary onClick={handleSubmit} disabled={submitting}>
          配信
        </Button>
      </span>
    </div>
  )
}

const displayFullName = (record) => record && `${record.displayLastName} ${record.displayFirstName}`

const MAX_GROUP_COUNT = 3

const getCurrentGroup = (employee) =>
  employee &&
  employee.personnelHistory &&
  _.range(MAX_GROUP_COUNT)
    .map((index) => employee.personnelHistory[`group${index}Name`])
    .filter(_.identity)

const ListTable = ({targets}) => {
  return (
    <div className="l-overflow-scroll">
      <table className={`m-table-list m-table-fixed`}>
        <thead>
          <tr className={styles.table}>
            <th className={styles.nameColumn}>氏名</th>
            <th className={styles.groupColumn}>グループ</th>
          </tr>
        </thead>
        <tbody>
          {targets.map((employee) => {
            const id = employee.id
            return (
              <tr className={styles.table} key={id}>
                <td className={styles.nameColumn}>{recordDisplay(displayFullName(employee))}</td>
                <td className={styles.groupColumn}>
                  {recordDisplay(
                    !_.isEmpty(getCurrentGroup(employee)) && <span>{wrapArrowText(getCurrentGroup(employee)[0])}</span>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const Form =
  (({
    handleSubmit,
    submitting,
    handleBack,
    targets,
    selectedCount,
    target,
    currentPage,
    setCurrentPage,
    limit,
    handleChangeLimit,
    loading,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <FormErrors />
        <>
          <h4 className={classNames('u-mb10', styles.summaryHeader)}>配信先一覧</h4>
          <PerPageWithResult
            total={selectedCount}
            page={currentPage}
            limit={limit}
            onChangeLimit={handleChangeLimit}
            isLoading={loading}
          />
          {targets && <ListTable targets={targets} />}
        </>
        {selectedCount && (
          <div className="u-mt30">
            <Paginator
              current={currentPage}
              rowsPerPage={limit}
              onClick={(page) => setCurrentPage(page)}
              totalResult={selectedCount}
            />
          </div>
        )}
        <ButtonArea handleSubmit={handleSubmit} submitting={submitting} handleBack={handleBack} target={target} />
      </form>
    )
  })
  |> reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmitFail,
  })

const Confirm = ({handleSetStep, selected, target, including_myself}) => {
  const dispatch = useDispatch()
  const [pathname, token, user, tmUser, notification, notificationOptions] = useSelector((state) => [
    state.router.location.pathname,
    state.auth.token,
    state.session.currentUser,
    state.session.currentTmUser,
    selector(state, 'talent_notification'),
    selector(state, 'talent_notification_options'),
  ])
  const [currentPage, setCurrentPage] = useState(1)
  const limit = getSavedDisplayEmployeeLimit(pathname, user)
  const selectedIds = _.keys(_.pickBy(selected))

  const handleBack = (i) => {
    history.pushState(null, null, null)
    handleSetStep(i)
    window.scrollTo(0, 0)
  }

  const handleChangeLimit = (value) => {
    saveDisplayEmployeeLimit(pathname, user, value)
    setCurrentPage(1)
    dispatch(submit(targetFormName))
  }

  const {data, loading} = useQuery(NOTIFICATION_TARGET_EMPLOYEES, {
    fetchPolicy: 'network-only',
    variables: {
      ids: selectedIds,
      target: target,
      includingMyself: including_myself,
      page: currentPage,
      per: +limit,
    },
  })

  const targets = data?.client?.notificationTargetEmployees.list
  const selectedCount = data?.client?.notificationTargetEmployees.totalCount

  return (
    <>
      <div className="l-breadcrumb">
        <Link to="/tm/notifications/" className="l-breadcrumb-link">
          従業員通知
        </Link>
        <span className="l-breadcrumb-link u-cur-pointer" onClick={() => handleBack('create')}>
          新規通知作成
        </span>
        <span className="l-breadcrumb-here">配信内容確認</span>
      </div>
      <div className={classNames(styles.ConfirmTitleWrap, 'l-title-wrap')}>
        <h1 className="m-title-main">配信内容確認</h1>
      </div>
      {notification && notificationOptions && (
        <>
          <Notification {...{notification, tmUser}} />
          <NotificationAnswer>
            <div className={styles.options}>
              {notificationOptions.map((option, index) => {
                if (option.label) {
                  return (
                    <div key={index} className={styles.option}>
                      {option.label}
                    </div>
                  )
                }
              })}
            </div>
          </NotificationAnswer>
        </>
      )}
      <Form
        {...{
          handleBack,
          targets,
          selectedCount,
          target,
          currentPage,
          setCurrentPage,
          limit,
          handleChangeLimit,
          loading,
        }}
        onSubmit={async () => {
          try {
            notification.including_myself = including_myself
            const values = {
              talent_notification: notification,
              talent_notification_options: notificationOptions,
              talent_notification_answers: {target: target, employee_ids: selectedIds},
            }
            const authedApi = api.createWithAuth(token)
            const {
              data: {id},
            } = await authedApi.tm.notifications.create(values)
            dispatch(push(`/tm/notifications/${id}`))
            dispatch(notifySuccess('配信しました'))
            dispatch(reset(formName))
            dispatch(actionCreators.employees.clearSelected())
          } catch (err) {
            dispatch(asyncError(err))
          }
        }}
      />
    </>
  )
}

export default Confirm
