import React, {useState} from 'react'
import {Section, CheckboxField} from 'jbc-front/components/Form'
import connectForm from 'reportForms/connectForm'
import {formValueSelector, reduxForm} from 'redux-form'
import {Notes, SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R5Report'
const selector = formValueSelector(formName)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({handleSubmit, submitting, submitText, employee = {}, procedureStatus, needUpgrade}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">被扶養者（異動）届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <Notes />
          <SubmitedOn />
          {['enroll', 'add_dependents'].includes(procedureStatus.procedure_type) && (
            <React.Fragment>
              <p className="u-mt30 u-mb20">
                出力・ダウンロード前に「マイナンバー出力」ボタンを押すか、印刷後に手でマイナンバー（個人番号）を記入してください。
                <br />
                この帳票にマイナンバーを出力・記入しない場合は、以下にチェックを入れて雇用保険受給の有無ください。その場合、
                <strong>被扶養者異動届提出後1か月以内</strong>に、個人番号届等でマイナンバーの提出が必要になります。
              </p>
              <CheckboxField name="submit_my_number" label="1カ月以内にマイナンバーの提出をします。" />
            </React.Fragment>
          )}
          <div className="u-ta-c u-mt30">
            <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => handleSubmit()}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})

const connectedForm = connectForm('r5', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee,
    procedureStatus: state.procedureStatuses.current.data,
    needUpgrade: selector(state, 'need_upgrade'),
  }),
})

export default connectedForm
