import {actionCreators} from 'actions'
import {connectPager} from 'components/paginators'
import {connectPerPage} from 'components/PerPage'
import connectListData from 'components/ListData'
import {getQueryDiff} from 'settings/auditLogs/reducers'
import api from 'api'

const querySelector = (state) => state.settings.auditLogs.query
const queryActionCreator = actionCreators.settings.auditLogs.setQuery

export const Pager = connectPager(querySelector, queryActionCreator)
export const PerPage = connectPerPage(querySelector, queryActionCreator)
export const loadList = (token, query, embed, params) => api.createWithAuth(token).auditLogs.list(query, embed, params)
export const ListData = connectListData(querySelector, actionCreators.settings.auditLogs, loadList, getQueryDiff)
