import {Component} from 'react'
import axios from 'axios'
import {replace} from 'connected-react-router'
import {connect} from 'react-redux'
import {notifySuccess} from 'store/actions/notify'

class JbcIdTmLogin extends Component {
  async componentDidMount() {
    const host = location.hostname === 'localhost' ? 'http://localhost:2500' : `${location.protocol}://${location.host}`
    const redirect_uri = `${host}/tm/api/jbc-oauth/callback`
    const {dispatch} = this.props
    try {
      const {data} = await axios.get('/tm/api/me')
      if (data.access_token) {
        dispatch(replace('/tm/employees'))
        dispatch(notifySuccess('すでにログイン済みです。従業員検索画面へ移動しました。'))
      } else {
        window.location = `${process.env.JBC_ID_SERVER}/users/sign_in?app_key=tm&redirect_to=${redirect_uri}`
      }
    } catch (err) {
      window.location = `${process.env.JBC_ID_SERVER}/users/sign_in?app_key=tm&redirect_to=${redirect_uri}`
    }
  }
  render() {
    return null
  }
}

export default connect()(JbcIdTmLogin)
