import React from 'react'
import {connect} from 'react-redux'
import japanese from 'japanese'
import {useDispatch} from 'react-redux'
import {autofill, FormName, FormSection} from 'redux-form'
import moment from 'moment'
import {getDiffFromOptions, getDiffFromCheckbox, dependentDefaultValue} from 'employees/form/common'
import {LabelMapper} from 'jbc-front/components/FormErrors'

import {
  BoxDouble,
  TextField,
  TextFieldWithKana,
  RadioField,
  DateField,
  CheckboxField,
  FileField,
  Section,
} from 'jbc-front/components/Form'
import {withPrefix} from 'FormFields'

import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'

import * as validators from 'validators'

export const ExpectedBirthDate = ({withRelatedProcedure = false, maternityDivision, startedAt, expectedFinishedAt}) => {
  const dispatch = useDispatch()
  const onChange = (form) => (value) => {
    if (!maternityDivision || !moment.isMoment(value)) {
      return
    }
    if (!startedAt) {
      const subDuration = maternityDivision === 'single' ? 41 : 97
      dispatch(autofill(form, 'started_at', value.clone().subtract(subDuration, 'days').format('L')))
    }
    if (!expectedFinishedAt) {
      dispatch(autofill(form, 'expected_finished_at', value.clone().add(56, 'days').format('L')))
    }
  }
  return (
    <FormName>
      {({form}) => (
        <DateField
          onChange={onChange(form)}
          name="expected_birth_date"
          label="出産予定年月日"
          {...{[withRelatedProcedure ? 'disabled' : 'required']: true}}
        />
      )}
    </FormName>
  )
}

export const maternityDivisionOptions = [
  {value: 'single', label: '単胎'},
  {value: 'multiple', label: '多胎'},
]

export const MaternityDivision = ({
  diff = {},
  withRelatedProcedure = false,
  expectedBirthDate,
  startedAt,
  expectedFinishedAt,
}) => {
  const dispatch = useDispatch()
  const onChange =
    (form) =>
    ({target: {value: maternityDivision}}) => {
      const date = moment(expectedBirthDate, 'YYYY/MM/DD')
      if (!date.isValid()) {
        return
      }
      const changeFrom = date.clone().subtract(maternityDivision === 'single' ? 97 : 41, 'days')
      const changeTo = date.clone().subtract(maternityDivision === 'single' ? 41 : 97, 'days')
      if (!startedAt || moment(startedAt, 'YYYY/MM/DD').isSame(changeFrom)) {
        dispatch(autofill(form, 'started_at', changeTo.format('L')))
      }
      if (!expectedFinishedAt) {
        dispatch(autofill(form, 'expected_finished_at', date.clone().add(56, 'days').format('L')))
      }
    }
  return (
    <FormName>
      {({form}) => (
        <RadioField
          name="maternity_division"
          label="出産種別"
          onChange={onChange(form)}
          options={maternityDivisionOptions}
          diff={getDiffFromOptions(maternityDivisionOptions, diff?.maternity_division)}
          {...{[withRelatedProcedure ? 'disabled' : 'required']: true}}
        />
      )}
    </FormName>
  )
}

export const StartedAt = ({withRelatedProcedure = false}) => (
  <DateField
    name="started_at"
    label="産前産後休業等開始年月日"
    {...{[withRelatedProcedure ? 'disabled' : 'required']: true}}
  />
)

export const ExpectedFinishedAt = ({withRelatedProcedure = false}) => (
  <DateField
    name="expected_finished_at"
    label="産前産後休業等終了予定年月日"
    {...{[withRelatedProcedure ? 'disabled' : 'required']: true}}
  />
)

export const maternityStatusOptions = [
  {value: 'before', label: '出産前'},
  {value: 'after', label: '出産後'},
  {value: 'other', label: 'その他'},
]

export const MaternityStatus = ({diff = {}}) => (
  <RadioField
    name="maternity_status"
    label="出産状況"
    options={maternityStatusOptions}
    diff={getDiffFromOptions(maternityStatusOptions, diff?.maternity_status)}
    required
  />
)

export const MaternityName = ({required, isCommonName, isKanji, prefix = '', noHankaku, diff}) => {
  const labelKanji = isKanji ? '（漢字）' : ''
  const labelCommonName = isCommonName ? '通称' : ''
  const dispatch = useDispatch()
  return (
    <FormName>
      {({form, sectionPrefix}) => (
        <BoxDouble>
          <TextFieldWithKana
            name={`${prefix}last_name`}
            label={`${labelCommonName}出生児の姓${labelKanji}`}
            type="text"
            onUpdateKana={(kana) => {
              dispatch(autofill(form, withPrefix(sectionPrefix, `${prefix}last_name_kana`), japanese.katakanize(kana)))
            }}
            required={required}
            validate={(noHankaku && validators.noHankaku) || validators.maxLength(32)}
            diff={diff[`${prefix}childcare_last_name`]}
          />
          <TextFieldWithKana
            name={`${prefix}first_name`}
            label={`${labelCommonName}出生児の名${labelKanji}`}
            type="text"
            onUpdateKana={(kana) => {
              dispatch(autofill(form, withPrefix(sectionPrefix, `${prefix}first_name_kana`), japanese.katakanize(kana)))
            }}
            validate={(noHankaku && validators.noHankaku) || validators.maxLength(32)}
            required={required}
            diff={diff[`${prefix}first_name`]}
          />

          <TextField
            name={`${prefix}last_name_kana`}
            label={`${labelCommonName}出生児の姓(カナ)`}
            validate={validators.zenkakuKatakanaWithSpace}
            required={required}
            diff={diff[`${prefix}last_name_kana`]}
          />
          <TextField
            name={`${prefix}first_name_kana`}
            label={`${labelCommonName}出生児の名(カナ)`}
            validate={validators.zenkakuKatakanaWithSpace}
            required={required}
            diff={diff[`${prefix}first_name_kana`]}
          />
        </BoxDouble>
      )}
    </FormName>
  )
}
MaternityName.defaultProps = {
  diff: {},
}

export const BirthDate = () => <DateField name="birth_date" label="出産年月日" required />

export const AddSupport = ({diff = {}, skipAddSupport = false}) => {
  if (skipAddSupport) {
    return <div>すでに社会保険の被扶養者に追加されています</div>
  }
  return (
    <CheckboxField
      name="add_support"
      label="社会保険の被扶養者に追加する"
      diff={getDiffFromCheckbox(diff.add_support)}
    />
  )
}

export const reasonOfAbortionOptions = [
  {value: 'stillbirth', label: '死産'},
  {value: 'abortion', label: '流産'},
  {value: 'induced_abortion', label: '人工妊娠中絶'},
]

export const ReasonOfAbortion = ({diff = {}}) => (
  <RadioField
    name="reason_of_abortion"
    label="出産できなかった理由"
    options={reasonOfAbortionOptions}
    diff={getDiffFromOptions(reasonOfAbortionOptions, diff?.reason_of_abortion)}
    required
  />
)

export const DateOfAbortion = () => <DateField name="date_of_abortion" label="理由該当年月日" required />

export const MotherChildNote = () => (
  <div>
    <LabelMapper name="mother_child_note" label="ファイル添付欄" />
    <FileField name="mother_child_note" label="ファイル添付欄" multiple />
  </div>
)

const renderChildren = ({setNoDependent, fields, index, name, procedureType, canDelete, skipAddSupport}) => (
  <li>
    <Section
      title={`出生児 (${index + 1})`}
      icon={
        canDelete &&
        (fields.length > 1 || typeof setNoDependent === 'function') && (
          <DeleteSquare
            onClick={() => {
              if (fields.length === 1) {
                fields.push(dependentDefaultValue(procedureType))
                setNoDependent()
              }
              fields.remove(index)
            }}
          />
        )
      }
    >
      <FormSection name={name}>
        <MaternityName />
        <BirthDate required />
        <AddSupport skipAddSupport={skipAddSupport} />
      </FormSection>
    </Section>
  </li>
)

const Children = connect((state, {index, selector}) => ({
  skipAddSupport: selector(state, `procedure_maternity_child_data[${index}].skip_add_support`),
}))(renderChildren)

export const renderChildInfo = ({fields, procedureType, canDelete, canAdd, selector, autofill, diff = []}) => (
  <div>
    <LabelMapper name="procedure_maternity_child_data" label="出生児情報の" />
    <ul>
      {fields.map((name, index) => (
        <Children
          diff={diff[index]}
          {...{
            index,
            name,
            fields,
            procedureType,
            canDelete,
            selector,
            autofill,
          }}
          key={index}
        />
      ))}
    </ul>
    {canAdd && (
      <div className="u-ta-r u-mt20 u-mb20">
        <div onClick={() => fields.push(dependentDefaultValue(procedureType))} className="u-txt-addinfo">
          <PlusSquare size={20} className="u-txt-addinfo-icon" />
          出生児を追加
        </div>
      </div>
    )}
  </div>
)
