import React, {useState, useEffect} from 'react'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import Loading from 'components/Loading'
import {useSelector, useDispatch} from 'react-redux'
import {parse} from 'query-string'

const YearEndAdjStartRequest = ({location: {search}}) => {
  const [{error, loading}, setState] = useState({error: false, loading: true})
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const {request_token} = parse(search)

  useEffect(() => {
    ;(async () => {
      try {
        const {
          data: {link},
        } = await api.createWithAuth(token).YearEndAdj.startRequest(request_token)
        window.location = link
      } catch (err) {
        dispatch(asyncError(err))
        setState({loading: false, error: true})
      }
    })()
  }, [])

  if (loading || error) {
    return (
      <div className="l-wrap-xs u-pt100">
        <div className="l-box-message">{loading ? <Loading /> : 'エラーが発生しました'}</div>
      </div>
    )
  }
  return null
}

export default YearEndAdjStartRequest
