import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {formMap} from 'procedureStatuses/ReportEdit'
import CommentModal from 'procedures/CommentModal'
import {parse} from 'query-string'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class ConfirmDependents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Report: null,
      initialValues: {},
      dependentId: null,
      formIndex: -1,
    }
  }

  componentDidMount() {
    const {
      loadProcedureStatus,
      loadTmpData,
      loadComments,
      token,
      match: {
        params: {id},
      },
    } = this.props
    loadProcedureStatus(id, token)
    loadTmpData(id, token)
    loadComments(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  componentDidUpdate() {
    const {
      location: {search},
    } = this.props
    const {dependent_index} = parse(search)
    const dependentIndex = dependent_index | 0
    if (+dependentIndex !== this.state.formIndex) {
      this.setState({formIndex: dependentIndex})
    }
  }

  afterSubmit = () => {
    const {
      nextOrAccept,
      token,
      match: {
        params: {id},
      },
      location: {search},
    } = this.props
    const query = parse(search)
    const {dependent, isFinal, reportType} = this.state
    const dependentIndex = query.dependent_index | 0
    return nextOrAccept({
      token,
      procedureStatusId: id,
      dependentId: dependent.id,
      isFinal,
      reportType,
      dependentIndex,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!nextProps.loading && this.props.loading) ||
      (!this.props.loading && nextProps.location !== this.props.location)
    ) {
      const {
        tmpData,
        location: {search},
        match: {
          params: {id},
        },
        notifyError,
        procedureStatus,
      } = nextProps
      const query = parse(search)
      const dependentIndex = query.dependent_index | 0
      const dependents = JSON.parse((tmpData.dependents && tmpData.dependents.data) || '{}')
      if (procedureStatus.status === 'draft') {
        this.props.history.replace(`/enroll/flow/${id}`)
      } else if (procedureStatus.status !== 'applying') {
        this.props.history.replace(`/procedure_statuses/${id}`)
      }
      if (_.isEmpty(dependents)) {
        this.props.history.replace(`/enroll/confirm/${id}`)
        notifyError('扶養の情報がありません')
        return
      }
      const dependent = dependents[dependentIndex]
      if (!dependent) {
        this.props.history.replace(`/enroll/confirm/${id}`)
        notifyError('扶養の情報がありません')
        return
      }
      const reportType = 'R150'
      const Report = formMap[reportType]
      const dependentInfo = tmpData[`dependent_info_${dependentIndex}`]
      if (!dependentInfo) {
        notifyError('従業員からの情報がありません')
        this.setState({
          Report,
          dependent,
          isFinal: dependentIndex + 1 >= dependents.length,
          reportType,
        })
        return
      }
      const values = JSON.parse(dependentInfo.data) || {}
      this.setState({
        Report,
        initialValues: {...values, employee_dependent_id: dependent.id},
        dependent,
        isFinal: dependentIndex + 1 >= dependents.length,
        reportType,
      })
      if (values.reportType !== reportType) {
        notifyError('現況表の種類と扶養の情報が一致しません')
        return
      }
    }
  }

  render() {
    const {Report, initialValues, dependent} = this.state
    const {
      match: {
        params: {id},
      },
      location: {search},
      loading,
      comments,
    } = this.props
    const query = parse(search)
    const dependentIndex = query.dependent_index | 0
    if (dependentIndex !== this.state.formIndex) return null
    return !loading && Report ? (
      <div key={dependentIndex}>
        <CommentModal procedureStatusId={id} comments={comments || []} procedureType="enroll">
          {({showModal}) => (
            <Report
              procedureStatusId={id}
              initialValues={initialValues}
              submitText="次へ"
              dependentId={dependent.id}
              afterSubmit={this.afterSubmit}
              name={`${dependent.last_name} ${dependent.first_name}`}
              relation_type={dependent.relation_type}
              relation_other={dependent.relation_other}
              comments={comments || {}}
              otherButtons={
                <Button onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
            />
          )}
        </CommentModal>
      </div>
    ) : null
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    data: state.procedureStatuses.current.data.employee,
    tmpData: state.procedureStatuses.tmpData.data,
    comments: fetchSelector(state, 'procedure_comments').data,
    loading: state.procedureStatuses.current.loading || state.procedureStatuses.tmpData.loading,
    procedureStatus: state.procedureStatuses.current.data,
  }),
  (dispatch) => ({
    loadProcedureStatus(id, token) {
      dispatch(actionCreators.procedureStatuses.current.fetchData(api.createWithAuth(token).procedureStatuses.get(id)))
    },
    loadTmpData(id, token) {
      dispatch(
        actionCreators.procedureStatuses.tmpData.fetchData(
          api.createWithAuth(token).procedureStatuses.tmpData.mapByName(id)
        )
      )
    },
    loadComments: (procedureStatusId, token) => {
      dispatch(
        actionCreators.fetchData(
          'procedure_comments',
          api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
        )
      )
    },
    destroy() {
      dispatch(actionCreators.procedureStatuses.current.destroy())
      dispatch(actionCreators.procedureStatuses.tmpData.destroy())
    },
    async nextOrAccept({token, procedureStatusId, dependentId, isFinal, reportType, dependentIndex}) {
      try {
        const {
          data: {flow_status: flowStatus},
        } = await api
          .createWithAuth(token)
          .procedureStatuses.tmpData.mapByName(procedureStatusId, 'draft', 'flow_status')
        const status = _.get(JSON.parse((flowStatus && flowStatus.data) || '{}'), 'status')
        const current = reportType === 'R150' ? `${reportType}_${dependentId}` : reportType
        await api.createWithAuth(token).procedureStatuses.tmpData.createOrUpdate(
          procedureStatusId,
          {
            detailFinished: true,
            status: {
              ...status,
              [current]: true,
            },
          },
          'draft',
          'flow_status'
        )
        if (isFinal) {
          await api.createWithAuth(token).procedureStatuses.accept(procedureStatusId)
          dispatch(push(`/enroll/flow/${procedureStatusId}`))
        } else {
          dispatch(push(`/enroll/confirm_dependents/${procedureStatusId}?dependent_index=${dependentIndex + 1}`))
        }
        dispatch(notifySuccess('保存しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    notifyError(msg) {
      dispatch(notifyError(msg))
    },
  })
)(ConfirmDependents)
