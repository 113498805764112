import React, {FC, useState, useEffect} from 'react'
import Modal from 'react-modal'
import {Close, Drugging} from 'jbc-front/components/icons'
import styles from 'settings/employeeCustom/FieldGroups/SortFieldGroups.scss'
import Button from 'jbc-front/components/Button'
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc'
import type {CustomEmployeeFieldGroup} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldGroup'
import type {EmployeeFieldGroup} from 'types/api/settings/employeeFieldGroups/employeeFieldGroup'

interface SortFieldGroupsProps {
  isOpen: boolean
  fieldGroupsAll: (EmployeeFieldGroup | CustomEmployeeFieldGroup)[]
  hideModal: () => void
  handleSubmit: (values: (EmployeeFieldGroup | CustomEmployeeFieldGroup)[]) => Promise<void>
}

const SortableItem = SortableElement(({fieldGroup}: {fieldGroup: EmployeeFieldGroup | CustomEmployeeFieldGroup}) => (
  <div className={styles.item}>
    <span className={styles.handle}>
      <Drugging />
    </span>
    {fieldGroup.label}
  </div>
))

const SortableList = SortableContainer(
  ({fieldGroups}: {fieldGroups: (EmployeeFieldGroup | CustomEmployeeFieldGroup)[]}) => (
    <div className={styles.list}>
      {fieldGroups.map((fieldGroup, index) => (
        <SortableItem key={`item-${index}`} index={index} fieldGroup={fieldGroup} />
      ))}
    </div>
  )
)

export const SortFieldGroups: FC<SortFieldGroupsProps> = (props) => {
  const {isOpen, fieldGroupsAll, hideModal, handleSubmit} = props
  const [fieldGroups, setFieldGroups] = useState<(EmployeeFieldGroup | CustomEmployeeFieldGroup)[]>(fieldGroupsAll)
  const [dragging, setDragging] = useState<boolean>(false)

  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflow = 'hidden'
      setFieldGroups(props.fieldGroupsAll)
    } else {
      document.body.style.overflow = ''
    }
  }, [props.isOpen])

  useEffect(() => {
    setFieldGroups(props.fieldGroupsAll)
  }, [props.fieldGroupsAll])

  useEffect(() => {
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  const handleSubmitDescription = () => {
    handleSubmit(fieldGroups.map((fieldGroup, index) => ({...fieldGroup, position: index + 1})))
  }

  const onSortEnd = ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => {
    setFieldGroups(arrayMove(fieldGroups, oldIndex, newIndex))
    setDragging(false)
  }

  return (
    <Modal className={styles.modal} isOpen={isOpen} overlayClassName={styles.overlay} contentLabel="Modal">
      <div className={styles.header}>
        <div className={styles.title}>表示項目の順番を変える</div>
        <div className={styles.tools}>
          <Close className={styles.close} onClick={() => hideModal()} />
        </div>
      </div>
      {isOpen && (
        <div className={dragging ? styles.bodyDragging : styles.body}>
          ドラッグアンドドロップで順番の入れ替えができます
          <SortableList
            lockAxis="y"
            transitionDuration={0}
            lockToContainerEdges
            helperClass={styles.itemDraged}
            fieldGroups={fieldGroups}
            onSortEnd={onSortEnd}
            onSortStart={() => setDragging(true)}
          />
        </div>
      )}
      <div className={styles.closeBottom}>
        <Button onClick={hideModal} key="cancel">
          キャンセル
        </Button>
        <Button primary onClick={handleSubmitDescription} key="save">
          保存する
        </Button>
      </div>
    </Modal>
  )
}
