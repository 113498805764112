import React, {Component} from 'react'
import {connect} from 'react-redux'

import styles from 'components/Tabs.scss'

export const Tab = ({children, active = false, disabled = false, as: As = 'div', ...rest}) => (
  <As className={disabled ? styles.typeTabDisabled : active ? styles.typeTabActive : styles.typeAreaTab} {...rest}>
    {children}
  </As>
)

export class FilterTab extends Component {
  handleClick = () => {
    const {query, column, filter, setQuery} = this.props
    setQuery({...query, [column]: filter, page: 1})
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {query, column, filter, label, setQuery, ...rest} = this.props
    /* eslint-enable no-unused-vars */
    const current = query[column]
    return (
      <div {...rest}>
        {(filter == current && <Tab active>{label}</Tab>) || <Tab onClick={this.handleClick}>{label}</Tab>}
      </div>
    )
  }
}

export const connectFilterTab = (selector, actionCreator) =>
  connect(
    (state) => ({
      query: selector(state),
    }),
    (dispatch) => ({
      setQuery: (query) => dispatch(actionCreator(query)),
    })
  )(FilterTab)
