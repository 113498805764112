import React, {Component} from 'react'
import Form, {makeInitialValuesForUpdate, formatValues} from 'procedures/changeAddress/Form'
import Master from 'Master'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {updateEmployeeDetail} from 'employees/Update'
import _ from 'lodash'
import {asyncError} from 'store/actions/asyncError'

class Update extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      loadProcedureStatus,
      token,
    } = this.props
    loadProcedureStatus(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      procedureStatus: {employee, ...procedureStatus},
      healthInsuranceType,
    } = this.props
    return (
      <>
        <Master masters={['nations']} />
        <Form
          onSubmit={this.handleSubmit}
          initialValues={makeInitialValuesForUpdate(procedureStatus, employee, healthInsuranceType)}
          submitText="保存"
          employee={employee || {}}
          residentCardUpdatedAt={
            procedureStatus.procedure_change_address_datum
              ? procedureStatus.procedure_change_address_datum.resident_card_updated_at
              : null
          }
          healthInsuranceType={healthInsuranceType}
        />
      </>
    )
  }

  handleSubmit = (values) => {
    const {update, token, procedureStatus, healthInsuranceType, nations} = this.props
    return update(procedureStatus, values, token, healthInsuranceType, nations)
  }
}

export default connect(
  (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
    token: state.auth.token,
    nations: state.master.nations,
  }),
  (dispatch) => ({
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api
            .createWithAuth(token)
            .procedureStatuses.get(id, [
              'employee',
              'employee_dependents',
              'office',
              'procedure_change_address_datum',
              'commuting_expenses',
            ])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.procedureStatuses.current.destroy())
    },
    update: async (procedureStatus, values, token, healthInsuranceType, nations) => {
      const authedApi = api.createWithAuth(token)
      try {
        const {employee, procedureData, residentCard, commutingExpenses, dependentPrecedureData, dependents, dateOn} =
          formatValues(values, healthInsuranceType, nations)
        await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: dateOn})
        await authedApi.procedureStatuses.changeAddressData.update(procedureStatus.id, procedureData)
        if (dependentPrecedureData) {
          await authedApi.procedureStatuses.changeAddressData.depData.updateAll(
            procedureStatus.id,
            dependentPrecedureData
          )
        }
        await updateEmployeeDetail(
          procedureStatus.employee_id,
          {employee, commutingExpenses, dependents, residentCard},
          token,
          ['residence_card'],
          null,
          {dependentsUpdateOnly: true}
        )
        dispatch(push(`/procedure_statuses/${procedureStatus.id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Update)
