import {useEffect} from 'react'
import {QueryHookOptions, useQuery as useQueryOrigin} from '@apollo/client'
import {QueryResult, OperationVariables} from '@apollo/client'
import {useDispatch} from 'react-redux'
import {handleQueryError} from 'libs/graphql'

export const useQuery = <TData = any, TVariables = OperationVariables>(
  query: any,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> => {
  const dispatch = useDispatch()
  const queryResult = useQueryOrigin(query, options)

  useEffect(() => {
    if (queryResult.error) {
      handleQueryError(queryResult.error, dispatch)
    }
  }, [dispatch, queryResult.error])

  return queryResult
}
