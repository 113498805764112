import {Component} from 'react'
import {queryEqual} from 'utils'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {parse, stringify} from 'query-string'
import {push} from 'connected-react-router'
import {getSavedDisplayEmployeeLimit, saveDisplayEmployeeLimit} from 'employees/list/utils'

class ListData extends Component {
  loadData = () => {
    const {loadData, token, query, embed, params} = this.props
    loadData(token, query, embed, params)
  }

  componentDidMount() {
    const {pathname, search} = this.props.location
    const {user} = this.props
    const limit = getSavedDisplayEmployeeLimit(pathname, user)
    this.props.setQuery({per_page: limit, ...parse(search.substr(1))})
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query !== this.props.query) {
      const query = this.props.getQueryDiff(nextProps.query)
      const limit = getSavedDisplayEmployeeLimit(nextProps.location.pathname, nextProps.user)
      if (!queryEqual(query, {per_page: limit, ...parse(this.props.location.search)})) {
        nextProps.history.push(this.props.location.pathname + '?' + stringify(query))
      }
      const {loadData, token, embed, params} = nextProps
      loadData(token, nextProps.query, embed, params)
    }
    if (nextProps.location.search !== this.props.location.search) {
      const query = this.props.getQueryDiff(nextProps.query)
      const limit = getSavedDisplayEmployeeLimit(nextProps.location.pathname, nextProps.user)
      const nextQuery = {per_page: limit, ...parse(nextProps.location.search)}
      if (!queryEqual(nextQuery, query)) {
        this.props.setQuery(nextQuery)
      }
    }
    if (nextProps.query.per_page != this.props.query.per_page) {
      saveDisplayEmployeeLimit(nextProps.location.pathname, nextProps.user, nextProps.query.per_page)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    return null
  }
}

export const makeListData = ({querySelector, setQuery, loadData, destroy, getQueryDiff}) =>
  connect(
    (state) => ({
      query: querySelector(state),
      token: state.auth.token,
      location: state.router.location,
      user: state.session.currentUser,
    }),
    (dispatch) => ({
      loadData: (token, query, embed, params) => {
        dispatch(loadData({token, query, embed, params}))
      },
      setQuery: (query) => {
        dispatch(setQuery(query))
      },
      destroy: () => {
        dispatch(destroy())
      },
      history: {
        push(url) {
          dispatch(push(url))
        },
      },
      getQueryDiff,
    }),
    null,
    {forwardRef: true}
  )(ListData)

export default (querySelector, actionCreators, loadData, getQueryDiff) =>
  withRouter(
    connect(
      (state) => ({
        query: querySelector(state),
        token: state.auth.token,
        user: state.session.currentUser,
      }),
      (dispatch) => ({
        loadData: (token, query, embed, params) => {
          dispatch(actionCreators.list.fetchData(loadData(token, query, embed, params)))
        },
        setQuery: (query) => {
          dispatch(actionCreators.setQuery(query))
        },
        destroy: () => {
          dispatch(actionCreators.list.destroy())
        },
        getQueryDiff,
      }),
      null,
      {forwardRef: true}
    )(ListData)
  )
