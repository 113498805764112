import React from 'react'
import styles from 'tm/employees/ShowMoreBtn.scss'
import {Pulldown} from 'jbc-front/components/icons'

const ShowMoreBtn = ({isExpand, onToggleClick}) => {
  const text = isExpand ? '閉じる' : 'もっと見る'
  return (
    <div className={styles.btnarea} onClick={onToggleClick}>
      <button className={isExpand ? styles.btnOpend : styles.btn}>
        {text}
        <Pulldown size={14} transform={isExpand ? 'rotate(180)' : 'rotate(0)'} />
      </button>
    </div>
  )
}

export default ShowMoreBtn
