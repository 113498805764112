import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import pascalcase from 'pascalcase'
import _ from 'lodash'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {Card, Container} from 'components/SelectIconCard'
import styles from 'settings/employeeCustom/FieldGroups/index.scss'
import {toColor} from 'settings/employeeCustom/ColorSelect'
import * as icons from 'jbc-front/components/icons'
import Button from 'jbc-front/components/Button'
import {asyncError} from 'store/actions/asyncError'

const icon_names = [
  'Custom2',
  'AffiliationHistory',
  'Custom3',
  'PersonalInformation',
  'Affiliation',
  'Custom57',
  'Project',
  'Custom8',
  'Custom54',
  'WorkHistory',
  'Custom35',
  'Custom38',
  'Custom18',
  'Custom17',
  'Custom53',
  'Custom14',
  'Custom40',
  'Custom48',
  'Custom22',
  'Custom51',
  'Skill',
  'EducationBackground',
  'Qualification',
  'Custom42',
  'Language',
  'Custom19',
  'Custom26',
  'Custom49',
]

const icon_colors = [0x3498db, 0xff6363, 0x9b59b6, 0x01b09c, 0x666666]

const EmployeeFieldGroups = ({
  handleChange,
  talentDisplaySettings,
  iconSelected,
  iconSelect,
  iconColorSelected,
  iconColorSelect,
  onSubmit,
  talentDisplaySettingsCount,
  changeFieldGroup,
  handleChangeCancel,
}) =>
  _.orderBy(talentDisplaySettings, ['key', 'position'], ['desc', 'asc']).map((talentGroup) => {
    const iconName = _.isNil(talentGroup.icon_name) ? pascalcase(talentGroup.code) : talentGroup.icon_name

    const IconName = _.isNil(talentGroup.icon_name) ? icons[pascalcase(talentGroup.code)] : icons[talentGroup.icon_name]

    const iconColor = _.isNil(talentGroup.icon_color) ? 0x3498db : talentGroup.icon_color

    const closeWithClickOutSideMethod = (e) => {
      if (e.target === e.currentTarget) {
        handleChangeCancel()
      }
    }
    return (
      IconName && (
        <div className={styles.card}>
          <Card
            key={talentGroup.lavel}
            icon={IconName}
            color={toColor(iconColor)}
            onClick={() => {
              handleChange(talentGroup, iconName, iconColor)
            }}
            title={talentGroup.label}
            isOpenIconsBox={changeFieldGroup && changeFieldGroup.id === talentGroup.id}
          >
            編集
          </Card>
          {changeFieldGroup && changeFieldGroup.id === talentGroup.id && changeFieldGroup.key === talentGroup.key && (
            <div className={styles.card_hidden}>
              <div
                className={styles.other_area}
                onClick={(e) => {
                  closeWithClickOutSideMethod(e)
                }}
              />
              <div
                className={
                  talentGroup.key === 'talent_display_setting'
                    ? styles.icon_box
                    : talentDisplaySettingsCount === 0
                    ? styles.icon_box
                    : styles.icon_box_static
                }
              >
                <div className={styles.icon_box_title}>アイコンを選択してください</div>
                <div className={styles.icons}>
                  {icon_names.map((num) => {
                    const name = num
                    const Icon = icons[name]
                    return (
                      <Icon
                        key={name}
                        size={50}
                        className={iconSelected === name ? styles.modal_icon_selected : styles.modal_icon}
                        onClick={() => iconSelect(talentGroup, name)}
                      />
                    )
                  })}
                </div>
                <div className={styles.icon_box_title}>アイコンの色を選択してください</div>
                <div className={styles.icon_colors}>
                  {icon_colors.map((color) => {
                    const style = {
                      background: toColor(color),
                    }
                    return (
                      <div
                        key={color}
                        style={style}
                        className={iconColorSelected === color ? styles.icon_color_selected : styles.icon_color}
                        onClick={() => iconColorSelect(talentGroup, color)}
                      />
                    )
                  })}
                </div>
                <div className={styles.icon_buttons}>
                  <Button
                    onClick={() => {
                      handleChangeCancel()
                    }}
                    className={styles.cancel_button}
                  >
                    キャンセル
                  </Button>
                  <Button
                    className={styles.submit_button}
                    primary
                    onClick={() => {
                      onSubmit()
                      handleChangeCancel()
                    }}
                  >
                    保存
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      )
    )
  })

class CustomFieldGroupIcons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      changeFieldGroup: null,
      iconSelected: null,
    }
  }

  static defaultProps = {
    talentDisplaySettings: [],
  }

  loadData = () => {
    const {token, loadFieldGroups, client} = this.props
    loadFieldGroups(client, token)
  }

  componentDidMount() {
    this.loadData()
  }

  handleChangeModalSubmit = () => {
    const {client, token, changeFieldGroup} = this.props
    changeFieldGroup(client, this.state.changeFieldGroup, token, {
      icon_name: this.state.iconSelected,
      icon_color: this.state.iconColorSelected,
      key: this.state.changeFieldGroup.key,
    }).then(() => {
      this.loadData()
      this.setState({changeFieldGroup: null})
    })
  }

  handleChange = (fieldGroup, iconName, iconColor) => {
    this.setState({
      iconSelected: iconName,
      iconColorSelected: iconColor,
      changeFieldGroup: fieldGroup,
    })
  }

  iconSelect = (fieldGroup, icon_name) => {
    this.setState({
      iconSelected: icon_name,
      changeFieldGroup: fieldGroup,
    })
  }

  iconColorSelect = (fieldGroup, color) => {
    this.setState({
      iconColorSelected: color,
      changeFieldGroup: fieldGroup,
    })
  }

  render() {
    const {talentDisplaySettings} = this.props
    const talentDisplaySettingsCount = talentDisplaySettings.filter(
      (talentDisplaySetting) => (talentDisplaySetting.key === 'talent_display_setting') & talentDisplaySetting.visible
    ).length
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">アイコン設定</h1>
          <p className="m-title-main-note">設定したアイコンは人材管理の従業員詳細画面に反映されます</p>
        </div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <div className="u-mb30">
              <Link to="/settings" className="l-breadcrumb-link u-mb20">
                設定
              </Link>
              <span className="l-breadcrumb-here u-mb20">アイコン設定</span>
            </div>
            <Container>
              <EmployeeFieldGroups
                handleChange={this.handleChange}
                iconSelected={this.state.iconSelected}
                iconSelect={this.iconSelect}
                iconColorSelected={this.state.iconColorSelected}
                iconColorSelect={this.iconColorSelect}
                onSubmit={this.handleChangeModalSubmit}
                talentDisplaySettings={talentDisplaySettings.filter(
                  (talentDisplaySetting) => talentDisplaySetting.key === 'talent_display_setting'
                )}
                changeFieldGroup={this.state.changeFieldGroup}
                handleChangeCancel={() => this.setState({changeFieldGroup: null})}
              />
            </Container>
            <Container>
              <EmployeeFieldGroups
                handleChange={this.handleChange}
                iconSelected={this.state.iconSelected}
                iconSelect={this.iconSelect}
                iconColorSelected={this.state.iconColorSelected}
                iconColorSelect={this.iconColorSelect}
                onSubmit={this.handleChangeModalSubmit}
                talentDisplaySettings={talentDisplaySettings.filter(
                  (talentDisplaySetting) =>
                    talentDisplaySetting.key === 'custom_employee_field_group' &&
                    !_.isEmpty(talentDisplaySetting.child_items)
                )}
                talentDisplaySettingsCount={talentDisplaySettingsCount}
                changeFieldGroup={this.state.changeFieldGroup}
                handleChangeCancel={() => this.setState({changeFieldGroup: null})}
              />
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    client: state.client.current,
    talentDisplaySettings: fetchSelector(state, 'talent_display_settings').data,
  }),
  (dispatch) => ({
    loadFieldGroups(client, token) {
      dispatch(
        actionCreators.fetchData('talent_display_settings', api.createWithAuth(token).talentDisplaySettings.list())
      )
    },
    async changeFieldGroup(client, fieldGroup, token, data) {
      try {
        await api.createWithAuth(token).employeeCustom.fieldGroups.update(client.id, fieldGroup.id, data)
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(CustomFieldGroupIcons)
