import React, {Component} from 'react'
import {Section, SelectField, BoxDouble} from 'jbc-front/components/Form'
import {connect} from 'react-redux'
import {FormSection} from 'redux-form'
import {fetchSelector, actionCreators} from 'actions'
import api from 'api'
import _ from 'lodash'
import {prefectureOptions} from 'FormFields'
import {LabelMapper} from 'jbc-front/components/FormErrors'

const governmentOfficeTypes = ['hello_work', 'pension_center']

class SubmissionDestination extends Component {
  static defaultProps = Object.assign(
    {submissionDestination: {}},
    ...governmentOfficeTypes.map((type) => ({[_.camelCase(`${type}s`)]: []}))
  )

  loadOptions = () => {
    const {token, loadOfficeGoverment, submissionDestination = {}} = this.props
    governmentOfficeTypes.forEach((type) => {
      const prefectureId = submissionDestination[`${type}_prefecture_id`]
      if (prefectureId) {
        loadOfficeGoverment(prefectureId, type, token)
      }
    })
  }

  componentDidMount() {
    this.loadOptions()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submissionDestination !== this.props.submissionDestination) {
      this.loadOptions()
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleChange = (type) => (event, value, previousValue) => {
    const {token, loadOfficeGoverment, autofill} = this.props
    if (value !== previousValue) {
      autofill(`submission_destination.${type}_submit_id`)
    }
    loadOfficeGoverment(value, type, token)
  }

  render() {
    const {helloWorks, pensionCenters} = this.props
    return (
      <Section title="届出の管轄">
        <FormSection name="submission_destination">
          <LabelMapper name="submission_destination" label="届出の管轄の" />
          <BoxDouble>
            <SelectField
              name="hello_work_prefecture_id"
              label="都道府県"
              options={prefectureOptions}
              onChange={this.handleChange('hello_work')}
            />
            <SelectField name="hello_work_submit_id" label="公共職業安定所" options={helloWorks} required />
            <SelectField
              name="pension_center_prefecture_id"
              label="都道府県"
              options={prefectureOptions}
              onChange={this.handleChange('pension_center')}
            />
            <SelectField name="pension_center_submit_id" label="年金事務所" options={pensionCenters} required />
          </BoxDouble>
        </FormSection>
      </Section>
    )
  }
}

export default connect(
  (state) =>
    Object.assign(
      {
        token: state.auth.token,
        submissionDestination: fetchSelector(state, 'submission_destination_edit').data,
      },
      ...governmentOfficeTypes.map((type) => ({[_.camelCase(`${type}s`)]: fetchSelector(state, `${type}s`).options}))
    ),
  (dispatch, {autofill}) => ({
    loadOfficeGoverment(prefectureId, governmentOfficeType, token) {
      dispatch(
        actionCreators.fetchData(
          `${governmentOfficeType}s`,
          prefectureId
            ? api
                .createWithAuth(token)
                .governmentOffices.list(prefectureId, governmentOfficeType)
                .then(({data}) => ({
                  options: data.map((office) => ({value: office.submit_id, label: office.disp_name})),
                }))
            : Promise.resolve({options: []})
        )
      )
    },
    destroy() {
      governmentOfficeTypes.forEach((type) => actionCreators.fetchDestroy(`${type}s`))
    },
    autofill(...args) {
      dispatch(autofill(...args))
    },
  })
)(SubmissionDestination)
