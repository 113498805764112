import gql from 'graphql-tag'

export const EGOV_ACCOUNT = gql`
  query {
    egovAccount {
      id
      email
      egovIdp
      gbizidAccountType
      registeredAt
      egovAccessToken {
        egovAccountId
        isActive
      }
    }
  }
`

export const DELETE_ACTIVE_EGOV_ACCOUNT = gql`
  mutation deleteActiveEgovAccount {
    deleteActiveEgovAccount(input: {}) {
      egovAccount {
        id
      }
    }
  }
`

export interface EgovAccountQueryResult {
  egovAccount: EgovAccount
}

export interface EgovAccount {
  id: string
  email: string
  egovIdp: string
  gbizidAccountType: string
  registeredAt: string
  egovAccessToken: EgovAccessToken
}

export interface EgovAccessToken {
  egovAccountId: string
  isActive: boolean
}
