import {combineReducers} from '@reduxjs/toolkit'
import {connectRouter} from 'connected-react-router'
import {reducer as formReducer} from 'redux-form'
import {reducer as notificationsReducer} from 'reapop'
import employees from 'employees/reducers'
import offices from 'offices/reducers'
import settings from 'settings/reducers'
import registration from 'registration/reducers'
import procedureStatuses from 'procedureStatuses/reducers'
import notificationsData from 'notifications/reducers'
import eGovProcedures from 'eGovProcedures/reducers'
import businessPartners from 'myNumber/businessPartner/reducers'
import documentEmployees from 'documentEmployees/reducers'
import tm from 'tm/reducers'

import auth from 'store/slices/auth'
import client from 'store/slices/client'
import session from 'store/slices/session'

import {actionTypes} from 'actions'
import procedures from 'procedures/reducers'

import {reducer as previewPdfReducer} from 'components/PreviewPdf'
import {reducer as formErrorsReducer} from 'jbc-front/components/FormErrors'
import {reducer as commentsReducer} from 'components/Comments'
import fetchReducer from 'reducers/fetchReducer'
import _ from 'lodash'

const fetchState = {
  loading: 0,
}

const fetchR = (state = fetchState, action) => {
  switch (action.type) {
    case 'FETCH_DATA':
      return {
        ...state,
        loading: state.loading + (state[action.meta.name] && state[action.meta.name].loading ? 0 : 1),
        [action.meta.name]: {
          ...state[action.meta.name],
          loading: true,
          ...(action.payload || {}),
        },
      }
    case 'FETCH_SUCCESSED':
      return {
        ...state,
        loading: state.loading - (state[action.meta.name] && state[action.meta.name].loading ? 1 : 0),
        [action.meta.name]: {
          loading: false,
          ...action.payload,
        },
      }
    case 'FETCH_FAILED':
      return {
        ...state,
        loading: state.loading - (state[action.meta.name] && state[action.meta.name].loading ? 1 : 0),
        [action.meta.name]: {
          loading: false,
          error: true,
        },
      }
    case 'FETCH_DESTROY':
      return {
        ..._.omit(state, action.meta.name),
        loading: state.loading - (state[action.meta.name] && state[action.meta.name].loading ? 1 : 0),
      }
    default:
      return state
  }
}

const rootReducer = (history) =>
  combineReducers({
    notifications: notificationsReducer(),
    router: connectRouter(history),
    form: formReducer,
    employees,
    client,
    offices,
    settings,
    registration,
    procedureStatuses,
    notificationsData,
    auth,
    session,
    procedures,
    master: fetchReducer(actionTypes.MASTER, {}),
    businessPartners,
    previewPdf: previewPdfReducer,
    formErrors: formErrorsReducer,
    comments: commentsReducer,
    fetchR,
    eGovProcedures,
    documentEmployees,
    tm,
  })

export default rootReducer
