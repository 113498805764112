import React from 'react'
import styles from 'documents/Contract.scss'

const SearchResult = ({total, page, limit, isLoading, failureMessage = '結果がありません', className}) => {
  const begin = limit * (page - 1) + 1
  const lastElementOfPage = limit * page
  const end = lastElementOfPage > total ? total : lastElementOfPage

  if (isLoading) return null

  return (
    <div className={className}>
      {total > 0 ? (
        <div>
          <span className={styles.searchResultTotal}>{total}</span>件中
          <span className={styles.searchResultRange}>
            {begin}-{end}
          </span>
          を表示
        </div>
      ) : (
        <p className={styles.searchResultErrorMessage}>{failureMessage}</p>
      )}
    </div>
  )
}

export default SearchResult
