// 非ログインページのパス
export const WITHOUT_AUTH_PATHS = {
  REGISTRATION: {
    INDEX: '/registration',
    COMPLETED: '/registration/completed',
  },
  REGISTRATION_TRIAL: {
    INDEX: '/registration/trial',
    COMPLETED: '/registration/trial/completed',
  },
  INVITATION_CONFIRMATION: {
    SHOW: '/invitation_confirmation',
  },
  CHANGE_EMAIL_CONFIRMATION: {
    SHOW: '/change_mail_confirmation',
  },
  EXPIRE_TOKENS: {
    INDEX: '/expire_token',
  },
}

// 管理者ページのパス
export const WITH_AUTH_ADMIN_PATHS = {
  EMPLOYEES: {
    UPDATE_REQUESTS: {
      INDEX: '/employees/update_requests',
      SHOW: '/employees/update_requests/:id',
    },
  },
  DIRECT_REQUEST: {
    INDEX: '/direct_request',
    REQUEST: '/direct_request/request',
  },
  DOCUMENTS: {
    INDEX: '/documents',
    SHOW: '/documents/:id',
    DOCUMENT_ID: {
      EMPLOYEES: {
        INDEX: '/documents/:documentId/employees',
      },
    },
    EMPLOYEES: {
      SHOW: '/documents/employees/:id',
    },
  },
  EGOV_PROCEDURES: {
    INDEX: '/egov_procedures',
    SHOW: '/egov_procedures/:id',
  },
  SETTINGS: {
    INDEX: '/settings',
    EGOV: {
      SHOW: '/settings/egov',
      EDIT: '/settings/egov/edit',
      ACCOUNT: {
        SHOW: '/settings/account',
      },
    },
    GROUPS: {
      IMPORT: '/settings/groups/import',
      IMPORT_UPDATE: '/settings/groups/import_update',
    },
  },
  PROCEDURE_STATUSES: {
    CHOOSE_PROCEDURE: '/procedure_statuses/choose_procedures',
  },
} as const

export const WITH_AUTH_TM_PATHS = {
  LOGOUT: '/tm/logout',
} as const

export const WITH_AUTH_PATHS = {
  LOGOUT: '/logout',
} as const

// MEMO: 権限管理実装後は従業員ページがマイページ(従業員も管理者もアクセスできる)になるはず
// マイページのパス
export const MYPAGE_PATHS = {
  DOCUMENTS: {
    INDEX: '/mypage/documents',
  },
  PROCEDURES: {
    CHANGE_DEPENDENTS: {
      INDEX: '/mypage/change_dependents',
    },
    ADD_DEPENDENTS: {
      CHOOSE_NEW_OR_EXISTS: '/mypage/add_dependents/choose_new_or_exists',
      CHOOSE_EXIST_DEPENDENTS: '/mypage/add_dependents/choose_exist_dependents',
      EMPLOYEE_INPUT: '/mypage/add_dependents/employee_input/:id?',
      EMPLOYEE_INPUT_DEPENDENTS: '/mypage/add_dependents/employee_input_dependents/:id',
    },
    REMOVE_DEPENDENTS: {
      EMPLOYEE_INPUT: '/mypage/remove_dependents/employee_input/:id?',
    },
  },
} as const

type GenerateDynamicPath = (
  path: string,
  patterns: {
    pattern: string
    replacement: string
  }[]
) => string

export const generateDynamicPath: GenerateDynamicPath = (path, patterns) => {
  return patterns.reduce((prev, current) => prev.replace(`:${current.pattern}`, current.replacement), path)
}
