import React, {FC} from 'react'
import {OfficeSelector, OfficeSelectorProps} from './OfficeSelector'

interface ScalarOfficeSelectorProps extends Omit<OfficeSelectorProps, 'selectedIds' | 'onChange'> {
  selectedIds: string | number
  onChange: (selectedIds: string) => void
}

/**
 * {@link OfficeSelector} の入出力をカンマ区切りの文字列にしたバージョン。
 * いくつかのフォームではそのような形式でOffice IDを扱っているため、実装を簡単にするために共通化している。
 */
export const ScalarOfficeSelector: FC<ScalarOfficeSelectorProps> = ({selectedIds, onChange, ...rest}) => {
  const idArray = selectedIds ? String(selectedIds).split(',') : []
  return <OfficeSelector {...rest} selectedIds={idArray} onChange={(ids) => onChange(ids.join(','))} />
}
