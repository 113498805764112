import React, {Component} from 'react'
import Form from 'procedures/changeDependents/remove/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import _ from 'lodash'
import checkEmployeeInput from 'components/checkEmployeeInput'
import checkUnemployed from 'components/checkUnemployed'
import compose from 'lodash/fp/compose'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

export const formName = 'changeDependents'

class EmployeeInput extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      loadEmployee,
      token,
      loadTmpData,
      loadComments,
    } = this.props
    loadEmployee(token)
    loadComments(id, token)
    if (id) {
      loadTmpData(id, token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  makeInitialValues() {
    const {
      tmpData: {application_procedure_remove_dependents: removeDependentsData},
      employee: {employee_dependents: dependents},
    } = this.props
    if (removeDependentsData) {
      return removeDependentsData
    }
    return dependents
      ? {
          dependents: dependents.map((dependent) =>
            _.pick(dependent, [
              'first_name',
              'last_name',
              'id',
              'remove_reason_detail',
              'remove_reason_type',
              'relation_type',
              'died_on',
            ])
          ),
        }
      : {}
  }

  render() {
    const {
      updateAndCreateProcedure,
      token,
      employee,
      healthInsuranceType,
      match: {
        params: {id},
      },
      comments,
    } = this.props
    const handleSubmit = (values, comment) =>
      updateAndCreateProcedure(id, employee.id, {...values, comment: comment}, token)
    return (
      <CommentModal
        formName={formName}
        procedureType="changeDependents"
        onSubmit={({comment, values}) => {
          handleSubmit(values, comment)
        }}
        comments={comments || []}
      >
        {({showModal}) => (
          <Form
            onSubmit={showModal}
            initialValues={this.makeInitialValues()}
            submitText="申請する"
            healthInsuranceType={healthInsuranceType}
            comments={comments || {}}
          />
        )}
      </CommentModal>
    )
  }
}

export default compose(
  checkEmployeeInput(),
  checkUnemployed(),
  connect(
    (state) => ({
      employee: state.employees.current.data,
      tmpData: state.procedureStatuses.tmpData.data,
      comments: fetchSelector(state, 'procedure_comments').data,
      healthInsuranceType: _.get(state.session.currentUser, 'employee.office.health_insurance_type'),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadEmployee: (token) => {
        dispatch(
          actionCreators.employees.current.fetchData(
            api.createWithAuth(token).employees.getCurrent(['employee_dependents', 'office'])
          )
        )
      },
      loadTmpData: (procedureStatusId, token) => {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(procedureStatusId, {
              type: 'application',
              name: 'procedure_remove_dependents',
            })
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndCreateProcedure: async (procedureStatusId, employeeId, values, token) => {
        const authedApi = api.createWithAuth(token)
        try {
          if (!procedureStatusId) {
            const {
              data: {id},
            } = await authedApi.procedureStatuses.start(
              employeeId,
              'remove_dependents',
              values.date_on,
              'employee_draft'
            )
            procedureStatusId = id
          }
          await authedApi.procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            values,
            'application',
            'procedure_remove_dependents'
          )
          await authedApi.procedureStatuses.apply(procedureStatusId, values.comment)
          dispatch(push('/dashboard'))
          dispatch(notifySuccess('申請しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(EmployeeInput)
