import React from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {reduxForm, formValueSelector, autofill as autofillForm} from 'redux-form'
import {CheckboxField, Label} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import {Delete} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import styles from 'employeeFiles/Top.scss'
import compose from 'lodash/fp/compose'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import _ from 'lodash'
import {getState} from 'utils'
import {asyncError} from 'store/actions/asyncError'

const formName = 'trashFiles'
const selector = formValueSelector(formName)
const autofill = autofillForm.bind(null, formName)

const Form = compose(
  connect((state) => ({
    clientFilesTrashed: fetchSelector(state, 'client_files_trashed').data,
    trashedFiles: selector(state, 'trashed_files'),
    token: state.auth.token,
  })),
  reduxForm({
    form: formName,
    onSubmit: async (values, dispatch, {hideModal, token, loadData}) => {
      try {
        const state = await getState(dispatch)
        const method = selector(state, '_method')
        const fileIds = _.map(_.pickBy(values.trashed_files), (value, key) => key.split('_')[1])
        const authedApi = api.createWithAuth(token)
        for (const id of fileIds) {
          if (method === 'delete') {
            await authedApi.uploadedFiles.delete(id)
          } else if (method === 'recover') {
            await authedApi.uploadedFiles.update(id, {trashed_by_admin: false})
          }
        }
        loadData()
        hideModal()
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(({handleSubmit, submitting, hideModal, clientFilesTrashed = {}, trashedFiles, dispatch}) => {
  const files = _.flatten(_.map(clientFilesTrashed))
  return (
    <React.Fragment>
      <Modal.Header hideModal={hideModal}>削除済みファイル</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {_.isEmpty(files) ? (
            <Label text="削除済のファイルがありません" />
          ) : (
            files.map((file) => (
              <CheckboxField key={file.id} name={`trashed_files.file_${file.id}`} label={file.file.filename} />
            ))
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button
            primary
            onClick={() => {
              dispatch(autofill('_method', 'recover'))
              handleSubmit()
            }}
            disabled={submitting || _.isEmpty(trashedFiles) || !_.some(trashedFiles)}
          >
            元に戻す
          </Button>
          <Button
            dark
            onClick={() => {
              dispatch(autofill('_method', 'delete'))
              handleSubmit()
            }}
            disabled={submitting || _.isEmpty(trashedFiles) || !_.some(trashedFiles)}
            className={styles.warning}
          >
            完全削除
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </React.Fragment>
  )
})

const Trash = ({isModalOpen, showModal, hideModal, token, dispatch, loadData}) => (
  <React.Fragment>
    <ActionButton onClick={showModal} icon={<Delete size={16} />}>
      削除済みファイル
    </ActionButton>
    <Modal
      isOpen={isModalOpen}
      hideModal={hideModal}
      onModalShow={() => {
        dispatch(
          actionCreators.fetchData(
            'client_files_trashed',
            api.createWithAuth(token).uploadedFiles.clientFiles({trashed: true})
          )
        )
      }}
      onModalHide={() => {
        dispatch(actionCreators.fetchDestroy('client_files_trashed'))
      }}
    >
      {isModalOpen && <Form hideModal={hideModal} loadData={loadData} />}
    </Modal>
  </React.Fragment>
)

export default compose(
  withModal,
  connect((state) => ({token: state.auth.token}))
)(Trash)
