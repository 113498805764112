import React, {Component} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Glass} from 'jbc-front/components/icons'

import styles from 'components/Search.scss'

export class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      keyword: props.query.search_keyword,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.query.search_keyword !== this.state.keyword) {
      this.setState({
        keyword: nextProps.query.search_keyword,
      })
    }
  }

  handleTextInput = (e) => {
    this.setState({
      keyword: e.target.value,
    })
    const {query, setQuery} = this.props
    setQuery({...query, search_keyword: e.target.value, page: 1})
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {query, setQuery, placeholder, ...rest} = this.props
    /* eslint-enable no-unused-vars */
    return (
      <div className={styles.textBoxWrap}>
        <input
          type="text"
          className={styles.textBox}
          value={this.state.keyword}
          onChange={this.handleTextInput}
          placeholder={placeholder}
          {...rest}
        />
        <span className={styles.icon}>
          <Glass size={16} />
        </span>
      </div>
    )
  }
}

export const connectSearch = (selector, actionCreator) =>
  connect(
    (state) => ({
      query: selector(state),
    }),
    (dispatch) => ({
      setQuery: _.debounce((query) => dispatch(actionCreator(query)), 1000),
    })
  )(Search)
