const actions = [
  'INITIALIZE_LIST',
  'ADD_LIST',
  'EDIT_NODE',
  'MOVE_LIST',
  'DELETE_LIST',
  'START_EDIT',
  'END_EDIT',
  'INITIALIZE',
]

const subActions = {}

export default [actions, subActions]
