import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Index} from 'pages/withAuth/adminPage/documents/[documentId]/employees/index/index'

export const DocumentIdRoutes: RouteParams[] = [
  {
    key: 'document/:documentId/employees',
    path: WITH_AUTH_ADMIN_PATHS.DOCUMENTS.DOCUMENT_ID.EMPLOYEES.INDEX,
    component: Index,
    exact: true,
  },
]
