import React from 'react'
import styles from 'components/SelectIconCard.scss'
import {Edit} from 'jbc-front/components/icons'

export const Card = ({icon, children, onClick, title, isOpenIconsBox, color}) => {
  const Icon = icon
  return (
    <div className={styles.base} title={title}>
      <div className={styles.title}>{title}</div>
      <Icon size={80} className={styles.test} color={color} />
      <button className={styles.button} onClick={onClick} disabled={isOpenIconsBox}>
        <Edit />
        &thinsp;{children}
      </button>
    </div>
  )
}

export const Container = ({children, ...rest}) => (
  <div {...rest} className={styles.container}>
    {children}
  </div>
)
