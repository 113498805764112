import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Wrap, TitleArea, Title, Timelinedate, Timelinecontent, Ul, Li, SubPanelarea} from 'tm/employees/Parts'
import * as icons from 'jbc-front/components/icons'
import {Project} from 'jbc-front/components/icons'
import ShowMoreBtn from './ShowMoreBtn'

class EmploymentInfomation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }
  render() {
    const {employee, userDisplaySetting} = this.props
    const icon_name = getCategoryIconName(userDisplaySetting, 'project')
    const color =
      (!_.isNull(getCategoryIconColor(userDisplaySetting, 'project')) &&
        getCategoryIconColor(userDisplaySetting, 'project')) ||
      '#3498db'
    const IconName = icons[icon_name]
    const style = {color: color}
    return (
      (showCategory(userDisplaySetting, 'project') &&
        employee.employee_project &&
        employee.employee_project.length > 0 && (
          <Panel>
            <Item>
              <Wrap>
                <TitleArea>
                  <Title>
                    {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || (
                      <Project size={30} color={color} />
                    )}
                    <div style={style}>プロジェクト</div>
                  </Title>
                </TitleArea>
                <SubPanelarea>
                  <Ul>
                    {employee.employee_project &&
                      (this.state.isExpand ? employee.employee_project : employee.employee_project.slice(0, 2)).map(
                        (project, index) =>
                          project && (
                            <Li key={index}>
                              <Timelinedate>
                                {recordDisplay(_.get(project, 'project_name'))}{' '}
                                {recordDisplay.date(_.get(project, 'start_date'), 'seireki')} -{' '}
                                {(_.get(project, 'end_date') &&
                                  recordDisplay.date(_.get(project, 'end_date'), 'seireki')) ||
                                  '現在'}
                              </Timelinedate>
                              {_.get(project, 'project_details') && (
                                <Timelinecontent>{recordDisplay(_.get(project, 'project_details'))}</Timelinecontent>
                              )}
                            </Li>
                          )
                      )}
                  </Ul>
                  {employee.employee_project && employee.employee_project.length > 2 && (
                    <ShowMoreBtn
                      isExpand={this.state.isExpand}
                      onToggleClick={() => {
                        this.setState({isExpand: !this.state.isExpand})
                      }}
                    />
                  )}
                </SubPanelarea>
              </Wrap>
            </Item>
          </Panel>
        )) ||
      null
    )
  }
}
export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmploymentInfomation)
