import {MYPAGE_PATHS} from 'consts/paths'
import {RouteParams} from 'types/routes'

// @ts-ignore
import changeDependents from '../../../../../procedures/changeDependents'

export const ChangeDependentRoutes: RouteParams[] = [
  {
    key: '/mypage/change_dependents',
    path: MYPAGE_PATHS.PROCEDURES.CHANGE_DEPENDENTS.INDEX,
    component: changeDependents.ChooseAddOrRemove,
    exact: true,
  },
  {
    key: '/mypage/add_dependents/choose_new_or_exists',
    path: MYPAGE_PATHS.PROCEDURES.ADD_DEPENDENTS.CHOOSE_NEW_OR_EXISTS,
    component: changeDependents.add.ChooseNewOrExists,
  },
  {
    key: '/mypage/add_dependents/choose_exist_dependents',
    path: MYPAGE_PATHS.PROCEDURES.ADD_DEPENDENTS.CHOOSE_EXIST_DEPENDENTS,
    component: changeDependents.add.ChooseExistDependents,
  },
  {
    key: '/mypage/add_dependents/employee_input/:id?',
    path: MYPAGE_PATHS.PROCEDURES.ADD_DEPENDENTS.EMPLOYEE_INPUT,
    component: changeDependents.add.AddEmployeeDependents,
    exact: true,
  },
  {
    key: '/mypage/add_dependents/employee_input_dependents/:id',
    path: MYPAGE_PATHS.PROCEDURES.ADD_DEPENDENTS.EMPLOYEE_INPUT_DEPENDENTS,
    component: changeDependents.add.EmployeeInputDependents,
  },
  {
    key: '/mypage/remove_dependents/employee_input/:id?',
    path: MYPAGE_PATHS.PROCEDURES.REMOVE_DEPENDENTS.EMPLOYEE_INPUT,
    component: changeDependents.remove.EmployeeInput,
    exact: true,
  },
]
