import React, {Component} from 'react'
import Form from 'procedures/changeDependents/remove/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {parse} from 'query-string'
import {asyncError} from 'store/actions/asyncError'

class Detail extends Component {
  componentDidMount() {
    const {
      loadEmployee,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    loadEmployee(employeeId, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const dependents = this.props.employee.employee_dependents
    const employee = this.props.employee
    const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
    const initialValues = dependents
      ? {
          dependents: dependents.map((dependent) =>
            _.pick(dependent, [
              'first_name',
              'last_name',
              'id',
              'remove_reason_detail',
              'remove_reason_type',
              'relation_type',
            ])
          ),
        }
      : {}
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        employee={employee}
        healthInsuranceType={healthInsuranceType}
      />
    )
  }

  handleSubmit = (values) => {
    const {
      updateAndCreateProcedure,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    return updateAndCreateProcedure(employeeId, values, token)
  }
}

export default connect(
  (state) => ({
    employee: state.employees.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadEmployee: (id, token) => {
      dispatch(
        actionCreators.employees.current.fetchData(
          api.createWithAuth(token).employees.get(id, ['employee_dependents', 'office'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
    },
    updateAndCreateProcedure: async (employeeId, {dependents, date_on: dateOn}, token) => {
      const authedApi = api.createWithAuth(token)
      try {
        const {
          data: {id},
        } = await authedApi.procedureStatuses.start(employeeId, 'remove_dependents', dateOn)
        for (let dependent of dependents) {
          if (dependent && dependent._will_remove) {
            await authedApi.employees.dependents.update(employeeId, {...dependent, removed: true, removed_on: dateOn})
            await authedApi.procedureStatuses.removeDependentsData.create(id, dependent.id)
          }
        }
        dispatch(push(`/remove_dependents/flow/${id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Detail)
