import React, {useState, useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import {createPortal} from 'react-dom'
import {Rnd} from 'react-rnd'
import _ from 'lodash'
import ActionButton from 'jbc-front/components/ActionButton'
import Button from 'jbc-front/components/Button'
import {Close, Visibility} from 'jbc-front/components/icons'
import {handleError} from 'libs/axiosErrorHandlers'
import {useLazyFetchDocumentSharedTemplatePdf} from 'hooks/api/documentSharedTemplates/useLazyFetchDocumentSharedTemplatePdf'
import {parseHtml} from 'documentTemplates/parseHtml'
import {DocumentSharedTemplate, IMPORT_FROM_DOCUMENT_SHARED_TEMPLATE} from 'documentTemplates/sample/query'
import styles from 'documentTemplates/sample/TemplatePreview.scss'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

// @ts-ignore
import {recordDisplay} from 'utils'
// @ts-ignore
import {useMutation} from 'components/Graphql'
// @ts-ignore
import PreviewContainer from 'documents/PreviewContainer'

interface DialogProps {
  children: React.ReactNode
}

const Dialog: React.FC<DialogProps> = ({children}) => {
  const el = useRef(document.createElement('div'))
  useEffect(() => {
    document.body.appendChild(el.current)
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps -- el.currentはライフサイクル間で変化しないので無視してOK
      document.body.removeChild(el.current)
    }
  }, [])
  return createPortal(children, el.current)
}

const setRndDefaultRegion = (el: Rnd) => {
  if (!el) return
  const {clientWidth, clientHeight, scrollTop} = document.documentElement
  const width = _.clamp(clientWidth * 0.5, 300, 1030)
  const height = Math.max(clientHeight * 0.8, 300)
  const x = (clientWidth - width) / 2
  const y = (clientHeight - height) / 2 + scrollTop
  el.updatePosition({x, y})
  el.updateSize({width, height})
}

interface TemplatePreviewProps {
  template: DocumentSharedTemplate
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({template}) => {
  const dispatch = useDispatch()
  const fetchTemplatePdf = useLazyFetchDocumentSharedTemplatePdf(template.id)
  const [importFromDocumentSharedTemplate] = useMutation(IMPORT_FROM_DOCUMENT_SHARED_TEMPLATE)
  const [isOpen, setIsOpen] = useState(false)
  const [templatePdf, setTemplatePdf] = useState<string | undefined>(undefined)
  const [submitting, setSubmitting] = useState(false)

  // fetch on open dialog
  useEffect(() => {
    if (isOpen && !fetchTemplatePdf.isLoading && !fetchTemplatePdf.resource && !fetchTemplatePdf.errors) {
      fetchTemplatePdf.getter()
    }
  }, [isOpen])

  // on completed fetch template pdf
  useEffect(() => {
    if (!fetchTemplatePdf.isLoading) {
      if (fetchTemplatePdf.errors) {
        handleError(fetchTemplatePdf.errors, dispatch)
        return
      }

      setTemplatePdf(fetchTemplatePdf.resource)
    }
  }, [fetchTemplatePdf.isLoading])

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await importFromDocumentSharedTemplate({variables: {input: {ids: [template.id]}}})
      dispatch(notifySuccess('取得しました'))
    } catch (err) {
      dispatch(asyncError(err))
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <ActionButton className={styles.previewButton} onClick={() => setIsOpen((v) => !v)}>
        <Visibility />
      </ActionButton>
      {isOpen && (
        <Dialog>
          <Rnd
            ref={setRndDefaultRegion}
            className={styles.draggable}
            minWidth="300px"
            minHeight="300px"
            bounds="window"
          >
            <div className={styles.draggableInner}>
              <div className={styles.header}>
                <div className={styles.headerTitle}>プレビュー</div>
                <Close className={styles.closeButton} onClick={() => setIsOpen(false)} />
              </div>
              <div className={styles.body}>
                <p className={styles.title}>{template.title}</p>
                <p
                  className={styles.description}
                  dangerouslySetInnerHTML={parseHtml(recordDisplay(template.description))}
                />
                <PreviewContainer className={styles.previewContainer} pdf={templatePdf} />
              </div>
              <div className={styles.footer}>
                <Button className={styles.footerButton} onClick={() => setIsOpen(false)}>
                  閉じる
                </Button>
                <Button primary className={styles.footerButton} disabled={submitting} onClick={handleSubmit}>
                  取得する
                </Button>
              </div>
            </div>
          </Rnd>
        </Dialog>
      )}
    </>
  )
}

export default TemplatePreview
