import React from 'react'
import {useDispatch} from 'react-redux'
import {useSelector} from 'hooks/redux'
import {useFetchCurrentClient} from 'hooks/api/currentClient'
import {useUpdateOffice} from 'hooks/api/offices'
import {parseBackUrl} from 'utils'
import ClientForm, {makeInitialValues} from 'client/ClientForm'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const Update = ({location, history}) => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.session.currentUser)
  const fetchCurrentClient = useFetchCurrentClient(currentUser.client_role.client_role_type, {
    embed: ['office'],
  })
  const currentOffice = fetchCurrentClient.resource?.current_client?.office
  const updateOffice = useUpdateOffice()

  const handleSubmit = async (values) => {
    try {
      if (
        values.office_contact_infos[2].rep_position_name ||
        values.office_contact_infos[2].rep_last_name ||
        values.office_contact_infos[2].rep_first_name
      ) {
        values.office_contact_infos[1].rep_position_name = values.office_contact_infos[2].rep_position_name
        values.office_contact_infos[1].rep_last_name = values.office_contact_infos[2].rep_last_name
        values.office_contact_infos[1].rep_first_name = values.office_contact_infos[2].rep_first_name
      }
      await updateOffice(currentOffice.id, values)
      history.push(parseBackUrl(location) || '/client')
      dispatch(notifySuccess('更新しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return currentOffice ? <ClientForm initialValues={makeInitialValues(currentOffice)} onSubmit={handleSubmit} /> : null
}

export default Update
