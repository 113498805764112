import {FC, useState} from 'react'
import useReactRouter from 'use-react-router'

import {NoticeModal} from './parts/NoticeModal'
import {WithdrawModal} from './parts/WithdrawModal'
import {MainTitle} from 'components/layout/MainTitle'
import {Content} from 'components/layout/Content'
import {Breadcrumb} from 'components/layout/Breadcrumb'
import {Panel, Head, Body} from 'components/ui/Panel'
import {Dl, Dt, Dd} from 'components/ui/DefinitionList'
import {DownloadButton} from 'components/ui/DownloadButton'
import {Table, Thead, Tbody, Tr, Th, Td} from 'components/ui/Table'

import {useDownloadOfficialDocumentFile} from 'hooks/api/egov/v1/egovProcedures/officialDocumentFile/useDownloadOfficialDocumentFile'
import {dateFormat, displayFormat} from 'libs/formatter'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {EgovProcedure} from './query'
import {EGOV_PROCEDURE_STATUSES} from 'consts/egovProcedureStatuses'
import styles from './Presenter.scss'

// jbc-front
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Button from 'jbc-front/components/Button'

import {useDownloadNoticeFile} from 'hooks/api/egov/v1/egovProcedures/noticeFile/useDownloadNoticeFile'

interface PresenterProps {
  egovProcedure: EgovProcedure
  refetch: (egovProcedureId: string) => void
}

export const Presenter: FC<PresenterProps> = ({egovProcedure, refetch}) => {
  const {history} = useReactRouter()
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState<boolean>(false)
  const [isWithdrawButtonHover, setIsWithdrawButtonHover] = useState<boolean>(false)
  const [isNoticeModalOpen, setIsNoticeModalOpen] = useState<boolean>(false)
  const [targetNotice, setTargetNotice] = useState<{title?: string; sentence?: string}>({})
  const officialDocumentFileDownload = useDownloadOfficialDocumentFile()
  const noticeFileDownload = useDownloadNoticeFile()

  const paths = [
    {
      key: 'settings',
      to: WITH_AUTH_ADMIN_PATHS.EGOV_PROCEDURES.INDEX,
      label: '電子申請一覧',
    },
  ]

  const {procedureStatus, office} = egovProcedure

  const employeeOrOfficeName = () => {
    if (procedureStatus) {
      const {
        employee: {firstName, lastName},
      } = procedureStatus
      return `${lastName} ${firstName}`
    } else {
      return office.name
    }
  }

  const name = employeeOrOfficeName()
  const {egovApplication} = egovProcedure
  const {officialDocuments, notices} = egovApplication
  const isWithdrawable =
    ['PROCESSING', 'ARRIVED', 'UNDER_REVIEW'].includes(egovProcedure.status) &&
    !['INVALID_TOKEN', 'ABORT'].includes(egovApplication.trackingLogStatus)

  const status = () => {
    switch (egovApplication.trackingLogStatus) {
      case 'INVALID_TOKEN':
        return <Dd className={styles.errorText}>アカウント連携失敗</Dd>
      case 'ABORT':
        return <Dd className={styles.errorText}>トラッキング失敗</Dd>
      default:
        return <Dd>{EGOV_PROCEDURE_STATUSES[egovProcedure.status]}</Dd>
    }
  }

  const handleOpenNoticeModal = (title?: string, sentence?: string) => {
    setTargetNotice({title, sentence})
    setIsNoticeModalOpen(true)
  }

  return (
    <>
      <MainTitle title="電子申請詳細情報" />
      <Content size="xs">
        <Breadcrumb paths={paths} current="電子申請詳細" />
        <Panel>
          <Head>{`${name}の電子申請情報`}</Head>
          <Body>
            <Dl separator="：">
              <Dt>氏名・事業所名</Dt>
              <Dd>{name}</Dd>
              <Dt>手続名</Dt>
              <Dd>{egovProcedure.procedureName}</Dd>
              <Dt>帳票名</Dt>
              <Dd>{egovProcedure.formName}</Dd>
              <Dt>到達番号</Dt>
              <Dd>{egovProcedure.egovApplication.arriveId}</Dd>
              <Dt>ステータス</Dt>
              {status()}
              <Dt>eGovアカウントのemail</Dt>
              <Dd>{displayFormat(egovProcedure.egovApplication.egovAccount.email)}</Dd>
              <Dt>eGovアカウントの種類</Dt>
              <Dd>{displayFormat(egovProcedure.egovApplication.egovAccount.egovIdp)}</Dd>
            </Dl>

            <Spacer size={16} />

            {egovApplication.trackingLogStatus === 'INVALID_TOKEN' && (
              <div className={styles.buttonContainer}>
                <Button primary onClick={() => history.push(WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.ACCOUNT.SHOW)}>
                  アカウント再連携
                </Button>
              </div>
            )}

            <Spacer size={16} />

            <div className="l-overflow-scroll">
              <Table>
                <Thead>
                  <Tr>
                    <Th>公文書発行日</Th>
                    <Th>ダウンロード期限</Th>
                    <Th>ダウンロード日</Th>
                    <Th width="100px">ダウンロード</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {officialDocuments.length > 0 ? (
                    officialDocuments.map((officialDocument, i) => (
                      <Tr key={`official-document-${i}`}>
                        <Td>{dateFormat(officialDocument.allowedDate, 'LLL')}</Td>
                        <Td>{dateFormat(officialDocument.docDownloadExpiredDate, 'LL')}</Td>
                        <Td>{dateFormat(officialDocument.docDownloadDate, 'LLL')}</Td>
                        <Td>
                          <DownloadButton
                            onClick={() => officialDocumentFileDownload(egovProcedure.id, officialDocument.id)}
                          />
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={4}>ダウンロード可能な公文書はありません</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </div>

            <Spacer size={16} />

            <div className="l-overflow-scroll">
              <Table>
                <Thead>
                  <Tr>
                    <Th>通知発行日時</Th>
                    <Th width="100px">ダウンロード</Th>
                    <Th>詳細</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {notices.length > 0 ? (
                    notices.map((notice, i) => (
                      <Tr key={`notice-${i}`}>
                        <Td>{dateFormat(notice.issueDate, 'LLL')}</Td>
                        <Td>
                          <DownloadButton
                            onClick={() => noticeFileDownload(egovProcedure.id, notice.id)}
                            disabled={!notice.isFileDownload}
                          />
                        </Td>
                        <Td>
                          <Button
                            className={styles.openNoticeModalButton}
                            onClick={() => handleOpenNoticeModal(notice.noticeTitle, notice.noticeSentence)}
                            disabled={!(notice.noticeTitle && notice.noticeSentence)}
                          >
                            詳細
                          </Button>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={4}>ダウンロード可能な通知はありません</Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </div>
          </Body>
        </Panel>
        <Spacer size={16} />
        <div className={styles.buttonContainer}>
          <Button
            disabled={!isWithdrawable}
            onClick={() => setIsWithdrawModalOpen(true)}
            onMouseOver={() => setIsWithdrawButtonHover(true)}
            onMouseOut={() => setIsWithdrawButtonHover(false)}
          >
            取下げ
          </Button>
          {!isWithdrawable && isWithdrawButtonHover && (
            <div className={styles.bubble}>この手続きは取下げできません</div>
          )}
        </div>

        <WithdrawModal
          isOpen={isWithdrawModalOpen}
          hideModal={() => setIsWithdrawModalOpen(false)}
          egovProcedure={egovProcedure}
          isWithdrawable={isWithdrawable}
          refetch={refetch}
        />

        <NoticeModal
          isOpen={isNoticeModalOpen}
          hideModal={() => setIsNoticeModalOpen(false)}
          title={targetNotice.title}
          sentence={targetNotice.sentence}
        />
      </Content>
    </>
  )
}
