import {FC, ReactNode, useEffect} from 'react'
import {push} from 'connected-react-router'
import {useDispatch} from 'react-redux'

interface Props {
  children: ReactNode
  clientRoleType: string
  employmentStatus?: string
  redirectPath: string
}

export const RejectUnemployeeRouter: FC<Props> = ({children, clientRoleType, employmentStatus, redirectPath}) => {
  const isUnEmployee = clientRoleType === 'member' && (!employmentStatus || employmentStatus === 'unemployed')
  const dispatch = useDispatch()

  useEffect(() => {
    if (isUnEmployee) {
      dispatch(push(redirectPath))
    }
  }, [])

  return <>{!isUnEmployee && <>{children}</>}</>
}
