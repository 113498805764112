import React, {useState, useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect} from 'react-router'
import classNames from 'classnames'
import {gql} from '@apollo/client'
import Button from 'jbc-front/components/Button'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {Email} from 'jbc-front/components/icons'
import {useMutation, useQuery} from 'components/Graphql'
import {Label, Section} from 'documents/Contract'
import {DOCUMENT_FRAGMENT} from 'documents/show/Query'
import styles from 'documents/Remind.scss'
import {getMainOfficeContactInfo} from 'offices/utils'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const REMIND_CONTRACT_INFO = gql`
  query document($id: ID!) {
    client {
      id
      planType
      document(id: $id) {
        id
        remindContractCount
        contractOriginalSenderInfo
      }
    }
  }
`

const REMIND_CONTRACT = gql`
  mutation remindContract($input: RemindContractInput!) {
    remindContract(input: $input) {
      document {
        ...DocumentFields
      }
      remind {
        sentCount
        errorCount
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`

const SenderInfoRadioField = ({currentUserInfo, contractOriginalSenderInfo, selectValue, setSelectValue}) => {
  const senderInfos = [
    {value: 'current', label: currentUserInfo},
    {value: 'original', label: contractOriginalSenderInfo},
  ]
  const handleChange = (event) => setSelectValue(event.target.value)

  return (
    <>
      {senderInfos.map(({value, label}) => (
        <div className={styles.senderInfoInputLabel} key={value}>
          <label>
            <input
              type="radio"
              value={value}
              checked={selectValue === value}
              onChange={handleChange}
              className="m-radio-input"
            />
            <span className="m-radio-parts">
              {value === 'original' ? <span className={styles.annotation}>*</span> : null}
              {label}
            </span>
          </label>
          {value === 'original' ? (
            <p className={classNames(styles.annotation, styles.originalSenderText)}>※電子契約依頼の作成者です</p>
          ) : null}
        </div>
      ))}
    </>
  )
}

const Body = ({
  loading,
  remindCount,
  handleSubmit,
  currentUserInfo,
  contractOriginalSenderInfo,
  selectValue,
  setSelectValue,
}) => {
  if (loading) {
    return null
  }

  if (remindCount === 0) {
    return (
      <Section>
        <p className="u-mb5">送信人数：{remindCount}人</p>
      </Section>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Section>
        <p className="u-mb5">送信人数：{remindCount}人</p>
      </Section>
      <Section>
        <Label>差出人</Label>
        {contractOriginalSenderInfo === null || currentUserInfo === contractOriginalSenderInfo ? (
          <p className={styles.text}>{currentUserInfo}</p>
        ) : (
          <SenderInfoRadioField
            currentUserInfo={currentUserInfo}
            contractOriginalSenderInfo={contractOriginalSenderInfo}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
          />
        )}
      </Section>
      <Section>
        <p className={styles.annotation}>※従業員の署名により、実際の送信件数と異なる場合があります</p>
        <p className={styles.annotation}>※前回のリマインドメール送信から1時間を経過していない場合は送信されません</p>
      </Section>
    </form>
  )
}

const RemindModal = ({hideModal, id}) => {
  const dispatch = useDispatch()
  const {data, error, loading} = useQuery(REMIND_CONTRACT_INFO, {
    fetchPolicy: 'network-only',
    variables: {id: id},
  })
  const currentUser = useSelector((state) => state.session.currentUser)
  const currentOffice = useSelector((state) => state.client.current.office)
  const senderClientName = getMainOfficeContactInfo(currentOffice).name
  const currentUserInfo = `${senderClientName} ${currentUser.name || currentUser.employee.full_name}`
  const remindCount = data?.client?.document?.remindContractCount
  const contractOriginalSenderInfo = data?.client?.document?.contractOriginalSenderInfo
  const [submitting, setSubmitting] = useState(false)
  const [selectValue, setSelectValue] = useState('current')
  const [remindContract] = useMutation(REMIND_CONTRACT)
  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true)
      await remindContract({variables: {input: {id: id, remindSender: selectValue}}})
      dispatch(notifySuccess('送信しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
    setSubmitting(false)
    hideModal()
  }, [id, selectValue])

  if (error) {
    return <Redirect to="/documents" />
  }

  return (
    <>
      <Modal.Header hideModal={hideModal}>電子契約リマインドメール送信確認</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Body
          loading={loading}
          remindCount={remindCount}
          handleSubmit={handleSubmit}
          currentUserInfo={currentUserInfo}
          contractOriginalSenderInfo={contractOriginalSenderInfo}
          selectValue={selectValue}
          setSelectValue={setSelectValue}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          {!loading && !!remindCount && (
            <Button primary onClick={handleSubmit} disabled={submitting}>
              送信する
            </Button>
          )}
        </Modal.Buttons>
      </Modal.Footer>
    </>
  )
}

const Remind = ({isModalOpen, showModal, hideModal = [], id}) => (
  <div className={styles.wrap}>
    <Button widthAuto onClick={showModal}>
      <span className={styles.icon}>
        <Email size={16} />
      </span>
      電子契約リマインドメール送信
    </Button>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      {isModalOpen && <RemindModal hideModal={hideModal} id={id} />}
    </Modal>
  </div>
)

export default withModal(Remind)
