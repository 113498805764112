import React from 'react'
import {FieldArray, FormSection} from 'redux-form'
import {Section, TextField, DateField, FileField, BoxDouble} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import {Description, withFormSelectors, getDiffFromFile} from 'employees/form/common'
import {useSelector} from 'hooks/redux'
import * as validators from 'validators'
import styles from 'employees/Form.scss'

const EmployeeQualificationFields = ({qualification, diff}) => (
  <FormSection name={qualification}>
    <TextField
      name="qualification_name"
      label="資格名"
      validate={validators.maxLength(32)}
      diff={diff.qualification_name}
      required
    />
    <BoxDouble>
      <div className={styles.arrayDoubleDate}>
        <DateField name="acquisition_date" label="取得更新年月日" diff={diff.acquisition_date} />
      </div>
      <div className={styles.arrayDoubleDate}>
        <DateField name="expiration_date" label="有効期限" diff={diff.expiration_date} />
      </div>
    </BoxDouble>
    <TextField
      name="registration_number"
      label="資格登録番号"
      validate={validators.maxLength(32)}
      diff={diff.registration_number}
    />
    <FileField name="certificate" label="証明書" diff={getDiffFromFile(diff.certificate)} />
  </FormSection>
)

const renderEmployeeQualification = ({fields, diffLst = []}) => (
  <div>
    <LabelMapper name="employee_qualifications" label="資格情報の" />
    {fields.map((qualification, index) => (
      <Section
        title={`資格 (${index + 1})`}
        key={index}
        icon={
          <DeleteSquare
            onClick={() => {
              fields.remove(index)
            }}
          />
        }
      >
        <EmployeeQualificationFields qualification={qualification} diff={diffLst[index] || {}} />
      </Section>
    ))}
    {fields.length < 1 && (
      <div className="u-ta-c">
        <p>
          「資格を追加」ボタンを押して、
          <br />
          資格情報を追加してください。
        </p>
      </div>
    )}
    <div className="u-ta-r u-mt20">
      <div onClick={() => fields.push({})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        資格を追加
      </div>
    </div>
  </div>
)

const EmployeeQualification = ({selector, description, description_color_by_rgb}) => {
  const diff = useSelector((state) => selector(state, '_diff')) || {}

  return (
    <Section title="資格情報">
      <Description {...{description, description_color_by_rgb}} />
      <FieldArray
        name="employee_qualifications"
        component={renderEmployeeQualification}
        diffLst={diff.employee_qualifications}
      />
    </Section>
  )
}

export default EmployeeQualification |> withFormSelectors
