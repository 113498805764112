import {FC, ReactNode} from 'react'
import {partition} from 'lodash'
import {useSelector} from 'hooks/redux'
import {isOfficeAdminSelector} from 'libs/redux'
import {useQuery} from 'hooks/graphql'
import {OFFICES_BY_IDS, OfficesByIdsResult, OfficesByIdsVariables} from './query'

export interface SelectedOffice {
  id: string
  name?: string | null
  loading: boolean
}

export interface ExtraItem {
  key: string
  label: string
}

interface SelectedOfficesProps {
  selectedIds: string[]
  extraItems?: ExtraItem[]
  render: (offices: SelectedOffice[]) => ReactNode
}

export const SelectedOffices: FC<SelectedOfficesProps> = ({selectedIds, extraItems, render}) => {
  const extraKeys = extraItems?.map((item) => item.key) ?? []
  const [selectedExtraKeys, selectedMasterIds] = partition(selectedIds, (id) => extraKeys.includes(id))
  const selectedExtras =
    extraItems
      ?.filter(({key}) => selectedExtraKeys.includes(key))
      ?.map(({key, label}) => ({id: key, name: label, loading: false})) ?? []

  const isOfficeAdmin = useSelector(isOfficeAdminSelector)
  const currentOffices = useSelector((state) => state.session.currentUser?.current_offices)
  const {data} = useQuery<OfficesByIdsResult, OfficesByIdsVariables>(OFFICES_BY_IDS, {
    variables: {ids: selectedMasterIds},
    skip: isOfficeAdmin || selectedMasterIds.length === 0,
  })

  if (isOfficeAdmin) {
    const offices =
      currentOffices
        ?.filter(({id}) => selectedMasterIds.includes(String(id)))
        ?.map(({id, name}) => ({id: String(id), name: name, loading: false})) ??
      selectedMasterIds.map((id) => ({id, name: undefined, loading: true}))
    return <>{render([...selectedExtras, ...offices])}</>
  } else {
    const offices =
      data?.client?.officesByIds?.map((office) => ({...office, loading: false})) ??
      selectedMasterIds.map((id) => ({id, name: undefined, loading: true}))
    return <>{render([...selectedExtras, ...offices])}</>
  }
}
