import React from 'react'
import styles from 'dashboard/dashboard.scss'
import * as icons from 'jbc-front/components/icons'

const tutorialListLink = [
  {
    linkIcon: 'VariousProceduresLine',
    linkTitle: '各種手続き',
    url: 'https://jobcan-lms.zendesk.com/hc/ja/categories/115000170351',
  },
  {
    linkIcon: 'MyNumberLine',
    linkTitle: 'マイナンバー管理',
    url: 'https://jobcan-lms.zendesk.com/hc/ja/sections/115000824232',
  },
  {
    linkIcon: 'StressCheckLine',
    linkTitle: 'ストレスチェック',
    url: 'https://jobcan-lms.zendesk.com/hc/ja/categories/360004802512',
  },
  {
    linkIcon: 'YearEndLine',
    linkTitle: '年末調整',
    url: 'https://jobcan-payroll.zendesk.com/hc/ja/categories/360001399833',
  },
  {
    linkIcon: 'EmployeeRegistrationLine',
    linkTitle: '従業員登録',
    url: 'https://jobcan-lms.zendesk.com/hc/ja/categories/115000169812',
  },
  {
    linkIcon: 'DocumentCreationLine',
    linkTitle: '書類作成',
    url: 'https://jobcan-lms.zendesk.com/hc/ja/categories/4409760441369',
  },
  {
    linkIcon: 'SettingLine',
    linkTitle: '設定',
    url: 'https://jobcan-lms.zendesk.com/hc/ja/categories/115000170331',
  },
]

const TutorialList = () => {
  return (
    <div>
      {tutorialListLink.map((item, index) => {
        const Icon = icons[item.linkIcon]
        return (
          <a href={item.url} key={index} target="_blank" rel="noopener">
            <div className={styles.tutorialIcon}>
              <Icon size={20} color="#666666" />
            </div>
            <div className={styles.tutorialTitle}>
              {item.linkTitle}
              {item.sublinkTitle && <span>{item.sublinkTitle}</span>}
            </div>
          </a>
        )
      })}
    </div>
  )
}

const Tutorial = () => {
  const BlankTargetIcon = icons['BlankTarget']
  return (
    <article className={styles.tutorial}>
      <div className={styles.card}>
        <div className={styles.card_header}>
          <div>
            チュートリアル<span>（各機能の使い方）</span>
            <BlankTargetIcon size={16} />
          </div>
        </div>
        <div className={styles.card_body}>
          <TutorialList />
        </div>
      </div>
    </article>
  )
}

export default Tutorial
