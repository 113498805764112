import React from 'react'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {bankAccountTypes} from 'employees/form/BankInfomation'
import {Dl, Dd, Dt, SubPanel, Grid} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import styles from 'employees/show/Parts.scss'
import classNames from 'classnames'

const BankInformation = ({editLink, employee, canEdit}) => (
  <SubPanel>
    <HeaddingWithLink to={`${editLink}#BankInfomation`} title="口座情報" canEdit={canEdit} isSubPanel />
    <Grid className={classNames([styles.forHalfGrid, styles.tableDesign])}>
      <Dl>
        <Dt>銀行コード</Dt>
        <Dd>{recordDisplay(employee.bank_code)}</Dd>
      </Dl>
      <Dl>
        <Dt>預金種別</Dt>
        <Dd>{recordDisplay.option(employee.bank_type, bankAccountTypes)}</Dd>
      </Dl>
      <Dl>
        <Dt>銀行名</Dt>
        <Dd>{recordDisplay(employee.bank_name)}</Dd>
      </Dl>
      <Dl>
        <Dt>口座名義</Dt>
        <Dd>{recordDisplay(employee.bank_name_kana)}</Dd>
      </Dl>
      <Dl>
        <Dt>支店コード</Dt>
        <Dd>{recordDisplay(employee.bank_branch_code)}</Dd>
      </Dl>
      <Dl>
        <Dt>口座番号</Dt>
        <Dd>{recordDisplay(employee.bank_number)}</Dd>
      </Dl>
      <Dl>
        <Dt>支店</Dt>
        <Dd>{recordDisplay(employee.bank_branch_name)}</Dd>
      </Dl>
    </Grid>
  </SubPanel>
)

export default connect((state) => ({
  employee: state.employees.current.data,
}))(BankInformation)
