import {FC} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {Link} from 'react-router-dom'
import Button from 'jbc-front/components/Button'
import {Upload} from 'jbc-front/components/icons'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Hint} from 'jbc-front/components/presenters/ui/Hint'
import {FileInput} from 'jbc-front/components/presenters/form/FileInput'
import {ErrorMessage} from 'jbc-front/components/presenters/form/ErrorMessage'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import {useUploadGroupsCsv} from 'hooks/api/importUpdateGroupsCsv/useUploadGroupsCsv'
import {asyncError} from 'store/actions/asyncError'
import styles from './FileUploadForm.scss'

// @ts-ignore
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
// @ts-ignore
import image from '../../../../../../../../../images/csv-update-help-group.png'

const Help = () => (
  <Hint width="520px">
    <div className={styles.help}>
      <div className={styles.image}>
        <img src={image} />
      </div>
      <br />
      グループ情報はグループ設定画面からダウンロードできます
    </div>
  </Hint>
)

type UploadCsvSchema = {
  file: File
}

type TaskRunningProps = {
  disabled?: boolean
  disabledReason?: string
}

export const FileUploadForm: FC = () => {
  const upload = useUploadGroupsCsv()
  const dispatch = useDispatch()

  const schema = yup.object({
    file: yup.mixed<File>().required('ファイルを選択してください'),
  })

  const {
    handleSubmit: handleReactHookFormSubmit,
    register,
    resetField,
    setValue,
    watch,
    formState: {errors},
  } = useForm<UploadCsvSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  })
  const file = watch('file')

  const handleSubmit = async (data: UploadCsvSchema) => {
    try {
      await upload({file: data.file})
      dispatch(push('/settings/groups'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  const onDrop = (files: File[], _f: unknown, _e: unknown) => {
    if (files[0]) {
      setValue('file', files[0])
    }
  }

  const onRemoveFile = () => {
    resetField('file')
  }

  return (
    <div className={`u-mt30 ${styles.mainNoteWrap}`}>
      <div className={`u-ta-c ${styles.mainNoteTitle}`}>更新するグループ情報を記載したファイルを選択してください</div>
      <div className={styles.mainNoteBody}>
        <div className={styles.mainNote}>対応しているファイル形式はCSVとなります</div>
        <div className={styles.mainNote}>
          グループ情報を
          <Link to="/settings/groups" className="u-txt-link" target="_blank">
            こちら
          </Link>
          <Help />
          からダウンロードし、更新したい箇所をご修正ください
        </div>
        <Spacer direction="y" size={20} />
        <form onSubmit={handleReactHookFormSubmit(handleSubmit)} className={styles.form}>
          <FileInput onDrop={onDrop} {...register('file')} placeholder="ファイルを選択 または ドラッグ＆ドロップ" />
          {errors.file?.message && <ErrorMessage>{errors.file.message}</ErrorMessage>}
          {file && (
            <>
              <Spacer direction="y" size={20} />
              <FileInput.File file={file} onRemove={onRemoveFile} />
            </>
          )}

          <Spacer direction="y" size={40} />
          <AsyncTaskConsumer>
            {({taskRunningProps}: {taskRunningProps: TaskRunningProps}) => (
              <Button primary widthWide onClick={handleReactHookFormSubmit(handleSubmit)} {...taskRunningProps}>
                <Upload />
                アップロード
              </Button>
            )}
          </AsyncTaskConsumer>
        </form>
      </div>
    </div>
  )
}
