import React, {Component} from 'react'
import {connect} from 'react-redux'
import api from 'api'
import {replace} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'

export default () => (Child) => {
  class CheckNeedEmployeeUpdate extends Component {
    state = {
      loading: true,
    }

    async componentDidMount() {
      const {token, dispatch, employee} = this.props
      try {
        if (employee) {
          const {
            data: {need},
          } = await api.createWithAuth(token).employees.checkNeedEmployeeUpdate.get(employee.id)
          if (need) {
            dispatch(replace(`/dashboard?error=employee_info`))
          }
        }
        this.setState({loading: false})
      } catch (err) {
        dispatch(asyncError(err))
      }
    }

    render() {
      return !this.state.loading && <Child {...this.props} />
    }
  }
  return connect((state) => ({
    token: state.auth.token,
    employee: state.session.currentUser.employee,
  }))(CheckNeedEmployeeUpdate)
}
