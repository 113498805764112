import {useState, useRef, useEffect} from 'react'
import {usePdf} from 'jbc-front/components/libs/react-pdf-js'
import {useDispatch} from 'react-redux'
import {notifyError} from 'store/actions/notify'

export const usePdfPreview = (file: string) => {
  const zooms = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500]
  const [page, setPage] = useState<number>(1)
  const [zoom, setZoom] = useState<number>(100)
  const [scale, setScale] = useState<number>(zoom / 100.0)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useDispatch()

  const handleDocumentLoadFail = () => {
    dispatch(notifyError('書類の読み込みに失敗しました'))
    setLoading(false)
  }

  const {pdfDocument} = usePdf({
    file,
    cMapUrl: `${window.location.origin}/cmaps/`,
    cMapPacked: true,
    // @ts-ignore
    workerSrc: new URL('@bundled-es-modules/pdfjs-dist/build/pdf.worker.js', import.meta.url).toString(),
    canvasRef,
    page,
    scale,
    onDocumentLoadFail: handleDocumentLoadFail,
  })

  const handleChangeZoom = (scaleType: 'in' | 'out') => {
    const currentIndex = zooms.findIndex((value) => zoom === value)
    if (currentIndex !== -1) {
      const nextIndex = (() => {
        switch (scaleType) {
          case 'in':
            return currentIndex + 1
          case 'out':
            return currentIndex - 1
          default:
            return currentIndex
        }
      })()

      const newZoomValue = zooms[nextIndex]

      if (newZoomValue) {
        setZoom(newZoomValue)
        setScale(newZoomValue / 100.0)
      }
    }
  }

  const handlePrevPage = () => {
    const prevPage = page - 1

    if (prevPage > 0) {
      setPage(prevPage)
    }
  }

  const handleNextPage = () => {
    const nextPage = page + 1

    if (pdfDocument?.numPages && nextPage <= pdfDocument.numPages) {
      setPage(nextPage)
    }
  }

  const controllerProps = {
    page,
    numPages: pdfDocument?.numPages || 0,
    zoom,
    onZoomOut: () => handleChangeZoom('out'),
    onZoomIn: () => handleChangeZoom('in'),
    onPrevious: handlePrevPage,
    onNext: handleNextPage,
  }

  useEffect(() => {
    if (pdfDocument) {
      setLoading(false)
    }
  }, [pdfDocument])

  return {
    canvasRef,
    controllerProps,
    loading,
  }
}
