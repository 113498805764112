import React from 'react'
import {useSelector} from 'react-redux'
import Errors from 'dashboard/Errors'
import Procedures from 'dashboard/Procedures'
import ProcedureStatuses from 'dashboard/ProcedureStatuses'
import Notifications from 'dashboard/Notifications'
import Profile from 'dashboard/Profile'
import {parse} from 'query-string'

const DashboardForEmployee = ({search}) => {
  const employee = useSelector((state) => state.session.currentUser.employee)

  return (
    <div className="l-flex">
      {parse(search).error && <Errors />}
      {employee && <Profile employee={employee} />}
      {!employee || employee.employment_status === 'unemployed' ? (
        <div />
      ) : (
        <div className="l-flex1-sub u-pc-pt30 u-sp-pt30">
          <Procedures />
        </div>
      )}
      <div className="l-flex1-main u-pc-pt30 u-sp-pt20" style={{maxWidth: '600px'}}>
        <Notifications isAdmin={false} />
        <ProcedureStatuses />
      </div>
    </div>
  )
}

export default DashboardForEmployee
