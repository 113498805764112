import React from 'react'
import Tag from 'jbc-front/components/Tag'
import {Glass} from 'jbc-front/components/icons'
import styles from 'components/Search.scss'

interface FilterProps {
  currentFilter?: string
  filter: string
  label: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export const Filter: React.FC<FilterProps> = ({currentFilter, filter, label, onClick}) => (
  <div>{(filter == currentFilter && <Tag active>{label}</Tag>) || <Tag onClick={onClick}>{label}</Tag>}</div>
)

interface SearchProps {
  defaultValue?: string | string[]
  placeholder?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const Search: React.FC<SearchProps> = ({defaultValue, placeholder, onChange}) => (
  <div className={styles.textBoxWrap}>
    <input
      type="text"
      className={styles.textBox}
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={placeholder}
    />
    <span className={styles.icon}>
      <Glass size={16} />
    </span>
  </div>
)
