import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Wrap, TitleArea, Title, Dl, Trthirds, Dd, Dt} from 'tm/employees/Parts'
import * as icons from 'jbc-front/components/icons'
import {PersonalInformation} from 'jbc-front/components/icons'

const EmploymentInfomation = ({employee, nations = [], userDisplaySetting}) => {
  const country = _.find(nations, {id: _.get(employee, 'nation_id')})
  const icon_name = getCategoryIconName(userDisplaySetting, 'personal_information')
  const color =
    (!_.isNull(getCategoryIconColor(userDisplaySetting, 'personal_information')) &&
      getCategoryIconColor(userDisplaySetting, 'personal_information')) ||
    '#3498db'
  const IconName = icons[icon_name]
  const style = {color: color}

  return (
    (showCategory(userDisplaySetting, 'personal_information') &&
      (!employee.gender && !employee.birthday ? null : (
        <Panel>
          <Item>
            <Wrap>
              <TitleArea>
                <Title>
                  {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || (
                    <PersonalInformation size={30} color={color} />
                  )}
                  <div style={style}>パーソナル情報</div>
                </Title>
              </TitleArea>

              <Dl>
                {employee.gender && (
                  <Trthirds>
                    <Dt>性別</Dt>
                    <Dd>{employee.gender === 'man' ? '男' : '女'}</Dd>
                  </Trthirds>
                )}
                {employee.birthday && (
                  <Trthirds>
                    <React.Fragment>
                      <Dt>生年月日</Dt>
                      <Dd>{recordDisplay.birthday(employee, 'seireki', false)}</Dd>
                    </React.Fragment>
                  </Trthirds>
                )}
                {employee.birthday && (
                  <Trthirds>
                    <Dt>年齢</Dt>
                    <Dd>{recordDisplay.age(employee)}</Dd>
                  </Trthirds>
                )}
                {employee.national_type === 'foreigner' && country && (
                  <Trthirds>
                    <Dt>国籍・地域</Dt>
                    <Dd>{recordDisplay(_.get(country, 'name'))}</Dd>
                  </Trthirds>
                )}
              </Dl>
            </Wrap>
          </Item>
        </Panel>
      ))) ||
    null
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
  nations: state.master.nations,
  visaTypes: state.master.visaTypes,
}))(EmploymentInfomation)
