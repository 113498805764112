import React from 'react'
import {Section} from 'jbc-front/components/Form'

import {reduxForm} from 'redux-form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'

import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'

const formName = 'R59Report'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, employee}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">雇用保険被保険者氏名変更届</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section>
        <SubmitedOn />
        <div className="u-ta-c u-mt30">
          <Button primary onClick={handleSubmit} disabled={submitting}>
            {submitText}
          </Button>
        </div>
      </Section>
    </div>
  </form>
))

const connectedForm = connectForm('r59', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
  }),
})

export default connectedForm
