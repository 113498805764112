import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
//@ts-ignore
import {Setting} from 'jbc-front/components/icons'
//@ts-ignore
import Modal from 'jbc-front/components/Modal'
//@ts-ignore
import {renderRadioField as RenderRadioField} from 'jbc-front/components/Form'
//@ts-ignore
import ListGroup from 'jbc-front/components/ListGroup'
//@ts-ignore
import styles from 'settings/employeeCustom/FieldGroups/CustomEmployeeFieldGroupPermission.scss'
import type {CustomEmployeeField} from 'types/api/settings/employeeFieldGroups/customEmployeeField'
import type {CustomEmployeeFieldGroup} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldGroup'
import type {CustomEmployeeFieldPermission} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldPermission'
import {usePostBulkedCustomEmployeeFields} from 'hooks/api/bulkedCustomEmployeeFields'

const clientRoles: {[id: string]: {label: string}} = {
  '1': {label: '全権管理者'},
  '2': {label: '社員'},
  '3': {label: '事業所管理者'},
  '4': {label: '運用担当者'},
}

const options = [
  {label: '更新可', value: 'updatable'},
  {label: '閲覧可', value: 'browsable'},
  {label: '非表示', value: 'not_display'},
]

export const CustomEmployeeFieldGroupPermission = ({
  initialCustomEmployeeFieldGroup,
  initialCustomEmployeeFields,
  loadData,
}: {
  initialCustomEmployeeFieldGroup: CustomEmployeeFieldGroup
  initialCustomEmployeeFields: CustomEmployeeField[]
  loadData: () => Promise<void>
}) => {
  const [showModal, setShowModal] = useState(false)
  const [customEmployeeFieldPermissions, setCustomEmployeeFieldPermissions] = useState<CustomEmployeeFieldPermission[]>(
    []
  )

  const initializeCustomEmployeeFieldPermissions = () => {
    setCustomEmployeeFieldPermissions(
      initialCustomEmployeeFields.flatMap((gf: CustomEmployeeField) => gf.custom_employee_field_permissions)
    )
  }

  useEffect(() => {
    initializeCustomEmployeeFieldPermissions()
  }, [initialCustomEmployeeFields])

  const customEmployeeFieldPermissionsGroupByClientRoleId: {[clientRoleId: number]: CustomEmployeeFieldPermission[]} =
    _.groupBy(customEmployeeFieldPermissions, (p) => p.client_role_id)

  const onChangePermission = (permissionId: number, value: string) => {
    const i = customEmployeeFieldPermissions.findIndex(({id}) => id === permissionId)
    setCustomEmployeeFieldPermissions(
      (prev) =>
        [
          ...prev.slice(0, Math.max(i, 0)),
          {...prev[i], permission: value},
          ...prev.slice(i + 1),
        ] as CustomEmployeeFieldPermission[]
    )
  }

  const modalBody = (
    <>
      <div className={styles.description}>
        更新可の場合、内容を入力・編集できます。
        <br />
        閲覧可の場合、内容を確認できますが更新はできません。
        <br />
        非表示の場合、内容が表示されません。
        <br />
      </div>
      {Object.entries(customEmployeeFieldPermissionsGroupByClientRoleId).map(([clientRoleId, permissions]) => (
        <ClientRolePermissions
          key={clientRoleId}
          clientRoleId={clientRoleId}
          permissions={permissions}
          initialCustomEmployeeFields={initialCustomEmployeeFields}
          onChangePermission={onChangePermission}
        />
      ))}
    </>
  )

  const postBulkedCustomEmployeeFields = usePostBulkedCustomEmployeeFields()
  const dispatch = useDispatch()
  const onSubmit = async () => {
    try {
      await postBulkedCustomEmployeeFields({
        custom_employee_field_group: initialCustomEmployeeFieldGroup,
        custom_employee_fields: initialCustomEmployeeFields.map((groupField) => ({
          custom_employee_field: _.omit(groupField, [
            'custom_employee_field_options',
            'custom_employee_field_permissions',
          ]),
          custom_employee_field_options: groupField.custom_employee_field_options,
          custom_employee_field_permissions: customEmployeeFieldPermissions.filter(
            (p) => p.custom_employee_field_id === groupField.id
          ),
        })),
      })
      await loadData()
      dispatch(notifySuccess('保存しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
    setShowModal(false)
  }

  const hideModal = () => {
    setShowModal(false)
    initializeCustomEmployeeFieldPermissions()
  }

  return (
    <>
      <Setting onClick={() => setShowModal(true)} className="u-cur-pointer u-ml15" />
      <Modal
        header={`「${initialCustomEmployeeFieldGroup.label}」の権限設定`}
        cancel="キャンセル"
        submit="保存"
        onSubmit={onSubmit}
        isOpen={showModal}
        hideModal={hideModal}
        body={modalBody}
      />
    </>
  )
}

const ClientRolePermissions = ({
  initialCustomEmployeeFields,
  onChangePermission,
  clientRoleId,
  permissions,
}: {
  initialCustomEmployeeFields: CustomEmployeeField[]
  onChangePermission: (permissionId: number, value: string) => void
  clientRoleId: string
  permissions: CustomEmployeeFieldPermission[]
}) => (
  <ListGroup key={clientRoleId}>
    <ListGroup.Title>
      <p>{clientRoles[clientRoleId]!.label}</p>
    </ListGroup.Title>
    <div className={styles.stripe}>
      {permissions.map((permission, key) => {
        const groupField = initialCustomEmployeeFields.find(({id}) => id === permission.custom_employee_field_id)!
        return (
          <ListGroup.Item key={key}>
            <div className={styles.item}>
              <RenderRadioField
                label={groupField.label}
                // @ts-ignore
                options={options}
                input={{
                  value: permission.permission,
                  onChange: ({target: {value}}) => onChangePermission(permission.id, value),
                }}
                meta={{}}
              />
            </div>
          </ListGroup.Item>
        )
      })}
    </div>
  </ListGroup>
)
