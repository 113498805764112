import type {Action, Dispatch, Store} from 'redux'
import type {State} from 'types/state'
import Cookies from 'js-cookie'
import {setCurrentUser, setSelectedOffices} from 'store/slices/session'

// @ts-ignore
import {selectedOffices as globalSelectedOffices} from 'offices/utils'

const DEFAULT_SELECTED_OFFICES = ''

const createStorageKey = (userId: string): string => `office#${userId}__selected_offices`

const saveSelectedOfficesToStorage = (userId: string, officeIds: string) => {
  const key = createStorageKey(userId)
  if (window.localStorage) {
    window.localStorage.setItem(key, officeIds)
  } else {
    Cookies.set(key, officeIds)
  }
}

const getSelectedOfficesFromStorage = (userId: string): string => {
  const selectedOfficeIds = window.localStorage
    ? window.localStorage.getItem(createStorageKey(userId))
    : Cookies.get(createStorageKey(userId))

  return selectedOfficeIds || DEFAULT_SELECTED_OFFICES
}

/**
 * 選択中の適用事業所をブラウザに保存するためのmiddleware
 */
export const persistSelectedOffices = (store: Store<State>) => (next: Dispatch) => (action: Action) => {
  next(action)

  if (setCurrentUser.match(action)) {
    const selectedOffices = getSelectedOfficesFromStorage(action.payload.id)
    store.dispatch(setSelectedOffices(selectedOffices))
  }

  if (setSelectedOffices.match(action)) {
    // 過去の実装との互換性のため、グローバル変数の更新が必要
    globalSelectedOffices.value = action.payload

    const userId = store.getState().session.currentUser.id
    if (userId) {
      saveSelectedOfficesToStorage(userId, action.payload)
    }
  }
}
