import React, {Component} from 'react'
import {connect} from 'react-redux'
import api from 'api'
import NaviForm from 'employees/NaviForm'
import {withFormSelectors, withFormSelectorsProvider} from 'employees/form/common'
import {push} from 'connected-react-router'
import {actionCreators, fetchSelector} from 'actions'
import {loadEmployee} from 'accountSettings/Profile'
import Button from 'jbc-front/components/Button'
import {updateEmployeeDetail} from 'employees/Update'
import {mapDispatchToProps as mapDispatchToPropsOfLayout} from 'Layout'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class ProfileEditForAdmin extends Component {
  componentDidMount() {
    const {
      token,
      client,
      loadData,
      loadFieldGroup,
      currentUser: {employee},
      history,
    } = this.props
    if (!employee) {
      history.replace('/account_settings/account')
    } else {
      loadData(employee.id, token)
      loadFieldGroup(token, client)
    }
  }

  componentDidUpdate(prevProps) {
    const {fields} = this.props
    const name = this.props.location.hash.slice(1)
    if (name && prevProps.fields !== fields && this._form) {
      const {current} = this._form.state
      if (name !== current) {
        this._form.scrollByHash()
      }
    }
  }

  handleSubmit = async (values) => {
    const {token, updateEmployee, fields, fetchCurrentUser} = this.props
    const errors = await updateEmployee(values, token, fields)
    if (!errors) {
      await fetchCurrentUser(token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      employee,
      fieldGroups,
      employeeFieldGroups,
      submit,
      submitting,
      pristine,
      currentUser: {id},
      selector,
    } = this.props

    return (
      id && (
        <NaviForm
          data={employee}
          fieldGroups={fieldGroups}
          employeeFieldGroups={employeeFieldGroups}
          ref={(form) => (this._form = form)}
          onSubmit={this.handleSubmit}
          selector={selector}
          needDirty={true}
          submitText="更新"
          naviSubmit={
            <Button primary disabled={submitting || pristine} onClick={submit} widthWide>
              更新
            </Button>
          }
        />
      )
    )
  }
}

const mapStateToProps = (state, {isSubmitting, isPristine}) => ({
  employee: state.employees.current.data,
  submitting: isSubmitting(state),
  pristine: isPristine(state),
  currentUser: state.session.currentUser,
  fields: fetchSelector(state, 'custom_fields').data,
  token: state.auth.token,
  client: state.client.current,
  location: state.router.location,
  fieldGroups: fetchSelector(state, 'custom_field_groups').data,
  employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
})

const mapDispatchToProps = (dispatch, {submit}) => ({
  loadData(id, token) {
    dispatch(actionCreators.employees.current.fetchData(loadEmployee(id, token)))
  },
  loadFieldGroup(token, client) {
    dispatch(actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list()))
    dispatch(
      actionCreators.fetchData(
        'custom_field_groups',
        api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
      )
    )
    dispatch(
      actionCreators.fetchData(
        'custom_fields',
        api.createWithAuth(token).employeeCustom.fields.list(client.id, {
          embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
        })
      )
    )
  },
  destroy() {
    dispatch(actionCreators.employees.current.destroy())
    dispatch(actionCreators.procedureStatuses.tmpData.destroy())
    dispatch(actionCreators.fetchDestroy('employee_field_groups'))
    dispatch(actionCreators.fetchDestroy('custom_field_groups'))
    dispatch(actionCreators.fetchDestroy('custom_fields'))
  },
  async updateEmployee(values, token, fields) {
    try {
      const employeeId = values.employee.id
      await updateEmployeeDetail(employeeId, values, token, undefined, fields)
      dispatch(push(`/account_settings/profile`))
      dispatch(notifySuccess('更新しました'))
    } catch (err) {
      dispatch(asyncError(err))
      return err
    }
  },
  fetchCurrentUser: mapDispatchToPropsOfLayout(dispatch).fetchCurrentUser,
  submit: () => {
    dispatch(submit)
  },
})

export default ProfileEditForAdmin
  |> connect(mapStateToProps, mapDispatchToProps)
  |> withFormSelectors
  |> withFormSelectorsProvider
