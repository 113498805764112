import {gql} from '@apollo/client'

export type Order = 'asc' | 'desc'
export type SortColumn = 'staff_code' | 'full_name_kana' | 'office' | 'group' | 'employment_type' | 'joined_at'

export type Search = {
  sortOrder?: Order
  sortColumn?: SortColumn
  q?: string
}

export type QueryVariables = {
  id: number
  per: number
  page: number
  search: Search
}

export type QueryResult = {
  client: Client
}

type Client = {
  id: number
  document: Document
}

export type Document = {
  id: number
  documentEmployeesPublishedCount: number
  documentEmployeesCount: number
  dynamicDocument: DynamicDocument
  searchDocumentEmployees: {
    totalCount: number
    list: SearchDocumentEmployee[]
  }
}

type DynamicDocument = {
  id: number
  previewable: boolean
  downloadable: boolean
}

export type SearchDocumentEmployee = {
  id: number
  staffCode: string
  lastname: string
  firstName: string
  displayLastName: string
  displayFirstName: string
  lastNameKana: string
  firstNameKana: string
  joinedAt: string
  invitationStatus: InvitationStatus
  personnelHistory: PersonnelHistory
  documentEmployee: DocumentEmployee
}

type InvitationStatus = 'not_invited' | 'invited' | 'only_enrollment_input' | 'registered'

type PersonnelHistory = {
  officeName: string
  employmentTypeName: string
  group0Name: string
  group1Name: string
  group2Name: string
}

type DocumentEmployee = {
  id: number
  firstViewedAt: string
  isPublished: boolean
}

export const DOCUMENT_EMPLOYEES = gql`
  query searchDocumentEmployees($id: ID!, $per: Int, $page: Int, $search: EmployeeSearchInput) {
    client {
      id
      document(id: $id) {
        id
        documentEmployeesPublishedCount
        documentEmployeesCount
        dynamicDocument {
          id
          previewable
          downloadable
        }
        searchDocumentEmployees(per: $per, page: $page, search: $search) {
          totalCount
          list {
            id
            staffCode
            lastName
            firstName
            displayLastName
            displayFirstName
            lastNameKana
            firstNameKana
            joinedAt
            invitationStatus
            personnelHistory {
              officeName
              employmentTypeName
              group0Name
              group1Name
              group2Name
            }
            documentEmployee(documentId: $id) {
              id
              firstViewedAt
              isPublished
            }
          }
        }
      }
    }
  }
`

export const IS_SENT_DOCUMENT_CONTRACT = gql`
  query isSentDocumentContract($id: ID!) {
    client {
      id
      planType
      document(id: $id) {
        id
        contractSent
      }
    }
  }
`

export const UPDATE_DOCUMENT_PUBLISHED = gql`
  mutation updateDocumentPublished($input: UpdateDocumentPublishedInput!) {
    updateDocumentPublished(input: $input) {
      document {
        id
      }
    }
  }
`

export const UPDATE_DOCUMENT_UNPUBLISHED = gql`
  mutation updateDocumentUnpublished($input: UpdateDocumentUnpublishedInput!) {
    updateDocumentUnpublished(input: $input) {
      document {
        id
      }
    }
  }
`

export const UPDATE_ALL_DOCUMENT_EMPLOYEE_PUBLISHED = gql`
  mutation UpdateAllDocumentEmployeePublished($input: UpdateAllDocumentEmployeePublishedInput!) {
    updateAllDocumentEmployeePublished(input: $input) {
      document {
        id
      }
    }
  }
`

export const UPDATE_ALL_DOCUMENT_EMPLOYEE_UNPUBLISHED = gql`
  mutation UpdateAllDocumentEmployeeUnpublished($input: UpdateAllDocumentEmployeeUnpublishedInput!) {
    updateAllDocumentEmployeeUnpublished(input: $input) {
      document {
        id
      }
    }
  }
`
