import React, {useEffect, useState} from 'react'
import styles from './SelectList.scss'
import fp from 'lodash/fp'
import {connect} from 'react-redux'
import {notifyError} from 'store/actions/notify'

const Hint = ({onClick, children}) => {
  useEffect(() => {
    document.addEventListener('click', onClick, true)
    return () => {
      document.removeEventListener('click', onClick, true)
    }
  }, [onClick])
  return (
    <div className={styles.hint}>
      <div className={styles.hintShape} />
      <div className={styles.hintMessage}>{children}</div>
    </div>
  )
}

export const Td = ({onClick, selected, disabledMessage}) => {
  const [showHint, setShowHint] = useState(false)
  function hideHint() {
    setShowHint(false)
  }
  const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
  const disabledClass = disabledMessage ? ' u-cur-notallowed' : ' u-cur-pointer'
  return (
    <td
      onClickCapture={(e) => {
        e.stopPropagation()
        disabledMessage ? setShowHint(true) : onClick()
      }}
      className={selectedClass + disabledClass}
      style={{width: 50, flexBasis: 50, minWidth: 50}}
    >
      {showHint && <Hint onClick={hideHint}>{disabledMessage}</Hint>}
      <input type="checkbox" checked={selected} className="u-cur-pointer" readOnly />
    </td>
  )
}

export const Th = ({onClick, selected}) => {
  const allClickClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
  return (
    <th
      onClick={onClick}
      className={allClickClass + ' m-table-list-check u-cur-pointer'}
      style={{width: 50, flexBasis: 50, minWidth: 50}}
    >
      <input type="checkbox" readOnly checked={selected} />
    </th>
  )
}

const SelectList = ({
  disabledMessage,
  shouldSelectByAll = (item) => !disabledMessage(item),
  list,
  children,
  isTable,
  selectedInfo,
  maxCount,
  overMaxCountMessage,
  dispatch,
}) => {
  const [selected, setSelected] = useState({})
  const toggle = (item) => {
    const selectedCount = fp.reduce((sum, value) => (value ? sum + 1 : sum), 0, selected)
    if (selected[item.id]) {
      setSelected(fp.unset(item.id, selected))
    } else if (selectedCount >= maxCount) {
      dispatch(notifyError(overMaxCountMessage))
    } else {
      setSelected(fp.set(item.id, selectedInfo(item), selected))
    }
  }

  const allSelected = () =>
    list.some((item) => selected[item.id]) && list.every((item) => !shouldSelectByAll(item) || selected[item.id])

  const selectAll = () => {
    if (allSelected()) {
      setSelected(Object.assign({}, selected, ...list.map((item) => ({[item.id]: false}))))
    } else {
      const selectedCount = fp.reduce((sum, value) => (value ? sum + 1 : sum), 0, selected)
      const added = list.filter((item) => shouldSelectByAll(item) && !selected[item.id])
      if (selectedCount + added.length > maxCount) {
        dispatch(notifyError(overMaxCountMessage))
      }
      if (selectedCount < maxCount) {
        setSelected(
          Object.assign(
            {},
            selected,
            ...(added |> fp.take(maxCount - selectedCount) |> fp.map((item) => ({[item.id]: selectedInfo(item)})))
          )
        )
      }
    }
  }

  const reset = () => {
    setSelected({})
  }

  return children({
    list: list.map((item) => ({
      item,
      selected: !!selected[item.id],
      toggle: () => toggle(item),
      td: isTable && (
        <Td disabledMessage={disabledMessage(item)} selected={!!selected[item.id]} onClick={() => toggle(item)} />
      ),
    })),
    selected,
    reset,
    selectAll,
    th: isTable && <Th selected={allSelected()} onClick={selectAll} />,
  })
}

SelectList.defaultProps = {
  disabledMessage: () => null,
  isTable: true,
  selectedInfo: () => true,
  maxCount: Infinity,
}

export default connect()(SelectList)
