import React from 'react'
import {connect} from 'react-redux'
import ListGroup from 'jbc-front/components/ListGroup'
import {Link} from 'react-router-dom'
import {recordDisplay} from 'utils'
import {showPreviewPdf} from 'notifications/List'
import styles from 'notifications/List.scss'
import PreviewPdf from 'components/PreviewPdf'

const DATE_WITH_YEAR_LENGTH = 9

const Notification = ({notification, token, showPreviewPdf}) => {
  const dateOrTime = recordDisplay.dateOrTime(notification.notification_content.created_at)
  return (
    <ListGroup.Item
      key={notification.id}
      {...(notification.notification_content.link
        ? {
            ...(notification.notification_content.link.startsWith('http')
              ? {href: notification.notification_content.link, as: 'a'}
              : {
                  to: notification.notification_content.link,
                  as: Link,
                }),
            target: notification.notification_content.target_blank ? '_blank' : undefined,
          }
        : notification.notification_content.type === 'FileUploadNotification'
        ? {
            as: 'a',
            onClick: () => {
              showPreviewPdf(notification.notification_content, token)
            },
          }
        : {})}
    >
      <span className={dateOrTime.length < DATE_WITH_YEAR_LENGTH ? styles.date : styles.dateWithYear}>
        {dateOrTime}
      </span>
      <span className={styles.notificationTitle}>{notification.notification_content.title}</span>
      {notification.notification_content.link ? <ListGroup.Icon /> : null}
    </ListGroup.Item>
  )
}

export const Notifications =
  (({notifications, token, showPreviewPdf, title, styles}) => {
    return (
      <ListGroup>
        <PreviewPdf />
        <div className={styles.card}>
          <div className={[styles.card_header, styles.insideBtnCardHeader].join(' ')}>
            <ListGroup.Title>
              {title}
              <div className={styles.more}>
                <Link to="/notifications" className={notifications.length > 0 ? styles.more_btn : styles.disabled}>
                  すべて見る
                </Link>
              </div>
            </ListGroup.Title>
          </div>
          <div className={styles.card_body}>
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notification={notification}
                  token={token}
                  showPreviewPdf={showPreviewPdf}
                />
              ))
            ) : (
              <ListGroup.Item className={styles.empty}>{title}はまだありません</ListGroup.Item>
            )}
          </div>
        </div>
      </ListGroup>
    )
  })
  |> connect(
    (state) => ({
      token: state.auth.token,
    }),
    (dispatch) => ({showPreviewPdf: showPreviewPdf(dispatch)})
  )
