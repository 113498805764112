import React, {Component} from 'react'
import Form, {autofill} from 'procedures/maternityLeave/start/Form'
import {alreadyExistSameDependent} from 'procedures/maternityLeave/Form'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import moment from 'moment'
import {asyncError} from 'store/actions/asyncError'

class Detail extends Component {
  componentDidMount() {
    const {
      loadData,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    loadData(employeeId, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {employee} = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={{
          procedure_maternity_child_data: [{}],
          maternity_status: 'before',
        }}
        employee={employee}
      />
    )
  }

  handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      mother_child_note: values.maternity_status === 'after' ? values.mother_child_note : [],
    }
    const {
      employee,
      dependents,
      createProcedure,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    if (alreadyExistSameDependent(formattedValues.procedure_maternity_child_data, dependents)) {
      throw new SubmissionError({'': 'すでに同じ被扶養者が登録されています'})
    } else {
      await createProcedure(employeeId, employee, values, token)
    }
  }
}

export default connect(
  (state) => ({
    employee: state.employees.current.data,
    dependents: state.employees.current.data.employee_dependents,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadData: (id, token) => {
      dispatch(
        actionCreators.employees.current.fetchData(
          api.createWithAuth(token).employees.get(id, ['office', 'employee_dependents'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
    },
    createProcedure: async (employeeId, employee, values, token) => {
      const {
        procedure_maternity_child_data: procedureMaternityChildData,
        expected_birth_date: expectedBirthDate,
        started_at: startOn,
        expected_finished_at: endOn,
        maternity_status: maternityStatus,
      } = values
      let procedureStatusId = values.procedure_status_id
      const authedApi = api.createWithAuth(token)
      try {
        await authedApi.employees.leaveOfAbsences.updateOrCreateForMaternity(employeeId, startOn, endOn)
        if (!procedureStatusId) {
          const {
            data: {id},
          } = await authedApi.procedureStatuses.start(employeeId, 'start_maternity', expectedBirthDate, 'in_progress')
          procedureStatusId = id
          await authedApi.procedureStatuses.reports.create(
            procedureStatusId,
            employee.office.health_insurance_type === 'its' ? 'r502' : 'r501',
            {submitOn: moment()}
          )
          dispatch(autofill('procedure_status_id', procedureStatusId))
        } else {
          await authedApi.procedureStatuses.update(procedureStatusId, {date_on: expectedBirthDate})
        }
        await authedApi.procedureStatuses.startMaternityData.create(procedureStatusId, values)
        await authedApi.procedureStatuses.startProgress(procedureStatusId)
        if (
          maternityStatus === 'after' &&
          procedureMaternityChildData.some((childDatum) => childDatum.add_support && !childDatum.skip_add_support)
        ) {
          dispatch(
            push(`/add_dependents/detail?employee_id=${employeeId}&maternity_procedure_status_id=${procedureStatusId}`)
          )
        } else {
          dispatch(push(`/procedure_statuses/${procedureStatusId}`))
        }
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Detail)
