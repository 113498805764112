const actions = [
  'SET_QUERY',
  'CHANGE',
  'CLEAR',
  'EDIT',
  'TOOGLE_SELECTED',
  'CLEAR_SELECTED',
  'SELECT_MULTI',
  'CHANGE_SELECTED',
]

export default [actions, {}]
