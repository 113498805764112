import React, {useState, useEffect} from 'react'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import _ from 'lodash'
import {useSelector, useDispatch} from 'react-redux'
import {useQuery} from '@apollo/client'
import {EMPLOYEE_DEPENDENTS} from './query'
import useReactRouter from 'use-react-router'
import api from 'api'
import Form, {autofill} from 'procedures/changeDependents/add/Form'
import {actionCreators} from 'actions'
import {dependentDefaultValue} from 'employees/form/common'
import {handlerNestFormResponse} from 'libs/errorHandler'
import {asyncError} from 'store/actions/asyncError'

export const Detail = () => {
  const {search} = useReactRouter().location
  const {
    employee_id: employeeId,
    maternity_procedure_status_id: maternityProcedureStatusId,
    dependentIds,
  } = parse(search, {arrayFormat: 'bracket'})

  const {employee, procedureStatus, token} = useSelector((state) => ({
    employee: state.employees.current.data,
    procedureStatus: state.procedureStatuses.current.data,
    token: state.auth.token,
  }))
  const procedureMaternityDatum =
    procedureStatus.procedure_finish_maternity_datum ||
    procedureStatus.procedure_extend_maternity_datum ||
    procedureStatus.procedure_start_maternity_datum

  const children = _.filter(procedureMaternityDatum?.procedure_maternity_child_data, 'add_support')
  const dateOn =
    children && children.length > 0 && _.uniq(children, 'birth_date').length == 1 ? children[0].birth_date : null

  const dispatch = useDispatch()

  const loadData = (id, maternityProcedureStatusId) => {
    dispatch(actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.get(id, ['office'])))
    if (maternityProcedureStatusId) {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api.createWithAuth(token).procedureStatuses.get(maternityProcedureStatusId, ['procedure_maternity_datum'])
        )
      )
    }
  }

  const destroy = () => {
    dispatch(actionCreators.employees.current.destroy())
  }

  const createProcedure = async ({
    dependents,
    marital_status,
    spouse_annual_income,
    spouse_monthly_income,
    date_on: dateOn,
    procedure_status_id: procedureStatusId,
  }) => {
    const authedApi = api.createWithAuth(token)
    try {
      if (!procedureStatusId) {
        const {
          data: {id},
        } = await authedApi.procedureStatuses.start(employeeId, 'add_dependents', dateOn)
        procedureStatusId = id
        dispatch(autofill('procedure_status_id', procedureStatusId))
      } else {
        await authedApi.procedureStatuses.update(procedureStatusId, {date_on: dateOn})
      }

      await authedApi.procedureStatuses.addDependentsData
        .updateAll(procedureStatusId, dependents)
        .catch(handlerNestFormResponse('dependents'))
      await authedApi.employees.update(employeeId, {marital_status, spouse_annual_income, spouse_monthly_income})
      dispatch(push(`/add_dependents/flow/${procedureStatusId}`))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  const {loading, data: dependentsData} = useQuery(EMPLOYEE_DEPENDENTS, {
    variables: {employeeId: employeeId, dependentIds},
  })

  const [dependents, setDependents] = useState(
    children
      ? children.map((child) => ({
          ...dependentDefaultValue(),
          first_name: child.first_name,
          first_name_kana: child.first_name_kana,
          last_name: child.last_name,
          last_name_kana: child.last_name_kana,
          birthday: child.birth_date,
          dependent_reason: '出生',
          relation_type: 'other',
          job: '乳児',
          annual_income: '0',
          annual_earnings: '0',
        }))
      : [dependentDefaultValue()]
  )

  useEffect(() => {
    loadData(employeeId, maternityProcedureStatusId)
    return () => destroy()
  }, [])

  const toString = (value) => {
    return value === null || value === undefined ? undefined : String(value)
  }

  const toFile = (file) => {
    return file.filename === null && file.url === null ? null : file
  }

  useEffect(() => {
    let isMounted = true

    if (!loading) {
      const fetchedDependentsData = dependentsData.client.employee.employeeDependentsWhereByIds.map((dependent) => {
        return {
          ...dependentDefaultValue(),
          id: dependent.id,
          first_name: dependent.firstName,
          last_name: dependent.lastName,
          first_name_kana: dependent.firstNameKana,
          last_name_kana: dependent.lastNameKana,
          birthday: dependent.birthday,
          gender: dependent.gender,
          relation_other: dependent.relationName,
          relation_type: dependent.relationType,
          relation_report_type: dependent.relationReportType,
          residence_status: dependent.residenceStatus,
          domestic_remittance: toString(dependent.domesticRemittance),
          remittance: toString(dependent.remittance),
          prefecture_id: dependent.prefecture?.id,
          postcode_0: dependent.postcode0,
          postcode_1: dependent.postcode1,
          city: dependent.city,
          house_number: dependent.houseNumber,
          building: dependent.building,
          address_kana: dependent.addressKana,
          address_foreign: dependent.addressForeign,
          job: dependent.job,
          phone_number_0: dependent.phoneNumber0,
          phone_number_1: dependent.phoneNumber1,
          phone_number_2: dependent.phoneNumber2,
          basic_pension_number_0: dependent.basicPensionNumber0,
          basic_pension_number_1: dependent.basicPensionNumber1,
          national_type: dependent.nationalType,
          romaji_name: dependent.romajiName,
          romaji_name_kana: dependent.romajiNameKana,
          dependent_reason: dependent.dependentReason,
          annual_income: toString(dependent.annualIncome),
          annual_earnings: toString(dependent.annualEarnings),
          handicap_type: dependent.handicapType,
          handicap_detail: dependent.handicapDetail,
          handicap_certificate: toFile(dependent.handicapCertificate),
          dependent_tax_law: dependent.dependentTaxLaw,
          is_non_resident: dependent.isNonResident,
          is_study_abroad: dependent.isStudyAbroad,
          related_to_relatives_document: toFile(dependent.relatedToRelativesDocument),
          related_to_remittance_document: toFile(dependent.relatedToRemittanceDocument),
          proving_study_abroad_document: toFile(dependent.provingStudyAbroadDocument),
        }
      })

      if (isMounted) {
        setDependents(fetchedDependentsData)
      }
    }

    return () => {
      isMounted = false
    }
  }, [loading])

  return (
    <Form
      onSubmit={(values) => createProcedure(values)}
      initialValues={{
        dependents: dependents,
        marital_status: employee.marital_status,
        spouse_annual_income: employee.spouse_annual_income,
        spouse_monthly_income: employee.spouse_monthly_income,
        date_on: dateOn,
      }}
      employee={employee}
    />
  )
}

export default Detail
