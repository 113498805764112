import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {Section, TextField, NumberFields, SelectField, Label, BoxDouble} from 'jbc-front/components/Form'
import {Copy, DeleteSquare} from 'jbc-front/components/icons'
import * as validators from 'validators'
import {connect} from 'react-redux'
import {reduxForm, formValueSelector, autofill, FormSection} from 'redux-form'
import {TaxOffice} from 'features/offices/TaxOffice'
import {HelloWork} from 'features/offices/HelloWork'
import {ClientName, CorporateNumber, Address, Phone, Representative} from 'FormFields'
import {toFormValues} from 'utils'
import _ from 'lodash'
import {useSelector} from 'hooks/redux'
import {useFormName} from 'hooks/reduxForm'

const healthInsuranceTypes = [
  {value: 'kyokai', label: '協会けんぽ'},
  {value: 'its', label: '関東ITソフトウェア健康保険組合'},
  {value: 'other', label: 'その他の組合健保'},
]

const formName = 'clientForm'
const selector = formValueSelector(formName)

const daysOfMounth = [
  ..._.range(1, 31).map((day) => ({value: `${day}`, label: `${day}日`})),
  {value: '-1', label: '末日'},
]

const mouthTypes = [
  {value: 'current', label: '当月'},
  {value: 'next', label: '翌月'},
]

const defaultMainOfficeSocial = {office_contact_info_type: 'main_office_social'}
const defaultLabor = {office_contact_info_type: 'labor'}
const defaultOwner = {office_contact_info_type: 'owner'}

export const validate = (values) => {
  const errors = {}
  const salaryPaymentErrorMsg = '（給与の支給日）を正しく入力してください'

  if ((!values.salary_payment_month_type || values.salary_payment_month_type === '-1') && values.salary_payment_date) {
    errors.salary_payment_month_type = salaryPaymentErrorMsg
  } else if (
    values.salary_payment_month_type &&
    values.salary_payment_month_type !== '-1' &&
    !values.salary_payment_date
  ) {
    errors.salary_payment_date = salaryPaymentErrorMsg
  }
  return errors
}

export const makeInitialValues = (currentOffice) => {
  const initialValues = toFormValues(currentOffice)
  const mainOfficeSocial = toFormValues(
    _.find(_.get(currentOffice, 'office_contact_infos'), {
      office_contact_info_type: 'main_office_social',
    })
  )
  const labor = toFormValues(
    _.find(_.get(currentOffice, 'office_contact_infos'), {
      office_contact_info_type: 'labor',
    })
  )
  const owner = toFormValues(
    _.find(_.get(currentOffice, 'office_contact_infos'), {
      office_contact_info_type: 'owner',
    })
  )

  initialValues.office_contact_infos = []
  initialValues.office_contact_infos.push(!_.isEmpty(mainOfficeSocial) ? mainOfficeSocial : defaultMainOfficeSocial)
  initialValues.office_contact_infos.push(!_.isEmpty(labor) ? labor : defaultLabor)
  initialValues.office_contact_infos.push(!_.isEmpty(owner) ? owner : defaultOwner)

  return initialValues
}

const copyFields = [
  'name',
  'name_kana',
  'postcode_0',
  'postcode_1',
  'prefecture_id',
  'city',
  'house_number',
  'building',
  'address_kana',
  'phone_number_0',
  'phone_number_1',
  'phone_number_2',
]

const BasicInformation = () => {
  const taxOfficePrefectureId = useSelector((state) => selector(state, 'tax_office_prefecture_id'))
  return (
    <Section title="事業所情報">
      <FormSection name="office_contact_infos[0]">
        <ClientName />
      </FormSection>
      <CorporateNumber />
      <FormSection name="office_contact_infos[0]">
        <Address officeContactInfo={true} />
        <Label text="事業主（代表者）" />
        <Representative />
        <Phone />
      </FormSection>
      <TaxOffice prefectureId={taxOfficePrefectureId} />
    </Section>
  )
}

class renderLaborInsurance extends Component {
  state = {
    enableInput: false,
  }

  componentDidMount() {
    const {laborFieldValues, ownerFieldValues} = this.props

    if (laborFieldValues !== defaultLabor || ownerFieldValues !== defaultOwner) {
      this.setState({enableInput: true})
    }
  }

  componentDidUpdate(prevProps) {
    // 労働保険情報の事業所情報の入力値を事業主情報に反映
    const {dispatch, laborFieldValues} = this.props
    const diff = _.omitBy(
      laborFieldValues,
      (value, key) => prevProps.laborFieldValues && prevProps.laborFieldValues[key] === value
    )

    if (!_.isEmpty(diff)) {
      _.forIn(diff, (value, key) => {
        dispatch(autofill(formName, `office_contact_infos[2].${key}`, value))
      })
    }
  }

  handleEnable = () => {
    this.setState({enableInput: true})
  }

  handleDisable = () => {
    const {dispatch} = this.props

    // 労働保険情報の事業所情報、事業主情報を消す
    dispatch(autofill(formName, 'office_contact_infos[1]', defaultLabor))
    dispatch(autofill(formName, 'office_contact_infos[2]', defaultOwner))
    this.setState({enableInput: false})
  }

  handleCopy = () => {
    const {dispatch, mainOfficeSocialFieldValues} = this.props

    // 事業所情報を労働保険情報の事業所情報、事業主情報にコピー
    copyFields.forEach((field) => {
      dispatch(autofill(formName, `office_contact_infos[1].${field}`, mainOfficeSocialFieldValues[field]))
    })
    dispatch(
      autofill(formName, 'office_contact_infos[2].rep_position_name', mainOfficeSocialFieldValues.rep_position_name)
    )
    dispatch(autofill(formName, 'office_contact_infos[2].rep_last_name', mainOfficeSocialFieldValues.rep_last_name))
    dispatch(autofill(formName, 'office_contact_infos[2].rep_first_name', mainOfficeSocialFieldValues.rep_first_name))
  }

  render() {
    return (
      <Section title="労働保険情報" icon={this.state.enableInput && <DeleteSquare onClick={this.handleDisable} />}>
        {this.state.enableInput ? (
          <React.Fragment>
            <p className="u-mb15">
              労働保険の事業所情報と社会保険の事業所情報が異なる場合、ここに別途入力してください。
              <br />
              労働保険と社会保険の事業所情報が同一の場合、右上のゴミ箱ボタンを押してフォームを閉じてください。
            </p>
            <ActionButton onClick={this.handleCopy} className="u-mb20" icon={<Copy />}>
              事業所情報をコピーする
            </ActionButton>
            <Section title="事業所情報">
              <FormSection name="office_contact_infos[1]">
                <ClientName />
                <Address />
                <Phone />
              </FormSection>
            </Section>
            <Section title="事業主情報">
              <FormSection name="office_contact_infos[2]">
                <Label text="事業主（代表者）" />
                <Representative />
                <ClientName label="事業主名称" />
                <Address />
                <Phone />
              </FormSection>
            </Section>
            <LabelMapper name="name" label="事業所名 / 事業主名称" />
            <LabelMapper name="name_kana" label="事業所名（カナ）/ 事業主名称（カナ）" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ActionButton onClick={this.handleEnable} className="u-mb15">
              労働保険事業所情報を別途入力
            </ActionButton>
            <p>
              労働保険の事業所情報と社会保険の事業所情報が異なる場合、このボタンをクリックして別途入力してください。
              <br />
              ※労働保険と社会保険の事業所情報が同一の場合は入力の必要はありません。
            </p>
          </React.Fragment>
        )}
      </Section>
    )
  }
}

const LaborInsurance = connect((state) => ({
  mainOfficeSocialFieldValues: selector(state, 'office_contact_infos[0]'),
  laborFieldValues: selector(state, 'office_contact_infos[1]'),
  ownerFieldValues: selector(state, 'office_contact_infos[2]'),
}))(renderLaborInsurance)

export const SocialInsurance = () => (
  <Section title="厚生年金保険情報">
    <NumberFields
      prefix="social_insurance_reference_code"
      texts={['', ' - ']}
      lengths={[2, 4]}
      needNumber={false}
      label="厚生年金保険 記号（事業所整理記号）"
      note="「数字2ケタ-カタカナまたは英数4ケタ以内」の形式です。年金事務所から送付された納付告知書などに記載されています。"
    />
    <TextField
      name="social_insurance_office_number"
      validate={[validators.number, validators.exactLengthNumber(5)]}
      label="厚生年金保険 番号（事業所番号）"
      note="年金事務所から付与される5桁の数字です。「適用通知書」や「保険料納入告知額・領収済額通知書」などに記載されています。"
    />
  </Section>
)

const renderHealthInsurance = ({healthInsuranceType}) => (
  <Section title="健康保険情報">
    <SelectField
      name="health_insurance_type"
      label="健康保険タイプ"
      options={healthInsuranceTypes}
      required
      note={
        <p>
          「協会けんぽ」「関東ITソフトウェア健康保険組合」以外にご加入の事業所の場合は、「その他の組合健保」を選択してください。詳細は
          <a
            href="https://jobcan-lms.zendesk.com/hc/ja/articles/115002715232#Contents2"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
        </p>
      }
    />
    <TextField
      name="health_insurance_reference_code"
      label="健康保険記号（事業所整理記号）"
      validate={[validators.number, validators.maxLength(healthInsuranceType === 'kyokai' ? 8 : 4)]}
      note="健康保険証に「記号」として表記されている数字です。協会けんぽは7~8桁、関東ITソフトウェア健康保険組合やその他の組合健保の場合は4桁になります。"
      noteWidth={310}
    />
  </Section>
)

export const HealthInsurance = connect((state, {formName}) => {
  const selector = formValueSelector(formName)
  return {
    healthInsuranceType: selector(state, 'health_insurance_type'),
  }
})(renderHealthInsurance)

export const EmploymentInsurance = () => {
  const formName = useFormName()
  const helloWorkPrefectureId = useSelector((state) => formValueSelector(formName)(state, 'hello_work_prefecture_id'))
  return (
    <Section title="雇用保険情報">
      <HelloWork prefectureId={helloWorkPrefectureId} />
      <NumberFields
        prefix="employment_insurance_office_number"
        texts={['', ' - ']}
        lengths={[4, 6, 1]}
        exactLength
        label="雇用保険 番号（事業所番号）"
      />
      <NumberFields
        prefix="labor_insurance_number"
        texts={['', ' - ']}
        lengths={[2, 1, 2, 6, 3]}
        exactLength
        label="労働保険番号"
      />
    </Section>
  )
}

export const RemunerationInformation = () => (
  <Section title="給与締め日・支給日情報">
    <SelectField name="salary_closing_date" label="給与の締め日" options={daysOfMounth} />
    <Label text="給与の支給日" />
    <BoxDouble>
      <SelectField name="salary_payment_month_type" label="月" options={mouthTypes} />
      <SelectField name="salary_payment_date" label="日" options={daysOfMounth} />
    </BoxDouble>
  </Section>
)

const base = ({handleSubmit, pristine, submitting}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">法人情報の入力</h1>
    </div>
    <LabelMapper name="office_contact_infos" label="事業所情報" />
    <div>
      <FormErrors />
      <BasicInformation />
      <LaborInsurance />
      <HealthInsurance formName={formName} />
      <SocialInsurance />
      <EmploymentInsurance />
      <RemunerationInformation />
      <div className="u-ta-c u-mt30">
        <Button primary onClick={handleSubmit} disabled={pristine || submitting}>
          更新
        </Button>
      </div>
    </div>
  </form>
)

export default reduxForm({
  form: formName,
  validate,
  enableReinitialize: true,
  onSubmitFail,
})(base)
