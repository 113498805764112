import {gql} from '@apollo/client'

export const DOCUMENT_SHARED_TEMPLATES = gql`
  query documentSharedTemplates {
    documentSharedTemplateCategories {
      id
      name
      publishedSharedTemplates {
        id
        title
        description
        downloadFilename
        thumbnail {
          url
        }
      }
    }
  }
`

export const IMPORT_FROM_DOCUMENT_SHARED_TEMPLATE = gql`
  mutation importFromDocumentSharedTemplate($input: ImportFromDocumentSharedTemplateInput!) {
    importFromDocumentSharedTemplate(input: $input) {
      clientMutationId
    }
  }
`

export interface DocumentSharedTemplateCategory {
  id: string
  name: string
  publishedSharedTemplates: DocumentSharedTemplate[]
}

export interface DocumentSharedTemplate {
  id: string
  title: string
  description?: string
  downloadFilename?: string
  thumbnail?: {
    url: string
  }
}
