import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import {UPDATE_DOCUMENT_UNPUBLISHED, EMPLOYEE_DOCUMENTS, Search} from '../../../query'
import styles from './PublishModal.scss'
import {notifySuccess} from 'store/actions/notify'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface UnPublishModalProps {
  documentId: number
  employeeId: number
  search: Search
}

export const UnPublishModal: FC<UnPublishModalProps> = ({documentId, employeeId, search}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const [unpublished, {loading}] = useMutation(UPDATE_DOCUMENT_UNPUBLISHED)
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    await unpublished({
      variables: {
        input: {
          id: documentId,
          employeeIds: [employeeId],
        },
      },
      refetchQueries: [
        {
          query: EMPLOYEE_DOCUMENTS,
          variables: {id: Number(employeeId), search},
          fetchPolicy: 'network-only',
        },
      ],
    })
    setIsOpen(false)
    dispatch(notifySuccess('書類を非公開にしました'))
  }

  return (
    <>
      <ActionButton onClick={() => handleOpen()}>公開取消</ActionButton>
      <Modal isOpen={isOpen} onClose={handleClose} className={styles.modal}>
        <Modal.Header onClose={handleClose}>書類の公開を取り消す</Modal.Header>
        <Modal.Body>
          <p>公開を取り消すと従業員がマイページから書類を閲覧できなくなります。書類公開を取り消しますか？</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={handleClose}>いいえ</Button>
            <Button primary disabled={loading} onClick={handleSubmit}>
              はい
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </>
  )
}
