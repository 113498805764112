import {FC} from 'react'
import {IconType, PostitOn, Postit} from 'jbc-front/components/icons'
import classnames from 'classnames'
import styles from './Icon.scss'

export type IconProps = {
  color?: ColorType
  className?: string | string[]
  fill?: boolean
  size?: number
  rest?: IconType
  badge?: boolean
}

type ColorType = 'blue' | 'yellow' | 'pink' | 'green' | 'purple' | 'orange'

export const Icon: FC<IconProps> = ({badge, ...rest}) => {
  return (
    <div className={styles.iconWrapper}>
      {badge && <div className={styles.badge} />}
      <MemoIcon {...rest} />
    </div>
  )
}

const MemoIcon: FC<IconProps> = ({color = 'blue', className, fill = false, size = 40, rest}) => {
  if (fill) {
    return <PostitOn className={classnames(styles[color], styles.icon, className)} size={size} {...rest} />
  } else {
    return <Postit className={classnames(styles.icon, className)} size={size} {...rest} />
  }
}
