import React from 'react'
import {MdGrade} from 'jbc-front/components/react-icons'

const colors = ['#ccc', '#f6a623', '#9b59b6', '#00c4ae']

const StarFlag = ({onClick, colorIndex}) => {
  const color = colors[colorIndex % colors.length]

  if (!color) {
    throw new Error('no match color')
  }

  return <MdGrade size={20} color={color} onClick={onClick} />
}

export default StarFlag
