import React from 'react'
import {useSelector} from 'react-redux'
import {parse} from 'query-string'
import Errors from 'dashboard/Errors'
import {Setups} from 'dashboard/Setups'
import ProcedureStatuses from 'dashboard/admin/ProcedureStatuses'
import Notifications from 'dashboard/Notifications'
import SystemAnnounces from 'dashboard/SystemAnnounces'
import Alerts from 'dashboard/Alerts'
import Plan from 'dashboard/Plan'
import Profile from 'dashboard/admin/Profile'
import {isOfficeAdminSelector} from 'utils'
import styles from './dashboard.scss'
import SurveyModal from 'SurveyModal'
import Tutorial from './Tutorial'
import Procedures from './admin/Procedures'
import {YearEndAdjustmentLink} from 'components/ui/YearEndAdjustmentLink'

const DashboardForAdmin = ({search}) => {
  const employee = useSelector((state) => state.session.currentUser.employee)
  const isOfficeAdmin = useSelector((state) => isOfficeAdminSelector(state))
  const planType = useSelector((state) => state.client.current.plan_type)

  return (
    <section className={styles.is_admin}>
      <section>
        <article>
          <Plan />
        </article>
      </section>

      {!isOfficeAdmin && planType !== 'free_plan' && (
        <YearEndAdjustmentLink isOfficeAdmin={isOfficeAdmin} planType={planType} shouldRender={false} />
      )}

      <section>
        <section>
          <SurveyModal />
          {parse(search).error && <Errors />}
          {!isOfficeAdmin && <Setups />}
        </section>

        <section className={styles.main_grid}>
          <SystemAnnounces />
          <article className={styles.notificationsForAdmin}>
            <Notifications isAdmin={true} />
          </article>

          <Alerts />
          <Procedures />
          <ProcedureStatuses />
          <Tutorial />
          {employee && <Profile employee={employee} />}
        </section>
      </section>
    </section>
  )
}

export default DashboardForAdmin
