import type {RouteParams} from 'types/routes'
import {WITHOUT_AUTH_PATHS} from 'consts/paths'
import {Completed} from 'pages/withoutAuth/registration/completed'

export const RegistrationRoutes: RouteParams[] = [
  {
    key: 'registration_completed',
    path: WITHOUT_AUTH_PATHS.REGISTRATION.COMPLETED,
    component: Completed,
    exact: true,
    whiteList: [],
  },
]
