import {actionTypes} from 'actions'
import update from 'immutability-helper'

const initialState = {
  status: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROCEDURES.SET_STATUS:
      return update(state, {status: {$set: action.payload}})
    case actionTypes.PROCEDURES.FINISH_STEP:
      return update(state, {status: {[action.payload]: {$set: true}}})
    default:
      return state
  }
}

export default reducer
