import {gql} from '@apollo/client'

export type QueryResult = {
  client: {
    id: number
    directRequestSendableGroups: DirectRequestSendableGroupType
  }
}

type DirectRequestSendableGroupType = {
  employeeFieldGroupMasters: EmployeeFieldGroupMaster[]
  customEmployeeFieldGroups: CustomEmployeeFieldGroup[]
}

export type EmployeeFieldGroupMaster = {
  id: string
  code: string
  label: string
}

export type CustomEmployeeFieldGroup = {
  id: string
  label: string
}

export const DIRECT_REQUEST_SENDABLE_GROUPS = gql`
  query directRequestSendableGroups {
    client {
      id
      directRequestSendableGroups {
        employeeFieldGroupMasters {
          id
          code
          label
        }
        customEmployeeFieldGroups {
          id
          label
        }
      }
    }
  }
`
