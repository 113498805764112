const fetchReducer =
  (actions, initialState = {}) =>
  (state = initialState, action) => {
    switch (action.type) {
      case actions.FETCH_DATA:
        return {...state, loading: true}
      case actions.FETCH_SUCCESSED:
        return {...state, loading: false, ...action.payload}
      case actions.FETCH_FAILED:
        return {...state, loading: false}
      case actions.DESTROY:
        return initialState
      default:
        return state
    }
  }
export default fetchReducer
