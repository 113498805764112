import React from 'react'
import {SectionHeader} from 'settings/ClientFiles'
import {FileTable} from 'settings/clientFiles/FileTable'
import Trash from 'settings/clientFiles/Trash'
import Upload from 'settings/clientFiles/Upload'
import styles from 'employeeFiles/Top.scss'

export const AdminFiles = ({
  files,
  trashInProcess,
  onDeleteFile,
  loadData,
  onChangeSortType,
  currentSortType,
  onChangeFileType,
}) => (
  <>
    <SectionHeader
      header="アップロード"
      body={(() => (
        <>
          10MB以下の社内規定ファイルを最大20個までアップロードできます。
          <br />
          アップロードしたファイルは全従業員がダウンロードできます。
          <br />
          例：各種手当申請書、社宅申込書等
        </>
      ))()}
    />
    <div className={styles.action}>
      <Upload loadData={loadData} />
      <Trash loadData={loadData} />
    </div>
    <FileTable
      type="uploaded"
      files={files}
      trashInProcess={trashInProcess}
      onDeleteFile={onDeleteFile}
      onChangeSortType={onChangeSortType}
      currentSortType={currentSortType}
      onChangeFileType={onChangeFileType}
    />
  </>
)
