import React, {useState, useEffect} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import Button from 'jbc-front/components/Button'
import {actionCreators} from 'actions'
import api from 'api'
import LoadingPage from 'components/LoadingPage'
import styles from 'tm/notifications/Answer.scss'
import {DeletedNotification, Notification, NotificationAnswer} from 'tm/notifications/Notification'
import classNames from 'classnames'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const Answer = ({
  match: {
    params: {id},
  },
}) => {
  const dispatch = useDispatch()
  const [
    token,
    currentTmUser,
    notification,
    loadingNotification,
    deletedNotification,
    options,
    answer,
    loadingAnswer,
    deletedAnswer,
  ] = useSelector((state) => [
    state.auth.token,
    state.session.currentTmUser,
    state.tm.notifications.current.data,
    state.tm.notifications.current.loading,
    state.tm.notifications.current.deleted,
    _.keyBy(state.tm.notifications.current.data.talent_notification_options, 'id'),
    state.tm.notifications.answer.data,
    state.tm.notifications.answer.loading,
    state.tm.notifications.answer.deleted,
  ])
  const [submitting, setSubmitting] = useState(false)
  useEffect(() => {
    dispatch(actionCreators.tm.notifications.current.fetchData(api.createWithAuth(token).tm.notifications.get(id)))
    dispatch(actionCreators.tm.notifications.answer.fetchData(api.createWithAuth(token).tm.notifications.getAnswer(id)))
    return () => {
      dispatch(actionCreators.tm.notifications.current.destroy())
      dispatch(actionCreators.tm.notifications.answer.destroy())
    }
  }, [])

  const selectedOption = options[answer.selected_option_id]
  const handleAnswerClick = async (optionId) => {
    try {
      setSubmitting(true)
      const res = await api.createWithAuth(token).tm.notifications.putAnswer(id, {selected_option_id: optionId})
      dispatch(actionCreators.tm.notifications.answer.fetchSuccessed(res))
      dispatch(actionCreators.tm.notifications.status.fetchData(api.createWithAuth(token).tm.notifications.status()))
      dispatch(notifySuccess('送信しました'))
    } catch (err) {
      if (err.response?.status === 404) {
        dispatch(notifyError('この通知は既に削除されているため、返信できません'))
      } else {
        dispatch(asyncError(err))
      }
    } finally {
      setSubmitting(false)
    }
  }

  if (
    notification.employee &&
    currentTmUser.employee &&
    notification.employee.id === currentTmUser.employee.id &&
    !notification.including_myself
  ) {
    return <Redirect to={`/tm/notifications/${id}`} />
  }

  return (
    <div className={'l-contents-wrap l-wrap-xxl ' + styles.contentsWrapSp}>
      <div className="l-breadcrumb">
        <Link to="/tm/notifications/" className="l-breadcrumb-link">
          従業員通知
        </Link>
        <span className="l-breadcrumb-here">受信した通知</span>
      </div>
      <div className={classNames(styles.createTitleWrap, 'l-title-wrap')}>
        <h1 className="m-title-main">受信した通知</h1>
      </div>
      {loadingNotification || loadingAnswer ? (
        <LoadingPage />
      ) : deletedNotification || deletedAnswer ? (
        <DeletedNotification />
      ) : (
        notification.id && (
          <>
            <Notification notification={notification} />
            <NotificationAnswer>
              <div>
                {selectedOption ? (
                  <p className={styles.answered}>「{selectedOption.label}」で返答しました</p>
                ) : (
                  <div className={styles.options}>
                    {notification.talent_notification_options.map((option) => (
                      <Button
                        primary
                        key={option.id}
                        onClick={() => handleAnswerClick(option.id)}
                        disabled={submitting}
                      >
                        {option.label}
                      </Button>
                    ))}
                  </div>
                )}
                <p className={styles.attention}>※返答後は回答の変更はできません</p>
              </div>
            </NotificationAnswer>
          </>
        )
      )}
    </div>
  )
}

export default Answer
