import React from 'react'
import {Section, RadioField, TextField} from 'jbc-front/components/Form'
import {connect} from 'react-redux'
import {number, maxLength} from 'validators'
import {isAdminSelector} from 'utils'
import {Description, getDiffFromOptions, withFormSelectors} from 'employees/form/common'

export const maritalStatusOptions = [
  {value: 'married', label: '有'},
  {value: 'single', label: '無'},
]

const Marital = ({diff = {}, marital_status, isAdmin, description, description_color_by_rgb}) => (
  <Section title="配偶者情報">
    <Description
      {...{
        description,
        description_color_by_rgb,
      }}
    />
    <RadioField
      name="marital_status"
      label="配偶者の有無"
      options={maritalStatusOptions}
      diff={getDiffFromOptions(maritalStatusOptions, diff?.marital_status)}
    />
    {marital_status === 'married' && (
      <React.Fragment>
        <TextField
          name="spouse_annual_income"
          label="配偶者の年間収入（去年）"
          validate={[number, maxLength(9)]}
          yen
          required={!isAdmin}
          note={
            !isAdmin && (
              <React.Fragment>
                配偶者が被扶養者、もしくは配偶者以外の被扶養者が存在する場合に手続き書類に記載が必要な項目です。
                <br />
                被扶養者がいない場合には直接使用することはない項目ですので、入力できない、入力したくない場合は0円などをご記入ください。
              </React.Fragment>
            )
          }
          diff={diff.spouse_annual_income}
        />
        <TextField
          name="spouse_monthly_income"
          label="配偶者の月額収入（現在）"
          validate={[number, maxLength(8)]}
          yen
          required={!isAdmin}
          diff={diff.spouse_monthly_income}
        />
      </React.Fragment>
    )}
  </Section>
)

export default Marital
  |> connect((state, {selector, isForceEmployee}) => ({
    diff: selector(state, '_diff'),
    marital_status: selector(state, 'marital_status'),
    isAdmin: !isForceEmployee && isAdminSelector(state),
  }))
  |> withFormSelectors
