import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {employmentStatuses} from 'employees/form/EmploymentInfomation'
import {Wrap, TitleArea, Title, Dl, Trhalf, Trfull, Dd, Dt} from 'tm/employees/Parts'
import * as icons from 'jbc-front/components/icons'
import {Affiliation} from 'jbc-front/components/icons'
import {wrapArrowText} from './Search'

const EmploymentInfomation = ({employee, userDisplaySetting}) => {
  const icon_name = getCategoryIconName(userDisplaySetting, 'affiliation')
  const color =
    (!_.isNull(getCategoryIconColor(userDisplaySetting, 'affiliation')) &&
      getCategoryIconColor(userDisplaySetting, 'affiliation')) ||
    '#3498db'
  const IconName = icons[icon_name]
  const style = {color: color}
  return (
    (showCategory(userDisplaySetting, 'affiliation') && (
      <Panel>
        <Item>
          <Wrap>
            <TitleArea>
              <Title>
                {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || (
                  <Affiliation size={30} color={color} />
                )}
                <div style={style}>所属</div>
              </Title>
            </TitleArea>
            <Dl>
              <Trhalf>
                <Dt>役職</Dt>
                <Dd>{recordDisplay(_.get(employee, 'personnel_history[0].position_name'))}</Dd>
              </Trhalf>

              <Trhalf>
                <Dt>職種</Dt>
                <Dd>{recordDisplay(_.get(employee, 'personnel_history[0].occupation_name'))}</Dd>
              </Trhalf>

              <Trhalf>
                <Dt>雇用形態</Dt>
                <Dd>{recordDisplay(_.get(employee, 'personnel_history[0].employment_type_name'))}</Dd>
              </Trhalf>

              <Trhalf>
                <Dt>在籍状況</Dt>
                <Dd>{recordDisplay(employmentStatuses[employee.employment_status])}</Dd>
              </Trhalf>

              <Trhalf>
                <Dt>入社日</Dt>
                <Dd>{recordDisplay.date(employee.joined_at, 'seireki')}</Dd>
              </Trhalf>

              <Trhalf>
                <Dt>勤続年数</Dt>
                <Dd>{recordDisplay.workingYears(employee)}</Dd>
              </Trhalf>

              <Trfull>
                <Dt>事業所</Dt>
                <Dd>{recordDisplay(_.get(employee, 'personnel_history[0].office_name'))}</Dd>
              </Trfull>

              {!_.isEmpty(employee.personnel_history) &&
                !_.isEmpty(_.get(employee, 'personnel_history[0].group_1_name')) && (
                  <Trfull>
                    <Dt>兼務グループ1</Dt>
                    <Dd>{wrapArrowText(_.get(employee, 'personnel_history[0].group_1_name'))}</Dd>
                  </Trfull>
                )}

              {!_.isEmpty(employee.personnel_history) &&
                !_.isEmpty(_.get(employee, 'personnel_history[0].group_2_name')) && (
                  <Trfull>
                    <Dt>兼務グループ2</Dt>
                    <Dd>{wrapArrowText(_.get(employee, 'personnel_history[0].group_2_name'))}</Dd>
                  </Trfull>
                )}
            </Dl>
          </Wrap>
        </Item>
      </Panel>
    )) ||
    null
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmploymentInfomation)
