import {FC} from 'react'
import {displayFormat, fullNameFormat, dateFormat} from 'libs/formatter'
import {Table} from 'components/ui/Table'
import {ColorLabel} from 'components/ui/ColorLabel'
import {StarFlagColumn} from './StarFlagColumn'
import {MemoColumn} from './MemoColumn'
import {ContractSendStatusColumn} from './ContractSendStatusColumn'
import {Document} from '../../query'
import {DOCUMENT_VISIVILITIES} from 'consts/document'
import {generateDynamicPath, WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import useReactRouter from 'use-react-router'

interface RowProps {
  document: Document
}

const VISIBILITIY_COLOR_MAP = {
  all_admins: 'green',
  private: 'blue',
} as const

export const Row: FC<RowProps> = ({document}) => {
  const {history} = useReactRouter()
  const fullName = (() => {
    if (document.createdUser) {
      if (document.createdUser.employee) {
        const {displayLastName, displayFirstName} = document.createdUser.employee
        return fullNameFormat(displayLastName, displayFirstName)
      }

      return document.createdUser.email
    }

    return displayFormat()
  })()

  const onMoveDetailPage = () => {
    const path = generateDynamicPath(WITH_AUTH_ADMIN_PATHS.DOCUMENTS.SHOW, [
      {pattern: 'id', replacement: String(document.id)},
    ])

    history.push(path)
  }

  return (
    <Table.Tr onClick={onMoveDetailPage} className="table-hover">
      <Table.Td>
        <StarFlagColumn document={document} />
      </Table.Td>

      <Table.Td>
        <MemoColumn document={document} />
      </Table.Td>

      <Table.Td>{dateFormat(document.createdAt, 'L')}</Table.Td>

      <Table.Td>{document.title}</Table.Td>

      <Table.Td>{document.documentEmployeesCount}</Table.Td>

      <Table.Td>
        {document.documentEmployeesPublishedCount === 0
          ? displayFormat()
          : `${document.documentEmployeesPublishedCount}/${document.documentEmployeesCount}`}
      </Table.Td>

      <Table.Td>
        <ContractSendStatusColumn status={document.contractSendStatus} />
      </Table.Td>

      <Table.Td>
        <ColorLabel color={VISIBILITIY_COLOR_MAP[document.visibility]}>
          {DOCUMENT_VISIVILITIES[document.visibility]}
        </ColorLabel>
      </Table.Td>

      <Table.Td>{fullName}</Table.Td>
    </Table.Tr>
  )
}
