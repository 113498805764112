import {FC} from 'react'
import {Link} from 'react-router-dom'
import styles from './MainOffice.scss'
import {Office} from 'jbc-front/components/icons'

interface MainOfficeProps {
  name: string
  linkTo?: string
}

export const MainOffice: FC<MainOfficeProps> = ({name, linkTo}) => (
  <div className={styles.client}>
    <Office size={30} />
    {linkTo ? (
      <Link to={linkTo} className={styles.link}>
        {name}
      </Link>
    ) : (
      <span className={styles.email}>{name}</span>
    )}
  </div>
)
