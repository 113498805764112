import React, {useState, useCallback} from 'react'
import scrollSpy from 'jbc-front/components/ScrollSpy'
import styles from 'employees/NaviForm.scss'
import {connect} from 'react-redux'
import Form from 'employees/Form'
import _ from 'lodash'
import ReviewComments from 'components/ReviewComments'
import Navi from 'employees/Navi'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {User} from 'jbc-front/components/icons'

const EmployeeName = ({currentUser, employee}) => (
  <div
    className={classNames(
      styles.photoName,
      currentUser.employee ? styles.haveJobcanHeader : styles.notHaveJobcanHeader
    )}
  >
    <Link to={`/employees/${employee.id}`} target="_blank" className={styles.linkEmployee}>
      <div className={styles.left}>
        {employee.icon?.url ? (
          <div style={{backgroundImage: `url("${employee.icon.url}")`}} className={styles.profileImg} />
        ) : (
          <User className={styles.profileImg} size={74} />
        )}
      </div>
      <div className={styles.right}>
        <span className={styles.name}>{employee.display_full_name}</span>
        <span className={styles.staffCode}>{employee.staff_code}</span>
      </div>
    </Link>
  </div>
)

const DetailForm = ({
  data,
  fieldGroups,
  employeeFieldGroups,
  employee,
  withText,
  withAlert,
  comments,
  currentUser,
  ...rest
}) => {
  const [commentHeight, setCommentHeight] = useState(0)
  const measureRefHeight = useCallback((node) => {
    if (node !== null) {
      setCommentHeight(node.clientHeight)
    }
  }, [])
  const hasComments = _.isArray(comments) && comments.length > 0
  return (
    !_.isEmpty(data) && (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">従業員情報の入力</h1>
          {withText && <div className="u-mt10">{withText}</div>}
          {withAlert && <div className={`${styles.alertText} u-mt30`}>{withAlert}</div>}
        </div>
        <ReviewComments ref={measureRefHeight} comments={comments} widthFixed />
        <div className="l-flex">
          <div className="l-flex3-sub u-ml0">
            {employee && <EmployeeName currentUser={currentUser} employee={employee} />}
            <Navi
              className={!employee && styles.notPhotoName}
              offset={
                172 +
                (employee ? 40 : 0) +
                (withText ? 30 : 0) +
                (withAlert ? 50 : 0) +
                (hasComments ? commentHeight + 30 : 0)
              }
              defaultHeight={!!employee}
              bottomOffset={216}
              employeeFieldGroups={employeeFieldGroups}
              isForm
              {...rest}
            />
          </div>
          <div className="l-flex1-main u-mr0">
            <Form {...{data, fieldGroups, employeeFieldGroups, ...rest}} />
          </div>
        </div>
      </div>
    )
  )
}

export default DetailForm
  |> scrollSpy
  |> connect((state) => ({location: state.router.location, currentUser: state.session.currentUser}), null, null, {
    forwardRef: true,
  })
