import React from 'react'
import styles from 'documents/create/Form.scss'
import {useWizard} from 'components/Wizard'
import {DocumentStep1, DocumentStep2, DocumentStep3, DocumentStep4, DocumentStepLine} from 'jbc-front/components/icons'

const stepHeaderLinkClassName = (step, current, breadcrumb) =>
  current === step
    ? styles.stepHeaderLinkColorCurrent
    : breadcrumb.includes(step)
    ? styles.stepHeaderLinkColorActive
    : styles.stepHeaderLinkColor

const StepHeaderLink = ({step, children}) => {
  const {draft, current, setStep, breadcrumb} = useWizard()
  return (
    <div>
      <p
        onClick={() => {
          if (draft.title?.length < 65) {
            setStep(step)
          }
        }}
        className={stepHeaderLinkClassName(step, current, breadcrumb)}
      >
        {children}
      </p>
    </div>
  )
}

const selectEmployeesOrAfter = (current) =>
  current === 'Confirmation' || current === 'UploadCustomVariables' || current === 'SelectEmployees'

const uploadCustomVariablesOrAfter = (current) => current === 'Confirmation' || current === 'UploadCustomVariables'

const WizardHeader = () => {
  const {current, draft} = useWizard()
  return (
    <div className="u-mb20">
      <div className={draft.templateObject?.hasCsvVariables ? styles.stepHeader : styles.stepHeaderHasNotCsv}>
        <div className={styles.stepCircle}>
          <DocumentStep1 size={36} />
        </div>
        <DocumentStepLine size={168} color={selectEmployeesOrAfter(current) ? '#3498db' : '#dbdbdb'} />
        <div className={styles.stepCircle}>
          <DocumentStep2 size={36} color={selectEmployeesOrAfter(current) ? '#3498db' : '#dbdbdb'} />
        </div>
        <DocumentStepLine size={168} color={uploadCustomVariablesOrAfter(current) ? '#3498db' : '#dbdbdb'} />
        <div className={styles.stepCircle}>
          <DocumentStep3 size={36} color={uploadCustomVariablesOrAfter(current) ? '#3498db' : '#dbdbdb'} />
        </div>
        {draft.templateObject?.hasCsvVariables && (
          <>
            <DocumentStepLine size={168} color={current === 'Confirmation' ? '#3498db' : '#dbdbdb'} />
            <div className={styles.stepCircle}>
              <DocumentStep4 size={36} color={current === 'Confirmation' ? '#3498db' : '#dbdbdb'} />
            </div>
          </>
        )}
      </div>

      <div className={draft.templateObject?.hasCsvVariables ? styles.stepHeaderLink : styles.stepHeaderLinkHasNotCsv}>
        <StepHeaderLink step="SelectTemplate">テンプレートを選択</StepHeaderLink>
        <StepHeaderLink step="SelectEmployees">対象の従業員を選択</StepHeaderLink>
        {draft.templateObject?.hasCsvVariables && (
          <StepHeaderLink step="UploadCustomVariables">書類情報を設定</StepHeaderLink>
        )}
        <StepHeaderLink step="Confirmation">書類イメージを確認</StepHeaderLink>
      </div>
    </div>
  )
}

export default WizardHeader
