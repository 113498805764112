import React, {FC} from 'react'
import Modal from 'jbc-front/components/CommonModal'
import Button from 'jbc-front/components/Button'
import {SubmitButton} from '../forms/SubmitButton'

interface Props {
  onSubmit: () => Promise<void>
  closeModal: () => void
  openModal: boolean
}

export const ConfirmUpgradeModal: FC<Props> = ({onSubmit, closeModal, openModal}) => {
  return (
    <Modal isOpen={openModal} hideModal={closeModal}>
      <Modal.Header hideModal={closeModal}>書類を最新のものと差し替えます</Modal.Header>
      <Modal.Body style={{whiteSpace: 'pre-wrap'}}>
        {`現在使用されている書式は最新ではありません。\n保存を実行すると最新の書式に差し代わり、元に戻すことができなくなります。 `}
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={closeModal}>キャンセル</Button>
          <SubmitButton primary text="保存" onSubmit={onSubmit} />
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  )
}
