import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchSelector} from 'actions'
import _ from 'lodash'
import {AnchorTarget} from 'jbc-front/components/ScrollSpy'
import {Dl, Dd, Dt, Grid, Pre} from 'employees/show/Parts'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {HeaddingWithLink} from 'employees/show/utils'
import {getPermission} from 'employees/utils'

const canEditFields = (fields, currentUser) => fields.some((field) => getPermission(field, currentUser) === 'updatable')

export const CustomField = ({field, employee, yearStyle}) => {
  const customValues = employee.employees_custom_values || []
  const value = customValues.find((value) => value.custom_employee_field_id === field.id) || {}
  switch (field.field_type) {
    case 'single_line_text':
    case 'radio_button':
    case 'pulldown':
      return <Dd>{recordDisplay(value.adjusted_value)}</Dd>
    case 'number':
      return (
        <Dd>
          {(field.allow_comma ? recordDisplay.splitComma : recordDisplay)(
            value.adjusted_value === 0 ? '0' : value.adjusted_value
          )}
        </Dd>
      )
    case 'checkbox':
      return (
        <Dd>
          <input type="checkbox" className="m-checkbox-input" readOnly checked={!!value.adjusted_value} />
          <span className="m-checkbox-parts">&nbsp;</span>
        </Dd>
      )
    case 'date':
      return <Dd>{recordDisplay.date(value.adjusted_value, yearStyle)}</Dd>
    case 'file':
      return <Dd>{recordDisplay.file(value.adjusted_value)}</Dd>
    case 'multi_line_text':
      return (
        <Dd>
          <Pre>{recordDisplay(value.adjusted_value)}</Pre>
        </Dd>
      )
  }
}

class CustomFields extends Component {
  static defaultProps = {
    fields: [],
    group: {},
  }

  render() {
    const {fields, employee, yearStyle, editLink, addAnchorTarget, currentUser, group, forProfiles} = this.props
    const fieldsMap = _.groupBy(fields, 'custom_employee_field_group_id')
    const groupFields = fieldsMap[group.id] || []

    return !_.isEmpty(groupFields) ? (
      <>
        {addAnchorTarget && <AnchorTarget name={`customFieldGroup${group.id}`} />}
        <Panel>
          <HeaddingWithLink
            to={`${editLink}#customFieldGroup${group.id}`}
            title={group.label}
            canEdit={canEditFields(groupFields, currentUser)}
          />
          <Item>
            <Grid forProfiles={forProfiles}>
              {groupFields.map((field) => (
                <Dl key={field.id}>
                  <Dt>{field.label}</Dt>
                  <CustomField employee={employee} yearStyle={yearStyle} field={field} key={field.id} />
                </Dl>
              ))}
            </Grid>
          </Item>
        </Panel>
      </>
    ) : null
  }
}

export default connect((state) => ({
  token: state.auth.token,
  client: state.client.current,
  fields: fetchSelector(state, 'custom_fields').data,
  currentUser: state.session.currentUser,
  employee: state.employees.current.data,
}))(CustomFields)
