import React from 'react'
import {useDispatch} from 'react-redux'
import {autofill, FormName} from 'redux-form'
import moment from 'moment'
import {TextField, RadioField, DateField} from 'jbc-front/components/Form'

export const ExpectedBirthDateBefore = ({
  maternityDivisionBefore,
  startedAtBefore,
  expectedFinishedAtBefore,
  withRelatedProcedure,
}) => {
  const dispatch = useDispatch()
  if (withRelatedProcedure) {
    return <TextField name="expected_birth_date_before" label="変更前の出産予定年月日" disabled />
  }
  const onChange = (form) => (value) => {
    if (!maternityDivisionBefore || !moment.isMoment(value)) {
      return
    }
    if (!startedAtBefore) {
      const subDuration = maternityDivisionBefore === 'single' ? 41 : 97
      dispatch(autofill(form, 'started_at_before', value.clone().subtract(subDuration, 'days').format('L')))
    }
    if (!expectedFinishedAtBefore) {
      dispatch(autofill(form, 'expected_finished_at_before', value.clone().add(56, 'days').format('L')))
    }
  }
  return (
    <FormName>
      {({form}) => (
        <DateField
          onChange={onChange(form)}
          name="expected_birth_date_before"
          label="変更前の出産予定年月日"
          required
        />
      )}
    </FormName>
  )
}

export const ExpectedBirthDateAfter = ({maternityDivisionAfter, startedAtAfter, expectedFinishedAtAfter}) => {
  const dispatch = useDispatch()
  const onChange = (form) => (value) => {
    if (!maternityDivisionAfter || !moment.isMoment(value)) {
      return
    }
    if (!startedAtAfter) {
      const subDuration = maternityDivisionAfter === 'single' ? 41 : 97
      dispatch(autofill(form, 'started_at_after', value.clone().subtract(subDuration, 'days').format('L')))
    }
    if (!expectedFinishedAtAfter) {
      dispatch(autofill(form, 'expected_finished_at_after', value.clone().add(56, 'days').format('L')))
    }
  }
  return (
    <FormName>
      {({form}) => (
        <DateField onChange={onChange(form)} name="expected_birth_date_after" label="変更後の出産予定年月日" required />
      )}
    </FormName>
  )
}

export const maternityDivisionOptions = [
  {value: 'single', label: '単胎'},
  {value: 'multiple', label: '多胎'},
]

export const MaternityDivisionBefore = ({
  withRelatedProcedure,
  expectedBirthDateBefore,
  startedAtBefore,
  expectedFinishedAtBefore,
}) => {
  const dispatch = useDispatch()
  const onChange =
    (form) =>
    ({target: {value: maternityDivisionBefore}}) => {
      const date = moment(expectedBirthDateBefore, 'YYYY/MM/DD')
      if (!date.isValid()) {
        return
      }
      const changeFrom = date.clone().subtract(maternityDivisionBefore === 'single' ? 97 : 41, 'days')
      const changeTo = date.clone().subtract(maternityDivisionBefore === 'single' ? 41 : 97, 'days')
      if (!startedAtBefore || moment(startedAtBefore, 'YYYY/MM/DD').isSame(changeFrom)) {
        dispatch(autofill(form, 'started_at_before', changeTo.format('L')))
      }
      if (!expectedFinishedAtBefore) {
        dispatch(autofill(form, 'expected_finished_at_before', date.clone().add(56, 'days').format('L')))
      }
    }
  return (
    <FormName>
      {({form}) => (
        <RadioField
          name="maternity_division_before"
          onChange={onChange(form)}
          label="変更前の出産種別"
          options={maternityDivisionOptions}
          required={!withRelatedProcedure}
          disabled={withRelatedProcedure}
        />
      )}
    </FormName>
  )
}
export const MaternityDivisionAfter = ({expectedBirthDateAfter, startedAtAfter, expectedFinishedAtAfter}) => {
  const dispatch = useDispatch()
  const onChange =
    (form) =>
    ({target: {value: maternityDivisionAfter}}) => {
      const date = moment(expectedBirthDateAfter, 'YYYY/MM/DD')
      if (!date.isValid()) {
        return
      }
      const changeFrom = date.clone().subtract(maternityDivisionAfter === 'single' ? 97 : 41, 'days')
      const changeTo = date.clone().subtract(maternityDivisionAfter === 'single' ? 41 : 97, 'days')
      if (!startedAtAfter || moment(startedAtAfter, 'YYYY/MM/DD').isSame(changeFrom)) {
        dispatch(autofill(form, 'started_at_after', changeTo.format('L')))
      }
      if (!expectedFinishedAtAfter) {
        dispatch(autofill(form, 'expected_finished_at_after', date.clone().add(56, 'days').format('L')))
      }
    }
  return (
    <FormName>
      {({form}) => (
        <RadioField
          name="maternity_division_after"
          onChange={onChange(form)}
          label="変更後の出産種別"
          options={maternityDivisionOptions}
          required
        />
      )}
    </FormName>
  )
}

export const StartedAtBefore = ({withRelatedProcedure}) => {
  if (withRelatedProcedure) {
    return <TextField name="started_at_before" label="変更前の産前産後休業等開始年月日" disabled />
  }
  return <DateField name="started_at_before" label="変更前の産前産後休業等開始年月日" required />
}
export const StartedAtAfter = () => (
  <DateField name="started_at_after" label="変更後の産前産後休業等開始年月日" required />
)

export const FinishedAtBefore = ({withRelatedProcedure}) => {
  if (withRelatedProcedure) {
    return <TextField name="expected_finished_at_before" label="変更前の産前産後休業等終了予定年月日" disabled />
  }
  return <DateField name="expected_finished_at_before" label="変更前の産前産後休業等終了予定年月日" required />
}
export const FinishedAtAfter = () => (
  <DateField name="expected_finished_at_after" label="変更後の産前産後休業等終了予定年月日" required />
)
