import React, {Component} from 'react'
import Form, {makeInitialValues, reset} from 'employees/csvFormat/Form'
import api from 'api'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import _ from 'lodash'
import {push} from 'connected-react-router'
import {parseBackUrl} from 'utils'

const formatAvilableFields = (data) =>
  data
    ? [
        ...data.export_csv_available_fields.map((field) => ({
          ...field,
          target_type: 'ExportCsvAvailableField',
          _id: _.uniqueId(),
        })),
        ..._.flatMap(data.custom_employee_field_groups, (group) =>
          group.custom_employee_fields.map((field) => ({
            ...field,
            group: group.label,
            target_type: 'CustomEmployeeField',
            _id: _.uniqueId(),
          }))
        ),
      ]
    : []

class CsvFormat extends Component {
  componentDidMount() {
    const {
      dispatch,
      token,
      match: {
        params: {id},
      },
    } = this.props
    if (id > 0) {
      dispatch(actionCreators.fetchData('csv_format', api.createWithAuth(token).employees.csvFormats.get(id)))
    }
    dispatch(actionCreators.fetchData('csv_formats', api.createWithAuth(token).employees.csvFormats.list()))
    dispatch(
      actionCreators.fetchData('available_fields', api.createWithAuth(token).employees.csvFormats.availableFields())
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match !== this.props.match) {
      const {
        dispatch,
        token,
        match: {
          params: {id},
        },
      } = nextProps
      dispatch(actionCreators.fetchDestroy('csv_format'))
      dispatch(reset())
      if (id > 0) {
        dispatch(actionCreators.fetchData('csv_format', api.createWithAuth(token).employees.csvFormats.get(id)))
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('csv_formats'))
    this.props.dispatch(actionCreators.fetchDestroy('csv_format'))
    this.props.dispatch(actionCreators.fetchDestroy('available_fields'))
  }

  getMode = () => {
    const {
      match: {path},
    } = this.props
    if (path.match(/create/)) {
      return 'create'
    }
    if (path.match(/duplicate/)) {
      return 'duplicate'
    }
    return 'edit'
  }

  render() {
    const {
      location,
      match: {
        params: {id},
      },
      csvFormat,
      availableFields: _availableFields,
      csvFormats = [],
      dispatch,
    } = this.props
    const availableFields = formatAvilableFields(_availableFields)
    let mode = this.getMode()
    return (
      <React.Fragment>
        <div className="l-main-title-wrap u-mb50">
          <h1 className="m-title-main">CSVフォーマット設定</h1>
        </div>
        <div className="l-wrap-xs">
          <Form
            mode={mode}
            id={id}
            goBack={() => {
              dispatch(push(parseBackUrl(location, '/employees')))
            }}
            formatOptions={csvFormats
              .filter(({id}) => id > 0)
              .map((format) => ({value: `${format.id}`, label: format.label}))}
            availableFields={availableFields}
            initialValues={makeInitialValues({mode, csvFormat, availableFields, id})}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  csvFormat: fetchSelector(state, 'csv_format').data,
  csvFormats: fetchSelector(state, 'csv_formats').data,
  availableFields: fetchSelector(state, 'available_fields').data,
}))(CsvFormat)
