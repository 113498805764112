import React, {useState, useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {reduxForm, reset} from 'redux-form'
import {createAction} from 'redux-actions'
import onClickOutside from 'react-onclickoutside'
import _ from 'lodash'
import {actionCreators as roumuActionCreators} from 'actions'
import moment from 'moment'
import {mapValues, mapKeys, flow} from 'lodash/fp'
import Button from 'jbc-front/components/Button'
import Modal from 'jbc-front/components/CommonModal'
import ActionButton from 'jbc-front/components/ActionButton'
import {TextAreaField} from 'jbc-front/components/Form'
import {EditSquare, DeleteSquare, Close, Postit, PostitOn} from 'jbc-front/components/icons'
import {DateField, RadioField} from '../components/FieldWithDiff'
import update from 'immutability-helper'
import {Rnd} from 'react-rnd'
import {toFormValues} from 'utils'
import classnames from 'classnames'
import {useMediaQuery} from 'hooks/useMediaQuery'
import styles from 'components/Comments.scss'

const setInitialValues = (comment) =>
  comment ? _.pick(toFormValues(comment), ['id', 'body', 'alert_at', 'color']) : {color: 'blue'}

const ColorLabel = ({color}) => <span className={styles[`comment_${color}`]}>●</span>

export const COMMENT_COLOR_OPTIONS = [
  {label: <ColorLabel color={'blue'} />, value: 'blue'},
  {label: <ColorLabel color={'yellow'} />, value: 'yellow'},
  {label: <ColorLabel color={'pink'} />, value: 'pink'},
  {label: <ColorLabel color={'green'} />, value: 'green'},
  {label: <ColorLabel color={'purple'} />, value: 'purple'},
  {label: <ColorLabel color={'orange'} />, value: 'orange'},
]

const InputForm = ({handleSubmit, name, hideModal}) => (
  <form onSubmit={handleSubmit} name={name}>
    <RadioField name="color" options={COMMENT_COLOR_OPTIONS} label="メモカラー" />
    <TextAreaField name="body" />
    <div className={styles.dateWrap}>
      <DateField name="alert_at" label="アラート設定" />
    </div>
    <div className={styles.modalButtons}>
      <Button onClick={hideModal} className={styles.modalButton}>
        キャンセル
      </Button>
      <Button primary className={styles.modalButton} as="button" type="submit">
        保存
      </Button>
    </div>
  </form>
)

const CommentList = ({comments, onEditClick, onDeleteClick, currentUserId}) => (
  <div className={classnames('cancel', styles.list)}>
    {comments.map((comment) => (
      <div key={comment.id} className={classnames(styles.row, styles[`comment_border_BG_default_${comment.color}`])}>
        <div className={styles.commentHeader}>
          <div className={styles.user}>{comment.user.email}</div>
          <div className={classnames('cancel', styles.action)}>
            {comment.user_id === currentUserId && (
              <div className={styles.tools}>
                <div className={styles.tool} onClick={() => onEditClick(comment)}>
                  <EditSquare />
                </div>
                <div className={styles.tool} onClick={() => onDeleteClick(comment.id)}>
                  <DeleteSquare />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.commentBody}>{comment.body}</div>
        <div className={styles.commentFooter}>
          <div className={styles.date}>
            アラート予定日: {comment.alert_at ? moment(comment.alert_at).format('YYYY/MM/DD') : '未設定'}
          </div>
          <div className={styles.date}>最終更新日時: {moment(comment.updated_at).format('YYYY/MM/DD HH:mm')}</div>
        </div>
      </div>
    ))}
  </div>
)

const DetailComment = ({hide, comments, onDeleteClick, onEditClick, currentUserId, contentClassName}) => {
  const contentRef = useRef(null)
  const rndWrapperRef = useRef(null)
  const rndRef = useRef(null)
  const [minHeight, setMinHeight] = useState(230)
  const oneComment = comments.length < 2
  const mq = useMediaQuery()
  const maxWidth = mq.isMobile ? 350 : 1220

  DetailComment.handleClickOutside = (e) => {
    // const editModal = document.querySelector('.editModal')
    // const isInsideEditModal = editModal && editModal.contains(e.target)
    const isInsideEditModal = e.target.closest('.editModal')
    if (!isInsideEditModal) {
      hide()
    }
  }

  const handleResize = () => {
    const contentElement = contentRef.current
    if (contentElement) {
      const computedStyle = window.getComputedStyle(contentElement)
      const padding = parseInt(computedStyle.paddingTop) + parseInt(computedStyle.paddingBottom)
      const newHeight = contentElement.scrollHeight + padding
      setMinHeight(newHeight)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (comments.length > 0 && rndRef.current) {
      const newWidth = comments.length === 1 ? 340 : 632
      rndRef.current.updateSize({width: `${newWidth}px`})

      if (contentRef.current && rndWrapperRef.current) {
        const contentRect = contentRef.current.getBoundingClientRect()
        const documentClientWidth = document.documentElement.clientWidth
        // 画面右端からはみ出しそうなら、はみ出さないように位置を調整
        if (contentRect.left + newWidth > documentClientWidth) {
          rndRef.current.updatePosition({
            x: documentClientWidth - newWidth - rndWrapperRef.current.getBoundingClientRect().left,
          })
        }
      }
    }
  }, [comments])

  const DetailCommentClassNames = classnames(styles.CommentRndContent, {
    [styles.oneComment]: oneComment,
    [styles.draggable]: true,
  })

  const commonContent = (
    <div className={classnames(mq.isMobile ? styles.commentContentWrap : null, contentClassName)}>
      <div className={styles.title}>
        <span>メモ</span>
        <Close className="u-cur-pointer" onClick={hide} />
      </div>
      <div ref={contentRef} className={styles.content}>
        <CommentList
          comments={comments}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          currentUserId={currentUserId}
        />
        <div className={`l-flex ${styles.footer}`}>
          <ActionButton className="cancel" onClick={() => onEditClick(null)}>
            メモを追加
          </ActionButton>
        </div>
      </div>
    </div>
  )

  return mq.isMobile ? (
    commonContent
  ) : (
    <div ref={rndWrapperRef}>
      <Rnd
        ref={rndRef}
        default={{
          x: 40,
          y: 0,
          width: '340px',
          height: 'auto',
        }}
        className={DetailCommentClassNames}
        minWidth={340}
        maxWidth={maxWidth}
        minHeight={minHeight}
        onResize={handleResize}
        bounds="#root"
        cancel=".cancel"
      >
        {commonContent}
      </Rnd>
    </div>
  )
}

// https://github.com/Pomax/react-onclickoutside/issues/327 の暫定的な対策
DetailComment.prototype = {}

const clickOutsideConfig = {
  handleClickOutside: () => DetailComment.handleClickOutside,
}

const DetailCommentClickOutside = onClickOutside(DetailComment, clickOutsideConfig)

const EditModal = ({hideModal, showEditModal, handleSubmit, inputForm, formName, onEditClick, comment, ...props}) => {
  const formRef = useRef(null)
  const InputForm = inputForm

  return (
    <Modal
      isOpen={showEditModal}
      hideModal={hideModal}
      portalClassName={classnames('ReactModalPortal editModal')}
      ariaHideApp={false}
    >
      <Modal.Header hideModal={hideModal}>{comment ? 'メモを更新' : 'メモを新規作成'}</Modal.Header>
      <Modal.Body>
        <InputForm
          {...props}
          ref={formRef}
          name={formName}
          onSubmit={handleSubmit}
          onEditClick={onEditClick}
          initialValues={setInitialValues(comment)}
          hideModal={hideModal}
        />
      </Modal.Body>
    </Modal>
  )
}

const DeleteModal = ({isShow, showDeleteModal, hideModal, handleDeleteAccept}) => {
  return (
    <Modal
      isOpen={showDeleteModal}
      hideModal={hideModal}
      portalClassName={classnames('ReactModalPortal', {'ignore-react-onclickoutside': isShow})}
      ariaHideApp={false}
    >
      <Modal.Header hideModal={hideModal}>削除の確認</Modal.Header>
      <Modal.Body>メモを削除しますか</Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>いいえ</Button>
          <Button primary onClick={handleDeleteAccept}>
            はい
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  )
}

export const Comments = ({
  hide,
  name,
  register,
  loadComment,
  destroy,
  commentableId,
  employeeId,
  deleteComment,
  updateComment,
  addComment,
  isShow,
  toogle,
  comments,
  fromAlerts,
  size,
  deleteMode,
  contentClassName,
  ...props
}) => {
  const [currentComment, setCurrentComment] = useState(null)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const formName = `comments_${name}`
  const _inputForm = reduxForm({
    form: formName,
    enableReinitialize: true,
  })(InputForm)

  useEffect(() => {
    loadComment()
    register(name)

    return () => {
      destroy(name)
    }
  }, [loadComment, register, destroy, name])

  useEffect(() => {
    hide()
    loadComment()
  }, [commentableId, employeeId])

  useEffect(() => {
    if (fromAlerts === true) {
      toogle()
    }
  }, [])

  const handleDeleteClick = (id) => {
    setShowDeleteModal(true)
    setDeleteId(id)
  }

  const handleEditClick = (comment) => {
    setShowEditModal(true)
    setCurrentComment(comment)
  }

  const handleDeleteAccept = () => {
    deleteComment(deleteId)
    hideModal()
  }

  const hideModal = () => {
    setShowEditModal(false)
    setShowDeleteModal(false)
    setDeleteId(null)
  }

  const handleSubmit = (comment) => {
    if (comment.id) {
      updateComment(comment, formName)
    } else {
      addComment(comment, formName)
    }
    hideModal()
  }

  const handleClick = () => {
    !deleteMode && (comments.length === 0 ? setShowEditModal(true) : toogle())
  }

  const postit =
    comments.length > 0 ? (
      <PostitOn className={styles[`comment_${comments.slice(-1)[0].color}`]} size={size} />
    ) : (
      <Postit size={size} />
    )

  return (
    <div className={styles.comments}>
      <div role="button" onClick={handleClick} className={isShow ? 'ignore-react-onclickoutside' : ''}>
        <span className={styles.postit}>{postit}</span>
      </div>

      {showDeleteModal && (
        <DeleteModal
          isShow={isShow}
          showDeleteModal={showDeleteModal}
          hideModal={hideModal}
          handleDeleteAccept={handleDeleteAccept}
        />
      )}

      {showEditModal && (
        <EditModal
          {...props}
          inputForm={_inputForm}
          formName={formName}
          isShow={isShow}
          showEditModal={showEditModal}
          hideModal={hideModal}
          onEditClick={handleEditClick}
          handleSubmit={handleSubmit}
          comment={currentComment}
        />
      )}

      {isShow && comments.length > 0 && (
        <DetailCommentClickOutside
          {...props}
          hide={hide}
          comments={comments}
          inputForm={_inputForm}
          formName={formName}
          onDeleteClick={handleDeleteClick}
          onEditClick={handleEditClick}
          handleSubmit={handleSubmit}
          contentClassName={contentClassName}
        />
      )}
    </div>
  )
}

export const initialState = {}
export const commentsInitialState = {
  list: [],
  show: false,
  editingId: null,
}

const SET_COMMENTS = 'COMMENTS/SET_COMMENTS'
const ADD_COMMENT = 'COMMENTS/ADD_COMMENT'
const DELETE_COMMENT = 'COMMENTS/DELETE_COMMENT'
const UPDATE_COMMENT = 'COMMENTS/UPDATE_COMMENT'
const DESTROY = 'COMMENTS/DESTROY'
const REGISTER = 'COMMENTS/REGISTER'
const SHOW = 'COMMENTS/SHOW'
const HIDE = 'COMMENTS/HIDE'
const TOOGLE = 'COMMENTS/TOOGLE'
const EDIT = 'COMMENTS/EDIT'

const _createAction = (action) =>
  createAction(
    action,
    (name, commentableId, payload) => payload,
    (name, commentableId) => ({name, commentableId})
  )
export const actionCreators = flow(
  mapValues(_createAction),
  mapKeys((key) => _.camelCase(key))
)({
  SET_COMMENTS,
  ADD_COMMENT,
  DELETE_COMMENT,
  UPDATE_COMMENT,
  DESTROY,
  REGISTER,
  SHOW,
  HIDE,
  TOOGLE,
  EDIT,
})

export const reducer = (state = initialState, action) => {
  const name = _.get(action, 'meta.name')
  const commentableId = _.get(action, 'meta.commentableId')

  switch (action.type) {
    case REGISTER:
      if (state[name]) {
        return update(state, {
          [name]: {
            [commentableId]: {
              $set: commentsInitialState,
            },
          },
        })
      } else {
        return _.assign({}, state, {[name]: {[commentableId]: commentsInitialState}})
      }
    case DESTROY: {
      const newState = update(state, {
        [name]: {
          $unset: [commentableId],
        },
      })
      return _.size(newState[name]) === 0 ? _.omit(state, name) : newState
    }
    case SET_COMMENTS:
      if (!state[name]?.[commentableId]) {
        return state
      }
      return update(state, {
        [name]: {
          [commentableId]: {
            list: {
              $set: action.payload,
            },
          },
        },
      })
    case ADD_COMMENT:
      return update(state, {
        [name]: {
          [commentableId]: {
            list: {
              $push: [action.payload],
            },
          },
        },
      })
    case UPDATE_COMMENT: {
      const index = state[name][commentableId].list.findIndex((comment) => comment.id === action.payload.id)
      if (index < 0) {
        return state
      }
      return update(state, {
        [name]: {
          [commentableId]: {
            list: {
              [index]: {
                $merge: action.payload,
              },
            },
            editingId: {
              $set: null,
            },
          },
        },
      })
    }
    case DELETE_COMMENT: {
      return update(state, {
        [name]: {
          [commentableId]: {
            list: {
              $apply: (list) => list.filter((comment) => action.payload !== comment.id),
            },
          },
        },
      })
    }
    case SHOW:
      return update(state, {
        [name]: {
          [commentableId]: {
            show: {
              $set: true,
            },
            editingId: {
              $set: null,
            },
          },
        },
      })
    case HIDE:
      return update(state, {
        [name]: {
          [commentableId]: {
            show: {
              $set: false,
            },
            editingId: {
              $set: null,
            },
          },
        },
      })
    case TOOGLE:
      return update(state, {
        [name]: {
          [commentableId]: {
            show: {
              $apply: (show) => !show,
            },
            editingId: {
              $set: null,
            },
          },
        },
      })
    case EDIT:
      return update(state, {
        [name]: {
          [commentableId]: {
            editingId: {
              $set: action.payload,
            },
          },
        },
      })
    default:
      return state
  }
}

const comments = (name, type) => {
  const mapStateToProps = (state, ownProps) => {
    const commentableId = ownProps.commentableId || 'default'
    return {
      name,
      type,
      commentableId,
      comments: _.get(state.comments, `${name}.${commentableId}.list`) || [],
      isShow: _.get(state.comments, `${name}.${commentableId}.show`),
      editingId: _.get(state.comments, `${name}.${commentableId}.editingId`),
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    const commentableId = ownProps.commentableId || 'default'
    const dispatches = bindActionCreators(
      {..._.mapValues(actionCreators, (actionCreator) => actionCreator.bind(null, name, commentableId)), reset},
      dispatch
    )
    return {
      ...dispatches,
      async loadComment() {
        try {
          const comments = await ownProps.loadComment()
          dispatches.setComments(comments)
        } catch (err) {
          dispatch(roumuActionCreators.asyncError(err))
        }
      },
      async addComment(params, formName) {
        try {
          const comment = await ownProps.addComment(params)
          dispatches.addComment(comment)
          dispatches.reset(formName)
        } catch (err) {
          dispatch(roumuActionCreators.asyncError(err))
        }
      },
      async updateComment(params, formName) {
        try {
          const comment = await ownProps.updateComment(params)
          dispatches.updateComment(comment)
          dispatches.reset(formName)
        } catch (err) {
          dispatch(roumuActionCreators.asyncError(err))
        }
      },
      async deleteComment(id) {
        try {
          await ownProps.deleteComment(id)
          dispatches.deleteComment(id)
        } catch (err) {
          dispatch(roumuActionCreators.asyncError(err))
        }
      },
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(Comments)
}

export default comments
