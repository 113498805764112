import {useEffect, useRef} from 'react'

/**
 * 前回のレンダリング時に引数として渡していた値を返すフック
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
