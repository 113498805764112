import React, {useState} from 'react'
import {SelectField, Section, NumberFields, RadioField, TextField, CheckboxField} from 'jbc-front/components/Form'
import {formValueSelector, reduxForm} from 'redux-form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {toBooleanProps} from 'utils'
import EmployeeName from 'procedures/EmployeeName'
import styles from 'reportForms/common.scss'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R58Report'
const selector = formValueSelector(formName)

const lostTypes = [
  {value: 'except_for_leave', label: '1. 離職以外の理由'},
  {value: 'other_reason_leave', label: '2. 事業主の都合による離職以外の離職'},
  {value: 'involuntary_leave', label: '3. 事業主の都合による離職'},
]

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, employee, existProcedureChangeName, needUpgrade}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">雇用保険被保険者資格喪失届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <SelectField
            name="lost_type"
            label="喪失原因"
            options={lostTypes}
            note="死亡、在籍出向、出向元への復帰などは1を、自己都合退職は2を、事業主の都合による解雇は3を選択します。"
          />
          <RadioField name="require_resignation_letter" label="離職票交付希望" {...toBooleanProps} />
          <NumberFields
            prefix="working_hours"
            label="1週間の所定労働時間"
            texts={['', ' 時間 ', ' 分 ']}
            lengths={[2, 2]}
          />
          <RadioField
            name="fill_up"
            label="補充採用予定の有無"
            {...toBooleanProps}
            note="ハローワークでの紹介を予定している場合に選択します。空白も可能です。"
          />
          <TextField name="reason" label="被保険者でなくなったことの原因" note="「一身上の都合による退職」など" />

          {existProcedureChangeName && (
            <>
              <p className={styles.textLabel}>氏名変更があります。資格喪失届に反映しますか？</p>
              <CheckboxField name="use_change_name" label="資格喪失届に直近の氏名変更を反映する" />
            </>
          )}

          <SubmitedOn />
          <div className="u-mb20">
            <p>
              ※ 平成30年5月以降、マイナンバー（個人番号）の記載・添付が必須になります。
              <br />
              出力・ダウンロードの前に「マイナンバー出力」ボタンを押すか、印刷後に手でマイナンバーを記入してください。
            </p>
          </div>
          <div>
            <p className={styles.textLabel}>マイナンバーを添付せず電子申請を行う場合以下にチェックを入れてください。</p>
            <CheckboxField name="ignore_my_number" label="本人事由によりマイナンバー届出不可" />
          </div>
          <div className="u-ta-c u-mt30">
            <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => {
          setOpenModal(false)
          handleSubmit()
        }}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})

const connectedForm = connectForm('r58', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    existProcedureChangeName: state.procedureStatuses.current.data.exist_procedure_change_name,
    needUpgrade: selector(state, 'need_upgrade'),
  }),
})

export default connectedForm
