import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import {Download} from 'jbc-front/components/icons'
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Note} from 'jbc-front/components/presenters/ui/Note'
import {Radio, RadioContainer} from 'jbc-front/components/presenters/form/Radio'
import {useDownloadGroupsCsv, EncodingType} from 'hooks/api/exportGroupsCsv/useDownloadGroupsCsv'

type CsvDownloadSchema = {
  encoding: EncodingType
}

export const CsvDownloadModal = () => {
  const download = useDownloadGroupsCsv()
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)

  const schema = yup.object({
    encoding: yup.mixed<EncodingType>().required(),
  })

  const {handleSubmit: handleReactHookFormSubmit, register} = useForm<CsvDownloadSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      encoding: 'UTF-8',
    },
  })

  const handleSubmit = async (data: CsvDownloadSchema) => {
    await download({encoding: data.encoding})
    handleClose()
  }

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>グループ情報ダウンロード</Button>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <form onSubmit={handleReactHookFormSubmit(handleSubmit)}>
          <Modal.Header onClose={handleClose}>グループ情報のダウンロード</Modal.Header>
          <Modal.Body>
            <Label>ファイル形式選択</Label>
            <Spacer direction="y" size={20} />
            <RadioContainer>
              <Radio {...register('encoding')} value="UTF-8">
                UTF-8（推奨）
              </Radio>
              <Radio {...register('encoding')} value="Windows-31J">
                Shift-JIS
              </Radio>
            </RadioContainer>
            <Spacer direction="y" size={6} />
            <Note>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。</Note>
          </Modal.Body>
          <Modal.Footer>
            <ButtonRow>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button primary onClick={handleReactHookFormSubmit(handleSubmit)}>
                <Download />
                ダウンロード
              </Button>
            </ButtonRow>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
