import React, {Component} from 'react'
import Form from 'procedures/changeDependents/remove/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {asyncError} from 'store/actions/asyncError'

class Update extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      loadProcedureStatus,
      token,
    } = this.props
    loadProcedureStatus(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  makeInitialValues() {
    const {procedureStatus} = this.props
    const {employee} = procedureStatus
    const dependents =
      employee &&
      employee.employee_dependents &&
      employee.employee_dependents.filter(
        (dependent) =>
          !dependent.removed ||
          procedureStatus.procedure_remove_dependents_data.find(
            (removeData) => removeData.employee_dependent_id === dependent.id
          )
      )
    return dependents
      ? {
          date_on: procedureStatus.date_on,
          dependents: dependents.map((dependent) => ({
            ..._.pick(dependent, [
              'first_name',
              'last_name',
              'id',
              'remove_reason_detail',
              'remove_reason_type',
              'removed',
              'relation_type',
              'died_on',
            ]),
            _will_remove: !!procedureStatus.procedure_remove_dependents_data.find(
              (removeData) => removeData.employee_dependent_id === dependent.id
            ),
          })),
          _allow_empty: true,
        }
      : {}
  }

  render() {
    const employee = this.props.procedureStatus.employee || {}
    const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={this.makeInitialValues()}
        submitText="保存"
        employee={employee}
        healthInsuranceType={healthInsuranceType}
      />
    )
  }

  handleSubmit = (values) => {
    const {update, token, procedureStatus} = this.props
    return update(procedureStatus, values, token)
  }
}

export default connect(
  (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api
            .createWithAuth(token)
            .procedureStatuses.get(
              id,
              ['employee', 'employee_dependents', 'procedure_remove_dependents_data', 'office'],
              {
                employee_dependent_removed: [0, 1],
              }
            )
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.procedureStatuses.current.destroy())
    },
    update: async (procedureStatus, {dependents, date_on: dateOn}, token) => {
      const authedApi = api.createWithAuth(token)
      try {
        await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: dateOn})
        for (let dependent of dependents) {
          if (dependent && dependent._will_remove && !dependent.removed) {
            await authedApi.employees.dependents.update(procedureStatus.employee_id, {
              ...dependent,
              removed: true,
              removed_on: dateOn,
            })
            await authedApi.procedureStatuses.removeDependentsData.create(procedureStatus.id, dependent.id)
          } else if (dependent && dependent._will_remove) {
            await authedApi.employees.dependents.update(procedureStatus.employee_id, {
              ...dependent,
              removed: true,
              removed_on: dateOn,
            })
          }
        }
        for (let removeData of procedureStatus.procedure_remove_dependents_data) {
          const dependent = dependents.find((dependent) => dependent.id === removeData.employee_dependent_id)
          if (dependent && !dependent._will_remove) {
            await authedApi.employees.dependents.update(procedureStatus.employee_id, {
              ...dependent,
              removed: false,
              removed_on: null,
            })
            await authedApi.procedureStatuses.removeDependentsData.delete(procedureStatus.id, removeData.id)
          }
        }
        dispatch(push(`/procedure_statuses/${procedureStatus.id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Update)
