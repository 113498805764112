import React from 'react'
import {FieldArray, FormSection} from 'redux-form'
import {Section, TextField, TextAreaField, DateField, BoxDouble} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import {Description, withFormSelectors} from 'employees/form/common'
import moment from 'moment'
import * as validators from 'jbc-front/utils/validators'
import _ from 'lodash'
import styles from 'employees/Form.scss'

const renderEmployeeWorkHistoryComponent = ({fields, index, name, diff = {}}) => (
  <li>
    <LabelMapper name="employee_work_histories" label="職歴の" />
    <Section
      title={`職歴 (${index + 1})`}
      key={index}
      icon={
        <DeleteSquare
          onClick={() => {
            fields.remove(index)
          }}
        />
      }
    >
      <FormSection name={name}>
        <TextField
          name="company_name"
          label="会社名"
          validate={validators.maxLength(32)}
          diff={diff.company_name}
          required
        />
        <BoxDouble>
          <div className={styles.arrayDoubleDate}>
            <DateField name="join_date" label="入社年月日" diff={diff.join_date} />
          </div>
          <div className={styles.arrayDoubleDate}>
            <DateField name="leave_date" label="退社年月日" diff={diff.leave_date} />
          </div>
        </BoxDouble>
        <TextField name="position" label="役職・職位" validate={validators.maxLength(30)} diff={diff.position} />
        <TextAreaField
          name="business_content"
          label="業務内容"
          validate={validators.maxLength(255)}
          diff={diff.business_content}
          rows="4"
        />
      </FormSection>
    </Section>
  </li>
)

const EmployeeWorkHistoryComponent = connect((state, {index, selector}) => ({
  joinDate: selector(state, `employee_work_histories[${index}].join_date`),
  leaveDate: selector(state, `employee_work_histories[${index}].leave_date`),
}))(renderEmployeeWorkHistoryComponent)

export const renderWorkHistories = ({fields, selector, diff = []}) => (
  <div>
    <LabelMapper name="employee_work_histories" label="職歴の" />
    <ul>
      {fields.map((name, index) => (
        <EmployeeWorkHistoryComponent
          diff={diff[index]}
          {...{
            index,
            name,
            fields,
            selector,
          }}
          key={index}
        />
      ))}
    </ul>
    {fields.length < 1 && (
      <div className="u-ta-c">
        <p>
          「職歴を追加」ボタンを押して、
          <br />
          職歴を追加してください。
        </p>
      </div>
    )}
    <div className="u-ta-r u-mt20">
      <div onClick={() => fields.push({})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        職歴を追加
      </div>
    </div>
  </div>
)

const EmployeeWorkHistory = ({selector, description, description_color_by_rgb, diff = {}}) => {
  return (
    <Section title="職歴">
      <Description {...{description, description_color_by_rgb}} />
      <FieldArray
        name="employee_work_histories"
        component={renderWorkHistories}
        diff={diff.employee_work_histories}
        {...{selector}}
      />
    </Section>
  )
}

export default EmployeeWorkHistory
  |> connect((state, {selector}) => ({
    diff: selector(state, '_diff'),
  }))
  |> withFormSelectors

const validatePeriods = ({name, startColumn, endColumn}, values) => {
  const periods = values[name]
  if (!periods) {
    return {}
  }

  const errors = {}
  periods.forEach((period, index) => {
    const startOn = period[startColumn] ? moment(period[startColumn]) : null
    const endOn = period[endColumn] ? moment(period[endColumn]) : null
    if (startOn && endOn && startOn.isAfter(endOn)) {
      _.set(errors, `${name}[${index}].${endColumn}`, 'は正しくありません')
    }
  })
  return errors
}

export const validate = (values) => {
  return Object.assign(
    {},
    validatePeriods({name: 'employee_work_histories', startColumn: 'join_date', endColumn: 'leave_date'}, values)
  )
}
