import React from 'react'
import {useState} from 'react'
import {Section} from 'jbc-front/components/Form'
import {formValueSelector, reduxForm} from 'redux-form'
import {Notes, SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R12Report'
const selector = formValueSelector(formName)
const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, employee, needUpgrade}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">健康保険厚生年金保険 被保険者氏名変更（訂正）届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <Notes />
          <SubmitedOn />
          <div className="u-ta-c u-mt30">
            <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => {
          setOpenModal(false)
          handleSubmit()
        }}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})

const connectedForm = connectForm('r12', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    needUpgrade: selector(state, 'need_upgrade'),
  }),
})

export default connectedForm
