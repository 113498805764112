import React, {useState, useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {gql} from '@apollo/client'
import Modal from 'jbc-front/components/Modal'
import {Delete as DeleteIcon} from 'jbc-front/components/icons'
import {notifySuccess} from 'store/actions/notify'
import {useMutation} from 'components/Graphql'
import styles from 'documentTemplates/List.scss'

const DELETE_DOCUMENT_TEMPLATE = gql`
  mutation deleteDocumentTemplate($input: DeleteDocumentTemplateInput!) {
    deleteDocumentTemplate(input: $input) {
      documentTemplate {
        id
      }
    }
  }
`

const Delete = ({id, title, onRequestFinish}) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [deleteDocumentTemplate] = useMutation(DELETE_DOCUMENT_TEMPLATE)
  const handleDeleteClick = useCallback(async () => {
    await deleteDocumentTemplate({variables: {input: {id}}})
    dispatch(notifySuccess('削除しました'))
    onRequestFinish()
  }, [id, onRequestFinish])

  return (
    <div>
      <Modal
        header="削除の確認"
        body={
          <p>
            削除されたテンプレートは復元することができません。
            <br />
            <span className={styles.warningText}>本当に{title}を削除しますか</span>
          </p>
        }
        onSubmit={handleDeleteClick}
        isOpen={showModal}
        hideModal={() => setShowModal(false)}
        submit="はい"
        cancel="いいえ"
      />
      <div className={styles.noteWrap}>
        <DeleteIcon size={20} className={styles.icon} onClick={() => setShowModal(true)} />
        <div className={styles.note}>テンプレートを削除</div>
      </div>
    </div>
  )
}

export default Delete
