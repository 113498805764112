import React, {useEffect, useRef, useMemo, useState} from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'
import SortableTree, {defaultGetNodeKey, map as _mapTree, walk} from 'react-sortable-tree'
import {actionCreators} from 'actions'
import {useNotify} from 'hooks/useNotify'
import api from 'api'
import {Link} from 'react-router-dom'
import ActionButton from 'jbc-front/components/ActionButton'
import {Expand, Contract, Strage, PlusSquare, Edit, MinusCircle, DeleteSquare} from 'jbc-front/components/icons'
import Button from 'jbc-front/components/Button'
import {Pulldown} from 'jbc-front/components/icons'
import Balloon from 'components/Balloon'
import {CsvDownloadModal} from 'pages/withAuth/adminPage/settings/groups/index/parts/CsvDownloadModal'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import AsyncTaskError from 'AsyncTaskError'
import {withAsyncTask} from 'AsyncTask'

import styles from 'settings/Groups.scss'
import {Tab} from 'components/Tabs'

const mapTree = (treeData, callback) =>
  _mapTree({treeData, getNodeKey: defaultGetNodeKey, ignoreCollapsed: false, callback})

const MAX_DEPTH = 5

const makeTree = (groups) => {
  let sequenceNumber = 0
  const childrenOf = _.groupBy(_.sortBy(groups, 'position'), 'parent_group_id')
  const make = (id) => {
    const next = childrenOf[id]
    if (!next) {
      return null
    }
    return next.map((group) => {
      const children = make(group.id)
      sequenceNumber += 1
      return {
        group,
        expanded: true,
        sequenceNumber,
        initialGroupCode: group.group_code,
        initialGroupName: group.name,
        _key: _.uniqueId(),
        canDelete: group.employees_groups_count === 0 && (!children || _.every(children, 'canDelete')),
        ...(children ? {children} : {}),
      }
    })
  }
  return make('null')
}

const checkAnyNodeExpanded = (tree) => {
  let isAnyNodeExpanded = false
  walk({
    treeData: tree,
    getNodeKey: defaultGetNodeKey,
    ignoreCollapsed: false,
    callback: ({node}) => {
      isAnyNodeExpanded = isAnyNodeExpanded || (node.expanded && !!node.children)
    },
  })
  return isAnyNodeExpanded
}

const checkAnyNodeEdited = (initialTree, otherTree) => {
  const toSimpleTree = (tree) =>
    tree.map(({sequenceNumber, group: {name, group_code}, children}) => ({
      sequenceNumber,
      name,
      group_code,
      children: children ? toSimpleTree(children) : [],
    }))
  return !_.isEqual(toSimpleTree(initialTree), toSimpleTree(otherTree))
}

const groupName = (node) => (node.group.group_code ? `${node.group.group_code} ${node.group.name}` : node.group.name)

const Groups = ({task, confirm, taskRunningProps}) => {
  const rstRefs = useRef()

  const token = useSelector((state) => state.auth.token)
  const initialTree = useSelector((state) => state.settings.groups.initialTree)
  const tree = useSelector((state) => state.settings.groups.tree)
  const editing = useSelector((state) => state.settings.groups.editing)

  const dispatch = useDispatch()
  const {
    initializeTree,
    setTree,
    addNode,
    deleteNode,
    insertNode,
    expandAll,
    collapseAll,
    startEdit,
    endEdit,
    updateNode,
    destroy,
  } = useMemo(() => bindActionCreators(actionCreators.settings.groups, dispatch), [dispatch])
  const {asyncError, notifySuccess, notifyError} = useMemo(
    () => bindActionCreators(actionCreators, dispatch),
    [dispatch]
  )

  const fetchData = async () => {
    try {
      const {groups} = await api.createWithAuth(token).groups.list()
      const initialTree = makeTree(groups) || []
      initializeTree(initialTree)
      endEdit()
    } catch (err) {
      asyncError(err)
    }
  }

  useEffect(() => {
    fetchData()
    return destroy
  }, [])

  const onCancelClick = () => fetchData()

  const [submitting, setSubmitting] = useState(false)
  const updateData = async (data) => {
    setSubmitting(true)
    try {
      await api.createWithAuth(token).groups.updateAll(data)
      const {groups} = await api.createWithAuth(token).groups.list()
      const updatedTree = makeTree(groups) || []
      initializeTree(updatedTree)
      endEdit()
      notifySuccess('更新しました')
    } catch (err) {
      if (err.response.status == 422 && err.response.data) {
        let notified = false
        _.each(err.response.data, (value) => {
          if (value.errors && value.errors.base) {
            notifyError(value.errors.base)
            notified = true
          }
        })
        if (notified) {
          return
        }
      }
      asyncError(err)
    } finally {
      setSubmitting(false)
    }
  }

  const onSaveClick = () => {
    let canSave = true
    walk({
      treeData: tree,
      getNodeKey: defaultGetNodeKey,
      ignoreCollapsed: false,
      callback: ({node}) => {
        canSave = canSave && node.group.name
      },
    })
    if (!canSave) {
      notifyError('グループ名を入力してください')
      return
    }
    updateData(mapTree(tree, ({node}) => _.pick(node, ['group', 'children', '_key'])))
  }

  const notify = useNotify()
  const onClickDisabledTab = () => notify('保存してください', 'warning')

  useEffect(() => {
    const refs = rstRefs.current
    if (!refs) {
      return
    }
    const rstNode = refs ? refs.getElementsByClassName('rst__node') : []
    for (const node of rstNode) {
      node.lastChild.style.maxWidth = `calc(100% - ${node.lastChild.style.left})`
    }
  }, [rstRefs.current])

  const isAnyNodeExpanded = checkAnyNodeExpanded(tree)

  const isAnyNodeEdited = checkAnyNodeEdited(initialTree, tree)

  return (
    <div>
      <div className="l-main-title-wrap u-pb0">
        <h1 className="m-title-main">組織管理の設定</h1>
        <p className="m-title-main-note">雇用形態、職種、グループ、役職の情報の設定を行えます</p>
        <div className={styles.tabs}>
          <Tab
            {...(isAnyNodeEdited ? {as: 'div', onClick: onClickDisabledTab} : {as: Link})}
            to="/settings/employment_types"
          >
            雇用形態
          </Tab>
          <Tab
            {...(isAnyNodeEdited ? {as: 'div', onClick: onClickDisabledTab} : {as: Link})}
            to="/settings/occupations"
          >
            職種
          </Tab>
          <Tab active>グループ</Tab>
          <Tab {...(isAnyNodeEdited ? {as: 'div', onClick: onClickDisabledTab} : {as: Link})} to="/settings/positions">
            役職
          </Tab>
        </div>
      </div>
      <AsyncTaskError task={task} confirm={confirm} displayTypes={['group_csv_import', 'group_csv_update']} />
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <span className="l-breadcrumb-here">グループ設定</span>
        </div>

        {!editing && (
          <div className={styles.buttonArea}>
            <CsvDownloadModal />

            <Balloon
              center
              switchRender={(toggle, isOpen) => (
                <Button
                  widthAuto
                  className={isOpen && 'ignore-react-onclickoutside'}
                  onClick={toggle}
                  {...taskRunningProps}
                >
                  グループ情報一括登録
                </Button>
              )}
            >
              <Balloon.Ul>
                <Balloon.Li className={styles.addLink}>
                  <Link to={WITH_AUTH_ADMIN_PATHS.SETTINGS.GROUPS.IMPORT}>
                    <Pulldown transform="rotate(-90)" />
                    新規登録（CSVインポート）
                  </Link>
                </Balloon.Li>
                <Balloon.Li className={styles.addLink}>
                  <Link to={WITH_AUTH_ADMIN_PATHS.SETTINGS.GROUPS.IMPORT_UPDATE}>
                    <Pulldown transform="rotate(-90)" />
                    更新（CSVインポート）
                  </Link>
                </Balloon.Li>
              </Balloon.Ul>
            </Balloon>
          </div>
        )}

        <div>
          <div className={styles.buttonWrap + ' u-mb10'}>
            {isAnyNodeExpanded ? (
              <ActionButton onClick={collapseAll} disabled={submitting} icon={<Contract size={15} />}>
                全て閉じる
              </ActionButton>
            ) : (
              <ActionButton onClick={expandAll} disabled={submitting} icon={<Expand size={15} />}>
                全て展開
              </ActionButton>
            )}
            {(_.isEmpty(tree) || editing) && (
              <ActionButton
                onClick={() => {
                  if (!editing) {
                    startEdit()
                  }
                  addNode()
                }}
                disabled={submitting}
                icon={<PlusSquare size={15} />}
              >
                追加
              </ActionButton>
            )}
            {editing && (
              <ActionButton
                onClick={onSaveClick}
                disabled={submitting || !isAnyNodeEdited}
                disabledReason={submitting ? null : '編集してから保存してください'}
                primary={isAnyNodeEdited}
                icon={<Strage size={15} />}
              >
                保存
              </ActionButton>
            )}
            {!editing && !_.isEmpty(tree) && (
              <ActionButton onClick={() => startEdit()} icon={<Edit size={15} />}>
                編集
              </ActionButton>
            )}
            {editing && (
              <ActionButton onClick={onCancelClick} disabled={submitting} icon={<MinusCircle size={15} />}>
                キャンセル
              </ActionButton>
            )}
          </div>
          <div className="l-overflow-scroll" ref={rstRefs} style={submitting ? {pointerEvents: 'none'} : {}}>
            <SortableTree
              treeData={tree}
              onChange={
                /* eslint-disable no-unused-vars */ (tree) =>
                  setTree(
                    mapTree(tree, ({node: {title, ...node}}) => ({
                      ...node,
                      canDelete:
                        node.group &&
                        node.group.employees_groups_count === 0 &&
                        (!node.children || _.every(node.children, 'canDelete')),
                    }))
                  )
                /* eslint-enable no-unused-vars */
              }
              maxDepth={MAX_DEPTH}
              isVirtualized={false}
              canDrag={editing}
              generateNodeProps={({path, node}) => ({
                buttons: [
                  editing && path.length < MAX_DEPTH && <PlusSquare size={17} onClick={() => insertNode(path)} />,
                  editing && (
                    <div className={node.canDelete ? styles.groupDelete : styles.groupDeleteNo}>
                      <DeleteSquare size={17} onClick={node.canDelete ? () => deleteNode(path) : null} />
                      {!node.canDelete && (
                        <div className={styles.noteWrap}>
                          <div className={styles.note}>
                            従業員に紐づいているので削除できません。
                            <a
                              href="https://jobcan-lms.zendesk.com/hc/ja/articles/360003860592"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              詳しくはこちら
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  ),
                ],
                title: node.group ? (
                  editing ? (
                    <>
                      <input
                        style={{
                          ...(node.initialGroupCode !== node.group.group_code && {backgroundColor: '#e1edfe'}),
                        }}
                        className={styles.inputGroupCode}
                        type="text"
                        placeholder="コードを入力してください"
                        value={node.group.group_code === null ? '' : node.group.group_code}
                        onChange={({target: {value}}) => updateNode([path, {group_code: value}])}
                      />
                      <input
                        style={{
                          ...(node.initialGroupName !== node.group.name && {backgroundColor: '#e1edfe'}),
                        }}
                        className={styles.inputGroupName}
                        type="text"
                        placeholder="グループ名を入力してください"
                        value={node.group.name}
                        maxLength="255"
                        onChange={({target: {value}}) => updateNode([path, {name: value}])}
                      />
                    </>
                  ) : (
                    groupName(node)
                  )
                ) : (
                  node.title
                ),
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAsyncTask(Groups)
