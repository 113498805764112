import fetchActions from 'fetchActions'

const actions = ['SET_QUERY', 'TOOGLE_SELECTED', 'CHANGE_SELECTED', 'CLEAR_SELECTED', 'CHANGE_MULTI']

const subActions = {
  LIST: fetchActions,
  CURRENT: fetchActions,
  ALL: fetchActions,
  DOCUMENT_LIMIT_EXCEEDED: fetchActions,
}

export default [actions, subActions]
