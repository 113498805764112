import React, {Component} from 'react'
import Form, {formatValues} from 'procedures/changeAddress/Form'
import Master from 'Master'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {updateEmployeeDetail} from 'employees/Update'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import Button from 'jbc-front/components/Button'
import checkEmployeeConfirm from 'components/checkEmployeeConfirm'
import compose from 'lodash/fp/compose'
import _ from 'lodash'
import {asyncError} from 'store/actions/asyncError'

const unitMap = {
  month_1: 'per_month',
  month_3: 'per_3months',
  month_6: 'per_6months',
}

class Confirm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      loadProcedureStatus,
      token,
      loadTmpData,
      loadComments,
    } = this.props
    loadProcedureStatus(id, token)
    loadTmpData(id, token)
    loadComments(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      tmpData: {application_procedure_change_address: changeAddressData},
      residenceCard,
      comments,
      match: {
        params: {id},
      },
      submitting,
      healthInsuranceType,
    } = this.props
    const changeAddressDataAndFile = {...changeAddressData, residence_card: residenceCard}
    return (
      <div>
        <Master masters={['nations']} />
        <CommentModal procedureStatusId={id} comments={comments || []} procedureType="changeAddress">
          {({showModal}) => (
            <Form
              onSubmit={this.handleSubmit}
              initialValues={changeAddressDataAndFile || {}}
              comments={comments || {}}
              submitText="承認して次へ"
              otherButtons={
                <Button disabled={submitting} onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
              healthInsuranceType={healthInsuranceType}
            />
          )}
        </CommentModal>
      </div>
    )
  }

  handleSubmit = (values) => {
    const {updateAndAccept, token, healthInsuranceType, procedureStatus, nations} = this.props
    return updateAndAccept(procedureStatus, values, token, healthInsuranceType, nations)
  }
}

export default compose(
  checkEmployeeConfirm({procedureType: 'change_address'}),
  connect(
    (state) => ({
      procedureStatus: state.procedureStatuses.current.data,
      tmpData: state.procedureStatuses.tmpData.data,
      residenceCard: state.procedureStatuses.tmpData.tmpFile,
      healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
      comments: fetchSelector(state, 'procedure_comments').data,
      token: state.auth.token,
      nations: state.master.nations,
    }),
    (dispatch) => ({
      loadProcedureStatus: (id, token) => {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api
              .createWithAuth(token)
              .procedureStatuses.get(id, ['employee', 'employee_dependents', 'office', 'procedure_comments'])
          )
        )
      },
      loadTmpData: (procedureStatusId, token) => {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(procedureStatusId, {
                type: 'application',
                name: 'procedure_change_address',
              }),
              api
                .createWithAuth(token)
                .procedureStatuses.tmpFiles.singleFile(procedureStatusId, 'application', 'procedure_change_address'),
            ])
              .then((result) => {
                // 支給単位に定期期間を入れる処理
                const application_procedure_change_address = result[0]?.data?.application_procedure_change_address
                const commute_span_type = application_procedure_change_address?.commute_span_type
                if (commute_span_type) {
                  const commuting_expenses_train = application_procedure_change_address?.commuting_expenses?.train
                  // 通勤種別が電車を含む場合の処理
                  if (commuting_expenses_train) {
                    _.each(commuting_expenses_train, (train) => {
                      // 支給単位の上書き防止
                      if (!train['payment_duration_unit']) {
                        train['payment_duration_unit'] = unitMap[commute_span_type]
                      }
                    })
                  }

                  const commuting_expenses_bus = application_procedure_change_address?.commuting_expenses?.bus
                  // 通勤種別がバスを含む場合の処理
                  if (commuting_expenses_bus) {
                    _.each(commuting_expenses_bus, (bus) => {
                      if (!bus['payment_duration_unit']) {
                        bus['payment_duration_unit'] = unitMap[commute_span_type]
                      }
                    })
                  }

                  const commuting_expenses_car = application_procedure_change_address?.commuting_expenses?.car
                  if (commuting_expenses_car) {
                    // 通勤種別が車・二輪車等を含む場合の処理
                    if (!commuting_expenses_car['payment_duration_unit']) {
                      commuting_expenses_car['payment_duration_unit'] = unitMap[commute_span_type]
                    }
                  }
                }

                return result
              })
              .then((result) => Object.assign({}, ...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndAccept: async (procedureStatus, values, token, healthInsuranceType, nations) => {
        const {employee, procedureData, residentCard, commutingExpenses, dependentPrecedureData, dependents, dateOn} =
          formatValues(values, healthInsuranceType, nations)
        try {
          const authedApi = api.createWithAuth(token)
          await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: dateOn})
          await authedApi.procedureStatuses.changeAddressData.create(procedureStatus.id, procedureData)
          if (dependentPrecedureData) {
            await authedApi.procedureStatuses.changeAddressData.depData.updateAll(
              procedureStatus.id,
              dependentPrecedureData
            )
          }
          await updateEmployeeDetail(
            procedureStatus.employee_id,
            {employee, commutingExpenses, dependents, residentCard},
            token,
            ['residence_card'],
            null,
            {dependentsUpdateOnly: true}
          )
          if (values.residence_card && !(values.residence_card instanceof File)) {
            await authedApi.employees.updateResidenceCardFromProcedure(
              procedureStatus.employee_id,
              procedureStatus.id,
              'application',
              'procedure_change_address'
            )
          }
          await authedApi.procedureStatuses.accept(procedureStatus.id)
          dispatch(push(`/change_address/flow/${procedureStatus.id}`))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(Confirm)
