import moment from 'moment'
import Cookies from 'js-cookie'
import _ from 'lodash'
import queryString from 'query-string'
import {actionCreators} from 'actions'
import api from 'api'
import {formValueSelector, getFormValues, autofill as autofillForm} from 'redux-form'
import {groupFullName} from 'employees/form/EmploymentInfomation'

export const formName = 'employeeSearchForm'
export const selector = formValueSelector(formName)
export const getValues = getFormValues(formName)
export const autofill = autofillForm.bind(null, formName)
export const defaultQuery = {
  employment_status: 'employed',
  display_type: 'default',
  _page: '1',
  sort: 'staff_code',
  order: 'asc',
}
const defaultLimit = '100'

export const convertQueryToForm = (query) => {
  return Object.assign(
    {sort_type: `${query.sort}__${query.order}`},
    ...Object.entries(query)
      .map(([k, v]) => convertQueryToFormField(k, v))
      .filter(_.identity)
  )
}

export const convertQueryToFormField = (fieldName, value) => {
  switch (fieldName) {
    case 'office_ids':
      return {offices: value.join(',')}
    case 'group_ids':
      return {groups: value.join(',')}
    case 'employment_status_ids':
      return {employment_statuses: value}
    case 'position_ids':
      return {positions: value}
    case 'employment_type_ids':
      return {employment_types: value}
    case 'occupation_ids':
      return {occupations: value}
    case '_page':
      return {page: value}
    case 'sort':
    case 'order':
      return undefined
    case 'tag_ids':
      return {tags: value.join(',')}
    case 'skills':
      return {skills: value.join(',')}
    case 'qualifications':
      return {qualifications: value.join(',')}
    case 'language_ids':
      return {languages: value}
    case 'language_level_ids':
      return {language_levels: value}
    case 'languages_qualification_name':
      return {languages_qualification_name: value}
    case 'projects':
      return {projects: value.join(',')}
    default:
      return {[fieldName]: value}
  }
}

export const convertFormToQueryString = (data) =>
  queryString.stringify(convertFormToQuery(data), {arrayFormat: 'bracket'})

export const convertFormToQuery = (data) => {
  if (data === undefined) return {}
  return Object.assign(
    {},
    ...Object.entries(data)
      .map(([k, v]) => convertFormToQueryField(k, v))
      .filter(_.identity)
  )
}

export const convertFormToQueryField = (fieldName, value) => {
  switch (fieldName) {
    case 'offices': {
      const officesIds = value.split(',')
      return !_.isEqual(officesIds, ['']) ? {office_ids: officesIds} : null
    }
    case 'groups': {
      const groupIds = value.split(',')
      return !_.isEqual(groupIds, ['']) ? {group_ids: groupIds} : null
    }
    case 'employment_statuses':
      return !_.isEmpty(value) ? {employment_status_ids: value} : null
    case 'positions':
      return !_.isEmpty(value) ? {position_ids: value} : null
    case 'employment_types':
      return !_.isEmpty(value) ? {employment_type_ids: value} : null
    case 'occupations':
      return !_.isEmpty(value) ? {occupation_ids: value} : null
    case 'joined_health_insurance':
    case 'joined_welfare_pension_insurance':
    case 'joined_employment_insurance':
      return value !== '' ? {[fieldName]: value} : null
    case 'limit':
      return null
    case 'page':
      return {_page: value}
    case 'sort_type': {
      const [sort, order] = value.split('__')
      return {order, sort}
    }
    case 'tags': {
      return !_.isEmpty(value) ? {tag_ids: value.split(/,|\s/)} : null
    }
    case 'skills': {
      return !_.isEmpty(value) ? {skills: value.split(/,|\s/)} : null
    }
    case 'qualifications': {
      return !_.isEmpty(value) ? {qualifications: value.split(/,|\s/)} : null
    }
    case 'languages': {
      return !_.isEqual(value) ? {language_ids: value} : null
    }
    case 'language_levels': {
      return !_.isEqual(value) ? {language_level_ids: value} : null
    }
    case 'languages_qualification_name': {
      return !_.isEqual(value) ? {languages_qualification_name: value} : null
    }
    case 'projects':
      return !_.isEmpty(value) ? {projects: value.split(/,|\s/)} : null
    default:
      return {[fieldName]: value}
  }
}

const addedFieldsKey = (user) => `#${user.id}__added_fields`

export const addOptions = [
  {label: '旧姓', value: 'former_name'},
  {label: '生年月日', value: 'birthday', fields: ['birthday_start', 'birthday_end']},
  {label: '性別 ', value: 'genders'},
  {label: '居住国', value: 'countries'},
  {label: '住所 都道府県', value: 'prefecture_ids'},
  {
    label: '国籍情報',
    value: 'national_types',
    fields: ['national_types', 'nation_ids', 'visa_type_ids'],
  },
  {label: '退職日', value: 'retired_at', fields: ['retired_at_start', 'retired_at_end']},
  {label: '退職区分', value: 'retire_types'},
  {label: '扶養の有無', value: 'has_dependents'},
  {label: '配偶者の有無', value: 'marital_statuses'},
  {label: '給与形態', value: 'remuneration_types'},
  {label: '障害者区分', value: 'handicap_types'},
  {label: '寡婦（夫）区分 ', value: 'widow_types'},
  {label: '勤労学生区分', value: 'working_student_types'},
  {label: 'タグ', value: 'tags'},
  {label: 'スキル', value: 'skills'},
  {label: '資格', value: 'qualifications'},
  {
    label: '語学',
    value: 'languages',
    fields: [
      'languages',
      'language_levels',
      'languages_qualification_name',
      'languages_score_from',
      'languages_score_to',
    ],
  },
  {label: 'プロジェクト', value: 'projects'},
]

const makeAddedFields = (query, user) => {
  let savedQuery = []
  if (window.localStorage) {
    const saved = window.localStorage.getItem(addedFieldsKey(user))
    if (saved) {
      savedQuery = JSON.parse(saved)
    }
  }
  let hasQuery = addOptions
    .filter((opt) => (opt.fields || [opt.value]).some((key) => !_.isEmpty(query[key])))
    .map((opt) => opt.value)
  return _.union(savedQuery, hasQuery)
}

export const saveAddedFields = (user, addedField) => {
  if (window.localStorage) {
    window.localStorage.setItem(addedFieldsKey(user), JSON.stringify(_.isEmpty(addedField) ? [] : addedField))
  }
}

export const getCurrentQueryFromLocation = (location, user) => {
  const query = {
    ...defaultQuery,
    age_base_date: moment().format('YYYY/MM/DD'),
    ...queryString.parse(location.search, {arrayFormat: 'bracket'}),
    limit: getSavedDisplayEmployeeLimit(location.pathname, user),
  }
  return {...query, _added_fields: makeAddedFields(query, user)}
}

const limitKey = (pathname, user) => `${pathname.replace(/\//g, '')}#${user.id}__disp_emp_limit`

export const getSavedDisplayEmployeeLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user))
    if (!_.isEmpty(limit)) {
      return limit
    }
  }
  const limit = Cookies.get(limitKey(pathname, user))
  return _.isEmpty(limit) ? defaultLimit : limit
}

export const saveDisplayEmployeeLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit)
  } else {
    Cookies.set(limitKey(pathname, user), limit)
  }
}

export const fetchEmployees =
  (query, additionalParams = {}) =>
  (dispatch, getState) => {
    const {
      auth: {token},
    } = getState()
    const request = api.createWithAuth(token).employees.search({...query, ...additionalParams})
    dispatch(actionCreators.employees.list.fetchData(request))
  }

// MEMO: Layout呼び出し時に最新のofficeが取得されているのでここで再度取得する必要はない
export const fetchSelections = (token, dispatch) => {
  const promises = [
    api.createWithAuth(token).groups.list(),
    api.createWithAuth(token).employees.selections.positions(),
    api.createWithAuth(token).employees.selections.employmentTypes(),
    api.createWithAuth(token).employees.selections.occupations(),
    api.createWithAuth(token).employees.selections.tags(),
    api.createWithAuth(token).employees.selections.languages(),
    api.createWithAuth(token).employees.selections.languageLevels(),
  ]
  dispatch(
    actionCreators.fetchData(
      'selections',
      Promise.all(promises).then((results) => {
        const [
          groupsResult,
          positionsResult,
          employmentTypesResult,
          occupationsResult,
          tagsResult,
          languagesResult,
          languageLevelsResult,
        ] = results
        return {
          groups: groupsResult.groups.map((group) => ({
            value: `${group.id}`,
            label: groupFullName(group, _.keyBy(groupsResult.groups, 'id')),
            parent_value: `${group.parent_group_id}`,
          })),
          positions: positionsResult.data.map((item) => ({...item, id: `${item.id}`})),
          employmentTypes: employmentTypesResult.data.map((item) => ({...item, id: `${item.id}`})),
          occupations: occupationsResult.data.map((item) => ({...item, id: `${item.id}`})),
          tags: tagsResult.data.map((tag) => ({
            value: `${tag.id}`,
            label: tag.name,
          })),
          languages: languagesResult.data.map((item) => ({...item, id: `${item.id}`})),
          languageLevels: languageLevelsResult.data.map((item) => ({...item, id: `${item.id}`})),
        }
      })
    )
  )
}

export const hasConditions = (formValues) => {
  return !_.isEqual(_.pickBy(_.omit(convertFormToQuery(formValues), ['_added_fields', 'age_base_date'])), defaultQuery)
}
