import {FC} from 'react'
import {useSelector} from 'react-redux'
import {Presenter} from './Presenter'

// @ts-ignore
import {isOfficeAdminSelector} from 'utils'

export const Index: FC = () => {
  const isOfficeAdmin = useSelector((state: any) => isOfficeAdminSelector(state))

  return <Presenter isOfficeAdmin={isOfficeAdmin} />
}
