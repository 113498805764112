import {AxiosError, AxiosResponse} from 'axios'
import _ from 'lodash'
import {logError} from './telemetry'
import {Dispatch} from 'redux'
import {push, replace} from 'connected-react-router'
import {UNEXPECTED_ERROR} from 'consts/errorMessages'
import {ErrorResponse, ErrorObj} from 'types/api'
import {notifyError} from 'store/actions/notify'

// @ts-ignore
import readBlob from 'readBlob'

export const isAxiosError = (error: any): error is AxiosError<ErrorResponse> => {
  return !!error.isAxiosError
}

export const handleError = async (error: Error, dispatch: Dispatch<any>): Promise<ErrorObj[] | void> => {
  if (process.env['NODE_ENV'] !== 'production') {
    console.error(error)
  }

  if (isAxiosError(error)) {
    // レスポンスがなかった場合のエラー
    if (!error.response) {
      return handleUnexpectedError(error, dispatch)
    }

    if (error.response.data instanceof Blob && error.response.data.type == 'application/json') {
      const data = await readBlob(error.response.data).json()
      error.response.data = data
    }

    // handle base error for all status code
    handleBaseErrors(error.response, dispatch)

    switch (error.response.status) {
      case 400:
        return handleBadRequest(error.response)
      case 401:
        return handleUnauthorized()
      case 403:
        return handleForbidden(error.response, dispatch)
      case 422:
        return handleUnprocessableEntity(error.response)
      default:
        return handleUnexpectedError(error, dispatch)
    }
  }
}

const handleUnexpectedError = (error: Error, dispatch: Dispatch<any>) => {
  logError(error)
  dispatch(notifyError(UNEXPECTED_ERROR))
}

// handle error for all status
// _baseの中身は全てnotifyErrorする
const handleBaseErrors = (response: AxiosResponse<ErrorResponse>, dispatch: Dispatch<any>) => {
  const baseErrors = response.data?._errors?.['_base']

  if (baseErrors) {
    if (typeof baseErrors === 'string') {
      dispatch(notifyError(baseErrors))
      return
    }

    const errors = _.uniq([...baseErrors].filter(_.isString))
    errors.forEach((error) => dispatch(notifyError(error)))
  }
}

// handle error for 404
const handleBadRequest = (response: AxiosResponse<ErrorResponse>): ErrorObj[] | void => {
  return filterdErrors(response)
}

// handle error for 401
const handleUnauthorized = () => {
  const isTm = /^\/tm/.test(window.location.pathname)
  window.location.pathname = isTm ? '/api/jbc-oauth/login?d=tm' : '/api/jbc-oauth/login'
}

// handle error for 403
const handleForbidden = (response: AxiosResponse<ErrorResponse>, dispatch: Dispatch<any>) => {
  if (response.data._redirect_to_employee) {
    dispatch(push('/initial_input'))
    return
  }

  if (response.data._redirect_to_login) {
    dispatch(replace('/login'))
    return
  }

  dispatch(notifyError('操作権限がありません'))
  return
}

// handle error for 422
const handleUnprocessableEntity = (response: AxiosResponse<ErrorResponse>): ErrorObj[] | void => {
  return filterdErrors(response)
}

// エラーレスポンスから_baseを除いたデータを返却する
const filterdErrors = (response: AxiosResponse<ErrorResponse>): ErrorObj[] | void => {
  if (response.data._errors) {
    const errors = _.omit(response.data._errors, ['_base'])
    return Object.keys(errors).map((key) => {
      return {[key]: errors[key]} as ErrorObj
    })
  }
}
