import React from 'react'
import {connect} from 'react-redux'
import {autofill} from 'redux-form'
import _ from 'lodash'
import BankSearchModal from 'jbc-front/components/BankSearchModal'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'

const initialState = {
  currentPage: 0,
  selectedBank: null,
}

const camelizeList = (arr) => arr.map((item) => _.mapKeys(item, (value, key) => _.camelCase(key)))

class BankSearchModalWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  _handleBankSearch = (q, page) => {
    const {token, dispatch} = this.props
    const params = page !== undefined ? {q, page} : {q}

    this.setState({
      currentPage: page,
    })

    dispatch(
      actionCreators.fetchData(
        'searchBanks',
        api
          .createWithAuth(token)
          .employees.banks.list(params)
          .then(({data: {payload}}) => ({
            banks: camelizeList(payload.banks),
            count: payload.count,
            totalPages: payload.total_pages,
          }))
      )
    )
  }

  _handleBranchSearch = (bank, q, page) => {
    const {token, dispatch} = this.props
    const params = page !== undefined ? {bank_id: bank.id, q, page} : {bank_id: bank.id, q}

    this.setState({
      currentPage: page,
    })

    dispatch(
      actionCreators.fetchData(
        'searchBranches',
        api
          .createWithAuth(token)
          .employees.branches.list(params)
          .then(({data: {payload}}) => ({
            branches: camelizeList(payload.bank_branches),
            count: payload.count,
            totalPages: payload.total_pages,
          }))
      )
    )
  }

  render() {
    const {isOpen, hideModal, searchBanks, searchBranches, dispatch, formName, onAfterSelect} = this.props
    const {currentPage, selectedBank} = this.state
    const {count = 0, totalPages = 0} = selectedBank === null ? searchBanks : searchBranches
    const banks = _.get(searchBanks, 'banks', [])
    const branches = _.get(searchBranches, 'branches', [])

    return (
      <BankSearchModal
        isOpen={isOpen}
        hideModal={hideModal}
        selectedBank={selectedBank}
        banks={banks}
        branches={branches}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        handleBankSearch={this._handleBankSearch}
        handleBranchSearch={this._handleBranchSearch}
        onBankSelect={(bank) => {
          dispatch(actionCreators.fetchDestroy('searchBanks'))
          this.setState({
            selectedBank: bank,
          })
          this._handleBranchSearch(bank, '')
        }}
        onReset={() => {
          dispatch(actionCreators.fetchDestroy('searchBranches'))
          this.setState(initialState)
          this._handleBankSearch('')
        }}
        onSelect={(bank, branch) => {
          dispatch(actionCreators.fetchDestroy('searchBranches'))
          dispatch(autofill(formName, 'bank_code', bank.bankCode))
          dispatch(autofill(formName, 'bank_name', bank.name))
          dispatch(autofill(formName, 'bank_branch_code', branch.branchCode))
          dispatch(autofill(formName, 'bank_branch_name', branch.name))
          this.setState(initialState)
          hideModal()
          onAfterSelect && onAfterSelect()
        }}
        onHide={() => {
          dispatch(actionCreators.fetchDestroy('searchBanks'))
          dispatch(actionCreators.fetchDestroy('searchBranches'))
          this.setState(initialState)
        }}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  searchBanks: fetchSelector(state, 'searchBanks'),
  searchBranches: fetchSelector(state, 'searchBranches'),
})
export default connect(mapStateToProps)(BankSearchModalWrapper)
