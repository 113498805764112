import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import {Panel, Headding} from 'components/SecondaryPanel'
import PrepareModal from 'procedureStatuses/eGov/PrepareModal'
import styles from 'procedureStatuses/eGov/Prepare.scss'
import {Link} from 'react-router-dom'
import {Pulldown} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import _ from 'lodash'
import {withRouter} from 'react-router'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'

export const eGovStatuses = {
  preparing: '準備中',
  processing: '処理中',
  inadequacy_error: 'エラー',
  arrived: '役所到達',
  under_review: '役所審査中',
  review_completed: '審査終了',
  procedure_completed: '手続終了',
  withdraw_processing: `処理中\n（取下げ処理中）`,
  withdraw_arrived: `役所到達\n（取下げ処理中）`,
  withdraw_under_review: `審査中\n（取下げ処理中）`,
  withdraw_procedure_completed: `手続終了\n（取下げ済み）`,
  procedure_completed_returnable: `手続終了\n（返戻）`,
  procedure_completed_resubmitted: `手続終了\n（再提出済み）`,
  under_review_wait_correction: `審査中\n（補正待ち）`,
}

class Prepare extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  render() {
    const {procedureStatus, loadProcedureStatus, history, planType} = this.props
    const {egov_informations: eGovInfomations, egov_procedure_statuses: eGovProcedures} = procedureStatus
    return (
      <React.Fragment>
        {!procedureStatus.egov_client_information_completed && (
          <Panel>
            <Headding>電子申請</Headding>
            <Button
              primary
              className={styles.prepareButton}
              onClick={() => {
                history.push('/settings/egov')
              }}
              disabled={planType === 'free_plan'}
              disabledReason={<p>無料プランのため利用できません。</p>}
            >
              電子申請を設定する
            </Button>
          </Panel>
        )}
        {procedureStatus.egov_client_information_completed && !_.isEmpty(eGovInfomations) && (
          <div className={styles.notice}>
            申請情報を入力してください
            <ActionButton
              primary
              onClick={() => {
                this.setState({showModal: true})
              }}
              disabled={planType === 'free_plan'}
              disabledReason={<p>無料プランのため利用できません。</p>}
            >
              電子申請の設定をする
            </ActionButton>
            <PrepareModal
              isOpen={this.state.showModal}
              loadProcedureStatus={loadProcedureStatus}
              hideModal={() => {
                this.setState({showModal: false})
              }}
            />
          </div>
        )}
        {!_.isEmpty(eGovProcedures) && (
          <Panel>
            <Headding>電子申請</Headding>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.th}>ステータス</th>
                  <th className={styles.thDoc}>書類</th>
                </tr>
              </thead>
              <tbody>
                {eGovProcedures.map((eGov) => (
                  <tr key={eGov.id} className={styles.tr}>
                    <td className={styles.td}>{eGovStatuses[eGov.status]}</td>
                    <td title={eGov.form_name} className={styles.reportName}>
                      {eGov.form_name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className={styles.detailLink}>
              <Link to={WITH_AUTH_ADMIN_PATHS.EGOV_PROCEDURES.INDEX} className="u-txt-link">
                一覧から詳細を確認
                <Pulldown size={20} transform="rotate(270)" color="#AAA" />
              </Link>
            </div>
          </Panel>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(
  connect((state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    planType: state.client.current.plan_type,
  }))(Prepare)
)
