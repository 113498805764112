import React from 'react'
import {Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {
  commuteTypes,
  carCommutingDistanceTypes,
  paymentDurationUnits,
  paymentTypeForDurationUnits,
  trainTypes,
  carTypes,
  busTypes,
  vehicleTypes,
} from 'employees/form/CommutingAllowanceInfomation'
import {Dl, Dd, Dt, SubPanel, ThirdPanel, Grid} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import styles from 'employees/show/Parts.scss'

const CommutingAllowanceInformation = ({employee, editLink, canEdit, forProfiles}) => {
  const commutingExpenses = _.groupBy(employee.commuting_expenses || [], 'commuting_expense_type')
  return (
    <SubPanel>
      <HeaddingWithLink to={`${editLink}#CommutingAllowanceInfomation`} title="通勤手当" canEdit={canEdit} isSubPanel />
      <Item>
        <Grid forProfiles={forProfiles}>
          <Dl>
            <Dt>通勤手段</Dt>
            <Dd>{recordDisplay.option(employee.commute_type, commuteTypes)}</Dd>
          </Dl>
        </Grid>
        <Grid>
          {commutingExpenses.train &&
            trainTypes.includes(employee.commute_type) &&
            commutingExpenses.train.map((expense, index) => (
              <ThirdPanel key={index}>
                <ThirdPanel.Headding>電車 #{index + 1}</ThirdPanel.Headding>
                <Grid className={[styles.forHalfGrid, styles.tableDesign]}>
                  <Dl>
                    <Dt>乗り駅</Dt>
                    <Dd>{recordDisplay(expense.get_on)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>支給単位</Dt>
                    <Dd>{recordDisplay.option(expense.payment_duration_unit, paymentDurationUnits)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>降り駅</Dt>
                    <Dd>{recordDisplay(expense.get_off)}</Dd>
                  </Dl>
                  {paymentTypeForDurationUnits(expense.payment_duration_unit) != 1 && (
                    <Dl>
                      <Dt>支給月</Dt>
                      <Dd>
                        {recordDisplay.option(
                          expense.payment_duration_type,
                          paymentTypeForDurationUnits(expense.payment_duration_unit)
                        )}
                      </Dd>
                    </Dl>
                  )}
                  <Dl>
                    <Dt>経由地</Dt>
                    <Dd>{expense.via || '無'}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>片道運賃</Dt>
                    <Dd>{recordDisplay.yen(expense.single_fare)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>定期代</Dt>
                    <Dd>{recordDisplay.yen(expense.pass_price)}</Dd>
                  </Dl>
                </Grid>
              </ThirdPanel>
            ))}
          {commutingExpenses.bus &&
            busTypes.includes(employee.commute_type) &&
            commutingExpenses.bus.map((expense, index) => (
              <ThirdPanel key={index}>
                <ThirdPanel.Headding>バス #{index + 1}</ThirdPanel.Headding>
                <Grid className={[styles.forHalfGrid, styles.tableDesign]}>
                  <Dl>
                    <Dt>乗り場</Dt>
                    <Dd>{recordDisplay(expense.get_on)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>支給単位</Dt>
                    <Dd>{recordDisplay.option(expense.payment_duration_unit, paymentDurationUnits)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>降り場</Dt>
                    <Dd>{recordDisplay(expense.get_off)}</Dd>
                  </Dl>
                  {paymentTypeForDurationUnits(expense.payment_duration_unit) != 1 && (
                    <Dl>
                      <Dt>支給月</Dt>
                      <Dd>
                        {recordDisplay.option(
                          expense.payment_duration_type,
                          paymentTypeForDurationUnits(expense.payment_duration_unit)
                        )}
                      </Dd>
                    </Dl>
                  )}
                  <Dl>
                    <Dt>経由地</Dt>
                    <Dd>{expense.via || '無'}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>片道運賃</Dt>
                    <Dd>{recordDisplay.yen(expense.single_fare)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>定期代</Dt>
                    <Dd>{recordDisplay.yen(expense.pass_price)}</Dd>
                  </Dl>
                </Grid>
              </ThirdPanel>
            ))}
          {commutingExpenses.car &&
            carTypes.includes(employee.commute_type) &&
            commutingExpenses.car.map((expense, index) => (
              <ThirdPanel key={index}>
                <ThirdPanel.Headding>車・二輪車等 #{index + 1}</ThirdPanel.Headding>
                <Grid className={[styles.forHalfGrid, styles.tableDesign]}>
                  <Dl>
                    <Dt>車両種別</Dt>
                    <Dd>{recordDisplay.option(expense.vehicle_type, vehicleTypes)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>片道通勤距離</Dt>
                    <Dd>{recordDisplay.option(expense.car_commuting_distance_type, carCommutingDistanceTypes)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>出発地</Dt>
                    <Dd>{recordDisplay(expense.get_on)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>支給単位</Dt>
                    <Dd>{recordDisplay.option(expense.payment_duration_unit, paymentDurationUnits)}</Dd>
                  </Dl>
                  <Dl>
                    <Dt>到着地</Dt>
                    <Dd>{recordDisplay(expense.get_off)}</Dd>
                  </Dl>
                  {paymentTypeForDurationUnits(expense.payment_duration_unit) != 1 && (
                    <Dl>
                      <Dt>支給月</Dt>
                      <Dd>
                        {recordDisplay.option(
                          expense.payment_duration_type,
                          paymentTypeForDurationUnits(expense.payment_duration_unit)
                        )}
                      </Dd>
                    </Dl>
                  )}
                  <Dl>
                    <Dt>支給額</Dt>
                    <Dd>{recordDisplay.yen(expense.pass_price)}</Dd>
                  </Dl>
                </Grid>
              </ThirdPanel>
            ))}
        </Grid>
      </Item>
    </SubPanel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(CommutingAllowanceInformation)
