import React, {Component} from 'react'
import {recordDisplay} from 'utils'
import {statuses, procedureTypes} from 'procedureStatuses/List'
import {makeMaternityDatum} from 'procedures/maternityLeave/Form'

const divisions = {
  single: '単胎',
  multiple: '多胎',
}

class ProceduresList extends Component {
  render() {
    const {procedureStatuses, handleClick} = this.props
    return (
      <div>
        <div className="l-overflow-scroll">
          <table className="m-table-list m-table-fixed">
            <thead>
              <tr>
                <th>手続き</th>
                <th>出産予定年月日</th>
                <th>出産種別</th>
                <th>休業開始年月日</th>
                <th>休業終了（予定）年月日</th>
                <th>ステータス</th>
              </tr>
            </thead>
            <tbody>
              {procedureStatuses.map((procedureStatus) => {
                const procedureMaternityDatum = makeMaternityDatum(procedureStatus)
                return (
                  <tr
                    key={procedureStatus.id}
                    style={{cursor: 'pointer'}}
                    className="table-hover"
                    onClick={() => handleClick(procedureStatus.id)}
                  >
                    <td>{procedureTypes[procedureStatus.procedure_type]}</td>
                    <td>
                      {recordDisplay(
                        procedureMaternityDatum?.expected_birth_date &&
                          procedureMaternityDatum.expected_birth_date.replace(/-/g, '/')
                      )}
                    </td>
                    <td>
                      {recordDisplay(
                        procedureMaternityDatum?.maternity_division &&
                          divisions[procedureMaternityDatum.maternity_division]
                      )}
                    </td>
                    <td>
                      {recordDisplay(
                        procedureMaternityDatum?.started_at && procedureMaternityDatum.started_at.replace(/-/g, '/')
                      )}
                    </td>
                    <td>
                      {recordDisplay(
                        procedureMaternityDatum?.expected_finished_at &&
                          procedureMaternityDatum.expected_finished_at.replace(/-/g, '/')
                      )}
                    </td>
                    <td>{recordDisplay(statuses[procedureStatus.status])}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
export default ProceduresList
