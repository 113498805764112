import {FC} from 'react'
import {ContractSendStatus} from '../../query'
import {FORMAT} from 'consts/format'
import styles from './ContractSendStatusColumn.scss'

interface ContractSendStatusColumnProps {
  status: ContractSendStatus
}

export const ContractSendStatusColumn: FC<ContractSendStatusColumnProps> = ({status}) => {
  switch (status) {
    case 'unsent':
      return <>{FORMAT.DEFAULT_STRING}</>
    case 'sent':
      return <>{FORMAT.TRUTHY_STRING}</>
    case 'canceled':
      return <span className={styles.canceledText}>取消済</span>
    default:
      return <>{FORMAT.DEFAULT_STRING}</>
  }
}
