import moment from 'moment'
import _ from 'lodash'
import React from 'react'
import {parse} from 'query-string'
import {toJpYearDate} from 'jbc-front/utils/jpYear'
import {toColor} from 'settings/employeeCustom/ColorSelect'

export const isDevelopmentEnv = process.env.NODE_ENV.startsWith('development')

export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
]

export const toDateStr = (date, format = 'LL') => {
  const dateMoment = moment(date)
  return dateMoment.isValid() ? dateMoment.format(format) : ''
}

export const toFormValues = (data, removeId = false) => {
  if (!data) {
    return {}
  }
  return _.fromPairs(
    _.toPairs(data)
      .filter(([key]) => key !== '__typename' && (removeId !== true || key !== 'id'))
      .filter(
        ([, value]) =>
          (_.isString(value) && value !== '') ||
          (_.isNumber(value) && !_.isNaN(value)) ||
          _.isBoolean(value) ||
          (_.isObject(value) && (value.url || value.filename))
      )
      .map(([key, value]) =>
        _.isNumber(value)
          ? [key, `${value}`]
          : _.isObject(value) && value.filename
          ? [key, {...value, name: value.filename}]
          : [key, value]
      )
  )
}

export const hasAddress = (data) => {
  return data.postcode_0 || data.postcode_1 || data.prefecture_id || data.city || data.house_number ? true : false
}

export const recordDisplay = (text) => (text ? text : '―')

recordDisplay.fullName = (record, withKana, onlyKana = false) => {
  const {first_name, last_name, display_last_name, display_first_name, last_name_kana, first_name_kana} = record || {}
  if (onlyKana) {
    if (!first_name_kana || !last_name_kana) {
      return recordDisplay()
    }
    return `${last_name_kana} ${first_name_kana}`
  }

  if (!first_name || !last_name) {
    return recordDisplay()
  }
  const firstName = withKana ? first_name : display_first_name || first_name
  const lastName = withKana ? last_name : display_last_name || last_name
  return [
    `${lastName} ${firstName}`,
    withKana && last_name_kana && first_name_kana && `（${last_name_kana} ${first_name_kana}）`,
  ]
    .filter((str) => str)
    .join(' ')
}

recordDisplay.registeredFullName = (record, withKana) => {
  const {first_name, last_name, last_name_kana, first_name_kana} = record || {}
  if (!first_name || !last_name) {
    return recordDisplay()
  }
  return [
    `${last_name} ${first_name}`,
    withKana && last_name_kana && first_name_kana && `（${last_name_kana} ${first_name_kana}）`,
  ]
    .filter((str) => str)
    .join(' ')
}

recordDisplay.yen = (record) => {
  if (!record) {
    return recordDisplay()
  }
  return numberWithSplit(record) + '円'
}

recordDisplay.splitComma = (record) => {
  if (!record) {
    return recordDisplay()
  }
  return numberWithSplit(record)
}

const JAPAN_VALUE = 392

recordDisplay.address = (record, nations) => {
  if (!record) {
    return recordDisplay()
  }
  const japan = nations && nations.find((nation) => nation.value === JAPAN_VALUE)
  if (
    (japan && record.country && record.country !== japan.id) ||
    record.residence_status === 'different_and_foreign_address'
  ) {
    const country = record.country && nations.find((nation) => nation.id === record.country)
    return country ? `${country.name} ${record.address_foreign}` : recordDisplay(record.address_foreign)
  }
  return (
    [
      record.postcode_0 && record.postcode_1 && `〒${record.postcode_0}-${record.postcode_1}\n`,
      prefectures[record.prefecture_id - 1],
      record.city,
      record.house_number,
      record.building,
    ]
      .filter((str) => str)
      .join(' ') || recordDisplay()
  )
}

recordDisplay.nationalType = (record) => {
  if (!record || !record.national_type || record.national_type !== 'foreigner') {
    return recordDisplay()
  }
  return '外国人'
}

recordDisplay.numbers = (record, name, length, split = '-') => {
  if (!record) {
    return recordDisplay()
  }
  const fields = _.range(0, length).map((idx) => record[`${name}_${idx}`])
  if (_.some(fields, (field) => !field)) {
    return recordDisplay()
  }
  return fields.join(split)
}

recordDisplay.birthday = (record, format = 'wareki', withAge = true) => {
  if (!record || !record.birthday) {
    return recordDisplay()
  }
  if (!withAge) {
    return `${recordDisplay.date(record.birthday, format)}`
  }
  return `${recordDisplay.date(record.birthday, format)}（${moment().diff(record.birthday, 'years')}歳）`
}

recordDisplay.age = (record) => {
  if (!record || !record.birthday) {
    return recordDisplay()
  }
  return `${moment().diff(record.birthday, 'years')}歳`
}

recordDisplay.date = (date, format = 'wareki') => {
  if (!date) {
    return recordDisplay()
  }
  if (format === 'wareki') {
    return toJpYearDate(date)
  }
  if (format === 'seireki') {
    return moment(date).format('LL')
  }
  return moment(date).format(format)
}

recordDisplay.dateYearMonth = (date) => {
  if (!date) {
    return recordDisplay()
  }
  return `${moment(date).format('YYYY')}年${moment(date).format('MMMM')}`
}

recordDisplay.dateOrTime = (dateTime) => {
  if (!dateTime) {
    return recordDisplay()
  }
  if (moment().isSame(dateTime, 'day')) {
    return moment(dateTime).format('LT')
  }
  if (moment().subtract(1, 'days').isSame(dateTime, 'day')) {
    return '昨日'
  }
  if (moment().subtract(2, 'days').isSame(dateTime, 'day')) {
    return '一昨日'
  }
  if (moment().isSame(dateTime, 'year')) {
    return moment(dateTime).format('MMMDo')
  }
  return moment(dateTime).format('LL')
}

recordDisplay.workingYears = (employee) => {
  if (!employee || !employee.joined_at) {
    return recordDisplay()
  }
  const base = (employee.retired_at ? moment.min(moment(), moment(employee.retired_at)) : moment()).add(1, 'day')
  const months = base.diff(employee.joined_at, 'months')

  if (months <= 0) {
    return recordDisplay()
  }
  if (months < 12) {
    return `${months}ヶ月`
  } else if (months % 12 == 0) {
    return `${months / 12}年`
  } else {
    return `${Math.floor(months / 12)}年${months % 12}ヶ月`
  }
}

recordDisplay.option = (value, options) => {
  const option = value && options.find((option) => option.value == value)
  return option ? option.label : recordDisplay()
}

recordDisplay.file = (value) =>
  value && value.url ? (
    <a href={value.url} className="u-txt-link" target="_blank" rel="noopener noreferrer" download>
      {value.filename}
    </a>
  ) : (
    recordDisplay(value && value.filename)
  )

recordDisplay.boolean = (value, trueText = '有', falseText = '無') => {
  if (value === true) {
    return trueText
  } else if (value === false) {
    return falseText
  }
  return recordDisplay()
}

recordDisplay.texts = (values = [], separator = ' ') => {
  const validValues = _.reject(values, (value) => _.isEmpty(value))
  if (!validValues.length) return recordDisplay()
  return recordDisplay(_.join(validValues, separator))
}

export const getState = (dispatch) =>
  new Promise((resolve) => {
    dispatch((dispatch, getState) => {
      resolve(getState())
    })
  })

export const getQueryDiffFactory = (defaultQuery) => (query) => {
  return _.omitBy(query, (value, key) => !value || (defaultQuery[key] && defaultQuery[key] === value))
}

export const queryEqual = (query, other) => {
  return (
    Object.keys(query).length === Object.keys(other).length &&
    _.reduce(query, (result, value, key) => result && other[key] == value, true)
  )
}

export const toBooleanProps = {
  options: [
    {value: 'true', label: '有'},
    {value: 'false', label: '無'},
  ],
  normalize: (value) => {
    switch (value) {
      case 'true':
        return true
      case 'false':
        return false
      default:
        return null
    }
  },
  format: (value) => {
    switch (value) {
      case true:
        return 'true'
      case false:
        return 'false'
      default:
        return null
    }
  },
}

export const makeBackUrl = ({pathname, search}) => encodeURIComponent(pathname + search)

export const parseBackUrl = (location, fallback) => {
  const {back_to: backTo} = parse(location.search)
  if (backTo && backTo.match(/^\/(?!api)/)) {
    return backTo
  } else {
    return fallback
  }
}

export const numberWithSplit = (num) => {
  const chars = [...`${num}`]
  const isMinus = chars[0] === '-'
  const charsWithoutMinus = isMinus ? chars.slice(1) : chars
  const dotIndex = charsWithoutMinus.findIndex((n) => n === '.')
  const [charsBeforeDot, charsAfterDot] =
    dotIndex === -1
      ? [charsWithoutMinus, []]
      : [charsWithoutMinus.slice(0, dotIndex), charsWithoutMinus.slice(dotIndex)]
  return (
    (isMinus ? '-' : '') +
    _.chunk(charsBeforeDot.reverse(), 3)
      .reverse()
      .map((arr) => arr.reverse().join(''))
      .join(',') +
    charsAfterDot.join('')
  )
}

export const isAdminSelector = (state) =>
  ['full_admin', 'office_admin', 'limited_admin'].includes(
    _.get(state.session.currentUser, 'client_role.client_role_type')
  )

export const isFullAdminSelector = (state) =>
  _.get(state.session.currentUser, 'client_role.client_role_type') === 'full_admin'

export const isOfficeAdminSelector = (state) =>
  _.get(state.session.currentUser, 'client_role.client_role_type') === 'office_admin'

export const isLimitedAdminSelector = (state) =>
  _.get(state.session.currentUser, 'client_role.client_role_type') === 'limited_admin'

export const toUpperCamel = (value) => value |> _.camelCase |> _.upperFirst

export const addDay = (dayStr, num) => dayStr && moment(dayStr).clone().add(num, 'day').format('YYYY-MM-DD')

export const showCategory = (setting, code) => !!_.find(setting, {code: code, visible: true})

export const findCustomFieldValue = (employee, field) =>
  employee.employees_custom_values?.filter(
    (value) => value.custom_employee_field_id === field.id && isCustomFieldValueExist(value.adjusted_value)
  ) || {}

const isCustomFieldValueExist = (value) => {
  switch (typeof value) {
    case 'string':
      return !!value
    case 'boolean':
      return true
    case 'object':
      if (value === null) {
        return false
      }
      return !!Object.keys(value).length
    default:
      return false
  }
}

export const getCategoryIconName = (setting, code) => _.get(_.find(setting, {code: code, visible: true}), 'icon_name')

export const getCategoryIconColor = (setting, code) =>
  (!_.isNull(_.get(_.find(setting, {code: code, visible: true}), 'icon_color')) &&
    toColor(_.get(_.find(setting, {code: code, visible: true}), 'icon_color'))) ||
  null

export const sortFieldGroupsAll = (employeeFieldGroups, fieldGroups) => {
  const defaultFieldGroupsMasterCount = 22

  if (employeeFieldGroups.length > defaultFieldGroupsMasterCount) {
    const [defaultFieldGroups, addFieldGroups] = _.chunk(employeeFieldGroups, defaultFieldGroupsMasterCount)
    if (hasSetting(addFieldGroups)) {
      return sortFieldGroups(employeeFieldGroups, fieldGroups)
    } else {
      return [...sortFieldDefaultGroups(defaultFieldGroups, fieldGroups), ...addFieldGroups]
    }
  } else {
    return sortFieldDefaultGroups(employeeFieldGroups, fieldGroups)
  }
}

const sortFieldGroups = (employeeFieldGroups, fieldGroups) =>
  _.sortBy([...employeeFieldGroups, ...fieldGroups], (group) =>
    group?.setting?.position ? group.setting.position : group.position
  )

const sortFieldDefaultGroups = (employeeFieldGroups, fieldGroups) => {
  if (hasSetting(employeeFieldGroups)) {
    return sortFieldGroups(employeeFieldGroups, fieldGroups)
  } else {
    return [...employeeFieldGroups, ...fieldGroups]
  }
}

const hasSetting = (employeeFieldGroups) => _.filter(employeeFieldGroups, (g) => !!g.setting?.position).length > 0
