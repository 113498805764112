import type {Action, Dispatch, Store} from 'redux'
import type {State} from 'types/state'
import {LOCATION_CHANGE, LocationChangeAction} from 'connected-react-router'
import Rollbar from 'rollbar'
import {setSelectedOffices} from '../slices/session'

// @ts-ignore
import {isDevelopmentEnv} from 'utils'

interface Buffer {
  timeoutHandle: NodeJS.Timeout
  actions: Action[]
}

type FetchName = string

interface FetchDataAction<P, S = undefined> extends Action {
  payload: S
  meta: {
    name: FetchName
    promise: Promise<P>
  }
}

const isFetchDataAction = (action: Action): action is FetchDataAction<unknown> => action.type === 'FETCH_DATA'

interface FetchDestroyAction extends Action {
  meta: {
    name: FetchName
  }
}

const isFetchDestroyAction = (action: Action): action is FetchDestroyAction => action.type === 'FETCH_DESTROY'

const isLocationChangeAction = (action: Action): action is LocationChangeAction => action.type === LOCATION_CHANGE

/**
 * https://github.com/d-o-n-u-t-s/roumu-front/pull/2219 で発見された不具合を検知するためのredux middlewareを生成
 */
export const createEarlyFetchDestroyDetector = () => {
  let currentBuffer: Buffer | null = null

  return (_store: Store<State>) => (next: Dispatch) => (action: Action) => {
    if (isLocationChangeAction(action)) {
      const locationChangeAction = action

      if (currentBuffer) {
        clearTimeout(currentBuffer.timeoutHandle)
      }

      const buffer: Buffer = {
        timeoutHandle: setTimeout(() => {
          currentBuffer = null

          const location = locationChangeAction.payload.location
          const path = `${location.pathname}${location.search}${location.hash}`

          const fetching = new Set<string>()
          for (const action of buffer.actions) {
            if (!action.type || action.type.startsWith('@@')) {
              continue
            }

            if (isFetchDataAction(action)) {
              fetching.add(`FETCH_DATA/${action.meta.name}`)
            }
            if (action.type.match(/\/FETCH_DATA$/)) {
              fetching.add(action.type.replace(/\/FETCH_DATA$/, ''))
            }

            if (isFetchDestroyAction(action)) {
              const key = `FETCH_DATA/${action.meta.name}`
              if (fetching.has(key)) {
                Rollbar.error(`EARLY_DESTROY: ${key} in ${path}`, {store_key: key, path})
                if (isDevelopmentEnv) {
                  console.error(`EARLY_DESTROY: ${key} in ${path}`, {store_key: key, path})
                }
              }
            }
            if (action.type.match(/\/DESTROY$/)) {
              const key = action.type.replace(/\/DESTROY$/, '')
              if (fetching.has(key)) {
                Rollbar.error(`EARLY_DESTROY: ${key} in ${path}`, {store_key: key, path})
                if (isDevelopmentEnv) {
                  console.error(`EARLY_DESTROY: ${key} in ${path}`, {store_key: key, path})
                }
              }
            }

            // session/setSelectedOfficesがdispatchされると強制リフレッシュが走って偽陽性になってしまうので無視する
            if (setSelectedOffices.match(action)) {
              fetching.clear()
            }
          }
        }, 1000),
        actions: [],
      }

      currentBuffer = buffer
    } else if (currentBuffer) {
      currentBuffer.actions.push(action)
    }

    next(action)
  }
}
