import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import Modal from 'jbc-front/components/Modal'
import Button from 'jbc-front/components/Button'
import _ from 'lodash'
import moment from 'moment'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import styles from 'employees/RosterOfEmployees.scss'

class rosterOfEmployees extends Component {
  static defaultProps = {
    rosterOfEmployees: [],
  }

  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  componentDidMount() {
    const {token, loadRosterOfEmployees} = this.props
    loadRosterOfEmployees(token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {rosterOfEmployees, token, createRosterOfEmployees, loading, loadRosterOfEmployees, dispatch} = this.props
    const latest = _.last(_.sortBy(rosterOfEmployees, 'created_at')) || {}
    const latestCreated = _.findLast(
      _.sortBy(rosterOfEmployees, 'created_at'),
      (rosterOfEmployee) => rosterOfEmployee.status === 'created'
    )
    const working = ['waiting', 'in_progress'].includes(latest.status)
    return (
      <div className={styles.container}>
        <Modal
          isOpen={this.state.showModal}
          hideModal={() => {
            this.setState({showModal: false})
          }}
          header="労働者名簿一括作成"
          body={
            <div>
              {latest.status === 'error' && (
                <p className={styles.error}>労働者名簿作成中にエラーが発生しました。再度作成ボタンを押してください。</p>
              )}
              {latestCreated ? (
                <React.Fragment>
                  作成済みの労働者名簿が存在します。
                  <br />
                  <div className={styles.downloadLink}>
                    <a
                      href={`/api/employee_generated_files/?token=${latestCreated.token}`}
                      target="_blank"
                      rel="noopener"
                      download
                      className="u-txt-link"
                    >
                      労働者名簿ダウンロード
                    </a>
                    （{moment(latestCreated.generated_at).format('YYYY/MM/DD HH:mm')}作成）
                  </div>
                  <p>
                    再作成を行うと既存の労働者名簿は削除されます。再作成が完了しましたらメールにてお送りいたします。
                    <br />
                    <br />
                    ※従業員数によって時間がかかる場合がございます。予めご了承ください。
                    <br />
                    ※労働者名簿は作成日から1ヶ月間サーバーに保存されます。
                    <br />
                  </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>
                    労働者名簿を作成します。作成が完了しましたらメールにてお送りいたします。
                    <br />
                    <br />
                    ※従業員数によって時間がかかる場合がございます。予めご了承ください。
                    <br />
                    ※労働者名簿は作成日から1ヶ月間サーバーに保存されます。
                  </p>
                </React.Fragment>
              )}
            </div>
          }
          cancel="キャンセル"
          submit={latest.status === 'created' ? '再作成' : '作成'}
          onSubmit={() => {
            createRosterOfEmployees(!_.isEmpty(latest), token).then(() => {
              this.setState({showModal: false})
              loadRosterOfEmployees(token)
              dispatch(notifySuccess('労働者名簿はメールにてお送りします'))
            })
          }}
        />
        <Button
          onClick={() => this.setState({showModal: true})}
          disabled={loading || working}
          disabledReason={working ? '作成に時間がかかるため、完了しましたらメールにてお送りいたします。' : undefined}
        >
          労働者名簿一括作成
        </Button>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    rosterOfEmployees: fetchSelector(state, 'roster_of_employees').data,
    loading: fetchSelector(state, 'roster_of_employees').loading,
  }),
  (dispatch) => ({
    loadRosterOfEmployees(token) {
      dispatch(actionCreators.fetchData('roster_of_employees', api.createWithAuth(token).rosterOfEmployees.list()))
    },
    destroy() {
      dispatch(actionCreators.fetchDestroy('roster_of_employees'))
    },
    async createRosterOfEmployees(reCreate, token) {
      try {
        await api.createWithAuth(token).rosterOfEmployees.create({re_create: reCreate})
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    dispatch,
  })
)(rosterOfEmployees)
