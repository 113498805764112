import React, {Component} from 'react'
import ActionButton from 'jbc-front/components/ActionButton'
import {ChangeRow, Delete, Edit, MinusCircle, Strage} from 'jbc-front/components/icons'
import DateTime from 'react-datetime'
import styles from 'employees/PersonnelHistory.scss'
import {getState, recordDisplay} from 'utils'
import moment from 'moment'
import {
  autofill as autofillForm,
  Field,
  FieldArray,
  FormName,
  FormSection,
  formValueSelector,
  getFormInitialValues,
  getFormValues,
  reduxForm,
  reset,
} from 'redux-form'
import Master from 'Master'
import {connect, useDispatch} from 'react-redux'
import {groupFullName} from 'employees/form/EmploymentInfomation'
import _ from 'lodash'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {handlerNestFormResponse} from 'libs/errorHandler'
import Select from 'react-select'
import {dateFieldProps} from 'jbc-front/components/Form'
import {Link} from 'react-router-dom'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import Modal from 'jbc-front/components/Modal'
import {logError} from 'libs/telemetry'
import {CsvDownloadModal} from './personnelHistory/CsvDownloadModal'
import {useSelector} from 'hooks/redux'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'
import {ScalarOfficeSelector} from 'features/offices/OfficeSelector'
import classNames from 'classnames'
import {withPrefix} from 'FormFields'
import {useApolloClient} from '@apollo/client'
import {findOfficeById} from 'libs/graphql/findOfficeById'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'PersonnelHistory'
const selector = formValueSelector(formName)
const autofill = autofillForm.bind(null, formName)

const renderDateTimeTd = ({input, meta: {touched, error}, ...rest}) => (
  <td {...(touched && error ? {className: 'invalid ' + styles.date} : {className: styles.date})}>
    <DateTime
      value={input.value}
      style={{width: '85px'}}
      onChange={(param) => input.onChange(param)}
      onBlur={() => {
        input.onBlur(input.value)
      }}
      dateFormat={'YYYY/MM/DD'}
      timeFormat={false}
      {...rest}
    />
  </td>
)

const toSelectOption = (options) => [
  {value: '', label: recordDisplay()},
  ...options.map((option) => ({value: option, label: option})),
]

const renderSelectTd = ({input, meta: {touched, error}, options}) => {
  let _options = options
  if (input.value && !options.includes(input.value)) {
    _options = [input.value, ...options]
  }
  return (
    <td {...(touched && error ? {className: 'invalid ' + styles.select} : {className: styles.select})}>
      <Select
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
        options={toSelectOption(_options)}
        placeholder={recordDisplay()}
        simpleValue
        clearable={false}
      />
    </td>
  )
}

const OfficeSelectorTdComponent = ({input, meta, sectionPrefix}) => {
  const initialValues = useSelector((state) => getFormInitialValues(formName)(state))
  const deleted = _.get(initialValues, withPrefix(sectionPrefix, 'office_deleted'))
  const initialOfficeName = _.get(initialValues, withPrefix(sectionPrefix, 'office_name'))
  return (
    <td className={styles.officeSelector}>
      <ScalarOfficeSelector
        filterByCurrentOffices
        selectedIds={input.value}
        onChange={(value) => {
          input.onChange(value)
          input.onBlur(value)
        }}
        extraItems={deleted ? [{key: String(meta.initial), label: initialOfficeName}] : undefined}
      >
        {([office]) => (
          <div className={classNames(styles.officeNameInSelector, {[styles.placeholder]: !office})}>
            {recordDisplay(office?.name)}
          </div>
        )}
      </ScalarOfficeSelector>
    </td>
  )
}

const OfficeSelectorTd = (props) => (
  <FormName>{({sectionPrefix}) => <OfficeSelectorTdComponent sectionPrefix={sectionPrefix} {...props} />}</FormName>
)

const masters = ['positions', 'groups', 'occupations', 'employmentTypes']

const Content = ({histories, hasBranchOffice}) => (
  <tbody>
    {histories.length > 0 ? (
      histories.map((history) => (
        <tr key={history.id}>
          <td className={styles.date}>
            <span>{recordDisplay(history.date_on && moment(history.date_on).format('YYYY/MM/DD'))}</span>
          </td>
          {hasBranchOffice && <td>{recordDisplay(history.office_name)}</td>}
          <td>{recordDisplay(history.employment_type_name)}</td>
          <td>{recordDisplay(history.position_name)}</td>
          <td>{recordDisplay(history.occupation_name)}</td>
          <td>{recordDisplay(history.group_0_name)}</td>
          <td>{recordDisplay(history.group_1_name)}</td>
          <td>{recordDisplay(history.group_2_name)}</td>
          <td>
            <Delete size={15} className={styles.inactive} />
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={8}>人事異動履歴がまだありません</td>
      </tr>
    )}
  </tbody>
)

const EditContent = ({fields, hasBranchOffice}) => {
  const client = useApolloClient()
  const dispatch = useDispatch()

  const positions = useSelector((state) => state.master.positions || [])
  const occupations = useSelector((state) => state.master.occupations || [])
  const employmentTypes = useSelector((state) => state.master.employmentTypes || [])
  const groups = useSelector((state) => state.master.groups || [])
  const groupsById = _.keyBy(groups, 'id')

  const setId = (index, field, data, isGroup) => (e, value) => {
    const current =
      value && _.find(data, isGroup ? (group) => groupFullName(group, data) === value : ({name}) => name === value)
    if (current) {
      dispatch(autofill(`histories[${index}].${field}`, current.id))
    } else if (!value) {
      dispatch(autofill(`histories[${index}].${field}`, null))
    }
  }
  const handleChangeOfficeId = (index) => async (e, newValue, prevValue, name) => {
    // 選択を解除したらoffice_nameも消す
    if (!newValue) {
      dispatch(autofill(`histories[${index}].office_name`, null))
      return
    }

    const initialValues = getFormInitialValues(formName)(await getState(dispatch))
    const deleted = _.get(initialValues, `histories[${index}].office_deleted`)
    const initialOfficeId = _.get(initialValues, `histories[${index}].office_id`)
    if (deleted && newValue === String(initialOfficeId)) {
      // 削除済の適用事業所を選択 -> office_nameを初期値に戻す
      dispatch(autofill(`histories[${index}].office_name`, _.get(initialValues, `histories[${index}].office_name`)))
      return
    }

    try {
      const office = await findOfficeById(client, newValue, {fetchPolicy: 'cache-first'})
      dispatch(autofill(`histories[${index}].office_name`, office.name))
    } catch (e) {
      dispatch(asyncError(e))
      dispatch(autofill(name, prevValue)) // 通信エラー時は入力をロールバック
    }
  }

  return (
    <tbody>
      {fields.map((field, index) => (
        <FormSection name={field} component="tr" key={index}>
          <Field component={renderDateTimeTd} {...dateFieldProps} name="date_on" />
          {hasBranchOffice && (
            <Field component={OfficeSelectorTd} name="office_id" onChange={handleChangeOfficeId(index)} />
          )}
          <Field
            component={renderSelectTd}
            name="employment_type_name"
            options={employmentTypes.map((value) => value.name)}
            onChange={setId(index, 'employment_type_id', employmentTypes, false)}
          />
          <Field
            component={renderSelectTd}
            name="position_name"
            options={positions.map((value) => value.name)}
            onChange={setId(index, 'position_id', positions, false)}
          />
          <Field
            component={renderSelectTd}
            name="occupation_name"
            options={occupations.map((value) => value.name)}
            onChange={setId(index, 'occupation_id', occupations, false)}
          />
          <Field
            component={renderSelectTd}
            name="group_0_name"
            options={groups.map((group) => groupFullName(group, groupsById))}
            onChange={setId(index, 'group_0_id', groupsById, true)}
          />
          <Field
            component={renderSelectTd}
            name="group_1_name"
            options={groups.map((group) => groupFullName(group, groupsById))}
            onChange={setId(index, 'group_1_id', groupsById, true)}
          />
          <Field
            component={renderSelectTd}
            name="group_2_name"
            options={groups.map((group) => groupFullName(group, groupsById))}
            onChange={setId(index, 'group_2_id', groupsById, true)}
          />
          <td>
            <Delete
              size={15}
              onClick={() => {
                fields.remove(index)
                // 保存済みの履歴が削除された場合は保存ボタン押下時にモーダルを表示
                if (fields.get(index).id) {
                  dispatch(autofill('_isHistoryRemove', true))
                }
              }}
              className={styles.active}
            />
          </td>
        </FormSection>
      ))}
    </tbody>
  )
}

const Histories = connect(
  (state) => ({
    values: getFormValues(formName)(state),
    editing: selector(state, '_editing'),
    showModal: selector(state, '_showModal'),
    showDeleteModal: selector(state, '_showDeleteModal'),
    isHistoryRemove: selector(state, '_isHistoryRemove'),
    personnelHistories: fetchSelector(state, 'personnel_histories').data,
  }),
  (dispatch) => ({
    handleCancel() {
      dispatch(reset(formName))
    },
    handleEdit() {
      dispatch(autofill('_editing', true))
    },
    handleHideModal() {
      dispatch(autofill('_showModal', false))
    },
    handleShowDeleteModal() {
      dispatch(autofill('_showDeleteModal', true))
    },
    handleHideDeleteModal() {
      dispatch(autofill('_showDeleteModal', false))
    },
  })
)(
  ({
    fields,
    showModal,
    showDeleteModal,
    editing,
    handleSubmit,
    handleCancel,
    handleEdit,
    handleHideModal,
    handleShowDeleteModal,
    handleHideDeleteModal,
    isHistoryRemove,
    personnelHistories = [],
  }) => {
    const hasBranchOffice = useHasBranchOffice()
    return (
      <div>
        <div className={styles.buttonWrap}>
          <div className={styles.editButton}>
            {editing ? (
              <>
                <AsyncTaskConsumer>
                  {({taskRunningProps}) => (
                    <ActionButton onClick={handleSubmit} icon={<Strage size={15} />} {...taskRunningProps}>
                      保存
                    </ActionButton>
                  )}
                </AsyncTaskConsumer>
                <ActionButton onClick={handleCancel} icon={<MinusCircle size={15} />}>
                  キャンセル
                </ActionButton>
                <ActionButton
                  onClick={() => fields.push(_.omit(fields.get(fields.length - 1), 'id'))}
                  icon={<ChangeRow size={15} />}
                >
                  行を追加
                </ActionButton>
              </>
            ) : (
              <ActionButton icon={<Edit size={15} />} onClick={handleEdit}>
                編集
              </ActionButton>
            )}
            <CsvDownloadModal disabled={editing} />
          </div>
          <div className={styles.settingButton}>
            <ActionButton as={Link} to="/offices" target="_blank">
              適用事業所の確認
            </ActionButton>
            <ActionButton as={Link} to="/settings/groups" target="_blank">
              グループの確認
            </ActionButton>
            <ActionButton as={Link} to="/settings/occupations" target="_blank">
              職種の確認
            </ActionButton>
            <ActionButton as={Link} to="/settings/positions" target="_blank">
              役職の確認
            </ActionButton>
            <ActionButton as={Link} to="/settings/employment_types" target="_blank">
              雇用形態の確認
            </ActionButton>
          </div>
        </div>
        <div className="l-overflow-scroll">
          <table className={styles.historyTable}>
            <thead>
              <tr>
                <th className={styles.rowDate}>異動日</th>
                {hasBranchOffice && <th>適用事業所</th>}
                <th>雇用形態</th>
                <th>役職</th>
                <th>職種</th>
                <th>グループ(1)</th>
                <th>グループ(2)</th>
                <th>グループ(3)</th>
                <th className={styles.rowIcon} />
              </tr>
            </thead>
            {editing ? (
              <React.Fragment>
                <EditContent fields={fields} hasBranchOffice={hasBranchOffice} />
                <Modal
                  header="保存の確認"
                  body={
                    <p>
                      適用事業所を変更すると、従業員の手続き等に影響が出る恐れがあります。
                      <br />
                      別途手続きが必要な場合は個別で対応ください。
                      <br />
                      ※異動日を未来に設定した場合でも、適用事業所は現時点で反映されます。
                      <br />
                      <span className={styles.warningText}>本当に人事異動を行いますか？</span>
                    </p>
                  }
                  onSubmit={() => {
                    if (isHistoryRemove) {
                      handleHideModal()
                      handleShowDeleteModal()
                    } else {
                      handleSubmit()
                    }
                  }}
                  isOpen={showModal}
                  hideModal={handleHideModal}
                  submit="はい"
                  cancel="いいえ"
                />
                <Modal
                  header="削除確認"
                  body={
                    <p>
                      削除された人事異動履歴は復元することができません。
                      <br />
                      <span className={styles.warningText}>本当に人事異動履歴を削除しますか？</span>
                    </p>
                  }
                  cancel="いいえ"
                  submit="はい"
                  onSubmit={handleSubmit}
                  isOpen={showDeleteModal}
                  hideModal={handleHideDeleteModal}
                />
              </React.Fragment>
            ) : (
              <Content histories={personnelHistories} hasBranchOffice={hasBranchOffice} />
            )}
          </table>
        </div>
      </div>
    )
  }
)

const validate = (values) => {
  if (!values.histories) {
    return undefined
  }
  const errors = {}
  values.histories.forEach((history, index) => {
    if (history.date_on) {
      const dateOn = moment(history.date_on, 'YYYY/MM/DD')
      if (!dateOn.isValid) {
        _.set(errors, `histories[${index}].date_on`, '異動日のデータ形式が正しくありません')
      }
      for (let i = 0; i < index; i++) {
        if (values.histories[i].date_on && moment(values.histories[i].date_on, 'YYYY/MM/DD').isSame(dateOn, 'day')) {
          _.set(errors, `histories[${index}].date_on`, '異動日が重複しています')
          break
        }
      }
    } else {
      _.set(errors, `histories[${index}].date_on`, '異動日を入力してください')
    }
  })
  return _.isEmpty(errors) ? undefined : errors
}

const onSubmitFail = (errors, dispatch, submitError) => {
  if (!errors && submitError) {
    const err = new Error('Unknown error occurred in submitting PersonnelHistory')
    err.cause = submitError
    logError(err)
  }
  if (errors.histories) {
    errors.histories.forEach((history) => {
      if (history) {
        _.each(history, (msg) => {
          dispatch(notifyError(msg))
        })
      }
    })
  }
}

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate,
  onSubmitFail,
})(({handleSubmit}) => (
  <form onSubmit={handleSubmit}>
    <FieldArray name="histories" component={Histories} handleSubmit={handleSubmit} />
  </form>
))

class PersonnelHistory extends Component {
  static defaultProps = {
    personnelHistories: [],
  }

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      dispatch,
    } = this.props
    dispatch(
      actionCreators.fetchData('personnel_histories', api.createWithAuth(token).employees.personnelHistories.list(id))
    )
    dispatch(actionCreators.fetchData('employee', api.createWithAuth(token).employees.get(id)))
  }

  componentWillUnmount() {
    const {dispatch} = this.props
    dispatch(actionCreators.fetchDestroy('personnel_histories'))
    dispatch(actionCreators.fetchDestroy('employee'))
  }

  handleSubmit = async (values) => {
    const {
      match: {
        params: {id},
      },
      token,
      dispatch,
      initialValues,
      showModal,
      showDeleteModal,
      isHistoryRemove,
    } = this.props
    // 適用事業所を変更している場合は警告モーダルを表示
    const reducer = (accumulator, current) =>
      moment(current.date_on).isAfter(moment(accumulator.date_on)) ? current : accumulator
    const beforeHistory = !_.isEmpty(initialValues.histories) ? initialValues.histories.reduce(reducer) : {}
    const afterHistory = !_.isEmpty(values.histories) ? values.histories.reduce(reducer) : {}

    if (!showModal && !showDeleteModal && beforeHistory.office_id !== afterHistory.office_id) {
      dispatch(autofill('_showModal', true))
    } else if (!showDeleteModal && isHistoryRemove) {
      dispatch(autofill('_showDeleteModal', true))
    } else {
      try {
        const data = values.histories
        const res = await api
          .createWithAuth(token)
          .employees.personnelHistories.updateAll(id, data)
          .catch(handlerNestFormResponse('histories'))
        dispatch({type: 'FETCH_SUCCESSED', meta: {name: 'personnel_histories'}, payload: {data: res.data}})
        dispatch(reset(formName))
      } catch (err) {
        dispatch(asyncError(err))
      }
    }
  }

  render() {
    const {
      personnelHistories,
      employee,
      match: {
        params: {id},
      },
    } = this.props
    return (
      <div>
        <Master masters={masters} />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">{recordDisplay.fullName(employee)}さんの人事異動履歴</h1>
        </div>
        <div className="l-contents-wrap">
          <div className={styles.wrap}>
            <div className="l-breadcrumb u-mb20">
              <Link to="/employees" className="l-breadcrumb-link">
                従業員一覧
              </Link>
              <Link to={`/employees/${id}`} className="l-breadcrumb-link">
                従業員情報
              </Link>
              <span className="l-breadcrumb-here">人事異動履歴</span>
            </div>
            <Form
              initialValues={{histories: personnelHistories.length > 0 ? personnelHistories : [{}], _editing: false}}
              onSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  showModal: selector(state, '_showModal'),
  showDeleteModal: selector(state, '_showDeleteModal'),
  isHistoryRemove: selector(state, '_isHistoryRemove'),
  initialValues: getFormInitialValues(formName)(state),
  personnelHistories: fetchSelector(state, 'personnel_histories').data,
  employee: fetchSelector(state, 'employee').data,
}))(PersonnelHistory)
