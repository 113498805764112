import React from 'react'
import {connect} from 'react-redux'
import styles from 'dashboard/dashboard.scss'
import {ListGroup, ListTitle, ListItem} from 'components/ListGroup'
import _ from 'lodash'
import {procedureList} from 'ProcedureNew'
import {isAdminSelector} from '../utils'

const Procedures = ({currentUser, isAdmin}) => {
  const procedures = procedureList(isAdmin).filter(
    ({type}) =>
      _.get(currentUser, `client_role.client_role_permissions.${type}.creatable`) &&
      (isAdmin || currentUser.employee.gender === 'woman' || type !== 'maternity_leave')
  )
  return (
    <ListGroup>
      <ListTitle>手続きの開始</ListTitle>
      {procedures.map((procedure, index) => {
        const Icon = procedure.icon
        return (
          <ListItem to={procedure.to} key={index}>
            <div className={styles.icon}>
              <Icon />
            </div>
            <div>
              <p className={styles.name}>{procedure.name}</p>
              <p className={styles.text}>{procedure.text}</p>
            </div>
          </ListItem>
        )
      })}
    </ListGroup>
  )
}

export default connect((state) => ({
  currentUser: state.session.currentUser,
  isAdmin: isAdminSelector(state),
}))(Procedures)
