import React, {useEffect, useState} from 'react'
import {Mutation} from 'components/Graphql'
import {gql} from '@apollo/client'
import LoadingPage from 'components/LoadingPage'

const GENERATE_MY_NUBMER_REQUEST_TOKEN = gql`
  mutation {
    generateMyNubmerRequestToken(input: {}) {
      accessToken
    }
  }
`

const withGenerateMyNubmerRequestToken = (Child) => (props) =>
  (
    <Mutation mutation={GENERATE_MY_NUBMER_REQUEST_TOKEN}>
      {(generateMyNubmerRequestToken) => (
        <Child {...props} generateMyNubmerRequestToken={generateMyNubmerRequestToken} />
      )}
    </Mutation>
  )

const LOADING = 1
const NO_REQUEST = 2

const EmployeeInput = ({generateMyNubmerRequestToken}) => {
  const [status, setStatus] = useState(LOADING)
  useEffect(() => {
    ;(async () => {
      const {data} = await generateMyNubmerRequestToken()
      const accessToken = data?.generateMyNubmerRequestToken?.accessToken
      if (accessToken) {
        window.location = `${process.env.MY_NUMBER_SERVER}/start?token=${accessToken}`
      } else {
        setStatus(NO_REQUEST)
      }
    })()
  }, [])
  return status === LOADING ? (
    <LoadingPage />
  ) : status === NO_REQUEST ? (
    <div className="l-wrap-xs u-pt100">
      <div className="l-box-message">
        <p className="m-title-sub u-pt0 u-ta-c">すでに入力済です</p>
      </div>
    </div>
  ) : null
}

export default EmployeeInput |> withGenerateMyNubmerRequestToken
