import moment from 'moment'
import _ from 'lodash'

export const normalizeExtendMaternityKeys = (maternityData) => {
  const table = {
    expected_birth_date_after: 'expected_birth_date',
    maternity_division_after: 'maternity_division',
    started_at_after: 'started_at',
    expected_finished_at_after: 'expected_finished_at',
  }
  return _.mapKeys(maternityData, (_v, key) => table[key] || key)
}

export const makeMaternityDatum = (procedureStatus) => {
  const startMaternityTmpData = _.mapValues(
    _.keyBy(procedureStatus?.procedure_tmp_data || [], (tmpData) => `${tmpData.procedure_tmp_type}_${tmpData.name}`),
    ({data}) => {
      try {
        return JSON.parse(data)
      } catch (err) {
        return null
      }
    }
  ).application_procedure_start_maternity
  return (
    [
      procedureStatus.procedure_finish_maternity_datum,
      procedureStatus.procedure_extend_maternity_datum,
      procedureStatus.procedure_start_maternity_datum,
      startMaternityTmpData,
    ].find((datum) => datum) |> normalizeExtendMaternityKeys
  )
}

export const createInitialChildData = (childData) => {
  if (childData.length === 0) {
    return [{}]
  }
  return childData.map((childDatum) => {
    if (childDatum.add_support) {
      return {..._.omit(childDatum, 'id'), skip_add_support: true}
    } else {
      return _.omit(childDatum, 'id')
    }
  })
}

export const alreadyExistSameDependent = (children, dependents) =>
  children
    .filter((child) => child.add_support && !child.skip_add_support)
    .flatMap((child) =>
      dependents.map(
        (dependent) =>
          child.first_name === dependent.first_name &&
          child.last_name === dependent.last_name &&
          moment(child.birth_date, 'YYYY/MM/DD').isSame(moment(dependent.birthday, 'YYYY-MM-DD'))
      )
    )
    .some((bool) => bool)
