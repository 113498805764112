import {push, replace} from 'connected-react-router'
import _ from 'lodash'
import {SubmissionError} from 'redux-form'
import {logError} from 'libs/telemetry'
import readBlob from 'readBlob'
import {getNestedErrors, getBaseErrors} from 'libs/errorHandler'
import {asyncError} from 'store/actions/asyncError'
import {notifyError} from 'store/actions/notify'

const errorHandler = (store) => (next) => (action) => {
  if (_.isObject(action) && asyncError.match(action)) {
    const err = action.payload
    if (err instanceof SubmissionError) {
      throw err
    }
    if (process.env.NODE_ENV !== 'production' && console.error) {
      console.error(err)
    }
    if (err.response) {
      if (err.response.data instanceof Blob && err.response.data.type == 'application/json') {
        readBlob(err.response.data)
          .json()
          .then((data) => {
            err.response.data = data
            store.dispatch(asyncError(err))
          })
        return
      }
      if (_.get(err.response, 'data._redirect_to_employee')) {
        store.dispatch(push('/initial_input'))
        return
      }
      if (_.get(err.response, 'data._redirect_to_login')) {
        store.dispatch(replace('/login'))
      }
      if (err.response.status == 401 && _.isEmpty(_.get(err.response, 'data._errors._base'))) {
        if (/^\/tm/.test(window.location.pathname)) {
          window.location = '/api/jbc-oauth/login?d=tm'
        } else {
          window.location = '/api/jbc-oauth/login'
        }
        return
      }
      if (err.response.status == 403 && _.isEmpty(_.get(err.response, 'data._errors._base'))) {
        store.dispatch(notifyError('操作権限がありません'))
        return
      }
      if (err.response.status !== 200) {
        const baseErrors = _.uniq([...getBaseErrors(err.response.data)].filter(_.isString))
        if (baseErrors.length > 0) {
          baseErrors.forEach((msg) => {
            store.dispatch(notifyError(msg))
          })
          return
        }
      }
      if (err.response.status == 422 && _.get(err.response, 'data._errors')) {
        const errors = _.mapValues(err.response.data._errors, (value) => (_.isArray(value) ? value[0] || '' : value))
        throw new SubmissionError(errors)
      }
      if (err.response.status == 422) {
        const errors = getNestedErrors(err.response.data)
        if (!_.isEmpty(errors)) {
          throw new SubmissionError(errors)
        }
      }
    }
    logError(err)
    store.dispatch(notifyError(err.message))
  }
  next(action)
}

export default errorHandler
