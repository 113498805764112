import React from 'react'
import {connect} from 'react-redux'
import {autofill, submit} from 'redux-form'
import {Pulldown} from 'jbc-front/components/icons'
import {selector, formName} from './utils'
import styles from './SearchForm.scss'

class SortableTh extends React.Component {
  static defaultProps = {
    currentSortType: '',
  }

  handleClick = () => {
    const {field, currentSortType, onChange} = this.props
    const [sort, order] = currentSortType.split('__')
    const newOrder = sort !== field || order === 'desc' ? 'asc' : 'desc'
    onChange(`${field}__${newOrder}`)
  }

  render() {
    const {field, globalClassName, children, currentSortType} = this.props
    const className = globalClassName ? `${styles.sortableTh} ${globalClassName}` : styles.sortableTh
    const [sort, order] = currentSortType.split('__')
    const showOrder = field === sort && (order === 'asc' || order === 'desc')
    return (
      <th onClick={this.handleClick} className={className}>
        {children}
        {(() => {
          if (showOrder && order === 'desc') {
            return <Pulldown size={7} className={styles.sortableThIconDesc} />
          } else if (showOrder && order === 'asc') {
            return <Pulldown size={7} className={styles.sortableThIconAsc} />
          }
        })()}
      </th>
    )
  }
}

const mapStateToProps = (state) => ({
  currentSortType: selector(state, 'sort_type'),
})
const mapDispatchToProps = (dispatch) => ({
  onChange: (sortType) => {
    dispatch(autofill(formName, 'sort_type', sortType))
    dispatch(submit(formName))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(SortableTh)
