import {FC} from 'react'
import {useDispatch} from 'react-redux'

import {useDelete} from 'hooks/api/egovProcedureStatuses/useDelete'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

import {SubmitButton} from 'components/forms/SubmitButton'
import Button from 'jbc-front/components/Button'

// @ts-ignore
import CommonModal from 'jbc-front/components/CommonModal'

import type {Refetch} from '../../index'
import styles from './DeleteModal.scss'

interface DeleteModalProps {
  egovProcedureId: string
  isOpen: boolean
  onClose: () => void
  refetch: Refetch
}

export const DeleteModal: FC<DeleteModalProps> = ({egovProcedureId, isOpen, onClose, refetch}) => {
  const dispatch = useDispatch()
  const destroy = useDelete()

  const handleSubmit = async () => {
    try {
      await destroy(egovProcedureId)
      dispatch(notifySuccess('削除しました'))
      await refetch({})
      onClose()
    } catch (e) {
      dispatch(asyncError('削除中にエラーが発生しました'))
    }
  }

  return (
    <CommonModal isOpen={isOpen}>
      <CommonModal.Header hideModal={onClose}>削除確認</CommonModal.Header>
      <CommonModal.Body>
        <p>
          削除された電子申請は復元することができません。
          <br />
          必要な場合はあらかじめエラー文言・公文書のダウンロードをお願いいたします。
          <br />
          <span className={styles.warningText}>本当に電子申請を削除しますか？</span>
        </p>
      </CommonModal.Body>
      <CommonModal.Footer>
        <form>
          <CommonModal.Buttons>
            <Button onClick={onClose}>キャンセル</Button>
            <SubmitButton primary onSubmit={handleSubmit}>
              削除
            </SubmitButton>
          </CommonModal.Buttons>
        </form>
      </CommonModal.Footer>
    </CommonModal>
  )
}
