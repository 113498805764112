import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import {UPDATE_ALL_DOCUMENT_EMPLOYEE_UNPUBLISHED, DOCUMENT_EMPLOYEES, QueryVariables} from '../../query'
import useRouter from 'use-react-router'
import {notifySuccess} from 'store/actions/notify'

import styles from './AllUnPublishModal.scss'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface UnPublishModalProps {
  isOpen: boolean
  onClose: () => void
  variables: QueryVariables
}

export const AllUnPublishModal: FC<UnPublishModalProps> = ({isOpen, onClose, variables}) => {
  const {
    match: {params},
  } = useRouter<{documentId: string}>()
  const documentId = params.documentId

  const [unpublished, {loading}] = useMutation(UPDATE_ALL_DOCUMENT_EMPLOYEE_UNPUBLISHED)
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    await unpublished({
      variables: {
        input: {
          documentId: documentId,
        },
      },
      refetchQueries: [
        {
          query: DOCUMENT_EMPLOYEES,
          variables: variables,
          fetchPolicy: 'network-only',
        },
      ],
    })
    onClose()
    dispatch(notifySuccess('書類を非公開にしました'))
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
        <Modal.Header onClose={onClose}>書類の公開を取り消す</Modal.Header>
        <Modal.Body>
          <p>公開を取り消すと従業員がマイページから書類を閲覧できなくなります。書類公開を取り消しますか？</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={onClose}>いいえ</Button>
            <Button primary disabled={loading} onClick={handleSubmit}>
              はい
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </>
  )
}
