const actions = [
  'INITIALIZE_TREE',
  'SET_TREE',
  'ADD_NODE',
  'UPDATE_NODE',
  'DELETE_NODE',
  'START_EDIT',
  'END_EDIT',
  'INSERT_NODE',
  'EXPAND_ALL',
  'COLLAPSE_ALL',
  'DESTROY',
]

const subActions = {}

export default [actions, subActions]
