import {connect} from 'react-redux'
import {ListTypeWithResult} from 'tm/employees/list/SearchForm'
import {selector} from 'tm/employees/list/utils'

const limits = ['10', '20', '50', '100', '200'].map((ele) => ({value: ele, label: `表示件数：${ele}件`}))
const sortTypes = [
  {value: 'staff_code__asc', label: 'スタッフコード 昇順'},
  {value: 'staff_code__desc', label: 'スタッフコード 降順'},
  {value: 'full_name_kana__asc', label: '氏名 昇順'},
  {value: 'full_name_kana__desc', label: '氏名 降順'},
  {value: 'group__asc', label: 'グループ 設定順'},
  {value: 'group__desc', label: 'グループ 設定逆順'},
]

const listTypes = [
  {value: 'card', label: 'カード表示'},
  {value: 'list', label: 'リスト表示'},
]

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: state.employees.list.count,
  limits,
  sortTypes,
  listTypes,
  page: selector(state, 'page'),
  isLoading: state.employees.list.loading,
})
export default connect(mapStateToProps)(ListTypeWithResult)
