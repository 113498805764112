import React from 'react'
import {useDispatch} from 'react-redux'
import api from 'api'
import {asyncError} from 'store/actions/asyncError'
import {push} from 'connected-react-router'
import BaseForm, {formatValues} from 'employees/BaseForm'
import {getState} from 'utils'
import {useSelector} from 'hooks/redux'
import {useApolloClient} from '@apollo/client'
import {findOfficeById} from 'libs/graphql/findOfficeById'

const NewDirectInput = () => {
  const apolloClient = useApolloClient()
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)

  const handleFormSubmit = async (values) => {
    try {
      const state = await getState(dispatch)
      const {employmentTypes = [], groups = []} = state.master
      const officeId = values.personnel_history?.[0]?.office_id
      const office = officeId && (await findOfficeById(apolloClient, officeId, {fetchPolicy: 'cache-first'}))
      const {employee, personnelHistory} = formatValues(values, employmentTypes, office, groups)
      const authedApi = api.createWithAuth(token)
      const {
        data: {id},
      } = await authedApi.employees.create(employee)
      await authedApi.employees.personnelHistories.updateAll(id, [personnelHistory])
      const procedureStatusId = (await authedApi.procedureStatuses.start(id, 'enroll', values.joined_at)).data.id
      dispatch(push(`/enroll/detail/${procedureStatusId}`))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <main>
      <BaseForm
        onSubmit={handleFormSubmit}
        submitText="手続きを開始する"
        emailRequired={false}
        initialValues={{personnel_history: [{groups: [{}]}]}}
      />
    </main>
  )
}

export default NewDirectInput
