import React from 'react'
import {connect} from 'react-redux'
import {Fields, Field, autofill, reduxForm, getFormValues, reset, getFormMeta, change} from 'redux-form'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {getState} from 'utils'
import {actionCreators} from 'actions'
import SortingFieldsWithResult from 'notifications/list/SortingFieldsWithResult'
import styles from 'notifications/list/SearchForm.scss'
import searchDetector from 'searchDetector'
import {dateString} from 'validators'
import {dateFieldProps} from 'jbc-front/components/Form'
import {SearchTextBox, FilteringTag} from 'jbc-front/components/SearchForm'
import ActionButton from 'jbc-front/components/ActionButton'
import {
  formName,
  getSavedDisplayNotificationLimit,
  saveDisplayNotificationLimit,
  fetchNotifications,
  getCurrentQueryFromLocation,
  hasConditions,
  convertQueryToForm,
  convertFormToQueryString,
} from 'notifications/list/utils'
import compose from 'lodash/fp/compose'
import moment from 'moment'

class SearchForm extends React.Component {
  constructor(props) {
    super(props)
  }

  clearForm = () => {
    const {dispatch} = this.props
    getState(dispatch).then((state) => {
      dispatch(reset(formName))
      dispatch(push({...state.router.location, search: ''}))
    })
  }

  onChangeStart = (e) => {
    const {dispatch} = this.props
    getState(dispatch).then((state) => {
      delete e.preventDefault
      const startOn = Object.values(e).join('')
      const endOn = getFormValues(formName)(state).created_at_end
      if ([startOn, endOn].every((date) => date && moment(date, 'YYYY/MM/DD').isValid())) {
        if (moment(endOn, 'YYYY/MM/DD').diff(moment(startOn, 'YYYY/MM/DD'), 'months') > 2) {
          const changeEndOn = moment(startOn).add(3, 'month').add(-1, 'day').format('YYYY/MM/DD')
          dispatch(change(formName, 'created_at_end', changeEndOn))
        }
      }
    })
  }

  render() {
    const {user, pathname, formValues, handleSubmit, notificationTypes} = this.props
    return (
      <div className={styles.searchForm}>
        <form onSubmit={handleSubmit}>
          <div className={styles.mainSearchFields}>
            <div className={styles.mainFormControls}>
              <div className={styles.mainFormControl}>
                <Field name="q" inputWidth={230} placeholder="アカウント" component={SearchTextBox} />
              </div>
              <ActionButton
                primary={hasConditions(formValues)}
                as="button"
                type="button"
                className={styles.mainFormControl}
                onClick={this.clearForm}
              >
                条件解除
              </ActionButton>
            </div>
          </div>

          <div className={styles.details}>
            <Field
              name="notification_type"
              header="通知種別"
              component={SearchNotificationTypeField}
              notificationTypes={notificationTypes}
              onNotificationTypeChange={handleSubmit}
            />
            <DateRow header="通知日時" prefix="created_at" onChangeStart={this.onChangeStart} />
          </div>
          <SortingFieldsWithResult
            limit={getSavedDisplayNotificationLimit(pathname, user)}
            onChangeLimit={(newLimit) => {
              saveDisplayNotificationLimit(pathname, user, newLimit)
              handleSubmit()
            }}
            onChangeSortType={handleSubmit}
          />
        </form>
      </div>
    )
  }
}

const validate = (values) => {
  const errors = {}
  ;['created_at'].forEach((prefix) => {
    const startOn = values[`${prefix}_start`]
    const endOn = values[`${prefix}_end`]
    if ([startOn, endOn].every((date) => date && moment(date, 'YYYY/MM/DD').isValid())) {
      if (moment(startOn, 'YYYY/MM/DD').diff(moment(endOn, 'YYYY/MM/DD'), 'days') > 0) {
        errors[`${prefix}_start`] = errors[`${prefix}_end`] = '日付の範囲が正しくありません'
      } else if (moment(endOn, 'YYYY/MM/DD').diff(moment(startOn, 'YYYY/MM/DD'), 'months') > 2) {
        errors[`${prefix}_start`] = errors[`${prefix}_end`] = '最大3ヶ月間の情報を検索できます'
      }
    } else {
      errors[`${prefix}_start`] = errors[`${prefix}_end`] = '日付を入力してください'
    }
  })
  return errors
}

const SearchNotificationTypeField = ({input: inputProps, header, notificationTypes, onNotificationTypeChange}) => {
  return (
    <div className={styles.detailsRow}>
      <div className={styles.detailsHeader}>{header}</div>
      <div className={styles.detailsBody}>
        <div className={styles.detailsBodyItems}>
          {notificationTypes.map((item) => (
            <FilteringTag
              key={item.id}
              selected={item.id === inputProps.value}
              onClick={() => {
                const newValue = item.id
                inputProps.onChange(newValue)
                inputProps.onBlur(newValue)
                onNotificationTypeChange()
              }}
            >
              {item.name}
            </FilteringTag>
          ))}
        </div>
      </div>
    </div>
  )
}

/* eslint-disable no-unused-vars */
export const renderError = ({names, label, ...fields}) => {
  /* eslint-enable no-unused-vars */
  const field = _.find(fields, (field) => field.meta && field.meta.touched && field.meta.error)
  if (field) {
    return (
      <p>
        {label}
        {field.meta.error}
      </p>
    )
  }
  return null
}

const DateRow = ({header, prefix, onChangeStart}) => (
  <div className={styles.detailsRow}>
    <div className={styles.detailsHeader}>{header}</div>
    <div className={styles.detailsBody}>
      <div className={styles.detailsBodyItems}>
        <Field
          name={`${prefix}_start`}
          component={SearchTextBox}
          type="date"
          {...dateFieldProps}
          validate={dateString}
          onChange={(e) => onChangeStart(e)}
        />
        <span className={styles.detailsComplementText}>から</span>
        <Field name={`${prefix}_end`} component={SearchTextBox} type="date" {...dateFieldProps} validate={dateString} />
        <span className={styles.detailsComplementText}>の間</span>
      </div>
      <div className={styles.detailsBodyErrors}>
        <Fields component={renderError} names={[`${prefix}_start`, `${prefix}_end`]} />
      </div>
    </div>
  </div>
)

export default compose(
  searchDetector({
    convertQueryToForm,
    getCurrentQueryFromLocation,
    fetchData(query, additionalParams, dispatch) {
      dispatch(fetchNotifications({query, additionalParams}))
    },
    destroy(dispatch) {
      dispatch(actionCreators.notifications.list.destroy())
    },
    convertFormToQueryString,
  }),
  connect((state) => ({
    token: state.auth.token,
    pathname: state.router.location.pathname,
    formValues: getFormValues(formName)(state),
    user: state.session.currentUser,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
    onSubmit(values, dispatch, {handleSearch}) {
      getState(dispatch).then((state) => {
        const autofilled = _.get(getFormMeta(formName)(state), 'page.autofilled')
        if (!autofilled) {
          dispatch(autofill(formName, 'page', 1))
        }
        getState(dispatch).then((state) => {
          handleSearch(getFormValues(formName)(state))
        })
      })
    },
  })
)(SearchForm)
