import {formValueSelector, getFormValues} from 'redux-form'
import moment from 'moment'
import Cookies from 'js-cookie'
import {actionCreators} from 'actions'
import api from 'api'
import _ from 'lodash'
import queryString from 'query-string'

export const formName = 'notificationSearchForm'
export const selector = formValueSelector(formName)
export const getValues = getFormValues(formName)
export const defaultQuery = {
  _page: '1',
  notification_type: 'all',
  created_at_start: moment().add(-3, 'month').add(1, 'day').format('YYYY/MM/DD'),
  created_at_end: moment().format('YYYY/MM/DD'),
}
const defaultLimit = '100'

export const convertQueryToForm = (query) => {
  return Object.assign(
    ...Object.entries(query)
      .map(([k, v]) => convertQueryToFormField(k, v))
      .filter(_.identity)
  )
}

export const convertQueryToFormField = (fieldName, value) => {
  switch (fieldName) {
    case 'notification_type_ids':
      return {notification_types: value}
    case '_page':
      return {page: value}
    default:
      return {[fieldName]: value}
  }
}

export const convertFormToQueryString = (data) =>
  queryString.stringify(convertFormToQuery(data), {arrayFormat: 'bracket'})

export const convertFormToQuery = (data) => {
  if (data === undefined) return {}
  return Object.assign(
    {},
    ...Object.entries(data)
      .map(([k, v]) => convertFormToQueryField(k, v))
      .filter(_.identity)
  )
}

export const convertFormToQueryField = (fieldName, value) => {
  switch (fieldName) {
    case 'notification_types':
      return !_.isEmpty(value) ? {notification_type_ids: value} : null
    case 'limit':
      return null
    case 'page':
      return {_page: value}
    default:
      return {[fieldName]: value}
  }
}

export const getCurrentQueryFromLocation = (location, user) => {
  const query = {
    ...defaultQuery,
    ...queryString.parse(location.search, {arrayFormat: 'bracket'}),
    limit: getSavedDisplayNotificationLimit(location.pathname, user),
  }
  return {...query}
}

const limitKey = (pathname, user) => `${pathname.replace(/\//g, '')}#${user.id}__disp_emp_limit`

export const getSavedDisplayNotificationLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user))
    if (!_.isEmpty(limit)) {
      return limit
    }
  }
  const limit = Cookies.get(limitKey(pathname, user))
  return _.isEmpty(limit) ? defaultLimit : limit
}

export const saveDisplayNotificationLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit)
  } else {
    Cookies.set(limitKey(pathname, user), limit)
  }
}

export const fetchNotifications =
  ({query, additionalParams}) =>
  (dispatch, getState) => {
    const {
      auth: {token},
    } = getState()
    const request = api.createWithAuth(token).notifications.list({...query, ...additionalParams})
    dispatch(actionCreators.notifications.list.fetchData(request))
  }

export const hasConditions = (formValues) => {
  return !_.isEqual(_.pickBy(convertFormToQuery(formValues)), defaultQuery)
}
