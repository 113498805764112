import {Component} from 'react'
import axios from 'axios'
import {replace} from 'connected-react-router'
import {connect} from 'react-redux'
import {notifySuccess} from 'store/actions/notify'

class JbcIdLogin extends Component {
  async componentDidMount() {
    const host = location.hostname === 'localhost' ? 'http://localhost:2500' : `${location.protocol}://${location.host}`
    const redirect_uri = `${host}/api/jbc-oauth/callback`
    const {dispatch} = this.props
    try {
      const {data} = await axios.get('/api/me')
      if (data.access_token) {
        dispatch(replace('/dashboard'))
        dispatch(notifySuccess('すでにログイン済みです。ダッシュボードへ移動しました。'))
      } else {
        window.location = process.env.USE_JBC_ID
          ? `${process.env.JBC_ID_SERVER}/users/sign_in?app_key=lms&redirect_to=${redirect_uri}`
          : 'users/login'
      }
    } catch (err) {
      window.location = process.env.USE_JBC_ID
        ? `${process.env.JBC_ID_SERVER}/users/sign_in?app_key=lms&redirect_to=${redirect_uri}`
        : 'users/login'
    }
  }
  render() {
    return null
  }
}

export default connect()(JbcIdLogin)
