import React, {useState, useEffect} from 'react'
import {actionCreators} from 'actions'
import {Link} from 'react-router-dom'
import api from 'api'
import styles from 'dashboard/dashboard.scss'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {convertFormToQueryString} from 'notifications/list/utils'
import Hint from 'jbc-front/components/Hint'
import {isLimitedAdminSelector} from 'utils'

const procedureTypes = [
  {value: 'enroll', name: '入社の手続き', color: styles.blue},
  {value: 'resign', name: '退社の手続き', color: styles.orange},
  {
    value: 'change_dependents',
    subValue: ['add_dependents', 'remove_dependents'],
    name: '扶養の変更',
    color: styles.green,
  },
  {value: 'change_address', name: '住所の変更', color: styles.pink},
  {value: 'change_name', name: '氏名の変更', color: styles.yellow},
  {
    value: 'maternity_leave',
    subValue: ['start_maternity', 'extend_maternity', 'finish_maternity'],
    name: '産休の手続き',
    color: styles.violet,
  },
]

const procedureStatuses = [
  {value: 'applying', name: '承認待ち'},
  {value: 'draft', name: '途中保存'},
  {value: 'in_progress', name: '対応中'},
  {value: 'request', name: '依頼中'},
  {value: 'done', name: '完了'},
]

const mynumberStatuses = [
  {value: 'last_7_days', name: '直近7日分の提出'},
  {value: 'working_and_re_requesting', name: '提出（再）依頼中'},
  {value: 'not_acquired', name: '未取得'},
  {value: 'acquired', name: '取得済'},
]

const List = ({type, getCount}) => (
  <>
    <ul className={styles.title_content}>
      {procedureStatuses.map((status, index) => (
        <li key={index}>{status.name}</li>
      ))}
    </ul>
    <ul className={styles.count_content}>
      {procedureStatuses.map((status, index) => {
        const count = getCount(type, status.value)
        return (
          <li key={index}>
            <ListData count={count} link={`/procedure_statuses?procedure_type=${type.value}&status=${status.value}`} />
          </li>
        )
      })}
    </ul>
  </>
)

const MyNumberList = ({getMyNumberCount}) => {
  const query = convertFormToQueryString({
    created_at_start: moment().subtract(6, 'd').format('YYYY/MM/DD'),
    created_at_end: moment().format('YYYY/MM/DD'),
  })

  return (
    <>
      <ul className={styles.title_content}>
        {mynumberStatuses.map((status, index) => (
          <li key={index}>{status.name}</li>
        ))}
      </ul>
      <ul className={styles.count_content}>
        <li>
          <ListData
            count={getMyNumberCount('last_7_days')}
            link={`/notifications?${query}&notification_type=MyNumberNotification`}
          />
        </li>
        <li>{getMyNumberCount('working_and_re_requesting')}</li>
        <li>
          <ListData
            count={getMyNumberCount('not_acquired')}
            link={'/my_number?my_number_employment_status=employed_and_joined&my_number_status=not_completed'}
          />
        </li>
        <li>
          <ListData
            count={getMyNumberCount('acquired')}
            link={'/my_number?my_number_employment_status=employed_and_joined&my_number_status=completed'}
          />
        </li>
      </ul>
    </>
  )
}

const ListData = ({count, link}) => {
  return <>{count > 0 ? <Link to={link}>{count}</Link> : 0}</>
}

const Table = ({summaries, my_number_summaries, isLimitedAdmin}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const getActiveClass = (index, className) => (currentTabIndex === index ? className : '')

  const getCount = (procedureType, status) =>
    (procedureType.subValue || [procedureType.value])
      .map((type) => summaries.find((key) => key.procedure_type === type && key.status === status)?.count || 0)
      .reduce((a, b) => a + b)

  const getMyNumberCount = (status) => my_number_summaries[status] || 0

  return (
    <div className={styles.container}>
      <div className={styles.container_wrap}>
        <div className={styles.wrap_tab_list}>
          <ul className={[styles.tab_list, isLimitedAdmin && styles.isForLimitedAdmin].join(' ')}>
            {procedureTypes.map((type, index) => {
              return (
                <li
                  key={index}
                  className={`${styles.tabs} ${type.color} ${getActiveClass(index, `${styles.active_tabs}`)}`}
                  onClick={() => setCurrentTabIndex(index)}
                >
                  <div className={styles.top}>
                    通知<span>{getCount(type, 'applying')}</span>件
                  </div>
                  <div className={styles.middle}>{type.name}</div>
                  <div className={styles.bottom} />
                </li>
              )
            })}
            {isLimitedAdmin ? null : (
              <li
                className={`${styles.tabs} ${styles.red} ${getActiveClass(6, `${styles.active_tabs}`)}`}
                onClick={() => setCurrentTabIndex(6)}
              >
                <div className={styles.top}>
                  通知<span>{getMyNumberCount('last_7_days')}</span>件
                </div>
                <div className={styles.middle}>マイナンバーの提出</div>
                <div className={styles.bottom} />
              </li>
            )}
          </ul>
        </div>
        <div>
          {procedureTypes.map((type, index) => {
            return (
              <div
                key={index}
                className={`${styles.content} ${type.color} ${getActiveClass(index, `${styles.active_content}`)}`}
              >
                <List type={type} getCount={getCount} />
              </div>
            )
          })}
          {isLimitedAdmin ? null : (
            <div className={`${styles.content} ${styles.red} ${getActiveClass(6, `${styles.active_content}`)}`}>
              <MyNumberList getMyNumberCount={getMyNumberCount} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const renderTable = (isLimitedAdmin, summaries, my_number_summaries) => {
  if (isLimitedAdmin) {
    return summaries && <Table summaries={summaries} isLimitedAdmin={isLimitedAdmin} />
  } else {
    return summaries && my_number_summaries && <Table summaries={summaries} my_number_summaries={my_number_summaries} />
  }
}

const ProcedureStatuses = () => {
  const dispatch = useDispatch()
  const [token, summaries, my_number_summaries, planType, isLimitedAdmin] = useSelector((state) => [
    state.auth.token,
    state.procedureStatuses.summary?.data?.summaries,
    state.procedureStatuses.myNumberSummary?.data?.summary,
    state.client.current.plan_type,
    isLimitedAdminSelector(state),
  ])

  useEffect(() => {
    dispatch(actionCreators.procedureStatuses.summary.fetchData(api.createWithAuth(token).procedureStatuses.summary()))
    dispatch(actionCreators.procedureStatuses.myNumberSummary.fetchData(api.createWithAuth(token).myNumber.summary()))
    return () => {
      dispatch(actionCreators.procedureStatuses.summary.destroy())
      dispatch(actionCreators.procedureStatuses.myNumberSummary.destroy())
    }
  }, [])

  return (
    <article className={styles.procedureStatus}>
      <div className={styles.procedureStatusWrap}>
        <div className={styles.card}>
          <div className={styles.card_header}>
            <div>
              現在進行中の手続き
              {planType == 'free_plan' && (
                <Hint
                  text={
                    <p>
                      無料プランは機能制限により手続き情報をご確認いただけない場合があります。
                      <br />
                      有料プランにお申し込みいただくことで、すべての手続きをご覧いただけるようになります。
                    </p>
                  }
                />
              )}
            </div>
          </div>
          <div className={styles.card_body}>{renderTable(isLimitedAdmin, summaries, my_number_summaries)}</div>
        </div>
      </div>
    </article>
  )
}

export default ProcedureStatuses
