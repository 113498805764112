import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Button from 'jbc-front/components/Button'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {Email} from 'jbc-front/components/icons'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import styles from 'tm/notifications/Remind.scss'

const Remind = ({isModalOpen, showModal, hideModal = [], id}) => {
  const dispatch = useDispatch()
  const [remindAnswers, setRemindAnswers] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const token = useSelector((state) => state.auth.token)

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await api.createWithAuth(token).tm.notifications.remind(id)
      dispatch(notifySuccess('配信しました'))
    } catch (err) {
      dispatch(asyncError(err))
      setRemindAnswers({})
    }
    setSubmitting(false)
    hideModal()
  }

  const handleClick = async () => {
    setSubmitting(true)
    try {
      const result = await api.createWithAuth(token).tm.notifications.remindAnswers(id)
      setRemindAnswers(result.data)
      showModal()
    } catch (err) {
      dispatch(asyncError(err))
      setRemindAnswers({})
    }
    setSubmitting(false)
  }

  return (
    <div className={styles.wrap}>
      <Button widthAuto onClick={handleClick} disabled={submitting} className={styles.remindButton}>
        <span className={styles.icon}>
          <Email size={16} />
        </span>
        リマインドメール送信
      </Button>
      <Modal isOpen={isModalOpen} hideModal={hideModal}>
        {isModalOpen && (
          <>
            <Modal.Header hideModal={hideModal}>リマインドメール送信確認</Modal.Header>
            <Modal.Body className={styles.modalbody}>
              <p>送信人数：{remindAnswers?.remind_answers_count}人</p>
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                {(!!remindAnswers?.remind_answers_count && (
                  <>
                    <Button onClick={hideModal}>キャンセル</Button>
                    <Button primary onClick={handleSubmit} disabled={submitting}>
                      送信する
                    </Button>
                  </>
                )) || <Button onClick={hideModal}>キャンセル</Button>}
              </Modal.Buttons>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  )
}

export default withModal(Remind)
