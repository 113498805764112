import React from 'react'
import {gql} from '@apollo/client'
import {useQuery} from 'components/Graphql'
import LoadingPage from 'components/LoadingPage'
import Button from 'jbc-front/components/Button'
import {Link} from 'react-router-dom'
import {Copy, Edit, Employee, Delete as DeleteIcon} from 'jbc-front/components/icons'
import Delete from './Delete'
import styles from 'settings/roles/List.scss'

const ROLES = gql`
  query roles {
    client {
      id
      roles {
        id
        name
        isDefault
        description
        personRoles {
          id
        }
      }
    }
  }
`

const List = () => {
  const {data, loading, error} = useQuery(ROLES, {fetchPolicy: 'network-only'})
  const roles = data?.client?.roles || []
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">権限一覧</h1>
      </div>
      <div className="l-wrap-xxl l-contents-wrap">
        <div className={styles.contentsHead}>
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <Link to="/settings/talent_display" className="l-breadcrumb-link">
              情報公開設定
            </Link>
            <span className="l-breadcrumb-here">権限一覧</span>
          </div>
          <Link to="/settings/roles/create">
            <Button>新規登録</Button>
          </Link>
        </div>
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="l-overflow-scroll">
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th className={styles.roleName}>権限名</th>
                  <th className={styles.default}>デフォルト</th>
                  <th className={styles.roleDescription}>説明</th>
                  <th colSpan={4} />
                </tr>
              </thead>
              <tbody>
                {!error && roles.length > 0 && (
                  <>
                    {roles.map((role) => (
                      <tr key={role.id} className={styles.row}>
                        <td>{role.name}</td>
                        <td className={styles.defaultCheck}>{role.isDefault ? '✔' : ''}</td>
                        <td>{role.description}</td>
                        <td>
                          <Link className={styles.copy} to={`/employees?role_ids[]=${role.id}`}>
                            <div className={styles.noteWrap}>
                              <Employee size={20} className={styles.icon} />
                              <div className={styles.note}>従業員一覧</div>
                            </div>
                          </Link>
                        </td>
                        <td className={styles.editColumn}>
                          <Link className={styles.copy} to={`/settings/roles/edit/${role.id}`}>
                            <div className={styles.noteWrap}>
                              <Edit size={20} className={styles.icon} />
                              <div className={styles.note}>権限を編集</div>
                            </div>
                          </Link>
                        </td>
                        <td className={styles.copyColumn}>
                          <Link className={styles.copy} to={`/settings/roles/create?role_id=${role.id}`}>
                            <div className={styles.noteWrap}>
                              <Copy size={20} className={styles.icon} />
                              <div className={styles.note}>
                                権限情報をコピーして
                                <br />
                                新しい権限を追加
                              </div>
                            </div>
                          </Link>
                        </td>
                        <td className={styles.copyColumn}>
                          {!role.isDefault &&
                            (!role.personRoles.length ? (
                              <div>
                                <span>
                                  <Delete roleId={role.id} />
                                </span>
                              </div>
                            ) : (
                              <div className={styles.noteWrap}>
                                <span className={styles.iconDisable}>
                                  <DeleteIcon size={20} />
                                </span>
                                <div className={styles.note}>
                                  この権限を持つユーザーが
                                  <br />
                                  いるので削除できません
                                </div>
                              </div>
                            ))}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

export default List
