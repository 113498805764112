import {actionCreators} from 'actions'

import {connectPager} from 'components/paginators'
import {connectFilterTag} from 'components/Tags'
import {connectFilterTab} from 'components/Tabs'
import {connectSearch} from 'components/Search'
import {connectPerPage} from 'components/PerPage'
import {connectOrderedTh} from 'components/OrderedTh'
import connectListData from 'components/ListData'
import {getQueryDiff, getCsvProcedureQueryDiff} from 'procedureStatuses/reducers'
import api from 'api'

const querySelector = (state) => state.procedureStatuses.query
const queryActionCreator = actionCreators.procedureStatuses.setQuery

export const OrderedTh = connectOrderedTh(querySelector, queryActionCreator)
export const Filter = connectFilterTag(querySelector, queryActionCreator)
export const Tab = connectFilterTab(querySelector, queryActionCreator)
export const Pager = connectPager(querySelector, queryActionCreator)
export const Search = connectSearch(querySelector, queryActionCreator)
export const PerPage = connectPerPage(querySelector, queryActionCreator)
export const ListData = connectListData(
  querySelector,
  actionCreators.procedureStatuses,
  (token, query) => api.createWithAuth(token).procedureStatuses.list(query, ['employee', 'request_status']),
  getQueryDiff
)
export const CsvListData = connectListData(
  querySelector,
  actionCreators.procedureStatuses,
  (token, query) => api.createWithAuth(token).csvProcedureStatuses.list(query, ['employee', 'office']),
  getCsvProcedureQueryDiff
)
