import type {ThunkDispatch} from '@reduxjs/toolkit'
import type {State} from 'types/state'

export const isAdminSelector = (state: State) =>
  ['full_admin', 'office_admin', 'limited_admin'].includes(
    state.session.currentUser?.client_role?.client_role_type || ''
  )

export const isOfficeAdminSelector = (state: State) =>
  state.session.currentUser?.client_role?.client_role_type === 'office_admin'

export const getState = (dispatch: ThunkDispatch<State, never, never>) =>
  new Promise<State>((resolve) => {
    dispatch((_dispatch, getState) => {
      resolve(getState())
    })
  })
