import React, {useState} from 'react'
import {Section, TextField, CheckboxField, Label} from 'jbc-front/components/Form'
import {toFormValues, recordDisplay} from 'utils'
import connectForm from 'reportForms/connectForm'
import {reduxForm, FormSection, formValueSelector} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {getRelatedDependents} from 'procedures/changeDependents/utils'
import _ from 'lodash'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R46Report'
const selector = formValueSelector(formName)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({
    handleSubmit,
    submitting,
    dependentInfoValues,
    dependents,
    submitText,
    isTarget,
    employee,
    procedureStatus,
    needUpgrade,
  }) => {
    const [openModal, setOpenModal] = useState(false)
    const r46Dependents = getRelatedDependents(dependents, procedureStatus)
    const branchSubmit = (needUpgrade) => {
      if (needUpgrade) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">健康保険被保険者証回収不能・滅失届</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <Section>
            <Label text="回収不能の対象者選択" />
            {procedureStatus.procedure_type === 'resign' && (
              <CheckboxField name="is_target" label={recordDisplay.fullName(employee)} />
            )}
            {procedureStatus.procedure_type === 'resign' && isTarget && (
              <TextField name="reason" label="被保険者証を返納できない理由" />
            )}
            <LabelMapper name="r46_dependent_param_set" label="扶養情報" />
            {r46Dependents &&
              r46Dependents.map((dependent, index) => {
                const value = (dependentInfoValues && dependentInfoValues[index]) || {}
                return (
                  <FormSection name={`r46_dependent_param_set[${index}]`} key={dependent.id}>
                    <CheckboxField name="is_target" label={recordDisplay.fullName(dependent)} />
                    {value.is_target && <TextField name="reason" label="被保険者証を返納できない理由" />}
                  </FormSection>
                )
              })}
            {procedureStatus.procedure_type === 'resign' && <TextField name="notes" label="備考" />}
            <div className="u-ta-c u-mt30">
              <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => {
            setOpenModal(false)
            handleSubmit()
          }}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)
const makeInitialValues = ({dependents, data: reportValues, procedureStatus}) => {
  if (!dependents) {
    return toFormValues(reportValues)
  }
  const r46DependentParamSet = _.get(reportValues, 'r46_dependent_param_set') || []
  const r46Dependents = getRelatedDependents(dependents, procedureStatus)
  const r46DependentValues = r46Dependents.map((dependent) =>
    toFormValues(
      _.find(r46DependentParamSet, (value) => value.employee_dependent_id == dependent.id) || {
        employee_dependent_id: dependent.id,
      }
    )
  )
  return _.assign(toFormValues(reportValues), {r46_dependent_param_set: r46DependentValues})
}
const connectedForm = connectForm('r46', Form, {
  mapState: (state) => ({
    dependents: _.get(state, 'procedureStatuses.current.data.employee.employee_dependents'),
    employee: _.get(state, 'procedureStatuses.current.data.employee') || {},
    dependentInfoValues: selector(state, 'r46_dependent_param_set'),
    isTarget: selector(state, 'is_target'),
    procedureStatus: state.procedureStatuses.current.data,
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  makeInitialValues,
})

export default connectedForm
