import {FC, ReactNode} from 'react'
import {Link} from 'react-router-dom'
import {Pulldown} from 'jbc-front/components/icons'
import styles from 'settings/settings.scss'

interface ItemProps {
  children: ReactNode
  title: string
  to: string
  disabled?: boolean
}

export const SettingItem: FC<ItemProps> = ({children, title, to, disabled}) => {
  const itemDetail = () => (
    <dl className={styles.definition}>
      <dt>
        <span className={styles.icon}>
          <Pulldown size={13} />
        </span>
        {title}
      </dt>
      <dd>{children}</dd>
    </dl>
  )

  return (
    <li>
      {disabled ? (
        <div className={styles.itemDisabled}>{itemDetail()}</div>
      ) : (
        <Link to={to} className={styles.itemWrap}>
          {itemDetail()}
        </Link>
      )}
    </li>
  )
}
