import React, {Component} from 'react'
import Form, {makeInitialValues} from 'procedures/maternityLeave/start/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import _ from 'lodash'
import checkEmployeeInput from 'components/checkEmployeeInput'
import checkUnemployed from 'components/checkUnemployed'
import compose from 'lodash/fp/compose'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'startMaternity'

class EmployeeInput extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      loadEmployee,
      token,
      loadTmpData,
      loadComments,
    } = this.props
    loadEmployee(token)
    loadComments(id, token)
    if (id) {
      loadTmpData(id, token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      employee,
      match: {
        params: {id},
      },
      tmpData: {application_procedure_start_maternity: maternityData},
      tmpFiles,
      comments,
      healthInsuranceType,
      updateAndCreateProcedure,
      token,
    } = this.props
    const handleSubmit = (values, comment) =>
      updateAndCreateProcedure(id, employee.id, {...values, comment: comment}, token)
    return (
      <div>
        <CommentModal
          formName={formName}
          procedureType="maternityLeave"
          onSubmit={({comment, values}) => {
            handleSubmit(values, comment)
          }}
          comments={comments || []}
        >
          {({showModal}) => (
            <Form
              onSubmit={showModal}
              initialValues={makeInitialValues(maternityData, tmpFiles)}
              tmpFiles={tmpFiles}
              submitText="申請する"
              healthInsuranceType={healthInsuranceType}
              comments={comments || {}}
            />
          )}
        </CommentModal>
      </div>
    )
  }
}

export default compose(
  checkEmployeeInput(),
  checkUnemployed(),
  connect(
    (state) => ({
      employee: state.employees.current.data,
      tmpData: state.procedureStatuses.tmpData.data,
      tmpFiles: state.procedureStatuses.tmpData.tmpFiles,
      comments: fetchSelector(state, 'procedure_comments').data,
      healthInsuranceType: _.get(state.session.currentUser, 'employee.office.health_insurance_type'),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadEmployee: (token) => {
        dispatch(actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.getCurrent(['office'])))
      },
      loadTmpData: (id, token) => {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api
                .createWithAuth(token)
                .procedureStatuses.tmpData.mapToJson(id, {type: 'application', name: 'procedure_start_maternity'}),
              api.createWithAuth(token).procedureStatuses.tmpFiles.list(id, 'application', 'mother_child_note'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndCreateProcedure: async (procedureStatusId, employeeId, values, token) => {
        const formattedValues = {
          ...values,
          mother_child_note: values.maternity_status === 'after' ? values.mother_child_note : [],
        }
        const authedApi = api.createWithAuth(token)
        try {
          const maternityProcedureId = await (async () => {
            if (procedureStatusId) {
              return procedureStatusId
            } else {
              const {
                data: {id},
              } = await authedApi.procedureStatuses.start(
                employeeId,
                'start_maternity',
                formattedValues.date_on,
                'employee_draft'
              )
              return id
            }
          })()
          await authedApi.procedureStatuses.tmpData.createOrUpdate(
            maternityProcedureId,
            formattedValues,
            'application',
            'procedure_start_maternity'
          )
          const newFiles = formattedValues.mother_child_note.filter((note) => !note.filename)
          const keepFileNames = formattedValues.mother_child_note.map((note) => note.filename).filter((n) => n)
          await authedApi.procedureStatuses.tmpFiles.bulkPost(
            maternityProcedureId,
            newFiles,
            keepFileNames,
            'application',
            'mother_child_note'
          )
          await authedApi.procedureStatuses.apply(maternityProcedureId, formattedValues.comment)
          if (formattedValues.procedure_maternity_child_data.some((child_datum) => child_datum.add_support)) {
            dispatch(
              push(`/mypage/add_dependents/employee_input?maternity_procedure_status_id=${maternityProcedureId}`)
            )
          } else {
            dispatch(push('/dashboard'))
          }
          dispatch(notifySuccess('申請しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(EmployeeInput)
