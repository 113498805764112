import {FC, useState, useEffect} from 'react'
import {displayFormat, numbersFormat} from 'libs/formatter'
import {getMainOfficeContactInfo} from 'libs/officeUtils'
import type {User} from 'types/api/users/user'
import type {CurrentClientForAdmin} from 'types/api/clients/currentClient'

/* eslint-disable no-var -- グローバル変数宣言ではvarしか使えない */
declare global {
  var setZopim: () => void
  var $zopim: any // eslint-disable-line @typescript-eslint/no-explicit-any
}
/* eslint-enable no-var */

const isEmpty = <T>(value?: T): value is undefined => !value || Object.keys(value).length === 0

const isAdmin = (user: User) =>
  user.client_role && ['full_admin', 'office_admin'].includes(user.client_role.client_role_type)

const isSupportedPlan = (planType: string) => ['paid_plan', 'trial_plan'].includes(planType)

interface ZopimLoaderProps {
  client?: CurrentClientForAdmin
  currentUser?: User
}

/**
 * チャットサポートが利用可能な管理者でのログインが確認できたらzopim.jsをロードする。
 */
export const ZopimLoader: FC<ZopimLoaderProps> = ({client, currentUser}) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (loaded) {
      return
    }

    if (isEmpty(client) || isEmpty(currentUser)) {
      return
    }

    if (isAdmin(currentUser) && isSupportedPlan(client.plan_type)) {
      const mainOfficeSocial = getMainOfficeContactInfo(client.office)

      window.setZopim = () => {
        if (typeof window.$zopim == 'undefined') {
          setTimeout(() => {
            window.setZopim()
          }, 300)
        } else {
          window.$zopim(() => {
            window.$zopim.livechat.setName(displayFormat(mainOfficeSocial?.name))
            window.$zopim.livechat.setEmail(currentUser.email)
            window.$zopim.livechat.setPhone(
              numbersFormat([
                mainOfficeSocial?.phone_number_0,
                mainOfficeSocial?.phone_number_1,
                mainOfficeSocial?.phone_number_2,
              ])
            )
          })
        }
      }
      // @ts-ignore
      import(/* webpackChunkName: "zopim" */ 'zopim')
    }

    // currentUserの権限が変わることはあんまりないので、ロードしなかったとしても判定は一度きり。
    setLoaded(true)
  }, [loaded, client, currentUser])

  return null
}
