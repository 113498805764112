import _ from 'lodash'

const trimFields = () => (next) => (action) => {
  if (_.isObject(action) && action.type === '@@redux-form/BLUR' && _.isString(action.payload)) {
    next({...action, payload: action.payload.trim()})
  } else {
    next(action)
  }
}

export default trimFields
