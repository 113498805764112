import {FC, useState, FormEvent} from 'react'
import {Glass} from 'jbc-front/components/icons'
import styles from './Search.scss'

interface SearchProps {
  onSubmit: (value?: string) => void
  placeholder?: string
}

export const Search: FC<SearchProps> = ({onSubmit, placeholder}) => {
  const [keyword, setKeyword] = useState<string>()
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    onSubmit(keyword)
    e.preventDefault()
  }

  return (
    <form onSubmit={handleSubmit} className={styles.searchContainer}>
      <input
        type="text"
        className={styles.search}
        onChange={(e) => {
          setKeyword(e.target.value)
        }}
        placeholder={placeholder}
      />
      <span className={styles.icon}>
        <Glass size={16} />
      </span>
    </form>
  )
}
