import {FC, useState} from 'react'
import Button from 'jbc-front/components/Button'
import {MainTitle} from 'components/layout/MainTitle'
import {Content} from 'components/layout/Content'
import {Breadcrumb} from 'components/layout/Breadcrumb'
import {Panel, Head, Body} from 'components/ui/Panel'
import {EgovAccountInfo, EgovAccountInfoProps} from './parts/EgovAccountInfo'
import _ from 'lodash'
import styles from './Presenter.scss'
// @ts-ignore
import Modal from 'jbc-front/components/Modal'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'

const handleLogin = () => (window.location.href = '/api/egov-oauth/login')
const handleUpdateLogin = () => (window.location.href = '/api/egov-oauth/token')

export interface PresenterProps {
  egovAccountInfoProps?: EgovAccountInfoProps
  handleLogout: () => Promise<void>
}

export const Presenter: FC<PresenterProps> = ({egovAccountInfoProps, handleLogout}) => {
  const paths = [
    {
      key: 'settings',
      to: WITH_AUTH_ADMIN_PATHS.SETTINGS.INDEX,
      label: '設定',
    },
  ]
  const [showModal, setShowModal] = useState(false)
  const renderButton = () => {
    if (!_.isEmpty(egovAccountInfoProps)) {
      if (egovAccountInfoProps?.egovAccessToken?.isActive) {
        // アクセストークンが有効
        return (
          <Button widthAuto onClick={() => setShowModal(true)}>
            連携解除
          </Button>
        )
      } else {
        // アクセストークンが期限切れ
        return (
          <>
            <p className={`u-mb20 ${styles.confirm}`}>e-Govアカウント認証の有効期限が切れています。再認証が必要です</p>
            <Button primary widthAuto onClick={handleUpdateLogin}>
              e-Govアカウント再認証
            </Button>
            <Button className="u-ml15" widthAuto onClick={() => setShowModal(true)}>
              連携解除
            </Button>
          </>
        )
      }
    } else {
      // EgovAccountが存在しない場合
      return (
        <Button primary widthAuto onClick={handleLogin}>
          e-Govアカウント登録
        </Button>
      )
    }
  }

  return (
    <>
      <MainTitle title="e-Govアカウント連携" description="e-Govアカウントの連携ができます" />
      <Content size="xs">
        <Breadcrumb paths={paths} current="e-Govアカウント連携" />
        <Panel className="u-mb30">
          <Head>e-Govアカウント連携</Head>
          <Body>
            <EgovAccountInfo {...egovAccountInfoProps} />
            {egovAccountInfoProps?.egovIdp === 'gbizid' && (
              <>
                <br />
                gBizID「<span className={styles.confirm}>メンバー</span>
                」のアカウントで電子申請の手続きを行う場合は、事前に代理人を選任する届出が必要となります。詳細は
                <a
                  href="https://jobcan-lms.zendesk.com/hc/ja/articles/360058045332"
                  target="_blank"
                  className="u-txt-link"
                  rel="noreferrer"
                >
                  こちら
                </a>
                。
              </>
            )}
            <div className="u-ta-c u-mt30">
              {renderButton()}
              <Modal
                header="連携解除の確認"
                body={
                  <p>
                    e-Govアカウントの連携を解除します。
                    <br />
                    連携を解除すると申請、取下げ、公文書・通知の取得ができなくなります。
                    <br />
                    <span className={styles.confirm}>本当に解除しますか？</span>
                  </p>
                }
                onSubmit={handleLogout}
                isOpen={showModal}
                hideModal={() => setShowModal(false)}
                submit="はい"
                cancel="いいえ"
              />
            </div>
          </Body>
        </Panel>
      </Content>
    </>
  )
}
