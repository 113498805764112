import React from 'react'
import Button from 'jbc-front/components/Button'

import {FileField, Section, RadioField} from 'jbc-front/components/Form'
import {reduxForm, getFormSubmitErrors as _getFormSubmitErrors, SubmissionError} from 'redux-form'
import {Download} from 'jbc-front/components/icons'

import styles from 'employees/FileImport.scss'
import api from 'api'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {Upload} from 'jbc-front/components/icons'
import _ from 'lodash'
import {downloadOptions} from 'employees/CsvDownload'
import {recordDisplay} from 'utils'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import {downloadFile} from 'libs/downloadFile'
import {asyncError} from 'store/actions/asyncError'

const formName = 'business_partner_import'
const getFormSubmitErrors = _getFormSubmitErrors(formName)

const Description = ({label, required, note, example}) => (
  <tr>
    <th>
      {label}
      {required && <span className={styles.required}>必須</span>}
    </th>
    <td>{recordDisplay(note)}</td>
    <td>{example}</td>
  </tr>
)
const Descriptions = ({children}) => (
  <div className={styles.tableWrap}>
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  </div>
)

export const validate = (values) => {
  const errors = {}
  if (_.isEmpty(values.file)) {
    errors.file = 'ファイルを選択してください'
  }
  return errors
}

const initialValues = {csv_update: false}

const DownloadForm =
  (({handleSubmit}) => (
    <form onSubmit={handleSubmit}>
      <dl className={styles.label}>
        <dt className={styles.number}>1</dt>
        <dd className={styles.detail}>
          <p className="u-mb20">ファイルの出力形式を選択してダウンロードしたCSVファイルをご利用ください。</p>
          <p className="u-mb20">Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください</p>
          <RadioField name="encoding" options={downloadOptions} label="ファイル形式選択" />
          <Button as="button" icon={<Download size={16} />} widthWide>
            サンプルデータをダウンロード
          </Button>
        </dd>
      </dl>
    </form>
  ))
  |> reduxForm({
    form: 'myNumberSampleDownload',
    initialValues: {encoding: 'UTF-8'},
    onSubmit(values, dispatch, {selectedOffices}) {
      return downloadFile({
        url: `/api/export_my_numbers_csv?encoding=${values.encoding}`,
        asyncError: (err) => dispatch(asyncError(err)),
        selectedOffices,
      })
    },
  })
  |> connect((state) => ({
    selectedOffices: state.session.selectedOffices,
  }))

const UploadForm =
  (({submitting, handleSubmit}) => (
    <form onSubmit={handleSubmit} className="u-mt20">
      <dl className={styles.label}>
        <dt className={styles.number}>2</dt>
        <dd className={styles.detail}>
          <p className={styles.text}>
            下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したい従業員の情報を入れてください。
          </p>
          <p>
            <div className="u-mb10 u-mt10">【注意】</div>
            <span className={styles.normal}>
              以下の項目は
              <span className={styles.important}>入力必須</span>
              になります。
              <ul>
                <li>・スタッフコード</li>
                <li>・マイナンバー</li>
                <li>・（被扶養者の情報を登録する場合）被扶養者 姓</li>
                <li>・（被扶養者の情報を登録する場合）被扶養者 名</li>
                <li>・（被扶養者の情報を登録する場合）被扶養者 生年月日</li>
                <li>・（被扶養者の情報を登録する場合）被扶養者 マイナンバー</li>
              </ul>
            </span>
          </p>
        </dd>
      </dl>
      <dl className={styles.label}>
        <dt className={styles.number}>3</dt>
        <dd className={styles.detail}>
          <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
          <FileField name="file" />
        </dd>
      </dl>
      <AsyncTaskConsumer>
        {({taskRunningProps}) => (
          <Button
            as="button"
            primary
            type="submit"
            disabled={submitting}
            icon={<Upload />}
            widthWide
            {...taskRunningProps}
          >
            アップロード
          </Button>
        )}
      </AsyncTaskConsumer>
    </form>
  ))
  |> reduxForm({
    form: formName,
    validate,
    async onSubmit(values, dispatch, {token}) {
      try {
        await api.createWithAuth(token).myNumber.import(values)
        dispatch(push('/my_number?my_number_employment_status=employed_and_joined'))
      } catch (err) {
        if (err.response && err.response.status == 422 && _.get(err.response, 'data._errors')) {
          throw new SubmissionError(err.response.data._errors)
        }
        dispatch(asyncError(err))
      }
    },
  })
  |> connect((state) => ({
    token: state.auth.token,
  }))

const FileImport = ({errors}) => (
  <div>
    <div className="l-contents-wrap">
      <h1 className="m-title-main">マイナンバーの一括登録</h1>
      <p className="m-title-main-note">従業員と被扶養者のマイナンバーを一括で登録できます。</p>
      <div className={`u-mt60 ${styles.mainNoteWrap}`}>
        <div className={styles.mainNoteTitle}>アップロード手順</div>
        <div className={styles.mainNoteBody}>
          <DownloadForm />
          <UploadForm initialValues={initialValues} />
        </div>
      </div>
    </div>
    {errors && errors.rows && (
      <Section title="ファイル内容に誤りがあります" error>
        <ul>
          {errors.rows.map((err) => (
            <li key={err.index}>
              第{err.index + 1}行：{err.name}
              {err.message}
            </li>
          ))}
        </ul>
      </Section>
    )}
    <div className="u-mt50">
      <Descriptions>
        <Description label="スタッフコード" required example="111" note="半角英数字50桁以内" />
        <Description label="姓" example="山田" note="※ダウンロード時には出力されますが、更新時には無視されます" />
        <Description label="名" example="太郎" note="※ダウンロード時には出力されますが、更新時には無視されます" />
        <Description label="マイナンバー" example="123412341234" note="半角数字12桁" />
        <Description label="在籍状況" example="在籍" note="※ダウンロード時には出力されますが、更新時には無視されます" />
        <Description label="被扶養者1 姓" example="山田" />
        <Description label="被扶養者1 名" example="花子" />
        <Description label="被扶養者1 続柄" example="配偶者" />
        <Description label="被扶養者1 生年月日" example="1980/1/1" note="半角数字 (yyyy/mm/dd)" />
        <Description label="被扶養者1 マイナンバー" example="123412341234" note="半角数字12桁" />
      </Descriptions>
    </div>
  </div>
)

export default connect((state) => ({
  errors: getFormSubmitErrors(state),
}))(FileImport)
