import React from 'react'
import {connect} from 'react-redux'
import {Description, withFormSelectors, getDiffFromOptions} from 'employees/form/common'
import {Section, DateField, RadioField, TextField, SelectField, Label, DateSpanFields} from 'jbc-front/components/Form'
import {JoinedAt, EmployeeNumber} from 'FormFields'
import {FieldArray, FormSection} from 'redux-form'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import _ from 'lodash'
import moment from 'moment'
import {toBooleanProps} from 'utils'
import * as validators from 'jbc-front/utils/validators'
import {toAdYearDate} from 'jbc-front/utils/jpYear'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import ActionButton from 'jbc-front/components/ActionButton'
import {Link} from 'react-router-dom'
import styles from './EmploymentInfomation.scss'

export const employmentStatuses = {
  employed: '在職',
  vacation: '休職',
  unemployed: '退職',
  join: '内定',
}

export const retireTypes = [
  {value: 'others', label: '一般退職'},
  {value: 'disability', label: '障害退職'},
  {value: 'passed_away', label: '死亡退職'},
]

const leaveReasonTypes = [
  {value: 'sickness', label: '私傷病休職'},
  {value: 'childbirth', label: '産前産後休業'},
  {value: 'childcare', label: '育児休業'},
  {value: 'at_birth', label: '出生時育児休業'},
  {value: 'nursing', label: '介護休業'},
  {value: 'others', label: 'その他'},
]

export const toOption = ({id, name}) => ({value: `${id}`, label: name})
export const groupFullName = ({name, parent_group_id}, groups) =>
  parent_group_id && groups[parent_group_id] ? `${groupFullName(groups[parent_group_id], groups)}->${name}` : name

const MAX_GROUP_COUNT = 3

export const getGroups = (employee) =>
  employee &&
  employee.personnel_history &&
  _.range(MAX_GROUP_COUNT)
    .map((index) => employee.personnel_history[`group_${index}_name`])
    .filter(_.identity)

export const getTmGroups = (employee) =>
  employee &&
  employee.personnel_history &&
  employee.personnel_history[0] &&
  _.range(MAX_GROUP_COUNT)
    .map((index) => employee.personnel_history[0][`group_${index}_name`])
    .filter(_.identity)

export const renderGroups = ({fields, options, placeholder}) => (
  <div className="u-mb20">
    <Label text="グループ" />
    <div>
      <ul>
        {fields.map((name, index) => {
          return (
            <li key={index} className="u-mb30">
              <SelectField
                name={`${name}.group_id`}
                label={`グループ(${index + 1})`}
                options={options}
                placeholder={placeholder}
              />
              {fields.length > 1 && (
                <div
                  className="u-txt-deleteinfo"
                  onClick={() => {
                    fields.remove(index)
                  }}
                >
                  <DeleteSquare className="u-txt-deleteinfo-icon" />
                  削除
                </div>
              )}
            </li>
          )
        })}
      </ul>
      {fields.length < MAX_GROUP_COUNT && (
        <div className="u-ta-r u-mt20 u-mb20">
          <div onClick={() => fields.push({_id: _.uniqueId('group_')})} className="u-txt-addinfo">
            所属グループ追加
            <PlusSquare size={20} className="u-txt-addinfo-icon" />
          </div>
        </div>
      )}
    </div>
  </div>
)

const sameDayIn = (absence1, absence2) => {
  const startOn = moment(absence1.start_on)
  if (startOn.isSame(absence2.start_on)) {
    return true
  }
  if (startOn.isAfter(absence2.start_on)) {
    return !absence2.end_on || startOn.isSameOrBefore(absence2.end_on)
  }
  return !absence1.end_on || moment(absence2.start_on).isSameOrBefore(absence1.end_on)
}

const validatePeriods = ({name, allowEndOnEmpty, allowSameDay, _afterJoinedAt}, values) => {
  const periods = values[name]
  const joinedAt = values.joined_at
  if (!periods) {
    return {}
  }

  const errors = {}
  let index = -1
  for (let period of periods) {
    index++
    if (!period.start_on) {
      _.set(errors, `${name}[${index}].start_on`, 'を入力してください')
      continue
    }
    if (!allowEndOnEmpty && !period.end_on) {
      _.set(errors, `${name}[${index}].end_on`, 'を入力してください')
      continue
    }
    const startOn =
      period.start_on && period.start_on.match(validators.jpYearRegExp)
        ? toAdYearDate(period.start_on, false)
        : period.start_on
    const endOn =
      period.end_on && period.end_on.match(validators.jpYearRegExp) ? toAdYearDate(period.end_on, false) : period.end_on
    if (moment(startOn).isValid() && joinedAt && moment(joinedAt).isValid() && moment(startOn).isBefore(joinedAt)) {
      _.set(errors, `${name}[${index}].start_on`, 'を入社日の後で入力してください')
      continue
    }
    if (endOn && moment(startOn).isAfter(endOn)) {
      _.set(errors, `${name}[${index}].end_on`, 'は正しくありません')
      continue
    }
    if (period.reason_type === 'at_birth' && moment(startOn).isBefore('2022-10-01')) {
      _.set(errors, `${name}[${index}].start_on`, 'を2022年10月01日以降にしてください（休職理由：出生時育児休業）')
      continue
    }
  }
  if (!_.isEmpty(errors)) {
    return errors
  }
  index = -1
  if (!allowSameDay) {
    for (let period of periods) {
      index++
      const startOn =
        period.start_on && period.start_on.match(validators.jpYearRegExp)
          ? toAdYearDate(period.start_on, false)
          : period.start_on
      for (let i = 0; i < index; i++) {
        if (startOn) {
          if (sameDayIn(periods[i], period)) {
            _.set(errors, `${name}[${index}]._fields`, 'は既存の期間と重複します')
          }
        }
      }
    }
  }
  return errors
}

export const validate = (values) => {
  return Object.assign(
    {},
    validatePeriods(
      {name: 'employee_leave_of_absences', allowEndOnEmpty: true, allowSameDay: false, afterJoinedAt: true},
      values
    ),
    values.has_contract_period
      ? validatePeriods(
          {name: 'contract_periods', allowEndOnEmpty: false, allowSameDay: true, afterJoinedAt: false},
          values
        )
      : {}
  )
}

const renderLeaveOfAbsences = ({fields}) => (
  <div>
    <ul>
      {fields.map((name, index) => (
        <li key={index}>
          <Section
            title="休職期間"
            icon={
              <DeleteSquare
                onClick={() => {
                  fields.remove(index)
                }}
              />
            }
          >
            <FormSection name={name}>
              <DateSpanFields label="休職期間" required />
              <SelectField options={leaveReasonTypes} name="reason_type" label="休職理由" />
            </FormSection>
          </Section>
        </li>
      ))}
    </ul>
    <div className="u-ta-r u-mt20 u-mb20">
      <div onClick={() => fields.push({_id: _.uniqueId('leave_of_absences_')})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        休職期間追加
      </div>
    </div>
  </div>
)

const renderContractPeriods = ({fields, setNoContractPeriod}) => (
  <div>
    <Label text="契約期間" />
    <ul>
      {fields.map((name, index) => (
        <li key={index}>
          <Section
            title="契約期間"
            icon={
              <DeleteSquare
                onClick={() => {
                  if (fields.length === 1) {
                    fields.push({})
                    setNoContractPeriod()
                  }
                  fields.remove(index)
                }}
              />
            }
          >
            <FormSection name={name}>
              <DateSpanFields label="契約期間" required />
            </FormSection>
          </Section>
        </li>
      ))}
    </ul>
    <div className="u-ta-r u-mt20 u-mb20">
      <div onClick={() => fields.push({_id: _.uniqueId('leave_of_absences_')})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        契約期間追加
      </div>
    </div>
  </div>
)

export const toSelectProps = (list, label) => ({
  label,
  options: list.map(toOption),
  placeholder: list.length > 0 ? undefined : `${label}が未設定です。設定から情報を入力してください`,
})

const EmploymentInfomation = ({
  hasContractPeriod,
  retiredAt,
  joinedAt,
  handleJoinedAtChange,
  handleRetiredAtChange,
  procedureType,
  employeeId,
  setNoContractPeriod,
  description,
  description_color_by_rgb,
  diff = {},
}) => {
  const {personnel_history} = diff
  return (
    <Section title="業務情報">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <EmployeeNumber diff={diff} />
      <div className={styles.notes}>
        スタッフコードを変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。
      </div>
      <Section title="人事情報">
        <FormSection name="personnel_history">
          {employeeId && (
            <ActionButton
              as={Link}
              to={`/employees/${employeeId}/personnel_history`}
              className="u-mb20"
              target="_blank"
              rel="noopener noreferrer"
            >
              人事異動履歴へ
            </ActionButton>
          )}
          <p className="u-mb20">
            「雇用形態」「役職」「職種」「グループ」は人事異動履歴の情報から自動で表示されます。
            <br />
            編集する場合は「人事異動履歴へ」ボタンを押してください。
          </p>
          <TextField name="office_name" label="適用事業所" diff={personnel_history?.office_name} disabled />
          <TextField
            name="employment_type_name_with_code"
            label="雇用形態"
            diff={personnel_history?.employment_type_name_with_code}
            disabled
          />
          <TextField
            name="position_name_with_code"
            label="役職"
            diff={personnel_history?.position_name_with_code}
            disabled
          />
          <TextField
            name="occupation_name_with_code"
            label="職種"
            diff={personnel_history?.occupation_name_with_code}
            disabled
          />
          <TextField
            name="group_0_full_name_with_code"
            label="グループ1"
            diff={personnel_history?.group_0_full_name_with_code}
            disabled
          />
          <TextField
            name="group_1_full_name_with_code"
            label="グループ2"
            diff={personnel_history?.group_1_full_name_with_code}
            disabled
          />
          <TextField
            name="group_2_full_name_with_code"
            label="グループ3"
            diff={personnel_history?.group_2_full_name_with_code}
            disabled
          />
        </FormSection>
      </Section>
      <TextField name="business_content" label="業務内容" diff={diff.business_content} />
      <TextField
        name="employment_status"
        label="在籍状況"
        diff={diff.employment_status}
        disabled
        format={(value) => employmentStatuses[value] || ''}
      />
      <JoinedAt required onChange={handleJoinedAtChange} diff={diff} />
      {procedureType !== 'enroll' && (
        <div>
          <DateField
            name="retired_at"
            label="退職日"
            example="例）1981/01/23、S56.1.23"
            isValidDate={(date) => !joinedAt || !moment(joinedAt).isValid() || moment(date).isAfter(joinedAt)}
            onChange={handleRetiredAtChange}
            diff={diff.retired_at}
          />
          {retiredAt && (
            <div>
              <RadioField
                name="retire_type"
                label="退職区分"
                options={retireTypes}
                diff={getDiffFromOptions(retireTypes, diff.retired_at)}
              />
              <TextField name="retire_reason" label="退職理由" diff={diff.retire_reason} />
            </div>
          )}
          <FieldArray name="employee_leave_of_absences" component={renderLeaveOfAbsences} />
          <LabelMapper name="employee_leave_of_absences" label="休職期間" />
        </div>
      )}
      <RadioField name="has_contract_period" label="契約期間の定め" {...toBooleanProps} />
      {hasContractPeriod && [
        <FieldArray
          key="contract"
          name="contract_periods"
          component={renderContractPeriods}
          setNoContractPeriod={setNoContractPeriod}
        />,
        <LabelMapper name="contract_periods" key="label" label="契約期間" />,
        <DateField key="alert" name="contract_alert_on" label="契約更新のためのアラート" />,
      ]}
    </Section>
  )
}

export default EmploymentInfomation
  |> connect(
    (state, {selector}) => ({
      retiredAt: selector(state, 'retired_at'),
      joinedAt: selector(state, 'joined_at'),
      hasContractPeriod: selector(state, 'has_contract_period'),
      employeeId: selector(state, 'id'),
      diff: selector(state, '_diff'),
    }),
    (dispatch, {selector, autofill, getInitialValues}) => ({
      handleJoinedAtChange(e, value) {
        dispatch((dispatch, getState) => {
          if (!value || !moment(value, 'YYYY/MM/DD').isValid) {
            return
          }
          const startOn = value.match(validators.jpYearRegExp) ? toAdYearDate(value) : value
          const state = getState()
          const dependents = selector(state, 'dependents') || []
          dispatch(autofill('welfare_pension_insurance.start_on', startOn))
          dispatch(autofill('health_insurance.start_on', startOn))
          dispatch(autofill('employment_insurance.start_on', startOn))
          dependents.forEach((dependent, index) => {
            if (!dependent.dependent_from) {
              dispatch(autofill(`dependents[${index}].dependent_from`, startOn))
            }
          })
        })
      },
      handleRetiredAtChange(e, value) {
        dispatch((dispatch, getState) => {
          const state = getState()
          const initialValues = getInitialValues(state)
          const retireTypesField = 'retire_type'
          const currentRetireType = selector(state, retireTypesField)
          const retireType = value ? currentRetireType || 'others' : null
          dispatch(autofill(retireTypesField, retireType))

          if (
            (value.match(validators.adYearRegExp) ||
              value.match(validators.jpYearRegExp) ||
              moment(value, moment.ISO_8601).isValid()) &&
            moment(value, 'YYYY/MM/DD').isValid()
          ) {
            const fillIf = (field, date, oldDate) => {
              const oldValue = selector(state, field)
              if (
                !oldDate ||
                !oldValue ||
                !moment(oldValue, 'YYYY/MM/DD').isValid() ||
                moment(oldValue, 'YYYY/MM/DD').isSame(oldDate, 'day')
              ) {
                dispatch(autofill(field, moment(date, 'YYYY/MM/DD').format('YYYY/MM/DD')))
              }
            }
            const endOn = value.match(validators.jpYearRegExp) ? toAdYearDate(value) : value
            const day = moment(endOn, 'YYYY/MM/DD').clone()
            const nextDay = moment(endOn, 'YYYY/MM/DD').clone().add(1, 'day')
            const oldDay = initialValues.joined_at && moment(selector(state, 'retired_at')).clone()
            const nextOldDay = initialValues.joined_at && moment(selector(state, 'retired_at')).clone().add(1, 'day')
            fillIf('welfare_pension_insurance.end_on', nextDay, nextOldDay)
            fillIf('health_insurance.end_on', nextDay, nextOldDay)
            fillIf('employment_insurance.end_on', day, oldDay)
          }
        })
      },
      setNoContractPeriod() {
        dispatch(autofill('has_contract_period', false))
      },
    })
  )
  |> withFormSelectors
