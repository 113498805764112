import {FC} from 'react'
import useRouter from 'use-react-router'

import {FloatingContainer} from 'components/layout/FloatingContainer'

import Button from 'jbc-front/components/Button'

type FloatingButtonsProps = {
  employeeId: number
}

export const ApplyingButton: FC<FloatingButtonsProps> = ({employeeId}) => {
  const {history} = useRouter()

  const handleMoveConfirm = () => {
    const path = `/employees/${employeeId}/detail_input_confirm`
    history.push(path)
  }

  return (
    <FloatingContainer>
      <Button primary onClick={handleMoveConfirm}>
        承認画面へ
      </Button>
    </FloatingContainer>
  )
}
