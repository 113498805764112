import React, {useState, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import {useSelector} from 'hooks/redux'
import {useFetchCustomEmployeeFieldGroups} from 'hooks/api/clients/customEmployeeFieldGroups'
import {useFetchCustomEmployeeFields} from 'hooks/api/clients/customEmployeeFields'
import {useFetchEmployeeFieldGroups} from 'hooks/api/employeeFieldGroups'
import type {CustomEmployeeField} from 'types/api/settings/employeeFieldGroups/customEmployeeField'
import type {CustomEmployeeFieldGroup} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldGroup'
import type {EmployeeFieldGroup} from 'types/api/settings/employeeFieldGroups/employeeFieldGroup'
//@ts-ignore
import api from 'api'
//@ts-ignore
import {actionCreators} from 'actions'
//@ts-ignore
import {sortFieldGroupsAll} from 'utils'
import {SortFieldGroups} from 'settings/employeeCustom/FieldGroups/SortFieldGroups'
//@ts-ignore
import {EmployeeFieldGroupItem} from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupItem'
import {CustomEmployeeFieldGroupItem} from 'settings/employeeCustom/FieldGroups/CustomEmployeeFieldGroupItem'
//@ts-ignore
import styles from 'settings/employeeCustom/FieldGroups/index.scss'
//@ts-ignore
import Button from 'jbc-front/components/Button'
//@ts-ignore
import ListGroup from 'jbc-front/components/ListGroup'
//@ts-ignore
import Modal from 'jbc-front/components/Modal'
import {Drugging, Plus} from 'jbc-front/components/icons'

const ListItem = ({group}: {group: EmployeeFieldGroup | CustomEmployeeFieldGroup}) => {
  const type = 'code' in group ? 'default' : 'custom'
  return (
    <ListGroup.Item as="a" href={`#field_${type}_group_${group.id}`}>
      <span className={styles.groupListLabel}>{group.label}</span>
      <ListGroup.Icon />
    </ListGroup.Item>
  )
}

const FieldGroups = () => {
  const [showSortModal, setShowSortModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletingFieldGroup, setDeletingFieldGroup] = useState<EmployeeFieldGroup | CustomEmployeeFieldGroup | null>(
    null
  )

  const token = useSelector((state) => state.auth.token)
  const client = useSelector((state: any) => state.client.current)
  const employee = useSelector((state: any) => state.session.currentUser.employee)

  const dispatch = useDispatch()
  const {asyncError} = useMemo(() => bindActionCreators(actionCreators, dispatch), [dispatch])

  const {
    resource: employeeFieldGroups,
    getter: employeeFieldGroupsGetter,
  }: {
    resource: EmployeeFieldGroup[]
    getter: () => Promise<void>
  } = useFetchEmployeeFieldGroups()

  const {
    resource: customEmployeeFields,
    getter: customEmployeeFieldsGetter,
  }: {
    resource: CustomEmployeeField[]
    getter: () => Promise<void>
  } = useFetchCustomEmployeeFields(client.id, {
    embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
  })

  const {
    resource: customEmployeeFieldGroups,
    getter: customEmployeeFieldGroupsGetter,
  }: {
    resource: CustomEmployeeFieldGroup[]
    getter: () => Promise<void>
  } = useFetchCustomEmployeeFieldGroups(client.id)

  const loadData = async () => {
    employeeFieldGroupsGetter()
    customEmployeeFieldsGetter()
    customEmployeeFieldGroupsGetter()
  }

  const updateFieldGroups = async (values: (EmployeeFieldGroup | CustomEmployeeFieldGroup)[]) => {
    try {
      const [updatingEmployeeFieldGroups, updatingCustomEmployeeFieldGroupsValue] = _.partition(values, 'code') as [
        EmployeeFieldGroup[],
        CustomEmployeeFieldGroup[]
      ]

      const updatingEmployeeFieldGroupsValue = updatingEmployeeFieldGroups.map(
        ({id, position, mutable_by_employee, setting}) => ({
          id,
          position,
          mutable_by_employee,
          setting,
        })
      )
      const postParams = {
        employee_field_groups: updatingEmployeeFieldGroupsValue,
        custom_employee_field_groups: updatingCustomEmployeeFieldGroupsValue,
      }
      await api.createWithAuth(token).employeeCustom.fieldGroups.updateAll(postParams)
    } catch (err) {
      asyncError(err)
    }
  }

  const deleteFieldGroup = async () => {
    try {
      await api.createWithAuth(token).employeeCustom.fieldGroups.delete(client.id, deletingFieldGroup!.id)
    } catch (err) {
      asyncError(err)
    }
  }

  const handleSortSubmit = async (values: (EmployeeFieldGroup | CustomEmployeeFieldGroup)[]) => {
    await updateFieldGroups(values)
    loadData()
    setShowSortModal(false)
  }

  const handleDeleteModalSubmit = () => {
    deleteFieldGroup().then(() => {
      loadData()
      setShowDeleteModal(false)
      setDeletingFieldGroup(null)
    })
  }

  const handleDelete = (fieldGroup: EmployeeFieldGroup | CustomEmployeeFieldGroup) => {
    setShowDeleteModal(true)
    setDeletingFieldGroup(fieldGroup)
  }

  const fieldGroupsAll: (EmployeeFieldGroup | CustomEmployeeFieldGroup)[] =
    (employeeFieldGroups &&
      customEmployeeFieldGroups &&
      sortFieldGroupsAll(employeeFieldGroups, customEmployeeFieldGroups)) ||
    []

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">従業員項目カスタマイズ</h1>
      </div>
      <div className="l-wrap-l l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <span className="l-breadcrumb-here">従業員項目カスタマイズ</span>
          <div className="l-flex u-mt10">
            <div className="l-flex3-sub">
              <div className={employee ? styles.sidebar : styles.sidebarForNonEmployees}>
                <Button
                  dark
                  icon={<Drugging />}
                  widthWide
                  style={{marginBottom: '10px'}}
                  onClick={() => setShowSortModal(true)}
                >
                  表示項目の順番を変える
                </Button>
                <Button
                  primary
                  widthWide
                  icon={<Plus />}
                  as={Link}
                  to="/settings/employee_custom/create"
                  className="u-md10"
                >
                  新規項目を追加
                </Button>
                <ListGroup>
                  <div className={styles.customNaviItems}>
                    {fieldGroupsAll.map((group, index) => (
                      <ListItem key={index} group={group} />
                    ))}
                  </div>
                </ListGroup>
              </div>
            </div>
            <div className="l-flex3-main u-mt10">
              {fieldGroupsAll.map((group) =>
                'code' in group ? (
                  <EmployeeFieldGroupItem
                    isEmployee={!!employee}
                    key={group.code}
                    employeeFieldGroup={group}
                    loadData={loadData}
                  />
                ) : (
                  <CustomEmployeeFieldGroupItem
                    key={group.id}
                    handleDelete={handleDelete}
                    group={group}
                    fields={customEmployeeFields}
                    loadData={loadData}
                    isEmployee={!!employee}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showDeleteModal}
        onSubmit={handleDeleteModalSubmit}
        hideModal={() => setShowDeleteModal(false)}
        body={
          <div className={styles.warning}>
            入力されている従業員の情報も削除されます。
            <br />
            本当に項目グループ「{deletingFieldGroup?.label}」を削除しますか？
          </div>
        }
        header="項目グループの削除"
        cancel="キャンセル"
        submit="削除"
      />
      <SortFieldGroups
        isOpen={showSortModal}
        fieldGroupsAll={fieldGroupsAll}
        hideModal={() => setShowSortModal(false)}
        handleSubmit={handleSortSubmit}
      />
    </div>
  )
}

export default FieldGroups
