import _ from 'lodash'

export const toFormDataPairs = function* (params, files) {
  for (const key in params) {
    if (files.includes(key)) {
      continue
    }
    const value = params[key]
    if (_.isArray(value)) {
      if (value.length > 0) {
        yield* value.map((v) => [`${key}[]`, v])
      } else {
        yield [(key, '')]
      }
    } else {
      if (_.isNull(value)) {
        yield [key, '']
      } else {
        yield [key, value]
      }
    }
  }
  for (let file of files) {
    if (_.isUndefined(params[file])) {
      continue
    }
    if (_.isEmpty(params[file])) {
      yield [`remove_${file}`, true]
    } else if (_.isArray(params[file])) {
      yield* params[file].map((v) => [`${file}[]`, v])
    } else {
      if (params[file] instanceof File) {
        yield [file, params[file]]
      }
    }
  }
}

export const toFormData = (pairs) => {
  return [...pairs].reduce((formData, [key, value]) => {
    formData.append(key, value)
    return formData
  }, new FormData())
}

export const bulkedToFormDataPaires = function* (data, root, files) {
  for (const record of data) {
    yield [`${root}[]__boundary__`, ''] // 1つ前のrecordとデータが混ざらないようにするため、常に同じ名前のキーを差し込んでおく
    yield* [...toFormDataPairs(record, files)].map(([k, v]) => [`${root}[]${k}`, v])
  }
}

export const bulkedToFormData = (data, root, files) => toFormData(bulkedToFormDataPaires(data, root, files))

const postWithFile = (params, files, post) => {
  return post(toFormData(toFormDataPairs(params, files)), {
    headers: {'content-type': 'multipart/form-data'},
  })
}

export default postWithFile
