import React, {useState} from 'react'
import {
  RadioField,
  SelectField,
  NumberFields,
  TextField,
  BoxDouble,
  Section,
  CheckboxField,
} from 'jbc-front/components/Form'
import {reduxForm, formValueSelector} from 'redux-form'
import {Notes, SubmitedOn} from 'FormFields'
import * as validators from 'validators'
import connectForm from 'reportForms/connectForm'
import _ from 'lodash'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {toBooleanProps} from 'utils'
import EmployeeName from 'procedures/EmployeeName'
import styles from 'reportForms/common.scss'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R57Report'

const acquisitionTypes = [
  {value: 'first_time', label: '新規'},
  {value: 'again', label: '再取得'},
]

const acquisitionTypesAgain = [
  {value: 'first_time', label: '新規', disabled: true},
  {value: 'again', label: '再取得'},
]

const joinReasons = [
  {value: 'new_employment_graduate', label: '1. 新規雇用（新規学卒）'},
  {value: 'new_employment_other', label: '2. 新規雇用（その他）'},
  {value: 'switch_from_day_labor', label: '3. 日雇からの切り替え'},
  {value: 'other', label: '4. その他'},
  {value: 'returning_to_the_dispatching_place_etc', label: '8. 出向元への復帰等（65歳以上）'},
]

const remunerationTypes = [
  {value: 'monthly', label: '1. 月給'},
  {value: 'weekly', label: '2. 週給'},
  {value: 'daily', label: '3. 日給'},
  {value: 'hourly', label: '4. 時間給'},
  {value: 'other', label: '5. その他'},
]

const employmentTypes = [
  {value: 'day', label: '1. 日雇'},
  {value: 'temporary', label: '2. 派遣'},
  {value: 'part_time', label: '3. パートタイム'},
  {value: 'periodical', label: '4. 有期契約労働者'},
  {value: 'seasonal', label: '5. 季節的雇用'},
  {value: 'sailor', label: '6. 船員'},
  {value: 'other', label: '7. その他'},
]

const occupationTypes = [
  {value: 'management', label: '01 管理的職業'},
  {value: 'profession', label: '02 専門的・技術的職業'},
  {value: 'clerical', label: '03 事務的職業'},
  {value: 'sales', label: '04 販売の職業'},
  {value: 'service', label: '05 サービスの職業'},
  {value: 'security', label: '06 保安の職業'},
  {value: 'farmer', label: '07 農林漁業の職業'},
  {value: 'production', label: '08 生産工程の職業'},
  {value: 'transporter', label: '09 輸送・機械運転の職業'},
  {value: 'construction', label: '10 建設・採掘の職業'},
  {value: 'cleaner', label: '11 運搬・清掃・包装等の職業'},
]

const employmentPaths = [
  {value: 'hello_work', label: '1. 安定所紹介'},
  {value: 'myself', label: '2. 自己就職'},
  {value: 'private', label: '3. 民間紹介'},
  {value: 'i_dont_know', label: '4. 把握していない'},
]

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, hasContractPeriod, submitText, employee, employment_insurance, needUpgrade}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">雇用保険被保険者資格取得届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          {employment_insurance &&
          (employment_insurance.person_number_0 ||
            employment_insurance.person_number_1 ||
            employment_insurance.person_number_2) ? (
            <RadioField name="acquisition_type" label="取得区分" options={acquisitionTypesAgain} />
          ) : (
            <RadioField name="acquisition_type" label="取得区分" options={acquisitionTypes} />
          )}
          <SelectField
            name="reason_type"
            label="被保険者となったことの原因"
            options={joinReasons}
            note="新卒で卒業年の3月1日～6月30日に入社する場合は「1」を、それ以外は「2」を選択します。"
          />
          <BoxDouble>
            <SelectField name="remuneration_type" label="賃金形態" options={remunerationTypes} require={true} />
            <TextField
              name="monthly_remuneration"
              label="賃金金額（月額）"
              validate={validators.number}
              require={true}
              yen
              note="基本給、通勤手当、住宅手当などを含めた1ヶ月分の給与を入力します。"
            />
            <SelectField
              name="employment_type"
              label="雇用形態"
              options={employmentTypes}
              noteWidth={240}
              note="正社員は「7. その他」になります。"
            />
            <SelectField
              name="occupation_type"
              label="職種"
              options={occupationTypes}
              noteWidth={180}
              note={
                <p>
                  職種分類の詳細は
                  <a
                    href="https://jobcan-lms.zendesk.com/hc/ja/articles/360001174352"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    こちら
                  </a>
                </p>
              }
            />
          </BoxDouble>
          <SelectField name="employment_path_type" label="就職経路" options={employmentPaths} />
          <NumberFields
            prefix="working_hours"
            label="1週間の所定労働時間"
            texts={['', ' 時間 ', ' 分 ']}
            lengths={[2, 2]}
          />
          {hasContractPeriod && (
            <div>
              <RadioField
                name="has_contract_update"
                label="契約更新条項の有無"
                {...toBooleanProps}
                note="契約更新条項とは「この契約が終了する１月前に双方が異議を述べないときは契約を自動更新するものとする。」といったものです。"
              />
            </div>
          )}
          <Notes />
          <SubmitedOn />
          <div className="u-mb20">
            <p>
              ※ 平成30年5月以降、マイナンバー（個人番号）の記載・添付が必須になります。
              <br />
              出力・ダウンロードの前に「マイナンバー出力」ボタンを押すか、印刷後に手でマイナンバーを記入してください。
            </p>
          </div>
          <div>
            <p className={styles.textLabel}>マイナンバーを添付せず電子申請を行う場合以下にチェックを入れてください。</p>
            <CheckboxField name="ignore_my_number" label="本人事由によりマイナンバー届出不可" />
          </div>
        </Section>
        <div className="u-ta-c u-mt30">
          <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
            {submitText}
          </Button>
        </div>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => {
          setOpenModal(false)
          handleSubmit()
        }}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})

export const selector = formValueSelector(formName)

const connectedForm = connectForm('r57', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    employment_insurance: _.get(state.procedureStatuses.current.data.employee, 'employment_insurance'),
    hasContractPeriod: _.get(state, 'procedureStatuses.current.data.employee.has_contract_period'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
})

export default connectedForm
