import {useFetchData} from 'hooks/api/useFetchData'
import {DefaultEmail, MailType} from 'types/api/settings/defaultEmail/defaultEmail'

type Query = Partial<{
  email_type: MailType
}>

export const useFetchDefaultEmail = (query?: Query) => {
  const path = '/api/default_email'
  return useFetchData<DefaultEmail[]>(path, [], {params: query})
}
