import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {reduxForm, getFormValues} from 'redux-form'
import Button from 'jbc-front/components/Button'
import {TextField, TextAreaField, Section, Label} from 'jbc-front/components/Form'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {useFetchDefaultEmail} from 'hooks/api/defaultEmail'
import {useFetchCustomEmails, usePostCustomEmail, useUpdateCustomEmail} from 'hooks/api/clients/customEmails'
import {useSelector} from 'hooks/redux'
import {mailTypes} from 'settings/customMails/List'
import {Preview} from 'settings/customMails/Preview'
import styles from 'settings/customMails/Edit.scss'

const formName = 'customMail'
const getValues = getFormValues(formName)

const Form =
  (({handleSubmit, onOpenPreview, defaultMail}) => (
    <>
      <FormErrors />
      <Section>
        <TextField name="subject" label="件名" placeholder={defaultMail.subject} />
        <Label text="デフォルト本文" />
        <div className={styles.body}>
          <p dangerouslySetInnerHTML={{__html: defaultMail.header}} />
        </div>
        <TextAreaField name="body" label="カスタマイズ文" />
        デフォルトの本文は編集できません。
        <div className={styles.help}>
          <ul>
            <li>
              {'<b>'}と{'</b>'}の間に文字を入れると太字になります。
            </li>
            <li>
              {'<u>'}と{'</u>'}の間に文字を入れると下線が付きます。
            </li>
          </ul>
        </div>
      </Section>
      <div className={styles.bottomButtonsArea}>
        <Button onClick={onOpenPreview}>プレビュー</Button>
        <Button primary onClick={handleSubmit}>
          保存
        </Button>
      </div>
    </>
  ))
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })

const Edit = ({
  match: {
    params: {mail_type: mailType},
  },
  history,
}) => {
  const dispatch = useDispatch()
  const [showPreview, setShowPreview] = useState(false)
  const client = useSelector((state) => state.client.current)
  const editedCustomMail = useSelector((state) => getValues(state))
  const fetchDefaultMail = useFetchDefaultEmail({email_type: mailType})
  const fetchCustomMails = useFetchCustomEmails(client.id, {email_type: mailType})
  const defaultMail = fetchDefaultMail.resource
  const customMail = fetchCustomMails.resource?.[0] || {}
  const createCustomMail = usePostCustomEmail()
  const updateCustomMail = useUpdateCustomEmail()

  const handleSubmit = async (values) => {
    const isUpdate = !!values.id
    try {
      if (isUpdate) {
        await updateCustomMail(client.id, values.id, values)
      } else {
        await createCustomMail(client.id, values)
      }
      history.push('/settings/custom_mails')
      dispatch(notifySuccess(isUpdate ? '更新しました' : '作成しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">メールカスタマイズ</h1>
        <p className="m-title-main-note">メールの内容をカスタマイズできます</p>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/custom_mails" className="l-breadcrumb-link">
            メールカスタマイズ
          </Link>
          <span className="l-breadcrumb-here">{mailTypes[mailType]}</span>
        </div>
        <Form
          initialValues={{...customMail, email_type: mailType}}
          defaultMail={defaultMail}
          onOpenPreview={() => setShowPreview(true)}
          onSubmit={handleSubmit}
        />
        <Preview
          isOpen={showPreview}
          onClosePreview={() => setShowPreview(false)}
          title={mailTypes[mailType]}
          defaultMail={defaultMail}
          customMail={editedCustomMail}
        />
      </div>
    </>
  )
}

export default Edit
