import React from 'react'
import styles from 'documents/create/Conditions.scss'
import {useWizard} from 'components/Wizard'

const Conditions = () => {
  const {current, draft} = useWizard()
  return (
    <div className={styles.conditions}>
      <div className={styles.title}>設定した条件</div>
      <div className={styles.row}>
        <div className={styles.rowHeader}>テンプレート：</div>
        <div className={styles.rowValue}>{draft.templateObject?.title}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowHeader}>書類名：</div>
        <div className={styles.rowValue}>{draft.title}</div>
      </div>
      {(current === 'UploadCustomVariables' || current === 'Confirmation') &&
        draft.employeeIds &&
        draft.checkEmployees && (
          <div className={styles.row}>
            <div className={styles.rowHeader}>選択した従業員：</div>
            <div className={styles.rowValue}>{draft.employeeIds.length}名</div>
          </div>
        )}
      {current === 'Confirmation' && draft.templateObject?.hasCsvVariables && draft.customVariablesFile && (
        <div className={styles.row}>
          <div className={styles.rowHeader}>書類に反映する情報：</div>
          <div className={styles.rowValue}>{draft.customVariablesFile.name}</div>
        </div>
      )}
    </div>
  )
}

export default Conditions
