import fetchActions from 'fetchActions'

const actions = ['UPDATE_TODO', 'UPDATE_TODO_ALL', 'SET_QUERY', 'SET_FLAG', 'SET_REPORT']

const subActions = {
  LIST: fetchActions,
  CURRENT: fetchActions,
  REPORT: fetchActions,
  TMP_DATA: fetchActions,
  SUMMARY: fetchActions,
  MY_NUMBER_SUMMARY: fetchActions,
}

export default [actions, subActions]
