import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {formMap} from 'procedureStatuses/ReportEdit'
import CommentModal from 'procedures/CommentModal'
import {parse} from 'query-string'
import {stopSubmit, touch} from 'procedures/changeDependents/add/Form'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class ConfirmDependents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Report: null,
      initialValues: {},
      dependentId: null,
      formIndex: -1,
    }
  }

  componentDidMount() {
    const {
      loadProcedureStatus,
      loadTmpData,
      loadComments,
      token,
      match: {
        params: {id},
      },
    } = this.props
    loadProcedureStatus(id, token)
    loadTmpData(id, token)
    loadComments(id, token)
  }

  componentDidUpdate() {
    const {
      location: {search},
    } = this.props
    const {dependent_index} = parse(search)
    const dependentIndex = dependent_index | 0
    if (+dependentIndex !== this.state.formIndex) {
      this.setState({formIndex: dependentIndex})
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleFormSubmit = (values) => {
    const {
      nextOrAccept,
      token,
      location: {search},
      procedureStatus,
    } = this.props
    const query = parse(search)
    const {dependent, isFinal, reportType} = this.state
    const dependentIndex = query.dependent_index | 0
    return nextOrAccept({
      token,
      procedureStatus,
      dependent,
      isFinal,
      reportType,
      dependentIndex,
      values,
    })
  }

  getDependents(props) {
    const {tmpData, procedureStatus} = props
    const procedureType = procedureStatus.procedure_type
    if (procedureType === 'enroll') {
      return tmpData.draft_enroll.employee.employee_dependents
    }
    return tmpData.draft_procedure_add_dependents.employee_dependents
  }

  componentWillReceiveProps(nextProps) {
    if (
      (!nextProps.loading && this.props.loading) ||
      (!this.props.loading && nextProps.location !== this.props.location)
    ) {
      const {
        tmpData,
        location: {search},
        match: {
          params: {id},
        },
        notifyError,
        procedureStatus,
      } = nextProps
      const query = parse(search)
      const dependentIndex = query.dependent_index | 0
      const dependents = this.getDependents(nextProps)
      const procedureType = procedureStatus.procedure_type
      if (procedureStatus.status === 'draft') {
        this.props.history.replace(`/${procedureType}/flow/${id}`)
      } else if (procedureStatus.status !== 'applying') {
        this.props.history.replace(`/procedure_statuses/${id}`)
      }
      if (_.isEmpty(dependents)) {
        this.props.history.replace(`/${procedureType}/confirm/${id}`)
        notifyError('扶養の情報がありません')
        return
      }
      const dependent = dependents[dependentIndex]
      if (!dependent) {
        this.props.history.replace(`/${procedureType}/confirm/${id}`)
        notifyError('扶養の情報がありません')
        return
      }
      const reportType = 'R150'
      const Report = formMap[reportType]
      const dependentInfo =
        _.get(
          tmpData,
          `draft_procedure_${procedureType}.report_params_sets.${dependent._id}.${reportType.toLowerCase()}_param_set`
        ) ||
        _.get(
          tmpData,
          `application_procedure_${procedureType}.report_params_sets.${
            dependent._id
          }.${reportType.toLowerCase()}_param_set`
        )
      if (!dependentInfo) {
        notifyError('従業員からの情報がありません')
        this.setState({
          Report,
          dependent,
          isFinal: dependentIndex + 1 >= dependents.length,
          reportType,
        })
        return
      }
      this.setState({
        Report,
        initialValues: dependentInfo,
        dependent,
        isFinal: dependentIndex + 1 >= dependents.length,
        reportType,
      })
    }
  }

  render() {
    const {Report, initialValues, dependent, isFinal} = this.state
    const {
      match: {
        params: {id},
      },
      loading,
      location: {search},
      comments,
    } = this.props
    const {dependent_index} = parse(search)
    const dependentIndex = dependent_index | 0
    if (dependentIndex !== this.state.formIndex) return null
    return !loading && Report ? (
      <div>
        <CommentModal procedureStatusId={id} comments={comments || {}} procedureType="changeDependents">
          {({showModal}) => (
            <Report
              procedureStatusId={id}
              initialValues={initialValues}
              submitText={isFinal ? '承認して次へ' : '次へ'}
              dependentId={dependent.id}
              onSubmit={this.handleFormSubmit}
              name={`${dependent.last_name} ${dependent.first_name}`}
              relation_type={dependent.relation_type}
              relation_other={dependent.relation_other}
              comments={comments || {}}
              otherButtons={
                <Button onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
            />
          )}
        </CommentModal>
      </div>
    ) : null
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    data: state.procedureStatuses.current.data.employee,
    tmpData: state.procedureStatuses.tmpData.data,
    comments: fetchSelector(state, 'procedure_comments').data,
    loading: state.procedureStatuses.current.loading || state.procedureStatuses.tmpData.loading,
    procedureStatus: state.procedureStatuses.current.data,
  }),
  (dispatch) => ({
    loadProcedureStatus(id, token) {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api.createWithAuth(token).procedureStatuses.get(id, ['employee'])
        )
      )
    },
    loadTmpData(id, token) {
      dispatch(
        actionCreators.procedureStatuses.tmpData.fetchData(
          api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(id, {})
        )
      )
    },
    loadComments: (procedureStatusId, token) => {
      dispatch(
        actionCreators.fetchData(
          'procedure_comments',
          api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
        )
      )
    },
    destroy() {
      dispatch(actionCreators.procedureStatuses.current.destroy())
      dispatch(actionCreators.procedureStatuses.tmpData.destroy())
    },
    async nextOrAccept({token, procedureStatus, dependent, isFinal, reportType, dependentIndex, values}) {
      try {
        const {id: procedureStatusId, procedure_type: procedureType} = procedureStatus
        const authedApi = api.createWithAuth(token)
        await authedApi.procedureStatuses.tmpData.updateJson(
          procedureStatus.id,
          'draft',
          `procedure_${procedureType}`,
          (data) => ({
            ...data,
            report_params_sets: {
              ...data.report_params_sets,
              [dependent._id]: {
                ...(data.report_params_sets[dependent._id] || {}),
                [`${reportType.toLowerCase()}_param_set`]: values,
              },
            },
          })
        )
        if (isFinal) {
          try {
            await api.createWithAuth(token).procedureStatuses.accept(procedureStatusId)
          } catch (err) {
            if (err.response && err.response.status == 422) {
              if (_.get(err.response, 'data.employee_dependents')) {
                const dependents = err.response.data.employee_dependents
                const index = dependents.findIndex((dependent) => dependent._errors)
                if (index >= 0) {
                  const errors = _.mapValues(dependents[index]._errors, (value) =>
                    _.isArray(value) ? value[0] || '' : value
                  )
                  dispatch(push(`/${procedureType}/confirm/${procedureStatusId}`))
                  const dependentsErrors = []
                  dependentsErrors[index] = errors
                  setTimeout(() => {
                    dispatch(stopSubmit({dependents: dependentsErrors}))
                    _.each(errors, (value, key) => dispatch(touch(`dependents[${index}].${key}`)))
                  }, 1000)
                  return
                }
              }
            }
            throw err
          }
          await api.createWithAuth(token).procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            {
              detailFinished: true,
              skipItsDependentsReports: true,
              status: {},
            },
            'draft',
            'flow_status'
          )
          dispatch(push(`/${procedureType}/flow/${procedureStatusId}`))
        } else {
          dispatch(
            push(`/${procedureType}/confirm_dependents/${procedureStatusId}?dependent_index=${dependentIndex + 1}`)
          )
        }
        dispatch(notifySuccess('保存しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    notifyError(msg) {
      dispatch(notifyError(msg))
    },
  })
)(ConfirmDependents)
