import React, {useState, useEffect} from 'react'
import Button from 'jbc-front/components/Button'
import styles from 'documents/create/Form.scss'
import {useWizard} from 'components/Wizard'
import Loading from 'components/Loading'
import Conditions from '../Conditions'
import {Close} from 'jbc-front/components/icons'
import PreviewContainer from 'documents/PreviewContainer'

const Warnings = ({validating, validatingProgress, warnings}) => {
  const [visible, setVisible] = useState(true)
  useEffect(() => {
    setVisible(true)
  }, [validating])

  if (validating) {
    return (
      <div className={styles.warningBoxInProgress}>
        <div className={styles.warningBoxLoading}>
          <Loading />
        </div>
        <p className={styles.warningBoxInProgressText}> エラーチェックをしています... ({validatingProgress}%)</p>
      </div>
    )
  } else if (visible && warnings?.length > 0) {
    return (
      <div className={styles.warningBox}>
        <div className={styles.warningBoxText}>
          以下のエラーが発生しています
          <Close className={styles.closeButton} onClick={() => setVisible(false)} />
        </div>
        {warnings?.map((warning, index) => (
          <li key={index}>
            <span>{warning.causePrefix}</span>
            <span className={styles.warningCause}>{warning?.cause}</span>
            <spen>{warning?.causeSuffix}</spen>
          </li>
        ))}
      </div>
    )
  }

  return null
}

const ConfirmationStep = ({handleSubmit, preview, validating, validatingProgress, warnings}) => {
  const {popStep, draft, setDraft} = useWizard()
  useEffect(() => {
    setDraft({...draft, preview: preview})
  }, [preview])
  return (
    <>
      <Conditions />
      <Warnings validating={validating} validatingProgress={validatingProgress} warnings={warnings} />
      <div className={styles.previewTitle}>{draft.title}</div>
      {draft.checkEmployees && (
        <div className={styles.previewDiscription}>
          <p className={styles.previewEmployeeCount}>選択した従業員：{draft.employeeIds.length}名</p>
          {draft.employeeIds.length > 0 && (
            <p className={styles.previewText}>※従業員を複数選択している場合は一人目のみ表示しています</p>
          )}
        </div>
      )}
      <div className={styles.previewSection}>
        <PreviewContainer pdf={draft.preview?.pdf} />
      </div>
      <div className={styles.bottomButtonsArea}>
        <Button
          className={styles.wizardBackButton}
          onClick={() => {
            popStep()
          }}
        >
          戻る
        </Button>
        <Button className={styles.wizardNextButton} primary onClick={handleSubmit}>
          書類作成
        </Button>
      </div>
    </>
  )
}

export default ConfirmationStep
