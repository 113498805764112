import type {RouteParams} from 'types/routes'
import {WITHOUT_AUTH_PATHS} from 'consts/paths'
import {Show} from 'pages/withoutAuth/invitationConfirmation/show'

export const InvitationConfirmationRoutes: RouteParams[] = [
  {
    key: 'invitation_confirmation',
    path: WITHOUT_AUTH_PATHS.INVITATION_CONFIRMATION.SHOW,
    component: Show,
    exact: true,
    whiteList: [],
  },
]
