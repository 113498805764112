import React from 'react'
import {Section, FileField} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {FormSection} from 'redux-form'
import {connect} from 'react-redux'
import {Description, withFormSelectors} from 'employees/form/common'
import styles from 'employees/form/BasicInfomation.scss'

const CurriculumVitae = ({dispatch, description, description_color_by_rgb, autofill}) => (
  <Section title="履歴書・職務経歴書">
    <Description
      {...{
        description,
        description_color_by_rgb,
      }}
    />
    <FormSection name="resumes">
      <LabelMapper name="resumes" label="履歴書・職務経歴書" />
      <FileField
        name="file"
        label="履歴書・職務経歴書"
        multiple
        onChange={(e, value, previousValue) => {
          if (previousValue !== value) {
            dispatch(autofill('_resumes_changed', true))
          }
        }}
      />
      <div className={styles.notes}>複数添付可</div>
    </FormSection>
  </Section>
)

export default CurriculumVitae |> connect() |> withFormSelectors
