import React from 'react'
import {Link} from 'react-router-dom'
import Button from 'jbc-front/components/Button'
import {Tab} from 'components/Tabs'
import styles from './Tabs.scss'

const LinkTab = ({children, current, to, ...rest}) =>
  current === to ? (
    <Tab active>{children}</Tab>
  ) : (
    <Tab as={Link} to={to} {...rest}>
      {children}
    </Tab>
  )

// TODO: broken layout in smartphone
export const Tabs = ({current, canSend}) => (
  <div className={styles.tabsWithActions}>
    <div className={styles.tabs}>
      <LinkTab current={current} to="/tm/notifications/inbox">
        受信した通知
      </LinkTab>
      {canSend && (
        <LinkTab current={current} to="/tm/notifications/sent">
          送信した通知
        </LinkTab>
      )}
      <LinkTab current={current} to="/tm/notifications/shared">
        閲覧できる通知
      </LinkTab>
    </div>
    <div className={styles.actions}>
      {canSend && (
        <Button as={Link} to="/tm/notifications/create" widthAuto>
          新規通知作成
        </Button>
      )}
    </div>
  </div>
)
