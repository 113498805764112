import React from 'react'
import {Link} from 'react-router-dom'
import {HEALTH_AND_WELFARE_PENSION} from 'procedureStatuses/reportList/common'
import ListGroup from 'jbc-front/components/ListGroup'
import {getReportList as getStartReportList} from 'procedureStatuses/reportList/startMaternity'
import {getReportList as getExtendReportList} from 'procedureStatuses/reportList/extendMaternity'

export const getReportList = function* ({
  procedureStatus,
  healthInsuranceType,
  healthInsuranceJoined,
  welfarePensionInsuranceJoined,
}) {
  if (procedureStatus?.procedure_start_maternity_datum) {
    yield* getStartReportList({
      healthInsuranceType,
      healthInsuranceJoined,
      welfarePensionInsuranceJoined,
    })
  }
  if (procedureStatus?.procedure_extend_maternity_datum) {
    yield* getExtendReportList({
      healthInsuranceType,
      healthInsuranceJoined,
      welfarePensionInsuranceJoined,
    })
  }
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') {
      yield {
        num: 'r505',
        title: '健康保険・厚生年金保険産前産後休業取得者終了届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    } else if (healthInsuranceType === 'its') {
      yield {
        num: 'r506',
        title: '健康保険・厚生年金保険産前産後休業取得者終了届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
  }
}

const ProcedureInfo = ({procedureStatus}) => (
  <ListGroup>
    <ListGroup.Title>手続き情報</ListGroup.Title>
    {procedureStatus.procedure_start_maternity_datum && (
      <ListGroup.Item as={Link} to={`/start_maternity/update/${procedureStatus.id}`}>
        手続き情報編集(産休の開始)
      </ListGroup.Item>
    )}
    {procedureStatus.procedure_extend_maternity_datum && (
      <ListGroup.Item as={Link} to={`/extend_maternity/update/${procedureStatus.id}`}>
        手続き情報編集(産休の変更)
      </ListGroup.Item>
    )}
    <ListGroup.Item as={Link} to={`/finish_maternity/update/${procedureStatus.id}`}>
      手続き情報編集(産休の終了)
    </ListGroup.Item>
  </ListGroup>
)

export default {getReportList, ProcedureInfo}
