import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {autofill, submit} from 'redux-form'
import Paginator from 'jbc-front/components/Paginator'
import styles from 'components/PerPage.scss'
import {getSavedDisplayEmployeeLimit, saveDisplayEmployeeLimit, formName, selector} from 'documentEmployees/list/utils'

const options = [10, 20, 50, 100, 200]

const PerPage = ({limit, onChange}) => {
  return (
    <select type="select" onChange={onChange} className={styles.select} value={limit}>
      {options.map((perPage) => (
        <option key={perPage} value={perPage}>
          表示件数：{perPage}件
        </option>
      ))}
    </select>
  )
}

const PaginatorWithPerPage = () => {
  const dispatch = useDispatch()
  const [pathname, user, count, page] = useSelector((state) => [
    state.router.location.pathname,
    state.session.currentUser,
    state.documentEmployees.list.count,
    selector(state, 'page'),
  ])
  const limit = getSavedDisplayEmployeeLimit(pathname, user)

  const onClick = (page) => {
    dispatch(autofill(formName, 'page', page.toString()))
    dispatch(submit(formName))
  }

  const onChangeLimit = (e) => {
    saveDisplayEmployeeLimit(pathname, user, e.target.value)
    dispatch(submit(formName))
  }

  return (
    <div>
      {count > 0 ? (
        <div className="m-pager-area">
          <Paginator current={page} totalResult={count} rowsPerPage={limit} pageLinks={7} onClick={onClick} />
          <PerPage pathname={pathname} user={user} limit={limit} onChange={onChangeLimit} />
        </div>
      ) : null}
    </div>
  )
}

export default PaginatorWithPerPage
