import React from 'react'
import {FormSection} from 'redux-form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {connect} from 'react-redux'
import {Description, withFormSelectors} from 'employees/form/common'
import {CompanyPhone, ExtensionPhone, Email} from 'FormFields'
import {Section} from 'jbc-front/components/Form'

const EmployeeContactInformation = ({diff: diffEmp = {}, description, description_color_by_rgb}) => {
  const diff = diffEmp.employee_contact_information || {}
  return (
    <Section title="社内連絡先">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <FormSection name="employee_contact_information">
        <LabelMapper name="employee_contact_information" label="社内連絡先の" />
        <div>
          <ExtensionPhone diff={diff} />
          <CompanyPhone diff={diff} />
          <Email diff={diff} />
        </div>
      </FormSection>
    </Section>
  )
}

export default EmployeeContactInformation
  |> connect((state, {selector}) => ({
    country: selector(state, 'country'),
    nations: state.master.nations,
    diff: selector(state, '_diff'),
  }))
  |> withFormSelectors
