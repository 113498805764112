import {FC} from 'react'
import Loading from 'components/Loading'
import {Result} from './parts/Result'

interface PresenterProps {
  isLoading: boolean
  isValid?: boolean
  errors?: Error
  token?: string
}

export const Presenter: FC<PresenterProps> = ({isLoading, isValid, errors, token}) => (
  <>
    <div className="l-title-wrap">
      <h1 className="m-title-main">招待承諾画面</h1>
    </div>
    {isLoading ? <Loading /> : <Result isValid={isValid} errors={errors} token={token} />}
  </>
)
