import React from 'react'
import {FieldArray, FormSection} from 'redux-form'
import {Section, SelectField, TextField, DateField, FileField, BoxDouble} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import {Description, withFormSelectors, getDiffFromFile} from 'employees/form/common'
import {useSelector} from 'hooks/redux'
import * as validators from 'validators'
import styles from 'employees/Form.scss'

const EmployeeLnaguageFields = ({language, diff, languageOptions, languageLevelOptions}) => (
  <FormSection name={language}>
    <BoxDouble>
      <SelectField options={languageOptions} name="language_id" label="言語" diff={diff.language_id} required />
      <SelectField options={languageLevelOptions} name="level" label="レベル" diff={diff.level} />
    </BoxDouble>
    <TextField
      name="qualification_name"
      label="資格名"
      validate={validators.maxLength(32)}
      diff={diff.qualification_name}
    />
    <BoxDouble>
      <TextField name="score" label="点数" validate={[validators.maxLength(6), validators.number]} diff={diff.score} />
      <div className={styles.arrayDoubleDate}>
        <DateField name="examination_date" label="受験日" diff={diff.examination_date} />
      </div>
    </BoxDouble>
    <FileField name="certificate" label="証明書" diff={getDiffFromFile(diff.certificate)} />
  </FormSection>
)

const renderEmployeeLanguage = ({languages, languageLevels, fields, diffLst = []}) => {
  const languageOptions = languages.map((language) => ({
    value: '' + language.id,
    label: language.name,
  }))
  const languageLevelOptions = languageLevels.map((language_level) => ({
    value: '' + language_level.id,
    label: language_level.name,
  }))

  return (
    <div>
      <LabelMapper name="employee_languages" label="語学情報の" />
      {fields.map((language, index) => (
        <Section
          title={`語学 (${index + 1})`}
          key={index}
          icon={
            <DeleteSquare
              onClick={() => {
                fields.remove(index)
              }}
            />
          }
        >
          <EmployeeLnaguageFields
            language={language}
            diff={diffLst[index] || {}}
            languageOptions={languageOptions}
            languageLevelOptions={languageLevelOptions}
          />
        </Section>
      ))}
      {fields.length < 1 && (
        <div className="u-ta-c">
          <p>
            「語学を追加」ボタンを押して、
            <br />
            語学情報を追加してください。
          </p>
        </div>
      )}
      <div className="u-ta-r u-mt20">
        <div onClick={() => fields.push({})} className="u-txt-addinfo">
          <PlusSquare size={20} className="u-txt-addinfo-icon" />
          語学を追加
        </div>
      </div>
    </div>
  )
}

const EmployeeLanguage = ({selector, description, description_color_by_rgb}) => {
  const diff = useSelector((state) => selector(state, '_diff')) || {}
  const languages = useSelector((state) => state.master.languages) || []
  const languageLevels = useSelector((state) => state.master.languageLevels) || []

  return (
    <Section title="語学情報">
      <Description {...{description, description_color_by_rgb}} />
      <FieldArray
        name="employee_languages"
        component={renderEmployeeLanguage}
        diffLst={diff.employee_languages}
        {...{languages, languageLevels}}
      />
    </Section>
  )
}

export default EmployeeLanguage |> withFormSelectors
