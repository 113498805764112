import {FC, useEffect} from 'react'
import {useSelector} from 'hooks/redux'
import {useDispatch} from 'react-redux'
import Cookies from 'js-cookie'
import {clearTelemetryUser} from 'libs/telemetry'
import {asyncError} from 'store/actions/asyncError'

// @ts-ignore
import api from 'api'

export const Logout: FC = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const doLogout = async () => {
    try {
      await api.createWithAuth(token).doLogout()
      Cookies.remove('access_token')
      clearTelemetryUser()
      window.location.href = '/login'
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  useEffect(() => {
    if (token) {
      doLogout()
    } else {
      window.location.href = '/login'
    }
  }, [])
  return <main className="u-ta-c u-pt100">ログアウトしています...</main>
}
