import React, {useState} from 'react'
import {Section, RadioField} from 'jbc-front/components/Form'
import {reduxForm, formValueSelector} from 'redux-form'
import {SubmitedOn, RomajiName, RomajiNameReason, Name, KanrentodokesyoSiyouSimei} from 'FormFields'
import connectForm from 'reportForms/connectForm'
import Button from 'jbc-front/components/Button'
import {toFormValues, toBooleanProps} from 'utils'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R55Report'

const hasRomajiNameOptions = [
  {value: 'true', label: '持っている'},
  {value: 'false', label: '持っていない'},
]

const hasResidentCardOptions = [
  {value: 'false', label: 'なし'},
  {value: 'true', label: 'あり'},
]

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, hasRomajiName, otherReason, employee, needUpgrade}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">厚生年金被保険者ローマ字氏名届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section title="ローマ字氏名">
          <RadioField
            required
            label="ローマ字氏名を"
            {...toBooleanProps}
            options={hasRomajiNameOptions}
            name="has_romaji_name"
          />
          {(hasRomajiName && <RomajiName required />) || <RomajiNameReason otherReason={otherReason} required />}
        </Section>
        <Section title="漢字氏名" note="※漢字氏名をお持ちの方のみ">
          <Name isKanji prefix="kanji_" />
        </Section>
        <Section title="通称氏名" note="※通称氏名をお持ちの方のみ">
          <Name isCommonName prefix="tsusho_" />
        </Section>
        <Section title="住民登録の有無">
          <RadioField
            required
            label="住民登録の有無"
            {...toBooleanProps}
            options={hasResidentCardOptions}
            name="has_resident_card"
          />
        </Section>
        <Section title="関連届書使用氏名">
          <KanrentodokesyoSiyouSimei required />
        </Section>
        <Section title="書類提出日">
          <SubmitedOn />
        </Section>
      </div>
      <div className="u-ta-c u-mt30">
        <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
          {submitText}
        </Button>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => {
          setOpenModal(false)
          handleSubmit()
        }}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})
const selector = formValueSelector(formName)

const makeInitialValues = ({data}) => {
  const values = toFormValues(data)
  values.has_romaji_name = !!values.romaji_name
  values.has_resident_card = !!values.has_resident_card
  return values
}

const connectedForm = connectForm('r55', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    hasRomajiName: selector(state, 'has_romaji_name'),
    otherReason: selector(state, 'other_reason'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  makeInitialValues,
})

export default connectedForm
