import {FC} from 'react'
import {Dl, Dt, Dd} from 'components/ui/DefinitionList'
import {EgovAccessToken} from '../query'

// @ts-ignore
import {recordDisplay} from 'utils'

export interface EgovAccountInfoProps {
  email?: string
  egovIdp?: string
  registeredAt?: string
  egovAccessToken?: EgovAccessToken
}

export const EgovAccountInfo: FC<EgovAccountInfoProps> = ({email = '', egovIdp = '', registeredAt = ''}) => {
  return (
    <Dl>
      <Dt>メールアドレス</Dt>
      <Dd>{email || 'ー'}</Dd>
      <Dt>アカウント種別</Dt>
      <Dd>{egovIdp || 'ー'}</Dd>
      <Dt>連携日時</Dt>
      <Dd>{recordDisplay.date(registeredAt, 'LLL') || 'ー'}</Dd>
    </Dl>
  )
}
