import React, {useState} from 'react'
import Modal from 'components/Modal'
import Button from 'jbc-front/components/Button'
import _ from 'lodash'
import fp from 'lodash/fp'
import {connect} from 'react-redux'
import api from 'api'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import styles from './BulkDelete.scss'

const BulkDelete = ({selected, dispatch, token, onSucess}) => {
  const [loading, setLoading] = useState(false)
  return (
    <Modal>
      {({hideModal, showModal, isOpen}) => (
        <>
          <Button
            primary
            onClick={() => {
              if (_.some(selected)) {
                showModal()
              } else {
                dispatch(notifyError('従業員を選択してください'))
              }
            }}
          >
            削除
          </Button>
          {isOpen && (
            <Modal.Modal>
              <Modal.Header>従業員削除</Modal.Header>
              <Modal.Body>
                <span>【注意】</span>
                <br />
                <br />
                従業員情報を削除すると、削除した従業員に関する全ての情報が消去され、当該従業員の情報は
                <span className={styles.warning}>復旧できなくなります</span>。<br />
                <br />
                <span className={styles.importantWarning}>本当に削除しますか？</span>
                <br />
                <br />
                削除される従業員
                <div>
                  <ul>
                    {Object.entries(selected).filter(([, v]) => v)
                      |> fp.sortBy(([, {name}]) => name)
                      |> fp.map(([id, {name}]) => <li key={id}>・{name}</li>)}
                  </ul>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Buttons>
                  <Button
                    primary
                    disabled={loading}
                    onClick={async () => {
                      setLoading(true)
                      try {
                        await api.createWithAuth(token).employees.bulkDelete(
                          Object.entries(selected)
                            .filter(([, v]) => v)
                            .map(([id]) => id)
                        )
                        hideModal()
                        dispatch(notifySuccess('従業員を削除しました'))
                        onSucess()
                      } catch (err) {
                        dispatch(asyncError(err))
                      } finally {
                        setLoading(false)
                      }
                    }}
                  >
                    削除する
                  </Button>
                  <Button onClick={hideModal} disabled={loading}>
                    キャンセル
                  </Button>
                </Modal.Buttons>
              </Modal.Footer>
            </Modal.Modal>
          )}
        </>
      )}
    </Modal>
  )
}

export default connect((state) => ({token: state.auth.token}))(BulkDelete)
