import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field} from 'redux-form'
import {fetchSelector} from 'actions'
import {toOpts} from 'employees/list/addedFields/utils'
import styles from 'jbc-front/components/SearchForm.scss'
import {LanguagesSelectBox, SearchTextBox} from 'jbc-front/components/SearchForm'

class Languages extends Component {
  static defaultProps = {
    languages: [],
    languageLevels: [],
  }

  render() {
    const {languages, languageLevels} = this.props
    return (
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>語学</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <span className={styles.detailsComplementLabel}>言語</span>
            <Field name="languages" component={LanguagesSelectBox} options={toOpts(languages)} />
            <div className={styles.detailsBodyItems}>
              <span className={styles.detailsComplementLabel3}>レベル</span>
              <Field name="language_levels" component={LanguagesSelectBox} options={toOpts(languageLevels)} />
            </div>
          </div>
          <div className={styles.detailsBodyItems}>
            <span className={styles.detailsComplementLabel}>資格</span>
            <Field name="languages_qualification_name" component={SearchTextBox} inputWidth={300} />
            <span className={styles.detailsComplementLabel2}>点数</span>
            <Field name="languages_score_from" component={SearchTextBox} inputWidth={60} maxLength={6} />
            <span className={styles.detailsComplementText}>点以上</span>
            <Field name="languages_score_to" component={SearchTextBox} inputWidth={60} maxLength={6} />
            <span className={styles.detailsComplementText}>点以下</span>
          </div>
        </div>
      </div>
    )
  }
}
export default connect((state) => ({
  token: state.auth.token,
  languages: fetchSelector(state, 'selections').languages,
  languageLevels: fetchSelector(state, 'selections').languageLevels,
}))(Languages)
