import React, {Component} from 'react'
import {connect} from 'react-redux'
import api from 'api'
import EmployeesList from 'procedures/EmployeesList'
import {asyncError} from 'store/actions/asyncError'
import {push} from 'connected-react-router'

class ChooseEmployeeDirect extends Component {
  handleClick = (employeeId) => {
    const {nextAction} = this.props
    const procedureType = this.props.location.pathname.match(/^\/(\w+)\/?/)[1]
    this.props.checkNeedEmployeeUpdate(nextAction, procedureType, employeeId, this.props.token)
  }

  render() {
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">従業員を選択してください</h1>
        </div>
        <div className="l-wrap-xl">
          <EmployeesList handleClick={(employeeId) => this.handleClick(employeeId)} />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    selected: state.employees.selected,
  }),
  (dispatch) => ({
    async checkNeedEmployeeUpdate(nextAction, procedureType, id, token) {
      try {
        const {
          data: {need},
        } = await api.createWithAuth(token).employees.checkNeedEmployeeUpdate.get(id)
        if (need) {
          dispatch(push(`/employees/${id}/edit?procedure_type=${procedureType}&next_action=${nextAction || 'detail'}`))
        } else {
          dispatch(push(`/${procedureType}/${nextAction || 'detail'}?employee_id=${id}`))
        }
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(ChooseEmployeeDirect)
