import React from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {reduxForm, Field} from 'redux-form'
import {Error} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import _ from 'lodash'
import styles from 'employees/CsvDownload.scss'
import {Download} from 'jbc-front/components/icons'
import {required} from 'jbc-front/utils/validators'
import {asyncError} from 'store/actions/asyncError'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import {getValues, convertFormToQuery, hasConditions} from 'settings/auditLogs/list/utils'
import {getState} from 'utils'
import {downloadFile} from 'libs/downloadFile'

const formName = 'csv_download'

const downloadOptions = [
  {value: 'UTF-8', label: 'UTF-8（推奨）'},
  {value: 'Windows-31J', label: 'Shift-JIS'},
]

const targetOptions = [
  {value: 'all', label: '全操作履歴'},
  {value: 'search', label: '検索絞込中の操作履歴'},
]

export const renderRadioField = ({label, input, options, disabled, note, radioNote, meta}) => (
  <div className={meta.error && meta.touched ? styles.invalidField : styles.field}>
    <div className={styles.label}>{label}</div>
    <div className={styles.note}>{note}</div>
    <div>
      {options.map(({label, value}) => (
        <div style={{display: 'inline-block'}} className="input-label" key={value}>
          <label>
            <input
              {..._.omit(input, 'value')}
              type="radio"
              value={value}
              checked={value == input.value}
              disabled={disabled}
              className={`m-radio-input${disabled ? ' disabled' : ''}`}
            />
            <span className={`m-radio-parts${disabled ? ' disabled' : ''}`}>{label}</span>
          </label>
        </div>
      ))}
    </div>
    {radioNote && <div className={styles.note}>{radioNote}</div>}
    <Error meta={meta} label={label} />
  </div>
)

const Form = compose(
  connect((state) => ({
    token: state.auth.token,
    location: state.router.location,
    selectedOffices: state.session.selectedOffices,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmit: async ({_target: target, audit_logs_query, ...values}, dispatch, {hideModal, selectedOffices}) => {
      const params = {...values, audit_logs_query}
      const parseFormStateToParams = async (dispatch) => {
        const state = await getState(dispatch)
        return convertFormToQuery(getValues(state))
      }
      if (target === 'search') {
        params.audit_logs_query = {...audit_logs_query, ...(await parseFormStateToParams(dispatch))}
      }
      hideModal()
      await downloadFile({
        url: `/api/export_audit_logs_csv`,
        asyncError: (err) => dispatch(asyncError(err)),
        params,
        usePost: true,
        selectedOffices,
      })
    },
  })
)(({handleSubmit, submitting, hideModal}) => {
  return (
    <React.Fragment>
      <Modal.Header hideModal={hideModal}>操作履歴のダウンロード</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Field
            component={renderRadioField}
            label="ファイル形式選択"
            name="encoding"
            options={downloadOptions}
            radioNote="Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。"
            validate={required}
          />
          <Field
            label="ダウンロード対象選択"
            component={renderRadioField}
            name="_target"
            options={targetOptions}
            note={
              <React.Fragment>
                全操作履歴：直前3ヶ月間の全操作履歴をダウンロードします。
                <br />
                検索絞込中の操作履歴：操作履歴画面で検索絞込した操作履歴をダウンロードします。
              </React.Fragment>
            }
            validate={required}
          />
          <br />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={submitting}>
            <Download />
            ダウンロード
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </React.Fragment>
  )
})

const initialValues = {
  encoding: 'UTF-8',
}

const CsvDownload = ({isModalOpen, showModal, hideModal, loadData, searchValues, additionalParams}) => (
  <React.Fragment>
    <Button as="a" widthAuto onClick={showModal}>
      操作履歴ダウンロード
    </Button>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      {isModalOpen && (
        <Form
          {...{hideModal, loadData}}
          initialValues={{
            ...initialValues,
            _target: hasConditions(searchValues) ? 'search' : 'all',
            audit_logs_query: additionalParams,
          }}
        />
      )}
    </Modal>
  </React.Fragment>
)

export default withModal(
  connect((state) => ({
    token: state.auth.token,
    searchValues: getValues(state),
  }))(CsvDownload)
)
