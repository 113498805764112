import React from 'react'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {Section, TextField, FileField, CheckboxField, SelectField} from 'jbc-front/components/Form'
import {reduxForm, FormSection, autofill as autofillForm, formValueSelector} from 'redux-form'
import {toFormValues} from 'utils'
import {Address, Name, Email, Phone} from 'FormFields'
import SubmissionDestination from 'settings/eGov/SubmissionDestination'
import {AnchorWithLink} from 'employees/Navi'
import ActionButton from 'jbc-front/components/ActionButton'
import {connect} from 'react-redux'
import {agentTypes} from 'settings/laborConsultant/Form'
import {zenkakuKatakanaWithZenkakuSpace, noHankaku} from 'validators'
import styles from 'settings/eGov/Form.scss'

const formName = 'clientForm'
export const autofill = autofillForm.bind(null, formName)
const selector = formValueSelector(formName)

const Person = ({title, name, contents, emailRequired}) => (
  <Section title={title}>
    <FormSection name={name}>
      {contents}
      <TextField name="corporate_name" label="法人団体名" required validate={noHankaku} />
      <TextField
        name="corporate_name_kana"
        label="法人団体名（カナ）"
        required
        validate={zenkakuKatakanaWithZenkakuSpace}
      />
      <Address noHankaku eGov required kanaRequired />
      <TextField name="position" label="役職" validate={noHankaku} />
      <Name noHankaku withZenkakuSpace required />
      <Phone required />
      <Email required={emailRequired} />
    </FormSection>
  </Section>
)

const BasicInfomation = connect((state) => ({
  isLaborConsultant: selector(state, 'contact_information.is_labor_consultant'),
}))(({isLaborConsultant, dispatch}) => (
  <Section title="基本情報">
    <Person name="applicant_information" title="申請者・提出者に関する情報" />
    <LabelMapper name="applicant_information" label="申請者・提出者に関する情報の" />
    <Person
      name="contact_information"
      title="連絡先に関する情報"
      contents={
        <React.Fragment>
          <CheckboxField label="社会保険労務士として連絡先を設定する" name="is_labor_consultant" />
          <div className="u-mb10" style={{color: '#999999'}}>
            提出代行者あるいは事務代理者として社会保険労務士に依頼する場合はこちらを設定してください
          </div>
          <ActionButton
            className="u-mb10"
            onClick={() => {
              dispatch((dispatch, getState) => {
                const state = getState()
                const applicantInformation = selector(state, 'applicant_information')
                dispatch(autofill('contact_information', applicantInformation))
              })
            }}
          >
            申請者・届出者に関する情報をコピー
          </ActionButton>
        </React.Fragment>
      }
      emailRequired
    />
    <LabelMapper name="contact_information" label="連絡先に関する情報の" />
    {isLaborConsultant && (
      <Section title="社会保険労務士に関する情報">
        <LabelMapper name="labor_consultant" label="社会保険労務士に関する情報の" />
        <FormSection name="labor_consultant">
          <SelectField name="agent_type" label="代理区分" options={agentTypes} />
          <FileField name="egov_acting_certificate_file" label="代理者代行証明書" accept=".jpg,.pdf" />
          <p className={styles.description}>JPEG(拡張子：jpg)又は、PDF（拡張子：pdf）でアップロードしてください。</p>
        </FormSection>
      </Section>
    )}
  </Section>
))

const Certificate = connect((state) => ({
  file: selector(state, 'certificate_file.file'),
}))(({file}) => (
  <Section title="電子証明書">
    <LabelMapper name="certificate_file" label={'\u200b'} />
    <FormSection name="certificate_file">
      <FileField
        name="file"
        label="電子証明書"
        note={
          <p>
            電子証明書は電子申請を行う場合に必要です。
            <br />
            電子証明書の取得方法は
            <a
              href="https://jobcan-lms.zendesk.com/hc/ja/articles/360001021912"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
          </p>
        }
      />
      {file instanceof File && <TextField required type="password" name="pin" label="PINコード" />}
    </FormSection>
  </Section>
))

const Base = ({handleSubmit, pristine, submitting}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">事業所情報の入力</h1>
    </div>
    <div>
      <FormErrors />
      <AnchorWithLink name="basic_infomation" />
      <BasicInfomation />
      <AnchorWithLink name="certificate" />
      <Certificate />
      <AnchorWithLink name="submission_destination" />
      <SubmissionDestination autofill={autofill} />
      <div className="u-ta-c u-mt30">
        <Button primary onClick={handleSubmit} disabled={pristine || submitting}>
          更新
        </Button>
      </div>
    </div>
  </form>
)

export const makeInitialValues = ({
  applicantInformation,
  contactInformation,
  certificateFile,
  submissionDestination,
  laborConsultant,
}) => ({
  applicant_information: toFormValues(applicantInformation),
  contact_information: toFormValues(contactInformation),
  certificate_file: toFormValues(certificateFile),
  submission_destination: toFormValues(submissionDestination),
  labor_consultant: toFormValues(laborConsultant),
})

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(Base)
