import React from 'react'
import {connect} from 'react-redux'
import {Field} from 'redux-form'
import {GroupsSelectBox, MultiTagFilteringFieldRow} from 'jbc-front/components/SearchForm'
import styles from 'jbc-front/components/SearchForm.scss'
import {fetchSelector} from 'actions'
import {employmentStatuses as allStatuses} from 'employees/list/SearchForm'

const employmentStatuses = allStatuses.filter((status) => status.id !== 'all')

const AdditionalSearchFields = ({groups = [], positions = [], employmentTypes = [], occupations = []}) => (
  <div className={styles.details}>
    <React.Fragment>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>グループ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field name="groups" component={GroupsSelectBox} options={groups} />
          </div>
        </div>
      </div>
      <MultiTagFilteringFieldRow header="役職" fieldName="positions" list={positions} />
      <MultiTagFilteringFieldRow header="雇用形態" fieldName="employment_types" list={employmentTypes} />
      <MultiTagFilteringFieldRow header="職種" fieldName="occupations" list={occupations} />
      <MultiTagFilteringFieldRow header="在籍状況" fieldName="employment_status" list={employmentStatuses} />
    </React.Fragment>
  </div>
)

const mapStateToProps = (state) => ({
  groups: fetchSelector(state, 'selections').groups,
  positions: fetchSelector(state, 'selections').positions,
  employmentTypes: fetchSelector(state, 'selections').employmentTypes,
  occupations: fetchSelector(state, 'selections').occupations,
})
export default connect(mapStateToProps)(AdditionalSearchFields)
