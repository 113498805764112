import {useAuthAxios} from 'hooks/api/useAuthAxios'
// @ts-ignore
import postWithFile from 'api/postWithFile'

type Params = {
  file: File
}

export const useUploadGroupsCsv = () => {
  const authAxios = useAuthAxios()
  return async (params: Params) => {
    const path = '/api/import_update_groups_csv/'
    return postWithFile(params, ['file'], authAxios.post.bind(authAxios, path))
  }
}
