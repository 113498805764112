import React, {Component} from 'react'
import ActionButton from 'jbc-front/components/ActionButton'
import {Fileup, Delete, Edit, Strage, MinusCircle, ChangeRow, Notification} from 'jbc-front/components/icons'
import DateTime from 'react-datetime'
import styles from 'employees/PersonnelHistory.scss'
import {recordDisplay, toBooleanProps, toFormValues} from 'utils'
import moment from 'moment'
import {reduxForm, FormSection, FieldArray, autofill as autofillForm, Field, reset, formValueSelector} from 'redux-form'
import Master from 'Master'
import {connect} from 'react-redux'
import {groupFullName} from 'employees/form/EmploymentInfomation'
import _ from 'lodash'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {handlerNestFormResponse} from 'libs/errorHandler'
import Select from 'react-select'
import {dateFieldProps} from 'jbc-front/components/Form'
import {Link} from 'react-router-dom'
import Dropzone from 'react-dropzone'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import classNames from 'classnames'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'VisaHistory'
const selector = formValueSelector(formName)
const autofill = autofillForm.bind(null, formName)

export const noVisaUntilType = (visaType) =>
  [26, 40] /* 永住者 高度専門職2号 */
    .includes(visaType?.value)

const dispatchTypes = [
  {value: 'dispatch', label: '1. 派遣・請負労働者として主として当該事業所以外で就労する場合'},
  {value: 'other', label: '2. 1に該当しない場合'},
]

const visaCodeLength = 12

const visaCodeInvalidMatcher = /[^a-zA-Z\d]/

const renderDateTimeTd = ({input, meta: {touched, error}, ...rest}) => (
  <td className={classNames(styles.date, {invalid: touched && error})}>
    <DateTime
      value={input.value}
      style={{width: '85px'}}
      onChange={(param) => input.onChange(param)}
      onBlur={() => {
        input.onBlur(input.value)
      }}
      dateFormat={'YYYY/MM/DD'}
      timeFormat={false}
      {...rest}
    />
  </td>
)

const renderSelectTd = ({input, meta: {touched, error}, options}) => {
  return (
    <td className={classNames(styles.select, {invalid: touched && error})}>
      <Select
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
        options={options}
        placeholder={recordDisplay()}
        simpleValue
        clearable={false}
        autosize={false}
      />
    </td>
  )
}

const renderVisaCodeTd = ({input, meta: {touched, error}}) => {
  return (
    <td className={classNames(styles.visaCode, {invalid: touched && error})}>
      <input type="text" name={input.name} value={input.value} onChange={input.onChange} />
    </td>
  )
}

export class renderFileTd extends Component {
  static defaultProps = {
    accepted: '*',
    multiple: false,
  }

  onDrop = (acceptedFiles) => {
    const {
      input: {onChange},
    } = this.props
    return onChange(acceptedFiles[0])
  }

  handleBlur = () => {
    const {
      input: {onBlur, value},
    } = this.props
    return onBlur(value)
  }

  clear = (e) => {
    e.stopPropagation()
    const {
      input: {onChange},
    } = this.props
    return onChange(null)
  }

  render() {
    const {
      input: {value},
      name,
      accept,
      multiple,
    } = this.props

    return (
      <td>
        <Dropzone
          accept={accept}
          onBlur={this.handleBlur}
          onDrop={this.onDrop}
          name={name}
          multiple={multiple}
          className="dropzone-input"
        >
          <div className={styles.icnTextarea}>
            {value ? (
              <React.Fragment>
                <div className={styles.fileInput}>
                  {value.url ? (
                    <a className="u-txt-link" onClick={(e) => e.stopPropagation()} href={value.url} download>
                      {value.filename}
                    </a>
                  ) : (
                    value.name
                  )}
                </div>
                <i onClick={this.clear}>
                  <Delete size={20} />
                </i>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <input type="text" readOnly placeholder="ファイル選択" value="" />
                <i>
                  <Fileup />
                </i>
              </React.Fragment>
            )}
          </div>
        </Dropzone>
      </td>
    )
  }
}

const masters = ['visaTypes']

const Content = connect((state) => ({visaTypes: state.master.visaTypes}))(({histories, visaTypes = []}) => (
  <tbody>
    {histories.length > 0 ? (
      histories.map((history) => (
        <tr key={history.id}>
          <td className={styles.date}>
            <span>
              {recordDisplay(
                _.get(
                  visaTypes.find((type) => type.id === history.visa_type_id),
                  'name'
                )
              )}
            </span>
          </td>
          <td>{recordDisplay(history.visa_until && moment(history.visa_until).format('YYYY/MM/DD'))}</td>
          <td>{recordDisplay.boolean(history.has_extra_action_permission)}</td>
          <td>{recordDisplay.option(history.dispatch_type, dispatchTypes)}</td>
          <td>{recordDisplay(history.visa_code)}</td>
          <td>{recordDisplay.file(history.residence_card)}</td>
          <td>
            <Delete size={15} className={styles.inactive} />
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={8}>在留資格履歴がまだありません</td>
      </tr>
    )}
  </tbody>
))

const renderEditContent = ({fields, visaTypes = []}) => {
  const visaTypeOptions = visaTypes.map((type) => ({value: `${type.id}`, label: type.name}))
  return (
    <tbody>
      {fields.map((field, index) => (
        <FormSection name={field} component="tr" key={index}>
          <Field component={renderSelectTd} name="visa_type_id" options={visaTypeOptions} />
          <Field component={renderDateTimeTd} {...dateFieldProps} name="visa_until" />
          <Field component={renderSelectTd} name="has_extra_action_permission" {...toBooleanProps} />
          <Field component={renderSelectTd} name="dispatch_type" options={dispatchTypes} />
          <Field component={renderVisaCodeTd} name="visa_code" />
          <Field component={renderFileTd} name="residence_card" />
          <td>
            <Delete size={15} onClick={() => fields.remove(index)} className={styles.active} />
          </td>
        </FormSection>
      ))}
    </tbody>
  )
}

renderEditContent.defaultProps = {
  positions: [],
  occupations: [],
  employmentTypes: [],
  groups: [],
}

const EditContent = connect(
  (state) => ({
    visaTypes: state.master.visaTypes,
  }),
  (dispatch) => ({
    setId: (index, field, data, isGroup) => (e, value) => {
      const current =
        value && _.find(data, isGroup ? (group) => groupFullName(group, data) === value : ({name}) => name === value)
      if (current) {
        dispatch(autofill(`visa_histories[${index}].${field}`, current.id))
      } else if (!value) {
        dispatch(autofill(`visa_histories[${index}].${field}`, null))
      }
    },
  })
)(renderEditContent)

const renderHistories = ({
  fields,
  editing,
  handleSubmit,
  handleCancel,
  handleEdit,
  visaHistories = [],
  employee = {},
  handleAlertOff,
  sendingAlertOff,
}) => (
  <div>
    <div className={styles.buttonWrap}>
      {editing ? (
        <div className={styles.editButton}>
          <AsyncTaskConsumer>
            {({taskRunningProps}) => (
              <ActionButton onClick={handleSubmit} icon={<Strage size={15} />} {...taskRunningProps}>
                保存
              </ActionButton>
            )}
          </AsyncTaskConsumer>
          <ActionButton onClick={handleCancel} icon={<MinusCircle size={15} />}>
            キャンセル
          </ActionButton>
          <ActionButton
            onClick={() => fields.push(_.omit(fields.get(fields.length - 1), ['id', 'residence_card']))}
            icon={<ChangeRow size={15} />}
          >
            行を追加
          </ActionButton>
        </div>
      ) : (
        <div className={styles.editButton}>
          <ActionButton icon={<Edit size={15} />} onClick={handleEdit}>
            編集
          </ActionButton>
        </div>
      )}
      <div className={styles.alert}>
        <FormSection name="employee">
          <label className="input-label">
            <Field
              component="input"
              type="checkbox"
              className="m-checkbox-input"
              disabled={!editing}
              name="is_visa_alert_on"
              id="is_visa_alert_on"
            />
            <span className="m-checkbox-parts">&nbsp;</span>
            在留期間満了日
          </label>
          <Field
            className={styles.alertInput}
            component="input"
            type="text"
            maxLength={3}
            name="visa_alert_days_before_visa_until"
            disabled={!editing}
          />
          <label htmlFor="is_visa_alert_on">日前にアラート</label>
        </FormSection>
        <div className="u-ta-r">
          {employee.has_visa_alert && (
            <ActionButton className={styles.alertOff} onClick={handleAlertOff} disabled={sendingAlertOff}>
              <Notification />
              アラートをオフにする
            </ActionButton>
          )}
        </div>
      </div>
    </div>
    <div className="l-overflow-scroll">
      <table className={styles.historyTable}>
        <thead>
          <tr>
            <th style={{width: '211px'}}>在留資格</th>
            <th style={{width: '103px'}}>在留期間満了日</th>
            <th style={{width: '140px'}}>在留外活動許可の有無</th>
            <th style={{width: '256px'}}>派遣・請負就労区分</th>
            <th style={{width: '138px'}}>在留カード番号</th>
            <th style={{width: '307px'}}>在留カード</th>
            <th style={{width: '45px'}} />
          </tr>
        </thead>
        {editing ? <EditContent fields={fields} /> : <Content histories={visaHistories} />}
      </table>
    </div>
  </div>
)

const Histories = connect(
  (state) => ({
    editing: selector(state, '_editing'),
    visaHistories: fetchSelector(state, 'visa_histories').data,
    employee: fetchSelector(state, 'employee').data,
  }),
  (dispatch) => ({
    handleCancel() {
      dispatch(reset(formName))
    },
    handleEdit() {
      dispatch(autofill('_editing', true))
    },
  })
)(renderHistories)

const validate = (values, {visaTypes}) => {
  if (!values.visa_histories) {
    return undefined
  }
  const errors = {}
  values.visa_histories.forEach((history, index) => {
    if (history.visa_until) {
      const dateOn = moment(history.visa_until, 'YYYY/MM/DD')
      if (!dateOn.isValid) {
        _.set(errors, `visa_histories[${index}].visa_until`, '在留期間満了日のデータ形式が正しくありません')
      }
      for (let i = 0; i < index; i++) {
        if (
          values.visa_histories[i].visa_until &&
          moment(values.visa_histories[i].visa_until, 'YYYY/MM/DD').isSame(dateOn, 'day')
        ) {
          _.set(errors, `visa_histories[${index}].visa_until`, '在留期間満了日が重複しています')
          break
        }
      }
    } else if (
      history.visa_type_id &&
      !noVisaUntilType(visaTypes?.find((visaType) => visaType.id == history.visa_type_id))
    ) {
      _.set(errors, `visa_histories[${index}].visa_until`, '在留期間満了日を入力してください')
    }

    if (history.residence_card && history.residence_card.name) {
      const match = history.residence_card.name.match(/(.*)(\.[^.]+$)/)

      if (_.isArray(match) && match[1]) {
        // 半角文字（半角カナを含む）の場合は1文字、全角の場合は2文字として扱う
        const length = _.sum(_.map(match[1], (value) => (value.match(/[\x20-\x7E\uFF65-\uFF9F]/) ? 1 : 2)))

        if (length > 80) {
          _.set(
            errors,
            `visa_histories[${index}].residence_card`,
            '在留カードのファイル名は半角80文字、全角40文字まで可能です'
          )
        }
      }
    }

    if (history.visa_code) {
      if (history.visa_code.length != visaCodeLength) {
        _.set(errors, `visa_histories[${index}].visa_code`, `在留カード番号は${visaCodeLength}桁で入力してください`)
      } else if (history.visa_code.match(visaCodeInvalidMatcher)) {
        _.set(errors, `visa_histories[${index}].visa_code`, '在留カード番号は半角英数字で入力してください')
      }
    }
  })
  const alertOn = _.get(values, 'employee.is_visa_alert_on')
  const dayBefore = _.get(values, 'employee.visa_alert_days_before_visa_until')
  if (alertOn && !dayBefore) {
    _.set(errors, 'employee.visa_alert_days_before_visa_until', 'アラートの日数を入力してください')
  }
  if (dayBefore) {
    if (!dayBefore.match(/^\d+$/)) {
      _.set(errors, 'employee.visa_alert_days_before_visa_until', 'アラートの日数を数値で入力してください')
    }
  }
  return _.isEmpty(errors) ? undefined : errors
}

const onSubmitFail = (errors, dispatch) => {
  if (errors.visa_histories) {
    errors.visa_histories.forEach((history) => {
      if (history) {
        _.each(history, (msg) => {
          let name = history.visa_until ? '在留期間満了日' : ''
          dispatch(notifyError(msg.startsWith(name) ? msg : `${name}${msg}`))
        })
      }
    })
  }
  if (_.get(errors, 'employee.visa_alert_days_before_visa_until')) {
    dispatch(notifyError(errors.employee.visa_alert_days_before_visa_until))
  }
}

const Form =
  (({handleSubmit, sendingAlertOff, handleAlertOff}) => (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="visa_histories"
        component={Histories}
        handleSubmit={handleSubmit}
        handleAlertOff={handleAlertOff}
        sendingAlertOff={sendingAlertOff}
      />
    </form>
  ))
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
    onSubmitFail,
  })
  |> connect((state) => ({
    visaTypes: state.master.visaTypes,
  }))

class VisaHistory extends Component {
  static defaultProps = {
    visaHistories: [],
  }

  state = {sendingAlertOff: false}

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      dispatch,
    } = this.props
    dispatch(actionCreators.fetchData('visa_histories', api.createWithAuth(token).employees.visaHistories.list(id)))
    dispatch(actionCreators.fetchData('employee', api.createWithAuth(token).employees.get(id, ['visa_alert'])))
  }

  componentWillUnmount() {
    const {dispatch} = this.props
    dispatch(actionCreators.fetchDestroy('visa_histories'))
    dispatch(actionCreators.fetchDestroy('employee'))
  }

  handleSubmit = async (values) => {
    const {
      match: {
        params: {id},
      },
      token,
      dispatch,
    } = this.props
    try {
      const res = await api
        .createWithAuth(token)
        .employees.visaHistories.updateAll(id, values, true)
        .catch(handlerNestFormResponse('visa_histories'))
      dispatch({type: 'FETCH_SUCCESSED', meta: {name: 'visa_histories'}, payload: {data: res.data}})
      dispatch(reset(formName))
      dispatch(actionCreators.fetchData('employee', api.createWithAuth(token).employees.get(id, ['visa_alert'])))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  handleAlertOff = async () => {
    const {
      match: {
        params: {id},
      },
      token,
      dispatch,
    } = this.props
    try {
      this.setState({sendingAlertOff: true})
      await api.createWithAuth(token).employees.visaHistories.alertOff(id)
      dispatch(
        actionCreators.fetchData(
          'employee',
          api
            .createWithAuth(token)
            .employees.get(id, ['visa_alert'])
            .finally(() => this.setState({sendingAlertOff: false}))
        )
      )
    } catch (err) {
      dispatch(asyncError(err))
      this.setState({sendingAlertOff: false})
    }
  }

  render() {
    const {
      visaHistories,
      employee,
      match: {
        params: {id},
      },
    } = this.props
    return (
      <div>
        <Master masters={masters} />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">{recordDisplay.fullName(employee)}さんの在留資格履歴</h1>
        </div>
        <div className="l-contents-wrap">
          <div className={styles.wrap}>
            <div className="l-breadcrumb u-mb20">
              <Link to="/employees" className="l-breadcrumb-link">
                従業員一覧
              </Link>
              <Link to={`/employees/${id}`} className="l-breadcrumb-link">
                従業員情報
              </Link>
              <span className="l-breadcrumb-here">在留資格履歴</span>
            </div>
            <Form
              initialValues={{
                visa_histories: visaHistories.length > 0 ? visaHistories.map(toFormValues) : [{}],
                _editing: false,
                employee: toFormValues(
                  _.pick(employee, ['id', 'is_visa_alert_on', 'visa_alert_days_before_visa_until'])
                ),
              }}
              onSubmit={this.handleSubmit}
              handleAlertOff={this.handleAlertOff}
              sendingAlertOff={this.state.sendingAlertOff}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  visaHistories: fetchSelector(state, 'visa_histories').data,
  employee: fetchSelector(state, 'employee').data,
}))(VisaHistory)
