import {FC, useState} from 'react'

// jbc-front
import {DeleteSquare} from 'jbc-front/components/icons'

import styles from './DeleteTd.scss'

interface DeleteIconProps {
  disabled: boolean
  onClick: () => void
}

export const DeleteTd: FC<DeleteIconProps> = ({disabled, onClick}) => {
  const className = disabled ? styles.disabled : styles.active
  const [isHover, setIsHover] = useState<boolean>(false)
  return (
    <td
      className={className}
      onClick={(e) => {
        onClick()
        e.stopPropagation()
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <DeleteSquare className={className} />
      {disabled && isHover && <div className={styles.note}>処理中のため削除できません</div>}
    </td>
  )
}
