import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {autofill} from 'redux-form'
import {useFormName, useFormSectionPrefix} from 'hooks/reduxForm'
import {useTaxOffices} from './useTaxOffices'

// @ts-expect-error
import {prefectureOptions, withPrefix} from 'FormFields'
// @ts-expect-error
import {BoxDouble, SelectField, Label} from 'jbc-front/components/Form'

interface TaxOfficeProps {
  prefectureId?: string
}

export const TaxOffice: FC<TaxOfficeProps> = ({prefectureId}) => {
  const dispatch = useDispatch()
  const formName = useFormName()
  const sectionPrefix = useFormSectionPrefix()
  const {data} = useTaxOffices(prefectureId || '', !prefectureId)
  const taxOfficeOptions = data?.taxOffices?.map(({id, name}) => ({value: id, label: name})) || []

  return (
    <div>
      <Label text="税務署" />
      <BoxDouble>
        <SelectField
          name="tax_office_prefecture_id"
          label="都道府県"
          options={prefectureOptions}
          onChange={(_event, value, previousValue) => {
            if (value !== previousValue) {
              dispatch(autofill(formName, withPrefix(sectionPrefix, 'tax_office_id'), null))
            }
          }}
        />
        <SelectField name="tax_office_id" label="管轄の税務署" options={taxOfficeOptions} />
      </BoxDouble>
    </div>
  )
}
