import React from 'react'
import Flow from 'procedures/Flow'
import _ from 'lodash'

export const makeFlow = function* (employee) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const employmentInsuranceJoined = _.get(employee, 'employment_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'its') {
      yield 'R8'
    } else {
      yield 'R7'
    }
  }
  if (employmentInsuranceJoined) {
    yield 'R58'
  }
}

const ResignFlow = ({location, match}) => (
  <Flow {...{makeFlow, location, match, embed: 'exist_procedure_change_name'}} />
)

export default ResignFlow
