import {useState, useCallback} from 'react'
import useReactRouter from 'use-react-router'

type ListType = 'list' | 'card'

const storageKey = (pathname: string, userId: string) => `${pathname.replace(/\//g, '')}#${userId}__employee_list_type`

const getSavedEmployeeListType = (pathname: string, userId: string): ListType | undefined => {
  const type = window.localStorage?.getItem(storageKey(pathname, userId))
  if (type && type !== '') {
    return type as ListType
  }
  return undefined
}

const saveEmployeeListType = (pathname: string, userId: string, listType: ListType) => {
  window.localStorage?.setItem(storageKey(pathname, userId), listType)
}

export const useEmployeeListType = (userId: string, defaultValue: ListType) => {
  const {
    location: {pathname},
  } = useReactRouter()
  const [current, setCurrent] = useState(getSavedEmployeeListType(pathname, userId) || defaultValue)
  const setValue = useCallback(
    (newValue: ListType) => {
      setCurrent(newValue)
      saveEmployeeListType(pathname, userId, newValue)
    },
    [pathname, userId]
  )
  return [current, setValue]
}
