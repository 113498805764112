import React from 'react'

import {Section, DateField, Label, CheckboxField, TextField, RadioField, Error} from 'jbc-front/components/Form'

import {
  reduxForm,
  formValueSelector,
  FormSection,
  Field,
  autofill as autofillForm,
  isSubmitting as isSubmittingForm,
} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {connect} from 'react-redux'
import _ from 'lodash'
import {bindActionCreators} from 'redux'
import {recordDisplay} from 'utils'
import EmployeeName from 'procedures/EmployeeName'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import ReviewComments from 'components/ReviewComments'

export const formName = 'changeDependents'
export const selector = formValueSelector(formName)
export const autofill = autofillForm.bind(null, formName)
export const isSubmitting = isSubmittingForm(formName)

const reasonTypesCommon = [
  {
    value: 'over_75',
    label: '75歳到達',
    note: '75歳になると「後期高齢者医療制度」に加入し、そこで被保険者となるため、健康保険の資格を喪失します。被扶養者異動日（資格喪失日）は75歳の誕生日です。',
  },
  {
    value: 'handicap',
    label: '障害認定',
    note: '家族が 65 歳～74 歳で一定の障害があると広域連合の障害認定を受けた方は「後期高齢者医療制度」の被保険者となるため、健康保険の資格を喪失します。',
  },
]

const reasonTypes = [
  {value: 'passed_away', label: '死亡'},
  {value: 'found_job', label: '就職'},
  {value: 'increase_in_income', label: '収入増加'},
  ...reasonTypesCommon,
  {value: 'others', label: 'その他'},
]

const reasonTypesKyokaiSpouse = [
  {value: 'passed_away', label: '死亡'},
  {value: 'divorce', label: '離婚'},
  {value: 'increase_in_income', label: '就職・収入増加'},
  ...reasonTypesCommon,
  {value: 'others', label: 'その他'},
]

const reasonTypesOthersSpouse = [
  {value: 'passed_away', label: '死亡'},
  {value: 'divorce', label: '離婚'},
  {value: 'increase_in_income', label: '収入増加'},
  ...reasonTypesCommon,
  {value: 'others', label: 'その他'},
]

const getReasonTypes = (healthInsuranceType, relation_type) => {
  if (relation_type === 'spouse') {
    return healthInsuranceType === 'kyokai' ? reasonTypesKyokaiSpouse : reasonTypesOthersSpouse
  } else {
    return reasonTypes
  }
}

const Form = ({
  submitting,
  handleSubmit,
  dependentValues,
  healthInsuranceType,
  otherButtons,
  employee,
  comments,
  submitText = '次へ',
}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">扶養削除</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <ReviewComments comments={comments} />
      <Section title="被扶養者異動日">
        <DateField
          name="date_on"
          label="被扶養者でなくなった日"
          required
          note="被扶養者が就職した時は「就職した日」（健康保険の資格取得日）を、離婚した時は「離婚日」を、死亡した時は「死亡日の翌日」を入力します。"
        />
      </Section>
      {_.isEmpty(dependentValues) ? (
        <Section title="被扶養者情報">該当従業員の扶養者情報がありません</Section>
      ) : (
        <Section title="被扶養者情報">
          <Label text="扶養から外れる人にチェックを入れてください" required />
          <Field component={Error} name="will_remove_dependents" label="被扶養者情報" />
          <LabelMapper name="will_remove_dependents" label="被扶養者情報" />
          {dependentValues.map((dependent, index) => (
            <FormSection name={`dependents[${index}]`} key={dependent.id}>
              <CheckboxField name="_will_remove" label={`${recordDisplay.fullName(dependent)}`} />
              {(healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') &&
                dependentValues[index] &&
                dependentValues[index]._will_remove && (
                  <RadioField
                    name="remove_reason_type"
                    key="remove_reason_type"
                    label={`扶養から外れた理由（${recordDisplay.fullName(dependent)}）`}
                    options={getReasonTypes(healthInsuranceType, dependentValues[index].relation_type)}
                    note="「就職」「離婚」「死亡」「収入増加」など24文字以内で入力してください。"
                    required
                  />
                )}
              {(healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') &&
                dependentValues[index] &&
                dependentValues[index]._will_remove &&
                dependentValues[index].remove_reason_type === 'others' && (
                  <TextField key="reason" name="remove_reason_detail" label="理由詳細" required />
                )}
              {(healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') &&
                dependentValues[index] &&
                dependentValues[index]._will_remove &&
                dependentValues[index].remove_reason_type === 'passed_away' && (
                  <DateField name="died_on" label="死亡日" required />
                )}
              {healthInsuranceType === 'its' && dependentValues[index] && dependentValues[index]._will_remove && (
                <TextField
                  name="remove_reason_detail"
                  label={`扶養から外れた理由（${recordDisplay.fullName(dependent)}）`}
                  note="「就職」「離婚」「死亡」「収入増加」など24文字以内で入力してください。"
                  required
                />
              )}
            </FormSection>
          ))}
        </Section>
      )}
      <div className="u-ta-c u-mt30">
        {otherButtons}
        <AsyncTaskConsumer>
          {({taskRunningProps}) => (
            <Button
              primary
              onClick={handleSubmit}
              disabled={submitting || _.isEmpty(dependentValues)}
              {...taskRunningProps}
            >
              {submitText}
            </Button>
          )}
        </AsyncTaskConsumer>
      </div>
    </div>
  </form>
)

const validate = (values) => {
  if (
    !values._allow_empty &&
    (_.isEmpty(values.dependents) || !_.some(values.dependents, (dependent) => dependent && dependent._will_remove))
  ) {
    return {
      will_remove_dependents: 'のいずれかにチェックを入れてください',
    }
  }
  return {}
}

export default connect(
  (state) => ({
    dependentValues: selector(state, 'dependents'),
  }),
  bindActionCreators.bind(null, {autofill})
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    validate,
  })(Form)
)
