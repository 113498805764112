import {actionCreators} from 'actions'
import {connectPager} from 'components/paginators'
import {connectPerPage} from 'components/PerPage'
import {makeListData} from 'components/ListData'
import {getQueryDiff} from 'settings/usersClients/reducers'
import api from 'api'

const querySelector = (state) => state.settings.usersClients.query
const setQuery = actionCreators.settings.usersClients.setQuery

export const Pager = connectPager(querySelector, setQuery)
export const PerPage = connectPerPage(querySelector, setQuery)
export const loadList = (token, query, embed, params) => api.createWithAuth(token).usersClients.list(query, params)
export const ListData = makeListData({
  querySelector,
  setQuery,
  getQueryDiff,
  loadData({token, query, params}) {
    return actionCreators.fetchData('users_clients', api.createWithAuth(token).usersClients.list(query, params))
  },
  destroy: actionCreators.fetchDestroy.bind(null, 'users_clients'),
})
export default ListData
