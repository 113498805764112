import {FC, useState} from 'react'
import {Preview} from 'jbc-front/components/icons'
import {Document} from '../../../query'
import {useDownloadUploadedDocument} from 'hooks/api/uploadedDocuments/useDownloadUploadedDocument'
import {PdfModal} from 'jbc-front/components/presenters/ui/PdfModal'
import ActionButton from 'jbc-front/components/ActionButton'
import {Print, Download} from 'jbc-front/components/icons'
import {PdfPreview} from './PdfPreview'
import styles from './PreviewModal.scss'

type PreviewModalProps = {
  document: Document
}

export const UploadedDocumentPreviewModal: FC<PreviewModalProps> = ({document}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const url = `/api/uploaded_documents/${document.id}`

  const downloadUploadedDocument = useDownloadUploadedDocument()

  const handleDownload = async () => {
    await downloadUploadedDocument(String(document.id))
  }

  const handlePrint = () => {
    window.open(`${url}?disposition=inline`, '', 'toolbar=0,menubar=0,location=0')
    window.addEventListener('load', () => window.print())
  }

  return (
    <>
      <i className="u-cur-pointer" onClick={handleOpen}>
        <Preview />
      </i>

      {isOpen && (
        <PdfModal isOpen={isOpen}>
          <PdfModal.Header className={styles.header}>
            <PdfModal.Title className={styles.title}>{document.title}</PdfModal.Title>
            <PdfModal.ToolContainer className={styles.tools} onClose={handleClose}>
              <ActionButton primary icon={<Print size={15} />} onClick={handlePrint}>
                印刷
              </ActionButton>

              <ActionButton primary icon={<Download size={15} />} onClick={handleDownload}>
                ダウンロード
              </ActionButton>
            </PdfModal.ToolContainer>
          </PdfModal.Header>
          <PdfPreview file={url} />
        </PdfModal>
      )}
    </>
  )
}
