import React, {Component} from 'react'
import Modal from 'react-modal'
import {Close} from 'jbc-front/components/icons'
import styles from 'myNumber/MyNumberModal.scss'
import {recordDisplay} from 'utils'
import Button from 'jbc-front/components/Button'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {Dl, Dd, Dt} from 'components/SecondaryPanel'
import {Panel, Item, Headding} from 'components/TertiaryPanel'
import {Edit} from 'jbc-front/components/icons'
import Form, {makeInitialValues, submit, isSubmitting} from 'myNumber/Form'
import RModal from 'jbc-front/components/Modal'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import {asyncError} from 'store/actions/asyncError'

export const myNumberTypes = {
  employee: 1,
  dependent: 2,
  businessPartner: 3,
}

const FileLink = ({file}) => {
  if (file && file.url) {
    return (
      <a href={file.url} download className="u-txt-link">
        {file.filename}
      </a>
    )
  }
  return <p>{recordDisplay()}</p>
}

class MyNumberModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: false,
      showDeleteModal: false,
    }
  }

  static defaultProps = {
    myNumber: {},
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        document.body.style.overflow = 'hidden'
        const {loadMynumber} = nextProps
        loadMynumber(nextProps)
        this.setState({editing: false})
      } else {
        nextProps.destroy()
        document.body.style.overflow = ''
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
    this.props.destroy()
  }

  handleSubmit = async (values) => {
    const {type, employee, dependent, token, businessPartner, loadMynumber, dispatch} = this.props
    try {
      if (type === myNumberTypes.employee) {
        await api.createWithAuth(token).employees.myNumber.create(employee.id, values)
      } else if (type === myNumberTypes.dependent) {
        await api.createWithAuth(token).employees.dependents.myNumber.create(employee.id, dependent.id, values)
      } else if (type === myNumberTypes.businessPartner) {
        await api.createWithAuth(token).businessPartners.myNumber.create(businessPartner.id, values)
      }
      await loadMynumber(this.props)
      this.setState({editing: false})
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  handleDeleteClick = () => {
    const {deleteMyNumber, loadMynumber} = this.props
    deleteMyNumber(this.props).then(() => {
      loadMynumber(this.props)
      this.setState({showDeleteModal: false})
    })
  }

  render() {
    const {isOpen, hide, submitting, employee, myNumber, type, dependent, submit, businessPartner} = this.props
    const idFiles = [0, 1].map((idx) => myNumber[`id_image_${idx}`]).filter((file) => file && file.url)
    return (
      <Modal className={styles.modal} isOpen={isOpen} overlayClassName={styles.overlay} contentLabel="Modal">
        <div className={styles.header}>
          <div className={styles.title}>
            {type === myNumberTypes.dependent
              ? recordDisplay.fullName(dependent)
              : type === myNumberTypes.employee
              ? recordDisplay.fullName(employee)
              : recordDisplay(businessPartner && businessPartner.name)}
            さんへのマイナンバー
          </div>
          <div className={styles.tools}>
            <Close className={styles.close} onClick={() => this.props.hide()} />
          </div>
        </div>
        {isOpen && !this.state.editing && (
          <div className={styles.body}>
            <Panel>
              <a
                onClick={() => {
                  this.setState({editing: true})
                }}
              >
                <Headding icon={<Edit />}>マイナンバー</Headding>
              </a>
              <Item>
                <Dl>
                  <Dt>マイナンバー</Dt>
                  <Dd>{myNumber.my_number || '未取得'}</Dd>
                  <Dt>番号確認書類</Dt>
                  <Dd>
                    <FileLink file={myNumber.my_number_image} />
                  </Dd>
                  <Dt>身元確認書類</Dt>
                  <Dd>
                    {recordDisplay(
                      idFiles.length > 0 &&
                        idFiles.map((file, index) => (
                          <React.Fragment key={index}>
                            <FileLink file={file} />
                            <br />
                          </React.Fragment>
                        ))
                    )}
                  </Dd>
                </Dl>
              </Item>
              {myNumber.id && (
                <div className="u-ta-c u-mt20">
                  <AsyncTaskConsumer>
                    {({taskRunningProps}) => (
                      <Button
                        onClick={() => {
                          this.setState({showDeleteModal: true})
                        }}
                        {...taskRunningProps}
                      >
                        マイナンバー削除
                      </Button>
                    )}
                  </AsyncTaskConsumer>
                </div>
              )}
            </Panel>
          </div>
        )}
        {isOpen && this.state.editing && (
          <div className={styles.body}>
            <Form
              onCancelClick={() => {
                this.setState({editing: false})
              }}
              initialValues={makeInitialValues(myNumber)}
              onSubmit={this.handleSubmit}
              isBusinessPartner={type === myNumberTypes.businessPartner}
            />
          </div>
        )}
        <div className={styles.closeBottom}>
          {this.state.editing ? (
            [
              <Button
                onClick={() => {
                  this.setState({editing: false})
                }}
                disabled={submitting}
                key="cancel"
              >
                キャンセル
              </Button>,
              <AsyncTaskConsumer key="save">
                {({taskRunningProps}) => (
                  <Button primary onClick={submit} disabled={submitting} {...taskRunningProps}>
                    保存する
                  </Button>
                )}
              </AsyncTaskConsumer>,
            ]
          ) : (
            <Button onClick={hide} disabled={submitting}>
              閉じる
            </Button>
          )}
        </div>
        <RModal
          header="削除の確認"
          body="マイナンバー情報を削除しますか"
          onSubmit={this.handleDeleteClick}
          isOpen={this.state.showDeleteModal}
          submit="はい"
          cancel="いいえ"
          hideModal={() => this.setState({showDeleteModal: false})}
        />
      </Modal>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    myNumber: fetchSelector(state, 'my_number').data,
    submitting: isSubmitting(state),
  }),
  (dispatch) => ({
    dispatch,
    submit() {
      dispatch(submit())
    },
    loadMynumber({type, employee, dependent, token, businessPartner}) {
      if (type === myNumberTypes.employee) {
        dispatch(actionCreators.fetchData('my_number', api.createWithAuth(token).employees.myNumber.get(employee.id)))
      } else if (type === myNumberTypes.dependent) {
        dispatch(
          actionCreators.fetchData(
            'my_number',
            api.createWithAuth(token).employees.dependents.myNumber.get(employee.id, dependent.id)
          )
        )
      } else if (type === myNumberTypes.businessPartner) {
        dispatch(
          actionCreators.fetchData(
            'my_number',
            api.createWithAuth(token).businessPartners.myNumber.get(businessPartner.id)
          )
        )
      }
    },
    destroy() {
      dispatch(actionCreators.fetchDestroy('my_number'))
    },
    async deleteMyNumber({type, employee, dependent, token, businessPartner}) {
      try {
        if (type === myNumberTypes.employee) {
          await api.createWithAuth(token).employees.myNumber.delete(employee.id)
        } else if (type === myNumberTypes.dependent) {
          await api.createWithAuth(token).employees.dependents.myNumber.delete(employee.id, dependent.id)
        } else if (type === myNumberTypes.businessPartner) {
          await api.createWithAuth(token).businessPartners.myNumber.delete(businessPartner.id)
        }
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(MyNumberModal)
