import React from 'react'
import EmployeesList from 'procedures/EmployeesList'

const ChooseEmployee = ({history}) => {
  const searchFormQueryConverter = (query) => ({
    ...query,
    _added_fields: [...query._added_fields, 'genders'],
    genders: ['woman'],
  })
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">従業員を選択してください</h1>
      </div>
      <div className="l-wrap-xl">
        <EmployeesList
          handleClick={(employeeId) => {
            history.push(`/start_maternity/detail?employee_id=${employeeId}`)
          }}
          searchFormAddedFieldHideValues={['genders']}
          searchFormQueryConverter={searchFormQueryConverter}
        />
      </div>
    </div>
  )
}
export default ChooseEmployee
