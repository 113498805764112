import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {Client} from 'types/store/client'
import type {CurrentClientForAdmin} from 'types/api/clients/currentClient'

const initialState = {
  current: {},
} as Client

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setCurrentClient(state, action: PayloadAction<CurrentClientForAdmin>) {
      state.current = action.payload
    },
    setFirstTimeFormAnswered(state) {
      state.current.first_time_form_answered = true
    },
  },
})

export const {setCurrentClient, setFirstTimeFormAnswered} = clientSlice.actions
export default clientSlice.reducer
