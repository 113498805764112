import React from 'react'
import {
  reduxForm,
  formValueSelector,
  submit as submitForm,
  FieldArray,
  FormSection,
  isPristine as isPristineForm,
  isSubmitting as isSubmittingForm,
  autofill as autofillForm,
} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'

import {Name, Birthday, Sex, Email, JoinedAt, EmployeeNumber} from 'FormFields'

import {Section, SelectField} from 'jbc-front/components/Form'
import {toOption, groupFullName, renderGroups, toSelectProps} from 'employees/form/EmploymentInfomation'
import Master from 'Master'
import {connect} from 'react-redux'
import _ from 'lodash'
import {AlertTriangle} from 'jbc-front/components/icons'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import {isOfficeAdminSelector} from 'utils'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'
import styles from './BaseForm.scss'
import {OfficeSelectorField} from '../features/offices/OfficeSelectorField'

const formName = 'employeeBaseForm'

const BasicInformation = ({emailRequired, employmentTypes, groups, isOfficeAdmin}) => {
  const hasBranchOffice = useHasBranchOffice()
  const groupById = _.keyBy(groups, 'id')
  return (
    <React.Fragment>
      <Section title="基本情報">
        <JoinedAt required />
        <Name required />
        <Birthday />
        <Sex />
        <Email required={emailRequired} />
        <FormSection name="personnel_history[0]">
          {hasBranchOffice && <OfficeSelectorField name="office_id" label="適用事業所" required={isOfficeAdmin} />}
          <SelectField name="employment_type_id" label="雇用形態" options={employmentTypes.map(toOption)} />
          <FieldArray
            name="groups"
            component={renderGroups}
            {...toSelectProps(
              groups.map((group) => ({...group, name: groupFullName(group, groupById)})),
              'グループ'
            )}
          />
          <LabelMapper name="groups" label="グループ" />
        </FormSection>
        <EmployeeNumber />
      </Section>
      <div className={styles.notes}>
        <div className={styles.icon}>
          <AlertTriangle size={36} />
        </div>
        <div className={styles.text}>
          従業員がジョブカン他サービスに既に登録されている場合、今回入力された
          <br />
          姓名、スタッフコードは、ご利用中のジョブカン他サービスにも自動反映されます。
        </div>
      </div>
    </React.Fragment>
  )
}

BasicInformation.defaultProps = {
  employmentTypes: [],
  groups: [],
  offices: [],
}

const BasicInformationContainer = connect((state) => ({
  employmentTypes: state.master.employmentTypes,
  groups: state.master.groups,
  token: state.auth.token,
  isOfficeAdmin: isOfficeAdminSelector(state),
}))(BasicInformation)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({handleSubmit, pristine, submitting, submitText, emailRequired, submitButtonOnClick, otherButtons}) => (
  <form onSubmit={handleSubmit}>
    <Master masters={['employmentTypes', 'groups']} />
    <div className="l-title-wrap">
      <h1 className="m-title-main">従業員登録</h1>
    </div>
    <div>
      <FormErrors />
      <BasicInformationContainer emailRequired={emailRequired} />
      <div className={styles.bottomButtonsArea}>
        <AsyncTaskConsumer>
          {({taskRunningProps}) => (
            <Button
              primary
              onClick={submitButtonOnClick || handleSubmit}
              disabled={pristine || submitting}
              {...taskRunningProps}
            >
              {submitText}
            </Button>
          )}
        </AsyncTaskConsumer>
        {otherButtons}
      </div>
    </div>
  </form>
))

export default Form

export const selector = formValueSelector(formName)
export const submit = submitForm.bind(null, formName)
export const isPristine = isPristineForm(formName)
export const isSubmitting = isSubmittingForm(formName)
export const autofill = autofillForm.bind(null, formName)

export const formatValues = (values, employmentTypes, office, groups) => {
  let {personnel_history: personnelHistory, ...employee} = values
  personnelHistory = {...(_.get(personnelHistory, '[0]') || {})}
  personnelHistory.date_on = values.joined_at
  personnelHistory.employment_type_name = (
    employmentTypes.find((type) => type.id == personnelHistory.employment_type_id) || {}
  ).name
  personnelHistory.office_name = office?.name
  const groupsValue = personnelHistory.groups || []
  groupsValue.forEach(({group_id: groupId}, index) => {
    personnelHistory[`group_${index}_id`] = groupId
    const group = groups.find((group) => group.id == groupId)
    if (group) {
      personnelHistory[`group_${index}_name`] = groupFullName(group, _.keyBy(groups, 'id'))
    }
  })
  return {employee, personnelHistory}
}
