import React from 'react'
import {connect} from 'react-redux'
import {Field} from 'redux-form'
import {fetchSelector} from 'actions'
import Select from 'react-select'
import DateTime from 'react-datetime'
import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import ActionButton from 'jbc-front/components/ActionButton'
import {Calendar} from 'jbc-front/components/icons'
import {employmentStatuses as allStatuses} from 'employees/list/SearchForm'
import styles from './SearchForm.scss'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'
import {ScalarOfficeSelector} from 'features/offices/OfficeSelector'

const employmentStatuses = allStatuses.filter((status) => status.id !== 'all')
const withNull = (lst) => [...(lst || []), {name: '未入力', id: 'null'}]
const withNullOpt = (lst) => [...(lst || []), {label: '未入力', value: 'null'}]

const convertArray = (strValue) => {
  const values = strValue.split(',')
  if (values[0] === '') {
    return []
  } else {
    return values
  }
}

const getOptionChildren = function* (options, currentValue) {
  for (const option of options) {
    if (option.parent_value == currentValue && currentValue !== 'null') {
      yield option.value
      yield* getOptionChildren(options, option.value)
    }
  }
}

export const addChildrenWhenParentClicked = (options, prevStrValue, currentStrValue) => {
  const [prevValues, currentValues] = [convertArray(prevStrValue), convertArray(currentStrValue)]
  const diff = _.difference(currentValues, prevValues)
  if (diff.length > 0) {
    const added = diff[0]
    return _.uniq([...currentValues, ...getOptionChildren(options, added)]).join(',')
  } else {
    return currentValues.join(',')
  }
}
const isMultiTagSelected = (values, id) => values.indexOf(id) >= 0
const takeNewMultiTagValues = (currentValues, item) =>
  isMultiTagSelected(currentValues, item.id)
    ? currentValues.filter((ele) => ele !== item.id)
    : [...currentValues, item.id]

const FilteringTag = ({children, selected, onClick}) => (
  <div className={styles.filteringTag}>
    <div className={selected ? styles.filteringTagSelected : styles.filteringTagDefault} onClick={onClick}>
      {children}
    </div>
  </div>
)

const MultiTagFilteringField = ({input: inputProps, header, list, takeNewValuesFunc, handleSubmit}) => (
  <div className={styles.detailsBodyItems}>
    {list.length > 0 ? (
      list.map((item) => (
        <FilteringTag
          key={item.id}
          selected={isMultiTagSelected(inputProps.value, item.id)}
          onClick={() => {
            const newValues = takeNewValuesFunc(inputProps.value, item)
            inputProps.onChange(newValues)
            inputProps.onBlur(newValues)
            if (handleSubmit) {
              handleSubmit()
            }
          }}
        >
          {item.name}
        </FilteringTag>
      ))
    ) : (
      <p>従業員の{header}が設定されていません</p>
    )}
  </div>
)

const OfficesSelectBox = ({input: inputProps}) => (
  <div className={styles.officesSelectBox}>
    <ScalarOfficeSelector
      multiple
      filterByCurrentOffices
      selectedIds={inputProps.value}
      onChange={(value) => {
        inputProps.onChange(value)
        inputProps.onBlur(value)
      }}
    />
  </div>
)

class GroupsSelectBox extends React.Component {
  render() {
    const {input: inputProps, options, ...rest} = this.props
    return (
      <div className={styles.groupsSelectBox}>
        <Select
          multi
          options={options}
          closeOnSelect={false}
          clearableValue={false}
          simpleValue
          placeholder="グループ選択"
          name={inputProps.name}
          value={inputProps.value}
          noResultsText="データが見つかりません"
          noOptionsMessage="データが見つかりません"
          onChange={(newValue) => {
            const value = addChildrenWhenParentClicked(options, inputProps.value, newValue)
            inputProps.onChange(value)
            inputProps.onBlur(value)
          }}
          {...rest}
        />
      </div>
    )
  }
}

class TagSelectBox extends React.Component {
  render() {
    const {input: inputProps, options, ...rest} = this.props
    return (
      <div className={styles.groupsSelectBox}>
        <Select
          multi
          options={options}
          closeOnSelect={false}
          clearableValue={false}
          simpleValue
          placeholder="タグ選択"
          name={inputProps.name}
          value={inputProps.value}
          noResultsText="データが見つかりません"
          noOptionsMessage="データが見つかりません"
          onChange={(newValue) => {
            const value = addChildrenWhenParentClicked(options, inputProps.value, newValue)
            inputProps.onChange(value)
            inputProps.onBlur(value)
          }}
          {...rest}
        />
      </div>
    )
  }
}

class MultiTagFilteringFieldRow extends React.Component {
  static defaultProps = {
    takeNewValuesFunc: takeNewMultiTagValues,
    list: [],
  }

  render() {
    const {header, fieldName, list, takeNewValuesFunc, ...rest} = this.props
    return (
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>{header}</div>
        <div className={styles.detailsBody}>
          <Field
            name={fieldName}
            component={MultiTagFilteringField}
            header={header}
            list={list}
            takeNewValuesFunc={takeNewValuesFunc}
            {...rest}
          />
        </div>
      </div>
    )
  }
}

class SearchTextBox extends React.Component {
  static defaultProps = {
    type: 'text',
  }

  render() {
    const {
      input: inputProps,
      meta: {invalid},
      type,
      inputWidth,
      ...rest
    } = this.props
    return (
      <div
        className={classNames(styles.searchTextBox, {
          [styles.searchTextBoxError]: invalid,
        })}
      >
        {type !== 'date' ? (
          <input type={type} style={{width: inputWidth}} {...inputProps} {...rest} />
        ) : (
          <div className={styles.searchTextBoxDateTimeWrapper}>
            <DateTime
              value={inputProps.value}
              onChange={(param) => {
                const date = moment(param)
                if (date.isValid()) {
                  inputProps.onChange(date.format('YYYY/MM/DD'))
                } else {
                  inputProps.onChange(param)
                }
              }}
              onBlur={() => inputProps.onBlur(inputProps.value)}
              dateFormat={'YYYY/MM/DD'}
              timeFormat={false}
              {...rest}
              ref={(dateTime) => (this._dateTime = dateTime)}
            />
            <i onClick={() => this._dateTime.openCalendar()}>
              <Calendar size={16} />
            </i>
          </div>
        )}
      </div>
    )
  }
}

const AdditionalSearchFields = ({
  groups = [],
  tags = [],
  positions = [],
  employmentTypes = [],
  occupations = [],
  handleClear,
  handleSubmit,
}) => {
  const hasBranchOffice = useHasBranchOffice()
  return (
    <div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>氏名</div>
        <div className={styles.detailsBody}>
          <div className={styles.groupsSelectBox}>
            <Field name="name" component={SearchTextBox} />
          </div>
        </div>
      </div>
      <ActionButton as="button" type="button" className={styles.mainFormControl} onClick={handleClear}>
        条件解除
      </ActionButton>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>メールアドレス</div>
        <div className={styles.detailsBody}>
          <div className={styles.groupsSelectBox}>
            <Field name="email" component={SearchTextBox} />
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>タグ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field name="tags" component={TagSelectBox} options={withNullOpt(tags)} />
          </div>
        </div>
      </div>
      {hasBranchOffice && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeader}>適用事業所</div>
          <div className={styles.detailsBody}>
            <div className={styles.detailsBodyItems}>
              <Field name="offices" component={OfficesSelectBox} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>グループ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field name="groups" component={GroupsSelectBox} options={withNullOpt(groups)} />
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>スタッフコード</div>
        <div className={styles.detailsBody}>
          <div className={styles.groupsSelectBox}>
            <Field name="staff_code" component={SearchTextBox} />
          </div>
        </div>
      </div>
      <MultiTagFilteringFieldRow header="役職" fieldName="positions" list={withNull(positions)} />
      <MultiTagFilteringFieldRow header="雇用形態" fieldName="employment_types" list={withNull(employmentTypes)} />
      <MultiTagFilteringFieldRow header="職種" fieldName="occupations" list={withNull(occupations)} />
      <MultiTagFilteringFieldRow
        header="在籍状況"
        fieldName="employment_status"
        list={employmentStatuses}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  groups: fetchSelector(state, 'selections').groups,
  positions: fetchSelector(state, 'selections').positions,
  employmentTypes: fetchSelector(state, 'selections').employmentTypes,
  occupations: fetchSelector(state, 'selections').occupations,
  tags: fetchSelector(state, 'selections').tags,
})
export default connect(mapStateToProps)(AdditionalSearchFields)
