import {FC} from 'react'
import {Table} from 'components/ui/Table'
import {Order, QueryVariables, Document, SortColumn} from '../../query'
import {Row} from './Row'
import {Hint} from 'jbc-front/components/presenters/ui/Hint'
import styles from './EmployeeList.scss'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'

type EmployeeListProps = {
  order: Order
  sortColumn: SortColumn
  onSort: (column: SortColumn) => void
  document?: Document
  variables: QueryVariables
}

export const EmployeeList: FC<EmployeeListProps> = ({order, sortColumn, onSort, document, variables}) => {
  const hasBranchOffice = useHasBranchOffice()

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.SortableTh
            width="12%"
            onClick={() => onSort('staff_code')}
            order={order}
            active={sortColumn === 'staff_code'}
          >
            スタッフコード
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('full_name_kana')}
            order={order}
            active={sortColumn === 'full_name_kana'}
          >
            氏名
          </Table.SortableTh>

          {hasBranchOffice && (
            <Table.SortableTh
              width="10%"
              onClick={() => onSort('office')}
              order={order}
              active={sortColumn === 'office'}
            >
              事業所
            </Table.SortableTh>
          )}

          <Table.SortableTh width="10%" onClick={() => onSort('group')} order={order} active={sortColumn === 'group'}>
            グループ
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('employment_type')}
            order={order}
            active={sortColumn === 'employment_type'}
          >
            雇用形態
          </Table.SortableTh>

          <Table.SortableTh
            width="9%"
            onClick={() => onSort('joined_at')}
            order={order}
            active={sortColumn === 'joined_at'}
          >
            入社日
          </Table.SortableTh>

          <Table.Th width="15%">
            <div className={styles.flex}>
              マイページ発行状況
              <Hint>
                マイページ未招待の従業員は、招待後に登録手続きを完了することで書類を閲覧できるようになります。
              </Hint>
            </div>
          </Table.Th>

          <Table.Th width="9%">初回閲覧日</Table.Th>

          <Table.Th width="10%">マイページ公開</Table.Th>
        </Table.Tr>
      </Table.Thead>

      <Table.Tbody>
        {document && document.searchDocumentEmployees && document.searchDocumentEmployees.list.length > 0 ? (
          document.searchDocumentEmployees.list.map((searchDocumentEmployee) => (
            <Row
              key={searchDocumentEmployee.id}
              searchDocumentEmployee={searchDocumentEmployee}
              hasBranchOffice={hasBranchOffice}
              publicDisabled={!document.dynamicDocument.downloadable}
              variables={variables}
            />
          ))
        ) : (
          <Table.Tr>
            <Table.Td colSpan={hasBranchOffice ? 9 : 8}>従業員がいません</Table.Td>
          </Table.Tr>
        )}
      </Table.Tbody>
    </Table>
  )
}
