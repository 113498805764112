import {useCallback, useRef} from 'react'
import _ from 'lodash'

type CancellableLauncher = (job: CancellableJob) => void
type CancellableJob = (isCancelled: () => boolean) => void

/**
 * キャンセル可能な非同期処理を作るためのHook
 * @param name アプリ内で一意な処理名
 */
export const useCancellable = (name: string): CancellableLauncher => {
  const currentJobId = useRef<string | null>(null)

  return useCallback(
    (job) => {
      const jobId = _.uniqueId(`${name}_`)
      currentJobId.current = jobId
      const isCancelled = () => currentJobId.current !== jobId
      job(isCancelled)
    },
    [name]
  )
}
