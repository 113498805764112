import {FC, ReactNode} from 'react'
import styles from './FloatingContainer.scss'
import classNames from 'classnames'

type FloatingContainterProps = {
  children: ReactNode
  className?: string
}

export const FloatingContainer: FC<FloatingContainterProps> = ({children, className}) => (
  <div className={classNames(styles.floatingButtonSpace, className)}>{children}</div>
)
