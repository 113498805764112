import React, {Component} from 'react'
import moment from 'moment'
import styles from 'employeeFiles/Top.scss'
import {DeleteSquare} from 'jbc-front/components/icons'
import {recordDisplay} from 'utils'
import Trash from 'employeeFiles/Trash'
import Upload from 'employeeFiles/Upload'
import api from 'api'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import _ from 'lodash'
import {showPreviewPdf} from 'notifications/List'
import {procedureTypes} from 'procedureStatuses/List'
import PreviewPdf from 'components/PreviewPdf'
import Hint from 'jbc-front/components/Hint'
import {asyncError} from 'store/actions/asyncError'

class Top extends Component {
  loadData = () => {
    const {dispatch, token} = this.props
    const promise = api.createWithAuth(token).uploadedFiles.employeeFiles()
    dispatch(actionCreators.fetchData('employee_files', promise))
    return promise
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('employee_files'))
  }

  deleteFile = async (id) => {
    const {token, dispatch} = this.props
    try {
      this.setState({trashInProcess: {...this.state.trashInProcess, [id]: true}})
      await api.createWithAuth(token).uploadedFiles.fileInfos.update(id, {trashed_by_member: true})
      await this.loadData()
    } catch (err) {
      dispatch(asyncError(err))
    } finally {
      this.setState({trashInProcess: _.omit(this.state.trashInProcess, id)})
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      trashInProcess: {},
    }
  }

  static defaultProps = {
    employeeFiles: {},
  }

  showPreviewPdf = showPreviewPdf(this.props.dispatch)

  render() {
    const {employeeFiles, token} = this.props
    return (
      <div>
        <div className="l-wrap-l">
          <div className={styles.titleWrap}>
            <h2 className="m-title-main">ダウンロード</h2>
          </div>
          <div className="u-mt10 u-ta-c">管理者が依頼またはアップロードしたファイルをダウンロードできます。</div>
          <div className={styles.action}>
            <Trash loadData={this.loadData} />
          </div>
          <h3 className={styles.titleTable}>手続きファイル</h3>
          <Hint text="管理者から印刷・捺印の依頼があった場合、該当書類が表示され、ダウンロードできます" />
          <div className="l-overflow-scroll">
            <table className="m-table-list m-table-fixed">
              <thead>
                <tr>
                  <th className={styles.th}>日付</th>
                  <th className={styles.th}>手続き</th>
                  <th className={styles.th}>ファイル名</th>
                  <th className={styles.th}>メッセージ</th>
                  <th className={styles.th}>削除</th>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(employeeFiles.notification_content_to_upload) ? (
                  <tr>
                    <td className={styles.td} colSpan={5}>
                      手続きファイルがありません
                    </td>
                  </tr>
                ) : (
                  <React.Fragment>
                    <PreviewPdf />
                    {employeeFiles.notification_content_to_upload.map((content) => (
                      <tr key={content.id}>
                        <td className={styles.td}>{moment(content.file_info.created_at).format('YYYY/MM/DD')}</td>
                        <td className={styles.td}>{recordDisplay(procedureTypes[content.procedure_type])}</td>
                        <td className={styles.td}>
                          <a
                            className="u-txt-link"
                            onClick={() => {
                              this.showPreviewPdf(content, token)
                            }}
                          >
                            {content.file_info.file_name}
                          </a>
                        </td>
                        <td className={styles.td + ' u-ta-l'}>
                          {recordDisplay(content.file_info.message_by_uploader)}
                        </td>
                        <td className={styles.td}>
                          <a
                            className={
                              this.state.trashInProcess[content.file_info.id]
                                ? styles.deleteButtonDisabled
                                : styles.deleteButton
                            }
                            onClick={() => {
                              this.deleteFile(content.file_info.id)
                            }}
                          >
                            <DeleteSquare />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                )}
              </tbody>
            </table>
          </div>
          <h3 className={styles.titleTable}>社内規定ファイル</h3>
          <Hint text="管理者が直接アップロードしたファイルが表示され、ダウンロードできます" />
          <div className="l-overflow-scroll">
            <table className="m-table-list m-table-fixed">
              <thead>
                <tr>
                  <th className={styles.th}>日付</th>
                  <th className={styles.th}>手続き</th>
                  <th className={styles.th}>ファイル名</th>
                  <th className={styles.th}>メッセージ</th>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(employeeFiles.files_uploaded_by_admin) ? (
                  <tr>
                    <td className={styles.td} colSpan={4}>
                      社内規定ファイルがありません
                    </td>
                  </tr>
                ) : (
                  employeeFiles.files_uploaded_by_admin.map((file) => (
                    <tr key={file.id}>
                      <td className={styles.td}>{moment(file.file_info.created_at).format('YYYY/MM/DD')}</td>
                      <td className={styles.td}>{recordDisplay()}</td>
                      <td className={styles.td}>{recordDisplay.file(file.file)}</td>
                      <td className={styles.td + ' u-ta-l'}>{recordDisplay(file.file_info.message_by_uploader)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className={styles.titleWrap}>
            <h2 className="m-title-main">アップロード</h2>
          </div>
          <div className="u-mt10 u-ta-c">
            ファイルをアップロードし、管理者に提出します。
            <br />
            10MB以下のファイルを最大20個までアップロードできます。
          </div>
          <div className={styles.action}>
            <Upload loadData={this.loadData} />
          </div>
          <div className="l-overflow-scroll">
            <table className="m-table-list m-table-fixed u-mt10">
              <thead>
                <tr>
                  <th className={styles.th}>日付</th>
                  <th className={styles.th}>手続き</th>
                  <th className={styles.th}>ファイル名</th>
                  <th className={styles.th}>メッセージ</th>
                  <th className={styles.th}>削除</th>
                </tr>
              </thead>
              <tbody>
                {_.isEmpty(employeeFiles.files_uploaded_by_member) ? (
                  <tr>
                    <td className={styles.td} colSpan={5}>
                      アップロードファイルがありません
                    </td>
                  </tr>
                ) : (
                  employeeFiles.files_uploaded_by_member.map((file) => (
                    <tr key={file.id}>
                      <td className={styles.td}>{moment(file.file_info.created_at).format('YYYY/MM/DD')}</td>
                      <td className={styles.td}>{recordDisplay()}</td>
                      <td className={styles.td}>{recordDisplay.file(file.file)}</td>
                      <td className={styles.td + ' u-ta-l'}>{recordDisplay(file.file_info.message_by_uploader)}</td>
                      <td className={styles.td}>
                        <a
                          className={
                            this.state.trashInProcess[file.file_info.id]
                              ? styles.deleteButtonDisabled
                              : styles.deleteButton
                          }
                          onClick={() => {
                            this.deleteFile(file.file_info.id)
                          }}
                        >
                          <DeleteSquare />
                        </a>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  employeeFiles: fetchSelector(state, 'employee_files').data,
}))(Top)
