import React from 'react'
import {Card, Container} from 'components/SelectCard'
import {UserSelect, UserAdd, Request, Edit} from 'jbc-front/components/icons'

import ChooseEmployeeRequest from 'procedures/enroll/ChooseEmployeeRequest'
import ChooseEmployeeDirect from 'procedures/enroll/ChooseEmployeeDirect'
import NewDirectInput from 'procedures/enroll/NewDirectInput'
import NewRequest from 'procedures/enroll/NewRequest'
import Detail from 'procedures/enroll/Detail'
import Flow from 'procedures/enroll/Flow'
import EmployeeInput from 'procedures/enroll/EmployeeInput'
import Confirm from 'procedures/enroll/Confirm'
import ConfirmDependents from 'procedures/enroll/ConfirmDependents'
import EmployeeInputDependents from 'procedures/enroll/EmployeeInputDependents'
import EmployeeInputCompleted from 'procedures/enroll/EmployeeInputCompleted'

const DirectInputOrRequestInput = ({
  match: {
    params: {method},
  },
}) => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">作成方法を選ぶ</h1>
    </div>
    <Container>
      <Card icon={Edit} to={method === 'new' ? '/enroll/new_direct_input' : '/enroll/direct/choose_exist_employee'}>
        直接入力する
      </Card>
      <Card icon={Request} to={method === 'new' ? '/enroll/new_request' : '/enroll/request/choose_exist_employee'}>
        本人に入力してもらう
      </Card>
    </Container>
  </div>
)

const NewOrExist = () => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">入社手続きの進め方を選択</h1>
    </div>
    <Container>
      <Card to="/enroll/exist/direct_input_or_request_input" icon={UserSelect}>
        登録済みの従業員から選択
      </Card>
      <Card to="/enroll/new/direct_input_or_request_input" icon={UserAdd}>
        新規従業員登録
      </Card>
    </Container>
  </div>
)

/*依頼完了*/
const RequestEmployeeToInputCompleted = () => (
  <div className="l-title-wrap">
    <h1 className="m-title-main">依頼が完了しました</h1>
  </div>
)

export default {
  DirectInputOrRequestInput,
  NewOrExist,
  ChooseEmployeeRequest,
  ChooseEmployeeDirect,
  RequestEmployeeToInputCompleted,
  NewDirectInput,
  NewRequest,
  Detail,
  Flow,
  EmployeeInput,
  EmployeeInputDependents,
  EmployeeInputCompleted,
  Confirm,
  ConfirmDependents,
}
