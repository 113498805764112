import {Query as ApolloQuery, Mutation as ApolloMutation} from '@apollo/client/react/components'
import {
  useQuery as useQueryOrigin,
  useMutation as useMutationOrigin,
  useLazyQuery as useLazyQueryOrigin,
} from '@apollo/client'
import LoadingPage from './LoadingPage'
import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'
import _ from 'lodash'
import fp from 'lodash/fp'
import {SubmissionError} from 'redux-form'
import {logError} from 'libs/telemetry'
import {handleQueryError} from 'libs/graphql'
import {notifyError} from 'store/actions/notify'

const camel = (value) =>
  _.isArray(value)
    ? value.map(camel)
    : _.isObject(value)
    ? _.mapValues(
        _.mapKeys(value, (v, k) => _.camelCase(k)),
        (v) => camel(v)
      )
    : value

/**
 * @deprecated 新規コードでは使用禁止。{@link useQuery} を使うこと。
 */
export const Query = connect()(({children, showLoading = false, dispatch, ...props}) => (
  <ApolloQuery {...props}>
    {({loading, error, ...rest}) => {
      if (loading) return showLoading ? <LoadingPage /> : null
      if (error) {
        handleQueryError(error, dispatch)
        return null
      }
      return children(rest)
    }}
  </ApolloQuery>
))

export const getBaseError = (data) => {
  if (_.isArray(data)) {
    let idx = 0
    for (const v of data) {
      let [base, rest] = getBaseError(v)
      if (base) {
        return [base, fp.set(`[${idx}]`, rest, data)]
      }
      idx++
    }
    return []
  }
  if (!_.isObject(data)) {
    return []
  }
  for (const [key, value] of Object.entries(data)) {
    if (key === 'base') {
      return [value, _.omit(data, 'base')]
    } else {
      let [base, rest] = getBaseError(value)
      if (base) {
        return [base, fp.set(key, rest, data)]
      }
    }
  }
  return []
}

/**
 * @deprecated 新規コードでは使用禁止。{@link useMutation} を使うこと。
 */
export const Mutation = connect()(({children, dispatch, ...props}) => (
  <ApolloMutation {...props}>
    {(mutation, result) =>
      children(async (...args) => {
        try {
          const res = await mutation(...args)
          return res
        } catch (err) {
          const msg = _.get(err, 'graphQLErrors[0].problems[0].explanation') || _.get(err, 'graphQLErrors[0].message')
          if (msg) {
            let errors = null
            try {
              errors = camel(JSON.parse(msg))
            } catch (__) {
              //
            }
            if (errors) {
              const [base, rest] = getBaseError(errors)
              if (base) {
                dispatch(notifyError(base))
                throw new SubmissionError(rest)
              }
              throw new SubmissionError(errors)
            } else {
              dispatch(notifyError(msg))
              throw err
            }
          }
          logError(err)
          dispatch(notifyError(_.get(err, 'networkError.result.error.message') || err.message))
          throw err
        }
      }, result)
    }
  </ApolloMutation>
))

export const useQuery = (...args) => {
  const dispatch = useDispatch()
  const ret = useQueryOrigin(...args)
  useEffect(() => {
    if (ret.error) {
      handleQueryError(ret.error, dispatch)
    }
  }, [ret.error])
  return ret
}

export const useLazyQuery = (...args) => {
  const dispatch = useDispatch()
  const tuple = useLazyQueryOrigin(...args)
  const [, ret] = tuple
  useEffect(() => {
    if (ret.error) {
      handleQueryError(ret.error, dispatch)
    }
  }, [ret.error])
  return tuple
}

const validateReadableFiles = async (data) => {
  if (_.isArray(data)) {
    const ary = await Promise.all(data.map(validateReadableFiles))
    if (ary.length === 0 || ary.every((v) => v === undefined)) {
      return undefined
    } else {
      return ary
    }
  }
  if (!_.isObject(data)) {
    return undefined
  }
  const ret = {}
  await Promise.all(
    Object.entries(data).map(([key, value]) => {
      if (value instanceof File) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', resolve)
          reader.addEventListener('abort', resolve)
          reader.addEventListener('error', () => {
            ret[key] = 'を正常にアップロードできませんでした。再度アップロードしてください。'
            resolve()
          })
          reader.readAsArrayBuffer(value)
        })
      } else {
        return validateReadableFiles(value).then((v) => (v ? (ret[key] = v) : undefined))
      }
    })
  )
  return _.size(ret) ? ret : undefined
}

export const useMutation = (...args) => {
  const query = args[0].loc?.source?.body?.trim()
  const dispatch = useDispatch()
  const [mutation, value] = useMutationOrigin(...args)
  return [
    async (...args) => {
      try {
        const res = await mutation(...args)
        return res
      } catch (err) {
        const msg =
          _.get(err, 'graphQLErrors[0].extensions.problems[0].explanation') || _.get(err, 'graphQLErrors[0].message')
        if (msg) {
          if (_.get(err, 'graphQLErrors[0].extensions.code') === 'BROKEN_MULTIPART_REQUEST') {
            const errors = await validateReadableFiles(args[0].variables?.input || {})
            dispatch(notifyError(msg))
            throw new SubmissionError(errors)
          }

          let errors = null
          try {
            errors = camel(JSON.parse(msg))
          } catch (__) {
            //
          }
          if (errors) {
            const [base, rest] = getBaseError(errors)
            if (base) {
              dispatch(notifyError(base))
              throw new SubmissionError(rest)
            }
            throw new SubmissionError(errors)
          } else {
            logError(err, {query})
            dispatch(notifyError(msg))
            throw err
          }
        }
        logError(err, {query})
        dispatch(notifyError(_.get(err, 'networkError.result.error.message') || err.message))
        throw err
      }
    },
    value,
  ]
}
