import React, {Component} from 'react'
import {Setting} from 'jbc-front/components/icons'
import {EmployeeFieldGroupPermissionModal} from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupPermission'

class PermissionField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  render() {
    const {
      input: {value, onBlur},
      label,
    } = this.props
    return (
      <div style={{display: 'inline-block', color: '#999'}}>
        <Setting
          onClick={() => {
            this.setState({showModal: true})
          }}
          className="u-cur-pointer"
        />
        <EmployeeFieldGroupPermissionModal
          label={label}
          isOpen={this.state.showModal}
          onSubmit={(values) => {
            onBlur((values && values.permissions) || [])
            this.setState({showModal: false})
          }}
          hideModal={() => {
            this.setState({showModal: false})
          }}
          initialValues={makeInitialValuesForCustom(value)}
          isCustomField
        />
      </div>
    )
  }
}

export default PermissionField

const clientRoles = [{id: 2, name: '社員'}]

export const makeInitialValuesForCustom = (permissions = []) => ({
  permissions: clientRoles.map((role) => ({
    ...((permissions && permissions.find((permission) => permission.client_role_id == role.id)) || {
      client_role_id: role.id,
    }),
    _name: role.name,
  })),
})
