import {
  DocumentNode,
  isApolloError,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useMutation as useMutationOrigin,
} from '@apollo/client'
import {Dispatch} from 'redux'
import {useDispatch} from 'react-redux'
import {isServerError} from 'libs/graphql'
import {logError} from 'libs/telemetry'

// @ts-ignore
import {actionCreators} from 'actions'

interface ErrorHandlerOptions<TData, TVariables> {
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>
  options: MutationHookOptions<TData, TVariables> | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
}

class BrokenMultipartRequestError extends Error {
  static {
    this.prototype.name = 'BrokenMultipartRequestError'
  }
}

export const useMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> => {
  const dispatch = useDispatch()
  const [mutateOrigin, value] = useMutationOrigin<TData, TVariables>(mutation, options)
  const mutate = (options?: MutationFunctionOptions<TData, TVariables>) => {
    const errorHandlerOptions = {
      mutation,
      options,
      dispatch,
    }
    return mutateOrigin(options)
      .catch(unwrapBrokenMultipartRequest(errorHandlerOptions))
      .catch(sendTelemetry(errorHandlerOptions))
      .catch(notifyError(errorHandlerOptions))
  }
  return [mutate, value]
}

const unwrapBrokenMultipartRequest =
  <TData, TVariables>(_options: ErrorHandlerOptions<TData, TVariables>) =>
  (error: Error) => {
    if (isApolloError(error)) {
      const err = error.graphQLErrors[0]
      if (err && err.extensions?.code === 'BROKEN_MULTIPART_REQUEST') {
        throw new BrokenMultipartRequestError(err.message)
      }
    }
    throw error
  }

const sendTelemetry =
  <TData, TVariables>(options: ErrorHandlerOptions<TData, TVariables>) =>
  (error: Error) => {
    // ログする必要のないエラーは無視
    if (error instanceof BrokenMultipartRequestError) {
      throw error
    }

    const query = options.mutation.loc?.source?.body
    logError(error, {query})
    throw error
  }

const notifyError =
  <TData, TVariables>(options: ErrorHandlerOptions<TData, TVariables>) =>
  (error: Error) => {
    options.dispatch(actionCreators.notifyError(fetchErrorMessage(error)))
    throw error
  }

const fetchErrorMessage = (error: Error) => {
  if (isApolloError(error)) {
    const graphQLErrorMessage = error.graphQLErrors[0]?.message
    if (graphQLErrorMessage) {
      return graphQLErrorMessage
    }

    if (error.networkError && isServerError(error.networkError)) {
      const networkErrorMessage = error.networkError.result['error']?.message
      if (networkErrorMessage) {
        return networkErrorMessage
      }
    }
  }

  return error.message
}
