import React, {FC, ReactNode, useEffect, useState} from 'react'
import Button, {ButtonProps} from 'jbc-front/components/Button'

interface Props extends ButtonProps {
  children?: ReactNode
  text?: string
  submitting?: boolean
  onSubmit: () => Promise<void>
}

/**
 * クリック時に必ず非活性になるボタンです.
 * フォームのサブミットなどに使用する想定で作成しています.
 * @param {Props} props
 * @returns ボタンコンポーネント
 */
export const SubmitButton: FC<Props> = (props) => {
  const {onSubmit, text, children, submitting, ...buttonProps} = props
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const onClick = async () => {
    setIsDisabled(true)
  }

  useEffect(() => {
    let isMounted = true

    const submit = async () => {
      await onSubmit()

      if (isMounted) {
        setIsDisabled(false)
      }
    }

    if (isDisabled) {
      submit()
    }

    return () => {
      isMounted = false
    }
  }, [isDisabled])

  return (
    <Button {...buttonProps} onClick={() => onClick()} disabled={submitting || isDisabled}>
      {text || children}
    </Button>
  )
}
