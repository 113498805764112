import React, {Component} from 'react'
import Form, {autofill, makeInitialValuesFromRelatedProcedure} from 'procedures/maternityLeave/extend/Form'
import {alreadyExistSameDependent} from 'procedures/maternityLeave/Form'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import moment from 'moment'
import {asyncError} from 'store/actions/asyncError'

class Detail extends Component {
  componentDidMount() {
    const {
      loadData,
      loadProcedureStatus,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId, procedure_id: procedureId} = parse(search)
    loadData(employeeId, token)
    if (procedureId) {
      loadProcedureStatus(procedureId, token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {employee, procedureStatus} = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={makeInitialValuesFromRelatedProcedure(procedureStatus)}
        employee={employee}
      />
    )
  }

  handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      mother_child_note: values.maternity_status === 'after' ? values.mother_child_note : [],
    }
    const {
      employee,
      dependents,
      createProcedure,
      location: {search},
      token,
    } = this.props
    const {procedure_id: procedureId} = parse(search)
    if (alreadyExistSameDependent(formattedValues.procedure_maternity_child_data, dependents)) {
      throw new SubmissionError({'': 'すでに同じ被扶養者が登録されています'})
    } else {
      await createProcedure(procedureId, employee, formattedValues, token)
    }
  }
}

export default connect(
  (state) => ({
    employee: state.employees.current.data,
    dependents: state.employees.current.data.employee_dependents,
    procedureStatus: state.procedureStatuses.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadData: (id, token) => {
      dispatch(
        actionCreators.employees.current.fetchData(
          api.createWithAuth(token).employees.get(id, ['office', 'employee_dependents'])
        )
      )
    },
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api.createWithAuth(token).procedureStatuses.get(id, ['employee', 'procedure_maternity_datum'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
      dispatch(actionCreators.procedureStatuses.current.destroy())
    },
    createProcedure: async (prevProcedureId, employee, values, token) => {
      const {
        procedure_maternity_child_data: procedureMaternityChildData,
        expected_birth_date_after: expectedBirthDate,
        started_at_before: startOnPrev,
        expected_finished_at_before: endOnPrev,
        started_at_after: startOn,
        expected_finished_at_after: endOn,
        maternity_status: maternityStatus,
      } = values
      const authedApi = api.createWithAuth(token)
      let procedureId = prevProcedureId
      try {
        await authedApi.employees.leaveOfAbsences.updateOrCreateForMaternity(
          employee.id,
          startOn,
          endOn,
          startOnPrev,
          endOnPrev
        )
        if (!procedureId) {
          const {
            data: {id},
          } = await authedApi.procedureStatuses.start(employee.id, 'extend_maternity', expectedBirthDate, 'in_progress')
          procedureId = id
        } else {
          await authedApi.procedureStatuses.update(procedureId, {
            procedure_type: 'extend_maternity',
            date_on: expectedBirthDate,
            status: 'in_progress',
          })
        }
        await authedApi.procedureStatuses.reports.create(
          procedureId,
          employee.office.health_insurance_type === 'its' ? 'r504' : 'r503',
          {submitOn: moment()}
        )
        dispatch(autofill('procedure_status_id', procedureId))
        await authedApi.procedureStatuses.extendMaternityData.create(procedureId, values)
        await authedApi.procedureStatuses.startProgress(procedureId)
        if (
          maternityStatus === 'after' &&
          procedureMaternityChildData.some((childDatum) => childDatum.add_support && !childDatum.skip_add_support)
        ) {
          dispatch(
            push(`/add_dependents/detail?employee_id=${employee.id}&maternity_procedure_status_id=${procedureId}`)
          )
        } else {
          dispatch(push(`/procedure_statuses/${procedureId}`))
        }
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Detail)
