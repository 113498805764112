import React from 'react'
import {useDispatch} from 'react-redux'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import styles from 'tm/employees/SelectItem.scss'

const CopyUrl = () => {
  const dispatch = useDispatch()
  const handleclick = async () => {
    try {
      const url = location.href
      const new_url = url.replace(/\?.*$/, '')
      await navigator.clipboard.writeText(new_url)
      dispatch(notifySuccess('URLをコピーしました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }
  return (
    <div className={styles.btnarea}>
      <button onClick={handleclick} className={styles.small}>
        URLのコピー
      </button>
    </div>
  )
}

export default CopyUrl
