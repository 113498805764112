import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Wrap, TitleArea, Title, Dl, Dd, Dt, SubPanelarea, SubPanel} from 'tm/employees/Parts'
import ShowMoreBtn from './ShowMoreBtn'
import * as icons from 'jbc-front/components/icons'
import {Skill} from 'jbc-front/components/icons'

class BasicInfomation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }
  render() {
    const {employee, userDisplaySetting} = this.props
    const icon_name = getCategoryIconName(userDisplaySetting, 'skill')
    const color =
      (!_.isNull(getCategoryIconColor(userDisplaySetting, 'skill')) &&
        getCategoryIconColor(userDisplaySetting, 'skill')) ||
      '#3498db'
    const IconName = icons[icon_name]
    const style = {color: color}
    return (
      (showCategory(userDisplaySetting, 'skill') && employee.employee_skill && employee.employee_skill.length > 0 && (
        <Panel>
          <Item>
            <Wrap>
              <TitleArea>
                <Title>
                  {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || <Skill size={30} color={color} />}
                  <div style={style}>スキル</div>
                </Title>
              </TitleArea>

              <SubPanelarea>
                {employee.employee_skill &&
                  (this.state.isExpand ? employee.employee_skill : employee.employee_skill.slice(0, 4)).map(
                    (skill, index) =>
                      skill && (
                        <SubPanel key={index}>
                          <SubPanel.Headding>{recordDisplay(_.get(skill, 'skill_name'))}</SubPanel.Headding>
                          {_.get(skill, 'acquisition_date') && _.get(skill, 'skill_details') && (
                            <Dl>
                              <Dt>習得年月</Dt>
                              <Dd>{recordDisplay.dateYearMonth(_.get(skill, 'acquisition_date'))}</Dd>
                              <Dt>スキル詳細</Dt>
                              <Dd>{recordDisplay(_.get(skill, 'skill_details'))}</Dd>
                            </Dl>
                          )}
                          {_.get(skill, 'acquisition_date') && !_.get(skill, 'skill_details') && (
                            <Dl>
                              <Dt>習得年月</Dt>
                              <Dd>{recordDisplay.dateYearMonth(_.get(skill, 'acquisition_date'))}</Dd>
                            </Dl>
                          )}
                          {!_.get(skill, 'acquisition_date') && _.get(skill, 'skill_details') && (
                            <Dl>
                              <Dt>スキル詳細</Dt>
                              <Dd>{recordDisplay(_.get(skill, 'skill_details'))}</Dd>
                            </Dl>
                          )}
                          {!_.get(skill, 'acquisition_date') && !_.get(skill, 'skill_details') && (
                            <Dl>
                              <Dt>スキル詳細</Dt>
                              <Dd>{'―'}</Dd>
                            </Dl>
                          )}
                        </SubPanel>
                      )
                  )}
                {employee.employee_skill && employee.employee_skill.length > 4 && (
                  <ShowMoreBtn
                    isExpand={this.state.isExpand}
                    onToggleClick={() => {
                      this.setState({isExpand: !this.state.isExpand})
                    }}
                  />
                )}
              </SubPanelarea>
            </Wrap>
          </Item>
        </Panel>
      )) ||
      null
    )
  }
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(BasicInfomation)
