import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Dl, Dd, Dt, Grid, SubPanel} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import styles from 'employees/show/Parts.scss'
import ReadMoreReadLess from 'components/ReadMoreReadLess'

const EmployeeWorkHistory = ({employee, editLink, yearStyle, canEdit}) => {
  const employeeWorkHistories = employee.employee_work_histories || []
  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeWorkHistory`} title="職歴" canEdit={canEdit} />
      <Item>
        <Grid className={employeeWorkHistories.length == 1 && styles.gridFill}>
          {employeeWorkHistories.map((employeeWorkHistory, index) => (
            <SubPanel key={index}>
              <SubPanel.Headding>職歴 ({index + 1})</SubPanel.Headding>
              <Grid className={styles.lastChildrenNotBorder}>
                <Dl>
                  <Dt>会社名</Dt>
                  <Dd>{recordDisplay(employeeWorkHistory.company_name)}</Dd>
                </Dl>
                <Dl>
                  <Dt>入社年月日</Dt>
                  <Dd>{recordDisplay.date(employeeWorkHistory.join_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>退社年月日</Dt>
                  <Dd>{recordDisplay.date(employeeWorkHistory.leave_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>役職・職位</Dt>
                  <Dd>{recordDisplay(employeeWorkHistory.position)}</Dd>
                </Dl>
                <Dl>
                  <Dt>業務内容</Dt>
                  <Dd>
                    <ReadMoreReadLess key={employeeWorkHistory.business_content}>
                      {recordDisplay(employeeWorkHistory.business_content)}
                    </ReadMoreReadLess>
                  </Dd>
                </Dl>
              </Grid>
            </SubPanel>
          ))}
        </Grid>
        {employeeWorkHistories.length < 1 && (
          <div className={styles.noData}>
            <p>職歴の登録はありません</p>
          </div>
        )}
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmployeeWorkHistory)
