import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import {mergeWith} from 'lodash'
import {flow, keyBy, mapValues, pick} from 'lodash/fp'
import type {User} from 'types/api/users/user'
import type {ClientRolePermission} from 'types/api/users/clientRole'
import type {TmUser} from 'types/api/tm/tmUsers/tmUser'
import type {Session, NormalizedClientRolePermissions} from 'types/store/session'
import {logout} from './auth'

const initialState = {
  currentUser: {},
  currentTmUser: {},
  selectedOffices: '',
} as Session

const pickPermissions = pick(['readable', 'creatable', 'updatable', 'deletable'])

const createRolePermissions = (clientRolePermissions: ClientRolePermission[]): NormalizedClientRolePermissions => {
  const result = flow(
    keyBy('permission_type'),
    mapValues(pickPermissions)
  )(clientRolePermissions) as unknown as NormalizedClientRolePermissions
  result['change_dependents'] = mergeWith(result['add_dependents'], result['remove_dependents'], (a, b) => a && b)
  result['maternity_leave'] = mergeWith(
    result['start_maternity'],
    result['extend_maternity'],
    result['finish_maternity'],
    (a, b) => a && b
  )
  result['childcare_leave'] = mergeWith(
    result['start_childcare'],
    result['extend_childcare'],
    result['finish_childcare'],
    (a, b) => a && b
  )
  return result
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<User>) {
      state.currentUser = {
        ...action.payload,
        client_role: action.payload.client_role && {
          ...action.payload.client_role,
          client_role_permissions: createRolePermissions(action.payload.client_role.client_role_permissions ?? []),
        },
      }
    },
    setCurrentTmUser(state, action: PayloadAction<TmUser>) {
      state.currentTmUser = action.payload
    },
    setSelectedOffices(state, action: PayloadAction<string>) {
      state.selectedOffices = action.payload
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState)
  },
})

export const {setCurrentUser, setCurrentTmUser, setSelectedOffices, clear} = sessionSlice.actions
export default sessionSlice.reducer
