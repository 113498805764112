import React, {Component} from 'react'
import {connect} from 'react-redux'
import {reduxForm} from 'redux-form'
import {TextField, CheckboxField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import {RoumuLogo} from 'jbc-front/components/icons'
import {Link} from 'react-router-dom'
import {replace} from 'connected-react-router'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import styles from 'Login.scss'
import {parseBackUrl} from 'utils'
import axios from 'axios'

const LoginForm = ({pristine, handleClickCollapse, shouldShowCidInput, submitting, handleSubmit}) => (
  <React.Fragment>
    <div className={styles.loginWrap}>
      <form className={styles.loginBox} onSubmit={handleSubmit}>
        <h1 className={styles.loginLogo}>
          <RoumuLogo size={200} />
        </h1>
        <p className={styles.loginTitle}>ログイン</p>
        <div className={styles.loginField}>
          <div className={styles.loginCollapseWrap} style={shouldShowCidInput ? {height: '170px'} : {}}>
            <TextField name="email" label="メールアドレス" />
            {shouldShowCidInput && <TextField name="client_code" label="会社ID" />}
            <a className={`${styles.loginCid} u-txt-link`} onClick={handleClickCollapse}>
              {shouldShowCidInput ? '会社IDの入力を閉じる' : '複数の会社に登録されていますか？'}
            </a>
          </div>
          <TextField name="password" label="パスワード" type="password" />
          <CheckboxField name="remember_login" label="ログインを記憶" />
          <div className={styles.loginButton}>
            <Button as="button" primary widthWide type="submit" disabled={pristine || submitting}>
              ログイン
            </Button>
          </div>
          <Link to="/forgot_password" className={styles.loginForgot}>
            パスワードをお忘れですか？
          </Link>
        </div>
        <div className={styles.commonLoginField}>
          <Button as="a" success widthWide href="/api/jbc-oauth/login">
            ジョブカン共通IDでログイン
          </Button>
          <a
            href="https://jobcan-id.zendesk.com/hc/ja/articles/900000789243"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.loginForgot}
          >
            ジョブカン共通IDとは？
          </a>
        </div>
      </form>
    </div>
  </React.Fragment>
)

const LoginFormContainer = reduxForm({
  form: 'loginForm',
  enableReinitialize: true,
})(LoginForm)

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shouldShowCidInput: false,
    }
  }
  async componentDidMount() {
    try {
      const {data} = await axios.get('/api/me')
      if (data.access_token) {
        this.props.moveDashboard()
      }
    } catch (err) {
      //ignore
    }
  }

  handleClickCollapse = () => {
    this.setState({shouldShowCidInput: !this.state.shouldShowCidInput})
  }

  render() {
    return (
      <LoginFormContainer
        handleClickCollapse={this.handleClickCollapse}
        onSubmit={this.props.handleSubmit}
        {...this.state}
      />
    )
  }
}

export default connect(
  (state) => state,
  (dispatch) => ({
    async handleSubmit(values) {
      try {
        await api.doLogin(values)
        dispatch((dispatch, getState) => {
          dispatch(replace(parseBackUrl(getState().router.location, '/dashboard')))
        })
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    async moveDashboard() {
      dispatch(replace('/dashboard'))
      dispatch(notifySuccess('すでにログイン済みです。ダッシュボードへ移動しました。'))
    },
  })
)(Login)
