import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Index} from 'pages/withAuth/adminPage/employees/updateRequests/index'
import {Show} from 'pages/withAuth/adminPage/employees/updateRequests/show'
import {RouteParams} from 'types/routes'

export const UpdateRequestRoutes: RouteParams[] = [
  {
    key: 'employees/update_requests',
    path: WITH_AUTH_ADMIN_PATHS.EMPLOYEES.UPDATE_REQUESTS.INDEX,
    component: Index,
    exact: true,
  },
  {
    key: 'employees/update_requests/:id/edit',
    path: WITH_AUTH_ADMIN_PATHS.EMPLOYEES.UPDATE_REQUESTS.SHOW,
    component: Show,
    exact: true,
  },
]
