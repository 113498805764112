import {combineReducers} from 'redux'
import {actionTypes} from 'actions'
import queryReducer from 'reducers/queryReducer'
import {getQueryDiffFactory} from 'utils'
import update from 'immutability-helper'
import _ from 'lodash'

const defaultQuery = {
  page: 1,
}

export const getQueryDiff = getQueryDiffFactory(defaultQuery)

const initialState = {}

const changedReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS.USERS_CLIENTS.CHANGE:
      return {
        ...state,
        ...{
          [action.payload.id]: {
            ...(state[`${action.payload.id}`] || {}),
            ...action.payload,
          },
        },
      }
    case actionTypes.SETTINGS.USERS_CLIENTS.CLEAR:
      return initialState
    case actionTypes.SETTINGS.USERS_CLIENTS.CHANGE_SELECTED: {
      const [selected, value] = action.payload
      return Object.assign(
        _.pickBy(state, (value, id) => !selected[id]),
        ..._.map(_.pickBy(selected), (v, id) =>
          state[id] ? {[id]: {...state[id], ...value, id}} : {[id]: {...value, id}}
        )
      )
    }
    default:
      return state
  }
}

const editReducer = (state = false, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS.USERS_CLIENTS.EDIT:
      return true
    case actionTypes.SETTINGS.USERS_CLIENTS.CLEAR:
      return false
    default:
      return state
  }
}

const selectedReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS.USERS_CLIENTS.TOOGLE_SELECTED:
      return update(state, {[action.payload]: {$apply: (x) => !x}})
    case actionTypes.SETTINGS.USERS_CLIENTS.CLEAR:
    case actionTypes.SETTINGS.USERS_CLIENTS.CHANGE_SELECTED:
      return {}
    case actionTypes.SETTINGS.USERS_CLIENTS.SELECT_MULTI:
      return {...state, ...action.payload}
    default:
      return state
  }
}

const reducer = combineReducers({
  query: queryReducer(actionTypes.SETTINGS.USERS_CLIENTS.SET_QUERY, defaultQuery),
  changes: changedReducer,
  editing: editReducer,
  selected: selectedReducer,
})

export default reducer
