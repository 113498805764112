import axios from 'axios'
import api from 'api'
import {getPermission} from 'employees/utils'
import {getBaseErrors} from 'libs/errorHandler'
import {SubmissionError} from 'redux-form'
import _ from 'lodash'

const updateCustomValues = async (employeeId, customValues, fields, token) => {
  const {data: currentUser} = await api.createWithAuth(token).users.getCurrent()
  const data = new FormData()
  for (const field of fields) {
    if (getPermission(field, currentUser) !== 'updatable') {
      continue
    }
    const value = customValues.find((value) => value.custom_employee_field_id == field.id)
    if (field.field_type === 'file') {
      data.append('employees_custom_values[]custom_employee_field_id', field.id)
      if (value && value.value && !value.value.url) {
        data.append('employees_custom_values[]file', value.value)
      } else if (!value || !value.value) {
        data.append('employees_custom_values[]remove_file', true)
      }
    } else {
      data.append('employees_custom_values[]custom_employee_field_id', field.id)
      data.append('employees_custom_values[]value', value?.value ?? '')
    }
  }
  return await axios
    .post(`/api/employees/${employeeId}/bulked_employees_custom_values`, data, {
      headers: {
        'content-type': 'multipart/form-data',
        Authentication: token,
      },
    })
    .catch((err) => {
      if (err.response && err.response.status === 422) {
        const errors = [...getBaseErrors(err.response.data)]
        const customFieldError = errors.filter((error) => error.match(/ : /))
        if (!_.isEmpty(customFieldError)) {
          throw new SubmissionError({
            custom_fields: Object.assign(
              {},
              ...customFieldError.map((error) => {
                const [, fieldName, msg] = error.split(/ : /)
                const field = fields.find((field) => field.label === fieldName)
                return {[`field_${field.id}`]: msg}
              })
            ),
          })
        }
      }
      throw err
    })
}

export default updateCustomValues
