import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {getFormValues, isSubmitting as isSubmittingForm} from 'redux-form'
import useReactRouter from 'use-react-router'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import {push} from 'connected-react-router'
import Modal from 'components/Modal'
import Button from 'jbc-front/components/Button'
import {TextAreaField} from 'jbc-front/components/Form'
import {reduxForm, submit as submitForm} from 'redux-form'
import ReviewComments from 'components/ReviewComments'
import {isAdminSelector, getState} from 'utils'
import styles from './CommentModal.scss'

const formName = 'commentForm'
const isSubmitting = isSubmittingForm(formName)

const modalTitle = (isAdmin, isDirectRequest, procedureType) => {
  if (isAdmin) {
    return '修正依頼'
  } else {
    if (isDirectRequest) {
      return '更新依頼'
    } else {
      switch (procedureType) {
        case 'enroll':
          return '入社の手続き'
        case 'changeDependents':
          return '扶養の変更'
        case 'changeName':
          return '氏名の変更'
        case 'changeAddress':
          return '住所の変更'
        case 'maternityLeave':
          return '産休の手続き'
        default:
          return 'その他申請'
      }
    }
  }
}

const useComments = (id, token, isAdmin) => {
  const [comments, setComments] = useState()
  const {
    detailInputRequest: {get},
    getCurrent,
  } = api.createWithAuth(token).employees
  useEffect(() => {
    isAdmin && id
      ? get(id).then(({data}) => setComments(_.get(data, 'comments')))
      : getCurrent(['detail_input_request', 'detail_input_comments']).then(({data}) =>
          setComments(_.get(data, 'detail_input_request.comments'))
        )
  }, [])
  return comments
}

const Form = reduxForm({
  form: 'commentForm',
})(({handleSubmit, comments, isAdmin, isDirectRequest}) => (
  <div className={styles.commentWrap}>
    <ReviewComments comments={comments} widthAuto />
    <form onSubmit={handleSubmit}>
      <div className="form_box_main">
        <TextAreaField
          name="comment"
          label="コメント"
          placeholder={
            isAdmin
              ? '修正して欲しい内容など入力してください'
              : isDirectRequest
              ? '更新して欲しい理由などを入力してください'
              : ''
          }
        />
      </div>
    </form>
  </div>
))

const CommentModal = ({
  procedureStatusId,
  isAdmin,
  token,
  reject,
  onSubmit,
  formSubmit,
  children,
  getFormValues,
  formName,
  procedureType,
  isDirectRequest,
  comments,
  submitting,
}) => {
  const {pathname} = useReactRouter().location
  const isAdminPage = isAdmin && !pathname.includes('/mypage/')

  if (isDirectRequest) {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- 呼び出し元がisDirectRequestをリテラルで指定している限り大丈夫
    comments = useComments(procedureStatusId, token, isAdminPage)
  }
  const handleReject = ({comment}) => reject({token, procedureStatusId, comment})
  const submitFnc = onSubmit || handleReject
  const handleCommentFormSubmit = async ({comment}) =>
    await submitFnc({values: formName ? await getFormValues(formName) : {}, comment})
  return (
    <Modal>
      {({hideModal, showModal, isOpen}) => (
        <>
          <Modal.Modal>
            <Modal.Header>{modalTitle(isAdminPage, isDirectRequest, procedureType)}</Modal.Header>
            <Modal.Body>
              <Form
                comments={comments}
                onSubmit={handleCommentFormSubmit}
                isAdminPage={isAdminPage}
                isDirectRequest={isDirectRequest}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                <Button onClick={hideModal}>キャンセル</Button>
                <Button primary onClick={() => formSubmit()} disabled={submitting}>
                  送信
                </Button>
              </Modal.Buttons>
            </Modal.Footer>
          </Modal.Modal>
          {children({showModal, isOpen})}
        </>
      )}
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  isAdmin: isAdminSelector(state),
  submitting: isSubmitting(state),
})

const mapDispatchToProps = (dispatch) => ({
  async reject({procedureStatusId, comment, token}) {
    try {
      await api.createWithAuth(token).procedureStatuses.reject(procedureStatusId, comment)
      dispatch(notifySuccess('依頼しました'))
      dispatch(push('/dashboard'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
  formSubmit() {
    dispatch(submitForm(formName))
  },
  async getFormValues(formName) {
    const state = await getState(dispatch)
    const getValues = getFormValues(formName)
    return getValues(state)
  },
})

export default CommentModal |> connect(mapStateToProps, mapDispatchToProps)
