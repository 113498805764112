import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators} from 'actions'
import api from 'api'
import {recordDisplay} from 'utils'
import moment from 'moment'
import _ from 'lodash'
import {ListData} from 'procedureStatuses/list/components'
import {procedureTypes} from 'procedureStatuses/List'
import styles from 'procedureStatuses/ListNotAdmin.scss'
import {asyncError} from 'store/actions/asyncError'

export const statusDisplay = (status, application_status) => {
  if (status === 'done') {
    return '完了'
  } else if (application_status === 'applying') {
    return '確認中'
  } else if (application_status === 'none' || application_status === 'rejected' || application_status === 'working') {
    return '未対応'
  } else {
    return '対応中'
  }
}

class Row extends Component {
  handleClick = () => {
    this.props.handleClick(this.props.procedureStatus.id)
  }

  render() {
    const {procedureStatus} = this.props
    return (
      <tr onClick={this.handleClick}>
        <td>
          {procedureStatus.request_status ? '依頼日：' : '申請日：'}{' '}
          {recordDisplay(procedureStatus.created_at && moment(procedureStatus.created_at).format('YYYY/MM/DD'))}
        </td>
        <td>{recordDisplay(procedureTypes[procedureStatus.procedure_type])}</td>
        <td>{statusDisplay(procedureStatus.status, procedureStatus.application_status)}</td>
      </tr>
    )
  }
}

class ListNotAdmin extends Component {
  handleRowClick = (id) => {
    const {procedureStatuses} = this.props
    const procedureStatus = _.find(procedureStatuses, (procedureStatus) => procedureStatus.id == id)
    if (procedureStatus && procedureStatus.link) {
      this.props.history.push(procedureStatus.link)
    }
  }

  render() {
    const {procedureStatuses} = this.props
    return (
      <div>
        <ListData />
        <div className="l-title-wrap">
          <div className="l-wrap-xxl">
            <h1 className="m-title-main">進行中の手続き一覧</h1>
          </div>
        </div>
        <div className="l-wrap-xs">
          <div className="l-overflow-scroll">
            <table className={styles.list + ' m-table-list'}>
              <thead>
                <tr>
                  <th>日時</th>
                  <th>手続き</th>
                  <th>ステータス</th>
                </tr>
              </thead>
              <tbody>
                {procedureStatuses.map((procedureStatus) => (
                  <Row procedureStatus={procedureStatus} key={procedureStatus.id} handleClick={this.handleRowClick} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  procedureStatuses: state.procedureStatuses.list.data,
  count: state.procedureStatuses.list.count,
  token: state.auth.token,
})

export default connect(mapStateToProps, (dispatch) => {
  return {
    async setFlag(token, id, flag) {
      try {
        dispatch(actionCreators.procedureStatuses.setFlag({id, flag}))
        await api.createWithAuth(token).procedureStatuses.update(id, {flag})
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  }
})(ListNotAdmin)
