import {Hint} from 'jbc-front/components/presenters/ui/Hint'
import styles from './PreparingColumn.scss'

export const PreparingColumn = () => (
  <>
    準備中
    <Hint className={styles.hint}>
      署名済の書類をプレビュー・ダウンロードする準備をしています。時間をおいて再度ご確認ください。
    </Hint>
  </>
)
