import React, {useState} from 'react'
import {connect, useSelector, useDispatch} from 'react-redux'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import EmployeeFieldGroupPermissionForm, {
  makeInitialValues,
  submit,
} from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupPermissionForm'
import styles from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupPermission.scss'
import {Setting} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/Modal'

export const EmployeeFieldGroupPermission = ({field, loadData}) => {
  const [showModal, setShowModal] = useState(false)
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()

  const updatePermissions = async (fieldId, values) => {
    try {
      await api.createWithAuth(token).employeeFieldGroups.update(fieldId, values)
      notifySuccess('保存しました') |> dispatch
    } catch (err) {
      asyncError(err) |> dispatch
    }
  }

  const handleSubmit = (values) => updatePermissions(field.id, values)

  return (
    <div className={styles.setting}>
      <Setting onClick={() => setShowModal(true)} className="u-cur-pointer" />
      <EmployeeFieldGroupPermissionModal
        label={field && field.label}
        canUpdate={field && field.mutable_by_employee}
        canHide={field && field.code !== 'basic_infomation'}
        isOpen={showModal}
        onSubmit={(values) => {
          handleSubmit(values).then(() => {
            setShowModal(false)
            loadData()
          })
        }}
        hideModal={() => setShowModal(false)}
        initialValues={makeInitialValues(field)}
      />
    </div>
  )
}

export const EmployeeFieldGroupPermissionModal =
  (({label, canUpdate, canHide, isOpen, onSubmit, submitForm, initialValues, ...props}) => (
    <Modal
      body={
        isOpen ? (
          <EmployeeFieldGroupPermissionForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            canUpdate={canUpdate}
            canHide={canHide}
          />
        ) : null
      }
      header={label ? `「${label}」の権限設定` : 'フィールドの権限設定'}
      cancel="キャンセル"
      submit="保存"
      isOpen={isOpen}
      onSubmit={() => submitForm()}
      {...props}
    />
  ))
  |> connect(null, (dispatch) => ({
    submitForm() {
      dispatch(submit())
    },
  }))
