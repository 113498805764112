import React, {useState} from 'react'
import {Section, DateField, CheckboxField} from 'jbc-front/components/Form'
import {formValueSelector, reduxForm} from 'redux-form'
import Button from 'jbc-front/components/Button'
import {toFormValues} from 'utils'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'
import styles from './R147Report.scss'

const formName = 'R147Report'
const selector = formValueSelector(formName)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, employee, needUpgrade, needUpdateResidentCard}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">健康保険 被保険者 被扶養者住所登録・変更届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <DateField name="submit_on" label="書類提出日" />
          {needUpdateResidentCard && (
            <>
              <CheckboxField name="update_resident_card" disabled label={'住民票住所も更新する'} />
              <span className={styles.warningText}>住民票住所の入力が必須の帳票のため、住民票住所も更新されます</span>
            </>
          )}
          <div className="u-ta-c u-mt30">
            <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => handleSubmit()}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})

const connectedForm = connectForm('r147', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    needUpgrade: selector(state, 'need_upgrade'),
    needUpdateResidentCard: selector(state, 'need_update_resident_card'),
  }),
  makeInitialValues: ({data, needUpdateResidentCard}) =>
    toFormValues({...data, update_resident_card: needUpdateResidentCard}),
})

export default connectedForm
