import React, {Component} from 'react'
import Form, {autofill, isSubmitting, makeInitialValuesFromTmpData, formatValues} from 'procedures/changeName/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import Button from 'jbc-front/components/Button'
import checkEmployeeConfirm from 'components/checkEmployeeConfirm'
import compose from 'lodash/fp/compose'
import {asyncError} from 'store/actions/asyncError'

class Confirm extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const {
      loadTmpData,
      token,
      match: {
        params: {id},
      },
      loadComments,
    } = this.props
    loadTmpData(id, token)
    loadComments(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      submitting,
      match: {
        params: {id},
      },
      tmpData: {application_procedure_change_name: changeNameData},
      comments,
      updateAndAccept,
      token,
    } = this.props
    return (
      <div>
        <CommentModal procedureStatusId={id} comments={comments || []} procedureType="changeName">
          {({showModal}) => (
            <Form
              onSubmit={(values) => updateAndAccept(id, values, null, token)}
              initialValues={makeInitialValuesFromTmpData(changeNameData)}
              comments={comments || {}}
              submitText="承認して次へ"
              otherButtons={
                <Button disabled={submitting} onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
            />
          )}
        </CommentModal>
      </div>
    )
  }
}

export default compose(
  checkEmployeeConfirm({procetureType: 'change_name'}),
  connect(
    (state) => ({
      tmpData: state.procedureStatuses.tmpData.data,
      comments: fetchSelector(state, 'procedure_comments').data,
      submitting: isSubmitting(state),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadTmpData(id, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            api
              .createWithAuth(token)
              .procedureStatuses.tmpData.mapToJson(id, {type: 'application', name: 'procedure_change_name'})
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
      },
      updateAndAccept: async (procedureStatusId, values, comment, token) => {
        try {
          const {dateOn, employee, procedureData} = formatValues(values)
          const authedApi = api.createWithAuth(token)
          await authedApi.procedureStatuses.update(procedureStatusId, {date_on: dateOn})
          if (procedureData.id) {
            await authedApi.procedureStatuses.changeNameData.update(procedureStatusId, procedureData)
          } else {
            const {
              data: {id},
            } = await authedApi.procedureStatuses.changeNameData.create(procedureStatusId, procedureData)
            dispatch(autofill('procedure_data.id', id))
          }
          const {data: procedureStatus} = await authedApi.procedureStatuses.get(procedureStatusId)
          await authedApi.employees.update(procedureStatus.employee_id, employee, [])
          await authedApi.procedureStatuses.accept(procedureStatus.id)
          dispatch(push(`/change_name/flow/${procedureStatusId}`))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(Confirm)
