import React from 'react'
import {useDispatch} from 'react-redux'
import {Redirect} from 'react-router'
import {push} from 'connected-react-router'
import {parse, stringify} from 'query-string'
import Paginator from 'jbc-front/components/Paginator'
import {SortableTh} from 'jbc-front/components/SearchForm'
import {useQuery} from 'components/Graphql'
import LoadingPage from 'components/LoadingPage'
import {recordDisplay} from 'utils'
import {sortTypeFromQuery} from 'documents/ShowContracts'
import PerPageWithResult from 'documents/contract/PerPageWithResult'
import {CONTRACT_DOCUMENT_TARGET} from 'documents/contract/query'
import {getSavedDisplayEmployeeLimit} from 'documents/contract/utils'
import styles from 'documents/Contract.scss'

const ContractTargetList = ({search, pathname, id, history, currentUser}) => {
  const dispatch = useDispatch()
  const limit = getSavedDisplayEmployeeLimit(pathname, currentUser)
  const query = parse(search)
  const [sort, order] = sortTypeFromQuery(query)
  const currentSortType = `${sort}__${order}`
  const {data, error, loading} = useQuery(CONTRACT_DOCUMENT_TARGET, {
    fetchPolicy: 'network-only',
    variables: {
      id: id,
      page: +query.page || 1,
      per: +limit || 10,
      search: {sortOrder: order, sortColumn: sort},
    },
  })
  const document = data?.client?.document
  const targetEmployees = document?.contractTargets?.list
  const totalCount = document?.contractTargets?.totalCount
  const handleChangeSortType = (sortType) => {
    const [sort, order] = sortType.split('__')
    history.push({pathname, search: stringify({...query, sort, order})})
  }

  if (error) {
    return <Redirect to="/documents" />
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <>
      <PerPageWithResult
        total={totalCount}
        page={+query.page || 1}
        loading={loading}
        onChange={() => dispatch(push({pathname, search: stringify({...query, page: 1})}))}
      />
      <div className="l-overflow-scroll">
        <table className={`m-table-list m-table-fixed`}>
          <thead>
            <tr>
              <SortableTh
                field="staff_code"
                onChange={handleChangeSortType}
                currentSortType={currentSortType}
                globalClassName={styles.staffCodeColumn}
              >
                スタッフコード
              </SortableTh>
              <SortableTh
                field="full_name_kana"
                onChange={handleChangeSortType}
                currentSortType={currentSortType}
                globalClassName={styles.nameColumn}
              >
                氏名
              </SortableTh>
              <SortableTh
                field="email"
                onChange={handleChangeSortType}
                currentSortType={currentSortType}
                globalClassName={styles.emailColumn}
              >
                電子契約送信先
              </SortableTh>
            </tr>
          </thead>
          <tbody>
            {targetEmployees && document?.contractTargets?.totalCount > 0 ? (
              targetEmployees.map((employee) => (
                <tr key={employee.id}>
                  <td className={styles.staffCodeColumn}>{recordDisplay(employee.staff_code)}</td>
                  <td className={styles.nameColumn}>{recordDisplay.fullName(employee)}</td>
                  <td className={styles.emailColumn}>{recordDisplay(employee.email)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3}>電子契約を送信可能な従業員が存在しません</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="u-mt30">
        <Paginator
          current={+query.page}
          rowsPerPage={limit}
          onClick={(page) => dispatch(push({pathname, search: stringify({...query, page})}))}
          totalResult={totalCount}
        />
      </div>
    </>
  )
}

export default ContractTargetList
