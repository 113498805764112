import React from 'react'
import Flow from 'procedures/Flow'
import _ from 'lodash'

export const makeFlow = function* (employee) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')

  if (healthInsuranceJoined) {
    if (healthInsuranceType === 'its') {
      yield 'R5'
    } else {
      yield 'R4'
    }
  }
}

const AddDependentFlow = ({location, match}) => (
  <Flow
    {...{makeFlow, location, match}}
    embed="procedure_remove_dependents_data"
    params={{employee_dependent_removed: [1]}}
  />
)

export default AddDependentFlow
