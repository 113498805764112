import {FC} from 'react'
import {Table} from 'components/ui/Table'
import {Row} from './Row'
import {Document, SortColumn, Order} from '../../query'

interface DocumentListProps {
  documents: Document[]
  onSort: (column: SortColumn) => void
  order: Order
  sortColumn: SortColumn
}

export const DocumentList: FC<DocumentListProps> = ({documents, onSort, order, sortColumn}) => {
  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.SortableTh
            onClick={() => onSort('flag')}
            order={order}
            active={sortColumn == 'flag'}
            width="5%"
          ></Table.SortableTh>

          <Table.Th width="5%">メモ</Table.Th>

          <Table.SortableTh
            onClick={() => onSort('created_at')}
            order={order}
            active={sortColumn == 'created_at'}
            width="10%"
          >
            作成日
          </Table.SortableTh>

          <Table.SortableTh onClick={() => onSort('title')} order={order} active={sortColumn == 'title'} width="28%">
            書類名
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('document_employees_count')}
            order={order}
            active={sortColumn == 'document_employees_count'}
            width="10%"
          >
            対象従業員数
          </Table.SortableTh>

          <Table.Th width="10%">マイページ公開</Table.Th>

          <Table.Th width="7%">電子契約</Table.Th>

          <Table.SortableTh
            onClick={() => onSort('visibility')}
            order={order}
            active={sortColumn == 'visibility'}
            width="10%"
          >
            閲覧範囲
          </Table.SortableTh>

          <Table.SortableTh
            onClick={() => onSort('created_user')}
            order={order}
            active={sortColumn == 'created_user'}
            width="15%"
          >
            作成者
          </Table.SortableTh>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {documents.length > 0 ? (
          documents.map((document) => <Row key={`document-list-row-${document.id}`} document={document} />)
        ) : (
          <tr>
            <td colSpan={9}>書類がありません</td>
          </tr>
        )}
      </Table.Tbody>
    </Table>
  )
}
