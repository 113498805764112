import {FC} from 'react'
import {QueryVariables, SearchDocumentEmployee} from '../../query'
import {dateFormat, displayFormat, fullNameFormat} from 'libs/formatter'
import {Table} from 'components/ui/Table'
import {MypagePulbishColumn} from './MypagePublishColumn'

type RowProps = {
  searchDocumentEmployee: SearchDocumentEmployee
  hasBranchOffice: boolean
  publicDisabled: boolean
  variables: QueryVariables
}

export const Row: FC<RowProps> = ({searchDocumentEmployee, hasBranchOffice, publicDisabled, variables}) => {
  const [invitationStatus, firstViewedAt] = (() => {
    switch (searchDocumentEmployee.invitationStatus) {
      case 'registered': {
        const firstViewedAt = searchDocumentEmployee.documentEmployee.firstViewedAt
        const date = firstViewedAt ? dateFormat(firstViewedAt, 'L') : '未閲覧'
        return ['登録済み', date]
      }
      case 'invited':
        return ['招待済み', displayFormat()]
      default:
        return [
          <a key="employees_invite" href="/employees/invite" className="u-txt-link">
            未招待
          </a>,
          displayFormat(),
        ]
    }
  })()

  const group = (() => {
    const group0Name = searchDocumentEmployee.personnelHistory?.group0Name
    const group1Name = searchDocumentEmployee.personnelHistory?.group1Name
    const group2Name = searchDocumentEmployee.personnelHistory?.group2Name
    const groups = [group0Name, group1Name, group2Name].filter((groupName) => !!groupName)

    if (groups.length > 0) {
      return groups.map((group, index) => (
        <span key={index}>
          {group}
          <br />
        </span>
      ))
    } else {
      return displayFormat()
    }
  })()

  return (
    <Table.Tr>
      <Table.Td>{displayFormat(searchDocumentEmployee.staffCode)}</Table.Td>

      <Table.Td>
        {fullNameFormat(searchDocumentEmployee.displayLastName, searchDocumentEmployee.displayFirstName)}
      </Table.Td>

      {hasBranchOffice && <Table.Td>{displayFormat(searchDocumentEmployee.personnelHistory?.officeName)}</Table.Td>}

      <Table.Td>{group}</Table.Td>

      <Table.Td>{displayFormat(searchDocumentEmployee.personnelHistory?.employmentTypeName)}</Table.Td>

      <Table.Td>{dateFormat(searchDocumentEmployee.joinedAt, 'L')}</Table.Td>

      <Table.Td>{invitationStatus}</Table.Td>

      <Table.Td>{firstViewedAt}</Table.Td>

      <Table.Td>
        <MypagePulbishColumn
          employeeId={searchDocumentEmployee.id}
          variables={variables}
          disabled={publicDisabled}
          isPublished={searchDocumentEmployee.documentEmployee.isPublished}
        />
      </Table.Td>
    </Table.Tr>
  )
}
