import {FC, useState} from 'react'
import {PdfPreview} from './PdfPreview'
import {PdfModal} from 'jbc-front/components/presenters/ui/PdfModal'
import {Preview} from 'jbc-front/components/icons'
import ActionButton from 'jbc-front/components/ActionButton'
import {Print, Download} from 'jbc-front/components/icons'
import {Document} from '../query'
import styles from './PreviewModal.scss'

type PreviewModalProps = {
  document: Document
  onDownload: () => Promise<void>
}

export const PreviewModal: FC<PreviewModalProps> = ({document, onDownload}) => {
  const url = `/api/employees/documents/${document.id}`

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const disabled = document.type == 'dynamic_document' && !document.dynamicDocument.downloadable

  const handlePrint = () => {
    window.open(`${url}?disposition=inline`, '', 'toolbar=0,menubar=0,location=0')
    window.addEventListener('load', () => window.print())
  }

  return (
    <>
      <i className="u-cur-pointer" onClick={handleOpen}>
        <Preview />
      </i>
      <PdfModal isOpen={isOpen}>
        <PdfModal.Header className={styles.header}>
          <PdfModal.Title className={styles.title}>{document.title}</PdfModal.Title>
          <PdfModal.ToolContainer className={styles.tools} onClose={handleClose}>
            <ActionButton primary icon={<Print size={15} />} onClick={handlePrint} disabled={disabled}>
              印刷
            </ActionButton>

            <ActionButton primary icon={<Download size={15} />} disabled={disabled} onClick={onDownload}>
              ダウンロード
            </ActionButton>
          </PdfModal.ToolContainer>
        </PdfModal.Header>
        <PdfPreview file={url} />
      </PdfModal>
    </>
  )
}
