import gql from 'graphql-tag'

export type QueryResult = {
  documentEmployees: DocumentEmployee[]
}

export type DocumentEmployee = {
  id: number
  publishedAt: string
  contractInfo?: ContractInfo
  document: Document
}

export type Document = {
  id: number
  title: string
  type: 'dynamic_document' | 'uploaded_document'
  dynamicDocument: DynamicDocument
  uploadedDocument: UploadedDocument
  createdUser?: CreatedUser
}

export type DynamicDocument = {
  id: number
  previewable: boolean
  downloadable: boolean
}

export type UploadedDocument = {
  id: number
  mimeType: string | null
}

export type CreatedUser = {
  id: number
  email: string
  employee: Employee
}

export type Employee = {
  id: number
  displayFirstName?: string
  displayLastName?: string
}

export type ContractInfo = {
  status: 'signed' | 'unsigned' | 'canceled'
  downloadableSignedDocument: boolean
}

export const DOCUMENT_EMPLOYEES = gql`
  query documentEmployees {
    documentEmployees {
      id
      publishedAt
      contractInfo {
        status
        downloadableSignedDocument
      }
      document {
        id
        title
        type
        dynamicDocument {
          id
          previewable
          downloadable
        }
        uploadedDocument {
          id
          mimeType
        }
        createdUser {
          id
          email
          employee {
            id
            displayFirstName
            displayLastName
          }
        }
      }
    }
  }
`
