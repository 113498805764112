import React from 'react'
import {Link} from 'react-router-dom'
import {HEALTH_AND_WELFARE_PENSION, OTHER} from 'procedureStatuses/reportList/common'
import _ from 'lodash'
import {getRelatedDependents} from 'procedures/changeDependents/utils'
import ListGroup from 'jbc-front/components/ListGroup'

export const getReportList = function* ({
  procedureStatus,
  healthInsuranceType,
  healthInsuranceJoined,
  welfarePensionInsuranceJoined,
}) {
  const dependents = getRelatedDependents(_.get(procedureStatus, 'employee.employee_dependents'), procedureStatus)
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')
  const employee = procedureStatus.employee

  if (
    (healthInsuranceType === 'kyokai' && (healthInsuranceJoined || welfarePensionInsuranceJoined)) ||
    (healthInsuranceType === 'other' && healthInsuranceJoined)
  ) {
    yield {
      num: 'r4',
      title:
        healthInsuranceType === 'kyokai'
          ? '健康保険被扶養者(異動)･国民年金第３号被保険者関係届'
          : '健康保険被扶養者（異動）届',
      editable: true,
      type: HEALTH_AND_WELFARE_PENSION,
    }
  }
  if (healthInsuranceJoined) {
    if (healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') {
      yield {
        num: 'r46',
        title: '健康保険被保険者証回収不能届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
    if (healthInsuranceType === 'its') {
      yield {
        num: 'r5',
        title: '被扶養者（異動）届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
      yield {
        num: 'r47',
        title: '健康保険被保険者証滅失届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
  }
  if (spouse && welfarePensionInsuranceJoined && healthInsuranceType !== 'kyokai') {
    yield {
      num: 'r6',
      title: '国民年金第３号被保険者関係届',
      editable: true,
      type: HEALTH_AND_WELFARE_PENSION,
    }
  }
  if (employee.employee_tax_classification?.tax_table_type === 'main_income') {
    yield {
      num: 'r132',
      title: '給与所得者の扶養控除等（異動）申告書',
      editable: false,
      type: OTHER,
    }
  }
}

const ProcedureInfo = ({procedureStatus}) => (
  <ListGroup>
    <ListGroup.Title>扶養者情報</ListGroup.Title>
    <ListGroup.Item as={Link} to={`/remove_dependents/update/${procedureStatus.id}`}>
      扶養者情報の編集
    </ListGroup.Item>
  </ListGroup>
)

export default {getReportList, ProcedureInfo}
