import {combineReducers} from 'redux'
import fetchReducer from 'reducers/fetchReducer'
import queryReducer from 'reducers/queryReducer'
import {actionTypes} from 'actions'
import {getQueryDiffFactory} from 'utils'

const defaultQuery = {
  page: 1,
}

export default combineReducers({
  list: fetchReducer(actionTypes.TM.NOTIFICATIONS.LIST, {data: [], count: 0}),
  current: fetchReducer(actionTypes.TM.NOTIFICATIONS.CURRENT, {data: {}}),
  answers: fetchReducer(actionTypes.TM.NOTIFICATIONS.ANSWERS, {data: [], count: 0}),
  answer: fetchReducer(actionTypes.TM.NOTIFICATIONS.ANSWER, {data: [], count: 0}),
  status: fetchReducer(actionTypes.TM.NOTIFICATIONS.STATUS, {data: {}}),
  query: queryReducer(actionTypes.TM.NOTIFICATIONS.SET_QUERY, defaultQuery),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
