import {useLayoutEffect} from 'react'

/**
 * Reduxをストアとした非同期データ取得・クリーンアップを行う時に使うuseEffect。
 *
 * React 17以降、useEffectのクリーンアップは非同期実行されるため、自身がアンマウントされた後に入れ替わりでマウントされたコンポーネントのcomponentDidMountより後に実行されることがある。
 * これにより、すでに新しい非同期処理のステートが書き込まれている所にクリーンアップ処理をかけて消してしまうバグが発生する。
 *
 * useLayoutEffect相当のタイミングでクリーンアップ処理を行うことで、パフォーマンスを犠牲にしてこのバグを回避することができる。
 */
export const useFetchEffect = useLayoutEffect
