import React, {Component} from 'react'
import {Card, Container} from 'components/SelectCard'
import {actionCreators} from 'actions'
import api from 'api'
import {connect} from 'react-redux'
import {MaternityStart, MaternityChange} from 'jbc-front/components/icons'
import {push} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'

class ChooseProcedureTypeForMember extends Component {
  componentDidMount() {
    const {loadProcedures, token} = this.props
    loadProcedures(token)
  }

  render() {
    const {procedureStatuses, selectProcedureType} = this.props
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">産休の手続きを選択</h1>
          <p className="m-title-main-note">産休の手続きの種類を選んでください</p>
        </div>
        <Container>
          <Card
            icon={MaternityStart}
            onClick={() => {
              selectProcedureType('start_maternity')
            }}
          >
            産休の開始
            <br />
            （出産予定日の連絡）
          </Card>
          {procedureStatuses.length > 0 && (
            <Card
              icon={MaternityChange}
              onClick={() => {
                selectProcedureType('change_maternity')
              }}
            >
              産休の届出情報の変更
              <br />
              （出産日の報告）
            </Card>
          )}
        </Container>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    procedureStatuses: state.procedureStatuses.list.data || [],
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadProcedures(token) {
      api
        .createWithAuth(token)
        .employees.getCurrent(['office'])
        .then((res) => {
          if (res?.data) {
            dispatch(
              actionCreators.procedureStatuses.list.fetchData(
                api
                  .createWithAuth(token)
                  .procedureStatuses.list({procedure_type: 'start_maternity', employee_id: res.data.id}, [
                    'employee',
                    'procedure_maternity_datum',
                  ])
              )
            )
          }
        })
    },
    selectProcedureType(procedureType) {
      try {
        if (procedureType === 'start_maternity') {
          dispatch(push('/start_maternity/employee_input'))
        } else {
          dispatch(push('/change_maternity/choose_procedure'))
        }
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(ChooseProcedureTypeForMember)
