import {connect} from 'react-redux'
import {SortingFieldsWithResult} from 'documents/create/list/SearchForm'
import {selector} from 'documents/create/list/utils'

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: state.employees.list.count,
  page: selector(state, 'page'),
  isLoading: state.employees.list.loading,
})
export default connect(mapStateToProps)(SortingFieldsWithResult)
