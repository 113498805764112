import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import Loading from 'components/Loading'
import styles from './YearEndAdjStart.scss'

class YearEndAdjStart extends Component {
  state = {
    creating: false,
    loading: true,
    error: false,
  }

  async componentDidMount() {
    const {token, dispatch} = this.props
    try {
      this.setState({loading: true})
      const {
        data: {status, link},
      } = await api.createWithAuth(token).YearEndAdj.start()
      if (status === 'started') {
        window.location = link
      } else {
        this.setState({loading: false})
      }
    } catch (err) {
      dispatch(asyncError(err))
      this.setState({loading: false, error: true})
    }
  }

  create = async () => {
    if (this.state.creating) {
      return
    }
    const {dispatch, token} = this.props
    try {
      this.setState({creating: true})
      const {
        data: {status, link},
      } = await api.createWithAuth(token).YearEndAdj.create()
      if (status === 'started') {
        window.location = link
      }
    } catch (err) {
      dispatch(asyncError(err))
    } finally {
      this.setState({creating: false})
    }
  }

  render() {
    if (this.state.loading || this.state.error) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">{this.state.loading ? <Loading /> : 'エラーが発生しました'}</div>
        </div>
      )
    }
    return (
      <React.Fragment>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">年末調整</h1>
          <p className="m-title-main-note" />
        </div>
        <div className="l-contents-wrap">
          <div className={styles.wrap}>
            <p className={styles.text}>
              ジョブカンの年末調整！
              <br />
              ジョブカン労務HR・給与計算に登録された情報をもとに年末調整を行うことができます。
              <br />
              年末調整を依頼されたら従業員は画面の手順に従って情報を入力するだけで書類が作成できます。
              <br />
              支払金額と控除額を計算し、過不足額の精算や役所提出用書類の作成まで自動化します。
            </p>
            <p className={styles.notice}>
              【注意】本社の事業所情報をもとに年末調整が行われますので、本社の事業所情報が正しく入力されているか予めご確認ください。
            </p>
            <div className={styles.buttons}>
              <Button primary large widthWide as={Link} to="/client" disabled={this.state.creating}>
                事業所情報を確認する
              </Button>
              <div className={styles.start}>
                <Button primary large widthWide disabled={this.state.creating} onClick={this.create}>
                  年末調整を開始する
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default connect((state) => ({token: state.auth.token}))(YearEndAdjStart)
