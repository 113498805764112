import React from 'react'
import styles from './AsyncTask.scss'
import {Close} from 'jbc-front/components/icons'

const AsyncTask = ({error, done, message, text, onCloseClick}) => (
  <div className={styles.wrap}>
    <div className={error ? styles.circleError : done ? styles.circleDone : styles.circle}>
      <div className="sk-circle1 sk-child" />
      <div className="sk-circle2 sk-child" />
      <div className="sk-circle3 sk-child" />
      <div className="sk-circle4 sk-child" />
      <div className="sk-circle5 sk-child" />
      <div className="sk-circle6 sk-child" />
      <div className="sk-circle7 sk-child" />
      <div className="sk-circle8 sk-child" />
      <div className="sk-circle9 sk-child" />
      <div className="sk-circle10 sk-child" />
      <div className="sk-circle11 sk-child" />
      <div className="sk-circle12 sk-child" />
      <span className={styles.text}>{text}</span>
    </div>
    <div className={styles.message}>
      {message}
      {(error || done) && (
        <a className={styles.close} onClick={onCloseClick}>
          <Close />
        </a>
      )}
    </div>
  </div>
)

export default AsyncTask
