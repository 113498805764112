import {FC} from 'react'
import {usePdfPreview} from 'hooks/usePdfPreview'
import {Pdf} from 'jbc-front/components/presenters/ui/Pdf'

type PdfPreviewProps = {
  file: string
}

export const PdfPreview: FC<PdfPreviewProps> = ({file}) => {
  const {loading, canvasRef, controllerProps} = usePdfPreview(file)

  return (
    <Pdf.Container>
      <Pdf loading={loading} ref={canvasRef} />
      <Pdf.ControllerContainer>
        <Pdf.Controller {...controllerProps} />
      </Pdf.ControllerContainer>
    </Pdf.Container>
  )
}
