import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Error} from 'jbc-front/components/presenters/form/Error'
import {Hint} from 'jbc-front/components/presenters/ui/Hint'
import Button from 'jbc-front/components/Button'

import {DOCUMENT_EMPLOYEES, UPDATE_ALL_DOCUMENT_EMPLOYEE_PUBLISHED, QueryVariables} from '../../query'
import styles from './AllPublishModal.scss'
import classNames from 'classnames'
import useRouter from 'use-react-router'
import {notifySuccess} from 'store/actions/notify'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface PublishModalProps {
  isOpen: boolean
  onClose: () => void
  variables: QueryVariables
  documentEmployeesPublishedCount: number
  documentEmployeesCount: number
}

interface PublishFormSchema {
  comment: string
}

export const AllPublishModal: FC<PublishModalProps> = ({
  isOpen,
  onClose,
  variables,
  documentEmployeesPublishedCount,
  documentEmployeesCount,
}) => {
  const {
    match: {params},
  } = useRouter<{documentId: string}>()
  const documentId = params.documentId

  const [published, {loading}] = useMutation(UPDATE_ALL_DOCUMENT_EMPLOYEE_PUBLISHED)
  const dispatch = useDispatch()

  const schema = yup.object({
    comment: yup.string().max(2000, '2000文字以内で入力してください'),
  })

  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm<PublishFormSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      comment: '',
    },
  })

  const onSubmit = async (data: PublishFormSchema) => {
    await published({
      variables: {
        input: {
          documentId: documentId,
          comment: data.comment,
        },
      },
      refetchQueries: [
        {
          query: DOCUMENT_EMPLOYEES,
          variables: variables,
          fetchPolicy: 'network-only',
        },
      ],
    })
    onClose()
    dispatch(notifySuccess('書類を公開しました'))
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <Modal.Header onClose={onClose}>書類を公開する</Modal.Header>
      <Modal.Body>
        <p className="u-mb15">
          公開するとマイページ登録済の従業員はマイページから書類を閲覧できるようになります。書類を公開しますか？
          <br />
          ※既に公開済みの従業員にメールは送信されません。
          <br />
          <br />
          公開される従業員：{documentEmployeesCount - documentEmployeesPublishedCount}名/{documentEmployeesCount}名
        </p>
        <p className="u-mb15">
          <span className={styles.caution}>
            マイページ未招待の従業員は、招待後に登録手続きを完了することで書類を閲覧できるようになります
          </span>
        </p>
        <Label className={classNames(styles.label, 'u-mb5')}>
          コメント
          <Hint leftPoint={-10}>メール本文にコメントを記載できます</Hint>
        </Label>
        <Textarea {...register('comment')} isError={!!errors.comment?.message} className={styles.textarea} />
        <div>最大2000文字</div>
        {errors.comment?.message && <Error error={errors.comment.message} />}
      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button onClick={onClose}>キャンセル</Button>
          <Button primary disabled={loading} onClick={handleSubmit(onSubmit)}>
            一括公開
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  )
}
