import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Show} from 'pages/withAuth/adminPage/documents/employees/show'

export const EmployeeRoutes: RouteParams[] = [
  {
    key: 'documents/employees/show',
    path: WITH_AUTH_ADMIN_PATHS.DOCUMENTS.EMPLOYEES.SHOW,
    component: Show,
    exact: true,
  },
]
