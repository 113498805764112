import React, {FC, ReactNode} from 'react'
import styles from './Header.scss'

interface HeaderProps {
  children: ReactNode
}

export const Header: FC<HeaderProps> = ({children}) => <div className={styles.header}>{children}</div>

interface ContentProps {
  children: ReactNode
}

export const Content: FC<ContentProps> = ({children}) => <div className={styles.content}>{children}</div>
