import {FC, useEffect} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {UPDATE_COMMENT, DOCUMENTS} from '../../query'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Button from 'jbc-front/components/Button'
import styles from './MemoForm.scss'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface UpdateMemoFormProps {
  id: number
  body: string
  reset: () => void
}

interface UpdateFormSchema {
  id: number
  body: string
}

export const MemoUpdateForm: FC<UpdateMemoFormProps> = ({id, body, reset}) => {
  const [update, {loading}] = useMutation(UPDATE_COMMENT)
  const {
    register,
    handleSubmit,
    setFocus,
    reset: formReset,
    formState: {isValid},
  } = useForm<UpdateFormSchema>({
    mode: 'onChange',
    defaultValues: {id, body},
    resolver: yupResolver(
      yup.object().shape({
        id: yup.number().required(),
        body: yup.string().required(),
      })
    ),
  })

  const onSubmit: SubmitHandler<UpdateFormSchema> = async (data) => {
    await update({
      variables: {input: data},
      refetchQueries: [{query: DOCUMENTS}],
    })

    formReset()
    reset()
  }

  useEffect(() => {
    setFocus('body')
  }, [setFocus])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Textarea {...register('body')} className={styles.textarea} />
      <Spacer direction="y" size={20} />
      <Button onClick={handleSubmit(onSubmit)} primary disabled={loading || !isValid}>
        保存
      </Button>
    </form>
  )
}
