import Cookies from 'js-cookie'
import _ from 'lodash'

const defaultLimit = '10'

const limitKey = (pathname, user) => {
  const key = pathname.replace('/documents/', '').replace(/\d+/g, '').replace('/contracts/create', 'create_contracts')
  return `${key}#${user.id}__disp_contract_limit`
}

export const getSavedDisplayEmployeeLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user))
    if (!_.isEmpty(limit)) {
      return limit
    }
  }
  const limit = Cookies.get(limitKey(pathname, user))
  return _.isEmpty(limit) ? defaultLimit : limit
}

export const saveDisplayEmployeeLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit)
  } else {
    Cookies.set(limitKey(pathname, user), limit)
  }
}
