import React, {useState, useEffect} from 'react'
import Loading from 'components/Loading'
import styles from './lazy.scss'

let froala = null
const importFroala = () =>
  import(/* webpackChunkName: "froala" */ 'documentTemplates/froala').then((module) => {
    froala = module
  })

const useImportFroala = () => {
  const [importState, setImportState] = useState('loading')

  useEffect(() => {
    if (froala) {
      setImportState('success')
    } else {
      importFroala()
        .then(() => setImportState('success'))
        .catch(() => setImportState('failed'))
    }
  }, [])

  return importState
}

const lazy = (component) =>
  React.forwardRef((props, ref) => {
    const importState = useImportFroala()
    switch (importState) {
      case 'loading':
        return (
          <div className={styles.container}>
            <div className={styles.loading}>
              <Loading type="spin" color="#e3e3e3" />
            </div>
          </div>
        )
      case 'success': {
        const Component = component()
        return <Component ref={ref} {...props} />
      }
      case 'failed':
        return (
          <div className={styles.container}>
            <div className={styles.error}>
              エラーが発生しました。
              <br />
              ブラウザをリロードして再度お試しください。
            </div>
          </div>
        )
    }
  })

export const FroalaEditorField = lazy(() => froala.FroalaEditorField)

export const FroalaPreview = lazy(() => froala.FroalaPreview)
