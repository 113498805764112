import React, {Component} from 'react'
import {connect} from 'react-redux'
import Sidebar from 'jbc-front/components/Sidebar'
import {Link} from 'react-router-dom'
import {
  Dashboard,
  Employee,
  Procedure,
  Task,
  MyNumber,
  Notification,
  Setting,
  Close,
  Hamburger,
  EGov,
  Fileup,
  Adjustment,
  DocumentPreparation,
  BlankTarget,
} from 'jbc-front/components/icons'
import styles from 'components/Sidebar.scss'
import onClickOutside from 'react-onclickoutside'
import {isAdminSelector, isOfficeAdminSelector, isLimitedAdminSelector} from 'utils'
import StressCheckStartModal from '../StressCheckStartModal'
import debounce from 'lodash/debounce'
import classNames from 'classnames'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {MYPAGE_PATHS} from 'consts/paths'

class _RoumuSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSidebarOpen: false,
      hover: false,
    }
  }

  setHover = () => {
    this.unsetHover.cancel()
    this.setState({
      hover: true,
    })
  }

  unsetHover = debounce(() => {
    if (matchMedia('only screen and (min-width: 737px)').matches) {
      this.setState({
        hover: false,
      })
    }
  }, 200)

  setHoverSp = () => {
    if (!matchMedia('only screen and (min-width: 737px)').matches) {
      this.unsetHover.cancel()
      this.setState({
        hover: true,
      })
    }
  }

  handleClickOutside = () => {
    this.HideMenu()
  }

  handleTap = () => {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen,
      hover: false,
    })
  }

  HideMenu = () => {
    this.setState({
      isSidebarOpen: false,
    })
  }

  render() {
    const {
      isAdmin,
      currentUser: {employee},
      isOfficeAdmin,
      isLimitedAdmin,
      planType,
    } = this.props
    return (
      <div
        className={classNames(styles.fixation, !employee && styles.notHaveJobcanHeader)}
        onMouseEnter={this.setHover}
      >
        <Sidebar offset={Infinity} isOpen={this.state.isSidebarOpen} hover={this.state.hover} onClick={this.setHoverSp}>
          <Sidebar.Item as={Link} to="/dashboard" icon={Dashboard} onClick={this.HideMenu}>
            ダッシュボード
          </Sidebar.Item>
          {isAdmin && (
            <Sidebar.Menu icon={Employee} title="従業員管理">
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/employees">
                従業員一覧
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/employees/choose_add_method">
                新規登録
              </Sidebar.MenuItem>
              <Sidebar.MenuItem
                as={Link}
                onClick={this.HideMenu}
                to={WITH_AUTH_ADMIN_PATHS.EMPLOYEES.UPDATE_REQUESTS.INDEX}
              >
                情報更新
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/employees/invite">
                マイページ招待
              </Sidebar.MenuItem>
            </Sidebar.Menu>
          )}
          {isAdmin ? (
            <Sidebar.Menu icon={Procedure} title="新規の手続き">
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/enroll">
                入社の手続き
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/resign">
                退社の手続き
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/change_dependents">
                扶養の追加・削除
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/maternity_leave">
                産休の手続き
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/change_address">
                住所の変更
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/change_name">
                氏名の変更
              </Sidebar.MenuItem>
            </Sidebar.Menu>
          ) : !employee || employee.employment_status === 'unemployed' ? (
            <div />
          ) : (
            <Sidebar.Item as={Link} to="/procedure_new" icon={Procedure} onClick={this.HideMenu}>
              新規の手続き
            </Sidebar.Item>
          )}
          {isAdmin ? (
            <Sidebar.Menu icon={Task} title="進行中の手続き">
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/procedure_statuses">
                すべての手続き
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/procedure_statuses?procedure_type=enroll">
                入社の手続き
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/procedure_statuses?procedure_type=resign">
                退社の手続き
              </Sidebar.MenuItem>
              <Sidebar.MenuItem
                as={Link}
                onClick={this.HideMenu}
                to="/procedure_statuses?procedure_type=change_dependents"
              >
                扶養の追加・削除
              </Sidebar.MenuItem>
              <Sidebar.MenuItem
                as={Link}
                onClick={this.HideMenu}
                to="/procedure_statuses?procedure_type=maternity_leave"
              >
                産休の手続き
              </Sidebar.MenuItem>
              <Sidebar.MenuItem
                as={Link}
                onClick={this.HideMenu}
                to="/procedure_statuses?procedure_type=change_address"
              >
                住所の変更
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/procedure_statuses?procedure_type=change_name">
                氏名の変更
              </Sidebar.MenuItem>
            </Sidebar.Menu>
          ) : (
            <Sidebar.Item as={Link} to="/procedure_statuses" icon={Task} onClick={this.HideMenu}>
              進行中の手続き
            </Sidebar.Item>
          )}
          {isAdmin ? (
            <Sidebar.Menu icon={DocumentPreparation} title="書類作成">
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/documents/create">
                書類新規作成
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/documents">
                書類一覧
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/document_employees">
                書類一覧（従業員別）
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to={MYPAGE_PATHS.DOCUMENTS.INDEX}>
                書類一覧（マイページ）
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/document_templates">
                テンプレート一覧
              </Sidebar.MenuItem>
            </Sidebar.Menu>
          ) : (
            <Sidebar.Item
              as={Link}
              to={MYPAGE_PATHS.DOCUMENTS.INDEX}
              icon={DocumentPreparation}
              onClick={this.HideMenu}
            >
              書類一覧
            </Sidebar.Item>
          )}
          {isAdmin && (
            <Sidebar.Item
              as={Link}
              to={WITH_AUTH_ADMIN_PATHS.EGOV_PROCEDURES.INDEX}
              icon={EGov}
              onClick={this.HideMenu}
            >
              電子申請一覧
            </Sidebar.Item>
          )}
          {isAdmin && !isLimitedAdmin && (
            <Sidebar.Menu icon={MyNumber} title="マイナンバー">
              <Sidebar.MenuItem
                as={Link}
                onClick={this.HideMenu}
                to="/my_number?my_number_employment_status=employed_and_joined"
              >
                従業員
              </Sidebar.MenuItem>
              <Sidebar.MenuItem as={Link} onClick={this.HideMenu} to="/my_number/business_partners">
                取引先
              </Sidebar.MenuItem>
            </Sidebar.Menu>
          )}
          {isAdmin && <StressCheckStartModal />}
          {isAdmin && !isOfficeAdmin && planType !== 'free_plan' && (
            <Sidebar.Item
              as={Link}
              to="/year_end_adj_start"
              target="_blank"
              icon={Adjustment}
              rightIcon={BlankTarget}
              onClick={this.HideMenu}
            >
              年末調整
            </Sidebar.Item>
          )}
          <Sidebar.Item as={Link} to="/notifications" icon={Notification} onClick={this.HideMenu}>
            通知
          </Sidebar.Item>
          {isAdmin && !isLimitedAdmin && (
            <Sidebar.Item as={Link} to="/settings" icon={Setting} onClick={this.HideMenu}>
              設定
            </Sidebar.Item>
          )}
          {!isAdmin && (
            <Sidebar.Item as={Link} to="/employee_files" icon={Fileup} onClick={this.HideMenu}>
              ファイル共有
            </Sidebar.Item>
          )}
        </Sidebar>
        <div onClick={this.handleTap} className={styles.menuBtn}>
          {this.state.isSidebarOpen ? <Close size={20} /> : <Hamburger size={20} />}
        </div>
      </div>
    )
  }
}

const RoumuSidebar = onClickOutside(_RoumuSidebar)

export default connect((state) => ({
  currentUser: state.session.currentUser,
  isAdmin: isAdminSelector(state),
  isOfficeAdmin: isOfficeAdminSelector(state),
  isLimitedAdmin: isLimitedAdminSelector(state),
  planType: state.client.current.plan_type,
}))(RoumuSidebar)
