import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'

import {FileField, Section} from 'jbc-front/components/Form'
import {reduxForm, getFormSubmitErrors as _getFormSubmitErrors, SubmissionError} from 'redux-form'

import styles from 'employees/FileImport.scss'
import api from 'api'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {Upload} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/CommonModal'
import Loading from 'components/Loading'
import _ from 'lodash'
import sample from '../../../samples/business_partner.csv'

const formName = 'business_partner_import'
const getFormSubmitErrors = _getFormSubmitErrors(formName)

export const FileImportDescription = ({update}) => (
  <div className={styles.tableWrap}>
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>管理コード{update && <span className={styles.required}>必須</span>}</th>
            <td>-</td>
            <td>111</td>
          </tr>
          <tr>
            <th>
              取引先名<span className={styles.required}>必須</span>
            </th>
            <td>-</td>
            <td>山田 太郎</td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>-</td>
            <td>yamada@lms.jobcan.ne.jp</td>
          </tr>
          <tr>
            <th>取引開始日</th>
            <td>半角数字 (yyyy/mm/dd)</td>
            <td>2010/4/1</td>
          </tr>
          <tr>
            <th>取引終了日</th>
            <td>半角数字 (yyyy/mm/dd)</td>
            <td>2011/4/1</td>
          </tr>
          <tr>
            <th>マイナンバー</th>
            <td>半角数字13桁以内</td>
            <td>123412341234</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

export const validate = (values) => {
  const errors = {}
  if (_.isEmpty(values.file)) {
    errors.file = 'ファイルを選択してください'
  }
  return errors
}

const initialValues = {csv_update: false}

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate,
})(({submitting, handleSubmit}) => (
  <form onSubmit={handleSubmit} className={`u-mt20 ${styles.form}`}>
    <div className="u-mb40">
      <FileField name="file" />
    </div>
    <Modal
      isOpen={submitting}
      bodyOpenClassName={styles.loadingModalBody}
      className={styles.loadingModal}
      overlayClassName={styles.loadingModalOverlay}
    >
      <Modal.Body>
        <Loading />
      </Modal.Body>
    </Modal>
    <Button as="button" primary type="submit" disabled={submitting} icon={<Upload />} widthWide>
      取引先登録
    </Button>
  </form>
))

class FileImport extends Component {
  handleSubmit = async (values) => {
    const {token, dispatch} = this.props

    try {
      await api.createWithAuth(token).businessPartners.import(values)
      dispatch(push('/my_number/business_partners'))
      dispatch(notifySuccess('インポートしました'))
    } catch (err) {
      if (err.response && err.response.status == 422 && _.get(err.response, 'data._errors')) {
        throw new SubmissionError(err.response.data._errors)
      }
      dispatch(asyncError(err))
    }
  }

  render() {
    const {errors} = this.props
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">新規取引先登録</h1>
        </div>
        {errors && errors.rows && (
          <Section title="ファイル内容に誤りがあります" error>
            <ul>
              {errors.rows.map((err) => (
                <li key={err.index}>
                  第{err.index + 1}行：{err.name}
                  {err.message}
                </li>
              ))}
            </ul>
          </Section>
        )}
        <div className={`u-mt30 u-ta-c ${styles.mainNoteWrap}`}>
          <div className={styles.mainNoteTitle}>新規に登録したい取引先情報を記載したファイルを選択してください</div>
          <div className={styles.mainNoteBody}>
            <div className={styles.mainNote}>対応しているファイル形式はCSVとなります</div>
            <div className={styles.mainNote}>
              <a className="u-txt-link" href={sample} target="_blank" rel="noopener" download="sample.csv">
                サンプルをダウンロード
              </a>
            </div>
            <Form onSubmit={this.handleSubmit} initialValues={initialValues} />
          </div>
        </div>
        <div className="u-mt100">
          <FileImportDescription />
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  errors: getFormSubmitErrors(state),
}))(FileImport)
