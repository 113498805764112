import React, {useState} from 'react'
import {TextAreaField, Section} from 'jbc-front/components/Form'
import {reduxForm, formValueSelector} from 'redux-form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import _ from 'lodash'
import EmployeeName from 'procedures/EmployeeName'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R14Report'
const selector = formValueSelector(formName)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, dependents, employee, needUpgrade}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }
  const spouse = dependents && dependents.find((dependent) => dependent.relation_type === 'spouse')
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">健康保険・厚生年金保険　被保険者住所変更届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <TextAreaField name="notes" label="被保険者 備考" />
          {spouse && <TextAreaField name="spouse_notes" label="配偶者 備考" />}
          <SubmitedOn />
          <div className="u-ta-c u-mt30">
            <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => {
          setOpenModal(false)
          handleSubmit()
        }}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
})

export default connectForm('r14', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    dependents: _.get(state, 'procedureStatuses.current.data.employee.employee_dependents'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
})
