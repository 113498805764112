import {useEffect, useState} from 'react'
import {AxiosRequestConfig} from 'axios'
import {useAuthAxios} from './useAuthAxios'

type FetchError = Error | undefined

type Options<H> = Partial<{
  defaultHeaders: H
  params: AxiosRequestConfig['params']
  immediate: boolean
}>

const defaultOptions = {
  immediate: true,
}

export const useFetchData = <T, H = undefined>(
  path: string,
  defaultResource: T,
  options: Options<H>
): {
  isLoading: boolean
  resource: T
  responseHeaders?: H
  totalCount?: number
  errors: FetchError
  getter: () => Promise<void>
} => {
  const {immediate, defaultHeaders, params} = {...defaultOptions, ...options}
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [resource, setResources] = useState<T>(defaultResource)
  const [responseHeaders, setResponseHeaders] = useState<H | undefined>(defaultHeaders)
  const [totalCount, setTotalCount] = useState<number | undefined>()
  const [errors, setErrors] = useState<FetchError>(undefined)
  const authAxios = useAuthAxios()

  const getter = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await authAxios.get(path, {params: params})
      const data: T = response.data
      setResources(data)
      const headers: H = response.headers
      setResponseHeaders(headers)

      const count = response.headers['x-total-count']
      if (count === undefined || count === null) {
        setTotalCount(undefined)
      } else {
        setTotalCount(Number(count))
      }
    } catch (e) {
      setErrors(e)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    immediate && getter()
  }, [])

  return {isLoading, resource, responseHeaders, totalCount, errors, getter}
}
