import React, {Component} from 'react'
import Form, {makeInitialValuesForUpdate, formatValues} from 'procedures/changeName/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'

class Update extends Component {
  componentDidMount() {
    const {
      loadProcedureStatus,
      match: {
        params: {id},
      },
      token,
    } = this.props
    loadProcedureStatus(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      procedureStatus: {employee, ...procedureStatus},
    } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={makeInitialValuesForUpdate(procedureStatus, employee)}
        submitText="保存"
        employee={employee || {}}
      />
    )
  }

  handleSubmit = (values) => {
    const {update, token, procedureStatus} = this.props
    return update(procedureStatus, values, token)
  }
}

export default connect(
  (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api.createWithAuth(token).procedureStatuses.get(id, ['employee', 'procedure_change_name_datum'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
    },
    update: async (procedureStatus, values, token) => {
      try {
        const {dateOn, employee, procedureData} = formatValues(values)
        const authedApi = api.createWithAuth(token)
        await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: dateOn})
        await authedApi.procedureStatuses.changeNameData.update(procedureStatus.id, procedureData)
        await authedApi.employees.update(procedureStatus.employee_id, employee, [])
        dispatch(push(`/procedure_statuses/${procedureStatus.id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Update)
