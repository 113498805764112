import React from 'react'
import {FormSection} from 'redux-form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {Section, TextField, BoxDouble, DateField} from 'jbc-front/components/Form'
import {number, maxLength} from 'validators'
import {Joined} from 'FormFields'
import {connect} from 'react-redux'
import {Description, withFormSelectors} from 'employees/form/common'
import {isAdminSelector} from 'utils'

const HealthInsuranceInfomation = ({
  procedureType,
  joined,
  isAdmin,
  description,
  description_color_by_rgb,
  retiredAt,
  diff = {},
}) =>
  isAdmin || joined ? (
    <Section title="健康保険">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <FormSection name="health_insurance">
        <LabelMapper name="health_insurance" label="健康保険の" />
        {isAdmin && <Joined outputsNote={procedureType === 'enroll'} diff={diff} />}
        {joined && (
          <div>
            <TextField
              name="health_insurance_insured_personnel_number"
              label="健康保険被保険者整理番号"
              validate={[number, maxLength(7)]}
              diff={diff.health_insurance_insured_personnel_number}
            />
            {procedureType !== 'enroll' && isAdmin && (
              <BoxDouble>
                <DateField name="start_on" label="資格取得日" diff={diff.start_on} required />
                <DateField name="end_on" label="資格喪失日" required={retiredAt} />
              </BoxDouble>
            )}
          </div>
        )}
      </FormSection>
    </Section>
  ) : null

export default HealthInsuranceInfomation
  |> connect((state, {selector}) => ({
    joined: selector(state, 'health_insurance.joined'),
    isAdmin: isAdminSelector(state),
    retiredAt: selector(state, 'retired_at'),
    diff: selector(state, '_diff.health_insurance'),
  }))
  |> withFormSelectors
