import React from 'react'
import styles from 'components/TertiaryPanel.scss'

export const Headding = ({children, icon}) => (
  <div className={styles.heading}>
    <div className={styles.tittle}>{children}</div>
    {icon && <span className={styles.leadIcon}>{icon}</span>}
  </div>
)

export const Panel = ({children}) => <div className={styles.base}>{children}</div>

export const Item = ({children}) => (
  <div className={styles.body}>
    <div className={styles.inner}>{children}</div>
  </div>
)
