import {useFetchData} from 'hooks/api/useFetchData'
import {CustomEmail} from 'types/api/settings/customEmails/customEmail'
import {MailType} from 'types/api/settings/defaultEmail/defaultEmail'

type Query = Partial<{
  email_type: MailType
}>

export const useFetchCustomEmails = (clientId: string, query?: Query) => {
  const path = `/api/clients/${clientId}/custom_emails`
  return useFetchData<CustomEmail[]>(path, [], {params: query})
}
