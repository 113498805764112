import {gql} from '@apollo/client'

const CONTRACT_EMPLOYEE_FRAGMENT = gql`
  fragment ContractEmployeeField on DocumentContractEmployee {
    id
    staff_code: staffCode
    first_name: firstName
    last_name: lastName
    display_last_name: displayLastName
    display_first_name: displayFirstName
    email: email
  }
`

const DOCUMENT_CONTRACT_FRAGMENT = gql`
  fragment DocumentContractField on DocumentEmployee {
    id
    employeeId
    contractEmployee {
      ...ContractEmployeeField
    }
    contractInfo {
      status
      gateway
      downloadableSignedDocument
      deletedAt
      deletedUser {
        id
        name
        employee {
          id
          displayFirstName
          displayLastName
        }
      }
    }
  }
  ${CONTRACT_EMPLOYEE_FRAGMENT}
`

const CONTRACT_INFO_FRAGMENT = gql`
  fragment ContractInfoField on ContractInfo {
    title
    senderName
    senderOfficeName
    comment
  }
`

export const SEARCH_DOCUMENT_CONTRACTS = gql`
  query searchDocumentContracts($id: ID!, $per: Int, $page: Int, $search: ContractSearchInput) {
    client {
      id
      document(id: $id) {
        id
        remindContractCount
        contractInfo {
          ...ContractInfoField
        }
        dynamicDocument {
          id
          previewable
        }
        searchDocumentContracts(per: $per, page: $page, search: $search) {
          totalCount
          list {
            ...DocumentContractField
          }
        }
      }
    }
  }
  ${CONTRACT_INFO_FRAGMENT}
  ${DOCUMENT_CONTRACT_FRAGMENT}
`

export const DELETE_CONTRACT = gql`
  mutation deleteContract($documentId: ID!, $employeeId: ID!) {
    deleteContract(input: {documentId: $documentId, employeeId: $employeeId}) {
      documentEmployee {
        ...DocumentContractField
      }
    }
  }
  ${DOCUMENT_CONTRACT_FRAGMENT}
`

export interface DeleteContractVariables {
  documentId: string
  employeeId: string
}
