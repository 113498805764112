import {FC} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useDispatch} from 'react-redux'

import {Label} from 'jbc-front/components/presenters/form/Label'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {ErrorMessage} from 'jbc-front/components/presenters/form/ErrorMessage'
import {Note} from 'jbc-front/components/presenters/ui/Note'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import classnames from 'classnames'
import {notifySuccess} from 'store/actions/notify'

import styles from './RemindModal.scss'
import {REMIND_DETAIL_INPUT_REQUEST, DETAIL_INPUT_REQUEST_WITH_FIELD_GROUPS} from '../../../../query'

// @ts-ignore
import {useMutation} from 'components/Graphql'

export type RemindModalProps = {
  id: number
  isOpen: boolean
  onClose: () => void
}

type Schema = {
  id: number
  comment?: string
}

export const RemindModal: FC<RemindModalProps> = ({isOpen, onClose, id}) => {
  const dispatch = useDispatch()
  const [remindRequest, {loading: remindSubmitting}] = useMutation(REMIND_DETAIL_INPUT_REQUEST, {
    refetchQueries: [DETAIL_INPUT_REQUEST_WITH_FIELD_GROUPS],
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      id,
      comment: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        id: yup.number().required(),
        comment: yup.string().max(2000, '2000文字以内で入力してください'),
      })
    ),
  })

  const handleClose = () => {
    reset()
    onClose()
  }

  const remind: SubmitHandler<Schema> = async (data) => {
    await remindRequest({
      variables: {
        input: {
          id: data.id,
          comment: data.comment,
        },
      },
    })
    dispatch(notifySuccess('リマインドメールを送信しました'))
    reset()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className={styles.modal}>
      <Modal.Header onClose={handleClose}>リマインドメール送信</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(remind)}>
          <Label className={classnames(styles.label, 'u-mb5')}>コメント</Label>
          <Textarea
            {...register('comment')}
            isError={!!errors.comment?.message}
            className={styles.textarea}
            placeholder="入力したメッセージは送信メールに反映されます"
          />
          {errors.comment?.message && <ErrorMessage>{errors.comment.message}</ErrorMessage>}
          <Note>最大2000文字</Note>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button primary disabled={remindSubmitting} onClick={handleSubmit(remind)}>
            送信
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  )
}
