import React, {Component} from 'react'
import Form from 'procedures/changeDependents/add/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {dependentDefaultValue} from 'employees/form/common'
import {handlerNestFormResponse} from 'libs/errorHandler'
import {toFormValues} from 'utils'
import {asyncError} from 'store/actions/asyncError'

class Update extends Component {
  componentDidMount() {
    const {
      loadData,
      token,
      match: {
        params: {id},
      },
    } = this.props
    loadData(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  makeInitialValues() {
    const {procedureStatus} = this.props
    if (!procedureStatus.id) {
      return {dependents: [dependentDefaultValue()]}
    }
    const dependents = procedureStatus.employee.employee_dependents
      .filter(({id}) =>
        procedureStatus.procedure_add_dependents_data.find(({employee_dependent_id}) => id === employee_dependent_id)
      )
      .map(toFormValues)
    return {
      date_on: procedureStatus.date_on,
      dependents,
      marital_status: procedureStatus.employee.marital_status,
      spouse_annual_income: procedureStatus.employee.spouse_annual_income,
      spouse_monthly_income: procedureStatus.employee.spouse_monthly_income,
    }
  }

  render() {
    const {procedureStatus} = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={this.makeInitialValues()}
        submitText="保存"
        employee={procedureStatus.employee || {}}
      />
    )
  }

  handleSubmit = (values) => {
    const {update, procedureStatus, token} = this.props
    return update(procedureStatus, values, token)
  }
}

export default connect(
  (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadData: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api
            .createWithAuth(token)
            .procedureStatuses.get(id, ['employee', 'employee_dependents', 'procedure_add_dependents_data'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.procedureStatuses.current.destroy())
    },
    update: async (
      procedureStatus,
      {dependents, marital_status, spouse_annual_income, spouse_monthly_income, date_on: dateOn},
      token
    ) => {
      const authedApi = api.createWithAuth(token)
      try {
        await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: dateOn})
        await authedApi.procedureStatuses.addDependentsData
          .updateAll(procedureStatus.id, dependents)
          .catch(handlerNestFormResponse('dependents'))
        await authedApi.employees.update(procedureStatus.employee_id, {
          marital_status,
          spouse_annual_income,
          spouse_monthly_income,
        })
        dispatch(push(`/procedure_statuses/${procedureStatus.id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Update)
