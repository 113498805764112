import {Dl, Dd, Dt} from 'components/ui/DefinitionList'
import {displayFormat, fullNameFormat} from 'libs/formatter'

// jbc-front
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Input} from 'jbc-front/components/presenters/form/Input'
import {Error} from 'jbc-front/components/presenters/form/Error'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Section} from 'jbc-front/components/Form'
// @ts-ignore
import ListGroup from 'jbc-front/components/ListGroup'
// @ts-ignore
import {recordDisplay} from 'utils'

import type {FC} from 'react'
import type {FormState, UseFormRegister} from 'react-hook-form'
import type {ApplyFormSchema} from '../ApplyModal'
import type {EgovCertificateFile, EgovProcedure, EgovAccount} from '../../query'

interface ApplyFormDetailProps {
  formStep: FormStep
  formState: FormState<ApplyFormSchema>
  register: UseFormRegister<ApplyFormSchema>
  egovProcedure: EgovProcedure
  egovCertificateFile?: EgovCertificateFile
  egovAccount?: EgovAccount
  formatError?: FormatError
}

export interface FormatError {
  title: string
  detail: string
  reportList: {
    formName: string
    attachedFileName: string
    item: string
    content: string
  }[]
}

export type FormStep = 'attachments' | 'pinCode' | 'error'

export const ApplyFormDetail: FC<ApplyFormDetailProps> = ({
  formStep,
  register,
  formState,
  egovProcedure,
  egovCertificateFile,
  egovAccount,
  formatError,
}) => {
  const FormDetail = () => {
    switch (formStep) {
      case 'attachments':
        return <Attachments egovProcedure={egovProcedure} />
      case 'pinCode':
        return (
          <PinCode
            register={register}
            formState={formState}
            egovProcedure={egovProcedure}
            egovCertificateFile={egovCertificateFile}
            egovAccount={egovAccount}
          />
        )
      case 'error':
        return formatError ? <Errors formatError={formatError} /> : <></>
    }
  }

  return <FormDetail />
}

interface AttachmentsProps {
  egovProcedure: EgovProcedure
}

const Attachments: FC<AttachmentsProps> = ({egovProcedure}) => {
  const employee = egovProcedure.procedureStatus?.employee
  const name = fullNameFormat(employee?.lastName, employee?.firstName)

  return (
    <>
      <ListGroup>
        <ListGroup.Title>手続き名称</ListGroup.Title>
        <ListGroup.Item>{egovProcedure.formName}</ListGroup.Item>
      </ListGroup>
      <ListGroup>
        <ListGroup.Title>対象従業員</ListGroup.Title>
        <ListGroup.Item>{name}</ListGroup.Item>
      </ListGroup>
      <ListGroup>
        <ListGroup.Title>添付書類</ListGroup.Title>
        {egovProcedure.attachments.length > 0 ? (
          egovProcedure.attachments.map((attachment, i) => (
            <ListGroup.Item key={`attachment-key-${i}`}>
              <a className="u-txt-link" href={attachment.fileUrl} target="_blank" rel="noopener" download>
                {attachment.fileName}
              </a>
            </ListGroup.Item>
          ))
        ) : (
          <ListGroup.Item>{displayFormat()}</ListGroup.Item>
        )}
      </ListGroup>
    </>
  )
}

interface PinCodeProps {
  formState: FormState<ApplyFormSchema>
  register: UseFormRegister<ApplyFormSchema>
  egovProcedure: EgovProcedure
  egovCertificateFile?: EgovCertificateFile
  egovAccount?: EgovAccount
}

const PinCode: FC<PinCodeProps> = ({formState, register, egovProcedure, egovCertificateFile, egovAccount}) => {
  const {errors} = formState
  const gbizidAccountType = egovAccount?.gbizidAccountType || ''

  return (
    <>
      <ListGroup>
        <ListGroup.Title>提出先</ListGroup.Title>
        <ListGroup.Item>{egovProcedure.submissionDestination}</ListGroup.Item>
      </ListGroup>
      {(egovProcedure.signRequired || !['prime', 'member'].includes(gbizidAccountType)) && (
        <>
          <ListGroup>
            <ListGroup.Title>電子証明書</ListGroup.Title>
            <ListGroup.Item>{recordDisplay(egovCertificateFile?.name)}</ListGroup.Item>
          </ListGroup>
          <Spacer size={20} />
          <Label>PINコード</Label>
          <Spacer size={8} />
          <Input type="password" isError={!!errors.pinCode?.message} {...register('pinCode')} />
          {errors.pinCode?.message && <Error error={errors.pinCode.message} />}
        </>
      )}
    </>
  )
}

interface ErrorsProps {
  formatError: FormatError
}

const Errors: FC<ErrorsProps> = ({formatError}) => {
  const sectionTitle = (
    <div>
      入力内容に誤りがあります。
      <br />
      修正後に再度申請をお願いします。
    </div>
  )

  return (
    <Section title={sectionTitle} error>
      <ListGroup>
        <ListGroup.Title>エラー内容</ListGroup.Title>
        <ListGroup.Item>{formatError.title}</ListGroup.Item>
        <ListGroup.Title>エラー詳細</ListGroup.Title>
        <ListGroup.Item>{formatError.detail}</ListGroup.Item>
        {formatError.reportList.length > 0 && (
          <>
            <ListGroup.Title>エラーレポート一覧</ListGroup.Title>
            <ListGroup.Item>
              {formatError.reportList.map((report, index) => {
                return (
                  <Dl separator=":" key={index}>
                    <Dt>様式名</Dt>
                    <Dd>{report.formName}</Dd>
                    <Dt>添付書類名</Dt>
                    <Dd>{report.attachedFileName}</Dd>
                    <Dt>項目名</Dt>
                    <Dd>{report.item}</Dd>
                    <Dt>エラー内容</Dt>
                    <Dd>{report.content}</Dd>
                  </Dl>
                )
              })}
            </ListGroup.Item>
          </>
        )}
      </ListGroup>
    </Section>
  )
}
