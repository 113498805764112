import {FC, ReactNode} from 'react'
// deprecated!!!
// 代わりにlayout/TitleContainerとui/MainTitleを使用する

interface Props {
  title: string
  description?: string | ReactNode // MEMO: 非推奨
  children?: ReactNode
}

export const MainTitle: FC<Props> = ({title, description, children}) => {
  return (
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">{title}</h1>
      {(description || children) && <p className="m-title-main-note">{description || children}</p>}
    </div>
  )
}
