import React, {Component} from 'react'
import {reduxForm} from 'redux-form'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {getState, toFormValues} from 'utils'
import {push} from 'connected-react-router'
import {FormContents} from 'myNumber/businessPartner/Create'
import {onSubmitFail} from 'jbc-front/components/FormErrors'
import compose from 'lodash/fp/compose'
import {connect} from 'react-redux'
import Button from 'jbc-front/components/Button'
import styles from './Edit.scss'
import {asyncError} from 'store/actions/asyncError'

const formName = 'businessPartnerForm'

class Edit extends Component {
  static defaultProps = {
    businessPartner: {},
  }

  componentDidMount() {
    const {
      token,
      dispatch,
      match: {
        params: {id},
      },
    } = this.props
    dispatch(actionCreators.fetchData('business_partner', api.createWithAuth(token).businessPartners.get(id)))
  }

  componentWillUnmount() {
    const {dispatch} = this.props
    dispatch(actionCreators.fetchDestroy('business_partner'))
  }

  render() {
    const {pristine, submitting, handleSubmit, dispatch} = this.props
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">取引先情報</h1>
        </div>
        <FormContents />
        <div className={styles.bottomButtonsArea}>
          <Button onClick={() => dispatch(push('/my_number/business_partners'))}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={pristine || submitting}>
            更新する
          </Button>
        </div>
      </form>
    )
  }
}

export default compose(
  connect((state) => ({
    token: state.auth.token,
    businessPartner: fetchSelector(state, 'business_partner').data,
  })),
  (Form) => (props) => <Form initialValues={toFormValues(props.businessPartner || {})} {...props} />,
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    onSubmit: async (values, dispatch, {businessPartner}) => {
      try {
        const {
          auth: {token},
        } = await getState(dispatch)
        await api.createWithAuth(token).businessPartners.update(businessPartner.id, values)
        dispatch(push(`/my_number/business_partners/${businessPartner.id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Edit)
