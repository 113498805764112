import React, {Component} from 'react'
import {connect} from 'react-redux'
import R1 from 'reportForms/R1Report'
import R3 from 'reportForms/R3Report'
import R12 from 'reportForms/R12Report'
import R13 from 'reportForms/R13Report'
import R14 from 'reportForms/R14Report'
import R55 from 'reportForms/R55Report'
import R57 from 'reportForms/R57Report'
import R4 from 'reportForms/R4Report'
import R5 from 'reportForms/R5Report'
import R6 from 'reportForms/R6Report'
import R7 from 'reportForms/R7Report'
import R8 from 'reportForms/R8Report'
import R46 from 'reportForms/R46Report'
import R47 from 'reportForms/R47Report'
import R56 from 'reportForms/R56Report'
import R58 from 'reportForms/R58Report'
import R59 from 'reportForms/R59Report'
import R60 from 'reportForms/R60Report'
import R71 from 'reportForms/R71Report'
import R131 from 'reportForms/R131Report'
import R135 from 'reportForms/R135Report'
import R136 from 'reportForms/R136Report'
import R137 from 'reportForms/R137Report'
import R140 from 'reportForms/R140Report'
import R147 from 'reportForms/R147Report'
import R148 from 'reportForms/R148Report'
import R150 from 'reportForms/R150Report'
import R501 from 'reportForms/R501Report'
import R502 from 'reportForms/R502Report'
import R503 from 'reportForms/R503Report'
import R504 from 'reportForms/R504Report'
import R505 from 'reportForms/R505Report'
import R506 from 'reportForms/R506Report'
import api from 'api'
import {actionCreators} from 'actions'
import {parse} from 'query-string'
export const formMap = {
  R1,
  R2: R1,
  R3,
  R12,
  R13,
  R14,
  R55,
  R57,
  R4,
  R5,
  R6,
  R7,
  R8,
  R46,
  R47,
  R56,
  R58,
  R59,
  R60,
  R71,
  R131,
  R135,
  R136,
  R137,
  R140,
  R147,
  R148,
  R150,
  R501,
  R502,
  R503,
  R504,
  R505,
  R506,
}

import {Redirect} from 'react-router'
class ReportEdit extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      loadProcedure,
    } = this.props
    loadProcedure(id, token)
  }
  componentWillUnmount() {
    this.props.destroy()
  }
  afterSubmit = () => {
    const {
      match: {
        params: {id},
      },
    } = this.props
    this.props.history.push(`/procedure_statuses/${id}`)
  }
  render() {
    const {
      procedureStatus,
      match: {
        params: {id},
      },
    } = this.props
    const current = this.props.location.hash ? this.props.location.hash.slice(1) : ''
    const Form = current ? formMap[current] : null
    if (procedureStatus.status && procedureStatus.status !== 'in_progress') {
      return <Redirect to={`/procedure_statuses/${id}`} />
    }
    return (
      <div>
        {current && Form && (
          <Form
            submitText="保存"
            procedureStatusId={id}
            afterSubmit={this.afterSubmit}
            dependentId={parse(this.props.location.search).dependent_id}
          />
        )}
      </div>
    )
  }
}
export default connect(
  (state) => ({
    token: state.auth.token,
    procedureStatus: state.procedureStatuses.current.data,
  }),
  (dispatch) => ({
    loadProcedure(id, token) {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api
            .createWithAuth(token)
            .procedureStatuses.get(
              id,
              [
                'employee',
                'employee_dependents',
                'employment_insurance',
                'my_number_present',
                'procedure_add_dependents_data',
                'procedure_remove_dependents_data',
                'procedure_maternity_datum',
                'procedure_maternity_child_data',
                'office',
                'exist_procedure_change_name',
              ],
              {employee_dependent_removed: [0, 1]}
            )
        )
      )
    },
    destroy() {
      dispatch(actionCreators.procedureStatuses.current.destroy)
    },
  })
)(ReportEdit)
