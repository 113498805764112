import React, {Component} from 'react'
import {connect} from 'react-redux'
import api from 'api'
import ProceduresList from 'procedures/maternityLeave/ProceduresList'
import {actionCreators} from 'actions'

class ChooseProcedureForMember extends Component {
  componentDidMount() {
    const {loadProcedures, token} = this.props
    loadProcedures(token)
  }

  handleClick = (procedureId) => {
    this.props.history.push(`/change_maternity/choose_extend_or_finish?procedure_id=${procedureId}`)
  }

  render() {
    const {procedureStatuses} = this.props
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">利用する手続き情報を選択してください</h1>
        </div>
        <div className="l-wrap-xl">
          <ProceduresList
            handleClick={(procedureId) => this.handleClick(procedureId)}
            procedureStatuses={procedureStatuses}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    procedureStatuses: state.procedureStatuses.list.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadProcedures(token) {
      api
        .createWithAuth(token)
        .employees.getCurrent(['office'])
        .then((res) => {
          if (res?.data) {
            dispatch(
              actionCreators.procedureStatuses.list.fetchData(
                api
                  .createWithAuth(token)
                  .procedureStatuses.list({procedure_type: 'start_maternity', employee_id: res.data.id}, [
                    'employee',
                    'procedure_maternity_datum',
                    'procedure_tmp_data',
                  ])
              )
            )
          }
        })
    },
  })
)(ChooseProcedureForMember)
