import React from 'react'
import {connect} from 'react-redux'
import {isAdminSelector} from 'utils'
import ProfileEditForAdmin from 'accountSettings/ProfileEditForAdmin'
import ProfileEditForEmployee from 'accountSettings/ProfileEditForEmployee'

const ProfileEdit = ({isAdmin, currentUser: {has_permission_edit_self_profile}}) => {
  const canEdit = isAdmin && has_permission_edit_self_profile
  return canEdit ? <ProfileEditForAdmin /> : <ProfileEditForEmployee />
}

export default ProfileEdit
  |> connect((state) => ({isAdmin: isAdminSelector(state), currentUser: state.session.currentUser}))
