import React, {Component} from 'react'
import {Provider, useSelector} from 'react-redux'
import {Route, Switch, Redirect} from 'react-router'
import Cookies from 'js-cookie'

import {configureStore, history} from 'store/configureStore'
import {ScrollToTopOnAnyNavigation} from 'components/ScrollToTopOnNavigation'
import Layout from 'Layout'
import Dashboard from 'Dashboard'
import enroll from 'procedures/enroll'
import resign from 'procedures/resign'
import changeAddress from 'procedures/changeAddress'
import changeName from 'procedures/changeName'
import changeDependents from 'procedures/changeDependents'
import maternityLeave from 'procedures/maternityLeave'
// import childCare from 'procedures/childCare'
import Login from 'Login'
import {Logout} from 'pages/withAuth/logout/index'
import {TmLogout} from 'pages/withAuth/tm/logout/index'
import employees from 'employees'
import procedureStatuses from 'procedureStatuses'
import ProcedureNew from 'ProcedureNew'
import tm from 'tm'
import settings from 'settings'
import Home from 'Home'
import RegistrationShow from 'registration/Create'
import RegistrationTrialShow from 'registration/CreateTrial'
import client from 'client'
import offices from 'offices'
import notifications from 'notifications'
import systemAnnounces from 'systemAnnounces'
import accountSettings from 'accountSettings'
import ForgotPassword from 'ForgotPassword'
import ResetPassword from 'ResetPassword'
import directRequest from 'directRequest'
import NotFound from 'NotFound'
import myNumber from 'myNumber'
import {ConnectedRouter} from 'connected-react-router'
import {connect} from 'react-redux'
import InitialInput from 'InitialInput'
import _ from 'lodash'
import eGovProcedures from 'eGovProcedures'
import employeeFiles from 'employeeFiles'
import JbcIdLogin from 'JbcIdLogin'
import YearEndAdjStart from 'YearEndAdjStart'
import YearEndAdjStartRequest from 'YearEndAdjStartRequest'
import StressCheckStart from 'StressCheckStart'
import StressCheckStartRequest from 'StressCheckStartRequest'
import documents from 'documents'
import documentEmployees from 'documentEmployees'
import documentTemplates from 'documentTemplates'
import configureApollo from 'configureApollo'
import {ApolloProvider} from '@apollo/client'
import {mypageRoutes} from 'routes/withAuth/mypageRoutes'
import {notifySuccess, notifyError} from 'store/actions/notify'

// MEMO: 今後routes配下に置き換えていく
import {withoutAuthRoutes} from 'routes/withoutAuth'
import {withAuthAdminRoutes} from 'routes/withAuth/adminRoutes'
import {WITH_AUTH_PATHS, WITH_AUTH_TM_PATHS, MYPAGE_PATHS} from 'consts/paths'

const store = configureStore()
const apolloClient = configureApollo('/graphql', store)
const mypageApolloClient = configureApollo('/mypage/graphql', store)
const tmApolloClient = configureApollo('/tm/graphql', store)

const NotLoggedIn = () => (
  <Layout.NotLoggedIn>
    <Switch>
      {process.env.NODE_ENV === 'production' ? (
        <Redirect from="/" exact to="/dashboard" />
      ) : (
        <Route path="/" exact component={Home} />
      )}
      <Route path="/login" component={JbcIdLogin} />
      <Route path="/tm_login" component={tm.JbcIdLogin} />
      <Route path="/users/login" component={Login} />
      <Route path="/registration" exact component={RegistrationShow} />
      <Route path="/registration/trial" exact component={RegistrationTrialShow} />
      <Route path="/enrollment_employee_input/:id" component={enroll.EmployeeInput} />
      <Route path="/enrollment_employee_input_completed" component={enroll.EmployeeInputCompleted} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/reset_password" component={ResetPassword} />
      <Route path="/enrollment_employee_input_dependents/:id" component={enroll.EmployeeInputDependents} />
      <Route path="/business_partner_my_number" exact component={myNumber.businessPartner.GuestInput} />
      {withoutAuthRoutes.map(({key, ...route}) => (
        <Route key={key} {...route} />
      ))}
      <Route component={NotFound} />
    </Switch>
  </Layout.NotLoggedIn>
)

const LoggedIn = () => {
  const token = useSelector((state) => state.auth.token)
  const currentUser = useSelector((state) => state.session.currentUser)

  if (!token || _.isEmpty(currentUser)) {
    return <Layout.LoggedIn />
  }

  const {
    client_role: {client_role_type},
    email_changeable,
    employee,
  } = currentUser

  const isOfficeAdmin = client_role_type === 'office_admin'
  const isLimitedAdmin = client_role_type === 'limited_admin'
  const hasPermissionEditSelfProfile = currentUser.has_permission_edit_self_profile
  const isAdmin = ['full_admin', 'office_admin', 'limited_admin'].includes(client_role_type)

  const buildAdminRoutes = () => {
    const routeProps = [
      {path: WITH_AUTH_PATHS.LOGOUT, component: Logout},
      {path: '/initial_input', exact: true, component: InitialInput},
      {path: '/dashboard', component: Dashboard},
      {path: '/employees', exact: true, component: employees.List},
      {path: '/employees/create', component: employees.Create},
      {path: '/employees/choose_add_method', component: employees.ChooseAddMethod},
      {path: '/employees/import', component: employees.FileImport},
      {path: '/employees/import_update', component: employees.FileImportUpdate},
      {path: '/employees/invite', component: employees.Invite},
      {path: '/employees/csv_formats/create', exact: true, component: employees.CsvFormat},
      {path: '/employees/csv_formats/:id', exact: true, component: employees.CsvFormat},
      {path: '/employees/csv_formats/:id/duplicate', exact: true, component: employees.CsvFormat},
      {path: '/employees/coordination_targets', exact: true, component: employees.CoordinationTargets},
      {
        path: '/employees/year_end_adj_coordination_targets',
        exact: true,
        component: employees.YearEndAdjCoordinationTargets,
      },
      {path: '/employees/employee_generated_files', exact: true, component: employees.DownloadRoster},
      {path: '/employees/:id', exact: true, component: employees.Show},
      {path: '/employees/:id/edit', component: employees.Update},
      {path: '/employees/:id/personnel_history', component: employees.PersonnelHistory},
      {path: '/employees/:id/visa_history', component: employees.VisaHistory},
      {path: '/employees/:id/audit_logs', exact: true, component: employees.auditLogs.List},
      {path: '/employees/:id/audit_logs/:log_id', component: employees.auditLogs.Show},
      {path: '/employees/:id/detail_input_confirm', component: employees.Confirm},
      {path: '/client', exact: true, component: client.Show},
      {path: '/client/edit', component: client.Update},
      {path: '/enroll', exact: true, component: enroll.NewOrExist},
      {path: '/enroll/choose_direct_input_employee', component: enroll.ChooseDirectInputEmployee},
      {path: '/enroll/request_employee_to_input_completed', component: enroll.RequestEmployeeToInputCompleted},
      {path: '/enroll/new_direct_input', component: enroll.NewDirectInput},
      {path: '/enroll/new_request', component: enroll.NewRequest},
      {path: '/enroll/detail/:id', component: enroll.Detail},
      {path: '/enroll/employee_input/:id', component: enroll.EmployeeInput},
      {path: '/enroll/employee_input_dependents/:id', component: enroll.EmployeeInputDependents},
      {path: '/enroll/confirm/:id', component: enroll.Confirm},
      {path: '/enroll/confirm_dependents/:id', component: enroll.ConfirmDependents},
      {path: '/enroll/request/choose_exist_employee', component: enroll.ChooseEmployeeRequest},
      {path: '/enroll/direct/choose_exist_employee', component: enroll.ChooseEmployeeDirect},
      {path: '/enroll/:method(new|exist)/direct_input_or_request_input', component: enroll.DirectInputOrRequestInput},
      {path: '/enroll/flow/:id', component: enroll.Flow},
      {path: '/resign', exact: true, component: resign.ChooseEmployee},
      {path: '/resign/request', component: resign.Request},
      {path: '/resign/detail/:id?', component: resign.Detail},
      {path: '/resign/flow/:id', component: resign.Flow},
      {path: '/resign/confirm/:id', component: resign.Confirm},
      {path: '/change_address', exact: true, component: changeAddress.ChooseEmployee},
      {path: '/change_address/detail', component: changeAddress.Detail},
      {path: '/change_address/confirm/:id', component: changeAddress.Confirm},
      {path: '/change_address/flow/:id', component: changeAddress.Flow},
      {path: '/change_address/update/:id', component: changeAddress.Update},
      {path: '/change_name', exact: true, component: changeName.ChooseEmployee},
      {path: '/change_name/detail', component: changeName.Detail},
      {path: '/change_name/flow/:id', component: changeName.Flow},
      {path: '/change_name/confirm/:id', component: changeName.Confirm},
      {path: '/change_name/update/:id', component: changeName.Update},
      {path: '/change_dependents', exact: true, component: changeDependents.ChooseEmployee},
      {path: '/change_dependents/choose_add_or_remove', component: changeDependents.ChooseAddOrRemove},
      {path: '/add_dependents/choose_new_or_exists', component: changeDependents.add.ChooseNewOrExists},
      {path: '/add_dependents/choose_exist_dependents', component: changeDependents.add.ChooseExistDependents},
      {path: '/add_dependents/detail', component: changeDependents.add.Detail},
      {path: '/add_dependents/confirm/:id', component: changeDependents.add.Confirm},
      {path: '/add_dependents/confirm_dependents/:id', component: changeDependents.add.ConfirmDependents},
      {path: '/add_dependents/flow/:id', component: changeDependents.add.Flow},
      {path: '/add_dependents/employee_input_dependents/:id', component: changeDependents.add.EmployeeInputDependents},
      {path: '/add_dependents/update/:id', component: changeDependents.add.Update},
      {path: '/remove_dependents/detail', component: changeDependents.remove.Detail},
      {path: '/remove_dependents/flow/:id', component: changeDependents.remove.Flow},
      {path: '/remove_dependents/confirm/:id', component: changeDependents.remove.Confirm},
      {path: '/remove_dependents/update/:id', component: changeDependents.remove.Update},
      {path: '/maternity_leave', exact: true, component: maternityLeave.ChooseProcedureTypeForAdmin},
      {path: '/start_maternity/choose_employee', exact: true, component: maternityLeave.start.ChooseEmployee},
      {path: '/start_maternity/detail', exact: true, component: maternityLeave.start.Detail},
      {path: '/start_maternity/update/:id', exact: true, component: maternityLeave.start.Update},
      {path: '/start_maternity/confirm/:id', exact: true, component: maternityLeave.start.Confirm},
      {path: '/extend_maternity/choose_employee', exact: true, component: maternityLeave.extend.ChooseEmployee},
      {path: '/extend_maternity/choose_use_or_create', exact: true, component: maternityLeave.extend.ChooseUseOrCreate},
      {
        path: '/extend_maternity/choose_procedure',
        exact: true,
        component: maternityLeave.extend.ChooseProcedureForAdmin,
      },
      {path: '/extend_maternity/detail', exact: true, component: maternityLeave.extend.Detail},
      {path: '/extend_maternity/update/:id', exact: true, component: maternityLeave.extend.Update},
      {path: '/extend_maternity/confirm/:id', exact: true, component: maternityLeave.extend.Confirm},
      {path: '/finish_maternity/choose_employee', exact: true, component: maternityLeave.finish.ChooseEmployee},
      {path: '/finish_maternity/choose_use_or_create', exact: true, component: maternityLeave.finish.ChooseUseOrCreate},
      {
        path: '/finish_maternity/choose_procedure',
        exact: true,
        component: maternityLeave.finish.ChooseProcedureForAdmin,
      },
      {path: '/finish_maternity/detail', exact: true, component: maternityLeave.finish.Detail},
      {path: '/finish_maternity/update/:id', exact: true, component: maternityLeave.finish.Update},
      {path: '/finish_maternity/confirm/:id', exact: true, component: maternityLeave.finish.Confirm},
      // { path: "/childcare", exact: true, component: childCare.ChooseEmployee },
      // { path: "/childcare/choose_childcare_procedures", component: childCare.ChooseChildcareProcedures },
      // { path: "/start_childcare/detail" component: childCare.start.Detail },
      // { path: "/start_childcare/confirm/:id" component: childCare.start.Confirm },
      // { path: "/extend_childcare/detail" component: childCare.extend.Detail },
      // { path: "/finish_childcare/detail" component: childCare.finish.Detail },
      {path: '/procedure_statuses', exact: true, component: procedureStatuses.List},
      {path: '/procedure_statuses/:id', exact: true, component: procedureStatuses.Show},
      {path: '/procedure_statuses/:id/report_edit', component: procedureStatuses.ReportEdit},
      {path: '/procedure_statuses/:id/r60_import_payments', component: procedureStatuses.R60ImportPayments},
      {path: '/procedure_new', component: ProcedureNew},
    ]

    if (client_role_type !== 'limited_admin') {
      routeProps.push(
        {key: 'settings_employment_types', path: '/settings/employment_types', component: settings.EmploymentTypes},
        {key: 'settings_groups', path: '/settings/groups', component: settings.Groups},
        {key: 'settings_positions', path: '/settings/positions', component: settings.Positions},
        {key: 'settings_occupations', path: '/settings/occupations', component: settings.Occupations},
        {key: 'settings_notifications', path: '/settings/notifications', component: settings.Notifications},
        {key: 'settings_audit_logs', path: '/settings/audit_logs', exact: true, component: settings.auditLogs.List},
        {key: 'settings_audit_logs/show', path: '/settings/audit_logs/:id', component: settings.auditLogs.Show},
        {
          key: 'settings_custom_mails',
          path: '/settings/custom_mails',
          exact: true,
          component: settings.customMails.List,
        },
        {
          key: 'settings_custom_mails/edit',
          path: '/settings/custom_mails/:mail_type',
          exact: true,
          component: settings.customMails.Edit,
        },
        {
          key: 'settings_employee_custom',
          path: '/settings/employee_custom',
          exact: true,
          component: settings.employeeCustom.FieldGroups,
        },
        {
          key: 'settings_employee_custom/create',
          path: '/settings/employee_custom/create',
          exact: true,
          component: settings.employeeCustom.CustomFieldGroup,
        },
        {
          key: 'settings_employee_custom/custom',
          path: '/settings/employee_custom/custom/:id',
          exact: true,
          component: settings.employeeCustom.CustomFieldGroup,
        },
        {
          key: 'settings_employee_custom/field_group',
          path: '/settings/employee_custom/:id',
          exact: true,
          component: settings.employeeCustom.FieldGroup,
        },
        {key: 'settings_client_files', path: '/settings/client_files', exact: true, component: settings.ClientFiles},
        {key: 'offices', path: '/offices', exact: true, component: offices.List},
        {key: 'offices_edit', path: '/offices/:id/edit', component: offices.Update}
      )
    }

    routeProps.push(
      {path: '/notifications', component: notifications.List},
      {path: '/system_announces', exact: true, component: systemAnnounces.List},
      {path: '/system_announces/:id', component: systemAnnounces.Show},
      {path: '/account_settings/profile', exact: true, component: accountSettings.Profile},
      {path: '/account_settings/profile/edit', component: accountSettings.ProfileEdit}
    )

    if (!employee) {
      routeProps.push({path: '/account_settings/account', exact: true, component: accountSettings.Account})
    }

    routeProps.push({path: '/account_settings/change_password', exact: true, component: accountSettings.ChangePassword})

    if (!isOfficeAdmin && !isLimitedAdmin) {
      routeProps.push(
        {key: 'offices_create', path: '/offices/create', exact: true, component: offices.Create},
        {key: 'settings_using_plan', path: '/settings/using_plan', component: settings.UsingPlan},
        {key: 'settings_allowed_ips', path: '/settings/allowed_ips_employee', component: settings.AllowedIpsEmployee},
        {key: 'settings_allowed_ips', path: '/settings/allowed_ips_other', component: settings.AllowedIpsOther},
        {key: 'settings_allowed_ips', path: '/settings/allowed_ips_tm', component: settings.AllowedIpsTm},
        {
          key: 'settings_client_roles',
          path: '/settings/client_roles',
          exact: true,
          component: settings.clientRoles.EmployeeList,
        },
        {
          key: 'settings_client_roles/list',
          path: '/settings/client_roles/list',
          exact: true,
          component: settings.clientRoles.List,
        },
        {
          key: 'settings_client_roles/edit/:id',
          path: '/settings/client_roles/edit/:id',
          exact: true,
          component: settings.clientRoles.Edit,
        },
        {
          key: 'settings_labor_consultant',
          path: '/settings/labor_consultant',
          exact: true,
          component: settings.laborConsultant.Show,
        },
        {
          key: 'settings_labor_consultant/edit',
          path: '/settings/labor_consultant/edit',
          component: settings.laborConsultant.Update,
        },
        {key: 'settings_coordination', path: '/settings/coordination', exact: true, component: settings.Coordination},
        {
          key: 'settings_talent_display',
          path: '/settings/talent_display',
          exact: true,
          component: settings.TalentDisplay,
        },
        {key: 'settings_roles/list', path: '/settings/roles/list', exact: true, component: settings.roles.List},
        {key: 'settings_roles/create', path: '/settings/roles/create', exact: true, component: settings.roles.Create},
        {key: 'settings_roles/edit', path: '/settings/roles/edit/:id', exact: true, component: settings.roles.Edit},
        {
          key: 'settings_custom_employee_field_group_icons',
          path: '/settings/custom_employee_field_group_icons',
          exact: true,
          component: settings.employeeCustom.CustomFieldGroupIcons,
        }
      )
    }

    if (!isOfficeAdmin) {
      routeProps.push({key: 'year_end_adj_start', path: '/year_end_adj_start', exact: true, component: YearEndAdjStart})
    }

    routeProps.push({key: 'stress_check_start', path: '/stress_check_start', exact: true, component: StressCheckStart})

    if (email_changeable) {
      routeProps.push({path: '/account_settings/change_email', exact: true, component: accountSettings.ChangeEmail})
    }

    routeProps.push({
      key: '/my_number/employee_input',
      path: '/my_number/employee_input',
      exact: true,
      component: myNumber.EmployeeInput,
    })

    if (!isLimitedAdmin) {
      routeProps.push(
        {key: '/my_number', path: '/my_number', exact: true, component: myNumber.List},
        {key: '/my_number/import', path: '/my_number/import', exact: true, component: myNumber.FileImport},
        {key: '/my_number/audit_logs', path: '/my_number/audit_logs', exact: true, component: myNumber.auditLogs.List},
        {
          key: '/my_number/audit_logs/:log_id',
          path: '/my_number/audit_logs/:log_id',
          component: myNumber.auditLogs.Show,
        },
        {
          key: '/my_number/business_partners',
          path: '/my_number/business_partners',
          exact: true,
          component: myNumber.businessPartner.List,
        },
        {
          key: '/my_number/business_partners/create',
          path: '/my_number/business_partners/create',
          exact: true,
          component: myNumber.businessPartner.Create,
        },
        {
          key: '/my_number/business_partners/import',
          path: '/my_number/business_partners/import',
          exact: true,
          component: myNumber.businessPartner.FileImport,
        },
        {
          key: '/my_number/business_partners/import_update',
          path: '/my_number/business_partners/import_update',
          component: myNumber.businessPartner.FileImportUpdate,
        },
        {
          key: '/my_number/business_partners/:id',
          path: '/my_number/business_partners/:id',
          exact: true,
          component: myNumber.businessPartner.Show,
        },
        {
          key: '/my_number/business_partners/:id/edit',
          path: '/my_number/business_partners/:id/edit',
          exact: true,
          component: myNumber.businessPartner.Edit,
        },
        {key: '/my_number/:id', path: '/my_number/:id', exact: true, component: myNumber.Show}
      )
    }

    routeProps.push({path: '/e_gov_procedures/:id', exact: true, component: eGovProcedures.Show})

    if (!hasPermissionEditSelfProfile) {
      routeProps.push(
        {key: '/direct_request/confirm', path: '/direct_request/confirm', component: directRequest.Confirm},
        {key: '/resign/employee_input/:id', path: '/resign/employee_input/:id', component: resign.EmployeeInput},
        {
          key: '/change_address/employee_input/:id?',
          path: '/change_address/employee_input/:id?',
          exact: true,
          component: changeAddress.EmployeeInput,
        },
        {
          key: '/change_name/employee_input/:id?',
          path: '/change_name/employee_input/:id?',
          component: changeName.EmployeeInput,
        }
      )
    }

    routeProps.push(
      {path: '/documents/create', exact: true, component: documents.Create},
      {path: '/documents/:id', exact: true, component: documents.Show},
      {path: '/documents/:id/contracts', exact: true, component: documents.ShowContracts},
      {path: '/documents/:id/contracts/create', exact: true, component: documents.Contract},
      {path: '/document_employees', exact: true, component: documentEmployees.List},
      {path: '/document_templates', exact: true, component: documentTemplates.List},
      {path: '/document_templates/create', exact: true, component: documentTemplates.Create},
      {path: '/document_templates/samples', exact: true, component: documentTemplates.Sample},
      {path: '/document_templates/:id', exact: true, component: documentTemplates.Edit},
      {path: '/year_end_adj_start/request', exact: true, component: YearEndAdjStartRequest},
      {path: '/stress_check_start/request', exact: true, component: StressCheckStartRequest}
    )

    const routes = []
    withAuthAdminRoutes.forEach((route, i) => {
      const {whiteList = ['full_admin', 'office_admin', 'limited_admin'], ...rest} = route
      if (whiteList.includes(client_role_type)) {
        routes.push(<Route key={`key-withAuth-adminRoutes-${i}`} {...rest} />)
      }
    })
    routeProps.forEach((props, i) => {
      routes.push(<Route key={`key-adminRoutes-${i}`} {...props} />)
    })

    routes.push(
      <Redirect
        key={`key-adminRoutes-redirect-account-settings`}
        from="/account_settings"
        exact
        to={employee ? '/account_settings/profile' : '/account_settings/account'}
      />,
      <Redirect
        key="key-adminRoutes-add_dependents_employee_input"
        from="/add_dependents/employee_input/:id"
        exact
        to={MYPAGE_PATHS.PROCEDURES.ADD_DEPENDENTS.EMPLOYEE_INPUT}
      />,
      <Redirect
        key="key-adminRoutes-redirect-remove_dependents_employee_input"
        from="/remove_dependents/employee_input/:id"
        exact
        to={MYPAGE_PATHS.PROCEDURES.REMOVE_DEPENDENTS.EMPLOYEE_INPUT}
      />
    )

    return routes
  }

  // TODO: mypageに移行
  const buildEmployeeRoutes = () => {
    const routeProps = [
      {path: WITH_AUTH_PATHS.LOGOUT, component: Logout},
      {path: '/initial_input', exact: true, component: InitialInput},
      {path: '/dashboard', component: Dashboard},
      {path: '/enroll/employee_input/:id', component: enroll.EmployeeInput},
      {path: '/enroll/employee_input_dependents/:id', component: enroll.EmployeeInputDependents},
      {path: '/change_address/employee_input/:id?', exact: true, component: changeAddress.EmployeeInput},
      {path: '/change_name/employee_input/:id?', component: changeName.EmployeeInput},
      {path: '/maternity_leave', exact: true, component: maternityLeave.ChooseProcedureTypeForMember},
      {path: '/start_maternity/employee_input/:id?', exact: true, component: maternityLeave.start.EmployeeInput},
      {path: '/extend_maternity/employee_input/:id?', exact: true, component: maternityLeave.extend.EmployeeInput},
      {path: '/change_maternity/choose_procedure', exact: true, component: maternityLeave.ChooseProcedureForMember},
      {path: '/change_maternity/choose_extend_or_finish', exact: true, component: maternityLeave.ChooseExtendOrFinish},
      /*<Route path="/childcare" exact component={childCare.ChooseChildcareProcedures} />*/
      /*<Route path="/start_childcare/employee_input/:id?" component={childCare.start.EmployeeInput} />*/
      /*<Route path="/extend_childcare/employee_input/:id?" component={childCare.extend.EmployeeInput} />*/
      /*<Route path="/finish_childcare/employee_input/:id?" component={childCare.finish.EmployeeInput} />*/
      {path: '/resign/employee_input/:id', component: resign.EmployeeInput},
      {path: '/procedure_statuses', exact: true, component: procedureStatuses.ListNotAdmin},
      {path: '/procedure_new', component: ProcedureNew},
      {path: '/notifications', component: notifications.List},
      {path: '/account_settings/profile', exact: true, component: accountSettings.Profile},
      {path: '/account_settings/profile/edit', component: accountSettings.ProfileEdit},
    ]

    if (!employee) {
      routeProps.push({path: '/account_settings/account', exact: true, component: accountSettings.Account})
    }

    routeProps.push({path: '/account_settings/change_password', exact: true, component: accountSettings.ChangePassword})

    if (email_changeable) {
      routeProps.push({path: '/account_settings/change_email', exact: true, component: accountSettings.ChangeEmail})
    }

    routeProps.push(
      {path: '/my_number/employee_input', exact: true, component: myNumber.EmployeeInput},
      {path: '/employee_files', exact: true, component: employeeFiles.Top},
      {path: '/direct_request/confirm', component: directRequest.Confirm},
      {path: '/year_end_adj_start/request', exact: true, component: YearEndAdjStartRequest},
      {path: '/stress_check_start/request', exact: true, component: StressCheckStartRequest}
    )

    const routes = []

    routeProps.forEach((props, i) => {
      routes.push(<Route key={`key-employeeRoutes-${i}`} {...props} />)
    })

    routes.push(
      <Redirect
        key={`key-employeeRoutes-redirect-change_address`}
        from="/change_address"
        exact
        to="/change_address/employee_input"
      />,
      <Redirect
        key={`key-employeeRoutes-redirect-change_name`}
        from="/change_name"
        exact
        to="/change_name/employee_input"
      />,
      <Redirect
        key={`key-employeeRoutes-redirect-account_settings`}
        from="/account_settings"
        exact
        to={employee ? '/account_settings/profile' : '/account_settings/account'}
      />,
      <Redirect
        key="key-employeeRoutes-redirect-add_dependents_employee_input"
        from="/add_dependents/employee_input/:id"
        exact
        to={MYPAGE_PATHS.PROCEDURES.ADD_DEPENDENTS.EMPLOYEE_INPUT}
      />,
      <Redirect
        key="key-employeeRoutes-redirect-remove_dependents_employee_input"
        from="/remove_dependents/employee_input/:id"
        exact
        to={MYPAGE_PATHS.PROCEDURES.REMOVE_DEPENDENTS.EMPLOYEE_INPUT}
      />
    )

    return routes
  }

  return (
    <Layout.LoggedIn>
      <Switch>
        {isAdmin ? buildAdminRoutes() : buildEmployeeRoutes()}
        <Route component={NotFound} />
      </Switch>
    </Layout.LoggedIn>
  )
}

const MypageRoutes = () => {
  const token = useSelector((state) => state.auth.token)
  const currentUser = useSelector((state) => state.session.currentUser)

  if (!token || _.isEmpty(currentUser)) {
    return <Layout.MypageLoggedIn />
  }

  return (
    <Layout.MypageLoggedIn>
      <Switch>
        {mypageRoutes.map((props, i) => (
          <Route key={`key-mypage-${i}`} {...props} />
        ))}
        <Route component={NotFound} />
      </Switch>
    </Layout.MypageLoggedIn>
  )
}

class TmLoggedIn extends Component {
  render() {
    const {currentTmUser, token} = this.props
    if (!token || _.isEmpty(currentTmUser)) {
      return <Layout.TmLoggedIn />
    }
    return (
      <Layout.TmLoggedIn>
        <Switch>
          <Route path={WITH_AUTH_TM_PATHS.LOGOUT} component={TmLogout} />
          <Route path="/tm/employees" exact component={tm.Employees.Search} />
          <Route path="/tm/employees/:id" exact component={tm.Employees.Detail} />
          <Redirect from="/tm/notifications" exact to="/tm/notifications/inbox" />
          <Route path="/tm/notifications/inbox" exact component={tm.Notifications.Inbox} />
          <Route path="/tm/notifications/inbox/all" exact component={tm.Notifications.InboxAll} />
          <Route path="/tm/notifications/sent" exact component={tm.Notifications.Sent} />
          <Route path="/tm/notifications/sent/all" exact component={tm.Notifications.SentAll} />
          <Route path="/tm/notifications/shared" exact component={tm.Notifications.Shared} />
          <Route path="/tm/notifications/shared/all" exact component={tm.Notifications.SharedAll} />
          <Route path="/tm/notifications/create" exact component={tm.Notifications.Create} />
          <Route path="/tm/notifications/:id" exact component={tm.Notifications.Show} />
          <Route path="/tm/notifications/:id/answer" exact component={tm.Notifications.Answer} />
        </Switch>
      </Layout.TmLoggedIn>
    )
  }
}

const ConnectedTmLoggedIn = connect((state) => ({
  token: state.auth.token,
  currentTmUser: state.session.currentTmUser,
}))(TmLoggedIn)

const withApollo = (WrappedComponent, client) => (props) =>
  (
    <ApolloProvider client={client}>
      <WrappedComponent {...props} />
    </ApolloProvider>
  )

const LoggedInWithApollo = withApollo(LoggedIn, apolloClient)
const MypageWithApollo = withApollo(MypageRoutes, mypageApolloClient)
const NotLoggedInWithApollo = withApollo(NotLoggedIn, apolloClient)
const TmLoggedInWithApollo = withApollo(ConnectedTmLoggedIn, tmApolloClient)

export default () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTopOnAnyNavigation />
      <Switch>
        <Route
          path={`/:path(
        logout|
        dashboard|
        employees|
        client|
        offices|
        enroll|
        resign|
        add_dependents|
        remove_dependents|
        change_address|
        change_name|
        change_dependents|
        maternity_leave|
        start_maternity|
        extend_maternity|
        finish_maternity|
        change_maternity|
        childcare|
        start_childcare|
        extend_childcare|
        finish_childcare|
        direct_request|
        procedure_statuses|
        procedure_new|
        settings|
        notifications|
        system_announces|
        account_settings|
        initial_input|
        my_number|
        e_gov_procedures|
        employee_files|
        documents|
        document_employees|
        document_templates|
        year_end_adj_start|
        stress_check_start|
        egov_procedures
        )`.replace(/\s/g, '')}
          component={LoggedInWithApollo}
        />
        <Route path="/mypage" component={MypageWithApollo} />
        <Route path="/tm" component={TmLoggedInWithApollo} />
        <Route component={NotLoggedInWithApollo} />
      </Switch>
    </ConnectedRouter>
  </Provider>
)

const errorMessage = Cookies.get('error_message')
if (errorMessage) {
  store.dispatch(notifyError(errorMessage))
  Cookies.remove('error_message')
}

const message = Cookies.get('message')
if (message) {
  store.dispatch(notifySuccess(message))
  Cookies.remove('message')
}
