import React, {Component} from 'react'
import styles from 'procedureStatuses/RelatedRecordsAlert.scss'
import ActionButton from 'jbc-front/components/ActionButton'
import Modal from 'jbc-front/components/Modal'
import {connect} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'

class RelatedRecordsAlertTodo extends Component {
  state = {
    showModal: false,
  }

  refreshTodoLists = async () => {
    const {
      dispatch,
      token,
      procedureStatus: {id},
      loadProcedureStatus,
    } = this.props
    try {
      await api.createWithAuth(token).procedureStatuses.refreshTodoLists(id)
      loadProcedureStatus()
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  render() {
    const {procedureStatus} = this.props
    return procedureStatus.displays_todo_list_refresh_button ? (
      <React.Fragment>
        <div className={styles.notice}>
          マスタデータが更新されています
          <ActionButton onClick={() => this.setState({showModal: true})} className={styles.actionButton}>
            ToDoリストを更新する
          </ActionButton>
        </div>
        <Modal
          isOpen={this.state.showModal}
          onSubmit={this.refreshTodoLists}
          hideModal={() => this.setState({showModal: false})}
          body="現在の進捗状況は保存されません。ToDoリストを更新しますか。"
          header="ToDoリストの更新"
          cancel="キャンセル"
          submit="更新"
        />
      </React.Fragment>
    ) : null
  }
}

export default connect((state) => ({
  procedureStatus: state.procedureStatuses.current.data,
  token: state.auth.token,
}))(RelatedRecordsAlertTodo)
