import React from 'react'
import Button from 'jbc-front/components/Button'
import styles from 'documents/create/Form.scss'
import {useWizard} from 'components/Wizard'
import List from 'documents/create/EmployeeList'
import Conditions from '../Conditions'
import _ from 'lodash'
import {FloatingButton} from 'components/FloatingButton'

const SelectEmployeesStep = ({selected, getDynamicDocument, validate}) => {
  const {pushStep, popStep, draft, setDraft} = useWizard()
  const needEmployees = draft.templateObject.hasCsvVariables || draft.templateObject.hasEmployeeVariables
  return (
    <div>
      <Conditions />
      {!needEmployees && (
        <div
          className={styles.checkEmployees}
          onClick={() => setDraft({...draft, checkEmployees: !draft.checkEmployees})}
        >
          <input className="m-checkbox-input" type="checkbox" checked={!draft.checkEmployees} />
          <span className="m-checkbox-parts">従業員を指定しない</span>
        </div>
      )}
      {(draft.checkEmployees || needEmployees) && <List selected={selected} />}
      <FloatingButton>
        <div className={styles.bottomButtonsArea}>
          <Button
            className={styles.wizardBackButton}
            onClick={() => {
              popStep()
            }}
          >
            テンプレート選択に戻る
          </Button>
          {(draft.templateObject?.hasCsvVariables && (
            <Button
              className={styles.wizardNextButton}
              primary
              disabled={needEmployees ? Object.values(selected).filter((select) => select).length === 0 : false}
              onClick={() => {
                setDraft({...draft, employeeIds: _.keys(_.pickBy(selected))})
                if (draft.customVariablesFile) {
                  getDynamicDocument({
                    variables: {
                      document: {
                        documentTemplateId: draft.documemtTemplateId,
                        customVariablesFile: draft.customVariablesFile,
                        title: draft.title,
                        employeeIds: draft.checkEmployees ? _.keys(_.pickBy(selected)) : null,
                      },
                    },
                  })
                  validate(draft.documemtTemplateId, draft.checkEmployees ? _.keys(_.pickBy(selected)) : null)
                }
                pushStep('UploadCustomVariables')
              }}
            >
              作成対象に設定
            </Button>
          )) || (
            <Button
              className={styles.wizardNextButton}
              primary
              disabled={
                needEmployees || draft.checkEmployees
                  ? Object.values(selected).filter((select) => select).length === 0
                  : false
              }
              onClick={() => {
                setDraft({...draft, employeeIds: _.keys(_.pickBy(selected))})
                getDynamicDocument({
                  variables: {
                    document: {
                      documentTemplateId: draft.documemtTemplateId,
                      customVariablesFile: null,
                      title: draft.title,
                      employeeIds: draft.checkEmployees ? _.keys(_.pickBy(selected)) : null,
                    },
                  },
                })
                validate(draft.documemtTemplateId, draft.checkEmployees ? _.keys(_.pickBy(selected)) : null)
                pushStep('Confirmation')
              }}
            >
              書類イメージを確認する
            </Button>
          )}
        </div>
      </FloatingButton>
    </div>
  )
}

export default SelectEmployeesStep
