import React from 'react'
import {Section, BoxDouble, TextField, RadioField} from 'jbc-front/components/Form'
import {withModal} from 'jbc-front/components/CommonModal'
import ActionButton from 'jbc-front/components/ActionButton'
import {number, maxLengthNumber, exactLengthNumber} from 'validators'
import {useSelector, useDispatch} from 'react-redux'
import {Description, getDiffFromOptions, withFormSelectors} from 'employees/form/common'
import BankSearchModalWrapper from 'employees/form/BankSearchModalWrapper'

export const bankAccountTypes = [
  {value: 'normal', label: '普通預金'},
  {value: 'current', label: '当座預金'},
]

const BankInfomation = ({
  selector,
  autofill,
  showModal,
  isModalOpen: isOpen,
  hideModal,
  description,
  description_color_by_rgb,
  formName,
}) => {
  const dispatch = useDispatch()
  const diff = useSelector((state) => selector(state, '_diff') || {})
  const bankNameKana = useSelector((state) => selector(state, 'bank_name_kana'))
  const nameKana = useSelector((state) =>
    [selector(state, 'last_name_kana'), selector(state, 'first_name_kana')].filter((n) => n).join('　')
  )

  const autoFillBankNameKana = () => {
    if (!bankNameKana && nameKana) {
      dispatch(autofill('bank_name_kana', nameKana))
    }
  }
  return (
    <Section title="口座情報">
      <Description {...{description, description_color_by_rgb}} />
      <ActionButton onClick={showModal} className="u-mb20">
        銀行コード検索
      </ActionButton>
      <BankSearchModalWrapper
        isOpen={isOpen}
        hideModal={hideModal}
        formName={formName}
        onAfterSelect={autoFillBankNameKana}
      />

      <BoxDouble>
        <TextField
          name="bank_code"
          label="銀行コード"
          validate={exactLengthNumber(4)}
          diff={diff.bank_code}
          onChange={autoFillBankNameKana}
        />
        <TextField name="bank_name" label="銀行名" diff={diff.bank_name} onChange={autoFillBankNameKana} />
        <TextField
          name="bank_branch_code"
          label="支店コード"
          validate={exactLengthNumber(3)}
          diff={diff.bank_branch_code}
          onChange={autoFillBankNameKana}
        />
        <TextField
          name="bank_branch_name"
          label="支店名"
          diff={diff.bank_branch_name}
          onChange={autoFillBankNameKana}
        />
      </BoxDouble>
      <RadioField
        name="bank_type"
        label="預金種別"
        options={bankAccountTypes}
        diff={getDiffFromOptions(bankAccountTypes, diff?.bank_type)}
        onChange={autoFillBankNameKana}
      />

      <BoxDouble>
        <TextField
          name="bank_number"
          label="口座番号"
          validate={[number, maxLengthNumber(7)]}
          diff={diff.bank_number}
          onChange={autoFillBankNameKana}
        />
        <TextField name="bank_name_kana" label="口座名義（カナ）" diff={diff.bank_name_kana} />
      </BoxDouble>
    </Section>
  )
}

export default BankInfomation |> withFormSelectors |> withModal
