import _ from 'lodash'

export const parseHtml = (html?: string) => {
  if (!html) {
    return {__html: ''}
  }
  let escaped = _.escape(html)
  escaped = escaped.replace(
    /&lt;a href=(&quot;|&#39;)(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)(&quot;|&#39;)&gt;(.+?)&lt;\/a&gt;/g,
    '<a href="$2" target="_blank" class="u-txt-link" rel="noopener noreferrer">$4</a>'
  )
  escaped = escaped.replace(
    /(^|[^"'>])(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g,
    '$1<a href="$2" target="_blank" class="u-txt-link" rel="noopener noreferrer">$2</a>'
  )
  escaped = escaped.replace(/&lt;b&gt;(.+?)&lt;\/b&gt;/g, '<b>$1</b>')
  escaped = escaped.replace(/&lt;u&gt;(.+?)&lt;\/u&gt;/g, '<u>$1</u>')
  escaped = escaped.replace(/&lt;i&gt;(.+?)&lt;\/i&gt;/g, '<i>$1</i>')
  escaped = escaped.replace(/\r\n|\r|\n/g, '<br />')
  return {__html: escaped}
}
