import {FC} from 'react'
import styles from './Presenter.scss'

interface PresenterProps {
  email: string
}

export const Presenter: FC<PresenterProps> = ({email}) => {
  return (
    <div>
      <div className="l-wrap-xs u-pt100">
        <div className={styles.main}>
          <p className="m-title-sub u-pt0 u-ta-c">ジョブカン労務HRの登録が完了しました</p>
          <p className="u-mb20">登録したメールアドレス（{email}）に認証のURLを送信しましたのでご確認ください。</p>
          <p>
            しばらく経ってもメールが届かない場合は、迷惑メールフォルダ等を確認するか、再度アカウント登録をお願いします。
          </p>
        </div>
      </div>
    </div>
  )
}
