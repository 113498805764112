import {saveAs} from 'file-saver'
import axios from 'axios'
import NProgress from 'nprogress'

interface RequestOption {
  url: string
  usePost?: boolean
  params?: unknown
  asyncError: (e: Error) => void
  selectedOffices: string
}

export const downloadFile = ({url, asyncError, selectedOffices, params = {}, usePost = false}: RequestOption) => {
  NProgress.start()
  const p = usePost
    ? axios.post(url, params, {responseType: 'blob', headers: {'X-Office-Ids': selectedOffices}})
    : axios.get(url, {responseType: 'blob', headers: {'X-Office-Ids': selectedOffices}, params})
  return p
    .then((res) => {
      saveAs(res.data, decodeURIComponent(res.headers['content-disposition'].match(/filename\*=UTF-8''(.*)/)[1]))
    })
    .catch(asyncError)
    .finally(() => {
      NProgress.done()
    })
}
