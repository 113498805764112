import React, {Component} from 'react'
import Form, {makeInitialValues} from 'procedures/maternityLeave/extend/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import CommentModal from 'procedures/CommentModal'
import _ from 'lodash'
import checkEmployeeInput from 'components/checkEmployeeInput'
import checkUnemployed from 'components/checkUnemployed'
import compose from 'lodash/fp/compose'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'extendMaternity'

class EmployeeInput extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      location: {search},
      loadEmployee,
      token,
      loadTmpData,
      loadComments,
      loadProcedureStatus,
    } = this.props
    const {procedure_id: procedureId} = parse(search)
    loadEmployee(token)
    loadComments(id, token)
    if (id) {
      loadTmpData(id, token)
    }
    if (procedureId) {
      loadProcedureStatus(procedureId, token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      procedureStatus,
      match: {
        params: {id},
      },
      location: {search},
      tmpData: {application_procedure_extend_maternity: extendMaternityData},
      tmpFiles = [],
      comments,
      healthInsuranceType,
      updateAndCreateProcedure,
      token,
    } = this.props
    const startMaternityData = _.mapValues(
      _.keyBy(procedureStatus?.procedure_tmp_data || [], (tmpData) => `${tmpData.procedure_tmp_type}_${tmpData.name}`),
      ({data}) => {
        try {
          return JSON.parse(data)
        } catch (err) {
          return null
        }
      }
    ).application_procedure_start_maternity
    const prevData = startMaternityData
      ? {
          expected_birth_date_before: startMaternityData.expected_birth_date,
          maternity_division_before: startMaternityData.maternity_division,
          started_at_before: startMaternityData.started_at,
          expected_finished_at_before: startMaternityData.expected_finished_at,
          maternity_status: startMaternityData.maternity_status,
          procedure_maternity_child_data: startMaternityData.procedure_maternity_child_data || [{}],
        }
      : null
    const maternityData = extendMaternityData || prevData
    const {procedure_id: procedureId} = parse(search)
    const handleSubmit = (values, comment) =>
      updateAndCreateProcedure(id || procedureId, {...values, comment: comment}, token)
    return (
      <div>
        <CommentModal
          formName={formName}
          procedureType="maternityLeave"
          onSubmit={({comment, values}) => {
            handleSubmit(values, comment)
          }}
          comments={comments || []}
        >
          {({showModal}) => (
            <Form
              onSubmit={showModal}
              initialValues={makeInitialValues(procedureStatus, maternityData, tmpFiles)}
              tmpFiles={tmpFiles}
              submitText="申請する"
              healthInsuranceType={healthInsuranceType}
              comments={comments || {}}
            />
          )}
        </CommentModal>
      </div>
    )
  }
}

export default compose(
  checkEmployeeInput(),
  checkUnemployed(),
  connect(
    (state) => ({
      employee: state.employees.current.data,
      procedureStatus: state.procedureStatuses.current.data,
      tmpData: state.procedureStatuses.tmpData.data,
      tmpFiles: state.procedureStatuses.tmpData.tmpFiles,
      comments: fetchSelector(state, 'procedure_comments').data,
      healthInsuranceType: _.get(state.session.currentUser, 'employee.office.health_insurance_type'),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadEmployee: (token) => {
        dispatch(actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.getCurrent(['office'])))
      },
      loadTmpData: (id, token) => {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api
                .createWithAuth(token)
                .procedureStatuses.tmpData.mapToJson(id, {type: 'application', name: 'procedure_extend_maternity'}),
              api.createWithAuth(token).procedureStatuses.tmpFiles.list(id, 'application', 'mother_child_note'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      loadProcedureStatus: (id, token) => {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api
              .createWithAuth(token)
              .procedureStatuses.get(id, ['employee', 'procedure_maternity_datum', 'procedure_tmp_data'])
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndCreateProcedure: async (procedureStatusId, values, token) => {
        const formattedValues = {
          ...values,
          mother_child_note: values.maternity_status === 'after' ? values.mother_child_note : [],
        }
        const authedApi = api.createWithAuth(token)
        try {
          await authedApi.procedureStatuses.updateFromEmployee(procedureStatusId, {
            procedure_type: 'extend_maternity',
            date_on: formattedValues.expectedBirthDate,
            status: 'employee_draft',
          })
          await authedApi.procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            formattedValues,
            'application',
            'procedure_extend_maternity'
          )
          const newFiles = formattedValues.mother_child_note.filter((note) => !note.filename)
          const keepFileNames = formattedValues.mother_child_note.map((note) => note.filename).filter((n) => n)
          await authedApi.procedureStatuses.tmpFiles.bulkPost(
            procedureStatusId,
            newFiles,
            keepFileNames,
            'application',
            'mother_child_note'
          )
          await authedApi.procedureStatuses.apply(procedureStatusId, formattedValues.comment)
          if (formattedValues.procedure_maternity_child_data.some((child_datum) => child_datum.add_support)) {
            dispatch(push(`/mypage/add_dependents/employee_input?maternity_procedure_status_id=${procedureStatusId}`))
          } else {
            dispatch(push('/dashboard'))
          }
          dispatch(notifySuccess('申請しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(EmployeeInput)
