import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Index} from 'pages/withAuth/adminPage/procedureStatuses/chooseProcedure'

export const ProcedureStatusRoutes: RouteParams[] = [
  {
    key: 'procedure_statuses/choose_procedures',
    path: WITH_AUTH_ADMIN_PATHS.PROCEDURE_STATUSES.CHOOSE_PROCEDURE,
    component: Index,
    exact: true,
    whiteList: ['full_admin', 'office_admin', 'limited_admin'],
  },
]
