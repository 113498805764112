import React, {Component} from 'react'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {Dl, Dd, Dt} from 'components/SecondaryPanel'
import {recordDisplay} from 'utils'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchSelector, actionCreators} from 'actions'
import api from 'api'
import _ from 'lodash'
import {downloadFile} from 'libs/downloadFile'
import styles from 'eGovProcedures/Show.scss'
import {Parser, ProcessNodeDefinitions} from 'html-to-react'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {asyncError} from 'store/actions/asyncError'

const errorParser = new Parser()
const processNodeDefinitions = new ProcessNodeDefinitions()
const processingInstructions = [
  {
    shouldProcessNode: function (node) {
      return !node.name || !node.name.match(/^hr|script$/i)
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

const Error = ({html, className}) =>
  html ? (
    <div className={className}>{errorParser.parseWithInstructions(html, () => true, processingInstructions)}</div>
  ) : (
    recordDisplay()
  )

class Show extends Component {
  componentDidMount() {
    const {
      token,
      loadEGovProcedure,
      match: {
        params: {id},
      },
    } = this.props
    loadEGovProcedure(token, id)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  static defaultProps = {
    eGovProcedure: {},
  }

  render() {
    const {eGovProcedure, asyncError, selectedOffices} = this.props
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">電子申請詳細情報</h1>
        </div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to={WITH_AUTH_ADMIN_PATHS.EGOV_PROCEDURES.INDEX} className="l-breadcrumb-link">
              電子申請一覧
            </Link>
            <span className="l-breadcrumb-here">電子申請詳細</span>
          </div>
          <Panel>
            <Headding>
              {eGovProcedure.procedure_status
                ? `${recordDisplay.fullName(_.get(eGovProcedure, 'procedure_status.employee'))}さん`
                : recordDisplay(eGovProcedure.office_name)}
              の電子申請情報
            </Headding>
            <div className="u-pc-pt30 u-pc-pr15 u-pc-pl15">
              <Item>
                <Dl>
                  <Dt>氏名・事業所名：</Dt>
                  <Dd>
                    {eGovProcedure.procedure_status
                      ? recordDisplay.registeredFullName(_.get(eGovProcedure, 'procedure_status.employee'))
                      : recordDisplay(eGovProcedure.office_name)}
                  </Dd>
                  <Dt>手続名：</Dt>
                  <Dd>{recordDisplay(eGovProcedure.procedure_name)}</Dd>
                  <Dt>帳票名：</Dt>
                  <Dd>{recordDisplay(eGovProcedure.form_name)}</Dd>
                  <Dt>送信番号：</Dt>
                  <Dd>{recordDisplay(eGovProcedure.send_number)}</Dd>
                  <Dt>到達番号：</Dt>
                  <Dd>{recordDisplay(eGovProcedure.arrive_id)}</Dd>
                  <Dt>エラー内容：</Dt>
                  <Dd>
                    <Error html={eGovProcedure.error_html} className={styles.error} />
                  </Dd>
                  <Dt>公文書・コメント：</Dt>
                  <Dd>
                    {eGovProcedure.has_document && (
                      <React.Fragment>
                        <a
                          href={`/api/egov_procedure_statuses/${eGovProcedure.id}/officialdocument`}
                          className="u-txt-link"
                          onClick={(e) => {
                            downloadFile({
                              url: `/api/egov_procedure_statuses/${eGovProcedure.id}/officialdocument`,
                              asyncError,
                              selectedOffices,
                            })
                            e.preventDefault()
                          }}
                        >
                          厚生労働省からのお知らせ
                        </a>
                        <br />
                      </React.Fragment>
                    )}
                    {eGovProcedure.has_comment && (
                      <React.Fragment>
                        <a
                          href={`/api/egov_procedure_statuses/${eGovProcedure.id}/comment`}
                          className="u-txt-link"
                          onClick={(e) => {
                            downloadFile({
                              url: `/api/egov_procedure_statuses/${eGovProcedure.id}/comment`,
                              asyncError,
                              selectedOffices,
                            })
                            e.preventDefault()
                          }}
                        >
                          コメント
                        </a>
                        <br />
                      </React.Fragment>
                    )}
                    {!eGovProcedure.has_document && !eGovProcedure.has_comment && recordDisplay()}
                  </Dd>
                </Dl>
              </Item>
            </div>
          </Panel>
          {eGovProcedure.status === 'procedure_completed' && (
            <p className={styles.noteWrap}>
              公文書ファイルが文字化けしている場合は
              <a
                className="u-txt-link"
                target="_blank"
                href="https://jobcan-lms.zendesk.com/hc/ja/articles/360001865711"
                rel="noopener noreferrer"
              >
                こちら
              </a>
            </p>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    eGovProcedure: fetchSelector(state, 'e_gov_procedure').data,
    selectedOffices: state.session.selectedOffices,
  }),
  (dispatch) => ({
    loadEGovProcedure(token, id) {
      dispatch(
        actionCreators.fetchData(
          'e_gov_procedure',
          api.createWithAuth(token).eGovProcedure.get(id, {
            embed: ['procedure_status'],
          })
        )
      )
    },
    destroy() {
      dispatch(actionCreators.fetchDestroy('e_gov_procedure'))
    },
    asyncError(err) {
      dispatch(asyncError(err))
    },
  })
)(Show)
