import React from 'react'
import {Card, Container} from 'components/SelectCard'
import {useDispatch} from 'react-redux'
import {UserSelect, UserAdd} from 'jbc-front/components/icons'
import {parse} from 'query-string'
import {push} from 'connected-react-router'

const ChooseUseOrCreate = ({location: {search}}) => {
  const {employee_id} = parse(search)
  const dispatch = useDispatch()
  const chooseProcedure = () => {
    dispatch(push(`/finish_maternity/choose_procedure?employee_id=${employee_id}`))
  }
  const createProcedure = () => {
    dispatch(push(`/finish_maternity/detail?employee_id=${employee_id}`))
  }

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">進め方を選択</h1>
        <p className="m-title-main-note">産休の手続きの進め方を選んでください</p>
      </div>
      <Container>
        <Card
          icon={UserSelect}
          onClick={() => {
            chooseProcedure()
          }}
        >
          過去の手続き情報を利用
        </Card>
        <Card icon={UserAdd} onClick={createProcedure}>
          手続きの新規作成
        </Card>
      </Container>
    </div>
  )
}
export default ChooseUseOrCreate
