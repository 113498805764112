import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Grid, Dl, Dt, Dd, Wrap} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'

const martialStatus = {married: '有', single: '無'}

const Marital = ({employee, editLink, canEdit, forProfiles}) => (
  <Panel>
    <HeaddingWithLink to={`${editLink}#Marital`} title="配偶者情報" canEdit={canEdit} />
    <Item>
      {forProfiles ? (
        <Wrap forProfiles>
          <Dl>
            <Dt>配偶者の有無</Dt>
            <Dd>{recordDisplay(martialStatus[employee.marital_status])}</Dd>
          </Dl>
          <Dl>
            <Dt>配偶者の年間収入（去年）</Dt>
            <Dd>{recordDisplay.yen(employee.spouse_annual_income)}</Dd>
          </Dl>
          <Dl>
            <Dt>配偶者の月額収入（現在）</Dt>
            <Dd>{recordDisplay.yen(employee.spouse_monthly_income)}</Dd>
          </Dl>
        </Wrap>
      ) : (
        <Grid forProfiles={forProfiles}>
          <Dl>
            <Dt>配偶者の有無</Dt>
            <Dd>{recordDisplay(martialStatus[employee.marital_status])}</Dd>
          </Dl>
          <Dl>
            <Dt>配偶者の年間収入（去年）</Dt>
            <Dd>{recordDisplay.yen(employee.spouse_annual_income)}</Dd>
          </Dl>
          <Dl>
            <Dt>配偶者の月額収入（現在）</Dt>
            <Dd>{recordDisplay.yen(employee.spouse_monthly_income)}</Dd>
          </Dl>
        </Grid>
      )}
    </Item>
  </Panel>
)

export default connect((state) => ({
  employee: state.employees.current.data,
}))(Marital)
