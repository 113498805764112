import React, {Component} from 'react'
import {connect} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import styles from './StressCheckStart.scss'
import Loading from 'components/Loading'
import {parse} from 'query-string'
import {isFullAdminSelector} from 'utils'

class StressCheckStart extends Component {
  state = {
    creating: false,
    error: false,
  }

  start = async () => {
    const {token, dispatch} = this.props
    if (!(await this.checkStatus())) {
      this.setState({error: true})
      return
    }
    try {
      const {
        data: {status, link},
      } = await api.createWithAuth(token).StressCheck.start()
      if (status === 'started') {
        window.location = link
      } else {
        this.create()
      }
    } catch (err) {
      this.setState({error: true})
      dispatch(asyncError(err))
    }
  }

  create = async () => {
    if (this.state.creating) {
      return
    }
    const {
      dispatch,
      token,
      location: {search},
    } = this.props
    try {
      const roleType = parse(search).role_type
      this.setState({creating: true})
      const {
        data: {status, link},
      } = await api.createWithAuth(token).StressCheck.create(roleType)
      if (status === 'started') {
        window.location = link
      }
    } catch (err) {
      this.setState({error: true})
      dispatch(asyncError(err))
    } finally {
      this.setState({creating: false})
    }
  }

  checkStatus = async () => {
    const {token, dispatch, isFullAdmin} = this.props
    try {
      const {
        data: {status},
      } = await api.createWithAuth(token).StressCheck.showStressCheckStatus()
      if (status === 'NOT_STARTED' && isFullAdmin) {
        return true
      } else if (status === 'HAS_ADMIN') {
        return true
      } else {
        return false
      }
    } catch (err) {
      dispatch(asyncError(err))
      return false
    }
  }

  componentDidMount() {
    this.start()
  }

  render() {
    return (
      <div className="l-wrap-xs u-pt100">
        {this.state.error ? (
          <div className="l-box-message">エラーが発生しました</div>
        ) : (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  isFullAdmin: isFullAdminSelector(state),
}))(StressCheckStart)
