import React from 'react'
import {TextField, Section} from 'jbc-front/components/Form'
import {reduxForm} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {connect} from 'react-redux'
import api from 'api'
import {getState} from 'utils'
import {push} from 'connected-react-router'
import {email} from 'validators'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const ChangeEmailForm = reduxForm({
  form: 'change_email',
  onSubmitFail,
})(({submitting, handleSubmit}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">メールアドレス変更</h1>
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section>
        <TextField name="new_email" label="メールアドレス" validate={email} required />
      </Section>
      <div className="u-ta-c u-mt30">
        <Button as="button" primary type="submit" disabled={submitting}>
          変更
        </Button>
      </div>
    </div>
  </form>
))

const ChangeEmail = connect(null, (dispatch) => ({
  async onSubmit(values) {
    const state = await getState(dispatch)
    const token = state.auth.token
    try {
      await api.createWithAuth(token).changeEmail(values)
      dispatch(notifySuccess('確認メールを送信しました'))
      dispatch(push('/account_settings/account'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
}))(ChangeEmailForm)

export default ChangeEmail
