import {FC} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {CREATE_DOCUMENT_COMMENT, DOCUMENTS} from '../../query'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Button from 'jbc-front/components/Button'
import styles from './MemoForm.scss'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface CreateMemoFormProps {
  documentId: number
  reset: () => void
}

interface CreateFormSchema {
  documentId: number
  body: string
}

export const MemoCreateForm: FC<CreateMemoFormProps> = ({documentId, reset}) => {
  const [create, {loading}] = useMutation(CREATE_DOCUMENT_COMMENT)
  const {
    register,
    handleSubmit,
    reset: formReset,
    formState: {isValid},
  } = useForm<CreateFormSchema>({
    mode: 'onChange',
    defaultValues: {
      documentId,
      body: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        documentId: yup.number().required(),
        body: yup.string().required(),
      })
    ),
  })

  const onSubmit: SubmitHandler<CreateFormSchema> = async (data) => {
    await create({
      variables: {input: data},
      refetchQueries: [{query: DOCUMENTS}],
    })

    formReset()
    reset()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Textarea {...register('body')} className={styles.textarea} />
      <Spacer direction="y" size={20} />
      <Button onClick={handleSubmit(onSubmit)} primary disabled={loading || !isValid}>
        保存
      </Button>
    </form>
  )
}
