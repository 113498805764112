import type {FC} from 'react'
import styles from './NoticeModal.scss'

// @ts-ignore
import CommonModal from 'jbc-front/components/CommonModal'

interface NoticeModalProps {
  isOpen: boolean
  hideModal: () => void
  title?: string
  sentence?: string
}

export const NoticeModal: FC<NoticeModalProps> = ({isOpen, hideModal, title, sentence}) => (
  <CommonModal isOpen={isOpen}>
    <CommonModal.Header hideModal={hideModal}>{title || '無題'}</CommonModal.Header>
    <CommonModal.Body>
      <div className={styles.sentence}>{sentence || 'この通知には詳細がありません'}</div>
    </CommonModal.Body>
  </CommonModal>
)
