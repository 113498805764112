import {connect} from 'react-redux'
import {SortingFieldsWithResult} from 'jbc-front/components/SearchForm'
import {selector} from 'settings/clientRoles/utils'
import {fetchSelector} from 'actions'

const limits = ['10', '20', '50', '100', '200'].map((ele) => ({value: ele, label: `${ele}件を表示`}))
const sortTypes = [
  {value: 'email__asc', label: 'メールアドレス 昇順'},
  {value: 'email__desc', label: 'メールアドレス 降順'},
  {value: 'staff_code__asc', label: 'スタッフコード 昇順'},
  {value: 'staff_code__desc', label: 'スタッフコード 降順'},
  {value: 'full_name_kana__asc', label: '氏名 昇順'},
  {value: 'full_name_kana__desc', label: '氏名 降順'},
]
const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: fetchSelector(state, 'users_clients').count,
  limits,
  sortTypes,
  page: selector(state, 'page'),
  isLoading: fetchSelector(state, 'users_clients').loading,
})
export default connect(mapStateToProps)(SortingFieldsWithResult)
