import React from 'react'
import {connect} from 'react-redux'
import {Fields, Field} from 'redux-form'
import Select from 'react-select'
import {dateFieldProps} from 'jbc-front/components/Form'
import {GroupsSelectBox, TagSelectBox, MultiTagFilteringFieldRow, SearchTextBox} from 'tm/employees/list/SearchForm'
import {maxAge, dateString} from 'validators'
import {selector} from 'tm/employees/list/utils'
import styles from './SearchForm.scss'
import {fetchSelector} from 'actions'
import {renderError} from 'employees/list/rows'
import {TextTmRow, DateTmRow} from './TmRows'
import {toOpts, withNull, withNullOpt} from 'tm/employees/list/addedFields/utils'
import ActionButton from 'jbc-front/components/ActionButton'

const genders = [
  {name: '男性', id: 'man'},
  {name: '女性', id: 'woman'},
]

export class OfficesSelectBox extends React.Component {
  render() {
    const {input: inputProps, options} = this.props
    return (
      <div className={styles.groupsSelectBox}>
        <Select
          multi
          options={options}
          closeOnSelect={false}
          clearableValue={false}
          simpleValue
          placeholder="事業所選択"
          name={inputProps.name}
          value={inputProps.value}
          onChange={(value) => {
            inputProps.onChange(value)
            inputProps.onBlur(value)
          }}
        />
      </div>
    )
  }
}

export class LanguagesSelectBox extends React.Component {
  render() {
    const {input: inputProps, options} = this.props
    return (
      <div className={styles.languagesSelectBox}>
        <Select
          options={options}
          closeOnSelect={false}
          clearableValue={false}
          simpleValue
          placeholder=""
          name={inputProps.name}
          value={inputProps.value}
          onChange={(value) => {
            inputProps.onChange(value)
            inputProps.onBlur(value)
          }}
        />
      </div>
    )
  }
}

const AdditionalSearchFields = ({
  groups = [],
  tags = [],
  displaySettings = [],
  isExpand,
  offices = [],
  positions = [],
  employmentTypes = [],
  occupations = [],
  employeeStatuses = [],
  languages = [],
  languageLevels = [],
  handleClear,
  searchEmail = true,
}) => {
  return (
    <div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>スタッフコード</div>
        <div className={styles.detailsBody}>
          <div className={styles.groupsSelectBox}>
            <Field name="staff_code" component={SearchTextBox} />
          </div>
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>氏名</div>
        <div className={styles.detailsBody}>
          <div className={styles.groupsSelectBox}>
            <Field name="name" component={SearchTextBox} />
          </div>
        </div>
      </div>
      <ActionButton as="button" type="button" className={styles.mainFormControl} onClick={handleClear}>
        条件解除
      </ActionButton>
      {displaySettings.some((setting) => setting.code == 'contact_information') && searchEmail && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeader}>メールアドレス</div>
          <div className={styles.detailsBody}>
            <div className={styles.groupsSelectBox}>
              <Field name="email" component={SearchTextBox} />
            </div>
          </div>
        </div>
      )}
      {displaySettings.some((setting) => setting.code == 'tag') && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeader}>タグ</div>
          <div className={styles.detailsBody}>
            <div className={styles.detailsBodyItems}>
              <Field name="tags" component={TagSelectBox} options={withNullOpt(tags)} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>グループ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field name="groups" component={GroupsSelectBox} options={groups} />
          </div>
        </div>
      </div>
      {isExpand && displaySettings.some((setting) => setting.code == 'affiliation') && offices.length > 1 && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeader}>事業所</div>
          <div className={styles.detailsBody}>
            <div className={styles.detailsBodyItems}>
              <Field name="offices" component={OfficesSelectBox} options={offices} />
            </div>
          </div>
        </div>
      )}
      {isExpand && displaySettings.some((setting) => setting.code == 'affiliation') && (
        <>
          <MultiTagFilteringFieldRow header="役職" fieldName="positions" list={positions} />
          <MultiTagFilteringFieldRow header="雇用形態" fieldName="employment_types" list={employmentTypes} />
          <MultiTagFilteringFieldRow header="職種" fieldName="occupations" list={occupations} />
          <MultiTagFilteringFieldRow header="在籍状況" fieldName="employment_statuses" list={employeeStatuses} />
          <DateTmRow header="入社日" errorLabel="日付" prefix="joined_at" />
        </>
      )}
      {isExpand && displaySettings.some((setting) => setting.code == 'personal_information') && (
        <>
          <DateTmRow header="生年月日" errorLabel="日付" prefix="birthday" />
          <div className={styles.detailsRow}>
            <div className={styles.detailsHeader}>年齢</div>
            <div className={styles.detailsBody}>
              <div className={styles.detailsBodyItems}>
                <Field name="age_from" component={SearchTextBox} validate={maxAge(120)} inputWidth={40} maxLength={3} />
                <span className={styles.detailsComplementText}>歳以上</span>
                <Field name="age_to" component={SearchTextBox} validate={maxAge(120)} inputWidth={40} maxLength={3} />
                <span className={styles.detailsComplementText}>歳以下</span>
                <div className={styles.detailsSelectDate}>
                  <Field
                    name="age_base_date"
                    component={SearchTextBox}
                    type="date"
                    {...dateFieldProps}
                    validate={dateString}
                  />
                </div>
                <span className={styles.detailsComplementText}>時点</span>
              </div>
              <div className={styles.detailsBodyErrors}>
                <Fields component={renderError} names={['age_from', 'age_to', 'age_base_date']} label="年齢" />
              </div>
            </div>
          </div>
          <MultiTagFilteringFieldRow header="性別" fieldName="genders" list={withNull(genders)} />
        </>
      )}
      {isExpand && displaySettings.some((setting) => setting.code == 'skill') && (
        <TextTmRow header="スキル" fieldName="skills" />
      )}
      {isExpand && displaySettings.some((setting) => setting.code == 'qualification') && (
        <TextTmRow header="資格" fieldName="qualifications" />
      )}
      {isExpand && displaySettings.some((setting) => setting.code == 'language') && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeader}>語学</div>
          <div className={styles.detailsBody}>
            <div className={styles.detailsBodyItems}>
              <span className={styles.detailsComplementLabel}>言語</span>
              <Field name="languages" component={LanguagesSelectBox} options={toOpts(languages)} />
              <div className={styles.detailsBodyItems}>
                <span className={styles.detailsComplementLabel2}>レベル</span>
                <Field name="language_levels" component={LanguagesSelectBox} options={toOpts(languageLevels)} />
              </div>
            </div>
            <div className={styles.detailsBodyItems}>
              <span className={styles.detailsComplementLabel}>資格</span>
              <Field name="languages_qualification_name" component={SearchTextBox} />
              <span className={styles.detailsComplementLabel2}>点数</span>
              <Field name="languages_score_from" component={SearchTextBox} inputWidth={60} maxLength={6} />
              <span className={styles.detailsComplementText}>点以上</span>
              <Field name="languages_score_to" component={SearchTextBox} inputWidth={60} maxLength={6} />
              <span className={styles.detailsComplementText}>点以下</span>
            </div>
          </div>
        </div>
      )}
      {isExpand && displaySettings.some((setting) => setting.code == 'project') && (
        <TextTmRow header="プロジェクト" fieldName="projects" />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  offices: fetchSelector(state, 'selections').allOffices,
  groups: fetchSelector(state, 'selections').groups,
  positions: fetchSelector(state, 'selections').positions,
  employmentTypes: fetchSelector(state, 'selections').employmentTypes,
  occupations: fetchSelector(state, 'selections').occupations,
  employeeStatuses: fetchSelector(state, 'selections').employeeStatuses,
  tags: fetchSelector(state, 'selections').tags,
  languages: fetchSelector(state, 'selections').languages,
  languageLevels: fetchSelector(state, 'selections').languageLevels,
  displaySettings: fetchSelector(state, 'selections').displaySettings,
  ageFrom: selector(state, 'age_from'),
  ageTo: selector(state, 'age_to'),
})
export default connect(mapStateToProps)(AdditionalSearchFields)
