import type {FC} from 'react'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {useMutation} from '@apollo/client'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {Dl, Dd, Dt} from 'components/ui/DefinitionList'
import {displayFormat} from 'libs/formatter'
import {notifySuccess, notifyError} from 'store/actions/notify'

import Button from 'jbc-front/components/Button'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Input} from 'jbc-front/components/presenters/form/Input'
import {Error} from 'jbc-front/components/presenters/form/Error'
import {Section} from 'jbc-front/components/Form'
// @ts-ignore
import ListGroup from 'jbc-front/components/ListGroup'

import {EgovProcedure, WithdrawResult, WITHDRAW_EGOV_PROCEDURE} from '../../query'

// @ts-ignore
import CommonModal from 'jbc-front/components/CommonModal'

import styles from './WithdrawModal.scss'

interface WithdrawModalProps {
  isOpen: boolean
  hideModal: () => void
  egovProcedure: EgovProcedure
  isWithdrawable: boolean
  refetch: (egovProcedureId: string) => void
}

interface WithdrawFormSchema {
  pin: string
  withdrawReason: string
}

interface ErrorReport {
  file_name: string
  item: string
  content: string
}

interface FormatError {
  title: string
  detail: string
  reportList: {
    item: string
    content: string
  }[]
}

interface ErrorBodyProps {
  formatError: FormatError
  closeModal: () => void
}

const ErrorBody: FC<ErrorBodyProps> = ({formatError, closeModal}) => (
  <>
    <CommonModal.Body>
      <Section
        title={
          <div>
            入力内容に誤りがあります。
            <br />
            修正後に再度申請をお願いします。
          </div>
        }
        error
      >
        <ListGroup>
          <ListGroup.Title>エラー内容</ListGroup.Title>
          <ListGroup.Item>{formatError.title}</ListGroup.Item>
          <ListGroup.Title>エラー詳細</ListGroup.Title>
          <ListGroup.Item>{formatError.detail}</ListGroup.Item>
          {formatError.reportList.length > 0 && (
            <>
              <ListGroup.Title>エラーレポート一覧</ListGroup.Title>
              {formatError.reportList.map((report, index) => {
                return (
                  <ListGroup.Item key={index}>
                    <Dl separator="：">
                      <Dt>項目名</Dt>
                      <Dd>{report.item}</Dd>
                      <Dt>エラー内容</Dt>
                      <Dd>{report.content}</Dd>
                    </Dl>
                  </ListGroup.Item>
                )
              })}
            </>
          )}
        </ListGroup>
      </Section>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={closeModal}>閉じる</Button>
    </CommonModal.Footer>
  </>
)

export const WithdrawModal: FC<WithdrawModalProps> = ({isOpen, hideModal, egovProcedure, isWithdrawable, refetch}) => {
  const [formatError, setFormatError] = useState<FormatError | null>(null)
  const [withdraw] = useMutation<WithdrawResult>(WITHDRAW_EGOV_PROCEDURE)
  const dispatch = useDispatch()
  const {
    egovApplication: {egovAccount},
  } = egovProcedure
  const signRequired = egovProcedure.signRequired || !['PRIME', 'MEMBER'].includes(egovAccount?.gbizidAccountType)
  const schema = yup.object({
    pin: signRequired ? yup.string().required('PINコードを入力してください') : yup.string().notRequired(),
    withdrawReason: yup.string().required('取下げ理由を入力してください'),
  })
  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setError,
  } = useForm<WithdrawFormSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      pin: '',
      withdrawReason: '',
    },
  })

  const handleWithdraw = async (data: {pin: string; withdrawReason: string}) => {
    if (!isWithdrawable) {
      dispatch(notifyError('この申請は取下げできません'))
      return
    }

    try {
      await withdraw({
        variables: {
          egovProcedureId: egovProcedure.id,
          pin: data.pin,
          withdrawReason: data.withdrawReason,
        },
      })

      dispatch(notifySuccess('取下げ申請に成功しました'))
      hideModal()
      refetch(egovProcedure.id)
    } catch (err) {
      try {
        const errors = JSON.parse(err.message)
        if (errors?.reason === 'egov_format_check_error') {
          const formatError = {
            title: errors.title,
            detail: displayFormat(errors.detail),
            reportList: errors.report_list.map((report: ErrorReport) => ({
              fileName: displayFormat(report.file_name),
              item: displayFormat(report.item),
              content: displayFormat(report.content),
            })),
          } as FormatError
          setFormatError(formatError)
        } else if (errors?.pin) {
          setError('pin', {type: 'custom', message: errors.pin})
        }
      } catch (__) {
        dispatch(notifyError(err.message))
        hideModal()
      }
    }
  }

  const closeModal = () => {
    setFormatError(null)
    hideModal()
  }

  const FormBody = () => (
    <>
      <CommonModal.Body>
        <p>
          この電子申請の取下げを行います。
          <br />
          <span className={styles.confirm}>取下げが完了するまで電子申請の削除はできなくなります。</span>
        </p>
        <Spacer size={8} />
        <form>
          {signRequired && (
            <>
              <Label>PINコード</Label>
              <Spacer size={4} />
              <Input type="password" {...register('pin')} isError={!!errors.pin?.message} />
              {errors.pin?.message && <Error error={errors.pin.message} />}
              <Spacer size={8} />
            </>
          )}
          <Label>取下げ理由</Label>
          <Spacer size={4} />
          <Textarea {...register('withdrawReason')} isError={!!errors.withdrawReason?.message} />
          {errors.withdrawReason?.message && <Error error={errors.withdrawReason.message} />}
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <CommonModal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={handleSubmit(handleWithdraw)} disabled={isSubmitting}>
            取下げ
          </Button>
        </CommonModal.Buttons>
      </CommonModal.Footer>
    </>
  )

  return (
    <CommonModal isOpen={isOpen}>
      <CommonModal.Header hideModal={closeModal}>申請取下げ</CommonModal.Header>
      {formatError ? <ErrorBody formatError={formatError} closeModal={closeModal} /> : <FormBody />}
    </CommonModal>
  )
}
