import React from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {gql} from '@apollo/client'
import {useQuery} from 'components/Graphql'
import {Tab} from 'components/Tabs'
import styles from './Tabs.scss'

const IS_SENT_DOCUMENT_CONTRACT = gql`
  query isSentDocumentContract($id: ID!) {
    client {
      id
      document(id: $id) {
        id
        contractSent
      }
    }
  }
`

const LinkTab = ({children, current, to, disabled, ...rest}) =>
  disabled ? (
    <Tab disabled>{children}</Tab>
  ) : current === to ? (
    <Tab active>{children}</Tab>
  ) : (
    <Tab as={Link} to={to} {...rest}>
      {children}
    </Tab>
  )

export const Tabs = ({current, id}) => {
  const planType = useSelector((state) => state.client.current.plan_type)
  const {data} = useQuery(IS_SENT_DOCUMENT_CONTRACT, {
    fetchPolicy: 'cache-and-network',
    variables: {id},
  })
  const isSentContract = data?.client?.document?.contractSent

  return (
    <div className={classNames(styles.tabs, {[styles.withContract]: planType === 'paid_plan'})}>
      <LinkTab current={current} to={`/documents/${id}`}>
        書類プレビュー
      </LinkTab>
      <LinkTab current={current} to={`/documents/${id}/employees`}>
        従業員一覧
      </LinkTab>
      {planType === 'paid_plan' && (
        <LinkTab current={current} to={`/documents/${id}/contracts`} disabled={!isSentContract}>
          電子契約一覧
        </LinkTab>
      )}
    </div>
  )
}
