import React from 'react'
import {Link} from 'react-router-dom'
import {HEALTH_AND_WELFARE_PENSION} from 'procedureStatuses/reportList/common'
import ListGroup from 'jbc-front/components/ListGroup'

export const getReportList = function* ({healthInsuranceType, healthInsuranceJoined, welfarePensionInsuranceJoined}) {
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') {
      yield {
        num: 'r501',
        title: '健康保険・厚生年金保険産前産後休業取得者申出書',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    } else if (healthInsuranceType === 'its') {
      yield {
        num: 'r502',
        title: '健康保険・厚生年金保険産前産後休業取得者申出書',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
  }
}

const ProcedureInfo = ({procedureStatus}) => (
  <ListGroup>
    <ListGroup.Title>手続き情報</ListGroup.Title>
    <ListGroup.Item as={Link} to={`/${procedureStatus.procedure_type}/update/${procedureStatus.id}`}>
      手続き情報編集
    </ListGroup.Item>
  </ListGroup>
)

export default {getReportList, ProcedureInfo}
