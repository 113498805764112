import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Index} from 'pages/withAuth/adminPage/settings/index'
import {RouteParams} from 'types/routes'
import {egovRoutes} from './egov'
import {groupRoutes} from './groups'

export const SettingRoutes: RouteParams[] = [
  {
    key: 'settings',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.INDEX,
    component: Index,
    exact: true,
    whiteList: ['full_admin', 'office_admin'],
  },
  ...egovRoutes,
  ...groupRoutes,
]
