import React from 'react'
import {
  reduxForm,
  autofill as autofillForm,
  formValueSelector,
  isSubmitting as isSubmittingForm,
  submit as submitForm,
} from 'redux-form'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {Section, TextField, FileField} from 'jbc-front/components/Form'
import {PlusSquare} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {toFormValues} from 'utils'
import * as validators from 'validators'
import _ from 'lodash'

const formName = 'myNumberForm'
const autofill = autofillForm.bind(null, formName)
const selector = formValueSelector(formName)

export const submit = submitForm.bind(null, formName)
export const isSubmitting = isSubmittingForm(formName)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({hasSecondIdImage, autofill, isBusinessPartner}) => {
  const lengthValidators = isBusinessPartner
    ? [validators.minLength(12), validators.maxLength(13)]
    : [validators.exactLengthNumber(12)]
  return (
    <form>
      <FormErrors />
      <div>
        <Section title="マイナンバー">
          <TextField
            name="my_number"
            label="マイナンバー"
            required
            validate={[validators.number, ...lengthValidators]}
          />
        </Section>
        <Section title="番号確認書類">
          <FileField name="my_number_image" label="番号確認書類" />
        </Section>
        <Section title="身元確認書類">
          <FileField name="id_image_0" label="身元確認書類" />
          {!hasSecondIdImage && (
            <div className="u-ta-r u-mt20 u-mb20">
              <div onClick={() => autofill('_has_second_id_image', true)} className="u-txt-addinfo">
                <PlusSquare size={20} className="u-txt-addinfo-icon" />
                身元確認書類を追加
              </div>
            </div>
          )}
          {hasSecondIdImage && (
            <div>
              <FileField name="id_image_1" label="身元確認書類" />
            </div>
          )}
        </Section>
      </div>
    </form>
  )
})

export const makeInitialValues = (myNumber) => {
  return {
    _has_second_id_image: !!_.get(myNumber, 'id_image_1.url'),
    ...toFormValues(myNumber),
  }
}

export default connect(
  (state) => ({
    hasSecondIdImage: selector(state, '_has_second_id_image'),
  }),
  bindActionCreators.bind(null, {autofill})
)(Form)
