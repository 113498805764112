import {FC} from 'react'
import {Document} from '../query'
import {PreviewModal} from './PreviewModal'
import {DownloadButton} from 'components/ui/DownloadButton'
import {useDownloadDocument} from 'hooks/api/employees/documents/useDownloadDocument'

interface PreviewColumnProps {
  document: Document
}

export const PreviewColumn: FC<PreviewColumnProps> = ({document}) => {
  const download = useDownloadDocument()

  const handleDownload = async () => {
    download(document.id)
  }

  switch (document.type) {
    case 'dynamic_document':
      return <PreviewModal document={document} onDownload={handleDownload} />
    case 'uploaded_document':
      if (document.uploadedDocument.mimeType === 'application/pdf') {
        return <PreviewModal document={document} onDownload={handleDownload} />
      } else {
        return <DownloadButton onClick={handleDownload} />
      }
    default:
      return null
  }
}
