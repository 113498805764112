import {CustomEmployeeFieldGroup} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldGroup'
import {CustomEmployeeField} from 'types/api/settings/employeeFieldGroups/customEmployeeField'
import {CustomEmployeeFieldOption} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldOption'
import {CustomEmployeeFieldPermission} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldPermission'
import {useAuthAxios} from 'hooks/api/useAuthAxios'

type Data = {
  custom_employee_field_group: CustomEmployeeFieldGroup
  custom_employee_fields: {
    custom_employee_field: Omit<
      CustomEmployeeField,
      'custom_employee_field_options' | 'custom_employee_field_permissions'
    >
    custom_employee_field_options: CustomEmployeeFieldOption[]
    custom_employee_field_permissions: CustomEmployeeFieldPermission[]
  }[]
}

export const usePostBulkedCustomEmployeeFields = () => {
  const authAxios = useAuthAxios()
  return async (data: Data) => authAxios.post('/api/bulked_custom_employee_fields', data)
}
