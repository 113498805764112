import {FC} from 'react'
import {Link} from 'react-router-dom'

interface ResultProps {
  isValid?: boolean
  errors?: Error
}

export const Result: FC<ResultProps> = ({isValid, errors}) => {
  if (errors) {
    return <p>エラーが発生しました。もう一度お試しください。</p>
  }

  if (isValid === false) {
    return (
      <p>
        このURLは登録が完了しているか、有効期限が過ぎています。
        <br />
        <Link to="/login" className="u-txt-link">
          ログインはこちらから
        </Link>
      </p>
    )
  }

  return (
    <>
      <div className="u-mb20">メールアドレス変更が完了しました!</div>
      <Link to="/dashboard" className="u-txt-link">
        ダッシュボードへ移動する
      </Link>
    </>
  )
}
