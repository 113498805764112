import React, {useEffect} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux'
import {Field, autofill, reduxForm, getFormValues, reset, submit} from 'redux-form'
import _ from 'lodash'
import {getState} from 'utils'
import {actionCreators} from 'actions'
import AdditionalSearchFields from 'employees/list/AdditionalSearchFields'
import SortingFieldsWithResult from 'documents/create/list/SortingFieldsWithResult'
import styles from 'employees/list/SearchForm.scss'
import {MainFormControls, SearchEmploymentStatusField} from 'jbc-front/components/SearchForm'

import {
  formName,
  convertQueryToForm,
  getCurrentQueryFromLocation,
  fetchEmployees,
  fetchEmployeesAll,
  fetchEmployeesLimitExceeded,
  convertFormToQueryString,
  fetchSelections,
  getSavedDisplayEmployeeLimit,
  saveDisplayEmployeeLimit,
  hasConditions,
} from 'documents/create/list/utils'
import compose from 'lodash/fp/compose'
import moment from 'moment'

export const employmentStatuses = [
  {id: 'all', name: 'すべて'},
  {id: 'employed', name: '在職中'},
  {id: 'vacation', name: '休職中'},
  {id: 'unemployed', name: '退職済'},
  {id: 'join', name: '内定'},
]

class SearchFormWithCardList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }

  componentDidMount() {
    const {token, dispatch} = this.props
    fetchSelections(token, dispatch)
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('selections'))
  }

  clearForm = () => {
    const {dispatch} = this.props
    getState(dispatch).then(() => {
      dispatch(reset(formName))
      dispatch(submit(formName))
    })
  }

  render() {
    const {user, pathname = '', formValues, handleSubmit} = this.props
    return (
      <div className={styles.searchForm}>
        <div className={styles.mainSearchFields}>
          <form id={'emplopyee'}>
            <Field
              name="employment_status"
              component={SearchEmploymentStatusField}
              employmentStatuses={employmentStatuses}
              onEmploymentStatusChange={handleSubmit}
            />
          </form>
          <MainFormControls
            isExpand={this.state.isExpand}
            hasConditions={hasConditions(formValues)}
            handleClear={this.clearForm}
            onToggleClick={() => {
              this.setState({isExpand: !this.state.isExpand})
            }}
          />
        </div>
        <form id={'emplopyee'}>
          {this.state.isExpand && <AdditionalSearchFields showJoinedStatusField={false} showAddedField={false} />}
          <SortingFieldsWithResult
            limit={getSavedDisplayEmployeeLimit(pathname, user)}
            onChangeLimit={(newLimit) => {
              saveDisplayEmployeeLimit(pathname, user, newLimit)
              handleSubmit()
            }}
            onChangeSortType={handleSubmit}
          />
        </form>
      </div>
    )
  }
}

const validate = (values) => {
  const errors = {}
  if (values.age_from && values.age_to && +values.age_from > +values.age_to) {
    errors.age_to = errors.age_from = 'の範囲が正しくありません'
  }
  if (
    [values['joined_at_start'], values['joined_at_end']].every(
      (date) => date && moment(date, 'YYYY/MM/DD').isValid()
    ) &&
    moment(values['joined_at_start'], 'YYYY/MM/DD').diff(moment(values['joined_at_end'], 'YYYY/MM/DD'), 'days') > 0
  ) {
    errors['joined_at_start'] = errors['joined_at_end'] = 'の範囲が正しくありません'
  }
  return errors
}

// URL使わないsearchDetector
const localSearchDetector =
  (SearchForm) =>
  ({additionalParams, ...rest}) => {
    const dispatch = useDispatch()
    const location = useSelector((state) => state.router.location)
    const user = useSelector((state) => state.session.currentUser)

    const searchByLocation = (location) => {
      const query = getCurrentQueryFromLocation(location, user) |> _.identity
      dispatch(fetchEmployees(query, additionalParams))
      dispatch(fetchEmployeesAll(query, additionalParams))
      dispatch(fetchEmployeesLimitExceeded(query, additionalParams))
    }

    useEffect(() => {
      searchByLocation(location)
      return () => {
        dispatch(actionCreators.employees.list.destroy())
        dispatch(actionCreators.employees.all.destroy())
        dispatch(actionCreators.employees.documentLimitExceeded.destroy())
      }
    }, [])

    return (
      <SearchForm
        initialValues={convertQueryToForm(getCurrentQueryFromLocation(location, user))}
        {...rest}
        handleSearch={(values) => searchByLocation({...location, search: convertFormToQueryString(values)})}
      />
    )
  }

export default compose(
  localSearchDetector,
  connect((state) => ({
    token: state.auth.token,
    pathname: state.router.location.pathname,
    formValues: getFormValues(formName)(state),
    user: state.session.currentUser,
  })),
  reduxForm({
    form: formName,
    validate,
    onSubmit(values, dispatch, {handleSearch}) {
      getState(dispatch).then((state) => {
        const page = _.get(getFormValues(formName)(state), 'page')
        const beforePage = _.get(getFormValues(formName)(state), 'before_page')
        if (page === beforePage) {
          dispatch(autofill(formName, 'page', 1))
        }
        dispatch(autofill(formName, 'before_page', page))
        getState(dispatch).then((state) => {
          handleSearch(getFormValues(formName)(state))
        })
      })
    },
  })
)(SearchFormWithCardList)
