import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router'
import api from 'api'
import {withFormSelectors, FormSelectorsProvider} from 'employees/form/common'
import {push} from 'connected-react-router'
import {actionCreators, fetchSelector} from 'actions'
import {loadEmployee} from 'accountSettings/Profile'
import Button from 'jbc-front/components/Button'
import {updateEmployeeDetail} from 'employees/Update'
import ProfileForm, {formName} from 'accountSettings/ProfileForm'
import {makeFormData} from 'InitialInput'
import _ from 'lodash'
import {CommentModal} from 'components/modals/CommentModal'
import {FieldGroupProvider} from 'employees/FieldWithGroup'
import {getFormValues} from 'redux-form'
import {getState} from 'utils'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class ProfileEditForEmployee extends Component {
  static defaultProps = {
    employeeFieldGroups: [],
  }

  componentDidMount() {
    const {
      token,
      client,
      loadData,
      loadFieldGroup,
      currentUser: {employee},
      history,
    } = this.props
    if (!employee) {
      history.replace('/account_settings/account')
    } else {
      loadData(employee.id, token)
      loadFieldGroup(token, client)
    }
  }

  handleSubmit = async (comment) => {
    const {fields, token, createRequest, getFormValues} = this.props
    const values = await getFormValues(formName)
    await createRequest(values, token, fields, comment)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      employee,
      submit,
      submitting,
      pristine,
      currentUser: {id},
      fieldGroups,
      employeeFieldGroups,
    } = this.props
    const redirectUrl = _.get(employee, 'detail_input_request.employee_input_url')
    if (redirectUrl) {
      return <Redirect to={redirectUrl} />
    }

    return (
      id && (
        <FieldGroupProvider type="UpdateProfile" permissionGroups={employeeFieldGroups}>
          <CommentModal modalTitle="その他申請" onSubmit={(formData) => this.handleSubmit(formData.comment)}>
            {(showModal, _isOpen) => (
              <ProfileForm
                data={employee}
                fieldGroups={fieldGroups}
                employeeFieldGroups={employeeFieldGroups}
                onSubmit={showModal}
                submitText="更新を依頼する"
                naviSubmit={
                  <Button primary disabled={submitting || pristine} onClick={submit} widthWide>
                    更新を依頼する
                  </Button>
                }
                requestStatus={_.get(employee, 'detail_input_request.status')}
              />
            )}
          </CommentModal>
        </FieldGroupProvider>
      )
    )
  }
}

const mapStateToProps = (state, {isSubmitting, isPristine}) => ({
  employee: state.employees.current.data,
  submitting: isSubmitting(state),
  pristine: isPristine(state),
  currentUser: state.session.currentUser,
  fields: fetchSelector(state, 'custom_fields').data,
  token: state.auth.token,
  fieldGroups: fetchSelector(state, 'custom_field_groups').data,
  employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
  client: state.client.current,
})

const mapDispatchToProps = (dispatch, {submit}) => ({
  loadData(id, token) {
    dispatch(actionCreators.employees.current.fetchData(loadEmployee(id, token)))
  },
  loadFieldGroup(token, client) {
    dispatch(actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list()))
    dispatch(
      actionCreators.fetchData(
        'custom_field_groups',
        api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
      )
    )
    dispatch(
      actionCreators.fetchData(
        'custom_fields',
        api.createWithAuth(token).employeeCustom.fields.list(client.id, {
          embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
        })
      )
    )
  },
  destroy() {
    dispatch(actionCreators.employees.current.destroy())
    dispatch(actionCreators.procedureStatuses.tmpData.destroy())
    dispatch(actionCreators.fetchDestroy('employee_field_groups'))
    dispatch(actionCreators.fetchDestroy('custom_field_groups'))
    dispatch(actionCreators.fetchDestroy('custom_fields'))
  },
  async createRequest(values, token, fields, comment) {
    const formData = makeFormData(values, fields, {type: 'profile_updated', comment})
    const employeeId = values.id
    try {
      await updateEmployeeDetail(employeeId, {resumes: values.resumes}, token, undefined, fields)
      await api.createWithAuth(token).detailInputData.create(formData, {
        headers: {'content-type': 'multipart/form-data'},
      })
      dispatch(push('/dashboard'))
      dispatch(notifySuccess('送信しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
  submit: () => {
    dispatch(submit)
  },
  async getFormValues(formName) {
    const state = await getState(dispatch)
    const getValues = getFormValues(formName)
    return getValues(state)
  },
})

const withFormSelectorsProvider = (WrappedComponent) => (props) =>
  (
    <FormSelectorsProvider formName={formName}>
      <WrappedComponent {...props} />
    </FormSelectorsProvider>
  )

export default ProfileEditForEmployee
  |> connect(mapStateToProps, mapDispatchToProps)
  |> withFormSelectors
  |> withFormSelectorsProvider
