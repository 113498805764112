import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field} from 'redux-form'
import styles from 'jbc-front/components/SearchForm.scss'
import {fetchSelector, actionCreators} from 'actions'
import api from 'api'
import Select from 'react-select'
import {idToStr, withNullOpt, toOpts} from 'employees/list/addedFields/utils'

const PrefectureSelect = ({input: {onChange, onBlur, value, name}, ...rest}) => (
  <Select
    multi
    closeOnSelect={false}
    clearableValue={false}
    simpleValue
    onChange={(value) => {
      onChange(value)
      onBlur(value)
    }}
    value={value}
    name={name}
    {...rest}
  />
)

class Prefectures extends Component {
  static defaultProps = {
    prefectures: [],
  }

  componentDidMount() {
    const {dispatch, token} = this.props
    const authedAPi = api.createWithAuth(token)
    dispatch(
      actionCreators.fetchData(
        'prefecture_selections',
        authedAPi.employees.selections.prefectures().then((res) => ({
          countries: res.data.map(idToStr),
        }))
      )
    )
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('prefecture_selections'))
  }
  render() {
    const {prefectures} = this.props
    return (
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>都道府県</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <div className={styles.groupsSelectBox}>
              <Field
                name="prefecture_ids"
                component={PrefectureSelect}
                format={(value) => (value ? value.join(',') : '')}
                parse={(value) => (value ? value.split(',') : [])}
                placeholder="都道府県選択"
                options={withNullOpt(toOpts(prefectures))}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default connect((state) => ({
  token: state.auth.token,
  prefectures: fetchSelector(state, 'prefecture_selections').countries,
}))(Prefectures)
