import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Paginator from 'jbc-front/components/Paginator'

export const Pager = ({query, setQuery, ...rest}) => {
  const handleClick = (page) => setQuery({...query, page})

  return <Paginator onClick={handleClick} current={query.page} rowsPerPage={query.per_page} {...rest} />
}

export const connectPager =
  (selector, actionCreator) =>
  ({...rest}) => {
    const query = useSelector((state) => selector(state))
    const dispatch = useDispatch()
    const setQuery = (newQuery) => dispatch(actionCreator(newQuery))

    return <Pager query={query} setQuery={setQuery} {...rest} />
  }
