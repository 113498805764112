import React from 'react'
import {Link} from 'react-router-dom'
import _ from 'lodash'

const EmployeeName = ({employee}) =>
  _.isEmpty(employee) ? null : (
    <div className="m-title-main-name">
      <Link to={`/employees/${employee.id}`} target="_blank">
        <span>スタッフコード：{employee.staff_code}</span>
        <span className="u-pl20">従業員名：{employee.display_full_name}</span>
      </Link>
    </div>
  )

export default EmployeeName
