import React, {Component} from 'react'
import {connect} from 'react-redux'
import Form from 'settings/laborConsultant/Form'
import {push} from 'connected-react-router'
import {actionCreators} from 'actions'
import api from 'api'
import {toFormValues} from 'utils'
import {Link} from 'react-router-dom'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Update extends Component {
  handleFormSubmit = (values) => {
    const {
      client: {id},
      handleFormSubmit,
      token,
    } = this.props
    return handleFormSubmit(token, id, values)
  }

  componentDidMount() {
    const {client, token, loadLaborConsultant} = this.props
    loadLaborConsultant(client.id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const initialValues = toFormValues(this.props.laborConsultant)
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">社労士情報</h1>
          <p className="m-title-main-note">社会保険労務士の情報を設定できます</p>
        </div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <Link to="/settings/labor_consultant" className="l-breadcrumb-link">
              社労士情報
            </Link>
            <span className="l-breadcrumb-here">編集</span>
          </div>
          <Form initialValues={initialValues} onSubmit={this.handleFormSubmit} />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    client: state.client.current,
    laborConsultant: state.settings.laborConsultant.data,
  }),
  (dispatch) => ({
    loadLaborConsultant(clientId, token) {
      dispatch(
        actionCreators.settings.laborConsultant.fetchData(
          api.createWithAuth(token).clients.laborConsultant.get(clientId)
        )
      )
    },
    destroy() {
      dispatch(actionCreators.settings.laborConsultant.destroy())
    },
    async handleFormSubmit(token, clientId, values) {
      try {
        await api.createWithAuth(token).clients.laborConsultant.createOrUpdate(clientId, values)
        dispatch(push('/settings/labor_consultant'))
        dispatch(notifySuccess('更新しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Update)
