import React from 'react'
import styles from 'myNumber/MyNumberModal.scss'
import {reduxForm, FormSection, submit as submitForm, Field, formValueSelector} from 'redux-form'
import FormErrors, {LabelMapper} from 'jbc-front/components/FormErrors'
import {Required, Section, CheckboxField, RadioField, Error} from 'jbc-front/components/Form'
import {recordDisplay, toBooleanProps} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Query} from 'components/Graphql'
import {gql} from '@apollo/client'

const PURPOSE_OF_USE_MASTER = gql`
  query purposeOfUseMaster($type: PurposeType) {
    purposeOfUseMasters(type: $type) {
      id
      name
    }
  }
`

const PURPOSE_OF_USE_MASTER_FOR_EMPLOYEE_AND_DEPENDENTS_VARIABLES = {
  type: 'for_employee_and_dependent',
}

const formName = 'myNumberRequest'

const requestOptions = [
  {value: 'true', label: '依頼する'},
  {value: 'false', label: '依頼しない'},
]

const validatePurposeOfUses = (purposeOfUse) => {
  return _.isEmpty(purposeOfUse) ? 'を一つ以上選択してください' : undefined
}

export const PurposeOfUse = ({name, sendRequest, formName}) => (
  <div>
    {formName && <CheckboxField name={`${name}._send_request`} label="マイナンバーの入力を依頼する" />}
    {(!formName || sendRequest) && (
      <FormSection name={name}>
        <Section title="確認書類の提出">
          <RadioField
            name="request_number_confirmation"
            label="番号確認書類"
            {...toBooleanProps}
            option={requestOptions}
          />
          <RadioField
            name="request_identity_confirmation"
            label="身元確認書類"
            {...toBooleanProps}
            option={requestOptions}
          />
        </Section>
        <Section
          title={
            <p>
              マイナンバーの利用目的
              <Required />
            </p>
          }
        >
          <Query query={PURPOSE_OF_USE_MASTER} variables={PURPOSE_OF_USE_MASTER_FOR_EMPLOYEE_AND_DEPENDENTS_VARIABLES}>
            {({data: {purposeOfUseMasters}}) => (
              <Field
                component={PurposeOfUseField}
                name="purpose_of_use_ids"
                validate={validatePurposeOfUses}
                purposeOfUseMasters={purposeOfUseMasters}
              />
            )}
          </Query>
        </Section>
      </FormSection>
    )}
  </div>
)

class PurposeOfUseField extends React.Component {
  constructor(props) {
    super(props)
    this.Form = reduxForm({
      form: _.uniqueId('purpose_of_use_form'),
      onSubmit: (values, dispatch, {input: {onChange}, purposeOfUseMasters}) => {
        onChange(purposeOfUseMasters.filter(({id}) => values[`purpose_of_use_${id}`]).map(({id}) => id))
      },
    })(PurposeOfUseSubForm)
  }

  render() {
    const {Form} = this
    return <Form {...this.props} />
  }
}

const PurposeOfUseSubForm = ({handleSubmit, purposeOfUseMasters, meta}) => (
  <>
    {purposeOfUseMasters.map(({id, name}) => (
      <CheckboxField
        name={`purpose_of_use_${id}`}
        label={name}
        key={id}
        onChange={() => {
          setTimeout(handleSubmit, 0)
        }}
      />
    ))}
    <Error meta={meta} label="利用目的" />
  </>
)

const PurposeOfUseContainer = connect((state, {name, formName}) => {
  const selector = formValueSelector(formName)
  return {
    sendRequest: selector(state, `${name}._send_request`),
  }
})(PurposeOfUse)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch) => {
    console.log(errors)
    if (errors && errors.my_number_purpose_of_uses) {
      dispatch({
        type: 'FORM_ERRORS/SET_ERRORS',
        payload: errors.my_number_purpose_of_uses
          .map((err, index) =>
            index === 0
              ? {route: [{key: '_purpose_of_uses'}, {key: 'employee'}], value: err?.purpose_of_use_ids}
              : {
                  route: [{key: '_purpose_of_uses'}, {key: `dependent_${index - 1}`}],
                  value: err?.purpose_of_use_ids,
                }
          )
          .filter(({value}) => _.isString(value)),
      })
    }
  },
})(({handleSubmit, employee}) => (
  <div className={styles.body}>
    <form onSubmit={handleSubmit}>
      <FormErrors />
      <Section title="本人の情報">
        <LabelMapper name="employee" label="本人の情報の" />
        <LabelMapper name="_purpose_of_uses" label="利用目的" />
        <PurposeOfUseContainer name="my_number_purpose_of_uses[0]" formName={formName} />
      </Section>
      {employee.employee_dependents &&
        employee.employee_dependents.map((dependent, index) => (
          <Section title={`${recordDisplay.fullName(dependent)}さんの情報`} key={index}>
            <LabelMapper name={`dependent_${index}`} label={`${recordDisplay.fullName(dependent)}さんの情報の`} />
            <PurposeOfUseContainer name={`my_number_purpose_of_uses[${index + 1}]`} formName={formName} />
          </Section>
        ))}
    </form>
  </div>
))

export const makeInitialValues = ({employee_dependents: dependents = [], id}) => ({
  employee_id: id,
  my_number_purpose_of_uses: [
    {target_type: 'Employee', target_id: id, _send_request: true},
    ...dependents.map((dependent) => ({
      target_type: 'EmployeeDependent',
      target_id: dependent.id,
      _send_request: true,
    })),
  ],
})

export const submit = submitForm.bind(null, formName)

export default Form
