import {FC, ReactNode, useEffect, useState} from 'react'
import {useFetchCheckNeedEmployeeUpdate} from 'hooks/api/employees/checkNeedEmployeeUpdate'
import {push} from 'connected-react-router'
import {useDispatch} from 'react-redux'
import {handleError} from 'libs/axiosErrorHandlers'

interface Props {
  children: ReactNode
  employeeId: string
  redirectPath: string
}

export const RequireEmployeeUpdateRouter: FC<Props> = ({children, employeeId, redirectPath}) => {
  const dispatch = useDispatch()
  const {isLoading, resource: checkNeedEmployeeUpdate, errors} = useFetchCheckNeedEmployeeUpdate(employeeId)
  const [isNotRequireUpdate, setIsNotRequireUpdate] = useState<boolean>(false)

  useEffect(() => {
    const getCheckNeedEmployeeUpdate = async () => {
      if (!isLoading) {
        if (errors) {
          await handleError(errors, dispatch)
          return
        }

        if (checkNeedEmployeeUpdate?.need) {
          dispatch(push(redirectPath))
        } else {
          setIsNotRequireUpdate(true)
        }
      }
    }

    getCheckNeedEmployeeUpdate()
  }, [isLoading])

  return <>{!isLoading && isNotRequireUpdate && <>{children}</>}</>
}
