import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Dl, Dd, Dt, Grid, SubPanel} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import {graduationTypes} from 'FormFields'
import _ from 'lodash'
import styles from 'employees/show/Parts.scss'

const EmployeeEducationBackground = ({employee, editLink, yearStyle, canEdit, schoolTypes = []}) => {
  const employeeEducationBackgrounds = employee.employee_education_backgrounds || []
  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeEducationBackground`} title="学歴" canEdit={canEdit} />
      <Item>
        <Grid className={employeeEducationBackgrounds.length == 1 && styles.gridFill}>
          {employeeEducationBackgrounds.map((employeeEducationBackground, index) => (
            <SubPanel key={index}>
              <SubPanel.Headding>学歴 ({index + 1})</SubPanel.Headding>
              <Grid className={styles.lastChildrenNotBorder}>
                <Dl>
                  <Dt>学校区分</Dt>
                  <Dd>
                    {recordDisplay.option(
                      _.get(employeeEducationBackground, 'school_type_id'),
                      schoolTypes.map((t) => ({value: t.id, label: t.name}))
                    )}
                  </Dd>
                </Dl>
                <Dl>
                  <Dt>学校名</Dt>
                  <Dd>{recordDisplay(employeeEducationBackground.school_name)}</Dd>
                </Dl>
                <Dl>
                  <Dt>学部学科専攻</Dt>
                  <Dd>{recordDisplay(employeeEducationBackground.department)}</Dd>
                </Dl>
                <Dl>
                  <Dt>入学年月日</Dt>
                  <Dd>{recordDisplay.date(employeeEducationBackground.entrance_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>卒業年月日</Dt>
                  <Dd>{recordDisplay.date(employeeEducationBackground.graduation_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>卒業区分</Dt>
                  <Dd>{recordDisplay.option(employeeEducationBackground.graduation_type, graduationTypes)}</Dd>
                </Dl>
              </Grid>
            </SubPanel>
          ))}
        </Grid>
        {employeeEducationBackgrounds.length < 1 && (
          <div className={styles.noData}>
            <p>学歴の登録はありません</p>
          </div>
        )}
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  schoolTypes: state.master.schoolTypes,
  employee: state.employees.current.data,
}))(EmployeeEducationBackground)
