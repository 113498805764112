import React, {Component} from 'react'
import Form from 'procedures/resign/EmployeeForm'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {toFormValues} from 'utils'
import _ from 'lodash'
import Loading from 'components/Loading'
import {logError} from 'libs/telemetry'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class EmployeeInput extends Component {
  componentDidMount() {
    const {
      loadProcedureStatus,
      token,
      match: {
        params: {id},
      },
    } = this.props
    loadProcedureStatus(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      procedureStatus: {resign_employee_input_datum: inputData, link, id},
      procedureStatus,
      updateAndCreateProcedure,
      token,
    } = this.props
    if (!link) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <div className="m-title-sub u-pt0 u-ta-c">{id ? 'すでに入力済です' : <Loading />}</div>
          </div>
        </div>
      )
    }
    return (
      <Form
        onSubmit={(values) => updateAndCreateProcedure(procedureStatus, values, token)}
        initialValues={inputData ? toFormValues(inputData) : {}}
      />
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    procedureStatus: state.procedureStatuses.current.data,
  }),
  (dispatch) => ({
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api
            .createWithAuth(token)
            .procedureStatuses.get(id, ['resign_employee_input_datum'])
            .then((res) => {
              if (res.data?.link && res.data?.resign_employee_input_datum) {
                logError(new Error('Malformed procedure status: resign_employee_input_datum is not null'), {
                  procedure_status_id: id,
                  resign_employee_input_datum_id: res.data.resign_employee_input_datum.id,
                })
              }
              return res
            })
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.procedureStatuses.current.destroy())
    },
    updateAndCreateProcedure: async (procedureStatus, values, token) => {
      try {
        const inputDataId = _.get(procedureStatus, 'resign_employee_input_datum.id')
        const procedureStatusId = procedureStatus.id
        const authedApi = api.createWithAuth(token)
        if (inputDataId) {
          await authedApi.procedureStatuses.resignEmployeeInputData.update(procedureStatusId, values)
        } else {
          await authedApi.procedureStatuses.resignEmployeeInputData.create(procedureStatusId, values)
        }
        await authedApi.procedureStatuses.apply(procedureStatusId)
        dispatch(notifySuccess('送信しました'))
        dispatch(push('/dashboard'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(EmployeeInput)
