import React from 'react'
import map from 'lodash/fp/map'
import {Link} from 'react-router-dom'
import ListGroup from 'jbc-front/components/ListGroup'
import {clientRoles as baseClientRoles} from 'settings/clientRoles/ChangeMulti'

const makeDescription = (clientRoleId) => {
  switch (clientRoleId) {
    case '1': {
      return '全ての権限を持つ管理者です。'
    }
    case '2': {
      return '社員のデフォルト権限です。'
    }
    case '3': {
      return '適用事業所の管理権限を持つ管理者です。'
    }
    case '4': {
      return '設定、マイナンバーの操作権限のない権限です。'
    }
  }
}

const clientRolesWithDesc =
  baseClientRoles |> map(({value, label}) => ({id: value, label, description: makeDescription(value)}))

const ListItem = ({id, label, description}) => (
  <ListGroup.Item as={Link} to={`/settings/client_roles/edit/${id}`}>
    <div className="u-pt5 u-pb5">
      <p className="u-mb10">{label}</p>
      <p>{description}</p>
    </div>
  </ListGroup.Item>
)

const List = () => {
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">権限一覧</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/client_roles" className="l-breadcrumb-link">
            権限管理設定
          </Link>
          <span className="l-breadcrumb-here">権限一覧</span>
        </div>
        <ListGroup>
          <ListGroup.Title>権限名</ListGroup.Title>
          {clientRolesWithDesc.map((clientRole) => (
            <ListItem key={clientRole.id} {...clientRole} />
          ))}
        </ListGroup>
      </div>
    </div>
  )
}

export default List
