import {FC, useState} from 'react'
import {Presenter} from './Presenter'
import {useQuery} from 'hooks/graphql'
import {EGOV_PROCEDURES, QueryVariables} from './query'
import {EgovProcedureStatuses} from 'consts/egovProcedureStatuses'

export type Refetch = (variables: {
  statuses?: EgovProcedureStatuses[]
  keyword?: string | undefined
  page?: number
  limit?: number
}) => Promise<void>

export const Index: FC = () => {
  const DEFAULT_LIMIT = 100
  const [variables, setVariables] = useState<QueryVariables>({
    statuses: [],
    keyword: undefined,
    page: 1,
    limit: DEFAULT_LIMIT,
  })

  const {data, refetch} = useQuery<any, QueryVariables>(EGOV_PROCEDURES, {
    variables,
    fetchPolicy: 'network-only',
  })

  const handleRefetch: Refetch = async ({statuses, keyword, page, limit}) => {
    const newVariables = {...variables}
    if (statuses) {
      newVariables.statuses = statuses
    }

    if (keyword) {
      newVariables.keyword = keyword
    }

    if (page) {
      newVariables.page = page
    }

    if (limit) {
      newVariables.limit = limit
    }

    await refetch(newVariables)
    setVariables(newVariables)
  }

  return (
    <Presenter
      defaultLimit={DEFAULT_LIMIT}
      totalCount={data?.client?.egovProcedures.totalCount}
      egovProcedures={data?.client?.egovProcedures.list}
      egovCertificateFile={data?.client?.egovCertificateFile}
      egovAccount={data?.egovAccount}
      refetch={handleRefetch}
    />
  )
}
