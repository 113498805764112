import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {ApplicableOffice} from 'jbc-front/components/icons'
import {OfficeSelector, SelectedOffice} from 'features/offices/OfficeSelector'
import {isAdminSelector, isOfficeAdminSelector} from 'libs/redux'
import {useSelector} from 'hooks/redux'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'
import {setSelectedOffices} from 'store/slices/session'
import styles from './OfficeSelect.scss'
import {notifySuccess} from 'store/actions/notify'

const displayOfficeName = (offices: SelectedOffice[], isOfficeAdmin: boolean) => {
  const [first, ...rest] = offices
  if (!first) {
    return isOfficeAdmin ? '全事業所' : '全社'
  }
  if (rest.length) {
    return '複数の適用事業所を選択中'
  }
  return first.loading ? '\u00A0' : first.name
}

export const OfficeSelect: FC = () => {
  const dispatch = useDispatch()
  const hasBranchOffice = useHasBranchOffice({denyIfSelectOne: false})
  const isAdmin = useSelector(isAdminSelector)
  const isOfficeAdmin = useSelector(isOfficeAdminSelector)
  const currentOffices = useSelector((state) => state.session.currentUser?.current_offices || [])

  const selectedOffices = useSelector((state) => state.session.selectedOffices)
  const selectedOfficeIds = selectedOffices.length ? selectedOffices.split(',') : []

  const handleChangeOffices = (newIds: string[]) => {
    const serializedValue = newIds.join(',')
    if (serializedValue !== selectedOffices) {
      dispatch(setSelectedOffices(serializedValue))
      dispatch(notifySuccess('事業所を変更しました'))
    }
  }

  if (!isAdmin || !hasBranchOffice) {
    // ユーザーが従業員であるか、操作可能な適用事業所が本社のみの場合は表示しない
    return null
  }

  return (
    <div className={styles.applicableOffice}>
      <ApplicableOffice size={30} />
      {isOfficeAdmin && currentOffices.length === 1 ? (
        <div className={styles.officeName}>{currentOffices[0]?.name}</div>
      ) : (
        <div className={styles.officeSelectorWrap}>
          <OfficeSelector multiple selectedIds={selectedOfficeIds} onChange={handleChangeOffices}>
            {(selectedOffices) => (
              <div className={styles.officeNameInSelector}>{displayOfficeName(selectedOffices, isOfficeAdmin)}</div>
            )}
          </OfficeSelector>
        </div>
      )}
    </div>
  )
}
