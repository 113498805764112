import React from 'react'
import ChooseEmployee from 'procedures/ChooseEmployee'
import Detail from 'procedures/resign/Detail'
import Flow from 'procedures/resign/Flow'
import Request from 'procedures/resign/Request'
import EmployeeInput from 'procedures/resign/EmployeeInput'
import Confirm from 'procedures/resign/Confirm'

export default {
  ChooseEmployee: (props) => <ChooseEmployee {...props} nextAction="request" />,
  Detail,
  Flow,
  Request,
  EmployeeInput,
  Confirm,
}
