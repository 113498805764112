import _ from 'lodash'
import type {FileType} from 'libs/formatter'
import type {CustomEmployeeField} from 'types/api/settings/employeeFieldGroups/customEmployeeField'

const formNames = [
  'icon',
  'employee_tax_classification.handicap_certificate',
  'employee_tax_classification.student_certificate',
  'welfare_pension_insurance.image',
  'employment_insurance.image',
  'visa_history.residence_card',
  'employee_dependents_in_single.child_support_allowance_file_name',
] as const

const hasManyFormNames = {
  dependents: [
    'related_to_relatives_document',
    'related_to_remittance_document',
    'proving_study_abroad_document',
    'handicap_certificate',
  ],
  employee_qualifications: ['certificate'],
  employee_languages: ['certificate'],
} as const

const toDbName = (name: string) => (name.match(/^.+\..+/) ? name : `employee.${name}`)

type File = globalThis.File | FileType | null | undefined

export interface FormFile {
  // フォーム上のフィールド名
  formName: string
  // DB上の一時ファイル名
  dbName: string
  // 添付ファイル
  file: File
}

interface EachFormFilesOption {
  // 権限設定が更新可以外のカスタマイズ項目も列挙
  ignorePermission: boolean
}

export const eachFormFiles = function* (
  values: Record<string, unknown>,
  customFields: CustomEmployeeField[],
  options: Partial<EachFormFilesOption> = {}
): Generator<FormFile> {
  const opts: EachFormFilesOption = {ignorePermission: false, ...options}

  for (const formName of formNames) {
    yield {
      formName,
      dbName: toDbName(formName),
      file: _.get(values, formName) as File,
    }
  }

  for (const [prefix, names] of Object.entries(hasManyFormNames)) {
    const children = values[prefix] as Record<string, unknown>[]
    if (!children) {
      continue
    }

    for (let index = 0; index < children.length; index++) {
      const child = children[index]
      for (const suffix of names) {
        const formName = [prefix, index, suffix].join('.')
        const file = _.get(child, suffix) as File
        yield {
          formName,
          dbName: formName,
          file,
        }
      }
    }
  }

  let fileFields = customFields.filter((field) => field.field_type === 'file')
  if (!opts.ignorePermission) {
    fileFields = fileFields.filter((field) => field.custom_employee_field_permissions[0]?.permission === 'updatable')
  }
  for (const field of fileFields) {
    const formName = `custom_fields.field_${field.id}`
    const dbName = `employees_custom_values.file.${field.id}`
    const file = _.get(values, formName) as File
    yield {
      formName,
      dbName,
      file,
    }
  }
}

export const isUpdateOrDeleteFile = (f: FormFile): boolean => f.file instanceof File || !f.file
