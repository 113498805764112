import {gql} from '@apollo/client'
import {useQuery} from './graphql'
import {useSelector} from './redux'
import {isAdminSelector} from 'libs/redux'

export const HAS_BRANCH_OFFICE = gql`
  query hasBranchOffice {
    client {
      id
      hasBranchOffice
    }
  }
`

interface HasBranchOfficeResult {
  client: {
    hasBranchOffice: boolean
  }
}

export interface HasBranchOfficeOptions {
  // 1つだけ適用事業所が選択されている時、戻り値をfalseにする。例えば、複数の適用事業所が操作対象になっている時だけ何かを表示するために使う。
  denyIfSelectOne: boolean
}

const defaultOptions: HasBranchOfficeOptions = {
  denyIfSelectOne: true,
}

export const useHasBranchOffice = (options?: HasBranchOfficeOptions) => {
  const isAdmin = useSelector(isAdminSelector)
  const selectedOffices = useSelector((state) => state.session.selectedOffices)

  const opts = {...defaultOptions, ...options}
  const {data} = useQuery<HasBranchOfficeResult>(HAS_BRANCH_OFFICE, {skip: !isAdmin})

  // 管理者でない場合、自身の所属する適用事業所しか操作できないので常にfalse
  if (!isAdmin) {
    return false
  }

  if (opts.denyIfSelectOne) {
    const selectedOfficeIds = selectedOffices.split(',')
    const isSelectOneOffice = selectedOfficeIds.length === 1 && selectedOfficeIds[0]
    return !isSelectOneOffice && !!data?.client?.hasBranchOffice
  } else {
    return !!data?.client?.hasBranchOffice
  }
}
