import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import Button from 'jbc-front/components/Button'
import {isDevelopmentEnv} from 'utils'

class Home extends Component {
  render() {
    return (
      <div className="u-ta-c">
        {isDevelopmentEnv && (
          <div
            style={{
              padding: '120px',
              marginBottom: '80px',
              backgroundColor: '#3498db',
              color: '#fff',
              fontSize: '30px',
            }}
          >
            こちらはdev環境です
          </div>
        )}
        {process.env.NODE_ENV === 'staging' && (
          <div
            style={{
              padding: '120px',
              marginBottom: '80px',
              backgroundColor: '#17b491',
              color: '#fff',
              fontSize: '30px',
            }}
          >
            こちらはstaging環境です
          </div>
        )}
        <div style={{paddingBottom: '30px'}}>
          <Link to="/registration" style={{display: 'block', marginBottom: '30px'}}>
            <Button large primary widthAuto>
              アカウント登録
            </Button>
          </Link>
          <Link to="/login" style={{display: 'block'}}>
            <Button large primary widthAuto>
              ログイン
            </Button>
          </Link>
        </div>
      </div>
    )
  }
}

export default connect((state) => state)(Home)
