import React, {Component} from 'react'
import styles from 'employees/csvFormat/MultiList.scss'
import _ from 'lodash'
import {Glass, Pulldown, ArrowDoubleNext, ArrowDoublePrev, Close} from 'jbc-front/components/icons'
import set from 'lodash/fp/set'

const arrayMove = (arr, sub, reverse) => {
  const result = [...arr]
  if (reverse) {
    result.reverse()
  }
  let top = true
  for (let i = 0; i < result.length; i++) {
    if (sub.includes(result[i])) {
      if (!top) {
        const t = result[i - 1]
        result[i - 1] = result[i]
        result[i] = t
      }
    } else {
      top = false
    }
  }
  if (reverse) {
    result.reverse()
  }
  return result
}

const select = (ids, arr) => ids.map((id) => _.find(arr, ['_id', id]))

const Search = ({onChange, value}) => (
  <div className={styles.searchWrap}>
    <input
      className={styles.search}
      placeholder="キーワード入力"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type="text"
    />
    <span className={styles.searchIcon}>
      <Glass size={16} />
    </span>
    {value && (
      <span className={styles.searchClear} onClick={() => onChange('')}>
        <Close size={16} />
      </span>
    )}
  </div>
)

export const addResult = (field) => ({
  kind: 'normal',
  label_alias: field.label,
  target_type: field.target_type,
  target_id: field.id,
  _id: field._id,
  _label: field.label,
})

class MultiList extends Component {
  newSpace = (count) => ({
    kind: 'space',
    _label: '空白',
    _id: _.uniqueId(),
    label_alias: count === 0 ? '空白' : `空白${count}`,
  })

  constructor(props) {
    super(props)
    this.state = {
      searchLeft: '',
      searchRight: '',
      selectedLeft: [],
      selectedRight: [],
      leftDraging: null,
      rightDraging: null,
      edittingAlias: null,
    }
  }

  handleMouseUp = () => {
    const {leftDraging, rightDraging} = this.state
    if (leftDraging !== null || rightDraging !== null) {
      this.setState({leftDraging: null, rightDraging: null})
    }
  }

  componentDidMount() {
    window.addEventListener('mouseup', this.handleMouseUp)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  onChange = (value) => {
    const {onChange, onBlur} = this.props
    onChange(value)
    if (onBlur) {
      onBlur(value)
    }
  }

  render() {
    const {availableFields, value} = this.props
    const {searchLeft, searchRight, selectedLeft, selectedRight, leftDraging, rightDraging, edittingAlias} = this.state
    const {onChange} = this
    const result = value || []
    let rightItems = result
    if (searchRight) {
      rightItems = rightItems.filter(({label_alias, _label}) => {
        return label_alias.indexOf(searchRight) >= 0 || _label.indexOf(searchRight) >= 0
      })
    }
    const resultIds = _.map(result, '_id')
    const groups = _.uniq(_.map(availableFields, 'group'))
    let leftGrouped = _.groupBy(availableFields, 'group')
    if (searchLeft) {
      leftGrouped = _.mapValues(leftGrouped, (fields) =>
        fields.filter(({label, kind}) => (label + (kind === 'composition' ? ' *' : '')).indexOf(searchLeft) >= 0)
      )
    }
    return (
      <div className={styles.multiListContainer}>
        <div>
          <div className={styles.title}>選択対象項目</div>
          <div className={styles.multiList}>
            <Search value={searchLeft} onChange={(value) => this.setState({searchLeft: value})} />
            <div className={styles.listWithGroup}>
              {_.map(groups, (group) => {
                const fields = leftGrouped[group]
                return (
                  <React.Fragment key={group}>
                    <div
                      className={styles.listTitle}
                      onClick={() => {
                        const notTarget = ({_id, kind}) =>
                          kind === 'composition' || selectedLeft.includes(_id) || result.some(({_id: id}) => id === _id)
                        this.setState({
                          selectedLeft: fields.every(notTarget)
                            ? selectedLeft.filter((id) => !fields.some(({_id}) => _id === id))
                            : [
                                ...selectedLeft,
                                ..._.map(
                                  fields.filter((field) => !notTarget(field)),
                                  '_id'
                                ),
                              ],
                        })
                      }}
                    >
                      {group}
                    </div>
                    {fields.map((field) =>
                      resultIds.includes(field._id) ? (
                        <div className={styles.itemDisabled} key={field._id}>
                          {field.label}
                          {field.kind === 'composition' ? ' *' : ''}
                        </div>
                      ) : selectedLeft.includes(field._id) ? (
                        <div
                          className={styles.itemSelected}
                          key={field._id}
                          onMouseDown={(e) => {
                            if (e.button !== 0) {
                              return
                            }
                            this.setState({
                              selectedLeft: selectedLeft.filter((x) => x !== field._id),
                              leftDraging: false,
                            })
                          }}
                          onMouseOver={() => {
                            if (leftDraging === false) {
                              this.setState({selectedLeft: selectedLeft.filter((x) => x !== field._id)})
                            }
                          }}
                        >
                          {field.label}
                          {field.kind === 'composition' ? ' *' : ''}
                        </div>
                      ) : (
                        <div
                          className={styles.item}
                          key={field._id}
                          onMouseDown={(e) => {
                            if (e.button !== 0) {
                              return
                            }
                            this.setState({selectedLeft: [...selectedLeft, field._id], leftDraging: true})
                          }}
                          onMouseOver={() => {
                            if (leftDraging === true) {
                              this.setState({selectedLeft: [...selectedLeft, field._id]})
                            }
                          }}
                        >
                          {field.label}
                          {field.kind === 'composition' ? ' *' : ''}
                        </div>
                      )
                    )}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
          <div className={styles.selectButtons}>
            <a className={styles.selectButton} onClick={() => this.setState({selectedLeft: []})}>
              選択解除
            </a>
            <a
              className={styles.selectButton}
              onClick={() =>
                this.setState({
                  selectedLeft: _.flatMap(leftGrouped)
                    .filter(({_id, kind}) => !resultIds.includes(_id) && kind !== 'composition')
                    .map(({_id}) => _id),
                })
              }
            >
              全項目選択
            </a>
          </div>
        </div>
        <div className={styles.buttonsBetween}>
          <a
            className={styles.buttonBetween}
            onClick={() => {
              this.setState({
                selectedLeft: [],
              })
              onChange([
                ...result,
                ...availableFields.filter((field) => selectedLeft.includes(field._id)).map(addResult),
              ])
            }}
          >
            <Pulldown className={styles.selectRight} size={15} />
          </a>
          <a
            className={styles.buttonBetween}
            onClick={() => {
              this.setState({
                selectedRight: [],
              })
              onChange(result.filter(({_id}) => !selectedRight.includes(_id)))
            }}
          >
            <Pulldown className={styles.selectLeft} size={15} />
          </a>
          <a
            className={styles.buttonBetween}
            onClick={() => onChange([...result, this.newSpace(result.filter((v) => v.kind === 'space').length)])}
          >
            空白
            <br />
            追加
          </a>
        </div>
        <div>
          <div className={styles.title}>選択済み項目</div>
          <div className={styles.multiListWithOrder}>
            <div className={styles.withNote}>
              <div className={styles.multiList}>
                <Search value={searchRight} onChange={(value) => this.setState({searchRight: value})} />
                <div className={styles.list}>
                  {rightItems.map((field, index) => {
                    const selected = selectedRight.includes(field._id)
                    return (
                      <div
                        key={field._id}
                        className={selected ? styles.itemSelected : styles.item}
                        onMouseDown={(e) => {
                          if (e.button !== 0 || edittingAlias !== null) {
                            return
                          }
                          if (selected) {
                            this.setState({
                              selectedRight: selectedRight.filter((x) => x !== field._id),
                              rightDraging: false,
                            })
                          } else {
                            this.setState({selectedRight: [...selectedRight, field._id], rightDraging: true})
                          }
                        }}
                        onMouseOver={() => {
                          if (rightDraging === false && selected) {
                            this.setState({selectedRight: selectedRight.filter((x) => x !== field._id)})
                          }
                          if (rightDraging === true && !selected) {
                            this.setState({selectedRight: [...selectedRight, field._id]})
                          }
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault()
                          this.setState({
                            edittingAlias: field._id,
                          })
                        }}
                      >
                        {(() => {
                          return edittingAlias === field._id ? (
                            <form onSubmit={() => this.setState({edittingAlias: null})}>
                              <input
                                type="text"
                                className={styles.aliasInput}
                                autoFocus
                                value={field.label_alias}
                                onChange={(e) => onChange(set(`[${index}].label_alias`, e.target.value || '', result))}
                                onBlur={() => this.setState({edittingAlias: null})}
                              />
                            </form>
                          ) : (
                            <span>
                              {field.label_alias === field._label
                                ? field._label
                                : `${field._label}（${field.label_alias}）`}
                            </span>
                          )
                        })()}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className={styles.selectButtons}>
                <a className={styles.selectButton} onClick={() => this.setState({selectedRight: []})}>
                  選択解除
                </a>
                <a
                  className={styles.selectButton}
                  onClick={() =>
                    this.setState({
                      selectedRight: _.map(rightItems, '_id'),
                    })
                  }
                >
                  全項目選択
                </a>
              </div>
              <span className={styles.note}>※右クリックで各項目のヘッダを変えることができます</span>
            </div>
            <div className={styles.buttonsOrder}>
              <div>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  onClick={() => {
                    const s = _.intersection(resultIds, selectedRight)
                    onChange(select([...s, ..._.difference(resultIds, s)], result))
                  }}
                >
                  <ArrowDoublePrev className={styles.orderTop} size={10} />
                </a>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  onClick={() => {
                    onChange(select(arrayMove(resultIds, selectedRight), result))
                  }}
                >
                  <Pulldown className={styles.orderPrev} size={10} />
                </a>
              </div>
              <div>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  onClick={() => {
                    onChange(select(arrayMove(resultIds, selectedRight, true), result))
                  }}
                >
                  <Pulldown size={10} />
                </a>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  onClick={() => {
                    const s = _.intersection(resultIds, selectedRight)
                    onChange(select([..._.difference(resultIds, s), ...s], result))
                  }}
                >
                  <ArrowDoubleNext className={styles.orderBottom} size={10} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MultiList
