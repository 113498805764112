import React from 'react'
import {reduxForm, submit as submitForm, Field, FormSection, formValueSelector, autofill} from 'redux-form'
import FormErrors, {LabelMapper} from 'jbc-front/components/FormErrors'
import {Required, Section, CheckboxField, RadioField, Error, TextAreaField} from 'jbc-front/components/Form'
import {toBooleanProps} from 'utils'
import _ from 'lodash'
import Button from 'jbc-front/components/Button'
import Modal from 'jbc-front/components/CommonModal'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import {Link} from 'react-router-dom'

const formName = 'myNumberRequest'
export const selector = formValueSelector(formName)

const requestOptions = [
  {value: 'true', label: '依頼する'},
  {value: 'false', label: '依頼しない'},
]

export const purposeOfUses = {
  bp_pou_0: '報酬、料金、契約金及び賞金等の支払調書作成事務',
  bp_pou_1: '不動産使用料等の支払調書作成事務',
  bp_pou_2: '不動産等の譲受けの対価の支払調書作成事務',
  bp_pou_3: '不動産等の売買又は貸付けのあっせん手数料の支払調書作成事務',
  bp_pou_4: '非居住者等に支払われる給与、報酬、年金及び賞金の支払調書',
  bp_pou_5: '非居住者等に支払われる不動産の使用料等の支払調書作成事務',
  bp_pou_6: '非居住者等に支払われる不動産の譲受けの対価の支払調書',
  bp_pou_7: '非居住者等に支払われる人的役務提供事業の対価の支払調書作成事務',
}

const validatePurposeOfUses = (purposeOfUse) => {
  return !purposeOfUse || !_.some(purposeOfUses, (value, key) => purposeOfUse[key])
    ? 'を一つ以上選択してください'
    : undefined
}

const notifyTypes = [
  {value: 'email', label: 'メールで提出'},
  {value: 'qr_code', label: 'QRコードで提出'},
]

const notifyTypesNoEmail = [
  {value: 'email', label: 'メールで提出', disabled: true},
  {value: 'qr_code', label: 'QRコードで提出'},
]

const Form = ({handleSubmit, hideModal, submitting, step, dispatch, notifyType, hasEmail}) => (
  <React.Fragment>
    {step === 'notify_type' ? (
      <React.Fragment>
        <Modal.Body>
          <RadioField
            options={hasEmail ? notifyTypes : notifyTypesNoEmail}
            name="my_number_input_request.notify_type"
            label="提出方法を選択してください"
          />
          {!hasEmail && <p className="u-mb10">※メールで提出依頼するためにはメールアドレスを入力してください</p>}
          ※QRコードで発行を選択するとpdfでQRコードが作成されます
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={hideModal} disabled={submitting}>
              キャンセル
            </Button>
            <Button primary onClick={() => dispatch(autofill(formName, '_step', 'others'))}>
              次へ
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Modal.Body>
          <FormErrors />
          <FormSection name="my_number_purpose_of_use">
            <LabelMapper name="_purpose_of_uses" label="利用目的" />
            <Section title="確認書類の提出">
              <RadioField
                name="request_number_confirmation"
                label="番号確認書類"
                {...toBooleanProps}
                option={requestOptions}
              />
              <RadioField
                name="request_identity_confirmation"
                label="身元確認書類"
                {...toBooleanProps}
                option={requestOptions}
              />
            </Section>
          </FormSection>
          <Section
            title={
              <p>
                マイナンバーの利用目的
                <Required />
              </p>
            }
            key="purposes"
          >
            <Field
              component={Error}
              name="my_number_purpose_of_use"
              label="利用目的"
              validate={validatePurposeOfUses}
            />
            <FormSection name="my_number_purpose_of_use">
              {_.map(purposeOfUses, (value, key) => (
                <CheckboxField name={key} label={value} key={key} />
              ))}
            </FormSection>
          </Section>
          {notifyType === 'email' && (
            <Section title="メール本文">
              <TextAreaField name="my_number_input_request.comment" label="本文" />
              メールのテンプレートは
              <Link
                to="/settings/custom_mails/request_to_input_my_number"
                className="u-txt-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                こちら
              </Link>
              から編集できます
            </Section>
          )}
          <p>
            ※{notifyType === 'email' ? 'URL' : 'QRコード'}の利用期限は発行してから30日間となります
            <br />
            期限がすぎた場合は再度依頼をお願いします
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={hideModal} disabled={submitting}>
              キャンセル
            </Button>
            <Button primary onClick={handleSubmit} disabled={submitting}>
              依頼する
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </React.Fragment>
    )}
  </React.Fragment>
)

export const makeInitialValues = ({id, email}) => ({
  business_partner_id: id,
  my_number_input_request: {
    notify_type: email ? 'email' : 'qr_code',
  },
  _step: 'notify_type',
})

export const submit = submitForm.bind(null, formName)

export default compose(
  connect((state) => ({
    notifyType: selector(state, 'my_number_input_request.notify_type'),
    step: selector(state, '_step'),
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch) => {
      if (errors && errors.my_number_purpose_of_use) {
        dispatch({
          type: 'FORM_ERRORS/SET_ERRORS',
          payload: [
            {
              route: [{key: '_purpose_of_uses'}],
              value: errors.my_number_purpose_of_use,
            },
          ],
        })
      }
    },
  })
)(Form)
