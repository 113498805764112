import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators} from 'actions'
import {updateEmployeeDetail} from 'employees/Update'
import {withFormSelectors, withFormSelectorsProvider} from 'employees/form/common'
import NaviForm from 'employees/NaviForm'
import {makeFormValues} from 'employees/Form'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {push} from 'connected-react-router'
import {getState} from 'utils'
import {saveWithFiles} from 'procedures/enroll/tmpData'
import {employeeDetailEmbed} from 'employees/Show'
import {fetchSelector} from 'actions'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Detail extends Component {
  static defaultProps = {
    draft: {},
  }

  componentDidMount() {
    const {id} = this.props.match.params
    this.props.loadProcedureStatus(id, this.props.token)
    this.props.loadTmpData(id, this.props.token)
    this.props.loadFieldGroup(this.props.token, this.props.client)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleFormSubmit = (values) => {
    const {
      updateEmployeeAndNext,
      token,
      match: {
        params: {id},
      },
      fields,
    } = this.props
    return updateEmployeeAndNext(values, token, id, fields)
  }

  handleSaveClick = () => {
    const {
      saveFormValues,
      match: {
        params: {id},
      },
      fields,
    } = this.props
    saveFormValues(id, fields)
  }

  render() {
    const {data, fieldGroups, employeeFieldGroups, draft, tmpFiles, selector} = this.props
    return (
      <NaviForm
        submitText="次へ"
        data={data}
        fieldGroups={fieldGroups}
        employeeFieldGroups={employeeFieldGroups}
        tmpData={draft.data}
        tmpFiles={tmpFiles}
        onSubmit={this.handleFormSubmit}
        procedureType="enroll"
        employee={data}
        selector={selector}
        naviSubmit={
          <Button primary onClick={this.handleSaveClick} widthWide>
            保存
          </Button>
        }
      />
    )
  }
}

export default Detail
  |> connect(
    (state) => ({
      token: state.auth.token,
      data: state.procedureStatuses.current.data.employee,
      draft: state.procedureStatuses.tmpData.data.employee,
      tmpFiles: state.procedureStatuses.tmpData.tmpFiles,
      fields: fetchSelector(state, 'custom_fields').data,
      fieldGroups: fetchSelector(state, 'custom_field_groups').data,
      employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
      client: state.client.current,
    }),
    (dispatch, {getValues}) => ({
      loadProcedureStatus(id, token) {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api.createWithAuth(token).procedureStatuses.get(id, ['employee', ...employeeDetailEmbed(false)])
          )
        )
      },
      loadTmpData(id, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api.createWithAuth(token).procedureStatuses.tmpData.mapByName(id, 'draft'),
              api.createWithAuth(token).procedureStatuses.tmpFiles.list(id, 'draft'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadFieldGroup(token, client) {
        dispatch(
          actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list())
        )
        dispatch(
          actionCreators.fetchData(
            'custom_field_groups',
            api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
          )
        )
        dispatch(
          actionCreators.fetchData(
            'custom_fields',
            api.createWithAuth(token).employeeCustom.fields.list(client.id, {
              embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
            })
          )
        )
      },
      destroy() {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
        dispatch(actionCreators.fetchDestroy('employee_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_fields'))
      },
      async updateEmployeeAndNext(values, token, procedureStatusId, fields) {
        try {
          const employeeId = values.employee.id
          await updateEmployeeDetail(employeeId, values, token, undefined, fields)
          await api.createWithAuth(token).procedureStatuses.moveTmpFilesToDb(procedureStatusId)
          await api.createWithAuth(token).procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            {
              detailFinished: true,
            },
            'draft',
            'flow_status'
          )
          dispatch(push(`/enroll/flow/${procedureStatusId}`))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      async saveFormValues(procedureStatusId, fields) {
        try {
          const state = await getState(dispatch)
          const values = getValues(state)
          const token = state.auth.token
          const authedApi = api.createWithAuth(token)
          await saveWithFiles(
            authedApi,
            procedureStatusId,
            values,
            'draft',
            state.procedureStatuses.tmpData.tmpFiles,
            fields
          )
          await updateEmployeeDetail(values.id, makeFormValues({values, hideEmploymentInfo: true}), token)
          dispatch(notifySuccess('保存しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
  |> withFormSelectors
  |> withFormSelectorsProvider
