import NOTIFICATIONS from 'tm/notifications/actions'
import ROLES from 'tm/roles/actions'
import fetchActions from 'fetchActions'

const actions = ['TM']
const subActions = {
  USER_DISPLAY_SETTINGS: fetchActions,
  NOTIFICATION_TEMPLATES: fetchActions,
  NOTIFICATIONS,
  ROLES,
}

export default [actions, subActions]
