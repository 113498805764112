import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {useUpdateStarFlag} from 'hooks/api/procedureStatuses'
import {ProcedureStatus} from 'types/api/procedureStatuses/procedureStatus'

// @ts-ignore
import {actionCreators} from 'actions'

// @ts-ignore
import StarFlag from 'components/StarFlag'

interface StarFlagColumnProps {
  procedureStatus: ProcedureStatus
}

const MAX_FLAG = 4 as const

export const StarFlagColumn: FC<StarFlagColumnProps> = ({procedureStatus}) => {
  const dispatch = useDispatch()
  const updateStarFlag = useUpdateStarFlag()

  const onClickStar = async () => {
    const newColorFlag = (procedureStatus.flag + 1) % MAX_FLAG

    try {
      await updateStarFlag(procedureStatus.id, {flag: newColorFlag})
      dispatch(actionCreators.procedureStatuses.setFlag({id: procedureStatus.id, flag: newColorFlag}))
    } catch (err) {
      dispatch(actionCreators.asyncError(err))
    }
  }

  return (
    <td onClick={onClickStar}>
      <StarFlag colorIndex={procedureStatus.flag} />
    </td>
  )
}
