import React from 'react'
import {useSelector} from 'react-redux'
import {recordDisplay} from 'utils'
import _ from 'lodash'
import {getGroups} from 'employees/form/EmploymentInfomation'
import {getSavedDisplayEmployeeLimit} from 'employees/list/utils'
import PaginatorWithResult from 'employees/list/PaginatorWithResult'
import SearchForm from 'employees/list/SearchForm'
import SortableTh from 'employees/list/SortableTh'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'

const EmployeesList = ({handleClick, searchFormAddedFieldHideValues, searchFormQueryConverter}) => {
  const employees = useSelector((state) => state.employees.list.data || [])
  const user = useSelector((state) => state.session.currentUser)
  const {pathname} = useSelector((state) => state.router.location)
  const hasBranchOffice = useHasBranchOffice()
  return (
    <div>
      <SearchForm
        additionalParams={{embed: ['personnel_history', 'office']}}
        addedFieldHideValues={searchFormAddedFieldHideValues}
        queryConverter={searchFormQueryConverter}
      />
      <div className="l-overflow-scroll">
        <table className="m-table-list m-table-fixed">
          <thead>
            <tr>
              <SortableTh field="staff_code" globalClassName="m-table-list-staffcode">
                スタッフ
                <wbr />
                コード
              </SortableTh>
              <SortableTh field="full_name_kana">氏名</SortableTh>
              <th>メールアドレス</th>
              <SortableTh field="employment_type">雇用形態</SortableTh>
              {hasBranchOffice && <SortableTh field="office">適用事業所</SortableTh>}
              <SortableTh field="group">グループ</SortableTh>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr
                key={employee.id}
                style={{cursor: 'pointer'}}
                className="table-hover"
                onClick={() => {
                  handleClick(employee.id)
                }}
              >
                <td>{recordDisplay(employee.staff_code)}</td>
                <td>{recordDisplay.fullName(employee)}</td>
                <td>{recordDisplay(employee.email)}</td>
                <td>{recordDisplay(_.get(employee, 'personnel_history.employment_type_name'))}</td>
                {hasBranchOffice && (
                  <td>
                    {recordDisplay(_.get(employee, 'personnel_history.office_name') || _.get(employee, 'office.name'))}
                  </td>
                )}
                <td>
                  {recordDisplay(
                    !_.isEmpty(getGroups(employee)) &&
                      getGroups(employee).map((group, index) => (
                        <span key={index}>
                          {group}
                          <br />
                        </span>
                      ))
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(pathname, user)} />
    </div>
  )
}
export default EmployeesList
