import {gql} from '@apollo/client'

export type DetailInputRequestStatus = 'none' | 'applying' | 'rejected' | 'accepted' | 'canceled'
export type DetailInputRequestType = 'InitialInput' | 'DirectRequest' | 'UpdateProfile'

export type QueryResult = {
  client: {
    id: number
    detailInputRequest: DetailInputRequest
    customEmployeeFieldGroups: CustomEmployeeFieldGroup[]
  }
  employeeFieldGroupMaster: EmployeeFieldGroupMaster[]
}

export type DetailInputRequest = {
  id: number
  status: DetailInputRequestStatus
  type: DetailInputRequestType
  createdAt: string
  updatedAt: string
  employee: Employee
  employeeFieldGroupMasters: EmployeeFieldGroupMaster[]
  customEmployeeFieldGroups: CustomEmployeeFieldGroup[]
  detailInputComments: DetailInputComment[]
}

export type Employee = {
  id: number
  staffCode: string
  displayFirstName: string
  displayLastName: string
  icon?: string
  personnelHistory?: PersonnelHistory
}

export type PersonnelHistory = {
  group0Name?: string
  group1Name?: string
  group2Name?: string
}

export type EmployeeFieldGroupMaster = {
  id: string
  label: string
  code: string
}

type CustomEmployeeFieldGroup = {
  id: string
  label: string
}

export type DetailInputComment = {
  id: number
  comment: string
  type: 'from_admin' | 'from_employee'
  user?: {
    id: number
    displayName: string
  }
  createdAt: string
}

export const DETAIL_INPUT_REQUEST_WITH_FIELD_GROUPS = gql`
  query DetailInputRequestWithFieldGroups($id: ID!) {
    client {
      id
      detailInputRequest(id: $id) {
        id
        status
        type
        createdAt
        updatedAt
        employee {
          id
          staffCode
          displayFirstName
          displayLastName
          icon
          personnelHistory {
            group0Name
            group1Name
            group2Name
          }
        }
        employeeFieldGroupMasters {
          id
          code
        }
        customEmployeeFieldGroups {
          id
        }
        detailInputComments {
          id
          comment
          type
          user {
            id
            displayName
          }
          createdAt
        }
      }
      customEmployeeFieldGroups(withFields: false) {
        id
        label
      }
    }
    employeeFieldGroupMaster {
      id
      label
      code
    }
  }
`

export const CANCEL_DETAIL_INPUT_REQUEST = gql`
  mutation cancelDetailInputRequest($input: CancelDetailInputRequestInput!) {
    cancelDetailInputRequest(input: $input) {
      detailInputRequest {
        id
      }
    }
  }
`

export const REMIND_DETAIL_INPUT_REQUEST = gql`
  mutation remindDetailInputRequest($input: RemindDetailInputRequestInput!) {
    remindDetailInputRequest(input: $input) {
      detailInputRequest {
        id
      }
    }
  }
`
