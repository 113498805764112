import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {Dl, Dd, Dt} from 'components/SecondaryPanel'
import {Panel as TertiaryPanel, Item as TertiaryItem, Headding as TertiaryHeadding} from 'components/TertiaryPanel'
import {recordDisplay, getState} from 'utils'
import _ from 'lodash'
import ActionButton from 'jbc-front/components/ActionButton'
import MyNumberModal, {myNumberTypes} from 'myNumber/MyNumberModal'
import {Delete, Edit} from 'jbc-front/components/icons'
import {purposeOfUses} from 'myNumber/businessPartner/RequestForm'
import Modal from 'jbc-front/components/Modal'
import {withModal} from 'jbc-front/components/CommonModal'
import compose from 'lodash/fp/compose'
import {replace} from 'connected-react-router'
import {Link} from 'react-router-dom'
import {asyncError} from 'store/actions/asyncError'

const businessStatuses = {
  planning: '取引予定',
  doing: '取引中',
  finished: '取引終了',
}

const DeleteIcon = compose(
  withModal,
  connect(null, (dispatch, {hideModal, id}) => ({
    async onSubmit() {
      try {
        const {
          auth: {token},
        } = await getState(dispatch)
        await api.createWithAuth(token).businessPartners.delete(id)
        dispatch(replace('/my_number/business_partners'))
        hideModal()
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  }))
)(({isModalOpen, hideModal, showModal, onSubmit}) => (
  <React.Fragment>
    <Modal
      isOpen={isModalOpen}
      hideModal={hideModal}
      header="削除の確認"
      body="取引先情報を削除しますか"
      onSubmit={onSubmit}
      submit="はい"
      cancel="いいえ"
    />
    <Delete size={16} onClick={showModal} className="u-cur-pointer" />
  </React.Fragment>
))

class Show extends Component {
  static defaultProps = {
    businessPartner: {},
  }

  state = {
    myNumberModal: {
      isOpen: false,
      businessPartner: {},
      type: null,
    },
  }

  componentDidMount() {
    const {id} = this.props.match.params
    const {dispatch, token} = this.props
    dispatch(
      actionCreators.fetchData(
        'business_partner',
        api.createWithAuth(token).businessPartners.get(id, {embed: 'my_number_input_request'})
      )
    )
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('business_partner'))
  }

  showMynumberModal = () => {
    const {businessPartner} = this.props
    this.setState({
      myNumberModal: {
        isOpen: true,
        businessPartner: businessPartner,
        type: myNumberTypes.businessPartner,
      },
    })
  }

  hideMyNumberModal = () => {
    this.setState({
      myNumberModal: {
        isOpen: false,
      },
    })
  }

  render() {
    const {businessPartner} = this.props
    const purposeOfUse = _.get(businessPartner, 'my_number_input_request.my_number_purpose_of_uses[0]')
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">マイナンバー情報</h1>
        </div>
        <div className="l-wrap-s">
          <Panel>
            <Headding icon={<DeleteIcon id={businessPartner.id} />}>
              {recordDisplay(businessPartner.name)}さんの情報
            </Headding>
            <div className="u-pc-pt30 u-pc-pr15 u-pc-pl15">
              <Item>
                <TertiaryPanel>
                  <Link to={`/my_number/business_partners/${businessPartner.id}/edit`}>
                    <TertiaryHeadding icon={<Edit size={16} />}>取引先情報</TertiaryHeadding>
                  </Link>
                  <TertiaryItem>
                    <Dl>
                      <Dt>管理コード：</Dt>
                      <Dd>{recordDisplay(businessPartner.code)}</Dd>
                      <Dt>取引先名：</Dt>
                      <Dd>{recordDisplay(businessPartner.name)}</Dd>
                      <Dt>メールアドレス：</Dt>
                      <Dd>{recordDisplay(businessPartner.email)}</Dd>
                      <Dt>契約状況：</Dt>
                      <Dd>{recordDisplay(businessStatuses[businessPartner.business_status])}</Dd>
                      <Dt>取引開始日：</Dt>
                      <Dd>{recordDisplay.date(businessPartner.start_on)}</Dd>
                      <Dt>取引終了日：</Dt>
                      <Dd>{recordDisplay.date(businessPartner.end_on)}</Dd>
                    </Dl>
                  </TertiaryItem>
                </TertiaryPanel>
                <TertiaryPanel>
                  <TertiaryHeadding>マイナンバー</TertiaryHeadding>
                  <TertiaryItem>
                    <Dl>
                      <Dt>マイナンバー</Dt>
                      <Dd>
                        <span>************</span>
                        <span className="u-ml30">
                          <ActionButton onClick={this.showMynumberModal}>表示</ActionButton>
                        </span>
                      </Dd>
                      <Dt>利用目的</Dt>
                      {_.isEmpty(purposeOfUse) ? (
                        <Dd>{recordDisplay()}</Dd>
                      ) : (
                        _.filter(purposeOfUses, (value, key) => purposeOfUse[key]).map((value, key) => (
                          <Dd key={key}>{value}</Dd>
                        ))
                      )}
                    </Dl>
                  </TertiaryItem>
                </TertiaryPanel>
              </Item>
            </div>
          </Panel>
        </div>
        <MyNumberModal {...this.state.myNumberModal} hide={this.hideMyNumberModal} />
      </div>
    )
  }
}

export default connect((state) => ({
  businessPartner: fetchSelector(state, 'business_partner').data,
  token: state.auth.token,
  currentUser: state.session.currentUser,
}))(Show)
