import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {reduxForm, getFormSubmitErrors as _getFormSubmitErrors, SubmissionError} from 'redux-form'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {Upload} from 'jbc-front/components/icons'
import Button from 'jbc-front/components/Button'
import Modal from 'jbc-front/components/CommonModal'
import {FileField, Section} from 'jbc-front/components/Form'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import api from 'api'
import {asyncError} from 'store/actions/asyncError'
import Loading from 'components/Loading'
import {validate} from 'employees/FileImport'
import styles from 'employees/FileImport.scss'

const getFormSubmitErrors = _getFormSubmitErrors('file_import')

const FileImportDescription = () => (
  <div className={styles.tableWrap}>
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>行番号</th>
            <td>
              入力情報を反映する賃金支払状況等の行番号を離職月を1行目とした通し番号で入力してください。14行目以降の入力は帳票では2枚目に記載され、ジョブカンでは25行まで登録することができます。
            </td>
            <td>14</td>
          </tr>
          <tr>
            <th>賃金支払基礎日数</th>
            <td>0~31の半角数字で入力</td>
            <td>31</td>
          </tr>
          <tr>
            <th>賃金支払対象期間開始日</th>
            <td>半角数字で入力 yyyy/mm/dd</td>
            <td>2021/01/01</td>
          </tr>
          <tr>
            <th>基礎日数</th>
            <td>0~31の半角数字で入力</td>
            <td>21</td>
          </tr>
          <tr>
            <th>賃金額（円）A欄</th>
            <td>
              月給の賃金を入力
              <br />
              ※通勤手当を3ヶ月または6ヶ月で支給している場合は当該月数で除した金額を各月に算入（端数は支給期間の最終月に計上）最後の月の給与を未計算で届出する場合は「未計算」と入力
            </td>
            <td>100000</td>
          </tr>
          <tr>
            <th>賃金額（円）B欄</th>
            <td>
              時間給、日給、出来高制の賃金を入力
              <br />
              最後の給与を未計算で届出する場合は「未計算」と入力
            </td>
            <td>50000</td>
          </tr>
          <tr>
            <th>備考欄</th>
            <td>16文字以内</td>
            <td>なし</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

const Form =
  (({submitting, handleSubmit}) => (
    <form onSubmit={handleSubmit} className={`u-mt20 ${styles.form}`}>
      <div className="u-mb40">
        <FileField name="file" />
      </div>
      <Modal
        isOpen={submitting}
        bodyOpenClassName={styles.loadingModalBody}
        className={styles.loadingModal}
        overlayClassName={styles.loadingModalOverlay}
      >
        <Modal.Body>
          <Loading />
        </Modal.Body>
      </Modal>
      <AsyncTaskConsumer>
        {({taskRunningProps}) => (
          <Button
            as="button"
            primary
            type="submit"
            disabled={submitting}
            icon={<Upload />}
            widthWide
            {...taskRunningProps}
          >
            登録
          </Button>
        )}
      </AsyncTaskConsumer>
    </form>
  ))
  |> reduxForm({
    form: 'file_import',
    enableReinitialize: true,
    validate,
  })

const R60ImportPayments = ({
  match: {
    params: {id},
  },
}) => {
  const dispatch = useDispatch()
  const [token, errors] = useSelector((state) => [state.auth.token, getFormSubmitErrors(state)])

  const handleSubmit = async (values) => {
    try {
      await api.createWithAuth(token).procedureStatuses.reports.importR60Csv(id, values)
      dispatch(push(`/procedure_statuses/${id}/report_edit#R60`))
    } catch (err) {
      if (err.response && err.response.status === 422 && _.get(err.response, 'data._errors')) {
        if (err.response.data._errors?.rows) {
          throw new SubmissionError(err.response.data._errors)
        }
      }
      dispatch(asyncError(err))
    }
  }

  return (
    <div>
      <div className="l-title-wrap u-pb30">
        <h1 className="m-title-main">日数・賃金額の一括登録</h1>
      </div>
      {errors?.rows && (
        <Section title="ファイル内容に誤りがあります" error>
          <ul>
            {errors.rows.map((err, idx) => (
              <li key={idx}>
                第{err.index + 1}行：{err.name}
                {err.message}
              </li>
            ))}
          </ul>
        </Section>
      )}
      <div className={`u-mt30 u-ta-c ${styles.mainNoteWrap}`}>
        <div className={styles.mainNoteTitle}>新規に登録したい賃金支払情報を記載したファイルを選択してください</div>
        <div className={styles.mainNoteBody}>
          <div className={styles.mainNote}>対応しているファイル形式はCSVとなります</div>
          <div className={styles.mainNote}>
            <a
              className="u-txt-link"
              href={`/api/procedure_statuses/${id}/report_param_sets/r60_csv`}
              rel="noopener"
              download
            >
              サンプルをダウンロード
            </a>
          </div>
          <Form onSubmit={handleSubmit} />
        </div>
      </div>
      <div className={`u-mt30 ${styles.warningWrap}`}>
        <dt className={styles.warningTitle}>【注意】</dt>
        <dd>
          セルを空白の状態でアップロードすると、
          <span className={styles.warning}>該当箇所の登録データが削除されますのでご注意ください</span>
        </dd>
      </div>
      <div className="u-mt50">
        <FileImportDescription />
      </div>
    </div>
  )
}

export default R60ImportPayments
