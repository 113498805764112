import React from 'react'
import Master from 'tm/Master'
import Tag from 'tm/employees/Tag'
import BelongsInfomation from 'tm/employees/BelongsInfomation'
import Careerhistory from 'tm/employees/Careerhistory'
import Project from 'tm/employees/Project'
import Skill from 'tm/employees/Skill'
import Qualification from 'tm/employees/Qualification'
import Language from 'tm/employees/Language'
import Workhistory from 'tm/employees/Workhistory'
import Educationalbackground from 'tm/employees/Educationalbackground'
import PersonalInfomation from 'tm/employees/PersonalInfomation'
import CustomFields from 'tm/employees/CustomFields'

const masters = ['nations', 'tags', 'languages', 'languageLevels', 'schoolTypes']

const Infos = ({userDisplaySetting}) => (
  <>
    <Master masters={masters} />
    <Tag userDisplaySetting={userDisplaySetting} />
    <BelongsInfomation userDisplaySetting={userDisplaySetting} />
    <Careerhistory userDisplaySetting={userDisplaySetting} />
    <Project userDisplaySetting={userDisplaySetting} />
    <Skill userDisplaySetting={userDisplaySetting} />
    <Qualification userDisplaySetting={userDisplaySetting} />
    <Language userDisplaySetting={userDisplaySetting} />
    <Workhistory userDisplaySetting={userDisplaySetting} />
    <Educationalbackground userDisplaySetting={userDisplaySetting} />
    <PersonalInfomation userDisplaySetting={userDisplaySetting} />
    <CustomFields userDisplaySetting={userDisplaySetting} />
  </>
)

export default Infos
