import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Index} from 'pages/withAuth/adminPage/directRequest/index'
import {Request} from 'pages/withAuth/adminPage/directRequest/request'

export const DirectRequestRoutes: RouteParams[] = [
  {
    key: 'direct_request',
    path: WITH_AUTH_ADMIN_PATHS.DIRECT_REQUEST.INDEX,
    component: Index,
    exact: true,
  },
  {
    key: 'direct_request/request',
    path: WITH_AUTH_ADMIN_PATHS.DIRECT_REQUEST.REQUEST,
    component: Request,
    exact: true,
  },
]
