import {combineReducers} from 'redux'
import {actionTypes} from 'actions'
import {getQueryDiffFactory} from 'utils'
import update from 'immutability-helper'
import fetchReducer from 'reducers/fetchReducer'
import queryReducer from 'reducers/queryReducer'

const defaultQuery = {
  page: 1,
  employment_status: 'employed',
  sort_column: null,
  search_keyword: '',
  order: null,
  my_number_status: 'all',
}

export const selectedReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEES.TOOGLE_SELECTED:
      return update(state, {[action.payload]: {$apply: (x) => !x}})
    case actionTypes.EMPLOYEES.CHANGE_SELECTED:
      return {[action.payload]: true}
    case actionTypes.EMPLOYEES.CLEAR_SELECTED:
      return {}
    case actionTypes.EMPLOYEES.CHANGE_MULTI:
      return Object.assign({}, state, ...action.payload)
    default:
      return state
  }
}

export default combineReducers({
  current: fetchReducer(actionTypes.EMPLOYEES.CURRENT, {data: {}}),
  list: fetchReducer(actionTypes.EMPLOYEES.LIST, {data: [], count: 0}),
  selected: selectedReducer,
  query: queryReducer(actionTypes.EMPLOYEES.SET_QUERY, defaultQuery),
  all: fetchReducer(actionTypes.EMPLOYEES.ALL, {data: []}),
  documentLimitExceeded: fetchReducer(actionTypes.EMPLOYEES.DOCUMENT_LIMIT_EXCEEDED, {data: []}),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
