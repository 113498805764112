const queryReducer =
  (setQuery, initialState = {}) =>
  (state = initialState, action) => {
    if (action.type === setQuery) {
      const query = {...initialState, ...action.payload}
      query.page = +query.page
      return query
    }
    return state
  }
export default queryReducer
