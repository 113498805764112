import React, {Component} from 'react'
import Form, {makeInitialValues, makeInitialValuesFromTmpData} from 'procedures/changeName/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import unset from 'lodash/fp/unset'
import checkEmployeeInput from 'components/checkEmployeeInput'
import checkUnemployed from 'components/checkUnemployed'
import compose from 'lodash/fp/compose'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'changeName'

class EmployeeInput extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      loadTmpData,
      loadEmployee,
      loadComments,
    } = this.props
    loadEmployee(token)
    loadComments(id, token)
    if (id) {
      loadTmpData(id, token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      employee,
      tmpData: {application_procedure_change_name: changeNameData},
      comments,
      match: {
        params: {id},
      },
      token,
      updateAndCreateProcedure,
    } = this.props
    const handleSubmit = (values, comment) =>
      updateAndCreateProcedure(id, employee.id, {...values, comment: comment}, token)
    return (
      <CommentModal
        formName={formName}
        procedureType="changeName"
        onSubmit={({comment, values}) => {
          handleSubmit(values, comment)
        }}
        comments={comments || []}
      >
        {({showModal}) => (
          <Form
            onSubmit={showModal}
            initialValues={changeNameData ? makeInitialValuesFromTmpData(changeNameData) : makeInitialValues(employee)}
            submitText="申請する"
            comments={comments || {}}
          />
        )}
      </CommentModal>
    )
  }
}

export default compose(
  checkEmployeeInput(),
  checkUnemployed(),
  connect(
    (state) => ({
      employee: state.employees.current.data,
      tmpData: state.procedureStatuses.tmpData.data,
      comments: fetchSelector(state, 'procedure_comments').data,
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadEmployee: (token) => {
        dispatch(actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.getCurrent()))
      },
      loadTmpData: (procedureStatusId, token) => {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(procedureStatusId, {
              type: 'application',
              name: 'procedure_change_name',
            })
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndCreateProcedure: async (procedureStatusId, employeeId, values, token) => {
        try {
          const authedApi = api.createWithAuth(token)
          if (!procedureStatusId) {
            const {
              data: {id},
            } = await authedApi.procedureStatuses.start(employeeId, 'change_name', values.date_on, 'employee_draft')
            procedureStatusId = id
          }
          const image = values.procedure_data.image
          const tmpData = unset('procedure_data.image', values)
          await authedApi.procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            tmpData,
            'application',
            'procedure_change_name'
          )
          if (image) {
            if (!image.url) {
              await authedApi.procedureStatuses.tmpFiles.createOrUpdate(
                procedureStatusId,
                image,
                'application',
                'image'
              )
            }
          } else {
            const {tmpFiles} = await authedApi.procedureStatuses.tmpFiles.list(procedureStatusId, 'application')
            for (const tmpFile of tmpFiles) {
              await authedApi.procedureStatuses.tmpFiles.delete(procedureStatusId, tmpFile.id)
            }
          }
          await authedApi.procedureStatuses.apply(procedureStatusId, values.comment)
          dispatch(push('/dashboard'))
          dispatch(notifySuccess('申請しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(EmployeeInput)
