import React from 'react'
import {Section, DateField} from 'jbc-front/components/Form'
import {
  reduxForm,
  FieldArray,
  formValueSelector,
  isSubmitting as isSubmittingForm,
  autofill as autofillForm,
  stopSubmit as stopSubmitForm,
  touch as touchForm,
} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {renderDependents, validate} from 'employees/form/Dependents'
import Marital from 'employees/form/Marital'
import EmployeeName from 'procedures/EmployeeName'
import {connect} from 'react-redux'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import ReviewComments from 'components/ReviewComments'

const formName = 'changeDependents'
const selector = formValueSelector(formName)
export const isSubmitting = isSubmittingForm(formName)
export const autofill = autofillForm.bind(null, formName)
export const stopSubmit = stopSubmitForm.bind(null, formName)
export const touch = touchForm.bind(null, formName)

const Form = ({setMarried, submitting, handleSubmit, otherButtons, employee, submitText = '次へ', comments}) => (
  <form className="new_boarding_procedure" onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">扶養追加</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <ReviewComments comments={comments} />
      <Section title="被扶養者異動日">
        <DateField
          name="date_on"
          label="被扶養者になった日"
          note="子供が生まれた場合は「出生日」を、結婚の場合は「結婚した日」を入力します。"
          required
        />
      </Section>
      <Section title="被扶養者情報">
        <FieldArray
          name="dependents"
          component={renderDependents}
          procedureType="add_dependents"
          formName={formName}
          setMarried={setMarried}
          selector={selector}
          autofill={autofill}
          canAdd
          canDelete
        />
      </Section>
      <Marital selector={selector} />
      <div className="u-ta-c u-mt30">
        {otherButtons}
        <AsyncTaskConsumer>
          {({taskRunningProps}) => (
            <Button primary onClick={handleSubmit} disabled={submitting} {...taskRunningProps}>
              {submitText}
            </Button>
          )}
        </AsyncTaskConsumer>
      </div>
    </div>
  </form>
)

export default Form
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    validate,
  })
  |> connect(null, (dispatch) => ({
    setMarried(e, value, oldValue) {
      if (value === 'spouse' && oldValue !== 'spouse') {
        dispatch(autofill('marital_status', 'married'))
      }
    },
  }))
