import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import {gql} from '@apollo/client'
import {parse} from 'query-string'
import LoadingPage from 'components/LoadingPage'
import {useMutation, useLazyQuery} from 'components/Graphql'
import {notifySuccess, notifyError} from 'store/actions/notify'
import Form from 'documentTemplates/Form'
import {DOCUMENT_TEMPLATE_FRAGMENT} from 'documentTemplates/List'
import {documentSanitizeHtml} from 'documentTemplates/documentSanitizeHtml'

const CREATE_DOCUMENT_TEMPLATE = gql`
  mutation createDocumentTemplate($input: CreateDocumentTemplateInput!) {
    createDocumentTemplate(input: $input) {
      documentTemplate {
        ...DocumentTemplateFields
      }
    }
  }
  ${DOCUMENT_TEMPLATE_FRAGMENT}
`

const DOCUMENT_TEMPLATE_FOR_COPY = gql`
  query documentTemplateForCopy($id: ID!) {
    client {
      id
      documentTemplate(id: $id) {
        id
        body
      }
    }
  }
`

const initialValues = {
  visibility: 'private',
  editability: 'private',
}

const makeInitialValues = (documentTemplate) =>
  documentTemplate ? {...initialValues, body: documentSanitizeHtml(documentTemplate.body)} : initialValues

const Create = ({location: {search}}) => {
  const {copy} = parse(search)

  const dispatch = useDispatch()
  const [createDocumentTemplate] = useMutation(CREATE_DOCUMENT_TEMPLATE)
  const [loadTemplateForCopy, templateForCopy] = useLazyQuery(DOCUMENT_TEMPLATE_FOR_COPY, {variables: {id: copy}})

  useEffect(() => {
    if (copy) {
      loadTemplateForCopy()
    }
  }, [])

  useEffect(() => {
    if (templateForCopy.called && !templateForCopy.loading && !templateForCopy.data?.client?.documentTemplate) {
      dispatch(notifyError('書類テンプレートがありません'))
    }
  }, [templateForCopy.called, templateForCopy.loading])

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">書類テンプレート</h1>
      </div>
      <div className="l-wrap-xxl l-contents-wrap">
        {copy && (!templateForCopy.called || templateForCopy.loading) ? (
          <LoadingPage />
        ) : (
          <Form
            initialValues={makeInitialValues(templateForCopy.data?.client?.documentTemplate)}
            onSubmit={async (values) => {
              await createDocumentTemplate({variables: {input: {documentTemplate: values}}})
              dispatch(push('/document_templates'))
              dispatch(notifySuccess('登録しました'))
            }}
          />
        )}
      </div>
    </>
  )
}

export default Create
