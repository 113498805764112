import React, {Component, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actionCreators} from 'actions'
import _ from 'lodash'
import compose from 'lodash/fp/compose'
import SearchFormWithCardList from 'documents/create/list/SearchFormWithCardList'
import PaginatorWithPerPage from 'documents/create/list/PaginatorWithPerPage'
import SortableTh from 'documents/create/list/SortableTh'
import SelectList from 'components/SelectList'
import {wrapArrowText} from 'employees/List'
import {getGroups} from 'employees/form/EmploymentInfomation'
import styles from 'documents/create/EmployeeList.scss'
import {recordDisplay} from 'utils'
import {useWizard} from 'components/Wizard'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'

const additionalParams = {embed: ['personnel_history']}

export class Hint extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true)
  }

  handleClick = () => {
    this.props.onClick()
  }
  render() {
    return (
      <div className={styles.hint}>
        <div className={styles.hintShape} />
        <div className={styles.hintMessage}>{this.props.children}</div>
      </div>
    )
  }
}

const Td = ({children, ...rest}) => {
  return <td {...rest}>{children}</td>
}

const isNotLimitExceededEmployee = (employee, exceededEmployees) => !exceededEmployees.includes(employee)

const isAllSelected = (employees, selected, employeesLimitExceeded) =>
  _.some(selected) &&
  employees.every((employee) => selected[employee] || !isNotLimitExceededEmployee(employee, employeesLimitExceeded))

const ListTable = ({
  list,
  selected,
  loading,
  hasBranchOffice,
  employeesAll,
  employeesLimitExceeded,
  handleSelectClick,
  changeMulti,
  showHintEmployeeId,
  setShowHintEmployeeId,
  hint,
}) => {
  const handleSelectAllClick = () => {
    const allSelected = isAllSelected(employeesAll, selected, employeesLimitExceeded)
    if (allSelected) {
      changeMulti(employeesAll.filter((employee) => selected[employee]).map((employee) => ({[employee]: false})))
    } else {
      changeMulti(
        employeesAll
          .filter((employee) => isNotLimitExceededEmployee(employee, employeesLimitExceeded))
          .map((employee) => ({[employee]: true}))
      )
    }
    discardStep(current)
  }

  const allSelected = isAllSelected(employeesAll, selected, employeesLimitExceeded)
  const allSelectedClass = allSelected ? ' m-table-checkbox-on ' : ' m-table-checkbox-off '
  const {discardStep, current} = useWizard()
  return (
    <div className="l-overflow-scroll">
      <table className={`m-table-list m-table-fixed ${hasBranchOffice ? styles.hasBranchOffice : ''}`}>
        <thead>
          <tr className={styles.table}>
            <th className={styles.checkColumn + allSelectedClass} onClick={handleSelectAllClick}>
              <input type="checkbox" readOnly checked={allSelected} />
            </th>
            <SortableTh field="staff_code" globalClassName={styles.staffCodeColumn}>
              スタッフコード
            </SortableTh>
            <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
              氏名
            </SortableTh>
            {hasBranchOffice && (
              <SortableTh field="office" globalClassName={styles.officeColumn}>
                事業所
              </SortableTh>
            )}
            <SortableTh field="group" globalClassName={styles.groupColumn}>
              グループ
            </SortableTh>
            <SortableTh field="employment_type" globalClassName={styles.employmentStatusColumn}>
              雇用形態
            </SortableTh>
            <SortableTh field="joined_at" globalClassName={styles.dateColumn}>
              入社日
            </SortableTh>
          </tr>
        </thead>
        <tbody>
          {loading ? null : !_.isEmpty(list) ? (
            list.map(({item: employee}) => {
              const id = employee.id
              const joined_at = _.get(employee, 'joined_at')
              const selectedClass = selected[id] ? ' m-table-checkbox-on ' : ' m-table-checkbox-off '
              return (
                <tr className={styles.table} key={id}>
                  <Td
                    className={styles.checkColumn + selectedClass}
                    onClick={() => {
                      handleSelectClick(id)
                      discardStep(current)
                    }}
                  >
                    {showHintEmployeeId === employee.id && (
                      <Hint onClick={() => setShowHintEmployeeId(null)}>{hint}</Hint>
                    )}
                    <input type="checkbox" readOnly checked={selected[id] || false} />
                  </Td>
                  <Td className={styles.staffCodeColumn}>{recordDisplay(employee.staff_code)}</Td>
                  <Td className={styles.nameColumn}>{recordDisplay.fullName(employee)}</Td>
                  {hasBranchOffice && (
                    <Td className={styles.officeColumn}>
                      {recordDisplay(_.get(employee, 'personnel_history.office_name'))}
                    </Td>
                  )}
                  <Td className={styles.groupColumn}>
                    {recordDisplay(
                      !_.isEmpty(getGroups(employee)) &&
                        getGroups(employee).map((group, index) => (
                          <span key={index}>
                            {wrapArrowText(group)}
                            <br />
                          </span>
                        ))
                    )}
                  </Td>
                  <Td className={styles.employmentStatusColumn}>
                    {recordDisplay(_.get(employee, 'personnel_history.employment_type_name'))}
                  </Td>
                  <Td className={styles.dateColumn}>{recordDisplay(joined_at && joined_at.replace(/-/g, '/'))}</Td>
                </tr>
              )
            })
          ) : (
            <tr className={styles.table} />
          )}
        </tbody>
      </table>
    </div>
  )
}

const EmployeeList = ({
  employees,
  employeesAll,
  employeesLimitExceeded,
  loading,
  toogleSelected,
  changeMulti,
  selected,
}) => {
  const hasBranchOffice = useHasBranchOffice()
  const [showHintEmployeeId, setShowHintEmployeeId] = useState()
  const [hint, setHint] = useState()
  const handleSelectClick = (id) => {
    if (disabledMessage(id)) {
      setShowHintEmployeeId(id)
      setHint(disabledMessage(id))
    } else {
      toogleSelected(id)
    }
  }

  const disabledMessage = (employee) => {
    if (employeesLimitExceeded.includes(employee)) {
      return '従業員に作成できる書類は99件までです'
    } else {
      return null
    }
  }

  return (
    <div>
      <SelectList list={employees}>
        {({list}) => {
          return (
            <div className="l-contents-wrap">
              <div className="l-wrap-xxl">
                <SearchFormWithCardList additionalParams={additionalParams} />
                <ListTable
                  {...{
                    list,
                    loading,
                    hasBranchOffice,
                    employeesAll,
                    employeesLimitExceeded,
                    selected,
                    changeMulti,
                    handleSelectClick,
                    showHintEmployeeId,
                    setShowHintEmployeeId,
                    hint,
                  }}
                />
                <PaginatorWithPerPage />
              </div>
            </div>
          )
        }}
      </SelectList>
    </div>
  )
}

export default compose(
  connect(
    (state) => ({
      employees: state.employees.list.data,
      employeesAll: state.employees.all.data,
      employeesLimitExceeded: state.employees.documentLimitExceeded.data,
      loading: state.employees.list.loading,
    }),
    (dispatch) => ({
      ...bindActionCreators(_.pick(actionCreators.employees, ['toogleSelected', 'changeMulti']), dispatch),
    })
  )
)(EmployeeList)
