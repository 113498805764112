import {FC, useEffect} from 'react'
import {Presenter} from './Presenter'
import {EGOV_ACCOUNT, EgovAccountQueryResult, DELETE_ACTIVE_EGOV_ACCOUNT} from './query'
import {useQuery} from 'hooks/graphql'
import {useDispatch} from 'react-redux'
import {parse} from 'query-string'
import {replace} from 'connected-react-router'
import useReactRouter from 'use-react-router'
import {notifySuccess, notifyError} from 'store/actions/notify'

// @ts-ignore
import {useMutation} from 'components/Graphql'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {EGOV_ACCOUNT_MESSAGES} from 'consts/egov'

export const Show: FC = () => {
  const {data, refetch} = useQuery<EgovAccountQueryResult>(EGOV_ACCOUNT)
  const dispatch = useDispatch()
  const [deleteActiveEgovAccount] = useMutation(DELETE_ACTIVE_EGOV_ACCOUNT)
  const {
    location: {search},
  } = useReactRouter()
  const {message_type: messageType, message} = parse(search)

  const notify = (messageType: string, message: string) => {
    dispatch(replace(WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.ACCOUNT.SHOW))

    const displayMessage = EGOV_ACCOUNT_MESSAGES[message]
    if (displayMessage) {
      switch (messageType) {
        case 'success':
          dispatch(notifySuccess(displayMessage))
          return
        case 'error':
          dispatch(notifyError(displayMessage))
          return
      }
    }
  }
  const handleLogout = async () => {
    await deleteActiveEgovAccount()
    refetch()
    dispatch(notifySuccess('e-Govアカウントの連携を解除しました'))
  }

  useEffect(() => {
    if (messageType && message) {
      if (typeof messageType === 'string' && typeof message == 'string') {
        notify(messageType, message)
      }
    }
  }, [messageType, message])

  return <Presenter egovAccountInfoProps={data?.egovAccount} handleLogout={handleLogout} />
}
