import {FC} from 'react'
import {QueryVariables} from '../../../query'
import {PublishModal} from './PublishModal'
import {UnPublishModal} from './UnPublishModal'

type MypagePublishColumnProps = {
  employeeId: number
  variables: QueryVariables
  disabled: boolean
  isPublished: boolean
}

export const MypagePulbishColumn: FC<MypagePublishColumnProps> = ({employeeId, variables, disabled, isPublished}) => {
  if (isPublished) {
    return <UnPublishModal employeeId={employeeId} variables={variables} disabled={disabled} />
  } else {
    return <PublishModal employeeId={employeeId} variables={variables} disabled={disabled} />
  }
}
