// MEMO: deprecated!!!

import React, {FC, ReactNode, useContext, useState} from 'react'
import CommonModal from 'jbc-front/components/CommonModal'

const ModalPropsContext = React.createContext<HandleModalProps>({
  showModal: () => {
    return
  },
  hideModal: () => {
    return
  },
  toggleModal: () => {
    return
  },
  isOpen: false,
})

interface Props {
  children: (props: HandleModalProps) => ReactNode
}

export interface HandleModalProps {
  showModal: () => void
  hideModal: () => void
  toggleModal: () => void
  isOpen: boolean
}

export const ModalContainer: FC<Props> = ({children}) => {
  const [isOpen, setIsOpen] = useState(false)
  const funcs: HandleModalProps = {
    showModal: () => {
      setIsOpen(true)
    },
    hideModal: () => setIsOpen(false),
    toggleModal: () => setIsOpen(!isOpen),
    isOpen,
  }

  return <ModalPropsContext.Provider value={funcs}>{children(funcs)}</ModalPropsContext.Provider>
}

interface ModalProps {
  children: ReactNode
}
export const Modal: FC<ModalProps> = ({children}) => {
  const {isOpen, ...rest} = useContext(ModalPropsContext)
  return (
    <CommonModal isOpen={isOpen} {...rest}>
      {children}
    </CommonModal>
  )
}

export const Header: FC<ModalProps> = ({children}) => {
  const {hideModal} = useContext(ModalPropsContext)
  return <CommonModal.Header hideModal={hideModal}>{children}</CommonModal.Header>
}

export const Body = CommonModal.Body
export const Footer = CommonModal.Footer
export const Buttons = CommonModal.Buttons

export default Modal
