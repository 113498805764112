import React, {Component} from 'react'
import {Color} from 'jbc-front/components/icons'
import styles from 'settings/employeeCustom/ColorSelect.scss'
import onClickOutside from 'react-onclickoutside'

const colors = [0xe61345, 0x3498db, 0x00c4ae, 0x333333]

export const toColor = (int) => (+int ? `#${(+int).toString(16).padStart(6, '0')}` : '#333333')
class _SelectPopup extends Component {
  handleClickOutside = () => {
    this.props.handleBlur()
  }

  render() {
    const {
      input: {value, onChange},
    } = this.props
    return (
      <div className={styles.popup}>
        文字色の変更
        {colors.map((color) => (
          <div
            key={color}
            style={{backgroundColor: toColor(color)}}
            className={+value === color ? styles.colorSelected : styles.color}
            onClick={
              +value !== color
                ? () => {
                    onChange(`${color}`)
                  }
                : undefined
            }
          />
        ))}
      </div>
    )
  }
}

const SelectPopup = onClickOutside(_SelectPopup)

class ColorSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSelect: false,
    }
  }

  handleBlur = () => {
    const {
      input: {onBlur, value},
    } = this.props
    onBlur(value)
    this.setState({showSelect: false})
  }

  handleIconClick = () => {
    if (!this.state.showSelect) {
      this.setState({showSelect: true})
    } else {
      this.handleBlur()
    }
  }

  render() {
    const {input} = this.props
    return (
      <div className={styles.container}>
        <div className={styles.iconWrap} onClick={this.handleIconClick}>
          {input.value && input.value !== '0' ? (
            <div className={styles.icon} style={{backgroundColor: toColor(input.value)}} />
          ) : (
            <Color />
          )}
        </div>
        {this.state.showSelect && (
          <SelectPopup input={input} handleBlur={this.handleBlur} outsideClickIgnoreClass={styles.icon} />
        )}
      </div>
    )
  }
}

export default ColorSelect
