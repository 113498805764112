import unset from 'lodash/fp/unset'
import {formatValues} from 'employees/utils'
import {eachFormFiles} from 'employees/eachFormFiles'

export const toFormName = (name) => {
  const m = name.match(/employees_custom_values\.file\.(.*)/)
  if (m) {
    return `custom_fields.field_${m[1]}`
  }
  return name.replace(/^employee\./, '')
}

const sendFile = async ({file, dbName, authedApi, procedureStatusId, procedureTmpType, tmpFiles}) => {
  if (file && file instanceof File) {
    await authedApi.procedureStatuses.tmpFiles.createOrUpdate(procedureStatusId, file, procedureTmpType, dbName)
  } else if (!file) {
    const tmpFile = tmpFiles.find((tmpFile) => tmpFile.name === dbName)
    if (tmpFile) {
      await authedApi.procedureStatuses.tmpFiles.delete(procedureStatusId, tmpFile.id)
    }
  }
}

// 被扶養者情報に紐付くファイルを一時保存
export const saveDependentFiles = async ({authedApi, procedureStatusId, dependents, procedureTmpType, tmpFiles}) => {
  for (const file of eachFormFiles({dependents}, [])) {
    if (!file.dbName.startsWith('dependents.')) {
      continue
    }

    await sendFile({
      file: file.file,
      dbName: file.dbName,
      authedApi,
      procedureStatusId,
      procedureTmpType,
      tmpFiles,
    })
  }
}

export const saveWithFiles = async (authedApi, procedureStatusId, values, procedureTmpType, tmpFiles, customFields) => {
  let tmpData = unset('resumes', formatValues(values))
  for (const file of eachFormFiles(values, customFields, {ignorePermission: true})) {
    if (file.file !== null) {
      tmpData = unset(file.formName, tmpData)
    }
  }

  await authedApi.procedureStatuses.tmpData.createOrUpdate(procedureStatusId, tmpData, procedureTmpType, 'employee')

  for (const file of eachFormFiles(values, customFields)) {
    await sendFile({file: file.file, dbName: file.dbName, authedApi, procedureStatusId, procedureTmpType, tmpFiles})
  }
}
