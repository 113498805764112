import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators} from 'actions'
import api from 'api'
import {formValueSelector, reset} from 'redux-form'
import styles from 'settings/AllowedIps.scss'
import {handlerNestFormResponse} from 'libs/errorHandler'
import {Tab} from 'components/Tabs'
import {makeForm} from 'settings/AllowedIpsUtills'
import {asyncError} from 'store/actions/asyncError'

const formName = 'allowedIpsOther'
const selector = formValueSelector(formName)

const Form = makeForm(formName)

const allowedIpOptions = {client_role_id: 1, restrict_scope: 0}

class AllowedIpsOther extends Component {
  componentDidMount() {
    const {loadAllowedIps, token} = this.props
    loadAllowedIps(token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleSubmit = (values) => {
    const {token, updateAllowedIps} = this.props
    return updateAllowedIps(token, values)
  }

  render() {
    const {allowedIps, editing} = this.props
    return (
      <div>
        <div className="l-main-title-wrap u-pb0">
          <h1 className="m-title-main">IPアドレス制限</h1>
          <p className="m-title-main-note">登録した IPアドレス以外からはアクセスできなくなります</p>
          <div className={styles.tabs}>
            <Tab active>管理者</Tab>
            <Tab as={editing ? 'div' : Link} to="/settings/allowed_ips_employee">
              社員
            </Tab>
            <Tab as={editing ? 'div' : Link} to="/settings/allowed_ips_tm">
              人材管理
            </Tab>
          </div>
        </div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <span className="l-breadcrumb-here">IPアドレス制限(管理者)</span>
          </div>
          <Form
            initialValues={{
              _editing: false,
              allowed_ips: allowedIps.length > 0 ? allowedIps : [allowedIpOptions],
            }}
            allowedIpOptions={allowedIpOptions}
            formName={formName}
            selector={selector}
            onSubmit={this.handleSubmit}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    allowedIps: state.settings.allowedIps.data,
    editing: selector(state, '_editing'),
  }),
  (dispatch) => ({
    loadAllowedIps(token) {
      dispatch(actionCreators.settings.allowedIps.fetchData(api.createWithAuth(token).allowedIps.list('other')))
    },
    destroy() {
      dispatch(actionCreators.settings.allowedIps.destroy())
    },
    async updateAllowedIps(token, values) {
      try {
        const data = values.allowed_ips
        const {data: result} = await api
          .createWithAuth(token)
          .allowedIps.updateAll(data, 'other')
          .catch(handlerNestFormResponse('allowed_ips'))
        dispatch(actionCreators.settings.allowedIps.fetchSuccessed({data: result}))
        dispatch(reset(formName))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(AllowedIpsOther)
