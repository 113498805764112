import React, {useState, useRef, ReactNode} from 'react'
import styles from './HoverNote.scss'

interface HoverNoteProps {
  message: string
  children: ReactNode
}

export const HoverNote: React.FC<HoverNoteProps> = ({message, children}) => {
  const [isHover, setHoverState] = useState(false)
  const container = useRef<HTMLDivElement | null>(null)
  const shapeWidth = 15
  const childWidth = container.current?.clientWidth

  return (
    <div
      className={styles.note}
      onMouseOver={() => setHoverState(true)}
      onMouseOut={() => setHoverState(false)}
      ref={container}
    >
      {isHover && (
        <>
          <div className={styles.shape} style={{left: childWidth ? `${childWidth / 2 - shapeWidth / 2}px` : '0'}} />
          <div className={styles.message}>{message}</div>
        </>
      )}
      {children}
    </div>
  )
}
