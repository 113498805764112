import React from 'react'
import moment from 'moment'
import ListGroup from 'jbc-front/components/ListGroup'
import {Link} from 'react-router-dom'
import styles from 'dashboard/dashboard.scss'
import {recordDisplay} from 'utils'
import {useQuery} from 'components/Graphql'
import {SYSTEM_ANNOUNCES} from 'systemAnnounces/List'

const MAX_COUNT = 5
const DATE_WITH_YEAR_LENGTH = 9
const NEW_LABEL_LIMIT = 7

const SystemAnnounce = ({systemAnnounce}) => {
  const dateOrTime = recordDisplay.dateOrTime(systemAnnounce.publishAt)
  const today = moment()
  const publishAt = moment(systemAnnounce.publishAt)
  const dateDiff = today.diff(publishAt, 'days')

  return (
    <ListGroup.Item
      key={systemAnnounce.id}
      {...(systemAnnounce.systemAnnounceType === 'link'
        ? {href: systemAnnounce.link, target: '_blank', as: 'a'}
        : {to: `system_announces/${systemAnnounce.id}`, as: Link})}
    >
      <span className={styles.dateWithNew}>
        <span className={dateOrTime.length < DATE_WITH_YEAR_LENGTH ? styles.date : styles.dateWithYear}>
          {dateDiff <= NEW_LABEL_LIMIT && <span className={styles.new}></span>}
          {dateOrTime}
        </span>
      </span>
      <span className={dateDiff <= NEW_LABEL_LIMIT ? styles.newAnnounceTitle : styles.announceTitle}>
        {systemAnnounce.title}
      </span>
      <ListGroup.Icon />
    </ListGroup.Item>
  )
}

const SystemAnnounces = () => {
  const {data, loading} = useQuery(SYSTEM_ANNOUNCES, {variables: {per: MAX_COUNT}, fetchPolicy: 'network-only'})
  const available = !loading && data
  return (
    <article>
      <div className={styles.card}>
        <ListGroup>
          <div className={[styles.card_header, styles.insideBtnCardHeader].join(' ')}>
            <ListGroup.Title>
              お知らせ
              <div className={styles.more}>
                <Link
                  to="/system_announces"
                  className={available && data.systemAnnounces.totalCount > 0 ? styles.more_btn : styles.disabled}
                >
                  すべて見る
                </Link>
              </div>
            </ListGroup.Title>
          </div>
          <div className={styles.card_body}>
            {available &&
              (data.systemAnnounces.totalCount > 0 ? (
                data.systemAnnounces.list.map((systemAnnounce) => (
                  <SystemAnnounce key={systemAnnounce.id} systemAnnounce={systemAnnounce} />
                ))
              ) : (
                <ListGroup.Item className={styles.empty}>お知らせはまだありません</ListGroup.Item>
              ))}
          </div>
        </ListGroup>
      </div>
    </article>
  )
}

export default SystemAnnounces
