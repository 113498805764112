import React from 'react'
import {useSelector} from 'react-redux'
import {useNotify} from 'hooks/useNotify'
import ListEdit, {checkAnyItemEditted} from 'settings/ListEdit'
import {Link} from 'react-router-dom'
import {Tab} from 'components/Tabs'
import styles from 'settings/Groups.scss'

const Positions = () => {
  const initialList = useSelector((state) => state.settings.listEdit.initialList)
  const list = useSelector((state) => state.settings.listEdit.list)
  const isAnyItemEditted = checkAnyItemEditted(initialList, list)
  const notify = useNotify()
  const onClickDisabledTab = () => notify('保存してください', 'warning')

  return (
    <div>
      <div className="l-main-title-wrap u-pb0">
        <h1 className="m-title-main">組織管理の設定</h1>
        <p className="m-title-main-note">雇用形態、職種、グループ、役職の情報の設定を行えます</p>
        <div className={styles.tabs}>
          <Tab
            {...(isAnyItemEditted ? {as: 'div', onClick: onClickDisabledTab} : {as: Link})}
            to="/settings/employment_types"
          >
            雇用形態
          </Tab>
          <Tab
            {...(isAnyItemEditted ? {as: 'div', onClick: onClickDisabledTab} : {as: Link})}
            to="/settings/occupations"
          >
            職種
          </Tab>
          <Tab {...(isAnyItemEditted ? {as: 'div', onClick: onClickDisabledTab} : {as: Link})} to="/settings/groups">
            グループ
          </Tab>
          <Tab active>役職</Tab>
        </div>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <span className="l-breadcrumb-here">役職設定</span>
        </div>
        <ListEdit typeCode="positions" />
      </div>
    </div>
  )
}

export default Positions
