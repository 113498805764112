import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchSelector} from 'actions'
import {FormSection} from 'redux-form'
import {
  Section,
  RadioField,
  FileField,
  TextField,
  TextAreaField,
  SelectField,
  DateField,
  CheckboxField,
  renderTextWithAnchor,
} from 'jbc-front/components/Form'
import _ from 'lodash'
import {AnchorTarget} from 'jbc-front/components/ScrollSpy'
import {
  floatValue,
  floatValueWithComma,
  numberValue,
  numberValueWithComma,
  maxLength,
  minLength,
  exactLength,
  maxNumber,
  minNumber,
} from 'validators'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {toColor} from 'settings/employeeCustom/ColorSelect'
import styles from 'employees/form/CustomFields.scss'
import {withFormSelectors, getDiffFromCheckbox, getDiffFromFile} from 'employees/form/common'
import {getPermission} from 'employees/utils'

const CustomField = ({field, diff}) => {
  const name = `field_${field.id}`
  const required = field.indispensability_type === 'indispensable'
  const fieldValid = () => {
    if (field.allow_decimal_point) {
      return field.allow_comma ? floatValueWithComma : floatValue
    }
    return field.allow_comma ? numberValueWithComma : numberValue
  }
  const rangeValid = () => {
    if (field.number_limit_type === 'range') {
      return [maxNumber(field.maximum_number), minNumber(field.minimum_number)]
    }
    return []
  }
  switch (field.field_type) {
    case 'single_line_text':
      return (
        <TextField
          label={field.label}
          name={name}
          required={required}
          validate={_.filter([
            field.text_limit_type === 'fixed' && exactLength(field.text_length),
            field.text_limit_type === 'range' && maxLength(field.maximum_text_length),
            field.text_limit_type === 'range' && minLength(field.minimum_text_length),
          ])}
          diff={diff[name]}
          isChangeLabelToAnchor
        />
      )
    case 'multi_line_text':
      return <TextAreaField label={field.label} name={name} required={required} diff={diff[name]} />
    case 'number':
      return (
        <TextField
          label={field.label}
          name={name}
          required={required}
          validate={[fieldValid(), ...rangeValid()]}
          diff={diff[name]}
          isChangeLabelToAnchor
        />
      )
    case 'radio_button': {
      const options = field.custom_employee_field_options.map((option) => ({
        label: option.label,
        value: option.label,
      }))
      return (
        <RadioField
          label={field.label}
          name={name}
          required={required}
          options={options}
          diff={diff[name]}
          isChangeLabelToAnchor
        />
      )
    }
    case 'checkbox':
      return <CheckboxField label={field.label} name={name} diff={getDiffFromCheckbox(diff[name])} />
    case 'pulldown':
      return (
        <SelectField
          label={field.label}
          name={name}
          required={required}
          options={field.custom_employee_field_options.map((option) => ({label: option.label, value: option.label}))}
          diff={diff[name]}
          isChangeLabelToAnchor
        />
      )
    case 'date':
      return <DateField label={field.label} name={name} required={required} diff={diff[name]} isChangeLabelToAnchor />
    case 'file':
      return (
        <FileField
          label={field.label}
          name={name}
          required={required}
          diff={getDiffFromFile(diff[name])}
          isChangeLabelToAnchor
        />
      )
  }
}

const CustomFieldReadonly = ({field}) => {
  const name = `field_${field.id}`
  switch (field.field_type) {
    case 'multi_line_text':
      return <TextAreaField label={field.label} name={name} disabled isChangeLabelToAnchor />
    case 'checkbox':
      return <CheckboxField label={field.label} name={name} disabled />
    case 'file':
      return <FileField label={field.label} name={name} disabled isChangeLabelToAnchor />
    default:
      return <TextField name={name} label={field.label} disabled isChangeLabelToAnchor />
  }
}

class CustomFields extends Component {
  static defaultProps = {
    fields: [],
    group: {},
    diff: {},
  }

  render() {
    const {fields, currentUser, addAnchorTarget, diff, group} = this.props
    const fieldsMap = _.groupBy(fields, 'custom_employee_field_group_id')
    const groupFields = fieldsMap[group.id] || []

    return (
      <>
        {addAnchorTarget && <AnchorTarget name={`customFieldGroup${group.id}`} id={`customFieldGroup${group.id}`} />}
        <FormSection name="custom_fields">
          <LabelMapper name="custom_fields" label={'\u200b'} />
          {!_.isEmpty(groupFields) && (
            <div id={group.id} key={group.id}>
              <Section title={group.label}>
                {group.description && (
                  <div
                    className={styles.description}
                    style={{
                      color: toColor(group.description_color_by_rgb),
                    }}
                  >
                    {renderTextWithAnchor(group.description)}
                  </div>
                )}
                {groupFields.map((field) => (
                  <div key={field.id}>
                    {getPermission(field, currentUser) === 'updatable' ? (
                      <CustomField field={field} diff={diff} />
                    ) : (
                      <CustomFieldReadonly field={field} />
                    )}
                    {field.description && (
                      <div
                        className={styles.description}
                        style={{
                          color: toColor(field.description_color_by_rgb),
                        }}
                      >
                        {renderTextWithAnchor(field.description)}
                      </div>
                    )}
                  </div>
                ))}
              </Section>
            </div>
          )}
        </FormSection>
      </>
    )
  }
}

export default CustomFields
  |> connect((state, {selector}) => ({
    token: state.auth.token,
    client: state.client.current,
    fields: fetchSelector(state, 'custom_fields').data,
    currentUser: state.session.currentUser,
    diff: selector(state, '_diff.custom_fields'),
  }))
  |> withFormSelectors
