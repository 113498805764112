import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'

export const useDownloadSignedDocument = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()

  return async (documentId: string, employeeId: string) => {
    const path = `/api/documents/${documentId}/document_sign_transactions/${employeeId}/signed_document`
    const getter = () => authAxios.get(path, {responseType: 'arraybuffer'})

    download(getter)
  }
}
