import React, {useState} from 'react'
import {gql} from '@apollo/client'
import {useMutation} from 'components/Graphql'
import {Delete as DeleteIcon} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/Modal'
import {useNotify} from 'hooks/useNotify'
import styles from 'settings/roles/List.scss'

const DELETE_ROLE = gql`
  mutation delete_role($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      client {
        id
        roles {
          id
          name
          isDefault
          description
          personRoles {
            id
          }
        }
      }
    }
  }
`

const Delete = ({roleId}) => {
  const notify = useNotify()
  const [showModal, setShowModal] = useState(false)
  const [mutation] = useMutation(DELETE_ROLE)

  const deleteFunc = async () => {
    await mutation({
      variables: {
        input: {
          id: roleId,
        },
      },
    })
    notify('削除しました', 'success')
  }

  return (
    <div>
      <Modal
        header="削除の確認"
        body={
          <p>
            削除された権限は復元することができませんのでご注意ください。
            <br />
            <span className={styles.confirm}>本当に削除しますか</span>
          </p>
        }
        onSubmit={deleteFunc}
        isOpen={showModal}
        hideModal={() => setShowModal(false)}
        submit="はい"
        cancel="いいえ"
      />
      <DeleteIcon size={20} className={styles.icon} onClick={() => setShowModal(true)} />
    </div>
  )
}

export default Delete
