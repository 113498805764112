import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {
  Description,
  getDiffFromOptions,
  dependentDefaultValue,
  withFormSelectors,
  getDiffFromCheckbox,
  getDiffFromFile,
} from 'employees/form/common'
import {
  Section,
  TextField,
  CheckboxField,
  RadioField,
  SelectField,
  DateField,
  FileField,
} from 'jbc-front/components/Form'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import Button from 'jbc-front/components/Button'
import {FieldArray, FormSection} from 'redux-form'
import {
  Name,
  Birthday,
  Sex,
  Address,
  Phone,
  HasDependent,
  SubIncomeCheck,
  HandicapType,
  AddressForeign,
  BasicPensionNumbers,
  NationalType,
  RomajiName,
} from 'FormFields'
import {number, maxLength} from 'validators'
import _ from 'lodash'
import {isAdminSelector, isLimitedAdminSelector} from 'utils'

export const ResidenceStatusOptions = [
  {value: 'same_address', label: '同居'},
  {value: 'different_address', label: '別居（国内）'},
  {value: 'different_and_foreign_address', label: '別居（国外）'},
]

const relationCategories = [
  {value: 'parents', label: '父母'},
  {value: 'child', label: '子'},
  {value: 'other', label: 'その他'},
]

const kyokaiRelationCategories = [
  {value: 'real_child_or_adopted_child', label: '実子・養子'},
  {value: 'other_child', label: '実子・養子以外の子'},
  {value: 'other', label: 'その他'},
]

export const relationshipOtherTypes = [
  '実父',
  '実母',
  '義父',
  '義母',
  '長男',
  '長女',
  '次男',
  '次女',
  '三男',
  '三女',
  '兄',
  '姉',
  '弟',
  '妹',
  '祖父',
  '祖母',
  '曽祖父母',
  '孫息子',
  '孫娘',
  '叔父',
  '叔母',
  '甥',
  '姪',
].map((val) => ({value: val, label: val}))

const relationTypes = [
  {value: 'spouse', label: '配偶者'},
  {value: 'other', label: 'その他'},
]

const DescriptionNonResidentDocuments = ({residenceStatus, isNonResident, relationType}) => {
  if (relationType === 'spouse' && residenceStatus === 'different_and_foreign_address' && isNonResident) {
    return (
      <p className="u-mb20">
        国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類）を添付してください。
      </p>
    )
  } else {
    return (
      <p className="u-mb20">
        国外居住親族に係る扶養控除等を受けようとする場合は関係書類（例：親族関係書類、送金関係書類、留学証明書類）を添付してください。
      </p>
    )
  }
}

export const DependentRelationship = ({
  healthInsuranceType,
  required,
  relationType,
  nationalType,
  relationOther,
  setMarried,
  diff,
}) => (
  <div>
    <RadioField
      name="relation_type"
      label="続柄"
      options={relationTypes}
      required={required}
      onChange={setMarried}
      diff={getDiffFromOptions(relationTypes, diff?.relation_type)}
    />
    {relationType === 'spouse' && (
      <div>
        <BasicPensionNumbers diff={diff} />
        <NationalType required={required} />
        {nationalType === 'foreigner' && <RomajiName diff={diff} />}
      </div>
    )}
    {relationType === 'other' && (
      <SelectField
        name="relation_other"
        label="続柄詳細"
        options={relationshipOtherTypes}
        creatable
        required={required}
        note="リストにない続柄は直接入力して追加できます"
        diff={diff.relation_other}
      />
    )}
    {relationType === 'other' &&
      relationOther &&
      !relationshipOtherTypes.find((option) => option.value === relationOther) && (
        <RadioField
          name="relation_report_type"
          options={healthInsuranceType == 'kyokai' ? kyokaiRelationCategories : relationCategories}
          label="カテゴリ"
          required={required}
          diff={getDiffFromOptions(
            healthInsuranceType == 'kyokai' ? kyokaiRelationCategories : relationCategories,
            diff?.relation_report_type
          )}
        />
      )}
  </div>
)

const renderDependent = ({
  healthInsuranceType,
  relationType,
  nationalType,
  relationOther,
  residenceStatus,
  handicapType,
  procedureStatus,
  employee,
  setNoDependent,
  fields,
  index,
  name,
  setMarried,
  procedureType,
  annualIncomeOnChange,
  canDelete,
  isNonResident,
  isStudyAbroad,
  diff = {},
}) => (
  <li>
    <Section
      title={`被扶養者 (${index + 1})`}
      icon={
        canDelete &&
        (fields.length > 1 || typeof setNoDependent === 'function') && (
          <DeleteSquare
            onClick={() => {
              if (fields.length === 1) {
                fields.push(dependentDefaultValue(procedureType))
                setNoDependent()
              }
              fields.remove(index)
            }}
          />
        )
      }
    >
      <FormSection name={name}>
        <DependentRelationship
          {...{healthInsuranceType, relationType, nationalType, relationOther, setMarried, diff}}
          required
        />
        <Name required={true} diff={diff} />
        <Birthday required={true} diff={diff} />
        <Sex required={true} diff={diff} />
        <RadioField
          name="residence_status"
          label="同居・別居"
          options={ResidenceStatusOptions}
          required
          diff={getDiffFromOptions(ResidenceStatusOptions, diff?.residence_status)}
        />
        {residenceStatus === 'different_address' && (
          <div>
            <Address required diff={diff} />
            <TextField
              name="domestic_remittance"
              label="国内居住親族への年間送金額"
              noteWidth={320}
              validate={number}
              yen
              diff={diff.domestic_remittance}
            />
          </div>
        )}
        {residenceStatus === 'different_and_foreign_address' && (
          <div>
            <AddressForeign required diff={diff} />
            <TextField
              name="remittance"
              label="国外居住親族への年間送金額"
              note="扶養者が国外に住んでいて、控除を受ける場合は、「親族関係書類」と「送金関係書類」を会社に提出する必要があります"
              noteWidth={320}
              validate={number}
              yen
              diff={diff.remittance}
            />
          </div>
        )}

        {residenceStatus === 'different_and_foreign_address' && (
          <CheckboxField
            name="is_non_resident"
            label="非居住者"
            description="扶養者が国内に住所を有せず、かつ、現在まで引き続き1年以上居所を有しない場合チェックしてください。"
            options={isNonResident}
            diff={getDiffFromCheckbox(diff.is_non_resident)}
          />
        )}

        {relationType !== 'spouse' && residenceStatus === 'different_and_foreign_address' && isNonResident && (
          <CheckboxField
            name="is_study_abroad"
            label="留学"
            description="扶養者が非居住者のうち留学の場合はチェックしてください。"
            diff={getDiffFromCheckbox(diff.is_study_abroad)}
            options={isStudyAbroad}
          />
        )}

        {residenceStatus === 'different_and_foreign_address' && isNonResident && (
          <>
            <p className="u-mb20">添付ファイル</p>
            <DescriptionNonResidentDocuments
              residenceStatus={residenceStatus}
              isNonResident={isNonResident}
              relationType={relationType}
            />
            <FileField
              name="related_to_relatives_document"
              label="親族関係書類"
              diff={getDiffFromFile(diff.related_to_relatives_document)}
            />
            <FileField
              name="related_to_remittance_document"
              label="送金関係書類"
              diff={getDiffFromFile(diff.related_to_remittance_document)}
            />
          </>
        )}

        {relationType !== 'spouse' &&
          residenceStatus === 'different_and_foreign_address' &&
          isStudyAbroad &&
          isNonResident && (
            <>
              <FileField
                name="proving_study_abroad_document"
                label="留学証明書類"
                diff={getDiffFromFile(diff.proving_study_abroad_document)}
              />
            </>
          )}

        <TextField
          name="job"
          label="職業"
          required={true}
          validate={maxLength(12)}
          note={
            <p>
              以下のように12文字以内で入力してください。
              <br />
              <br />
              配偶者の場合・・・「主婦」「会社員」など
              <br />
              学生の場合・・・「小学生」「中学生」16歳以上の学生の場合は「高校◯年」「大学◯年」
              <br />
              乳幼児の場合・・・満1歳に満たない場合は「乳児」満1歳から小学校入学までは「幼児」
              <br />
              その他・・・「年金受給者」「無職」など
            </p>
          }
          diff={diff.job}
        />
        <Phone diff={diff} />
        <TextField
          name="annual_income"
          label="年間収入（今後1年間見込）"
          validate={number}
          yen
          required
          onChange={(e, value) => annualIncomeOnChange(e, value, relationType, procedureStatus, employee)}
          diff={diff.annual_income}
        />
        <TextField
          name="annual_earnings"
          label="年間所得（1～12月）"
          validate={number}
          yen
          required
          diff={diff.annual_earnings}
        />
        <HandicapType handicapType={handicapType} diff={diff} />
        <CheckboxField
          name="dependent_tax_law"
          label="税の扶養対象"
          diff={getDiffFromCheckbox(diff.dependent_tax_law)}
        />
        {!['enroll', 'add_dependents'].includes(procedureType) && (
          <DateField
            name="dependent_from"
            label="被扶養者になった日"
            note="入社の場合は入社日を、子どもが生まれた場合は「出生日」を、結婚の場合は「結婚した日」を入力します"
            diff={diff.dependent_from}
          />
        )}
        {procedureType !== 'enroll' && (
          <TextField
            name="dependent_reason"
            label="扶養に追加された理由"
            validate={maxLength(24)}
            note="「出生」「結婚」など24文字以内で入力してください"
            noteWidth={315}
            required
            diff={diff.dependent_reason}
          />
        )}
      </FormSection>
    </Section>
  </li>
)

const Dependent = connect(
  (state, {index, selector}) => ({
    healthInsuranceType: _.get(state.employees.current.data, 'office.health_insurance_type'),
    relationType: selector(state, `dependents[${index}].relation_type`),
    nationalType: selector(state, `dependents[${index}].national_type`),
    relationOther: selector(state, `dependents[${index}].relation_other`),
    residenceStatus: selector(state, `dependents[${index}].residence_status`),
    handicapType: selector(state, `dependents[${index}].handicap_type`),
    procedureStatus: state.procedureStatuses.current.data,
    employee: state.employees.current.data,
    isNonResident: selector(state, `dependents[${index}].is_non_resident`),
    isStudyAbroad: selector(state, `dependents[${index}].is_study_abroad`),
  }),
  (dispatch, {index, autofill}) => ({
    annualIncomeOnChange(e, value, relationType, procedureStatus, employee) {
      const earnings = Math.max(0, Number(value) - 550000)
      const rounededEarnings = earnings < 1000 ? Math.floor(earnings / 1000) * 100 : earnings
      dispatch(autofill(`dependents[${index}].annual_earnings`, `${rounededEarnings}`))

      if (relationType === 'spouse') {
        if (!_.get(procedureStatus, 'employee.spouse_annual_income') && !employee.spouse_annual_income) {
          dispatch(autofill(`spouse_annual_income`, value))
        }
        if (!_.get(procedureStatus, 'employee.spouse_monthly_income') && !employee.spouse_monthly_income) {
          dispatch(autofill(`spouse_monthly_income`, Math.floor(Number(value) / 12).toString()))
        }
      }
    },
  })
)(renderDependent)

export const renderDependents = ({
  fields,
  setNoDependent,
  procedureType,
  setMarried,
  canDelete,
  canAdd,
  selector,
  autofill,
  diff = [],
}) => (
  <div>
    <LabelMapper name="dependents" label="扶養情報の" />
    <ul>
      {fields.map((name, index) => (
        <Dependent
          diff={diff[index]}
          {...{
            index,
            name,
            setMarried,
            fields,
            procedureType,
            setNoDependent,
            canDelete,
            selector,
            autofill,
          }}
          key={index}
        />
      ))}
    </ul>
    {canAdd && (
      <div className="u-ta-r u-mt20 u-mb20">
        <div onClick={() => fields.push(dependentDefaultValue(procedureType))} className="u-txt-addinfo">
          <PlusSquare size={20} className="u-txt-addinfo-icon" />
          被扶養者を追加
        </div>
      </div>
    )}
  </div>
)

const Dependents = ({
  hasDependent,
  setNoDependent,
  setMarried,
  dependentValues,
  procedureType,
  isInitialInput,
  isAdmin,
  isLimitedAdmin,
  description,
  description_color_by_rgb,
  selector,
  autofill,
  diff = {},
}) => {
  const to = isLimitedAdmin ? '/change_dependents' : '/mypage/change_dependents'
  return (
    <Section title="扶養情報">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      {isInitialInput || isAdmin ? (
        <HasDependent diff={diff} />
      ) : (
        <>
          <p className="u-mb10">被扶養者の追加・削除を行う場合は別途手続きが必要になります</p>
          <Button as={Link} to={to} className="u-mb20">
            扶養の追加・削除の手続きへ
          </Button>
        </>
      )}
      {hasDependent && (
        <FieldArray
          name="dependents"
          component={renderDependents}
          diff={diff.dependents}
          canAdd={isInitialInput || isAdmin}
          canDelete={isInitialInput || isAdmin}
          {...{dependentValues, setNoDependent, procedureType, setMarried, selector, autofill}}
        />
      )}
      <SubIncomeCheck diff={diff} />
    </Section>
  )
}

export default Dependents
  |> connect(
    (state, {selector, isForceEmployee}) => ({
      hasDependent: selector(state, 'has_dependent'),
      diff: selector(state, '_diff'),
      isAdmin: !isForceEmployee && isAdminSelector(state),
      isLimitedAdmin: isLimitedAdminSelector(state),
    }),
    (dispatch, {autofill}) => ({
      setNoDependent: () => dispatch(autofill('has_dependent', false)),
      setMarried(e, value, oldValue) {
        if (value === 'spouse' && oldValue !== 'spouse') {
          dispatch(autofill('marital_status', 'married'))
        }
      },
    })
  )
  |> withFormSelectors

export const validate = (values) => {
  const {dependents} = values
  if (!dependents) {
    return undefined
  }

  const errors = {dependents: []}
  dependents.forEach((dependent, index) => {
    if (dependent.annual_income && dependent.annual_income.length > 7) {
      _.set(errors.dependents, `[${index}].annual_income`, 'を7桁以内の数値で入力してください')
    }

    const max = dependent.relation_type == 'spouse' ? 950000 : 480000
    if (+dependent.annual_earnings > max && dependent.dependent_tax_law) {
      _.set(
        errors.dependents,
        `[${index}].annual_earnings`,
        `が${
          max / 10000
        }万円を超えて扶養に入れる事はできません。税の扶養対象のチェックを外すか、年間所得に${max}以下の値を入力してください。`
      )
    }
  })
  return errors.dependents.length > 0 ? errors : undefined
}
