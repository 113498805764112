import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import NaviForm from 'employees/NaviForm'
import {formName, withFormSelectorsProvider, withFormSelectors} from 'employees/form/common'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import {getState, isAdminSelector} from 'utils'
import {saveWithFiles} from 'procedures/enroll/tmpData'
import {updateEmployeeDetail} from 'employees/Update'
import _ from 'lodash'
import {employeeDetailEmbed} from 'employees/Show'
import Completed from 'procedures/enroll/EmployeeInputCompleted'
import {FieldGroupProvider} from 'employees/FieldWithGroup'
import {reflectEnrollmentInputToken} from 'libs/enrollmentInputToken'
import {setCurrentClient} from 'store/slices/client'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const NOT_LOGIN_PATH = '/enrollment_employee_input/:id'

class Detail extends Component {
  static defaultProps = {
    tmpData: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      querying: true,
      valid: null,
      error: false,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      loadProcedureStatus,
      loadFieldGroup,
      loadTmpData,
      loadCurrentClient,
      loadComments,
    } = this.props
    loadProcedureStatus(id, token, this.props.client)
    loadFieldGroup(token, this.props.client)
    loadTmpData(id, token)
    loadCurrentClient(token)
    loadComments(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleFormSubmit = (comment) => {
    const {
      saveAndNext,
      match: {
        params: {id},
        path,
      },
      healthInsuranceType,
      token,
      fields,
    } = this.props
    return saveAndNext(id, token, healthInsuranceType, path !== NOT_LOGIN_PATH, fields, comment)
  }

  render() {
    const {
      data,
      submit,
      submitting,
      tmpData,
      tmpFiles,
      comments,
      procedureStatus,
      fieldGroups,
      employeeFieldGroups,
      selector,
      healthInsuranceType,
    } = this.props
    const {id, application_status, procedure_type} = procedureStatus
    if (!id) {
      return null
    }
    if (['applying', 'accepted'].includes(application_status)) {
      return <Completed afterInput />
    }
    return (
      <FieldGroupProvider type={procedure_type} permissionGroups={employeeFieldGroups}>
        <CommentModal
          formName={formName}
          procedureType={procedure_type}
          onSubmit={(values) => {
            this.handleFormSubmit(values.comment)
          }}
          comments={comments || []}
        >
          {({showModal}) => (
            <NaviForm
              submitText="完了"
              data={data}
              onSubmit={(values) =>
                healthInsuranceType === 'its' && values.dependents.length > 0 ? this.handleFormSubmit() : showModal()
              }
              hideEmploymentInfo
              tmpData={tmpData.data}
              tmpFiles={tmpFiles}
              procedureType={procedure_type}
              isInitialInput
              fieldGroups={fieldGroups}
              employeeFieldGroups={employeeFieldGroups}
              selector={selector}
              comments={comments || {}}
              naviSubmit={
                <Button primary disabled={submitting} onClick={submit} widthWide>
                  完了
                </Button>
              }
            />
          )}
        </CommentModal>
      </FieldGroupProvider>
    )
  }
}

export default Detail
  |> connect(
    (state, {isSubmitting}) => ({
      token: state.auth.token,
      data: state.procedureStatuses.current.data.employee,
      tmpData: state.procedureStatuses.tmpData.data.employee,
      tmpFiles: state.procedureStatuses.tmpData.tmpFiles,
      comments: fetchSelector(state, 'procedure_comments').data,
      healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
      fields: fetchSelector(state, 'custom_fields').data,
      submitting: isSubmitting(state),
      procedureStatus: state.procedureStatuses.current.data,
      fieldGroups: fetchSelector(state, 'custom_field_groups').data,
      employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
      isAdmin: isAdminSelector(state),
      client: state.client.current,
    }),
    (dispatch, {submit, getValues}) => ({
      loadProcedureStatus(id, token) {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api.createWithAuth(token).procedureStatuses.get(id, ['employee', ...employeeDetailEmbed(true)])
          )
        )
      },
      loadFieldGroup(token, client) {
        dispatch(
          actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list())
        )
        dispatch(
          actionCreators.fetchData(
            'custom_field_groups',
            api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
          )
        )
        dispatch(
          actionCreators.fetchData(
            'custom_fields',
            api.createWithAuth(token).employeeCustom.fields.list(client.id, {
              embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
            })
          )
        )
      },
      async loadCurrentClient(token) {
        try {
          const data = await api.createWithAuth(token).clients.getCurrent(['office'])
          dispatch(setCurrentClient(data))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      destroy() {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
        dispatch(actionCreators.fetchDestroy('employee_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_fields'))
      },
      loadTmpData(procedureStatusId, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api
                .createWithAuth(token)
                .procedureStatuses.tmpData.mapByName(procedureStatusId, 'application', 'employee'),
              api.createWithAuth(token).procedureStatuses.tmpFiles.list(procedureStatusId, 'application'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      async saveAndNext(procedureStatusId, token, healthInsuranceType, isLogin, fields, comment) {
        const state = await getState(dispatch)
        const values = getValues(state)
        try {
          const authedApi = api.createWithAuth(token)
          await saveWithFiles(
            authedApi,
            procedureStatusId,
            values,
            'application',
            state.procedureStatuses.tmpData.tmpFiles,
            fields
          )
          await updateEmployeeDetail(values.id, {resumes: values.resumes}, token, undefined, fields)
          const {dependents, has_dependent: hasDependent} = values
          if (
            healthInsuranceType === 'its' &&
            _.get(values, 'health_insurance.joined') &&
            hasDependent &&
            !_.isEmpty(dependents)
          ) {
            dispatch(
              push(
                isLogin
                  ? `/enroll/employee_input_dependents/${procedureStatusId}?dependent_index=0`
                  : `/enrollment_employee_input_dependents/${procedureStatusId}?enrollment_input_token=${token}&dependent_index=0`
              )
            )
          } else {
            await authedApi.procedureStatuses.apply(procedureStatusId, comment)
            dispatch(push(isLogin ? '/dashboard' : '/enrollment_employee_input_completed'))
          }
          dispatch(notifySuccess('提出しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      submit: () => {
        dispatch(submit)
      },
    })
  )
  |> withFormSelectors
  |> withFormSelectorsProvider
  |> reflectEnrollmentInputToken(NOT_LOGIN_PATH)
