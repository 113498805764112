export const PROCEDURE_NAMES: {[key: string]: string} = {
  r1: '健康保険・厚生年金保険被保険者資格取得届',
  r4: '健康保険被扶養者（異動）・国民年金第３号被保険者関係届',
  r5: '健康保険 被扶養者（異動）届',
  r6: '国民年金第３号被保険者関係届',
  r7: '健康保険・厚生年金保険被保険者資格喪失届',
  r57: '雇用保険被保険者資格取得届',
  r58: '雇用保険被保険者資格喪失届（離職票なし）',
  r60: '雇用保険被保険者資格喪失届（離職票あり）',
} as const
