import {FC, useState, useEffect, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {parse} from 'query-string'
import {push} from 'connected-react-router'
import useReactRouter from 'use-react-router'
import {ADD_DEPENDENTS} from 'consts/procedureType'
import {RequireEmployeeUpdateRouter} from 'components/router/RequireEmployeeUpdateRouter'
import {Section} from 'jbc-front/components/presenters/form/Section'
import {Label, Required} from 'jbc-front/components/presenters/form/Label'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Checkbox} from 'jbc-front/components/presenters/form/Checkbox'
import Button from 'jbc-front/components/Button'
import {Error} from 'jbc-front/components/presenters/form/Error'
import {useFetchEmployeeDependents} from 'hooks/api/employees/employeeDependents'
import {verify} from 'validators/index'
import {handleError} from 'libs/axiosErrorHandlers'

// @ts-ignore
import {isAdminSelector} from 'utils'

interface CheckboxDependent {
  id: number
  name: string
  checked: boolean
}

interface FormErrors {
  dependents: string[]
}

const ChooseExistDependents: FC = () => {
  const dispatch = useDispatch()
  const isAdmin = useSelector(isAdminSelector)
  const currentUser = useSelector((state: any) => state.session.currentUser)
  const {search, pathname} = useReactRouter().location
  const isEmployeeInput = !isAdmin || !isAdmin || pathname.includes('/mypage/')
  const employeeId = isEmployeeInput ? currentUser.employee.id : parse(search)['employee_id']
  const [checkboxDependents, setCheckboxDependents] = useState<CheckboxDependent[]>([])
  const initialErrors = {dependents: []}
  const [errors, setErrors] = useState<FormErrors>({...initialErrors})

  const {isLoading, resource: dependents, errors: fetchError} = useFetchEmployeeDependents(employeeId as string)

  useEffect(() => {
    const getEmployeeDependents = async () => {
      if (!isLoading) {
        if (fetchError) {
          await handleError(fetchError, dispatch)
          return
        }

        if (dependents) {
          const newCheckboxDependents = dependents.map((dependent) => {
            return {
              id: dependent.id,
              name: `${dependent.last_name} ${dependent.first_name}`,
              checked: false,
            }
          })

          setCheckboxDependents(newCheckboxDependents)
        }
      }
    }

    getEmployeeDependents()
  }, [isLoading])

  // 扶養情報がチェックされているかを確認するvalidator
  const requireCheckValidator = (values: CheckboxDependent[]) => {
    const result = {isValid: false, message: '被扶養者情報のいずれかにチェックを入れてください'}
    values.forEach((value) => {
      if (value.checked) {
        result.isValid = true
        result.message = ''
      }
    })

    return result
  }

  const handleSubmit = () => {
    const {isValid, messages} = verify(checkboxDependents, [requireCheckValidator])

    if (isValid) {
      // チェックのついた扶養のIDをqueryにして画面遷移
      const query = new URLSearchParams()
      checkboxDependents.forEach((checkboxDependent) => {
        if (checkboxDependent.checked) {
          query.append('dependentIds[]', String(checkboxDependent.id))
        }
      })
      const path = isEmployeeInput
        ? `/mypage/add_dependents/employee_input?${query}`
        : `/add_dependents/detail?employee_id=${employeeId}&${query}`

      dispatch(push(path))
    } else {
      setErrors({dependents: messages})
    }
  }

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const newCheckboxDependents = checkboxDependents.map((dependent) => {
      return {
        ...dependent,
        checked: String(dependent.id) == e.target.id ? !dependent.checked : dependent.checked,
      }
    })
    setCheckboxDependents(newCheckboxDependents)
    setErrors({...initialErrors})
  }

  const checkErrors = () => {
    const errorMessages = Object.keys(errors).filter((key) => errors[key as keyof FormErrors].length > 0)
    return errorMessages.length > 0
  }

  const handlePush = () => {
    const path = isEmployeeInput
      ? '/mypage/add_dependents/employee_input'
      : `/add_dependents/detail?employee_id=${employeeId}`
    dispatch(push(path))
  }

  return (
    <RequireEmployeeUpdateRouter
      employeeId={employeeId as string}
      redirectPath={`/employees/${employeeId}/edit?procedure_type=${ADD_DEPENDENTS}&next_action=choose_exist_dependents`}
    >
      <div className="l-title-wrap">
        <h1 className="m-title-main">扶養に追加する親族を選択</h1>
      </div>
      {checkboxDependents.length > 0 ? (
        <form>
          {checkErrors() && (
            <Section title="入力内容に誤りがあります" error>
              {errors.dependents.map((error) => (
                <Error key={error} error={error} />
              ))}
            </Section>
          )}
          <Section title="被扶養者情報">
            <Label>
              扶養に追加する人にチェックを入れてください
              <Spacer direction="x" size={4} />
              <Required />
            </Label>
            <Spacer size={8} />
            <ul>
              {checkboxDependents.map((dependent) => {
                return (
                  <li className="u-mb10" key={`dependent-checkbox-${dependent.id}`}>
                    <Checkbox id={String(dependent.id)} checked={dependent.checked} onChange={handleCheck}>
                      {dependent.name}
                    </Checkbox>
                  </li>
                )
              })}
            </ul>
          </Section>
          <div className="u-ta-c u-mt30">
            <Button primary onClick={handleSubmit}>
              次へ
            </Button>
          </div>
        </form>
      ) : (
        <>
          <Section title="被扶養者情報">
            <p>被扶養者情報が存在しません。</p>
          </Section>
          <div className="u-ta-c u-mt30">
            <Button primary onClick={handlePush}>
              被扶養者情報を追加する
            </Button>
          </div>
        </>
      )}
    </RequireEmployeeUpdateRouter>
  )
}

export default ChooseExistDependents
