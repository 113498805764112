import React from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {reduxForm, getFormValues} from 'redux-form'
import {CheckboxField, Label} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import {Delete} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import _ from 'lodash'
import {asyncError} from 'store/actions/asyncError'

const formName = 'trashFiles'
const getValues = getFormValues(formName)

const Form = compose(
  connect((state) => ({
    employeeFilesTrashed: fetchSelector(state, 'employee_files_trashed').data,
    formValues: getValues(state),
    token: state.auth.token,
  })),
  reduxForm({
    form: formName,
    onSubmit: async (values, dispatch, {hideModal, token, loadData}) => {
      try {
        const fileIds = _.map(_.pickBy(values), (value, key) => key.split('_')[1])
        const authedApi = api.createWithAuth(token)
        for (const id of fileIds) {
          await authedApi.uploadedFiles.fileInfos.update(id, {trashed_by_member: false})
        }
        loadData()
        hideModal()
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(({handleSubmit, submitting, hideModal, employeeFilesTrashed = {}, formValues}) => {
  const files = _.flatten(_.map(employeeFilesTrashed))
  return (
    <React.Fragment>
      <Modal.Header hideModal={hideModal}>削除済みファイル</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          {_.isEmpty(files) ? (
            <Label text="削除済のファイルがありません" />
          ) : (
            files.map((file) => (
              <CheckboxField
                key={file.file_info.id}
                name={`file_${file.file_info.id}`}
                label={file.file ? file.file.filename : file.file_info.file_name}
              />
            ))
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={submitting || _.isEmpty(formValues) || !_.some(formValues)}>
            元に戻す
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </React.Fragment>
  )
})

const Trash = ({isModalOpen, showModal, hideModal, dispatch, token, loadData}) => (
  <div>
    <ActionButton onClick={showModal} icon={<Delete size={16} />}>
      削除済みファイル
    </ActionButton>
    <Modal
      isOpen={isModalOpen}
      hideModal={hideModal}
      onModalShow={() => {
        dispatch(
          actionCreators.fetchData(
            'employee_files_trashed',
            api.createWithAuth(token).uploadedFiles.employeeFiles({trashed: true})
          )
        )
      }}
      onModalHide={() => {
        dispatch(actionCreators.fetchDestroy('employee_files_trashed'))
      }}
    >
      {isModalOpen && <Form hideModal={hideModal} loadData={loadData} />}
    </Modal>
  </div>
)

export default compose(
  withModal,
  connect((state) => ({token: state.auth.token}))
)(Trash)
