import React, {Component} from 'react'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

import api from 'api'
import styles from 'employees/Show.scss'
import {Delete as DeleteIcons} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/Modal'

class Delete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  handleDeleteClick = () => {
    const {id, token} = this.props
    return this.props.deleteEmployee(id, token)
  }

  render() {
    return (
      <div>
        <Modal
          header="削除の確認"
          body={
            <p>
              削除された従業員情報は復元することができません。
              <br />
              従業員のアカウントも一緒に削除され、ログインできなくなりますのでご注意ください。
              <br />
              <span className={styles.warningText}>本当に従業員を削除しますか</span>
            </p>
          }
          onSubmit={this.handleDeleteClick}
          isOpen={this.state.showModal}
          hideModal={() => this.setState({showModal: false})}
          submit="はい"
          cancel="いいえ"
        />
        <div className={styles.delete} onClick={() => this.setState({showModal: true})}>
          <span className={styles.icon}>
            <DeleteIcons size={14} />
          </span>
          <span className={styles.text}>{this.props.children}</span>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => state.auth,
  (dispatch) => ({
    async deleteEmployee(id, token) {
      try {
        await api.createWithAuth(token).employees.delete(id)
        dispatch(push(`/employees`))
        dispatch(notifySuccess('削除しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Delete)
