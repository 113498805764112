import React from 'react'
import {connect, useSelector, useDispatch} from 'react-redux'
import {Fields, Field, FieldArray, change} from 'redux-form'
import {dateFieldProps} from 'jbc-front/components/Form'
import {GroupsSelectBox, MultiTagFilteringFieldRow, SearchTextBox} from 'jbc-front/components/SearchForm'
import {maxAge, dateString} from 'validators'
import JoinedStatusSelectBoxes from 'employees/list/JoinedStatusSelectBoxes'
import {formName, selector, addOptions, saveAddedFields} from 'employees/list/utils'
import styles from 'jbc-front/components/SearchForm.scss'
import {fetchSelector} from 'actions'
import {SearchSelectBox} from 'jbc-front/components/SearchForm'
import {widowTypes, workingStudentTypes} from 'employees/form/PersonalType'
import {handicapTypes} from 'FormFields'
import {remunerationTypeOptions} from 'employees/form/RemunerationInfomation'
import {maritalStatusOptions} from 'employees/form/Marital'
import {retireTypes} from 'employees/form/EmploymentInfomation'
import {Close} from 'jbc-front/components/icons'
import {renderError, TextRow, DateRow} from 'employees/list/rows'
import {fromOpts, withNull, withNullOpt} from 'employees/list/addedFields/utils'
import Prefectures from 'employees/list/addedFields/Prefectures'
import NationalTypes from 'employees/list/addedFields/NationalTypes'
import Countries from 'employees/list/addedFields/Countries'
import Tags from 'employees/list/addedFields/Tags'
import Skills from 'employees/list/addedFields/Skills'
import Qualifications from 'employees/list/addedFields/Qualifications'
import Projects from 'employees/list/addedFields/Projects'
import Languages from 'employees/list/addedFields/Languages'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'
import {ScalarOfficeSelector} from 'features/offices/OfficeSelector'

const genders = [
  {name: '男性', id: 'man'},
  {name: '女性', id: 'woman'},
]

const hasDependents = [
  {name: '有', id: 'true'},
  {name: '無', id: 'false'},
]

const renderAddedField = (field) => {
  switch (field) {
    case 'genders':
      return <MultiTagFilteringFieldRow header="性別" fieldName="genders" list={withNull(genders)} />
    case 'remuneration_types':
      return (
        <MultiTagFilteringFieldRow
          header="給与形態"
          fieldName="remuneration_types"
          list={withNull(fromOpts(remunerationTypeOptions))}
        />
      )
    case 'handicap_types':
      return (
        <MultiTagFilteringFieldRow
          header="障害者区分"
          fieldName="handicap_types"
          list={withNull(fromOpts(handicapTypes))}
        />
      )
    case 'widow_types':
      return (
        <MultiTagFilteringFieldRow
          header="寡婦（夫）区分 "
          fieldName="widow_types"
          list={withNull(fromOpts(widowTypes))}
        />
      )
    case 'working_student_types':
      return (
        <MultiTagFilteringFieldRow
          header="勤労学生区分"
          fieldName="working_student_types"
          list={withNull(fromOpts(workingStudentTypes))}
        />
      )
    case 'has_dependents':
      return <MultiTagFilteringFieldRow header="扶養の有無" fieldName="has_dependents" list={hasDependents} />

    case 'marital_statuses':
      return (
        <MultiTagFilteringFieldRow
          header="配偶者の有無"
          fieldName="marital_statuses"
          list={withNull(fromOpts(maritalStatusOptions))}
        />
      )

    case 'national_types':
      return <NationalTypes />
    case 'countries':
      return <Countries />
    case 'retired_at':
      return <DateRow header="退職日" errorLabel="日付" prefix="retired_at" />
    case 'retire_types':
      return (
        <MultiTagFilteringFieldRow header="退職区分" fieldName="retire_types" list={withNull(fromOpts(retireTypes))} />
      )
    case 'former_name':
      return <TextRow header="旧姓" fieldName="former_name" />
    case 'birthday':
      return <DateRow header="生年月日" errorLabel="日付" prefix="birthday" />
    case 'prefecture_ids':
      return <Prefectures />
    case 'tags':
      return <Tags />
    case 'skills':
      return <Skills />
    case 'qualifications':
      return <Qualifications />
    case 'languages':
      return <Languages />
    case 'projects':
      return <Projects />
    default:
      return null
  }
}

const AddedFields = ({fields, hideValues}) => {
  const dispatch = useDispatch()
  const addedFields = useSelector((state) => selector(state, '_added_fields') || []).filter(
    ({value}) => !hideValues.includes(value)
  )
  return (
    <>
      {addedFields.map((field, index) => (
        <AddedField field={field} key={field} fields={fields} index={index} dispatch={dispatch} />
      ))}
      <div className={styles.addFieldSelect}>
        <SearchSelectBox
          options={addOptions.filter(({value}) => !(hideValues.includes(value) || addedFields?.includes(value)))}
          width={220}
          input={{
            onChange: (v) => {
              fields.push(v)
              dispatch((dispatch, getState) => {
                const user = getState().session.currentUser
                saveAddedFields(user, selector(getState(), '_added_fields'))
              })
            },
          }}
          closeOnSelect={false}
          placeholder="検索条件追加"
        />
      </div>
    </>
  )
}

const AddedField = ({field, fields, index, dispatch}) => {
  return (
    <div className={styles.addedField}>
      {renderAddedField(field)}
      <a
        className={styles.close}
        onClick={() => {
          fields.remove(index)
          const opt = addOptions.find((opt) => opt.value === field)
          ;(opt.fields || [opt.value]).forEach((field) => {
            dispatch(change(formName, field, null))
          })
          dispatch((dispatch, getState) => {
            const user = getState().session.currentUser
            saveAddedFields(user, selector(getState(), '_added_fields'))
          })
        }}
      >
        <Close />
      </a>
    </div>
  )
}

const OfficesSelectBox = ({input: inputProps}) => (
  <div style={{width: '400px'}}>
    <ScalarOfficeSelector
      multiple
      filterByCurrentOffices
      selectedIds={inputProps.value}
      onChange={(value) => {
        inputProps.onChange(value)
        inputProps.onBlur(value)
      }}
    />
  </div>
)

const AdditionalSearchFields = ({
  groups = [],
  positions = [],
  employmentTypes = [],
  occupations = [],
  addedFieldHideValues,
  showJoinedStatusField = true,
  showAddedField = true,
}) => {
  const hasBranchOffice = useHasBranchOffice()
  return (
    <div className={styles.details}>
      {hasBranchOffice && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeader}>適用事業所</div>
          <div className={styles.detailsBody}>
            <div className={styles.detailsBodyItems}>
              <Field name="offices" component={OfficesSelectBox} />
            </div>
          </div>
        </div>
      )}
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>グループ</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field name="groups" component={GroupsSelectBox} options={withNullOpt(groups)} />
          </div>
        </div>
      </div>
      <MultiTagFilteringFieldRow header="役職" fieldName="positions" list={withNull(positions)} />
      <MultiTagFilteringFieldRow header="雇用形態" fieldName="employment_types" list={withNull(employmentTypes)} />
      <MultiTagFilteringFieldRow header="職種" fieldName="occupations" list={withNull(occupations)} />
      {showJoinedStatusField ? (
        <div className={styles.detailsRow}>
          <div className={styles.detailsHeader}>加入状況</div>
          <div className={styles.detailsBody}>
            <div className={styles.detailsBodyItems}>
              <JoinedStatusSelectBoxes />
            </div>
          </div>
        </div>
      ) : null}
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>年齢</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <Field name="age_from" component={SearchTextBox} validate={maxAge(120)} inputWidth={40} maxLength={3} />
            <span className={styles.detailsComplementText}>歳以上</span>
            <Field name="age_to" component={SearchTextBox} validate={maxAge(120)} inputWidth={40} maxLength={3} />
            <span className={styles.detailsComplementText}>歳以下</span>
            <Field
              name="age_base_date"
              component={SearchTextBox}
              type="date"
              {...dateFieldProps}
              validate={dateString}
            />
            <span className={styles.detailsComplementText}>時点</span>
          </div>
          <div className={styles.detailsBodyErrors}>
            <Fields component={renderError} names={['age_from', 'age_to', 'age_base_date']} label="年齢" />
          </div>
        </div>
      </div>
      <DateRow header="入社日" errorLabel="日付" prefix="joined_at" />
      {showAddedField && (
        <FieldArray name="_added_fields" component={AddedFields} hideValues={addedFieldHideValues || []} />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  groups: fetchSelector(state, 'selections').groups,
  positions: fetchSelector(state, 'selections').positions,
  employmentTypes: fetchSelector(state, 'selections').employmentTypes,
  occupations: fetchSelector(state, 'selections').occupations,
  ageFrom: selector(state, 'age_from'),
  ageTo: selector(state, 'age_to'),
})
export default connect(mapStateToProps)(AdditionalSearchFields)
