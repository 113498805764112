import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import useReactRouter from 'use-react-router'
import {useSelector} from 'hooks/redux'
import {useFetchEffect} from 'hooks/useFetchEffect'
import type {Dispatch} from 'redux'
import type {Location} from 'history'
import type {User} from 'types/api/users/user'

interface SearchDetectorParam<Query, FormValues, AdditionalParams> {
  /**
   * 検索クエリをフォームの入力値に変換する。
   * @param query 検索クエリ
   */
  convertQueryToForm: (query: Query) => FormValues

  /**
   * locationとログインユーザ情報から現在の検索クエリを得る。
   * @param location
   * @param user ログインユーザ
   */
  getCurrentQueryFromLocation: (location: Location, user: User) => Query

  /**
   * 指定された検索クエリでAPIを実行する。
   * @param query 検索クエリ
   * @param additionalParams 検索クエリの追加パラメータ
   * @param dispatch
   */
  fetchData: (query: Query, additionalParams: AdditionalParams, dispatch: Dispatch) => void

  /**
   * 検索フォームのクリーンアップ処理。コンポーネントのアンマウント時に実行される。
   * @param dispatch
   */
  destroy: (dispatch: Dispatch) => void

  /**
   * フォームの入力値をURLのクエリパラメータ文字列に変換する。検索条件が変更された後、locationを更新するために使われる。
   * @param data フォームの入力値
   */
  convertFormToQueryString: (data: FormValues) => string
}

interface SearchFormProps<Query, FormValues, AdditionalParams> {
  initialValues: FormValues

  /**
   * 検索クエリに追加、または上書きするパラメータ。
   */
  additionalParams: AdditionalParams

  /**
   * フォームがsubmitされた時など、wrapされた側のコンポーネントが検索を実行する時に呼び出す関数。
   * @param values フォームの入力値
   */
  handleSearch: (values: FormValues) => void

  /**
   * APIを実行する直前に呼び出される。検索クエリを加工する必要がある場合に指定する。
   * @param query 検索クエリ
   */
  queryConverter?: (query: Query) => Query
}

/**
 * 検索フォームをラップし、ブラウザのURLと検索条件を同期する機能を追加するHoC。
 *
 * フォームの入力値とAPIに渡す検索クエリを相互に変換する方法と、検索APIの実行方法を引数として与える必要がある。
 */
const searchDetector =
  <Query, FormValues, AdditionalParams>({
    convertQueryToForm,
    getCurrentQueryFromLocation,
    fetchData,
    destroy,
    convertFormToQueryString,
  }: SearchDetectorParam<Query, FormValues, AdditionalParams>) =>
  <OriginalProps extends SearchFormProps<Query, FormValues, AdditionalParams>>(
    SearchForm: React.ComponentType<OriginalProps>
  ) => {
    const SearchDetector = (props: OriginalProps) => {
      const {additionalParams, queryConverter} = props
      const dispatch = useDispatch()
      const {location} = useReactRouter()
      const user = useSelector((state) => state.session.currentUser)

      // アンマウント時のクリーンアップ
      useFetchEffect(
        () => () => {
          dispatch(destroy)
        },
        [dispatch]
      )

      // locationが更新されたら検索を実行
      useEffect(() => {
        const query = getCurrentQueryFromLocation(location, user)
        fetchData(queryConverter ? queryConverter(query) : query, additionalParams, dispatch)
      }, [dispatch, location, user]) // eslint-disable-line react-hooks/exhaustive-deps -- queryConverter, additionalParamsは通常変更しないため、意図的に無視

      // フォーム側から検索がトリガーされたら (≒ 検索条件が更新されたら)、locationに反映
      // それによって次のAPI呼び出しをトリガーする
      const handleSearch = (values: FormValues) => {
        const search = convertFormToQueryString(values)
        dispatch(push({...location, search}))
      }

      const query = getCurrentQueryFromLocation(location, user)
      return <SearchForm {...props} initialValues={convertQueryToForm(query)} handleSearch={handleSearch} />
    }
    SearchDetector.displayName = `SearchDetector(${SearchForm.displayName || SearchForm.name || 'Component'})`
    return SearchDetector
  }

export default searchDetector
