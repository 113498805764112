import React from 'react'
import styles from 'tm/employees/Parts.scss'
import {Panel, Item, Headding} from 'tm/employees/PrimaryPanel'
import _ from 'lodash'

export const Tag = ({children}) => <div className={styles.tag}>{children}</div>
export const Ul = ({children}) => <div className={styles.ul}>{children}</div>
export const Li = ({children}) => <div className={styles.li}>{children}</div>
export const Timelinedate = ({children}) => <div className={styles.timelinedate}>{children}</div>
export const Timelinecontent = ({children}) => <div className={styles.timelinecontent}>{children}</div>
export const Timeline = ({children}) => <div className={styles.timeline}>{children}</div>

export const TitleArea = ({children}) => <div className={styles.titlearea}>{children}</div>
export const Title = ({children}) => <div className={styles.title}>{children}</div>
export const Tr = ({children}) => <div className={styles.tr}>{children}</div>
export const Trthirds = ({children}) => <div className={styles.trthirds}>{children}</div>
export const Trhalf = ({children}) => <div className={styles.trhalf}>{children}</div>
export const Trfull = ({children}) => <div className={styles.trfull}>{children}</div>
export const Dl = ({children}) => <dl className={styles.dl}>{children}</dl>
export const Dt = ({children, className, width}) => (
  <dt className={[styles.dt, className].filter(_.identity).join(' ')} style={width && {width: `${width}%`}}>
    {children}
  </dt>
)
export const Dd = ({children, className, width}) => (
  <dd className={[styles.dd, className].filter(_.identity).join(' ')} style={width && {width: `${width}%`}}>
    {children}
  </dd>
)
export const Half = ({children}) => <div className={styles.half}>{children}</div>
export const Table = ({children}) => <table className={styles.infoTable}>{children}</table>
export const Pre = ({children}) => <span className={styles.pre}>{children}</span>

export const SubPanelarea = ({children}) => <span className={styles.subpanelarea}>{children}</span>
export const SubPanel = ({children}) => <Panel className={styles.subPanel}>{children}</Panel>
SubPanel.Headding = ({children, icon}) => (
  <Headding className={styles.subHeadding} icon={icon}>
    {children}
  </Headding>
)
SubPanel.Item = Item

export const ThirdPanel = ({children}) => <Panel className={styles.thirdPanel}>{children}</Panel>
ThirdPanel.Headding = ({children, icon}) => (
  <Headding className={styles.thirdHeadding} icon={icon}>
    {children}
  </Headding>
)
ThirdPanel.Item = Item

export const Wrap = ({children}) => <div className={styles.wrap}>{children}</div>
