import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {
  Wrap,
  TitleArea,
  Title,
  Timelinedate,
  Timelinecontent,
  Ul,
  Li,
  Dl,
  Trhalf,
  Trfull,
  Dd,
  Dt,
  SubPanelarea,
} from 'tm/employees/Parts'
import * as icons from 'jbc-front/components/icons'
import {WorkHistory} from 'jbc-front/components/icons'
import ShowMoreBtn from './ShowMoreBtn'

class EmploymentInfomation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }
  render() {
    const {employee, userDisplaySetting} = this.props
    const icon_name = getCategoryIconName(userDisplaySetting, 'work_history')
    const color =
      (!_.isNull(getCategoryIconColor(userDisplaySetting, 'work_history')) &&
        getCategoryIconColor(userDisplaySetting, 'work_history')) ||
      '#3498db'
    const IconName = icons[icon_name]
    const style = {color: color}
    return (
      (showCategory(userDisplaySetting, 'work_history') &&
        employee.employee_work_history &&
        employee.employee_work_history.length > 0 && (
          <Panel>
            <Item>
              <Wrap>
                <TitleArea>
                  <Title>
                    {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || (
                      <WorkHistory size={30} color={color} />
                    )}
                    <div style={style}>職歴</div>
                  </Title>
                </TitleArea>
                <SubPanelarea>
                  <Ul>
                    {employee.employee_work_history &&
                      (this.state.isExpand
                        ? employee.employee_work_history
                        : employee.employee_work_history.slice(0, 2)
                      ).map(
                        (work_history, index) =>
                          work_history && (
                            <Li key={index}>
                              <Timelinedate>
                                {recordDisplay(_.get(work_history, 'company_name'))}{' '}
                                {(_.get(work_history, 'join_date') &&
                                  `${recordDisplay.dateYearMonth(_.get(work_history, 'join_date'))} - `) ||
                                  ''}
                                {(_.get(work_history, 'leave_date') &&
                                  recordDisplay.dateYearMonth(_.get(work_history, 'leave_date'))) ||
                                  ''}
                              </Timelinedate>
                              {!_.get(work_history, 'position') && !_.get(work_history, 'business_content') ? null : (
                                <Timelinecontent>
                                  <Dl>
                                    {_.get(work_history, 'position') && (
                                      <Trhalf>
                                        <Dt>役職・職位</Dt>
                                        <Dd>{recordDisplay(_.get(work_history, 'position'))}</Dd>
                                      </Trhalf>
                                    )}
                                    {_.get(work_history, 'business_content') && (
                                      <Trfull>
                                        <Dt>業務内容</Dt>
                                        <Dd>{recordDisplay(_.get(work_history, 'business_content'))}</Dd>
                                      </Trfull>
                                    )}
                                  </Dl>
                                </Timelinecontent>
                              )}
                            </Li>
                          )
                      )}
                  </Ul>
                  {employee.employee_work_history && employee.employee_work_history.length > 2 && (
                    <ShowMoreBtn
                      isExpand={this.state.isExpand}
                      onToggleClick={() => {
                        this.setState({isExpand: !this.state.isExpand})
                      }}
                    />
                  )}
                </SubPanelarea>
              </Wrap>
            </Item>
          </Panel>
        )) ||
      null
    )
  }
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmploymentInfomation)
