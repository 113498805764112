// @ts-ignore
import sanitizeHtml from 'sanitize-html'
// @ts-ignore
import {allowedTags, allowedAttributes} from 'documentTemplates/list/utils'

interface Result {
  [key: string]: string[]
}

export const documentSanitizeHtml = (html?: string) => {
  if (!html) {
    return ''
  }
  const sanitized = sanitizeHtml(html, {
    allowedTags: allowedTags,
    allowedAttributes: allowedTags.reduce((hash: Result, tag: string) => {
      hash[tag] = allowedAttributes
      return hash
    }, {}),
  })

  return sanitized
}
