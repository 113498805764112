import React from 'react'
import useReactRouter from 'use-react-router'
import {gql} from '@apollo/client'
import {Link} from 'react-router-dom'
import {useNotify} from 'hooks/useNotify'
import {useQuery, useMutation} from 'components/Graphql'
import Form, {createRoleValue} from 'settings/roles/Form'
import LoadingPage from 'components/LoadingPage'
import styles from 'settings/roles/Edit.scss'

const ROLE = gql`
  query role($roleId: Int!) {
    client {
      id
      role(roleId: $roleId) {
        id
        name
        description
        notificationPermission
        searchTargets {
          id
          targetType
          targetSearchType
          talentSearchSettings {
            id
            targetType
            employmentStatus
            positionId
            employmentTypeId
            occupationId
            officeId
            groupId
          }
        }
        talentDisplaySettings {
          id
          talentDisplaySettingCategoryId
        }
        customEmployeeFieldGroupPermissions {
          id
          customEmployeeFieldGroupId
        }
      }
    }
  }
`

const UPDATE_ROLE = gql`
  mutation update_role($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      client {
        id
        roles {
          id
          name
        }
      }
    }
  }
`

const Content = ({id}) => {
  const notify = useNotify()
  const {history} = useReactRouter()
  const {data, loading, error} = useQuery(ROLE, {
    variables: {roleId: parseInt(id)},
    fetchPolicy: 'network-only',
  })
  const [mutation] = useMutation(UPDATE_ROLE)

  const updateRole = async ({
    name,
    description,
    notificationPermission,
    employmentStatuses,
    positionIds,
    employmentTypeIds,
    occupationIds,
    officeSearchType,
    officeIds,
    groupSearchType,
    groupIds,
    defaultCategoryIds,
    customCategoryIds,
  }) => {
    await mutation({
      variables: {
        input: {
          id,
          name,
          description,
          notificationPermission,
          employmentStatuses,
          positionIds,
          employmentTypeIds,
          occupationIds,
          officeSearchType,
          officeIds: officeSearchType === 'get_select' ? officeIds.split(',') : [],
          groupSearchType,
          groupIds: groupSearchType === 'get_select' ? groupIds.split(',') : [],
          defaultCategoryIds,
          customCategoryIds,
        },
      },
    })
    notify('権限を更新しました', 'success')
    history.push('/settings/roles/list')
  }

  if (loading) {
    return <LoadingPage />
  }
  if (error) {
    return null
  }

  return <Form initialValues={createRoleValue(data.client.role)} onSubmit={updateRole} />
}

const Edit = ({
  match: {
    params: {id},
  },
}) => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">閲覧権限設定</h1>
    </div>
    <div className="l-wrap-xxl l-contents-wrap">
      <div className={styles.contentsHead}>
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/talent_display" className="l-breadcrumb-link">
            情報公開設定
          </Link>
          <span className="l-breadcrumb-here">閲覧権限設定</span>
        </div>
      </div>
      <Content id={id} />
    </div>
  </div>
)

export default Edit
