import React, {Component} from 'react'
import {connect} from 'react-redux'
import api from 'api'
import styles from 'offices/List.scss'
import {Delete as DeleteIcon} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/Modal'
import {withApollo} from '@apollo/client/react/hoc'
import {HAS_BRANCH_OFFICE} from 'hooks/useHasBranchOffice'
import {REFETCH_QUERIES_ON_UPDATE_OFFICE} from 'features/offices/OfficeSelector'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Delete extends Component {
  state = {
    showModal: false,
  }

  handleDeleteClick = async () => {
    const {dispatch, token, id, onRequestFinish, client} = this.props
    try {
      await api.createWithAuth(token).offices.delete(id)
      dispatch(notifySuccess('削除しました'))
      onRequestFinish()
      client.refetchQueries({include: [HAS_BRANCH_OFFICE, ...REFETCH_QUERIES_ON_UPDATE_OFFICE]})
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  render() {
    return (
      <div>
        <Modal
          header="削除の確認"
          body="適用事業所を削除しますか"
          onSubmit={this.handleDeleteClick}
          isOpen={this.state.showModal}
          hideModal={() => this.setState({showModal: false})}
          submit="はい"
          cancel="いいえ"
        />
        <DeleteIcon size={20} className={styles.icon} onClick={() => this.setState({showModal: true})} />
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
}))(withApollo(Delete))
