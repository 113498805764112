import React from 'react'
import moment from 'moment'
import DateTime from 'react-datetime'
import {formValueSelector, FormSection, FieldArray, autofill as autofillForm, Field} from 'redux-form'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {connect} from 'react-redux'
import ActionButton from 'jbc-front/components/ActionButton'
import {makeRowsData, ONE_PAGE_ROWS, TWO_PAGE_ROWS} from 'reportForms/r60Utils'
import defaults from 'lodash/fp/defaults'
import {RadioField, TextField, dateFieldProps} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {wage} from 'validators'
import styles from 'reportForms/R60Report.scss'

const formName = 'R60Report'
const selector = formValueSelector(formName)
const autofill = autofillForm.bind(null, formName)

const InsuredPersonTypes = [
  {value: 'normal', label: '一般被保険者'},
  {value: 'short_time_employment', label: '短期雇用特例被保険者'},
]

const renderDateTimeTd = ({input, disabled, meta: {touched, error}, ...rest}) => (
  <td {...(touched && error ? {className: 'invalid ' + styles.date} : {className: styles.date})}>
    {disabled ? (
      <input disabled value={input.value} />
    ) : (
      <DateTime
        value={input.value}
        style={{width: '85px'}}
        onChange={(param) => input.onChange(param)}
        onBlur={() => {
          input.onBlur(input.value)
        }}
        dateFormat={'YYYY/MM/DD'}
        timeFormat={false}
        {...rest}
      />
    )}
  </td>
)

const renderTd = ({input, disabled, meta: {touched, error}}) => (
  <td {...(touched && error ? {className: 'invalid ' + styles.def} : {className: styles.def})}>
    <input {...input} disabled={disabled} />
  </td>
)

const renderRemarksTd = ({input, disabled, meta: {touched, error}}) => (
  <td {...(touched && error ? {className: 'invalid ' + styles.remarks} : {className: styles.remarks})}>
    <input {...input} disabled={disabled} />
  </td>
)

const isValidWage = (value) =>
  (_.isNumber(value) && value >= 0 && _.isFinite(value)) || (_.isString(value) && isValidWage(+value))

const Row = ({name, index, insuredPersonType, monthlyWage, hourlyOrDailyWage}) => (
  <FormSection name={name} component="tr">
    <Field
      component={renderDateTimeTd}
      name="insured_term_start_on"
      disabled={insuredPersonType === 'short_time_employment'}
      {...dateFieldProps}
    />
    {index === 0 ? (
      <td>離職日</td>
    ) : (
      <Field
        component={renderDateTimeTd}
        type="text"
        name="insured_term_end_on"
        disabled={insuredPersonType === 'short_time_employment'}
        {...dateFieldProps}
      />
    )}
    {index === 0 ? (
      <td>離職月</td>
    ) : (
      <Field
        component={renderTd}
        type="text"
        name="month_of_short_term_employee"
        disabled={insuredPersonType === 'normal'}
      />
    )}
    <Field component={renderTd} type="text" name="insured_base_date_num" />
    <Field component={renderDateTimeTd} type="text" name="payment_term_start_on" {...dateFieldProps} />
    {index === 0 ? (
      <td>離職日</td>
    ) : (
      <Field component={renderDateTimeTd} type="text" name="payment_term_end_on" {...dateFieldProps} />
    )}
    <Field component={renderTd} type="text" name="payment_base_date_num" />
    <Field component={renderTd} type="text" name="monthly_wage" validate={wage} />
    <Field component={renderTd} type="text" name="hourly_or_daily_wage" validate={wage} />
    <td>
      {isValidWage(monthlyWage) || isValidWage(hourlyOrDailyWage)
        ? +(isValidWage(monthlyWage) ? monthlyWage : 0) + +(isValidWage(hourlyOrDailyWage) ? hourlyOrDailyWage : 0)
        : ''}
    </td>
    <Field component={renderRemarksTd} type="text" name="notes" />
  </FormSection>
)

const ConnectedRow = connect((state, ownProps) => {
  const paymentData = selector(state, 'r60_payment_param_sets')
  const {index} = ownProps
  return paymentData
    ? {
        monthlyWage: paymentData[index].monthly_wage,
        hourlyOrDailyWage: paymentData[index].hourly_or_daily_wage,
      }
    : {}
})(Row)

const renderRemunerationStatus = ({fields, insuredPersonType}) => (
  <tbody>
    {fields.map((field, index) => (
      <ConnectedRow name={field} index={index} key={index} insuredPersonType={insuredPersonType} />
    ))}
  </tbody>
)

const RemunerationStatus = connect((state) => ({
  insuredPersonType: selector(state, 'insured_person_type'),
}))(renderRemunerationStatus)

const PaymentTable = ({
  procedureStatus: {employee},
  addPage,
  paymentDataCount,
  insuredPersonType,
  removePage,
  handleInsuredPersonTypeChange,
  recalc,
  importFromCsv,
}) => (
  <div>
    <RadioField
      name="insured_person_type"
      label="被保険者区分"
      options={InsuredPersonTypes}
      onChange={handleInsuredPersonTypeChange}
    />
    <div className="l-overflow-scroll">
      <table className={styles.paymentTable}>
        <thead>
          <tr>
            <th colSpan={3}>被保険者期間算定対象期間</th>
            <th rowSpan={3}>賃金支払基礎日数</th>
            <th rowSpan={3} colSpan={2}>
              賃金支払対象期間
            </th>
            <th rowSpan={3}>基礎日数</th>
            <th rowSpan={2} colSpan={3}>
              賃金額（円）
            </th>
            <th rowSpan={3}>備考</th>
          </tr>
          <tr>
            <th colSpan={2}>(A)一般被保険者等</th>
            <th rowSpan={2}>(B)短期雇用特例被保険者</th>
          </tr>
          <tr>
            <th>離職日の翌日</th>
            <th>{employee && employee.retired_at && moment(employee.retired_at).add(1, 'day').format('MM/DD')}</th>
            <th>A</th>
            <th>B</th>
            <th>計</th>
          </tr>
        </thead>
        <FieldArray name="r60_payment_param_sets" component={RemunerationStatus} />
        <LabelMapper name="r60_payment_param_sets" label="給与情報" />
        <LabelMapper name="monthly_wage" label="賃金額" />
        <LabelMapper name="hourly_or_daily_wage" label="賃金額" />
        <LabelMapper name="insured_term_start_on" label="被保険者期間算定対象期間" />
        <LabelMapper name="insured_term_end_on" label="被保険者期間算定対象期間" />
        <LabelMapper name="payment_term_start_on" label="賃金支払対象期間" />
        <LabelMapper name="payment_term_end_on" label="賃金支払対象期間" />
        <LabelMapper name="insured_base_date_num" label="被保険者期間算定対象期間基礎日数" />
        <LabelMapper name="payment_base_date_num" label="賃金支払対象期間基礎日数" />
        <LabelMapper name="notes" label="備考" />
      </table>
    </div>
    <div className="u-mb40">
      {employee &&
        paymentDataCount <
          makeRowsData(employee, TWO_PAGE_ROWS, insuredPersonType === 'short_time_employment').length && (
          <ActionButton
            onClick={addPage}
            disabled={employee.retired_at === null}
            disabledReason={'退職日を設定してください'}
          >
            ページを追加
          </ActionButton>
        )}
      {paymentDataCount > ONE_PAGE_ROWS && <ActionButton onClick={removePage}>ページを削除</ActionButton>}
      <ActionButton
        onClick={recalc}
        disabled={employee && employee.retired_at === null}
        disabledReason={'退職日を設定してください'}
        className="u-ml10"
      >
        日付を再計算する
      </ActionButton>
      <ActionButton
        onClick={importFromCsv}
        disabled={employee && employee.retired_at === null}
        disabledReason={'退職日を設定してください'}
        className="u-ml10"
      >
        日数・賃金額の一括登録
      </ActionButton>
    </div>
    <TextField name="special_report" label="賃金に関する特記事項" />
  </div>
)

export default connect(
  (state) => ({
    paymentDataCount: selector(state, 'r60_payment_param_sets') ? selector(state, 'r60_payment_param_sets').length : 0,
    insuredPersonType: selector(state, 'insured_person_type'),
    procedureStatus: state.procedureStatuses.current.data,
  }),
  (dispatch) => ({
    addPage() {
      dispatch((dispatch, getState) => {
        const state = getState()
        const paymentData = selector(state, 'r60_payment_param_sets')
        const insuredPersonType = selector(state, 'insured_person_type')
        const {employee} = state.procedureStatuses.current.data
        const data = makeRowsData(employee, TWO_PAGE_ROWS, insuredPersonType === 'short_time_employment')
        dispatch(autofill('r60_payment_param_sets', _.zipWith(data, paymentData, defaults)))
      })
    },
    removePage() {
      dispatch((dispatch, getState) => {
        const state = getState()
        const paymentData = selector(state, 'r60_payment_param_sets')
        dispatch(autofill('r60_payment_param_sets', paymentData.slice(0, ONE_PAGE_ROWS)))
      })
    },
    handleInsuredPersonTypeChange(e, value) {
      dispatch((dispatch, getState) => {
        const state = getState()
        const paymentData = selector(state, 'r60_payment_param_sets')
        if (value === 'short_time_employment') {
          dispatch(
            autofill(
              'r60_payment_param_sets',
              paymentData.map((data) => ({...data, insured_term_start_on: null, insured_term_end_on: null}))
            )
          )
        } else {
          const {employee} = state.procedureStatuses.current.data
          if (!employee || !employee.office) {
            return
          }
          const data = makeRowsData(employee, paymentData.length, false).map((data) =>
            _.pick(data, ['insured_term_start_on', 'insured_term_end_on'])
          )
          dispatch(
            autofill(
              'r60_payment_param_sets',
              _.zipWith(
                paymentData.map((data) => ({...data, month_of_short_term_employee: null})),
                data,
                defaults
              )
            )
          )
        }
      })
    },
    recalc() {
      dispatch((dispatch, getState) => {
        const state = getState()
        const paymentData = selector(state, 'r60_payment_param_sets')
        const insuredPersonType = selector(state, 'insured_person_type')
        const {employee} = state.procedureStatuses.current.data
        if (!employee || !employee.office) {
          return
        }
        const data = makeRowsData(
          employee,
          paymentData.length > ONE_PAGE_ROWS ? TWO_PAGE_ROWS : ONE_PAGE_ROWS,
          insuredPersonType === 'short_time_employment'
        )
        dispatch(autofill('r60_payment_param_sets', _.zipWith(paymentData, data, defaults).slice(0, data.length)))
      })
    },
    importFromCsv() {
      dispatch((dispatch, getState) => {
        const state = getState()
        const {id} = state.procedureStatuses.current.data
        dispatch(push(`/procedure_statuses/${id}/r60_import_payments`))
      })
    },
  })
)(PaymentTable)
