import {FC, ReactNode} from 'react'

interface MainTitleProps {
  children?: ReactNode
}

export const MainTitle: FC<MainTitleProps> = ({children}) => <h1 className="m-title-main">{children}</h1>

interface DescriptionProps {
  children?: ReactNode
}

export const Description: FC<DescriptionProps> = ({children}) => <p className="m-title-main-note">{children}</p>
