import Inbox from 'tm/notifications/Inbox'
import InboxAll from 'tm/notifications/InboxAll'
import Sent from 'tm/notifications/Sent'
import SentAll from 'tm/notifications/SentAll'
import Shared from 'tm/notifications/Shared'
import SharedAll from 'tm/notifications/SharedAll'
import Create from 'tm/notifications/Create'
import Show from 'tm/notifications/Show'
import Answer from 'tm/notifications/Answer'

export default {
  Inbox,
  InboxAll,
  Sent,
  SentAll,
  Shared,
  SharedAll,
  Create,
  Show,
  Answer,
}
