import {useState} from 'react'
import {useDispatch} from 'react-redux'
import NProgress from 'nprogress'
import {saveAs} from 'file-saver'
import {AxiosResponse} from 'axios'
import {notifyError} from 'store/actions/notify'

// @ts-ignore
import readBlob from 'readBlob'

export type FileResponseDataType = string | Blob

export const useFileDownload = <T extends FileResponseDataType = any>() => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)

  const download = async (getter: () => Promise<AxiosResponse<T>>) => {
    NProgress.start()
    setLoading(true)
    try {
      const res = await getter()

      const blob = new Blob([res.data], {type: res.headers['content-type']})

      const filename = decodeURIComponent(res.headers['content-disposition'].match(/filename\*=UTF-8''(.*)/)[1])
      saveAs(blob, filename)
    } catch (error) {
      const blob = new Blob([error.response.data])

      // MEMO: readBlobに型がないので err: anyを許容
      await readBlob(blob)
        .json()
        .then((err: any) => {
          const message = err?._errors?._base[0]
          if (message) {
            dispatch(notifyError(message))
          } else {
            dispatch(notifyError('ファイルのダウンロードに失敗しました。'))
          }
        })
        .catch(() => {
          dispatch(notifyError('ファイルのダウンロードに失敗しました。'))
        })

      throw error
    } finally {
      NProgress.done()
      setLoading(false)
    }
  }

  return {download, loading}
}
