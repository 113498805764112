import React from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import Button from 'jbc-front/components/Button'
import {DeleteSquare} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import _ from 'lodash'
import {bindActionCreators} from 'redux'
import {actionCreators} from 'actions'
import styles from 'settings/clientRoles/EmployeeList.scss'

const Delete = ({showModal, hideModal, isModalOpen, selectedUsers, changeSelected}) => (
  <React.Fragment>
    <a className={_.some(selectedUsers) ? styles.multiLink : styles.multiLinkDisabled} onClick={showModal}>
      <DeleteSquare className="u-mr5" />
      チェックを入れたアカウントを削除
    </a>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      <Modal.Header hideModal={hideModal}>アカウント削除</Modal.Header>
      <Modal.Body>
        選択された{_.filter(selectedUsers).length}個のアカウントを削除します。
        <br />
        <br />
        ※ アカウントを削除しても従業員情報は削除されません。
        <br />
        入力依頼中の場合、依頼が取り消しになりますのでご注意ください。
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button
            primary
            onClick={() => {
              changeSelected([selectedUsers, {_delete: true}])
              hideModal()
            }}
          >
            削除
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  </React.Fragment>
)

export default compose(
  connect(
    (state) => ({
      selectedUsers: state.settings.usersClients.selected,
    }),
    bindActionCreators.bind(null, actionCreators.settings.usersClients)
  ),
  withModal
)(Delete)
