import {saveAs} from 'file-saver'
import axios from 'axios'
import NProgress from 'nprogress'

const downloadFile = (url, token, asyncError, params = {}, usePost = false) => {
  NProgress.start()
  const p = usePost
    ? axios.post(url, params, {
        responseType: 'blob',
        headers: {Authentication: token},
      })
    : axios.get(url, {
        responseType: 'blob',
        headers: {Authentication: token},
        params,
      })
  return p
    .then((res) => {
      saveAs(res.data, decodeURIComponent(res.headers['content-disposition'].match(/filename\*=UTF-8''(.*)/)[1]))
    })
    .catch(asyncError)
    .finally(() => {
      NProgress.done()
    })
}

export default downloadFile
