import {FC, useState, useEffect} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {DOCUMENT_VISIVILITIES} from 'consts/document'
import {Search, CREATE_UPLOADED_DOCUMENT, EMPLOYEE_DOCUMENTS} from '../../query'
import {Label, Required} from 'jbc-front/components/presenters/form/Label'
import {Input} from 'jbc-front/components/presenters/form/Input'
import {FileInput} from 'jbc-front/components/presenters/form/FileInput'
import {Radio, RadioContainer} from 'jbc-front/components/presenters/form/Radio'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {ErrorMessage} from 'jbc-front/components/presenters/form/ErrorMessage'
import {Hint} from 'jbc-front/components/presenters/ui/Hint'
import {Note} from 'jbc-front/components/presenters/ui/Note'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'
import classnames from 'classnames'
import styles from './FileUploadModal.scss'

// @ts-ignore
import {useMutation} from 'components/Graphql'

export type FileUploadModalProps = {
  isOpen: boolean
  onClose: () => void
  search: Search
  employeeId: number
}

type Visibility = keyof typeof DOCUMENT_VISIVILITIES
type IsPublished = 'publish' | 'unpublish'

type Schema = {
  employeeId: number
  title: string
  visibility: Visibility
  isPublished: IsPublished
  comment?: string
  file: File
}

export const FileUploadModal: FC<FileUploadModalProps> = ({isOpen, onClose, search, employeeId}) => {
  const [uploadDocument, {loading}] = useMutation(CREATE_UPLOADED_DOCUMENT)
  const [file, setFile] = useState<File>()
  const {
    register,
    handleSubmit,
    reset,
    resetField,
    setValue,
    watch,
    formState: {errors},
  } = useForm<Schema>({
    mode: 'onChange',
    defaultValues: {
      employeeId: employeeId,
      title: '',
      isPublished: 'unpublish',
      visibility: 'private',
    },
    resolver: yupResolver(
      yup.object().shape({
        employeeId: yup.number().required(),
        title: yup.string(),
        visibility: yup.mixed<Visibility>().required('公開範囲を選択してください'),
        isPublished: yup.mixed<IsPublished>().required(),
        comment: yup.string(),
        file: yup.mixed<File>().required('ファイルを入力してください'),
      })
    ),
  })
  const isPublished = watch('isPublished', 'unpublish')

  const handleClose = () => {
    setFile(undefined)
    reset()
    onClose()
  }

  const handleUpload: SubmitHandler<Schema> = async (data) => {
    await uploadDocument({
      variables: {
        input: {
          employeeId: data.employeeId,
          document: {
            title: data.title || data.file.name,
            visibility: data.visibility,
            file: data.file,
          },
          isPublished: data.isPublished == 'publish',
          comment: data.comment,
        },
      },
      refetchQueries: [
        {
          query: EMPLOYEE_DOCUMENTS,
          variables: {id: Number(employeeId), search},
          fetchPolicy: 'cache-and-network',
        },
      ],
    })

    handleClose()
  }

  const onDrop = (files: File[], _f: unknown, _e: unknown) => {
    if (files[0]) {
      setFile(files[0])
    }
  }

  const onRemoveFile = () => {
    setFile(undefined)
  }

  useEffect(() => {
    if (file) {
      setValue('file', file)
    } else {
      resetField('file')
    }
  }, [file])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className={styles.modal}>
      <Modal.Header onClose={handleClose}>アップロードファイル選択</Modal.Header>
      <Modal.Body className={styles.body}>
        <form onSubmit={handleSubmit(handleUpload)}>
          <Label>
            ファイルアップロード
            <Spacer direction="x" size={8} />
            <Required />
            <Spacer direction="x" size={8} />
          </Label>
          <Spacer direction="y" size={8} />
          <FileInput onDrop={onDrop} {...register('file')} placeholder="ファイルを選択" />
          {errors.file?.message && <ErrorMessage>{errors.file.message}</ErrorMessage>}
          <Note>ファイルの最大容量は10MBまでになります。</Note>
          {file && (
            <>
              <Spacer direction="y" size={20} />
              <FileInput.File file={file} onRemove={onRemoveFile} />
            </>
          )}

          <Spacer direction="y" size={20} />

          <Label>書類名</Label>
          <Spacer direction="y" size={8} />
          <Input {...register('title')} isError={false} />
          {errors.title?.message && <ErrorMessage>{errors.title.message}</ErrorMessage>}

          <Spacer direction="y" size={20} />

          <Label>公開範囲</Label>
          <Spacer direction="y" size={8} />
          <RadioContainer>
            <Radio {...register('visibility')} value="private">
              自分のみ
            </Radio>
            <Radio {...register('visibility')} value="all_admins">
              管理者全体
            </Radio>
          </RadioContainer>
          {errors.visibility?.message && <ErrorMessage>{errors.visibility.message}</ErrorMessage>}

          <Spacer direction="y" size={20} />

          <Label>マイページ公開</Label>
          <Spacer direction="y" size={8} />
          <RadioContainer>
            <Radio {...register('isPublished')} value={'unpublish'}>
              公開しない
            </Radio>
            <Radio {...register('isPublished')} value={'publish'}>
              公開する
            </Radio>
          </RadioContainer>
          {errors.visibility?.message && <ErrorMessage>{errors.visibility.message}</ErrorMessage>}

          <Spacer direction="y" size={20} />

          {isPublished == 'publish' && (
            <>
              <p className="u-mb15">
                公開するとマイページ登録済の従業員はマイページから書類を閲覧できるようになります。書類を公開しますか？
                <br />
                <span className={styles.caution}>
                  マイページ未招待の従業員は、招待後に登録手続きを完了することで書類を閲覧できるようになります
                </span>
              </p>
              <Label className={classnames(styles.label, 'u-mb5')}>
                コメント
                <Hint leftPoint={-10}>メール本文にコメントを記載できます</Hint>
              </Label>
              <Textarea {...register('comment')} isError={!!errors.comment?.message} className={styles.textarea} />
              <div>最大2000文字</div>
              {errors.comment?.message && <ErrorMessage>{errors.comment.message}</ErrorMessage>}

              <Spacer direction="y" size={20} />
            </>
          )}

          <p>
            <span className={styles.bold}>【注意】</span>
            <br />
            1従業員にアップロードできるのは99件までです
          </p>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button primary disabled={loading} onClick={handleSubmit(handleUpload)}>
            アップロード
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  )
}
