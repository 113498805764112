import React from 'react'
import api from 'api'
import {useSelector, useDispatch} from 'react-redux'
import {push} from 'connected-react-router'
import EmployeesList from 'procedures/EmployeesList'

const ChooseEmployee = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const selectEmployee = (employeeId) => {
    Promise.all(
      ['start_maternity', 'extend_maternity'].map((procedure_type) =>
        api
          .createWithAuth(token)
          .procedureStatuses.list({procedure_type: procedure_type, employee_id: employeeId}, [
            'employee',
            'procedure_maternity_datum',
          ])
      )
    )
      .then((all) => ({data: all.map(({data}) => data).flat()}))
      .then(({data}) => {
        if (data.length > 0) {
          dispatch(push(`/finish_maternity/choose_use_or_create?employee_id=${employeeId}`))
        } else {
          dispatch(push(`/finish_maternity/detail?employee_id=${employeeId}`))
        }
      })
  }
  const searchFormQueryConverter = (query) => ({
    ...query,
    _added_fields: [...query._added_fields, 'genders'],
    genders: ['woman'],
  })
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">従業員を選択してください</h1>
      </div>
      <div className="l-wrap-xl">
        <EmployeesList
          handleClick={(employeeId) => {
            selectEmployee(employeeId)
          }}
          searchFormAddedFieldHideValues={['genders']}
          searchFormQueryConverter={searchFormQueryConverter}
        />
      </div>
    </div>
  )
}

export default ChooseEmployee
