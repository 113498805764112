import {Card, Container} from 'components/SelectCard'
import {DependentAdd, DependentDelete} from 'jbc-front/components/icons'
import {useSelector, useDispatch} from 'react-redux'
import useReactRouter from 'use-react-router'
import {parse} from 'query-string'
import {push} from 'connected-react-router'
import {isAdminSelector} from '../../utils'

const ChooseAddOrRemove = () => {
  const {search, pathname} = useReactRouter().location
  const dispatch = useDispatch()
  const {isAdmin, currentUserEmployeeId} = useSelector((state) => ({
    isAdmin: isAdminSelector(state),
    currentUserEmployeeId: state.session.currentUser.employee?.id,
  }))

  const isEmployeeInput = !isAdmin || pathname.includes('/mypage/')
  const employeeId = isAdmin ? parse(search).employee_id : currentUserEmployeeId

  const onClickAddDependent = async () => {
    const path = isEmployeeInput
      ? `/mypage/add_dependents/choose_new_or_exists`
      : `/add_dependents/choose_new_or_exists?employee_id=${employeeId}`
    dispatch(push(path))
  }

  const onClickRemoveDependent = async () => {
    const path = isEmployeeInput
      ? '/mypage/remove_dependents/employee_input'
      : `/remove_dependents/detail?employee_id=${employeeId}`
    dispatch(push(path))
  }

  return (
    <>
      <div className="l-title-wrap">
        <h1 className="m-title-main">扶養の変更の種類を選択</h1>
        <p className="m-title-main-note">扶養の変更の種類を選んでください</p>
      </div>
      <Container>
        <Card icon={DependentAdd} onClick={onClickAddDependent}>
          扶養の追加
        </Card>
        <Card icon={DependentDelete} onClick={onClickRemoveDependent}>
          扶養の削除
        </Card>
      </Container>
    </>
  )
}

export default ChooseAddOrRemove
