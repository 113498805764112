import {FC, MouseEventHandler} from 'react'
import styles from './UserMenu.scss'
import {Pulldown, User} from 'jbc-front/components/icons'

// @ts-ignore
import DropDownMenu from 'components/DropDownMenu'

interface UserProps {
  name: string
  menuItems?: MenuItem[]
}

// FIXME: DropDownMenuが持つべき型定義なので、そちらに移したい
export interface MenuItem {
  label: string
  description?: string
  style?: string
  onClick?: () => void
}

export const UserMenu: FC<UserProps> = ({name, menuItems = []}) => {
  interface ToggleProps {
    onClick: MouseEventHandler<HTMLAnchorElement>
  }
  const toggle: FC<ToggleProps> = ({onClick}) => (
    <a onClick={onClick}>
      <User size={30} />
      <span className={styles.email}>{name}&nbsp;様&nbsp;</span>
      <span className={styles.pulldown}>
        <Pulldown size={10} />
        &nbsp;
      </span>
    </a>
  )

  return (
    <div className={styles.user}>
      {menuItems.length > 0 ? (
        <DropDownMenu toggle={toggle} menuItems={menuItems} />
      ) : (
        <>
          <User size={30} />
          <span className={styles.email}>{name}&nbsp;様&nbsp;</span>
        </>
      )}
    </div>
  )
}
