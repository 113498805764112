import React from 'react'
import styles from 'components/SelectCard.scss'
import {Link} from 'react-router-dom'

export const Card = ({icon, children, to, onClick}) => {
  const Icon = icon
  const Card = to ? Link : 'div'
  return (
    <Card className={styles.base} to={to} onClick={onClick}>
      <Icon size={40} className={styles.icon} />
      <div className={styles.button}>{children}</div>
    </Card>
  )
}

export const Container = ({children, ...rest}) => (
  <div {...rest} className={styles.container}>
    {children}
  </div>
)
