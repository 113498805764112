import {MYPAGE_PATHS} from 'consts/paths'
import {RouteParams} from 'types/routes'
import {Index} from 'pages/withAuth/mypage/documents/index'

export const DocumentRoutes: RouteParams[] = [
  {
    key: 'documents',
    path: MYPAGE_PATHS.DOCUMENTS.INDEX,
    component: Index,
    exact: true,
    whiteList: ['member'],
  },
]
