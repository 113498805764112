import {useState, useCallback} from 'react'
import useReactRouter from 'use-react-router'

type DisplayType = 'default' | 'compact'

const storageKey = (pathname: string, userId: string) =>
  `${pathname.replace(/\//g, '')}#${userId}__employee_display_type`

const getSavedDisplayType = (pathname: string, userId: string): DisplayType | undefined => {
  const type = window.localStorage?.getItem(storageKey(pathname, userId))
  if (type && type !== '') {
    return type as DisplayType
  }
  return undefined
}

const saveDisplayType = (pathname: string, userId: string, displayType: DisplayType) => {
  window.localStorage?.setItem(storageKey(pathname, userId), displayType)
}

export const useDisplayType = (userId: string, defaultValue: DisplayType) => {
  const {
    location: {pathname},
  } = useReactRouter()
  const [current, setCurrent] = useState(getSavedDisplayType(pathname, userId) || defaultValue)
  const setValue = useCallback(
    (newValue: DisplayType) => {
      setCurrent(newValue)
      saveDisplayType(pathname, userId, newValue)
    },
    [pathname, userId]
  )
  return [current, setValue]
}
