import List from 'documentTemplates/List'
import Create from 'documentTemplates/Create'
import Edit from 'documentTemplates/Edit'
import Sample from 'documentTemplates/Sample'

export default {
  List,
  Create,
  Edit,
  Sample,
}
