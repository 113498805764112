import {FC, useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {push} from 'connected-react-router'
import * as yup from 'yup'
import _ from 'lodash'
import fp from 'lodash/fp'
import classNames from 'classnames'
import Button from 'jbc-front/components/Button'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import {Required} from 'jbc-front/components/presenters/form/Label'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Radio, RadioContainer} from 'jbc-front/components/presenters/form/Radio'
import {Input} from 'jbc-front/components/presenters/form/Input'
import {Checkbox} from 'jbc-front/components/presenters/form/Checkbox'
import {ErrorMessage} from 'jbc-front/components/presenters/form/ErrorMessage'
import {useSelector} from 'hooks/redux'
import {useFetchProcedureCsvSerialNumbers} from 'hooks/api/exportProcedureCsv'
import {ProcedureKind} from 'types/api/csvProcedureStatuses/procedureKind'
import {ProcedureStatus} from 'types/api/procedureStatuses/procedureStatus'
import {
  useDownloadProcedureCsv,
  usePostProcedureCsvRequest,
  Params as CsvDownloadSchema,
} from 'hooks/api/exportProcedureCsv'
import styles from './CsvDownloadModal.scss'

// @ts-ignore
import {isLimitedAdminSelector} from 'utils'
// @ts-ignore
import {actionCreators} from 'actions'

const MAX_DOWNLOAD_COUNT = 400

type CsvDownloadModalProps = {
  selected: {[key: string]: string}
  procedureKind: ProcedureKind
  procedureStatuses: ProcedureStatus[]
}

const insuranceTypes = {
  social_insurance: '社会保険',
  employment_insurance: '雇用保険',
}

const insuranceTypeByProcedureKind = (procedureKind: ProcedureKind) => {
  switch (procedureKind) {
    case 'r1':
    case 'r4':
    case 'r5':
    case 'r6':
    case 'r7':
      return 'social_insurance'
    case 'r57':
    case 'r58':
    case 'r60':
      return 'employment_insurance'
  }
}

const schema = yup.object().shape({
  procedure_kind: yup.mixed<ProcedureKind>().required(),
  procedure_status_ids: yup.array().required(),
  serial_number: yup
    .string()
    .test(
      'isSerialNumber',
      '001〜999までの数字を入力してください。',
      (value) => !!value && value !== '000' && /^\d{3}$/.test(value)
    ),
  show_my_number: yup.bool(),
  submission_destination: yup
    .string()
    .when('$insuranceType', (insuranceType, schema) =>
      insuranceType === 'social_insurance'
        ? schema
            .oneOf(['pension_office', 'health_insurance_office'], '提出先は年金事務所か健康保険組合を選択してください')
            .required()
        : schema
    ),
})

export const CsvDownloadModal: FC<CsvDownloadModalProps> = ({selected, procedureKind, procedureStatuses}) => {
  const selectedCount = fp.reduce((sum, value) => (value ? sum + 1 : sum), 0, selected)
  const downloadable = selectedCount > 0
  const procedureStatusIds = _.keys(_.pickBy(selected))
  const insuranceType = insuranceTypeByProcedureKind(procedureKind)
  const client = useSelector((state) => state.client.current)
  const isLimitedAdmin = useSelector(isLimitedAdminSelector)
  const download = useDownloadProcedureCsv()
  const postCsvRequest = usePostProcedureCsvRequest()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [showHint, setShowHint] = useState(false)
  const [onlyPensionOffice, setOnlyPensionOffice] = useState(false)
  const [onlyHealthInsuranceOffice, setOnlyHealthInsuranceOffice] = useState(false)
  const {resource: procedureCsvSerialNumbers, getter: serialNumbersGetter} = useFetchProcedureCsvSerialNumbers(
    client.id
  )

  const makeDefaultValues = (): CsvDownloadSchema => {
    const values = {
      procedure_kind: procedureKind,
      procedure_status_ids: [],
      serial_number: '',
      show_my_number: false,
    }

    return insuranceType === 'social_insurance' ? {...values, submission_destination: 'pension_office'} : values
  }

  const {
    handleSubmit: handleReactHookFormSubmit,
    register,
    setValue,
    watch,
    trigger,
    reset,
    formState: {errors, isSubmitting, isValid},
  } = useForm<CsvDownloadSchema>({
    resolver: yupResolver(schema),
    context: {insuranceType},
    mode: 'onChange',
    defaultValues: makeDefaultValues(),
  })
  const submissionDestination = watch('submission_destination')

  const handleClose = () => {
    setShowHint(false)
    setOnlyPensionOffice(false)
    setOnlyHealthInsuranceOffice(false)
    reset()
    setIsOpen(false)
  }
  const handleSubmit = async (data: CsvDownloadSchema) => {
    if (procedureStatusIds.length >= MAX_DOWNLOAD_COUNT) {
      try {
        await postCsvRequest({...data, procedure_status_ids: procedureStatusIds})
        dispatch(actionCreators.notifySuccess('手続きデータはメールにてお送りします'))
        dispatch(push('/procedure_statuses'))
      } catch (err) {
        dispatch(actionCreators.asyncError(err))
      }
    } else {
      await download({...data, procedure_status_ids: procedureStatusIds})
      serialNumbersGetter()
    }
    handleClose()
  }

  useEffect(() => {
    if (isOpen) {
      if (procedureKind === 'r4' || procedureKind === 'r6') {
        setOnlyPensionOffice(true)
      } else if (procedureKind === 'r5') {
        setOnlyHealthInsuranceOffice(true)
        setValue('submission_destination', 'health_insurance_office')
      }
      serialNumbersGetter()
    }
  }, [isOpen])

  useEffect(() => {
    const destinationType =
      insuranceType === 'social_insurance' ? submissionDestination || 'pension_office' : 'employment_insurance'
    if (procedureCsvSerialNumbers) {
      setValue('serial_number', procedureCsvSerialNumbers[destinationType])
      trigger('serial_number')
    }
  }, [procedureCsvSerialNumbers])

  useEffect(() => {
    if (submissionDestination && procedureCsvSerialNumbers) {
      setValue('serial_number', procedureCsvSerialNumbers[submissionDestination])
      trigger('serial_number')
    }
  }, [submissionDestination])

  const healthInsuranceTypeByEmployee = (id: number) => {
    const procedureStatus = procedureStatuses.find((procedureStatus) => procedureStatus && procedureStatus.id === id)
    return procedureStatus && procedureStatus.employee.office.health_insurance_type
  }

  useEffect(() => {
    if (procedureStatusIds.length === 0) return

    if (['r1', 'r7'].includes(procedureKind)) {
      const healthInsuranceTypes = [
        ...new Set(
          procedureStatusIds.map((procedureStatusId) => healthInsuranceTypeByEmployee(Number(procedureStatusId)))
        ),
      ]

      if (healthInsuranceTypes.length > 1) {
        setShowHint(true)
        setOnlyPensionOffice(true)
      } else if (healthInsuranceTypes.includes('kyokai')) {
        setShowHint(false)
        setOnlyPensionOffice(true)
      } else {
        setShowHint(false)
        setOnlyPensionOffice(false)
      }
    }
  }, [procedureStatusIds])

  return (
    <>
      <Button disabled={!downloadable} primary onClick={() => setIsOpen(true)}>
        ダウンロード
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose} className={styles.csvModal}>
        <form onSubmit={handleReactHookFormSubmit(handleSubmit)}>
          <Modal.Header onClose={handleClose}>{`CSV(${insuranceTypes[insuranceType]})ダウンロード`}</Modal.Header>
          <Modal.Body>
            {insuranceType === 'social_insurance' && (
              <>
                <div className={styles.itemBox}>
                  <p>
                    提出先
                    <Required />
                  </p>
                  <div>
                    <RadioContainer>
                      <Radio
                        {...register('submission_destination')}
                        value="pension_office"
                        disabled={onlyPensionOffice || onlyHealthInsuranceOffice}
                      >
                        年金事務所
                      </Radio>
                      <Radio
                        {...register('submission_destination')}
                        value="health_insurance_office"
                        disabled={onlyPensionOffice || onlyHealthInsuranceOffice}
                      >
                        <div className={styles.hintWrap}>
                          {showHint && (
                            <div className={styles.hint}>
                              複数の健康保険組合が混在して
                              <br />
                              いるため選択できません
                            </div>
                          )}
                          健康保険組合
                        </div>
                      </Radio>
                    </RadioContainer>
                    {errors.submission_destination?.message && (
                      <ErrorMessage>{errors.submission_destination.message}</ErrorMessage>
                    )}
                  </div>
                </div>
                <Spacer direction="y" size={30} />
              </>
            )}

            <div className={styles.itemBox}>
              <p>
                {insuranceType === 'social_insurance' ? '媒体通番' : 'FD通番'}
                <Required />
              </p>
              <div>
                <Input {...register('serial_number')} isError={!!errors.serial_number?.message} maxLength={3} />
                {errors.serial_number?.message && <ErrorMessage>{errors.serial_number.message}</ErrorMessage>}
              </div>
            </div>

            {!isLimitedAdmin && (
              <>
                <Spacer direction="y" size={30} />
                <div className={classNames(styles.itemBox, styles.checkbox)}>
                  <Checkbox {...register('show_my_number')}>マイナンバーを出力する</Checkbox>
                </div>
                <div>
                  {errors.show_my_number?.message && <ErrorMessage>{errors.show_my_number.message}</ErrorMessage>}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ButtonRow>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button primary disabled={isSubmitting || !isValid} onClick={handleReactHookFormSubmit(handleSubmit)}>
                ダウンロード
              </Button>
            </ButtonRow>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
