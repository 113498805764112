import {React, useState} from 'react'
import {Section} from 'jbc-front/components/Form'
import {
  reduxForm,
  isSubmitting as isSubmittingForm,
  autofill as autofillForm,
  stopSubmit as stopSubmitForm,
  formValueSelector,
  touch as touchForm,
  FieldArray,
} from 'redux-form'
import {bindActionCreators} from 'redux'
import moment from 'moment'
import {
  ExpectedBirthDate,
  MaternityDivision,
  StartedAt,
  ExpectedFinishedAt,
  MaternityStatus,
  ReasonOfAbortion,
  DateOfAbortion,
  MotherChildNote,
  renderChildInfo,
} from 'procedures/maternityLeave/MaternityForm'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import {connect} from 'react-redux'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import ReviewComments from 'components/ReviewComments'
import {ConfirmUpgradeModal} from 'components/modals/ConfirmUpgradeModal'

const formName = 'startMaternity'
export const isSubmitting = isSubmittingForm(formName)
export const autofill = autofillForm.bind(null, formName)
export const stopSubmit = stopSubmitForm.bind(null, formName)
export const touch = touchForm.bind(null, formName)

const Form = ({
  submitting,
  maternity_status,
  expectedBirthDate,
  maternityDivision,
  startedAt,
  expectedFinishedAt,
  handleSubmit,
  otherButtons,
  employee,
  submitText = '完了',
  comments,
  needUpgrade,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const branchSubmit = (needUpgrade) => {
    if (needUpgrade) {
      setOpenModal(true)
    } else {
      handleSubmit()
    }
  }

  return (
    <form className="new_boarding_procedure" onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">産休開始の手続き</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <ReviewComments comments={comments} />
        <Section title="出産情報">
          <ExpectedBirthDate
            maternityDivision={maternityDivision}
            startedAt={startedAt}
            expectedFinishedAt={expectedFinishedAt}
          />
          <MaternityDivision
            expectedBirthDate={expectedBirthDate}
            startedAt={startedAt}
            expectedFinishedAt={expectedFinishedAt}
          />
          <StartedAt />
          <ExpectedFinishedAt />
        </Section>
        <Section title="出生児情報">
          <MaternityStatus />
          {maternity_status === 'after' && (
            <>
              <FieldArray
                name="procedure_maternity_child_data"
                component={renderChildInfo}
                procedureType="start_maternity"
                formName={formName}
                selector={selector}
                autofill={autofill}
                canAdd
                canDelete
              />
              <MotherChildNote />
            </>
          )}
          {maternity_status === 'other' && (
            <>
              <ReasonOfAbortion required />
              <DateOfAbortion required />
            </>
          )}
        </Section>
        <div className="u-ta-c u-mt30">
          {otherButtons}
          <AsyncTaskConsumer>
            {({taskRunningProps}) => (
              <Button primary onClick={() => branchSubmit(needUpgrade)} disabled={submitting} {...taskRunningProps}>
                {submitText}
              </Button>
            )}
          </AsyncTaskConsumer>
        </div>
      </div>
      <ConfirmUpgradeModal
        onSubmit={() => {
          setOpenModal(false)
          handleSubmit()
        }}
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
      />
    </form>
  )
}

export const makeInitialValues = (maternityData, tmpFiles) => {
  if (maternityData) {
    return {
      ...maternityData,
      mother_child_note: tmpFiles.map((tmpFile) => ({id: tmpFile.id, ...tmpFile.file})),
    }
  } else {
    return {
      mother_child_note: [],
      maternity_status: 'before',
      procedure_maternity_child_data: [{}],
    }
  }
}

export const makeInitialValuesForUpdate = (procedureStatus, employee) => {
  if (!employee || !procedureStatus) {
    return {procedure_maternity_child_data: [{}]}
  }
  const {procedure_start_maternity_datum: maternityData, date_on: dateOn} = procedureStatus
  if (maternityData && maternityData.procedure_maternity_child_data.length === 0) {
    return {date_on: dateOn, ...maternityData, procedure_maternity_child_data: [{}]}
  } else {
    const formattedMaternityChildData = maternityData.procedure_maternity_child_data.map((child) => {
      if (child.add_support) {
        return {...child, skip_add_support: true}
      } else {
        return child
      }
    })
    return {date_on: dateOn, ...maternityData, procedure_maternity_child_data: formattedMaternityChildData}
  }
}

export const selector = formValueSelector(formName)

const validate = ({started_at, expected_finished_at}) =>
  started_at && expected_finished_at && moment(started_at).isAfter(moment(expected_finished_at))
    ? {expected_finished_at: 'を産前産後休業等開始年月日より前に設定することはできません。'}
    : {}

export default connect(
  (state) => ({
    maternity_status: selector(state, 'maternity_status'),
    expectedBirthDate: selector(state, 'expected_birth_date'),
    maternityDivision: selector(state, 'maternity_division'),
    startedAt: selector(state, 'started_at'),
    expectedFinishedAt: selector(state, 'expected_finished_at'),
    needUpgrade: selector(state, 'need_upgrade'),
  }),
  bindActionCreators.bind(null, {autofill})
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    validate,
  })(Form)
)
