import {FC} from 'react'
import {Header} from 'components/layout/Header'
import {Link} from 'react-router-dom'
import styles from './Header.scss'
import {Logo} from './parts/Logo'

export const NotLoggedInHeader: FC = () => (
  <Header.Content>
    <Link to="/">
      <Logo />
    </Link>
    <Link to="/login" className={styles.link}>
      Login
    </Link>
  </Header.Content>
)
