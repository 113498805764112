import {FC, useState} from 'react'
import {useSearchPayrollReportCityByAddress} from './PayrollCitySelector/useSearchPayrollReportCityByAddress'
import styles from './PayrollReportCitySection.scss'
import Modal from 'jbc-front/components/CommonModal'
import {Section} from 'jbc-front/components/Form'
import ActionButton from 'jbc-front/components/ActionButton'
import {Map, MapMarker, ResidenceRegistration} from 'jbc-front/components/icons'
import {MasterFinder} from 'components/ui/MasterFinder'
import {useSearchPayrollReportCity} from './PayrollCitySelector/useSearchPayrollCities'
import {useSelector, useDispatch} from 'react-redux'
import {formValueSelector, autofill} from 'redux-form'
import {SearchedCity} from './PayrollReportCityType'
import {notifyError} from '../../../store/actions/notify'
import {buildCityName} from './utils'

const PER_PAGE = 10

export const PayrollReportCitySection: FC = () => {
  const [searchKeyword, setSearchKeyword] = useState<string | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState(1)
  const formName = 'employeeForm'
  const selector = formValueSelector(formName)
  const dispatch = useDispatch()

  // 現住所
  const city = useSelector((state) => selector(state, 'city'))
  const prefecture_id = useSelector((state) => selector(state, 'prefecture_id'))
  const employee_resident_tax_city_code = useSelector((state) => selector(state, 'employee_resident_tax.city_code'))
  const employee_resident_tax_city_name = useSelector((state) => selector(state, 'employee_resident_tax.city_name'))

  // 住民票住所
  const resident_card_prefecture_id = useSelector((state) => selector(state, 'resident_card.prefecture_id'))
  const resident_card_city = useSelector((state) => selector(state, 'resident_card.city'))

  const cityByResidentCard = useSearchPayrollReportCityByAddress(
    resident_card_city,
    Number(resident_card_prefecture_id)
  )

  const [isOpen, setIsOpen] = useState(false)
  const showModal = () => {
    setIsOpen(true)
    setSearchKeyword(undefined)
    setCurrentPage(1)
  }
  const hideModal = () => {
    setIsOpen(false)
  }

  const handleSearch = (keyword: string | undefined, page: number) => {
    setSearchKeyword(keyword)
    setCurrentPage(page)
  }

  const parseLabel = (label: string | undefined) => {
    return label?.split('：') ?? ['']
  }

  const cityByCurrentAddress = useSearchPayrollReportCityByAddress(city, Number(prefecture_id))

  const setCityFromAddress = (searchedCity: SearchedCity | undefined) => {
    if (searchedCity) {
      const fullCityName = buildCityName(searchedCity.prefectureName, searchedCity.name)
      dispatch(autofill(formName, 'employee_resident_tax.city_code', searchedCity.organizationCode))
      dispatch(autofill(formName, 'employee_resident_tax.city_name', fullCityName))
    } else {
      dispatch(notifyError('市区町村が見つかりませんでした'))
    }
  }

  const handleSelect = (item: {label: string} | undefined) => {
    const [selectedCityCode = '', selectedName = ''] = parseLabel(item?.label)
    dispatch(autofill(formName, 'employee_resident_tax.city_code', selectedCityCode))
    dispatch(autofill(formName, 'employee_resident_tax.city_name', selectedName))
    hideModal()
  }

  const handleClear = () => {
    setIsOpen(false)
    dispatch(autofill(formName, 'employee_resident_tax.city_code', ''))
    dispatch(autofill(formName, 'employee_resident_tax.city_name', ''))
  }

  const {payrollReportCities, totalCount} = useSearchPayrollReportCity(currentPage, PER_PAGE, searchKeyword, !isOpen)

  const searchResults = payrollReportCities?.map((city) => ({
    key: String(city.id),
    label: `${city.organizationCode}：${city.prefectureName} ${city.name}`,
  }))

  return (
    <>
      <Section title="給与支払報告書提出先">
        <div className={styles.body}>
          <div className={styles.tools}>
            <ActionButton icon={<Map size={16} />} onClick={showModal}>
              市区町村を選択
            </ActionButton>
            <ActionButton
              icon={<ResidenceRegistration size={16} />}
              onClick={() => setCityFromAddress(cityByResidentCard)}
            >
              住民票住所から取得
            </ActionButton>
            <ActionButton icon={<MapMarker size={16} />} onClick={() => setCityFromAddress(cityByCurrentAddress)}>
              現住所から取得
            </ActionButton>
          </div>
          <Modal isOpen={isOpen} hideModal={hideModal}>
            <Modal.Header hideModal={hideModal}>市区町村を選択</Modal.Header>
            <Modal.Body>
              <MasterFinder
                className={styles.masterFinder}
                placeholder="市区町村コード・市区町村名で検索"
                searchResults={searchResults}
                totalResult={totalCount}
                currentPage={currentPage}
                perPage={PER_PAGE}
                onSearch={handleSearch}
                onSelect={handleSelect}
                onClear={handleClear}
              />
            </Modal.Body>
          </Modal>
          <div className={styles.preview}>
            <dl className={styles.dl}>
              <dt>市区町村コード</dt>
              <dd>{employee_resident_tax_city_code}</dd>
            </dl>
            <dl className={styles.dl}>
              <dt>市区町村名</dt>
              <dd>{employee_resident_tax_city_name}</dd>
            </dl>
          </div>
        </div>
      </Section>
    </>
  )
}
