import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators} from 'actions'
import api from 'api'
import styles from 'settings/Notifications.scss'
import ActionButton from 'jbc-front/components/ActionButton'
import {Edit, Strage, MinusCircle} from 'jbc-front/components/icons'
import {recordDisplay, getState} from 'utils'
import ListData, {PerPage, Pager} from 'settings/notifications/ListData'
import {fetchSelector} from 'actions'
import {asyncError} from 'store/actions/asyncError'

const renderContent = ({usersClients = [], editing, change, changes}) => (
  <tbody>
    {usersClients.map((userClient) => {
      const value = {...userClient, ...changes[userClient.id]}
      return (
        <tr key={userClient.id}>
          <td style={{wordWrap: 'break-word'}}>{userClient.user.email}</td>
          <td style={{wordWrap: 'break-word'}}>
            {userClient.user.name || recordDisplay.fullName(userClient.user.employee)}
          </td>
          <td>
            {editing ? (
              <label className="input-label">
                <input
                  type="checkbox"
                  checked={value.receive_email}
                  onChange={() => change(userClient.id, !value.receive_email)}
                  className="m-checkbox-input"
                />
                <span className="m-checkbox-parts">受信する</span>
              </label>
            ) : userClient.receive_email ? (
              '受信する'
            ) : (
              '受信しない'
            )}
          </td>
        </tr>
      )
    })}
  </tbody>
)

const Content = connect(
  (state) => ({
    usersClients: fetchSelector(state, 'users_clients').data,
    editing: state.settings.usersClients.editing,
    changes: state.settings.usersClients.changes,
  }),
  (dispatch) => ({
    change(id, value) {
      dispatch(actionCreators.settings.usersClients.change({id, receive_email: value}))
    },
  })
)(renderContent)

const renderUsersTable = ({editing, handleSubmit, handleCancel, handleEdit}) => (
  <div>
    {editing ? (
      <div className={styles.buttonWrap}>
        <ActionButton onClick={handleSubmit} icon={<Strage size={15} />}>
          保存
        </ActionButton>
        <ActionButton onClick={handleCancel} icon={<MinusCircle size={15} />}>
          キャンセル
        </ActionButton>
      </div>
    ) : (
      <div>
        <ActionButton onClick={handleEdit} icon={<Edit size={15} />}>
          編集
        </ActionButton>
      </div>
    )}
    <div className="l-overflow-scroll">
      <table className={styles.dataTable}>
        <thead>
          <tr>
            <th className={styles.email}>email</th>
            <th className={styles.name}>氏名</th>
            <th className={styles.notification}>通知の受信</th>
          </tr>
        </thead>
        <Content />
      </table>
    </div>
  </div>
)

const UsersTable = connect(
  (state) => ({
    editing: state.settings.usersClients.editing,
  }),
  (dispatch) => ({
    handleCancel() {
      dispatch(actionCreators.settings.usersClients.clear())
    },
    handleEdit() {
      dispatch(actionCreators.settings.usersClients.edit())
    },
  })
)(renderUsersTable)

class Notifications extends Component {
  handleSubmit = () => {
    this.props.updateUsersClients().then(this._listData.loadData)
  }

  componentWillUnmount() {
    this.props.clear()
  }

  render() {
    const {count} = this.props
    return (
      <div>
        <ListData
          params={{
            embed: ['user', 'employee'],
            client_role_client_role_type: ['full_admin', 'office_admin', 'limited_admin'],
          }}
          ref={(listData) => (this._listData = listData)}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">通知先設定</h1>
          <p className="m-title-main-note">従業員からの連絡を受け取る宛先を設定できます</p>
        </div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <span className="l-breadcrumb-here">通知先設定</span>
          </div>
          <UsersTable handleSubmit={this.handleSubmit} />
          <div className="m-pager-area">
            <Pager totalResult={count} pageLinks={7} />
            <PerPage />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    additionalNotificationDsts: state.settings.additionalNotificationDsts.data,
    count: fetchSelector(state, 'users_clients').count,
    usersClients: fetchSelector(state, 'users_clients').data,
  }),
  (dispatch) => ({
    clear() {
      dispatch(actionCreators.settings.usersClients.clear())
    },
    async updateUsersClients() {
      try {
        const state = await getState(dispatch)
        const token = state.auth.token
        const changes = Object.values(state.settings.usersClients.changes)
        await api.createWithAuth(token).usersClients.updateMulti({
          updates: changes,
        })
        dispatch(actionCreators.settings.usersClients.clear())
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Notifications)
