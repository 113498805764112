import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {parse} from 'query-string'
import {asyncError} from 'store/actions/asyncError'
import styles from 'employees/DownloadRoster.scss'
import {downloadFile as _downloadFile} from 'libs/downloadFile'
import {useSelector} from 'hooks/redux'

const makeErrorData = (status) => {
  switch (status) {
    case 401: {
      return {
        redirect: '/employees',
        header: 'ダウンロード期間が切れています',
        message: '最新の労働者名簿をダウンロードするにはこちら',
      }
    }
    case 403: {
      return {
        redirect: '/dashboard',
        header: 'ダウンロード権限がありません',
        message: 'ダッシュボードへの移動はこちら',
      }
    }
    default: {
      return {
        redirect: '/dashboard',
        header: 'ダウンロードに失敗しました',
        message: 'ダッシュボードへ移動する',
      }
    }
  }
}

const DownloadRoster = ({location: {search}, dispatch}) => {
  const {token} = parse(search)
  const [errorData, setErrorData] = useState({header: '', redirect: '', message: ''})
  const selectedOffices = useSelector((state) => state.session.selectedOffices)
  const downloadFile = () =>
    _downloadFile({
      url: '/api/employee_generated_files',
      asyncError: (err) => {
        const {status} = err.response || {}
        if (status != 401) {
          dispatch(asyncError(err))
        }
        setErrorData(makeErrorData(status))
      },
      params: {token},
      selectedOffices,
    })
  useEffect(() => {
    downloadFile()
  }, [])
  return (
    <div className="l-flex">
      <div className={styles.cardWrap}>
        {errorData.header ? (
          <>
            {errorData.header && <h2>{errorData.header}</h2>}
            <Link to={errorData.redirect} className="u-txt-link">
              {errorData.message}
            </Link>
          </>
        ) : (
          <>
            <h2>ダウンロードは自動で実行されます</h2>
            <p className={styles.description}>
              ダウンロードが行われない場合は
              <span className="u-txt-link u-cur-pointer " onClick={() => downloadFile()}>
                こちら
              </span>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default connect((state) => ({location: state.router.location}))(DownloadRoster)
