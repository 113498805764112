import React from 'react'
import {Link} from 'react-router-dom'
import styles from 'NotFound.scss'

const NotFound = () => (
  <div className="l-flex">
    <div className={styles.contents}>
      <h2 className={styles.title}>ページを表示できません</h2>
      <div className={styles.cardWrap}>
        <p>アクセスしようとしたページはアクセス権限がないか、削除・変更された可能性があります。</p>
        <Link to="/dashboard" className="u-txt-link">
          ダッシュボードへ
        </Link>
      </div>
    </div>
  </div>
)

export default NotFound
