import {gql} from '@apollo/client'
import {DOCUMENT_VISIVILITIES} from 'consts/document'

export interface QueryVariables {
  per?: number
  page?: number
  search?: Search
}

export type Order = 'asc' | 'desc'
export type SortColumn = 'flag' | 'visibility' | 'title' | 'document_employees_count' | 'created_at' | 'created_user'

export interface Search {
  sortOrder?: Order
  sortColumn?: SortColumn
  filter?: 'all' | 'star' | 'all_admins' | 'private'
  q?: string
}

export interface QueryResult {
  client: {
    id: number
    documents: {
      totalCount: number
      list: Document[]
    }
  }
}

export type ContractSendStatus = 'unsent' | 'sent' | 'canceled'

export interface Document {
  id: number
  title: string
  visibility: keyof typeof DOCUMENT_VISIVILITIES
  documentEmployeesPublishedCount: number
  documentEmployeesCount: number
  flag: number
  isPublished: boolean
  contractSendStatus: ContractSendStatus
  createdUser?: CreatedUser
  createdAt: string
  comments: Comment[]
}

export interface Comment {
  id: number
  body: string
  createdAt: string
  commenter: Commenter
}

export interface Commenter {
  id: number
  email: string | null
}

export interface CreatedUser {
  id: number
  email: string | null
  employee: Employee
}

export interface Employee {
  id: number
  displayLastName: string
  displayFirstName: string
}

export const DOCUMENTS = gql`
  query documents($per: Int, $page: Int, $search: DocumentSearchInput) {
    client {
      id
      documents(per: $per, page: $page, search: $search) {
        totalCount
        list {
          id
          title
          visibility
          documentEmployeesPublishedCount
          documentEmployeesCount
          flag
          createdAt
          contractSendStatus
          createdUser {
            id
            name
            email
            employee {
              id
              displayFirstName
              displayLastName
            }
          }
          comments {
            id
            body
            createdAt
            commenter {
              id
              email
            }
          }
        }
      }
    }
  }
`

export const UPDATE_DOCUMENT_FLAG = gql`
  mutation updateDocumentFlag($input: UpdateDocumentFlagInput!) {
    updateDocumentFlag(input: $input) {
      document {
        id
      }
    }
  }
`

export const CREATE_DOCUMENT_COMMENT = gql`
  mutation createDocumentComment($input: CreateDocumentCommentInput!) {
    createDocumentComment(input: $input) {
      comment {
        id
      }
    }
  }
`

export const UPDATE_COMMENT = gql`
  mutation updateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      comment {
        id
      }
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation deleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      comment {
        id
      }
    }
  }
`
