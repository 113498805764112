import moment from 'moment'
import Cookies from 'js-cookie'
import _ from 'lodash'
import queryString from 'query-string'
import {actionCreators} from 'actions'
import api from 'api'
import {formValueSelector, getFormValues, autofill as autofillForm} from 'redux-form'

export const formName = 'tmEmployeeSearchForm'
export const selector = formValueSelector(formName)
export const getValues = getFormValues(formName)
export const autofill = autofillForm.bind(null, formName)
export const defaultQuery = {
  _page: '1',
  sort: 'staff_code',
  order: 'asc',
}
const defaultLimit = '50'

export const convertQueryToForm = (query) => {
  return Object.assign(
    {sort_type: `${query.sort}__${query.order}`},
    ...Object.entries(query)
      .map(([k, v]) => convertQueryToFormField(k, v))
      .filter(_.identity)
  )
}

export const convertQueryToFormField = (fieldName, value) => {
  switch (fieldName) {
    case 'name':
      return {name: value.join(',')}
    case 'email':
      return {email: value.join(',')}
    case 'staff_code':
      return {staff_code: value.join(',')}
    case 'office_ids':
      return {offices: value.join(',')}
    case 'group_ids':
      return {groups: value.join(',')}
    case 'employment_status_ids':
      return {employment_statuses: value}
    case 'position_ids':
      return {positions: value}
    case 'tag_ids':
      return {tags: value.join(',')}
    case 'language_ids':
      return {languages: value}
    case 'language_level_ids':
      return {language_levels: value}
    case 'languages_qualification_name':
      return {languages_qualification_name: value}
    case 'skills':
      return {skills: value.join(',')}
    case 'qualifications':
      return {qualifications: value.join(',')}
    case 'employment_type_ids':
      return {employment_types: value}
    case 'occupation_ids':
      return {occupations: value}
    case 'projects':
      return {projects: value.join(',')}
    case '_page':
      return {page: value}
    case 'sort':
    case 'order':
      return undefined
    default:
      return {[fieldName]: value}
  }
}

export const convertFormToQueryString = (data) =>
  queryString.stringify(convertFormToQuery(data), {arrayFormat: 'bracket'})

export const convertFormToQuery = (data) => {
  if (data === undefined) return {}
  return Object.assign(
    {},
    ...Object.entries(data)
      .map(([k, v]) => convertFormToQueryField(k, v))
      .filter(_.identity)
  )
}

export const convertFormToQueryField = (fieldName, value) => {
  switch (fieldName) {
    case 'name': {
      return !_.isEmpty(value) ? {name: value.split(/,|\s/)} : null
    }
    case 'email': {
      return !_.isEmpty(value) ? {email: value.split(/,|\s/)} : null
    }
    case 'staff_code': {
      return !_.isEmpty(value) ? {staff_code: value.split(/,|\s/)} : null
    }
    case 'offices': {
      const officesIds = value.split(',')
      return !_.isEqual(officesIds, ['']) ? {office_ids: officesIds} : null
    }
    case 'groups': {
      const groupIds = value.split(',')
      return !_.isEqual(groupIds, ['']) ? {group_ids: groupIds} : null
    }
    case 'tags': {
      const tagIds = value.split(',')
      return !_.isEqual(tagIds, ['']) ? {tag_ids: tagIds} : null
    }
    case 'languages': {
      return !_.isEqual(value) ? {language_ids: value} : null
    }
    case 'language_levels': {
      return !_.isEqual(value) ? {language_level_ids: value} : null
    }
    case 'languages_qualification_name': {
      return !_.isEqual(value) ? {languages_qualification_name: value} : null
    }
    case 'skills': {
      return !_.isEmpty(value) ? {skills: value.split(/,|\s/)} : null
    }
    case 'qualifications': {
      return !_.isEmpty(value) ? {qualifications: value.split(/,|\s/)} : null
    }
    case 'employment_statuses':
      return !_.isEmpty(value) ? {employment_status_ids: value} : null
    case 'positions':
      return !_.isEmpty(value) ? {position_ids: value} : null
    case 'employment_types':
      return !_.isEmpty(value) ? {employment_type_ids: value} : null
    case 'occupations':
      return !_.isEmpty(value) ? {occupation_ids: value} : null
    case 'projects':
      return !_.isEmpty(value) ? {projects: value.split(/,|\s/)} : null
    case 'joined_health_insurance':
    case 'joined_welfare_pension_insurance':
    case 'joined_employment_insurance':
      return value !== '' ? {[fieldName]: value} : null
    case 'limit':
      return null
    case 'page':
      return {_page: value}
    case 'sort_type': {
      const [sort, order] = value.split('__')
      return {order, sort}
    }
    default:
      return {[fieldName]: value}
  }
}

const addedFieldsKey = (user) => `#${user.id}__tm_added_fields`

export const addOptions = [
  {label: '勤務事業所', value: 'office_options'},
  {label: '役職', value: 'positions'},
  {label: '雇用形態', value: 'employment_types'},
  {label: '職種', value: 'occupations'},
  {label: '入社日', value: 'joined_at', fields: ['joined_at_start', 'joined_at_end']},
  {label: '資格', value: 'qualifications'},
  {label: '語学', value: 'languages'},
  {label: 'スキル', value: 'skills'},
  {label: '年齢', value: 'age'},
  {label: '生年月日', value: 'birthday', fields: ['birthday_start', 'birthday_end']},
  {label: '性別 ', value: 'genders'},
]

const makeAddedFields = (query, user) => {
  let savedQuery = []
  if (window.localStorage) {
    const saved = window.localStorage.getItem(addedFieldsKey(user))
    if (saved) {
      savedQuery = JSON.parse(saved)
    }
  }
  let hasQuery = addOptions
    .filter((opt) => (opt.fields || [opt.value]).some((key) => !_.isEmpty(query[key])))
    .map((opt) => opt.value)
  return _.union(savedQuery, hasQuery)
}

export const saveAddedFields = (user, addedField) => {
  if (window.localStorage) {
    window.localStorage.setItem(addedFieldsKey(user), JSON.stringify(_.isEmpty(addedField) ? [] : addedField))
  }
}

export const getCurrentQueryFromLocation = (location, user) => {
  const query = {
    ...defaultQuery,
    age_base_date: moment().format('YYYY/MM/DD'),
    ...queryString.parse(location.search, {arrayFormat: 'bracket'}),
    limit: getSavedDisplayEmployeeLimit(location.pathname, user),
  }
  return {...query, _added_fields: makeAddedFields(query, user)}
}

const limitKey = (pathname, user) => `${pathname.replace(/\//g, '')}#${user.id}__disp_emp_limit`

export const getSavedDisplayEmployeeLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user))
    if (!_.isEmpty(limit)) {
      return limit
    }
  }
  const limit = Cookies.get(limitKey(pathname, user))
  return _.isEmpty(limit) ? defaultLimit : limit
}

export const saveDisplayEmployeeLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit)
  } else {
    Cookies.set(limitKey(pathname, user), limit)
  }
}

export const fetchEmployees =
  (query, additionalParams = {}) =>
  (dispatch, getState) => {
    const {
      auth: {token},
    } = getState()
    const request = api.createWithAuth(token).tm.employees.search({...query, ...additionalParams})
    dispatch(actionCreators.employees.list.fetchData(request))
  }

export const fetchEmployeesAll =
  (query, additionalParams = {}) =>
  (dispatch, getState) => {
    const {
      auth: {token},
    } = getState()
    const request = api.createWithAuth(token).tm.employees.searchAll({...query, ...additionalParams})
    dispatch(actionCreators.employees.all.fetchData(request))
  }

const parseResult = (result) => {
  let options = result.data.items.map((item) => ({...item, id: `${item.id}`}))
  if (result.data.with_null) {
    options = [...options, {name: '未入力', id: null}]
  }
  return options
}

const parseGroupsResult = (groupsResult) => {
  let options = groupsResult.groups.items.map((group) => ({
    value: `${group.id}`,
    label: group.full_name,
    parent_value: `${group.parent_group_id}`,
  }))
  if (groupsResult.groups.with_null) {
    options = [...options, {label: '未入力', value: null, parent_value: null}]
  }
  return options
}

export const fetchSelections = (token, dispatch) => {
  const promises = [
    api.createWithAuth(token).tm.groups.list(),
    api.createWithAuth(token).tm.employees.selections.positions(),
    api.createWithAuth(token).tm.employees.selections.employmentTypes(),
    api.createWithAuth(token).tm.employees.selections.occupations(),
    api.createWithAuth(token).tm.employees.selections.employeeStatuses(),
    api.createWithAuth(token).tm.employees.selections.languages(),
    api.createWithAuth(token).tm.employees.selections.languageLevels(),
    api.createWithAuth(token).tm.employees.selections.tags(),
    api.createWithAuth(token).tm.employees.selections.allOffices(),
    api.createWithAuth(token).tm.displaySettings.list(),
  ]
  dispatch(
    actionCreators.fetchData(
      'selections',
      Promise.all(promises).then((results) => {
        const [
          groupsResult,
          positionsResult,
          employmentTypesResult,
          occupationsResult,
          employeeStatusesResult,
          languagesResult,
          languageLevelsResult,
          tagsResult,
          allOfficesResult,
          displaySettingsResult,
        ] = results
        return {
          groups: parseGroupsResult(groupsResult),
          positions: parseResult(positionsResult),
          employmentTypes: parseResult(employmentTypesResult),
          occupations: parseResult(occupationsResult),
          employeeStatuses: employeeStatusesResult.data.map((item) => ({...item, id: `${item.id}`})),
          languages: languagesResult.data.map((item) => ({...item, id: `${item.id}`})),
          languageLevels: languageLevelsResult.data.map((item) => ({...item, id: `${item.id}`})),
          tags: tagsResult.data.map((tag) => ({
            value: `${tag.id}`,
            label: tag.name,
          })),
          allOffices: allOfficesResult.data.map((allOffice) => ({
            value: `${allOffice.id}`,
            label: allOffice.name,
          })),
          displaySettings: displaySettingsResult.displaySettings.map((displaySetting) => ({
            value: `${displaySetting.id}`,
            code: displaySetting.talent_display_setting_category.code,
          })),
        }
      })
    )
  )
}

export const hasConditions = (formValues) => {
  return !_.isEqual(_.pickBy(_.omit(convertFormToQuery(formValues), ['_added_fields', 'age_base_date'])), defaultQuery)
}
