import React from 'react'
import {Link} from 'react-router-dom'
import styles from 'dashboard/dashboard.scss'
import * as icons from 'jbc-front/components/icons'

const proceduresItems = [
  {
    procedureIcon: 'Join',
    procedureTitle: '入社の手続き',
    procedureText: '新しい従業員が入社するときの手続き',
    url: 'enroll',
  },
  {
    procedureIcon: 'Leave',
    procedureTitle: '退社の手続き',
    procedureText: '従業員が退社するときの手続き',
    url: 'resign',
  },
  {
    procedureIcon: 'Dependent',
    procedureTitle: '扶養の変更',
    procedureText: '結婚や出産、子どもが就職するなどの手続き',
    url: 'change_dependents',
  },
  {
    procedureIcon: 'Maternity',
    procedureTitle: '産休の手続き',
    procedureText: '出産前後の休職の手続き',
    url: 'maternity_leave',
  },
  {
    procedureIcon: 'Adress',
    procedureTitle: '住所の変更',
    procedureText: '引越など住所が変わるときの手続き',
    url: 'change_address',
  },
  {
    procedureIcon: 'Name',
    procedureTitle: '氏名の変更',
    procedureText: '結婚などで氏名が変わるときの手続き',
    url: 'change_name',
  },
]

const ProceduresList = () => {
  return (
    <div className={styles.proceduresRow}>
      {proceduresItems.map((item, index) => {
        const Icon = icons[item.procedureIcon]
        return (
          <div className={styles.proceduresCol} key={index}>
            <Link to={`/${item.url}`}>
              <Icon size={20} />
              <span className={styles.procedureItemTitle}>{item.procedureTitle}</span>
              <span className={styles.procedureItemText}>{item.procedureText}</span>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

const Procedures = () => {
  return (
    <article className={styles.procedures}>
      <div className={styles.card}>
        <div className={styles.card_header}>
          <div>手続きの開始</div>
        </div>
        <div className={styles.card_body}>
          <ProceduresList />
        </div>
      </div>
    </article>
  )
}

export default Procedures
