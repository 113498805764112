import {useState} from 'react'

type Order = 'desc' | 'asc'

type OnSort<Column> = (order: Order, column: Column) => void
type UseSortColumn = <Column>(
  defaultColumn: Column,
  onSort: OnSort<Column>
) => {
  sort: (column: Column) => void
  sortOrder: Order
  sortColumn: Column
}

export const useSortColumn: UseSortColumn = <Column>(defaultColumn: Column, onSort: OnSort<Column>) => {
  const [sortOrder, setSortOrder] = useState<Order>('desc')
  const [sortColumn, setSortColumn] = useState<Column>(defaultColumn)

  const sort = (column: Column) => {
    if (sortColumn === column) {
      const nextOrder = inverseOrder()
      onSort(nextOrder, sortColumn)
      setSortOrder(nextOrder)
    } else {
      onSort('desc', column)
      setSortOrder('desc')
      setSortColumn(column)
    }
  }

  const inverseOrder = () => {
    switch (sortOrder) {
      case 'desc':
        return 'asc'
      case 'asc':
        return 'desc'
    }
  }

  return {
    sort,
    sortOrder,
    sortColumn,
  }
}
