import React from 'react'
import _ from 'lodash'
import {RadioField, Section} from 'jbc-front/components/Form'
import {reduxForm, submit as submitForm} from 'redux-form'
import styles from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupPermissionForm.scss'

const formName = 'permissionForm'

export const submit = submitForm.bind(null, formName)

export const basicOptions = [
  {label: '更新可', value: 'updatable'},
  {label: '閲覧可', value: 'browsable'},
  {label: '非表示', value: 'not_display'},
]

const requestOptions = [
  {label: '更新可', value: 'updatable'},
  {label: '非表示', value: 'not_display'},
]

const clientRoles = [
  {name: '社員マイページ', options: basicOptions, isEmployee: true},
  {name: '従業員招待時の情報入力、入社の手続き', options: requestOptions},
]

const filterIf = (condition, cb) => (options) => condition ? _.filter(options, cb) : options

const Form = ({handleSubmit, canUpdate, canHide}) => (
  <form onSubmit={handleSubmit}>
    <div className="form_box_main">
      <div className={styles.description}>
        更新可の場合、内容を入力・編集できます。
        <br />
        閲覧可の場合、内容を確認できますが更新はできません。
        <br />
        非表示の場合、内容が表示されません。
        <br />
      </div>
      {clientRoles.map((field, index) => {
        const {options, isEmployee} = field
        const _options =
          options
          |> filterIf(!canUpdate, (role) => role.value !== 'updatable')
          |> filterIf(!canHide, (role) => role.value !== 'not_display')
        return (
          <Section title={field.name} key={index}>
            <RadioField options={_options} name={isEmployee ? 'permission' : 'permission_invitation'} />
          </Section>
        )
      })}
    </div>
  </form>
)

export default reduxForm({
  form: formName,
})(Form)

export const makeInitialValues = (employee) => ({
  permission: employee.permission,
  permission_invitation: employee.permission_invitation,
})
