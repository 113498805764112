import React, {Component} from 'react'
import Form, {isSubmitting} from 'procedures/changeDependents/remove/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import _ from 'lodash'
import CommentModal from 'procedures/CommentModal'
import Button from 'jbc-front/components/Button'
import checkEmployeeConfirm from 'components/checkEmployeeConfirm'
import compose from 'lodash/fp/compose'
import {isAdminSelector} from 'utils'
import {asyncError} from 'store/actions/asyncError'

class Confirm extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      loadProcedureStatus,
      token,
      loadTmpData,
      loadComments,
    } = this.props
    loadProcedureStatus(id, token)
    loadTmpData(id, token)
    loadComments(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  makeInitialValues() {
    const {
      tmpData: {application_procedure_remove_dependents: removeDependentsData},
      procedureStatus: {employee},
    } = this.props
    if (removeDependentsData) {
      return removeDependentsData
    }
    return employee && employee.employee_dependents
      ? {
          dependents: employee.employee_dependents.map((dependent) =>
            _.pick(dependent, [
              'first_name',
              'last_name',
              'id',
              'remove_reason_detail',
              'remove_reason_type',
              'relation_type',
              'died_on',
            ])
          ),
        }
      : {}
  }

  render() {
    const {
      procedureStatus: {employee},
      match: {
        params: {id},
      },
      submitting,
      healthInsuranceType,
      isAdmin,
      comments,
    } = this.props
    return (
      <div>
        <CommentModal procedureStatusId={id} comments={comments || []} procedureType="changeDependents">
          {({showModal}) => (
            <Form
              onSubmit={this.handleSubmit}
              employee={employee}
              initialValues={this.makeInitialValues()}
              submitText="承認して次へ"
              otherButtons={
                <Button disabled={submitting} onClick={showModal} className="u-mr20">
                  修正を依頼する
                </Button>
              }
              healthInsuranceType={healthInsuranceType}
              comments={comments || {}}
              isAdmin={isAdmin}
            />
          )}
        </CommentModal>
      </div>
    )
  }

  handleSubmit = (values) => {
    const {
      updateAndAccept,
      procedureStatus,
      match: {
        params: {id},
      },
      token,
    } = this.props
    return updateAndAccept(id, procedureStatus.employee_id, values, token)
  }
}

export default compose(
  checkEmployeeConfirm({procetureType: 'remove_dependents'}),
  connect(
    (state) => ({
      procedureStatus: state.procedureStatuses.current.data,
      tmpData: state.procedureStatuses.tmpData.data,
      comments: fetchSelector(state, 'procedure_comments').data,
      submitting: isSubmitting(state),
      healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
      isAdmin: isAdminSelector(state),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadProcedureStatus: (id, token) => {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(
            api.createWithAuth(token).procedureStatuses.get(id, ['employee', 'employee_dependents', 'office'])
          )
        )
      },
      loadTmpData: (procedureStatusId, token) => {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(procedureStatusId, {
              type: 'application',
              name: 'procedure_remove_dependents',
            })
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndAccept: async (procedureStatusId, employeeId, {dependents, date_on: dateOn}, token) => {
        const authedApi = api.createWithAuth(token)
        try {
          await authedApi.procedureStatuses.update(procedureStatusId, {date_on: dateOn})
          for (let dependent of dependents) {
            if (dependent && dependent._will_remove) {
              await authedApi.employees.dependents.update(employeeId, {
                ...dependent,
                removed: true,
                removed_on: dateOn,
              })
              await authedApi.procedureStatuses.removeDependentsData.create(procedureStatusId, dependent.id)
            }
          }
          await authedApi.procedureStatuses.accept(procedureStatusId)
          dispatch(push(`/remove_dependents/flow/${procedureStatusId}`))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(Confirm)
