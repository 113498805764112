import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import api from 'api'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {push} from 'connected-react-router'
import BaseForm, {selector as baseFormSelector, submit, formatValues} from 'employees/BaseForm'
import Modal from 'jbc-front/components/Modal'
import {getState} from 'utils'
import {useSelector} from 'hooks/redux'
import {useApolloClient} from '@apollo/client'
import {findOfficeById} from 'libs/graphql/findOfficeById'

const NewRequest = () => {
  const apolloClient = useApolloClient()
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const token = useSelector((state) => state.auth.token)
  const name = useSelector((state) => baseFormSelector(state, 'last_name'))

  const handleFormSubmit = async (values) => {
    try {
      const state = await getState(dispatch)
      const {employmentTypes = [], groups = []} = state.master
      const officeId = values.personnel_history?.[0]?.office_id
      const office = officeId && (await findOfficeById(apolloClient, officeId, {fetchPolicy: 'cache-first'}))
      const {employee, personnelHistory} = formatValues(values, employmentTypes, office, groups)
      const authedApi = api.createWithAuth(token)
      const {
        data: {id},
      } = await authedApi.employees.create(employee)
      authedApi.employees.personnelHistories.updateAll(id, [personnelHistory])
      await authedApi.requestProcedure(id, 'enroll')
      dispatch(notifySuccess('依頼しました'))
      dispatch(push('/dashboard'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  const handleModalSubmit = () => {
    dispatch(submit())
    return true
  }

  return (
    <main>
      <BaseForm
        onSubmit={handleFormSubmit}
        submitText="従業員本人に入力を依頼する"
        emailRequired
        submitButtonOnClick={() => setShowModal(true)}
        initialValues={{personnel_history: [{groups: [{}]}]}}
      />
      <Modal
        header="本人に入力を依頼する"
        body={`${name}さん宛に、入社手続きの入力依頼をメールします。`}
        cancel="キャンセル"
        submit="送信"
        onSubmit={handleModalSubmit}
        isOpen={showModal}
        hideModal={() => setShowModal(false)}
      />
    </main>
  )
}

export default NewRequest
