import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {autofill} from 'redux-form'
import {useFormName, useFormSectionPrefix} from 'hooks/reduxForm'
import {useGovernmentOffices} from './useGovernmentOffices'

// @ts-expect-error
import {prefectureOptions, withPrefix} from 'FormFields'
// @ts-expect-error
import {BoxDouble, SelectField, Label} from 'jbc-front/components/Form'

interface HelloWorkProps {
  prefectureId?: string
}

export const HelloWork: FC<HelloWorkProps> = ({prefectureId}) => {
  const dispatch = useDispatch()
  const formName = useFormName()
  const sectionPrefix = useFormSectionPrefix()
  const {data} = useGovernmentOffices(prefectureId || '', 'hello_work', !prefectureId)
  const helloWorkOptions = data?.governmentOffices?.map(({id, dispName}) => ({value: id, label: dispName})) || []

  return (
    <div>
      <Label text="ハローワーク" />
      <BoxDouble>
        <SelectField
          name="hello_work_prefecture_id"
          label="都道府県"
          options={prefectureOptions}
          onChange={(_event, value, previousValue) => {
            if (value !== previousValue) {
              dispatch(autofill(formName, withPrefix(sectionPrefix, 'hello_work_id'), null))
            }
          }}
        />
        <SelectField name="hello_work_id" label="管轄のハローワーク" options={helloWorkOptions} />
      </BoxDouble>
    </div>
  )
}
