import {FC} from 'react'
import {useDispatch} from 'react-redux'

import {CANCEL_DETAIL_INPUT_REQUEST, DETAIL_INPUT_REQUEST_WITH_FIELD_GROUPS} from '../../../../query'
import {notifySuccess} from 'store/actions/notify'

import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'

// @ts-ignore
import {useMutation} from 'components/Graphql'

export type RemindModalProps = {
  id: number
  isOpen: boolean
  onClose: () => void
}

export const CancelModal: FC<RemindModalProps> = ({isOpen, onClose, id}) => {
  const dispatch = useDispatch()
  const [cancelRequest, {loading: cancelSubmitting}] = useMutation(CANCEL_DETAIL_INPUT_REQUEST, {
    refetchQueries: [DETAIL_INPUT_REQUEST_WITH_FIELD_GROUPS],
  })

  const handleWithdraw = async () => {
    await cancelRequest({variables: {input: {id}}})
    dispatch(notifySuccess('キャンセルしました'))
    onClose()
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Modal.Header onClose={handleClose}>依頼を取り下げる</Modal.Header>
      <Modal.Body>依頼を取り下げてもよろしいですか？</Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button primary disabled={cancelSubmitting} onClick={handleWithdraw}>
            取り下げる
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  )
}
