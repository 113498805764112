import {FC, useState} from 'react'
import {Preview} from 'jbc-front/components/icons'
import {Document} from '../../../query'
import {useDownloadDynamicDocument} from 'hooks/api/dynamicDocuments/useDownloadDynamicDocument'
import {useDownloadSignedDocument} from 'hooks/api/documents/documentSignTransactions/useDownloadSignedDocument'
import {PdfModal} from 'jbc-front/components/presenters/ui/PdfModal'
import ActionButton from 'jbc-front/components/ActionButton'
import {Print, Download} from 'jbc-front/components/icons'
import {PdfPreview} from './PdfPreview'
import styles from './PreviewModal.scss'

type PreviewModalProps = {
  employeeId: number
  document: Document
}

export const DynamicDocumentPreviewModal: FC<PreviewModalProps> = ({employeeId, document}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const disabled = !document.dynamicDocument.downloadable
  const contractStatus = document.documentEmployee.contractInfo?.status

  const signedDocumentDownload = useDownloadSignedDocument()
  const dynamicDocumentDownload = useDownloadDynamicDocument()
  const url =
    contractStatus === 'signed'
      ? `/api/documents/${document.id}/document_sign_transactions/${employeeId}/signed_document`
      : `/api/dynamic_documents/${document.id}.pdf?employee_id=${employeeId}`

  const handleDownload = async () => {
    if (contractStatus === 'signed') {
      signedDocumentDownload(String(document.id), String(employeeId))
    } else {
      dynamicDocumentDownload(String(document.id), String(employeeId))
    }
  }

  const handlePrint = () => {
    if (contractStatus === 'signed') {
      const w = window.open(`${url}?disposition=inline`, '', 'toolbar=0,menubar=0,location=0')
      w?.addEventListener('load', () => w.print())
    } else {
      const w = window.open(`${url}&disposition=inline`, '', 'toolbar=0,menubar=0,location=0')
      w?.addEventListener('load', () => w.print())
    }
  }

  return (
    <>
      <i className="u-cur-pointer" onClick={handleOpen}>
        <Preview />
      </i>
      {isOpen && (
        <PdfModal isOpen={isOpen}>
          <PdfModal.Header className={styles.header}>
            <PdfModal.Title className={styles.title}>{document.title}</PdfModal.Title>
            <PdfModal.ToolContainer className={styles.tools} onClose={handleClose}>
              <ActionButton primary icon={<Print size={15} />} onClick={handlePrint} disabled={disabled}>
                印刷
              </ActionButton>

              <ActionButton primary icon={<Download size={15} />} disabled={disabled} onClick={handleDownload}>
                ダウンロード
              </ActionButton>
            </PdfModal.ToolContainer>
          </PdfModal.Header>
          <PdfPreview file={url} />
        </PdfModal>
      )}
    </>
  )
}
