import React from 'react'
import {TextField, DateField, SelectField, Section} from 'jbc-front/components/Form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {reduxForm, formValueSelector} from 'redux-form'
import {DependentHealthInsurance, DependentIncomeStatus} from 'reportForms/common'
import EmployeeName from 'procedures/EmployeeName'
import {isAdminSelector} from 'utils'

const formName = 'R135Report'

const applicationReasonTypes = [
  {value: 'entrance', label: '被保険者が当組合に取得したことに伴う申請'},
  {value: 'marriage', label: '被保険者と婚姻に伴う申請'},
  {value: 'retirement', label: '配偶者の退職に伴う申請'},
  {value: 'financial_change', label: '退職以外の理由で、配偶者の就労・収入状況の変化に伴う申請'},
  {value: 'unemployment_benefits_end', label: '配偶者の失業給付受給終了に伴う申請'},
  {value: 'others', label: 'その他'},
]

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, applicationReasonType, isAdmin, otherButtons, employee}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">被扶養者現況表（配偶者）</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section>
        <Section title="申請する理由">
          <SelectField
            name="application_reason_type"
            label="申請理由"
            options={applicationReasonTypes}
            required={!isAdmin}
          />
          {applicationReasonType === 'marriage' && <DateField name="married_at" label="婚姻日" required={!isAdmin} />}
          {applicationReasonType === 'retirement' && (
            <DateField name="spouse_retired_at" label="退職日" required={!isAdmin} />
          )}
          {applicationReasonType === 'others' && (
            <TextField name="application_reason_other" label="理由" required={!isAdmin} />
          )}
        </Section>
        <DependentHealthInsurance name="配偶者" formName={formName} required={!isAdmin} />
        <DependentIncomeStatus title="配偶者の現在の就労・収入状況" formName={formName} required={!isAdmin} />
        {isAdmin && <SubmitedOn />}
        <div className="u-ta-c u-mt30">
          {otherButtons}
          <Button primary onClick={handleSubmit} disabled={submitting}>
            {submitText}
          </Button>
        </div>
      </Section>
    </div>
  </form>
))

const selector = formValueSelector(formName)

const connectedForm = connectForm('r135', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    applicationReasonType: selector(state, 'application_reason_type'),
    isAdmin: isAdminSelector,
  }),
})

export default connectedForm
