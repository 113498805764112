import {createActions} from 'redux-actions'
import _ from 'lodash'

import {asyncError} from 'store/actions/asyncError'
import {notifySuccess, notifyError} from 'store/actions/notify'

import EMPLOYEES from 'employees/actions'
import PROCEDURE_STATUSES from 'procedureStatuses/actions'
import OFFICES from 'offices/actions'
import PROCEDURES from 'procedures/actions'
import SETTINGS from 'settings/actions'
import AUDITLOGS from 'settings/auditLogs/actions'
import NOTIFICATIONS from 'notifications/actions'
import REGISTRATION from 'registration/actions'
import E_GOV_PROCEDURES from 'eGovProcedures/actions'
import BUSINESS_PARTNERS from 'myNumber/businessPartner/actions'
import DOCUMENT_EMPLOYEES from 'documentEmployees/actions'
import TM from 'tm/actions'
import fetchActions from 'fetchActions'

export {useNotify} from 'hooks/useNotify'

const actions = []

const subActions = {
  EMPLOYEES,
  PROCEDURE_STATUSES,
  OFFICES,
  PROCEDURES,
  SETTINGS,
  AUDITLOGS,
  NOTIFICATIONS,
  REGISTRATION,
  E_GOV_PROCEDURES,
  MASTER: fetchActions,
  BUSINESS_PARTNERS,
  DOCUMENT_EMPLOYEES,
  TM,
}

const mapWithSub = ([actions, sub], fun, path = []) =>
  _.assign(
    ..._.map(actions, (action) => {
      return {[action]: fun(action, path)}
    }),
    ..._.map(sub, (val, key) => {
      return {[key]: mapWithSub(val, fun, [...path, key])}
    })
  )

export const makeActionTypes = (actions, subActions) =>
  mapWithSub([actions, subActions], (action, path) => (path.length > 0 ? `${path.join('/')}/${action}` : action))

export const makeActionCreators = (actions, subActions) =>
  createActions(mapWithSub([actions, subActions], () => (a) => a))

export const actionTypes = makeActionTypes(actions, subActions)

export const actionCreators = makeActionCreators(actions, subActions)

/** @deprecated 新規コードでは store/actions/notify.ts から直接importすること */
actionCreators.notifySuccess = notifySuccess

/** @deprecated 新規コードでは store/actions/notify.ts から直接importすること */
actionCreators.notifyError = notifyError

/** @deprecated 新規コードでは store/actions/asyncError.ts から直接importすること */
actionCreators.asyncError = asyncError

actionCreators.fetchData = (name, promise, state) => ({
  type: 'FETCH_DATA',
  payload: state,
  meta: {
    name,
    promise,
  },
})

actionCreators.fetchDestroy = (name) => ({
  type: 'FETCH_DESTROY',
  meta: {
    name,
  },
})

const empty = {
  loading: false,
}

export const fetchSelector = (state, name) => state.fetchR[name] || empty
