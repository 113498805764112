import React from 'react'
import _ from 'lodash'
import {number} from 'validators'
import {relationshipOtherTypes} from 'employees/form/Dependents'
import {
  Section,
  CheckboxField,
  BoxInner,
  SelectField,
  RadioField,
  TextField,
  DateField,
  Error,
  Label,
} from 'jbc-front/components/Form'
import {toBooleanProps} from 'utils'
import {Field, formValueSelector} from 'redux-form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {constUndef} from 'validators'
import {connect} from 'react-redux'
import styles from 'reportForms/common.scss'

const otherFamilyValidate = (value, values) => {
  if (['no_other_family_support', 'other_family_support', 'living_cost_other'].every((name) => !values[name])) {
    return 'の該当項目をチェックしてください'
  }
}

const renderOtherFamilySupport = ({name, otherFamilySupport, livingCostOther, required}) => (
  <Section title={`被保険者、配偶者以外で${name}の生計費を負担している家族について`}>
    <Field
      component={Error}
      name="_other_family_support"
      label="生計費を負担している家族"
      validate={required ? otherFamilyValidate : constUndef}
    />
    <LabelMapper name="_other_family_support" label="生計費を負担している家族" />
    <CheckboxField name="no_other_family_support" label="生計費を負担している家族なし" />
    <CheckboxField name="other_family_support" label="生計費を負担している家族あり" />
    {otherFamilySupport && (
      <BoxInner>
        <SelectField
          name="support_family_relationship"
          label="続柄"
          options={relationshipOtherTypes}
          creatable
          required={required}
        />
        <TextField name="support_amount" label="負担額" validate={number} yen required={required} />
        <TextField name="cannot_support_reason" label="その家族が、扶養できない理由" required={required} />
      </BoxInner>
    )}
    <CheckboxField name="living_cost_other" label="その他" />
    {livingCostOther && <TextField name="living_cost_other_detail" label="詳細" required={required} />}
  </Section>
)

export const OtherFamilySupport = connect((state, {formName}) => {
  const selector = formValueSelector(formName)
  return {
    otherFamilySupport: selector(state, 'other_family_support'),
    livingCostOther: selector(state, 'living_cost_other'),
  }
})(renderOtherFamilySupport)

const noUnemploymentBenefitsReasons = [
  {value: 'not_enrolled', label: '雇用保険に未加入'},
  {value: 'not_enough_term', label: '加入期間不足'},
  {value: 'receiving_ended', label: '受給終了'},
]

const incomeValidate = (value, values) => {
  if (
    [
      'younger_than_highschool_student',
      'college_student',
      'employment_income',
      'never_worked',
      'over_2years_after_retirement',
      'within_2years_after_retirement',
      'receiving_unemployment_benefits',
      'self_employed',
      'pension_income',
      'receiving_accident_and_sickness_benefits',
      'has_other_income',
    ].every((name) => !values[name])
  ) {
    return 'の該当項目をチェックしてください'
  }
}

const withIn2YearsValidate = (value, values) => {
  if (
    value &&
    [
      'no_unemployment_benefits',
      'no_wish_to_work',
      'planning_to_apply_unemployment_benefits',
      'unemployment_benefits_restricted',
      'postpone_unemployment_benefits',
    ].every((name) => !values[name])
  ) {
    return 'の該当項目をチェックしてください'
  }
}

const pensionIncomeValidate = (value, values) => {
  if (
    value &&
    [
      'old_age_pension',
      'disability_pension',
      'survivors_pension',
      'private_pension',
      'company_pension',
      'other_pension',
    ].every((name) => !values[name])
  ) {
    return 'の該当項目をチェックしてください'
  }
}

const renderDependentIncomeStatus = ({
  title,
  isChild = false,
  employmentIncome,
  over2yearsAfterRetirement,
  within2yearsAfterRetirement,
  noUnemploymentBenefits,
  planningToApplyUnemploymentBenefits,
  unemploymentBenefitsRestricted,
  pensionIncome,
  otherPension,
  hasOtherIncome,
  required,
}) => (
  <Section title={title}>
    <Field component={Error} name="_income" label="就労・収入状況" validate={required ? incomeValidate : constUndef} />
    <LabelMapper name="_income" label="就労・収入状況" />
    {isChild && [
      <CheckboxField
        key="younger_than_highschool_student"
        name="younger_than_highschool_student"
        label="乳児、未就学児童、小中高校生（全日制）"
      />,
      <CheckboxField
        key="college_student"
        name="college_student"
        label="大学・専門・予備校生・通信制・夜間校生　※通信制・夜間校生は在学証明書不可"
      />,
    ]}
    <CheckboxField name="employment_income" label="給与収入（パート・アルバイト等）" />
    {employmentIncome && <TextField name="monthly_income" label="月額" validate={number} yen required={required} />}
    <CheckboxField name="never_worked" label="働いたことがない（パート・アルバイト等を含む）" />
    <CheckboxField name="over_2years_after_retirement" label="現在、退職してから２年以上経過している" />
    {over2yearsAfterRetirement && <DateField name="retired_at" label="退職日" required={required} />}
    <CheckboxField
      name="within_2years_after_retirement"
      label="現在、退職してから2年未満である"
      validate={required ? withIn2YearsValidate : constUndef}
    />
    {within2yearsAfterRetirement && (
      <BoxInner>
        <CheckboxField name="no_unemployment_benefits" label="失業給付の受給権なし" />
        {noUnemploymentBenefits && (
          <SelectField
            name="no_unemployment_benefits_reason"
            options={noUnemploymentBenefitsReasons}
            label="理由"
            required={required}
          />
        )}
        <CheckboxField name="no_wish_to_work" label="就労する意思がないため、失業給付の手続きを行わない" />
        <CheckboxField name="planning_to_apply_unemployment_benefits" label="失業給付を申請予定" />
        {planningToApplyUnemploymentBenefits && (
          <DateField name="unemployment_benefits_submit_on" label="申請予定年月日" required={required} />
        )}
        <CheckboxField name="unemployment_benefits_restricted" label="自己都合による退職のため、待機・給付制限期間中" />
        {unemploymentBenefitsRestricted && (
          <DateField name="unemployment_benefits_start_on" label="受給開始日" required={required} />
        )}
        <CheckboxField name="postpone_unemployment_benefits" label="失業給付の受給期間を延長する" />
      </BoxInner>
    )}
    <CheckboxField
      name="receiving_unemployment_benefits"
      label="失業給付を受給中（60歳未満の方　日額3,612円未満、60歳以上の方　5,000円未満であること）"
    />
    <CheckboxField name="self_employed" label="自営業収入（事業/不動産/販売等）" />
    <CheckboxField
      name="pension_income"
      label="各種年金収入"
      validate={required ? pensionIncomeValidate : constUndef}
    />
    {pensionIncome && (
      <BoxInner>
        <CheckboxField name="old_age_pension" label="老齢" />
        <CheckboxField name="disability_pension" label="障害" />
        <CheckboxField name="survivors_pension" label="遺族" />
        <CheckboxField name="private_pension" label="個人" />
        <CheckboxField name="company_pension" label="企業" />
        <CheckboxField name="other_pension" label="その他" />
        {otherPension && <TextField name="other_pension_detail" label="詳細" required={required} />}
      </BoxInner>
    )}
    <CheckboxField name="receiving_accident_and_sickness_benefits" label="傷病手当金を受給中・手続き中・受給終了" />
    <CheckboxField name="has_other_income" label="その他" />
    {hasOtherIncome && <TextField name="other_income" label="詳細" required={required} />}
  </Section>
)

export const DependentIncomeStatus = connect((state, {formName}) => {
  const selector = formValueSelector(formName)
  return {
    employmentIncome: selector(state, 'employment_income'),
    over2yearsAfterRetirement: selector(state, 'over_2years_after_retirement'),
    within2yearsAfterRetirement: selector(state, 'within_2years_after_retirement'),
    noUnemploymentBenefits: selector(state, 'no_unemployment_benefits'),
    planningToApplyUnemploymentBenefits: selector(state, 'planning_to_apply_unemployment_benefits'),
    unemploymentBenefitsRestricted: selector(state, 'unemployment_benefits_restricted'),
    pensionIncome: selector(state, 'pension_income'),
    otherPension: selector(state, 'other_pension'),
    hasOtherIncome: selector(state, 'has_other_income'),
  }
})(renderDependentIncomeStatus)

const healthInsuranceTypes = [
  {value: 'health_insurance', label: '組合菅掌健康保険組合、全国健康保険協会等'},
  {value: 'voluntary_continuation', label: '任意継続保険'},
  {value: 'national_health_insurance', label: '国民健康保険、無保険'},
]

const insurancePositions = [
  {value: 'employees_support', label: '被保険者の扶養として'},
  {value: 'others_support', label: '被保険者以外の扶養として'},
  {value: 'as_self', label: '本人として'},
]

const lostStatuses = [
  {value: 'true', label: '喪失済み'},
  {value: 'false', label: '未喪失'},
]

const renderDependentHealthInsurance = ({name, healthInsuranceType, healthInsuranceIsLost, required}) => (
  <Section title={`${name}が直近で加入していた（している）健康保険`}>
    <SelectField name="health_insurance_type" options={healthInsuranceTypes} label="健康保険種類" required={required} />
    {(healthInsuranceType === 'health_insurance' || healthInsuranceType === 'voluntary_continuation') && (
      <>
        <SelectField
          name="health_insurance_position"
          label="加入種別"
          options={insurancePositions}
          required={required}
        />
        <SelectField
          name="health_insurance_is_lost"
          label="喪失状況"
          {...toBooleanProps}
          options={lostStatuses}
          required={required}
        />
        {healthInsuranceIsLost && <DateField name="health_insurance_lost_at" label="喪失日" required={required} />}
      </>
    )}
  </Section>
)
export const DependentHealthInsurance = connect((state, {formName}) => {
  const selector = formValueSelector(formName)
  return {
    healthInsuranceType: selector(state, 'health_insurance_type'),
    healthInsurancePosition: selector(state, 'health_insurance_position'),
    healthInsuranceIsLost: selector(state, 'health_insurance_is_lost'),
  }
})(renderDependentHealthInsurance)

const hasSpouseOptions = [
  {value: 'true', label: '配偶者あり'},
  {value: 'false', label: '配偶者なし'},
]

const spouseNotExistsTypes = (isChild) =>
  isChild
    ? [
        {value: 'divorced_or_not_married', label: '離婚、未婚、死別'},
        {value: 'living_separatedly', label: '離婚を前提に別居中'},
      ]
    : [
        {value: 'divorced_or_not_married', label: '離婚、未婚'},
        {value: 'passed_away', label: '死別'},
        {value: 'living_separatedly', label: '離婚を前提に別居中'},
      ]

const hasSpouseValidate = (value, values) => {
  if (
    [
      'spouse_has_income',
      'spouse_not_has_income',
      'apply_spouse_together',
      'spouse_already_recognized',
      'spouse_unemployed',
    ].every((name) => !values[name])
  ) {
    return 'の該当項目をチェックしてください'
  }
}

const renderSpouseInformation = ({
  name,
  required,
  hasSpouse,
  spouseNotExistsType,
  survivorsPensionOthers,
  spouseHasIncome,
  isChild,
}) => (
  <Section title={`${name}の配偶者の状況`}>
    <SelectField
      name="has_spouse"
      {...toBooleanProps}
      options={hasSpouseOptions}
      required={required}
      label="配偶者の状況"
    />
    {hasSpouse && (
      <div>
        <Label text="詳細" />
        <Field
          component={Error}
          name="_has_spouse"
          label="配偶者の状況詳細"
          validate={required ? hasSpouseValidate : constUndef}
        />
        <LabelMapper name="_has_spouse" label="配偶者の状況詳細" />
        <CheckboxField name="spouse_has_income" label="収入あり" />
        {spouseHasIncome && (
          <TextField validate={number} name="spouse_monthly_income" label="月額" yen required={required} />
        )}
        <CheckboxField name="spouse_not_has_income" label="収入なし" />
        <CheckboxField name="apply_spouse_together" label="今回一緒に申請する" />
        <CheckboxField name="spouse_already_recognized" label="既に被扶養者として認定されている" />
        {isChild && <CheckboxField name="spouse_unemployed" label="失業中" />}
      </div>
    )}
    {hasSpouse === false && (
      <div>
        <SelectField
          name="spouse_not_exists_type"
          label="詳細"
          options={spouseNotExistsTypes(isChild)}
          required={required}
        />
        {spouseNotExistsType === 'passed_away' && (
          <BoxInner>
            <Label text="遺族年金の受給がない場合は理由を選択してください" />
            <CheckboxField name="survivors_pension_not_enrolled" label="厚生・共済年金未加入" />
            <CheckboxField name="survivors_pension_not_enough_term" label="加入期間不足" />
            <CheckboxField name="chose_private_pension" label="自分の年金を選択" />
            <CheckboxField name="survivors_pension_others" label="その他" />
            {survivorsPensionOthers && (
              <TextField name="survivors_pension_others_detail" label="理由" required={required} />
            )}
          </BoxInner>
        )}
      </div>
    )}
  </Section>
)
export const SpouseInformation = connect((state, {formName}) => {
  const selector = formValueSelector(formName)
  return {
    hasSpouse: selector(state, 'has_spouse'),
    spouseHasIncome: selector(state, 'spouse_has_income'),
    spouseNotExistsType: selector(state, 'spouse_not_exists_type'),
    survivorsPensionOthers: selector(state, 'survivors_pension_others'),
  }
})(renderSpouseInformation)

const myNumberOptions = [
  {value: 'true', label: 'マイナンバーを出力する'},
  {value: 'false', label: '基礎年金番号を出力する'},
]

const myNumberOptionsDisabled = [
  {value: 'true', label: 'マイナンバーを出力する', disabled: true},
  {value: 'false', label: '基礎年金番号を出力する'},
]

export const MyNumber = ({employee, dependents}) => {
  if (employee?.my_number_present || _.some(dependents, 'my_number_present')) {
    return <RadioField name="is_use_my_number" label="本人確認番号" {...toBooleanProps} options={myNumberOptions} />
  } else {
    return (
      <React.Fragment>
        <RadioField
          name="is_use_my_number"
          label="本人確認番号"
          {...toBooleanProps}
          options={myNumberOptionsDisabled}
        />
        <p className={styles.annotation}>
          マイナンバーを記入する場合は、マイナンバーを入力した後に帳票の編集画面から再度選択してください。
        </p>
      </React.Fragment>
    )
  }
}
