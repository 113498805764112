import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Import} from 'pages/withAuth/adminPage/settings/groups/import'
import {ImportUpdate} from 'pages/withAuth/adminPage/settings/groups/importUpdate'

export const groupRoutes: RouteParams[] = [
  {
    key: 'settings/groups/import',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.GROUPS.IMPORT,
    component: Import,
    exact: true,
    whiteList: ['full_admin', 'office_admin', 'limited_admin'],
  },
  {
    key: 'settings/groups/import_update',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.GROUPS.IMPORT_UPDATE,
    component: ImportUpdate,
    exact: true,
    whiteList: ['full_admin', 'office_admin', 'limited_admin'],
  },
]
