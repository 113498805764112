import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import {useMutation} from 'hooks/graphql'
import {fullNameFormat} from 'libs/formatter'
import {DELETE_CONTRACT, DeleteContractVariables} from './query'
import styles from './CancelModal.scss'

// @ts-ignore
import {actionCreators} from 'actions'

interface Employee {
  staff_code: string
  first_name: string
  last_name: string
  display_first_name: string
  display_last_name: string
}

interface CancelModalProps {
  documentId: string
  employeeId: string
  employee: Employee
  disabled?: boolean
  disabledReason?: string
}

export const CancelModal: FC<CancelModalProps> = ({documentId, employeeId, employee, disabled, disabledReason}) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const [deleteContract, {loading}] = useMutation<unknown, DeleteContractVariables>(DELETE_CONTRACT)

  const handleAccept = async () => {
    await deleteContract({variables: {documentId, employeeId}})
    handleClose()
    dispatch(actionCreators.notifySuccess('電子契約送信を取り消しました'))
  }

  return (
    <>
      <ActionButton
        className={styles.cancelButton}
        onClick={handleOpen}
        disabled={disabled}
        disabledReason={disabledReason}
      >
        取り消す
      </ActionButton>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <Modal.Header onClose={handleClose}>電子契約送信を取り消す</Modal.Header>
        <Modal.Body>
          <p>下記の従業員の電子契約送信を取り消します。</p>
          <ul>
            <li>・{displayEmployeeName(employee)}</li>
          </ul>
          <Spacer size={10} />
          <div className={styles.warning}>
            <p className={styles.warningTitle}>【注意】</p>
            <ul>
              <li>・送信を取り消した従業員に電子契約を再送信することはできません。</li>
              <li>・送信を取り消しても電子契約の課金対象になります。</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button primary disabled={loading} onClick={handleAccept}>
              取り消す
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const displayEmployeeName = (employee: Employee) => {
  const {staff_code, first_name, last_name, display_first_name, display_last_name} = employee
  const fullName = fullNameFormat(display_last_name || last_name, display_first_name || first_name)
  return staff_code ? `${staff_code} ${fullName}` : fullName
}
