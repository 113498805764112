import {Validator} from 'types/validator'

interface Result {
  isValid: boolean
  messages: string[]
}

export const verify = <T>(value: T, validators: Validator<T>[]): Result => {
  const result: Result = {isValid: true, messages: []}

  validators.forEach((validator) => {
    const {isValid, message} = validator(value)
    result.isValid = result.isValid && isValid
    if (message) {
      result.messages.push(message)
    }
  })

  return result
}
