import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'

import {FileField, Section} from 'jbc-front/components/Form'
import {reduxForm, getFormSubmitErrors as _getFormSubmitErrors, SubmissionError} from 'redux-form'
const getFormSubmitErrors = _getFormSubmitErrors('file_import')

import {FileImportDescription, validate} from 'myNumber/businessPartner/FileImport'
import styles from 'employees/FileImport.scss'
import api from 'api'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Question, Upload} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/CommonModal'
import Loading from 'components/Loading'
import image from '../../../images/csv-update-help-business-partner.png'
import onClickOutside from 'react-onclickoutside'

const Form = reduxForm({
  form: 'file_import',
  enableReinitialize: true,
  validate,
})(({submitting, handleSubmit}) => (
  <form onSubmit={handleSubmit} className={`u-mt20 ${styles.form}`}>
    <div className="u-mb40">
      <FileField name="file" />
    </div>
    <Modal
      isOpen={submitting}
      bodyOpenClassName={styles.loadingModalBody}
      className={styles.loadingModal}
      overlayClassName={styles.loadingModalOverlay}
    >
      <Modal.Body>
        <Loading />
      </Modal.Body>
    </Modal>
    <Button as="button" primary type="submit" disabled={submitting} icon={<Upload />} widthWide>
      取引先登録
    </Button>
  </form>
))

const initialValues = {csv_update: true}

const Help = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide()
    }

    render() {
      return (
        <div className={styles.help}>
          <div className={styles.imageBusinessPartner}>
            <img src={image} />
          </div>
          <br />
          取引先データは取引先一覧画面からダウンロードできます
        </div>
      )
    }
  }
)

class FileImportUpdate extends Component {
  state = {
    showHelp: false,
  }

  handleSubmit = (values) => {
    const {token, importEmployees} = this.props
    return importEmployees(token, values)
  }

  render() {
    const {errors} = this.props
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">取引先情報の更新</h1>
        </div>
        {errors && errors.rows && (
          <Section title="ファイル内容に誤りがあります" error>
            <ul>
              {errors.rows.map((err) => (
                <li key={err.index}>
                  第{err.index + 1}行：{err.name}
                  {err.message}
                </li>
              ))}
            </ul>
          </Section>
        )}
        <div className={`u-mt30 u-ta-c ${styles.mainNoteWrap}`}>
          <div className={styles.mainNoteTitle}>更新する取引先情報を記載したファイルを選択してください</div>
          <div className={styles.mainNoteBody}>
            <div className={styles.mainNote}>対応しているファイル形式はCSVとなります</div>
            <div className={styles.mainNote}>
              取引先データを
              <Link to="/my_number/business_partners" className="u-txt-link" target="_blank">
                こちら
              </Link>
              <span
                className={`u-ml5 u-cur-pointer ignore-react-onclickoutside ${styles.helpWrap}`}
                onClick={() => {
                  this.setState({showHelp: !this.state.showHelp})
                }}
              >
                <Question />
                {this.state.showHelp && (
                  <Help
                    hide={() => {
                      this.setState({showHelp: false})
                    }}
                  />
                )}
              </span>
              からダウンロードし、更新したい箇所をご修正ください
            </div>
            <Form onSubmit={this.handleSubmit} initialValues={initialValues} />
          </div>
        </div>
        <div className={`u-mt50 ${styles.warningWrap}`}>
          <p className="u-ta-l">
            <span className={styles.warningTitle}>【注意】</span>
            ①管理コードが一致する取引先情報を更新しますので
            <span className={styles.warning}>正しい管理コードをご記入ください</span>
            <br />
            ②空白の状態で更新すると、<span className={styles.warning}>データが削除されますのでご注意ください</span>
            <br />
          </p>
        </div>
        <div className="u-mt50">
          <FileImportDescription update />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    errors: getFormSubmitErrors(state),
  }),
  (dispatch) => ({
    async importEmployees(token, values) {
      try {
        await api.createWithAuth(token).businessPartners.import(values)
        dispatch(push('/my_number/business_partners'))
        dispatch(notifySuccess('インポートしました'))
      } catch (err) {
        if (err.response && err.response.status == 422 && _.get(err.response, 'data._errors')) {
          throw new SubmissionError(err.response.data._errors)
        }
        dispatch(asyncError(err))
      }
    },
  })
)(FileImportUpdate)
