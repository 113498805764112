import React, {Component} from 'react'
import {connect} from 'react-redux'
import {MultiTagFilteringFieldRow} from 'jbc-front/components/SearchForm'
import {fetchSelector, actionCreators} from 'actions'
import api from 'api'
import {withNull, idToStr} from 'employees/list/addedFields/utils'

class Countries extends Component {
  static defaultProps = {
    countries: [],
  }
  componentDidMount() {
    const {dispatch, token} = this.props
    const authedAPi = api.createWithAuth(token)
    dispatch(
      actionCreators.fetchData(
        'country_selections',
        authedAPi.employees.selections.countries().then((res) => ({
          countries: res.data.map(idToStr),
        }))
      )
    )
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('country_selections'))
  }

  render() {
    const {countries} = this.props
    return <MultiTagFilteringFieldRow header="居住国" fieldName="countries" list={withNull(countries)} />
  }
}
export default connect((state) => ({
  token: state.auth.token,
  countries: fetchSelector(state, 'country_selections').countries,
}))(Countries)
