import fetchActions from 'fetchActions'

const actions = ['SET_QUERY']

const subActions = {
  LIST: fetchActions,
  CURRENT: fetchActions,
}

export default [actions, subActions]
