import React from 'react'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Edit} from 'jbc-front/components/icons'
import {HashLink} from 'react-router-hash-link'
import {Dl, Dd, Dt, Grid} from 'employees/show/Parts'

const EmploymentInsuranceInformation = ({employee, yearStyle, editLink}) => {
  const employmentInsurance = employee.employment_insurance || {}
  const endOn = employmentInsurance?.end_on || employee?.retired_at
  const employmentInsuranceImage = employmentInsurance.image || {}
  return (
    <Panel>
      <HashLink to={`${editLink}#EmploymentInsuranceInfomation`}>
        <Headding icon={<Edit icon={16} />}>雇用保険</Headding>
      </HashLink>
      <Item>
        <Grid>
          <Dl>
            <Dt>加入状況</Dt>
            <Dd>{recordDisplay.boolean(employmentInsurance.joined, '加入', '未加入')}</Dd>
          </Dl>
          <Dl>
            <Dt>雇用保険 被保険者番号</Dt>
            <Dd>{recordDisplay.numbers(employmentInsurance.joined && employmentInsurance, 'person_number', 3)}</Dd>
          </Dl>
          <Dl>
            <Dt>資格取得日</Dt>
            <Dd>{recordDisplay.date(employmentInsurance.joined && employmentInsurance.start_on, yearStyle)}</Dd>
          </Dl>
          <Dl>
            <Dt>離職等年月日</Dt>
            <Dd>{recordDisplay.date(employmentInsurance.joined && endOn, yearStyle)}</Dd>
          </Dl>
          {employmentInsurance.need_detail && (
            <>
              <Dl>
                <Dt>雇用保険被保険者番号が分からない</Dt>
                <Dd>はい</Dd>
              </Dl>
              <Dl>
                <Dt>前職の会社名</Dt>
                <Dd>{recordDisplay(employmentInsurance.last_company_name)}</Dd>
              </Dl>
              <Dl>
                <Dt>前職の会社住所</Dt>
                <Dd>{recordDisplay(employmentInsurance.last_company_address)}</Dd>
              </Dl>
            </>
          )}
          {employmentInsuranceImage.filename && (
            <Dl>
              <Dt>雇用保険被保険者証画像</Dt>
              <Dd>{recordDisplay.file(employmentInsurance.image)}</Dd>
            </Dl>
          )}
        </Grid>
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmploymentInsuranceInformation)
