import React, {Component} from 'react'
import PrepareForm, {selector, destroy, autofill} from 'procedureStatuses/eGov/PrepareForm'
import styles from 'procedureStatuses/eGov/PrepareModal.scss'
import Modal from 'react-modal'
import {Close} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import _ from 'lodash'
import {getState} from 'utils'
import api from 'api'
import {asyncError} from 'store/actions/asyncError'
import {getBaseErrors} from 'libs/errorHandler'

class PrepareModal extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
    const {dispatch} = this.props
    dispatch(destroy())
  }

  handleEditClick = ({num, dependentId}) => {
    const {procedureStatusId} = this.props
    let editUrl = `/procedure_statuses/${procedureStatusId}/report_edit`
    if (dependentId) {
      editUrl += `?dependent_id=${dependentId}`
    }
    editUrl += `#${_.toUpper(num)}`
    this.props.history.push(editUrl)
  }

  handleSubmit = async (values) => {
    const {dispatch, loadProcedureStatus} = this.props
    try {
      const state = await getState(dispatch)
      const token = state.auth.token
      const procedureCode = selector(state, 'procedure_code')
      const info = state.procedureStatuses.current.data.egov_informations.find(
        (info) => info.procedure_code === procedureCode
      )
      const formData = [
        ['procedure_code', procedureCode],
        ['procedure_status_id', state.procedureStatuses.current.data.id],
        ...(function* () {
          const recommendedAttachments = _.groupBy(
            _.zip(info.recommended_attachments, values.attachments),
            ([req]) => req.form_id
          )
          let index = 0
          for (const formId of info.form_id) {
            yield ['applications[]form_id', formId]
            if (index == 0 && !_.isEmpty(values.attachments_other)) {
              for (const file of values.attachments_other) {
                yield ['applications[]attachments[]document_name', 'その他']
                yield ['applications[]attachments[]file', file]
              }
            }
            let attachments = recommendedAttachments[formId] || []
            for (const [req, file] of attachments) {
              if (file) {
                yield ['applications[]attachments[]document_name', req.key]
                yield ['applications[]attachments[]file', file]
              }
            }
            index++
          }
        })(),
      ].reduce((formData, [key, value]) => {
        formData.append(key, value)
        return formData
      }, new FormData())
      await api.createWithAuth(token).eGovProcedure.prepare(formData)
      this.props.hideModal()
      dispatch(destroy())
      loadProcedureStatus()
    } catch (err) {
      if (err.response && err.response.status === 422) {
        const errors =
          err.response.data._errors.validationErrors ||
          [...getBaseErrors(err.response.data)].map((e) => ({errorMessage: e}))

        if (!_.isEmpty(errors)) {
          dispatch(autofill('_step', 'error_list'))
          dispatch(autofill('_errors', errors))
        } else {
          dispatch(asyncError(err))
        }
      } else {
        dispatch(asyncError(err))
      }
    }
  }

  hideModal = () => {
    const {hideModal, dispatch} = this.props
    hideModal()
    dispatch(destroy())
  }

  render() {
    const {isOpen, step, pdfShow} = this.props
    return (
      <React.Fragment>
        <Modal
          className={styles.modal}
          isOpen={isOpen && !pdfShow}
          overlayClassName={styles.overlay}
          contentLabel="Modal"
        >
          <div className={styles.header}>
            <div className={styles.title}>{step ? '新規申請内容' : '電子申請可能な書類'}</div>
            <div className={styles.tools}>
              <Close className={styles.close} onClick={this.hideModal} />
            </div>
          </div>
          {isOpen && <PrepareForm hideModal={this.hideModal} onSubmit={this.handleSubmit} />}
        </Modal>
      </React.Fragment>
    )
  }
}

export default connect((state) => ({
  step: selector(state, '_step'),
  pdfShow: state.previewPdf.show,
}))(PrepareModal)
