import React, {FC, useEffect, useState} from 'react'
import {useApolloClient} from '@apollo/client'
import {Close} from 'jbc-front/components/icons'
import {useCancellable} from 'hooks/useCancellable'
import {fullNameFormat} from 'libs/formatter'
import {handleQueryError} from 'libs/graphql'
import {useDispatch} from 'react-redux'
import {
  CONTRACT_DOCUMENT_TARGET,
  ContractDocumentTarget,
  ContractDocumentTargetResult,
  ContractDocumentTargetVariables,
} from './query'
import styles from './ContractTargetWarnings.scss'

interface Warning {
  employee: ContractDocumentTarget
  cause: string
}

const validateContractTarget = (employee: ContractDocumentTarget): Warning[] => {
  const warnings = []

  const fullName = fullNameFormat(employee.display_last_name, employee.first_name)
  if ([...fullName].length > 60) {
    warnings.push({
      employee,
      cause: '氏名が60文字を超えています。依頼メールに氏名が正しく表示されない可能性があります。',
    })
  }

  return warnings
}

interface ContractTargetWarningsProps {
  documentId: string
}

export const ContractTargetWarnings: FC<ContractTargetWarningsProps> = ({documentId}) => {
  const [visible, setVisible] = useState(true)
  const [warnings, setWarnings] = useState<Warning[]>([])

  const dispatch = useDispatch()
  const apolloClient = useApolloClient()
  const runCancellable = useCancellable('ContractTargetWarnings')
  useEffect(() => {
    runCancellable(async (isCancelled) => {
      setVisible(true)
      setWarnings([])

      try {
        const PAGE_SIZE = 100
        for (let page = 1; ; page++) {
          if (isCancelled()) return

          // 対象従業員を1ページ取得
          const {
            data: {
              client: {
                document: {contractTargets},
              },
            },
          } = await apolloClient.query<ContractDocumentTargetResult, ContractDocumentTargetVariables>({
            query: CONTRACT_DOCUMENT_TARGET,
            fetchPolicy: 'network-only',
            variables: {
              id: documentId,
              per: PAGE_SIZE,
              page,
              search: {sortColumn: 'staff_code', sortOrder: 'asc'},
            },
          })

          // 1ページ分バリデーション
          for (const employee of contractTargets.list) {
            const warns = validateContractTarget(employee)
            if (warns.length > 0) {
              setWarnings((prev) => [...prev, ...warns])
            }
          }

          // 次のページがなさそうなら終了
          if (contractTargets.list.length < PAGE_SIZE) {
            break
          }
        }
      } catch (error) {
        handleQueryError(error, dispatch)
      }
    })
  }, [documentId, dispatch, apolloClient, runCancellable])

  if (warnings.length === 0 || !visible) {
    return null
  } else {
    return (
      <div className={styles.warningBox}>
        <div className={styles.warningBoxText}>
          以下のエラーが発生しています
          <Close className={styles.closeButton} onClick={() => setVisible(false)} />
        </div>
        {warnings.map((warning, index) => {
          const fullName = fullNameFormat(warning.employee.display_last_name, warning.employee.display_first_name)
          const fullNameWithStaffCode = `${warning.employee.staff_code} ${fullName}`.trim()

          return (
            <li key={index}>
              {fullNameWithStaffCode}さんの{warning.cause}
            </li>
          )
        })}
      </div>
    )
  }
}
