import {gql} from '@apollo/client'

const CONTRACT_TARGET_FRAGMENT = gql`
  fragment ContractTargetFields on Employee {
    id
    staff_code: staffCode
    first_name: firstName
    last_name: lastName
    display_last_name: displayLastName
    display_first_name: displayFirstName
    email
  }
`

export const CONTRACT_DOCUMENT_TARGET = gql`
  query contractDocumentTarget($id: ID!, $per: Int, $page: Int, $search: ContractTargetSearchInput) {
    client {
      id
      document(id: $id) {
        id
        contractTargets(per: $per, page: $page, search: $search) {
          totalCount
          list {
            ...ContractTargetFields
          }
        }
      }
    }
  }
  ${CONTRACT_TARGET_FRAGMENT}
`

export interface ContractDocumentTargetVariables {
  id: string
  per?: number
  page?: number
  search?: ContractTargetSearchInput
}

type ContractTargetSearchInput = Partial<{
  sortOrder: 'asc' | 'desc' | null
  sortColumn: 'staff_code' | 'full_name_kana' | 'email' | null
}>

export interface ContractDocumentTargetResult {
  client: {
    id: string
    document: {
      id: string
      contractTargets: {
        totalCount: number
        list: ContractDocumentTarget[]
      }
    }
  }
}

export interface ContractDocumentTarget {
  id: string
  staff_code: string | null
  first_name: string
  last_name: string
  display_last_name: string
  display_first_name: string
  email: string | null
}
