import {createSlice} from '@reduxjs/toolkit'
import type {PayloadAction} from '@reduxjs/toolkit'
import type {Auth} from 'types/store/auth'

const initialState = {
  token: '',
} as Auth

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    logout: () => initialState,
  },
})

export const {setToken, logout} = authSlice.actions
export default authSlice.reducer
