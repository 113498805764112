import _ from 'lodash'
import readBlob from 'readBlob'
import postWithFile, {bulkedToFormDataPaires, toFormData} from 'api/postWithFile'
import {formatValues} from 'employees/utils'

export const toBase64 = (buffer) =>
  btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''))

export const toArrayBuffer = ({data}) => readBlob(data).arrayBuffer()

const procedureStatuses = (roumuAxios) => ({
  list(query, embed = []) {
    let procedure_type = ''
    switch (query.procedure_type) {
      case 'change_dependents':
        procedure_type = ['add_dependents', 'remove_dependents']
        break
      case 'maternity_leave':
        procedure_type = ['start_maternity', 'extend_maternity', 'finish_maternity']
        break
      default:
        procedure_type = query.procedure_type
    }
    return roumuAxios
      .get('/api/procedure_statuses', {
        params: {
          _page: query.page,
          status: query.status,
          limit: query.per_page,
          q: query.search_keyword,
          procedure_type: procedure_type,
          employee_id: query.employee_id,
          embed,
        },
      })
      .then((res) => {
        const count = res.headers['x-total-count']
        return {data: res.data, count: count}
      })
  },
  get(id, embed = [], params = {}) {
    return roumuAxios
      .get(`/api/procedure_statuses/${id}`, {
        params: {
          ...params,
          embed,
        },
      })
      .then((res) => {
        return {
          data: res.data,
        }
      })
  },
  update(id, params) {
    return roumuAxios.put(`/api/procedure_statuses/${id}`, params)
  },
  updateFromEmployee(id, params) {
    return roumuAxios.put(`/api/procedure_statuses/${id}/update_from_employee`, params)
  },
  delete(id) {
    return roumuAxios.delete(`/api/procedure_statuses/${id}`)
  },
  start(employeeId, procedureType, dateOn, status = 'draft') {
    return roumuAxios
      .post('/api/procedure_statuses', {
        employee_id: employeeId,
        procedure_type: procedureType,
        date_on: dateOn,
        status,
      })
      .catch((res) => {
        const errors = _.get(res, 'response.data.errors')
        if (errors && errors.length > 0) {
          throw new Error(errors[0])
        }
        throw res
      })
  },
  apply(procedureStatusId, comment) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/apply`, {comment})
  },
  accept(procedureStatusId) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/accept`)
  },
  reject(procedureStatusId, comment) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/reject`, {comment})
  },
  startProgress(procedureStatusId) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/start_progress`)
  },
  summary() {
    return roumuAxios.get(`/api/procedure_statuses/summary`).then((res) => ({data: res.data}))
  },

  draft: {
    update(procedureStatusId, data) {
      return roumuAxios.put(`/api/procedure_statuses/${procedureStatusId}/draft`, {data: JSON.stringify(data)})
    },
  },
  tmpData: {
    mapByName(procedureStatusId, type, name) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data`, {
          params: _.pickBy({
            name,
            procedure_tmp_type: type,
          }),
        })
        .then((res) => {
          return {
            data: _.keyBy(res.data, 'name'),
          }
        })
    },
    mapToJson(procedureStatusId, {type, name}) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data`, {
          params: _.pickBy({
            name,
            procedure_tmp_type: type,
          }),
        })
        .then((res) => {
          return {
            data: _.mapValues(
              _.keyBy(res.data, (tmpData) => `${tmpData.procedure_tmp_type}_${tmpData.name}`),
              ({data}) => {
                try {
                  return JSON.parse(data)
                } catch (err) {
                  return null
                }
              }
            ),
          }
        })
    },
    async updateJson(procedureStatusId, type, name, updateData) {
      const {data: tmpData} = await roumuAxios.get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data`, {
        params: _.pickBy({
          name,
          procedure_tmp_type: type,
        }),
      })
      const id = _.get(tmpData, '[0].id')
      const data = (() => {
        try {
          if (tmpData.length === 0) {
            return undefined
          }
          return JSON.parse(tmpData[0].data) || undefined
        } catch (err) {
          return undefined
        }
      })()
      const newData = formatValues(updateData(data))
      if (id) {
        return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data/${id}`, {
          data: JSON.stringify(newData),
        })
      } else {
        return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data`, {
          data: JSON.stringify(newData),
          name,
          procedure_tmp_type: type,
        })
      }
    },
    create(procedureStatusId, data, type, name) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data`, {
        data: JSON.stringify(data),
        name,
        procedure_tmp_type: type,
      })
    },
    update(procedureStatusId, data, id) {
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data/${id}`, {
        data: JSON.stringify(data),
      })
    },
    async createOrUpdate(procedureStatusId, data, type, name) {
      const {data: tmpData} = await roumuAxios.get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data`, {
        params: _.pickBy({
          name,
          procedure_tmp_type: type,
        }),
      })
      const id = _.get(tmpData, '[0].id')
      if (id) {
        return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data/${id}`, {
          data: JSON.stringify(data),
        })
      } else {
        return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_data`, {
          data: JSON.stringify(data),
          name,
          procedure_tmp_type: type,
        })
      }
    },
  },
  tmpFiles: {
    list(procedureStatusId, type, name) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_files`, {
          params: _.pickBy({
            name,
            procedure_tmp_type: type,
          }),
        })
        .then((res) => ({
          tmpFiles: res.data,
        }))
    },
    singleFile(procedureStatusId, type, name) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_files`, {
          params: _.pickBy({
            name,
            procedure_tmp_type: type,
          }),
        })
        .then((res) => {
          const file = res.data.length > 0 ? res.data[0].file : null
          return {
            tmpFile: file,
          }
        })
    },
    async createOrUpdate(procedureStatusId, file, type, name) {
      const {data: tmpFile} = await roumuAxios.get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_files`, {
        params: {name, procedure_tmp_type: type},
      })
      const id = _.get(tmpFile, '[0].id')
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', name)
      formData.append('procedure_tmp_type', type)
      if (id) {
        return roumuAxios.put(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_files/${id}`, formData)
      } else {
        return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_files`, formData)
      }
    },
    delete(procedureStatusId, tmpFileId) {
      return roumuAxios.delete(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_files/${tmpFileId}`)
    },
    async getTmpFileID(procedureStatusId, type, name) {
      const {data: tmpFile} = await roumuAxios.get(`/api/procedure_statuses/${procedureStatusId}/procedure_tmp_files`, {
        params: {name, procedure_tmp_type: type},
      })
      return tmpFile?.[0]?.id
    },
    async bulkPost(procedureStatusId, newFiles, keepFileNames, type, name) {
      const formData = new FormData()
      newFiles.forEach((newFile) => formData.append(`new_files[]`, newFile))
      keepFileNames.forEach((keepFileName) => formData.append(`keep_file_names[]`, keepFileName))
      formData.append('name', name)
      formData.append('procedure_tmp_type', type)
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/bulked_procedure_tmp_files`, formData)
    },
  },
  moveTmpFilesToDb(procedureStatusId, rejectFiles = []) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/move_tmp_files_to_db`, {
      reject_files: rejectFiles,
    })
  },
  procedureComments: {
    list(id) {
      if (!id) {
        return [{}]
      }
      return roumuAxios.get(`/api/procedure_statuses/${id}/procedure_comments`).then((res) => ({data: res.data}))
    },
  },
  reports: {
    get(procedureStatusId, report, params = null) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/report_param_sets/${report}`, params ? {params} : undefined)
        .then((res) => {
          return {
            [report]: res.data,
          }
        })
    },
    create(procedureStatusId, report, data) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/report_param_sets/${report}`, data)
    },
    update(procedureStatusId, report, data) {
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/report_param_sets/${report}`, data)
    },
    getPdf(procedureStatusId, report, params = {}) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/pdf`, {
          responseType: 'blob',
          params: {
            type: report,
            ...params,
          },
        })
        .then(toArrayBuffer)
        .then(toBase64)
    },
    getPdfToBeUpload(procedureStatusId, report, params = {}) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/pdf_to_be_uploaded`, {
          responseType: 'blob',
          params: {
            type: report,
            ...params,
          },
        })
        .then(toArrayBuffer)
        .then(toBase64)
    },
    importR60Csv(procedureStatusId, params) {
      return postWithFile(
        params,
        ['file'],
        roumuAxios.put.bind(roumuAxios, `/api/procedure_statuses/${procedureStatusId}/report_param_sets/r60_csv`)
      )
    },
  },
  clearRelatedRecords(procedureStatusId) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/clear_procedure_related_records`)
  },
  updateTodoAll(procedureStatusId, status) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/all_todos`, {status: status})
  },
  refreshTodoLists(procedureStatusId) {
    return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/refresh_todo_lists`)
  },
  addDependentsData: {
    list(procedureStatusId) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/procedure_add_dependents_data`)
        .then((res) => ({data: res.data}))
    },
    create(procedureStatusId, dependentId) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_add_dependents_data`, {
        employee_dependent_id: dependentId,
      })
    },
    delete(procedureStatusId, addDependentsDataId) {
      return roumuAxios.delete(
        `/api/procedure_statuses/${procedureStatusId}/procedure_add_dependents_data/${addDependentsDataId}`
      )
    },
    deleteDependents(procedureStatusId, dependentIds) {
      return roumuAxios.post(
        `/api/procedure_statuses/${procedureStatusId}/procedure_add_dependents_data/delete_employee_dependents`,
        {ids: dependentIds}
      )
    },
    createDependent(procedureStatusId, data) {
      return roumuAxios.post(
        `/api/procedure_statuses/${procedureStatusId}/procedure_add_dependents_data/employee_dependents`,
        data
      )
    },
    updateAll(procedureStatusId, data) {
      return roumuAxios.post(
        `/api/procedure_statuses/${procedureStatusId}/bulked_procedure_add_dependents`,
        toFormData([
          ...bulkedToFormDataPaires(data, 'employee_dependents', [
            'related_to_relatives_document',
            'related_to_remittance_document',
            'proving_study_abroad_document',
            'handicap_certificate',
          ]),
        ])
      )
    },
  },
  maternity: {
    reportBirth(procedureStatusId) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/report_birth`)
    },
  },
  startMaternityData: {
    create(procedureStatusId, data) {
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      data.mother_child_note?.forEach((note) => {
        formData.append(note._id, note)
      })
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_start_maternity_datum`, formData)
    },
    update(procedureStatusId, data) {
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      data.mother_child_note?.forEach((note) => {
        formData.append(note._id, note)
      })
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_start_maternity_datum`, formData)
    },
  },
  extendMaternityData: {
    create(procedureStatusId, data) {
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      data.mother_child_note?.forEach((note) => {
        formData.append(note._id, note)
      })
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_extend_maternity_datum`, formData)
    },
    update(procedureStatusId, data) {
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      data.mother_child_note?.forEach((note) => {
        formData.append(note._id, note)
      })
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_extend_maternity_datum`, formData)
    },
  },
  finishMaternityData: {
    create(procedureStatusId, data) {
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      data.mother_child_note?.forEach((note) => {
        formData.append(note._id, note)
      })
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_finish_maternity_datum`, formData)
    },
    update(procedureStatusId, data) {
      const formData = new FormData()
      formData.append('json', JSON.stringify(data))
      data.mother_child_note?.forEach((note) => {
        formData.append(note._id, note)
      })
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_finish_maternity_datum`, formData)
    },
  },
  childcareData: {
    create(procedureStatusId, data) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_childcare_datum`, data)
    },
  },
  changeNameData: {
    create(procedureStatusId, data) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_change_name_datum`, data)
    },
    update(procedureStatusId, data) {
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_change_name_datum`, data)
    },
    delete(procedureStatusId) {
      return roumuAxios.delete(`/api/procedure_statuses/${procedureStatusId}/procedure_change_name_datum`)
    },
  },
  removeDependentsData: {
    list(procedureStatusId) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/procedure_remove_dependents_data`)
        .then((res) => ({data: res.data}))
    },
    create(procedureStatusId, dependentId) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_remove_dependents_data`, {
        employee_dependent_id: dependentId,
      })
    },
    delete(procedureStatusId, addDependentsDataId) {
      return roumuAxios.delete(
        `/api/procedure_statuses/${procedureStatusId}/procedure_remove_dependents_data/${addDependentsDataId}`
      )
    },
  },
  changeAddressData: {
    create(procedureStatusId, data) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum`, data)
    },
    update(procedureStatusId, data) {
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum`, data)
    },
    delete(procedureStatusId) {
      return roumuAxios.delete(`/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum`)
    },
    depData: {
      create(procedureStatusId, data) {
        return roumuAxios.post(
          `/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum/procedure_change_address_dep_data`,
          data
        )
      },
      update(procedureStatusId, data) {
        return roumuAxios.patch(
          `/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum/procedure_change_address_dep_data/${data.id}`,
          data
        )
      },
      delete(procedureStatusId, depDataId) {
        return roumuAxios.delete(
          `/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum/procedure_change_address_dep_data/${depDataId}`
        )
      },
      async updateAll(procedureStatusId, data) {
        const {data: list} = await roumuAxios.get(
          `/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum/procedure_change_address_dep_data`
        )
        const removed = list.filter((dep) => !data.find((data) => data.id === dep.id))
        for (const dep of removed) {
          await roumuAxios.delete(
            `/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum/procedure_change_address_dep_data/${dep.id}`
          )
        }
        for (const dep of data) {
          if (dep.id) {
            await roumuAxios.patch(
              `/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum/procedure_change_address_dep_data/${dep.id}`,
              dep
            )
          } else {
            await roumuAxios.post(
              `/api/procedure_statuses/${procedureStatusId}/procedure_change_address_datum/procedure_change_address_dep_data`,
              dep
            )
          }
        }
      },
    },
  },
  resignEmployeeInputData: {
    create(procedureStatusId, data) {
      return roumuAxios.post(`/api/procedure_statuses/${procedureStatusId}/resign_employee_input_datum`, data)
    },
    update(procedureStatusId, data) {
      return roumuAxios.patch(`/api/procedure_statuses/${procedureStatusId}/resign_employee_input_datum`, data)
    },
    delete(procedureStatusId) {
      return roumuAxios.delete(`/api/procedure_statuses/${procedureStatusId}/resign_employee_input_datum`)
    },
  },
  checkNeedEmployeeUpdate: {
    get(procedureStatusId) {
      return roumuAxios
        .get(`/api/procedure_statuses/${procedureStatusId}/check_need_employee_updates`)
        .then((res) => ({data: res.data}))
    },
  },
})

export default procedureStatuses
