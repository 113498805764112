import React from 'react'
import Flow from 'procedures/Flow'
import _ from 'lodash'

export const makeFlow = function* (employee) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'its') {
      yield 'R13'
    } else {
      yield 'R12'
    }
  }
  if (welfarePensionInsuranceJoined && employee.national_type === 'foreigner') {
    yield 'R55'
  }
}

const ChangeNameFlow = ({location, match}) => <Flow {...{makeFlow, location, match}} />

export default ChangeNameFlow
