import {FC} from 'react'
import {Table} from 'components/ui/Table'
import {PreviewColumn} from './PreviewColumn'
import {DocumentEmployee} from '../query'
import {displayFormat, dateFormat, fullNameFormat} from 'libs/formatter'
import styles from './Row.scss'
import {PreparingColumn} from './PreparingColumn'

type RowProps = {
  documentEmployee: DocumentEmployee
}

export const Row: FC<RowProps> = ({documentEmployee}) => {
  const createdUser = documentEmployee.document.createdUser
  const fullName = (() => {
    if (createdUser) {
      if (createdUser.employee) {
        const {displayLastName, displayFirstName} = createdUser.employee
        return fullNameFormat(displayLastName, displayFirstName)
      }

      return createdUser.email
    }

    return displayFormat()
  })()

  const contractStatus = (() => {
    switch (documentEmployee.contractInfo?.status) {
      case 'signed':
        return '署名済'
      case 'unsigned':
        return '未署名'
      case 'canceled':
        return <span className={styles.canceledText}>取消済</span>
      default:
        return displayFormat()
    }
  })()

  const signedButNotDownloadable =
    documentEmployee.contractInfo?.status === 'signed' && !documentEmployee.contractInfo?.downloadableSignedDocument

  return (
    <Table.Tr key={`document-table-row-${documentEmployee.document.id}`}>
      <Table.Td>{dateFormat(documentEmployee.publishedAt, 'L')}</Table.Td>
      <Table.Td>{documentEmployee.document.title}</Table.Td>
      <Table.Td>{fullName}</Table.Td>
      <Table.Td>{contractStatus}</Table.Td>
      <Table.Td>
        {signedButNotDownloadable ? <PreparingColumn /> : <PreviewColumn document={documentEmployee.document} />}
      </Table.Td>
    </Table.Tr>
  )
}
