import React from 'react'
import {connect} from 'react-redux'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {Section, TextField, SelectField, RadioField} from 'jbc-front/components/Form'
import {formValueSelector, reduxForm} from 'redux-form'
import {Phone, prefectureOptions} from 'FormFields'
import compose from 'lodash/fp/compose'

const formName = 'laborConsultantForm'
const selector = formValueSelector(formName)

export const applicationTypes = [
  {value: 'client', label: '事業主'},
  {value: 'labor_consultant', label: '社会保険労務士'},
]

export const agentTypes = [
  {value: 'submission_agent', label: '提出代行者'},
  {value: 'office_agent', label: '事務代理者'},
]

const Form = ({handleSubmit, pristine, submitting, applicationType}) => (
  <form onSubmit={handleSubmit}>
    <FormErrors />
    <Section title="社労士情報">
      <TextField name="name" label="社労士名" />
      <TextField name="name_kana" label="社労士名（カナ）" />
      <TextField name="labor_consultant_code" label="社労士コード" />
      <RadioField name="application_type" label="提出元" options={applicationTypes} />
      {applicationType && applicationType === 'labor_consultant' && (
        <SelectField name="agent_type" label="代理区分" options={agentTypes} />
      )}
      <SelectField name="prefecture_id" label="社会保険労務士都道府県会" options={prefectureOptions} />
      <TextField name="labor_consultant_office_name" label="社労士事務所名" />
      <Phone />
    </Section>
    <div className="u-ta-c u-mt30">
      <Button primary onClick={handleSubmit} disabled={pristine || submitting}>
        更新
      </Button>
    </div>
  </form>
)

export default compose(
  connect((state) => ({
    applicationType: selector(state, 'application_type'),
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })
)(Form)
