import React, {Component} from 'react'
import Form, {makeInitialValues, formatValues} from 'procedures/changeAddress/Form'
import Master from 'Master'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {updateEmployeeDetail} from 'employees/Update'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import _ from 'lodash'
import {asyncError} from 'store/actions/asyncError'

class Detail extends Component {
  componentDidMount() {
    const {
      loadData,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    loadData(employeeId, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {employee, healthInsuranceType} = this.props
    return (
      <>
        <Master masters={['nations']} />
        <Form
          onSubmit={this.handleSubmit}
          initialValues={makeInitialValues(employee, healthInsuranceType)}
          healthInsuranceType={healthInsuranceType}
          employee={employee}
        />
      </>
    )
  }

  handleSubmit = (values) => {
    const {
      updateAndCreateProcedure,
      location: {search},
      token,
      healthInsuranceType,
      nations,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    return updateAndCreateProcedure(employeeId, values, token, healthInsuranceType, nations)
  }
}

export default connect(
  (state) => ({
    employee: state.employees.current.data,
    healthInsuranceType: _.get(state.employees.current.data, 'office.health_insurance_type'),
    token: state.auth.token,
    nations: state.master.nations,
  }),
  (dispatch) => ({
    loadData: (id, token) => {
      dispatch(
        actionCreators.employees.current.fetchData(
          api.createWithAuth(token).employees.get(id, ['employee_dependents', 'commuting_expenses', 'office'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
    },
    updateAndCreateProcedure: async (employeeId, values, token, healthInsuranceType, nations) => {
      const {employee, procedureData, residentCard, commutingExpenses, dependentPrecedureData, dependents, dateOn} =
        formatValues(values, healthInsuranceType, nations)
      try {
        const authedApi = api.createWithAuth(token)
        const {
          data: {id},
        } = await authedApi.procedureStatuses.start(employeeId, 'change_address', dateOn)
        await authedApi.procedureStatuses.changeAddressData.create(id, procedureData)
        if (dependentPrecedureData) {
          await authedApi.procedureStatuses.changeAddressData.depData.updateAll(id, dependentPrecedureData)
        }
        await updateEmployeeDetail(
          employeeId,
          {employee, commutingExpenses, dependents, residentCard},
          token,
          ['residence_card'],
          null,
          {
            dependentsUpdateOnly: true,
          }
        )
        dispatch(push(`/change_address/flow/${id}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Detail)
