import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import _ from 'lodash'
import {recordDisplay} from 'utils'
import styles from 'myNumber/List.scss'
import {Filter, Pager, PerPage, ListData, OrderedTh, Search} from 'employees/list/components'
import {actionCreators} from 'actions'
import api from 'api'
import Modal from 'jbc-front/components/Modal'
import {getGroups, employmentStatuses} from 'employees/form/EmploymentInfomation'
import {Field, reduxForm, submit} from 'redux-form'
import {invitationStatuses} from 'employees/Invite'
import Request from 'myNumber/Request'
import MultiRequest from 'myNumber/MultiRequest'
import {bindActionCreators} from 'redux'
import {downloadFile} from 'libs/downloadFile'
import {Tab} from 'components/Tabs'
import {renderRadioField} from 'employees/CsvDownload'
import {required} from 'jbc-front/utils/validators'
import {Download} from 'jbc-front/components/icons'
import AsyncTaskError from 'AsyncTaskError'
import {withAsyncTask} from 'AsyncTask'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const submitDownload = submit.bind(null, 'download')
export const DownloadForm = reduxForm({form: 'download'})(() => (
  <>
    <Field
      component={renderRadioField}
      label="ファイル形式選択"
      name="encoding"
      options={downloadOptions}
      validate={required}
    />
    <div className={styles.note}>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください</div>
  </>
))

const employmentStatusOptions = {
  all: 'すべて',
  employed_and_joined: '在職及び内定',
  vacation: '休職中',
  unemployed: '退職済',
}

export const tabs = [
  {label: '従業員', value: 'employee', handleClick: () => undefined, active: true},
  {
    label: '取引先',
    value: 'business_partners',
    handleClick: (history) => () => history.push('/my_number/business_partners'),
    active: false,
  },
]

class EmploymentStatusComponent extends Component {
  handleChange = (e) => {
    const {query, setQuery} = this.props
    setQuery({...query, page: 1, my_number_employment_status: e.target.value})
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {query, setQuery, ...rest} = this.props
    /* eslint-enable no-unused-vars */
    return (
      <select
        type="select"
        onChange={this.handleChange}
        className={styles.select}
        value={query.my_number_employment_status}
        {...rest}
      >
        {_.map(employmentStatusOptions, (value, key) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    )
  }
}

const EmploymentStatus = connect(
  (state) => ({
    query: state.employees.query,
  }),
  (dispatch) => ({
    setQuery: (query) => dispatch(actionCreators.employees.setQuery(query)),
  })
)(EmploymentStatusComponent)

class Row extends Component {
  handleClick = () => {
    const {onClick, employee} = this.props
    onClick(employee.id)
  }

  handleInviteClick = (e) => {
    const {onInviteClick, employee} = this.props
    onInviteClick(employee.id)
    e.stopPropagation()
  }

  handleRequestClick = (e) => {
    const {onRequestClick, employee} = this.props
    onRequestClick(employee.id)
    e.stopPropagation()
  }

  handleInputClick = (e) => {
    const {onInputClick, employee, disabled} = this.props
    if (!disabled) {
      onInputClick(employee.id)
    }
    e.stopPropagation()
  }

  render() {
    const {employee, selected, disabled} = this.props
    const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
    const disabledClass = disabled ? ' u-cur-notallowed' : ' u-cur-pointer'

    return (
      <tr onClick={this.handleClick} className="table-hover">
        <td onClickCapture={this.handleInputClick} className={selectedClass + disabledClass}>
          <input
            type="checkbox"
            checked={selected}
            className={disabledClass ? 'u-cur-notallowed' : 'u-cur-pointer'}
            readOnly
          />
        </td>
        <td className="u-nowrap">{recordDisplay(employee.staff_code)}</td>
        <td>{recordDisplay.fullName(employee)}</td>
        <td>{recordDisplay(employmentStatuses[employee.employment_status])}</td>
        <td>
          {recordDisplay(
            !_.isEmpty(getGroups(employee)) &&
              getGroups(employee).map((group, index) => (
                <span key={index}>
                  {group}
                  <br />
                </span>
              ))
          )}
        </td>
        <td className="u-nowrap">{recordDisplay(employee.joined_at && employee.joined_at.replace(/-/g, '/'))}</td>
        <td className="u-nowrap">
          {recordDisplay(invitationStatuses[employee.invitation_status])}
          {employee.invitation_status !== 'registered' && (
            <div className="u-mt10">
              <ActionButton
                primary
                onClick={this.handleInviteClick}
                disabled={!employee.email}
                {...(employee.email
                  ? {}
                  : {
                      onClickCapture: (e) => {
                        e.stopPropagation()
                      },
                    })}
              >
                招待する
              </ActionButton>
            </div>
          )}
        </td>
        <td className="u-nowrap">
          {recordDisplay(employee.my_number_input_request_status)}
          {employee.invitation_status === 'registered' && (
            <div className="u-mt10">
              <ActionButton primary onClick={this.handleRequestClick}>
                {_.get(employee, 'my_number_input_request.status') === 'completed' ? '再提出依頼' : '提出依頼'}
              </ActionButton>
            </div>
          )}
        </td>
      </tr>
    )
  }
}

const downloadOptions = [
  {value: 'UTF-8', label: 'UTF-8（推奨）'},
  {value: 'Windows-31J', label: 'Shift-JIS'},
]

const selectByAll = (employee) => employee.invitation_status === 'registered'

const isAllSelected = (employees, selected) =>
  _.some(selected) && employees.every((employee) => selected[employee.id] || !selectByAll(employee))

const myNumberFilters = {
  all: 'すべて',
  not_completed: '未取得',
  completed: '取得済',
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showDownloadModal: false,
      showInviteModal: false,
      showMultiRequestModal: false,
      inviteEmployeeId: null,
      showRequest: false,
      requestEmployeeId: null,
    }
  }

  handleRowClick = (id) => {
    this.props.history.push(`/my_number/${id}`)
  }

  handleInviteClick = (id) => {
    this.setState({showInviteModal: true, inviteEmployeeId: id})
  }

  handleRequestClick = (id) => {
    this.setState({showRequest: true, requestEmployeeId: id})
  }

  handleMultiRequestClick = () => {
    const selected = _.keys(_.pickBy(this.props.selected))
    if (selected.length === 0) {
      this.props.notifyNoSelect()
    } else {
      this.setState({showMultiRequestModal: true})
    }
  }

  handleInputClick = (id) => {
    this.props.toogleSelected(id)
  }

  downloadFile = (values) => {
    this.setState({showDownloadModal: false})
    return downloadFile({
      url: `/api/export_my_numbers_csv?encoding=${values.encoding}`,
      asyncError: this.props.asyncError,
      selectedOffices: this.props.selectedOffices,
    })
  }

  handleInviteSubmit = () => {
    const {inviteEmployeeId} = this.state
    const {invite, token} = this.props
    invite(token, inviteEmployeeId).then(() => this.setState({showInviteModal: false}))
  }

  handleSelectAllClick = () => {
    const {employees, selected, changeMulti} = this.props
    const allSelected = isAllSelected(employees, selected)
    if (allSelected) {
      changeMulti(employees.filter((employee) => selected[employee.id]).map((employee) => ({[employee.id]: false})))
    } else {
      changeMulti(employees.filter(selectByAll).map((employee) => ({[employee.id]: true})))
    }
  }

  componentWillUnmount() {
    this.props.clearSelected()
  }

  render() {
    const {employees, count, selected, history, task, confirm} = this.props
    const allSelected = isAllSelected(employees, selected)
    const allClickClass = allSelected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'

    return (
      <div>
        <ListData embed={['personnel_history', 'my_number_input_request', 'user']} />
        <div className="l-main-title-wrap u-pb0">
          <h1 className="m-title-main">マイナンバー一覧</h1>
          <p className="m-title-main-note">
            ジョブカン給与計算をご利用の場合、登録されたマイナンバーや取得状況は
            <br />
            スタッフコードをもとにジョブカン給与計算にも自動反映されます。
          </p>
          <div className={styles.tabs}>
            {tabs.map(({label, handleClick, active}, i) => (
              <Tab onClick={handleClick(history)} active={active} key={i}>
                {label}
              </Tab>
            ))}
          </div>
        </div>
        <AsyncTaskError task={task} confirm={confirm} displayTypes={['my_number_csv_import']} />
        <div className="l-contents-wrap">
          <div className="l-wrap-xxl">
            <div className={styles.buttonArea}>
              <div className={styles.buttonDl}>
                <Button as="a" widthAuto onClick={() => this.setState({showDownloadModal: true})}>
                  マイナンバーダウンロード
                </Button>
              </div>
              <Link to={`/my_number/import`}>
                <Button primary widthAuto>
                  マイナンバー一括登録
                </Button>
              </Link>
              <Link to="/my_number/audit_logs">
                <Button primary widthAuto>
                  操作履歴
                </Button>
              </Link>
            </div>
            <div className={styles.topArea}>
              <div className={styles.refineArea}>
                {_.map(myNumberFilters, (filter, key) => (
                  <Filter key={key} filter={key} column="my_number_status" label={filter} />
                ))}
              </div>
              <Search placeholder="スタッフコード、氏名、メール" />
            </div>
            <div className="u-mb20">
              <EmploymentStatus />
            </div>
            <div className="l-overflow-scroll">
              <table className="m-table-list">
                <thead>
                  <tr>
                    <th
                      onClick={this.handleSelectAllClick}
                      className={allClickClass + ' m-table-list-check u-cur-pointer'}
                    >
                      <input type="checkbox" readOnly checked={allSelected} />
                    </th>
                    <OrderedTh text="スタッフコード" column="staff_code" />
                    <th>氏名</th>
                    <th className="u-nowrap">在職状況</th>
                    <th>グループ</th>
                    <OrderedTh text="入社日" column="joined_at" />
                    <th>招待状況</th>
                    <th>マイナンバー</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((employee) => (
                    <Row
                      key={employee.id}
                      onClick={this.handleRowClick}
                      onInviteClick={this.handleInviteClick}
                      onRequestClick={this.handleRequestClick}
                      onInputClick={this.handleInputClick}
                      selected={selected[employee.id] || false}
                      disabled={!selectByAll(employee)}
                      {...{employee}}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <Modal
              header="従業員情報のダウンロード"
              onSubmit={() => this.props.submitDownload()}
              isOpen={this.state.showDownloadModal}
              submit={
                <React.Fragment>
                  <Download />
                  ダウンロード
                </React.Fragment>
              }
              cancel="キャンセル"
              hideModal={() => this.setState({showDownloadModal: false})}
              body={<DownloadForm initialValues={{encoding: 'UTF-8'}} onSubmit={this.downloadFile} />}
            />
            <Modal
              header="本人に招待メールを送る"
              body={'選択された従業員宛に、招待メールを送信します。'}
              cancel="キャンセル"
              submit="送信"
              onSubmit={this.handleInviteSubmit}
              isOpen={this.state.showInviteModal}
              hideModal={() => this.setState({showInviteModal: false})}
            />
            <MultiRequest
              isOpen={this.state.showMultiRequestModal}
              hide={() => this.setState({showMultiRequestModal: false})}
            />
            <Request
              isOpen={this.state.showRequest}
              hide={() => this.setState({showRequest: false})}
              employeeId={this.state.requestEmployeeId}
            />
            <div className="m-pager-area">
              <Pager totalResult={count} pageLinks={7} />
              <PerPage />
            </div>
          </div>
          <div className="u-mt20 u-ta-c">
            <Button primary onClick={this.handleMultiRequestClick}>
              一括提出依頼
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default List
  |> connect(
    (state) => ({
      employees: state.employees.list.data,
      count: state.employees.list.count,
      token: state.auth.token,
      query: state.employees.query,
      selected: state.employees.selected,
      selectedOffices: state.session.selectedOffices,
    }),
    (dispatch) => ({
      notifyNoSelect() {
        dispatch(notifyError('従業員が選択されていません'))
      },
      loadEmployees(token, query) {
        dispatch(actionCreators.employees.list.fetchData(api.createWithAuth(token).employees.list(query)))
      },
      submitDownload() {
        dispatch(submitDownload())
      },
      async invite(token, id) {
        try {
          await api.createWithAuth(token).employees.invite(id)
          dispatch(notifySuccess('送信しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      ...bindActionCreators(
        {
          asyncError: asyncError,
          ..._.pick(actionCreators.employees, ['toogleSelected', 'clearSelected', 'changeMulti']),
        },
        dispatch
      ),
    })
  )
  |> withAsyncTask
