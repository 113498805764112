import {connect} from 'react-redux'
import {autofill, submit} from 'redux-form'
import {PaginatorWithResult} from 'jbc-front/components/SearchForm'
import {formName, selector} from 'notifications/list/utils'

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: state.notificationsData.list.count,
  isLoading: state.notificationsData.list.loading,
  page: selector(state, 'page'),
})
const mapDispatchToProps = (dispatch) => ({
  onClick: (page) => {
    dispatch(autofill(formName, 'page', page.toString()))
    dispatch(submit(formName))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(PaginatorWithResult)
