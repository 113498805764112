export const getAddedDependents = (dependents, procedureStatus) =>
  dependents
    ? dependents.filter((dependent) =>
        procedureStatus.procedure_add_dependents_data.find(
          (addDependentsData) => addDependentsData.employee_dependent_id === dependent.id
        )
      )
    : []

export const getRemovedDependents = (dependents, procedureStatus) =>
  dependents
    ? dependents.filter((dependent) =>
        procedureStatus.procedure_remove_dependents_data.find(
          (removeDependentsData) => removeDependentsData.employee_dependent_id === dependent.id
        )
      )
    : []

export const getRelatedDependents = (dependents, procedureStatus) => {
  switch (procedureStatus.procedure_type) {
    case 'add_dependents':
      return getAddedDependents(dependents, procedureStatus)
    case 'remove_dependents':
      return getRemovedDependents(dependents, procedureStatus)
    default:
      return dependents ? dependents.filter((dependent) => !dependent.removed) : []
  }
}
