import React, {Component} from 'react'
import {connect} from 'react-redux'
import OfficeForm from 'offices/OfficeForm'
import {push} from 'connected-react-router'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {toFormValues} from 'utils'
import _ from 'lodash'
import set from 'lodash/fp/set'
import {parse} from 'query-string'
import {withApollo} from '@apollo/client/react/hoc'
import {HAS_BRANCH_OFFICE} from 'hooks/useHasBranchOffice'
import {REFETCH_QUERIES_ON_UPDATE_OFFICE} from 'features/offices/OfficeSelector'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const makeInitialValues = (office, clientId) => {
  const initialValues = toFormValues(office)
  const mainOfficeSocial = _.find(_.get(office, 'office_contact_infos'), {
    office_contact_info_type: 'main_office_social',
  })
  const labor = _.find(_.get(office, 'office_contact_infos'), {
    office_contact_info_type: 'labor',
  })

  const owner = _.find(_.get(office, 'office_contact_infos'), {
    office_contact_info_type: 'owner',
  })

  initialValues.name = null
  initialValues.office_code = null
  initialValues.is_main_office = false
  initialValues.social_contact_info_office_id = '' + clientId
  initialValues.office_contact_infos = []
  initialValues.office_contact_infos[0] = toFormValues(
    Object.assign({}, labor || {...mainOfficeSocial, office_contact_info_type: 'labor'}, {
      id: null,
      rep_position_name: null,
      rep_last_name: null,
      rep_first_name: null,
    })
  )
  initialValues.office_contact_infos[1] = toFormValues(
    Object.assign({}, owner || {...mainOfficeSocial, office_contact_info_type: 'owner'}, {id: null})
  )

  return initialValues
}

class Create extends Component {
  componentDidMount() {
    const {
      dispatch,
      token,
      location: {search},
    } = this.props
    const {copy} = parse(search)

    if (copy) {
      dispatch(
        actionCreators.fetchData(
          'copy_office',
          api.createWithAuth(token).offices.get(copy, {embed: 'office_contact_info'})
        )
      )
    }
  }

  componentWillUnmount() {
    const {dispatch} = this.props
    dispatch(actionCreators.fetchDestroy('copy_office'))
  }

  handleFormSubmit = async (values) => {
    const {dispatch, token, client} = this.props
    try {
      values = set('name', values.office_name, values)
      if (
        values.office_contact_infos[1].rep_position_name ||
        values.office_contact_infos[1].rep_last_name ||
        values.office_contact_infos[1].rep_first_name
      ) {
        values.office_contact_infos[0].rep_position_name = values.office_contact_infos[1].rep_position_name
        values.office_contact_infos[0].rep_last_name = values.office_contact_infos[1].rep_last_name
        values.office_contact_infos[0].rep_first_name = values.office_contact_infos[1].rep_first_name
      }
      await api.createWithAuth(token).offices.create(values)
      dispatch(push('/offices'))
      dispatch(notifySuccess('追加しました'))
      client.refetchQueries({include: [HAS_BRANCH_OFFICE, ...REFETCH_QUERIES_ON_UPDATE_OFFICE]})
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  render() {
    const {
      clientOffice,
      copyOffice,
      location: {search},
    } = this.props
    const {copy} = parse(search)

    if (copy) {
      return copyOffice ? (
        <OfficeForm
          initialValues={makeInitialValues(copyOffice, clientOffice.id)}
          onSubmit={this.handleFormSubmit}
          mode="create"
        />
      ) : null
    } else {
      return clientOffice ? (
        <OfficeForm
          initialValues={makeInitialValues(clientOffice, clientOffice.id)}
          onSubmit={this.handleFormSubmit}
          mode="create"
        />
      ) : null
    }
  }
}

export default connect((state) => ({
  clientOffice: state.client.current.office,
  copyOffice: fetchSelector(state, 'copy_office').data,
  location: state.router.location,
  token: state.auth.token,
}))(withApollo(Create))
