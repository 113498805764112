import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import _ from 'lodash'
import api from 'api'
import * as icons from 'jbc-front/components/icons'
import {CustomField} from 'employees/show/CustomFields'
import {toColor} from 'settings/employeeCustom/ColorSelect'
import {Wrap, TitleArea, Title, Dl, Trfull, Dt} from 'tm/employees/Parts'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {showCategory, findCustomFieldValue} from 'utils'

const CustomFields = ({userDisplaySetting}) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const employee = useSelector((state) => state.employees.current.data)
  const [fieldGroups = []] = useSelector((state) => [fetchSelector(state, 'custom_field_groups').data])
  const [fields = []] = useSelector((state) => [fetchSelector(state, 'custom_fields').data])
  const fieldsMap = _.groupBy(fields, 'custom_employee_field_group_id')

  useEffect(() => {
    const authedApi = api.createWithAuth(token)

    dispatch(actionCreators.fetchData('custom_field_groups', authedApi.tm.employeeCustom.fieldGroups.list()))
    dispatch(
      actionCreators.fetchData(
        'custom_fields',
        authedApi.tm.employeeCustom.fields
          .list()
          .then(({data: fields}) => ({data: fields.map((field) => ({...field}))}))
      )
    )
    return () => {
      dispatch(actionCreators.fetchDestroy('custom_field_groups'))
      dispatch(actionCreators.fetchDestroy('custom_fields'))
    }
  }, [])

  return (
    <>
      {fieldGroups &&
        fieldGroups
          .filter((fieldGroup) => !_.isEmpty(fieldsMap[fieldGroup.id]))
          .map((fieldGroup) => {
            const fields = fieldsMap[fieldGroup.id] || []
            const IconName = icons[fieldGroup.icon_name]
            const style = {color: toColor(fieldGroup.icon_color)}

            return (
              showCategory(userDisplaySetting, fieldGroup.label) &&
              fields.find((field) => findCustomFieldValue(employee, field).length > 0) && (
                <div key={fieldGroup.id}>
                  <Panel>
                    <Item>
                      <Wrap>
                        <TitleArea>
                          <Title>
                            <IconName size={30} color={toColor(fieldGroup.icon_color)} />
                            <div style={style}>{fieldGroup.label}</div>
                          </Title>
                        </TitleArea>
                        <Dl>
                          {fields.map((field) => (
                            <React.Fragment key={field.id}>
                              <Trfull>
                                <Dt>{field.label}</Dt>
                                <CustomField
                                  employee={employee}
                                  yearStyle="seireki"
                                  field={field}
                                  key={field.id}
                                  width={100}
                                />
                              </Trfull>
                            </React.Fragment>
                          ))}
                        </Dl>
                      </Wrap>
                    </Item>
                  </Panel>
                </div>
              )
            )
          })}
    </>
  )
}

export default CustomFields
