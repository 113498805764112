import ChooseExtendOrFinish from 'procedures/maternityLeave/ChooseExtendOrFinish'
import ChooseProcedureForMember from 'procedures/maternityLeave/ChooseProcedureForMember'
import ChooseProcedureTypeForAdmin from 'procedures/maternityLeave/ChooseProcedureTypeForAdmin'
import ChooseProcedureTypeForMember from 'procedures/maternityLeave/ChooseProcedureTypeForMember'
import start from 'procedures/maternityLeave/start'
import extend from 'procedures/maternityLeave/extend'
import finish from 'procedures/maternityLeave/finish'

export default {
  ChooseExtendOrFinish,
  ChooseProcedureForMember,
  ChooseProcedureTypeForAdmin,
  ChooseProcedureTypeForMember,
  start,
  extend,
  finish,
}
