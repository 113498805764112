import React from 'react'
import {Field} from 'redux-form'
import {connect} from 'react-redux'
import {SearchResult, SearchSelectBox} from 'jbc-front/components/SearchForm'
import {selector} from 'settings/auditLogs/list/utils'
import styles from 'settings/auditLogs/list/SearchForm.scss'

const limits = ['10', '20', '50', '100', '200'].map((ele) => ({value: ele, label: `${ele}件を表示`}))
const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: state.settings.auditLogs.list.count,
  limits,
  page: selector(state, 'page'),
  isLoading: state.settings.auditLogs.list.loading,
})
export const SortingFieldsWithResult = ({
  limit,
  count,
  limits,
  page,
  isLoading,
  onChangeLimit,
  failureMessage = '操作履歴が見つかりませんでした',
}) => (
  <div className={styles.sortingFieldsWithResult}>
    <SearchResult
      total={count}
      page={page}
      limit={limit}
      isLoading={isLoading}
      failureMessage={failureMessage}
      className={styles.resultCenter}
    />
    <SortingFields limits={limits} onChangeLimit={onChangeLimit} />
  </div>
)
export const SortingFields = ({limits, onChangeLimit}) => (
  <div className={styles.sortingFields}>
    <Field
      name="limit"
      component={SearchSelectBox}
      options={limits}
      width={110}
      onSelect={(newValue) => {
        onChangeLimit(newValue)
      }}
    />
  </div>
)
export default connect(mapStateToProps)(SortingFieldsWithResult)
