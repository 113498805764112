import ChooseEmployee from 'procedures/maternityLeave/extend/ChooseEmployee'
import ChooseProcedureForAdmin from 'procedures/maternityLeave/extend/ChooseProcedureForAdmin'
import ChooseUseOrCreate from 'procedures/maternityLeave/extend/ChooseUseOrCreate'
import Confirm from 'procedures/maternityLeave/extend/Confirm'
import Detail from 'procedures/maternityLeave/extend/Detail'
import EmployeeInput from 'procedures/maternityLeave/extend/EmployeeInput'
import Update from 'procedures/maternityLeave/extend/Update'

export default {
  ChooseEmployee,
  ChooseProcedureForAdmin,
  ChooseUseOrCreate,
  Confirm,
  Detail,
  EmployeeInput,
  Update,
}
