import React from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import compose from 'lodash/fp/compose'
import classNames from 'classnames'
import SearchFormWithCardList from 'documentEmployees/list/SearchFormWithCardList'
import PaginatorWithPerPage from 'documentEmployees/list/PaginatorWithPerPage'
import SortableTh from 'documentEmployees/list/SortableTh'
import LoadingPage from 'components/LoadingPage'
import SelectList from 'components/SelectList'
import {wrapArrowText} from 'employees/List'
import {getGroups} from 'employees/form/EmploymentInfomation'
import styles from 'documentEmployees/List.scss'
import {recordDisplay} from 'utils'
import {WITH_AUTH_ADMIN_PATHS, generateDynamicPath} from 'consts/paths'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'

const additionalParams = {embed: ['personnel_history']}

const ListTable = ({list, hasBranchOffice, history}) => (
  <div className="l-overflow-scroll">
    <table className={`m-table-list m-table-fixed ${hasBranchOffice ? styles.hasBranchOffice : ''}`}>
      <thead>
        <tr className={styles.table}>
          <SortableTh field="staff_code" globalClassName={styles.staffCodeColumn}>
            スタッフコード
          </SortableTh>
          <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
            氏名
          </SortableTh>
          {hasBranchOffice && (
            <SortableTh field="office" globalClassName={styles.officeColumn}>
              事業所
            </SortableTh>
          )}
          <SortableTh field="group" globalClassName={styles.groupColumn}>
            グループ
          </SortableTh>
          <SortableTh field="employment_type" globalClassName={styles.employmentStatusColumn}>
            雇用形態
          </SortableTh>
          <SortableTh field="document_counts" globalClassName={styles.countColumn}>
            書類数
          </SortableTh>
          <SortableTh field="created_at" globalClassName={styles.dateColumn}>
            最終更新日
          </SortableTh>
        </tr>
      </thead>
      <tbody>
        {!_.isEmpty(list) ? (
          list.map(({item: employee}) => {
            const id = employee.id
            const created_at = _.get(employee, 'employee_documents.created_at')

            const handleClick = () => {
              const path = generateDynamicPath(WITH_AUTH_ADMIN_PATHS.DOCUMENTS.EMPLOYEES.SHOW, [
                {pattern: 'id', replacement: id},
              ])
              history.push(path)
            }

            return (
              <tr onClick={handleClick} className={classNames(styles.table, 'table-hover')} key={id}>
                <td className={styles.staffCodeColumn}>{recordDisplay(employee.staff_code)}</td>
                <td className={styles.nameColumn}>{recordDisplay.fullName(employee)}</td>
                {hasBranchOffice && (
                  <td className={styles.officeColumn}>
                    {recordDisplay(_.get(employee, 'personnel_history.office_name'))}
                  </td>
                )}
                <td className={styles.groupColumn}>
                  {recordDisplay(
                    !_.isEmpty(getGroups(employee)) &&
                      getGroups(employee).map((group, index) => (
                        <span key={index}>
                          {wrapArrowText(group)}
                          <br />
                        </span>
                      ))
                  )}
                </td>
                <td className={styles.employmentStatusColumn}>
                  {recordDisplay(_.get(employee, 'personnel_history.employment_type_name'))}
                </td>
                <td className={styles.countColumn}>{recordDisplay(_.get(employee, 'employee_documents.count'))}</td>
                <td className={styles.dateColumn}>{created_at && recordDisplay.date(created_at, 'YYYY/MM/DD')}</td>
              </tr>
            )
          })
        ) : (
          <tr className={styles.table}>
            <td className={styles.noList}>書類がありません</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
)

const List = ({employees, loading, history}) => {
  const hasBranchOffice = useHasBranchOffice()
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">書類一覧（従業員別）</h1>
      </div>
      <SelectList list={employees}>
        {({list}) => {
          return (
            <div className="l-contents-wrap">
              <div className="l-wrap-xxl">
                <SearchFormWithCardList additionalParams={additionalParams} searchType={'documents'} />
                {loading ? (
                  <LoadingPage />
                ) : (
                  <>
                    <ListTable {...{list, hasBranchOffice, history}} />
                    <PaginatorWithPerPage />
                  </>
                )}
              </div>
            </div>
          )
        }}
      </SelectList>
    </div>
  )
}

export default compose(
  connect((state) => ({
    employees: state.documentEmployees.list.data,
    loading: state.documentEmployees.list.loading,
  }))
)(List)
