import React, {useState} from 'react'
import {reduxForm} from 'redux-form'
import {onSubmitFail} from 'jbc-front/components/FormErrors'
import {Section, FileField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import styles from 'documents/create/Form.scss'
import {useWizard} from 'components/Wizard'
import _ from 'lodash'
import Conditions from '../Conditions'
import {Close} from 'jbc-front/components/icons'
import {useDispatch} from 'react-redux'
import {downloadFile} from 'libs/downloadFile'
import {asyncError} from 'store/actions/asyncError'
import {useSelector} from 'hooks/redux'

const formName = 'UploadCustomVariablesStepForm'

const wrapperSectionStyle = {
  maxWidth: 'unset',
  marginTop: 30,
}

const UploadCustomVariablesStep =
  (({initialValues, setInitialValues, getDynamicDocument, preview, loading, validate}) => {
    const {pushStep, popStep, draft, setDraft, current, discardStep} = useWizard()
    const [viewErrors, setViewErrors] = useState(true)
    const dispatch = useDispatch()
    const selectedOffices = useSelector((state) => state.session.selectedOffices)

    const sampleDownload = async (employeeIds) => {
      const params = {employee_ids: employeeIds}
      await downloadFile({
        url: '/api/export_document_sample_csv',
        asyncError: (err) => dispatch(asyncError(err)),
        params,
        usePost: true,
        selectedOffices,
      })
    }

    return (
      <form>
        <Conditions />
        {viewErrors && preview?.errors.length > 0 && (
          <div className={styles.warningBox}>
            <div className={styles.warningBoxText}>
              CSVで以下のエラーが発生しています
              <Close className={styles.closeButton} onClick={() => setViewErrors(false)} />
            </div>
            {JSON.parse(preview?.errors[0])['base']?.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
            {JSON.parse(preview?.errors[0])['rows']?.map((error, index) => (
              <li key={index}>
                第{error['index']}行:{error['name']}
                {error['message']}
              </li>
            ))}
          </div>
        )}
        <Section title="書類に反映する情報を記載したファイルを選択してください" style={wrapperSectionStyle}>
          <p className={styles.boldText}>対応しているファイル形式はCSVとなります</p>
          <span className="u-txt-link u-cur-pointer" onClick={() => sampleDownload(draft.employeeIds)}>
            サンプルをダウンロード
          </span>
          <FileField
            accept=".csv"
            name="CustomVariablesFile"
            onChange={(value) => {
              getDynamicDocument({
                variables: {
                  document: {
                    documentTemplateId: draft.documemtTemplateId,
                    customVariablesFile: value,
                    title: draft.title,
                    employeeIds: draft.checkEmployees ? draft.employeeIds : null,
                  },
                },
              })
              validate(draft.templateObject?.id, draft.checkEmployees ? draft.employeeIds : null)
              discardStep(current)
              setViewErrors(true)
              setDraft({...draft, customVariablesFile: value})
              setInitialValues({...initialValues, CustomVariablesFile: value})
            }}
          />
          <p className={styles.boldText}>【注意】</p>
          <p>
            ①スタッフコードが一致する従業員の書類に反映しますので
            <span className={styles.redText}>正しいスタッフコードをご記入ください</span>
          </p>
          <p>
            ②スペースの状態で設定すると、<span className={styles.redText}>書類に空白で反映されます</span>
          </p>
        </Section>
        <div className={styles.bottomButtonsArea}>
          <Button
            className={styles.wizardBackButton}
            onClick={() => {
              popStep()
            }}
          >
            従業員選択に戻る
          </Button>
          <Button
            className={styles.wizardNextButton}
            primary
            disabled={_.isEmpty(draft.customVariablesFile) || loading || !_.isEmpty(preview?.errors)}
            onClick={() => {
              validate(draft.documemtTemplateId, draft.checkEmployees ? draft.employeeIds : null)
              setDraft({...draft, preview: preview})
              pushStep('Confirmation')
            }}
          >
            書類イメージを確認する
          </Button>
        </div>
      </form>
    )
  }) |> reduxForm({form: formName, enableReinitialize: true, onSubmitFail})

export default UploadCustomVariablesStep
