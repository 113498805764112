import React, {Component} from 'react'
import {connect} from 'react-redux'
import {replace} from 'connected-react-router'
import _ from 'lodash'
import {actionCreators, fetchSelector} from 'actions'
import NaviForm from 'employees/NaviForm'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {withFormSelectors, withFormSelectorsProvider} from 'employees/form/common'
import {loadEmployee} from 'employees/Show'
import {updateEmployeeDetail} from 'employees/Update'
import {CommentModal} from 'components/modals/CommentModal'
import {FieldGroupProvider} from 'employees/FieldWithGroup'
import {isAdminSelector, getState} from 'libs/redux'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {eachFormFiles, isUpdateOrDeleteFile} from 'employees/eachFormFiles'

const Loading = ({children, required}) => (required.every((value) => value && !_.isEmpty(value)) ? children : null)
class Confirm extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      client,
      loadData,
      loadFieldGroups,
    } = this.props
    loadData(id, token)
    loadFieldGroups(token, client)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleFormSubmit = async (values) => {
    const {
      dispatch,
      token,
      match: {
        params: {id},
      },
      fields,
      getValues,
    } = this.props
    try {
      const state = await getState(dispatch)
      const formValues = getValues(state)
      const rejectFilesFromAccept = Array.from(eachFormFiles(formValues, fields))
        .filter(isUpdateOrDeleteFile)
        .map((f) => f.dbName)

      await updateEmployeeDetail(id, values, token, undefined, fields)
      await api.createWithAuth(token).employees.detailInputRequest.accept(id, {reject_files: rejectFilesFromAccept})
      dispatch(notifySuccess('保存しました'))
      dispatch(replace(WITH_AUTH_ADMIN_PATHS.EMPLOYEES.UPDATE_REQUESTS.INDEX))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  handleModalSubmit = async (comment) => {
    const {
      dispatch,
      token,
      match: {
        params: {id},
      },
    } = this.props
    try {
      await api.createWithAuth(token).employees.detailInputRequest.reject(id, {comment})
      dispatch(notifySuccess('送信しました'))
      dispatch(replace(`/employees/${id}`))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  componentDidUpdate() {
    const {
      dispatch,
      inputRequest,
      match: {
        params: {id},
      },
    } = this.props
    if (inputRequest && inputRequest.status !== 'applying') {
      dispatch(notifyError(inputRequest.status === 'accepted' ? '既に承認済みです' : '現在確認することができません'))
      dispatch(replace(`/employees/${id}`))
    }
  }

  submitForm = () => {
    const {dispatch, submit} = this.props
    dispatch(submit)
  }

  render() {
    const {submitting, inputRequest, employee, fieldGroups, employeeFieldGroups, selector, isAdmin} = this.props
    const comments = _.get(employee, 'detail_input_request.comments')
    const {type} = inputRequest || {type: null}
    const modalTitle = isAdmin ? '修正依頼' : '更新依頼'
    const placeholder = isAdmin
      ? '修正して欲しい内容などを入力してください'
      : '更新して欲しい理由などを入力してください'

    return (
      <Loading required={[employee, employeeFieldGroups]}>
        <FieldGroupProvider permissionGroups={employeeFieldGroups}>
          <CommentModal
            modalTitle={modalTitle}
            placeholder={placeholder}
            onSubmit={(formData) => this.handleModalSubmit(formData.comment)}
            comments={isAdmin ? comments : []}
          >
            {(showModal, _isOpen) => (
              <NaviForm
                submitText="承認する"
                data={employee}
                fieldGroups={fieldGroups}
                employeeFieldGroups={employeeFieldGroups}
                employee={employee}
                tmpData={_.get(inputRequest, 'detail_input_datum.data_raw')}
                onSubmit={this.handleFormSubmit}
                tmpFiles={_.get(inputRequest, 'detail_input_datum.detail_input_files')}
                comments={comments}
                withDiff
                withText="入力・修正箇所には承認待ちアイコンがついていますので、変更点を確認の上更新してください。"
                naviSubmit={
                  <Button primary disabled={submitting} onClick={this.submitForm} widthWide>
                    承認する
                  </Button>
                }
                otherButtons={
                  <Button disabled={submitting} onClick={showModal} className="u-mr20">
                    修正を依頼する
                  </Button>
                }
                shouldShowCancel={type === 'DirectRequest' || type === 'InitialInput'}
                selector={selector}
              />
            )}
          </CommentModal>
        </FieldGroupProvider>
      </Loading>
    )
  }
}

export default Confirm
  |> connect(
    (state, {isSubmitting}) => ({
      token: state.auth.token,
      client: state.client.current,
      employee: state.employees.current.data,
      fields: fetchSelector(state, 'custom_fields').data,
      submitting: isSubmitting(state),
      inputRequest: fetchSelector(state, 'detail_input_request').data,
      fieldGroups: fetchSelector(state, 'custom_field_groups').data,
      employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
      isAdmin: isAdminSelector(state),
    }),
    (dispatch) => ({
      loadData(id, token) {
        dispatch(actionCreators.employees.current.fetchData(loadEmployee(id, token)))
        dispatch(
          actionCreators.fetchData(
            'detail_input_request',
            api.createWithAuth(token).employees.detailInputRequest.get(id)
          )
        )
      },
      loadFieldGroups(token, client) {
        dispatch(
          actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list())
        )
        dispatch(
          actionCreators.fetchData(
            'custom_field_groups',
            api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
          )
        )
        dispatch(
          actionCreators.fetchData(
            'custom_fields',
            api.createWithAuth(token).employeeCustom.fields.list(client.id, {
              embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
            })
          )
        )
      },
      destroy() {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.fetchDestroy('detail_input_request'))
        dispatch(actionCreators.fetchDestroy('employee_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_fields'))
      },
      dispatch,
    })
  )
  |> withFormSelectors
  |> withFormSelectorsProvider
