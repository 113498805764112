import React from 'react'
import {TextField, DateField, SelectField, Section} from 'jbc-front/components/Form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {reduxForm, formValueSelector} from 'redux-form'
import {
  DependentHealthInsurance,
  DependentIncomeStatus,
  OtherFamilySupport,
  SpouseInformation,
} from 'reportForms/common'
import _ from 'lodash'
import {recordDisplay, isAdminSelector} from 'utils'
import EmployeeName from 'procedures/EmployeeName'

const formName = 'R137Report'

const applicationReasonTypes = (name) => [
  {label: '被保険者が当組合に取得したことに伴う申請', value: 'entrance'},
  {label: `${name}の退職に伴う申請`, value: 'target_retirement'},
  {label: `退職以外の理由で、${name}の就労・収入状況の変化に伴う申請`, value: 'financial_change'},
  {label: `${name}の失業給付受給終了に伴う申請`, value: 'target_unemployment_benefits_ended'},
  {label: `${name}の配偶者が死亡したことに伴う申請`, value: 'target_spouse_passed_away'},
  {label: 'その他', value: 'others'},
]

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, name, applicationReasonType, isAdmin, otherButtons, employee}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">被扶養者現況表（{name}）</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section>
        <Section title="申請する理由">
          <SelectField
            name="application_reason_type"
            options={applicationReasonTypes(name)}
            required={!isAdmin}
            label="申請理由"
          />
          {applicationReasonType === 'target_retirement' && (
            <DateField name="target_retired_at" label="退職日" required={!isAdmin} />
          )}
          {applicationReasonType === 'others' && (
            <TextField name="application_reason_other" label="理由" required={!isAdmin} />
          )}
        </Section>
        <DependentHealthInsurance formName={formName} required={!isAdmin} name={name} />
        <SpouseInformation formName={formName} name={name} required={!isAdmin} />
        <OtherFamilySupport formName={formName} required={!isAdmin} name={name} />
        <DependentIncomeStatus title={`${name}の現在の就労・収入状況`} formName={formName} required={!isAdmin} />
        {isAdmin && <SubmitedOn />}
        <div className="u-ta-c u-mt30">
          {otherButtons}
          <Button primary onClick={handleSubmit} disabled={submitting}>
            {submitText}
          </Button>
        </div>
      </Section>
    </div>
  </form>
))

const selector = formValueSelector(formName)

const connectedForm = connectForm('r137', Form, {
  mapState: (state, ownProps) => {
    const {dependentId, name} = ownProps
    const dependents = _.get(state, `procedureStatuses.current.data.employee.employee_dependents`)
    const dependent = dependents && dependents.find((dependent) => dependent.id == dependentId)
    return {
      employee: state.procedureStatuses.current.data.employee || {},
      applicationReasonType: selector(state, 'application_reason_type'),
      name: name || (dependent && dependent.relation_other) || recordDisplay(),
      isAdmin: isAdminSelector(state),
    }
  },
})

export default connectedForm
