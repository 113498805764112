import React from 'react'
import {reduxForm, Field} from 'redux-form'
import Button from 'jbc-front/components/Button'
import {TextAreaField} from 'jbc-front/components/Form'
import styles from 'settings/employeeCustom/EmployeeFieldGroup/EmployeeFieldGroupForm.scss'
import ColorSelect from 'settings/employeeCustom/ColorSelect'
import {Panel, Item, Headding} from 'components/PrimaryPanel'

const formName = 'fieldGroupForm'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
})(({submitting, handleSubmit, label}) => (
  <form onSubmit={handleSubmit}>
    <Panel>
      <Headding>{label}</Headding>
      <Item>
        <div className={styles.descriptionWrap}>
          <div className={styles.descriptionLabel}>
            <p>説明文</p>
            <Field component={ColorSelect} name="description_color_by_rgb" />
          </div>
          <TextAreaField name="description" rows="5" />
        </div>
      </Item>
    </Panel>
    <div className="u-ta-c">
      <Button primary onClick={handleSubmit} disabled={submitting}>
        保存
      </Button>
    </div>
  </form>
))

export default Form
