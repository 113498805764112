export const EGOV_PROCEDURE_STATUSES = {
  STATUS_UNKNOWN: 'ステータス不明',
  PREPARING: '準備中',
  PROCESSING: '処理中',
  INADEQUACY_ERROR: 'エラー',
  ARRIVED: '役所到達',
  UNDER_REVIEW: '役所審査中',
  REVIEW_COMPLETED: '審査終了',
  PROCEDURE_COMPLETED: '手続終了',
  WITHDRAW_PROCESSING: '取下げ処理中',
  WITHDRAW_ARRIVED: '役所到達（取下げ処理中）',
  WITHDRAW_UNDER_REVIEW: '取下げ審査中',
  WITHDRAW_PROCEDURE_COMPLETED: '取下げ完了',
  PROCEDURE_COMPLETED_RETURNABLE: '手続終了（返戻）',
  PROCEDURE_COMPLETED_RESUBMITTED: '手続終了（再提出済み）',
  UNDER_REVIEW_WAIT_CORRECTION: '審査中（補正待ち）',
} as const

export type EgovProcedureStatuses = keyof typeof EGOV_PROCEDURE_STATUSES
