import React, {useEffect, useState} from 'react'
import {useFetchEffect} from 'hooks/useFetchEffect'
import Form from 'procedures/changeDependents/add/Form'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import CommentModal from 'procedures/CommentModal'
import {assignTmpFiles, dependentDefaultValue} from 'employees/form/common'
import _ from 'lodash'
import {toFormValues} from 'utils'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import useReactRouter from 'use-react-router'
import {RequireEmployeeUpdateRouter} from 'components/router/RequireEmployeeUpdateRouter'
import {RejectUnemployeeRouter} from 'components/router/RejectUnemployeeRouter'
import {useQuery} from '@apollo/client'
import {EMPLOYEE_DEPENDENTS} from './query'
import {saveDependentFiles} from 'procedures/enroll/tmpData'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

// MEMO: このコンポーネントは管理者は利用しない想定
export const AddEmployeeDependents = () => {
  const healthInsuranceType = useSelector((state) =>
    _.get(state.session.currentUser, 'employee.office.health_insurance_type')
  )
  const tmpData = useSelector((state) => state.procedureStatuses.tmpData.data)
  const tmpFiles = useSelector((state) => state.procedureStatuses.tmpData.tmpFiles) || []
  const comments = useSelector((state) => fetchSelector(state, 'procedure_comments').data)
  const token = useSelector((state) => state.auth.token)
  const employee = useSelector((state) => state.session.currentUser.employee)
  const clientRoleType = useSelector((state) => state.session.currentUser.client_role.client_role_type)

  const {
    match,
    location: {search},
  } = useReactRouter()
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})
  const {dependentIds} = parse(search, {arrayFormat: 'bracket'})
  const {loading, error, data} = useQuery(EMPLOYEE_DEPENDENTS, {variables: {dependentIds}})
  const procedureStatusId = match.params.id

  // フォームの初期値を設定
  const makeInitialValues = (additionalDependents = []) => {
    const {
      application_procedure_add_dependents: addDependents,
      application_procedure_start_maternity: procedureStartMaternity,
    } = tmpData
    const existsDependents = additionalDependents.map((dependent) => ({
      ...dependentDefaultValue(),
      ...dependent,
    }))
    const employeeDependents = addDependents
      ? addDependents.employee_dependents.map((dependent) => ({
          ...dependentDefaultValue(),
          ...dependent,
        }))
      : []
    const maternityChildData = procedureStartMaternity
      ? procedureStartMaternity.procedure_maternity_child_data.map((dependent) => ({
          ...dependentDefaultValue(),
          first_name: dependent.first_name,
          first_name_kana: dependent.first_name_kana,
          last_name: dependent.last_name,
          last_name_kana: dependent.last_name_kana,
          birthday: dependent.birth_date,
          dependent_reason: '出生',
          relation_type: 'other',
        }))
      : []
    const dependents = [...existsDependents, ...employeeDependents, ...maternityChildData]

    const dependentsFormValues = {
      dependents: dependents.length > 0 ? dependents : [dependentDefaultValue()],
      date_on: procedureStartMaternity?.expected_birth_date,
      marital_status: employee.marital_status,
      spouse_annual_income: employee.spouse_annual_income,
      spouse_monthly_income: employee.spouse_monthly_income,
      ...toFormValues(addDependents),
    }

    return assignTmpFiles({...dependentsFormValues}, tmpFiles)
  }

  const loadTmpData = (token, procedureStatusId) => {
    dispatch(
      actionCreators.procedureStatuses.tmpData.fetchData(
        Promise.all([
          api
            .createWithAuth(token)
            .procedureStatuses.tmpData.mapToJson(procedureStatusId, {name: 'procedure_add_dependents'}),
          api.createWithAuth(token).procedureStatuses.tmpFiles.list(procedureStatusId, 'application'),
        ]).then((result) => Object.assign(...result))
      )
    )
  }

  useFetchEffect(() => {
    const {maternity_procedure_status_id: maternityProcedureStatusId} = parse(search)

    // load comments
    dispatch(
      actionCreators.fetchData(
        'procedure_comments',
        api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
      )
    )

    if (procedureStatusId) {
      // load procedureStatus
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api.createWithAuth(token).procedureStatuses.get(procedureStatusId)
        )
      )
      // load tmpData
      loadTmpData(token, procedureStatusId)
    }

    if (maternityProcedureStatusId) {
      // load maternityProcedureStatus
      dispatch(
        actionCreators.procedureStatuses.tmpData.fetchData(
          api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(maternityProcedureStatusId, {
            type: 'application',
            name: 'procedure_start_maternity',
          })
        )
      )
    }
    setInitialValues(makeInitialValues())

    return () => {
      dispatch(actionCreators.procedureStatuses.current.destroy())
      dispatch(actionCreators.procedureStatuses.tmpData.destroy())
    }
  }, [])

  const toString = (value) => {
    return value === null || value === undefined ? undefined : String(value)
  }

  const toFile = (file) => {
    return file.filename === null && file.url === null ? null : file
  }

  useEffect(() => {
    if (!loading && !error && data && tmpData) {
      const additionalDependents = data.user.employee.employeeDependentsWhereByIds.map((dependent) => ({
        id: dependent.id,
        first_name: dependent.firstName,
        last_name: dependent.lastName,
        first_name_kana: dependent.firstNameKana,
        last_name_kana: dependent.lastNameKana,
        birthday: dependent.birthday,
        gender: dependent.gender,
        relation_other: dependent.relationName,
        relation_type: dependent.relationType,
        relation_report_type: dependent.relationReportType,
        residence_status: dependent.residenceStatus,
        domestic_remittance: toString(dependent.domesticRemittance),
        remittance: toString(dependent.remittance),
        prefecture_id: dependent.prefecture?.id,
        postcode_0: dependent.postcode0,
        postcode_1: dependent.postcode1,
        city: dependent.city,
        house_number: dependent.houseNumber,
        building: dependent.building,
        address_kana: dependent.addressKana,
        address_foreign: dependent.addressForeign,
        job: dependent.job,
        phone_number_0: dependent.phoneNumber0,
        phone_number_1: dependent.phoneNumber1,
        phone_number_2: dependent.phoneNumber2,
        basic_pension_number_0: dependent.basicPensionNumber0,
        basic_pension_number_1: dependent.basicPensionNumber1,
        national_type: dependent.nationalType,
        romaji_name: dependent.romajiName,
        romaji_name_kana: dependent.romajiNameKana,
        dependent_reason: dependent.dependentReason,
        annual_income: toString(dependent.annualIncome),
        annual_earnings: toString(dependent.annualEarnings),
        handicap_type: dependent.handicapType,
        handicap_detail: dependent.handicapDetail,
        handicap_certificate: toFile(dependent.handicapCertificate),
        dependent_tax_law: dependent.dependentTaxLaw,
        is_non_resident: dependent.isNonResident,
        is_study_abroad: dependent.isStudyAbroad,
        related_to_relatives_document: toFile(dependent.relatedToRelativesDocument),
        related_to_remittance_document: toFile(dependent.relatedToRemittanceDocument),
        proving_study_abroad_document: toFile(dependent.provingStudyAbroadDocument),
      }))
      setInitialValues(makeInitialValues(additionalDependents))
    }
  }, [loading, tmpData])

  const handleSubmit = async (values, comment) => {
    const {date_on: dateOn, dependents, ...rest} = values
    const authedApi = api.createWithAuth(token)
    try {
      const {data: employeeData} = await authedApi.employees.getCurrent(['health_insurance', 'employee_dependents'])

      // 二人以上の配偶者情報が入力されている場合
      if (dependents.filter((dependent) => dependent.relation_type === 'spouse').length > 1) {
        dispatch(notifyError('配偶者を2人以上登録する事はできません。'))
        return
      }
      const spouse = employeeData.employee_dependents.find((dependent) => dependent.relation_type === 'spouse')
      const inputDependents = {
        others: [],
        spouse: null,
      }
      dependents.forEach((dependent) => {
        if (dependent.relation_type === 'spouse') {
          inputDependents.spouse == dependents
        } else {
          inputDependents.others.push(dependents)
        }
      })
      // 登録済みの配偶者が存在する && 配偶者->その他に変更されていない && 新しく配偶者情報が入力されている
      if (spouse && !inputDependents.others.map((dep) => dep.id).includes(spouse.id) && inputDependents.spouse) {
        dispatch(notifyError('配偶者を2人以上登録する事はできません。'))
        return
      }

      // 被扶養者の異動日チェック
      const dateOnMoment = moment(dateOn, 'YYYY/MM/DD')
      if (dependents.some((dependent) => dateOnMoment.isBefore(moment(dependent.birthday, 'YYYY/MM/DD')))) {
        dispatch(notifyError('被扶養者の生年月日を異動日より未来に設定する事はできません。'))
        return
      }

      // procedureStatusを作成してIDを返す
      const createProcedureStatusId = async () => {
        const {data} = await authedApi.procedureStatuses.start(
          employeeData.id,
          'add_dependents',
          dateOn,
          'employee_draft'
        )
        return data.id
      }

      const targetProcedureStatusId = procedureStatusId || (await createProcedureStatusId())

      await authedApi.procedureStatuses.tmpData.updateJson(
        targetProcedureStatusId,
        'application',
        'procedure_add_dependents',
        (data = {employee_dependents: [], report_params_sets: {}}) => ({
          ...data,
          date_on: dateOn,
          employee_dependents: dependents,
          ...rest,
        })
      )

      await saveDependentFiles({
        authedApi,
        procedureStatusId: targetProcedureStatusId,
        dependents,
        procedureTmpType: 'application',
        tmpFiles,
      })

      if (healthInsuranceType === 'its' && _.get(employeeData, 'health_insurance.joined')) {
        // IT健保の場合
        dispatch(push(`/mypage/add_dependents/employee_input_dependents/${targetProcedureStatusId}?dependent_index=0`))
        dispatch(notifySuccess('保存しました'))
      } else {
        await authedApi.procedureStatuses.apply(targetProcedureStatusId, comment)
        dispatch(push('/dashboard'))
        dispatch(notifySuccess('申請しました'))
      }
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <RequireEmployeeUpdateRouter employeeId={employee.id} redirectPath="/dashboard?error=employee_info">
      <RejectUnemployeeRouter
        clientRoleType={clientRoleType}
        employmentStatus={employee?.employment_status}
        redirectPath="/dashboard?error=employee_info"
      >
        <CommentModal
          formName={'changeDependents'}
          procedureType="changeDependents"
          onSubmit={({values, comment}) => handleSubmit(values, comment)}
          comments={comments || []}
        >
          {({showModal}) => (
            <Form
              onSubmit={healthInsuranceType === 'its' ? handleSubmit : showModal}
              initialValues={initialValues}
              submitText={healthInsuranceType === 'its' ? '次へ' : '申請'}
              comments={comments || {}}
            />
          )}
        </CommentModal>
      </RejectUnemployeeRouter>
    </RequireEmployeeUpdateRouter>
  )
}
