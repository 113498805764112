import React from 'react'
import styles from 'settings/customMails/Preview.scss'
import {reduxForm, submit as submitForm} from 'redux-form'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {Section} from 'jbc-front/components/Form'
import {PurposeOfUse} from 'myNumber/RequestForm'
import {Query} from 'components/Graphql'
import {gql} from '@apollo/client'

const DEPENDENTS = gql`
  query employeeDependents($ids: [ID!]!) {
    employeeDependents(ids: $ids) {
      id
      firstName
      lastName
    }
  }
`

const formName = 'myNumberMultiRequest'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch, ...rest) => {
    if (errors?.employee_my_number_purpose_of_uses?.purpose_of_use_ids) {
      errors.employee_my_number_purpose_of_uses = errors.employee_my_number_purpose_of_uses.purpose_of_use_ids
    }
    if (errors?.employee_dependents_my_number_purpose_of_uses?.purpose_of_use_ids) {
      errors.employee_dependents_my_number_purpose_of_uses =
        errors.employee_dependents_my_number_purpose_of_uses.purpose_of_use_ids
    }
    return onSubmitFail(errors, dispatch, ...rest)
  },
})(({handleSubmit, employeeIds}) => (
  <div className={styles.body}>
    <Query query={DEPENDENTS} variables={{ids: employeeIds}}>
      {({data: {employeeDependents}}) => (
        <form onSubmit={handleSubmit}>
          <FormErrors />
          <Section title="本人の情報">
            <LabelMapper name="employee_my_number_purpose_of_uses" label="本人の情報の利用目的" />
            <PurposeOfUse name="employee_my_number_purpose_of_uses" />
          </Section>
          {employeeDependents.length > 0 && (
            <Section title="被扶養者の情報">
              <LabelMapper name="employee_dependents_my_number_purpose_of_uses" label="被扶養者の情報の利用目的" />
              <PurposeOfUse name="employee_dependents_my_number_purpose_of_uses" />
            </Section>
          )}
        </form>
      )}
    </Query>
  </div>
))

export const submit = submitForm.bind(null, formName)

export default Form
