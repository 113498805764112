import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {ListGroup, ListItem} from 'components/ListGroup'
import moment from 'moment'
import styles from 'settings/auditLogs/List.scss'
import PaginatorWithResult from 'settings/auditLogs/list/PaginatorWithResult'
import {getSavedDisplayAuditLogLimit} from 'settings/auditLogs/list/utils'
import SearchForm from 'settings/auditLogs/list/SearchForm'

const operationTypes = [
  {id: 'all', name: 'すべて'},
  {id: 'enroll', name: '入社の手続き'},
  {id: 'resign', name: '退社の手続き'},
  {id: 'change_address', name: '住所変更の手続き'},
  {id: 'change_name', name: '氏名変更の手続き'},
  {id: 'change_dependents', name: '扶養変更の手続き'},
  {id: 'maternity_leave', name: '産休の手続き'},
  {id: 'employee_info', name: '従業員情報'},
  {id: 'client_info', name: '事業所・取引先情報'},
]

const List = ({list, user, location: {pathname}}) => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">操作履歴</h1>
    </div>
    <div className="l-wrap-s l-contents-wrap">
      <div className="l-breadcrumb u-mb20">
        <Link to="/settings" className="l-breadcrumb-link">
          設定
        </Link>
        <span className="l-breadcrumb-here">操作履歴</span>
      </div>
      <SearchForm operationTypes={operationTypes} />
      <div>
        <AuditLogGroup list={list} path="settings" />
        <PaginatorWithResult limit={getSavedDisplayAuditLogLimit(pathname, user)} />
      </div>
    </div>
  </div>
)

export const AuditLogGroup = ({list, path}) => (
  <ListGroup>
    {list.map((log) => (
      <ListItem key={log.id} to={`/${path}/audit_logs/${log.id}`}>
        <div className={styles.logWrap}>
          <p className={styles.logTitle}>{log.title}</p>
          <div className={styles.created}>
            {decorate(log.created_at)}
            <div className={styles.note}>{moment(log.created_at).format('YYYY/MM/DD  HH:mm:ss')}</div>
          </div>
        </div>
      </ListItem>
    ))}
  </ListGroup>
)

const decorate = (createdAt) => {
  const duration = moment.duration(moment().diff(moment(createdAt)))
  const days = Math.floor(duration.asDays()) + 1
  if (days > 3) {
    return moment(createdAt).format('YYYY/MM/DD')
  }
  return moment(createdAt).fromNow()
}

export default connect((state) => ({
  list: state.settings.auditLogs.list.data,
  count: state.settings.auditLogs.list.count,
  location: state.router.location,
  user: state.session.currentUser,
}))(List)
