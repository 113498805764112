import gql from 'graphql-tag'
import {EgovProcedureStatuses} from 'consts/egovProcedureStatuses'

export interface QueryVariables {
  statuses: EgovProcedureStatuses[]
  keyword?: string
  officeIds?: number[]
  page?: number
  limit?: number
}

export const EGOV_PROCEDURES = gql`
  query ($statuses: [EgovV1EgovProcedureStatus!], $keyword: String, $page: Int, $limit: Int) {
    client {
      egovCertificateFile {
        name
      }
      egovProcedures(statuses: $statuses, keyword: $keyword, page: $page, limit: $limit) {
        totalCount
        list {
          id
          status
          isApplicatable
          formName
          procedureName
          submissionDestination
          signRequired
          isDeletable
          attachments {
            id
            fileName
            fileUrl
            documentName
          }
          procedureStatus {
            id
            procedureType
            employee {
              firstName
              lastName
            }
          }
          office {
            id
            name
          }
          application {
            sendNumber
            appliedAt
            documents {
              sendNumber
            }
            comments {
              sendNumber
            }
          }
          egovApplication {
            arriveId
            appliedAt
            officialDocuments {
              id
            }
            notices {
              id
            }
            trackingLogStatus
          }
        }
      }
    }
    egovAccount {
      id
      egovIdp
      gbizidAccountType
    }
  }
`

export interface EgovCertificateFile {
  name: string
}

export interface EgovProcedure {
  id: string
  formName: string
  status: EgovProcedureStatuses
  application: Application | null
  egovApplication: EgovApplication | null
  procedureName: string
  procedureStatus?: ProcedureStatus
  office: Office
  attachments: Attachment[]
  submissionDestination: string
  signRequired: boolean
  isDeletable: boolean
}

export interface EgovAccount {
  id: string
  egovIdp: string
  gbizidAccountType: string
}

interface Attachment {
  fileName: string
  fileUrl: string
  documentName: string
}

interface Application {
  sendNumber: string
  appliedAt: string
  documents: Document[]
  comments: Comment[]
}

interface Document {
  sendNumber: string
}

interface Comment {
  sendNumber: string
}

interface EgovApplication {
  arriveId: string
  appliedAt: string
  officialDocuments: OfficialDocument[]
  notices: Notice[]
  trackingLogStatus: 'SUCCESS' | 'INVALID_TOKEN' | 'ABORT' | 'TRACKING_LOG_STATUS_UNKNOWN'
}

interface OfficialDocument {
  id: string
}

interface Notice {
  id: string
}

interface Office {
  id: number
  name: string
}

interface ProcedureStatus {
  id: number
  procedureType: string
  employee: Employee
}

interface Employee {
  firstName: string
  lastName: string
}
