import {FC} from 'react'
import useReactRouter from 'use-react-router'
import {parse} from 'query-string'
import {useCheckChangeEmailConfirmationToken} from 'hooks/api/changeEmailConfirmation'
import {Presenter} from './Presenter'

export const Show: FC = () => {
  const {
    location: {search},
  } = useReactRouter<{search: string}>()
  const {confirmation_token: token} = parse(search)
  const {isLoading, isValid, errors} = useCheckChangeEmailConfirmationToken(
    (Array.isArray(token) ? token[0] : token) ?? undefined
  )

  return <Presenter isLoading={isLoading} isValid={isValid} errors={errors} />
}
