import {FC, useEffect} from 'react'
import useReactRouter from 'use-react-router'
import {Presenter} from './Presenter'
import {useLazyQuery} from 'hooks/graphql'
import {EGOV_PROCEDURE, QueryVariables, QueryResult} from './query'

export const Show: FC = () => {
  const {match} = useReactRouter<{id: string}>()
  const [fetch, {loading, data}] = useLazyQuery<QueryResult, QueryVariables>(EGOV_PROCEDURE, {
    fetchPolicy: 'network-only',
  })

  const refetch = (egovProcedureId: string) => {
    fetch({variables: {egovProcedureId}})
  }

  useEffect(() => {
    const egovProcedureId = match.params.id
    if (egovProcedureId) {
      fetch({variables: {egovProcedureId}})
    }
  }, [match.params.id])

  return !loading && data ? <Presenter refetch={refetch} egovProcedure={data.client.egovProcedure} /> : <></>
}
