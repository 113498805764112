import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators} from 'actions'
import {Link} from 'react-router-dom'
import api from 'api'
import styles from 'dashboard/dashboard.scss'
import {Notifications as List} from 'notifications/List'
import {Notifications as ListForAdmin} from 'dashboard/admin/notifications/List'

const MAX_COUNT = 5

class Notifications extends Component {
  componentDidMount() {
    this.props.showProcedureStatuses(this.props.token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {notifications, count, isAdmin} = this.props
    const title = isAdmin ? '従業員の通知' : '管理者からの通知'

    return (
      <div>
        {isAdmin ? (
          <ListForAdmin notifications={notifications} title={title} styles={styles} />
        ) : (
          <>
            <List notifications={notifications} title={title} styles={styles} />
            {count > notifications.length && (
              <div className={styles.more}>
                <Link to="/notifications" className={styles.more}>
                  すべて見る
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  notifications: state.notificationsData.list.data,
  count: state.notificationsData.list.count,
  token: state.auth.token,
})

const mapDispatchToProps = (dispatch) => ({
  showProcedureStatuses(token) {
    dispatch(
      actionCreators.notifications.list.fetchData(api.createWithAuth(token).notifications.list({limit: MAX_COUNT}))
    )
  },
  destroy() {
    dispatch(actionCreators.notifications.list.destroy())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
