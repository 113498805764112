import React from 'react'
import styles from 'systemAnnounces/Show.scss'
import _ from 'lodash'
import moment from 'moment'
import {gql} from '@apollo/client'
import {Query} from 'components/Graphql'

export const SYSTEM_ANNOUNCE = gql`
  query systemAnnounce($id: ID!) {
    systemAnnounce(id: $id) {
      id
      title
      body
      publishAt
    }
  }
`

const parseHtml = (html) => {
  if (!html) {
    return {__html: ''}
  }
  let escaped = _.escape(html)
  escaped = escaped.replace(
    /&lt;a href=(&quot;|&#39;)(https?:\/\/.+?)(&quot;|&#39;)&gt;(.+?)&lt;\/a&gt;/g,
    '<a href="$2" target="_blank">$4</a>'
  )
  escaped = escaped.replace(
    /(^|[^"'>])(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g,
    '$1<a href="$2" target="_blank">$2</a>'
  )
  return {__html: _.unescape(escaped)}
}

const Show = ({
  match: {
    params: {id},
  },
}) => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">お知らせ</h1>
    </div>
    <Query query={SYSTEM_ANNOUNCE} variables={{id}} showLoading>
      {({data: {systemAnnounce}}) => (
        <div className="l-wrap-s">
          <div className={styles.date}>{moment(systemAnnounce.publishAt).format('YYYY年MM月DD日 HH:mm')}</div>
          <h2 className={styles.title}>{systemAnnounce.title}</h2>
          <div className={styles.body} dangerouslySetInnerHTML={parseHtml(systemAnnounce.body)} />
        </div>
      )}
    </Query>
  </div>
)

export default Show
