import {FC} from 'react'
import styles from './CsvFileDescription.scss'

export interface DescriptionProps {
  update?: boolean
}

export const CsvFileDescription: FC<DescriptionProps> = ({update}) => (
  <div className={styles.tableWrap}>
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>グループコード{update && <span className={styles.required}>必須</span>}</th>
            <td>
              グループに登録するコードを入力します。
              <br />
              登録上限：半角255文字まで
              <br />
              登録済みのグループにすでに付与されているIDの削除・変更は行わないでください。
            </td>
            <td>B1001</td>
          </tr>
          <tr>
            <th>
              グループ名<span className={styles.required}>必須</span>
            </th>
            <td>
              グループに登録する名称を入力します。
              <br />
              登録上限：半角255文字まで(&apos;-&apos;, &apos;_&apos;を含む)
            </td>
            <td>第一グループ</td>
          </tr>
          <tr>
            <th>階層</th>
            <td>1~5階層まで指定できます。</td>
            <td>2</td>
          </tr>
          <tr>
            <th>親グループコード</th>
            <td>
              親グループに設定するグループのコードを入力します。
              <br />
              第1階層の場合は親グループコードの指定は不要です。
            </td>
            <td>A1001</td>
          </tr>
          <tr>
            <th>親グループ名</th>
            <td>-</td>
            <td>営業部</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)
