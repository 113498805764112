import React from 'react'
import {FileNameLink} from 'components/ui/FileNameLink'
import {Panel, Item} from 'components/PrimaryPanel'
import {useSelector} from 'hooks/redux'
import {Dl, Dd, Dt, Grid, SubPanel} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import styles from 'employees/show/Parts.scss'
import {displayFormat, dateFormat} from 'libs/formatter'

const Certificate = ({certificate}) => (
  <>
    {certificate && certificate.url ? (
      <FileNameLink url={certificate.url}>{displayFormat(certificate.filename)}</FileNameLink>
    ) : (
      displayFormat(certificate?.filename)
    )}
  </>
)

const LanguageItem = ({index, employeeLanguage, languageOptions, languageLevelOptions, yearStyle}) => {
  const findLanguage = () => {
    if (!employeeLanguage.language_id) {
      return null
    }

    return languageOptions.find((option) => option.value === employeeLanguage.language_id)?.label
  }
  const findLanguageLevel = () => {
    if (!employeeLanguage.level) {
      return null
    }

    return languageLevelOptions.find((option) => option.value === employeeLanguage.level)?.label
  }

  return (
    <SubPanel>
      <SubPanel.Headding>語学 ({index + 1})</SubPanel.Headding>
      <Grid className={styles.lastChildrenNotBorder}>
        <Dl>
          <Dt>言語</Dt>
          <Dd>{displayFormat(findLanguage())}</Dd>
        </Dl>
        <Dl>
          <Dt>レベル</Dt>
          <Dd>{displayFormat(findLanguageLevel())}</Dd>
        </Dl>
        <Dl>
          <Dt>資格名</Dt>
          <Dd>{displayFormat(employeeLanguage.qualification_name)}</Dd>
        </Dl>
        <Dl>
          <Dt>点数</Dt>
          <Dd>{displayFormat(employeeLanguage.score)}</Dd>
        </Dl>
        <Dl>
          <Dt>受験日</Dt>
          <Dd>{dateFormat(employeeLanguage.examination_date, yearStyle)}</Dd>
        </Dl>
        <Dl>
          <Dt>証明書</Dt>
          <Dd>
            <Certificate certificate={employeeLanguage.certificate} />
          </Dd>
        </Dl>
      </Grid>
    </SubPanel>
  )
}

const EmployeeLanguage = ({editLink, yearStyle, canEdit}) => {
  const employeeLanguages = useSelector((state) => state.employees.current.data.employee_languages) || []
  const languages = useSelector((state) => state.master.languages) || []
  const languageLevels = useSelector((state) => state.master.languageLevels) || []
  const languageOptions = languages.map((t) => ({value: t.id, label: t.name}))
  const languageLevelOptions = languageLevels.map((t) => ({value: t.id, label: t.name}))

  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeLanguage`} title="語学情報" canEdit={canEdit} />
      <Item>
        <Grid className={employeeLanguages.length == 1 && styles.gridFill}>
          {employeeLanguages.map((employeeLanguage, index) => (
            <LanguageItem
              key={employeeLanguage.id}
              index={index}
              employeeLanguage={employeeLanguage}
              languageOptions={languageOptions}
              languageLevelOptions={languageLevelOptions}
              yearStyle={yearStyle}
            />
          ))}
        </Grid>
        {employeeLanguages.length < 1 && (
          <div className={styles.noData}>
            <p>語学情報の登録はありません</p>
          </div>
        )}
      </Item>
    </Panel>
  )
}

export default EmployeeLanguage
