import React from 'react'
import formStyles from 'jbc-front/components/Form.scss'
import {Label, arrowRenderer as Arrow, renderTextWithAnchor} from 'jbc-front/components/Form'
import styles from 'settings/employeeCustom/FieldGroups/FieldView.scss'
import {Calendar, Fileup} from 'jbc-front/components/icons'
import {toColor} from 'settings/employeeCustom/ColorSelect'

const Icon = ({field}) => {
  switch (field.field_type) {
    case 'date':
      return (
        <i>
          <Calendar />
        </i>
      )
    case 'file':
      return (
        <i>
          <Fileup />
        </i>
      )
    case 'pulldown':
      return (
        <i>
          <Arrow isOpen />
        </i>
      )
    default:
      return null
  }
}

const Input = ({field, icons}) => {
  switch (field.field_type) {
    case 'multi_line_text':
      return (
        <div>
          <Label text={field.label} required={field.indispensability_type === 'indispensable'} isChangeLabelToAnchor />
          <div className={styles.fieldWrap}>
            <div className={formStyles.icnTextarea}>
              <textarea readOnly rows="10" />
            </div>
            {icons}
          </div>
        </div>
      )
    case 'checkbox':
      return (
        <div>
          <label className="input-label">
            <input type="checkbox" readOnly checked={false} className="m-checkbox-input" />
            <span className="m-checkbox-parts">{field.label}</span>
          </label>
          {icons}
        </div>
      )
    case 'radio_button':
      return (
        <div className={styles.radio}>
          <Label text={field.label} required={field.indispensability_type === 'indispensable'} isChangeLabelToAnchor />
          <div>
            {field.custom_employee_field_options.map(({label, id}) => (
              <div style={{display: 'inline-block'}} className="input-label" key={id}>
                <label>
                  <input type="radio" checked={false} readOnly className="m-radio-input" />
                  <span className="m-radio-parts">{label}</span>
                </label>
              </div>
            ))}
            {icons}
          </div>
        </div>
      )
    case 'pulldown':
      return (
        <div>
          <Label text={field.label} required={field.indispensability_type === 'indispensable'} isChangeLabelToAnchor />
          <div className={styles.fieldWrap}>
            <div className={formStyles.icnTextarea}>
              <input readOnly type="text" />
              <Icon field={field} />
            </div>
            {icons}
          </div>
          <div className={styles.options}>
            {field.custom_employee_field_options.map(({label, id}) => (
              <div key={id} className={styles.option}>
                {label}
              </div>
            ))}
          </div>
        </div>
      )
    default:
      return (
        <div>
          <Label text={field.label} required={field.indispensability_type === 'indispensable'} isChangeLabelToAnchor />
          <div className={styles.fieldWrap}>
            <div className={formStyles.icnTextarea}>
              <input readOnly type="text" />
              <Icon field={field} />
            </div>
            {icons}
          </div>
        </div>
      )
  }
}

const FieldView = ({field, icons}) => (
  <div className={formStyles.boxWrap}>
    <Input field={field} icons={icons} />
    {field.description && (
      <div className={styles.description} style={{color: toColor(field.description_color_by_rgb)}}>
        {renderTextWithAnchor(field.description)}
      </div>
    )}
  </div>
)

export default FieldView
