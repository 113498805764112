import {useFetchData} from 'hooks/api/useFetchData'
import {CurrentClientForMember, CurrentClientForAdmin} from 'types/api/clients/currentClient'

type Query = {
  embed: ['office']
}

type AdminRole = 'full_admin' | 'office_admin' | 'limited_admin'
type MemberRole = 'member'

export const useFetchCurrentClient = (clientRoleType: AdminRole | MemberRole, query?: Query) => {
  type T = typeof clientRoleType extends AdminRole ? CurrentClientForAdmin[] : CurrentClientForMember[]
  const path = '/api/current_client'
  return useFetchData<T>(path, [], {params: query})
}
