import {gql} from '@apollo/client'

export const GOVERNMENT_OFFICES = gql`
  query governmentOffices($prefectureId: ID!, $governmentOfficeType: GovernmentOfficeType!) {
    governmentOffices(prefectureId: $prefectureId, governmentOfficeType: $governmentOfficeType) {
      id
      prefectureId
      governmentOfficeType
      submitId
      name
      dispName
      shortName
    }
  }
`

export interface GovernmentOfficesVariables {
  prefectureId: string
  governmentOfficeType: GovernmentOfficeType
}

export interface GovernmentOfficesResult {
  governmentOffices: GovernmentOffice[]
}

export type GovernmentOfficeType = 'hello_work' | 'pension_office' | 'pension_center'

export interface GovernmentOffice {
  id: string
  prefectureId: string
  governmentOfficeType: GovernmentOfficeType
  submitId: string
  name: string
  dispName: string
  shortName: string
}
