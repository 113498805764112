import {FC, useEffect, useState} from 'react'
import {useSelector} from 'hooks/redux'
import useReactRouter from 'use-react-router'
import {stringify} from 'query-string'
import Button from 'jbc-front/components/Button'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import Loading from 'components/Loading'
import {useFetchApplicableProcedures} from 'hooks/api/procedureStatuses'
import {useFetchProcedureCsvRequest} from 'hooks/api/exportProcedureCsv'
import styles from './SelectProcedureModal.scss'

// @ts-ignore
import ListGroup from 'jbc-front/components/ListGroup'

type ModalProps = {
  csvDownloadable: boolean
}

export const SelectProcedureModal: FC<ModalProps> = ({csvDownloadable}) => {
  const query = useSelector((state) => state.procedureStatuses.query)
  const [isOpen, setIsOpen] = useState(false)
  const {history} = useReactRouter()
  const handleClose = () => setIsOpen(false)
  const {
    isLoading,
    resource: applicableProcedures,
    getter: applicableProceduresGetter,
  } = useFetchApplicableProcedures(query.procedure_type)
  const {isLoading: isCsvRequestLoading, resource: csvRequest} = useFetchProcedureCsvRequest()
  const working = csvRequest && ['waiting', 'in_progress'].includes(csvRequest.status)
  const onClickCsvProcedure = (procedureKind: string) => {
    history.push({
      pathname: '/procedure_statuses/choose_procedures',
      search: stringify({
        procedure_type: query.procedure_type,
        status: query.status,
        search_keyword: query.search_keyword,
        per_page: query.per_page,
        procedure_kind: procedureKind,
      }),
    })
  }

  useEffect(() => {
    if (isOpen) {
      applicableProceduresGetter()
    }
  }, [isOpen])

  return (
    <>
      <Button
        className={styles.eGovButton}
        disabled={!csvDownloadable || working}
        disabledReason={
          csvDownloadable && working ? '作成に時間がかかるため、完了しましたらメールにてお送りいたします。' : undefined
        }
        primary
        onClick={() => setIsOpen(true)}
      >
        提出用CSVダウンロード
      </Button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        <Modal.Header onClose={handleClose}>書類作成</Modal.Header>
        <Modal.Body>
          <div>
            {isLoading || isCsvRequestLoading ? (
              <Loading />
            ) : (
              <>
                <h5>作成する書類を選択してください</h5>
                <ListGroup>
                  {applicableProcedures.map((procedure) => (
                    <ListGroup.Item
                      key={procedure.procedure_kind}
                      as="a"
                      onClick={() => onClickCsvProcedure(procedure.procedure_kind)}
                    >
                      {procedure.name}
                      <ListGroup.Icon />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
