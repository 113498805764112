import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {Section, BoxDouble, SelectField, TextField} from 'jbc-front/components/Form'
import {connect} from 'react-redux'
import ActionButton from 'jbc-front/components/ActionButton'
import {MinusCircle, SelectTable, AutoCalculation} from 'jbc-front/components/icons'
import {Description, withFormSelectors} from 'employees/form/common'
import {number} from 'validators'
import _ from 'lodash'
import styles from 'employees/form/RemunerationInfomation.scss'
import onClickOutside from 'react-onclickoutside'
import {numberWithSplit} from 'utils'

export const remunerationTypeOptions = [
  {value: 'annually', label: '年俸'},
  {value: 'monthly', label: '月給'},
  {value: 'daily', label: '日給'},
  {value: 'hourly', label: '時給'},
]

const getStandardMonthlyAmount = (amounts, monthlyAmount) => {
  const amount = _.maxBy(
    amounts.filter((amount) => monthlyAmount >= amount.monthly_amount_from),
    'monthly_amount_from'
  )
  return amount ? amount.standard_monthly_amount : 0
}

class _StandardMonthlyAmountTable extends Component {
  handleClickOutside = () => {
    this.props.hide()
  }

  render() {
    const {healthStandardMonthlyAmounts, pensionStandardMonthlyAmounts, autofill, hide} = this.props
    return (
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th colSpan={2}>等級</th>
              <th rowSpan={2}>標準報酬月額</th>
              <th rowSpan={2}>報酬月額（円以上～円未満）</th>
            </tr>
            <tr>
              <th>健康保険</th>
              <th>厚生年金保険</th>
            </tr>
          </thead>
          <tbody>
            {healthStandardMonthlyAmounts.map((healthStandardMonthlyAmount) => {
              const pensionStandardMonthlyAmount = pensionStandardMonthlyAmounts.find(
                ({monthly_amount_to, monthly_amount_from}) =>
                  (healthStandardMonthlyAmount.monthly_amount_to !== null &&
                    healthStandardMonthlyAmount.monthly_amount_to === monthly_amount_to) ||
                  (monthly_amount_to === null &&
                    monthly_amount_from === healthStandardMonthlyAmount.monthly_amount_from)
              )
              return (
                <tr
                  key={healthStandardMonthlyAmount.id}
                  onClick={() => {
                    autofill(
                      'standard_monthly_amount_health',
                      getStandardMonthlyAmount(
                        healthStandardMonthlyAmounts,
                        healthStandardMonthlyAmount.standard_monthly_amount
                      )
                    )
                    autofill(
                      'standard_monthly_amount_pension',
                      getStandardMonthlyAmount(
                        pensionStandardMonthlyAmounts,
                        healthStandardMonthlyAmount.standard_monthly_amount
                      )
                    )
                    hide()
                    document.getElementsByName('RemunerationInfomation')[0].scrollIntoView()
                  }}
                >
                  <td>{healthStandardMonthlyAmount.grade}</td>
                  <td>{pensionStandardMonthlyAmount ? pensionStandardMonthlyAmount.grade : ''}</td>
                  <td>{numberWithSplit(healthStandardMonthlyAmount.standard_monthly_amount)}円</td>
                  <td>
                    {`${numberWithSplit(healthStandardMonthlyAmount.monthly_amount_from)}円 〜 ` +
                      (healthStandardMonthlyAmount.monthly_amount_to
                        ? `${numberWithSplit(healthStandardMonthlyAmount.monthly_amount_to)}円`
                        : '')}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

const StandardMonthlyAmountTable = onClickOutside(_StandardMonthlyAmountTable)

class RemunerationInfomation extends Component {
  handleClick = () => {
    this.setState({showStandardMonthlyAmountTable: false})
  }

  constructor(props) {
    super(props)
    this.state = {
      showStandardMonthlyAmountTable: false,
    }
  }

  hideTable = () => {
    this.setState({showStandardMonthlyAmountTable: false})
  }

  render() {
    const {
      healthStandardMonthlyAmounts,
      pensionStandardMonthlyAmounts,
      autofill,
      monthlyAmount,
      standardMonthlyAmountHealthValue,
      description,
      description_color_by_rgb,
      diff = {},
    } = this.props
    const {showStandardMonthlyAmountTable} = this.state
    return (
      <Section title="給与">
        <Description
          {...{
            description,
            description_color_by_rgb,
          }}
        />
        <BoxDouble>
          <SelectField
            name="remuneration_type"
            label="給与形態"
            options={remunerationTypeOptions}
            diff={_.get(diff, 'remuneration_type')}
          />
          <TextField
            name="remuneration_base"
            label="基本給"
            validate={number}
            yen
            diff={_.get(diff, 'remuneration_base')}
          />
          <TextField
            name="monthly_remuneration"
            label="報酬月額（通貨）"
            validate={number}
            yen
            note="通勤手当他各種手当を含めた１ヶ月の給与の合計（見込）金額を入力します。"
            diff={_.get(diff, 'monthly_remuneration')}
          />
          <TextField
            name="monthly_remuneration_in_kind"
            label="報酬月額（現物）"
            validate={number}
            yen
            note="定期券を現物で支給している場合は月額相当分金額をこちらに入力します。"
            diff={_.get(diff, 'monthly_remuneration_in_kind')}
          />
        </BoxDouble>
        <div className="u-mb10">
          <ActionButton
            icon={<SelectTable />}
            onClick={() => this.setState({showStandardMonthlyAmountTable: true})}
            className="u-mr10"
          >
            表から選択
          </ActionButton>
          <ActionButton
            icon={<AutoCalculation />}
            onClick={() => {
              autofill(
                'standard_monthly_amount_health',
                getStandardMonthlyAmount(healthStandardMonthlyAmounts, monthlyAmount)
              )
              autofill(
                'standard_monthly_amount_pension',
                getStandardMonthlyAmount(pensionStandardMonthlyAmounts, monthlyAmount)
              )
            }}
            className="u-mr10"
          >
            報酬月額から自動計算
          </ActionButton>
          {standardMonthlyAmountHealthValue && (
            <ActionButton
              icon={<MinusCircle />}
              onClick={() => {
                autofill('standard_monthly_amount_health', '')
                autofill('standard_monthly_amount_pension', '')
              }}
            >
              クリア
            </ActionButton>
          )}
        </div>
        {showStandardMonthlyAmountTable && (
          <StandardMonthlyAmountTable
            {...{healthStandardMonthlyAmounts, pensionStandardMonthlyAmounts, autofill}}
            hide={this.hideTable}
          />
        )}
        <TextField
          name="standard_monthly_amount_health"
          label="標準報酬月額（健康保険）"
          disabled
          diff={_.get(diff, 'standard_monthly_amount_health')}
        />
        <TextField
          name="standard_monthly_amount_pension"
          label="標準報酬月額（厚生年金保険）"
          disabled
          diff={_.get(diff, 'standard_monthly_amount_pension')}
        />
        <TextField
          name="estimated_annual_income"
          label="今年の年間収入見積額"
          validate={number}
          diff={_.get(diff, 'estimated_annual_income')}
          yen
          onChange={(e, newValue) => {
            let earnings

            if (newValue < 551000) {
              earnings = 0
            } else {
              let rounded

              if (newValue >= 1619000 && newValue < 1620000) {
                rounded = parseInt(newValue / 1000) * 1000
              } else if (newValue >= 1620000 && newValue < 1624000) {
                rounded = parseInt(newValue / 2000) * 2000
              } else if (newValue >= 1624000 && newValue < 6600000) {
                rounded = parseInt(newValue / 4000) * 4000
              } else {
                rounded = newValue
              }
              const lessThan180 = (rounded * 60) / 100 + 100000
              earnings = parseInt(
                Math.min(
                  rounded - 550000,
                  Math.max(
                    lessThan180 > 550000 ? lessThan180 : 550000,
                    (rounded * 70) / 100 - 80000,
                    (rounded * 80) / 100 - 440000,
                    (rounded * 90) / 100 - 1100000,
                    rounded - 1950000
                  )
                )
              )
            }

            autofill('estimated_annual_earnings', `${earnings}`)
          }}
          note={
            <p>
              「今年の年間収入見積額」を入力すると、「今年の年間所得見積額」に自動で値が入ります。（詳しい計算方法は
              <a
                href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1410.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                国税庁HP
              </a>
              をご覧ください。）
            </p>
          }
        />
        <TextField
          name="estimated_annual_earnings"
          label="今年の年間所得見積額"
          validate={number}
          diff={_.get(diff, 'estimated_annual_earnings')}
          yen
          note={
            <p>
              「源泉控除対象配偶者」の判定に使用します。
              <br />
              従業員本人と生計を一にする配偶者の合計所得金額が95万円以下で、かつ従業員本人の年間所得が900万円以下である事が要件です。
              <br />
              「源泉控除対象配偶者」に該当すると、毎月天引きする所得税を計算する際に、扶養親族等を1人加算します。
              <br />
              （配偶者控除についての詳細は
              <a href="https://www.nta.go.jp/users/gensen/haigusya/index.htm" target="_blank" rel="noopener noreferrer">
                国税庁HP
              </a>
              をご覧ください。）
            </p>
          }
        />
      </Section>
    )
  }
}

export default RemunerationInfomation
  |> connect(
    (state, {selector}) => ({
      healthStandardMonthlyAmounts: state.master.healthStandardMonthlyAmounts || [],
      pensionStandardMonthlyAmounts: state.master.pensionStandardMonthlyAmounts || [],
      standardMonthlyAmountHealthValue: selector(state, 'standard_monthly_amount_health'),
      monthlyAmount:
        (+selector(state, 'monthly_remuneration') || 0) + (+selector(state, 'monthly_remuneration_in_kind') || 0),
      diff: selector(state, '_diff'),
    }),
    (dispatch, {autofill}) => bindActionCreators.bind(null, {autofill})
  )
  |> withFormSelectors
