import React from 'react'
import styles from 'components/ShowMoreButton.scss'
import {Pulldown} from 'jbc-front/components/icons'

const ShowMoreButton = ({isExpanded, onClick}) => {
  const text = isExpanded ? '閉じる' : 'もっと見る'
  return (
    <button className={styles.btn} onClick={onClick}>
      {text}
      <Pulldown size={14} transform={isExpanded ? 'rotate(180)' : 'rotate(0)'} />
    </button>
  )
}

export default ShowMoreButton
