import {FC, useState} from 'react'
import useReactRouter from 'use-react-router'
import {useQuery} from 'hooks/graphql'
import {EMPLOYEE_DOCUMENTS, QueryResult, QueryVariables, Search, Order, SortColumn} from './query'
import {TitleContainer} from 'components/layout/TitleContainer'
import {MainTitle} from 'components/ui/MainTitle'
import {Content} from 'components/layout/Content'
import {LoadingPage} from 'components/ui/LoadingPage'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Button from 'jbc-front/components/Button'
import {Upload} from 'jbc-front/components/icons'
import {DocumentList} from './parts/DocumentList'
import {FileUploadModal} from './parts/FileUploadModal'

import styles from './Presenter.scss'

export const Presenter: FC = () => {
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false)
  const {
    history,
    match: {
      params: {id},
    },
  } = useReactRouter<{id: string}>()
  const [sortOrder, setSortOrder] = useState<Order>('desc')
  const [sortColumn, setSortColumn] = useState<SortColumn>('created_at')
  const [search, setSearch] = useState<Search>({
    sortOrder,
    sortColumn,
  })
  const {data, loading} = useQuery<QueryResult, QueryVariables>(EMPLOYEE_DOCUMENTS, {
    fetchPolicy: 'network-only',
    variables: {id: Number(id), search},
  })
  const employeeDocuments = data?.client?.employeeDocuments
  const employee = employeeDocuments?.employee
  const documents = employeeDocuments?.documents

  const handleSort = (column: SortColumn) => {
    if (sortColumn === column) {
      const nextOrder = inverseOrder()
      setSearch((prev) => ({
        ...prev,
        sortOrder: nextOrder,
      }))
      setSortOrder(nextOrder)
    } else {
      setSearch({
        sortOrder: 'desc',
        sortColumn: column,
      })
      setSortOrder('desc')
      setSortColumn(column)
    }
  }

  const inverseOrder = () => {
    switch (sortOrder) {
      case 'desc':
        return 'asc'
      case 'asc':
        return 'desc'
    }
  }

  const handleBack = () => {
    history.push('/document_employees')
  }

  return (
    <>
      {employee && (
        <TitleContainer subPage>
          <MainTitle>{`${employee?.displayLastName} ${employee?.displayFirstName}`}さんの書類一覧</MainTitle>
        </TitleContainer>
      )}

      <Content size="xxl">
        {loading ? (
          <LoadingPage />
        ) : (
          <>
            {employee && (
              <div className={styles.fileUploadContainer}>
                <Button primary onClick={() => setIsFileUploadModalOpen(true)}>
                  <Upload />
                  <Spacer direction="x" size={7} />
                  ファイルアップロード
                </Button>
                <FileUploadModal
                  isOpen={isFileUploadModalOpen}
                  onClose={() => setIsFileUploadModalOpen(false)}
                  search={search}
                  employeeId={employee.id}
                />
              </div>
            )}

            <Spacer direction="y" size={20} />

            {documents && employee && (
              <DocumentList
                employeeId={employee.id}
                documents={documents}
                onSort={handleSort}
                order={sortOrder}
                sortColumn={sortColumn}
              />
            )}

            <Spacer direction="y" size={30} />

            <div className="u-ta-c">
              <Button onClick={handleBack}>戻る</Button>
            </div>
          </>
        )}
      </Content>
    </>
  )
}
