import React, {useReducer, useState, useContext, useCallback, useEffect, useRef} from 'react'
import _ from 'lodash'

const WizardContext = React.createContext({})

export const useWizard = () => useContext(WizardContext)

export const useBeforeUnload = (returnValue = '') => {
  const handleBeforeUnload = useCallback(
    (event) => {
      event.preventDefault()
      event.returnValue = returnValue
    },
    [returnValue]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [handleBeforeUnload])
}

const ACTION_POP = 'POP'
const ACTION_PUSH = 'PUSH'
const ACTION_SET = 'SET'
const ACTION_DISCARD = 'DISCARD'

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_POP: {
      const {current, breadcrumb} = state
      const currentIndex = breadcrumb.indexOf(current)
      if (currentIndex < 1) {
        return state
      }
      return {...state, current: breadcrumb[currentIndex - 1]}
    }
    case ACTION_PUSH: {
      const {current, breadcrumb} = state
      const {next} = action.payload
      if (_.last(breadcrumb) === current) {
        // current = A
        // next = B
        // breadcrumb = [A]
        return {
          ...state,
          current: next,
          breadcrumb: [...breadcrumb, next],
        }
      } else if (breadcrumb[breadcrumb.indexOf(current) + 1] === next) {
        // current = A
        // next = B
        // breadcrumb = [A, B]
        return {
          ...state,
          current: next,
        }
      } else {
        // current = A
        // next = C
        // breadcrumb = [A, B]
        console.error('not implemented yet')
        return state
      }
    }
    case ACTION_SET: {
      const {step} = action.payload
      if (!state.breadcrumb.includes(step)) {
        console.error(`invalid step: '${step}'`)
        return state
      }
      return {
        ...state,
        current: step,
      }
    }
    case ACTION_DISCARD: {
      const {breadcrumb} = state
      const {step} = action.payload
      if (!state.breadcrumb.includes(step)) {
        return {...state, breadcrumb: [step]}
      }
      return {...state, breadcrumb: breadcrumb.slice(0, breadcrumb.indexOf(step) + 1)}
    }
    default:
      throw new Error(`invalid action: '${action}'`)
  }
}

export const Wizard = ({initialStep, children}) => {
  const [state, dispatch] = useReducer(reducer, {
    current: initialStep,
    breadcrumb: [initialStep],
  })
  const stateRef = useRef(state)
  useEffect(() => {
    stateRef.current = state
  }, [state])

  const [draft, setDraft] = useState({})

  const pushStep = useCallback((next, scrollToTop = true) => {
    dispatch({type: ACTION_PUSH, payload: {next}})
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [])

  const popStep = useCallback((scrollToTop = true) => {
    dispatch({type: ACTION_POP})
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [])

  const setStep = useCallback((step, scrollToTop = true) => {
    dispatch({type: ACTION_SET, payload: {step}})
    if (scrollToTop) {
      window.scrollTo(0, 0)
    }
  }, [])

  const handlePopState = useCallback(() => {
    if (_.first(stateRef.current.breadcrumb) === stateRef.current.current) {
      window.history.back()
    } else {
      window.history.pushState(null, null, null)
      popStep()
    }
  }, [])

  const discardStep = useCallback((step) => {
    dispatch({type: ACTION_DISCARD, payload: {step}})
  }, [])

  useEffect(() => {
    window.history.pushState(null, null, null)
    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [])

  const context = {
    ...state,
    pushStep,
    popStep,
    setStep,
    discardStep,
    draft,
    setDraft,
  }
  return <WizardContext.Provider value={context}>{children}</WizardContext.Provider>
}

export const Step = ({name, children}) => {
  const {current} = useContext(WizardContext)
  return current === name ? children : null
}
