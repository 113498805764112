import {FC} from 'react'
import {EgovProcedure} from '../../query'
import {PrepareRow} from '../PrepareRow'
import {ApplicationRow} from '../ApplicationRow'
import {EgovApplicationRow} from '../EgovApplicationRow'
import {Hint} from 'jbc-front/components/presenters/ui/Hint'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import {fullNameFormat} from 'libs/formatter'

import styles from './List.scss'

interface ListProps {
  egovProcedures?: EgovProcedure[]
  onOpenApplyModal: (egovProcedure: EgovProcedure) => void
  onOpenDeleteModal: (egovProcedureId: string) => void
}

export const List: FC<ListProps> = ({egovProcedures = [], onOpenApplyModal, onOpenDeleteModal}) => {
  const switchTableRow = (egovProcedure: EgovProcedure) => {
    const isDeletable = egovProcedure.isDeletable
    const handleOpenDeleteModal = () => {
      if (isDeletable) {
        onOpenDeleteModal(egovProcedure.id)
      }
    }

    const employeeOrOfficeName = () => {
      if (egovProcedure.procedureStatus) {
        const {
          employee: {firstName, lastName},
        } = egovProcedure.procedureStatus
        return fullNameFormat(lastName, firstName)
      } else {
        return egovProcedure.office.name
      }
    }

    if (egovProcedure.application) {
      return (
        <ApplicationRow
          key={`table-row-key-${egovProcedure.id}`}
          egovProcedure={egovProcedure}
          employeeOrOfficeName={employeeOrOfficeName()}
          onOpenDeleteModal={handleOpenDeleteModal}
          isDeletable={isDeletable}
        />
      )
    } else if (egovProcedure.egovApplication) {
      return (
        <EgovApplicationRow
          key={`table-row-key-${egovProcedure.id}`}
          egovProcedure={egovProcedure}
          employeeOrOfficeName={employeeOrOfficeName()}
          onOpenDeleteModal={handleOpenDeleteModal}
          isDeletable={isDeletable}
        />
      )
    } else {
      return (
        <PrepareRow
          key={`table-row-key-${egovProcedure.id}`}
          egovProcedure={egovProcedure}
          employeeOrOfficeName={employeeOrOfficeName()}
          onOpenApplyModal={onOpenApplyModal}
          onOpenDeleteModal={handleOpenDeleteModal}
          isDeletable={isDeletable}
        />
      )
    }
  }

  return (
    <div className="l-overflow-scroll">
      <table className={`m-table-list ${styles.table}`}>
        <thead>
          <tr>
            <th className={styles.th}>ステータス</th>
            <th className={styles.th}>氏名・事業所名</th>
            <th className={styles.th}>手続き</th>
            <th className={styles.th}>帳票</th>
            <th className={styles.th}>
              <div className={styles.withHint}>
                公文書・コメント
                <Spacer size={4} direction="x" />
                <Hint>
                  <p>
                    公文書ファイルが文字化けしている場合は
                    <a
                      target="_blank"
                      href="https://jobcan-lms.zendesk.com/hc/ja/articles/360001865711"
                      rel="noopener noreferrer"
                    >
                      こちら
                    </a>
                  </p>
                </Hint>
              </div>
            </th>
            <th className={styles.th}>申請日時</th>
            <th className={styles.th}>削除</th>
          </tr>
        </thead>
        <tbody>
          {egovProcedures?.length > 0 ? (
            egovProcedures.map((egovProcedure) => switchTableRow(egovProcedure))
          ) : (
            <tr>
              <td colSpan={7}>電子申請がまだありません</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
