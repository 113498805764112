import React, {useEffect} from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {reduxForm, formValueSelector, FormSection, Field} from 'redux-form'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import ListGroup from 'jbc-front/components/ListGroup'
import {clientRoles} from 'settings/clientRoles/ChangeMulti'
import {InputRadio} from 'employees/CsvDownload'
import {basicOptions} from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupPermissionForm'
import {tabs} from 'myNumber/List'
import styles from 'settings/clientRoles/Edit.scss'

const formName = 'updateForm'
const selector = formValueSelector(formName)

const toInitialValues = (fields, path, value) => _.fromPairs(fields.map((field) => [field[path], value]))

const toRadioValue = (value, toBoolean) =>
  value ? (toBoolean ? 'true' : 'updatable') : toBoolean ? 'false' : 'not_display'

const makeInitialvalues = (clientRoleId, employeeFieldGroups, customFieldGroups) => ({
  role_settings: {client_role: clientRoleId},
  default_role_settings: {
    ...toInitialValues(employeeFieldGroups, 'code', toRadioValue(clientRoleId !== '2')),
    ...toInitialValues(tabs, 'value', toRadioValue(clientRoleId !== '2' && clientRoleId !== '4', true)),
    csv_download: toRadioValue(clientRoleId !== '2', true),
  },
  customize_role_settings: {
    ...toInitialValues(customFieldGroups, 'id', toRadioValue(clientRoleId !== '2')),
  },
})

const makeClientRoleDescription = (clientRoleId) => {
  switch (clientRoleId) {
    case '1': {
      return '全ての設定等の権限を持つ管理者です'
    }
    case '2': {
      return 'ジョブカン労務HRのサービス設定を変更することは出来ません'
    }
    case '3': {
      return (
        <ul>
          <li>・サービス設定のうち、利用プラン、権限管理設定、IPアドレス制限の設定は変更することが出来ません</li>
          <li>・通知は自分の設定のみ、事業所情報は権限を持っている事業所のみ変更可能です</li>
        </ul>
      )
    }
    case '4': {
      return (
        <ul>
          <li>・ジョブカン労務HRの設定、マイナンバーに関する操作権限はありません</li>
          <li>・従業員一覧、手続き等は全従業員について参照することが可能です</li>
        </ul>
      )
    }
  }
}

const renderRadioField = ({label, input, options, description, disabled, em, row}) => (
  <div>
    <div className={classNames({[styles.rowRadioField]: row})}>
      <label className={classNames(styles.radioLabel, {[styles.radioLabelEm]: em}, {[styles.rowRadioLabel]: row})}>
        {label}
      </label>
      <div className={styles.radioButtonsWrap}>
        {options.map(({label, value}) => (
          <InputRadio {...{label, value, input, disabled}} key={value} />
        ))}
      </div>
    </div>
    {description && <div className={styles.description}>{description}</div>}
  </div>
)

const Section = ({title, description, children}) => (
  <section>
    {title && <h2 className={styles.sectionTitle}>{title}</h2>}
    {description && <p className={styles.description}>{description}</p>}
    <div className={classNames(styles.sectionContents, {[styles.sectionContentsMargin]: title || description})}>
      {children}
    </div>
  </section>
)

const FormGroup = ({title, sectionName, readOnly, children}) => (
  <ListGroup>
    <ListGroup.Title>
      <p>{title}</p>
    </ListGroup.Title>
    <ListGroup.Item style={{padding: '30px'}}>
      <FormSection name={sectionName}>
        <div className={classNames(styles.formWrap, {[styles.readOnly]: readOnly})}>{children}</div>
      </FormSection>
    </ListGroup.Item>
  </ListGroup>
)

const RoleForm = ({readOnly}) => {
  const {client_role} = useSelector((state) => selector(state, 'role_settings'))
  return (
    <Field
      name="client_role"
      label="権限名"
      component={renderRadioField}
      options={clientRoles}
      description={makeClientRoleDescription(client_role)}
      disabled={readOnly}
    />
  )
}

const DefaultRoleForm = ({fieldGroups, readOnly}) => (
  <div className={styles.sectionWrap}>
    {!_.isEmpty(fieldGroups) && (
      <Section title="本人以外の従業員情報">
        {fieldGroups.map(({code, label}) => (
          <Field
            key={code}
            component={renderRadioField}
            label={label}
            name={code}
            options={basicOptions}
            disabled={readOnly}
            row
          />
        ))}
      </Section>
    )}
    <Section>
      <Field
        label="従業員情報CSVダウンロード"
        name="csv_download"
        component={renderRadioField}
        options={[
          {value: 'true', label: '可能'},
          {value: 'false', label: '不可能'},
        ]}
        disabled={readOnly}
        row
        em
      />
    </Section>
    <Section title="マイナンバー">
      {tabs.map(({label, value}, i) => (
        <Field
          key={i}
          label={label}
          name={value}
          component={renderRadioField}
          options={[
            {value: 'true', label: '操作可能'},
            {value: 'false', label: '操作不可能'},
          ]}
          disabled={readOnly}
          row
        />
      ))}
    </Section>
  </div>
)

const CustomizeRoleForm = ({fieldGroups, readOnly}) => (
  <Section>
    {fieldGroups.map(({label, id}) => (
      <Field
        key={id}
        label={label}
        name={id.toString()}
        component={renderRadioField}
        options={basicOptions}
        disabled={readOnly}
        row
      />
    ))}
  </Section>
)

const Form =
  (({handleSubmit, employeeFieldGroups, customFieldGroups}) => {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup title="権限設定" sectionName="role_settings" readOnly>
          <RoleForm readOnly />
        </FormGroup>
        <FormGroup title="デフォルト項目の権限設定" sectionName="default_role_settings" readOnly>
          <DefaultRoleForm fieldGroups={employeeFieldGroups} readOnly />
        </FormGroup>
        {!_.isEmpty(customFieldGroups) && (
          <FormGroup title="カスタマイズ項目の設定" sectionName="customize_role_settings" readOnly>
            <CustomizeRoleForm fieldGroups={customFieldGroups} readOnly />
          </FormGroup>
        )}
      </form>
    )
  })
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
  })

const Edit = (props) => {
  const {id} = props.match.params
  const [token, client, employeeFieldGroups = [], customFieldGroups = []] = useSelector((state) => [
    state.auth.token,
    state.client.current,
    fetchSelector(state, 'employee_field_groups').data,
    fetchSelector(state, 'custom_field_groups').data,
  ])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actionCreators.fetchData('employee_field_groups', api.createWithAuth(token).employeeFieldGroups.list()))
    dispatch(
      actionCreators.fetchData(
        'custom_field_groups',
        api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
      )
    )
    return () => {
      dispatch(actionCreators.fetchDestroy('employee_field_groups'))
      dispatch(actionCreators.fetchDestroy('custom_field_groups'))
    }
  }, [])
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">権限詳細</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <Link to="/settings/client_roles" className="l-breadcrumb-link">
            権限管理設定
          </Link>
          <Link to="/settings/client_roles/list" className="l-breadcrumb-link">
            権限一覧
          </Link>
          <span className="l-breadcrumb-here">権限詳細</span>
        </div>
        <Form
          initialValues={makeInitialvalues(id, employeeFieldGroups, customFieldGroups)}
          employeeFieldGroups={employeeFieldGroups}
          customFieldGroups={customFieldGroups}
        />
      </div>
    </div>
  )
}

export default Edit
