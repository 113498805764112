import {FC} from 'react'
import {Content} from 'components/layout/Content'
import {SubPageTitle} from 'components/ui/SubPageTitle'
import {Table} from 'components/ui/Table'
import {Row} from './parts/Row'
import {DOCUMENT_EMPLOYEES, QueryResult} from './query'
import {useQuery} from 'hooks/graphql'

export const Presenter: FC = () => {
  const {data} = useQuery<QueryResult>(DOCUMENT_EMPLOYEES, {fetchPolicy: 'network-only'})
  const documentEmployees = data?.documentEmployees || []

  return (
    <Content size="l">
      <SubPageTitle>書類一覧</SubPageTitle>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th width={'20%'}>公開日</Table.Th>
            <Table.Th width={'40%'}>書類名</Table.Th>
            <Table.Th width={'22%'}>作成者</Table.Th>
            <Table.Th width={'8%'}>署名</Table.Th>
            <Table.Th width={'10%'}>プレビュー</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {documentEmployees.length > 0 ? (
            documentEmployees.map((documentEmployee) => (
              <Row key={documentEmployee.id} documentEmployee={documentEmployee} />
            ))
          ) : (
            <Table.Tr>
              <Table.Td colSpan={5}>書類が存在しません</Table.Td>
            </Table.Tr>
          )}
        </Table.Tbody>
      </Table>
    </Content>
  )
}
