import {FC} from 'react'
import {Link} from 'react-router-dom'
import {Adjustment} from 'jbc-front/components/icons'
import styles from './YearEndAdjustmentLink.scss'

// @ts-ignore
import {ListGroup, ListItem} from 'components/ListGroup'

interface YearEndAdjustmentLinkProps {
  shouldRender: boolean
}

export const YearEndAdjustmentLink: FC<YearEndAdjustmentLinkProps> = ({shouldRender}) => {
  if (!shouldRender) {
    return null
  }
  return (
    <section className={styles.yeaLinkWrap}>
      <ListGroup className={styles.yeaLink}>
        <ListItem as={Link} to="/year_end_adj_start" target="_blank">
          <div className={styles.icon}>
            <Adjustment />
          </div>
          <div>
            <p className={styles.name}>令和5年年末調整</p>
            <p className={styles.text}>年末調整を行います</p>
          </div>
        </ListItem>
      </ListGroup>
    </section>
  )
}
