import React, {useMemo, useState, useEffect, useRef} from 'react'
import styles from './dropdown-menu.scss'
import {useOutsideClick} from 'hooks/useOutSideClick'
import classNames from 'classnames'

const MenuItems = ({menuItems, closeMenu}) => {
  const refForOutsideClick = useOutsideClick(closeMenu)

  return (
    <div className="dd-menu-items" ref={refForOutsideClick}>
      <ul key="items" className="dd-items-right">
        {menuItems.map(({label, description, style, onClick, disabled, disabledReason = null}, index) => (
          <li
            onClick={() => {
              if (onClick && !disabled) {
                onClick()
                closeMenu()
              }
            }}
            className={classNames(style, {[styles.disabled]: disabled, clickable: onClick && !disabled})}
            key={index}
          >
            {label}
            {description && <span>{description}</span>}
            {disabled && disabledReason && <span className={styles.disabledReason}>{disabledReason}</span>}
          </li>
        ))}
      </ul>
    </div>
  )
}

const DropDownMenu = ({menuItems, toggle}) => {
  const menuRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const handleToggleClick = (e) => {
    e?.stopPropagation?.()
    setIsOpen((isOpen) => !isOpen)
  }

  useEffect(() => {
    const closeMenu = () => {
      setIsOpen(false)
    }

    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        closeMenu()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [isOpen])

  const Toggle = useMemo(
    () =>
      toggle ||
      (({onClick}) => (
        <button type="text" onClick={onClick}>
          Click me!
        </button>
      )),
    [toggle]
  )

  return (
    <div ref={menuRef} className="c-dropdown">
      <div className="dd-menu dd-menu-right">
        <Toggle onClick={handleToggleClick} isOpen={isOpen} />
        {isOpen && <MenuItems menuItems={menuItems} closeMenu={() => setIsOpen(false)} />}
      </div>
    </div>
  )
}

export default DropDownMenu
