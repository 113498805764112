import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import PaginatorWithResult from 'settings/auditLogs/list/PaginatorWithResult'
import {getSavedDisplayAuditLogLimit} from 'settings/auditLogs/list/utils'
import {AuditLogGroup} from 'settings/auditLogs/List'
import SearchForm from 'settings/auditLogs/list/SearchForm'

const operationTypes = [
  {id: 'all', name: 'すべて'},
  {id: 'employee_info', name: '従業員情報'},
]

const List = ({list, user, location: {pathname}}) => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">操作履歴</h1>
    </div>
    <div className="l-wrap-s l-contents-wrap">
      <div className="l-breadcrumb u-mb20">
        <Link to="/my_number?my_number_employment_status=employed_and_joined" className="l-breadcrumb-link">
          マイナンバー
        </Link>
        <span className="l-breadcrumb-here">操作履歴</span>
      </div>
      <SearchForm operationTypes={operationTypes} additionalParams={{my_number: true}} />
      <div>
        <AuditLogGroup list={list} path="my_number" />
        <PaginatorWithResult limit={getSavedDisplayAuditLogLimit(pathname, user)} />
      </div>
    </div>
  </div>
)

export default connect((state) => ({
  list: state.settings.auditLogs.list.data,
  location: state.router.location,
  user: state.session.currentUser,
}))(List)
