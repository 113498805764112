import React, {useState, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {push} from 'connected-react-router'
import {gql} from '@apollo/client'
import {actionCreators} from 'actions'
import {useMutation, useQuery} from 'components/Graphql'
import WizardStepForms from 'documents/create/WizardStepForms'
import {Wizard, useWizard} from 'components/Wizard'
import {parse} from 'query-string'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const headerMemo = {
  SelectTemplate: ' 利用するテンプレートを選択してください',
  SelectEmployees: ' 作成する対象の従業員を選択してください（複数選択可）',
  UploadCustomVariables: ' 作成する書類情報を設定してください',
  Confirmation: ' 書類イメージを確認してください',
}

const DOCUMENT_FRAGMENT = gql`
  fragment DocumentFields on Document {
    id
    title
    visibility
    documentEmployeesCount
    flag
    createdAt
    isSign
    createdUser {
      id
      name
      employee {
        id
        displayFirstName
        displayLastName
      }
    }
  }
`

const CREATE_DOCUMENT = gql`
  mutation createDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        ...DocumentFields
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
`

const DOCUMENT_TEMPLATE_FRAGMENT = gql`
  fragment DocumentTemplateFields on DocumentTemplate {
    id
    title
    draft
    body
    visibility
    hasCsvVariables
    hasEmployeeVariables
    description
    downloadFilename
    documentTemplateCategoryName
    style
  }
`

const DOCUMENT_TEMPLATES = gql`
  query documentTemplates($search: DocumentTemplateSearchInput) {
    client {
      id
      documentTemplates(search: $search) {
        list {
          ...DocumentTemplateFields
        }
      }
    }
  }
  ${DOCUMENT_TEMPLATE_FRAGMENT}
`

const Create = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actionCreators.employees.clearSelected())
    return () => dispatch(actionCreators.employees.clearSelected())
  }, [])
  return (
    <Wizard initialStep="SelectTemplate">
      <TitleHeader />
      <FormArea />
    </Wizard>
  )
}

const TitleHeader = () => {
  const {current, breadcrumb} = useWizard()
  return (
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">書類作成</h1>
      <p className="m-title-main-note">
        Step{breadcrumb.indexOf(current) + 1}
        {headerMemo[current]}
      </p>
    </div>
  )
}

const FormArea = () => {
  const location = useSelector((state) => state.router.location)
  const {draft, setDraft} = useWizard()
  const dispatch = useDispatch()
  const [createDocument] = useMutation(CREATE_DOCUMENT)
  const [initialValues, setInitialValues] = useState()
  const [selected] = useSelector((state) => [state.employees.selected])
  const {data} = useQuery(DOCUMENT_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      search: {sortOrder: 'desc', asSelectOptions: true},
    },
  })
  const templateList = data?.client?.documentTemplates.list
  const templateOptions = useMemo(
    () => templateList?.filter((c) => !c.draft).map((c) => ({value: c.id, label: c.title})) || [],
    [data]
  )

  const handleSubmit = async () => {
    try {
      const {data} = await createDocument({
        variables: {
          input: {
            document: {
              documentTemplateId: draft.documemtTemplateId,
              customVariablesFile: draft.customVariablesFile,
              title: draft.title,
              employeeIds: draft.checkEmployees ? draft.employeeIds : null,
            },
          },
        },
      })
      dispatch(push(`/documents/${data.createDocument.document.id}`))
      dispatch(notifySuccess('作成しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  useEffect(() => {
    const templateObject = templateList?.find((c) => c.id == parse(location.search).template)
    setDraft({
      ...draft,
      templateObject: templateObject,
      templateList: templateList,
      title: templateObject?.title,
      customVariablesFile: null,
      documemtTemplateId: templateObject?.id || null,
      checkEmployees: templateObject?.hasCsvVariables || templateObject?.hasEmployeeVariables,
    })
    setInitialValues({
      documentTemplateSelect: templateObject?.id,
    })
  }, [data])
  return (
    <div className="l-wrap-xxl l-contents-wrap">
      <WizardStepForms
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        selected={selected}
        templateOptions={templateOptions}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default Create
