import React from 'react'
import {connect} from 'react-redux'
import {autofill, reduxForm, getFormValues, reset, getFormMeta} from 'redux-form'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {getState} from 'utils'
import {actionCreators} from 'actions'
import AdditionalSearchFields from 'tm/employees/list/AdditionalSearchFields'
import ListTypeWithResult from 'tm/employees/list/ListTypeWithResult'
import styles from 'tm/employees/list/SearchForm.scss'
import searchDetector from 'searchDetector'
import {Pulldown} from 'jbc-front/components/icons'

import {
  formName,
  getSavedDisplayEmployeeLimit,
  saveDisplayEmployeeLimit,
  convertQueryToForm,
  getCurrentQueryFromLocation,
  fetchEmployees,
  fetchEmployeesAll,
  convertFormToQueryString,
  fetchSelections,
  hasConditions,
} from 'tm/employees/list/utils'
import compose from 'lodash/fp/compose'
import moment from 'moment'

class SearchFormWithCardList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }

  componentDidMount() {
    const {token, dispatch} = this.props
    fetchSelections(token, dispatch)
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('selections'))
  }

  clearForm = () => {
    const {dispatch} = this.props
    getState(dispatch).then((state) => {
      dispatch(reset(formName))
      dispatch(push({...state.router.location, search: ''}))
    })
  }

  render() {
    const {user, pathname = '', formValues, handleSubmit, searchEmail, displayListType} = this.props
    const text = this.state.isExpand ? '詳細条件を閉じる' : '詳細条件を開く'
    return (
      <div className={styles.searchForm}>
        <div className={styles.details}>
          <form onSubmit={handleSubmit}>
            {
              <AdditionalSearchFields
                isExpand={this.state.isExpand}
                hasConditions={hasConditions(formValues)}
                handleClear={this.clearForm}
                searchEmail={searchEmail}
              />
            }
          </form>
          {
            <ExpandButton
              text={text}
              isExpand={this.state.isExpand}
              onToggleClick={() => {
                this.setState({isExpand: !this.state.isExpand})
              }}
            />
          }
        </div>
        <form onSubmit={handleSubmit}>
          <ListTypeWithResult
            limit={getSavedDisplayEmployeeLimit(pathname, user)}
            onChangeLimit={(newLimit) => {
              saveDisplayEmployeeLimit(pathname, user, newLimit)
              handleSubmit()
            }}
            onChangeSortType={handleSubmit}
            displayListType={displayListType}
          />
        </form>
      </div>
    )
  }
}

const ExpandButton = ({text, isExpand, onToggleClick}) => (
  <div className={styles.btnarea} onClick={onToggleClick}>
    <button className={isExpand ? styles.btnOpend : styles.btn}>
      {text}
      <Pulldown size={14} transform={isExpand ? 'rotate(180)' : 'rotate(0)'} />
    </button>
  </div>
)

const validate = (values) => {
  const errors = {}
  if (values.age_from && values.age_to && +values.age_from > +values.age_to) {
    errors.age_to = errors.age_from = 'の範囲が正しくありません'
  }
  ;['joined_at', 'retired_at', 'birthday'].forEach((prefix) => {
    if (
      [values[`${prefix}_start`], values[`${prefix}_end`]].every(
        (date) => date && moment(date, 'YYYY/MM/DD').isValid()
      ) &&
      moment(values[`${prefix}_start`], 'YYYY/MM/DD').diff(moment(values[`${prefix}_end`], 'YYYY/MM/DD'), 'days') > 0
    ) {
      errors[`${prefix}_start`] = errors[`${prefix}_end`] = 'の範囲が正しくありません'
    }
  })
  return errors
}

export default compose(
  searchDetector({
    convertQueryToForm,
    convertFormToQueryString,
    getCurrentQueryFromLocation,
    fetchData(query, additionalParams, dispatch) {
      dispatch(fetchEmployees(query, additionalParams))
      dispatch(fetchEmployeesAll(query, {only_tm_user: additionalParams.only_tm_user}))
    },
    destroy(dispatch) {
      dispatch(actionCreators.employees.list.destroy())
      dispatch(actionCreators.employees.all.destroy())
    },
  }),
  connect((state) => ({
    token: state.auth.token,
    pathname: state.router.location.pathname,
    formValues: getFormValues(formName)(state),
    user: state.session.currentUser,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
    onSubmit(values, dispatch, {handleSearch}) {
      getState(dispatch).then((state) => {
        const autofilled = _.get(getFormMeta(formName)(state), 'page.autofilled')
        if (!autofilled) {
          dispatch(autofill(formName, 'page', 1))
        }
        getState(dispatch).then((state) => {
          handleSearch(getFormValues(formName)(state))
        })
      })
    },
  })
)(SearchFormWithCardList)
