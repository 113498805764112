import {useSelector} from 'hooks/redux'
import {isOfficeAdminSelector} from 'libs/redux'
import {useQuery} from 'hooks/graphql'
import {SEARCH_OFFICES, SearchOfficesResult, SearchOfficesVariables} from './query'

/**
 * 適用事業所を指定の条件で検索する。
 * 現在のログインユーザーが事業所管理者の場合、検索可能な適用事業所は権限を付与されているものに限定される。
 * @param page ページ
 * @param perPage ページあたりの表示件数
 * @param searchKeyword 検索キーワード
 * @param filterByCurrentOffices 現在の操作対象として指定されている適用事業所の中から検索する
 * @param skip 検索処理を実行しないようにする
 */
export const useSearchOffices = (
  page: number,
  perPage: number,
  searchKeyword: string | undefined,
  filterByCurrentOffices: boolean | undefined,
  skip: boolean
) => {
  const isOfficeAdmin = useSelector(isOfficeAdminSelector)
  const currentOffices = useSelector((state) => state.session.currentUser?.current_offices)
  const searchOffices = useQuery<SearchOfficesResult, SearchOfficesVariables>(SEARCH_OFFICES, {
    fetchPolicy: 'network-only',
    variables: {per: perPage, page, search: {q: searchKeyword, filterByCurrentOffices}},
    skip: isOfficeAdmin || skip,
  })

  if (isOfficeAdmin) {
    // 事業所管理者の場合はマスタではなく、ログインユーザー情報に関連付けられている適用事業所のリストを参照する
    const offset = perPage * (page - 1)
    return {
      offices: currentOffices?.slice(offset, offset + perPage),
      totalCount: currentOffices?.length,
    }
  } else {
    return {
      offices: searchOffices.data?.client?.searchOffices?.list,
      totalCount: searchOffices.data?.client?.searchOffices?.totalCount,
    }
  }
}
