import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import Button from 'jbc-front/components/Button'
import CreditCardInfo from 'jbc-front/components/CreditCardInfo'
import LoadingPage from 'components/LoadingPage'
import ChangePaymentMethod from 'settings/usingPlan/ChangePaymentMethod'
import {recordDisplay} from 'utils'
import styles from 'settings/UsingPlan.scss'

const plans = {
  trial_plan: 'お試しプラン',
  free_plan: '無料プラン',
  paid_plan: '有料プラン',
}

const BillInfo = ({billInfo = {}}) => (
  <>
    <div className="u-mb5 u-mt20">請求情報</div>
    <div className={styles.cardInfo}>
      <dl className={styles.cardField}>
        <dt>会社名：</dt>
        <dd>{recordDisplay(billInfo.name)}</dd>
      </dl>
      <dl className={styles.cardField}>
        <dt>部署名：</dt>
        <dd>{recordDisplay(billInfo.group)}</dd>
      </dl>
      <dl className={styles.cardField}>
        <dt>担当者氏名：</dt>
        <dd>
          {recordDisplay.fullName(
            billInfo.employee_last_name &&
              billInfo.employee_first_name && {
                first_name: billInfo.employee_first_name,
                last_name: billInfo.employee_last_name,
              }
          )}
        </dd>
      </dl>
      <dl className={styles.cardField}>
        <dt>住所：</dt>
        <dd>{recordDisplay.address(billInfo)}</dd>
      </dl>
      <dl className={styles.cardField}>
        <dt>電話番号：</dt>
        <dd>{recordDisplay.numbers(billInfo, 'phone_number', 3)}</dd>
      </dl>
      <dl className={styles.cardField}>
        <dt>メールアドレス：</dt>
        <dd>{recordDisplay(billInfo.employee_email)}</dd>
      </dl>
    </div>
  </>
)

const paymentMethodTypes = [
  {label: '請求書払い(銀行・コンビニ)', value: 'bill'},
  {label: 'クレジットカード支払い', value: 'credit_card'},
]

const PaymentInfo = ({paymentInfo, planControllable = false}) => {
  const paymentMethodType = paymentInfo?.payment_method_type

  return (
    <>
      {planControllable && (
        <div className={styles.payments}>
          <div>
            <p className={styles.paymentsTitle}>
              支払い方法
              <ChangePaymentMethod paymentInfo={paymentInfo} />
            </p>
            <div className={styles.paymentsMethod}>{recordDisplay.option(paymentMethodType, paymentMethodTypes)}</div>
            {paymentMethodType === 'credit_card' && (
              <div>
                <div className="u-mb5 u-mt20">カード情報</div>
                <CreditCardInfo className={styles.cardInfo} cardInfo={_.get(paymentInfo, 'credit_info.cards[0]')} />
              </div>
            )}
            {paymentMethodType === 'bill' && <BillInfo billInfo={paymentInfo.bill_info || {}} />}
          </div>
        </div>
      )}
    </>
  )
}

const Row = ({monthlyBilling}) => (
  <tr>
    <td>{moment(monthlyBilling.date_on).format('YYYY年M月')}</td>
    <td>¥{monthlyBilling.base_price.toLocaleString()}</td>
    <td>{monthlyBilling.chargeable_employees_count.toLocaleString()}人</td>
    <td>¥{monthlyBilling.price.toLocaleString()}</td>
    <td>¥{monthlyBilling.sign_base_price.toLocaleString()}</td>
    <td>
      {monthlyBilling.sign_transaction_count === 0 ? (
        '0件'
      ) : (
        <Link className="u-txt-link" to="/documents">
          {monthlyBilling.sign_transaction_count.toLocaleString()}件
        </Link>
      )}
    </td>
    <td>¥{monthlyBilling.sign_price.toLocaleString()}</td>
    <td>¥{monthlyBilling.total_price.toLocaleString()}</td>
  </tr>
)

const BillingList = ({monthlyBillings}) => (
  <div className={`${styles.billings} l-overflow-scroll`}>
    <p className={styles.billingsTitle}>請求情報</p>
    <table className="m-table-list">
      <thead>
        <tr>
          <th>年/月</th>
          <th>単価</th>
          <th>ユーザー数</th>
          <th>合計額（税抜き）</th>
          <th>単価（電子契約）</th>
          <th>利用数（電子契約）</th>
          <th>合計額（電子契約）</th>
          <th>合計額（税込み）</th>
        </tr>
      </thead>
      <tbody>
        {_.isEmpty(monthlyBillings) ? (
          <tr>
            <td colSpan="8">請求情報の履歴はありません</td>
          </tr>
        ) : (
          monthlyBillings.map((monthlyBilling) => <Row key={monthlyBilling.id} monthlyBilling={monthlyBilling} />)
        )}
      </tbody>
    </table>
  </div>
)

const PlanContents = ({planType, trialFinishAt, planControllable, paymentInfo, monthlyBillings}) => {
  const isPaidPlan = planType === 'paid_plan'

  return (
    <div className="l-wrap-xxl l-contents-wrap">
      <div className="l-breadcrumb u-mb20">
        <Link to="/settings" className="l-breadcrumb-link">
          設定
        </Link>
        <span className="l-breadcrumb-here">利用プラン</span>
      </div>

      <div className={styles.box}>
        <div className={styles.boxHead}>利用プラン</div>
        <div className={styles.boxBody}>
          <div className={styles.plans}>
            <div className={styles.plansName}>{plans[planType]}</div>
            {planType === 'trial_plan' ? (
              <div className={styles.plansDate}>
                お試し期間終了日: <span>{moment(trialFinishAt).format('YYYY年MM月DD日')}</span>
              </div>
            ) : null}
          </div>
          {isPaidPlan ? (
            <PaymentInfo paymentInfo={paymentInfo} planControllable={planControllable} />
          ) : (
            <div>
              <p className={styles.plansNote}>
                お試しプラン終了後は無料プランに移行します。無料プランは機能制限があります
              </p>
              <ul className={styles.plansNoteLists}>
                <li>・従業員の登録人数が5人まで</li>
                <li>・手続きの保存期間が30日まで</li>
                <li>・電子契約が利用できません</li>
                <li>・チャットサポートが利用できません</li>
              </ul>
              <div className="u-mt10">
                <a
                  href="https://lms.jobcan.ne.jp/plan/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-txt-link"
                >
                  詳しくはこちら
                </a>
              </div>
              <div className="u-mt50 u-mb20 u-ta-c">
                <Button
                  success
                  large
                  as={Link}
                  onClick={() => {
                    window.open(`${process.env.JBC_ID_SERVER}/webform`, '_blank')
                  }}
                >
                  有料プランの申込はこちら
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      {isPaidPlan && planControllable ? <BillingList monthlyBillings={monthlyBillings} /> : null}
    </div>
  )
}

const UsingPlan = () => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const client = useSelector((state) => state.client.current)
  const paymentInfoLoading = useSelector((state) => fetchSelector(state, 'payment_info').loading)
  const paymentInfo = useSelector((state) => fetchSelector(state, 'payment_info').data)
  const monthlyBillingsLoading = useSelector((state) => fetchSelector(state, 'client_monthly_billings').loading)
  const monthlyBillings = useSelector((state) => fetchSelector(state, 'client_monthly_billings').data)
  const trialFinishAt = client.trial_finish_at
  const planType = client.plan_type
  const planControllable = client.plan_controllable
  const loading = paymentInfoLoading || monthlyBillingsLoading

  useEffect(() => {
    dispatch(actionCreators.fetchData('payment_info', api.createWithAuth(token).clients.getPaymentInfo()))
    return () => {
      dispatch(actionCreators.fetchDestroy('payment_info'))
    }
  }, [])

  useEffect(() => {
    dispatch(
      actionCreators.fetchData('client_monthly_billings', api.createWithAuth(token).clients.getMonthlyBillings())
    )
    return () => {
      dispatch(actionCreators.fetchDestroy('client_monthly_billings'))
    }
  }, [])

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">利用プラン</h1>
      </div>
      {loading ? (
        <LoadingPage />
      ) : (
        paymentInfo &&
        monthlyBillings && (
          <PlanContents
            planType={planType}
            trialFinishAt={trialFinishAt}
            planControllable={planControllable}
            paymentInfo={paymentInfo}
            monthlyBillings={monthlyBillings}
          />
        )
      )}
    </>
  )
}
export default UsingPlan
