import React from 'react'
import {Card, Container} from 'components/SelectCard'
import {connect} from 'react-redux'
import {MaternityStart, MaternityChange, MaternityEnd} from 'jbc-front/components/icons'
import {parse} from 'query-string'
import {push} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'

const ChooseProcedureTypeForAdmin = ({location: {search}, selectProcedureType}) => {
  const {employee_id} = parse(search)
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">産休の手続きを選択</h1>
        <p className="m-title-main-note">産休の手続きの種類を選んでください</p>
      </div>
      <Container>
        <Card
          icon={MaternityStart}
          onClick={() => {
            selectProcedureType('start_maternity', employee_id)
          }}
        >
          産休の開始
        </Card>
        <Card
          icon={MaternityChange}
          onClick={() => {
            selectProcedureType('extend_maternity', employee_id)
          }}
        >
          産休の届出情報の変更
        </Card>
        <Card
          icon={MaternityEnd}
          onClick={() => {
            selectProcedureType('finish_maternity', employee_id)
          }}
        >
          産休の終了
        </Card>
      </Container>
    </div>
  )
}

export default connect(
  () => {},
  (dispatch) => ({
    selectProcedureType(procedureType) {
      try {
        dispatch(push(`/${procedureType}/choose_employee`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(ChooseProcedureTypeForAdmin)
