import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Dl, Dd, Dt, Grid, SubPanel} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import styles from 'employees/show/Parts.scss'
import ReadMoreReadLess from 'components/ReadMoreReadLess'

const EmployeeProject = ({employee, editLink, yearStyle, canEdit}) => {
  const employeeProjects = employee.employee_projects || []
  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeProject`} title="プロジェクト" canEdit={canEdit} />
      <Item>
        <Grid className={employeeProjects.length == 1 && styles.gridFill}>
          {employeeProjects.map((employeeProject, index) => (
            <SubPanel key={index}>
              <SubPanel.Headding>プロジェクト ({index + 1})</SubPanel.Headding>
              <Grid className={styles.lastChildrenNotBorder}>
                <Dl>
                  <Dt>プロジェクト名</Dt>
                  <Dd>{recordDisplay(employeeProject.project_name)}</Dd>
                </Dl>
                <Dl>
                  <Dt>開始年月日</Dt>
                  <Dd>{recordDisplay.date(employeeProject.start_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>終了年月日</Dt>
                  <Dd>{recordDisplay.date(employeeProject.end_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>プロジェクト詳細</Dt>
                  <Dd>
                    <ReadMoreReadLess key={employeeProject.project_details}>
                      {recordDisplay(employeeProject.project_details)}
                    </ReadMoreReadLess>
                  </Dd>
                </Dl>
              </Grid>
            </SubPanel>
          ))}
        </Grid>
        {employeeProjects.length < 1 && (
          <div className={styles.noData}>
            <p>プロジェクトの登録はありません</p>
          </div>
        )}
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmployeeProject)
