import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {reduxForm, FormSection, formValueSelector} from 'redux-form'
import {CheckboxField, Label, RadioField, TextAreaField} from 'jbc-front/components/Form'
import {toBooleanProps} from 'utils'
import compose from 'lodash/fp/compose'
import {connect} from 'react-redux'
import _ from 'lodash'
import api from 'api'
import {getState} from 'utils'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import styles from './UploadRequest.scss'
import onClickOutside from 'react-onclickoutside'
import {Link} from 'react-router-dom'

const formName = 'uploadRequest'

const selector = formValueSelector(formName)

const sendMailOptions = [
  {value: 'true', label: '従業員にメール送信'},
  {value: 'false', label: '従業員にメール送信しない'},
]

const displayReports = {
  enroll: ['r56', 'r135', 'r136', 'r137', 'r150', 'r148', 'r132', 'r4', 'r5'],
  add_dependents: ['r56', 'r135', 'r136', 'r137', 'r150', 'r148', 'r132', 'r4', 'r5'],
  remove_dependents: ['r4', 'r5', 'r47', 'r132'],
  resign: ['r47'],
}

const Warning = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide()
    }

    render() {
      return (
        <div className={styles.help}>
          この従業員がアカウント未登録の為、依頼できません。
          <br />
          従業員招待は
          <Link className="u-txt-link" to="/employees/invite">
            こちら
          </Link>
        </div>
      )
    }
  }
)

const Form = compose(
  connect(
    (state) => ({
      reports: selector(state, 'reports'),
    }),
    (dispatch, {hideModal, reportList, procedureStatus}) => ({
      async onSubmit({reports = {}, ...values}) {
        try {
          const state = await getState(dispatch)
          await api.createWithAuth(state.auth.token).requestToUpload.create({
            file_infos: _.map(reports, (value, key) => {
              const m = key.match(/(.+)_(.+)/)
              const [num, dependentId] = m ? [m[1], m[2]] : [key]
              const report = reportList.find(
                (report) => report.num === num && (!dependentId || report.dependentId === parseInt(dependentId))
              )
              return _.pickBy({
                file_name: report.title,
                report_name: num,
                employee_dependent_id: dependentId,
              })
            }),
            ...values,
            procedure_status_id: procedureStatus.id,
          })
          hideModal()
          dispatch(notifySuccess('従業員への依頼が完了しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  ),
  reduxForm({
    form: formName,
  })
)(({handleSubmit, hideModal, displayReportList, submitting, reports}) => (
  <React.Fragment>
    <Modal.Body>
      <form onSubmit={handleSubmit}>
        <Label text="従業員の捺印が必要な書類があります。依頼する書類にチェック。" />
        <FormSection name="reports">
          {displayReportList.map((report, index) => (
            <CheckboxField
              key={index}
              label={report.title}
              name={report.dependentId ? `${report.num}_${report.dependentId}` : report.num}
            />
          ))}
        </FormSection>
        <RadioField label="メール送信" name="send_email" {...toBooleanProps} options={sendMailOptions} />
        <TextAreaField label="メッセージ" name="message_by_uploader" />
      </form>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <Button primary onClick={handleSubmit} disabled={submitting || !reports || !_.some(reports)}>
          依頼
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </React.Fragment>
))

class UploadRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showWarning: false,
    }
  }

  render() {
    const {isModalOpen, showModal, hideModal, reportList, procedureStatus} = this.props
    const displayReportList =
      displayReports[procedureStatus.procedure_type] &&
      reportList.filter((report) => displayReports[procedureStatus.procedure_type].includes(report.num))
    if (_.isEmpty(displayReportList)) {
      return null
    }
    const registered = _.get(procedureStatus, 'employee.invitation_status') === 'registered'
    return (
      <div>
        <div className={styles.upload}>
          <Button
            primary
            widthWide
            className="ignore-react-onclickoutside"
            onClick={() => {
              if (registered) {
                showModal()
              } else {
                this.setState({showWarning: !this.state.showWarning})
              }
            }}
          >
            従業員へ印刷・捺印依頼
          </Button>
          {this.state.showWarning && <Warning hide={() => this.setState({showWarning: false})} />}
          <Modal isOpen={isModalOpen} hideModal={hideModal}>
            <Modal.Header hideModal={hideModal}>従業員へ印刷・捺印依頼</Modal.Header>
            {isModalOpen && (
              <Form
                {...{hideModal, procedureStatus, reportList, displayReportList}}
                initialValues={{send_email: true}}
              />
            )}
          </Modal>
        </div>
      </div>
    )
  }
}

export default withModal(UploadRequest)
