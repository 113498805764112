import React, {Component} from 'react'
import Modal from 'jbc-front/components/CommonModal'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import api from 'api'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import Form, {makeInitialValues, selector} from 'myNumber/businessPartner/RequestForm'
import {downloadFile} from 'libs/downloadFile'

class Request extends Component {
  static defaultProps = {
    businessPartner: {},
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
  }

  handleSubmit = async (values) => {
    const {token, hide, dispatch, onRequestFinish, selectedOffices} = this.props
    const requests =
      values.my_number_purpose_of_uses && values.my_number_purpose_of_uses.filter((request) => request._send_request)
    try {
      await api.createWithAuth(token).businessPartners.request({...values, my_number_purpose_of_uses: requests})
      if (values.my_number_input_request.notify_type === 'qr_code') {
        await downloadFile({
          url: `/api/business_partners/${values.business_partner_id}/input_request_pdf`,
          asyncError: (err) => dispatch(asyncError(err)),
          selectedOffices,
        })
      } else {
        dispatch(notifySuccess('依頼しました'))
      }
      onRequestFinish()
      hide()
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  render() {
    const {isOpen, hide, businessPartner, step, notifyType} = this.props
    return (
      <Modal isOpen={isOpen}>
        <Modal.Header hideModal={hide}>
          {recordDisplay(businessPartner.name)}さんへのマイナンバー提出依頼
          {step === 'others' && `(${notifyType === 'email' ? 'メール' : 'QRコード'})`}
        </Modal.Header>
        {isOpen && (
          <Form
            initialValues={makeInitialValues(businessPartner)}
            onSubmit={this.handleSubmit}
            hideModal={hide}
            hasEmail={!!businessPartner.email}
          />
        )}
      </Modal>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  employee: state.employees.current.data,
  step: selector(state, '_step'),
  notifyType: selector(state, 'my_number_input_request.notify_type'),
  selectedOffices: state.session.selectedOffices,
}))(Request)
