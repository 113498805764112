import React, {useRef, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {gql} from '@apollo/client'
import {parse, stringify} from 'query-string'
import classNames from 'classnames'
import {Subject} from 'rxjs'
import {debounceTime} from 'rxjs/operators'
import Button from 'jbc-front/components/Button'
import Paginator from 'jbc-front/components/Paginator'
import {SortableTh} from 'jbc-front/components/SearchForm'
import {Copy, Edit} from 'jbc-front/components/icons'
import {useQuery, useMutation} from 'components/Graphql'
import LoadingPage from 'components/LoadingPage'
import StarFlag from 'components/StarFlag'
import {recordDisplay} from 'utils'
import {Filter, Search} from 'documents/ListData'
import {visibilityOptions} from 'documentTemplates/Form'
import Badge from 'documentTemplates/Badge'
import Delete from 'documentTemplates/Delete'
import styles from 'documentTemplates/List.scss'
import PerPage from 'documentTemplates/list/PerPage'
import {getSavedDisplayEmployeeLimit} from 'documentTemplates/list/utils'

const statuses = {
  all: 'すべて',
  star: 'スター付き',
  all_admins: '管理者全体',
  private: '自分のみ',
  draft: '下書き',
}

const MAX_FLAG = 2

export const DOCUMENT_TEMPLATE_FRAGMENT = gql`
  fragment DocumentTemplateFields on DocumentTemplate {
    id
    title
    draft
    visibility
    editability
    documentTemplateCategoryName
    flag
    updatedAt
    createdUser {
      id
    }
    updatedUser {
      id
      name
      employee {
        id
        displayFirstName
        displayLastName
      }
    }
  }
`

const DOCUMENT_TEMPLATES = gql`
  query documentTemplates($per: Int, $page: Int, $search: DocumentTemplateSearchInput) {
    client {
      id
      documentTemplates(per: $per, page: $page, search: $search) {
        totalCount
        list {
          ...DocumentTemplateFields
        }
      }
    }
  }
  ${DOCUMENT_TEMPLATE_FRAGMENT}
`

const UPDATE_DOCUMENT_TEMPLATE_FLAG = gql`
  mutation updateDocumentTemplateFlag($input: UpdateDocumentTemplateFlagInput!) {
    updateDocumentTemplateFlag(input: $input) {
      documentTemplate {
        id
        flag
      }
    }
  }
`

const IconButton = ({to, text, children}) => (
  <Link to={to}>
    <div className={styles.noteWrap}>
      {children}
      <div className={styles.note}>{text}</div>
    </div>
  </Link>
)

const IconButtonLarge = ({to, text, children}) => (
  <Link to={to}>
    <div className={styles.noteWrap}>
      {children}
      <div className={styles.noteLarge}>{text}</div>
    </div>
  </Link>
)

export const isEditableTemplate = (currentUser, documentTemplate) =>
  (currentUser && documentTemplate.updatedUser?.id == currentUser.id) || documentTemplate.editability === 'all_admins'

const visibilityToColor = {
  private: 'blue',
  all_admins: 'green',
}

const displayFullName = (record) => record && `${record.displayLastName} ${record.displayFirstName}`

const Row = ({documentTemplate, history, refetch, handleStarClick}) => {
  const currentUser = useSelector((state) => state.session.currentUser)
  const editable = isEditableTemplate(currentUser, documentTemplate)
  const visibility = visibilityOptions.find((opt) => opt.value === documentTemplate.visibility)
  const handleClick = () => {
    if (!documentTemplate.draft) {
      history.push(`/documents/create?template=${documentTemplate.id}`)
    }
  }

  return (
    <tr className={classNames({'table-hover': !documentTemplate.draft})}>
      <td onClick={() => handleStarClick(documentTemplate.id, documentTemplate.flag)}>
        <StarFlag colorIndex={documentTemplate.flag} />
      </td>
      <td onClick={handleClick} className={styles.visibilityColumn}>
        {documentTemplate.draft ? (
          <Badge color="orange" className={styles.visibilityBadge}>
            下書き
          </Badge>
        ) : visibility ? (
          <Badge color={visibilityToColor[visibility.value]} className={styles.visibilityBadge}>
            {visibility.label}
          </Badge>
        ) : null}
      </td>
      <td onClick={handleClick} className={styles.categoryColumn}>
        {recordDisplay(documentTemplate.documentTemplateCategoryName)}
      </td>
      <td onClick={handleClick} className={styles.titleColumn}>
        {recordDisplay(documentTemplate.title)}
      </td>
      <td onClick={handleClick} className={styles.updatedAtColumn}>
        {recordDisplay.date(documentTemplate.updatedAt, 'YYYY/MM/DD')}
      </td>
      <td onClick={handleClick} className={styles.updatedUserColumn}>
        {documentTemplate.updatedUser
          ? recordDisplay(
              documentTemplate.updatedUser.employee
                ? displayFullName(documentTemplate.updatedUser.employee)
                : documentTemplate.updatedUser.name
            )
          : recordDisplay()}
      </td>
      <td className={styles.controlColumn}>
        <div>
          {editable ? (
            <IconButton to={`/document_templates/${documentTemplate.id}`} text="テンプレートを編集">
              <Edit size={20} className={styles.icon} />
            </IconButton>
          ) : (
            <div />
          )}
          <IconButtonLarge
            to={`/document_templates/create?copy=${documentTemplate.id}`}
            text={
              <>
                テンプレートをコピーして
                <br />
                新しい書類テンプレートを追加
              </>
            }
          >
            <Copy size={20} className={styles.icon} />
          </IconButtonLarge>
          {editable ? (
            <Delete id={documentTemplate.id} title={documentTemplate.title} onRequestFinish={refetch} />
          ) : (
            <div />
          )}
        </div>
      </td>
    </tr>
  )
}

const sortTypeFromQuery = ({sort, order}) => {
  sort = sort || 'updated_at'
  order = order === 'asc' ? 'asc' : 'desc'
  return [sort, order]
}

const List = ({location: {search, pathname}, history}) => {
  const currentUser = useSelector((state) => state.session.currentUser)
  const limit = getSavedDisplayEmployeeLimit(pathname, currentUser)
  const query = parse(search)
  const [sort, order] = sortTypeFromQuery(query)
  const currentSortType = `${sort}__${order}`
  const filter = query.filter || 'all'
  const {data, refetch, loading} = useQuery(DOCUMENT_TEMPLATES, {
    fetchPolicy: 'network-only',
    variables: {
      page: +query.page || 1,
      per: +limit,
      search: {sortOrder: order, sortColumn: sort, filter, q: query.q},
    },
  })
  const [updateDocumentTemplateFlag] = useMutation(UPDATE_DOCUMENT_TEMPLATE_FLAG)
  const keywordSubject = useRef(new Subject() |> debounceTime(1000))

  useEffect(() => {
    const subscription = keywordSubject.current.subscribe((q) =>
      history.push({pathname, search: stringify({...query, q, page: 1})})
    )
    return () => subscription.unsubscribe()
  }, [history, pathname, query])

  const handleChangeSortType = (sortType) => {
    const [sort, order] = sortType.split('__')
    history.push({pathname, search: stringify({...query, sort, order})})
  }
  const handleStarClick = (id, flag) => {
    const newFlag = (flag + 1) % MAX_FLAG
    updateDocumentTemplateFlag({variables: {input: {id, flag: newFlag}}})
  }

  const documentTemplates = data?.client?.documentTemplates
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">テンプレート一覧</h1>
      </div>
      <div className="l-wrap-xxl l-contents-wrap">
        <div className={styles.buttonArea}>
          <Link to="/document_templates/samples" className={styles.createTemplateButtonWrap}>
            <Button className={styles.createTemplateButton}>サンプルから取得</Button>
          </Link>
          <Link to="/document_templates/create" className={styles.createTemplateButtonWrap}>
            <Button className={styles.createTemplateButton}>新規作成</Button>
          </Link>
        </div>
        <div className={styles.searchArea}>
          <div className={styles.refineAreaLeft}>
            {Object.entries(statuses).map(([key, label]) => (
              <Filter
                key={key}
                filter={key}
                currentFilter={filter}
                label={label}
                onClick={() => history.push({pathname, search: stringify({...query, filter: key, page: 1})})}
              />
            ))}
          </div>
          <div className={styles.refineAreaRight}>
            <Search placeholder="テンプレート名" onChange={(e) => keywordSubject.current.next(e.target.value)} />
          </div>
        </div>
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="l-overflow-scroll">
            <table className="m-table-list">
              <thead>
                <tr>
                  <th className={styles.starColumn} />
                  <SortableTh
                    field="visibility"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.visibilityColumn}
                  >
                    公開範囲
                  </SortableTh>
                  <SortableTh
                    field="category"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.categoryColumn}
                  >
                    カテゴリ
                  </SortableTh>
                  <SortableTh
                    field="title"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.titleColumn}
                  >
                    テンプレート名
                  </SortableTh>
                  <SortableTh
                    field="updated_at"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.updatedAtColumn}
                  >
                    最終更新日
                  </SortableTh>
                  <SortableTh
                    field="updated_user"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.updatedUserColumn}
                  >
                    最終更新者
                  </SortableTh>
                  <th className={styles.controlColumn}>&nbsp;</th>
                </tr>
              </thead>
              {documentTemplates && documentTemplates.totalCount > 0 ? (
                <tbody>
                  {documentTemplates.list.map((documentTemplate) => (
                    <Row
                      key={documentTemplate.id}
                      documentTemplate={documentTemplate}
                      history={history}
                      refetch={refetch}
                      handleStarClick={handleStarClick}
                    />
                  ))}
                </tbody>
              ) : (
                <tr>
                  <td colSpan={7}>テンプレートがありません</td>
                </tr>
              )}
            </table>
          </div>
        )}
        {documentTemplates && (
          <div className="m-pager-area">
            <Paginator
              current={query.page}
              rowsPerPage={limit}
              onClick={(page) => history.push({pathname, search: stringify({...query, page})})}
              totalResult={documentTemplates.totalCount}
            />
            <PerPage onChange={() => history.push({pathname, search: stringify({...query, page: 1})})} />
          </div>
        )}
      </div>
    </div>
  )
}

export default List
