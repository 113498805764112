import {FC, ReactNode} from 'react'
import styles from './Content.scss'
interface Props {
  children?: ReactNode
  size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xl-3' | 'xl-4'
}

export const Content: FC<Props> = ({children, size}) => {
  const style = () => {
    switch (size) {
      case 'xs':
      case 's':
      case 'm':
      case 'l':
      case 'xl':
      case 'xxl':
        return `l-wrap-${size}`
      case 'xl-3':
      case 'xl-4':
        return styles[size]
      default:
        return ''
    }
  }
  const className = `${style()} l-contents-wrap`.trim()

  return <div className={className}>{children}</div>
}
