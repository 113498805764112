import {FC, ReactNode} from 'react'
import useReactRouter from 'use-react-router'
import {useDispatch} from 'react-redux'
import {useSelector} from 'hooks/redux'
import {asyncError} from 'store/actions/asyncError'

import {displayFormat, dateFormat} from 'libs/formatter'
import {EgovProcedure} from '../../query'
import {EGOV_PROCEDURE_STATUSES} from 'consts/egovProcedureStatuses'
import {DeleteTd} from '../DeleteTd'
import styles from './ApplicationRow.scss'

import {downloadFile} from 'libs/downloadFile'

interface ApplicationRowProps {
  egovProcedure: EgovProcedure
  employeeOrOfficeName: string
  isDeletable: boolean
  onOpenDeleteModal: (egovProcedureId: string) => void
}

export const ApplicationRow: FC<ApplicationRowProps> = ({
  egovProcedure,
  isDeletable,
  onOpenDeleteModal,
  employeeOrOfficeName,
}) => {
  const {history} = useReactRouter()
  const {application} = egovProcedure

  const hasDocuments = application?.documents && application?.documents.length > 0
  const hasComments = application?.comments && application?.comments.length > 0

  return (
    <tr className="table-hover" onClick={() => history.push(`/e_gov_procedures/${egovProcedure.id}`)}>
      <td>{EGOV_PROCEDURE_STATUSES[egovProcedure.status]}</td>
      <td>{employeeOrOfficeName}</td>
      <td>{displayFormat(egovProcedure.procedureName)}</td>
      <td title={egovProcedure.formName} className={styles.overflowEllips}>
        {egovProcedure.formName}
      </td>
      <td>
        {hasDocuments && (
          <DownloadLink downloadPath={`/api/egov_procedure_statuses/${egovProcedure.id}/officialdocument`}>
            厚生労働省からのお知らせ
          </DownloadLink>
        )}
        {hasComments && (
          <DownloadLink downloadPath={`/api/egov_procedure_statuses/${egovProcedure.id}/comment`}>
            コメント
          </DownloadLink>
        )}
        {!hasDocuments && !hasComments && displayFormat()}
      </td>
      <td>{dateFormat(application?.appliedAt, 'LLL')}</td>
      <DeleteTd onClick={() => onOpenDeleteModal(egovProcedure.id)} disabled={!isDeletable} />
    </tr>
  )
}

interface DownloadLinkProps {
  downloadPath: string
  children: ReactNode
}

const DownloadLink: FC<DownloadLinkProps> = ({downloadPath, children}) => {
  const dispatch = useDispatch()
  const selectedOffices = useSelector((state) => state.session.selectedOffices)
  return (
    <>
      <a
        href={downloadPath}
        className="u-txt-link"
        onClick={(e) => {
          downloadFile({url: downloadPath, asyncError: (err: Error) => dispatch(asyncError(err)), selectedOffices})
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        {children}
      </a>
      <br />
    </>
  )
}
