import {EgovProcedureRoutes} from './egovProcedures'
import {SettingRoutes} from './settings'
import {RouteParams} from 'types/routes'
import {DocumentRoutes} from './documents'
import {EmployeeRoutes} from './employees'
import {DirectRequestRoutes} from './directRequest'
import {ProcedureStatusRoutes} from './procedureStatuses'

export const withAuthAdminRoutes: RouteParams[] = [
  ...EmployeeRoutes,
  ...DirectRequestRoutes,
  ...DocumentRoutes,
  ...EgovProcedureRoutes,
  ...SettingRoutes,
  ...ProcedureStatusRoutes,
]
