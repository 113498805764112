import List from 'myNumber/List'
import Show from 'myNumber/Show'
import EmployeeInput from 'myNumber/EmployeeInput'
import auditLogs from 'myNumber/auditLogs'
import businessPartner from 'myNumber/businessPartner'
import FileImport from 'myNumber/FileImport'

export default {
  List,
  Show,
  EmployeeInput,
  auditLogs,
  businessPartner,
  FileImport,
}
