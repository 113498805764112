import React from 'react'
import {FileNameLink} from 'components/ui/FileNameLink'
import {Panel, Item} from 'components/PrimaryPanel'
import {Dl, Dd, Dt, Grid, SubPanel} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import {useSelector} from 'hooks/redux'
import styles from 'employees/show/Parts.scss'
import {displayFormat, dateFormat} from 'libs/formatter'

const Certificate = ({certificate}) => (
  <>
    {certificate && certificate.url ? (
      <FileNameLink url={certificate.url}>{displayFormat(certificate.filename)}</FileNameLink>
    ) : (
      displayFormat(certificate?.filename)
    )}
  </>
)

const EmployeeQualification = ({editLink, yearStyle, canEdit}) => {
  const employeeQualifications = useSelector((state) => state.employees.current.data.employee_qualifications) || []

  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeQualification`} title="資格情報" canEdit={canEdit} />
      <Item>
        <Grid className={employeeQualifications.length == 1 && styles.gridFill}>
          {employeeQualifications.map((employeeQualification, index) => (
            <SubPanel key={employeeQualification.id}>
              <SubPanel.Headding>資格 ({index + 1})</SubPanel.Headding>
              <Grid className={styles.lastChildrenNotBorder}>
                <Dl>
                  <Dt>資格名</Dt>
                  <Dd>{displayFormat(employeeQualification.qualification_name)}</Dd>
                </Dl>
                <Dl>
                  <Dt>取得更新年月日</Dt>
                  <Dd>{dateFormat(employeeQualification.acquisition_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>有効期限</Dt>
                  <Dd>{dateFormat(employeeQualification.expiration_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>資格登録番号</Dt>
                  <Dd>{displayFormat(employeeQualification.registration_number)}</Dd>
                </Dl>
                <Dl>
                  <Dt>証明書</Dt>
                  <Dd>
                    <Certificate certificate={employeeQualification.certificate} />
                  </Dd>
                </Dl>
              </Grid>
            </SubPanel>
          ))}
        </Grid>
        {employeeQualifications.length < 1 && (
          <div className={styles.noData}>
            <p>資格情報の登録はありません</p>
          </div>
        )}
      </Item>
    </Panel>
  )
}

export default EmployeeQualification
