import React, {Component} from 'react'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Pulldown} from 'jbc-front/components/icons'

import styles from 'components/OrderedTh.scss'

class OrderedTh extends Component {
  handleClick = () => {
    const {query, column, setQuery} = this.props
    const order = query.sort_column !== column || query.order === 'desc' ? 'asc' : 'desc'
    setQuery(_.assign({}, query, {order, sort_column: column, page: 1}))
  }
  render() {
    const {text, query, column, addclass} = this.props
    const showOrder = column === query.sort_column && (query.order === 'asc' || query.order === 'desc')
    return (
      <th onClick={this.handleClick} className={styles.th + ` ${addclass ? addclass : ''}`}>
        {text}
        {(() => {
          if (showOrder && query.order === 'desc') {
            return <Pulldown size={7} className={styles.iconDesc} />
          } else if (showOrder && query.order === 'asc') {
            return <Pulldown size={7} className={styles.iconAsc} />
          }
        })()}
      </th>
    )
  }
}

export const connectOrderedTh = (selector, actionCreator) =>
  connect(
    (state) => ({
      query: selector(state),
    }),
    (dispatch) => ({
      setQuery: (query) => dispatch(actionCreator(query)),
    })
  )(OrderedTh)

export default OrderedTh
