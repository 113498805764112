import ChooseEmployee from 'procedures/maternityLeave/finish/ChooseEmployee'
import ChooseUseOrCreate from 'procedures/maternityLeave/finish/ChooseUseOrCreate'
import ChooseProcedureForAdmin from 'procedures/maternityLeave/finish/ChooseProcedureForAdmin'
import Detail from 'procedures/maternityLeave/finish/Detail'
import Update from 'procedures/maternityLeave/finish/Update'

export default {
  ChooseEmployee,
  ChooseUseOrCreate,
  ChooseProcedureForAdmin,
  Detail,
  Update,
}
