import React, {useEffect, useRef} from 'react'
import {Redirect} from 'react-router'
import {useDispatch} from 'react-redux'
import {parse, stringify} from 'query-string'
import {Subject} from 'rxjs'
import {debounceTime} from 'rxjs/operators'
import moment from 'moment'
import Hint from 'jbc-front/components/Hint'
import Paginator from 'jbc-front/components/Paginator'
import {SortableTh} from 'jbc-front/components/SearchForm'
import {downloadFile} from 'libs/downloadFile'
import {recordDisplay} from 'utils'
import {useQuery} from 'components/Graphql'
import LoadingPage from 'components/LoadingPage'
import {PerPage} from 'components/PerPage'
import CsvDownload from 'documents/CsvDownload'
import {Search} from 'documents/ListData'
import Remind from 'documents/Remind'
import {SearchResult} from 'documents/create/list/SearchForm'
import {CommonTitle} from 'documents/show/CommonTitle'
import {CancelModal} from 'documents/showContracts/CancelModal'
import {DownloadFileIcon} from 'documents/showContracts/DownloadFileIcon'
import {SEARCH_DOCUMENT_CONTRACTS} from 'documents/showContracts/query'
import styles from 'documents/ShowContracts.scss'
import {useSelector} from 'hooks/redux'
import {asyncError} from 'store/actions/asyncError'
import {displayFormat, fullNameFormat} from 'libs/formatter'

const ContractInfo = ({contractInfo}) => (
  <div className="u-mb20">
    <div className={styles.contractInfoBox}>
      <div className={styles.title}>依頼内容</div>
      <div className={styles.row}>
        <div className={styles.rowHeader}>差出人：</div>
        <div className={styles.rowValue}>
          {contractInfo.senderOfficeName} {contractInfo.senderName}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowHeader}>書類名：</div>
        <div className={styles.rowValue}>{contractInfo.title}</div>
      </div>
      {contractInfo.comment && (
        <div className={styles.row}>
          <div className={styles.rowHeader}>コメント：</div>
          <div className={styles.rowValueComment}>{contractInfo.comment}</div>
        </div>
      )}
    </div>
  </div>
)

const RemindButtonArea = ({id}) => (
  <>
    <Remind id={id} />
    <Hint
      text="署名完了されていない方へ1週間おきにリマインドメールが自動送信されていますが、追加で送信したい場合にクリックしてください。"
      width={260}
      messageLeft={-220}
    />
  </>
)

const DownloadButton = ({documentId, employeeId}) => {
  const dispatch = useDispatch()
  const selectedOffices = useSelector((state) => state.session.selectedOffices)
  const handleClickDownload = async () => {
    await downloadFile({
      url: `/api/documents/${documentId}/document_sign_transactions/${employeeId}/signed_document`,
      asyncError: (err) => dispatch(asyncError(err)),
      usePost: false,
      selectedOffices,
    })
  }
  return (
    <div className={styles.downloadButton} onClick={handleClickDownload}>
      <DownloadFileIcon />
    </div>
  )
}

const CancelColumn = ({documentId, contract}) => {
  const contractInfo = contract.contractInfo
  if (contractInfo?.status === 'unsigned') {
    const cancelModalDisabledProps =
      contractInfo.gateway !== 'gmosign_envelope'
        ? {disabled: true, disabledReason: 'キャンセル機能リリース前に作成された書類のため、キャンセル出来ません'}
        : {}

    return (
      <CancelModal
        documentId={documentId}
        employeeId={contract.employeeId}
        employee={contract.contractEmployee}
        {...cancelModalDisabledProps}
      />
    )
  }
  if (contractInfo?.status === 'canceled') {
    const deletedUser = contractInfo.deletedUser
    const deletedEmployee = deletedUser?.employee
    return (
      <>
        {moment(contractInfo.deletedAt).format('YYYY/MM/DD HH:mm')}
        <br />
        {deletedEmployee
          ? fullNameFormat(deletedEmployee.displayLastName, deletedEmployee.displayFirstName)
          : displayFormat(deletedUser?.name)}
      </>
    )
  }

  return null
}

const convertContractStatus = (status) => {
  switch (status) {
    case 'signed':
      return '署名済'
    case 'unsigned':
      return '未署名'
    case 'canceled':
      return <span className={styles.canceledText}>取消済</span>
    default:
      return '対象外'
  }
}

const Row = ({documentId, contract}) => {
  const employee = contract.contractEmployee
  const status = contract.contractInfo?.status

  return (
    <tr>
      <td className={styles.staffCodeColumn}>{recordDisplay(employee.staff_code)}</td>
      <td className={styles.nameColumn}>{recordDisplay.fullName(employee)}</td>
      <td className={styles.emailColumn}>{recordDisplay(employee.email)}</td>
      <td className={styles.contractColumn}>{convertContractStatus(status)}</td>
      <td className={styles.downloadColumn}>
        {status === 'signed' ? (
          contract.contractInfo?.downloadableSignedDocument ? (
            <DownloadButton documentId={documentId} employeeId={contract.employeeId} />
          ) : (
            <>
              準備中
              <Hint text="ダウンロードの準備をしています。時間をおいて再度ご確認ください。" baseLine />
            </>
          )
        ) : null}
      </td>
      <td className={styles.cancelColumn}>
        <CancelColumn documentId={documentId} contract={contract} />
      </td>
    </tr>
  )
}

export const sortTypeFromQuery = ({sort, order}) => {
  sort = sort || 'staff_code'
  order = order === 'desc' ? 'desc' : 'asc'
  return [sort, order]
}

const ShowContracts = ({
  location: {search, pathname},
  match: {
    params: {id},
  },
  history,
}) => {
  const query = parse(search)
  const [sort, order] = sortTypeFromQuery(query)
  const currentSortType = `${sort}__${order}`
  const {data, error, loading} = useQuery(SEARCH_DOCUMENT_CONTRACTS, {
    fetchPolicy: 'network-only',
    variables: {
      id,
      page: +query.page || 1,
      per: +query.per_page || 10,
      search: {sortOrder: order, sortColumn: sort, q: query.q},
    },
  })
  const handleChangeSortType = (sortType) => {
    const [sort, order] = sortType.split('__')
    history.push({pathname, search: stringify({...query, sort, order})})
  }

  const keywordSubject = useRef(new Subject() |> debounceTime(1000))

  useEffect(() => {
    const subscription = keywordSubject.current.subscribe((q) =>
      history.push({pathname, search: stringify({...query, q, page: 1})})
    )
    return () => subscription.unsubscribe()
  }, [history, pathname, query])

  if (error) {
    return <Redirect to={`/documents/${id}`} />
  }

  const document = data?.client?.document
  const dynamicDocument = document?.dynamicDocument
  const contractInfo = document?.contractInfo
  const contracts = document?.searchDocumentContracts
  const remindCount = document?.remindContractCount

  return (
    <>
      <CommonTitle pathname={pathname} id={id} />
      <div className="l-wrap-xxl l-contents-wrap">
        {contracts && <ContractInfo contractInfo={contractInfo} />}
        <div className={styles.remindArea}>{!loading && !!remindCount && <RemindButtonArea id={id} />}</div>
        <div className={styles.listTopArea}>
          <div className={styles.buttonArea}>
            <CsvDownload
              id={id}
              search={query.q}
              contract={true}
              disabled={!dynamicDocument?.previewable}
              disabledReason={dynamicDocument && '書類の作成・更新中はダウンロードできません'}
            />
          </div>
          {contracts && (
            <SearchResult
              total={contracts.totalCount}
              page={query.page || 1}
              limit={query.per_page || 10}
              isLoading={loading}
              failureMessage=""
            />
          )}
          <Search
            placeholder="スタッフコード、氏名、メール"
            onChange={(e) => keywordSubject.current.next(e.target.value)}
          />
        </div>
        {loading ? (
          <LoadingPage />
        ) : (
          <div className="l-overflow-scroll">
            <table className="m-table-list m-table-fixed">
              <thead>
                <tr>
                  <SortableTh
                    field="staff_code"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.staffCodeColumn}
                  >
                    スタッフコード
                  </SortableTh>
                  <SortableTh
                    field="full_name_kana"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.nameColumn}
                  >
                    氏名
                  </SortableTh>
                  <SortableTh
                    field="email"
                    onChange={handleChangeSortType}
                    currentSortType={currentSortType}
                    globalClassName={styles.emailColumn}
                  >
                    電子契約送信先
                  </SortableTh>
                  <th className={styles.contractColumn}>ステータス</th>
                  <th className={styles.downloadColumn}>ダウンロード</th>
                  <th className={styles.cancelColumn}>送信取消</th>
                </tr>
              </thead>
              <tbody>
                {contracts && contracts.totalCount > 0 ? (
                  contracts.list.map((contract) => (
                    <Row key={contract.id} documentId={dynamicDocument.id} contract={contract} />
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>電子契約はありません</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <div className="m-pager-area">
          {contracts && (
            <Paginator
              current={query.page}
              rowsPerPage={query.per_page}
              onClick={(page) => history.push({pathname, search: stringify({...query, page})})}
              totalResult={contracts.totalCount}
            />
          )}
          <PerPage query={query} setQuery={(query) => history.push({pathname, search: stringify(query)})} />
        </div>
      </div>
    </>
  )
}

export default ShowContracts
