import React from 'react'
import {MdChevronRight} from 'jbc-front/components/react-icons'
import styles from 'components/ListGroup.scss'
import {Link} from 'react-router-dom'
import _ from 'lodash'

export const ListGroup = ({className, children, ...rest}) => (
  <div className={[styles.group, className].filter(_.identity).join(' ')} {...rest}>
    {children}
  </div>
)

export const ListTitle = ({children, ...rest}) => (
  <div className={styles.title} {...rest}>
    {children}
  </div>
)

export const ListItem = ({children, to, onClick, href, as, ...rest}) => {
  const Component = as || (to && Link) || (href && 'a') || 'div'

  return (
    <Component className={styles.item} onClick={onClick} href={href} to={to} {...rest}>
      {children}
    </Component>
  )
}

export const LinkIcon = (props) => (
  <div className={styles.arrow} {...props}>
    <MdChevronRight size={20} color="#ccc" />
  </div>
)
