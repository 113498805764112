import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import styles from 'tm/employees/Detail.scss'
import Infos from 'tm/employees/Infos'
import SelectItem from 'tm/employees/SelectItem'
import CopyUrlBtn from 'tm/employees/CopyUrlBtn'
import {recordDisplay, showCategory, makeBackUrl} from 'utils'
import {getSaveDisplayYearStyle} from 'employees/show/utils'
import scrollSpy from 'jbc-front/components/ScrollSpy'
import compose from 'lodash/fp/compose'
import {User, ArrowDoublePrev, ArrowDoubleNext} from 'jbc-front/components/icons'
import {parse} from 'query-string'
import _ from 'lodash'
import {getTmGroups} from 'employees/form/EmploymentInfomation'
import {wrapArrowText} from './Search'

const employeeDetailEmbed = [
  'personnel_history',
  'employee_contact_information',
  'employee_tag',
  'employee_project',
  'employee_qualification',
  'employee_language',
  'employee_skill',
  'employee_work_history',
  'employee_education_background',
  'employees_custom_values',
]

export const loadEmployee = async (id, token) => {
  const authedApi = api.createWithAuth(token)
  return authedApi.tm.employees.get(id, employeeDetailEmbed)
}

class Show extends Component {
  state = {
    yearStyle: getSaveDisplayYearStyle(),
  }

  getQuery(props = this.props) {
    const {
      location: {search},
    } = props
    const {query: queryStr, index: indexStr} = parse(search)
    if (!queryStr || !indexStr) {
      return {}
    }
    const index = +indexStr
    const query = JSON.parse(queryStr)
    if (index === 0) {
      query.limit = 2
      query.offset = 0
    } else {
      query.limit = 3
      query.offset = index - 1
    }
    return {query, index, queryStr}
  }

  fetchData(props = this.props) {
    const {id} = props.match.params
    const {token, dispatch, currentTmUser} = props
    dispatch(actionCreators.employees.current.fetchData(loadEmployee(id, token, dispatch)))
    dispatch(
      actionCreators.tm.userDisplaySettings.fetchData(
        api.createWithAuth(token).tm.userDisplaySettings.list(currentTmUser.employee.id)
      )
    )
    const {query, index} = this.getQuery(props)
    if (query) {
      dispatch(
        actionCreators.fetchData(
          'neighbor_employees',
          api
            .createWithAuth(token)
            .tm.employees.search(query)
            .then(({data: employees}) => {
              const next = _.last(employees)
              const prev = _.first(employees)
              if (index === 0) {
                if (employees[0] && Number(id) !== Number(employees[0].id)) {
                  // 「退職」などでemployee_statusが変わった場合
                  return {
                    prev: null,
                    next: employees[0],
                  }
                }
                return {
                  prev: null,
                  next,
                }
              }
              if (employees.length > 0 && employees.length < 3) {
                return {
                  prev,
                  next: null,
                }
              }
              if (employees.length === 3) {
                if (Number(id) !== Number(employees[1].id)) {
                  // 「退職」などでemployee_statusが変わった場合
                  return {
                    prev,
                    next: employees[1],
                  }
                }
                return {
                  prev,
                  next,
                }
              }
              return {
                prev: null,
                next: null,
              }
            })
        )
      )
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.fetchData(nextProps)
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.employees.current.destroy())
    this.props.dispatch(actionCreators.fetchDestroy('neighbor_employees'))
  }

  render() {
    const {employee, prev, next, location, userDisplaySetting} = this.props
    const {id} = this.props.match.params
    const {queryStr, index} = this.getQuery()
    return (
      <div>
        <div className={styles.naviArea}>
          <div className={styles.topNavi}>
            <div className="l-tm-breadcrumb">
              <Link to="/tm/employees" className="l-tm-breadcrumb-link">
                従業員検索
              </Link>
              <span className="l-tm-breadcrumb-here">従業員詳細</span>
            </div>
            <div className={styles.buttonArea}>
              <CopyUrlBtn />
              <SelectItem userDisplaySetting={userDisplaySetting} />
            </div>
          </div>
          <div className={styles.bottomNavi}>
            {prev && (
              <Link to={`/tm/employees/${prev.id}/?query=${encodeURIComponent(queryStr)}&index=${index - 1}`}>
                <ArrowDoublePrev className={styles.arrowIcon} />
                {recordDisplay.fullName(prev)}
              </Link>
            )}
            {prev && next && <span className={styles.arrowSplit}>|</span>}
            {next && (
              <Link to={`/tm/employees/${next.id}/?query=${encodeURIComponent(queryStr)}&index=${index + 1}`}>
                {recordDisplay.fullName(next)}
                <ArrowDoubleNext className={styles.arrowIcon} />
              </Link>
            )}
          </div>
        </div>

        <div className={styles.profileArea}>
          <div className={styles.profileDetail}>
            <div>
              {employee.icon && employee.icon.url ? (
                <div style={{backgroundImage: `url("${employee.icon.url}")`}} className={styles.profileImg} />
              ) : (
                <User className={styles.profileImg} size={74} />
              )}
            </div>

            <div className={styles.basicInfo}>
              <div>
                <div>
                  <span className={styles.staffCode}>スタッフコード</span>{' '}
                  <span>{recordDisplay(employee.staff_code)}</span>
                </div>
                <div className={styles.kana}>
                  {!_.get(employee, 'business_first_name') && !_.get(employee, 'business_last_name') && (
                    <span className={styles.staffKana}>{recordDisplay.fullName(employee, false, true)}</span>
                  )}
                </div>
                <div>
                  <span className={styles.staffName}>{recordDisplay.fullName(employee, false)}</span>
                </div>
                <div>
                  {recordDisplay(
                    !_.isEmpty(getTmGroups(employee)) && (
                      <span className={styles.staffGroup}>{wrapArrowText(getTmGroups(employee)[0])}</span>
                    )
                  )}
                </div>
                {(showCategory(userDisplaySetting, 'contact_information') && (
                  <div className={styles.internalContact}>
                    <div>
                      <span className={styles.extNumber}>内線番号</span>{' '}
                      <span>{recordDisplay(_.get(employee, 'employee_contact_information.extension_number'))}</span>
                      <span>/</span>
                    </div>
                    <div>
                      <span className={styles.phoneNumber}>電話番号(社用)</span>{' '}
                      <span>
                        {recordDisplay.numbers(_.get(employee, 'employee_contact_information'), 'phone_number', 3)}
                      </span>
                      <span>/</span>
                    </div>
                    <div>
                      <span className={styles.mail}>メール(社用)</span>{' '}
                      <span>{recordDisplay(_.get(employee, 'employee_contact_information.email'))}</span>
                    </div>
                  </div>
                )) ||
                  null}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.lFlex}>
          <div className={styles.infoArea}>
            <Infos
              id={id}
              yearStyle={this.state.yearStyle}
              editLink={`/employees/${id}/edit?back_to=${makeBackUrl(location)}`}
              userDisplaySetting={userDisplaySetting}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  scrollSpy,
  connect((state) => ({
    employee: state.employees.current.data,
    currentTmUser: state.session.currentTmUser,
    token: state.auth.token,
    prev: fetchSelector(state, 'neighbor_employees').prev,
    next: fetchSelector(state, 'neighbor_employees').next,
    userDisplaySetting: state.tm.userDisplaySettings.data,
  }))
)(Show)
