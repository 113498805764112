import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {gql} from '@apollo/client'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {useNotify} from 'hooks/useNotify'
import _ from 'lodash'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import {Label} from 'jbc-front/components/Form'
import {Edit, EditSquare, Strage, MinusCircle} from 'jbc-front/components/icons'
import {recordDisplay} from 'utils'
import {useQuery, useMutation} from 'components/Graphql'
import SelectList from 'components/SelectList'
import Modal from 'components/Modal'
import {getGroups} from 'employees/form/EmploymentInfomation'
import SortableTh from './roles/employeeList/SortableTh'
import PaginatorWithResult from './roles/employeeList/PaginatorWithResult'
import SearchForm from './roles/employeeList/SearchForm'
import {getSavedDisplayEmployeeLimit} from './roles/employeeList/utils'
import styles from './TalentDisplay.scss'

const ROLES = gql`
  query roles {
    client {
      id
      roles {
        id
        name
        isDefault
        description
      }
    }
  }
`

const APPLY_ROLE_CHANGES = gql`
  mutation apply_role_changes($input: ApplyRoleChangesInput!) {
    applyRoleChanges(input: $input) {
      client {
        id
      }
    }
  }
`

const wrapArrowText = (source) => {
  return _.flatMap(source.split('->'), (text, index) =>
    index === 0
      ? text
      : [
          <span className={styles.arrowText} key={index}>
            -&gt;
          </span>,
          text,
        ]
  )
}

const Td = ({children, ...rest}) => <td {...rest}>{children}</td>

const ChangeMulti = ({selected, roleOptions, bulkChange, reset}) => (
  <Modal>
    {({hideModal, showModal, isOpen}) => {
      /* eslint-disable react-hooks/rules-of-hooks -- 動くけどrender propで使うのはダメなので修正が望ましい */
      const [bulkRole, setBulkRole] = useState(null)
      useEffect(() => {
        setBulkRole(null)
      }, [isOpen])
      /* eslint-enable */
      return (
        <>
          <div className={_.some(selected) ? styles.multiLink : styles.multiLinkDisabled} onClick={showModal}>
            <EditSquare className="u-mr5" />
            チェックを入れたアカウントの権限を一括変更
          </div>
          {isOpen && (
            <Modal.Modal>
              <Modal.Header hideModal={hideModal}>アカウント権限一括変更</Modal.Header>
              <Modal.Body className={styles.modalBody} style={{overflow: 'visible'}}>
                選択された{_.filter(selected).length}個のアカウントの権限を以下のように変更します。
                <br />
                <br />
                <Label text="権限" required />
                <Select
                  name={'bulked_role_id'}
                  value={_.find(roleOptions, {value: bulkRole})}
                  onChange={(v) => setBulkRole(v)}
                  options={roleOptions}
                  simpleValue
                  clearable={false}
                  placeholder={'選択してください'}
                />
              </Modal.Body>
              <Modal.Footer>
                <Modal.Buttons>
                  <Button onClick={hideModal}>キャンセル</Button>
                  <Button
                    primary
                    onClick={() => {
                      bulkChange(selected, bulkRole)
                      reset()
                      hideModal()
                    }}
                    disabled={!bulkRole}
                  >
                    変更
                  </Button>
                </Modal.Buttons>
              </Modal.Footer>
            </Modal.Modal>
          )}
        </>
      )
    }}
  </Modal>
)

const ListTable = ({list, th, editMode, roleOptions, employeeRoles, roleChanges, setRoleChanges}) => (
  <div className="l-overflow-scroll">
    <table className={'m-table-list m-table-fixed'}>
      <thead>
        <tr className={styles.table}>
          {editMode && th}
          <SortableTh field="email" globalClassName={styles.emailColumn}>
            メールアドレス
          </SortableTh>
          <SortableTh field="staff_code" globalClassName={styles.staffcodeColumn}>
            スタッフコード
          </SortableTh>
          <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
            氏名
          </SortableTh>
          <SortableTh field="group" globalClassName={styles.groupColumn}>
            グループ
          </SortableTh>
          <th className={styles.roleColumn}>権限</th>
        </tr>
      </thead>
      <tbody>
        {list.map(({item: employee, toggle, td}) => {
          const id = employee.id
          const onClick = editMode ? toggle : undefined
          const roleId = _.find(employeeRoles, {employeeId: id})?.roleId
          const changedRoleId = _.find(roleChanges, {employeeId: id})?.roleId
          return (
            <tr className={styles.table} key={id}>
              {editMode && td}
              <Td onClick={onClick} className={styles.emailColumn}>
                {recordDisplay(employee.email)}
              </Td>
              <Td onClick={onClick} className={styles.staffcodeColumn}>
                {recordDisplay(employee.staff_code)}
              </Td>
              <Td onClick={onClick} className={styles.nameColumn}>
                {recordDisplay.fullName(employee)}
              </Td>
              <Td onClick={onClick} className={styles.groupColumn}>
                {recordDisplay(
                  !_.isEmpty(getGroups(employee)) &&
                    getGroups(employee).map((group, index) => (
                      <span key={index}>
                        {wrapArrowText(group)}
                        <br />
                      </span>
                    ))
                )}
              </Td>
              <Td className={styles.roleColumn}>
                {editMode ? (
                  <Select
                    name={`${employee.id}.role_id`}
                    value={_.find(roleOptions, {value: `${changedRoleId || roleId}`})}
                    onChange={(v) => {
                      const newRoleChanges = [...roleChanges]
                      _.remove(newRoleChanges, {employeeId: employee.id})
                      if (parseInt(v) !== roleId) {
                        newRoleChanges.push({employeeId: employee.id, roleId: parseInt(v)})
                      }
                      setRoleChanges(newRoleChanges)
                    }}
                    options={roleOptions}
                    simpleValue
                    clearable={false}
                  />
                ) : (
                  <div>{recordDisplay(_.find(roleOptions, {value: `${roleId}`})?.label)}</div>
                )}
              </Td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </div>
)

const additionalParams = {only_tm_user: true, embed: ['personnel_history', 'role']}

const TalentDisplay = () => {
  const employees = useSelector((state) => state.employees.list.data || [])
  const user = useSelector((state) => state.session.currentUser)
  const {pathname} = useSelector((state) => state.router.location)
  const notify = useNotify()
  const [employeeRoles, setEmployeeRoles] = useState([])
  const [roleChanges, setRoleChanges] = useState([])
  const [editMode, setEditMode] = useState(false)
  const {data, loading, error} = useQuery(ROLES, {fetchPolicy: 'network-only'})
  const [mutation] = useMutation(APPLY_ROLE_CHANGES)
  const roles = data?.client?.roles || []
  const roleOptions = roles.map((role) => ({value: role.id, label: role.name}))
  const applyRoleChanges = async () => {
    await mutation({variables: {input: {roleChanges}}})
    const changedEmployeeIds = roleChanges.map((change) => change.employeeId)
    let newEmployeeRoles = [...employeeRoles]
    newEmployeeRoles = _.reject(newEmployeeRoles, (employeeRole) =>
      changedEmployeeIds.includes(employeeRole.employeeId)
    )
    newEmployeeRoles = newEmployeeRoles.concat(roleChanges)
    notify('権限を変更しました', 'success')
    setEmployeeRoles(newEmployeeRoles)
    setRoleChanges([])
    setEditMode(false)
  }
  const bulkChange = (selected, bulkRole) => {
    const employeeIds = Object.keys(selected).map((key) => parseInt(key))
    let newRoleChanges = [...roleChanges]
    newRoleChanges = _.reject(newRoleChanges, (change) => employeeIds.includes(change.employeeId))
    newRoleChanges = newRoleChanges.concat(employeeIds.map((id) => ({employeeId: id, roleId: parseInt(bulkRole)})))
    setRoleChanges(newRoleChanges)
  }
  useEffect(() => {
    setEmployeeRoles(employees.map((employee) => ({employeeId: employee.id, roleId: employee.role.id})))
  }, [employees])
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">情報公開設定</h1>
      </div>
      <div className="l-wrap-xxl l-contents-wrap">
        <div className={styles.contentsHead}>
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <span className="l-breadcrumb-here">情報公開設定</span>
          </div>
          <div className={styles.buttons}>
            <Link to="/settings/roles/create" className={styles.linkButton}>
              <Button>権限追加</Button>
            </Link>
            <Link to="/settings/roles/list" className={styles.linkButton}>
              <Button>権限一覧</Button>
            </Link>
          </div>
        </div>
        {!loading && !error && (
          <div className={styles.widePage}>
            <SelectList
              isTable={true}
              list={employees}
              selectedInfo={(employee) => ({
                name: (employee.staff_code ? employee.staff_code + ' ' : '') + employee.display_full_name,
              })}
            >
              {({list, th, reset, selected}) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks -- 動くけどrender propで使うのはダメなので修正が望ましい
                useEffect(() => {
                  if (!editMode) {
                    reset()
                  }
                }, [editMode])
                return (
                  <>
                    <SearchForm
                      buttons={
                        editMode ? (
                          <div className={styles.buttonWrap}>
                            <ActionButton onClick={() => applyRoleChanges()} icon={<Strage size={15} />}>
                              保存
                            </ActionButton>
                            <ActionButton
                              onClick={() => {
                                setRoleChanges([])
                                setEditMode(false)
                              }}
                              icon={<MinusCircle size={15} />}
                            >
                              キャンセル
                            </ActionButton>
                          </div>
                        ) : (
                          <div>
                            <ActionButton onClick={() => setEditMode(true)} icon={<Edit size={15} />}>
                              編集
                            </ActionButton>
                          </div>
                        )
                      }
                      additionalParams={additionalParams}
                    />
                    <ListTable {...{list, th, editMode, roleOptions, employeeRoles, roleChanges, setRoleChanges}} />
                    <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(pathname, user)} />
                    {editMode && (
                      <ChangeMulti
                        selected={selected}
                        roleOptions={roleOptions}
                        bulkChange={bulkChange}
                        reset={reset}
                      />
                    )}
                  </>
                )
              }}
            </SelectList>
          </div>
        )}
      </div>
    </div>
  )
}

export default TalentDisplay
