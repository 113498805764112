import {FC} from 'react'
import {Search} from '../../../query'
import {UnPublishModal} from './UnPublishModal'
import {PublishModal} from './PublishModal'

type PublishColumnProps = {
  isPublished: boolean
  documentId: number
  employeeId: number
  search: Search
}

export const PublishColumn: FC<PublishColumnProps> = ({isPublished, employeeId, documentId, search}) => {
  if (isPublished) {
    return <UnPublishModal documentId={documentId} employeeId={employeeId} search={search} />
  } else {
    return <PublishModal documentId={documentId} employeeId={employeeId} search={search} />
  }
}
