import React, {Component} from 'react'
import {connect} from 'react-redux'
import api from 'api'
import {replace} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'

export default ({procetureType}) =>
  (Child) => {
    class CheckNeedEmployeeUpdate extends Component {
      state = {
        loading: true,
      }

      async componentDidMount() {
        const {
          match: {
            params: {id},
          },
          token,
          dispatch,
        } = this.props
        try {
          const {
            data: {need, employee_id},
          } = await api.createWithAuth(token).procedureStatuses.checkNeedEmployeeUpdate.get(id)
          if (need) {
            dispatch(
              replace(
                `/employees/${employee_id}/edit?procedure_type=${procetureType}&next_action=confirm&procedure_status_id=${id}`
              )
            )
          }
          this.setState({loading: false})
        } catch (err) {
          dispatch(asyncError(err))
        }
      }

      render() {
        return !this.state.loading && <Child {...this.props} />
      }
    }
    return connect((state) => ({
      token: state.auth.token,
    }))(CheckNeedEmployeeUpdate)
  }
