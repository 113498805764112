import {FC} from 'react'
import useRouter from 'use-react-router'

import {displayFormat, fullNameFormat, dateFormat} from 'libs/formatter'
import {STATUS_MAP} from 'consts/updateRequestStatus'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {useQuery} from 'hooks/graphql'
import {Content} from 'components/layout/Content'
import {Loading} from 'components/ui/Loading'
import {CommentList} from 'components/ui/CommentList'
import {Breadcrumb} from 'components/layout/Breadcrumb'
import {Dl, Dt, Dd} from 'components/ui/DefinitionList'

import {User} from 'jbc-front/components/icons'
import {Checkbox} from 'jbc-front/components/presenters/form/Checkbox'
import {Spacer} from 'jbc-front/components//presenters/Spacer'

import {FloatingButtons} from './parts/FloatingButtons'
import {QueryResult, DETAIL_INPUT_REQUEST_WITH_FIELD_GROUPS} from './query'
import styles from './Presenter.scss'

const TYPE = {
  InitialInput: 'マイページ招待',
  DirectRequest: '管理者',
  UpdateProfile: '従業員',
}

export const Presenter: FC = () => {
  const paths = [
    {
      key: 'settings',
      to: WITH_AUTH_ADMIN_PATHS.EMPLOYEES.UPDATE_REQUESTS.INDEX,
      label: '更新依頼一覧',
    },
  ]
  const {
    match: {
      params: {id},
    },
  } = useRouter<{id: string}>()
  const {data, loading} = useQuery<QueryResult>(DETAIL_INPUT_REQUEST_WITH_FIELD_GROUPS, {
    fetchPolicy: 'network-only',
    variables: {id},
  })

  const detailInputRequest = data?.client?.detailInputRequest
  const detailInputRequestGroups = data?.client?.detailInputRequest?.employeeFieldGroupMasters
  const detailInputRequestCustomGroups = data?.client?.detailInputRequest?.customEmployeeFieldGroups
  const employee = detailInputRequest?.employee
  const customEmployeeFieldGroups = data?.client?.customEmployeeFieldGroups
  const requestGroupsCount =
    detailInputRequestGroups &&
    detailInputRequestCustomGroups &&
    detailInputRequestGroups.length + detailInputRequestCustomGroups.length

  const checked = (code: string) => {
    const codes = detailInputRequestGroups?.map((master) => master.code)
    return codes?.includes(code)
  }

  const customChecked = (id: string) => {
    const ids = detailInputRequestCustomGroups?.map((custom) => custom.id)
    return ids?.includes(id)
  }

  return (
    <Content size="m">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Breadcrumb paths={paths} current="更新依頼詳細" />

          {employee && (
            <div className={styles.detailInputRequestContainer}>
              {employee.icon ? (
                <div style={{backgroundImage: `url("${employee.icon}")`}} className={styles.profileImg} />
              ) : (
                <User className={styles.profileImg} size={74} />
              )}
              <div className={styles.employeeInfo}>
                <p className={styles.fullName}>{fullNameFormat(employee.displayLastName, employee.displayFirstName)}</p>
                <p className={styles.staffCode}>{employee.staffCode}</p>
                {employee.personnelHistory?.group0Name && <p>{employee.personnelHistory.group0Name}</p>}
                {employee.personnelHistory?.group0Name && <p>{employee.personnelHistory.group1Name}</p>}
                {employee.personnelHistory?.group0Name && <p>{employee.personnelHistory.group2Name}</p>}
              </div>
            </div>
          )}

          <Spacer direction="y" size={40} />

          {detailInputRequest && (
            <>
              <div className={styles.detailInputRequestContainer}>
                <Dl>
                  <Dt>ステータス</Dt>
                  <Dd>{STATUS_MAP[detailInputRequest.status]}</Dd>

                  <Dt>依頼者</Dt>
                  <Dd>{TYPE[detailInputRequest.type]}</Dd>

                  <Dt>依頼日時</Dt>
                  <Dd>{dateFormat(detailInputRequest.createdAt, 'YYYY/MM/DD HH:mm:ss')}</Dd>

                  <Dt>最終更新日時</Dt>
                  <Dd>{dateFormat(detailInputRequest.updatedAt, 'YYYY/MM/DD HH:mm:ss')}</Dd>
                </Dl>
              </div>

              <Spacer direction="y" size={40} />

              {detailInputRequest.detailInputComments.length > 0 && (
                <>
                  <CommentList>
                    {detailInputRequest.detailInputComments.map((detailInputComment) => (
                      <CommentList.Item
                        key={`comment-item-${detailInputComment.id}`}
                        type={detailInputComment.type}
                        commenterName={displayFormat(detailInputComment.user?.displayName)}
                        commentedAt={detailInputComment.createdAt}
                        comment={detailInputComment.comment}
                      />
                    ))}
                  </CommentList>
                  <Spacer direction="y" size={40} />
                </>
              )}

              <div className={styles.checkboxContainer}>
                <p className={styles.heading}>デフォルト項目</p>
                {data?.employeeFieldGroupMaster?.map((employeeFieldGroup) => (
                  <div key={`key-checkbox-${employeeFieldGroup.id}`} className={styles.checkbox}>
                    <Checkbox defaultChecked={checked(employeeFieldGroup.code)} disabled>
                      {employeeFieldGroup.label}
                    </Checkbox>
                  </div>
                ))}

                {customEmployeeFieldGroups && customEmployeeFieldGroups.length > 0 && (
                  <>
                    <p className={styles.heading}>カスタマイズ項目</p>
                    {customEmployeeFieldGroups.map((customEmployeeFieldGroup) => (
                      <div key={`key-checkbox-${customEmployeeFieldGroup.id}`} className={styles.checkbox}>
                        <Checkbox defaultChecked={customChecked(customEmployeeFieldGroup.id)} disabled>
                          {customEmployeeFieldGroup.label}
                        </Checkbox>
                      </div>
                    ))}
                  </>
                )}
              </div>

              {detailInputRequest.status === 'accepted' && (
                <div className="u-mt10">
                  <p>※更新内容の詳細については操作履歴よりご確認ください。</p>
                </div>
              )}

              <Spacer direction="y" size={40} />

              <FloatingButtons
                detailInputRequest={detailInputRequest}
                disabledRemind={!['none', 'rejected'].includes(detailInputRequest.status) || !requestGroupsCount}
              />
            </>
          )}
        </>
      )}
    </Content>
  )
}
