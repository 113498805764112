import React from 'react'
import {recordDisplay} from 'utils'
import styles from 'systemAnnounces/List.scss'
import {gql} from '@apollo/client'
import Paginator from 'jbc-front/components/Paginator'
import {Query} from 'components/Graphql'
import {parse} from 'query-string'
import ListGroup from 'jbc-front/components/ListGroup'
import {Link} from 'react-router-dom'

const DATE_WITH_YEAR_LENGTH = 9
export const SYSTEM_ANNOUNCES = gql`
  query systemAnnounces($per: Int, $page: Int) {
    systemAnnounces(per: $per, page: $page) {
      totalCount
      list {
        id
        title
        link
        publishAt
        systemAnnounceType
      }
    }
  }
`

const SystemAnnounce = ({systemAnnounce}) => {
  const dateOrTime = recordDisplay.dateOrTime(systemAnnounce.publishAt)

  return (
    <ListGroup.Item
      className={styles.list}
      key={systemAnnounce.id}
      {...(systemAnnounce.systemAnnounceType === 'link'
        ? {href: systemAnnounce.link, target: '_blank', as: 'a'}
        : {to: `system_announces/${systemAnnounce.id}`, as: Link})}
    >
      <span className={dateOrTime.length < DATE_WITH_YEAR_LENGTH ? styles.date : styles.dateWithYear}>
        {dateOrTime}
      </span>
      {systemAnnounce.title}
    </ListGroup.Item>
  )
}

const PER_PAGE = 100

const List = ({location: {search, pathname}, history}) => {
  const {page} = parse(search)
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">お知らせ</h1>
      </div>
      <Query query={SYSTEM_ANNOUNCES} variables={{page: page | 0, per: PER_PAGE}} fetchPolicy="network-only">
        {({
          data: {
            systemAnnounces: {list, totalCount},
          },
        }) => (
          <div className="l-wrap-s">
            <ListGroup style={{width: '100%'}}>
              <ListGroup.Title>お知らせ</ListGroup.Title>
              {totalCount > 0 ? (
                list.map((systemAnnounce) => <SystemAnnounce key={systemAnnounce.id} systemAnnounce={systemAnnounce} />)
              ) : (
                <ListGroup.Item className={styles.empty}>通知がまだありません</ListGroup.Item>
              )}
            </ListGroup>
            <div className="m-pager-area">
              <Paginator
                current={page}
                rowsPerPage={PER_PAGE}
                onClick={(page) => history.push(`${pathname}?page=${page}`)}
                totalResult={totalCount}
              />
              <div style={{width: 140}} />
            </div>
          </div>
        )}
      </Query>
    </div>
  )
}

export default List
