import React from 'react'
import {FieldArray, FormSection} from 'redux-form'
import {Section, SelectField, TextField, DateField, RadioField, BoxDouble} from 'jbc-front/components/Form'
import {graduationTypes} from 'FormFields'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import {Description, getDiffFromOptions, withFormSelectors} from 'employees/form/common'
import moment from 'moment'
import * as validators from 'jbc-front/utils/validators'
import _ from 'lodash'
import styles from 'employees/Form.scss'

const renderEmployeeEducationBackgroundComponent = ({fields, index, name, schoolTypes, diff = {}}) => {
  const schoolTypeOptions = schoolTypes.map((schoolType) => ({
    value: '' + schoolType.id,
    label: schoolType.name,
  }))

  return (
    <li>
      <LabelMapper name="employee_education_backgrounds" label="学歴の" />
      <Section
        title={`学歴 (${index + 1})`}
        key={index}
        icon={
          <DeleteSquare
            onClick={() => {
              fields.remove(index)
            }}
          />
        }
      >
        <FormSection name={name}>
          <SelectField
            options={schoolTypeOptions}
            name="school_type_id"
            label="学校区分"
            diff={diff.school_type_id}
            required
          />
          <TextField
            name="school_name"
            label="学校名"
            validate={validators.maxLength(32)}
            diff={diff.school_name}
            required
          />
          <TextField
            name="department"
            label="学部学科専攻"
            validate={validators.maxLength(50)}
            diff={diff.department}
          />
          <BoxDouble>
            <div className={styles.arrayDoubleDate}>
              <DateField name="entrance_date" label="入学年月日" diff={diff.entrance_date} />
            </div>
            <div className={styles.arrayDoubleDate}>
              <DateField name="graduation_date" label="卒業年月日" diff={diff.graduation_date} />
            </div>
          </BoxDouble>
          <RadioField
            name="graduation_type"
            label="卒業区分"
            options={graduationTypes}
            diff={getDiffFromOptions(graduationTypes, diff?.graduation_type)}
          />
        </FormSection>
      </Section>
    </li>
  )
}

const EmployeeEducationBackgroundComponent = connect((state, {index, selector}) => ({
  schoolTypes: state.master.schoolTypes || [],
  entranceDate: selector(state, `employee_education_backgrounds[${index}].entrance_date`),
  graduationDate: selector(state, `employee_education_backgrounds[${index}].graduation_date`),
}))(renderEmployeeEducationBackgroundComponent)

export const renderEducationBackground = ({fields, selector, diff = []}) => (
  <div>
    <LabelMapper name="employee_education_backgrounds" label="学歴の" />
    <ul>
      {fields.map((name, index) => (
        <EmployeeEducationBackgroundComponent
          diff={diff[index]}
          {...{
            index,
            name,
            fields,
            selector,
          }}
          key={index}
        />
      ))}
    </ul>
    {fields.length < 1 && (
      <div className="u-ta-c">
        <p>
          「学歴を追加」ボタンを押して、
          <br />
          学歴を追加してください。
        </p>
      </div>
    )}
    <div className="u-ta-r u-mt20">
      <div onClick={() => fields.push({})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        学歴を追加
      </div>
    </div>
  </div>
)

const EmployeeEducationBackground = ({selector, description, description_color_by_rgb, diff = {}}) => {
  return (
    <Section title="学歴">
      <Description {...{description, description_color_by_rgb}} />
      <FieldArray
        name="employee_education_backgrounds"
        component={renderEducationBackground}
        diff={diff.employee_education_backgrounds}
        {...{selector}}
      />
    </Section>
  )
}

export default EmployeeEducationBackground
  |> connect((state, {selector}) => ({
    diff: selector(state, '_diff'),
  }))
  |> withFormSelectors

const validatePeriods = ({name, startColumn, endColumn}, values) => {
  const periods = values[name]
  if (!periods) {
    return {}
  }

  const errors = {}
  periods.forEach((period, index) => {
    const startOn = period[startColumn] ? moment(period[startColumn]) : null
    const endOn = period[endColumn] ? moment(period[endColumn]) : null
    if (startOn && endOn && startOn.isAfter(endOn)) {
      _.set(errors, `${name}[${index}].${endColumn}`, 'は正しくありません')
    }
  })
  return errors
}

export const validate = (values) => {
  return Object.assign(
    {},
    validatePeriods(
      {name: 'employee_education_backgrounds', startColumn: 'entrance_date', endColumn: 'graduation_date'},
      values
    )
  )
}
