import {combineReducers} from 'redux'
import fetchReducer from 'reducers/fetchReducer'
import {actionTypes} from 'actions'
import notifications from 'tm/notifications/reducers'
import roles from 'tm/roles/reducers'

const reducer = combineReducers({
  userDisplaySettings: fetchReducer(actionTypes.TM.USER_DISPLAY_SETTINGS, {data: []}),
  notificationTemplates: fetchReducer(actionTypes.TM.NOTIFICATION_TEMPLATES, {data: []}),
  notifications,
  roles,
})

export default reducer
