import React, {useState, useEffect, useRef} from 'react'
import styles from 'components/ReadMoreReadLess.scss'
import ShowMoreButton from 'components/ShowMoreButton'
import classNames from 'classnames'

const ReadMoreReadLess = ({children, className}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const ref = useRef(null)
  const handleClickBtn = () => setIsExpanded((prev) => !prev)
  useEffect(() => {
    const readMoreLessEventFunct = () => {
      const element = ref.current
      const hasCollapsing = (el) => {
        const {clientHeight, scrollHeight} = el
        return clientHeight !== scrollHeight
      }

      if (element) {
        const hadCollapseClass = element.classList.contains(styles.collapse)
        if (!hadCollapseClass) element.classList.add(styles.collapse)
        setShowButton(hasCollapsing(element))
        if (!hadCollapseClass) element.classList.remove(styles.collapse)
      }
    }
    window.addEventListener('resize', readMoreLessEventFunct)
    readMoreLessEventFunct()
    return () => window.removeEventListener('resize', readMoreLessEventFunct)
  }, [children])

  return (
    <div>
      <div ref={ref} className={classNames(!isExpanded && styles.collapse, className)}>
        {children}
      </div>
      {showButton && (
        <div className={classNames(styles.btnarea, className)}>
          <ShowMoreButton isExpanded={isExpanded} onClick={handleClickBtn} />
        </div>
      )}
    </div>
  )
}

export default ReadMoreReadLess
