import ChooseEmployee from 'procedures/ChooseEmployee'
import Detail from 'procedures/changeAddress/Detail'
import Flow from 'procedures/changeAddress/Flow'
import Confirm from 'procedures/changeAddress/Confirm'
import EmployeeInput from 'procedures/changeAddress/EmployeeInput'
import Update from 'procedures/changeAddress/Update'

export default {
  ChooseEmployee,
  Detail,
  Confirm,
  Flow,
  EmployeeInput,
  Update,
}
