import {useEffect} from 'react'
import {LazyQueryHookOptions, QueryTuple, useLazyQuery as useLazyQueryOrigin} from '@apollo/client'
import {OperationVariables} from '@apollo/client'
import {useDispatch} from 'react-redux'
import {handleQueryError} from 'libs/graphql'

export const useLazyQuery: typeof useLazyQueryOrigin = <TData = any, TVariables = OperationVariables>(
  query: any,
  options?: LazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> => {
  const dispatch = useDispatch()
  const queryTuple = useLazyQueryOrigin(query, options)
  const [, queryResult] = queryTuple

  useEffect(() => {
    if (queryResult.error) {
      handleQueryError(queryResult.error, dispatch)
    }
  }, [dispatch, queryResult.error])

  return queryTuple
}
