import React from 'react'
import styles from 'components/PrimaryPanel.scss'

export const Headding = ({children, icon, ...rest}) => (
  <div className={styles.heading} {...rest}>
    <div>{children}</div>
    {icon && <span className={styles.leadIcon}>{icon}</span>}
  </div>
)

export const Panel = ({children, ...rest}) => (
  <div className={styles.base} {...rest}>
    {children}
  </div>
)

export const Lead = ({children, icon}) => (
  <div className={styles.lead}>
    icon && <span className={styles.leadIcon}>{icon}</span>
    {children}
  </div>
)

export const Icon = ({children}) => <div className={styles.iconMemo}>{children}</div>

export const Item = ({children}) => <div className={styles.inner}>{children}</div>
