import React, {useMemo, useCallback} from 'react'
import japanese from 'japanese'
import api from 'api'
import {useDispatch} from 'react-redux'
import {Field, autofill, FormName, formValueSelector, FormSection} from 'redux-form'
import {toBooleanProps, prefectures, getState} from 'utils'
import styles from 'FormFields.scss'
import {getDiffFromOptions, getDiffFromCheckbox, getDiffFromFile} from 'employees/form/common'

import {
  Label,
  BoxDouble,
  TextField,
  TextFieldWithKana,
  DateField,
  SelectField,
  RadioField,
  CheckboxField,
  TextAreaField,
  NumberFields,
  FileField,
} from 'jbc-front/components/Form'

import * as validators from 'validators'
import _ from 'lodash'

const romaReasonOptions = [
  {name: 'living_short_time', label: '短期在留者であるため'},
  {name: 'has_address_in_foreign_countries', label: '海外に住所を有している者であるため'},
  {
    name: 'no_entry_of_romaji_name',
    label: '在留カード（または特別永住者証明書）にローマ字氏名が記載されていないため',
  },
  {name: 'other_reason', label: 'その他'},
]

export const nationalTypes = [
  {value: 'japanese', label: '日本人'},
  {value: 'foreigner', label: '外国人'},
]

export const prefectureOptions = prefectures.map((val, index) => ({value: `${index + 1}`, label: val}))

export const genderOptions = [
  {value: 'man', label: '男性'},
  {value: 'woman', label: '女性'},
]

export const handicapTypes = [
  {value: 'none', label: '対象外'},
  {value: 'normal', label: '一般障害者'},
  {value: 'special', label: '特別障害者'},
]

export const nonResidentTypes = [
  {value: 'none', label: '対象外'},
  {value: 'non_resident', label: '非居住者'},
]

export const taxTableTypes = [
  {value: 'main_income', label: '甲欄'},
  {value: 'sub_income', label: '乙欄'},
  {value: 'none', label: '所得税を計算しない'},
]

export const victimTypes = [
  {value: 'none', label: '対象外'},
  {value: 'victim', label: '災害者'},
]

export const graduationTypes = [
  {value: 'graduate', label: '卒業'},
  {value: 'drop_out', label: '中退'},
]

const joinedOptions = [
  {value: 'true', label: '加入'},
  {value: 'false', label: '未加入'},
]

const kanrentodokesyoSiyouSimei = [
  {value: 'romaji', label: '1. ローマ字'},
  {value: 'kanji', label: '2. 漢字'},
  {value: 'tsusho', label: '3. 通称'},
]

export const Birthday = ({required, diff = {}}) => (
  <DateField
    name="birthday"
    label="生年月日"
    viewMode="years"
    example="例）1981/01/23、S56.1.23"
    required={required}
    diff={diff.birthday}
  />
)

export const Sex = ({required, diff = {}}) => (
  <RadioField
    name="gender"
    label="性別"
    options={genderOptions}
    required={required}
    diff={getDiffFromOptions(genderOptions, diff?.gender)}
  />
)

export const withPrefix = (prefix, name) => (prefix ? `${prefix}.${name}` : name)

export const Name = ({required, isCommonName, isKanji, prefix = '', noHankaku, withZenkakuSpace, diff}) => {
  const labelKanji = isKanji ? '（漢字）' : ''
  const labelCommonName = isCommonName ? '通称' : ''
  const dispatch = useDispatch()
  return (
    <FormName>
      {({form, sectionPrefix}) => (
        <BoxDouble>
          <TextFieldWithKana
            name={`${prefix}last_name`}
            label={`${labelCommonName}姓${labelKanji}`}
            type="text"
            onUpdateKana={(kana) => {
              dispatch(autofill(form, withPrefix(sectionPrefix, `${prefix}last_name_kana`), japanese.katakanize(kana)))
            }}
            required={required}
            validate={(noHankaku && validators.noHankaku) || validators.maxLength(32)}
            diff={diff[`${prefix}last_name`]}
          />
          <TextFieldWithKana
            name={`${prefix}first_name`}
            label={`${labelCommonName}名${labelKanji}`}
            type="text"
            onUpdateKana={(kana) => {
              dispatch(autofill(form, withPrefix(sectionPrefix, `${prefix}first_name_kana`), japanese.katakanize(kana)))
            }}
            validate={(noHankaku && validators.noHankaku) || validators.maxLength(32)}
            required={required}
            diff={diff[`${prefix}first_name`]}
          />

          <TextField
            name={`${prefix}last_name_kana`}
            label={`${labelCommonName}姓(カナ)`}
            validate={
              withZenkakuSpace ? validators.zenkakuKatakanaWithZenkakuSpace : validators.zenkakuKatakanaWithSpace
            }
            required={required}
            diff={diff[`${prefix}last_name_kana`]}
          />
          <TextField
            name={`${prefix}first_name_kana`}
            label={`${labelCommonName}名(カナ)`}
            validate={
              withZenkakuSpace ? validators.zenkakuKatakanaWithZenkakuSpace : validators.zenkakuKatakanaWithSpace
            }
            required={required}
            diff={diff[`${prefix}first_name_kana`]}
          />
        </BoxDouble>
      )}
    </FormName>
  )
}
Name.defaultProps = {
  diff: {},
}

export const AddressWithCountry = ({required, isForeign, countryOptions, diff = {}}) => (
  <div>
    <SelectField
      name="country"
      label={isForeign ? 'Country' : '居住国'}
      options={countryOptions}
      required={required}
      diff={diff.country}
    />
    {isForeign ? <AddressForeign required={required} diff={diff} /> : <Address required={required} diff={diff} />}
  </div>
)

export const AddressForeign = ({required, diff = {}}) => (
  <TextField name="address_foreign" label="Address" required={required} diff={diff.address_foreign} />
)

const AddressComponent = ({
  required,
  kanaRequired,
  prefix = '',
  withKana,
  officeContactInfo,
  noHankaku,
  eGov,
  diff,
  disabled,
  form,
  sectionPrefix,
}) => {
  const dispatch = useDispatch()
  const selector = useMemo(() => formValueSelector(form), [form])
  const handleChange = useCallback(
    async (event, value, previousValue, name) => {
      const state = await getState(dispatch)
      const token = state.auth.token
      const postcode_0 = name.match(/.*postcode_0$/)
        ? value
        : selector(state, withPrefix(sectionPrefix, `${prefix}postcode_0`))
      const postcode_1 = name.match(/.*postcode_1$/)
        ? value
        : selector(state, withPrefix(sectionPrefix, `${prefix}postcode_1`))
      if (postcode_0?.length === 3 && postcode_1?.length === 4) {
        const postcode = `${postcode_0}${postcode_1}`
        if (postcode.match(/\d{7}/)) {
          try {
            const address = await api.createWithAuth(token).getAddressByPostcode(postcode)
            if (address && +postcode === address.postcode) {
              const prefecture = prefectureOptions.find(
                (prefectureOption) => prefectureOption.label === address.prefecture
              )
              if (prefecture) {
                dispatch(autofill(form, withPrefix(sectionPrefix, `${prefix}prefecture_id`), prefecture.value))
              }
              dispatch(autofill(form, withPrefix(sectionPrefix, `${prefix}city`), address.city))
              dispatch(autofill(form, withPrefix(sectionPrefix, `${prefix}house_number`), address.street))
              if (withKana) {
                dispatch(
                  autofill(
                    form,
                    withPrefix(sectionPrefix, `${prefix}address_kana`),
                    address.prefecture_kana + address.city_kana + address.street_kana
                  )
                )
              }
            }
          } catch (err) {
            // ignore error
            if (process.env.NODE_ENV !== 'production' && console.error) {
              console.error(err)
            }
          }
        }
      }
    },
    [form, sectionPrefix]
  )
  const validate = [eGov ? validators.eGovZenkakuKatakana : validators.zenkakuKatakanaLoose]
  if (noHankaku) {
    validate.concat(validators.noHankaku)
  }
  if (officeContactInfo) {
    validate.concat(validators.maxLength(255))
  }

  return (
    <>
      <NumberFields
        texts={['〒 ', ' - ']}
        lengths={[3, 4]}
        required={required}
        label="郵便番号"
        prefix={`${prefix}postcode`}
        exactLength={true}
        onChange={handleChange}
        diff={diff.postcode}
        disabled={disabled}
      />
      <BoxDouble>
        <SelectField
          name={`${prefix}prefecture_id`}
          label="都道府県"
          options={prefectureOptions}
          required={required}
          diff={diff[`${prefix}prefecture_id`]}
          disabled={disabled}
        />
        <TextField
          name={`${prefix}city`}
          label="市区町村"
          required={required}
          validate={noHankaku && validators.noHankaku}
          diff={diff[`${prefix}city`]}
          disabled={disabled}
          placeholder="渋谷区"
        />
        <TextField
          name={`${prefix}house_number`}
          label="丁目番地号"
          validate={noHankaku && validators.noHankaku}
          diff={diff[`${prefix}house_number`]}
          disabled={disabled}
          placeholder="9-99-99"
        />
        <TextField
          name={`${prefix}building`}
          label="建物名"
          validate={noHankaku && validators.noHankaku}
          diff={diff[`${prefix}building`]}
          disabled={disabled}
          placeholder="ジョブカンアパート"
        />
      </BoxDouble>
      {withKana && (
        <TextField
          name={`${prefix}address_kana`}
          label="住所カナ"
          required={kanaRequired}
          validate={validate}
          diff={diff[`${prefix}address_kana`]}
          disabled={disabled}
        />
      )}
    </>
  )
}
AddressComponent.defaultProps = {
  withKana: true,
  officeContactInfo: false,
  eGov: false,
  diff: {},
}

export const Address = (props) => (
  <FormName>{({form, sectionPrefix}) => <AddressComponent {...{form, sectionPrefix, ...props}} />}</FormName>
)

export const Phone = ({required, diff = {}, disabled}) => (
  <NumberFields
    texts={['', ' - ']}
    lengths={[4, 4, 4]}
    required={required}
    label="電話番号"
    prefix="phone_number"
    diff={_.get(diff, 'phone_number')}
    disabled={disabled}
  />
)

export const CompanyPhone = ({required, diff = {}, disabled}) => (
  <NumberFields
    texts={['', ' - ']}
    lengths={[4, 4, 4]}
    required={required}
    label="電話番号"
    prefix="phone_number"
    diff={_.get(diff, 'phone_number')}
    disabled={disabled}
  />
)

export const ExtensionPhone = ({required, diff = {}, disabled}) => (
  <TextField
    name="extension_number"
    label="内線番号"
    validate={[validators.maxLength(6), validators.number]}
    required={required}
    diff={diff.extension_number}
    disabled={disabled}
  />
)

export const Email = ({required, diff = {}}) => (
  <TextField
    name="email"
    label="メールアドレス"
    validate={[validators.maxLength(255), validators.email]}
    required={required}
    diff={diff.email}
  />
)

export const ClientName = ({label = '事業所名'}) => (
  <div>
    <TextField name="name" label={label} required validate={validators.maxLength(50)} />
    <TextField
      name="name_kana"
      label={`${label}（カナ）`}
      required
      validate={[validators.zenkakuKatakanaLoose, validators.maxLength(50)]}
    />
  </div>
)

export const CorporateNumber = () => (
  <TextField
    name="corporate_number"
    label="法人番号"
    validate={[validators.exactLengthNumber(13), validators.corporateNumber]}
  />
)

export const EmployeeNumber = ({diff = {}}) => (
  <TextField
    name="staff_code"
    label="スタッフコード"
    validate={[validators.maxLength(50), validators.staffCode]}
    note={`貴社のフォーマットにあわせてご記入ください。\n例：社員番号`}
    diff={diff?.staff_code}
  />
)

export const NationalType = ({required, diff = {}}) => (
  <RadioField
    name="national_type"
    label="外国人区分"
    options={nationalTypes}
    required={required}
    diff={getDiffFromOptions(nationalTypes, diff?.national_type)}
  />
)

export const RomajiName = ({required, diff = {}}) => (
  <div>
    <TextField
      name="romaji_name"
      label="ローマ字氏名"
      validate={[validators.romajiName, validators.maxLength(128)]}
      required={required}
      diff={diff.romaji_name}
    />
    <TextField
      name="romaji_name_kana"
      label="ローマ字氏名カナ"
      validate={[validators.zenkakuKatakanaWithSpace, validators.maxLength(64)]}
      required={required}
      diff={diff.romaji_name_kana}
    />
  </div>
)

export const RomajiNameReason = ({otherReason, required}) => (
  <div>
    <Label text="ローマ字氏名を持っていない理由" required={required} />
    <CheckboxFieldsBlock options={romaReasonOptions} />
    {otherReason && <TextField name="reason" label="理由" required={required} />}
  </div>
)

export const CheckboxFieldsBlock = ({label, options}) => (
  <div className={styles.boxWrap}>
    <Label text={label} />
    {options.map(({name, label}) => (
      <label key={`${name}_checkbox`} className="input-label">
        <Field component="input" type="checkbox" name={name} className="m-checkbox-input-block" />
        <span className="m-checkbox-parts-block">{label} </span>
      </label>
    ))}
  </div>
)

export const KanrentodokesyoSiyouSimei = (required) => (
  <SelectField
    name="kanrentodokesyo_siyou_simei"
    label="関連届書使用氏名"
    placeholder="関連届書使用氏名を選択してください"
    note={
      <p>
        関連届出書氏名は以下のように選択してください
        <br />
        既に基礎年金番号が発行されている場合
        <br />
        →「年金手帳または基礎年金番号通知書」に記載されている氏名に用いられているもの
        <br />
        基礎年金番号が発行されていない場合
        <br />
        →「住民票」に記載されている氏名に用いられているもの
      </p>
    }
    options={kanrentodokesyoSiyouSimei}
    required={required}
    validate={validators.requiredSelect}
  />
)

export const HasDependent = ({diff}) => (
  <RadioField
    name="has_dependent"
    label="扶養の有無"
    {...toBooleanProps}
    note={
      <p>
        扶養についての詳しい説明は
        <a
          href="https://jobcan-lms.zendesk.com/hc/ja/articles/360018787951-%E6%89%B6%E9%A4%8A%E3%81%A8%E3%81%AF"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
        をご覧ください。
      </p>
    }
    diff={getDiffFromOptions(toBooleanProps.options, diff?.has_dependent)}
  />
)

export const BasicPensionNumbers = ({diff = {}}) => (
  <NumberFields
    texts={['', ' - ']}
    lengths={[4, 6]}
    label="基礎年金番号"
    prefix="basic_pension_number"
    exactLength={true}
    diff={diff.basic_pension_number}
  />
)

export const Notes = () => <TextAreaField name="notes" label="備考" />

export const JoinedAt = ({diff, ...rest}) => (
  <DateField name="joined_at" label="入社日" example="例）1981/01/23、S56.1.23" diff={diff?.joined_at} {...rest} />
)

export const SubmitedOn = () => <DateField name="submit_on" label="書類提出日" />

export const HandicapType = ({handicapType, isEmployee = false, diff = {}}) => (
  <div>
    <RadioField
      name="handicap_type"
      label="障害者区分"
      options={handicapTypes}
      note={
        <p>
          納税者本人やその家族が障害者である場合、住民税や所得税の負担金額が少なくなります。（障害者控除は、扶養控除の適用外である「16歳未満の扶養親族」がいる場合でも適用されます。）詳しくは
          <a
            href="https://jobcan-lms.zendesk.com/hc/ja/articles/115001907492"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
        </p>
      }
      diff={getDiffFromOptions(handicapTypes, diff?.handicap_type)}
    />
    {handicapType && handicapType !== 'none' && (
      <>
        <TextField
          name="handicap_detail"
          label="詳細情報"
          note="障害者手帳を持っている場合は、その種類と交付年月日、障害の等級などを記入してください"
          diff={diff.handicap_detail}
        />
        {isEmployee ? (
          <FormSection name="employee_tax_classification">
            <FileField
              name="handicap_certificate"
              label="障害者手帳画像"
              diff={getDiffFromFile(diff.employee_tax_classification?.handicap_certificate)}
            />
          </FormSection>
        ) : (
          <FileField
            name="handicap_certificate"
            label="障害者手帳画像"
            diff={getDiffFromFile(diff.handicap_certificate)}
          />
        )}
      </>
    )}
  </div>
)

export const SubIncomeCheck = ({diff = {}}) => (
  <CheckboxField
    name="is_sub_income_report"
    label="従たる給与についての扶養控除等申告書の提出"
    note="通常はチェックを入れません。複数掛け持ちで2社以上から給料を受け取っている場合に提出する書類です。"
    noteWidth={320}
    diff={getDiffFromCheckbox(diff.is_sub_income_report)}
  />
)

export const Joined = ({outputsNote, diff}) => (
  <React.Fragment>
    <RadioField
      name="joined"
      label="加入状況"
      {...toBooleanProps}
      options={joinedOptions}
      diff={getDiffFromOptions(joinedOptions, diff?.joined?.toString())}
    />
    {outputsNote && <p className={styles.joinedNote}>加入手続きを行う場合「加入」を選択してください。</p>}
  </React.Fragment>
)

export const Representative = () => (
  <React.Fragment>
    <TextField validate={validators.maxLength(64)} name="rep_position_name" label="役職名" />
    <BoxDouble>
      <TextField name="rep_last_name" label="姓" />
      <TextField name="rep_first_name" label="名" />
    </BoxDouble>
  </React.Fragment>
)
