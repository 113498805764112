import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import styles from 'settings/Coordination.scss'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {recordDisplay} from 'utils'
import Button from 'jbc-front/components/Button'
import {PayrollLogo, Link as LinkIcon, UnLink} from 'jbc-front/components/icons'
import {isSync} from 'components/Coordination'
import _ from 'lodash'

class Coordination extends Component {
  state = {
    resetting: false,
  }

  loadData() {
    const {dispatch, token} = this.props
    dispatch(actionCreators.fetchData('jbc-token', api.createWithAuth(token).jbcToken.get()))
    dispatch(
      actionCreators.fetchData('payroll-sync-statuses', api.createWithAuth(token).clients.payroll.syncStatuses())
    )
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('jbc-token'))
  }

  render() {
    const {payrollSyncStatuses, client} = this.props
    return (
      <React.Fragment>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">サービス連携</h1>
        </div>
        <div
          className={`l-contents-wrap ${
            client.plan_type && client.plan_type !== 'free_plan' ? 'l-wrap-xs' : 'l-wrap-s'
          }`}
        >
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <span className="l-breadcrumb-here">サービス連携</span>
          </div>
        </div>
        {client.plan_type && client.plan_type !== 'free_plan' ? (
          <Payroll payrollSyncStatuses={payrollSyncStatuses} />
        ) : (
          <div className="l-wrap-s">
            <div className={styles.box}>
              <div className={styles.boxHead}>サービス連携</div>
              <div className={styles.boxBody}>
                <div>
                  <p>ジョブカンシリーズとのサービス連携は無料プランではご利用いただけません。</p>
                  <p>サービス連携の利用をご希望の場合は、以下より有料プランの申込を行ってください。</p>
                  <div className="u-mt50 u-mb20 u-ta-c">
                    <Button
                      success
                      large
                      onClick={() => {
                        window.open(`${process.env.JBC_ID_SERVER}/webform`, '_blank')
                      }}
                    >
                      有料プランの申込はこちら
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const Payroll = ({payrollSyncStatuses = {}}) => {
  return (
    <React.Fragment>
      <div className="l-wrap-s">
        <div className={styles.notes}>
          <div>
            <p className={styles.serviceName}>【ジョブカン給与計算】</p>
            <ul className={styles.buttons}>
              <li>
                <Button as={Link} to="/employees">
                  従業員情報
                </Button>
              </li>
              <li>
                <Button as={Link} to="/client">
                  事業所情報
                </Button>
              </li>
              {/*<li>
                <Button as={Link} to="/settings/groups">
                  組織情報
                </Button>
              </li>*/}
            </ul>
            <p>上記の情報を連携することができます</p>
          </div>
        </div>
        <div className="l-overflow-scroll">
          <table className={styles.table}>
            <thead>
              <tr>
                <td />
                <td>サービス名</td>
                <td>ステータス</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <a href="https://payroll.jobcan.ne.jp" rel="noopener noreferrer" target="_blank">
                    <PayrollLogo size={222} />
                  </a>
                </th>
                <td>
                  <a
                    href="https://payroll.jobcan.jp/users/sign_in"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="u-txt-link"
                  >
                    ジョブカン給与計算
                  </a>
                </td>
                <td>
                  {isSync() ? (
                    <React.Fragment>
                      <div className={styles.linked}>
                        <LinkIcon size={23} />
                        連携済
                      </div>
                      <div className={styles.linkedWrap}>
                        <dl className={styles.linkedDate}>
                          <dt>従業員情報の最終連携日時</dt>
                          <dd>{recordDisplay.date(_.get(payrollSyncStatuses, 'employee.synced_at'), 'LLL')}</dd>
                        </dl>
                        <dl className={styles.linkedDate}>
                          <dt>法人情報の最終連携日時</dt>
                          <dd>{recordDisplay.date(_.get(payrollSyncStatuses, 'client.synced_at'), 'LLL')}</dd>
                        </dl>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className={styles.nolink}>
                      <UnLink size={23} />
                      未連携
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  )
}
export default connect((state) => ({
  payrollSyncStatuses: fetchSelector(state, 'payroll-sync-statuses').data,
  token: state.auth.token,
  client: state.client.current,
}))(Coordination)
