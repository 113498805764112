import gql from 'graphql-tag'

export type DetailInputRequestStatus = 'none' | 'applying' | 'rejected' | 'accepted' | 'canceled'
export type DetailInputRequestType = 'InitialInput' | 'DirectRequest' | 'UpdateProfile'
export type SortColumn = 'status' | 'staff_code' | 'employee_name' | 'created_at' | 'updated_at'

export type QueryVariables = {
  page: number
  per: number
  search: Search
}

export type Search = {
  filter: DetailInputRequestStatus[]
  sortColumn: SortColumn
  sortOrder: 'asc' | 'desc'
  q?: string
}

export type QueryResult = {
  client: {
    id: number
    detailInputRequests: {
      list: DetailInputRequest[]
      totalCount: number
    }
  }
}

export type DetailInputRequest = {
  id: number
  status: DetailInputRequestStatus
  type: DetailInputRequestType
  createdAt: string
  updatedAt: string
  employee: Employee
}

export type Employee = {
  id: number
  staffCode: string
  displayFirstName: string
  displayLastName: string
  personnelHistory?: PersonnelHistory
}

export type PersonnelHistory = {
  group0Name?: string
  group1Name?: string
  group2Name?: string
}

export const DETAIL_INPUT_REQUESTS = gql`
  query DetailInputRequests($per: Int, $page: Int, $search: DetailInputRequestSearchInput) {
    client {
      id
      detailInputRequests(per: $per, page: $page, search: $search) {
        totalCount
        list {
          id
          status
          type
          createdAt
          updatedAt
          employee {
            id
            staffCode
            displayFirstName
            displayLastName
            personnelHistory {
              group0Name
              group1Name
              group2Name
            }
          }
        }
      }
    }
  }
`
