import React from 'react'
import {gql} from '@apollo/client'
import ActionButton from 'jbc-front/components/ActionButton'
import {Revert} from 'jbc-front/components/icons'
import {DYNAMIC_DOCUMENT_ASYNC_TASK_FIELDS_FRAGMENT, DynamicDocumentAsyncTaskFields} from './Query'
import styles from './DynamicDocumentAsyncStatus.scss'

import Loading from 'components/Loading'
// @ts-ignore
import {useMutation} from 'components/Graphql'
// @ts-ignore
import {recordDisplay} from 'utils'

const ARCHIVE_DYNAMIC_DOCUMENT = gql`
  mutation archiveDynamicDocument($input: ArchiveDynamicDocumentInput!) {
    archiveDynamicDocument(input: $input) {
      document {
        id
        dynamicDocument {
          id
          ...DynamicDocumentAsyncTaskFields
        }
      }
    }
  }
  ${DYNAMIC_DOCUMENT_ASYNC_TASK_FIELDS_FRAGMENT}
`

const asyncStatusTitle = (dynamicDocument: DynamicDocumentAsyncTaskFields) => {
  switch (dynamicDocument.status) {
    case 'waiting':
    case 'in_progress':
      if (dynamicDocument.shouldUpdateVariableSnapshot) {
        if (dynamicDocument.generatedAt) {
          return '書類を更新しています。'
        } else {
          return '書類を作成しています。'
        }
      } else {
        return '印刷・ダウンロードの準備をしています。'
      }
    case 'failed':
      if (dynamicDocument.shouldUpdateVariableSnapshot) {
        if (dynamicDocument.generatedAt) {
          return '書類の更新中にエラーが発生しました。情報更新はまだ反映されていません。'
        } else {
          return '書類の作成中にエラーが発生しました。'
        }
      } else {
        return '印刷・ダウンロードの準備中にエラーが発生しました。作成した書類の印刷・ダウンロードを行う場合は「再試行」をクリックしてください。'
      }
  }
  return
}

interface DynamicDocumentAsyncStatusProps {
  id: string
  dynamicDocument: DynamicDocumentAsyncTaskFields
}

const DynamicDocumentAsyncStatus: React.FC<DynamicDocumentAsyncStatusProps> = ({id, dynamicDocument}) => {
  const [archiveDynamicDocument] = useMutation(ARCHIVE_DYNAMIC_DOCUMENT)
  const retry = async () => {
    await archiveDynamicDocument({variables: {input: {id}}})
  }
  switch (dynamicDocument.status) {
    case 'waiting':
    case 'in_progress':
      return (
        <div className={styles.asyncStatusInProgress}>
          <div className={styles.asyncStatusInner}>
            <div className={styles.asyncStatusLoading}>
              <Loading />
            </div>
            <div className={styles.asyncStatusInfo}>
              <p className={styles.asyncStatusTitle}>{asyncStatusTitle(dynamicDocument)}</p>
              <div className={styles.asyncStatusDates}>
                <dl>
                  <dt>開始日時：</dt>
                  <dd>{recordDisplay.date(dynamicDocument.startedAt, 'LLL')}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )
    case 'failed':
      return (
        <div className={styles.asyncStatusError}>
          <div className={styles.asyncStatusInner}>
            <div className={styles.asyncStatusInfo}>
              <p className={styles.asyncStatusTitle}>{asyncStatusTitle(dynamicDocument)}</p>
              <div className={styles.asyncStatusDates}>
                <dl>
                  <dt>開始日時：</dt>
                  <dd>{recordDisplay.date(dynamicDocument.startedAt, 'LLL')}</dd>
                </dl>
              </div>
              <div className={styles.asyncStatusActions}>
                <ActionButton icon={<Revert />} onClick={() => retry()}>
                  再試行
                </ActionButton>
              </div>
            </div>
          </div>
        </div>
      )
    default:
      return null
  }
}

export default DynamicDocumentAsyncStatus
