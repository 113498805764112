import React from 'react'
import Flow from 'procedures/Flow'
import _ from 'lodash'
import {getAddedDependents} from 'procedures/changeDependents/utils'

export const makeFlow = function* (employee, procedureStatus, flowStatus) {
  if (_.isEmpty(employee) || _.isEmpty(employee.office) || _.isEmpty(procedureStatus)) {
    return
  }
  const healthInsuranceJoined = _.get(employee, 'health_insurance.joined')
  const welfarePensionInsuranceJoined = _.get(employee, 'welfare_pension_insurance.joined')
  const healthInsuranceType = _.get(employee, 'office.health_insurance_type')
  const dependents = getAddedDependents(employee.employee_dependents, procedureStatus)
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')
  const others = dependents.filter((dependent) => dependent.relation_type !== 'spouse')

  if (healthInsuranceJoined) {
    if (healthInsuranceType === 'its' && !flowStatus.skipItsDependentsReports) {
      if (spouse) {
        yield `R150_${spouse.id}`
      }
      yield* others.map((dependent) => `R150_${dependent.id}`)
    }
    if (healthInsuranceType === 'its') {
      yield 'R5'
    } else {
      yield 'R4'
    }
  }
  if (welfarePensionInsuranceJoined && spouse && spouse.national_type === 'foreigner') {
    yield 'R56'
  }
  return
}

const AddDependentFlow = ({location, match}) => (
  <Flow {...{makeFlow, location, match}} embed="procedure_add_dependents_data" />
)

export default AddDependentFlow
