import List from 'procedureStatuses/List'
import Show from 'procedureStatuses/Show'
import ReportEdit from 'procedureStatuses/ReportEdit'
import ListNotAdmin from 'procedureStatuses/ListNotAdmin'
import R60ImportPayments from 'procedureStatuses/R60ImportPayments'

export default {
  List,
  Show,
  ReportEdit,
  ListNotAdmin,
  R60ImportPayments,
}
