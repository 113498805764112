import React from 'react'
import {ListItem, LinkIcon, ListGroup} from 'components/ListGroup'
import _ from 'lodash'
import {useSelector} from 'hooks/redux'
import {getMainOfficeContactInfo} from 'offices/utils'
import styles from 'dashboard/dashboard.scss'
import {isLimitedAdminSelector} from 'utils'
import {useFetchEmployees} from 'hooks/api/employees'
import {useFetchEmploymentTypes} from 'hooks/api/employmentTypes'
import {useFetchGroups} from 'hooks/api/groups'
import {useFetchOccupations} from 'hooks/api/occupations'
import {useFetchPositions} from 'hooks/api/positions'

const setups = {
  client: {
    title: '事業所の登録',
    text: '事業所の住所や健康保険、厚生年金の情報を入力します',
    url: '/client/edit',
  },
  employment_types: {
    title: '組織情報の設定',
    text: '雇用形態、職種、グループ、役職の登録を行います',
    url: '/settings/employment_types',
  },
  employees: {
    title: '従業員の登録',
    text: '新しい従業員の登録を行います',
    url: '/employees/choose_add_method',
  },
}

const getCurrentSetupKeys = function* ({
  currentOffice,
  employeeCount,
  employmentTypes,
  occupations,
  groups,
  positions,
}) {
  const mainOfficeSocial = getMainOfficeContactInfo(currentOffice)

  if (!_.isEmpty(mainOfficeSocial)) {
    if (
      _.some(['name', 'name_kana'], (key) => !mainOfficeSocial[key]) ||
      !_.get(currentOffice, 'health_insurance_type')
    ) {
      yield 'client'
    }
  }
  if (
    [
      [employmentTypes, 4],
      [occupations, 0],
      [groups, 0],
      [positions, 0],
    ].every(([e, l]) => e && e.length <= l)
  ) {
    yield 'employment_types'
  }
  if (employeeCount !== null && employeeCount <= 1) {
    yield 'employees'
  }
}

const getCurrentSetups = (props) => _.pick(setups, [...getCurrentSetupKeys(props)])

export const Setups = () => {
  const currentOffice = useSelector((state) => state.client.current.office)
  const isLimitedAdmin = useSelector(isLimitedAdminSelector)

  const fetchEmployees = useFetchEmployees({limit: 1})
  const fetchEmploymentTypes = useFetchEmploymentTypes()
  const fetchOccupations = useFetchOccupations()
  const fetchGroups = useFetchGroups()
  const fetchPositions = useFetchPositions()

  const loading =
    fetchEmployees.isLoading ||
    fetchEmploymentTypes.isLoading ||
    fetchOccupations.isLoading ||
    fetchGroups.isLoading ||
    fetchPositions.isLoading
  const showSetups = !isLimitedAdmin && !loading
  const currentSetups =
    showSetups &&
    getCurrentSetups({
      currentOffice,
      employeeCount: fetchEmployees.isLoading ? null : fetchEmployees.totalCount,
      employmentTypes: fetchEmploymentTypes.resource,
      occupations: fetchOccupations.resource,
      groups: fetchGroups.resource,
      positions: fetchPositions.resource,
    })
  return (
    <article>
      {showSetups && !_.isEmpty(currentSetups) && (
        <div className={styles.setups}>
          <h2>まずは必要なセットアップを完了しましょう</h2>
          <div>
            <ListGroup className={styles.grid_setups_list}>
              {_.map(currentSetups, (setup, key) => (
                <ListItem to={setup.url} key={key} className={styles.list}>
                  <dl className={styles.setup}>
                    <dt className={styles.setupTitle}>{setup.title}</dt>
                    <dd className={styles.setupText}>{setup.text}</dd>
                  </dl>
                  <LinkIcon className={styles.setupsIcons} color="#fff" size={16} />
                </ListItem>
              ))}
            </ListGroup>
          </div>
        </div>
      )}
    </article>
  )
}
