import {FC} from 'react'
import {Document} from '../../../query'
import {Download} from './Download'
import {DynamicDocumentPreviewModal} from './DynamicDocumentPreviewModal'
import {UploadedDocumentPreviewModal} from './UploadedDocumentPreviewModal'

interface PreviewColumnProps {
  employeeId: number
  document: Document
}

export const PreviewColumn: FC<PreviewColumnProps> = ({document, employeeId}) => {
  switch (document.type) {
    case 'dynamic_document':
      return <DynamicDocumentPreviewModal document={document} employeeId={employeeId} />
    case 'uploaded_document':
      if (document.uploadedDocument.mimeType === 'application/pdf') {
        return <UploadedDocumentPreviewModal document={document} />
      } else {
        return <Download document={document} employeeId={employeeId} />
      }
      return null
  }
}
