import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {push} from 'connected-react-router'
import Modal from 'jbc-front/components/Modal'
import {Delete as DeleteIcon} from 'jbc-front/components/icons'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import styles from 'tm/notifications/Delete.scss'

const Delete = ({id}) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const [showModal, setShowModal] = useState(false)
  const onSubmit = async () => {
    try {
      await api.createWithAuth(token).tm.notifications.delete(id)
      dispatch(push(`/tm/notifications/sent`))
      dispatch(notifySuccess('削除しました'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <div className={styles.wrap}>
      <Modal
        header="削除確認"
        body={
          <p>
            通知を削除すると、既に返信された内容も確認できなくなりますのでご注意ください。
            <br />
            <span className={styles.warningText}>本当に削除しますか？</span>
          </p>
        }
        onSubmit={onSubmit}
        isOpen={showModal}
        hideModal={() => setShowModal(false)}
        submit="はい"
        cancel="いいえ"
      />
      <div className={styles.delete} onClick={() => setShowModal(true)}>
        <span className={styles.icon}>
          <DeleteIcon size={14} />
        </span>
        <span className={styles.text}>通知を削除</span>
      </div>
    </div>
  )
}

export default Delete
