import List from 'employees/List'
import Show from 'employees/Show'
import Update from 'employees/Update'
import Create from 'employees/Create'
import ChooseAddMethod from 'employees/ChooseAddMethod'
import FileImport from 'employees/FileImport'
import FileImportUpdate from 'employees/FileImportUpdate'
import Invite from 'employees/Invite'
import PersonnelHistory from 'employees/PersonnelHistory'
import VisaHistory from 'employees/VisaHistory'
import auditLogs from 'employees/auditLogs'
import CsvFormat from 'employees/CsvFormat'
import Confirm from 'employees/Confirm'
import CoordinationTargets from 'employees/CoordinationTargets'
import DownloadRoster from 'employees/DownloadRoster'
import YearEndAdjCoordinationTargets from 'employees/YearEndAdjCoordinationTargets'

export default {
  List,
  Show,
  Update,
  Create,
  ChooseAddMethod,
  FileImport,
  FileImportUpdate,
  Invite,
  PersonnelHistory,
  VisaHistory,
  auditLogs,
  CsvFormat,
  Confirm,
  CoordinationTargets,
  DownloadRoster,
  YearEndAdjCoordinationTargets,
}
