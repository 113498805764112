import {FC, ReactNode} from 'react'

interface FileNameLinkProps {
  url: string
  children: ReactNode
}

export const FileNameLink: FC<FileNameLinkProps> = ({url, children}) => (
  <a href={url} className="u-txt-link" target="_blank" rel="noopener noreferrer" download>
    {children}
  </a>
)
