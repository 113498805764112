import React from 'react'
import {Link} from 'react-router-dom'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {EmployeeFieldGroupPermission} from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupPermission'
import {toColor} from 'settings/employeeCustom/ColorSelect'
import styles from 'settings/employeeCustom/FieldGroups/EmployeeFieldGroupItem.scss'
import {Edit} from 'jbc-front/components/icons'
import formStyles from 'jbc-front/components/Form.scss'

export const EmployeeFieldGroupItem = ({employeeFieldGroup, loadData, isEmployee}) => {
  const {description, description_color_by_rgb} = employeeFieldGroup?.setting || {}
  return (
    <Panel>
      <a
        className={isEmployee ? styles.anchorLink : styles.anchorLinkNonEmployees}
        id={`field_default_group_${employeeFieldGroup.id}`}
      ></a>
      <Headding
        icon={
          <div className={styles.employeeFieldButtons}>
            <Link to={`/settings/employee_custom/${employeeFieldGroup.id}`}>
              <Edit size={20} className={styles.editIcon} />
            </Link>
            <EmployeeFieldGroupPermission field={employeeFieldGroup} loadData={loadData} />
          </div>
        }
      >
        {employeeFieldGroup.label}
      </Headding>
      <Item>
        <div className={formStyles.boxMain}>
          {description && (
            <div
              className={styles.description}
              style={{
                color: toColor(description_color_by_rgb),
              }}
            >
              {description}
            </div>
          )}
        </div>
      </Item>
    </Panel>
  )
}
