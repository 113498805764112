import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {Redirect} from 'react-router'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import _ from 'lodash'
import {formMap} from 'procedureStatuses/ReportEdit'
import {parse} from 'query-string'
import Completed from 'procedures/enroll/EmployeeInputCompleted'
import {reflectEnrollmentInputToken} from 'libs/enrollmentInputToken'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const NOT_LOGIN_PATH = '/enrollment_employee_input_dependents/:id'

class InputDependents extends Component {
  static defaultProps = {
    tmpData: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      querying: true,
      valid: null,
      error: false,
      formIndex: -1,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      loadProcedureStatus,
      loadTmpData,
      loadComments,
    } = this.props
    loadProcedureStatus(id, token)
    loadTmpData(id, token)
    loadComments(id, token)
  }

  componentDidUpdate() {
    const {
      location: {search},
    } = this.props
    const {dependent_index} = parse(search)
    const dependentIndex = dependent_index | 0
    if (+dependentIndex !== this.state.formIndex) {
      this.setState({formIndex: dependentIndex})
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleFormSubmit = (values, comment) => {
    const {
      saveAndNext,
      location: {search},
      match: {
        params: {id},
        path,
      },
      tmpData,
      token,
    } = this.props
    const {dependent_index} = parse(search)
    const {dependents} = tmpData.employee ? JSON.parse(tmpData.employee.data) : {dependents: []}
    return saveAndNext(
      id,
      token,
      values,
      comment,
      +dependent_index,
      +dependent_index + 1 >= dependents.length,
      path !== NOT_LOGIN_PATH
    )
  }

  render() {
    const {
      tmpData,
      loading,
      location: {search},
      match: {
        params: {id},
      },
      path,
      procedureStatus,
      comments,
    } = this.props
    const {enrollment_input_token: token, dependent_index} = parse(search)
    const dependentIndex = dependent_index | 0
    const isLogin = path !== NOT_LOGIN_PATH
    if (loading || !tmpData.employee) {
      return null
    }
    if (!procedureStatus.id) {
      return null
    }

    if (['applying', 'accepted'].includes(procedureStatus.application_status)) {
      return <Completed afterInput />
    }
    const values = tmpData.employee ? JSON.parse(tmpData.employee.data) : {}
    const dependents = values.dependents
    if (_.isEmpty(dependents)) {
      return (
        <Redirect
          to={
            isLogin
              ? `/enroll/employee_input/${id}`
              : `/enrollment_employee_input/${id}?enrollment_input_token=${token}`
          }
        />
      )
    }
    if (dependentIndex > dependents.length || dependentIndex < 0) {
      return (
        <Redirect
          to={
            isLogin
              ? `/enroll/employee_input_dependents/${id}?dependent_index=0`
              : `/enrollment_employee_input_dependents/${id}?enrollment_input_token=${token}&dependent_index=0`
          }
        />
      )
    }
    const dependent = dependents[dependentIndex]
    const reportType = 'R150'
    const formName = 'R150Report'
    const Report = formMap[reportType]
    const isFinal = dependentIndex + 1 >= dependents.length
    const dependent_info = JSON.parse(_.get(tmpData, `dependent_info_${dependentIndex}.data`, '{}'))
    let initialValues = {
      ...dependent_info,
      reportType,
    }
    if (!dependent_info.application_reason_type) {
      initialValues.application_reason_type = 'entrance'
    }
    if (dependentIndex !== this.state.formIndex) return null
    return (
      <CommentModal
        formName={formName}
        procedureType="enroll"
        onSubmit={({values, comment}) => {
          this.handleFormSubmit(values, comment)
        }}
        comments={comments || []}
      >
        {({showModal}) => (
          <Report
            key={dependentIndex}
            onSubmit={isFinal ? showModal : this.handleFormSubmit}
            submitText={isFinal ? '完了' : '次へ'}
            skipLoad
            name={`${dependent.last_name} ${dependent.first_name}`}
            relation_type={dependent.relation_type}
            relation_other={dependent.relation_other}
            comments={comments || {}}
            initialValues={initialValues}
          />
        )}
      </CommentModal>
    )
  }
}

export default InputDependents
  |> connect(
    (state) => ({
      token: state.auth.token,
      tmpData: state.procedureStatuses.tmpData.data,
      comments: fetchSelector(state, 'procedure_comments').data,
      loading: state.procedureStatuses.current.loading || state.procedureStatuses.tmpData.loading,
      procedureStatus: state.procedureStatuses.current.data,
    }),
    (dispatch) => ({
      loadProcedureStatus(id, token) {
        dispatch(
          actionCreators.procedureStatuses.current.fetchData(api.createWithAuth(token).procedureStatuses.get(id))
        )
      },
      destroy() {
        dispatch(actionCreators.procedureStatuses.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      loadTmpData(procedureStatusId, token) {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            api.createWithAuth(token).procedureStatuses.tmpData.mapByName(procedureStatusId, 'application')
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      async saveAndNext(procedureStatusId, token, values, comment, dependentIndex, isFinal, isLogin) {
        try {
          const authedApi = api.createWithAuth(token)
          await authedApi.procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            values,
            'application',
            `dependent_info_${dependentIndex}`
          )
          if (isFinal) {
            await authedApi.procedureStatuses.apply(procedureStatusId, comment)
            dispatch(push(isLogin ? '/dashboard' : '/enrollment_employee_input_completed'))
            dispatch(notifySuccess('提出しました'))
          } else {
            dispatch(
              push(
                isLogin
                  ? `/enroll/employee_input_dependents/${procedureStatusId}?dependent_index=${dependentIndex + 1}`
                  : `/enrollment_employee_input_dependents/${procedureStatusId}?enrollment_input_token=${token}&dependent_index=${
                      dependentIndex + 1
                    }`
              )
            )
            dispatch(notifySuccess('保存しました'))
            window.scrollTo(0, 0)
          }
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
  |> reflectEnrollmentInputToken(NOT_LOGIN_PATH)
