import {useEffect, useState} from 'react'
import {MainTitle} from 'components/layout/MainTitle'
import {Content} from 'components/layout/Content'
import {Search} from 'components/ui/Search'
import {PerPage} from 'components/ui/PerPage'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import styles from './Presenter.scss'
import moment from 'moment'

// jbc-front
import Tag from 'jbc-front/components/Tag'
import Paginator from 'jbc-front/components/Paginator'
import {EgovProcedureStatuses} from 'consts/egovProcedureStatuses'

// parts
import {List} from './parts/List'
import {ApplyModal} from './parts/ApplyModal'
import {DeleteModal} from './parts/DeleteModal'

import type {FC} from 'react'
import type {EgovCertificateFile, EgovProcedure, EgovAccount} from './query'
import type {Refetch} from './index'

interface PresenterProps {
  defaultLimit: number
  totalCount?: number
  egovProcedures?: EgovProcedure[]
  egovCertificateFile?: EgovCertificateFile
  egovAccount?: EgovAccount
  refetch: Refetch
}

interface EgovProcedureStatuse {
  label: 'すべて' | '準備中' | '申請中' | '取下げ中' | 'エラー' | '完了'
  value: EgovProcedureStatuses[]
}

// フィルター関連
const EGOV_PROCEDURE_STATUSES: EgovProcedureStatuse[] = [
  {
    label: 'すべて',
    value: [],
  },
  {
    label: '準備中',
    value: ['PREPARING'],
  },
  {
    label: '申請中',
    value: ['PROCESSING', 'ARRIVED', 'UNDER_REVIEW', 'REVIEW_COMPLETED', 'UNDER_REVIEW_WAIT_CORRECTION'],
  },
  {
    label: '取下げ中',
    value: ['WITHDRAW_PROCESSING', 'WITHDRAW_ARRIVED', 'WITHDRAW_UNDER_REVIEW'],
  },
  {
    label: 'エラー',
    value: ['INADEQUACY_ERROR'],
  },
  {
    label: '完了',
    value: [
      'PROCEDURE_COMPLETED',
      'WITHDRAW_PROCEDURE_COMPLETED',
      'PROCEDURE_COMPLETED_RETURNABLE',
      'PROCEDURE_COMPLETED_RESUBMITTED',
    ],
  },
]

const EGOV_SERVICE_OUTAGE_START = '2024-07-17 00:00'
const EGOV_SERVICE_OUTAGE_END = '2024-08-15 23:59'

export const Presenter: FC<PresenterProps> = ({
  defaultLimit,
  totalCount = 0,
  egovProcedures,
  egovCertificateFile,
  egovAccount,
  refetch,
}) => {
  // Paginator関連
  const [limit, setLimit] = useState<number>(defaultLimit)
  const handleChangeLimit = (limit: number) => {
    setLimit(limit)
    setPaginatorState((prevPaginatorState) => ({
      ...prevPaginatorState,
      current: 1,
      rowsPerPage: limit,
    }))
    refetch({page: 1, limit})
  }
  const [paginatorState, setPaginatorState] = useState(() => {
    return {
      current: 1,
      totalResult: totalCount,
      rowsPerPage: limit,
      pageLinks: 7,
    }
  })
  useEffect(() => {
    setPaginatorState((prevPaginatorState) => ({...prevPaginatorState, totalResult: totalCount}))
  }, [totalCount])
  const handleChangePaginator = (page: number) => {
    const newPaginatorState = {...paginatorState, current: page}
    setPaginatorState(newPaginatorState)
    refetch({page})
  }

  const [isActiveStatus, setIsActiveStatus] = useState<EgovProcedureStatuse>({
    label: 'すべて',
    value: [],
  })
  const handleTagClick = (status: EgovProcedureStatuse) => {
    setIsActiveStatus(status)
    refetch({statuses: status.value})
  }

  // モーダル関連
  const [applyTarget, setApplyTarget] = useState<EgovProcedure | undefined>()
  const [isApplyModalOpen, setIsApplyModalOpen] = useState<boolean>(false)
  const handleOpenApplyModal = (egovProcedure: EgovProcedure) => {
    setApplyTarget(egovProcedure)
    setIsApplyModalOpen(true)
  }
  const handleCloseApplyModal = () => {
    setApplyTarget(undefined)
    setIsApplyModalOpen(false)
  }
  const [deleteTarget, setDeleteTarget] = useState<string | undefined>()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const handleOpenDeleteModal = (egovProcedureId: string) => {
    setDeleteTarget(egovProcedureId)
    setIsDeleteModalOpen(true)
  }
  const handleCloseDeleteModal = () => {
    setDeleteTarget(undefined)
    setIsDeleteModalOpen(false)
  }

  const isEgovServiceOutage = moment().isBetween(EGOV_SERVICE_OUTAGE_START, EGOV_SERVICE_OUTAGE_END, 'minute', '[]')

  return (
    <>
      <MainTitle title="電子申請一覧">
        <span className="m-title-main-note">
          「申請準備中」の電子申請のステータスをクリックし、電子申請を行ってください。詳しくは
          <a
            className="u-txt-link"
            href="https://jobcan-lms.zendesk.com/hc/ja/sections/360000223271"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          <>
            <br />
            e-Govアカウント連携は
            <a href={`${WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.ACCOUNT.SHOW}`} className="u-txt-link">
              こちら
            </a>
          </>
        </span>

        {isEgovServiceOutage && (
          <div className="u-mt20">
            <span className={styles.alertText}>
              2024年8月9日（金）19時00分～2024年8月13日（火）08時00分の期間、e-Govの全サービスが停止します。
            </span>
            詳しくは
            <a
              target="_blank"
              href="https://www.e-gov.go.jp/news/2024-07-12t1551310900_871.html"
              className="u-txt-link"
              rel="noopener"
            >
              こちら
            </a>
          </div>
        )}
      </MainTitle>
      <Content size="xxl">
        <div className={styles.filterContainer}>
          <div className={styles.statusFilterContainer}>
            {EGOV_PROCEDURE_STATUSES.map((status, i) => (
              <Tag
                key={`status-filter-${i}`}
                active={isActiveStatus?.label == status.label}
                onClick={() => handleTagClick(status)}
              >
                {status.label}
              </Tag>
            ))}
          </div>
          <Search
            onSubmit={(value?: string) => refetch({keyword: value})}
            placeholder={'スタッフコード、氏名、メール'}
          />
        </div>
        <List
          onOpenDeleteModal={handleOpenDeleteModal}
          onOpenApplyModal={handleOpenApplyModal}
          egovProcedures={egovProcedures}
        />
        <div className="m-pager-area">
          <Paginator {...paginatorState} onClick={handleChangePaginator} />
          <PerPage onChange={handleChangeLimit} />
        </div>
      </Content>

      {applyTarget && (
        <ApplyModal
          isOpen={isApplyModalOpen}
          onClose={handleCloseApplyModal}
          egovProcedure={applyTarget}
          egovCertificateFile={egovCertificateFile}
          egovAccount={egovAccount}
          refetch={refetch}
        />
      )}

      {deleteTarget && (
        <DeleteModal
          egovProcedureId={deleteTarget}
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          refetch={refetch}
        />
      )}
    </>
  )
}
