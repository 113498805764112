import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'

export const useDownloadDocument = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()
  return async (documentId: number) => {
    const path = `/api/employees/documents/${documentId}`
    const getter = () => authAxios.get(path, {responseType: 'arraybuffer'})

    download(getter)
  }
}
