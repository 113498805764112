import {useFetchData} from 'hooks/api/useFetchData'
import {ClientFiles} from 'types/api/settings/clientFiles/clientFiles'

interface ResponseHeader {
  'x-displays-see-more-proc'?: string
  'x-displays-see-more-not-proc'?: string
}

type Query = Partial<{
  sort: 'created_at' | 'file_name' | 'uploader_name' | null
  order: 'asc' | 'desc' | null
  see_more_belongs_to_procedure: boolean | null
  see_more_not_belongs_to_procedure: boolean | null
}>

export const useFetchClientFiles = (query?: Query) => {
  const path = '/api/clients_files'
  return useFetchData<ClientFiles, ResponseHeader>(
    path,
    {},
    {
      defaultHeaders: {},
      params: query,
      immediate: false,
    }
  )
}
