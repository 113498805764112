import React, {Component} from 'react'
import onClickOutside from 'react-onclickoutside'
import styles from 'components/Balloon.scss'
import _ from 'lodash'

class Balloon extends Component {
  state = {
    show: false,
  }

  hide = () => {
    this.setState({show: false})
  }

  toggle = () => {
    this.setState((state) => ({show: !state.show}))
  }

  render() {
    const {switchRender, children, right, center} = this.props
    return (
      <div className={styles.switch}>
        {switchRender(this.toggle, this.state.show)}
        {this.state.show && (
          <BalloonContent hide={this.hide} right={right} center={center}>
            {children && _.isFunction(children) ? children(this.hide) : children}
          </BalloonContent>
        )}
      </div>
    )
  }

  static Ul = ({children, ...rest}) => <ul {...rest}>{children}</ul>
  static Li = ({children, ...rest}) => (
    <li className={styles.li} {...rest}>
      {children}
    </li>
  )
}

const BalloonContent = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide()
    }
    render() {
      const {center, right} = this.props
      return <div className={(right && styles.right) || (center && styles.center)}>{this.props.children}</div>
    }
  }
)

export default Balloon
