import {FC} from 'react'
import {useDispatch} from 'react-redux'
import Loading from 'components/Loading'
import {asyncError} from 'store/actions/asyncError'
import {Result} from './parts/Result'

interface PresenterProps {
  isLoading: boolean
  isValid?: boolean
  errors?: Error
}

export const Presenter: FC<PresenterProps> = ({isLoading, isValid, errors}) => {
  const dispatch = useDispatch()

  if (errors) {
    dispatch(asyncError(errors))
  }

  return (
    <>
      <div className="l-title-wrap">
        <h1 className="m-title-main">アカウントメールアドレス確認画面</h1>
      </div>
      <div className="l-wrap-xs">
        <div className="l-box-message">{isLoading ? <Loading /> : <Result isValid={isValid} errors={errors} />}</div>
      </div>
    </>
  )
}
