import React from 'react'
import {relationshipOtherTypes} from 'employees/form/Dependents'
import styles from 'procedureStatuses/ReportList.scss'
import {Headding} from 'components/SecondaryPanel'
import {MenuEllipsis} from 'jbc-front/components/icons'
import NProgress from 'nprogress'
import axios from 'axios'
import ListGroup from 'jbc-front/components/ListGroup'

export const relationTypes = {
  CHILD: 'child',
  PARENT: 'parent',
  OTHER: 'other',
}

export const getRelationType = (dependent) => {
  const {relation_other} = dependent
  if (['長男', '長女', '次男', '次女', '三男', '三女'].includes(relation_other)) {
    return relationTypes.CHILD
  }
  if (['実父', '実母', '義父', '義母'].includes(relation_other)) {
    return relationTypes.PARENT
  }
  if (dependent.relation_report_type && !relationshipOtherTypes.find((relation) => relation.value === dependent)) {
    return ['father', 'mother'].includes(dependent.relation_report_type)
      ? relationTypes.PARENT
      : dependent.relation_report_type
  }
  return relationTypes.OTHER
}

const downloadPdfs = async (pdfList, procedureTitle) => {
  NProgress.start()
  const {default: download} = await import(
    /* webpackChunkName: "download-pdfs" */ 'procedureStatuses/reportList/downloadPdfs'
  )
  await download(pdfList, procedureTitle, NProgress, axios)
}

export const menuItems = (reportList, title, onAsyncError) => [
  {
    onClick: () => {
      downloadPdfs(reportList, title).catch((err) => {
        NProgress.done()
        onAsyncError(err)
      })
    },
    label: 'まとめてダウンロード',
  },
]
export const toggle = ({onClick}) =>
  onClick ? (
    <a className={styles.headdingWrap} onClick={onClick}>
      <Headding icon={onClick ? MenuEllipsis : undefined}>関連書類</Headding>
    </a>
  ) : (
    <Headding icon={onClick ? MenuEllipsis : undefined}>関連書類</Headding>
  )

export const Report = ({onClick, title, num, edit = true, dependentId}) => (
  <ListGroup.Item as="a" href="javascript:void(0)" onClick={onClick.bind(null, {num, title, edit, dependentId})}>
    {title}
  </ListGroup.Item>
)

export const HEALTH_AND_WELFARE_PENSION = 1
export const EMPLOYMENT = 2
export const OTHER = 3
