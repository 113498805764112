import {actionCreators} from 'actions'
import {connectPager} from 'components/paginators'
import {connectFilterTag} from 'components/Tags'
import {connectSearch} from 'components/Search'
import {connectPerPage} from 'components/PerPage'
import connectListData from 'components/ListData'
import {getQueryDiff} from 'employees/reducers'
import {connectOrderedTh} from 'components/OrderedTh'
import api from 'api'

const querySelector = (state) => state.employees.query
const queryActionCreator = actionCreators.employees.setQuery

export const Filter = connectFilterTag(querySelector, queryActionCreator)
export const Pager = connectPager(querySelector, queryActionCreator)
export const Search = connectSearch(querySelector, queryActionCreator)
export const PerPage = connectPerPage(querySelector, queryActionCreator)
export const loadList = (token, query, embed, params) => api.createWithAuth(token).employees.list(query, embed, params)
export const ListData = connectListData(querySelector, actionCreators.employees, loadList, getQueryDiff)
export const OrderedTh = connectOrderedTh(querySelector, queryActionCreator)
