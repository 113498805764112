import React from 'react'
import {Field} from 'redux-form'
import {connect} from 'react-redux'
import {Description, withFormSelectors} from 'employees/form/common'
import {Section, Label, Diff} from 'jbc-front/components/Form'
import {WithContext as ReactTags} from 'react-tag-input'
import 'employees/form/EmployeeTag.scss'

const makeReactTagsValues = (employee_tags) => (employee_tags || []).map(({name}) => ({id: name, text: name}))

const formatValues = (values) => values.map(({id: tag_id, text: name}) => ({tag_id, name}))

const renderTagField = ({input: {onChange, value}, diff = {}}) => {
  const tags = makeReactTagsValues(value)

  const handleAddition = ({id, text}) => {
    if (tags.length < 10) {
      onChange(formatValues([...tags, {id, text}]))
    }
  }

  const handleDelete = (i) => {
    onChange(formatValues(tags.filter((_tag, index) => index !== i)))
  }

  const employeeTags = diff.employee_tags || {}

  return (
    <div>
      <Label diff={employeeTags.name} />
      <ReactTags
        tags={tags}
        inputFieldPosition="top"
        placeholder="タグとする文字を入力して「Enter」キーを押してください"
        autofocus={false}
        allowDragDrop={false}
        allowDeleteFromEmptyInput={false}
        allowAdditionFromPaste={false}
        handleAddition={handleAddition}
        handleDelete={handleDelete}
        maxLength={20}
      />
      <div className="u-mt5">
        <Diff diff={employeeTags.name} />
      </div>
      <p
        style={{
          marginTop: '15px',
          color: '#999',
        }}
      >
        タグは各20文字以内、最大で10個まで登録できます
      </p>
    </div>
  )
}

const EmployeeTag = ({description, description_color_by_rgb, diff = {}}) => {
  return (
    <Section title="タグ">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <div>
        <Field name="employee_tags" component={renderTagField} diff={diff} />
      </div>
    </Section>
  )
}

export default EmployeeTag
  |> connect((state, {selector}) => ({
    diff: selector(state, '_diff'),
  }))
  |> withFormSelectors
