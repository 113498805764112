import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {useDispatch} from 'react-redux'

import {displayFormat} from 'libs/formatter'
import {usePost} from 'hooks/api/egovProcedureStatuses/applies/usePost'
import {ApplyFormDetail, FormatError} from '../ApplyFormDetail'
import {SubmitButton} from 'components/forms/SubmitButton'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

// jbc-front
import CommonModal from 'jbc-front/components/CommonModal'
import Button from 'jbc-front/components/Button'

import type {FC} from 'react'
import type {FormStep} from '../ApplyFormDetail'
import type {EgovCertificateFile, EgovProcedure, EgovAccount} from '../../query'
import type {Refetch} from '../../index'

interface ApplyModalProps {
  isOpen: boolean
  onClose: () => void
  egovProcedure: EgovProcedure
  egovCertificateFile?: EgovCertificateFile
  egovAccount?: EgovAccount
  refetch: Refetch
}

interface ErrorReport {
  attached_file_name: string
  content: string
  file_name: string
  form_name: string
  item: string
}

export interface ApplyFormSchema {
  pinCode: string
}

export const ApplyModal: FC<ApplyModalProps> = ({
  isOpen,
  onClose,
  egovProcedure,
  egovCertificateFile,
  egovAccount,
  refetch,
}) => {
  const dispatch = useDispatch()
  const post = usePost()
  const [formStep, setFormStep] = useState<FormStep>('attachments')
  const [formatError, setFormatError] = useState<FormatError>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const gbizidAccountType = egovAccount?.gbizidAccountType || ''

  const handleClose = () => {
    setFormStep('attachments')
    onClose()
  }

  const schema = yup.object({
    pinCode: yup.string().required('PINコードを入力してください'),
  })

  const {handleSubmit, formState, register, setError} = useForm<ApplyFormSchema>({
    resolver:
      egovProcedure.signRequired || !['prime', 'member'].includes(gbizidAccountType) ? yupResolver(schema) : undefined,
    reValidateMode: 'onBlur',
  })

  const onSubmit = async (data: ApplyFormSchema) => {
    if (submitting) return

    try {
      setSubmitting(true)
      await post(egovProcedure.id, data.pinCode)
      dispatch(notifySuccess('申請が完了しました'))
      await refetch({})
      onClose()
    } catch (e) {
      if (e.response?.data?._errors?.reason === 'egov_format_check_error') {
        const errors = e.response.data._errors
        const newFormatError = {
          title: errors.title,
          detail: displayFormat(errors.detail),
          reportList: errors.report_list.map((report: ErrorReport) => ({
            attachedFileName: displayFormat(report.attached_file_name),
            content: displayFormat(report.content),
            fileName: displayFormat(report.file_name),
            formName: displayFormat(report.form_name),
            item: displayFormat(report.item),
          })),
        } as FormatError

        setFormatError(newFormatError)
        setFormStep('error')
      } else if (e.response?.data?._errors?.pin) {
        setError('pinCode', {type: 'custom', message: 'PINコードが間違っています'})
      } else {
        dispatch(asyncError(e))
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <CommonModal isOpen={isOpen}>
      <CommonModal.Header hideModal={handleClose}>申請内容</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ApplyFormDetail
            egovProcedure={egovProcedure}
            egovCertificateFile={egovCertificateFile}
            egovAccount={egovAccount}
            formStep={formStep}
            formState={formState}
            register={register}
            formatError={formatError}
          />
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <CommonModal.Buttons>
          {formStep == 'attachments' && (
            <>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button primary onClick={() => setFormStep('pinCode')}>
                次へ
              </Button>
            </>
          )}
          {formStep == 'pinCode' && (
            <>
              <Button onClick={handleClose}>キャンセル</Button>
              <SubmitButton primary submitting={submitting} onSubmit={handleSubmit(onSubmit)}>
                申請
              </SubmitButton>
            </>
          )}
          {formStep == 'error' && <Button onClick={handleClose}>閉じる</Button>}
        </CommonModal.Buttons>
      </CommonModal.Footer>
    </CommonModal>
  )
}
