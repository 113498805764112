import React from 'react'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import api from 'api'
import {push} from 'connected-react-router'
import Modal from 'jbc-front/components/Modal'
import {withModal} from 'jbc-front/components/CommonModal'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const CancelModal = ({id, render, isModalOpen, token, showModal, cancel, ...rest}) => (
  <>
    <Modal
      isOpen={isModalOpen}
      body="依頼を取り下げてもよろしいですか？"
      onSubmit={() => cancel(id, token)}
      header="依頼を取り下げる"
      cancel="キャンセル"
      submit="取り下げる"
      {...rest}
    />
    {render({showModal})}
  </>
)

export default compose(
  withModal,
  connect(
    (state) => ({token: state.auth.token}),
    (dispatch) => ({
      async cancel(procedureStatusId, token) {
        try {
          await api.createWithAuth(token).employees.detailInputRequest.cancel(procedureStatusId)
          dispatch(notifySuccess('依頼を取り下げました'))
          dispatch(push('/dashboard'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(CancelModal)
