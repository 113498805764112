import {FC, useState} from 'react'
import {Document} from '../../../query'
import {DownloadButton} from 'components/ui/DownloadButton'
import {useDownloadDynamicDocument} from 'hooks/api/dynamicDocuments/useDownloadDynamicDocument'
import {useDownloadUploadedDocument} from 'hooks/api/uploadedDocuments/useDownloadUploadedDocument'
import {HoverDescription, DescriptionContainer} from 'jbc-front/components/presenters/ui/HoverDescription'

type DownloadColumnProps = {
  employeeId: number
  document: Document
}

export const Download: FC<DownloadColumnProps> = ({employeeId, document}) => {
  const [isHover, setIsHover] = useState<boolean>(false)

  const downloadDynamicDocument = useDownloadDynamicDocument()
  const downloadUploadedDocument = useDownloadUploadedDocument()

  const handleDownload = async () => {
    switch (document.type) {
      case 'dynamic_document':
        await downloadDynamicDocument(String(document.id), String(employeeId))
        return
      case 'uploaded_document':
        await downloadUploadedDocument(String(document.id))
        return
    }
  }

  if (document.type == 'dynamic_document' && !document.dynamicDocument.downloadable) {
    return (
      <DescriptionContainer onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        <DownloadButton disabled>
          <HoverDescription isHover={isHover}>書類の作成・更新中はダウンロードできません</HoverDescription>
        </DownloadButton>
      </DescriptionContainer>
    )
  }

  return <DownloadButton onClick={handleDownload} />
}
