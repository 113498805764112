import React from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {recordDisplay} from 'utils'
import {parseHtml} from 'tm/notifications/utils'
import styles from 'tm/notifications/Notification.scss'

const Title = ({notification, actions, currentTmUser}) => (
  <div>
    {notification.send_status != 'completed' && notification.employee.id === currentTmUser.employee.id && (
      <div className={styles.reserved}>配信予約中</div>
    )}
    {notification.send_status == 'completed' && notification.employee.id === currentTmUser.employee.id && (
      <div className={styles.sended}>配信済</div>
    )}
    <p>
      <span className={styles.timestamp}>
        {notification.reserved_option == 'immediate' && moment(notification.created_at).format('LLL')}
        {notification.reserved_option == 'reserved' && moment(notification.reserved_at).format('LLL')}
        {notification.reserved_option == 'reserved' &&
          notification.send_status != 'completed' &&
          notification.employee.id === currentTmUser.employee.id && <span>(配信予定)</span>}
      </span>
      <span className={styles.sender}>配信者：{recordDisplay.fullName(notification.employee)}</span>
    </p>
    <div className={styles.subjectWrapper}>
      <h2 className={styles.subject}>{notification.subject}</h2>
      {actions}
    </div>
  </div>
)

const Body = ({body, ...rest}) => {
  return (
    <div {...rest}>
      <p style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={parseHtml(body)} />
    </div>
  )
}

export const Notification = ({notification, actions}) => {
  const [currentTmUser] = useSelector((state) => [state.session.currentTmUser])
  return (
    <>
      <Title notification={notification} actions={actions} {...{currentTmUser}} />
      <div className={styles.box}>
        <Body className={styles.body} body={notification.body} />
        {notification.file.filename && (
          <div className={styles.file}>
            添付ファイル{'　'}
            {recordDisplay.file(notification.file)}
          </div>
        )}
      </div>
    </>
  )
}

export const NotificationAnswer = ({children}) => <div className={styles.answerBox}>{children}</div>

export const DeletedNotification = () => (
  <div className="l-wrap-xs u-pt100">
    <div className="l-box-message">この通知は既に削除されています</div>
  </div>
)
