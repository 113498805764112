import {FC, ReactNode, forwardRef, MouseEventHandler} from 'react'
import {EditSquare, DeleteSquare} from 'jbc-front/components/icons'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import styles from './Memo.scss'

type MemoContainerProps = JSX.IntrinsicElements['div'] & {
  children: ReactNode
  onOpen: () => void
}

export const Memo = forwardRef<HTMLDivElement, MemoContainerProps>(function MemoComponent(
  {onOpen, children, ...rest},
  ref
) {
  const handleOpen: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    onOpen()
  }

  return (
    <div onClick={handleOpen} ref={ref} className={styles.memoContainer} {...rest}>
      {children}
    </div>
  )
})

interface MemoObj {
  id: number
  author: string
  createdAt: string
  editable: boolean
  body: string
}

interface ItemProps {
  memo: MemoObj
  onClickDelete: (id: number) => void
  onClickUpdate: (id: number, body: string) => void
}

export const Item: FC<ItemProps> = ({memo, onClickDelete, onClickUpdate}) => {
  const handleClickUpdate = () => onClickUpdate(memo.id, memo.body)
  const handleClickDelete = () => onClickDelete(memo.id)

  return (
    <div className={styles.memoItem}>
      <div className={styles.memoItemHeader}>
        <div className={styles.textContainer}>
          <div className={styles.author}>{memo.author}</div>
          <span className={styles.time}>{memo.createdAt}</span>
        </div>
        {memo.editable && (
          <div className={styles.tools}>
            <EditSquare className={styles.toolIcon} onClick={handleClickUpdate} />
            <DeleteSquare className={styles.toolIcon} onClick={handleClickDelete} />
          </div>
        )}
      </div>
      <Spacer direction="y" size={20} />
      <p className={`${styles.memoText} u-ta-l`}>{memo.body}</p>
    </div>
  )
}
