import React, {useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {reduxForm, formValueSelector, getFormInitialValues} from 'redux-form'
import {push} from 'connected-react-router'
import {gql} from '@apollo/client'
import {RadioField, Section, TextAreaField, TextField, SelectField, CheckboxField} from 'jbc-front/components/Form'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import Button from 'jbc-front/components/Button'
import {maxLength, required, saferFilename, saferFilenameExcludeVariables, notOnlyVariables} from 'validators'
import {useQuery} from 'components/Graphql'
import {FroalaEditorField} from 'documentTemplates/froala/lazy'
import styles from 'documentTemplates/Form.scss'

const DOCUMENT_TEMPLATE_CATEGORIES = gql`
  query documentTemplateCategories {
    client {
      id
      documentTemplateCategories {
        id
        name
      }
    }
  }
`

const formName = 'documentTemplateForm'
const selector = formValueSelector(formName)
const initialSelector = getFormInitialValues(formName)

const wrapperSectionStyle = {
  maxWidth: 'unset',
}

export const visibilityOptions = [
  {value: 'private', label: '自分のみ'},
  {value: 'all_admins', label: '管理者全体'},
]

const editabilityOptions = [
  {value: 'private', label: '自分のみ'},
  {value: 'all_admins', label: '管理者全体'},
]

const Form =
  (({handleSubmit, pristine, submitting, autofill}) => {
    const dispatch = useDispatch()
    const {data} = useQuery(DOCUMENT_TEMPLATE_CATEGORIES, {
      fetchPolicy: 'cache-and-network',
    })
    const categoryOptions = useMemo(
      () => data?.client?.documentTemplateCategories?.map((c) => ({value: c.name, label: c.name})) || [],
      [data]
    )
    const initCategoryName = useSelector((state) => initialSelector(state).documentTemplateCategoryName)
    const [draft, visibility] = useSelector((state) => [selector(state, 'draft'), selector(state, 'visibility')])

    return (
      <form onSubmit={handleSubmit}>
        <FormErrors />
        <p className={styles.variablesHelp}>
          書類に利用できる情報は
          <a
            className="u-txt-link"
            href="https://jobcan-lms.zendesk.com/hc/ja/articles/4409823366425"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          を参照してください
        </p>
        <Section title="書類テンプレート" style={wrapperSectionStyle}>
          <SelectField
            name="documentTemplateCategoryName"
            label="カテゴリ"
            required
            creatable
            options={categoryOptions.filter((option) => option.value != initCategoryName)}
            validate={maxLength(64)}
          />
          <TextField name="title" label="書類テンプレート名" required validate={[maxLength(64), saferFilename]} />
          <TextAreaField name="description" label="説明" rows="3" validate={maxLength(255)} />
          <TextField
            name="downloadFilename"
            label="ダウンロード時のファイル名"
            note="ファイル名に変数を設定することができます。"
            validate={[maxLength(128), saferFilenameExcludeVariables, notOnlyVariables]}
          />
          <FroalaEditorField name="body" label="書類レイアウト" required validate={required} />
          <div className={styles.permissions}>
            <CheckboxField
              name="draft"
              label="下書き"
              onChange={(e, value) => {
                if (value) {
                  autofill('visibility', 'private')
                  autofill('editability', 'private')
                }
              }}
            />
            {!draft && (
              <div className={styles.formRow}>
                <div className={styles.formRowHeader}>公開範囲：</div>
                <RadioField
                  name="visibility"
                  options={visibilityOptions}
                  required
                  noLabel
                  onChange={(e, value) => {
                    if (value !== 'all_admins') {
                      autofill('editability', 'private')
                    }
                  }}
                />
              </div>
            )}
            {visibility === 'all_admins' && (
              <div className={styles.formRow}>
                <div className={styles.formRowHeader}>編集権限：</div>
                <RadioField name="editability" options={editabilityOptions} required noLabel />
              </div>
            )}
          </div>
        </Section>
        <div className={styles.bottomButtonsArea}>
          <Button onClick={() => dispatch(push('/document_templates'))} disabled={submitting} className={styles.button}>
            キャンセル
          </Button>
          <Button primary onClick={handleSubmit} disabled={pristine || submitting} className={styles.button}>
            テンプレート登録
          </Button>
        </div>
      </form>
    )
  }) |> reduxForm({form: formName, enableReinitialize: true, onSubmitFail})

export default Form
