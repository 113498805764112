import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Dl, Dd, Dt, Grid, SubPanel} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import styles from 'employees/show/Parts.scss'
import ReadMoreReadLess from 'components/ReadMoreReadLess'

const EmployeeSkill = ({employee, editLink, yearStyle, canEdit}) => {
  const employeeSkills = employee.employee_skills || []
  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeSkill`} title="スキル" canEdit={canEdit} />
      <Item>
        <Grid className={employeeSkills.length == 1 && styles.gridFill}>
          {employeeSkills.map((employeeSkill, index) => (
            <SubPanel key={index}>
              <SubPanel.Headding>スキル ({index + 1})</SubPanel.Headding>
              <Grid className={styles.lastChildrenNotBorder}>
                <Dl>
                  <Dt>スキル名</Dt>
                  <Dd>{recordDisplay(employeeSkill.skill_name)}</Dd>
                </Dl>
                <Dl>
                  <Dt>習得年月日</Dt>
                  <Dd>{recordDisplay.date(employeeSkill.acquisition_date, yearStyle)}</Dd>
                </Dl>
                <Dl>
                  <Dt>スキル詳細</Dt>
                  <Dd>
                    <ReadMoreReadLess key={employeeSkill.skill_details}>
                      {recordDisplay(employeeSkill.skill_details)}
                    </ReadMoreReadLess>
                  </Dd>
                </Dl>
              </Grid>
            </SubPanel>
          ))}
        </Grid>
        {employeeSkills.length < 1 && (
          <div className={styles.noData}>
            <p>スキルの登録はありません</p>
          </div>
        )}
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmployeeSkill)
