import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Error} from 'jbc-front/components/presenters/form/Error'
import {Hint} from 'jbc-front/components/presenters/ui/Hint'
import ActionButton from 'jbc-front/components/ActionButton'
import Button from 'jbc-front/components/Button'
import {EMPLOYEE_DOCUMENTS, UPDATE_DOCUMENT_PUBLISHED, Search} from '../../../query'
import styles from './PublishModal.scss'
import classNames from 'classnames'
import {notifySuccess} from 'store/actions/notify'

// @ts-ignore
import {useMutation} from 'components/Graphql'

interface PublishModalProps {
  documentId: number
  employeeId: number
  search: Search
}

interface PublishFormSchema {
  comment: string
}

export const PublishModal: FC<PublishModalProps> = ({documentId, employeeId, search}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const [published, {loading}] = useMutation(UPDATE_DOCUMENT_PUBLISHED)
  const dispatch = useDispatch()

  const schema = yup.object({
    comment: yup.string().max(2000, '2000文字以内で入力してください'),
  })

  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm<PublishFormSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      comment: '',
    },
  })

  const onSubmit = async (data: PublishFormSchema) => {
    await published({
      variables: {
        input: {
          id: documentId,
          employeeIds: [employeeId],
          comment: data.comment,
        },
      },
      refetchQueries: [
        {
          query: EMPLOYEE_DOCUMENTS,
          variables: {id: employeeId, search},
          fetchPolicy: 'network-only',
        },
      ],
    })
    setIsOpen(false)
    dispatch(notifySuccess('書類を公開しました'))
  }

  return (
    <>
      <ActionButton primary onClick={() => handleOpen()}>
        公開する
      </ActionButton>
      <Modal isOpen={isOpen} onClose={handleClose} className={styles.modal}>
        <Modal.Header onClose={handleClose}>書類を公開する</Modal.Header>
        <Modal.Body>
          <p className="u-mb15">
            公開するとマイページ登録済の従業員はマイページから書類を閲覧できるようになります。書類を公開しますか？
            <br />
            <span className={styles.caution}>
              マイページ未招待の従業員は、招待後に登録手続きを完了することで書類を閲覧できるようになります
            </span>
          </p>
          <Label className={classNames(styles.label, 'u-mb5')}>
            コメント
            <Hint leftPoint={-10}>メール本文にコメントを記載できます</Hint>
          </Label>
          <Textarea {...register('comment')} isError={!!errors.comment?.message} className={styles.textarea} />
          <div>最大2000文字</div>
          {errors.comment?.message && <Error error={errors.comment.message} />}
        </Modal.Body>
        <Modal.Footer>
          <ButtonRow>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button primary disabled={loading} onClick={handleSubmit(onSubmit)}>
              公開
            </Button>
          </ButtonRow>
        </Modal.Footer>
      </Modal>
    </>
  )
}
