import {configureStore as rtkConfigureStore} from '@reduxjs/toolkit'
import {createEpicMiddleware} from 'redux-observable'
import epics from 'epics'
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory as createHistory} from 'history'
import rootReducers from 'reducers'
import {isDevelopmentEnv} from 'utils'
import errorHandler from './middleware/errorHandler'
import trimFields from './middleware/trimFields'
import {persistSelectedOffices} from './middleware/persistSelectedOffices'
import {createEarlyFetchDestroyDetector} from './middleware/earlyFetchDestroyDetector'

const epicMiddleware = createEpicMiddleware()
export const history = createHistory()
let middleware = [
  errorHandler,
  epicMiddleware,
  routerMiddleware(history),
  trimFields,
  persistSelectedOffices,
  createEarlyFetchDestroyDetector(),
]

export function configureStore(initialState) {
  const store = rtkConfigureStore({
    reducer: rootReducers(history),
    preloadedState: initialState,
    devTools: isDevelopmentEnv,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({immutableCheck: false, serializableCheck: false}).concat(middleware),
  })
  epicMiddleware.run(epics)
  if (module.hot) {
    module.hot.accept('reducers', () => {
      const nextRootReducer = require('reducers').default
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
