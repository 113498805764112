import React, {Component} from 'react'
import {Card, Container} from 'components/SelectCard'
import {actionCreators} from 'actions'
import api from 'api'
import Modal from 'components/Modal'
import Button from 'jbc-front/components/Button'
import {parse} from 'query-string'
import {connect} from 'react-redux'
import {CalenderChecked, CalenderChanged} from 'jbc-front/components/icons'
import {push} from 'connected-react-router'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class ChooseExtendOrFinish extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
    }
  }

  componentDidMount() {
    const {
      location: {search},
      loadProcedureStatus,
      token,
    } = this.props
    const {procedure_id} = parse(search)
    loadProcedureStatus(procedure_id, token)
  }

  async handleSubmit(id, token) {
    const {reportBirth} = this.props
    this.setState({submitting: true})
    await reportBirth(id, token)
    this.setState({submitting: false})
  }

  render() {
    const {procedureStatus, token} = this.props
    return (
      <Modal>
        {({hideModal, showModal, isOpen}) => {
          const dateOn = procedureStatus?.procedure_start_maternity_datum?.expected_birth_date?.replace(/-/g, '/')
          return (
            <>
              <div>
                <div className="l-title-wrap">
                  <h1 className="m-title-main">出産日を報告</h1>
                  {dateOn && <p className="m-title-main-note">{`出産予定日：${dateOn}`}</p>}
                </div>
                <Container>
                  <Card
                    icon={CalenderChecked}
                    onClick={() => {
                      showModal()
                    }}
                  >
                    出産予定日に出産
                  </Card>
                  <Card
                    icon={CalenderChanged}
                    onClick={() => {
                      this.props.history.push(`/extend_maternity/employee_input?procedure_id=${procedureStatus.id}`)
                    }}
                  >
                    出産予定日と異なる日に出産
                  </Card>
                </Container>
              </div>
              {isOpen && (
                <Modal.Modal>
                  <Modal.Header>出産予定日に出産した</Modal.Header>
                  <Modal.Body>
                    <div>
                      管理者に出産予定日通りに出産したことを報告します。
                      <br />
                      よろしいですか？
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Modal.Buttons>
                      <Button onClick={hideModal}>キャンセル</Button>
                      <Button
                        primary
                        onClick={() => this.handleSubmit(procedureStatus.id, token)}
                        disabled={this.state.submitting}
                      >
                        報告
                      </Button>
                    </Modal.Buttons>
                  </Modal.Footer>
                </Modal.Modal>
              )}
            </>
          )
        }}
      </Modal>
    )
  }
}

export default connect(
  (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api.createWithAuth(token).procedureStatuses.get(id, ['employee', 'procedure_maternity_datum'])
        )
      )
    },
    reportBirth: async (id, token) => {
      try {
        await api.createWithAuth(token).procedureStatuses.maternity.reportBirth(id)
        dispatch(push('/dashboard'))
        dispatch(notifySuccess('報告しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(ChooseExtendOrFinish)
