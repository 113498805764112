import {useMemo} from 'react'
import useReactRouter from 'use-react-router'
import {parse, stringify} from 'query-string'
import _ from 'lodash'

export const useSearchQuery = () => {
  const {
    history,
    location: {search, pathname},
  } = useReactRouter()
  const query = parse(search)
  const q = query['q']
  const setSearchQuery = useMemo(
    () => _.debounce((q: string) => history.push({pathname, search: stringify({q})}), 1000),
    [history, pathname]
  )
  return {searchQuery: _.isArray(q) ? q[0] : q ?? undefined, setSearchQuery}
}
