import {useEffect} from 'react'
import NProgress from 'nprogress'
import {useSelector} from 'hooks/redux'
import {usePrevious} from 'hooks/usePrevious'

export const LoadingProgress = () => {
  // 何もしていない時は0、読み込み中のリソースの数で大きくなる
  const count = useSelector(
    (state) =>
      (state.employees.current.loading ? 1 : 0) +
      (state.employees.list.loading ? 1 : 0) +
      (state.procedureStatuses.current.loading ? 1 : 0) +
      (state.procedureStatuses.list.loading ? 1 : 0) +
      (state.procedureStatuses.tmpData.loading ? 1 : 0) +
      (state.procedureStatuses.report.loading ? 1 : 0) +
      (state.master.loading ? 1 : 0) +
      (state.settings.auditLogs.list.loading ? 1 : 0) +
      (state.settings.auditLogs.current.loading ? 1 : 0) +
      (state.settings.allowedIps.loading ? 1 : 0) +
      (state.settings.additionalNotificationDsts.loading ? 1 : 0) +
      (state.settings.laborConsultant.loading ? 1 : 0) +
      (state.notificationsData.list.loading ? 1 : 0) +
      state.fetchR.loading
  )
  const prevCount = usePrevious(count) || 0

  useEffect(() => {
    NProgress.configure({showSpinner: false})
  }, [])

  useEffect(() => {
    if (count !== prevCount) {
      if (prevCount === 0 && count > 0) {
        NProgress.start()
      } else if (count === 0 && prevCount > 0) {
        NProgress.done()
      } else if (count < prevCount) {
        NProgress.inc()
      }
    }
  }, [count, prevCount])

  return null
}
