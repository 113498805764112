import React from 'react'
import {TextField, Section} from 'jbc-front/components/Form'
import {reduxForm} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {connect} from 'react-redux'
import {resetPassword, checkResetPasswordToken} from 'api'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import {useEffect} from 'react'

const validate = (values) => {
  const errors = {}
  if (values.password && values.password_confirmation && values.password !== values.password_confirmation) {
    errors.user_password_confirmation = 'が一致しません'
  }
  return errors
}

const ChangePasswordForm = reduxForm({
  form: 'change_password',
  onSubmitFail,
  validate,
})(({submitting, handleSubmit}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">新しいパスワードを入力してください</h1>
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section>
        <TextField name="password" label="新パスワード" type="password" required />
        <TextField name="password_confirmation" label="新パスワード(確認用)" type="password" required />
      </Section>
      <div className="u-ta-c u-mt30">
        <Button as="button" primary type="submit" disabled={submitting}>
          変更
        </Button>
      </div>
    </div>
  </form>
))

const ChangePassword = connect(null, (dispatch) => ({
  onSubmit: async (values) => {
    try {
      await resetPassword(values)
      dispatch(notifySuccess('変更しました'))
      dispatch(push('/users/login'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
  redirectToLogin: () => {
    dispatch(push('/expire_token'))
  },
}))(({onSubmit, redirectToLogin, location: {search}}) => {
  useEffect(() => {
    const initialTokenCheck = async () => {
      try {
        await checkResetPasswordToken(parse(search).reset_password_token)
      } catch {
        redirectToLogin()
      }
    }
    initialTokenCheck()
  }, [])
  return <ChangePasswordForm onSubmit={onSubmit} initialValues={{token: parse(search).reset_password_token}} />
})

export default ChangePassword
