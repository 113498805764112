import {gql} from '@apollo/client'

export const TAX_OFFICES = gql`
  query taxOffices($prefectureId: ID!) {
    taxOffices(prefectureId: $prefectureId) {
      id
      name
      officeNumber
      prefectureId
      telPart0
      telPart1
      telPart2
      jurisdiction
      address
    }
  }
`

export interface TaxOfficesVariables {
  prefectureId: string
}

export interface TaxOfficesResult {
  taxOffices: TaxOffice[]
}

export interface TaxOffice {
  id: string
  name: string
  officeNumber: string
  prefectureId: string
  telPart0: string
  telPart1: string
  telPart2: string
  jurisdiction: string
  address: string
}
