import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Wrap, TitleArea, Title, Dl, Dd, Dt, SubPanelarea, SubPanel} from 'tm/employees/Parts'
import * as icons from 'jbc-front/components/icons'
import {Qualification} from 'jbc-front/components/icons'
import ShowMoreBtn from './ShowMoreBtn'

class BasicInfomation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }
  render() {
    const {employee, userDisplaySetting} = this.props
    const icon_name = getCategoryIconName(userDisplaySetting, 'qualification')
    const color =
      (!_.isNull(getCategoryIconColor(userDisplaySetting, 'qualification')) &&
        getCategoryIconColor(userDisplaySetting, 'qualification')) ||
      '#3498db'
    const IconName = icons[icon_name]
    const style = {color: color}
    return (
      (showCategory(userDisplaySetting, 'qualification') &&
        employee.employee_qualification &&
        employee.employee_qualification.length > 0 && (
          <Panel>
            <Item>
              <Wrap>
                <TitleArea>
                  <Title>
                    {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || (
                      <Qualification size={30} color={color} />
                    )}
                    <div style={style}>資格</div>
                  </Title>
                </TitleArea>

                <SubPanelarea>
                  {employee.employee_qualification &&
                    (this.state.isExpand
                      ? employee.employee_qualification
                      : employee.employee_qualification.slice(0, 4)
                    ).map(
                      (qualification, index) =>
                        qualification && (
                          <SubPanel key={index}>
                            <SubPanel.Headding>
                              {recordDisplay(_.get(qualification, 'qualification_name'))}
                            </SubPanel.Headding>
                            <Dl>
                              <Dt>取得更新年月</Dt>
                              <Dd>{recordDisplay.dateYearMonth(_.get(qualification, 'acquisition_date'))}</Dd>
                            </Dl>
                          </SubPanel>
                        )
                    )}
                  {employee.employee_qualification && employee.employee_qualification.length > 4 && (
                    <ShowMoreBtn
                      isExpand={this.state.isExpand}
                      onToggleClick={() => {
                        this.setState({isExpand: !this.state.isExpand})
                      }}
                    />
                  )}
                </SubPanelarea>
              </Wrap>
            </Item>
          </Panel>
        )) ||
      null
    )
  }
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(BasicInfomation)
