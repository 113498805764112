import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Button from 'jbc-front/components/Button'
import styles from 'dashboard/dashboard.scss'
import moment from 'moment'

class Plan extends Component {
  render() {
    const {trialFinishAt, planType} = this.props

    return (
      <div>
        {planType === 'trial_plan' && (
          <div className={styles.plan}>
            <div className={styles.planInner}>
              <p className={styles.planText}>
                お試しプラン 残り{moment(trialFinishAt, 'YYYY-MM-DD').diff(moment().subtract(1, 'days'), 'days')}日
              </p>
              <p className={styles.planInquiry}>
                電話でのお問い合わせはこちら <span className={styles.planPhonenumber}>050-3204-4943</span>
                <br />
                受付時間：10:00〜12:00、13:00〜17:00 (土日祝除く)
              </p>
              <Link to="/settings/using_plan" className={styles.button}>
                <Button>有料プランへの申込はこちら</Button>
              </Link>
            </div>
          </div>
        )}
        {planType === 'free_plan' && (
          <div className={styles.plan}>
            <div className={styles.planInner}>
              <p className={styles.planText}>現在、無料プランをご利用中です</p>
              <p className={styles.planInquiry}>※ 無料プランは機能制限がございます</p>
              <Link to="/settings/using_plan" className={styles.button}>
                <Button>有料プランへの申込はこちら</Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect((state) => ({
  trialFinishAt: state.client.current.trial_finish_at,
  planType: state.client.current.plan_type,
}))(Plan)
