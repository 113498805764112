import React from 'react'
import {Link} from 'react-router-dom'
import styles from 'procedures/enroll/EmployeeInputCompleted.scss'
import {RoumuLogo} from 'jbc-front/components/icons'

export default ({afterInput}) => {
  return (
    <div className={styles.wrap}>
      <div>
        <div className={styles.logo}>
          <RoumuLogo size={200} />
        </div>
        <p className={styles.thanks}>{afterInput ? 'すでに入力済みです' : '入力ありがとうございました'}</p>
        <p className={styles.text}>管理者が確認するまで少々お待ちください</p>
        <p className={styles.login}>
          ログインは<Link to="/login">こちら</Link>
        </p>
      </div>
    </div>
  )
}
