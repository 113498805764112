import GROUPS from 'settings/groups/actions'
import LIST_EDIT from 'settings/listEdit/actions'
import AUDIT_LOGS from 'settings/auditLogs/actions'
import USERS_CLIENTS from 'settings/usersClients/actions'
import fetchActions from 'fetchActions'
const actions = []

const subActions = {
  GROUPS,
  LIST_EDIT,
  AUDIT_LOGS,
  ALLOWED_IPS: fetchActions,
  ADDITIONAL_NOTIFICATION_DSTS: fetchActions,
  LABOR_CONSULTANT: fetchActions,
  USERS_CLIENTS,
  TALENT_DISPLAY_SETTINGS: fetchActions,
  TALENT_SEARCH_SETTINGS: fetchActions,
}

export default [actions, subActions]
