import _ from 'lodash'
import {actionTypes} from 'actions'
import arrayMove from 'array-move'

const initialState = {
  initialList: [],
  list: [],
  editing: false,
}

const listEdit = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS.LIST_EDIT.INITIALIZE_LIST:
      return {
        ...state,
        initialList: [...action.payload],
        list: [...action.payload].map((i) => ({...i, initialName: i.name, initialCode: i.code})),
        editing: false,
      }
    case actionTypes.SETTINGS.LIST_EDIT.ADD_LIST:
      return {
        ...state,
        list: [
          ...state.list,
          {
            id: '',
            localId: _.uniqueId(),
            name: '',
            employees_count: 0,
          },
        ],
      }
    case actionTypes.SETTINGS.LIST_EDIT.EDIT_NODE:
      return {
        ...state,
        list: state.list.map((data) =>
          data.localId === action.payload.localId ? {...data, ...action.payload.value} : data
        ),
      }
    case actionTypes.SETTINGS.LIST_EDIT.MOVE_LIST:
      return {
        ...state,
        list: arrayMove(state.list, action.payload.oldIndex, action.payload.newIndex),
      }
    case actionTypes.SETTINGS.LIST_EDIT.DELETE_LIST:
      return {
        ...state,
        list: state.list.filter((data) => data.localId != action.payload.localId),
      }
    case actionTypes.SETTINGS.LIST_EDIT.START_EDIT:
      return {
        ...state,
        editing: true,
        list: state.list.map((list) => ({
          ...list,
          localId: _.uniqueId(),
        })),
      }
    case actionTypes.SETTINGS.LIST_EDIT.END_EDIT:
      return {
        ...state,
        editing: false,
      }
    case actionTypes.SETTINGS.LIST_EDIT.INITIALIZE:
      return initialState
    default:
      return state
  }
}

export default listEdit
