import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Dl, Dd, Dt} from 'components/SecondaryPanel'
import {Panel, Headding, Item} from 'components/PrimaryPanel'
import {Edit} from 'jbc-front/components/icons'
import {Link} from 'react-router-dom'
import api from 'api'
import {recordDisplay} from 'utils'
import {getMainOfficeContactInfo} from 'offices/utils'
import _ from 'lodash'
import Coordination from 'components/Coordination'
import {isOfficeAdminSelector} from 'utils'
import {setCurrentClient} from 'store/slices/client'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

export const healthInsuranceTypeMap = {
  kyokai: '協会けんぽ',
  its: '関東ITソフトウェア健康保険組合',
  other: 'その他の組合健保',
}

export const salaryMonth = (month) => {
  if (month === 'current') {
    return '当月'
  } else if (month === 'next') {
    return '翌月'
  } else {
    return ''
  }
}
export const salaryDate = (date) => (date ? (date === -1 ? '末日' : `${date}日`) : '')

class Client extends Component {
  loadClient = async () => {
    const {token, dispatch} = this.props
    try {
      const data = await api.createWithAuth(token).clients.getCurrent(['office', 'hello_work', 'tax_office'])
      dispatch(setCurrentClient(data))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  componentDidMount() {
    this.loadClient()
  }

  handleExport = async () => {
    const {token, dispatch} = this.props
    await api.createWithAuth(token).clients.payroll.export()
    dispatch(notifySuccess('同期しました'))
  }

  handleImport = async () => {
    const {token, dispatch} = this.props
    await api.createWithAuth(token).clients.payroll.import()
    await this.loadClient()
    dispatch(notifySuccess('同期しました'))
  }

  render() {
    const {currentOffice, planType, isOfficeAdmin, currentOffices} = this.props
    const mainOfficeSocial = getMainOfficeContactInfo(currentOffice)

    return (
      <div className="l-wrap-s">
        <div className="l-title-wrap">
          <h1 className="m-title-main">法人情報</h1>
        </div>
        {!isOfficeAdmin && (
          <div className="u-mb20 u-ta-r">
            <Coordination
              right
              onExport={this.handleExport}
              onImport={this.handleImport}
              confirmUrl="https://jobcan-lms.zendesk.com/hc/ja/articles/360003477151"
              isFreePlan={planType === 'free_plan'}
              importContent="事業所情報"
            />
          </div>
        )}
        <Panel>
          {!isOfficeAdmin || currentOffices.some((office) => office.is_main_office) ? (
            <Link to="/client/edit">
              <Headding icon={<Edit icon={16} />}>事業所</Headding>
            </Link>
          ) : (
            <Headding>事業所</Headding>
          )}
          <Item>
            <Dl>
              <Dt>事業所名：</Dt>
              <Dd>
                {recordDisplay(
                  mainOfficeSocial &&
                    mainOfficeSocial.name &&
                    mainOfficeSocial.name + (mainOfficeSocial.name_kana ? ` ${mainOfficeSocial.name_kana}` : '')
                )}
              </Dd>
              <Dt>住所：</Dt>
              <Dd>{recordDisplay.address(mainOfficeSocial, null)}</Dd>
              <Dt>事業主（代表者）：</Dt>
              <Dd>
                {recordDisplay.texts([
                  mainOfficeSocial.rep_position_name,
                  mainOfficeSocial.rep_last_name,
                  mainOfficeSocial.rep_first_name,
                ])}
              </Dd>
              <Dt>電話番号：</Dt>
              <Dd>{recordDisplay.numbers(mainOfficeSocial, 'phone_number', 3)}</Dd>
              <Dt>法人番号：</Dt>
              <Dd>{recordDisplay(_.get(currentOffice, 'corporate_number'))}</Dd>
              <Dt>管轄の税務署：</Dt>
              <Dd>{recordDisplay(_.get(currentOffice, 'tax_office.name'))}</Dd>
              <Dt>健康保険タイプ：</Dt>
              <Dd>{recordDisplay(healthInsuranceTypeMap[_.get(currentOffice, 'health_insurance_type')])}</Dd>
              <Dt>健康保険記号：</Dt>
              <Dd>{recordDisplay(_.get(currentOffice, 'health_insurance_reference_code'))}</Dd>
              <Dt>厚生年金保険記号：</Dt>
              <Dd>{recordDisplay.numbers(currentOffice, 'social_insurance_reference_code', 2)}</Dd>
              <Dt>厚生年金保険番号：</Dt>
              <Dd>{recordDisplay(_.get(currentOffice, 'social_insurance_office_number'))}</Dd>
              <Dt>管轄のハローワーク：</Dt>
              <Dd>{recordDisplay(_.get(currentOffice, 'hello_work.disp_name'))}</Dd>
              <Dt>雇用保険番号：</Dt>
              <Dd>{recordDisplay.numbers(currentOffice, 'employment_insurance_office_number', 3)}</Dd>
              <Dt>労働保険番号：</Dt>
              <Dd>{recordDisplay.numbers(currentOffice, 'labor_insurance_number', 5)}</Dd>
              <Dt>給与の締め日：</Dt>
              <Dd>{recordDisplay(salaryDate(_.get(currentOffice, 'salary_closing_date')))}</Dd>
              <Dt>給与の支給日：</Dt>
              <Dd>
                {recordDisplay(
                  salaryMonth(_.get(currentOffice, 'salary_payment_month_type')) +
                    salaryDate(_.get(currentOffice, 'salary_payment_date'))
                )}
              </Dd>
            </Dl>
          </Item>
        </Panel>
      </div>
    )
  }
}

const select = (state) => ({
  currentOffice: state.client.current.office,
  planType: state.client.current.plan_type,
  token: state.auth.token,
  isOfficeAdmin: isOfficeAdminSelector(state),
  currentOffices: state.session.currentUser.current_offices,
})

export default connect(select)(Client)
