import {RoumuLogo, BrandLogo} from 'jbc-front/components/icons'
import styles from './Logo.scss'

export const Logo = () => <RoumuLogo className={styles.brand} />

export const TmLogo = () => (
  <div className={styles.tmLogo}>
    <BrandLogo className={styles.brandTm} />
    <div className={styles.tmLogoText}>人材管理</div>
  </div>
)
