import React, {Component} from 'react'
//import PDF from 'react-pdf-js'
import {createAction} from 'redux-actions'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import api from 'api'
import Modal from 'react-modal'
import ActionButton from 'jbc-front/components/ActionButton'
import {MyNumber, Edit, Download, Print, PlusSquare, MinusSquare, Pulldown, Close} from 'jbc-front/components/icons'
import _ from 'lodash'
import Loading from 'components/Loading'
import {isAdminSelector, isLimitedAdminSelector} from 'utils'
import {asyncError} from 'store/actions/asyncError'
import {notifyError} from 'store/actions/notify'

import styles from 'components/PreviewPdf.scss'

// マイナンバーを利用する帳票のリスト
const useMyNumberReports = ['r2', 'r8', 'r13', 'r57', 'r58', 'r501', 'r502', 'r503', 'r504', 'r505', 'r506']
// マイナンバーの利用可否を編集画面で選択する帳票のリスト（その他健保以外）
const usableMyNumberReports = ['r1', 'r4', 'r6', 'r7']
const isUseMyNumberByReport = {
  r1: (payload) => payload.isUseMyNumber,
  r2: _.constant(true),
  r4: (payload) => payload.isUseMyNumber,
  r5: (payload) => ['enroll', 'add_dependents'].includes(payload.procedureType),
  r6: (payload) => (payload.healthInsuranceType != 'other' ? payload.isUseMyNumber : true),
  r7: (payload) => (payload.healthInsuranceType != 'other' ? payload.isUseMyNumber : true),
  r8: _.constant(true),
  r13: _.constant(true),
  r57: _.constant(true),
  r58: _.constant(true),
  r501: _.constant(true),
  r502: _.constant(true),
  r503: _.constant(true),
  r504: _.constant(true),
  r505: _.constant(true),
  r506: _.constant(true),
}

let PDF = null
const getPDF = () =>
  import(/* webpackChunkName: "react-pdf-js" */ 'react-pdf-js').then((_PDF) => {
    PDF = _PDF.default
  })

class PreviewPdf extends Component {
  componentWillMount() {
    this.props.hide()
  }

  onDocumentComplete = (totalPages) => {
    this.props.setTotalPages(totalPages)
  }

  onPageComplete = () => {
    if (this.props.previewPdf.firstTime) {
      const container = this._pdfContainer
      const canvas = container.getElementsByTagName('canvas')[0]
      const {clientWidth: cw, clientHeight: ch} = canvas
      const {clientWidth: dw, clientHeight: dh} = container
      const scale = dw > dh ? Math.max((dw / cw) * 0.5, (dh - 80) / ch) : Math.max(((dh - 80) / ch) * 0.6, dw / cw)
      this.props.setScale(scale)
    }
  }

  handlePrevious = () => {
    this.props.prevPage()
  }

  handleNext = () => {
    this.props.nextPage()
  }

  handleZoomIn = () => {
    this.props.zoomIn()
  }

  handleZoomOut = () => {
    this.props.zoomOut()
  }

  handleEditClick = () => {
    const {onEditClick, previewPdf} = this.props
    if (previewPdf.edit) {
      onEditClick(previewPdf)
    }
  }

  handleMyNumberClick = () => {
    const {
      procedureStatuses,
      token,
      isAdmin,
      asyncError,
      notifyError,
      getPdfAsync,
      previewPdf,
      setPdf,
      setMyNumberVisibility,
    } = this.props

    if (_.get(procedureStatuses, 'current.data.is_related_records_dirty')) {
      notifyError('編集内容はまだ反映されていません。「帳票に反映する」を押してください。')
      return
    }

    if (!isAdmin) {
      notifyError('マイナンバーの閲覧権限がありません')
      return
    }

    const procedureType = _.get(procedureStatuses, 'current.data.procedure_type')
    if (previewPdf.num === 'r5' && ['enroll', 'add_dependents'].includes(procedureType)) {
      // 帳票R5の場合は対象の被扶養者がマイナンバーを持っているかチェックして、持っていなければエラーメッセージを出す
      api
        .createWithAuth(token)
        .procedureStatuses.get(previewPdf.procedureStatusId, [
          'employee',
          'employee_dependents',
          'procedure_add_dependents_data',
          'my_number_present',
        ])
        .then(({data}) => {
          const dependents =
            procedureType === 'add_dependents'
              ? data.employee.employee_dependents.filter(({id}) =>
                  data.procedure_add_dependents_data.find(({employee_dependent_id}) => id === employee_dependent_id)
                )
              : data.employee.employee_dependents

          if (dependents && _.some(dependents, 'my_number_present')) {
            getPdfAsync(previewPdf, token, setPdf, setMyNumberVisibility)
          } else {
            notifyError('被扶養者のマイナンバーを入力してください')
          }
        })
        .catch((err) => {
          asyncError(err)
        })
    } else if (useMyNumberReports.includes(previewPdf.num) || usableMyNumberReports.includes(previewPdf.num)) {
      // R5以外のマイナンバー対応帳票の場合は被保険者か被扶養者がマイナンバーを持っているかチェックして、持っていなければエラーメッセージを出す
      api
        .createWithAuth(token)
        .employees.get(_.get(procedureStatuses, 'current.data.employee.id'), [
          'employee_dependents',
          'my_number_present',
        ])
        .then(({data}) => {
          const dependents = data.employee_dependents
          if (data.my_number_present || (dependents && _.some(dependents, 'my_number_present'))) {
            getPdfAsync(previewPdf, token, setPdf, setMyNumberVisibility)
          } else {
            notifyError('従業員のマイナンバーを入力してください')
          }
        })
        .catch((err) => {
          asyncError(err)
        })
    } else {
      getPdfAsync(previewPdf, token, setPdf, setMyNumberVisibility)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {previewPdf, token, checkUsedMyNumber, setUseMyNumber} = this.props
    if (nextProps.previewPdf.show !== previewPdf.show) {
      if (nextProps.previewPdf.show) {
        if (!PDF) {
          getPDF().then(() => this.forceUpdate())
        }
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }

    if (
      nextProps.previewPdf.procedureStatusId &&
      nextProps.previewPdf.procedureStatusId !== previewPdf.procedureStatusId &&
      usableMyNumberReports.includes(nextProps.previewPdf.num)
    ) {
      checkUsedMyNumber(
        token,
        nextProps.previewPdf.procedureStatusId,
        nextProps.previewPdf.num,
        nextProps.previewPdf.dependentId,
        setUseMyNumber
      )
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
    this.props.hide()
  }

  printPdf = () => {
    const {url} = this.props.previewPdf
    const isVisibleMyNumber = this.props.previewPdf.isVisibleMyNumber ? '&is_visible_my_number=true' : ''
    const w = window.open(`${url}&disposition=inline${isVisibleMyNumber}`, '', 'toolbar=0,menubar=0,location=0')
    if (_.isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print()
      })
    }
  }

  render() {
    const {healthInsuranceType, procedureStatuses, isLimitedAdmin} = this.props
    const {pdf, page, totalPages, scale, title, num, zoom, edit, url, show, isUseMyNumber, isVisibleMyNumber} =
      this.props.previewPdf

    const downloadUrl = isVisibleMyNumber ? `${url}&is_visible_my_number=true` : url
    const procedureType = _.get(procedureStatuses, 'current.data.procedure_type')
    const MyNumberVisibility = isUseMyNumberByReport[num] || (() => false)
    const myNumberButton =
      !isLimitedAdmin && MyNumberVisibility({procedureType, isUseMyNumber, healthInsuranceType}) ? (
        <ActionButton className={styles.button} onClick={this.handleMyNumberClick} icon={<MyNumber size={15} />}>
          {`マイナンバー${isVisibleMyNumber ? 'を隠す' : '出力'}`}
        </ActionButton>
      ) : null

    return (
      <Modal className={styles.modal} isOpen={show} overlayClassName={styles.overlay} contentLabel="Modal">
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <div className={styles.tools}>
            {myNumberButton}
            {edit !== null && (
              <ActionButton
                className={styles.buttonEdit}
                onClick={this.handleEditClick}
                disabled={!edit}
                icon={<Edit size={15} />}
              >
                編集
              </ActionButton>
            )}
            <ActionButton primary className={styles.button} onClick={this.printPdf} icon={<Print size={15} />}>
              印刷
            </ActionButton>
            <ActionButton
              primary
              className={styles.button}
              href={downloadUrl}
              download
              as="a"
              icon={<Download size={15} />}
            >
              ダウンロード
            </ActionButton>
            <Close className={styles.close} onClick={() => this.props.hide()} />
          </div>
        </div>
        <div className={styles.body} ref={(div) => (this._pdfContainer = div)}>
          <div className={styles.pdf}>
            {pdf && PDF ? (
              <PDF
                content={pdf}
                onDocumentComplete={this.onDocumentComplete}
                onPageComplete={this.onPageComplete}
                page={page}
                scale={(scale * zoom) / 100.0}
                ref={(pdf) => (this._pdf = pdf)}
              />
            ) : (
              <div className={styles.loading}>
                <Loading type="spin" color="#e3e3e3" />
              </div>
            )}
          </div>
          <div className={styles.controller}>
            <span className={styles.zoom} onClick={this.handleZoomOut}>
              <MinusSquare size={20} />
            </span>
            <span className={styles.scale}>{zoom}％</span>
            <span className={styles.zoom} onClick={this.handleZoomIn}>
              <PlusSquare size={20} />
            </span>
            <span className={styles.pagePrev} onClick={this.handlePrevious}>
              <Pulldown size={12} />
            </span>
            <span className={styles.pageNumber}>
              {page} / {totalPages}
            </span>
            <span className={styles.pageNext} onClick={this.handleNext}>
              <Pulldown size={12} />
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}
const initialState = {
  pdf: null,
  page: 1,
  totalPages: 1,
  scale: 1,
  zoom: 100,
  show: false,
  firstTime: true,
  title: '',
  procedureStatusId: '',
  num: '',
  url: '',
  isUseMyNumber: false,
  isVisibleMyNumber: false,
}

const NEXT_PAGE = 'PREVIEW_PDF.NEXT_PAGE'
const PREV_PAGE = 'PREVIEW_PDF.PREV_PAGE'
const ZOOM_IN = 'PREVIEW_PDF.ZOOM_IN'
const ZOOM_OUT = 'PREVIEW_PDF.ZOOM_OUT'
const SET_SCALE = 'PREVIEW_PDF.SET_SCALE'
const SHOW = 'PREVIEW_PDF.SHOW'
const HIDE = 'PREVIEW_PDF.HIDE'
const SET_TOTAL_PAGES = 'PREVIEW_PDF.SET_TOTAL_PAGES'
const SET_PDF = 'PREVIEW_PDF.SET_PDF'
const SET_USE_MY_NUMBER = 'PREVIEW_PDF.SET_USE_MY_NUMBER'
const SET_MY_NUMBER_VISIBILITY = 'PREVIEW_PDF.SET_MY_NUMBER_VISIBILITY'

const nextPage = createAction(NEXT_PAGE)
const prevPage = createAction(PREV_PAGE)
const zoomIn = createAction(ZOOM_IN)
const zoomOut = createAction(ZOOM_OUT)
const setScale = createAction(SET_SCALE)
const setTotalPages = createAction(SET_TOTAL_PAGES)
const setUseMyNumber = createAction(SET_USE_MY_NUMBER)
const setMyNumberVisibility = createAction(SET_MY_NUMBER_VISIBILITY)
export const show = createAction(SHOW)
export const hide = createAction(HIDE)
export const setPdf = createAction(SET_PDF)

const zooms = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500]

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NEXT_PAGE: {
      const {totalPages, page} = state
      return page < totalPages ? _.assign({}, state, {page: page + 1}) : state
    }
    case PREV_PAGE: {
      const {page} = state
      return page > 1 ? _.assign({}, state, {page: page - 1}) : state
    }
    case ZOOM_IN: {
      const {zoom: current} = state
      const index = zooms.indexOf(current) + 1
      return index < zooms.length ? _.assign({}, state, {zoom: zooms[index]}) : state
    }
    case ZOOM_OUT: {
      const {zoom: current} = state
      const index = zooms.indexOf(current) - 1
      return index >= 0 ? _.assign({}, state, {zoom: zooms[index]}) : state
    }
    case SET_SCALE:
      return _.assign({}, state, {
        scale: action.payload,
        firstTime: false,
      })
    case SET_TOTAL_PAGES: {
      const totalPages = action.payload
      return _.assign({}, state, {totalPages})
    }
    case SET_USE_MY_NUMBER: {
      const isUseMyNumber = action.payload
      return _.assign({}, state, {isUseMyNumber})
    }
    case SET_MY_NUMBER_VISIBILITY: {
      const isVisibleMyNumber = action.payload
      return _.assign({}, state, {isVisibleMyNumber})
    }
    case SHOW:
      return {
        ...initialState,
        ...action.payload,
        show: true,
      }
    case HIDE:
      return initialState
    case SET_PDF:
      return state.show
        ? {
            ...state,
            pdf: action.payload,
          }
        : state
    default:
      return state
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    isAdmin: isAdminSelector(state),
    isLimitedAdmin: isLimitedAdminSelector(state),
    healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
    procedureStatuses: state.procedureStatuses,
    previewPdf: state.previewPdf,
  }),
  (dispatch) => ({
    async getPdfAsync(previewPdf, token, setPdf, setMyNumberVisibility) {
      const isVisibleMyNumber = !previewPdf.isVisibleMyNumber
      setPdf(null)
      try {
        await getPDF()
        const pdf = await api
          .createWithAuth(token)
          .procedureStatuses.reports.getPdf(previewPdf.procedureStatusId, previewPdf.num, {
            is_visible_my_number: isVisibleMyNumber,
            employee_dependent_id: previewPdf.dependentId,
          })
        setPdf(pdf)
        setMyNumberVisibility(isVisibleMyNumber)
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    async checkUsedMyNumber(token, procedureStatusId, reportNum, dependentId, setUseMyNumber) {
      try {
        const report = await api.createWithAuth(token).procedureStatuses.reports.get(procedureStatusId, reportNum, {
          employee_dependent_id: dependentId,
        })
        setUseMyNumber(report[reportNum].is_use_my_number)
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    asyncError(err) {
      dispatch(asyncError(err))
    },
    notifyError(msg) {
      dispatch(notifyError(msg))
    },
    ...bindActionCreators(
      {
        nextPage,
        prevPage,
        zoomIn,
        zoomOut,
        show,
        hide,
        setPdf,
        setUseMyNumber,
        setMyNumberVisibility,
        setTotalPages,
        setScale,
      },
      dispatch
    ),
  })
)(PreviewPdf)
