import {combineReducers} from 'redux'
import {actionTypes} from 'actions'
import {getQueryDiffFactory} from 'utils'
import fetchReducer from 'reducers/fetchReducer'
import queryReducer from 'reducers/queryReducer'

const defaultQuery = {
  page: 1,
  employment_status: 'employed',
  sort_column: null,
  search_keyword: '',
  order: null,
}

export default combineReducers({
  list: fetchReducer(actionTypes.DOCUMENT_EMPLOYEES.LIST, {data: [], count: 0}),
  query: queryReducer(actionTypes.DOCUMENT_EMPLOYEES.SET_QUERY, defaultQuery),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
