import React, {Component} from 'react'
import {connect} from 'react-redux'
import ListGroup from 'jbc-front/components/ListGroup'
import {Progress} from 'components/Progress'
import {actionCreators} from 'actions'
import {Link} from 'react-router-dom'
import api from 'api'
import {statuses} from 'procedureStatuses/List'
import {statusDisplay} from 'procedureStatuses/ListNotAdmin'
import styles from 'dashboard/dashboard.scss'
import DateOn from 'components/DateOn'
import {titleMap} from 'procedureStatuses/Show'
import _ from 'lodash'

const MAX_COUNT = 5

class ProcedureStatuses extends Component {
  componentDidMount() {
    this.props.showProcedureStatuses(this.props.token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {procedureStatuses, count, currentUser} = this.props
    const isAdmin = ['full_admin', 'office_admin'].includes(_.get(currentUser, 'client_role.client_role_type'))
    return (
      <div className="u-mt30">
        <ListGroup>
          <ListGroup.Title>現在進行中の手続き</ListGroup.Title>
          {procedureStatuses.length > 0 ? (
            procedureStatuses.map((procedureStatus) => {
              const {display_first_name, display_last_name} = procedureStatus.employee
              return (
                <ListGroup.Item
                  as={procedureStatus.link ? Link : 'div'}
                  to={procedureStatus.link}
                  className={styles.list}
                  key={procedureStatus.id}
                >
                  <div className={styles.procedureTitle}>
                    {isAdmin ? display_last_name : ''} {isAdmin ? display_first_name : ''}
                    {isAdmin ? 'さんの' : ''}
                    {titleMap[procedureStatus.procedure_type]}
                  </div>
                  {isAdmin ? (
                    <div className={styles.progress}>
                      <Progress progress={procedureStatus.progress} key="progress" />
                    </div>
                  ) : (
                    ''
                  )}
                  {isAdmin ? (
                    <div className={styles.tag}>
                      <span>{statuses[procedureStatus.status]}</span>
                    </div>
                  ) : (
                    <div className={styles.tag}>
                      <span>{statusDisplay(procedureStatus.status, procedureStatus.application_status)}</span>
                    </div>
                  )}
                  <div className={styles.procedureDate}>
                    <DateOn date={procedureStatus.date_on} />
                  </div>
                </ListGroup.Item>
              )
            })
          ) : (
            <ListGroup.Item className={styles.empty}>進行中の手続きはまだありません</ListGroup.Item>
          )}
        </ListGroup>
        {count > procedureStatuses.length && (
          <div className={styles.more}>
            <Link to="/procedure_statuses">すべて見る</Link>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  procedureStatuses: state.procedureStatuses.list.data,
  count: state.procedureStatuses.list.count,
  currentUser: state.session.currentUser,
  token: state.auth.token,
})

const mapDispatchToProps = (dispatch) => {
  return {
    showProcedureStatuses(token) {
      dispatch(
        actionCreators.procedureStatuses.list.fetchData(
          api.createWithAuth(token).procedureStatuses.list({per_page: MAX_COUNT}, ['employee'])
        )
      )
    },
    destroy() {
      dispatch(actionCreators.procedureStatuses.list.destroy())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureStatuses)
