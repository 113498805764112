import React from 'react'
import {Link} from 'react-router-dom'
import {wrapArrowText} from 'employees/List'
import styles from 'dashboard/dashboard.scss'
import {Edit, User, Visibility, Email} from 'jbc-front/components/icons'
import Button from 'jbc-front/components/Button'
import {recordDisplay} from 'utils'
import {getGroups} from 'employees/form/EmploymentInfomation'
import _ from 'lodash'

const Profile = ({employee}) => (
  <div className={styles.profileWrap}>
    <div className={styles.profileCard}>
      <div className={styles.profileCardImageWrap}>
        {employee.icon && employee.icon.url ? (
          <div style={{backgroundImage: `url("${employee.icon.url}")`}} className={styles.profileCardImage} />
        ) : (
          <User className={styles.profileCardImage} size={74} />
        )}
      </div>
      <div className={styles.profileCardDescription}>
        <div className={styles.profileCardHeader}>
          <h5 className={styles.profileCardName}>{recordDisplay.fullName(employee)}</h5>
        </div>
        {_.get(employee, 'personnel_history.occupation_name') && (
          <p className={styles.profileCardPersonnelHistory}>{employee.personnel_history.occupation_name}</p>
        )}
        {!_.isEmpty(getGroups(employee)) && (
          <p className={styles.profileCardGroup}>
            {recordDisplay(
              getGroups(employee).map((group, index) => (
                <span key={index}>
                  {wrapArrowText(group)}
                  {index + 1 !== getGroups(employee).length && <span>&#47;</span>}
                </span>
              ))
            )}
          </p>
        )}
        <div className={styles.profileCardEmail}>
          <div>
            <Email size={15} />
          </div>
          <p>{employee.email}</p>
        </div>
      </div>
      <div className={styles.profileCardButtons}>
        <Link to="/account_settings/profile">
          <Button icon={<Visibility className={styles.visibilityButton} />} className={styles.profileCardButton}>
            閲覧する
          </Button>
        </Link>
        <Link to="/account_settings/profile/edit">
          <Button icon={<Edit />} className={styles.profileCardButton}>
            編集する
          </Button>
        </Link>
      </div>
    </div>
  </div>
)

export default Profile
