import Cookies from 'js-cookie'
import _ from 'lodash'

const defaultLimit = '10'

const limitKey = (pathname, user) => `${pathname.replace(/\//g, '')}#${user.id}__disp_doctemp_limit`

export const getSavedDisplayEmployeeLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user))
    if (!_.isEmpty(limit)) {
      return limit
    }
  }
  const limit = Cookies.get(limitKey(pathname, user))
  return _.isEmpty(limit) ? defaultLimit : limit
}

export const saveDisplayEmployeeLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit)
  } else {
    Cookies.set(limitKey(pathname, user), limit)
  }
}

export const allowedTags = [
  'p',
  'div',
  'span',
  'br',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'a',
  'ul',
  'ol',
  'li',
  'table',
  'tbody',
  'tr',
  'td',
  'pre',
  'strong',
  'em',
  'u',
  's',
  'sub',
  'sup',
  'hr',
  'blockquote',
  'thead',
  'th',
]

export const allowedAttributes = [
  'accept',
  'accept-charset',
  'accesskey',
  'action',
  'align',
  'allowfullscreen',
  'allowtransparency',
  'alt',
  'aria-.*',
  'async',
  'autocomplete',
  'autofocus',
  'autoplay',
  'autosave',
  'background',
  'bgcolor',
  'border',
  'charset',
  'cellpadding',
  'cellspacing',
  'checked',
  'cite',
  'class',
  'color',
  'cols',
  'colspan',
  'content',
  'contenteditable',
  'contextmenu',
  'controls',
  'coords',
  'data',
  'data-.*',
  'datetime',
  'default',
  'defer',
  'dir',
  'dirname',
  'disabled',
  'download',
  'draggable',
  'dropzone',
  'enctype',
  'for',
  'form',
  'formaction',
  'frameborder',
  'headers',
  'height',
  'hidden',
  'high',
  'href',
  'hreflang',
  'http-equiv',
  'icon',
  'id',
  'ismap',
  'itemprop',
  'keytype',
  'kind',
  'label',
  'lang',
  'language',
  'list',
  'loop',
  'low',
  'max',
  'maxlength',
  'media',
  'method',
  'min',
  'mozallowfullscreen',
  'multiple',
  'muted',
  'name',
  'novalidate',
  'open',
  'optimum',
  'pattern',
  'ping',
  'placeholder',
  'playsinline',
  'poster',
  'preload',
  'pubdate',
  'radiogroup',
  'readonly',
  'rel',
  'required',
  'reversed',
  'rows',
  'rowspan',
  'sandbox',
  'scope',
  'scoped',
  'scrolling',
  'seamless',
  'selected',
  'shape',
  'size',
  'sizes',
  'span',
  'src',
  'srcdoc',
  'srclang',
  'srcset',
  'start',
  'step',
  'summary',
  'spellcheck',
  'style',
  'tabindex',
  'target',
  'title',
  'type',
  'translate',
  'usemap',
  'value',
  'valign',
  'webkitallowfullscreen',
  'width',
  'wrap',
]
