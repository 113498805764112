import {gql} from '@apollo/client'

export const SEARCH_OFFICES = gql`
  query searchOffices($per: Int, $page: Int, $search: OfficeSearchInput) {
    client {
      id
      searchOffices(per: $per, page: $page, search: $search) {
        totalCount
        list {
          id
          name
        }
      }
    }
  }
`

export const OFFICES_BY_IDS = gql`
  query officesByIds($ids: [ID!]!) {
    client {
      id
      officesByIds(ids: $ids) {
        id
        name
      }
    }
  }
`

export interface SearchOfficesVariables {
  page: number
  per: number
  search: OfficeSearchInput
}

export interface OfficeSearchInput {
  q?: string
  filterByCurrentOffices?: boolean
}

export interface SearchOfficesResult {
  client: {
    searchOffices: {
      totalCount: number
      list: Office[]
    }
  }
}

export interface OfficesByIdsVariables {
  ids: string[]
}

export interface OfficesByIdsResult {
  client: {
    officesByIds: Office[]
  }
}

export interface Office {
  id: string
  name: string
}
