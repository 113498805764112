import React, {useRef} from 'react'
import {useDispatch} from 'react-redux'
import {push, replace} from 'connected-react-router'
import api from 'api'
import BaseForm, {formatValues, isSubmitting, isPristine, autofill} from 'employees/BaseForm'
import {getState} from 'utils'
import Button from 'jbc-front/components/Button'
import _ from 'lodash'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import {useSelector} from 'hooks/redux'
import {useApolloClient} from '@apollo/client'
import {findOfficeById} from 'libs/graphql/findOfficeById'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const New = () => {
  const formRef = useRef()
  const apolloClient = useApolloClient()
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const submitting = useSelector(isSubmitting)
  const pristine = useSelector(isPristine)

  const handleFormSubmit = async (values) => {
    try {
      const authedApi = api.createWithAuth(token)
      const state = await getState(dispatch)
      const {employmentTypes = [], groups = []} = state.master
      const officeId = values.personnel_history?.[0]?.office_id
      const office = officeId && (await findOfficeById(apolloClient, officeId, {fetchPolicy: 'cache-first'}))
      const {employee, personnelHistory} = formatValues(values, employmentTypes, office, groups)
      const {
        data: {id},
      } = await authedApi.employees.create(employee)
      authedApi.employees.personnelHistories.updateAll(id, [personnelHistory])
      dispatch(push('/employees'))
      dispatch(notifySuccess('追加しました'))
      return id
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  const handleSubmitAndDetail = () => {
    dispatch(autofill('_to_detail', true))
    const submit = formRef.current.submit()
    if (_.isFunction(submit.then)) {
      submit.then((id) => _.isNumber(id) && dispatch(replace(`/employees/${id}/edit`)))
    }
  }

  return (
    <BaseForm
      onSubmit={handleFormSubmit}
      submitText="保存して一覧に戻る"
      initialValues={{personnel_history: [{groups: [{}]}]}}
      otherButtons={
        <AsyncTaskConsumer>
          {({taskRunningProps}) => (
            <Button primary onClick={handleSubmitAndDetail} disabled={pristine || submitting} {...taskRunningProps}>
              保存して詳細情報の入力へ
            </Button>
          )}
        </AsyncTaskConsumer>
      }
      ref={formRef}
    />
  )
}

export default New
