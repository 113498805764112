import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import ProceduresList from 'procedures/maternityLeave/ProceduresList'
import {actionCreators} from 'actions'
import api from 'api'
import {parse} from 'query-string'

const ChooseProcedureForAdmin = ({location: {search}, history}) => {
  const {employee_id} = parse(search)

  const token = useSelector((state) => state.auth.token)
  const procedureStatuses = useSelector((state) => state.procedureStatuses.list.data)

  const dispatch = useDispatch()
  const loadProcedures = () => {
    dispatch(
      actionCreators.procedureStatuses.list.fetchData(
        Promise.all(
          ['start_maternity', 'extend_maternity'].map((procedure_type) =>
            api
              .createWithAuth(token)
              .procedureStatuses.list({procedure_type, employee_id}, ['employee', 'procedure_maternity_datum'])
          )
        ).then((all) => ({data: all.map(({data}) => data).flat()}))
      )
    )
  }

  useEffect(() => {
    loadProcedures()
  }, [])

  const handleClick = (procedureId) => {
    history.push(`/finish_maternity/detail?employee_id=${employee_id}&procedure_id=${procedureId}`)
  }

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">利用する手続き情報を選択してください</h1>
      </div>
      <div className="l-wrap-xl">
        <ProceduresList handleClick={handleClick} procedureStatuses={procedureStatuses} />
      </div>
    </div>
  )
}

export default ChooseProcedureForAdmin
