import React from 'react'
import {number, maxLengthNumber} from 'validators'
import {FieldArray, FormSection} from 'redux-form'
import {Section, BoxDouble, TextField, SelectField, Label} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import {Description, withFormSelectors, getDiffFromOptions} from 'employees/form/common'
import _ from 'lodash'

export const commuteTypes = [
  {value: 'train', label: '電車'},
  {value: 'bus', label: 'バス'},
  {value: 'car', label: '車・二輪車等'},
  {value: 'walk', label: '徒歩'},
  {value: 'train_bus', label: '電車＋バス'},
  {value: 'train_car', label: '電車＋車・二輪車等'},
]

export const vehicleTypes = [
  {value: 'car', label: '車'},
  {value: 'bike', label: 'バイク'},
  {value: 'scooter', label: '原付'},
  {value: 'bicycle', label: '自転車'},
]

export const trainTypes = ['train', 'train_car', 'train_bus']
export const busTypes = ['bus', 'train_bus']
export const carTypes = ['car', 'train_car']

export const paymentDurationUnits = [
  {value: 'per_month', label: '1ヶ月'},
  {value: 'per_3months', label: '3ヶ月'},
  {value: 'per_6months', label: '6ヶ月'},
  {value: 'per_working_days', label: '出勤日数に応じて毎月支給'},
]

export const isEach3MonthsPayments = [
  {value: 'on_1_4_7_10', label: '1月/4月/7月/10月'},
  {value: 'on_2_5_8_11', label: '2月/5月/8月/11月'},
  {value: 'on_3_6_9_12', label: '3月/6月/9月/12月'},
]

export const isEach6MonthsPayments = [
  {value: 'on_1_7', label: '1月/7月'},
  {value: 'on_2_8', label: '2月/8月'},
  {value: 'on_3_9', label: '3月/9月'},
  {value: 'on_4_10', label: '4月/10月'},
  {value: 'on_5_11', label: '5月/11月'},
  {value: 'on_6_12', label: '6月/12月'},
]

// diff用の検索オプション
export const isEachDiffPayments = [
  {value: 'on_1_4_7_10', label: '1月/4月/7月/10月'},
  {value: 'on_2_5_8_11', label: '2月/5月/8月/11月'},
  {value: 'on_3_6_9_12', label: '3月/6月/9月/12月'},
  {value: 'on_1_7', label: '1月/7月'},
  {value: 'on_2_8', label: '2月/8月'},
  {value: 'on_3_9', label: '3月/9月'},
  {value: 'on_4_10', label: '4月/10月'},
  {value: 'on_5_11', label: '5月/11月'},
  {value: 'on_6_12', label: '6月/12月'},
]

const MONTHLY = 1

export const paymentTypeForDurationUnits = (value) => {
  switch (value) {
    case 'per_3months':
      return isEach3MonthsPayments
    case 'per_6months':
      return isEach6MonthsPayments
    default:
      return MONTHLY
  }
}

export const carCommutingDistanceTypes = [
  {value: 'ds_under_2', label: '2km未満'},
  {value: 'ds_2_10', label: '2km～10km'},
  {value: 'ds_10_15', label: '10km～15km'},
  {value: 'ds_15_25', label: '15km～25km'},
  {value: 'ds_25_35', label: '25km～35km'},
  {value: 'ds_35_45', label: '35km～45km'},
  {value: 'ds_45_55', label: '45km～55km'},
  {value: 'ds_over_55', label: '55km以上'},
]

const renderCommutingExpense = ({fields, commutingExpenseType, selector, diffLst = []}) => (
  <div>
    <Label text={commutingExpenseType === 'train' ? '電車' : 'バス'} />
    {fields.map((expense, index) => {
      const diff = diffLst[index] || {}
      return (
        <Section
          title="経路"
          key={index}
          icon={
            <DeleteSquare
              onClick={() => {
                fields.remove(index)
                if (fields.length === 1) {
                  fields.push({})
                }
              }}
            />
          }
        >
          <TrainBusCommutingExpense
            diff={diff}
            {...{
              index,
              commutingExpenseType,
              expense,
              selector,
            }}
            key={index}
          />
        </Section>
      )
    })}
    <div className="u-ta-r u-mt20 u-mb20">
      <div onClick={() => fields.push({})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        経路を追加
      </div>
    </div>
  </div>
)

export const CommutingAllowanceInfomation = ({
  description,
  description_color_by_rgb,
  commuteType,
  carPaymentDurationUnit,
  selector,
  diff = {},
}) => (
  <Section title="通勤手当">
    <Description {...{description, description_color_by_rgb}} />
    <SelectField name="commute_type" label="通勤手段" options={commuteTypes} diff={diff.commute_type} />
    <FormSection name="commuting_expenses">
      <LabelMapper name="commuting_expenses" label="通勤手当の" />
      {trainTypes.includes(commuteType) && (
        <React.Fragment>
          <FieldArray
            name="train"
            component={renderCommutingExpense}
            commutingExpenseType="train"
            diffLst={_.get(diff, 'commuting_expenses.train')}
            {...{selector}}
          />
          <LabelMapper name="train" label="電車の" />
        </React.Fragment>
      )}
      {busTypes.includes(commuteType) && (
        <React.Fragment>
          <FieldArray
            name="bus"
            component={renderCommutingExpense}
            commutingExpenseType="bus"
            diffLst={_.get(diff, 'commuting_expenses.bus')}
            {...{selector}}
          />
          <LabelMapper name="bus" label="バスの" />
        </React.Fragment>
      )}
      {carTypes.includes(commuteType) && (
        <div>
          <Label text="車・二輪車等" />
          <Section title="経路">
            <FormSection name="car">
              <SelectField
                name="vehicle_type"
                label="車両種別"
                options={vehicleTypes}
                diff={_.get(diff, 'commuting_expenses.car.vehicle_type')}
              />
              <BoxDouble>
                <TextField name="get_on" label="出発地" diff={_.get(diff, 'commuting_expenses.car.get_on')} />
                <TextField name="get_off" label="到着地" diff={_.get(diff, 'commuting_expenses.car.get_off')} />
              </BoxDouble>
              <SelectField
                name="car_commuting_distance_type"
                label="片道の通勤距離"
                options={carCommutingDistanceTypes}
                diff={_.get(diff, 'commuting_expenses.car.car_commuting_distance_type')}
              />
              <SelectField
                name="payment_duration_unit"
                label="支給単位"
                options={paymentDurationUnits}
                diff={_.get(diff, 'commuting_expenses.car.payment_duration_unit')}
              />
              {paymentTypeForDurationUnits(carPaymentDurationUnit) != MONTHLY && (
                <SelectField
                  name="payment_duration_type"
                  label="支給月"
                  options={paymentTypeForDurationUnits(carPaymentDurationUnit)}
                  diff={getDiffFromOptions(
                    isEachDiffPayments,
                    _.get(diff, 'commuting_expenses.car.payment_duration_type')
                  )}
                />
              )}
              <TextField
                name="pass_price"
                label="支給額"
                validate={[number, maxLengthNumber(9)]}
                yen
                diff={_.get(diff, 'commuting_expenses.car.pass_price')}
              />
              <LabelMapper name="car" label="車・二輪車等の" />
            </FormSection>
          </Section>
        </div>
      )}
    </FormSection>
  </Section>
)

export default CommutingAllowanceInfomation
  |> connect((state, {selector}) => ({
    commuteType: selector(state, 'commute_type'),
    carPaymentDurationUnit: selector(state, 'commuting_expenses.car.payment_duration_unit'),
    diff: selector(state, '_diff'),
  }))
  |> withFormSelectors

const renderTrainBusCommutingExpense = ({
  expense,
  trainPaymentDurationUnit,
  busPaymentDurationUnit,
  commutingExpenseType,
  diff = {},
}) => (
  <FormSection name={expense}>
    <BoxDouble>
      <TextField name="get_on" label={commutingExpenseType === 'train' ? '乗駅' : '乗車停留所'} diff={diff.get_on} />
      <TextField name="get_off" label={commutingExpenseType === 'train' ? '降駅' : '降車停留所'} diff={diff.get_off} />
    </BoxDouble>
    <TextField name="via" label="経由地" diff={diff.via} />
    <SelectField
      name="payment_duration_unit"
      label="支給単位"
      options={paymentDurationUnits}
      diff={diff.payment_duration_unit}
    />
    {trainTypes.includes(commutingExpenseType) && paymentTypeForDurationUnits(trainPaymentDurationUnit) != MONTHLY && (
      <SelectField
        name="payment_duration_type"
        label="支給月"
        options={paymentTypeForDurationUnits(trainPaymentDurationUnit)}
        diff={getDiffFromOptions(isEachDiffPayments, diff.payment_duration_type)}
      />
    )}
    {busTypes.includes(commutingExpenseType) && paymentTypeForDurationUnits(busPaymentDurationUnit) != MONTHLY && (
      <SelectField
        name="payment_duration_type"
        label="支給月"
        options={paymentTypeForDurationUnits(busPaymentDurationUnit)}
        diff={getDiffFromOptions(isEachDiffPayments, diff.payment_duration_type)}
      />
    )}
    <BoxDouble>
      <TextField
        name="single_fare"
        label="片道運賃"
        validate={[number, maxLengthNumber(9)]}
        yen
        diff={diff.single_fare}
      />
      <TextField name="pass_price" label="定期代" validate={[number, maxLengthNumber(9)]} yen diff={diff.pass_price} />
    </BoxDouble>
  </FormSection>
)

const TrainBusCommutingExpense = connect((state, {index, selector}) => ({
  trainPaymentDurationUnit: selector(state, `commuting_expenses.train[${index}].payment_duration_unit`),
  busPaymentDurationUnit: selector(state, `commuting_expenses.bus[${index}].payment_duration_unit`),
}))(renderTrainBusCommutingExpense)
