import {FC, useEffect, useRef} from 'react'
import {useDispatch} from 'react-redux'
import _ from 'lodash'
import {useSelector} from 'hooks/redux'

// @ts-ignore
import api from 'api'
// @ts-ignore
import {actionCreators} from 'actions'

interface MasterProps {
  masters: string[]
}

export const Master: FC<MasterProps> = ({masters}) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const previousMasters = useRef<string[]>([])

  useEffect(() => {
    const newMasters = _.difference(masters, previousMasters.current)
    newMasters.forEach((master) => {
      dispatch(actionCreators.master.fetchData(api.createWithAuth(token).tm[master].list()))
    })
    previousMasters.current = masters
  }, [masters, dispatch, token])

  return null
}

export default Master
