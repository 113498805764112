import React from 'react'
import {
  autofill as autofillForm,
  reduxForm,
  Field,
  getFormSyncErrors,
  formValueSelector,
  reset as resetForm,
} from 'redux-form'
import {arrowRenderer, Error, Section} from 'jbc-front/components/Form'
import styles from 'employees/csvFormat/Form.scss'
import Select from 'react-select'
import {Delete as DeleteIcon} from 'jbc-front/components/icons'
import {required} from 'jbc-front/utils/validators'
import MultiList, {addResult} from 'employees/csvFormat/MultiList'
import _ from 'lodash'
import Button from 'jbc-front/components/Button'
import {getState} from 'utils'
import api from 'api'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import compose from 'lodash/fp/compose'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {parse, unparse} from 'papaparse'
import {parseBackUrl} from 'utils'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const csvConfig = {delimiter: ',', quoteChar: '"'}

const formName = 'csvDownload'
const autofill = autofillForm.bind(null, formName)
const getSyncErrors = getFormSyncErrors(formName)
const selector = formValueSelector(formName)
export const reset = resetForm.bind(null, formName)

const renderSelectField = ({input, label, options, note, placeholder, meta, icons, ...rest}) => (
  <div className={meta.error && meta.touched ? styles.invalidField : styles.field}>
    <div className={styles.note}>{note}</div>
    <div className={styles.fieldWrap}>
      <div className={styles.icnTextarea}>
        <Select
          name={input.name}
          value={input.value}
          onChange={input.onChange}
          onBlur={() => input.onBlur(input.value)}
          options={options}
          placeholder={placeholder}
          simpleValue
          arrowRenderer={arrowRenderer}
          clearable={false}
          autosize={false}
          {...rest}
        />
      </div>
      {icons && <div className={styles.icons}>{icons}</div>}
    </div>
    <Error meta={meta} label={label} />
  </div>
)

export const renderInputField = ({
  input,
  label,
  type,
  meta,
  readOnly,
  disabled,
  maxLength,
  placeholder,
  note,
  as = 'input',
}) => {
  const Input = as
  return (
    <div className={styles.field}>
      <div className={styles.label}>{label}</div>
      <div className={styles.fieldWrap}>
        <div className={styles.icnTextarea}>
          <Input
            {...{readOnly, disabled, type, ...input}}
            className={meta.error && meta.touched ? `${styles.invalid}` : ''}
            maxLength={maxLength}
            placeholder={placeholder}
          />
        </div>
      </div>
      <Error meta={meta} label={label} />
      {note && <div className={styles.note}>{note}</div>}
    </div>
  )
}

const renderMultiList = ({input, meta, availableFields}) => (
  <React.Fragment>
    <MultiList {...input} availableFields={availableFields} />
    {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
  </React.Fragment>
)

const resultValidate = (result) => {
  if (_.isEmpty(result)) {
    return '項目を1個以上選択してください'
  }
}

const toColumns = (header) => parse(header, csvConfig).data[0]

const headerValidate = (header, {export_csv_format_fields: result}) => {
  if (!header) {
    return
  }
  if (_.isEmpty(result)) {
    return 'と選択済の項目が一致しません'
  }
  const {errors, data} = parse(header, csvConfig)
  if (!_.isEmpty(errors)) {
    return 'の形式が正しくありません'
  }
  if (data[0].length !== result.length) {
    return 'と選択済の項目が一致しません'
  }
  if (_.uniq(data[0]).length !== data[0].length) {
    return 'に重複の項目があります'
  }
}

const makeHeaders = (result) => unparse([_.map(result, 'label_alias')], csvConfig)

const Delete = withModal(({showModal, hideModal, isModalOpen, dispatch, id, goBack}) => (
  <React.Fragment>
    <a onClick={showModal} className={styles.deleteIcon}>
      <DeleteIcon size={20} />
    </a>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      <Modal.Header hideModal={hideModal}>削除確認</Modal.Header>
      <Modal.Body>本当に削除してよろしいですか？</Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button
            primary
            onClick={async () => {
              hideModal()
              const {
                auth: {token},
              } = await getState(dispatch)
              await api.createWithAuth(token).employees.csvFormats.delete(id)
              goBack()
            }}
          >
            削除
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </Modal>
  </React.Fragment>
))

const Form = ({
  handleSubmit,
  dispatch,
  submitting,
  mode,
  formatOptions,
  goBack,
  id,
  isModalOpen,
  showModal,
  hideModal,
  dirty,
  format,
  availableFields,
  location,
}) => (
  <Section title="フォーマットの編集">
    {mode === 'edit' && (
      <React.Fragment>
        <Field
          name="_format"
          label="フォーマット"
          component={renderSelectField}
          options={formatOptions}
          icons={<Delete {...{dispatch, id, goBack}} />}
          onChange={(_v, v, p) => {
            const backUrl = parseBackUrl(location)
            if (v !== p) {
              if (dirty) {
                showModal()
              } else {
                dispatch(
                  push(`/employees/csv_formats/${v}` + (backUrl ? `?back_to=${encodeURIComponent(backUrl)}` : ''))
                )
              }
            }
          }}
        />
        <Modal isOpen={isModalOpen} hideModal={hideModal}>
          <Modal.Header hideModal={hideModal}>ページ移動</Modal.Header>
          <Modal.Body>
            ページを移動すると保存されてない内容が失われます。
            <br />
            本当に移動してよろしいですか？
          </Modal.Body>
          <Modal.Footer>
            <Modal.Buttons>
              <Button onClick={hideModal}>キャンセル</Button>
              <Button
                primary
                onClick={() => {
                  const backUrl = parseBackUrl(location)
                  dispatch(
                    push(
                      `/employees/csv_formats/${format}` + (backUrl ? `?back_to=${encodeURIComponent(backUrl)}` : '')
                    )
                  )
                  hideModal()
                }}
              >
                移動
              </Button>
            </Modal.Buttons>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )}
    <Field
      name="label"
      component={renderInputField}
      label="フォーマット名"
      validate={required}
      placeholder="フォーマット名を入力してください"
      type="text"
    />
    <Field
      name="_header"
      component={renderInputField}
      label="ヘッダ"
      validate={headerValidate}
      placeholder="CSVヘッダ"
      type="text"
      as="textarea"
      onBlur={(e, header) =>
        dispatch((dispatch, getState) => {
          if (!_.get(getSyncErrors(getState()), '_header')) {
            const result = selector(getState(), 'export_csv_format_fields')
            if (_.isEmpty(result)) {
              return
            }
            if (_.isEmpty(header)) {
              dispatch(autofill('_header', makeHeaders(result)))
              e.preventDefault()
              return
            }
            const columns = toColumns(header)
            const newResult = result.map((item, index) => ({
              ...item,
              label_alias: columns[index],
            }))
            dispatch(autofill('export_csv_format_fields', newResult))
            dispatch(autofill('_header', makeHeaders(newResult)))
            e.preventDefault()
          }
        })
      }
      note={
        <React.Fragment>
          ヘッダ(CSVファイルに出力される項目名)を変更できます。
          <br />
          但し、変更したヘッダでアップロードすることはできないのでご注意ください。
        </React.Fragment>
      }
    />
    <Field
      component={renderMultiList}
      name="export_csv_format_fields"
      onChange={(e, v) => dispatch(autofill('_header', makeHeaders(v)))}
      validate={resultValidate}
      availableFields={availableFields}
    />
    <div className={styles.buttons}>
      <Button onClick={goBack}>戻る</Button>
      <Button primary disabled={submitting} onClick={handleSubmit}>
        保存
      </Button>
    </div>
  </Section>
)

export default compose(
  withModal,
  connect((state) => ({
    format: selector(state, '_format'),
    location: state.router.location,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmit: async (values, dispatch, {mode, id, goBack}) => {
      try {
        const {
          auth: {token},
        } = await getState(dispatch)
        const {export_csv_format_fields: exportCsvFormatFields, ...format} = values
        const authedApi = api.createWithAuth(token)
        if (mode === 'create' || mode === 'duplicate') {
          const res = await authedApi.employees.csvFormats.create(format)
          id = res.data.id
        } else {
          await authedApi.employees.csvFormats.update(id, format)
        }
        await authedApi.employees.csvFormats.updateFields(id, {
          export_csv_format_fields: exportCsvFormatFields.map((field, index) => ({
            ...field,
            position: index + 1,
            label_alias: field.label_alias === field._label ? null : field.label_alias || '',
          })),
        })
        dispatch(notifySuccess('保存しました'))
        goBack()
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Form)

export const makeInitialValues = ({mode, csvFormat, availableFields, id}) => {
  const values = {}
  if (csvFormat && mode === 'edit') {
    values.label = csvFormat.label
  }
  if ((mode === 'edit' || (mode === 'duplicate' && id > 0)) && csvFormat && availableFields.length > 0) {
    const formatFields = csvFormat.export_csv_format_fields.map((formatField) => ({
      ...formatField,
      _id:
        formatField.kind === 'space'
          ? _.uniqueId()
          : availableFields.find(
              (field) => formatField.target_type === field.target_type && formatField.target_id === field.id
            )._id,
      _label: formatField.label,
      label_alias: formatField.label_alias === null ? formatField.label : formatField.label_alias,
    }))
    Object.assign(values, {
      _header: makeHeaders(formatFields),
      export_csv_format_fields: formatFields,
    })
  }
  if (mode === 'duplicate' && id < 0 && availableFields) {
    const formatFields = availableFields.filter(({kind}) => kind !== 'composition').map(addResult)
    Object.assign(values, {
      _header: makeHeaders(formatFields),
      export_csv_format_fields: formatFields,
    })
  }
  if (mode === 'edit' && csvFormat) {
    values._format = `${csvFormat.id}`
  }
  return values
}
