import {FC} from 'react'
import {FileUploadForm} from './parts/FileUploadForm'
import {CsvFileDescription} from './parts/CsvFileDescription'

export const Presenter: FC = () => {
  return (
    <div>
      <div className="l-title-wrap u-pb30">
        <h1 className="m-title-main">グループ情報の登録</h1>
      </div>
      <FileUploadForm />
      <div className="u-mt100">
        <CsvFileDescription />
      </div>
    </div>
  )
}
