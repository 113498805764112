import {FC} from 'react'
import {DELETE_COMMENT, DOCUMENTS} from '../../query'
import {Modal} from 'jbc-front/components/presenters/ui/Modal'
import {ButtonRow} from 'jbc-front/components/presenters/layout/ButtonRow'
import Button from 'jbc-front/components/Button'

// @ts-ignore
import {useMutation} from 'components/Graphql'

type MemoDeleteModal = {
  memoId: number
  isOpen: boolean
  onClose: () => void
}

export const MemoDeleteModal: FC<MemoDeleteModal> = ({memoId, isOpen, onClose}) => {
  const [deleteMemo, {loading}] = useMutation(DELETE_COMMENT)

  const handleSubmit = async () => {
    await deleteMemo({
      variables: {input: {id: Number(memoId)}},
      refetchQueries: [{query: DOCUMENTS}],
    })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header onClose={onClose}>削除の確認</Modal.Header>
      <Modal.Body>
        <p>メモを削除しますか</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonRow>
          <Button onClick={onClose} disabled={loading}>
            いいえ
          </Button>
          <Button primary onClick={() => handleSubmit()} disabled={loading}>
            はい
          </Button>
        </ButtonRow>
      </Modal.Footer>
    </Modal>
  )
}
