import React from 'react'
import {connect} from 'react-redux'
import {Field, autofill, reduxForm, getFormValues, reset, getFormMeta} from 'redux-form'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {getState} from 'utils'
import {actionCreators} from 'actions'
import AdditionalSearchFields from 'settings/clientRoles/AdditionalSearchFields'
import SortingFieldsWithResult from 'settings/clientRoles/SortingFieldsWithResult'
import styles from 'settings/clientRoles/SearchForm.scss'
import searchDetector from 'searchDetector'
import {MainFormControls, FilteringTag} from 'jbc-front/components/SearchForm'
import {
  convertFormToQuery,
  getSavedDisplayEmployeeLimit,
  saveDisplayEmployeeLimit,
  convertQueryToForm,
  convertFormToQueryString,
  fetchSelections,
} from 'employees/list/utils'
import {getCurrentQueryFromLocation, defaultQuery, formName, fetchUsersClients} from 'settings/clientRoles/utils'
import compose from 'lodash/fp/compose'
import {clientRoles} from 'settings/clientRoles/ChangeMulti'

const searchUserOptions = [
  {value: 'all', name: 'すべて'},
  ...clientRoles.map((role) => ({
    value: role.value,
    name: role.label,
  })),
]

export const SearchUserTypeField = ({input: inputProps, searchUserOptions, onUserTypeChange}) => {
  return (
    <div className={styles.searchUserTypeField}>
      {searchUserOptions.map((item) => (
        <div className={styles.noteWrap} key={item.value}>
          <FilteringTag
            selected={item.value === inputProps.value}
            onClick={() => {
              const newValue = item.value
              inputProps.onChange(newValue)
              inputProps.onBlur(newValue)
              onUserTypeChange()
            }}
          >
            {item.name}
          </FilteringTag>
          {item.note && (
            <div className={styles.note}>
              <p>{item.note}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

class SearchForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }

  componentDidMount() {
    const {token, dispatch} = this.props
    fetchSelections(token, dispatch)
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('selections'))
  }

  clearForm = () => {
    const {dispatch} = this.props
    getState(dispatch).then((state) => {
      dispatch(reset(formName))
      dispatch(push({...state.router.location, search: ''}))
    })
  }

  render() {
    const {pathname, formValues, handleSubmit, buttons, user} = this.props
    return (
      <div className={styles.searchForm}>
        <form onSubmit={handleSubmit}>
          <div className={styles.mainSearchFields}>
            <Field
              name="client_role_id"
              component={SearchUserTypeField}
              searchUserOptions={searchUserOptions}
              onUserTypeChange={handleSubmit}
            />
            <MainFormControls
              isExpand={this.state.isExpand}
              hasConditions={!_.isEqual(convertFormToQuery(formValues), defaultQuery)}
              handleClear={this.clearForm}
              onToggleClick={() => {
                this.setState({isExpand: !this.state.isExpand})
              }}
            />
          </div>
          {this.state.isExpand && <AdditionalSearchFields />}
          <div className={styles.buttons}>{buttons}</div>
          <div className={styles.sortingFields}>
            <SortingFieldsWithResult
              failureMessage="従業員が見つかりませんでした"
              limit={getSavedDisplayEmployeeLimit(pathname, user)}
              onChangeLimit={(newLimit) => {
                saveDisplayEmployeeLimit(pathname, user, newLimit)
                handleSubmit()
              }}
              onChangeSortType={handleSubmit}
            />
          </div>
        </form>
      </div>
    )
  }
}

export default compose(
  searchDetector({
    convertQueryToForm,
    convertFormToQueryString,
    getCurrentQueryFromLocation,
    fetchData(query, additionalParams, dispatch) {
      dispatch(fetchUsersClients(query, additionalParams))
    },
    destroy(dispatch) {
      dispatch(actionCreators.fetchDestroy('users_clients'))
    },
  }),
  connect((state) => ({
    token: state.auth.token,
    pathname: state.router.location.pathname,
    formValues: getFormValues(formName)(state),
    user: state.session.currentUser,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmit(values, dispatch, {handleSearch}) {
      getState(dispatch).then((state) => {
        const autofilled = _.get(getFormMeta(formName)(state), 'page.autofilled')
        if (!autofilled) {
          dispatch(autofill(formName, 'page', 1))
        }
        getState(dispatch).then((state) => {
          const formValues = getFormValues(formName)(state)
          handleSearch(formValues.client_role_id === 'all' ? _.omit(formValues, 'client_role_id') : formValues)
        })
      })
    },
  })
)(SearchForm)
