import React from 'react'
import {connect} from 'react-redux'
import {autofill, submit} from 'redux-form'
import Paginator from 'jbc-front/components/Paginator'
import {formName, selector} from './utils'
import styles from './SearchForm.scss'

const PaginatorWithResult = ({limit, count, page, onClick}) => {
  return (
    <div>
      {count > 0 ? (
        <div className={styles.paginatorWithResult}>
          <Paginator current={page} totalResult={count} rowsPerPage={limit} pageLinks={7} onClick={onClick} />
        </div>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: state.employees.list.count,
  isLoading: state.employees.list.loading,
  page: selector(state, 'page'),
})
const mapDispatchToProps = (dispatch) => ({
  onClick: (page) => {
    dispatch(autofill(formName, 'page', page.toString()))
    dispatch(submit(formName))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(PaginatorWithResult)
