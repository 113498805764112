import _ from 'lodash'

const EMPLOYEE_ROLE_ID = 2

export const getPermission = (field, currentUser) => {
  if (['full_admin', 'office_admin', 'limited_admin'].includes(_.get(currentUser, 'client_role.client_role_type'))) {
    return 'updatable'
  }
  const permission = field.custom_employee_field_permissions.find(
    (permission) => permission.client_role_id === _.get(currentUser, 'client_role.id', EMPLOYEE_ROLE_ID)
  )
  if (!permission) {
    return 'not_display'
  }
  return permission.permission
}

const defaultSpouseIncome = {
  spouse_annual_income: null,
  spouse_monthly_income: null,
}

export const formatValues = (formData) =>
  formData.marital_status === 'married' ? formData : {...formData, ...defaultSpouseIncome}
