import React from 'react'
import {DateField, Section, RadioField, TextField} from 'jbc-front/components/Form'
import {reduxForm} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'

const formName = 'changeAddress'

const retiredTypes = [
  {value: 'others', label: '一般退職'},
  {value: 'disability', label: '障害退職'},
  {value: 'passed_away', label: '死亡退職'},
]

const Form = ({submitting, handleSubmit, employee}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">退職情報を入力してください</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section title="退職情報">
        <DateField name="date_on" label="退職日" required />
        <RadioField name="retire_type" label="退職区分" required options={retiredTypes} />
        <TextField name="retire_reason" label="退職理由" />
      </Section>
      <div className="u-ta-c u-mt30">
        <AsyncTaskConsumer>
          {({taskRunningProps}) => (
            <Button primary onClick={handleSubmit} disabled={submitting} {...taskRunningProps}>
              次へ
            </Button>
          )}
        </AsyncTaskConsumer>
      </div>
    </div>
  </form>
)

export const formatValues = ({date_on: dateOn, ...employee}) => ({
  dateOn,
  employee: {
    retired_at: dateOn,
    ...employee,
  },
})

export default reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(Form)
