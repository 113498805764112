import moment from 'moment'
import {FORMAT} from 'consts/format'

// @ts-ignore
import {toJpYearDate} from 'jbc-front/utils/jpYear'

export const displayFormat = (value?: string): string => (value ? value : FORMAT.DEFAULT_STRING)

export const dateFormat = (date?: string, format: 'wareki' | 'seireki' | string = 'wareki'): string => {
  if (!date) return displayFormat()

  switch (format) {
    case 'wareki':
      return toJpYearDate(date)
    case 'seireki':
      return moment(date).format('LL')
    default:
      return moment(date).format(format)
  }
}

export const dayOrTimeFormat = (dateTime?: string) => {
  if (!dateTime) return displayFormat()

  if (moment().isSame(dateTime, 'day')) {
    return moment(dateTime).format('LT')
  }
  if (moment().subtract(1, 'days').isSame(dateTime, 'day')) {
    return '昨日'
  }
  if (moment().subtract(2, 'days').isSame(dateTime, 'day')) {
    return '一昨日'
  }
  if (moment().isSame(dateTime, 'year')) {
    return moment(dateTime).format('MMMDo')
  }

  return moment(dateTime).format('LL')
}

export const fullNameFormat = (lastName?: string, firstName?: string): string => {
  if (!(lastName && firstName)) return displayFormat()
  return `${lastName} ${firstName}`
}

export const booleanFormat = (args: {value: boolean; truthyString?: string; falsyString?: string}): string => {
  if (args.value) {
    return args.truthyString === undefined ? FORMAT.TRUTHY_STRING : args.truthyString
  } else {
    return args.falsyString === undefined ? FORMAT.FALSY_STRING : args.falsyString
  }
}

export const numbersFormat = <T>(numbers: T[], delimiter = '-') => {
  // 全ての要素がtruthyの時だけ出力
  if (numbers.length === 0 || numbers.some((num) => !num)) {
    return FORMAT.DEFAULT_STRING
  }
  return numbers.join(delimiter)
}

export type FileType = {
  url?: string
  filename?: string
  file?: string
}

export const fileFormFormat = (file?: FileType | File) => {
  if (file instanceof File) {
    return {
      url: '',
      name: file.name,
    }
  }

  if (!file || !file?.filename || !file?.url) {
    return undefined
  }

  return {
    url: file.url,
    name: file.filename,
  }
}
