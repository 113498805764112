import React, {Component} from 'react'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {Dl, Dd, Dt} from 'components/SecondaryPanel'
import * as Tertiary from 'components/TertiaryPanel'
import {recordDisplay} from 'utils'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {fetchSelector, actionCreators} from 'actions'
import api from 'api'
import {Edit} from 'jbc-front/components/icons'
import {agentTypes} from 'settings/laborConsultant/Form'

const Person = ({person}) => (
  <Dl>
    <Dt>法人団体名：</Dt>
    <Dd>
      {recordDisplay(
        person.corporate_name &&
          person.corporate_name + (person.corporate_name_kana ? `(${person.corporate_name_kana})` : '')
      )}
    </Dd>
    <Dt>住所：</Dt>
    <Dd>{recordDisplay.address(person)}</Dd>
    <Dt>役職：</Dt>
    <Dd>{recordDisplay(person.position)}</Dd>
    <Dt>氏名：</Dt>
    <Dd>{recordDisplay.fullName(person, true)}</Dd>
    <Dt>電話番号：</Dt>
    <Dd>{recordDisplay.numbers(person, 'phone_number', 3)}</Dd>
    <Dt>メールアドレス：</Dt>
    <Dd>{recordDisplay(person.email)}</Dd>
  </Dl>
)

class Show extends Component {
  componentDidMount() {
    const {
      token,
      loadApplicantInformation,
      loadContactInformation,
      loadCertificateFile,
      loadSubmissionDestination,
      loadLaborConsultant,
    } = this.props
    loadApplicantInformation(token)
    loadContactInformation(token)
    loadCertificateFile(token)
    loadSubmissionDestination(token)
    loadLaborConsultant(token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  static defaultProps = {
    applicantInformation: {},
    contactInformation: {},
    certificateFile: {},
    submissionDestination: {},
    laborConsultant: {},
  }

  render() {
    const {applicantInformation, contactInformation, certificateFile, submissionDestination, laborConsultant} =
      this.props
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">電子申請情報</h1>
          <p className="m-title-main-note">電子申請の情報を設定できます</p>
        </div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <span className="l-breadcrumb-here">電子申請情報</span>
          </div>
          <Panel>
            <Link to="/settings/egov/edit#basic_infomation">
              <Headding icon={<Edit icon={16} />}>基本情報</Headding>
            </Link>
            <div className="u-pc-pt15 u-pc-pr15 u-pc-pl15">
              <Item>
                <Tertiary.Panel>
                  <Tertiary.Headding>申請者・提出者に関する情報</Tertiary.Headding>
                  <Tertiary.Item>
                    <Person person={applicantInformation} />
                  </Tertiary.Item>
                </Tertiary.Panel>
                <Tertiary.Panel>
                  <Tertiary.Headding>連絡先に関する情報</Tertiary.Headding>
                  <Tertiary.Item>
                    <Person person={contactInformation} />
                  </Tertiary.Item>
                </Tertiary.Panel>
                {contactInformation.is_labor_consultant && (
                  <Tertiary.Panel>
                    <Tertiary.Headding>社会保険労務士に関する情報</Tertiary.Headding>
                    <Tertiary.Item>
                      <Dl>
                        <Dt>代理区分：</Dt>
                        <Dd>{recordDisplay.option(laborConsultant.agent_type, agentTypes)}</Dd>
                        <Dt>代理者代行証明書</Dt>
                        <Dd>{recordDisplay.file(laborConsultant.egov_acting_certificate_file)}</Dd>
                      </Dl>
                    </Tertiary.Item>
                  </Tertiary.Panel>
                )}
              </Item>
            </div>
          </Panel>
          {contactInformation.is_labor_consultant === false && (
            <Panel>
              <Link to="/settings/egov/edit#certificate">
                <Headding icon={<Edit icon={16} />}>電子証明書</Headding>
              </Link>
              <div className="u-pc-pt15 u-pc-pr15 u-pc-pl15">
                <Item>
                  <Dl>
                    <Dt>電子証明書：</Dt>
                    <Dd>{recordDisplay.file(certificateFile.file)}</Dd>
                  </Dl>
                </Item>
              </div>
            </Panel>
          )}
          <Panel>
            <Link to="/settings/egov/edit#submission_destination">
              <Headding icon={<Edit icon={16} />}>届出の管轄</Headding>
            </Link>
            <div className="u-pc-pt15 u-pc-pr15 u-pc-pl15">
              <Item>
                <Dl>
                  <Dt>公共職業安定所：</Dt>
                  <Dd>{recordDisplay(submissionDestination.hello_work_name)}</Dd>
                  <Dt>年金事務所：</Dt>
                  <Dd>{recordDisplay(submissionDestination.pension_center_name)}</Dd>
                </Dl>
              </Item>
            </div>
          </Panel>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    applicantInformation: fetchSelector(state, 'applicant_information').data,
    contactInformation: fetchSelector(state, 'contact_information').data,
    certificateFile: fetchSelector(state, 'certificate_file').data,
    submissionDestination: fetchSelector(state, 'submission_destination').data,
    laborConsultant: fetchSelector(state, 'labor_consultant').data,
  }),
  (dispatch) => ({
    loadApplicantInformation(token) {
      dispatch(actionCreators.fetchData('applicant_information', api.createWithAuth(token).applicantInformation.get()))
    },
    loadContactInformation(token) {
      dispatch(actionCreators.fetchData('contact_information', api.createWithAuth(token).contactInformation.get()))
    },
    loadCertificateFile(token) {
      dispatch(actionCreators.fetchData('certificate_file', api.createWithAuth(token).certificateFile.get()))
    },
    loadSubmissionDestination(token) {
      dispatch(
        actionCreators.fetchData('submission_destination', api.createWithAuth(token).submissionDestination.get())
      )
    },
    loadLaborConsultant(token) {
      dispatch(actionCreators.fetchData('labor_consultant', api.createWithAuth(token).clients.laborConsultant.get()))
    },
    destroy() {
      dispatch(actionCreators.fetchDestroy('applicant_information'))
      dispatch(actionCreators.fetchDestroy('contact_information'))
      dispatch(actionCreators.fetchDestroy('certificate_file'))
      dispatch(actionCreators.fetchDestroy('submission_destination'))
      dispatch(actionCreators.fetchDestroy('labor_consultant'))
    },
  })
)(Show)
