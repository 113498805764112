import {useEffect, useState} from 'react'
import axios, {AxiosRequestConfig} from 'axios'

type Valid = boolean | undefined

type CheckError = Error | undefined

export interface CheckResult {
  isLoading: boolean
  isValid: Valid
  errors: CheckError
}

export const useCheckConfirmationToken = (path: string, params: AxiosRequestConfig['params']): CheckResult => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isValid, setIsValid] = useState<Valid>(undefined)
  const [errors, setErrors] = useState<CheckError>(undefined)

  const checkConfirmationToken = async (): Promise<void> => {
    try {
      await axios.get(path, params)
      setIsValid(true)
    } catch (e) {
      if (e.response && e.response.status === 422) {
        setIsValid(false)
      } else {
        setErrors(e)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    checkConfirmationToken()
  }, [])

  return {isLoading, isValid, errors}
}
