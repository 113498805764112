import React from 'react'
import {useState} from 'react'
import {TextField, CheckboxField, SelectField, Section, Label, BoxDouble} from 'jbc-front/components/Form'
import {formValueSelector, reduxForm} from 'redux-form'
import {SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import {MyNumber} from 'reportForms/common'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'
import styles from 'reportForms/common.scss'
import _ from 'lodash'
import {required, maxLengthNumber, maxLength} from 'validators'
import {ConfirmUpgradeModal} from '../components/modals/ConfirmUpgradeModal'

const formName = 'R1Report'
const selector = formValueSelector(formName)

const genderTypes = [
  {value: 'man', label: '1. 男性'},
  {value: 'woman', label: '2. 女性'},
  {value: 'coal_mine_husband', label: '3. 坑内員'},
  {value: 'pension_joined_man', label: '5. 厚生年金基金加入済み男性'},
  {value: 'pension_joined_woman', label: '6. 厚生年金基金加入済み女性'},
  {value: 'pension_joined_coal_mine_husband', label: '7. 厚生年金基金加入済み坑内員'},
]

// その他健保用（旧帳票）
const acquisitionTypes = [
  {value: 'first_join', label: '1. 健保・厚年'},
  {value: 'expatriated', label: '3. 共済出向'},
  {value: 'sailor_insurance_ongoing', label: '4. 船保任継'},
]

// 協会けんぽ用（新帳票）
const newAcquisitionTypes = [
  {value: 'first_join', label: '1. 健保・厚年'},
  {value: 'expatriated', label: '3. 共済出向'},
  {value: 'sailor_insurance_ongoing', label: '4. 船保任継'},
]

const reasonForBlankAddressOptions = [
  {value: 'living_abroad', label: '1. 海外在住'},
  {value: 'temporary_visitor', label: '2. 短期滞在'},
  {value: 'other', label: '3. その他'},
]

const Notes = ({isOtherNotes, healthInsuranceType, isGetContinuedReEmployment}) => (
  <div className={styles.notes}>
    <Label text="備考" />
    <CheckboxField name="is_reached_70_yo" label="1. 70歳以上被用者該当" />
    <CheckboxField name="is_get_multiple_office_worker" label="2. 二以上事業所勤務者の取得" />
    <CheckboxField
      name="short_time_worker"
      label={healthInsuranceType === 'kyokai' ? '3. 短時間労働者の取得（特定適用事業所等）' : '3. 短時間労働者'}
      note="勤務時間が通常の3/4未満の場合は対象の可能性があります。"
    />
    <CheckboxField
      name="is_get_continued_re_employment"
      label="4. 退職後の継続再雇用者の取得"
      note={
        healthInsuranceType === 'its' &&
        'ITS健保加入の関連会社からの転籍の場合や、60歳以上の者で1日の空白もなく再雇用される場合はチェックをつけてください。また、従前の事業所記号と被保険者番号を入力してください。'
      }
    />
    {isGetContinuedReEmployment && healthInsuranceType === 'its' && (
      <div>
        <TextField
          name="get_continued_re_employment_reference_code"
          label="従前の事業所記号"
          validate={[required, maxLengthNumber(8)]}
        />
        <TextField
          name="get_continued_re_employment_insurance_number"
          label="従前の被保険者番号"
          validate={[required, maxLength(7)]}
        />
      </div>
    )}
    {<CheckboxField name="is_other_notes" label="5. その他" />}
    {isOtherNotes && (
      <div className={styles.notesOther}>
        <TextField name="notes_other" label="備考" />
      </div>
    )}
  </div>
)

const KyokaiHealthInsurance = ({employee, isOtherNotes, isUseMyNumber, reasonForBlankAddress, healthInsuranceType}) => (
  <React.Fragment>
    <div className="u-mb20">
      <BoxDouble>
        <SelectField name="gender_type" label="資格取得種別" options={genderTypes} />
        <SelectField name="acquisition_type" label="取得区分" options={newAcquisitionTypes} />
      </BoxDouble>
    </div>
    <div className="u-mb40">
      <Notes isOtherNotes={isOtherNotes} healthInsuranceType={healthInsuranceType} />
    </div>
    <div className="u-mb40">
      <MyNumber employee={employee} />
    </div>
    <div className="u-mb40">
      {!isUseMyNumber && (
        <BoxDouble>
          <SelectField
            name="reason_for_blank_address"
            label="住民票住所を記載できない理由"
            options={reasonForBlankAddressOptions}
          />
        </BoxDouble>
      )}
      {!isUseMyNumber && reasonForBlankAddress === 'other' && (
        <div className={styles.notesOther}>
          <TextField name="notes_reason_for_blank_address" label="住民票住所を記載できない理由" />
        </div>
      )}
    </div>
    <SubmitedOn />
  </React.Fragment>
)

const ItsHealthInsurance = ({isOtherNotes, healthInsuranceType, isGetContinuedReEmployment}) => (
  <React.Fragment>
    <div className="u-mb20">
      <BoxDouble>
        <SelectField name="acquisition_type" label="取得区分" options={newAcquisitionTypes} />
      </BoxDouble>
    </div>
    <div className="u-mb40">
      <Notes
        isOtherNotes={isOtherNotes}
        healthInsuranceType={healthInsuranceType}
        isGetContinuedReEmployment={isGetContinuedReEmployment}
      />
    </div>
    <SubmitedOn />
    <div className="u-mt40 u-mb40">
      <p>
        出力・ダウンロード前に「マイナンバー出力」ボタンを押すか、又は印刷後手でマイナンバーを記入してください。
        <br />
        この帳票にマイナンバーを出力・記入しない場合は、取得届提出後１か月以内に、個人番号届等で個人番号の提出が必要になります。
      </p>
    </div>
  </React.Fragment>
)

const OtherHealthInsurance = ({employee, isOtherNotes, healthInsuranceType}) => (
  <React.Fragment>
    <div className="u-mb20">
      <BoxDouble>
        <SelectField name="gender_type" label="資格取得種別" options={genderTypes} />
        <SelectField name="acquisition_type" label="取得区分" options={acquisitionTypes} />
      </BoxDouble>
    </div>
    <div className="u-mb40">
      <Notes isOtherNotes={isOtherNotes} healthInsuranceType={healthInsuranceType} />
    </div>
    <div className="u-mb40">
      <MyNumber employee={employee} />
    </div>
    <SubmitedOn />
  </React.Fragment>
)

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(
  ({
    submitting,
    handleSubmit,
    submitText,
    employee,
    healthInsuranceType,
    isOtherNotes,
    isUseMyNumber,
    reasonForBlankAddress,
    isGetContinuedReEmployment,
    requireConfirmModal,
  }) => {
    const [openModal, setOpenModal] = useState(false)
    const branchSubmit = (requireConfirmModal) => {
      if (requireConfirmModal) {
        setOpenModal(true)
      } else {
        handleSubmit()
      }
    }
    return (
      <form onSubmit={handleSubmit}>
        <div className="l-title-wrap">
          <h1 className="m-title-main">健康保険・厚生年金保険被保険者資格取得届</h1>
          <EmployeeName employee={employee} />
        </div>
        <div className="basic_information">
          <FormErrors />
          <Section>
            {healthInsuranceType === 'kyokai' ? (
              <KyokaiHealthInsurance
                employee={employee}
                isOtherNotes={isOtherNotes}
                isUseMyNumber={isUseMyNumber}
                reasonForBlankAddress={reasonForBlankAddress}
                healthInsuranceType={healthInsuranceType}
              />
            ) : healthInsuranceType === 'its' ? (
              <ItsHealthInsurance
                employee={employee}
                isOtherNotes={isOtherNotes}
                healthInsuranceType={healthInsuranceType}
                isGetContinuedReEmployment={isGetContinuedReEmployment}
              />
            ) : (
              <OtherHealthInsurance
                isOtherNotes={isOtherNotes}
                healthInsuranceType={healthInsuranceType}
                employee={employee}
              />
            )}
            <div className="u-ta-c u-mt30">
              <Button primary onClick={() => branchSubmit(requireConfirmModal)} disabled={submitting}>
                {submitText}
              </Button>
            </div>
          </Section>
        </div>
        <ConfirmUpgradeModal
          onSubmit={() => {
            setOpenModal(false)
            handleSubmit()
          }}
          closeModal={() => setOpenModal(false)}
          openModal={openModal}
        />
      </form>
    )
  }
)

const connectedForm = connectForm('r1', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    healthInsuranceType: _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
    isOtherNotes: selector(state, 'is_other_notes'),
    isGetContinuedReEmployment: selector(state, 'is_get_continued_re_employment'),
    isUseMyNumber: selector(state, 'is_use_my_number'),
    reasonForBlankAddress: selector(state, 'reason_for_blank_address'),
    requireConfirmModal: selector(state, 'require_confirm_modal'),
  }),
})

export default connectedForm
