import React from 'react'
import Cookies from 'js-cookie'
import _ from 'lodash'
import {HashLink} from 'react-router-hash-link'
import {Headding} from 'components/PrimaryPanel'
import {Edit} from 'jbc-front/components/icons'
import {SubPanel} from 'employees/show/Parts'

const defaultYearStyle = 'wareki'
const yearStyleKey = 'disp_year_style'

export const getSaveDisplayYearStyle = () => {
  if (window.localStorage) {
    const yearStyle = window.localStorage.getItem(yearStyleKey)
    if (!_.isEmpty(yearStyle)) {
      return yearStyle
    }
  }
  const yearStyle = Cookies.get(yearStyleKey)
  return _.isEmpty(yearStyle) ? defaultYearStyle : yearStyle
}

export const saveDisplayYearStyle = (yearStyle) => {
  if (window.localStorage) {
    window.localStorage.setItem(yearStyleKey, yearStyle)
  } else {
    Cookies.set(yearStyleKey, yearStyle)
  }
}

export const HeaddingWithLink = ({to, title, canEdit, isSubPanel}) => {
  const Head = isSubPanel ? SubPanel.Headding : Headding
  return canEdit ? (
    <HashLink to={to}>
      <Head icon={<Edit icon={16} />}>{title}</Head>
    </HashLink>
  ) : (
    <Head>{title}</Head>
  )
}
