import {actionCreators} from 'actions'
import api from 'api'
import {formValueSelector, getFormValues, autofill as autofillForm} from 'redux-form'
import {getSavedDisplayEmployeeLimit} from 'employees/list/utils'
import {parse} from 'query-string'
import _ from 'lodash'

export const formName = 'usersClientsSearchForm'
export const selector = formValueSelector(formName)
export const getValues = getFormValues(formName)
export const autofill = autofillForm.bind(null, formName)

export const defaultQuery = {
  _page: '1',
  sort: 'email',
  order: 'asc',
  client_role_id: 'all',
}

export const getCurrentQueryFromLocation = (location, user) => ({
  ...defaultQuery,
  ...parse(location.search, {arrayFormat: 'bracket'}),
  limit: getSavedDisplayEmployeeLimit(location.pathname, user),
})

export const fetchUsersClients =
  (query, additionalParams = {}) =>
  (dispatch, getState) => {
    const {
      auth: {token},
    } = getState()
    const requestQuery = query.client_role_id === 'all' ? _.omit(query, 'client_role_id') : query
    dispatch(
      actionCreators.fetchData(
        'users_clients',
        api
          .createWithAuth(token)
          .usersClients.list({}, {...requestQuery, ...additionalParams})
          .then(({data, count}) => ({
            count,
            data: data.map((userClient) => ({
              ...userClient,
              users_client_office_office_ids: userClient.offices.map((office) => office.id),
            })),
          }))
      )
    )
  }
