import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'

type Params = {
  encoding?: EncodingType
}

export type EncodingType = 'UTF-8' | 'Windows-31J'

export const useDownloadPersonnelHistoriesCsv = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()

  return async (employeeId: string, params: Params) => {
    const path = `/api/employees/${employeeId}/export_personnel_histories_csv`
    const getter = () => authAxios.get(path, {responseType: 'arraybuffer', params})

    download(getter)
  }
}
