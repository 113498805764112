import 'core-js/stable'
import 'whatwg-fetch'
import 'normalize.css'
import 'font-awesome/scss/font-awesome.scss'
import 'jbc-front/styles/default.scss'
import 'react-sortable-tree/style.css'
import 'nprogress/nprogress.css'
import React from 'react'
import {render} from 'react-dom'
import Root from 'Root'
import Rollbar from 'rollbar'
import moment from 'moment'
import Modal from 'react-modal'
import {datadogRum} from '@datadog/browser-rum'

moment.locale('ja')
moment.updateLocale('ja', {
  months: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
})

Modal.setAppElement('#root')

const rollbarConfig = {
  accessToken: '60d8d94854fe4217a13fdd4136625de0',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.COMMIT_REF,
        guess_uncaught_frames: true,
      },
    },
  },
  enabled: process.env.ENABLE_ROLLBAR,
  scrubFields: ['password', 'value', 'token'],
}

Rollbar.init(rollbarConfig)

if (process.env.ENABLE_DATADOG) {
  datadogRum.init({
    applicationId: '36253bb1-83a8-4737-8636-da7e6efe03d6',
    clientToken: 'pub5849c047d00c8613e3f22ec8b41f9e2b',
    site: 'ap1.datadoghq.com',
    service: 'roumu-front',
    env: process.env.NODE_ENV,
    version: process.env.COMMIT_REF,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [`${process.env.DATADOG_TRACING_ORIGIN}/api`, `${process.env.DATADOG_TRACING_ORIGIN}/graphql`],
  })
}

render(<Root />, document.getElementById('root'))

if (module.hot) {
  module.hot.accept('Root', function () {
    const NextApp = require('Root').default
    render(<NextApp />, document.getElementById('root'))
  })
}
