import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import type {RouteParams} from 'types/routes'
import {Show} from 'pages/withAuth/adminPage/settings/egov/account/show'

export const accountRoutes: RouteParams[] = [
  {
    key: 'settings/egov/account',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.ACCOUNT.SHOW,
    component: Show,
    exact: true,
    whiteList: ['full_admin', 'office_admin', 'limited_admin'],
  },
]
