import moment from 'moment'
import _ from 'lodash'

export const ONE_PAGE_ROWS = 13
export const TWO_PAGE_ROWS = 25

export const getPaymentDayAtMonth = (monthDate, closingDate) => {
  if (closingDate == -1) {
    return monthDate.clone().add(1, 'month').startOf('month').subtract(1, 'day')
  }
  const date = monthDate.clone()
  date.set('date', closingDate)
  if (date.get('date') == closingDate) {
    return date
  }
  return date.startOf('month').subtract(1, 'day')
}

export const getLastPaymentDay = (date, closingDate) => {
  const atMonth = getPaymentDayAtMonth(date, closingDate)
  return atMonth.isSameOrAfter(date, 'day') ? getPaymentDayAtMonth(atMonth.subtract(1, 'month'), closingDate) : atMonth
}

const dateFormat = (date) => date.format('YYYY/MM/DD')

export const makeRowsData = (employee, maxIndex = ONE_PAGE_ROWS, isShortTime = false) => {
  if (employee.retired_at === null) {
    return []
  }
  const paymentData = [...makePaymentData(employee, _.get(employee, 'office.salary_closing_date'), maxIndex)]
  if (isShortTime) {
    return paymentData.map((data, index) => ({...data, row_number: index}))
  }
  const insuranceData = [...makeInsuranceData(employee, maxIndex)]
  return _.zipWith(
    _.range(Math.max(insuranceData.length, paymentData.length)).map((no) => ({
      row_number: no,
      insured_term_end_on: null,
      insured_term_start_on: null,
      insured_base_date_num: null,
      payment_term_start_on: null,
      payment_term_end_on: null,
      payment_base_date_num: null,
    })),
    insuranceData,
    paymentData,
    Object.assign
  )
}

export const makeInsuranceData = function* (employee, maxIndex) {
  const retiredAt = moment(employee.retired_at)
  const joinedAt = moment(employee.joined_at)
  const termBaseDate = retiredAt.clone().add(1, 'day')
  let index = 0
  while (index < maxIndex) {
    let insuranceStart = termBaseDate.clone().subtract(1 + index, 'month')
    let insuranceEnd = termBaseDate.clone().subtract(index, 'month').subtract(1, 'day')
    if (insuranceStart.isSameOrBefore(joinedAt, 'day')) {
      insuranceStart = joinedAt
    }
    yield {
      insured_term_start_on: dateFormat(insuranceStart),
      insured_term_end_on: dateFormat(insuranceEnd),
      insured_base_date_num: insuranceEnd.diff(insuranceStart, 'days') + 1,
    }
    if (insuranceStart.isSame(joinedAt, 'day')) {
      return
    }
    index++
  }
}

export const makePaymentData = function* (employee, closingDate, maxIndex) {
  const retiredAt = moment(employee.retired_at)
  const joinedAt = moment(employee.joined_at)
  let paymentStart = getLastPaymentDay(retiredAt, closingDate).add(1, 'day')
  let paymentEnd = retiredAt
  let index = 0
  while (index < maxIndex) {
    if (paymentStart.isSameOrBefore(joinedAt, 'day')) {
      paymentStart = joinedAt
    }
    yield {
      payment_term_start_on: dateFormat(paymentStart),
      payment_term_end_on: dateFormat(paymentEnd),
      payment_base_date_num: paymentEnd.diff(paymentStart, 'days') + 1,
    }
    if (paymentStart.isSame(joinedAt, 'day')) {
      return
    }
    index++
    paymentEnd = paymentStart.clone().subtract(1, 'day')
    paymentStart = getLastPaymentDay(paymentEnd, closingDate).clone().add(1, 'day')
  }
}
