import React from 'react'
import classNames from 'classnames'
import styles from 'documentTemplates/Badge.scss'

const colors = {
  green: styles.badgeGreen,
  orange: styles.badgeOrange,
  blue: styles.badgeBlue,
}

const Badge = ({color, className, children, ...rest}) => (
  <div className={classNames(styles.badge, colors[color], className)} {...rest}>
    {children}
  </div>
)

export default Badge
