import React, {Component} from 'react'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import onClickOutside from 'react-onclickoutside'
import Button from 'jbc-front/components/Button'
import {Pulldown, HeadOffice, Employee, Edit, Copy, Same, Delete as DeleteIcon} from 'jbc-front/components/icons'
import Delete from 'offices/Delete'
import {healthInsuranceTypeMap, salaryMonth, salaryDate} from 'client/Show'
import {Pager, PerPage, ListData} from 'offices/ListData'
import {fetchSelector} from 'actions'
import {recordDisplay, isOfficeAdminSelector} from 'utils'
import {getMainOfficeContactInfo, getLaborContactInfo, getOwnerContactInfo} from 'offices/utils'
import styles from 'offices/List.scss'

const ChooseAddMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide()
    }

    render() {
      return (
        <div className={styles.chooseAddMethod}>
          <ul>
            <li className={styles.addLink}>
              <Link to="/offices/create">
                <Pulldown transform="rotate(-90)" />
                新規登録（直接入力）
              </Link>
            </li>
            {/*<li className={styles.addLink}>*/}
            {/*<Link to="/settings/offices/import">*/}
            {/*<Pulldown transform="rotate(-90)" />新規登録（CSVインポート）*/}
            {/*</Link>*/}
            {/*</li>*/}
            {/*<li className={styles.addLink}>*/}
            {/*<Link to="/settings/offices/import_update">*/}
            {/*<Pulldown transform="rotate(-90)" />更新（CSVインポート）*/}
            {/*</Link>*/}
            {/*</li>*/}
          </ul>
        </div>
      )
    }
  }
)

const IconButton = ({to, text, children}) => (
  <Link to={to}>
    <div className={styles.noteWrap}>
      {children}
      <div className={styles.note}>{text}</div>
    </div>
  </Link>
)

const displayTextWithSameIcon = (current, comparison, isMainOffice) => (
  <React.Fragment>
    {current}
    {!isMainOffice && current === comparison && (
      <div className={styles.noteWrap}>
        <Same className={styles.iconSame} size={20} />
        <div className={styles.sameNote}>本社と同一の内容です</div>
      </div>
    )}
  </React.Fragment>
)

const OfficeDetail = connect((state) => ({
  clientOffice: state.client.current.office,
}))(({office, clientOffice}) => {
  const applicableOfficeOwner = getOwnerContactInfo(office)
  const mainOffice = getMainOfficeContactInfo(clientOffice)
  const isSameRep =
    !office.is_main_office &&
    _.get(applicableOfficeOwner, 'rep_position_name') === _.get(mainOffice, 'rep_position_name') &&
    _.get(applicableOfficeOwner, 'rep_last_name') === _.get(mainOffice, 'rep_last_name') &&
    _.get(applicableOfficeOwner, 'rep_first_name') === _.get(mainOffice, 'rep_first_name')
      ? true
      : false
  return (
    <div className={styles.accordionBody}>
      <dl className={styles.bodyList}>
        <dt>適用事業所名</dt>
        <dd>
          {office.is_main_office
            ? recordDisplay(_.get(mainOffice, 'name'))
            : displayTextWithSameIcon(
                recordDisplay(_.get(applicableOfficeOwner, 'name')),
                recordDisplay(_.get(mainOffice, 'name')),
                false
              )}
        </dd>
        <dt>事業主（代表者）</dt>
        <dd>
          {office.is_main_office
            ? recordDisplay.texts([
                _.get(mainOffice, 'rep_position_name'),
                _.get(mainOffice, 'rep_last_name'),
                _.get(mainOffice, 'rep_first_name'),
              ])
            : recordDisplay.texts([
                _.get(applicableOfficeOwner, 'rep_position_name'),
                _.get(applicableOfficeOwner, 'rep_last_name'),
                _.get(applicableOfficeOwner, 'rep_first_name'),
              ])}
          {isSameRep && (
            <div className={styles.noteWrap}>
              <Same className={styles.iconSame} size={20} />
              <div className={styles.sameNote}>本店と同一の内容です</div>
            </div>
          )}
        </dd>
        <dt>法人番号</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay(_.get(office, 'corporate_number')),
            recordDisplay(_.get(clientOffice, 'corporate_number')),
            office.is_main_office
          )}
        </dd>
        <dt>管轄の税務署</dt>
        <dd>
          {recordDisplay(_.get(office, 'tax_office.name'))}
          {!office.is_main_office && _.get(office, 'tax_office_id') === _.get(clientOffice, 'tax_office_id') && (
            <div className={styles.noteWrap}>
              <Same className={styles.iconSame} size={20} />
              <div className={styles.sameNote}>本社と同一の内容です</div>
            </div>
          )}
        </dd>
        {!office.is_main_office && (
          <React.Fragment>
            <dt>社会保険事業所情報の参照先</dt>
            <dd>{recordDisplay(_.get(office, 'social_contact_info_office_name'))}</dd>
          </React.Fragment>
        )}
      </dl>
      <dl className={styles.bodyListNarrow}>
        <dt>厚生年金保険記号</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay.numbers(office, 'social_insurance_reference_code', 2),
            recordDisplay.numbers(clientOffice, 'social_insurance_reference_code', 2),
            office.is_main_office
          )}
        </dd>
        <dt>厚生年金保険番号</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay(_.get(office, 'social_insurance_office_number')),
            recordDisplay(_.get(clientOffice, 'social_insurance_office_number')),
            office.is_main_office
          )}
        </dd>
      </dl>
      <dl className={`${styles.bodyListNarrow} u-ml15`}>
        <dt>給与の締め日</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay(salaryDate(_.get(office, 'salary_closing_date'))),
            recordDisplay(salaryDate(_.get(clientOffice, 'salary_closing_date'))),
            office.is_main_office
          )}
        </dd>
        <dt>給与の支給日</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay(
              salaryMonth(_.get(office, 'salary_payment_month_type')) + salaryDate(_.get(office, 'salary_payment_date'))
            ),
            recordDisplay(
              salaryMonth(_.get(office, 'salary_payment_month_type')) +
                salaryDate(_.get(clientOffice, 'salary_payment_date'))
            ),
            office.is_main_office
          )}
        </dd>
      </dl>
      <dl className={styles.bodyList}>
        <dt>健康保険タイプ</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay(healthInsuranceTypeMap[_.get(office, 'health_insurance_type')]),
            recordDisplay(healthInsuranceTypeMap[_.get(clientOffice, 'health_insurance_type')]),
            office.is_main_office
          )}
        </dd>
        <dt>健康保険記号</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay(_.get(office, 'health_insurance_reference_code')),
            recordDisplay(_.get(clientOffice, 'health_insurance_reference_code')),
            office.is_main_office
          )}
        </dd>
      </dl>
      <dl className={styles.bodyListWide}>
        <dt>管轄のハローワーク</dt>
        <dd>
          {recordDisplay(_.get(office, 'hello_work.disp_name'))}
          {!office.is_main_office && _.get(office, 'hello_work_id') === _.get(clientOffice, 'hello_work_id') && (
            <div className={styles.noteWrap}>
              <Same className={styles.iconSame} size={20} />
              <div className={styles.sameNote}>本社と同一の内容です</div>
            </div>
          )}
        </dd>
        <dt>雇用保険番号</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay.numbers(office, 'employment_insurance_office_number', 3),
            recordDisplay.numbers(clientOffice, 'employment_insurance_office_number', 3),
            office.is_main_office
          )}
        </dd>
        <dt>労働保険番号</dt>
        <dd>
          {displayTextWithSameIcon(
            recordDisplay.numbers(office, 'labor_insurance_number', 5),
            recordDisplay.numbers(clientOffice, 'labor_insurance_number', 5),
            office.is_main_office
          )}
        </dd>
      </dl>
    </div>
  )
})

class Row extends Component {
  state = {
    showBody: false,
  }

  handleClick = () => {
    this.setState({showBody: !this.state.showBody})
  }

  render() {
    const {office, loadData, isOfficeAdmin} = this.props
    const editUrl = office.is_main_office
      ? `client/edit?back_to=${encodeURIComponent('/offices')}`
      : `/offices/${office.id}/edit`
    const mainOfficeSocial = getMainOfficeContactInfo(office)
    const labor = getLaborContactInfo(office)
    const officeContactInfo = office.is_main_office ? mainOfficeSocial : labor

    return (
      <li className={styles.list}>
        <div className={styles.accordionHead}>
          <div onClick={this.handleClick} className={styles.codeColumn}>
            <Pulldown className={styles.iconPullDown} transform={this.state.showBody ? 'rotate(0)' : 'rotate(-90)'} />
            {recordDisplay(office.office_code)}
          </div>
          <div onClick={this.handleClick} className={styles.nameColumn}>
            {office.is_main_office && (
              <div className={styles.noteWrap}>
                <HeadOffice size={20} className={styles.icon} />
                <div className={styles.mainOfficeNote}>
                  デフォルトで設定される本社です。
                  <br />
                  法人情報でも確認することができます。
                </div>
              </div>
            )}
            {recordDisplay(office.name)}
          </div>
          <div onClick={this.handleClick} className={styles.addressColumn}>
            {recordDisplay.address(officeContactInfo, null)}
          </div>
          <div onClick={this.handleClick} className={styles.phoneNumberColumn}>
            {recordDisplay.numbers(officeContactInfo, 'phone_number', 3)}
          </div>
          <div className={styles.controlColumn}>
            <IconButton to={`/employees?office_ids[]=${office.id}`} text="所属従業員一覧">
              <Employee size={20} className={styles.icon} />
            </IconButton>
            <IconButton to={editUrl} text="適用事業所情報を編集">
              <Edit size={20} className={styles.icon} />
            </IconButton>
            {isOfficeAdmin ? (
              <Copy size={20} className={styles.iconDisable} />
            ) : (
              <IconButton to={`/offices/create?copy=${office.id}`} text="適用事業所をコピーして新しい適用事業所を追加">
                <Copy size={20} className={styles.icon} />
              </IconButton>
            )}
            {!office.is_main_office &&
              (!office.has_employees ? (
                <Delete id={office.id} className={styles.icon} onRequestFinish={() => loadData()} />
              ) : (
                <div className={styles.noteWrap}>
                  <span className={styles.iconDisable}>
                    <DeleteIcon size={20} />
                  </span>
                  <div className={styles.note}>所属している従業員がいるので削除できません</div>
                </div>
              ))}
          </div>
        </div>
        <div className={`${styles.accordionBodyWrap} ${this.state.showBody ? styles.isOpen : ''}`}>
          <OfficeDetail office={office} />
        </div>
      </li>
    )
  }
}

class List extends Component {
  state = {
    showChooseAddMethod: false,
  }

  static defaultProps = {
    offices: [],
  }

  render() {
    const {offices, count, isOfficeAdmin} = this.props
    return (
      <div>
        <ListData
          params={{embed: ['office_contact_info', 'hello_work', 'tax_office', 'social_contact_info_office_name']}}
          ref={(elm) => (this._listData = elm)}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">適用事業所設定</h1>
          <p className="m-title-main-note">
            複数の事業所を登録して管理することができます。詳しい内容は
            <a
              className="u-txt-link"
              href="https://jobcan-lms.zendesk.com/hc/ja/articles/360013651311"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
          </p>
        </div>
        <div className="l-contents-wrap">
          <div className="l-wrap-xl">
            <div className={styles.buttonArea}>
              <Button
                className={`${styles.buttonChooseAdd} ignore-react-onclickoutside`}
                onClick={() => this.setState({showChooseAddMethod: !this.state.showChooseAddMethod})}
                primary
                disabled={isOfficeAdmin}
                widthAuto
              >
                適用事業所登録
              </Button>
              {this.state.showChooseAddMethod && (
                <ChooseAddMethod hide={() => this.setState({showChooseAddMethod: false})} />
              )}
            </div>

            <div className="l-overflow-scroll">
              <ul className={styles.accordionList}>
                <li className={styles.accordionListHead}>
                  <div className={styles.codeColumn}>適用事業所コード</div>
                  <div className={styles.nameColumn}>適用事業所別名</div>
                  <div className={styles.addressColumn}>住所</div>
                  <div className={styles.phoneNumberColumn}>電話番号</div>
                  <div className={styles.controlColumn}>&nbsp;</div>
                </li>
                {offices.map((office) => (
                  <Row
                    key={office.id}
                    office={office}
                    loadData={this._listData && this._listData.loadData}
                    isOfficeAdmin={isOfficeAdmin}
                  />
                ))}
              </ul>
            </div>
            <div className="m-pager-area">
              <Pager totalResult={count} pageLinks={7} />
              <PerPage />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  offices: fetchSelector(state, 'offices_page').data,
  count: fetchSelector(state, 'offices_page').count,
  isOfficeAdmin: isOfficeAdminSelector(state),
}))(List)
