import React from 'react'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Edit} from 'jbc-front/components/icons'
import {HashLink} from 'react-router-hash-link'
import {Dl, Dd, Dt, Half, SubPanel, Grid} from 'employees/show/Parts'
import styles from 'employees/show/Parts.scss'
const dispatchTypes = [
  {value: 'dispatch', label: '1. 派遣・請負労働者として主として当該事業所以外で就労する場合'},
  {value: 'other', label: '2. 1に該当しない場合'},
]
const BasicInformation = ({editLink, employee, yearStyle, nations = [], visaTypes = []}) => {
  const emergencyContact = employee.emergency_contact || {}
  const visaUntil = _.get(employee, 'visa_history.visa_until')
  const country = _.find(nations, {id: _.get(employee, 'nation_id')})
  const hasBusinessName = employee.business_last_name || employee.business_first_name
  const residentCard = employee.resident_card || {}

  return (
    <Panel>
      <HashLink to={`${editLink}#BasicInfomation`}>
        <Headding icon={<Edit icon={16} />}>基本情報</Headding>
      </HashLink>
      <Item>
        <Grid>
          <Dl>
            <Dt>氏名</Dt>
            <Dd>{recordDisplay.fullName(employee, true)}</Dd>
          </Dl>
          {employee.national_type === 'foreigner' && employee.romaji_name && (
            <Dl>
              <Dt>ローマ字氏名</Dt>
              <Dd>
                {recordDisplay(employee.romaji_name)} （{recordDisplay(employee.romaji_name_kana)}）
              </Dd>
            </Dl>
          )}
          {hasBusinessName && (
            <Dl>
              <Dt>ビジネスネーム</Dt>
              <Dd>{recordDisplay.fullName(employee)}</Dd>
            </Dl>
          )}
          <Dl>
            <Dt>旧姓</Dt>
            <Dd>{recordDisplay(employee.former_name)}</Dd>
          </Dl>
          <Dl>
            <Dt>生年月日</Dt>
            <Dd>{recordDisplay.birthday(employee, yearStyle)}</Dd>
          </Dl>
          <Dl>
            <Dt>性別</Dt>
            <Dd>{employee.gender && (employee.gender === 'man' ? '男' : '女')}</Dd>
          </Dl>
          <Dl>
            <Dt>電話番号</Dt>
            <Dd>{recordDisplay.numbers(employee, 'phone_number', 3)}</Dd>
          </Dl>
          <Dl>
            <Dt>メールアドレス</Dt>
            <Dd>{recordDisplay(employee.email)}</Dd>
          </Dl>
          <Dl>
            <Dt>住所</Dt>
            <Dd>{recordDisplay.address(employee, nations)}</Dd>
          </Dl>
          <Dl>
            <Dt>住所カナ</Dt>
            <Dd>{recordDisplay(employee.address_kana)}</Dd>
          </Dl>
          <Dl>
            <Dt>世帯主の続柄</Dt>
            <Dd>{employee.householder_relationship}</Dd>
          </Dl>
          {employee.householder_relationship !== '本人' && (
            <Dl>
              <Dt>世帯主の氏名</Dt>
              <Dd>{recordDisplay(employee.householder_name)}</Dd>
            </Dl>
          )}
          <Dl>
            <Dt>外国人区分</Dt>
            <Dd>{employee.national_type && (employee.national_type === 'foreigner' ? '外国人' : '日本人')}</Dd>
          </Dl>
          {employee.national_type === 'foreigner' && (
            <>
              <Dl>
                <Dt>国籍・地域</Dt>
                <Dd>{recordDisplay(_.get(country, 'name'))}</Dd>
              </Dl>
              <Dl>
                <Dt>在留資格</Dt>
                <Dd>
                  {recordDisplay.option(
                    _.get(employee, 'visa_history.visa_type_id'),
                    visaTypes.map((t) => ({value: t.id, label: t.name}))
                  )}
                </Dd>
              </Dl>
              <Dl>
                <Dt>在留期間</Dt>
                <Dd>{visaUntil ? `${recordDisplay.date(visaUntil, yearStyle)}まで` : '―'}</Dd>
              </Dl>
              <Dl>
                <Dt>資格外活動許可の有無</Dt>
                <Dd>{recordDisplay.boolean(_.get(employee, 'visa_history.has_extra_action_permission'))}</Dd>
              </Dl>
              <Dl>
                <Dt>派遣・請負就労区分</Dt>
                <Dd>{recordDisplay.option(_.get(employee, 'visa_history.dispatch_type'), dispatchTypes)}</Dd>
              </Dl>
              <Dl>
                <Dt>在留カード番号</Dt>
                <Dd>{recordDisplay(_.get(employee, 'visa_history.visa_code'))}</Dd>
              </Dl>
              <Dl>
                <Dt>在留カード画像</Dt>
                <Dd>{recordDisplay.file(_.get(employee, 'visa_history.residence_card'))}</Dd>
              </Dl>
            </>
          )}
        </Grid>
        <Grid>
          <Half>
            <SubPanel>
              <HashLink to={`${editLink}#EmergencyContact`}>
                <SubPanel.Headding icon={<Edit icon={16} />}>緊急連絡先</SubPanel.Headding>
              </HashLink>
              <Grid className={styles.panelHalf}>
                <Dl>
                  <Dt>続柄</Dt>
                  <Dd>{recordDisplay(emergencyContact.relationship)}</Dd>
                </Dl>
                <Dl>
                  <Dt>氏名</Dt>
                  <Dd>{recordDisplay.fullName(emergencyContact, true)}</Dd>
                </Dl>
                <Dl>
                  <Dt>電話番号</Dt>
                  <Dd>{recordDisplay.numbers(emergencyContact, 'phone_number', 3)}</Dd>
                </Dl>
                <Dl>
                  <Dt>住所</Dt>
                  <Dd>{recordDisplay.address(emergencyContact)}</Dd>
                </Dl>
              </Grid>
            </SubPanel>
          </Half>
          <Half>
            <SubPanel>
              <HashLink to={`${editLink}#ResidentCard`}>
                <SubPanel.Headding icon={<Edit icon={16} />}>住民票住所</SubPanel.Headding>
              </HashLink>
              <Grid className={styles.panelHalf}>
                <Dl>
                  <Dt>住所</Dt>
                  <Dd>{recordDisplay.address(residentCard)}</Dd>
                </Dl>
                <Dl>
                  <Dt>住所カナ</Dt>
                  <Dd>{recordDisplay(residentCard.address_kana)}</Dd>
                </Dl>
              </Grid>
            </SubPanel>
          </Half>
        </Grid>
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
  nations: state.master.nations,
  visaTypes: state.master.visaTypes,
}))(BasicInformation)
