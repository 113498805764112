import {actionCreators} from 'actions'
import {connectPager} from 'components/paginators'
import {connectFilterTag} from 'components/Tags'
import {connectPerPage} from 'components/PerPage'
import {makeListData} from 'components/ListData'
import {getQueryDiff} from 'myNumber/businessPartner/reducers'
import api from 'api'

const querySelector = (state) => state.businessPartners.query
const setQuery = actionCreators.businessPartners.setQuery

export const Filter = connectFilterTag(querySelector, setQuery)
export const Pager = connectPager(querySelector, setQuery)
export const PerPage = connectPerPage(querySelector, setQuery)
export const ListData = makeListData({
  querySelector,
  setQuery,
  getQueryDiff,
  loadData({token, query, params}) {
    return actionCreators.fetchData(
      'business_partners',
      api.createWithAuth(token).businessPartners.list({
        _page: query.page,
        limit: query.per_page,
        q: query.search_keyword,
        ...params,
        ...(query.my_number_status === 'all'
          ? {}
          : {
              exist_my_number: query.my_number_status === 'completed',
            }),
      })
    )
  },
  destroy: actionCreators.fetchDestroy.bind(null, 'business_partners'),
})
