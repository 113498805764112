import {useRef, useEffect, useState} from 'react'
import {Subject} from 'rxjs'
import {debounceTime} from 'rxjs/operators'

type Callback = (value: string) => void

export const useKeywordInput = (debouncedCallback: Callback): [string, Subject<string>] => {
  const [keyword, setKeyword] = useState<string>('')
  const subjectRef = useRef<Subject<string>>()
  subjectRef.current ||= new Subject<string>()
  const callbackRef = useRef<Callback>()
  callbackRef.current = debouncedCallback

  useEffect(() => {
    const subject = subjectRef.current ?? failOnNull()
    const subscription = subject.subscribe(setKeyword)
    subscription.add(subject.pipe(debounceTime(1000)).subscribe((value) => callbackRef.current?.(value)))
    return () => subscription.unsubscribe()
  }, [])

  return [keyword, subjectRef.current]
}

const failOnNull = (): never => {
  throw 'value must not be null or undefined'
}
