import ChooseEmployee from 'procedures/ChooseEmployee'
import Detail from 'procedures/changeName/Detail'
import Flow from 'procedures/changeName/Flow'
import EmployeeInput from 'procedures/changeName/EmployeeInput'
import Confirm from 'procedures/changeName/Confirm'
import Update from 'procedures/changeName/Update'

export default {
  ChooseEmployee,
  Detail,
  Flow,
  EmployeeInput,
  Confirm,
  Update,
}
