import {DownloadFile} from 'jbc-front/components/icons'
import type {FC, ButtonHTMLAttributes, ReactNode} from 'react'
import classNames from 'classnames'
import styles from './DownloadButton.scss'

interface DownloadButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: SizeType
  children?: ReactNode
}

type SizeType = 'small' | 'medium' | 'large'

const BUTTON_SIZE = {
  small: 10,
  medium: 18,
  large: 30,
} as const

export const DownloadButton: FC<DownloadButtonProps> = ({children, size = 'medium', ...buttonProps}) => (
  <button className={classNames(styles.downloadFileButton, styles[size])} {...buttonProps}>
    <DownloadFile size={BUTTON_SIZE[size]} color="#fff" />
    {children}
  </button>
)
