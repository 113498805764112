import React from 'react'
import {useSelector} from 'react-redux'
import {FieldArray, FormSection, reduxForm, formValueSelector, reset} from 'redux-form'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import Button from 'jbc-front/components/Button'
import {CheckboxField} from 'jbc-front/components/Form'
import styles from 'tm/employees/SelectItem.scss'
import api from 'api'
import {actionCreators} from 'actions'
import _ from 'lodash'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'talentUserDisplaySettingUpdateForm'
const selector = formValueSelector(formName)
const useFormValue = (...args) => useSelector((state) => selector(state, ...args))

const settingCategories = (userDisplaySetting) => _.orderBy(userDisplaySetting, ['key', 'position'], ['desc', 'asc'])

const makeInitialValues = (userDisplaySetting) => ({
  talent_user_display_excludes: settingCategories(userDisplaySetting).map(({visible, ...rest}) => ({
    ...rest,
    visible: visible,
  })),
})

const formatValues = (values) => ({
  talent_user_display_excludes: values.talent_user_display_excludes,
})

const DisplayExcludeSetting = ({field}) => {
  const name = useFormValue(`${field}.label`)
  const childItems = useFormValue(`${field}.child_items`)
  return (
    childItems && (
      <div style={{display: 'inline-block'}} className="input-label">
        <FormSection name={field}>
          <CheckboxField name="visible" label={name} />
        </FormSection>
      </div>
    )
  )
}

const renderDisplayExcludeSettings = ({fields, title}) => {
  return (
    <div className={styles.displayExcludeSettings}>
      <h2 className={styles.title}>{title}</h2>
      {fields.map((field, index) => (
        <DisplayExcludeSetting field={field} key={index} />
      ))}
    </div>
  )
}

const Form =
  (({handleSubmit, submitting, hideModal = [], userDisplaySetting}) => {
    return (
      <React.Fragment>
        <Modal.Header hideModal={hideModal}>表示する項目の選択</Modal.Header>
        <Modal.Body className={styles.modalbody}>
          {(userDisplaySetting && userDisplaySetting.length > 0 && (
            <form onSubmit={handleSubmit}>
              <FormErrors />
              <FieldArray
                name="talent_user_display_excludes"
                title="表示項目の選択"
                component={renderDisplayExcludeSettings}
              />
            </form>
          )) || <p className={styles.substitute_text}>選択できる項目はありません</p>}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            {(userDisplaySetting && userDisplaySetting.length > 0 && (
              <React.Fragment>
                <Button onClick={hideModal}>キャンセル</Button>
                <Button primary onClick={handleSubmit} disabled={submitting}>
                  保存
                </Button>
              </React.Fragment>
            )) || <Button onClick={hideModal}>キャンセル</Button>}
          </Modal.Buttons>
        </Modal.Footer>
      </React.Fragment>
    )
  })
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })

const SelectItem = ({isModalOpen, showModal, hideModal = []}) => {
  const [token, currentTmUser, userDisplaySetting = []] = useSelector((state) => [
    state.auth.token,
    state.session.currentTmUser,
    state.tm.userDisplaySettings.data,
  ])
  return (
    <React.Fragment>
      <div className={styles.btnarea}>
        <Button widthAuto onClick={showModal} className={styles.small}>
          表示項目の選択
        </Button>
      </div>
      <Modal isOpen={isModalOpen} hideModal={hideModal}>
        {isModalOpen && (
          <Form
            {...{hideModal, userDisplaySetting}}
            initialValues={makeInitialValues(userDisplaySetting)}
            onSubmit={async ({...values}, dispatch, {hideModal}) => {
              const {talent_user_display_excludes = []} = formatValues(values)
              const authApi = api.createWithAuth(token)
              try {
                const {data: userDisplayResult} = await authApi.tm.userDisplaySettings.updateAll(
                  currentTmUser.employee.id,
                  talent_user_display_excludes
                )
                dispatch(actionCreators.tm.userDisplaySettings.fetchSuccessed({data: userDisplayResult}))
                dispatch(notifySuccess('保存しました'))
                dispatch(
                  actionCreators.tm.userDisplaySettings.fetchData(
                    authApi.tm.userDisplaySettings.list(currentTmUser.employee.id)
                  )
                )
                dispatch(reset(formName))
              } catch (err) {
                dispatch(asyncError(err))
              }
              hideModal()
            }}
          />
        )}
      </Modal>
    </React.Fragment>
  )
}

export default withModal(SelectItem)
