import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field} from 'redux-form'
import styles from 'jbc-front/components/SearchForm.scss'
import {SearchTextBox} from 'jbc-front/components/SearchForm'

class Skills extends Component {
  static defaultProps = {
    skills: [],
  }

  render() {
    return (
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>スキル</div>
        <div className={styles.detailsBody}>
          <div className={styles.detailsBodyItems}>
            <div className={styles.SearchTextBox}>
              <Field name="skills" component={SearchTextBox} type="text" inputWidth={400} />
            </div>
          </div>
          <div className={styles.notes}>複数条件の場合はスペース区切りで入力してください</div>
        </div>
      </div>
    )
  }
}
export default connect((state) => ({
  token: state.auth.token,
}))(Skills)
