import React from 'react'
import {useSelector} from 'react-redux'
import classNames from 'classnames'
import SearchResult from 'documents/contract/SearchResult'
import {getSavedDisplayEmployeeLimit, saveDisplayEmployeeLimit} from 'documents/contract/utils'
import styles from 'documents/Contract.scss'

const options = ['10', '20', '50', '100', '200']

const PerPageWithResult = ({total, page, loading, onChange}) => {
  const pathname = useSelector((state) => state.router.location.pathname)
  const user = useSelector((state) => state.session.currentUser)
  const limit = getSavedDisplayEmployeeLimit(pathname, user)
  const onChangeLimit = (e) => {
    onChange()
    saveDisplayEmployeeLimit(pathname, user, e.target.value)
  }

  return (
    <div className={classNames('u-mt10', styles.PerPageWithResult)}>
      <div className={styles.searchSelectBox}>
        <select type="select" onChange={onChangeLimit} className={styles.select} value={limit}>
          {options.map((perPage) => (
            <option key={perPage} value={perPage}>
              表示件数：{perPage}件
            </option>
          ))}
        </select>
      </div>
      <div className={styles.listTypeSearchResult}>
        <SearchResult total={total} page={page} limit={limit} isLoading={loading} />
      </div>
      <div className={classNames('u-mb10', styles.resultRight)} />
    </div>
  )
}

export default PerPageWithResult
