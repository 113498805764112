import React from 'react'
import {TextField, Section, CheckboxField, DateField} from 'jbc-front/components/Form'
import {number} from 'validators'
import {reduxForm, formValueSelector} from 'redux-form'
import {Notes} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import EmployeeName from 'procedures/EmployeeName'

const formName = 'R131Report'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, outputsRemuneration, employee}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">退職証明書</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section>
        <CheckboxField label="退職日" name="outputs_retired_at" />
        <CheckboxField label="在籍期間" name="outputs_employment_term" />
        <CheckboxField label="業務内容" name="outputs_business_content" />
        <CheckboxField label="役職" name="outputs_position" />
        <CheckboxField label="給与" name="outputs_remuneration" />
        {outputsRemuneration && <TextField type="number" label="金額" validate={number} yen name="remuneration" />}
        <CheckboxField label="退職理由" name="outputs_retire_reason" />
        <Notes />
        <DateField name="published_at" label="発行日" />
        <div className="u-ta-c u-mt30">
          <Button primary onClick={handleSubmit} disabled={submitting}>
            {submitText}
          </Button>
        </div>
      </Section>
    </div>
  </form>
))

const selector = formValueSelector(formName)

export default connectForm('r131', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
    outputsRemuneration: selector(state, 'outputs_remuneration'),
  }),
})
