import React, {useState} from 'react'
import {Section, TextField} from 'jbc-front/components/Form'
import {reduxForm} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import {Email} from 'FormFields'
import {requestResetPassword} from 'api'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import styles from 'Login.scss'

const ForgotPasswordForm = reduxForm({
  form: 'change_password',
  onSubmitFail,
})(({submitting, handleSubmit}) => {
  const [shouldShowCidInput, setShouldShowCidInput] = useState(false)
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">メールアドレスを入力してください</h1>
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <Email required />
          {shouldShowCidInput && <TextField name="client_code" label="会社ID" />}
          <a
            className={`${styles.loginCid} u-txt-link`}
            onClick={() => setShouldShowCidInput((prevState) => !prevState)}
          >
            {shouldShowCidInput ? '会社IDの入力を閉じる' : '複数の会社に登録されていますか？'}
          </a>
        </Section>
        <div className="u-ta-c u-mt30">
          <Button as="button" primary type="submit" disabled={submitting}>
            送信
          </Button>
        </div>
      </div>
    </form>
  )
})

const ForgotPassword = connect(null, (dispatch) => ({
  async onSubmit(values) {
    try {
      await requestResetPassword(values)
      dispatch(notifySuccess('送信しました'))
      dispatch(push('/login'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
}))(ForgotPasswordForm)

export default ForgotPassword
