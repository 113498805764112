import React, {useEffect} from 'react'
import Hint from 'jbc-front/components/Hint'
import {FileTable} from 'settings/clientFiles/FileTable'
import styles from 'employeeFiles/Top.scss'

const convertTableTitle = (type) => (type === 'procedure' ? '手続きファイル' : '社内規定ファイル')
const convertHintText = (type) =>
  type === 'procedure'
    ? '手続きファイルとして提出したファイルが表示され'
    : '社内規定ファイルとして提出したファイルが表示され'

export const MemberFiles = ({
  type,
  files,
  trashInProcess,
  onDeleteFile,
  hasMoreFiles,
  showMoreFiles,
  onChangeShowMore,
  loadMoreFile,
  onChangeSortType,
  currentSortType,
  onChangeFileType,
}) => {
  useEffect(() => {
    if (showMoreFiles) {
      loadMoreFile()
    }
  }, [showMoreFiles])

  return (
    <div id={type}>
      <h3 className={styles.titleTable}>{convertTableTitle(type)}</h3>
      <Hint text={`従業員が${convertHintText(type)}、ダウンロードできます`} />
      <FileTable
        type={type}
        files={files}
        trashInProcess={trashInProcess}
        onDeleteFile={onDeleteFile}
        onChangeSortType={onChangeSortType}
        currentSortType={currentSortType}
        onChangeFileType={onChangeFileType}
      />
      {hasMoreFiles && !showMoreFiles && (
        <div className={styles.more}>
          <a onClick={() => onChangeShowMore(true)}>もっと見る</a>
        </div>
      )}
    </div>
  )
}
