import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators} from 'actions'
import api from 'api'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import {Dl, Dd, Dt} from 'components/SecondaryPanel'
import {Panel as TertiaryPanel, Item as TertiaryItem, Headding as TertiaryHeadding} from 'components/TertiaryPanel'
import {recordDisplay} from 'utils'
import {employmentStatuses, getGroups} from 'employees/form/EmploymentInfomation'
import _ from 'lodash'
import ActionButton from 'jbc-front/components/ActionButton'
import MyNumberModal, {myNumberTypes} from 'myNumber/MyNumberModal'

export const loadEmployee = async (id, token) => {
  const authedApi = api.createWithAuth(token)
  return authedApi.employees.get(id, [
    'personnel_history',
    'employee_dependents',
    'office',
    'my_number_purpose_of_uses',
  ])
}

class Show extends Component {
  constructor(props) {
    super(props)
    this.state = {
      myNumberModal: {
        isOpen: false,
        employee: {},
        dependent: {},
        type: null,
      },
    }
  }
  componentDidMount() {
    const {id} = this.props.match.params
    this.props.loadData(id, this.props.token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  showEmployeeModal = () => {
    const {employee} = this.props
    this.setState({
      myNumberModal: {
        isOpen: true,
        employee: employee,
        type: myNumberTypes.employee,
      },
    })
  }

  showDependentModal = (dependent) => {
    const {employee} = this.props
    this.setState({
      myNumberModal: {
        isOpen: true,
        employee: employee,
        type: myNumberTypes.dependent,
        dependent: dependent,
      },
    })
  }

  hideMyNumberModal = () => {
    this.setState({
      myNumberModal: {
        isOpen: false,
      },
    })
  }

  render() {
    const {employee} = this.props
    const {employee_dependents: dependents = [], my_number_purpose_of_uses: myNumberPurposeOfUses} = employee
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">マイナンバー情報</h1>
        </div>
        <div className="l-wrap-s">
          <Panel>
            <Headding>{recordDisplay.fullName(employee)}さんの情報</Headding>
            <div className="u-pc-pt30 u-pc-pr15 u-pc-pl15">
              <Item>
                <TertiaryPanel>
                  <TertiaryHeadding>従業員情報</TertiaryHeadding>
                  <TertiaryItem>
                    <Dl>
                      <Dt>氏名：</Dt>
                      <Dd>{recordDisplay.fullName(employee, true)}</Dd>
                      <Dt>スタッフコード：</Dt>
                      <Dd>{recordDisplay(employee.staff_code)}</Dd>
                      <Dt>在籍状況：</Dt>
                      <Dd>{recordDisplay(employmentStatuses[employee.employment_status])}</Dd>
                      <Dt>グループ：</Dt>
                      <Dd>
                        {recordDisplay(
                          !_.isEmpty(getGroups(employee)) &&
                            getGroups(employee).map((group, index) => (
                              <span key={index}>
                                {group}
                                <br />
                              </span>
                            ))
                        )}
                      </Dd>
                      <Dt>職種：</Dt>
                      <Dd>{recordDisplay(_.get(employee, 'personnel_history.occupation_name'))}</Dd>
                      <Dt>入社日：</Dt>
                      <Dd>{recordDisplay.date(employee.joined_at)}</Dd>
                      <Dt>退職年月日：</Dt>
                      <Dd>{recordDisplay.date(employee.retired_at)}</Dd>
                    </Dl>
                  </TertiaryItem>
                </TertiaryPanel>
                <TertiaryPanel>
                  <TertiaryHeadding>マイナンバー</TertiaryHeadding>
                  <TertiaryItem>
                    <Dl>
                      <Dt>マイナンバー</Dt>
                      <Dd>
                        <span>************</span>
                        <span className="u-ml30">
                          <ActionButton onClick={this.showEmployeeModal}>表示</ActionButton>
                        </span>
                      </Dd>
                      <Dt>利用目的</Dt>
                      {_.isEmpty(myNumberPurposeOfUses) ? (
                        <Dd>{recordDisplay()}</Dd>
                      ) : (
                        myNumberPurposeOfUses.map((name, key) => <Dd key={key}>{name}</Dd>)
                      )}
                    </Dl>
                  </TertiaryItem>
                </TertiaryPanel>
              </Item>
            </div>
          </Panel>
          {dependents.map((dependent) => {
            const myNumberPurposeOfUses = dependent.my_number_purpose_of_uses
            return (
              <Panel key={dependent.id}>
                <Headding>{recordDisplay.fullName(dependent)}さんの情報</Headding>
                <div className="u-pc-pt30 u-pc-pr15 u-pc-pl15">
                  <Item>
                    <TertiaryPanel>
                      <TertiaryHeadding>マイナンバー</TertiaryHeadding>
                      <TertiaryItem>
                        <Dl>
                          <Dt>マイナンバー</Dt>
                          <Dd>
                            <span>************</span>
                            <span className="u-ml30">
                              <ActionButton onClick={this.showDependentModal.bind(this, dependent)}>表示</ActionButton>
                            </span>
                          </Dd>
                          <Dt>利用目的</Dt>
                          {_.isEmpty(myNumberPurposeOfUses) ? (
                            <Dd>{recordDisplay()}</Dd>
                          ) : (
                            myNumberPurposeOfUses.map((name, key) => <Dd key={key}>{name}</Dd>)
                          )}
                        </Dl>
                      </TertiaryItem>
                    </TertiaryPanel>
                  </Item>
                </div>
              </Panel>
            )
          })}
        </div>
        <MyNumberModal {...this.state.myNumberModal} hide={this.hideMyNumberModal} />
      </div>
    )
  }
}

export default connect(
  (state) => ({
    employee: state.employees.current.data,
    token: state.auth.token,
    currentUser: state.session.currentUser,
  }),
  (dispatch) => ({
    loadData: (id, token) => {
      dispatch(actionCreators.employees.current.fetchData(loadEmployee(id, token, dispatch)))
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
    },
  })
)(Show)
