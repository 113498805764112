import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect} from 'react-router'
import {push} from 'connected-react-router'
import {gql} from '@apollo/client'
import {omit} from 'lodash/fp'
import {toFormValues} from 'utils'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {useQuery, useMutation} from 'components/Graphql'
import LoadingPage from 'components/LoadingPage'
import Form from 'documentTemplates/Form'
import {DOCUMENT_TEMPLATE_FRAGMENT, isEditableTemplate} from 'documentTemplates/List'
import {documentSanitizeHtml} from 'documentTemplates/documentSanitizeHtml'

const DOCUMENT_TEMPLATE = gql`
  query documentTemplate($id: ID!) {
    client {
      id
      documentTemplate(id: $id) {
        id
        title
        description
        downloadFilename
        draft
        visibility
        editability
        body
        documentTemplateCategoryName
        createdUser {
          id
        }
        updatedUser {
          id
        }
      }
    }
  }
`

const UPDATE_DOCUMENT_TEMPLATE = gql`
  mutation updateDocumentTemplate($input: UpdateDocumentTemplateInput!) {
    updateDocumentTemplate(input: $input) {
      documentTemplate {
        ...DocumentTemplateFields
      }
    }
  }
  ${DOCUMENT_TEMPLATE_FRAGMENT}
`

const makeInitialValues = (documentTemplate) =>
  toFormValues({...documentTemplate, body: documentSanitizeHtml(documentTemplate.body)}, true) |> omit(['createdUser'])

const Edit = ({
  match: {
    params: {id},
  },
}) => {
  const currentUser = useSelector((state) => state.session.currentUser)
  const dispatch = useDispatch()
  const {data, error, loading} = useQuery(DOCUMENT_TEMPLATE, {fetchPolicy: 'network-only', variables: {id}})
  const [updateDocumentTemplate] = useMutation(UPDATE_DOCUMENT_TEMPLATE)

  if (error) {
    return <Redirect to="/document_templates" />
  }

  let documentTemplate = data?.client?.documentTemplate
  if (!loading) {
    if (!documentTemplate) {
      dispatch(notifyError('書類テンプレートがありません'))
      return <Redirect to="/document_templates" />
    }

    if (!isEditableTemplate(currentUser, documentTemplate)) {
      dispatch(notifyError('操作権限がありません'))
      return <Redirect to="/document_templates" />
    }
  }

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">書類テンプレート</h1>
      </div>
      <div className="l-wrap-xxl l-contents-wrap">
        {loading ? (
          <LoadingPage />
        ) : (
          <Form
            initialValues={makeInitialValues(documentTemplate)}
            onSubmit={async (values) => {
              await updateDocumentTemplate({variables: {input: {id, documentTemplate: values}}})
              dispatch(push('/document_templates'))
              dispatch(notifySuccess('登録しました'))
            }}
          />
        )}
      </div>
    </>
  )
}

export default Edit
