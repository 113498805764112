import Detail from 'procedures/changeDependents/remove/Detail'
import Flow from 'procedures/changeDependents/remove/Flow'
import EmployeeInput from 'procedures/changeDependents/remove/EmployeeInput'
import Confirm from 'procedures/changeDependents/remove/Confirm'
import Update from 'procedures/changeDependents/remove/Update'

export default {
  Detail,
  Flow,
  EmployeeInput,
  Confirm,
  Update,
}
