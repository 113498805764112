import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchSelector, actionCreators} from 'actions'
import api from 'api'
import Form, {makeInitialValues} from 'settings/eGov/Form'
import {Link} from 'react-router-dom'
import {handlerNestFormResponse} from 'libs/errorHandler'
import {push} from 'connected-react-router'
import {getState} from 'utils'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Edit extends Component {
  componentDidMount() {
    const {
      token,
      loadApplicantInformation,
      loadContactInformation,
      loadCertificateFile,
      loadSubmissionDestination,
      loadLaborConsultant,
    } = this.props
    loadApplicantInformation(token)
    loadContactInformation(token)
    loadCertificateFile(token)
    loadSubmissionDestination(token)
    loadLaborConsultant(token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  static defaultProps = {
    applicantInformation: {},
    contactInformation: {},
    certificateFile: {},
    submissionDestination: {},
    laborConsultant: {},
  }

  handleSubmit = (values) => {
    const {token, save} = this.props
    return save(values, token)
  }

  render() {
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">電子申請情報編集</h1>
          <p className="m-title-main-note">電子申請の情報を設定できます</p>
        </div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <Link to="/settings/egov" className="l-breadcrumb-link">
              電子申請情報
            </Link>
            <span className="l-breadcrumb-here">電子申請情報編集</span>
            <Form initialValues={makeInitialValues(this.props)} onSubmit={this.handleSubmit} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    applicantInformation: fetchSelector(state, 'applicant_information_edit').data,
    contactInformation: fetchSelector(state, 'contact_information_edit').data,
    certificateFile: fetchSelector(state, 'certificate_file_edit').data,
    submissionDestination: fetchSelector(state, 'submission_destination_edit').data,
    laborConsultant: fetchSelector(state, 'labor_consultant').data,
  }),
  (dispatch) => ({
    loadApplicantInformation(token) {
      dispatch(
        actionCreators.fetchData('applicant_information_edit', api.createWithAuth(token).applicantInformation.get())
      )
    },
    loadContactInformation(token) {
      dispatch(actionCreators.fetchData('contact_information_edit', api.createWithAuth(token).contactInformation.get()))
    },
    loadCertificateFile(token) {
      dispatch(actionCreators.fetchData('certificate_file_edit', api.createWithAuth(token).certificateFile.get()))
    },
    loadSubmissionDestination(token) {
      dispatch(
        actionCreators.fetchData('submission_destination_edit', api.createWithAuth(token).submissionDestination.get())
      )
    },
    loadLaborConsultant(token) {
      dispatch(actionCreators.fetchData('labor_consultant', api.createWithAuth(token).clients.laborConsultant.get()))
    },
    destroy() {
      dispatch(actionCreators.fetchDestroy('applicant_information_edit'))
      dispatch(actionCreators.fetchDestroy('contact_information_edit'))
      dispatch(actionCreators.fetchDestroy('certificate_file_edit'))
      dispatch(actionCreators.fetchDestroy('submission_destination_edit'))
      dispatch(actionCreators.fetchDestroy('labor_consultant'))
    },
    async save(values, token) {
      try {
        const authedApi = api.createWithAuth(token)
        await authedApi.applicantInformation
          .create(values.applicant_information)
          .catch(handlerNestFormResponse('applicant_information'))
        await authedApi.contactInformation
          .create(values.contact_information)
          .catch(handlerNestFormResponse('contact_information'))
        await authedApi.certificateFile
          .create(values.certificate_file || {})
          .catch(handlerNestFormResponse('certificate_file'))
        await authedApi.submissionDestination
          .create(values.submission_destination)
          .catch(handlerNestFormResponse('submission_destination'))
        const state = await getState(dispatch)
        const client = state.client.current
        await authedApi.clients.laborConsultant
          .createOrUpdate(client.id, values.labor_consultant, true)
          .catch(handlerNestFormResponse('labor_consultant'))
        dispatch(push('/settings/egov'))
        dispatch(notifySuccess('更新しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Edit)
