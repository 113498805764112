import _ from 'lodash'
import moment from 'moment'
export const constUndef = () => undefined

export const required = (value) =>
  (value && (!_.isString(value) || value.trim())) || value === false ? undefined : 'を入力してください'

export const requiredSelect = (value) =>
  (value && (!_.isString(value) || value.trim())) || value === false ? undefined : 'を選択してください'

export const requiredIf = (condition) => (condition ? required : constUndef)

export const number = (value) => (value && !value.match(/^\d+$/) ? 'を半角数字で入力してください' : undefined)

export const wage = (value) =>
  value && !value.match(/^\d{1,7}$/) && value !== '未計算'
    ? 'は半角数値7桁以内か「未計算」と入力してください。'
    : undefined

export const numberValue = (value) =>
  value && !value.match(/^[+-]?\d+$/)
    ? 'は半角数字と「-（マイナス）」で入力してください。また、小数点は入力できません。'
    : undefined

export const numberValueWithComma = (value) =>
  value && !value.replaceAll(',', '').match(/^[+-]?\d+$/)
    ? 'は半角数字と「-（マイナス）」「,」で入力してください。また、小数点は入力できません。'
    : undefined

export const floatValue = (value) =>
  value && !value.match(/^[+-]?(\d+\.\d*|\d*\.\d+|\d+)(e[-+]{0,1}\d+)?$/i)
    ? 'は半角数字と「-（マイナス）」「.」で入力してください。'
    : undefined

export const floatValueWithComma = (value) =>
  value && !value.replaceAll(',', '').match(/^[+-]?(\d+\.\d*|\d*\.\d+|\d+)(e[-+]{0,1}\d+)?$/i)
    ? 'は半角数字と「-（マイナス）」「.」「,」で入力してください。'
    : undefined

const exactLengthNumberValidators = []
export const exactLengthNumber = (len) => {
  if (!exactLengthNumberValidators[len]) {
    exactLengthNumberValidators[len] = (value) =>
      len && value && value.length !== len ? `は${len}桁の半角数字で入力してください` : undefined
  }
  return exactLengthNumberValidators[len]
}

const maxLengthNumberValidators = []
export const maxLengthNumber = (max) => {
  if (!maxLengthNumberValidators[max]) {
    maxLengthNumberValidators[max] = (value) =>
      value && value.length > max ? `は半角数字${max}桁以下で入力してください` : undefined
  }
  return maxLengthNumberValidators[max]
}

const maxLengthValidators = []
export const maxLength = (max) => {
  if (!maxLengthValidators[max]) {
    maxLengthValidators[max] = (value) =>
      value && value.length > max ? `を${max}文字以内で入力してください` : undefined
  }
  return maxLengthValidators[max]
}

const minLengthValidators = []
export const minLength = (min) => {
  if (!minLengthValidators[min]) {
    minLengthValidators[min] = (value) =>
      value && value.length < min ? `を${min}文字以上で入力してください` : undefined
  }
  return minLengthValidators[min]
}

const maxNumberValidators = []
export const maxNumber = (max) => {
  if (!maxNumberValidators[max]) {
    maxNumberValidators[max] = (value) => (value && +value > max ? `を${max}以内で入力してください` : undefined)
  }
  return maxNumberValidators[max]
}

const minNumberValidators = []
export const minNumber = (min) => {
  if (!minNumberValidators[min]) {
    minNumberValidators[min] = (value) => (value && +value < min ? `を${min}以上で入力してください` : undefined)
  }
  return minNumberValidators[min]
}

const maxAgeValidators = []
export const maxAge = (max) => {
  if (!maxAgeValidators[max]) {
    maxAgeValidators[max] = (value) => {
      if (value) {
        const parsed = parseInt(value)
        if (number(value) || isNaN(parsed) || parsed < 0 || parsed > max) {
          return `は0から${max}までの半角数字で入力してください`
        }
      }
      return undefined
    }
  }
  return maxAgeValidators[max]
}

export const zenkakuKatakana = (value) =>
  value && !value.match(/^[\u30A0-\u30FF]+$/) ? 'を全角カタカナで入力してください' : undefined

export const email = (value) =>
  value && !value.match(/^([^@\s]+)@((?:[a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,})$/i) ? 'が正しくありません' : undefined

export const romajiName = (value) =>
  value && !value.match(/^[A-Z a-z-]+$/) ? 'を半角英字で入力してください' : undefined

export const zenkakuKatakanaWithSpace = (value) =>
  value && !value.match(/^[\u30A0-\u30FF\u3000 ]+$/) ? 'を全角カタカナとスペースで入力してください' : undefined

export const zenkakuKatakanaWithZenkakuSpace = (value) =>
  value && !value.match(/^[\u30A0-\u30FF\u3000]+$/) ? 'を全角カタカナと全角スペースで入力してください' : undefined

export const zenkakuKatakanaLoose = (value) =>
  value && value.match(/[\u4E00-\u9FFF\u3005-\u3006\u3040-\u309f]/)
    ? 'を全角カタカナとスペースで入力してください'
    : undefined

export const corporateNumber = (value) => (value && !isCorporateNumber(value) ? 'が正しくありません' : undefined)

export const staffCode = (value) =>
  value && !value.match(/^[\w-_]+$/) ? 'は半角英数字と「-」「_」で入力してください。' : undefined

export const officeCode = (value) =>
  value && !value.match(/^[A-Za-z0-9]*$/) ? 'は半角英数字で入力してください。' : undefined

export const visaCode = (value) =>
  value && !value.match(/^[A-Za-z0-9]*$/) ? 'は半角英数字で入力してください。' : undefined

export const date = (value) =>
  value && !moment(value, 'YYYY/MM/DD').isValid() ? 'の入力形式が正しくありません' : undefined

// Format: YYYY/MM/DD or YYYY-MM-DD
export const dateString = (value) => {
  if (!value) return undefined

  if (!/^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(value)) {
    return 'の入力形式が正しくありません'
  }

  return undefined
}

// http://www.houjin-bangou.nta.go.jp/documents/checkdigit.pdf
const isCorporateNumber = (value) => {
  if (!value.match(/^\d{13}$/)) {
    return false
  }
  const digits = _.map(value, (digit) => +digit)
  const realDigits = _.tail(digits)
  const oddSum = _.sum(realDigits.filter((val, index) => index % 2 === 1))
  const evenSum = _.sum(realDigits.filter((val, index) => index % 2 === 0))
  const checkDigit = 9 - ((evenSum * 2 + oddSum) % 9)
  return digits[0] === checkDigit
}

const exactLengthValidators = []
export const exactLength = (len) => {
  if (!exactLengthValidators[len]) {
    exactLengthValidators[len] = (value) =>
      len && value && value.length !== len ? `は${len}文字で入力してください` : undefined
  }
  return exactLengthValidators[len]
}

export const noHankaku = (value) => (value && value.match(/[ -~]+/) ? 'を全角で入力してください' : undefined)

export const noZenkaku = (value) => (value && value.match(/[^ -~]+/) ? 'を半角で入力してください' : undefined)

export const eGovZenkakuKatakana = (value) =>
  value && !value.match(/^([\u30A1-\u30F6\uFF10-\uFF19]|[－ー\u3000])+$/) ? 'を全角で入力してください' : undefined

export const saferFilename = (value) =>
  // eslint-disable-next-line no-control-regex
  value && value.match(/[\x00-\x19\\/?*:|"<>]/) ? 'に使用できない文字が含まれています' : undefined

export const saferFilenameExcludeVariables = (value) =>
  value ? saferFilename(value.replace(/{{{\s*(.+?)\s*}}}/g, '')) : undefined

export const notOnlyVariables = (value) =>
  value && _.isEmpty(value.replace(/{{{\s*(.+?)\s*}}}/g, ''))
    ? 'は変数のみでは無効です。1文字以上の文字や数値などを含めてください。'
    : undefined
