import React, {useEffect} from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {reduxForm, Field, formValueSelector} from 'redux-form'
import {arrowRenderer, Error} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import _ from 'lodash'
import styles from 'employees/CsvDownload.scss'
import Select from 'react-select'
import {Edit, Copy, Download, PlusCircle} from 'jbc-front/components/icons'
import {required} from 'jbc-front/utils/validators'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import {Link} from 'react-router-dom'
import {getValues, convertFormToQuery, hasConditions} from 'employees/list/utils'
import {getState, makeBackUrl} from 'utils'
import {downloadFile} from 'libs/downloadFile'
import classnames from 'classnames'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const EXPORT_CSV_FORMAT_ALL = '-1'
const EXPORT_CSV_FORMAT_DELETE = '-2'
const formName = 'csv_download'
const selector = formValueSelector(formName)

const targetFileOptions = [
  {value: 'employee', label: '従業員情報'},
  {value: 'personnelHistory', label: '人事異動履歴'},
]

export const downloadOptions = [
  {value: 'UTF-8', label: 'UTF-8（推奨）'},
  {value: 'Windows-31J', label: 'Shift-JIS'},
]

const targetOptions = [
  {value: 'all', label: '全従業員'},
  {value: 'search', label: '検索絞込中の従業員'},
]

const MAX_DOWNLOAD_COUNT = 400

export const InputRadio = ({input, label, value, disabled}) => (
  <div style={{display: 'inline-block'}} className="input-label">
    <label>
      <input
        {..._.omit(input, 'value')}
        type="radio"
        value={value}
        checked={value == input.value}
        disabled={disabled}
        className={`m-radio-input${disabled ? ' disabled' : ''}`}
      />
      <span className={`m-radio-parts${disabled ? ' disabled' : ''}`}>{label}</span>
    </label>
  </div>
)

export const renderRadioField = ({label, input, options, disabled, note, radioNote, meta}) => (
  <div className={meta.error && meta.touched ? styles.invalidField : styles.field}>
    <div className={styles.label}>{label}</div>
    <div className={styles.note}>{note}</div>
    <div>
      {options.map(({label, value}) => (
        <InputRadio {...{label, value, input, disabled}} key={value} />
      ))}
    </div>
    {radioNote && <div className={styles.note}>{radioNote}</div>}
    <Error meta={meta} label={label} />
  </div>
)
const renderSelectField = ({input, label, options, note, placeholder, meta, ...rest}) => (
  <div className={meta.error && meta.touched ? styles.invalidField : styles.field}>
    <div className={styles.label}>{label}</div>
    <div className={styles.note}>{note}</div>
    <Select
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      onBlur={() => input.onBlur(input.value)}
      options={options}
      placeholder={placeholder}
      simpleValue
      arrowRenderer={arrowRenderer}
      clearable={false}
      {...rest}
    />
    <Error meta={meta} label={label} />
  </div>
)

const downloadEmployeeFile = async ({dispatch, searchValues, values, token, selectedOffices}) => {
  const {count} = await api.createWithAuth(token).employees.search({...searchValues, _page: 1, limit: 0})
  if (count >= MAX_DOWNLOAD_COUNT) {
    try {
      await api.createWithAuth(token).employeeCsvRequests.create({...values, re_create: true})
    } catch (err) {
      dispatch(asyncError(err))
    }
    dispatch(notifySuccess('従業員データはメールにてお送りします'))
    dispatch(actionCreators.fetchData('employee_csv_requests', api.createWithAuth(token).employeeCsvRequests.list()))
  } else {
    await downloadFile({
      url: `/api/export_employees_csv`,
      asyncError: (err) => dispatch(asyncError(err)),
      params: values,
      usePost: true,
      selectedOffices,
    })
  }
}

const downloadPersonnelHistoryFile = async ({dispatch, searchValues, values, token, targetFile, selectedOffices}) => {
  const {count} = await api.createWithAuth(token).employees.searchDownloadCount({...searchValues, targetFile})
  if (count >= MAX_DOWNLOAD_COUNT) {
    try {
      await api.createWithAuth(token).personnelHistoryCsvRequests.create({...values, re_create: true})
    } catch (err) {
      dispatch(asyncError(err))
    }
    dispatch(notifySuccess('人事異動履歴データはメールにてお送りします'))
    dispatch(
      actionCreators.fetchData(
        'personnel_history_csv_requests',
        api.createWithAuth(token).personnelHistoryCsvRequests.list()
      )
    )
  } else {
    await downloadFile({
      url: `/api/export_personnel_histories_csv`,
      asyncError: (err) => dispatch(asyncError(err)),
      params: values,
      usePost: true,
      selectedOffices,
    })
  }
}

const Form = compose(
  connect((state) => ({
    token: state.auth.token,
    csvFormats: fetchSelector(state, 'csv_formats').data,
    columns: selector(state, 'export_csv_format_id'),
    targetFile: selector(state, 'target_file'),
    location: state.router.location,
    selectedOffices: state.session.selectedOffices,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmit: async (
      {_target: target, target_file: targetFile, ...values},
      dispatch,
      {hideModal, token, selectedOffices}
    ) => {
      let searchValues
      if (target === 'search') {
        const state = await getState(dispatch)
        searchValues = convertFormToQuery(getValues(state))
        values = {...values, employees_query: searchValues}
      } else {
        searchValues = {
          employment_status: ['employed', 'vacation', 'join'],
        }
      }
      if (targetFile === 'employee') {
        await downloadEmployeeFile({dispatch, searchValues, values, token, selectedOffices})
      } else {
        await downloadPersonnelHistoryFile({dispatch, searchValues, values, token, targetFile, selectedOffices})
      }
      hideModal()
    },
  })
)(({handleSubmit, submitting, hideModal, csvFormats = [], columns, location, targetFile}) => {
  const backTo = makeBackUrl(location)
  const disabled = [EXPORT_CSV_FORMAT_ALL, EXPORT_CSV_FORMAT_DELETE].includes(columns)
  return (
    <React.Fragment>
      <Modal.Header hideModal={hideModal}>従業員データダウンロード</Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Field
            component={renderRadioField}
            label="出力ファイル選択"
            name="target_file"
            options={targetFileOptions}
            validate={required}
          />
          <Field
            component={renderRadioField}
            label="ファイル形式選択"
            name="encoding"
            options={downloadOptions}
            radioNote="Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。"
            validate={required}
          />
          <Field
            label="ダウンロード対象選択"
            component={renderRadioField}
            name="_target"
            options={targetOptions}
            note={
              <React.Fragment>
                全従業員：全従業員{targetFile === 'employee' ? '情報' : 'の人事異動履歴'}
                をダウンロードします。（※退職済みの従業員除く）
                <br />
                検索絞込中の従業員：
                {targetFile === 'employee' ? '従業員一覧で検索絞込した従業員情報' : '検索絞込した従業員の人事異動履歴'}
                をダウンロードします。
              </React.Fragment>
            }
            validate={required}
          />
          {targetFile === 'employee' && (
            <>
              <Field
                component={renderSelectField}
                label="ダウンロード項目選択"
                name="export_csv_format_id"
                options={csvFormats.map((format) => ({value: `${format.id}`, label: format.label}))}
                note={
                  <React.Fragment>
                    項目とヘッダを指定してフォーマット作成し、ダウンロードできます。
                    <br />
                    すべての項目をダウンロードしたい場合は【全項目】を選択してください。
                  </React.Fragment>
                }
                validate={required}
              />
              <div className={styles.buttons}>
                <ActionButton
                  className={classnames(styles.button, disabled && styles.disabled)}
                  disabled={disabled}
                  as={Link}
                  to={`/employees/csv_formats/${columns}?back_to=${backTo}`}
                >
                  <Edit />
                  閲覧・編集
                </ActionButton>
                <ActionButton
                  className={styles.button}
                  as={Link}
                  to={`/employees/csv_formats/create?back_to=${backTo}`}
                >
                  <PlusCircle />
                  新規項目フォーマット作成
                </ActionButton>
                <ActionButton
                  className={styles.button}
                  as={Link}
                  to={`/employees/csv_formats/${columns}/duplicate?back_to=${backTo}`}
                >
                  <Copy />
                  フォーマットのコピー作成
                </ActionButton>
              </div>
              <br />
              <br />
            </>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={submitting}>
            <Download />
            ダウンロード
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </React.Fragment>
  )
})

const initialValues = {
  encoding: 'UTF-8',
  target_file: 'employee',
  export_csv_format_id: '-1',
}

const CsvDownload = ({
  isModalOpen,
  showModal,
  hideModal,
  loadData,
  dispatch,
  token,
  searchValues,
  csvRequests = [],
  personnelHistoryCsvRequests = [],
  loading,
}) => {
  useEffect(() => {
    dispatch(actionCreators.fetchData('employee_csv_requests', api.createWithAuth(token).employeeCsvRequests.list()))
    dispatch(
      actionCreators.fetchData(
        'personnel_history_csv_requests',
        api.createWithAuth(token).personnelHistoryCsvRequests.list()
      )
    )
    return () => {
      dispatch(actionCreators.fetchDestroy('employee_csv_requests'))
      dispatch(actionCreators.fetchDestroy('personnel_history_csv_requests'))
    }
  }, [])
  const latest = _.last(_.sortBy(csvRequests.concat(personnelHistoryCsvRequests), 'created_at')) || {}
  const working = ['waiting', 'in_progress'].includes(latest.status)
  return (
    <React.Fragment>
      <Button
        widthAuto
        onClick={showModal}
        disabled={working || loading}
        disabledReason={working ? '作成に時間がかかるため、完了しましたらメールにてお送りいたします。' : undefined}
      >
        従業員データダウンロード
      </Button>
      <Modal
        isOpen={isModalOpen}
        hideModal={hideModal}
        onModalShow={() => {
          dispatch(actionCreators.fetchData('csv_formats', api.createWithAuth(token).employees.csvFormats.list()))
        }}
        onModalHide={() => {
          dispatch(actionCreators.fetchDestroy('csv_formats'))
        }}
      >
        {isModalOpen && (
          <Form
            {...{hideModal, loadData}}
            initialValues={{
              ...initialValues,
              _target: hasConditions(searchValues) ? 'search' : 'all',
            }}
          />
        )}
      </Modal>
    </React.Fragment>
  )
}

export default withModal(
  connect((state) => ({
    token: state.auth.token,
    searchValues: getValues(state),
    csvRequests: fetchSelector(state, 'employee_csv_requests').data,
    personnelHistoryCsvRequests: fetchSelector(state, 'personnel_history_csv_requests').data,
    loading: fetchSelector(state, 'roster_of_employees').loading,
  }))(CsvDownload)
)
