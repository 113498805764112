import React, {useMemo, useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {FieldArray, FormSection, reduxForm, formValueSelector, reset} from 'redux-form'
import _ from 'lodash'
import api from 'api'
import {actionCreators} from 'actions'
import moment from 'moment'
import {maxLength, requiredIf, required} from 'validators'
import Button from 'jbc-front/components/Button'
import FormErrors, {LabelMapper, onSubmitFail} from 'jbc-front/components/FormErrors'
import {TextAreaField, TextField, RadioField, FileField, DateField, SelectField} from 'jbc-front/components/Form'
import Hint from 'jbc-front/components/Hint'
import SelectItem from './SelectItem'
import Target from './Target'
import Confirm from './Confirm'
import styles from 'tm/notifications/Create.scss'
import classNames from 'classnames'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'tmNotificationCreateForm'
const selector = formValueSelector(formName)

const makeInitialValues = () => ({
  talent_notification: {
    answers_visibility: 'private',
    reserved_option: 'immediate',
  },
  talent_notification_options: _.times(4, (n) => ({ordinal: n + 1})),
})

const answersVisibilityOptions = [
  {value: 'all_recipients', label: '受信したユーザー全員に公開する'},
  {value: 'private', label: '非公開'},
]

const notificationReservedOptions = [
  {value: 'immediate', label: '今すぐ配信'},
  {value: 'reserved', label: '予約配信'},
]

const notificationReservedTimeOptions = [
  {value: '00:00', label: '00:00'},
  {value: '01:00', label: '01:00'},
  {value: '02:00', label: '02:00'},
  {value: '03:00', label: '03:00'},
  {value: '04:00', label: '04:00'},
  {value: '05:00', label: '05:00'},
  {value: '06:00', label: '06:00'},
  {value: '07:00', label: '07:00'},
  {value: '08:00', label: '08:00'},
  {value: '09:00', label: '09:00'},
  {value: '10:00', label: '10:00'},
  {value: '11:00', label: '11:00'},
  {value: '12:00', label: '12:00'},
  {value: '13:00', label: '13:00'},
  {value: '14:00', label: '14:00'},
  {value: '15:00', label: '15:00'},
  {value: '16:00', label: '16:00'},
  {value: '17:00', label: '17:00'},
  {value: '18:00', label: '18:00'},
  {value: '19:00', label: '19:00'},
  {value: '20:00', label: '20:00'},
  {value: '21:00', label: '21:00'},
  {value: '22:00', label: '22:00'},
  {value: '23:00', label: '23:00'},
]

const HeaderRequired = () => <span className={styles.required}>必須</span>

const validateFulfillPrevious = (index) => (value, values) => {
  if (index === 0 || !value || !values.talent_notification_options) {
    return undefined
  }

  if (values.talent_notification_options.slice(0, index).some((opt) => !opt.label)) {
    return `より前に返信${index}を入力してください`
  }

  return undefined
}

const validateUniqueness = (index) => (value, values) => {
  if (index === 0 || !value || !values.talent_notification_options) {
    return undefined
  }

  const same = values.talent_notification_options.slice(0, index).findIndex((opt) => opt.label === value)
  if (same !== -1) {
    return `が他の返信内容と重複しています`
  }
}

const NotificationOption = ({field, index}) => {
  const memoizedValidateFulfillPrevious = useMemo(() => validateFulfillPrevious(index), [index])
  const memoizedValidateUniqueness = useMemo(() => validateUniqueness(index), [index])
  return (
    <FormSection key={index} name={field}>
      <div className={styles.detailsBodyItems}>
        <span className={styles.label1}>
          {`返信${index + 1}`}
          {index === 0 && <HeaderRequired />}
        </span>
        <span className={styles.label2}>
          <TextField
            name="label"
            label={`返信${index + 1}`}
            noLabel
            validate={[
              maxLength(10),
              memoizedValidateFulfillPrevious,
              memoizedValidateUniqueness,
              requiredIf(index === 0),
            ]}
          />
        </span>
        <LabelMapper name="label" label="返信" />
      </div>
    </FormSection>
  )
}

const renderNotificationOptions = ({fields}) =>
  fields.map((field, index) => <NotificationOption key={index} field={field} index={index} />)

const HintBox = () => (
  <>
    本文の文字を強調・装飾することも可能です。詳細は
    <a href="https://jobcan-lms.zendesk.com/hc/ja/articles/360060267351" target="_blank" rel=" noopener noreferrer">
      こちら
    </a>
  </>
)

const Form =
  (({
    handleSubmit,
    onSubmitForAllEmployees,
    onSubmitForSelection,
    submitting,
    autofill,
    notificationTemplates,
    user,
    including_myself,
    pristine,
  }) => {
    const dispatch = useDispatch()
    const [reserved_option] = useSelector((state) => [selector(state, 'talent_notification.reserved_option')])
    const handleSelectTemplate = (template) => {
      autofill('talent_notification.subject', template.subject)
      autofill('talent_notification.body', template.body)
      _.times(4, (n) => {
        autofill(
          `talent_notification_options[${n}].label`,
          template.talent_notification_option_templates[n]?.label || ''
        )
      })
    }
    const handleSelectClick = (id) => {
      dispatch(actionCreators.employees.toogleSelected(id))
    }

    return (
      <form onSubmit={handleSubmit}>
        <FormErrors />
        <div className={styles.details}>
          <FormSection name="talent_notification">
            <LabelMapper name="talent_notification" label={'\u200b'} />
            {notificationTemplates && notificationTemplates.length > 0 && (
              <div className="u-mb30">
                <SelectItem onSubmit={handleSelectTemplate} />
              </div>
            )}
          </FormSection>
          <FormSection name="talent_notification">
            <div className={styles.detailsRow}>
              <div className={styles.detailsHeader}>
                タイトル
                <HeaderRequired />
              </div>
              <div className={styles.detailsBody}>
                <TextField
                  name="subject"
                  label="タイトル"
                  noLabel
                  description="最大50文字"
                  validate={[maxLength(50), required]}
                />
              </div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsHeader}>
                本文
                <HeaderRequired />
                <Hint text={<HintBox />} width={280} messageLeft={-65} baseLine />
              </div>
              <div className={styles.detailsBody}>
                <TextAreaField
                  name="body"
                  label="本文"
                  noLabel
                  description="最大2000文字"
                  validate={[maxLength(2000), required]}
                />
              </div>
            </div>
            <div className={styles.detailsRow}>
              <div className={styles.detailsHeader}>添付ファイル</div>
              <div className={styles.detailsBody}>
                <FileField name="file" label="添付ファイル" noLabel />
              </div>
            </div>
          </FormSection>
          <div className={styles.detailsRow}>
            <div className={styles.detailsHeader}>
              返信内容<span className={styles.description}>（各最大10文字）</span>
            </div>
            <div className={styles.detailsBody}>
              <FieldArray name="talent_notification_options" component={renderNotificationOptions} />
            </div>
          </div>
          <LabelMapper name="talent_notification_options" label={'\u200b'} />
          <div className={styles.detailsRow}>
            <div className={styles.detailsHeader}>
              返信状況公開範囲
              <HeaderRequired />
            </div>
            <div className={styles.detailsBody}>
              <FormSection name="talent_notification">
                <RadioField
                  name="answers_visibility"
                  label="返信状況公開範囲"
                  noLabel
                  options={answersVisibilityOptions}
                  validate={required}
                />
              </FormSection>
            </div>
          </div>
          <FormSection name="talent_notification">
            <div className={styles.detailsRow}>
              <div className={styles.detailsHeader}>配信日時設定</div>
              <div className={styles.detailsBody}>
                <div className={styles.detailsBodyItems}>
                  <span className={styles.reservedOption}>
                    <RadioField
                      name="reserved_option"
                      label="配信日時設定"
                      noLabel
                      options={notificationReservedOptions}
                    />
                  </span>
                  {reserved_option == 'reserved' && (
                    <>
                      <span className={styles.reservedDate}>
                        <DateField
                          name="reserved_date"
                          label="予約配信日付"
                          noLabel
                          isValidDate={(date) => moment(date).isSameOrAfter(moment().format('YYYY/MM/DD'))}
                          jpYearDate={false}
                        />
                      </span>
                      <span className={styles.reservedTime}>
                        <SelectField
                          name="reserved_time"
                          label="予約配信時間"
                          noLabel
                          options={notificationReservedTimeOptions}
                          placeholder="時間選択"
                        />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </FormSection>
        </div>
        <div className={styles.buttonArea}>
          <FormSection name="talent_notification">
            <div className={styles.includingMyself} onClick={() => handleSelectClick(user.employee.id)}>
              <input className="m-checkbox-input" type="checkbox" checked={including_myself} readOnly />
              <span className="m-checkbox-parts">回答者に自分自身を含める</span>
            </div>
          </FormSection>
          <span className={styles.button}>
            <Button primary onClick={handleSubmit(onSubmitForAllEmployees)} disabled={pristine || submitting}>
              全員に配信
            </Button>
          </span>
          <span className={styles.button}>
            <Button primary onClick={handleSubmit(onSubmitForSelection)} disabled={pristine || submitting}>
              通知対象を選択
            </Button>
          </span>
        </div>
      </form>
    )
  })
  |> reduxForm({
    form: formName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    shouldError: () => true,
    onSubmitFail,
  })

const invalidEventRegex = /(on(\S+?)=("|')(.+?)("|')|on(\S+?)("|')(.+?)("|')|("|')javascript:(\S+?)("|'))/g

const htmlTagsCheck = (dispatch, values) => {
  if (values.talent_notification.body?.match(invalidEventRegex)) {
    values.talent_notification.body.match(invalidEventRegex).map((invalidEventAttribute) => {
      dispatch(notifyError(`${invalidEventAttribute}は利用できません`))
    })
    return false
  }
  return true
}

const reservedCheck = (dispatch, values) => {
  if (values.talent_notification.reserved_option == 'reserved') {
    if (!values.talent_notification.reserved_date || !values.talent_notification.reserved_time) {
      dispatch(notifyError('予約配信日時を入力してください'))
      return false
    }

    const current_datetime = moment()
    const reserved_at = moment(
      values.talent_notification.reserved_date + ' ' + values.talent_notification.reserved_time + ':00'
    )
    if (current_datetime >= reserved_at) {
      dispatch(notifyError('予約配信日時は、現在より後の日時を入力してください'))
      return false
    }
    Object.assign(values.talent_notification, {reserved_at: reserved_at})
  }

  return values
}

const Create = ({history, pristine}) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState('create')
  const [target, setTarget] = useState('all')
  const stepRef = useRef('create')
  const targetRef = useRef('all')
  const [token, notificationTemplates = [], user, selected] = useSelector((state) => [
    state.auth.token,
    state.tm.notificationTemplates.data,
    state.session.currentTmUser,
    state.employees.selected,
  ])
  const including_myself = selected[user.employee.id] || false

  const handleReload = (event) => {
    event.preventDefault()
    event.returnValue = 'このサイトを再読み込みしますか？行った変更が保存されない可能性があります。'
  }

  const overridePopstate = () => {
    switch (targetRef.current) {
      case 'all':
        overridePopstateAll()
        return
      case 'select':
        overridePopstateSelect()
        return
    }
  }

  const overridePopstateAll = () => {
    switch (stepRef.current) {
      case 'create':
        window.history.back()
        return
      case 'confirm':
        window.history.pushState(null, null, null)
        setStep('create')
        return
    }
  }

  const overridePopstateSelect = () => {
    switch (stepRef.current) {
      case 'create':
        window.history.back()
        return
      case 'target':
        window.history.pushState(null, null, null)
        setStep('create')
        return
      case 'confirm':
        window.history.pushState(null, null, null)
        setStep('target')
        return
    }
  }

  useEffect(() => {
    history.replace('/tm/notifications/create')
    dispatch(
      actionCreators.tm.notificationTemplates.fetchData(api.createWithAuth(token).tm.notificationTemplates.list())
    )
    window.history.pushState(null, null, null)
    window.addEventListener('popstate', overridePopstate, false)
    window.addEventListener('beforeunload', handleReload, false)
    dispatch(reset(formName))
    dispatch(actionCreators.employees.clearSelected())
    return () => {
      dispatch(actionCreators.tm.notificationTemplates.destroy())
      window.removeEventListener('popstate', overridePopstate, false)
      window.removeEventListener('beforeunload', handleReload, false)
    }
  }, [])

  useEffect(() => {
    stepRef.current = step
  }, [step])

  useEffect(() => {
    targetRef.current = target
  }, [target])

  return (
    <div className={'l-contents-wrap l-wrap-xxl ' + styles.contentsWrapSp}>
      {step === 'create' && (
        <>
          <div className="l-breadcrumb">
            <Link to="/tm/notifications/" className="l-breadcrumb-link">
              従業員通知
            </Link>
            <span className="l-breadcrumb-here">新規通知作成</span>
          </div>
          <div className={classNames(styles.createTitleWrap, 'l-title-wrap')}>
            <h1 className="m-title-main">新規通知作成</h1>
          </div>
          <Form
            {...{notificationTemplates, user, including_myself, pristine}}
            initialValues={makeInitialValues()}
            onSubmitForAllEmployees={(values) => {
              try {
                if (!htmlTagsCheck(dispatch, values)) {
                  return
                }
                if (!(values = reservedCheck(dispatch, values))) {
                  return
                }
                setTarget('all')
                setStep('confirm')
                window.scrollTo(0, 0)
              } catch (err) {
                dispatch(asyncError(err))
              }
            }}
            onSubmitForSelection={(values) => {
              try {
                if (!htmlTagsCheck(dispatch, values)) {
                  return
                }
                if (!(values = reservedCheck(dispatch, values))) {
                  return
                }
                setTarget('select')
                setStep('target')
                window.scrollTo(0, 0)
              } catch (err) {
                dispatch(asyncError(err))
              }
            }}
          />
        </>
      )}
      {step === 'target' && (
        <Target handleSetStep={setStep} user={user} selected={selected} including_myself={including_myself} />
      )}
      {step === 'confirm' && (
        <Confirm handleSetStep={setStep} selected={selected} target={target} including_myself={including_myself} />
      )}
    </div>
  )
}

export default Create
