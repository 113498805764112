import React from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import PaymentMethodForm from 'settings/usingPlan/PaymentMethodForm'
import _ from 'lodash'
import {connect} from 'react-redux'
import moment from 'moment'
import {notifyError} from 'store/actions/notify'

const ChangePaymentMethod = ({isModalOpen, hideModal, showModal, paymentInfo = {}, dispatch}) => (
  <React.Fragment>
    <a
      className="u-txt-link u-ml10"
      onClick={() => {
        const now = moment().utcOffset(9)
        if (now.date() === 1 && now.hour() <= 3 && now.hour() >= 0) {
          dispatch(notifyError('毎月1日0時から4時までは支払い方法の変更ができません'))
        } else {
          showModal()
        }
      }}
    >
      変更
    </a>
    <Modal isOpen={isModalOpen}>
      <Modal.Header hideModal={hideModal}>決済方法を選択してください</Modal.Header>
      {isModalOpen && (
        <PaymentMethodForm
          hideModal={hideModal}
          initialValues={{
            payment_method_type: 'credit_card',
            credit_info: {
              employee_email:
                paymentInfo.payment_method_type === 'bill'
                  ? _.get(paymentInfo, 'bill_info.employee_email')
                  : _.get(paymentInfo, 'credit_info.employee_email'),
            },
          }}
        />
      )}
    </Modal>
  </React.Fragment>
)

export default connect()(withModal(ChangePaymentMethod))
