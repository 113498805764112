import React from 'react'
import {NumberFields, TextField, DateField, Section, Label, BoxDouble} from 'jbc-front/components/Form'
import {reduxForm} from 'redux-form'
import {Address, SubmitedOn} from 'FormFields'
import Button from 'jbc-front/components/Button'
import connectForm from 'reportForms/connectForm'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {zenkakuKatakana} from 'validators'
import EmployeeName from 'procedures/EmployeeName'

const formName = 'R71Report'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({submitting, handleSubmit, submitText, employee}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">外国人雇用状況届出書</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <Section>
        <BoxDouble>
          <TextField name="last_name" label="姓" type="text" />
          <TextField name="first_name" label="名" type="text" />
          <TextField name="middle_name" label="ミドルネーム" type="text" />
        </BoxDouble>
        <BoxDouble>
          <TextField name="last_name_kana" label="姓カナ" validate={zenkakuKatakana} />
          <TextField name="first_name_kana" label="名カナ" validate={zenkakuKatakana} />
          <TextField name="middle_name_kana" label="ミドルネームカナ" validate={zenkakuKatakana} />
        </BoxDouble>
        <BoxDouble>
          <DateField name="joined_at_0" label="雇入れ年月日1" />
          <DateField name="joined_at_1" label="雇入れ年月日2" />
          <DateField name="joined_at_2" label="雇入れ年月日3" />
        </BoxDouble>
        <BoxDouble>
          <DateField name="retired_at_0" label="離職年月日1" />
          <DateField name="retired_at_1" label="離職年月日2" />
          <DateField name="retired_at_2" label="離職年月日3" />
        </BoxDouble>
        <TextField name="main_office_name" label="主たる事務所名" />
        <Label text="主たる事務所 所在地" />
        <Address prefix="main_office_" withKana={false} />
        <NumberFields texts={['', ' - ']} lengths={[4, 4, 4]} label="電話番号" prefix="main_office_phone_number" />
        <SubmitedOn />
      </Section>
      <div className="u-ta-c u-mt30">
        <Button primary onClick={handleSubmit} disabled={submitting}>
          {submitText}
        </Button>
      </div>
    </div>
  </form>
))

const connectedForm = connectForm('r71', Form, {
  mapState: (state) => ({
    employee: state.procedureStatuses.current.data.employee || {},
  }),
})

export default connectedForm
