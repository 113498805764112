import React, {useEffect} from 'react'
import Form, {makeInitialValuesForUpdate} from 'procedures/maternityLeave/finish/Form'
import {alreadyExistSameDependent} from 'procedures/maternityLeave/Form'
import {useDispatch, useSelector} from 'react-redux'
import {SubmissionError} from 'redux-form'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'

export default ({
  match: {
    params: {id},
  },
}) => {
  const procedureStatus = useSelector((state) => state.procedureStatuses.current.data)
  const dependents = useSelector((state) => state.procedureStatuses.current.data?.employee?.employee_dependents)
  const token = useSelector((state) => state.auth.token)
  const authedApi = api.createWithAuth(token)
  const dispatch = useDispatch()

  useEffect(() => {
    loadProcedureStatus()
    return () => destroy()
  }, [])

  const loadProcedureStatus = () => {
    dispatch(
      actionCreators.procedureStatuses.current.fetchData(
        authedApi.procedureStatuses.get(id, ['employee', 'employee_dependents', 'procedure_maternity_datum'])
      )
    )
  }

  const destroy = () => {
    dispatch(actionCreators.employees.current.destroy())
  }

  const update = async (values) => {
    if (alreadyExistSameDependent(values.procedure_maternity_child_data, dependents)) {
      throw new SubmissionError({'': 'すでに同じ被扶養者が登録されています'})
    }
    try {
      const formattedValues = {
        ...values,
        procedure_maternity_child_data:
          values.maternity_status === 'after' ? values.procedure_maternity_child_data : [],
        mother_child_note: values.maternity_status === 'after' ? values.mother_child_note : [],
      }
      await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: formattedValues.expected_birth_date})
      await authedApi.procedureStatuses.finishMaternityData.update(procedureStatus.id, formattedValues)
      if (
        formattedValues.procedure_maternity_child_data.some(
          (child_datum) => child_datum.add_support && !child_datum.skip_add_support
        )
      ) {
        dispatch(
          push(
            `/add_dependents/detail?employee_id=${procedureStatus.employee_id}&maternity_procedure_status_id=${procedureStatus.id}`
          )
        )
      } else {
        dispatch(push(`/procedure_statuses/${procedureStatus.id}`))
      }
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <Form
      onSubmit={update}
      initialValues={makeInitialValuesForUpdate(procedureStatus, procedureStatus.employee)}
      submitText="保存"
      employee={procedureStatus.employee || {}}
    />
  )
}
