import React from 'react'
import {Error} from 'jbc-front/components/Form'
import {autofill, Field} from 'redux-form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {notifyError} from 'store/actions/notify'
import styles from './CardInput.scss'
import _ from 'lodash'
import PayjpCheckout from 'jbc-front/components/PayjpCheckout'
import CreditCardInfo from 'jbc-front/components/CreditCardInfo'
import {required} from 'validators'

export const CardInput = ({dispatch, disabled, formName, cardToken}) => (
  <div className={disabled ? `${styles.disablePointer} u-mb20` : 'u-mb20'}>
    <PayjpCheckout
      apiKey={process.env.PAYJP_KEY}
      submitText="カード情報を入力する"
      text="カード情報を入力する"
      onCreated={({id, card}) => {
        dispatch(autofill(formName, 'credit_info.card_token', id))
        dispatch(autofill(formName, 'credit_info._card_info', card))
      }}
      onFailed={(errorCode, errorResponse) => {
        dispatch(notifyError(_.get(errorResponse, 'message') || `エラーが発生しました(code: ${errorCode})`))
      }}
      previousToken={cardToken}
    />
    <LabelMapper name="card_token" label="カード情報" />
    <Field name="card_token" validate={required} label="カード情報" component={Error} />
  </div>
)

export const CardInfo = ({cardInfo}) => (
  <div>
    <div>カード情報</div>
    <CreditCardInfo cardInfo={cardInfo} className={styles.cardInfo} />
  </div>
)
