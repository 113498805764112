import React from 'react'
import {connect} from 'react-redux'
import {notifyError} from 'store/actions/notify'
import {reduxForm, FieldArray, FormSection, Field, reset} from 'redux-form'
import styles from 'settings/AllowedIps.scss'
import ActionButton from 'jbc-front/components/ActionButton'
import {Edit, Strage, MinusCircle, ChangeRow} from 'jbc-front/components/icons'
import {Delete} from 'jbc-front/components/icons'
import {recordDisplay} from 'utils'
import {required} from 'validators'
import _ from 'lodash'

export const renderIpsTable = ({editing, handleSubmit, handleCancel, handleEdit, fields, allowedIpOptions}) => (
  <div>
    {editing ? (
      <div className={styles.buttonWrap}>
        <ActionButton onClick={handleSubmit} icon={<Strage size={15} />}>
          保存
        </ActionButton>
        <ActionButton onClick={handleCancel} icon={<MinusCircle size={15} />}>
          キャンセル
        </ActionButton>
        <ActionButton
          onClick={() => {
            fields.push(allowedIpOptions)
          }}
          icon={<ChangeRow size={15} />}
        >
          行を追加
        </ActionButton>
      </div>
    ) : (
      <div>
        <ActionButton onClick={handleEdit} icon={<Edit size={15} />}>
          編集
        </ActionButton>
      </div>
    )}
    <table className={styles.dataTable}>
      <thead>
        <tr>
          <th>IPアドレス</th>
          <th>備考</th>
          <th className={styles.cellIcon} />
        </tr>
      </thead>
      {editing ? <EditContent fields={fields} /> : <Content />}
    </table>
  </div>
)

export const IpsTable = connect(
  (state, {selector}) => ({
    editing: selector(state, '_editing'),
  }),
  (dispatch, {formName, autofill}) => ({
    handleCancel() {
      dispatch(reset(formName))
    },
    handleEdit() {
      autofill('_editing', true)
    },
  })
)(renderIpsTable)

export const makeForm = (formName) =>
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })(({handleSubmit, allowedIpOptions, selector, autofill}) => (
    <form onSubmit={handleSubmit}>
      <FieldArray
        name="allowed_ips"
        component={IpsTable}
        handleSubmit={handleSubmit}
        allowedIpOptions={allowedIpOptions}
        formName={formName}
        autofill={autofill}
        selector={selector}
      />
    </form>
  ))

export const renderInput = ({input, meta: {touched, error}, ...rest}) => (
  <td>
    <input className={touched && error ? styles.invalidFormInput : styles.formInput} {...input} {...rest} />
  </td>
)

export const renderContent = ({allowedIps}) => (
  <tbody>
    {allowedIps.length > 0 ? (
      allowedIps.map((allowedIp) => (
        <tr key={allowedIp.id}>
          <td>
            <p className={styles.formInput}>{recordDisplay(allowedIp.ip)}</p>
          </td>
          <td>
            <p className={styles.notes}>{recordDisplay(allowedIp.notes)}</p>
          </td>
          <td />
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan={3}>
          <div className={styles.listNodata}>IPアドレスを登録してください。</div>
        </td>
      </tr>
    )}
  </tbody>
)

export const Content = connect((state) => ({
  allowedIps: state.settings.allowedIps.data,
}))(renderContent)

export const EditContent = ({fields}) => (
  <tbody>
    {fields.map((field, index) => (
      <FormSection name={field} component="tr" key={index}>
        <Field component={renderInput} name="ip" validate={required} />
        <Field component={renderInput} name="notes" />
        <td className={styles.listDelete}>
          <Delete
            size="20"
            onClick={() => {
              fields.remove(index)
            }}
          />
        </td>
      </FormSection>
    ))}
  </tbody>
)

export const names = {
  ip: 'IPアドレス',
  notes: '備考',
}

export const onSubmitFail = (errors, dispatch) => {
  if (errors.allowed_ips) {
    errors.allowed_ips.forEach((allowedIp) => {
      if (allowedIp) {
        _.each(allowedIp, (msg, key) => {
          dispatch(notifyError(`${names[key]}${msg}`))
        })
      }
    })
  }
}
