import React, {Component} from 'react'
import Form, {autofill, formatValues, makeInitialValues} from 'procedures/changeName/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {parse} from 'query-string'
import {asyncError} from 'store/actions/asyncError'

class Detail extends Component {
  componentDidMount() {
    const {
      loadData,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    loadData(employeeId, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {employee} = this.props
    return <Form onSubmit={this.handleSubmit} initialValues={makeInitialValues(employee)} employee={employee} />
  }

  handleSubmit = (values) => {
    const {
      updateAndCreateProcedure,
      location: {search},
      token,
    } = this.props
    const {employee_id: employeeId} = parse(search)
    return updateAndCreateProcedure(employeeId, values, token)
  }
}

export default connect(
  (state) => ({
    employee: state.employees.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadData: (id, token) => {
      dispatch(actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.get(id)))
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
    },
    updateAndCreateProcedure: async (employeeId, {procedure_status_id: procedureStatusId, ...values}, token) => {
      try {
        const authedApi = api.createWithAuth(token)
        const {dateOn, employee, procedureData} = formatValues(values)
        if (!procedureStatusId) {
          const {
            data: {id},
          } = await authedApi.procedureStatuses.start(employeeId, 'change_name', dateOn)
          dispatch(autofill('procedure_status_id', id))
          procedureStatusId = id
        }
        if (procedureData.id) {
          await authedApi.procedureStatuses.changeNameData.update(procedureStatusId, procedureData)
        } else {
          const {
            data: {id},
          } = await authedApi.procedureStatuses.changeNameData.create(procedureStatusId, procedureData)
          dispatch(autofill('procedure_data.id', id))
        }
        await authedApi.employees.update(employeeId, employee, [])
        dispatch(push(`/change_name/flow/${procedureStatusId}`))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Detail)
