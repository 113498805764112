const readBlob = (blob) => {
  const read = (method) => {
    const reader = new FileReader(blob)
    return new Promise((resolve, reject) => {
      reader[method](blob)
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })
  }
  return {
    json() {
      return read('readAsText').then((json) => JSON.parse(json))
    },
    arrayBuffer() {
      return read('readAsArrayBuffer')
    },
  }
}

export default readBlob
