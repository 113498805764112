import React from 'react'
import {FieldArray, FormSection} from 'redux-form'
import {Section, TextField, DateField, TextAreaField} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {PlusSquare, DeleteSquare} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import {Description, withFormSelectors} from 'employees/form/common'
import * as validators from 'validators'

const renderEmployeeSkill = ({fields, diffLst = []}) => (
  <div>
    <LabelMapper name="employee_skills" label="スキルの" />
    {fields.map((skill, index) => {
      const diff = diffLst[index] || {}
      return (
        <Section
          title={`スキル (${index + 1})`}
          key={index}
          icon={
            <DeleteSquare
              onClick={() => {
                fields.remove(index)
              }}
            />
          }
        >
          <FormSection name={skill}>
            <TextField
              name="skill_name"
              label="スキル名"
              validate={validators.maxLength(32)}
              diff={diff.skill_name}
              required
            />
            <DateField name="acquisition_date" label="習得年月日" diff={diff.acquisition_date} />
            <TextAreaField
              name="skill_details"
              label="スキル詳細"
              validate={validators.maxLength(255)}
              diff={diff.skill_details}
              rows="4"
            />
          </FormSection>
        </Section>
      )
    })}
    {fields.length < 1 && (
      <div className="u-ta-c">
        <p>
          「スキルを追加」ボタンを押して、
          <br />
          スキルを追加してください。
        </p>
      </div>
    )}
    <div className="u-ta-r u-mt20">
      <div onClick={() => fields.push({})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />
        スキルを追加
      </div>
    </div>
  </div>
)

export const EmployeeSkill = ({description, description_color_by_rgb, diff = {}}) => (
  <Section title="スキル">
    <Description {...{description, description_color_by_rgb}} />
    <FieldArray name="employee_skills" component={renderEmployeeSkill} diffLst={diff.employee_skills} />
  </Section>
)

export default EmployeeSkill
  |> connect((state, {selector}) => ({
    diff: selector(state, '_diff'),
  }))
  |> withFormSelectors
