import {FC} from 'react'
import {Link} from 'react-router-dom'
import {PasswordForm} from '../PasswordForm'

interface FailedProps {
  isValid?: boolean
  errors?: Error
  token?: string
}

export const Result: FC<FailedProps> = ({isValid, errors, token}) => {
  if (errors) {
    return (
      <div className="l-wrap-xs u-pt100">
        <div className="l-box-message">
          <p>エラーが発生しました。もう一度お試しください。</p>
        </div>
      </div>
    )
  }

  if (isValid === false || !token) {
    return (
      <div className="l-wrap-xs u-pt100">
        <div className="l-box-message">
          <p>
            このURLは登録が完了しているか、有効期限が過ぎています。
            <br />
            <Link to="/login" className="u-txt-link">
              ログインはこちらから
            </Link>
          </p>
        </div>
      </div>
    )
  }

  return <PasswordForm token={token} />
}
