import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import {Log} from 'settings/auditLogs/Show'

class Show extends Component {
  componentDidMount() {
    const {
      token,
      match: {
        params: {log_id: id},
      },
      loadData,
    } = this.props
    loadData(token, id)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {log} = this.props
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">操作履歴</h1>
        </div>
        <div className="l-wrap-s l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/my_number?my_number_employment_status=employed_and_joined" className="l-breadcrumb-link">
              マイナンバー
            </Link>
            <Link to="/my_number/audit_logs" className="l-breadcrumb-link">
              操作履歴
            </Link>
            <span className="l-breadcrumb-here">操作履歴詳細</span>
          </div>
          <Log log={log} />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    log: state.settings.auditLogs.current.data,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadData(token, id) {
      dispatch(actionCreators.settings.auditLogs.current.fetchData(api.createWithAuth(token).auditLogs.get(id)))
    },
    destroy() {
      dispatch(actionCreators.settings.auditLogs.current.destroy())
    },
  })
)(Show)
