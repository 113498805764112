import {FC, ReactNode} from 'react'

interface SubPageTitleProps {
  children: ReactNode
}

export const SubPageTitle: FC<SubPageTitleProps> = ({children}) => {
  return (
    <div className="l-title-wrap">
      <h1 className="m-title-main">{children}</h1>
    </div>
  )
}
