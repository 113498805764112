import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import {DeleteSquare} from 'jbc-front/components/icons'
import {FileNameLink} from 'components/ui/FileNameLink'
import {displayFormat, dateFormat} from 'libs/formatter'
import styles from 'employeeFiles/Top.scss'
import {SortableTh} from 'jbc-front/components/SearchForm'

const makeNotFoundMessage = (type) => {
  switch (type) {
    case 'uploaded':
      return 'アップロード'
    case 'procedure':
      return '手続き'
    case 'other':
      return '社内規定'
    default:
      return null
  }
}

const SortableThWithFileType = ({field, onChangeSortType, currentSortType, onChangeFileType, children}) => {
  const handleClick = (sortType) => {
    onChangeSortType(sortType)
    onChangeFileType()
  }

  return (
    <SortableTh field={field} currentSortType={currentSortType} onChange={handleClick} globalClassName={styles.th}>
      {children}
    </SortableTh>
  )
}

const TableHeader = ({type, onChangeSortType, currentSortType, onChangeFileType}) => (
  <thead>
    <tr>
      <SortableThWithFileType
        field="created_at"
        onChangeSortType={onChangeSortType}
        currentSortType={currentSortType}
        onChangeFileType={onChangeFileType}
      >
        日付
      </SortableThWithFileType>
      <SortableThWithFileType
        field="file_name"
        onChangeSortType={onChangeSortType}
        currentSortType={currentSortType}
        onChangeFileType={onChangeFileType}
      >
        ファイル名
      </SortableThWithFileType>
      <SortableThWithFileType
        field="uploader_name"
        onChangeSortType={onChangeSortType}
        currentSortType={currentSortType}
        onChangeFileType={onChangeFileType}
      >
        {type === 'uploaded' ? '担当者' : '申請者'}
      </SortableThWithFileType>
      <th className={styles.th}>メッセージ</th>
      <th className={styles.th}>削除</th>
    </tr>
  </thead>
)

const Row = ({file, trashInProcess, onDeleteFile}) => {
  const fileObject = file.file

  return (
    <tr>
      <td className={styles.td}>{dateFormat(file.file_info.created_at, 'YYYY/MM/DD')}</td>
      <td className={styles.td}>
        {fileObject && fileObject.url ? (
          <FileNameLink url={fileObject.url}>{displayFormat(fileObject.filename)}</FileNameLink>
        ) : (
          displayFormat(fileObject?.filename)
        )}
      </td>
      <td className={styles.td}>{displayFormat(file.uploader_name)}</td>
      <td className={classNames(styles.td, 'u-ta-l')}>{displayFormat(file.file_info.message_by_uploader)}</td>
      <td className={styles.td}>
        <a
          className={trashInProcess[file.file_info.id] ? styles.deleteButtonDisabled : styles.deleteButton}
          onClick={() => onDeleteFile(file.file_info.id)}
        >
          <DeleteSquare />
        </a>
      </td>
    </tr>
  )
}

export const FileTable = ({
  type,
  files,
  trashInProcess,
  onDeleteFile,
  onChangeSortType,
  currentSortType,
  onChangeFileType,
}) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list m-table-fixed">
      <TableHeader
        type={type}
        onChangeSortType={onChangeSortType}
        currentSortType={currentSortType}
        onChangeFileType={onChangeFileType}
      />
      <tbody>
        {_.isEmpty(files) ? (
          <tr>
            <td className={styles.td} colSpan={5}>
              {makeNotFoundMessage(type)}ファイルがありません
            </td>
          </tr>
        ) : (
          files.map((file) => (
            <Row key={file.id} file={file} trashInProcess={trashInProcess} onDeleteFile={onDeleteFile} />
          ))
        )}
      </tbody>
    </table>
  </div>
)
