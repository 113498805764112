import {combineReducers} from 'redux'
import {getQueryDiffFactory} from 'utils'
import {actionTypes} from 'actions'
import fetchReducer from 'reducers/fetchReducer'
import queryReducer from 'reducers/queryReducer'

const defaultQuery = {
  page: 1,
}

export default combineReducers({
  current: fetchReducer(actionTypes.SETTINGS.AUDIT_LOGS.CURRENT, {data: {}}),
  list: fetchReducer(actionTypes.SETTINGS.AUDIT_LOGS.LIST, {data: [], count: 0}),
  query: queryReducer(actionTypes.SETTINGS.AUDIT_LOGS.SET_QUERY, defaultQuery),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
