import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import type {RouteParams} from 'types/routes'
import {accountRoutes} from './account'

// @ts-ignore
import Show from 'settings/eGov/Show'
// @ts-ignore
import Edit from 'settings/eGov/Edit'

export const egovRoutes: RouteParams[] = [
  {
    key: 'settings/egov',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.SHOW,
    component: Show,
    exact: true,
    whiteList: ['full_admin', 'office_admin'],
  },
  {
    key: 'settings/egov/edit',
    path: WITH_AUTH_ADMIN_PATHS.SETTINGS.EGOV.EDIT,
    component: Edit,
    exact: true,
    whiteList: ['full_admin', 'office_admin'],
  },
  ...accountRoutes,
]
