import {actionTypes} from 'actions'
import {combineReducers} from 'redux'
import {getQueryDiffFactory} from 'utils'
import queryReducer from 'reducers/queryReducer'

const defaultQuery = {
  page: 1,
  status: 'all',
  my_number_status: 'all',
}

export default combineReducers({
  query: queryReducer(actionTypes.BUSINESS_PARTNERS.SET_QUERY, defaultQuery),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
