import React, {Component} from 'react'
import {connect} from 'react-redux'
import {replace} from 'connected-react-router'

export default () => (Child) => {
  class checkNoEmployeeOrUnemployed extends Component {
    state = {
      checking: true,
    }

    componentDidMount() {
      const {dispatch, employee, clientRoleType} = this.props
      if (clientRoleType === 'member' && (!employee || employee.employment_status === 'unemployed')) {
        dispatch(replace(`/dashboard?error=unemployed`))
      }
      this.setState({checking: false})
    }

    render() {
      return !this.state.checking && <Child {...this.props} />
    }
  }
  return connect((state) => ({
    employee: state.session.currentUser.employee,
    clientRoleType: state.session.currentUser.client_role.client_role_type,
  }))(checkNoEmployeeOrUnemployed)
}
