import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {Section, TextField, Label} from 'jbc-front/components/Form'
import * as validators from 'validators'
import {connect} from 'react-redux'
import {reduxForm, formValueSelector, autofill, FormSection} from 'redux-form'
import {TaxOffice} from 'features/offices/TaxOffice'
import {ClientName, CorporateNumber, Address, Phone, Representative} from 'FormFields'
import {
  HealthInsurance,
  SocialInsurance,
  EmploymentInsurance,
  RemunerationInformation,
  validate,
} from 'client/ClientForm'
import _ from 'lodash'
import styles from 'offices/OfficeForm.scss'
import {OfficeSelectorField} from 'features/offices/OfficeSelectorField'

const formName = 'officeForm'
const selector = formValueSelector(formName)

const ApplicableOfficeInformation = () => (
  <Section title="適用事業所管理情報">
    <TextField name="office_name" label="適用事業所別名" required validate={validators.maxLength(20)} />
    <p className={styles.note}>適用事業所設定や通知に示される名称です。20文字以内で入力してください。</p>
    <TextField
      name="office_code"
      label="適用事業所コード"
      validate={[validators.maxLength(10), validators.officeCode]}
    />
    <p className={styles.note}>適用事業所管理に使われるコードです。半角英数10文字以内で入力してください。</p>
  </Section>
)

class renderLaborInsurance extends Component {
  componentDidUpdate(prevProps) {
    // 労働保険情報の事業所情報の入力値を事業主情報に反映
    const {dispatch, laborFieldValues} = this.props
    const diff = _.omitBy(
      laborFieldValues,
      (value, key) => prevProps.laborFieldValues && prevProps.laborFieldValues[key] === value
    )

    if (!_.isEmpty(diff)) {
      _.forIn(diff, (value, key) => {
        dispatch(autofill(formName, `office_contact_infos[1].${key}`, value))
      })
    }
  }

  render() {
    const {taxOfficePrefectureId} = this.props
    return (
      <Section title="労働保険情報">
        <Section title="事業所情報">
          <FormSection name="office_contact_infos[0]">
            <ClientName />
            <Address />
            <Phone />
          </FormSection>
          <CorporateNumber />
          <TaxOffice prefectureId={taxOfficePrefectureId} />
        </Section>
        <Section title="事業主情報">
          <FormSection name="office_contact_infos[1]">
            <Label text="事業主（代表者）" />
            <Representative />
            <ClientName label="事業主名称" />
            <Address />
            <Phone />
          </FormSection>
        </Section>
        <LabelMapper name="office_name" label="適用事業所別名" />
        <LabelMapper name="name" label="事業所名 / 事業主名称" />
        <LabelMapper name="name_kana" label="事業所名（カナ）/ 事業主名称（カナ）" />
      </Section>
    )
  }
}

const LaborInsurance = connect((state) => ({
  laborFieldValues: selector(state, 'office_contact_infos[0]'),
  ownerFieldValues: selector(state, 'office_contact_infos[1]'),
  taxOfficePrefectureId: selector(state, 'tax_office_prefecture_id'),
}))(renderLaborInsurance)

const SocialInsuranceReference = ({mode}) => (
  <Section title="社会保険事業所情報">
    <OfficeSelectorField
      name="social_contact_info_office_id"
      label="社会保険事業所情報の参照先"
      required
      extraItems={mode === 'create' ? [{key: '-1', label: '（現在編集中の適用事業所）'}] : undefined}
    />
  </Section>
)

const base = ({handleSubmit, pristine, submitting, mode}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">適用事業所情報の入力</h1>
    </div>
    <LabelMapper name="office_contact_infos" label="適用事業所情報" />
    <div>
      <FormErrors />
      <ApplicableOfficeInformation />
      <LaborInsurance />
      <SocialInsuranceReference mode={mode} />
      <HealthInsurance formName={formName} />
      <SocialInsurance />
      <EmploymentInsurance />
      <RemunerationInformation />
      <div className="u-ta-c u-mt30">
        <Button primary onClick={handleSubmit} disabled={pristine || submitting}>
          保存
        </Button>
      </div>
    </div>
  </form>
)

export default reduxForm({
  form: formName,
  validate,
  enableReinitialize: true,
  onSubmitFail,
})(base)
