import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Index} from 'pages/withAuth/adminPage/documents/index'
import {EmployeeRoutes} from './employees'
import {DocumentIdRoutes} from './[documentId]'

export const DocumentRoutes: RouteParams[] = [
  ...DocumentIdRoutes,
  ...EmployeeRoutes,
  {
    key: 'documents',
    path: WITH_AUTH_ADMIN_PATHS.DOCUMENTS.INDEX,
    component: Index,
    exact: true,
  },
]
