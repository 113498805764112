import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import styles from 'dashboard/dashboard.scss'
import {parse} from 'query-string'

const messages = {
  employee_info: '手続き申請に必要な必須情報が入力されていません。手続きを申請する前に管理者にお問い合わせください。',
  unemployed: '手続き申請ができません。管理者にお問い合わせください。',
}

class Errors extends Component {
  render() {
    const {search} = this.props
    return (
      <div className={styles.errors}>
        <Panel>
          <Headding className={styles.errorHedding}>エラーメッセージ</Headding>
          <Item>
            <ul>
              <li className={styles.error}>{messages[parse(search).error]}</li>
            </ul>
          </Item>
        </Panel>
      </div>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  search: location.search,
}))(Errors)
