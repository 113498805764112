import {useEffect, useState} from 'react'
import {useSortColumn} from 'hooks/useSort'
import {useQuery} from 'hooks/graphql'
import {useKeywordInput} from 'hooks/useKeywordInput'
import {QueryResult, QueryVariables, DetailInputRequestStatus, DETAIL_INPUT_REQUESTS, SortColumn} from '../query'
import useRouter from 'use-react-router'

type searchParamsType = {
  page?: string
  per?: string
  sortColumn?: string
  sortOrder?: string
  q?: string
}

export const useFetchRequestList = () => {
  const {
    match,
    history,
    location: {search},
  } = useRouter()

  const queryString = history.location.state || search
  const defaultQuery = new URLSearchParams(queryString as string)
  const defaultFilters = defaultQuery.getAll('filter') as DetailInputRequestStatus[]
  const [filters, setFilters] = useState<DetailInputRequestStatus[]>(
    defaultFilters.length > 0 ? defaultFilters : ['none', 'applying', 'rejected']
  )
  const [searchParams, setSearchParams] = useState<searchParamsType>({
    page: defaultQuery.get('page') || '1',
    per: defaultQuery.get('per') || '10',
    sortColumn: defaultQuery.get('sortColumn') || 'updated_at',
    sortOrder: defaultQuery.get('sortOrder') || 'desc',
    q: defaultQuery.get('q') || undefined,
  })

  useEffect(() => {
    const query = new URLSearchParams()
    Object.entries(searchParams).forEach(([key, value]) => value && query.append(key, value))
    filters?.forEach((value) => query.append('filter', value))
    history.push(`${match.path}?${query.toString()}`, query.toString())
  }, [searchParams, filters])

  const sortProps = useSortColumn<SortColumn>('updated_at', (sortOrder: 'desc' | 'asc', sortColumn: SortColumn) => {
    setSearchParams({...searchParams, sortOrder, sortColumn})
  })

  const [, keywordSubject] = useKeywordInput((value: string) => {
    setSearchParams({...searchParams, q: value})
  })

  const handleFilterSelct = (key: DetailInputRequestStatus) => {
    if (filters.includes(key)) {
      if (filters.length > 1) {
        const newFilters = filters.filter((status) => status !== key)
        setFilters(newFilters)
      }
    } else {
      const newFilters = [...filters, key]
      setFilters(newFilters)
    }
  }

  const handlePerChange = (per: number) => {
    setSearchParams({...searchParams, per: String(per)})
  }

  const handlePageChange = (page: number) => {
    setSearchParams({...searchParams, page: String(page)})
  }

  const variables: QueryVariables = {
    page: Number(searchParams.page),
    per: Number(searchParams.per),
    search: {
      sortColumn: searchParams.sortColumn as SortColumn,
      sortOrder: (searchParams.sortOrder as 'asc' | 'desc') || 'desc',
      filter: filters,
      q: searchParams.q,
    },
  }

  const {data, loading} = useQuery<QueryResult>(DETAIL_INPUT_REQUESTS, {
    fetchPolicy: 'network-only',
    variables,
  })

  return {
    variables,
    sortProps,
    keywordSubject,
    handleFilterSelct,
    handlePerChange,
    handlePageChange,
    data,
    loading,
  }
}
