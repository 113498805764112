import {connect} from 'react-redux'
import {autofill, submit} from 'redux-form'
import {SortableTh} from 'jbc-front/components/SearchForm'
import {selector, formName} from 'employees/list/utils'

const mapStateToProps = (state) => ({
  currentSortType: selector(state, 'sort_type'),
})
const mapDispatchToProps = (dispatch) => ({
  onChange: (sortType) => {
    dispatch(autofill(formName, 'sort_type', sortType))
    dispatch(submit(formName))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(SortableTh)
