import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'

type Params = {
  encoding: EncodingType
}

export type EncodingType = 'UTF-8' | 'Windows-31J'

export const useDownloadGroupsCsv = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()

  return async (params: Params) => {
    const path = '/api/export_groups_csv/'
    const getter = () => authAxios.get(path, {responseType: 'arraybuffer', params})

    download(getter)
  }
}
