import {useAuthAxios} from 'hooks/api/useAuthAxios'

export type Params = {
  flag: number
}

export const useUpdateStarFlag = () => {
  const authAxios = useAuthAxios()
  return async (id: number, data: Params) => authAxios.patch(`/api/procedure_statuses/${id}`, data)
}
