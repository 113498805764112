import Create from 'documents/Create'
import Show from 'documents/Show'
import ShowContracts from 'documents/ShowContracts'
import Contract from 'documents/Contract'

export default {
  Create,
  Show,
  ShowContracts,
  Contract,
}
