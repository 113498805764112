import React from 'react'
import {FormSection} from 'redux-form'
import {connect} from 'react-redux'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {Description, withFormSelectors, getDiffFromCheckbox, getDiffFromFile} from 'employees/form/common'
import {isAdminSelector} from 'utils'
import {
  Section,
  CheckboxField,
  NumberFields,
  TextField,
  FileField,
  BoxDouble,
  DateField,
} from 'jbc-front/components/Form'
import {Joined} from 'FormFields'
import _ from 'lodash'
import styles from 'employees/form/BasicInfomation.scss'

const EmploymentInsuranceInfomation = ({
  isAdmin,
  needDetail,
  procedureType,
  showNoteForEmplpyee,
  joined,
  shouldShowComponent,
  description,
  description_color_by_rgb,
  isInitialInput,
  retiredAt,
  diff = {},
}) =>
  shouldShowComponent ? (
    <Section title="雇用保険">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <FormSection name="employment_insurance">
        <LabelMapper name="employment_insurance" label="雇用保険の" />
        {(isAdmin || (procedureType !== 'enroll' && !isInitialInput)) && (
          <Joined outputsNote={procedureType === 'enroll'} diff={diff} />
        )}
        {joined && (
          <div>
            {showNoteForEmplpyee && (
              <p className="u-mb20">※以下の雇用保険情報は過去に雇用保険に加入したことがある方のみご記入ください。</p>
            )}
            <NumberFields
              texts={['', ' - ']}
              lengths={[4, 6, 1]}
              label="雇用保険被保険者番号"
              prefix="person_number"
              exactLength={true}
              diff={diff.person_number}
            />
            <FileField name="image" label="雇用保険被保険者証画像" diff={getDiffFromFile(diff.image)} />
            <div className={styles.notes}>
              すでにファイルが存在する場合は、ファイルを更新した時点で上書き更新されますのでご注意ください。
            </div>
            <CheckboxField
              name="need_detail"
              label="雇用保険被保険者番号が分からない"
              diff={getDiffFromCheckbox(diff.need_detail)}
            />
            {needDetail && (
              <React.Fragment>
                <TextField name="last_company_name" label="前職の会社名" diff={diff.last_company_name} />
                <TextField name="last_company_address" label="前職の会社住所" diff={diff.last_company_address} />
              </React.Fragment>
            )}
            {procedureType !== 'enroll' && (
              <BoxDouble>
                {!isInitialInput && <DateField name="start_on" label="資格取得日" diff={diff.start_on} required />}
                {isAdmin && <DateField name="end_on" label="離職等年月日" required={retiredAt} />}
              </BoxDouble>
            )}
          </div>
        )}
      </FormSection>
    </Section>
  ) : null

export default EmploymentInsuranceInfomation
  |> connect((state, {procedureType, selector, isInitialInput, isForceEmployee}) => {
    const isAdmin = !isForceEmployee && isAdminSelector(state)
    const joined = selector(state, 'employment_insurance.joined')
    return {
      isAdmin,
      joined,
      retiredAt: selector(state, 'retired_at'),
      needDetail: selector(state, 'employment_insurance.need_detail'),
      diff: selector(state, '_diff.employment_insurance'),
      shouldShowComponent: isAdmin || joined || procedureType !== 'enroll',
      showNoteForEmplpyee: isInitialInput || procedureType === 'enroll',
    }
  })
  |> withFormSelectors

const validatePersonNumber = ({name, personNumbers}, values) => {
  const employmentInsurance = values[name]
  if (!employmentInsurance) {
    return {}
  }
  const isPersonNumber = personNumbers.some((personNumber) => {
    return !employmentInsurance[personNumber] === false
  })

  const errors = {}
  if (isPersonNumber) {
    personNumbers.forEach((personNumber) => {
      if (!employmentInsurance[personNumber]) {
        _.set(errors, `${name}[${personNumber}]`, 'を入力してください')
      }
    })
  }

  return errors
}

export const validate = (values) => {
  return Object.assign(
    {},
    validatePersonNumber(
      {name: 'employment_insurance', personNumbers: ['person_number_0', 'person_number_1', 'person_number_2']},
      values
    )
  )
}
