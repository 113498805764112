import {actionTypes} from 'actions'
import update from 'immutability-helper'
import {combineReducers} from 'redux'
import {getQueryDiffFactory} from 'utils'
import fetchReducer from 'reducers/fetchReducer'
import queryReducer from 'reducers/queryReducer'
import _ from 'lodash'

const initialState = {
  data: {},
}
const listInitialState = {
  data: [],
  count: 0,
}

const defaultQuery = {
  page: 1,
  status: 'all',
  procedure_type: 'all',
  sortColumn: null,
  order: null,
  searchKeyword: null,
}

const defaultCsvProcedureQuery = {
  page: 1,
  status: null,
  procedure_type: null,
  search_keyword: null,
  procedure_kind: null,
}

const currentReducer =
  (withReducer) =>
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.PROCEDURE_STATUSES.UPDATE_TODO: {
        const delta = action.payload.status == 'done' ? 1 : -1
        const allDone = state.data.done_todos_count + delta === state.data.total_todos_count
        const doneCount = state.data.done_todos_count + delta
        const progress = Math.floor((doneCount / state.data.total_todos_count) * 100)
        return update(state, {
          data: {
            todo_lists: {
              [action.payload.todoListNo]: {
                todos: {
                  [action.payload.todoNo]: {
                    $merge: {status: action.payload.status},
                  },
                },
              },
            },
            progress: {$set: progress},
            done_todos_count: {$apply: (count) => count + delta},
            status: {$set: allDone ? 'done' : 'in_progress'},
          },
        })
      }
      case actionTypes.PROCEDURE_STATUSES.UPDATE_TODO_ALL: {
        const doneCount = action.payload.status == 'done' ? state.data.total_todos_count : 0
        const progress = action.payload.status == 'done' ? 100 : 0
        return {
          data: {
            ...state.data,
            todo_lists: state.data.todo_lists.map((todo_list) => ({
              ...todo_list,
              todos: (todo_list.todos || []).map((todo) => ({
                ...todo,
                status: action.payload.status,
              })),
            })),
            progress: progress,
            done_todos_count: doneCount,
            status: action.payload.status,
          },
        }
      }
      default:
        return withReducer(state, action)
    }
  }

const listReducer =
  (withReducer) =>
  (state = listInitialState, action) => {
    switch (action.type) {
      case actionTypes.PROCEDURE_STATUSES.SET_FLAG: {
        const index = _.findIndex(state.data, (procedureStatus) => procedureStatus.id == action.payload.id)
        if (index >= 0) {
          return update(state, {
            data: {
              [index]: {
                flag: {
                  $set: action.payload.flag,
                },
              },
            },
          })
        }
        return state
      }
      default:
        return withReducer(state, action)
    }
  }

export default combineReducers({
  query: queryReducer(actionTypes.PROCEDURE_STATUSES.SET_QUERY, defaultQuery),
  list: listReducer(fetchReducer(actionTypes.PROCEDURE_STATUSES.LIST, listInitialState)),
  current: currentReducer(fetchReducer(actionTypes.PROCEDURE_STATUSES.CURRENT, initialState)),
  report: fetchReducer(actionTypes.PROCEDURE_STATUSES.REPORT, initialState),
  tmpData: fetchReducer(actionTypes.PROCEDURE_STATUSES.TMP_DATA, initialState),
  summary: fetchReducer(actionTypes.PROCEDURE_STATUSES.SUMMARY, initialState),
  myNumberSummary: fetchReducer(actionTypes.PROCEDURE_STATUSES.MY_NUMBER_SUMMARY, initialState),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
export const getCsvProcedureQueryDiff = getQueryDiffFactory(defaultCsvProcedureQuery)
