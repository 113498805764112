import React from 'react'
import {Field} from 'redux-form'
import {SearchSelectBox} from 'jbc-front/components/SearchForm'
import styles from 'employees/list/JoinedStatusSelectBoxes.scss'

const options = [
  {value: '', label: '-'},
  {value: 'true', label: '加入'},
  {value: 'false', label: '未加入'},
  {value: 'null', label: '未入力'},
]

class JoinedStatusSelectBoxes extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.field}>
          <span className={styles.fieldLabel}>健康保険</span>
          <Field name="joined_health_insurance" component={SearchSelectBox} options={options} width={110} />
        </div>

        <div className={styles.field}>
          <span className={styles.fieldLabel}>厚生年金保険</span>
          <Field name="joined_welfare_pension_insurance" component={SearchSelectBox} options={options} width={110} />
        </div>

        <div className={styles.field}>
          <span className={styles.fieldLabel}>雇用保険</span>
          <Field name="joined_employment_insurance" component={SearchSelectBox} options={options} width={110} />
        </div>
      </div>
    )
  }
}

export default JoinedStatusSelectBoxes
