import {FC} from 'react'
import {Link} from 'react-router-dom'

interface Props {
  paths: Path[]
  current: string
}

interface Path {
  key: string
  to: string
  label: string
}

export const Breadcrumb: FC<Props> = ({paths, current}) => {
  return (
    <div className="l-breadcrumb u-mb20">
      {paths.map((path: Path) => (
        <Link key={path.key} to={path.to} className="l-breadcrumb-link">
          {path.label}
        </Link>
      ))}
      <span className="l-breadcrumb-here">{current}</span>
    </div>
  )
}
