import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {Link} from 'react-router-dom'
import Button from 'jbc-front/components/Button'
import ActionButton from 'jbc-front/components/ActionButton'
import _ from 'lodash'
import {recordDisplay} from 'utils'
import styles from 'myNumber/List.scss'
import {Filter, Pager, PerPage, ListData} from 'myNumber/businessPartner/ListData'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import Modal from 'jbc-front/components/Modal'
import {submit} from 'redux-form'
import Request from 'myNumber/businessPartner/Request'
import {downloadFile} from 'libs/downloadFile'
import {Tab} from 'components/Tabs'
import {Download, Pulldown} from 'jbc-front/components/icons'
import {DownloadForm} from 'myNumber/List'
import Balloon from 'components/Balloon'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const submitDownload = submit.bind(null, 'download')

const businessStatuses = {
  planning: '取引予定',
  doing: '取引中',
  finished: '取引終了',
}

class Row extends Component {
  handleClick = () => {
    const {onClick, businessPartner} = this.props
    onClick(businessPartner.id)
  }

  handleRequestClick = (e) => {
    const {onRequestClick, businessPartner} = this.props
    onRequestClick(businessPartner)
    e.stopPropagation()
  }

  render() {
    const {businessPartner} = this.props

    return (
      <tr onClick={this.handleClick} className="table-hover">
        <td className="u-nowrap">{recordDisplay(businessPartner.code)}</td>
        <td>{recordDisplay(businessPartner.name)}</td>
        <td>{recordDisplay(businessStatuses[businessPartner.business_status])}</td>
        <td>{recordDisplay(businessPartner.email)}</td>
        <td className="u-nowrap">
          {recordDisplay(businessPartner.my_number_input_request_status)}
          <div className="u-mt10">
            <ActionButton primary onClick={this.handleRequestClick}>
              {_.get(businessPartner, 'my_number_input_request.status') === 'completed' ? '再提出依頼' : '提出依頼'}
            </ActionButton>
          </div>
        </td>
      </tr>
    )
  }
}

const myNumberFilters = {
  all: 'すべて',
  not_completed: '未取得',
  completed: '取得済',
}

class List extends Component {
  state = {
    showDownloadModal: false,
    showRequest: false,
    requestBusinessPartner: {},
    showChooseAddMethod: false,
  }

  static defaultProps = {
    businessPartners: [],
  }

  handleRowClick = (id) => {
    this.props.history.push(`/my_number/business_partners/${id}`)
  }

  handleRequestClick = (businessPartner) => {
    this.setState({showRequest: true, requestBusinessPartner: businessPartner})
  }

  downloadFile = (values) => {
    this.setState({showDownloadModal: false})
    return downloadFile({
      url: `/api/export_business_partners_csv?encoding=${values.encoding}`,
      asyncError: this.props.asyncError,
      selectedOffices: this.props.selectedOffices,
    })
  }

  render() {
    const {businessPartners, count, history} = this.props

    return (
      <div>
        <ListData params={{embed: 'my_number_input_request'}} ref={(elm) => (this._listData = elm)} />
        <div className="l-main-title-wrap u-pb0">
          <h1 className="m-title-main">マイナンバー一覧</h1>
          <p className="m-title-main-note">
            ジョブカン給与計算をご利用の場合、登録されたマイナンバーや取得状況は
            <br />
            スタッフコードをもとにジョブカン給与計算にも自動反映されます。
          </p>
          <div className={styles.tabs}>
            <Tab onClick={() => history.push('/my_number?my_number_employment_status=employed_and_joined')}>従業員</Tab>
            <Tab active>取引先</Tab>
          </div>
        </div>
        <div className="l-contents-wrap">
          <div className="l-wrap-xxl">
            <div className={styles.buttonArea}>
              <div className={styles.buttonDl}>
                <Button as="a" widthAuto onClick={() => this.setState({showDownloadModal: true})}>
                  マイナンバーダウンロード
                </Button>
              </div>
              <Balloon
                center
                switchRender={(toggle, isOpen) => (
                  <Button as="a" className={isOpen && 'ignore-react-onclickoutside'} onClick={toggle} primary widthAuto>
                    取引先登録
                  </Button>
                )}
              >
                <Balloon.Ul>
                  <Balloon.Li className={styles.addLink}>
                    <Link to="/my_number/business_partners/create">
                      <Pulldown transform="rotate(-90)" />
                      新規登録（直接入力）
                    </Link>
                  </Balloon.Li>
                  <Balloon.Li className={styles.addLink}>
                    <Link to="/my_number/business_partners/import">
                      <Pulldown transform="rotate(-90)" />
                      新規登録（CSVインポート）
                    </Link>
                  </Balloon.Li>
                  <Balloon.Li className={styles.addLink}>
                    <Link to="/my_number/business_partners/import_update">
                      <Pulldown transform="rotate(-90)" />
                      更新（CSVインポート）
                    </Link>
                  </Balloon.Li>
                </Balloon.Ul>
              </Balloon>
              <Link to="/my_number/audit_logs">
                <Button primary widthAuto>
                  操作履歴
                </Button>
              </Link>
            </div>
            <div className={styles.topArea}>
              <div className={styles.refineArea}>
                {_.map(myNumberFilters, (filter, key) => (
                  <Filter key={key} filter={key} column="my_number_status" label={filter} />
                ))}
              </div>
            </div>
            <div className="l-overflow-scroll">
              <table className="m-table-list">
                <thead>
                  <tr>
                    <th>管理コード</th>
                    <th>取引先名</th>
                    <th>取引状況</th>
                    <th>メールアドレス</th>
                    <th>マイナンバー</th>
                  </tr>
                </thead>
                <tbody>
                  {businessPartners.map((businessPartner) => (
                    <Row
                      key={businessPartner.id}
                      onClick={this.handleRowClick}
                      onRequestClick={this.handleRequestClick}
                      businessPartner={businessPartner}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <Modal
              header="取引先情報のダウンロード"
              onSubmit={() => this.props.submitDownload()}
              isOpen={this.state.showDownloadModal}
              submit={
                <React.Fragment>
                  <Download />
                  ダウンロード
                </React.Fragment>
              }
              cancel="キャンセル"
              hideModal={() => this.setState({showDownloadModal: false})}
              body={<DownloadForm initialValues={{encoding: 'UTF-8'}} onSubmit={this.downloadFile} />}
            />
            <Request
              isOpen={this.state.showRequest}
              hide={() => this.setState({showRequest: false})}
              type="businessPartner"
              businessPartner={this.state.requestBusinessPartner}
              onRequestFinish={() => this._listData.loadData()}
            />
            <div className="m-pager-area">
              <Pager totalResult={count} pageLinks={7} />
              <PerPage />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    businessPartners: fetchSelector(state, 'business_partners').data,
    count: fetchSelector(state, 'business_partners').count,
    token: state.auth.token,
    query: state.businessPartners.query,
    selectedOffices: state.session.selectedOffices,
  }),
  (dispatch) => ({
    notifyNoSelect() {
      dispatch(notifyError('従業員が選択されていません'))
    },
    loadEmployees(token, query) {
      dispatch(actionCreators.businessPartners.list.fetchData(api.createWithAuth(token).businessPartners.list(query)))
    },
    submitDownload() {
      dispatch(submitDownload())
    },
    async invite(token, id) {
      try {
        await api.createWithAuth(token).businessPartners.invite(id)
        dispatch(notifySuccess('送信しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    ...bindActionCreators({asyncError: asyncError}, dispatch),
  })
)(List)
