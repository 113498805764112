import {FC, ReactNode} from 'react'
import {useDispatch} from 'react-redux'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup/dist/yup'
import {usePostInitialPassword} from 'hooks/api/InviteeInitialPassword'
import {asyncError} from 'store/actions/asyncError'

import {Error} from 'jbc-front/components/presenters/form/Error'
import {Input} from 'jbc-front/components/presenters/form/Input/Input'
import {Label, Required} from 'jbc-front/components/presenters/form/Label'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Button from 'jbc-front/components/Button'
import {Section} from 'jbc-front/components/Form'

interface LabelWithRequiredProps {
  children: ReactNode
}

interface PasswordFormProps {
  token: string
}

interface PasswordFormSchema {
  password: string
  password_confirmation: string
}

const LabelWithRequired: FC<LabelWithRequiredProps> = ({children}) => (
  <>
    <Label>
      {children}
      <Spacer size={5} direction="x" />
      <Required />
    </Label>
    <Spacer size={8} />
  </>
)

export const PasswordForm: FC<PasswordFormProps> = ({token}) => {
  const dispatch = useDispatch()
  const schema = yup.object({
    password: yup.string().required('を入力してください'),
    password_confirmation: yup.string().required('を入力してください'),
  })
  const {
    handleSubmit,
    formState: {errors, isSubmitting, isDirty},
    register,
    setError,
  } = useForm<PasswordFormSchema>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
  })

  const post = usePostInitialPassword(token)
  const createInitialPassword = async (data: {password: string; password_confirmation: string}) => {
    try {
      await post(data)
    } catch (err) {
      if (err.response.status === 422 && err.response.data?._errors) {
        const passwordError = err.response.data._errors?.password
        const passwordConfirmationError = err.response.data._errors?.password_confirmation
        passwordError && setError('password', {type: 'custom', message: passwordError[0]})
        passwordConfirmationError &&
          setError('password_confirmation', {type: 'custom', message: passwordConfirmationError[0]})
      } else {
        dispatch(asyncError(err))
      }
    }
  }

  return (
    <form>
      <Section>
        <LabelWithRequired>パスワード</LabelWithRequired>
        <Input {...register('password')} type="password" isError={!!errors.password?.message} />
        {errors.password?.message && <Error error={errors.password.message} label="パスワード" />}
        <Spacer size={20} />
        <LabelWithRequired>パスワード(確認用)</LabelWithRequired>
        <Input
          {...register('password_confirmation')}
          type="password"
          isError={!!errors.password_confirmation?.message}
        />
        {errors.password_confirmation?.message && (
          <Error error={errors.password_confirmation.message} label="パスワード(確認用)" />
        )}
        <div className="u-ta-c u-mt30">
          <Button onClick={handleSubmit(createInitialPassword)} primary disabled={!isDirty || isSubmitting}>
            登録する
          </Button>
        </div>
      </Section>
    </form>
  )
}
