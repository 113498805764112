import React from 'react'
import {FormSection} from 'redux-form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {Copy} from 'jbc-front/components/icons'
import {connect} from 'react-redux'
import {Description, JAPAN_VALUE, withFormSelectors} from 'employees/form/common'
import {addressNames} from 'employees/form/BasicInfomation'
import {Name, Address, Phone} from 'FormFields'
import ActionButton from 'jbc-front/components/ActionButton'
import {Section, SelectField} from 'jbc-front/components/Form'
import {relationshipOtherTypes} from 'employees/form/Dependents'
import _ from 'lodash'

const relationships = [{value: '夫', label: '夫'}, {value: '妻', label: '妻'}, ...relationshipOtherTypes]

const EmergencyContact = ({
  country,
  copyAddress,
  diff: diffEmp = {},
  nations = [],
  description,
  description_color_by_rgb,
}) => {
  const japan = _.find(nations, (nation) => nation.value === JAPAN_VALUE)
  const japanId = japan ? '' + japan.id : null
  const diff = diffEmp.emergency_contact || {}
  return (
    <Section title="緊急連絡先">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <FormSection name="emergency_contact">
        <LabelMapper name="emergency_contact" label="緊急連絡先の" />
        <div>
          <Name diff={diff} />
          <SelectField creatable name="relationship" label="続柄" options={relationships} diff={diff.relationship} />
          <Phone diff={diff} />
          <ActionButton
            className="u-mt40"
            onClick={copyAddress}
            disabled={country != japanId}
            icon={<Copy size={13} />}
          >
            現住所をコピーする
          </ActionButton>
          <div className="u-mt20">
            <Address diff={diff} />
          </div>
        </div>
      </FormSection>
    </Section>
  )
}

export default EmergencyContact
  |> connect(
    (state, {selector}) => ({
      country: selector(state, 'country'),
      nations: state.master.nations,
      diff: selector(state, '_diff'),
    }),
    (dispatch, {getValues, autofill}) => ({
      copyAddress() {
        dispatch((dispatch, getState) => {
          const values = getValues(getState())
          addressNames.forEach((name) => dispatch(autofill(`emergency_contact.${name}`, values[name])))
        })
      },
    })
  )
  |> withFormSelectors
