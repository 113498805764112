import {Question} from 'jbc-front/components/icons'
import styles from './Help.scss'

export const Help = () => (
  <div className={styles.help}>
    <a target="_blank" rel="noopener noreferrer" href="https://jobcan-lms.zendesk.com/hc/ja">
      <Question size={20} />
      ヘルプ
    </a>
  </div>
)
