import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'

export const useDownloadOfficialDocumentFile = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()

  return async (egovProcedureId: string, officialDocumentId: string) => {
    const path = `/api/egov/v1/egov_procedures/${egovProcedureId}/official_document_file/${officialDocumentId}`
    const getter = () => authAxios.get(path, {responseType: 'arraybuffer'})

    download(getter)
  }
}
