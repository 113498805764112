import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'
import styles from 'employees/List.scss'
import {Link as LinkIcon, Pulldown} from 'jbc-front/components/icons'
import Balloon from 'components/Balloon'
import Cookies from 'js-cookie'
import Modal from 'jbc-front/components/CommonModal'
import {Link} from 'react-router-dom'
import set from 'lodash/fp/set'
import {connect} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'

export const isSync = (service = 'payroll') => Cookies.get(`using_jbc_${service}`) === 'true'

const UnsyncModal = ({isOpen, hideModal}) => (
  <Modal isOpen={isOpen} hideModal={hideModal}>
    <Modal.Header hideModal={hideModal}>ジョブカン給与計算との連携</Modal.Header>
    <Modal.Body>
      現在、連携設定が行われていません。
      <br />
      連携の設定を行うことで各ジョブカンシリーズとの情報の連携が簡単にできます。
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal}>閉じる</Button>
        <Button primary as={Link} to="/settings/coordination">
          設定画面へ
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </Modal>
)

const ImportModal = ({
  isOpen,
  hideModal,
  importContent,
  notice = '連携項目がすべて上書きされます',
  onSubmit,
  submitting,
}) => (
  <Modal isOpen={isOpen} hideModal={hideModal}>
    <Modal.Header hideModal={hideModal}>ジョブカン給与計算から{importContent}を取得</Modal.Header>
    <Modal.Body>{notice}</Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal} disabled={submitting}>
          キャンセル
        </Button>
        <Button primary onClick={onSubmit} disabled={submitting}>
          取得
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </Modal>
)

class Coordination extends Component {
  state = {
    importModal: {
      isOpen: false,
      submitting: false,
    },
    unsyncModal: {
      isOpen: false,
    },
  }

  static defaultProps = {
    others: [],
    text: '連携',
  }

  handleImport = async () => {
    const {onImport, dispatch} = this.props
    try {
      this.setState(set('importModal.submitting', true))
      await onImport()
      this.setState(set('importModal.isOpen', false))
    } catch (err) {
      dispatch(asyncError(err))
    } finally {
      this.setState(set('importModal.submitting', false))
    }
  }

  render() {
    const {importModal, unsyncModal} = this.state
    const {others, center, right, text, confirmUrl, notice, isFreePlan, importContent} = this.props
    return (
      <React.Fragment>
        <Balloon
          center={center}
          right={right}
          switchRender={(toggle, isOpen) => (
            <Button
              {...(isOpen ? {className: 'ignore-react-onclickoutside'} : {})}
              onClick={toggle}
              icon={<LinkIcon size={17} />}
              widthAuto
              disabled={isFreePlan}
              disabledReason={isFreePlan && <p>無料プランのため利用できません。</p>}
            >
              {text}
              <span className={styles.triangle} />
            </Button>
          )}
        >
          {(hide) => (
            <React.Fragment>
              <Balloon.Ul className={styles.links}>
                <Balloon.Li>
                  <a
                    onClick={() => {
                      if (isSync()) {
                        this.setState(set('importModal.isOpen', true))
                      } else {
                        this.setState(set('unsyncModal.isOpen', true))
                      }
                      hide()
                    }}
                  >
                    <Pulldown transform="rotate(-90)" />
                    ジョブカン給与計算から取得
                  </a>
                </Balloon.Li>
                {confirmUrl && (
                  <Balloon.Li>
                    <a href={confirmUrl} target="_blank" rel="noopener noreferrer">
                      <Pulldown transform="rotate(-90)" />
                      ジョブカン給与計算との連携項目を確認する
                    </a>
                  </Balloon.Li>
                )}
                {others.map((value, index) => (
                  <Balloon.Li key={index}>{value}</Balloon.Li>
                ))}
              </Balloon.Ul>
            </React.Fragment>
          )}
        </Balloon>
        <UnsyncModal {...unsyncModal} hideModal={() => this.setState(set('unsyncModal.isOpen', false))} />
        <ImportModal
          {...importModal}
          hideModal={() => this.setState(set('importModal.isOpen', false))}
          onSubmit={this.handleImport}
          notice={notice}
          importContent={importContent}
        />
      </React.Fragment>
    )
  }
}

export default connect()(Coordination)
