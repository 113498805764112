import React from 'react'
import {Fields, Field} from 'redux-form'
import _ from 'lodash'
import {dateFieldProps} from 'jbc-front/components/Form'
import {SearchTextBox} from './SearchForm'
import {dateString} from 'validators'
import styles from './SearchForm.scss'

/* eslint-disable no-unused-vars */
export const renderError = ({names, label, ...fields}) => {
  /* eslint-enable no-unused-vars */
  const field = _.find(fields, (field) => field.meta && field.meta.touched && field.meta.error)
  if (field) {
    return (
      <p>
        {label}
        {field.meta.error}
      </p>
    )
  }
  return null
}

export const DateTmRow = ({header, errorLabel, prefix}) => (
  <div className={styles.detailsRow}>
    <div className={styles.detailsHeader}>{header}</div>
    <div className={styles.detailsBody}>
      <div className={styles.detailsBodyItems}>
        <Field
          name={`${prefix}_start`}
          component={SearchTextBox}
          type="date"
          {...dateFieldProps}
          validate={dateString}
          className={styles.selectDate}
        />
        <span className={styles.detailsComplementText}>から</span>
        <Field
          name={`${prefix}_end`}
          component={SearchTextBox}
          type="date"
          {...dateFieldProps}
          validate={dateString}
          className={styles.selectDate}
        />
        <span className={styles.detailsComplementText}>の間</span>
      </div>
      <div className={styles.detailsBodyErrors}>
        <Fields component={renderError} names={[`${prefix}_start`, `${prefix}_end`]} label={errorLabel} />
      </div>
    </div>
  </div>
)

export const TextTmRow = ({header, fieldName}) => (
  <div className={styles.detailsRow}>
    <div className={styles.detailsHeader}>{header}</div>
    <div className={styles.detailsBody}>
      <div className={styles.detailsBodyItems}>
        <div className={styles.SearchTextBox}>
          <Field name={fieldName} component={SearchTextBox} type="text" />
        </div>
      </div>
      <div className={styles.notes}>複数条件の場合はスペース区切りで入力してください</div>
      <div className={styles.detailsBodyErrors}>
        <Fields component={renderError} names={[fieldName]} label={header} />
      </div>
    </div>
  </div>
)
