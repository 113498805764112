import {connect} from 'react-redux'
import {ListTypeWithResult} from 'jbc-front/components/SearchForm'
import {selector} from 'employees/list/utils'

const limits = ['10', '20', '50', '100', '200'].map((ele) => ({value: ele, label: `${ele}件を表示`}))
const sortTypes = [
  {value: 'staff_code__asc', label: 'スタッフコード 昇順'},
  {value: 'staff_code__desc', label: 'スタッフコード 降順'},
  {value: 'full_name_kana__asc', label: '氏名 昇順'},
  {value: 'full_name_kana__desc', label: '氏名 降順'},
  {value: 'employment_type__asc', label: '雇用形態 設定順'},
  {value: 'employment_type__desc', label: '雇用形態 設定逆順'},
  {value: 'group__asc', label: 'グループ 設定順'},
  {value: 'group__desc', label: 'グループ 設定逆順'},
  {value: 'position__asc', label: '役職 設定順'},
  {value: 'position__desc', label: '役職 設定逆順'},
  {value: 'occupation__asc', label: '職種 設定順'},
  {value: 'occupation__desc', label: '職種 設定逆順'},
  {value: 'joined_at__desc', label: '入社日 新しい順'},
  {value: 'joined_at__asc', label: '入社日 古い順'},
  {value: 'retired_at__desc', label: '退社日 新しい順'},
  {value: 'retired_at__asc', label: '退社日 古い順'},
]

const listTypes = [
  {value: 'list', label: 'リスト表示'},
  {value: 'card', label: 'カード表示'},
]

const displayTypes = [
  {value: 'default', label: 'デフォルト'},
  {value: 'compact', label: 'コンパクト'},
]

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: state.employees.list.count,
  limits,
  sortTypes,
  listTypes,
  displayTypes,
  page: selector(state, 'page'),
  isLoading: state.employees.list.loading,
})
export default connect(mapStateToProps)(ListTypeWithResult)
