import React from 'react'
import {Field} from 'redux-form'
import {connect} from 'react-redux'
import Select from 'react-select'
import {selector} from './utils'
import styles from './SearchForm.scss'

const limits = ['10', '20', '50', '100', '200'].map((ele) => ({value: ele, label: `表示件数：${ele}件`}))
const sortTypes = [
  {value: 'email__asc', label: 'メールアドレス 昇順'},
  {value: 'email__desc', label: 'メールアドレス 降順'},
  {value: 'staff_code__asc', label: 'スタッフコード 昇順'},
  {value: 'staff_code__desc', label: 'スタッフコード 降順'},
  {value: 'full_name_kana__asc', label: '氏名 昇順'},
  {value: 'full_name_kana__desc', label: '氏名 降順'},
  {value: 'group__asc', label: 'グループ 設定順'},
  {value: 'group__desc', label: 'グループ 設定逆順'},
]

class SearchSelectBox extends React.Component {
  render() {
    const {input: inputProps, options, width, onSelect, ...rest} = this.props
    return (
      <div className={styles.searchSelectBox} style={{width}}>
        <Select
          {...inputProps}
          options={options}
          searchable={false}
          clearable={false}
          simpleValue
          value={inputProps.value}
          onChange={(newValue) => {
            inputProps.onChange(newValue)
            if (onSelect) {
              onSelect(newValue)
            }
          }}
          {...rest}
        />
      </div>
    )
  }
}

const SearchResult = ({total, page, limit, isLoading, failureMessage = '結果がありません', className}) => {
  if (isLoading) return <div className={className} />
  const begin = limit * (page - 1) + 1
  const lastElementOfPage = limit * page
  const end = lastElementOfPage > total ? total : lastElementOfPage
  return (
    <div className={className}>
      {total > 0 ? (
        <div>
          <span className={styles.searchResultTotal}>{total}</span>件中
          <span className={styles.searchResultRange}>
            {begin}-{end}
          </span>
          を表示
        </div>
      ) : (
        <p className={styles.searchResultErrorMessage}>{failureMessage}</p>
      )}
    </div>
  )
}

const SortingFields = ({limits, sortTypes, onChangeLimit, onChangeSortType, sortName}) => (
  <div className={styles.sortingFields}>
    <Field
      name="limit"
      component={SearchSelectBox}
      options={limits}
      width={130}
      onSelect={(newValue) => {
        onChangeLimit(newValue)
      }}
    />
    <Field
      name={sortName}
      component={SearchSelectBox}
      options={sortTypes}
      width={180}
      onSelect={() => onChangeSortType()}
    />
  </div>
)

const SortingFieldsWithResult = ({
  limit,
  count,
  limits,
  sortTypes,
  page,
  isLoading,
  onChangeLimit,
  onChangeSortType,
  failureMessage = '従業員が見つかりませんでした',
  sortName = 'sort_type',
}) => (
  <div className={styles.sortingFieldsWithResult}>
    <SearchResult
      total={count}
      page={page}
      limit={limit}
      isLoading={isLoading}
      failureMessage={failureMessage}
      className={styles.resultCenter}
    />
    <SortingFields
      limits={limits}
      sortTypes={sortTypes}
      onChangeLimit={onChangeLimit}
      onChangeSortType={onChangeSortType}
      sortName={sortName}
    />
  </div>
)

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  count: state.employees.list.count,
  limits,
  sortTypes,
  page: selector(state, 'page'),
  isLoading: state.employees.list.loading,
})
export default connect(mapStateToProps)(SortingFieldsWithResult)
