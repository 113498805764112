import React from 'react'
import 'react-select/dist/react-select.css'
import {Pulldown} from 'jbc-front/components/icons'
import styles from 'documents/create/list/SearchForm.scss'

export const SearchResult = ({total, page, limit, isLoading, failureMessage = '結果がありません', className}) => {
  if (isLoading) return <div className={className} />
  const begin = limit * (page - 1) + 1
  const lastElementOfPage = limit * page
  const end = lastElementOfPage > total ? total : lastElementOfPage
  return (
    <div className={className}>
      {total > 0 ? (
        <div>
          <span className={styles.searchResultTotal}>{total}</span>件中
          <span className={styles.searchResultRange}>
            {begin}-{end}
          </span>
          を表示
        </div>
      ) : (
        <p className={styles.searchResultErrorMessage}>{failureMessage}</p>
      )}
    </div>
  )
}

export class SortableTh extends React.Component {
  static defaultProps = {
    currentSortType: '',
  }

  handleClick = () => {
    const {field, currentSortType, onChange} = this.props
    const [sort, order] = currentSortType.split('__')
    const newOrder = sort !== field || order === 'desc' ? 'asc' : 'desc'
    onChange(`${field}__${newOrder}`)
  }

  render() {
    const {field, globalClassName, children, currentSortType} = this.props
    const className = globalClassName ? `${styles.sortableTh} ${globalClassName}` : styles.sortableTh
    const [sort, order] = currentSortType.split('__')
    const showOrder = field === sort && (order === 'asc' || order === 'desc')
    return (
      <th onClick={this.handleClick} className={className}>
        {children}
        {(() => {
          if (showOrder && order === 'desc') {
            return <Pulldown size={7} className={styles.sortableThIconDesc} />
          } else if (showOrder && order === 'asc') {
            return <Pulldown size={7} className={styles.sortableThIconAsc} />
          }
        })()}
      </th>
    )
  }
}

export const SortingFieldsWithResult = ({
  limit,
  count,
  page,
  isLoading,
  failureMessage = '従業員が見つかりませんでした',
}) => (
  <div className={styles.sortingFieldsWithResult}>
    <SearchResult
      total={count}
      page={page}
      limit={limit}
      isLoading={isLoading}
      failureMessage={failureMessage}
      className={styles.resultCenter}
    />
  </div>
)
