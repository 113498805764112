import {FC} from 'react'
import useRouter from 'use-react-router'

import {useFetchRequestList} from './hooks/useFetchRequestList'
import {RequestList} from './parts/RequestList'
import {STATUS, STATUS_MAP} from 'consts/updateRequestStatus'
import styles from './Presenter.scss'

import {TitleContainer} from 'components/layout/TitleContainer'
import {MainTitle} from 'components/ui/MainTitle'
import {Content} from 'components/layout/Content'
import {KeywordSearch} from 'components/ui/KeywordSearch'
import {Loading} from 'components/ui/Loading'
import {PerPage} from 'components/ui/PerPage'
import {SearchResult} from 'components/ui/SearchResult'

import {FilterTag} from 'jbc-front/components/presenters/ui/FilterTag'
import {Spacer} from 'jbc-front/components/presenters/Spacer'
import Button from 'jbc-front/components/Button'
import Paginator from 'jbc-front/components/Paginator'

export const Presenter: FC = () => {
  const {history} = useRouter()
  const {variables, sortProps, keywordSubject, handleFilterSelct, handlePerChange, handlePageChange, data, loading} =
    useFetchRequestList()
  const handleMoveRequest = () => history.push('/direct_request')
  return (
    <>
      <TitleContainer>
        <MainTitle>従業員更新依頼一覧</MainTitle>
      </TitleContainer>

      <Content size="xl-4">
        <div className={styles.pageTopToolsContainer}>
          <div className={styles.flexContainer}>
            {STATUS.map((key) => (
              <FilterTag
                key={`filter-tag-${key}`}
                isActive={variables.search.filter.includes(key)}
                onClick={() => handleFilterSelct(key)}
              >
                {STATUS_MAP[key]}
              </FilterTag>
            ))}
          </div>

          <Button primary widthAuto className={styles.buton} onClick={handleMoveRequest}>
            更新を依頼する
          </Button>
        </div>

        <Spacer direction="y" size={20} />

        <div className={styles.gridContainer}>
          <SearchResult
            total={data?.client.detailInputRequests.totalCount || 0}
            page={variables.page}
            limit={variables.per}
          />

          <div>
            <KeywordSearch
              className={styles.keywordSearch}
              placeholder={'スタッフコード、氏名'}
              onChange={(value) => keywordSubject.next(value)}
            />
            <PerPage selected={variables.per} onChange={handlePerChange} />
          </div>
        </div>

        <Spacer direction="y" size={20} />

        {loading ? (
          <Loading />
        ) : (
          data?.client.detailInputRequests?.list && (
            <RequestList {...sortProps} detailInputRequests={data?.client.detailInputRequests?.list} />
          )
        )}

        <Spacer direction="y" size={20} />

        <div className={styles.paginatorContainer}>
          <Paginator
            current={variables.page}
            rowsPerPage={variables.per}
            onClick={handlePageChange}
            totalResult={data?.client?.detailInputRequests?.totalCount}
          />
        </div>
      </Content>
    </>
  )
}
