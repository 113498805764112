import React, {useState, useCallback} from 'react'
import {useDispatch} from 'react-redux'
import {gql} from '@apollo/client'
import Modal from 'jbc-front/components/Modal'
import {Delete as DeleteIcon} from 'jbc-front/components/icons'
import {useMutation} from 'components/Graphql'
import styles from 'documents/Show.scss'
import ActionButton from 'jbc-front/components/ActionButton'
import {notifySuccess} from 'store/actions/notify'

const DELETE_DOCUMENT = gql`
  mutation deleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      document {
        id
      }
    }
  }
`

const Delete = ({documentId, title, onRequestFinish, disabled, disabledReason}) => {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [deleteDocument] = useMutation(DELETE_DOCUMENT)
  const handleDeleteClick = useCallback(async () => {
    await deleteDocument({variables: {input: {id: documentId}}})
    dispatch(notifySuccess('削除しました'))
    onRequestFinish()
  }, [documentId, onRequestFinish])

  return (
    <div style={{display: 'inline-block'}}>
      <Modal
        header="削除の確認"
        body={
          <p className={styles.deleteModalBody}>
            削除された書類は復元することが出来ません。
            <br />
            <span className={styles.warningText}>本当に{title}を削除しますか</span>
          </p>
        }
        onSubmit={handleDeleteClick}
        isOpen={showModal}
        hideModal={() => setShowModal(false)}
        submit="はい"
        cancel="いいえ"
      />
      <ActionButton
        className={styles.toolButton}
        icon={<DeleteIcon size={15} />}
        onClick={() => setShowModal(true)}
        disabled={disabled}
        disabledReason={disabledReason}
      >
        削除
      </ActionButton>
    </div>
  )
}

export default Delete
