import type {FC} from 'react'
import {Pulldown} from 'jbc-front/components/icons'
import classnames from 'classnames'
import styles from './Table.scss'

type TableComponent = FC<JSX.IntrinsicElements['table']> & {
  Thead: FC<JSX.IntrinsicElements['thead']>
  Tbody: FC<JSX.IntrinsicElements['tbody']>
  Tr: FC<JSX.IntrinsicElements['tr']>
  Td: FC<JSX.IntrinsicElements['td']>
  Th: FC<ThProps>
  SortableTh: FC<SortableThProps>
}

type ThProps = JSX.IntrinsicElements['th'] & {
  width?: string
}

type ThStyle = {
  width?: string
}

type SortableThProps = ThProps & {
  order: 'asc' | 'desc'
  active?: boolean
}

export const Table: TableComponent = ({children, ...tableProps}) => {
  return (
    <div className="l-overflow-scroll">
      <table className={classnames('m-table-list', styles.table)} {...tableProps}>
        {children}
      </table>
    </div>
  )
}

export const Thead: FC<JSX.IntrinsicElements['thead']> = ({children, ...theadProps}) => {
  return <thead {...theadProps}>{children}</thead>
}
export const Tbody: FC<JSX.IntrinsicElements['tbody']> = ({children, ...tbodyProps}) => {
  return <tbody {...tbodyProps}>{children}</tbody>
}
export const Tr: FC<JSX.IntrinsicElements['tr']> = ({children, ...trProps}) => <tr {...trProps}>{children}</tr>
export const Td: FC<JSX.IntrinsicElements['td']> = ({children, ...tdProps}) => <td {...tdProps}>{children}</td>
export const Th: FC<ThProps> = ({children, width, ...thProps}) => {
  const thStyle: ThStyle = {}

  if (width) {
    thStyle.width = width
  }

  return (
    <th style={thStyle} {...thProps}>
      {children}
    </th>
  )
}

export const SortableTh: FC<SortableThProps> = ({order, active = false, width, children, className, ...thProps}) => {
  const pulldownStyle = (() => {
    switch (order) {
      case 'asc':
        return styles.asc
      case 'desc':
        return styles.desc
    }
  })()

  const thStyle: ThStyle = {}

  if (width) {
    thStyle.width = width
  }

  return (
    <Th {...thProps} style={thStyle} className={classnames(className, styles.sortable)}>
      {children}
      {active && <Pulldown className={pulldownStyle} />}
    </Th>
  )
}

Table.Thead = Thead
Table.Tbody = Tbody
Table.Tr = Tr
Table.Td = Td
Table.Th = Th
Table.SortableTh = SortableTh
