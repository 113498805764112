import {FC, ReactNode} from 'react'

import styles from './CommentList.scss'
import {AdminUser, SingleEmployee} from 'jbc-front/components/icons'
import {dayOrTimeFormat} from 'libs/formatter'

// MEMO: コメントの詳細な知識を持っているのでfeaturesディレクトリを作成してそこに配置するべき

type CommentListProps = {
  children: ReactNode
}

export const CommentList: FC<CommentListProps> = ({children}) => {
  return <ul className={styles.commentList}>{children}</ul>
}

type ItemProps = {
  type: 'from_employee' | 'from_admin'
  commenterName: string
  commentedAt: string
  comment: string
}

export const Item: FC<ItemProps> = ({type, commenterName, comment, commentedAt}) => {
  const isAdmin = type === 'from_admin'
  const name = isAdmin ? `管理者(${commenterName})` : commenterName
  const UserIcon = isAdmin ? AdminUser : SingleEmployee

  return (
    <li className={styles.commentItem}>
      <div className={styles.commenterContainer}>
        <div>
          <UserIcon className={styles.icon} />
          <span>{name}</span>
        </div>
        <span className={styles.dateTime}>{dayOrTimeFormat(commentedAt)}</span>
      </div>
      <p className={styles.comment}>{comment}</p>
    </li>
  )
}
