import {FC, ReactNode, HTMLProps} from 'react'
import styles from './ColorLabel.scss'
import classnames from 'classnames'

interface ColorLabelProps {
  children: ReactNode
  color?: 'green' | 'orange' | 'blue'
  className?: string | []
  rest?: HTMLProps<HTMLDivElement>
}

export const ColorLabel: FC<ColorLabelProps> = ({children, color = 'green', className, rest}) => {
  const classNames = classnames(styles.label, styles[color], className)

  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  )
}
