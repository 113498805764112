import React, {FC} from 'react'
import {Redirect} from 'react-router'
import _ from 'lodash'

import {Loading} from 'components/ui/Loading'
import {useQuery} from 'hooks/graphql'
import {useSelector} from 'hooks/redux'

import {RequestForm} from './parts/RequestForm'
import {QueryResult, DIRECT_REQUEST_SENDABLE_GROUPS} from './query'

export const Presenter: FC = () => {
  const selected = useSelector((state) => state.employees.selected)
  const employeeIds = _.keys(_.pickBy(selected, _.identity))
  const {data, loading} = useQuery<QueryResult>(DIRECT_REQUEST_SENDABLE_GROUPS, {
    fetchPolicy: 'network-only',
  })
  const employeeFieldGroupMasters = data?.client?.directRequestSendableGroups?.employeeFieldGroupMasters
  const customEmployeeFieldGroups = data?.client?.directRequestSendableGroups?.customEmployeeFieldGroups

  if (_.isEmpty(employeeIds)) {
    return <Redirect to={'/direct_request'} />
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className="l-title-wrap">
        <h1 className="m-title-main">以下の情報を従業員に入力依頼できます</h1>
      </div>
      {employeeFieldGroupMasters && customEmployeeFieldGroups && (
        <RequestForm {...{employeeIds, employeeFieldGroupMasters, customEmployeeFieldGroups}} />
      )}
    </>
  )
}
