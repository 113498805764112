import React, {FC} from 'react'
import {Field, Validator, WrappedFieldProps} from 'redux-form'
import {Label, Error} from 'jbc-front/components/Form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {ScalarOfficeSelector, ExtraItem} from '../OfficeSelector'
import styles from './OfficeSelectorField.scss'

// @ts-ignore
import {requiredIf} from 'validators'

const wrap = <T,>(value: T | T[]) => {
  if (value) {
    return Array.isArray(value) ? value : [value]
  } else {
    return []
  }
}

interface OfficeSelectorFieldProps extends OfficeSelectorFieldRendererProps {
  name: string
  validate?: Validator | Validator[]
}

/**
 * {@link OfficeSelector} をredux-formのFieldと結合したコンポーネント。従来、jbc-frontのSelectFieldを用いて実装していた部分と互換性がある。
 */
export const OfficeSelectorField: FC<OfficeSelectorFieldProps> = ({name, required, validate, ...rest}) => (
  <Field
    component={renderField}
    validate={[...wrap(validate), requiredIf(required)]}
    name={name}
    required={required}
    {...rest}
  />
)

interface OfficeSelectorFieldRendererProps {
  label: string
  multiple?: boolean
  required?: boolean
  diff?: string
  extraItems?: ExtraItem[]
}

const renderField: FC<WrappedFieldProps & OfficeSelectorFieldRendererProps> = ({
  label,
  multiple,
  required,
  diff,
  extraItems,
  input,
  meta,
}) => (
  <div className={styles.box}>
    <Label text={label} required={required} diff={diff} />
    <ScalarOfficeSelector
      multiple={multiple}
      filterByCurrentOffices
      selectedIds={input.value}
      extraItems={extraItems}
      onChange={(value) => {
        input.onChange(value)
        input.onBlur(value)
      }}
    />
    <Error meta={meta} label={label} />
    <LabelMapper name={input.name} label={label} />
  </div>
)
