import React, {Component} from 'react'
import Tag from 'jbc-front/components/Tag'
import {connect} from 'react-redux'

export class FilterTag extends Component {
  handleClick = () => {
    const {query, column, filter, setQuery} = this.props
    setQuery({...query, [column]: filter, page: 1})
  }

  render() {
    /* eslint-disable no-unused-vars */
    const {query, column, filter, label, setQuery, ...rest} = this.props
    /* eslint-enable no-unused-vars */
    const current = query[column]
    return (
      <div {...rest}>
        {(filter == current && <Tag active>{label}</Tag>) || <Tag onClick={this.handleClick}>{label}</Tag>}
      </div>
    )
  }
}

export const connectFilterTag = (selector, actionCreator) =>
  connect(
    (state) => ({
      query: selector(state),
    }),
    (dispatch) => ({
      setQuery: (query) => dispatch(actionCreator(query)),
    })
  )(FilterTag)
