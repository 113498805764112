import {FC, ReactNode} from 'react'
import {Link} from 'react-router-dom'
import styles from './Tab.scss'

type TabProps = {
  active?: boolean
  disabled?: boolean
  to?: string
  children: ReactNode
}

export const Tab: FC<TabProps> = ({active = false, disabled = false, to = '', children}) => {
  if (disabled) {
    return <div className={styles.typeTabDisabled}>{children}</div>
  }

  if (active) {
    return <div className={styles.typeTabActive}>{children}</div>
  } else {
    return (
      <Link to={to} className={styles.typeAreaTab}>
        {children}
      </Link>
    )
  }
}
