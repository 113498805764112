import Profile from 'accountSettings/Profile'
import ProfileEdit from 'accountSettings/ProfileEdit'
import ProfileEditForAdmin from 'accountSettings/ProfileEditForAdmin'
import ChangePassword from 'accountSettings/ChangePassword'
import ChangeEmail from 'accountSettings/ChangeEmail'
import Account from 'accountSettings/Account'

export default {
  Profile,
  ChangePassword,
  ChangeEmail,
  ProfileEdit,
  ProfileEditForAdmin,
  Account,
}
