import {Context, useContext} from 'react'

// document: https://github.com/redux-form/redux-form/blob/v8.3.10/docs/api/ReduxFormContext.md
// source:   https://github.com/redux-form/redux-form/blob/v8.3.10/src/ReduxFormContext.js
// @ts-ignore
import {ReduxFormContext as _ReduxFormContext} from 'redux-form'

// この辺を見ると何がContext内にあるのかが分かる
// https://github.com/redux-form/redux-form/blob/v8.3.10/src/createReduxForm.js#L881
interface ReduxFormContextType {
  form: string
  sectionPrefix?: string
}

export const ReduxFormContext = _ReduxFormContext as Context<ReduxFormContextType>

const useReduxFormContext = () => useContext(ReduxFormContext)

export const useFormName = () => useReduxFormContext().form

export const useFormSectionPrefix = () => useReduxFormContext().sectionPrefix
