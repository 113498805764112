import React, {useState, useEffect} from 'react'
import {gql} from '@apollo/client'
import _ from 'lodash'
import {reduxForm, submit, Field, isSubmitting as isSubmittingForm} from 'redux-form'
import {useSelector, useDispatch} from 'react-redux'
import {Mutation} from 'components/Graphql'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import Modal from 'jbc-front/components/CommonModal'
import {SelectField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import styles from 'SurveyModal.scss'
import {setFirstTimeFormAnswered} from 'store/slices/client'

const formName = 'surveyForm'
const isSubmitting = isSubmittingForm(formName)

const introductionScaleOptions = [
  {value: 'below_5', label: '5人未満'},
  {value: 'between_5_49', label: '5〜49名'},
  {value: 'between_50_99', label: '50〜99名'},
  {value: 'between_100_249', label: '100〜249名'},
  {value: 'between_250_499', label: '250〜499名'},
  {value: 'between_500_749', label: '500〜749名'},
  {value: 'between_750_999', label: '750〜999名'},
  {value: 'between_1000_4999', label: '1000〜4999名'},
  {value: 'between_5000_9999', label: '5000〜9999名'},
  {value: 'over_10000', label: '10,000名〜'},
]

const introductionTimingOptions = [
  {value: 'from_now', label: '早急に導入したい'},
  {value: 'within_one_month', label: '1ヶ月以内'},
  {value: 'within_three_months', label: '3ヶ月以内'},
  {value: 'within_one_year', label: '1年以内'},
  {value: 'undecided', label: '未定'},
]

const wantToRealizeFields = [
  {value: 'laborManagementEfficiencyImprovement', label: '労務全般の業務効率化'},
  {value: 'procedureReportsGeneration', label: '各種手続き帳票の自動作成'},
  {value: 'enrollmentAndRetirementEfficiencyImprovement', label: '入退社手続きの効率化'},
  {value: 'yearEndAdjustmentEfficiencyImprovement', label: '年末調整の業務効率化'},
  {value: 'electronicApplicationForProcedures', label: '各種手続きの電子申請'},
  {value: 'myNumberManagement', label: 'マイナンバーの管理'},
  {value: 'betterExperienceForJobcanSeries', label: 'ジョブカンシリーズをより便利に利用したい'},
  {value: 'other', label: 'その他'},
  {value: 'employeeInformationManagementInOnePlace', label: '従業員情報の一元管理'},
]

const userRoles = [
  {value: 'decision_maker', label: '意思決定者'},
  {value: 'it_manager', label: '実務担当者'},
  {value: 'hr_manager', label: '情報システム担当者'},
  {value: 'labor_consultant', label: '社労士'},
  {value: 'other_role', label: 'その他'},
]

const ToggleButtons = ({input, options}) => (
  <div className={styles.toggles}>
    {options.map(({value, label}) => (
      <div
        key={value}
        className={value == input.value ? styles.toggleOn : styles.toggleOff}
        onClick={() => input.onChange(value)}
      >
        {label}
      </div>
    ))}
  </div>
)

const ANSWER_FIRST_TIME_FORM = gql`
  mutation answerFirstTimeForm($input: AnswerFirstTimeFormInput!) {
    answerFirstTimeForm(input: $input) {
      client {
        id
      }
    }
  }
`

const Form =
  (({handleSubmit}) => (
    <form onSubmit={handleSubmit} className={styles.formWrap}>
      <h2 className={styles.description}>ジョブカン労務HRのご利用にあたり、アンケートにご協力ください。</h2>
      <div className={styles.selectFieldWrap}>
        <SelectField name="introductionScale" label="利用予定人数を教えてください" options={introductionScaleOptions} />
      </div>
      <div className={styles.selectFieldWrap}>
        <SelectField
          name="introductionTiming"
          label="利用開始時期を教えてください"
          placeholder="未回答"
          options={introductionTimingOptions}
        />
      </div>
      <section className="u-mb40">
        <label className={styles.label}>ジョブカン労務HRによって実現したいことを教えてください(複数回答可)</label>
        <div className={styles.checkboxFormWrap}>
          {wantToRealizeFields.map(({value, label}) => (
            <label key={value} className={`input-label ${styles.checkboxArea}`}>
              <Field component="input" type="checkbox" name={value} className="m-checkbox-input" />
              <span className="m-checkbox-parts">{label}</span>
            </label>
          ))}
        </div>
      </section>
      <section>
        <label className={styles.label}>あなたの役割について教えてください</label>
        <Field name="userRole" component={ToggleButtons} options={userRoles} />
      </section>
    </form>
  )) |> reduxForm({form: formName})

const SurveyModal = () => {
  const [isFirstTimeFormAnswered, submitting] = useSelector((state) => [
    state.client.current.first_time_form_answered,
    isSubmitting(state),
  ])
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const handleStart = () => dispatch(submit(formName))
  const hideModal = () => setIsOpen(false)
  const handleSubmit = (setAnswer) => async (values) => {
    try {
      await setAnswer({variables: {input: _.pickBy(values, _.identity)}})
      dispatch(notifySuccess('ご回答ありがとうございます'))
    } catch (err) {
      dispatch(asyncError(err))
    } finally {
      dispatch(setFirstTimeFormAnswered())
      setIsOpen(false)
    }
  }
  useEffect(() => {
    if (_.isBoolean(isFirstTimeFormAnswered)) {
      setIsOpen(!isFirstTimeFormAnswered)
    }
  }, [isFirstTimeFormAnswered])
  return (
    <Mutation mutation={ANSWER_FIRST_TIME_FORM}>
      {(setAnswer) => (
        <Modal isOpen={isOpen}>
          <Modal.Header hideModal={hideModal}>アンケート</Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit(setAnswer)} />
          </Modal.Body>
          <Modal.Footer>
            <Modal.Buttons>
              <Button primary onClick={handleStart} disabled={submitting}>
                利用開始
              </Button>
            </Modal.Buttons>
          </Modal.Footer>
        </Modal>
      )}
    </Mutation>
  )
}

export default SurveyModal
