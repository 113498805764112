import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Dl, Wrap, Tag} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'
import _ from 'lodash'
import styles from 'employees/show/Parts.scss'

const EmployeeTag = ({employee, editLink, canEdit}) => {
  const employeeTags = employee.employee_tags || []
  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#EmployeeTag`} title="タグ" canEdit={canEdit} />
      <Item>
        <Wrap>
          <Dl>
            {employeeTags.map((employeeTag, index) => (
              <Tag key={index}>{recordDisplay(_.get(employeeTag, 'name'))}</Tag>
            ))}
          </Dl>
        </Wrap>
        {employeeTags.length < 1 && (
          <div className={styles.noData}>
            <p>タグの登録はありません</p>
          </div>
        )}
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  tags: state.master.tags,
  employee: state.employees.current.data,
}))(EmployeeTag)
