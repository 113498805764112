import React from 'react'
import {useSelector} from 'react-redux'
import moment from 'moment'
import Button from 'jbc-front/components/Button'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {CheckboxField} from 'jbc-front/components/Form'
import {isSignFreePeriod} from 'documents/Contract'
import styles from 'documents/Contract.scss'

const AgreeModal = ({hideModal, handleSubmit, submitting, change, selector}) => {
  const isSignFree = isSignFreePeriod(moment())
  const isAgreed = useSelector((state) => selector(state, 'agreed'))
  const resetAgreed = () => {
    change('agreed', false)
    hideModal()
  }

  const BillingAnnotateText = ({children}) =>
    isSignFree ? <span className={styles.freePeriod}>{children}</span> : children

  return (
    <>
      <Modal.Header hideModal={resetAgreed}>送信の確認</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <p className="u-mb30">
          <BillingAnnotateText>「送信する」をクリックすると1件毎に定価200円（税抜）が発生します。</BillingAnnotateText>
          <br />
          {isSignFree && (
            <span className={styles.annotation}>キャンペーン期間（2023年1月～2月）につき電子契約利用料が無料</span>
          )}
          <br />
          <BillingAnnotateText>
            ※各種無料キャンペーンの対象外です。無料期間に関わらず送信数に応じた課金が発生します。
          </BillingAnnotateText>
          <br />
          動作のご確認は
          <a
            className="u-txt-link"
            href="https://jobcan-lms.zendesk.com/hc/ja/articles/10060233784601"
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          をご参照ください。
          <br />
          <BillingAnnotateText>
            ※送信時点で課金対象となります。メール不達の場合も対象となりますので送信前に送信先アドレスのご確認をお願いします。
          </BillingAnnotateText>
          <br />
          ※署名依頼メールはGMOサインより送信されます。
          <br />「<span className={styles.email}>noreply@gmosign.com</span>」からのメールを受信できるようお願いします。
          <br />
          <BillingAnnotateText>
            ※電子契約利用料として毎月の労務HRサービス利用料と一緒に請求致します。
            <br />
            ※月額最低利用料金の算出に電子契約料は含まれません。
            <br />
            月額最低利用料金でご利用頂いているお客様は最低利用料＋電子契約利用料の請求となります。
          </BillingAnnotateText>
          <br />
          ※署名有効期限は30日間となります。
        </p>
        <div className={styles.agreeContainer}>
          <div className={styles.agreeCheck}>
            <CheckboxField name="agreed" label="確認しました" />
            <CheckboxField name="isPublished" label="送信時にマイページに公開する" />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={resetAgreed}>キャンセル</Button>
          <Button primary onClick={handleSubmit} disabled={!isAgreed || submitting}>
            送信する
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </>
  )
}

const Agreement = ({isModalOpen, showModal, hideModal = [], handleSubmit, submitting, change, disabled, selector}) => (
  <div className={styles.wrap}>
    <Button primary onClick={showModal} disabled={disabled}>
      送信する
    </Button>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      {isModalOpen && (
        <AgreeModal
          hideModal={hideModal}
          handleSubmit={handleSubmit}
          submitting={submitting}
          change={change}
          selector={selector}
        />
      )}
    </Modal>
  </div>
)

export default withModal(Agreement)
