import React, {Component} from 'react'
import Modal from 'react-modal'
import {Close} from 'jbc-front/components/icons'
import styles from 'myNumber/MyNumberModal.scss'
import {recordDisplay} from 'utils'
import Button from 'jbc-front/components/Button'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import Form, {makeInitialValues, submit} from 'myNumber/RequestForm'
import _ from 'lodash'
import {notifySuccess, notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Request extends Component {
  loadData(props = this.props) {
    const {dispatch, employeeId, token} = props
    dispatch(
      actionCreators.employees.current.fetchData(
        api.createWithAuth(token).employees.get(employeeId, ['employee_dependents'])
      )
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
    if (nextProps.employeeId !== this.props.employeeId && nextProps.employeeId) {
      this.loadData(nextProps)
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
    const {dispatch} = this.props
    dispatch(actionCreators.employees.current.destroy())
  }

  handleSubmit = async (values) => {
    const {token, hide, dispatch} = this.props
    const requests =
      values.my_number_purpose_of_uses && values.my_number_purpose_of_uses.filter((request) => request._send_request)
    if (_.isEmpty(requests)) {
      dispatch(notifyError('依頼する対象者にチェックを入れてくだい'))
      return
    }
    try {
      await api.createWithAuth(token).myNumber.request({...values, my_number_purpose_of_uses: requests})
      dispatch(notifySuccess('依頼しました'))
      hide()
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  handleSubmitClick = () => {
    this.props.dispatch(submit())
  }

  render() {
    const {isOpen, hide, submitting, employee} = this.props
    return (
      <Modal className={styles.modal} isOpen={isOpen} overlayClassName={styles.overlay} contentLabel="Modal">
        <div className={styles.header}>
          <div className={styles.title}>{recordDisplay.fullName(employee)}さんへのマイナンバー提出依頼</div>
          <div className={styles.tools}>
            <Close className={styles.close} onClick={() => this.props.hide()} />
          </div>
        </div>
        {isOpen && (
          <Form employee={employee} initialValues={makeInitialValues(employee)} onSubmit={this.handleSubmit} />
        )}
        <div className={styles.closeBottom}>
          <Button onClick={hide} disabled={submitting}>
            キャンセル
          </Button>
          <Button primary onClick={this.handleSubmitClick} disabled={submitting}>
            依頼する
          </Button>
        </div>
      </Modal>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  employee: state.employees.current.data,
  businessPartner: fetchSelector(state, 'business_partner'),
}))(Request)
