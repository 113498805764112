import {FC} from 'react'
import {displayFormat, booleanFormat, fullNameFormat, dateFormat} from 'libs/formatter'
import {Table} from 'components/ui/Table'
import {ColorLabel} from 'components/ui/ColorLabel'
import {Document, Search} from '../../query'
import {DOCUMENT_VISIVILITIES} from 'consts/document'
import {DeleteColumn} from './DeleteColumn'
import {PreviewColumn} from './PreviewColumn'
import {PublishColumn} from './PublishColumn'
import styles from './Row.scss'

interface RowProps {
  employeeId: number
  document: Document
  search: Search
}

const VISIBILITIY_COLOR_MAP = {
  all_admins: 'green',
  private: 'blue',
} as const

export const Row: FC<RowProps> = ({document, employeeId, search}) => {
  const fullName = (() => {
    if (document.createdUser) {
      if (document.createdUser.employee) {
        const {displayLastName, displayFirstName} = document.createdUser.employee
        return fullNameFormat(displayLastName, displayFirstName)
      }

      return document.createdUser.email
    }

    return displayFormat()
  })()

  const contractStatus = (() => {
    switch (document.documentEmployee.contractInfo?.status) {
      case 'signed':
        return '署名済'
      case 'unsigned':
        return '未署名'
      default:
        return displayFormat()
    }
  })()

  return (
    <Table.Tr>
      <Table.Td>{dateFormat(document.createdAt, 'L')}</Table.Td>

      <Table.Td>{document.title}</Table.Td>

      <Table.Td>
        {document.documentEmployee.contractInfo?.status === 'canceled' ? (
          <span className={styles.canceledText}>取消済</span>
        ) : (
          booleanFormat({value: document.isSign})
        )}
      </Table.Td>

      <Table.Td>{contractStatus}</Table.Td>

      <Table.Td>
        <ColorLabel color={VISIBILITIY_COLOR_MAP[document.visibility]}>
          {DOCUMENT_VISIVILITIES[document.visibility]}
        </ColorLabel>
      </Table.Td>

      <Table.Td>{fullName}</Table.Td>

      <Table.Td>
        <PreviewColumn document={document} employeeId={employeeId} />
      </Table.Td>

      <Table.Td>
        <PublishColumn
          isPublished={document.documentEmployee.isPublished}
          employeeId={employeeId}
          documentId={document.id}
          search={search}
        />
      </Table.Td>

      <Table.Td>
        {document.type == 'uploaded_document' && (
          <DeleteColumn document={document} employeeId={employeeId} search={search} />
        )}
      </Table.Td>
    </Table.Tr>
  )
}
