import React, {FC, useState, ReactNode} from 'react'
import * as Modal from './Modal'
import Button from 'jbc-front/components/Button'
import {SubmitButton} from 'components/forms/SubmitButton'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'
import {Label} from 'jbc-front/components/presenters/form/Label'
import styles from './CommentModal.scss'

// .jsファイルは一旦警告を無視する
// @ts-ignore
import ReviewComments from 'components/ReviewComments'
// @ts-ignore
import {Spacer} from 'jbc-front/components/presenters/Spacer'

interface Props {
  modalTitle: string
  children: (showModal: () => void, isOpen: boolean) => ReactNode
  placeholder?: string
  onSubmit: (formData: FormData) => Promise<void>
  onChange?: (comment: string) => string
  comments?: any
}

interface FormData {
  comment: string
}

export const CommentModal: FC<Props> = ({
  modalTitle,
  children,
  placeholder = '',
  onSubmit,
  comments = [],
  onChange = (comment: string) => {
    return comment
  },
}) => {
  const [formData, setFormData] = useState<FormData>({comment: ''})
  const onCommentChange = (value: string) => {
    const comment = onChange(value)
    setFormData({comment})
  }

  return (
    <Modal.ModalContainer>
      {({showModal, hideModal, isOpen}) => (
        <>
          <Modal.Modal>
            <Modal.Header>{modalTitle}</Modal.Header>
            <Modal.Body>
              <Form comments={comments} onChange={onCommentChange} placeholder={placeholder} />
            </Modal.Body>
            <Modal.Footer>
              <Modal.Buttons>
                <Button onClick={hideModal}>キャンセル</Button>
                <SubmitButton primary onSubmit={() => onSubmit(formData)} text="送信" />
              </Modal.Buttons>
            </Modal.Footer>
          </Modal.Modal>
          {children(showModal, isOpen)}
        </>
      )}
    </Modal.ModalContainer>
  )
}

interface FormProps {
  placeholder: string
  onChange: (value: string) => void
  comments: any
}

const Form: FC<FormProps> = (props) => {
  const {placeholder, onChange, comments = []} = props

  const textareaProps = {
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value),
    name: 'comment',
    placeholder: placeholder,
    className: styles.textarea,
  }

  return (
    <div className={styles.commentWrap}>
      <ReviewComments comments={comments} widthAuto />
      <div className="form_box_main">
        <Label>コメント</Label>
        <Spacer size={8} />
        <Textarea {...textareaProps} />
      </div>
    </div>
  )
}
