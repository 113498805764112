import React from 'react'
import {reduxForm} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import {Email} from 'FormFields'
import {Section} from 'jbc-front/components/Form'
import {TextField, DateField} from 'jbc-front/components/Form'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import styles from './Edit.scss'

const formName = 'businessPartnerForm'

export const FormContents = () => (
  <React.Fragment>
    <FormErrors />
    <Section title="基本情報">
      <TextField name="code" label="管理コード" />
      <TextField name="name" label="取引先名" required />
      <Email />
      <DateField name="start_on" label="取引開始日" />
      <DateField name="end_on" label="取引終了日" />
    </Section>
  </React.Fragment>
)

const CreateForm = ({pristine, submitting, handleSubmit, dispatch}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">取引先登録</h1>
    </div>
    <FormContents />
    <div className={styles.bottomButtonsArea}>
      <Button onClick={() => dispatch(push('/my_number/business_partners'))}>キャンセル</Button>
      <Button primary onClick={handleSubmit} disabled={pristine || submitting}>
        登録する
      </Button>
    </div>
  </form>
)

export default compose(
  connect((state) => ({token: state.auth.token})),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
    onSubmit: async (values, dispatch, {token}) => {
      try {
        await api.createWithAuth(token).businessPartners.create(values)
        dispatch(push('/my_number/business_partners'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(CreateForm)
