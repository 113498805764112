import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {actionCreators} from 'actions'
import api from 'api'
import styles from 'settings/clientRoles/EmployeeList.scss'
import ActionButton from 'jbc-front/components/ActionButton'
import {Edit, Strage, MinusCircle} from 'jbc-front/components/icons'
import {recordDisplay, getState} from 'utils'
import _ from 'lodash'
import Select from 'react-select'
import {bindActionCreators} from 'redux'
import Button from 'jbc-front/components/Button'
import Invite from 'settings/clientRoles/Invite'
import Delete from 'settings/clientRoles/Delete'
import ChangeMulti, {clientRoles, OFFICE_ADMIN} from 'settings/clientRoles/ChangeMulti'
import {getSavedDisplayEmployeeLimit} from 'employees/list/utils'
import PaginatorWithResult from 'settings/clientRoles/PaginatorWithResult'
import SearchForm from 'settings/clientRoles/SearchForm'
import SortableTh from 'settings/clientRoles/SortableTh'
import {fetchSelector} from 'actions'
import {fetchUsersClients, getCurrentQueryFromLocation} from 'settings/clientRoles/utils'
import Hint from 'jbc-front/components/Hint'
import {OfficeSelector} from 'features/offices/OfficeSelector'
import {asyncError} from 'store/actions/asyncError'

const additionalParams = {
  embed: ['user', 'employee', 'client_role', 'office'],
}

const Content = connect(
  (state) => ({
    editing: state.settings.usersClients.editing,
    changes: state.settings.usersClients.changes,
    selectedUsers: state.settings.usersClients.selected,
    usersClients: fetchSelector(state, 'users_clients').data,
  }),
  bindActionCreators.bind(null, actionCreators.settings.usersClients)
)(({usersClients = [], editing, change, changes, toogleSelected, selectedUsers}) => (
  <tbody>
    {usersClients.map((userClient) => {
      const value = {...userClient, ...changes[userClient.id]}
      const selected = !!selectedUsers[userClient.id]
      return (
        <tr key={userClient.id} className={value._delete && styles.deleleted}>
          {editing && (
            <td
              className={selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'}
              onClick={() => toogleSelected(userClient.id)}
            >
              <input type="checkbox" checked={selected} className={'u-cur-pointer'} readOnly />
            </td>
          )}
          <td style={{wordWrap: 'break-word'}}>{recordDisplay(userClient.user.email)}</td>
          <td style={{wordWrap: 'break-word'}}>
            {recordDisplay(
              userClient.user.employee !== null ? (
                _.get(userClient.user, 'employee.staff_code')
              ) : (
                <div className={styles.staffCodeWithHelp}>
                  <p>従業員情報なし</p>
                  <Hint text="「権限管理設定＞アカウント招待」から登録したアカウントです。従業員情報と紐付けたい場合は、一度「権限管理設定＞編集」からアカウントを削除してください。その後、「従業員管理＞従業員を招待」から再度アカウント登録を行ってください。" />
                </div>
              )
            )}
          </td>
          <td style={{wordWrap: 'break-word'}}>
            {userClient.user.employee
              ? recordDisplay.fullName(userClient.user.employee)
              : recordDisplay(userClient.user.name)}
          </td>
          <td className={styles.select}>
            {editing ? (
              <Select
                name={`${value.id}.client_role_id`}
                value={`${value.client_role_id}`}
                onChange={(v) => change({id: userClient.id, client_role_id: v})}
                options={clientRoles}
                simpleValue
                clearable={false}
              />
            ) : (
              <div className={styles.noEdit}>{recordDisplay(_.get(userClient, 'client_role.name'))}</div>
            )}
          </td>
          <td>
            {editing
              ? `${value.client_role_id}` === OFFICE_ADMIN && (
                  <OfficeSelector
                    multiple
                    selectedIds={value.users_client_office_office_ids.map(String)}
                    onChange={(ids) => {
                      change({id: userClient.id, users_client_office_office_ids: ids})
                    }}
                  />
                )
              : `${userClient.client_role_id}` === OFFICE_ADMIN && (
                  <div>{userClient.offices.map((office) => office.name).join('\n')}</div>
                )}
          </td>
        </tr>
      )
    })}
  </tbody>
))

const renderUsersTable = ({
  editing,
  handleSubmit,
  clear,
  edit,
  selectedUsers,
  usersClients = [],
  selectMulti,
  changes,
}) => {
  const shouldSelect = ({id}) => !changes[id] || !changes[id]._delete
  const allSelected =
    usersClients.some(shouldSelect) &&
    usersClients.every((userClient) => !shouldSelect(userClient) || selectedUsers[userClient.id])
  const allClickClass = allSelected ? 'm-table-checkbox-on' : 'm-table-checkbox-off'
  return (
    <div>
      <SearchForm
        buttons={
          editing ? (
            <div className={styles.buttonWrap}>
              <ActionButton onClick={handleSubmit} icon={<Strage size={15} />}>
                保存
              </ActionButton>
              <ActionButton onClick={() => clear()} icon={<MinusCircle size={15} />}>
                キャンセル
              </ActionButton>
            </div>
          ) : (
            <div>
              <ActionButton onClick={() => edit()} icon={<Edit size={15} />}>
                編集
              </ActionButton>
            </div>
          )
        }
        additionalParams={additionalParams}
      />

      <div className="l-overflow-scroll">
        <table className={styles.dataTable}>
          <thead>
            <tr>
              {editing && (
                <th
                  onClick={() =>
                    selectMulti(
                      Object.assign({}, ...usersClients.filter(shouldSelect).map(({id}) => ({[id]: !allSelected})))
                    )
                  }
                  className={allClickClass + ' m-table-list-check u-cur-pointer'}
                >
                  <input type="checkbox" readOnly checked={allSelected} />
                </th>
              )}
              <SortableTh field="email">メールアドレス</SortableTh>
              <SortableTh field="staff_code" globalClassName={styles.staffCode}>
                スタッフコード
              </SortableTh>
              <SortableTh field="full_name_kana" globalClassName={styles.name}>
                氏名
              </SortableTh>
              <th className={styles.role}>権限</th>
              <th>適用事業所</th>
            </tr>
          </thead>
          <Content />
        </table>
      </div>
    </div>
  )
}

const UsersTable = connect(
  (state) => ({
    usersClients: fetchSelector(state, 'users_clients').data,
    selectedUsers: state.settings.usersClients.selected,
    editing: state.settings.usersClients.editing,
    changes: state.settings.usersClients.changes,
  }),
  bindActionCreators.bind(null, actionCreators.settings.usersClients)
)(renderUsersTable)

class EmployeeList extends Component {
  handleSubmit = async () => {
    const {dispatch, location, user} = this.props
    try {
      const state = await getState(dispatch)
      const token = state.auth.token
      const changes = Object.values(state.settings.usersClients.changes)
      await api.createWithAuth(token).usersClients.updateMulti({
        updates: changes.filter((change) => !change._delete),
        delete: Object.keys(_.pickBy(state.settings.usersClients.changes, (change) => change._delete)),
      })
      dispatch(actionCreators.settings.usersClients.clear())
      dispatch(fetchUsersClients(getCurrentQueryFromLocation(location, user), additionalParams))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.settings.usersClients.clear())
  }

  render() {
    const {
      user,
      editing,
      location: {pathname},
    } = this.props
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">権限管理設定</h1>
          <p className="m-title-main-note">権限の変更ができます</p>
          <p className={styles.titleNote}>
            ※ マイページを持っていない従業員は一覧に表示されません。
            <br />
            従業員を招待してマイページを発行してください。従業員招待は
            <Link className="u-txt-link" to="/employees/invite">
              こちら
            </Link>
          </p>
        </div>
        <div className="l-wrap-xxl l-contents-wrap">
          <div className={styles.contentsHead}>
            <div className="l-breadcrumb u-mb20">
              <Link to="/settings" className="l-breadcrumb-link">
                設定
              </Link>
              <span className="l-breadcrumb-here">権限管理設定</span>
            </div>
            <div className={styles.contentsHeadButtonWrap}>
              <Link to="/settings/client_roles/list">
                <Button primary widthAuto>
                  権限一覧
                </Button>
              </Link>
              <Invite />
            </div>
          </div>

          <UsersTable handleSubmit={this.handleSubmit} />
          <PaginatorWithResult
            limit={getSavedDisplayEmployeeLimit(pathname, user)}
            failureMessage="アカウントが見つかりませんでした"
          />
          {editing && (
            <React.Fragment>
              <ChangeMulti />
              <br />
              <Delete />
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  additionalNotificationDsts: state.settings.additionalNotificationDsts.data,
  editing: state.settings.usersClients.editing,
  location: state.router.location,
  user: state.session.currentUser,
  token: state.auth.token,
}))(EmployeeList)
