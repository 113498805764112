import axios from 'axios'
import Cookies from 'js-cookie'

type Data = {
  password: string
  password_confirmation: string
}

export const usePostInitialPassword =
  (token?: string) =>
  async (data: Data): Promise<void> => {
    const {
      data: {access_token: accessToken},
    } = await axios.post('/api/invitee_initial_password', {token, ...data})
    Cookies.set('access_token', accessToken)
    Cookies.set('message', 'アカウントが登録されました')
    window.location.href = '/dashboard'
  }
