import {useAuthAxios} from 'hooks/api/useAuthAxios'
import {useFileDownload} from 'hooks/api/useFileDownload'

type Params = {
  target?: TargetType
  encoding?: EncodingType
  search?: string
}

export type TargetType = 'search' | 'all'
export type EncodingType = 'UTF-8' | 'Windows-31J'

export const useDownloadDocumentsCsv = () => {
  const authAxios = useAuthAxios()
  const {download} = useFileDownload()

  return async (documentId: string, params: Params) => {
    const path = `/api/export_documents_csv/${documentId}`
    const getter = () => authAxios.get(path, {responseType: 'arraybuffer', params})

    download(getter)
  }
}
