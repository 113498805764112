import {ApolloError, ServerError} from '@apollo/client'
import {Dispatch} from 'redux'
import {push} from 'connected-react-router'
import {notifyError} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

export const handleQueryError = (error: ApolloError, dispatch: Dispatch) => {
  if (error.networkError) {
    if (shouldRedirectToInitialInput(error.networkError)) {
      dispatch(push('/initial_input'))
      return
    }

    dispatch(asyncError(error.networkError))
    return
  }

  if (error.graphQLErrors) {
    const message = error.graphQLErrors[0]?.message
    if (message) {
      dispatch(notifyError(message))
      return
    }
  }
}

export const isServerError = (error: Error): error is ServerError => error.name === 'ServerError'

const shouldRedirectToInitialInput = (error: Error) =>
  isServerError(error) && error.statusCode === 403 && error.result['_redirect_to_employee']
