import {FC, MouseEvent} from 'react'
import {MdGrade} from 'jbc-front/components/react-icons'

export type ColorType = 'gray' | 'orange' | 'purple' | 'lightGreen'

const colorMap = {
  gray: '#ccc',
  orange: '#f6a623',
  purple: '#9b59b6',
  lightGreen: '00c4ae',
}

interface StarFlagProps {
  color?: ColorType
  onClick?: () => void
}

export const StarFlag: FC<StarFlagProps> = ({onClick, color = 'gray'}) => {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()

    if (onClick) {
      onClick()
    }
  }

  return (
    <i onClick={handleClick}>
      <MdGrade size={20} color={colorMap[color]} onClick={onClick} />
    </i>
  )
}
