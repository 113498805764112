import {ApolloClient, gql, QueryOptions} from '@apollo/client'

const OFFICE_BY_ID = gql`
  query officeById($id: ID!) {
    client {
      id
      officesByIds(ids: [$id]) {
        id
        name
      }
    }
  }
`

interface OfficesByIdsVariables {
  id: string
}

interface OfficesByIdsResult {
  client: {
    officesByIds: Office[]
  }
}

interface Office {
  id: string
  name: string
}

export const findOfficeById = async (
  client: ApolloClient<object>,
  officeId: string,
  options?: Omit<QueryOptions<OfficesByIdsResult, OfficesByIdsVariables>, 'query' | 'variables'>
) => {
  const {data} = await client.query<OfficesByIdsResult, OfficesByIdsVariables>({
    ...options,
    query: OFFICE_BY_ID,
    variables: {id: officeId},
  })
  return data.client.officesByIds[0]
}
