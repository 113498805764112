import React, {FC} from 'react'
import {useDispatch} from 'react-redux'
import {useForm, SubmitHandler} from 'react-hook-form'
import {push} from 'connected-react-router'
import classNames from 'classnames'

import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {useSelector} from 'hooks/redux'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {EmployeeFieldGroupMaster, CustomEmployeeFieldGroup} from '../../query'
import styles from './RequestForm.scss'

import Button from 'jbc-front/components/Button'
import {Checkbox} from 'jbc-front/components/presenters/form/Checkbox'
import {Label} from 'jbc-front/components/presenters/form/Label'
import {Spacer} from 'jbc-front/components//presenters/Spacer'
import {Textarea} from 'jbc-front/components/presenters/form/Textarea'

// @ts-ignore
import api from 'api'

type RequestFromProps = {
  employeeIds: string[]
  employeeFieldGroupMasters: EmployeeFieldGroupMaster[]
  customEmployeeFieldGroups: CustomEmployeeFieldGroup[]
}

interface RequestFormSchema {
  employee_ids: string[]
  employee_field_group_master_codes: string[]
  custom_employee_field_group_ids: string[]
  comment?: string
}

export const RequestForm: FC<RequestFromProps> = ({
  employeeIds,
  employeeFieldGroupMasters,
  customEmployeeFieldGroups,
}) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const {
    handleSubmit,
    formState: {dirtyFields, isSubmitting},
    register,
  } = useForm<RequestFormSchema>({
    reValidateMode: 'onBlur',
    defaultValues: {
      employee_field_group_master_codes: [],
      custom_employee_field_group_ids: [],
      comment: '',
    },
  })

  const isInvalid = !dirtyFields.employee_field_group_master_codes && !dirtyFields.custom_employee_field_group_ids

  const createDetailInputRequest: SubmitHandler<RequestFormSchema> = async (data) => {
    try {
      const {employee_field_group_master_codes, custom_employee_field_group_ids, comment} = data

      await api.createWithAuth(token).employees.detailInputRequest.create({
        employee_ids: employeeIds,
        employee_field_group_master_codes,
        custom_employee_field_group_ids,
        comment,
      })
      dispatch(notifySuccess('送信しました'))
      dispatch(push(WITH_AUTH_ADMIN_PATHS.EMPLOYEES.UPDATE_REQUESTS.INDEX))
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  return (
    <div className="l-wrap-s">
      <form onSubmit={handleSubmit(createDetailInputRequest)}>
        <div className={styles.checkboxContainer}>
          <p className={styles.heading}>デフォルト項目</p>
          {employeeFieldGroupMasters.map((employeeFieldGroup) => (
            <div key={employeeFieldGroup.code} className={classNames('input-label', styles.checkbox)}>
              <Checkbox {...register('employee_field_group_master_codes')} value={employeeFieldGroup.code}>
                {employeeFieldGroup.label}
              </Checkbox>
            </div>
          ))}

          {customEmployeeFieldGroups.length > 0 && (
            <>
              <p className={styles.heading}>カスタマイズ項目</p>
              {customEmployeeFieldGroups.map((customGroup) => (
                <div key={customGroup.id} className={classNames('input-label', styles.checkbox)}>
                  <Checkbox {...register('custom_employee_field_group_ids')} value={customGroup.id}>
                    {customGroup.label}
                  </Checkbox>
                </div>
              ))}
            </>
          )}
        </div>
        <div className={styles.formWrap}>
          <Label>メッセージ</Label>
          <Spacer direction="y" size={8} />
          <Textarea {...register('comment')} placeholder="入力したメッセージは送信メールに反映されます" />
          <Spacer direction="y" size={20} />
          <div className={styles.bottomButtonsArea}>
            <Button onClick={() => dispatch(push('/direct_request'))}>キャンセル</Button>
            <div className={styles.buttonNoteWrap}>
              <Button primary onClick={handleSubmit(createDetailInputRequest)} disabled={isInvalid || isSubmitting}>
                上記の入力依頼を送信
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
