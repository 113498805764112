import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import {asyncError} from 'store/actions/asyncError'
import LoadingPage from 'components/LoadingPage'
import {useFetchClientFiles} from 'hooks/api/clientFiles'
import {useUpdateFileInfo} from 'hooks/api/fileInfos'
import {AdminFiles} from 'settings/clientFiles/AdminFiles'
import {MemberFiles} from 'settings/clientFiles/MemberFiles'
import styles from 'employeeFiles/Top.scss'
import {parse, stringify} from 'query-string'

export const SectionHeader = ({header, body}) => (
  <>
    <div className={styles.titleWrap}>
      <h2 className="m-title-main">{header}</h2>
    </div>
    <div className="u-mt10 u-ta-c">{body}</div>
  </>
)
const sortTypeFromQuery = ({sort, order}) => {
  return [sort || 'created_at', order || 'desc']
}

const ClientFiles = ({location: {search, pathname}, history}) => {
  const dispatch = useDispatch()
  const [trashInProcess, setTrashInProcess] = useState({})
  const [showMoreProcedureFiles, setShowMoreProcedureFiles] = useState(false)
  const [showMoreOtherFiles, setShowMoreOtherFiles] = useState(false)
  const [fileType, setFileType] = useState('uploaded')
  const query = parse(search)
  const [sort, order] = sortTypeFromQuery(query)
  const currentSortType = `${sort}__${order}`
  const handleChangeSortType = (sortType) => {
    const [sort, order] = sortType.split('__')
    history.push({pathname, search: stringify({...query, sort, order})})
  }
  const fetchClientFiles = useFetchClientFiles({
    sort,
    order,
  })
  const fetchMoreProcedureFiles = useFetchClientFiles({
    sort,
    order,
    see_more_belongs_to_procedure: true,
  })
  const fetchMoreOtherFiles = useFetchClientFiles({
    sort,
    order,
    see_more_not_belongs_to_procedure: true,
  })
  const loading =
    fetchClientFiles.isLoading ||
    (showMoreProcedureFiles && fetchMoreProcedureFiles.isLoading) ||
    (showMoreOtherFiles && fetchMoreOtherFiles.isLoading)
  const clientFiles = fetchClientFiles.resource
  const moreProcedureFiles = fetchMoreProcedureFiles.resource
  const moreOtherFiles = fetchMoreOtherFiles.resource
  const adminFiles = clientFiles?.files_uploaded_by_admin || []
  const procedureFiles = _.uniqBy(
    [
      ...(clientFiles?.files_uploaded_by_member_belongs_to_procedure || []),
      ...((showMoreProcedureFiles && moreProcedureFiles?.files_uploaded_by_member_belongs_to_procedure) || []),
    ],
    'id'
  )
  const otherFiles = _.uniqBy(
    [
      ...(clientFiles?.files_uploaded_by_member_not_belongs_to_procedure || []),
      ...((showMoreOtherFiles && moreOtherFiles?.files_uploaded_by_member_not_belongs_to_procedure) || []),
    ],
    'id'
  )
  const update = useUpdateFileInfo()

  const loadData = () => {
    const promises = []
    promises.push(fetchClientFiles.getter())
    if (showMoreProcedureFiles) {
      promises.push(fetchMoreProcedureFiles.getter())
    }
    if (showMoreOtherFiles) {
      promises.push(fetchMoreOtherFiles.getter())
    }

    return Promise.all(promises)
  }

  const handleDeleteFile = async (id) => {
    try {
      setTrashInProcess({...trashInProcess, [id]: true})
      await update(id, {trashed_by_admin: true})
      await loadData()
    } catch (err) {
      dispatch(asyncError(err))
    } finally {
      setTrashInProcess(_.omit(trashInProcess, id))
    }
  }

  useEffect(() => {
    const scrollBySortableTh = async () => {
      await loadData()

      if (fileType !== 'uploaded') {
        document.getElementById(fileType).scrollIntoView()
        window.scrollBy(0, -80)
      }
    }
    scrollBySortableTh()
  }, [sort, order])

  return (
    <>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">ファイル共有</h1>
        <p className="m-title-main-note">
          社内規定ファイルをアップロード、従業員が記入したファイルをダウンロードできます
        </p>
      </div>
      <div className="l-wrap-m l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <Link to="/settings" className="l-breadcrumb-link">
            設定
          </Link>
          <span className="l-breadcrumb-here">ファイル共有</span>
        </div>
        {loading && !_.every([adminFiles, procedureFiles, otherFiles]) ? (
          <LoadingPage />
        ) : (
          <>
            <AdminFiles
              files={adminFiles}
              trashInProcess={trashInProcess}
              onDeleteFile={handleDeleteFile}
              loadData={loadData}
              onChangeSortType={handleChangeSortType}
              currentSortType={currentSortType}
              onChangeFileType={() => setFileType('uploaded')}
            />
            <>
              <SectionHeader header="ダウンロード" body="従業員がアップロードしたファイルをダウンロードできます。" />
              <MemberFiles
                type="procedure"
                files={procedureFiles}
                trashInProcess={trashInProcess}
                onDeleteFile={handleDeleteFile}
                hasMoreFiles={fetchClientFiles.responseHeaders['x-displays-see-more-proc'] === 'true'}
                showMoreFiles={showMoreProcedureFiles}
                onChangeShowMore={setShowMoreProcedureFiles}
                loadMoreFile={fetchMoreProcedureFiles.getter}
                onChangeSortType={handleChangeSortType}
                currentSortType={currentSortType}
                onChangeFileType={() => setFileType('procedure')}
              />
              <MemberFiles
                type="other"
                files={otherFiles}
                trashInProcess={trashInProcess}
                onDeleteFile={handleDeleteFile}
                hasMoreFiles={fetchClientFiles.responseHeaders['x-displays-see-more-not-proc'] === 'true'}
                showMoreFiles={showMoreOtherFiles}
                onChangeShowMore={setShowMoreOtherFiles}
                loadMoreFile={fetchMoreOtherFiles.getter}
                onChangeSortType={handleChangeSortType}
                currentSortType={currentSortType}
                onChangeFileType={() => setFileType('other')}
              />
            </>
          </>
        )}
      </div>
    </>
  )
}

export default ClientFiles
