import React from 'react'
import {Section, TextField, CheckboxField, DateField, Label} from 'jbc-front/components/Form'
import {toFormValues} from 'utils'
import connectForm from 'reportForms/connectForm'
import {reduxForm, FormSection, formValueSelector} from 'redux-form'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {recordDisplay} from 'utils'
import _ from 'lodash'
import {getRelatedDependents} from 'procedures/changeDependents/utils'
import EmployeeName from 'procedures/EmployeeName'

const formName = 'R47Report'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  onSubmitFail,
})(({handleSubmit, submitting, dependentInfoValues, dependents, submitText, isTarget, employee, procedureStatus}) => {
  const r47Dependents = getRelatedDependents(dependents, procedureStatus)
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">健康保険被保険者証滅失届</h1>
        <EmployeeName employee={employee} />
      </div>
      <div className="basic_information">
        <FormErrors />
        <Section>
          <Label text="回収不能の対象者選択" />
          {procedureStatus.procedure_type === 'resign' && (
            <CheckboxField name="is_target" label={recordDisplay.fullName(employee)} />
          )}
          {procedureStatus.procedure_type === 'resign' && isTarget && (
            <div>
              <DateField name="lost_on" label="滅失日" />
              <TextField name="reason" label="被保険者証を紛失したときの状況" />
              <TextField name="notes" label="備考" />
            </div>
          )}

          <LabelMapper name="r47_dependent_param_set" label="扶養情報" />
          {r47Dependents &&
            r47Dependents.map((dependent, index) => {
              const value = (dependentInfoValues && dependentInfoValues[index]) || {}
              return (
                <FormSection name={`r47_dependent_param_set[${index}]`} key={dependent.id}>
                  <CheckboxField name="is_target" label={recordDisplay.fullName(dependent)} />
                  {value.is_target && (
                    <div>
                      <DateField name="lost_on" label="滅失日" />
                      <TextField name="reason" label="被保険者証を紛失したときの状況" />
                      <TextField name="notes" label="備考" />
                    </div>
                  )}
                </FormSection>
              )
            })}
          <DateField name="submit_on" label="書類提出日" />
          <div className="u-ta-c u-mt30">
            <Button primary onClick={handleSubmit} disabled={submitting}>
              {submitText}
            </Button>
          </div>
        </Section>
      </div>
    </form>
  )
})
const makeInitialValues = ({dependents, data: reportValues, procedureStatus}) => {
  if (!dependents) {
    return toFormValues(reportValues)
  }
  const r47DependentParamSet = _.get(reportValues, 'r47_dependent_param_set') || []
  const r47Dependents = getRelatedDependents(dependents, procedureStatus)
  const r47DependentValues = r47Dependents.map((dependent) =>
    toFormValues(
      _.find(r47DependentParamSet, (value) => value.employee_dependent_id == dependent.id) || {
        employee_dependent_id: dependent.id,
      }
    )
  )
  return _.assign(toFormValues(reportValues), {r47_dependent_param_set: r47DependentValues})
}
const selector = formValueSelector(formName)
const connectedForm = connectForm('r47', Form, {
  mapState: (state) => ({
    dependents: _.get(state, 'procedureStatuses.current.data.employee.employee_dependents'),
    employee: _.get(state, 'procedureStatuses.current.data.employee') || {},
    dependentInfoValues: selector(state, 'r47_dependent_param_set'),
    isTarget: selector(state, 'is_target'),
    procedureStatus: state.procedureStatuses.current.data,
  }),
  makeInitialValues,
})

export default connectedForm
