import React from 'react'
import {useSelector} from 'react-redux'
import {isAdminSelector} from 'utils'
import DashboardForEmployee from 'dashboard/DashboardForEmployee'
import DashboardForAdmin from 'dashboard/DashboardForAdmin'

const Dashboard = ({location: {search}}) => {
  const isAdmin = useSelector((state) => isAdminSelector(state))

  return isAdmin ? <DashboardForAdmin search={search} /> : <DashboardForEmployee search={search} />
}

export default Dashboard
