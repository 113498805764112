import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {push} from 'connected-react-router'

import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import EmployeeFieldGroupForm from 'settings/employeeCustom/EmployeeFieldGroup/EmployeeFieldGroupForm'
import {asyncError} from 'store/actions/asyncError'

class FieldGroup extends Component {
  static defaultProps = {
    employeeFieldGroup: [],
  }
  componentDidMount() {
    const {
      token,
      loadFieldGroup,
      match: {
        params: {id},
      },
    } = this.props
    loadFieldGroup(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  handleSubmit = (values) => {
    const {
      token,
      updateFieldGroup,
      match: {
        params: {id},
      },
    } = this.props
    return updateFieldGroup(id, values, token)
  }

  render() {
    const {employeeFieldGroup} = this.props
    const {setting} = employeeFieldGroup || {}
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">従業員項目カスタマイズ</h1>
        </div>
        <div className="l-wrap-l l-contents-wrap">
          <div className="l-breadcrumb u-mb20">
            <Link to="/settings" className="l-breadcrumb-link">
              設定
            </Link>
            <Link to="/settings/employee_custom" className="l-breadcrumb-link">
              従業員項目カスタマイズ
            </Link>
            <span className="l-breadcrumb-here">編集</span>
          </div>
          <div className="l-wrap-xs">
            <EmployeeFieldGroupForm
              label={employeeFieldGroup.label}
              onSubmit={this.handleSubmit}
              initialValues={setting}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    employeeFieldGroup: fetchSelector(state, 'employee_field_group').data,
  }),
  (dispatch) => ({
    loadFieldGroup(fieldGroupId, token) {
      dispatch(
        actionCreators.fetchData(
          'employee_field_group',
          api.createWithAuth(token).employeeFieldGroups.get(fieldGroupId)
        )
      )
    },
    destroy() {
      dispatch(actionCreators.fetchDestroy('employee_field_group'))
    },
    async updateFieldGroup(fieldGroupId, values, token) {
      try {
        await api.createWithAuth(token).employeeFieldGroups.update(fieldGroupId, values)
        dispatch(push('/settings/employee_custom'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(FieldGroup)
