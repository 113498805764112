import React from 'react'
import {gql} from '@apollo/client'
import {Close} from 'jbc-front/components/icons'
import {DOCUMENT_CONTRACT_REQUEST_ASYNC_TASK_FIELDS_FRAGMENT, DocumentContractRequestAsyncTaskFields} from './Query'
import styles from './DynamicDocumentAsyncStatus.scss'

import Loading from 'components/Loading'
// @ts-ignore
import {useMutation} from 'components/Graphql'
// @ts-ignore
import {recordDisplay} from 'utils'

const CONFIRM_DOCUMENT_CONTRACT_REQUEST = gql`
  mutation confirmDocumentContractRequest($id: ID!) {
    confirmDocumentContractRequest(input: {id: $id}) {
      documentContractRequest {
        ...DocumentContractRequestAsyncTaskFields
      }
    }
  }
  ${DOCUMENT_CONTRACT_REQUEST_ASYNC_TASK_FIELDS_FRAGMENT}
`

interface DocumentContractRequestAsyncStatusProps {
  documentContractRequest: DocumentContractRequestAsyncTaskFields
}

const DocumentContractRequestAsyncStatus: React.FC<DocumentContractRequestAsyncStatusProps> = ({
  documentContractRequest,
}) => {
  const [confirmDocumentContractRequest] = useMutation(CONFIRM_DOCUMENT_CONTRACT_REQUEST)

  if (!documentContractRequest || documentContractRequest.confirmed) {
    return null
  }

  const handleClose = async () => {
    await confirmDocumentContractRequest({variables: {id: documentContractRequest.id}})
  }

  switch (documentContractRequest.status) {
    case 'waiting':
    case 'in_progress':
      return (
        <div className={styles.asyncStatusInProgress}>
          <div className={styles.asyncStatusInner}>
            <div className={styles.asyncStatusLoading}>
              <Loading />
            </div>
            <div className={styles.asyncStatusInfo}>
              <p className={styles.asyncStatusTitle}>電子契約の署名依頼を送信しています。</p>
              <div className={styles.asyncStatusDates}>
                <dl>
                  <dt>開始日時：</dt>
                  <dd>{recordDisplay.date(documentContractRequest.startedAt, 'LLL')}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )
    case 'success':
      return (
        <div className={styles.asyncStatusSuccess}>
          <div className={styles.asyncStatusInner}>
            <div onClick={handleClose} className={styles.asyncStatusClose}>
              <Close size={20} />
            </div>
            <div className={styles.asyncStatusInfo}>
              <p className={styles.asyncStatusTitle}>
                電子契約の送信が完了しました。結果を「電子契約一覧」画面より確認してください。
              </p>
              <div className={styles.asyncStatusDates}>
                <dl>
                  <dt>開始日時：</dt>
                  <dd>{recordDisplay.date(documentContractRequest.startedAt, 'LLL')}</dd>
                </dl>
                <dl>
                  <dt>完了日時：</dt>
                  <dd>{recordDisplay.date(documentContractRequest.finishedAt, 'LLL')}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )
    case 'failed':
      return (
        <div className={styles.asyncStatusError}>
          <div className={styles.asyncStatusInner}>
            <div className={styles.asyncStatusInfo}>
              <p className={styles.asyncStatusTitle}>
                電子契約の送信にてエラーが発生しました。
                <br />
                送信先への署名依頼が送信されている可能性があります。送信状況をお調べしますので、サポート窓口までお問い合わせください。
              </p>
              <div className={styles.asyncStatusDates}>
                <dl>
                  <dt>開始日時：</dt>
                  <dd>{recordDisplay.date(documentContractRequest.startedAt, 'LLL')}</dd>
                </dl>
                <dl>
                  <dt>エラー日時：</dt>
                  <dd>{recordDisplay.date(documentContractRequest.finishedAt, 'LLL')}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )
    default:
      return null
  }
}

export default DocumentContractRequestAsyncStatus
