import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {replace} from 'connected-react-router'
import _ from 'lodash'
import {actionCreators, fetchSelector} from 'actions'
import NaviForm from 'employees/NaviForm'
import api from 'api'
import Button from 'jbc-front/components/Button'
import {formName, withFormSelectorsProvider, withFormSelectors} from 'employees/form/common'
import {employeeDetailEmbed} from 'employees/Show'
import {default as _Loading} from 'components/Loading'
import {makeFormData} from 'InitialInput'
import {getState, isAdminSelector} from 'utils'
import {CommentModal} from 'components/modals/CommentModal'
import {makeFormValues} from 'employees/Form'
import {updateEmployeeDetail} from 'employees/Update'
import {FieldGroupProvider} from 'employees/FieldWithGroup'
import {getFormValues} from 'redux-form'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const Loading = ({loaded, children}) =>
  loaded ? (
    children
  ) : (
    <div className="l-wrap-xs u-pt100">
      <div className="l-box-message">
        <_Loading />
      </div>
    </div>
  )

const Accepted = ({requestStatus, children}) => {
  switch (requestStatus) {
    case 'none':
    case 'rejected':
      return children
    case 'applying':
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">管理者の承認待ちです</div>
        </div>
      )
    default:
      // キャンセル, 承認済み
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">既にこの更新依頼は終了しています</div>
        </div>
      )
  }
}

const Confirm = ({
  data,
  submit,
  submitting,
  token,
  client,
  loadData,
  destroy,
  update,
  fieldGroups,
  employeeFieldGroups,
  fields,
  selector,
  isAdmin,
  getFormValues,
}) => {
  const {comments, groups, custom_group_ids, type, detail_input_datum} = _.get(data, 'detail_input_request') || {}

  const handleSubmit = async (comment) => {
    const values = await getFormValues(formName)
    const formValues = makeFormValues({values, hideEmploymentInfo: true})
    await update(token, formValues, comment, fields)
  }

  const [fetched, setFetched] = useState(false)
  useEffect(() => {
    const asyncLoadData = async () => {
      await loadData(token, client)
      setFetched(true)
    }

    asyncLoadData()
    return () => destroy()
  }, [])

  const modalTitle = isAdmin ? '修正依頼' : '更新依頼'
  const placeholder = isAdmin ? '修正して欲しい内容などを入力してください' : '更新して欲しい理由などを入力してください'

  return (
    <Loading loaded={fetched}>
      <Accepted requestStatus={data?.detail_input_request?.status}>
        <FieldGroupProvider
          type={type}
          requestGroups={groups}
          requestCustomGroupIds={custom_group_ids}
          permissionGroups={employeeFieldGroups}
        >
          <CommentModal
            modalTitle={modalTitle}
            placeholder={placeholder}
            onSubmit={(formData) => handleSubmit(formData.comment)}
            comments={isAdmin ? comments : []}
          >
            {(showModal, _isOpen) => (
              <NaviForm
                submitText="完了"
                data={data}
                fieldGroups={fieldGroups}
                employeeFieldGroups={employeeFieldGroups}
                tmpData={detail_input_datum?.data_raw}
                tmpFiles={detail_input_datum?.detail_input_files}
                employee={data}
                hideEmploymentInfo
                onSubmit={showModal}
                comments={comments}
                selector={selector}
                isForceEmployee
                naviSubmit={
                  <Button primary disabled={submitting} onClick={() => submit()} widthWide>
                    完了
                  </Button>
                }
              />
            )}
          </CommentModal>
        </FieldGroupProvider>
      </Accepted>
    </Loading>
  )
}

export default Confirm
  |> connect(
    (state, {isSubmitting}) => ({
      data: state.employees.current.data,
      token: state.auth.token,
      client: state.client.current,
      fields: fetchSelector(state, 'custom_fields').data,
      submitting: isSubmitting(state),
      fieldGroups: fetchSelector(state, 'custom_field_groups').data,
      employeeFieldGroups: fetchSelector(state, 'employee_field_groups').data,
      isAdmin: isAdminSelector(state),
    }),
    (dispatch, {getValues, submit}) => ({
      loadData(token, client) {
        return Promise.all([
          api.createWithAuth(token).employees.getCurrent(employeeDetailEmbed(true)),
          api.createWithAuth(token).employeeFieldGroups.list(),
        ]).then(([currentEmployee, employeeFieldGroupsList]) => {
          dispatch(actionCreators.employees.current.fetchData(new Promise((r) => r(currentEmployee))))
          dispatch(actionCreators.fetchData('employee_field_groups', new Promise((r) => r(employeeFieldGroupsList))))
          dispatch(
            actionCreators.fetchData(
              'custom_field_groups',
              api.createWithAuth(token).employeeCustom.fieldGroups.list(client.id)
            )
          )
          dispatch(
            actionCreators.fetchData(
              'custom_fields',
              api.createWithAuth(token).employeeCustom.fields.list(client.id, {
                embed: ['custom_employee_field_options', 'custom_employee_field_permissions'],
              })
            )
          )
        })
      },
      destroy() {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.fetchDestroy('employee_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_field_groups'))
        dispatch(actionCreators.fetchDestroy('custom_fields'))
      },
      async update(token, values, comment, fields) {
        const state = await getState(dispatch)
        const formValues = getValues(state)
        try {
          await updateEmployeeDetail(values.employee.id, {resumes: values.resumes}, token, undefined, fields)
          await api
            .createWithAuth(token)
            .detailInputData.create(makeFormData(formValues, fields, {type: 'direct_request', comment}), {
              headers: {'content-type': 'multipart/form-data'},
            })
          dispatch(replace('/dashboard'))
          dispatch(notifySuccess('送信しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      submit() {
        dispatch(submit)
      },
      async getFormValues(formName) {
        const state = await getState(dispatch)
        const getValues = getFormValues(formName)
        return getValues(state)
      },
    })
  )
  |> withFormSelectors
  |> withFormSelectorsProvider
