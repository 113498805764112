import React from 'react'

import {reduxForm} from 'redux-form'
import {Section, TextField, CheckboxField, BoxDouble} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import * as validators from 'validators'
import FormErrors, {onSubmitFail} from 'jbc-front/components/FormErrors'
import styles from 'registration/registration.scss'
import {Phone} from 'FormFields'

let RegistrationTrialForm = ({handleSubmit, pristine, submitting}) => (
  <form onSubmit={handleSubmit}>
    <FormErrors />
    <Section className={styles.wrap}>
      <TextField name="office_contact_info_name" label="会社名" required validate={validators.maxLength(255)} />

      <BoxDouble>
        <TextField name="employee_last_name" label="姓" required validate={validators.maxLength(32)} />
        <TextField name="employee_first_name" label="名" required validate={validators.maxLength(32)} />
      </BoxDouble>

      <Phone required />
      <TextField
        name="user_email"
        label="メールアドレス"
        required
        validate={[validators.maxLength(255), validators.email]}
      />
      <TextField name="user_password" label="パスワード" type="password" required validate={validators.minLength(8)} />
      <TextField name="user_password_confirmation" label="パスワード(確認用)" type="password" required />

      <CheckboxField
        name="agree_to_tos"
        label="利用規約・プライバシーポリシー・情報セキュリティ基本方針"
        labelContent={
          <span>
            &nbsp;
            <a className="u-txt-link" href="https://lms.jobcan.ne.jp/terms" target="_blank" rel="noopener noreferrer">
              利用規約
            </a>
            および
            <a className="u-txt-link" href="https://all.jobcan.ne.jp/policy/" target="_blank" rel="noopener noreferrer">
              プライバシーポリシー
            </a>
            、
            <a
              className="u-txt-link"
              href="https://all.jobcan.ne.jp/personalinfo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              情報セキュリティ基本方針
            </a>
            に同意します
          </span>
        }
      />
      <div className="u-ta-c u-mt30">
        <Button onClick={handleSubmit} primary disabled={pristine || submitting}>
          無料で試す
        </Button>
      </div>
    </Section>
  </form>
)

const registrationValidate = (values) => {
  const errors = {}
  if (
    values.user_password &&
    values.user_password_confirmation &&
    values.user_password !== values.user_password_confirmation
  ) {
    errors.user_password_confirmation = 'が一致しません'
  }
  if (values.agree_to_tos != true) {
    errors.agree_to_tos = 'に同意してください'
  }
  return errors
}

export default reduxForm({
  form: 'registrationTrialForm',
  validate: registrationValidate,
  enableReinitialize: true,
  onSubmitFail,
})(RegistrationTrialForm)
