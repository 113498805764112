import React, {forwardRef} from 'react'
import {renderTextWithAnchor} from 'jbc-front/components/Form'
import {AdminUser, SingleEmployee} from 'jbc-front/components/icons'
import {recordDisplay} from 'utils'
import styles from './ReviewComments.scss'

const Comment = ({comment}) => {
  const _comment = comment.split(/\r\n|\r|\n/)
  return (
    <div>
      {_comment.map((value, i) => (
        <p key={i}>{renderTextWithAnchor(value)}</p>
      ))}
    </div>
  )
}

const User = ({type, user}) => {
  const isAdmin = type === 'from_admin'
  const UserIcon = isAdmin ? AdminUser : SingleEmployee
  const fullName = recordDisplay.fullName(user?.employee)
  const name = isAdmin ? ['(', fullName, ')'].join('') : fullName
  return (
    <>
      <UserIcon className={styles.reviewrIcon} />
      {isAdmin && <span>管理者</span>}
      <p className={styles.reviewerName}>{name}</p>
    </>
  )
}

const ReviewComments = forwardRef(({comments = [], widthFixed, widthAuto}, ref) => {
  const addtionalContentClass = (widthFixed && styles.contentFixed) || (widthAuto && styles.contentAuto) || ''
  return (
    comments.length > 0 && (
      <div ref={ref} className={`${styles.content} ${addtionalContentClass}`}>
        <div className={styles.reviewComments}>
          {comments.map(({id, type, comment, created_at, user}) => (
            <div key={id} className={styles.reviewCommentWrap}>
              <div className={styles.reviewerWrap}>
                <div className={styles.reviewer}>
                  <User type={type} user={user} />
                </div>
                <p className={styles.reviewDate}>{recordDisplay.dateOrTime(created_at)}</p>
              </div>
              <Comment comment={comment} />
            </div>
          ))}
        </div>
      </div>
    )
  )
})

export default ReviewComments
