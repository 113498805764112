import Rollbar from 'rollbar'
import {datadogRum} from '@datadog/browser-rum'

export const logError = (error: Error, context?: object) => {
  Rollbar.error(error, context)
  datadogRum.addError(error, context)
}

export interface TelemetryUser {
  id: string
  customer_code?: string
}

export const setTelemetryUser = (user: TelemetryUser) => {
  datadogRum.setUser({
    ...user,
  })
}

export const clearTelemetryUser = () => {
  datadogRum.clearUser()
}
