import React from 'react'
import {Link} from 'react-router-dom'
import {HEALTH_AND_WELFARE_PENSION} from 'procedureStatuses/reportList/common'
import ListGroup from 'jbc-front/components/ListGroup'

export const getReportList = function* ({healthInsuranceType, healthInsuranceJoined, welfarePensionInsuranceJoined}) {
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    yield {
      num: 'r14',
      title: '健康保険・厚生年金保険　被保険者住所変更届',
      editable: true,
      type: HEALTH_AND_WELFARE_PENSION,
    }
    if (healthInsuranceType === 'its' && healthInsuranceJoined) {
      yield {
        num: 'r147',
        title: '健康保険 被保険者 被扶養者住所登録・変更届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
    if (healthInsuranceType === 'other' && healthInsuranceJoined) {
      yield {
        num: 'r14Other',
        title: '健康保険 住所変更届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
  }
}

const ProcedureInfo = ({procedureStatus}) => (
  <ListGroup>
    <ListGroup.Title>手続き情報</ListGroup.Title>
    <ListGroup.Item as={Link} to={`/change_address/update/${procedureStatus.id}`}>
      手続き情報編集
    </ListGroup.Item>
  </ListGroup>
)

export default {getReportList, ProcedureInfo}
