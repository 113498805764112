import React from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {Pager, PerPage, ListData} from 'settings/auditLogs/components'
import {ListGroup, ListItem} from 'components/ListGroup'
import moment from 'moment'

const List = ({
  list,
  count,
  match: {
    params: {id},
  },
}) => (
  <div>
    <ListData params={{employee_id: id}} />
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">操作履歴</h1>
    </div>
    <div className="l-wrap-s l-contents-wrap">
      <div className="l-breadcrumb u-mb20">
        <Link to="/employees" className="l-breadcrumb-link">
          従業員一覧
        </Link>
        <Link to={`/employees/${id}`} className="l-breadcrumb-link">
          従業員情報
        </Link>
        <span className="l-breadcrumb-here">操作履歴</span>
      </div>
      <div>
        <ListGroup>
          {list.map((log) => (
            <ListItem key={log.id} to={`/employees/${id}/audit_logs/${log.id}`}>
              {log.title}{' '}
              <span style={{whiteSpace: 'nowrap', paddingLeft: '10px'}} className="u-ml-auto">
                {moment(log.created_at).fromNow()}
              </span>
            </ListItem>
          ))}
        </ListGroup>
        <div className="m-pager-area">
          <Pager totalResult={count} pageLinks={7} />
          <PerPage />
        </div>
      </div>
    </div>
  </div>
)

export default connect((state) => ({
  list: state.settings.auditLogs.list.data,
  count: state.settings.auditLogs.list.count,
}))(List)
