import {combineReducers} from 'redux'
import {getQueryDiffFactory} from 'utils'
import {actionTypes} from 'actions'
import fetchReducer from 'reducers/fetchReducer'
import queryReducer from 'reducers/queryReducer'

const defaultQuery = {
  page: 1,
  from_employee: false,
}

export default combineReducers({
  list: fetchReducer(actionTypes.NOTIFICATIONS.LIST, {data: [], count: 0}),
  query: queryReducer(actionTypes.NOTIFICATIONS.SET_QUERY, defaultQuery),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
