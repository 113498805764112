import React, {Component} from 'react'
import {connect} from 'react-redux'
import {asyncError} from 'store/actions/asyncError'
import api from 'api'
import {withRouter} from 'react-router'
import PreviewPdf, {show as showPreviewPdf, setPdf} from 'components/PreviewPdf'
import _ from 'lodash'
import enroll from 'procedureStatuses/reportList/enroll'
import resign from 'procedureStatuses/reportList/resign'
import changeAddress from 'procedureStatuses/reportList/changeAddress'
import changeName from 'procedureStatuses/reportList/changeName'
import addDependents from 'procedureStatuses/reportList/addDependents'
import removeDependents from 'procedureStatuses/reportList/removeDependents'
import startMaternity from 'procedureStatuses/reportList/startMaternity'
import extendMaternity from 'procedureStatuses/reportList/extendMaternity'
import finishMaternity from 'procedureStatuses/reportList/finishMaternity'
import {
  HEALTH_AND_WELFARE_PENSION,
  EMPLOYMENT,
  OTHER,
  toggle,
  Report,
  menuItems,
} from 'procedureStatuses/reportList/common'
import {Link} from 'react-router-dom'
import ListGroup from 'jbc-front/components/ListGroup'
import {Panel, Headding} from 'components/SecondaryPanel'
import DropDownMenu from 'components/DropDownMenu'
import UploadRequest from 'procedureStatuses/UploadRequest'
import {makeBackUrl, recordDisplay} from 'utils'
import {getMainOfficeContactInfo} from 'offices/utils'

const procedures = {
  enroll,
  resign,
  change_address: changeAddress,
  change_name: changeName,
  add_dependents: addDependents,
  remove_dependents: removeDependents,
  start_maternity: startMaternity,
  extend_maternity: extendMaternity,
  finish_maternity: finishMaternity,
}

const mapReport = (infos, handleClick) => (report, index) =>
  (
    <Report
      onClick={handleClick}
      edit={report.editable ? infos.procedureStatus.status === 'in_progress' : null}
      key={index}
      {..._.omit(report, ['type', 'editable'])}
    />
  )

class ReportList extends Component {
  constructor(props) {
    super(props)
    this.pdfList = []
  }

  getUrl = (params) => {
    const {dependentId, num} = params
    let pdfUrl = `/api/procedure_statuses/${this.props.procedureStatus.id}/pdf?type=${num}`
    if (dependentId) {
      pdfUrl += `&employee_dependent_id=${dependentId}`
    }
    return pdfUrl
  }

  showPreviewPdf = (params) => {
    const {
      showPreviewPdf,
      token,
      procedureStatus: {id},
    } = this.props
    const pdfUrl = this.getUrl(params)
    showPreviewPdf({...params, url: pdfUrl, procedureStatusId: id}, id, token)
  }

  handleTodoChange = (todoListNo, todoNo, todo) => {
    this.props.updateTodo(this.props.token, todoListNo, todoNo, todo.id, todo.status == 'todo' ? 'done' : 'todo')
  }

  handleEditClick = ({num, dependentId}) => {
    const {procedureStatusId} = this.props
    let editUrl = `/procedure_statuses/${procedureStatusId}/report_edit`
    if (dependentId) {
      editUrl += `?dependent_id=${dependentId}`
    }
    if (num === 'r14Other') {
      num = 'r14'
    }
    editUrl += `#${_.toUpper(num)}`
    this.props.history.push(editUrl)
  }

  render() {
    const {currentOffice, procedureStatus, location, title, onAsyncError} = this.props
    const healthInsuranceType = _.get(procedureStatus, 'employee.office.health_insurance_type')
    const healthInsuranceJoined = _.get(procedureStatus, 'employee.health_insurance.joined')
    const welfarePensionInsuranceJoined = _.get(procedureStatus, 'employee.welfare_pension_insurance.joined')
    const employmentInsuranceJoined = _.get(procedureStatus, 'employee.employment_insurance.joined')
    const employee = procedureStatus.employee
    const employeeFullName = employee.display_full_name
    const mainOfficeSocial = getMainOfficeContactInfo(currentOffice)
    const infos = {
      procedureStatus,
      healthInsuranceType,
      healthInsuranceJoined,
      welfarePensionInsuranceJoined,
      employmentInsuranceJoined,
    }
    const reportList = [...procedures[procedureStatus.procedure_type].getReportList(infos)]
    const reportGroups = _.groupBy(reportList, 'type')
    const ProcedureInfo = procedures[procedureStatus.procedure_type].ProcedureInfo
    return (
      <React.Fragment>
        <Panel>
          {reportList.length > 0 ? (
            <DropDownMenu
              toggle={toggle}
              menuItems={menuItems(
                reportList.map((report) => ({
                  url: this.getUrl(report),
                  title: report.title,
                })),
                title,
                onAsyncError
              )}
            />
          ) : (
            toggle({})
          )}
          {reportGroups[HEALTH_AND_WELFARE_PENSION] && (
            <ListGroup>
              <ListGroup.Title>社会保険</ListGroup.Title>
              {reportGroups[HEALTH_AND_WELFARE_PENSION].map(mapReport(infos, this.showPreviewPdf))}
            </ListGroup>
          )}
          {reportGroups[EMPLOYMENT] && (
            <ListGroup>
              <ListGroup.Title>雇用保険</ListGroup.Title>
              {reportGroups[EMPLOYMENT].map(mapReport(infos, this.showPreviewPdf))}
            </ListGroup>
          )}
          {reportGroups[OTHER] && (
            <ListGroup>
              <ListGroup.Title>その他</ListGroup.Title>
              {reportGroups[OTHER].map(mapReport(infos, this.showPreviewPdf))}
            </ListGroup>
          )}
          <UploadRequest {...{reportList, procedureStatus}} />
        </Panel>
        <Panel>
          <Headding>関連データ</Headding>
          <ListGroup>
            <ListGroup.Title>事業所情報</ListGroup.Title>
            <ListGroup.Item as={Link} to="/client">
              {recordDisplay(mainOfficeSocial.name)}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup>
            <ListGroup.Title>従業員情報</ListGroup.Title>
            <ListGroup.Item
              as={Link}
              to={`/employees/${procedureStatus.employee_id}/edit?back_to=${makeBackUrl(location)}`}
            >
              {employeeFullName}
            </ListGroup.Item>
          </ListGroup>
          <ProcedureInfo procedureStatus={procedureStatus} />
        </Panel>
        <PreviewPdf onEditClick={this.handleEditClick} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  procedureStatus: state.procedureStatuses.current.data,
  currentUser: state.session.currentUser,
  currentOffice: state.client.current.office,
  token: state.auth.token,
})

export default withRouter(
  connect(mapStateToProps, (dispatch) => ({
    async showPreviewPdf(params, procedureStatusId, token) {
      try {
        dispatch(showPreviewPdf(params))
        const {num, dependentId} = params
        const pdf = await api
          .createWithAuth(token)
          .procedureStatuses.reports.getPdf(
            procedureStatusId,
            num,
            dependentId ? {employee_dependent_id: dependentId} : {}
          )
        dispatch(setPdf(pdf))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    onAsyncError(err) {
      dispatch(asyncError(err))
    },
  }))(ReportList)
)
