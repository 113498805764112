import React from 'react'
import {Field} from 'redux-form'
import Select from 'react-select'
import DateTime from 'react-datetime'
import moment from 'moment'
import _ from 'lodash'
import classNames from 'classnames'
import 'react-select/dist/react-select.css'
import ActionButton from 'jbc-front/components/ActionButton'
import Paginator from 'jbc-front/components/Paginator'
import {Calendar, Pulldown, Card, List} from 'jbc-front/components/icons'
import styles from './SearchForm.scss'
import {Glass} from 'jbc-front/components/icons'

export const MainFormControls = ({isExpand, hasConditions, handleClear, onToggleClick}) => (
  <div
    className={styles.mainFormControls}
    style={isExpand ? {borderBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : {}}
  >
    <div className={styles.mainFormControl}>
      <div className={styles.textBoxWrap}>
        <Field
          name="q"
          inputWidth={260}
          className={styles.textBox}
          placeholder="スタッフコード、氏名、メールアドレス"
          component={SearchTextBox}
        />
        <span className={styles.icon}>
          <Glass size={16} />
        </span>
      </div>
    </div>
    <ActionButton
      primary={hasConditions}
      as="button"
      type="button"
      className={styles.mainFormControl}
      onClick={handleClear}
    >
      条件解除
    </ActionButton>
    <ActionButton
      primary={isExpand}
      as="button"
      type="button"
      className={styles.mainFormControl}
      onClick={onToggleClick}
    >
      詳細検索
      <Pulldown size={18} className={isExpand ? styles.expandIconOpen : styles.expandIconClose} />
    </ActionButton>
  </div>
)

export const SearchResult = ({total, page, limit, isLoading, failureMessage = '結果がありません', className}) => {
  if (isLoading) return <div className={className} />
  const begin = limit * (page - 1) + 1
  const lastElementOfPage = limit * page
  const end = lastElementOfPage > total ? total : lastElementOfPage
  return (
    <div className={className}>
      {total > 0 ? (
        <div>
          <span className={styles.searchResultTotal}>{total}</span>件中
          <span className={styles.searchResultRange}>
            {begin}-{end}
          </span>
          を表示
        </div>
      ) : (
        <p className={styles.searchResultErrorMessage}>{failureMessage}</p>
      )}
    </div>
  )
}

const convertArray = (strValue) => {
  const values = strValue.split(',')
  if (values[0] === '') {
    return []
  } else {
    return values
  }
}

const getOptionChildren = function* (options, currentValue) {
  for (const option of options) {
    if (option.parent_value == currentValue && currentValue !== 'null') {
      yield option.value
      yield* getOptionChildren(options, option.value)
    }
  }
}

const addChildrenWhenParentClicked = (options, prevStrValue, currentStrValue) => {
  const [prevValues, currentValues] = [convertArray(prevStrValue), convertArray(currentStrValue)]
  const diff = _.difference(currentValues, prevValues)
  if (diff.length > 0) {
    const added = diff[0]
    return _.uniq([...currentValues, ...getOptionChildren(options, added)]).join(',')
  } else {
    return currentValues.join(',')
  }
}

export class GroupsSelectBox extends React.Component {
  render() {
    const {input: inputProps, options, ...rest} = this.props
    return (
      <div className={styles.groupsSelectBox}>
        <Select
          multi
          options={options}
          closeOnSelect={false}
          clearableValue={false}
          simpleValue
          placeholder="グループ選択"
          name={inputProps.name}
          value={inputProps.value}
          noResultsText="データが見つかりません"
          noOptionsMessage="データが見つかりません"
          onChange={(newValue) => {
            const value = addChildrenWhenParentClicked(options, inputProps.value, newValue)
            inputProps.onChange(value)
            inputProps.onBlur(value)
          }}
          {...rest}
        />
      </div>
    )
  }
}

export class TagSelectBox extends React.Component {
  render() {
    const {input: inputProps, options, ...rest} = this.props
    return (
      <div className={styles.groupsSelectBox}>
        <Select
          multi
          options={options}
          closeOnSelect={false}
          clearableValue={false}
          simpleValue
          placeholder="タグ選択"
          name={inputProps.name}
          value={inputProps.value}
          noResultsText="データが見つかりません"
          noOptionsMessage="データが見つかりません"
          onChange={(newValue) => {
            const value = addChildrenWhenParentClicked(options, inputProps.value, newValue)
            inputProps.onChange(value)
            inputProps.onBlur(value)
          }}
          {...rest}
        />
      </div>
    )
  }
}

export class SearchSelectBox extends React.Component {
  render() {
    const {input: inputProps, options, width, onSelect, ...rest} = this.props
    return (
      <div className={styles.searchSelectBox} style={{width}}>
        <Select
          {...inputProps}
          options={options}
          searchable={false}
          clearable={false}
          simpleValue
          value={inputProps.value}
          onChange={(newValue) => {
            inputProps.onChange(newValue)
            if (onSelect) {
              onSelect(newValue)
            }
          }}
          {...rest}
        />
      </div>
    )
  }
}

export class SearchTextBox extends React.Component {
  static defaultProps = {
    type: 'text',
  }

  render() {
    const {
      input: inputProps,
      meta: {invalid},
      type,
      inputWidth,
      ...rest
    } = this.props
    return (
      <div
        className={classNames(styles.searchTextBox, {
          [styles.searchTextBoxError]: invalid,
          [styles.langQualification]: inputProps.name === 'languages_qualification_name',
        })}
      >
        {type !== 'date' ? (
          <input type={type} style={{width: inputWidth}} {...inputProps} {...rest} />
        ) : (
          <div className={styles.searchTextBoxDateTimeWrapper}>
            <DateTime
              value={inputProps.value}
              onChange={(param) => {
                const date = moment(param)
                if (date.isValid()) {
                  inputProps.onChange(date.format('YYYY/MM/DD'))
                } else {
                  inputProps.onChange(param)
                }
              }}
              onBlur={() => inputProps.onBlur(inputProps.value)}
              dateFormat={'YYYY/MM/DD'}
              timeFormat={false}
              {...rest}
              ref={(dateTime) => (this._dateTime = dateTime)}
            />
            <i onClick={() => this._dateTime.openCalendar()}>
              <Calendar size={16} />
            </i>
          </div>
        )}
      </div>
    )
  }
}

export class SortableTh extends React.Component {
  static defaultProps = {
    currentSortType: '',
  }

  handleClick = () => {
    const {field, currentSortType, onChange} = this.props
    const [sort, order] = currentSortType.split('__')
    const newOrder = sort !== field || order === 'desc' ? 'asc' : 'desc'
    onChange(`${field}__${newOrder}`)
  }

  render() {
    const {field, globalClassName, children, currentSortType} = this.props
    const className = globalClassName ? `${styles.sortableTh} ${globalClassName}` : styles.sortableTh
    const [sort, order] = currentSortType.split('__')
    const showOrder = field === sort && (order === 'asc' || order === 'desc')
    return (
      <th onClick={this.handleClick} className={className}>
        {children}
        {(() => {
          if (showOrder && order === 'desc') {
            return <Pulldown size={7} className={styles.sortableThIconDesc} />
          } else if (showOrder && order === 'asc') {
            return <Pulldown size={7} className={styles.sortableThIconAsc} />
          }
        })()}
      </th>
    )
  }
}

export const FilteringTag = ({children, selected, onClick}) => (
  <div className={styles.filteringTag}>
    <div className={selected ? styles.filteringTagSelected : styles.filteringTagDefault} onClick={onClick}>
      {children}
    </div>
  </div>
)

export const SearchEmploymentStatusField = ({input: inputProps, employmentStatuses, onEmploymentStatusChange}) => {
  return (
    <div className={styles.searchEmploymentStatusField}>
      {employmentStatuses.map((item) => (
        <FilteringTag
          key={item.id}
          selected={item.id === inputProps.value}
          onClick={() => {
            const newValue = item.id
            inputProps.onChange(newValue)
            inputProps.onBlur(newValue)
            onEmploymentStatusChange()
          }}
        >
          {item.name}
        </FilteringTag>
      ))}
    </div>
  )
}

export const PaginatorWithResult = ({limit, count, page, onClick}) => {
  return (
    <div>
      {count > 0 ? (
        <div className={styles.paginatorWithResult}>
          <Paginator current={page} totalResult={count} rowsPerPage={limit} pageLinks={7} onClick={onClick} />
        </div>
      ) : null}
    </div>
  )
}
export const SortingFieldsWithResult = ({
  limit,
  count,
  limits,
  sortTypes,
  page,
  isLoading,
  onChangeLimit,
  onChangeSortType,
  failureMessage = '従業員が見つかりませんでした',
  sortName = 'sort_type',
}) => (
  <div className={styles.sortingFieldsWithResult}>
    <SearchResult
      total={count}
      page={page}
      limit={limit}
      isLoading={isLoading}
      failureMessage={failureMessage}
      className={styles.resultCenter}
    />
    <SortingFields
      limits={limits}
      sortTypes={sortTypes}
      onChangeLimit={onChangeLimit}
      onChangeSortType={onChangeSortType}
      sortName={sortName}
    />
  </div>
)

const renderLabelWithIcon = ({value, label}) => {
  return (
    <div className={styles.selectOptionWithIcon}>
      {value === 'card' && (
        <div className={styles.selectOptionIcon}>
          <List />
        </div>
      )}
      {value === 'list' && (
        <div className={styles.selectOptionIcon}>
          <Card />
        </div>
      )}
      <p>{label}</p>
    </div>
  )
}

export const ListTypeWithResult = ({
  limit,
  count,
  limits,
  sortTypes,
  listType,
  listTypes,
  page,
  isLoading,
  onChangeLimit,
  onChangeSortType,
  onChangeListType,
  displayListType = true,
  failureMessage = '従業員が見つかりませんでした',
}) => (
  <div className={styles.listTypeWithResult}>
    <div className={styles.listTypeSelectFields}>
      {displayListType && (
        <SearchSelectBox
          options={listTypes}
          valueRenderer={renderLabelWithIcon}
          optionRenderer={renderLabelWithIcon}
          width={120}
          input={{
            value: listType,
            onChange: (newValue) => onChangeListType(newValue),
          }}
        />
      )}
      <Field
        name="limit"
        component={SearchSelectBox}
        options={limits}
        width={130}
        onSelect={(newValue) => {
          onChangeLimit(newValue)
        }}
      />
    </div>
    <div className={styles.listTypeSearchResult}>
      <SearchResult total={count} page={page} limit={limit} isLoading={isLoading} failureMessage={failureMessage} />
    </div>
    <Field
      name="sort_type"
      component={SearchSelectBox}
      options={sortTypes}
      width={160}
      onSelect={() => onChangeSortType()}
    />
  </div>
)

export const SortingFields = ({limits, sortTypes, onChangeLimit, onChangeSortType, sortName}) => (
  <div className={styles.sortingFields}>
    <Field
      name="limit"
      component={SearchSelectBox}
      options={limits}
      width={130}
      onSelect={(newValue) => {
        onChangeLimit(newValue)
      }}
    />
    <Field
      name={sortName}
      component={SearchSelectBox}
      options={sortTypes}
      width={180}
      onSelect={() => onChangeSortType()}
    />
  </div>
)

const isMultiTagSelected = (values, id) => values.indexOf(id) >= 0

export const takeNewMultiTagValues = (currentValues, item) =>
  isMultiTagSelected(currentValues, item.id)
    ? currentValues.filter((ele) => ele !== item.id)
    : [...currentValues, item.id]

const MultiTagFilteringField = ({input: inputProps, header, list, takeNewValuesFunc}) => (
  <div className={styles.detailsBodyItems}>
    {list.length > 0 ? (
      list.map((item) => (
        <FilteringTag
          key={item.id}
          selected={isMultiTagSelected(inputProps.value, item.id)}
          onClick={() => {
            const newValues = takeNewValuesFunc(inputProps.value, item)
            inputProps.onChange(newValues)
            inputProps.onBlur(newValues)
          }}
        >
          {item.name}
        </FilteringTag>
      ))
    ) : (
      <p>従業員の{header}が設定されていません</p>
    )}
  </div>
)

export class MultiTagFilteringFieldRow extends React.Component {
  static defaultProps = {
    takeNewValuesFunc: takeNewMultiTagValues,
    list: [],
  }

  render() {
    const {header, fieldName, list, takeNewValuesFunc, ...rest} = this.props
    return (
      <div className={styles.detailsRow}>
        <div className={styles.detailsHeader}>{header}</div>
        <div className={styles.detailsBody}>
          <Field
            name={fieldName}
            component={MultiTagFilteringField}
            header={header}
            list={list}
            takeNewValuesFunc={takeNewValuesFunc}
            {...rest}
          />
        </div>
      </div>
    )
  }
}
