import _ from 'lodash'
import {SubmissionError} from 'redux-form'

export const getNestedErrors = (data, path = '', res = {}) => {
  if (!_.isObject(data)) {
    return {}
  }
  Object.entries(data).forEach(([key, value]) => {
    if (key === '_errors') {
      _.set(
        res,
        path.slice(1),
        _.mapValues(value, (v) => (_.isArray(v) ? v[0] : v))
      )
    } else if (_.isArray(value)) {
      value.forEach((v, index) => getNestedErrors(v, `${path}.${key}[${index}]`, res))
    } else if (_.isObject(value)) {
      getNestedErrors(value, `${path}.${key}`, res)
    }
  })
  return res
}

export const getBaseErrors = function* (data) {
  if (_.isArray(data)) {
    for (const v of data) {
      yield* getBaseErrors(v)
    }
    return
  }
  if (!_.isObject(data)) {
    return
  }
  for (const [key, value] of Object.entries(data)) {
    if (key === '_base') {
      if (_.isArray(value)) {
        yield* value
      } else {
        yield value
      }
    } else {
      yield* getBaseErrors(value)
    }
  }
}

export const handlerNestFormResponse = (path) => (err) => {
  if (!err.response) {
    throw err
  }
  if (!getBaseErrors(err.response.data).next().done) {
    throw err
  }
  if (err.response && err.response.status === 422) {
    const data = err.response.data
    const errors = getNestedErrors(_.set({}, path, data))
    if (!_.isEmpty(errors)) {
      throw new SubmissionError(errors)
    }
  }
  throw err
}
