import React, {Component} from 'react'
import Button from 'jbc-front/components/Button'

import {FileField, Section} from 'jbc-front/components/Form'
import {reduxForm, getFormSubmitErrors as _getFormSubmitErrors, SubmissionError} from 'redux-form'

const getFormSubmitErrors = _getFormSubmitErrors('file_import')

import {FileImportDescription, validate} from 'employees/FileImport'
import styles from 'employees/FileImport.scss'
import api from 'api'
import {connect} from 'react-redux'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Question, Upload} from 'jbc-front/components/icons'
import Modal from 'jbc-front/components/CommonModal'
import Loading from 'components/Loading'
import image from '../../images/csv-update-help.png'
import onClickOutside from 'react-onclickoutside'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import {asyncError} from 'store/actions/asyncError'

const Form = reduxForm({
  form: 'file_import',
  enableReinitialize: true,
  validate,
})(({submitting, handleSubmit}) => (
  <form onSubmit={handleSubmit} className={`u-mt20 ${styles.form}`}>
    <div className="u-mb40">
      <FileField name="file" />
    </div>
    <AsyncTaskConsumer>
      {({taskRunningProps}) => (
        <Button
          as="button"
          primary
          type="submit"
          disabled={submitting}
          icon={<Upload />}
          widthWide
          {...taskRunningProps}
        >
          従業員登録
        </Button>
      )}
    </AsyncTaskConsumer>
    <Modal
      isOpen={submitting}
      bodyOpenClassName={styles.loadingModalBody}
      className={styles.loadingModal}
      overlayClassName={styles.loadingModalOverlay}
    >
      <Modal.Body>
        <Loading />
      </Modal.Body>
    </Modal>
  </form>
))

const initialValues = {csv_update: true}

const Help = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide()
    }

    render() {
      return (
        <div className={styles.help}>
          <div className={styles.image}>
            <img src={image} />
          </div>
          <br />
          従業員データは従業員一覧画面からダウンロードできます
        </div>
      )
    }
  }
)

class FileImportUpdate extends Component {
  state = {
    showHelp: false,
  }

  handleSubmit = (values) => {
    const {token, importEmployees} = this.props
    return importEmployees(token, values)
  }

  render() {
    const {errors} = this.props
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">従業員情報の更新</h1>
          <div className={`u-mt60 ${styles.mainNoteWrap}`}>
            <div className={styles.mainNoteTitle}>更新する従業員情報を記載したファイルを選択してください</div>
            <div className={styles.mainNoteBody}>
              <div className={styles.mainNote}>対応しているファイル形式はCSVとなります</div>
              <div className={styles.mainNote}>
                従業員データを
                <Link to="/employees" className="u-txt-link" target="_blank">
                  こちら
                </Link>
                <span
                  className={`u-ml5 u-cur-pointer ignore-react-onclickoutside ${styles.helpWrap}`}
                  onClick={() => {
                    this.setState({showHelp: !this.state.showHelp})
                  }}
                >
                  <Question />
                  {this.state.showHelp && (
                    <Help
                      hide={() => {
                        this.setState({showHelp: false})
                      }}
                    />
                  )}
                </span>
                からダウンロードし、更新したい箇所をご修正ください
              </div>
              <Form onSubmit={this.handleSubmit} initialValues={initialValues} />
            </div>
          </div>
        </div>
        {errors && errors.rows && (
          <Section title="ファイル内容に誤りがあります" error>
            <ul>
              {errors.rows.map((err) => (
                <li key={err.index}>
                  第{err.index + 1}行：{err.name}
                  {err.message}
                </li>
              ))}
            </ul>
          </Section>
        )}
        <div className={styles.warningWrap}>
          <dt className={styles.warningTitle}>【注意】</dt>
          <dd>
            ①スタッフコードが一致する従業員情報を更新しますので
            <span className={styles.warning}>正しいスタッフコードをご記入ください</span>
          </dd>
          <dd>
            ②空白の状態で更新すると、<span className={styles.warning}>データが削除されますのでご注意ください</span>
          </dd>
          <dd>
            ③更新するユーザが、ジョブカン他サービスにも登録されている場合、
            <span className={styles.warning}>
              姓名、メールアドレスを変更すると、ご利用中のジョブカン他サービスにも自動反映されます。またビジネスネーム姓名に入力された場合、ビジネスネーム姓名が他サービスの姓名に自動反映されます。
              （但しジョブカン給与計算でビジネスネームが設定済みの従業員に関しては、ビジネスネーム姓名に対して自動反映されます）
            </span>
          </dd>
        </div>
        <div className="u-mt50">
          <FileImportDescription update />
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    errors: getFormSubmitErrors(state),
  }),
  (dispatch) => ({
    async importEmployees(token, values) {
      try {
        await api.createWithAuth(token).employees.import(values)
        dispatch(push('/employees'))
      } catch (err) {
        if (err.response && err.response.status == 422 && _.get(err.response, 'data._errors')) {
          throw new SubmissionError(err.response.data._errors)
        }
        dispatch(asyncError(err))
      }
    },
  })
)(FileImportUpdate)
