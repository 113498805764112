import {FC} from 'react'
import {EgovProcedure} from '../../query'
import {DeleteTd} from '../DeleteTd'
import {displayFormat} from 'libs/formatter'
import styles from './PrepareRow.scss'

// jbc-front
import Button from 'jbc-front/components/Button'

interface PrepareRowProps {
  egovProcedure: EgovProcedure
  employeeOrOfficeName: string
  onOpenApplyModal: (egovProcedure: EgovProcedure) => void
  onOpenDeleteModal: (egovProcedureId: string) => void
  isDeletable: boolean
}

export const PrepareRow: FC<PrepareRowProps> = ({
  egovProcedure,
  onOpenApplyModal,
  onOpenDeleteModal,
  isDeletable,
  employeeOrOfficeName,
}) => {
  return (
    <tr className="table-hover" onClick={() => onOpenApplyModal(egovProcedure)}>
      <td>
        <Button primary onClick={() => onOpenApplyModal(egovProcedure)} className={styles.prepareButton}>
          <p className={styles.prepareText}>申請準備中</p>
          <span className={styles.prepareSubText}>申請する</span>
        </Button>
      </td>
      <td>{employeeOrOfficeName}</td>
      <td>{displayFormat(egovProcedure.procedureName)}</td>
      <td title={egovProcedure.formName} className={styles.overflowEllips}>
        {egovProcedure.formName}
      </td>
      <td>{displayFormat()}</td>
      <td>{displayFormat()}</td>
      <DeleteTd onClick={() => onOpenDeleteModal(egovProcedure.id)} disabled={!isDeletable} />
    </tr>
  )
}
