import React, {Component} from 'react'
import {connect} from 'react-redux'
import {toFormValues} from 'utils'
import api from 'api'
import {actionCreators} from 'actions'
import {asyncError} from 'store/actions/asyncError'

const makeInitialValues = ({data}) => toFormValues(data)

const connectForm = (report, Form, options = {}) =>
  connect(
    (state, ownProps) => ({
      data: state.procedureStatuses.report[report],
      token: state.auth.token,
      ...(options.mapState ? options.mapState(state, ownProps) : {}),
    }),
    (dispatch, ownProps) => ({
      loadReport(procedureStatusId, token) {
        if (ownProps.skipLoad) {
          return
        }
        const {dependentId} = ownProps
        dispatch(
          actionCreators.procedureStatuses.report.fetchData(
            api
              .createWithAuth(token)
              .procedureStatuses.reports.get(procedureStatusId, report, {employee_dependent_id: dependentId})
          )
        )
      },
      updateReport: async (procedureStatusId, values, token, afterAction) => {
        try {
          if (values.id && !options.createOnly) {
            await api.createWithAuth(token).procedureStatuses.reports.update(procedureStatusId, report, values)
          } else {
            await api.createWithAuth(token).procedureStatuses.reports.create(procedureStatusId, report, values)
          }
          afterAction()
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
      destroy() {
        dispatch(actionCreators.procedureStatuses.report.destroy())
      },
      ...(options.mapDispatch ? options.mapDispatch(dispatch, ownProps) : {}),
    })
  )(
    class extends Component {
      static displayName = 'reportFormHoc'
      componentDidMount() {
        const {loadReport, token, procedureStatusId} = this.props
        loadReport(procedureStatusId, token)
      }

      componentWillUnmount() {
        this.props.destroy()
      }

      handleSubmit = (values) => {
        const {token, procedureStatusId, updateReport, afterSubmit} = this.props
        return updateReport(procedureStatusId, values, token, afterSubmit)
      }

      render() {
        /* eslint-disable no-unused-vars */
        const {token, loadReport, afterSubmit, updateReport, data, ...rest} = this.props
        /* eslint-enable no-unused-vars */
        const _makeInitialValues = options.makeInitialValues || makeInitialValues
        return (
          <Form onSubmit={this.handleSubmit} initialValues={_makeInitialValues(this.props)} token={token} {...rest} />
        )
      }
    }
  )

export default connectForm
