import {useFetchData} from 'hooks/api/useFetchData'
import {ApplicableProcedure} from 'types/api/csvProcedureStatuses/applicableProcedure'

export const useFetchApplicableProcedures = (procedure_type?: string) => {
  const path = '/api/csv_procedure_statuses/applicable_procedures'

  return useFetchData<ApplicableProcedure[]>(path, [], {
    params: {
      procedure_type: procedure_type,
    },
    immediate: false,
  })
}
