import {FC} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {parse} from 'query-string'
import {push} from 'connected-react-router'
import useReactRouter from 'use-react-router'
import {ADD_DEPENDENTS} from 'consts/procedureType'
import {DependentAdd, DependentAddExists} from 'jbc-front/components/icons'
import {RequireEmployeeUpdateRouter} from 'components/router/RequireEmployeeUpdateRouter'
import style from './ChooseNewOrExists.scss'

// @ts-ignore
import {isAdminSelector} from 'utils'
// @ts-ignore
import {Card, Container} from 'components/SelectCard'

const ChooseNewOrExists: FC = () => {
  const dispatch = useDispatch()
  const {search, pathname} = useReactRouter().location
  const {isAdmin, currentUserEmployeeId} = useSelector((state: any) => ({
    isAdmin: isAdminSelector(state),
    currentUserEmployeeId: state.session.currentUser.employee?.id,
  }))
  const isEmployeeInput = !isAdmin || pathname.includes('/mypage/')
  const employeeId = isEmployeeInput ? currentUserEmployeeId : parse(search)['employee_id']

  // 従来の扶養家族追加ページへ移動
  const onClickNewDependents = () => {
    const path = isEmployeeInput
      ? '/mypage/add_dependents/employee_input'
      : `/add_dependents/detail?employee_id=${employeeId}`
    dispatch(push(path))
  }

  // 既存の扶養家族を選択するページへ遷移
  const onClickExistDependents = () => {
    const path = isEmployeeInput
      ? '/mypage/add_dependents/choose_exist_dependents'
      : `/add_dependents/choose_exist_dependents?employee_id=${employeeId}`
    dispatch(push(path))
  }

  const redirectPath = isEmployeeInput
    ? `/dashboard?error=employee_info`
    : `/employees/${employeeId}/edit?procedure_type=${ADD_DEPENDENTS}&next_action=choose_new_or_exists`

  return (
    <RequireEmployeeUpdateRouter employeeId={employeeId as string} redirectPath={redirectPath}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">手続き方法を選択</h1>
        <p className="m-title-main-note">手続き方法を選択してください</p>
      </div>
      <Container>
        <Card icon={DependentAdd} onClick={onClickNewDependents}>
          <span className={style.buttonText}>新たに被扶養者情報を登録する</span>
        </Card>
        <Card icon={DependentAddExists} onClick={onClickExistDependents}>
          <span className={style.buttonText}>既存の扶養情報で手続きする</span>
        </Card>
      </Container>
    </RequireEmployeeUpdateRouter>
  )
}

export default ChooseNewOrExists
