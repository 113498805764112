import React, {Component} from 'react'
import {connect, useSelector} from 'react-redux'
import Sidebar from 'jbc-front/components/Sidebar'
import {Link} from 'react-router-dom'
import {TmEmployee, TmNotification, Close, Hamburger} from 'jbc-front/components/icons'
import styles from 'tm/components/Sidebar.scss'
import onClickOutside from 'react-onclickoutside'
import {actionCreators} from 'actions'
import api from 'api'

const TmNotificationIcon = (props) => {
  const status = useSelector((state) => state.tm.notifications.status.data)
  const hasUnanswered = status?.unanswered_count && status.unanswered_count > 0
  return (
    <div className={styles.notificationIcon}>
      <TmNotification {...props} />
      {hasUnanswered ? <div className={styles.unanswered} /> : null}
    </div>
  )
}

const NotificationCount = ({count}) => {
  if (count && count > 0) {
    const displayCount = count >= 10 ? '9+' : count
    return <span className={styles.notificationCount}>{displayCount}</span>
  }
  return null
}

class _TmSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSidebarOpen: false,
    }
  }

  componentDidMount() {
    if (this.props.token) {
      this.props.loadData(this.props.token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  componentDidUpdate(prevProps) {
    if (this.props.token && (prevProps.token !== this.props.token || prevProps.location !== this.props.location)) {
      this.props.loadData(this.props.token)
    }
  }

  handleClickOutside = () => {
    this.HideMenu()
  }

  handleTap = () => {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen,
    })
  }

  HideMenu = () => {
    this.setState({
      isSidebarOpen: false,
    })
  }

  render() {
    const {notificationStatus} = this.props

    return (
      <div className={styles.fixation}>
        <Sidebar offset={Infinity} isOpen={this.state.isSidebarOpen}>
          <Sidebar.Item as={Link} to="/tm/employees" icon={TmEmployee} onClick={this.HideMenu}>
            従業員検索
          </Sidebar.Item>
          <Sidebar.Item as={Link} to="/tm/notifications" icon={TmNotificationIcon} onClick={this.HideMenu}>
            <span>従業員通知</span>
            <NotificationCount count={notificationStatus?.unanswered_count} />
          </Sidebar.Item>
        </Sidebar>
        <div onClick={this.handleTap} className={styles.menuBtn}>
          {this.state.isSidebarOpen ? <Close size={20} /> : <Hamburger size={20} />}
        </div>
      </div>
    )
  }
}

const TmSidebar = onClickOutside(_TmSidebar)

export default connect(
  (state) => ({
    token: state.auth.token,
    currentUser: state.session.currentUser,
    location: state.router.location,
    notificationStatus: state.tm.notifications.status.data,
  }),
  (dispatch) => ({
    loadData(token) {
      dispatch(actionCreators.tm.notifications.status.fetchData(api.createWithAuth(token).tm.notifications.status()))
    },
    destroy() {
      dispatch(actionCreators.tm.notifications.status.destroy())
    },
  })
)(TmSidebar)
