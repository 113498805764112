import React from 'react'
import {MinusSquare, PlusSquare, Pulldown} from 'jbc-front/components/icons'
import styles from 'components/PreviewPdf.scss'

const zooms = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500]

const PreviewController = ({page, totalPages, zoom, onChangePage, onChangeZoom}) => {
  const handlePreviousPage = () => {
    if (page > 1) {
      onChangePage(page - 1)
    }
  }
  const handleNextPage = () => {
    if (page < totalPages) {
      onChangePage(page + 1)
    }
  }
  const handleZoomIn = () => {
    const index = zooms.indexOf(zoom) + 1
    if (index < zooms.length) {
      onChangeZoom(zooms[index])
    }
  }
  const handleZoomOut = () => {
    const index = zooms.indexOf(zoom) - 1
    if (index >= 0) {
      onChangeZoom(zooms[index])
    }
  }

  return (
    <div className={styles.controller}>
      <span className={styles.zoom} onClick={handleZoomOut}>
        <MinusSquare size={20} />
      </span>
      <span className={styles.scale}>{zoom}％</span>
      <span className={styles.zoom} onClick={handleZoomIn}>
        <PlusSquare size={20} />
      </span>
      <span className={styles.pagePrev} onClick={handlePreviousPage}>
        <Pulldown size={12} />
      </span>
      <span className={styles.pageNumber}>
        {page} / {totalPages}
      </span>
      <span className={styles.pageNext} onClick={handleNextPage}>
        <Pulldown size={12} />
      </span>
    </div>
  )
}

export default PreviewController
