import React, {useEffect} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import classNames from 'classnames'
import queryString from 'query-string'
import {actionCreators} from 'actions'
import api from 'api'
import {recordDisplay} from 'utils'
import LoadingPage from 'components/LoadingPage'
import {getTmGroups} from 'employees/form/EmploymentInfomation'
import {wrapArrowText} from 'tm/employees/Search'
import Delete from 'tm/notifications/Delete'
import styles from 'tm/notifications/Show.scss'
import {Notification, NotificationAnswer, DeletedNotification} from 'tm/notifications/Notification'
import {SortableTh} from 'jbc-front/components/SearchForm'
import CsvDownload from './CsvDownload'
import Remind from './Remind'

const sortTypeFromQuery = ({sort, order}) => {
  sort = sort || 'selected_option_id'
  order = order === 'desc' ? 'desc' : 'asc'
  return [sort, order]
}

const List = ({notification, location: {search, pathname}, history, answers, loadingAnswers}) => {
  const [sort, order] = sortTypeFromQuery(queryString.parse(search))
  const currentSortType = `${sort}__${order}`
  const dispatch = useDispatch()
  const [token] = useSelector((state) => [state.auth.token])
  useEffect(() => {
    dispatch(
      actionCreators.tm.notifications.answers.fetchData(
        api.createWithAuth(token).tm.notifications.answers(notification.id, {sort, order})
      )
    )
    return () => {
      dispatch(actionCreators.tm.notifications.answers.destroy())
    }
  }, [notification, sort, order])
  const options = _.keyBy(notification.talent_notification_options, 'id')
  const handleChangeSortType = (sortType) => {
    const [sort, order] = sortType.split('__')
    history.push({pathname, search: queryString.stringify({sort, order})})
  }

  if (loadingAnswers) {
    return <LoadingPage />
  } else {
    return (
      <table className="m-table-list m-table-fixed">
        <thead>
          <tr>
            <th className={styles.nameColumn}>氏名</th>
            <th>グループ</th>
            <SortableTh
              field="selected_option_id"
              onChange={handleChangeSortType}
              currentSortType={currentSortType}
              globalClassName={styles.answerColumn}
            >
              返信状況
            </SortableTh>
            <SortableTh
              field="answered_at"
              onChange={handleChangeSortType}
              currentSortType={currentSortType}
              globalClassName={styles.answeredAtColumn}
            >
              返信日時
            </SortableTh>
          </tr>
        </thead>
        <tbody>
          {answers.map((answer) => {
            const selectedOption = options[answer.selected_option_id]
            return (
              <tr key={answer.id} className={styles.row}>
                <td>{recordDisplay.fullName(answer.employee)}</td>
                <td>
                  {recordDisplay(
                    !_.isEmpty(getTmGroups(answer.employee)) && (
                      <span>{wrapArrowText(getTmGroups(answer.employee)[0])}</span>
                    )
                  )}
                </td>
                <td>{recordDisplay(selectedOption?.label || '未回答')}</td>
                <td>{recordDisplay(selectedOption && moment(answer.answered_at).format('YYYY/MM/DD HH:mm:ss'))}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
}

const Show = ({
  match: {
    params: {id},
  },
  location,
  history,
}) => {
  const dispatch = useDispatch()
  const [token, currentTmUser, notification, loading, deleted, answers, loadingAnswers] = useSelector((state) => [
    state.auth.token,
    state.session.currentTmUser,
    state.tm.notifications.current.data,
    state.tm.notifications.current.loading,
    state.tm.notifications.current.deleted,
    state.tm.notifications.answers.data,
    state.tm.notifications.answers.loading,
  ])
  useEffect(() => {
    dispatch(actionCreators.tm.notifications.current.fetchData(api.createWithAuth(token).tm.notifications.get(id)))
    return () => {
      dispatch(actionCreators.tm.notifications.current.destroy())
    }
  }, [])

  if (
    notification.employee &&
    currentTmUser.employee &&
    notification.answers_visibility !== 'all_recipients' &&
    notification.employee.id !== currentTmUser.employee.id
  ) {
    return <Redirect to={`/tm/notifications/${id}/answer`} />
  }

  const checkUnAnswers = (answers) => !!_.find(answers, ['selected_option_id', null])

  return (
    <div className={'l-contents-wrap l-wrap-xxl ' + styles.contentsWrapSp}>
      <div className="l-breadcrumb">
        <Link to="/tm/notifications/" className="l-breadcrumb-link">
          従業員通知
        </Link>
        <span className="l-breadcrumb-here">通知詳細</span>
      </div>
      <div className={classNames(styles.createTitleWrap, 'l-title-wrap')}>
        <h1 className="m-title-main">通知詳細</h1>
      </div>
      {loading ? (
        <LoadingPage />
      ) : deleted ? (
        <DeletedNotification />
      ) : (
        notification.id && (
          <>
            <Notification
              notification={notification}
              actions={notification.employee.id == currentTmUser.employee.id && <Delete id={id} />}
            />
            <NotificationAnswer>
              <div className={styles.options}>
                {notification.talent_notification_options.map((option) => (
                  <div key={option.id} className={styles.option}>
                    {option.label}
                  </div>
                ))}
              </div>
            </NotificationAnswer>
            <div className="u-mt20">
              <div className={styles.subjectWrapper}>
                <h4 className={classNames('u-mb10', styles.summaryHeader)}>確認状況（サマリ）</h4>
                <div className={classNames('u-mb10', styles.buttonArea)}>
                  {!_.isEmpty(answers) && <CsvDownload />}
                  {notification.employee.id == currentTmUser.employee.id &&
                    notification.send_status == 'completed' &&
                    checkUnAnswers(answers) && <Remind id={id} />}
                </div>
              </div>
              <div className={styles.overflowScroll}>
                <List
                  notification={notification}
                  location={location}
                  history={history}
                  answers={answers}
                  loadingAnswers={loadingAnswers}
                />
              </div>
            </div>
          </>
        )
      )}
    </div>
  )
}

export default Show
