import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Wrap, TitleArea, Title, Dl, Dd, Dt, SubPanelarea, SubPanel} from 'tm/employees/Parts'
import * as icons from 'jbc-front/components/icons'
import {Language} from 'jbc-front/components/icons'
import ShowMoreBtn from './ShowMoreBtn'

class BasicInfomation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }
  render() {
    const {employee, languages, languageLevels, userDisplaySetting} = this.props
    const icon_name = getCategoryIconName(userDisplaySetting, 'language')
    const color =
      (!_.isNull(getCategoryIconColor(userDisplaySetting, 'language')) &&
        getCategoryIconColor(userDisplaySetting, 'language')) ||
      '#3498db'
    const IconName = icons[icon_name]
    const style = {color: color}
    return (
      (showCategory(userDisplaySetting, 'language') &&
        employee.employee_language &&
        employee.employee_language.length > 0 && (
          <Panel>
            <Item>
              <Wrap>
                <TitleArea>
                  <Title>
                    {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || (
                      <Language size={30} color={color} />
                    )}
                    <div style={style}>語学</div>
                  </Title>
                </TitleArea>

                <SubPanelarea>
                  {employee.employee_language &&
                    (this.state.isExpand ? employee.employee_language : employee.employee_language.slice(0, 4)).map(
                      (language, index) =>
                        language && (
                          <SubPanel key={index}>
                            <SubPanel.Headding>
                              {recordDisplay.option(
                                _.get(language, 'language_id'),
                                languages.map((t) => ({value: t.id, label: t.name}))
                              )}
                            </SubPanel.Headding>
                            {_.get(language, 'level') &&
                              _.get(language, 'qualification_name') &&
                              _.get(language, 'score') && (
                                <Dl>
                                  <Dt>レベル</Dt>
                                  <Dd>
                                    {recordDisplay.option(
                                      _.get(language, 'level'),
                                      languageLevels.map((t) => ({value: t.id, label: t.name}))
                                    )}
                                  </Dd>
                                  <Dt>資格名</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'qualification_name'))}</Dd>
                                  <Dt>点数</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'score'))}</Dd>
                                </Dl>
                              )}
                            {_.get(language, 'level') &&
                              _.get(language, 'qualification_name') &&
                              !_.get(language, 'score') && (
                                <Dl>
                                  <Dt>レベル</Dt>
                                  <Dd>
                                    {recordDisplay.option(
                                      _.get(language, 'level'),
                                      languageLevels.map((t) => ({value: t.id, label: t.name}))
                                    )}
                                  </Dd>
                                  <Dt>資格名</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'qualification_name'))}</Dd>
                                </Dl>
                              )}
                            {_.get(language, 'level') &&
                              !_.get(language, 'qualification_name') &&
                              _.get(language, 'score') && (
                                <Dl>
                                  <Dt>レベル</Dt>
                                  <Dd>
                                    {recordDisplay.option(
                                      _.get(language, 'level'),
                                      languageLevels.map((t) => ({value: t.id, label: t.name}))
                                    )}
                                  </Dd>
                                  <Dt>点数</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'score'))}</Dd>
                                </Dl>
                              )}
                            {!_.get(language, 'level') &&
                              _.get(language, 'qualification_name') &&
                              _.get(language, 'score') && (
                                <Dl>
                                  <Dt>資格名</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'qualification_name'))}</Dd>
                                  <Dt>点数</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'score'))}</Dd>
                                </Dl>
                              )}
                            {!_.get(language, 'level') &&
                              !_.get(language, 'qualification_name') &&
                              _.get(language, 'score') && (
                                <Dl>
                                  <Dt>点数</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'score'))}</Dd>
                                </Dl>
                              )}
                            {_.get(language, 'level') &&
                              !_.get(language, 'qualification_name') &&
                              !_.get(language, 'score') && (
                                <Dl>
                                  <Dt>レベル</Dt>
                                  <Dd>
                                    {recordDisplay.option(
                                      _.get(language, 'level'),
                                      languageLevels.map((t) => ({value: t.id, label: t.name}))
                                    )}
                                  </Dd>
                                </Dl>
                              )}
                            {!_.get(language, 'level') &&
                              _.get(language, 'qualification_name') &&
                              !_.get(language, 'score') && (
                                <Dl>
                                  <Dt>資格名</Dt>
                                  <Dd>{recordDisplay(_.get(language, 'qualification_name'))}</Dd>
                                </Dl>
                              )}
                            {!_.get(language, 'level') &&
                              !_.get(language, 'qualification_name') &&
                              !_.get(language, 'score') && (
                                <Dl>
                                  <Dt>レベル</Dt>
                                  <Dd>{'―'}</Dd>
                                </Dl>
                              )}
                          </SubPanel>
                        )
                    )}
                  {employee.employee_language && employee.employee_language.length > 4 && (
                    <ShowMoreBtn
                      isExpand={this.state.isExpand}
                      onToggleClick={() => {
                        this.setState({isExpand: !this.state.isExpand})
                      }}
                    />
                  )}
                </SubPanelarea>
              </Wrap>
            </Item>
          </Panel>
        )) ||
      null
    )
  }
}

export default connect((state) => ({
  employee: state.employees.current.data,
  languages: state.master.languages,
  languageLevels: state.master.languageLevels,
}))(BasicInfomation)
