import {actionCreators} from 'actions'
import {connectPager} from 'components/paginators'
import {connectPerPage} from 'components/PerPage'
import {makeListData} from 'components/ListData'
import {getQueryDiff} from 'offices/reducers'
import api from 'api'

const querySelector = (state) => state.offices.query
const setQuery = actionCreators.offices.setQuery

export const Pager = connectPager(querySelector, setQuery)
export const PerPage = connectPerPage(querySelector, setQuery)
export const ListData = makeListData({
  querySelector,
  setQuery,
  getQueryDiff,
  loadData({token, query, params}) {
    return actionCreators.fetchData(
      'offices_page',
      api.createWithAuth(token).offices.list({
        _page: query.page,
        limit: query.per_page,
        ...params,
      })
    )
  },
  destroy: actionCreators.fetchDestroy.bind(null, 'offices_page'),
})
