import {FC, ReactNode} from 'react'
import styles from './Panel.scss'

interface HeadProps {
  children: ReactNode
  className?: string
}

interface BodyProps {
  children: ReactNode
  className?: string
}

interface PanelProps {
  children: ReactNode
  className?: string
}

export const Panel: FC<PanelProps> = ({children, className}) => {
  const classNames = className ? `${styles.panel} ${className}` : styles.panel

  return <div className={classNames}>{children}</div>
}

export const Head: FC<HeadProps> = ({children, className}) => {
  const classNames = className ? `${styles.head} ${className}` : styles.head

  return <div className={classNames}>{children}</div>
}

export const Body: FC<BodyProps> = ({children, className}) => {
  const classNames = className ? `${styles.body} ${className}` : styles.body

  return <div className={classNames}>{children}</div>
}
