import {FC} from 'react'
import {FileUploadForm} from './parts/FileUploadForm'
import {CsvFileDescription} from '../import/parts/CsvFileDescription'
import styles from './Presenter.scss'

export const Presenter: FC = () => {
  return (
    <div>
      <div className="l-title-wrap u-pb30">
        <h1 className="m-title-main">グループ情報の更新</h1>
      </div>
      <FileUploadForm />

      <div className={`u-mt50 ${styles.warningWrap}`}>
        <p className="u-ta-l">
          <span className={styles.warningTitle}>【注意】</span>
          ①グループコードが一致するグループ情報を更新しますので
          <span className={styles.warning}>正しいグループコードをご記入ください</span>
          <br />
          ②グループの数がCSVデータの編集前より減る場合、差分は削除されません。不要なグループは設定画面より削除してください
          <br />
        </p>
      </div>

      <div className="u-mt100">
        <CsvFileDescription update />
      </div>
    </div>
  )
}
