import React from 'react'
import moment from 'moment'
import {DateField, Section, CheckboxField, RadioField, FileField} from 'jbc-front/components/Form'
import {
  reduxForm,
  formValueSelector,
  FormSection,
  autofill as autofillForm,
  isSubmitting as isSubmittingForm,
} from 'redux-form'
import {Address, AddressForeign} from 'FormFields'
import Button from 'jbc-front/components/Button'
import FormErrors, {onSubmitFail, LabelMapper} from 'jbc-front/components/FormErrors'
import {CommutingAllowanceInfomation} from 'employees/form/CommutingAllowanceInfomation'
import {ResidenceStatusOptions} from 'employees/form/Dependents'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {Edit} from 'jbc-front/components/icons'
import ActionButton from 'jbc-front/components/ActionButton'
import {bindActionCreators} from 'redux'
import _ from 'lodash'
import {addressNames} from 'employees/form/BasicInfomation'
import {toFormValues} from 'utils'
import {getCommutingExpenseValues} from 'employees/Form'
import styles from 'procedures/changeAddress/changeAddress.scss'
import EmployeeName from 'procedures/EmployeeName'
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
import ReviewComments from 'components/ReviewComments'
import {JAPAN_VALUE} from 'employees/form/common'

const formName = 'changeAddress'
const selector = formValueSelector(formName)
export const autofill = autofillForm.bind(null, formName)
export const isSubmitting = isSubmittingForm(formName)

export const makeInitialValues = (employee, healthInsuranceType) => {
  if (!employee || !employee.id) {
    return {
      commuting_expenses: {train: [{}], bus: [{}]},
    }
  }
  const values = {id: employee.id}
  values.procedure_data = toFormValues(_.pick(employee, addressNames))
  values.commuting_expenses = {train: [{}], bus: [{}]}
  const dependents = (employee.employee_dependents || []).map((dependent) =>
    toFormValues(
      _.pick(dependent, [
        ...addressNames,
        'last_name',
        'first_name',
        'relation_type',
        'residence_status',
        'address_foreign',
        'id',
      ])
    )
  )
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')
  values.spouse = spouse
    ? {
        id: spouse.id,
        _address_will_change: true,
        residence_status: 'same_address',
        procedure_data: _.pick(spouse, [...addressNames, 'residence_status', 'address_foreign']),
        house_number: '',
        building: '',
        address_kana: '',
      }
    : null
  values.other_dependents = dependents.filter((dependent) => dependent.relation_type !== 'spouse')
  if (healthInsuranceType === 'its') {
    values.other_dependents = values.other_dependents.map((dependent) => ({
      ..._.pick(dependent, ['first_name', 'last_name', 'id']),
      _address_will_change: true,
      residence_status: 'same_address',
      procedure_data: _.pick(dependent, [...addressNames, 'residence_status', 'address_foreign']),
    }))
    values._update_resident_card = true
  }
  values.house_number = ''
  values.building = ''
  values.address_kana = ''
  return values
}

const initialDependent = (dependent, changeAddressDepData) => {
  const procedureData = changeAddressDepData.find((depData) => depData.employee_dependent_id == dependent.id)
  if (procedureData) {
    return {
      ...dependent,
      procedure_data: procedureData,
      _address_will_change: true,
    }
  } else {
    return {
      ..._.pick(dependent, ['id', 'first_name', 'last_name']),
      residence_status: 'same_address',
      procedure_data: _.pick(dependent, [...addressNames, 'residence_status', 'address_foreign']),
      _address_will_change: false,
    }
  }
}

export const makeInitialValuesForUpdate = (procedureStatus, employee, healthInsuranceType) => {
  if (!employee || !procedureStatus) {
    return {
      commuting_expenses: {train: [{}], bus: [{}]},
    }
  }
  const changeAddressData = procedureStatus.procedure_change_address_datum || {}
  const changeAddressDepData = changeAddressData.procedure_change_address_dep_data || []
  const values = toFormValues(
    _.pick(employee, [...addressNames, 'id', 'commute_span_type', 'commute_type', 'residence_card'])
  )
  values.date_on = procedureStatus.date_on
  let commutingExpensesObject = employee.commuting_expenses
    ? _.groupBy(employee.commuting_expenses.map(toFormValues), 'commuting_expense_type')
    : {}
  if (commutingExpensesObject.car) {
    commutingExpensesObject.car = commutingExpensesObject.car[0]
  }
  if (!commutingExpensesObject.train) {
    commutingExpensesObject.train = [{}]
  }
  if (!commutingExpensesObject.bus) {
    commutingExpensesObject.bus = [{}]
  }
  values.commuting_expenses = commutingExpensesObject
  values.procedure_data = toFormValues(changeAddressData)
  const dependents = (employee.employee_dependents || []).map((dependent) =>
    toFormValues(
      _.pick(dependent, [
        ...addressNames,
        'last_name',
        'first_name',
        'relation_type',
        'residence_status',
        'address_foreign',
        'id',
      ])
    )
  )
  const spouse = dependents.find((dependent) => dependent.relation_type === 'spouse')
  const otherDependents = dependents.filter((dependent) => dependent.relation_type !== 'spouse')
  if (spouse) {
    values.spouse = initialDependent(spouse, changeAddressDepData)
  }
  if (healthInsuranceType === 'its') {
    values.other_dependents = otherDependents.map((dependent) => initialDependent(dependent, changeAddressDepData))
  } else {
    values.other_dependents = otherDependents
  }
  return values
}

const formatDependent = ({procedure_data: procedureData = {}, ...dependent} = {}, employeeProcedureData) => {
  if (!dependent._address_will_change) {
    if (procedureData.residence_status === 'same_address') {
      return [
        {
          ...dependent,
          residence_status: 'different_address',
          ..._.pick(employeeProcedureData, addressNames),
        },
        null,
      ]
    }
    return [_.pick(dependent, 'id'), null]
  }
  return [dependent, {...procedureData, employee_dependent_id: dependent.id}]
}

export const formatValues = (
  {
    commuting_expenses: commutingExpensesObject,
    date_on: dateOn,
    procedure_data: procedureData,
    _update_resident_card: updateResidentCard,
    spouse,
    other_dependents: otherDependents,
    ...employee
  },
  healthInsuranceType,
  nations
) => {
  if (employee.house_number === undefined) {
    employee.house_number = ''
  }
  if (employee.building === undefined) {
    employee.building = ''
  }
  if (employee.address_kana === undefined) {
    employee.address_kana = ''
  }
  const commutingExpenses = getCommutingExpenseValues(employee.commute_type, commutingExpensesObject)
  const residentCard = updateResidentCard ? _.pick(employee, addressNames) : null
  if (updateResidentCard) {
    procedureData.resident_card_updated_at = new Date()
  }
  const values = {
    employee,
    procedureData,
    commutingExpenses,
    residentCard,
    dateOn,
  }
  const japan = _.find(nations, (nation) => nation.value === JAPAN_VALUE)
  if (japan) {
    values.employee = {
      country: japan.id,
      ...values.employee,
    }
  }

  if (healthInsuranceType === 'its') {
    let dependentValues = otherDependents || []
    if (spouse && spouse.id) {
      dependentValues = [spouse, ...otherDependents]
    }
    const [dependents, dependentPrecedureData] = _.unzip(
      dependentValues.map((dependent) => formatDependent(dependent, procedureData))
    )
    return {
      ...values,
      dependents,
      dependentPrecedureData: dependentPrecedureData ? dependentPrecedureData.filter(_.identity) : [],
    }
  } else {
    if (spouse && spouse.id) {
      const [spouseData, spouseProcedureData] = formatDependent(spouse, procedureData)
      return {
        ...values,
        dependents: [spouseData, ...(otherDependents || [])],
        dependentPrecedureData: [spouseProcedureData].filter(_.identity),
      }
    } else {
      return {
        ...values,
        dependents: otherDependents || [],
        dependentPrecedureData: [],
      }
    }
  }
}

const DependentAddress = ({dependent, autofill, showOldAddress = false}) => (
  <div>
    {showOldAddress && (
      <Section title="変更前住所">
        {dependent._edit_procedure_data ? (
          <FormSection name="procedure_data">
            <RadioField name="residence_status" label="同居・別居" options={ResidenceStatusOptions} required />
            {dependent.procedure_data.residence_status === 'different_address' && <Address required />}
            {dependent.procedure_data.residence_status === 'different_and_foreign_address' && (
              <AddressForeign required />
            )}
          </FormSection>
        ) : (
          <div>
            {(dependent.procedure_data.residence_status === 'same_address' && '同居') ||
              recordDisplay.address(dependent.procedure_data)}
            <ActionButton
              icon={<Edit />}
              className="u-float-right"
              onClick={() => autofill('_edit_procedure_data', true)}
            />
          </div>
        )}
      </Section>
    )}
    <Section title="新しい住所">
      <RadioField name="residence_status" label="同居・別居" options={ResidenceStatusOptions} required />
      {dependent.residence_status === 'different_address' && <Address required />}
      {dependent.residence_status === 'different_and_foreign_address' && <AddressForeign required />}
      {<DateField name="procedure_data.change_on" label="住所変更年月日" />}
    </Section>
  </div>
)

const Form = ({
  submitting,
  handleSubmit,
  oldAddress = {},
  commuteType,
  carPaymentDurationUnit,
  editOldAddress,
  residentCardUpdatedAt,
  autofill,
  otherDependentValues = [],
  spouse,
  dateOn,
  healthInsuranceType,
  otherButtons,
  employee,
  comments,
  submitText = '次へ',
}) => (
  <form onSubmit={handleSubmit}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">住所情報</h1>
      <EmployeeName employee={employee} />
    </div>
    <div className="basic_information">
      <FormErrors />
      <ReviewComments comments={comments} />
      <Section title="引越の日">
        <DateField
          name="date_on"
          label="引越の日"
          required
          onChange={(e, value) => {
            if (spouse) {
              autofill('spouse.procedure_data.change_on', value)
            }
            if (otherDependentValues) {
              otherDependentValues.forEach((dependent, index) =>
                autofill(`other_dependents[${index}].procedure_data.change_on`, value)
              )
            }
          }}
        />
      </Section>
      <Section title="変更前住所">
        {editOldAddress ? (
          <FormSection name="procedure_data">
            <LabelMapper name="procedure_data" label="変更前住所の" />
            <Address />
          </FormSection>
        ) : (
          <div className={styles.old}>
            <div>{recordDisplay.address(oldAddress)}</div>
            <div className={styles.edit}>
              <ActionButton icon={<Edit />} onClick={() => autofill('_edit_procedure_data', true)} />
            </div>
          </div>
        )}
      </Section>
      <Section title="新しい住所">
        <Address required />
        <FileField name="residence_card" label="住所確認書類（例：住民票等)" />
        <CheckboxField
          name="_update_resident_card"
          disabled={healthInsuranceType === 'its'}
          label={
            residentCardUpdatedAt
              ? `住民票住所も更新する（${moment(residentCardUpdatedAt).format('YYYY/MM/DD HH:mm')} 更新済）`
              : '住民票住所も更新する'
          }
        />
        {healthInsuranceType === 'its' && (
          <span className={styles.warningText}>住民票住所の入力が必須の帳票のため、住民票住所も更新されます</span>
        )}
      </Section>
      <CommutingAllowanceInfomation
        commuteType={commuteType}
        carPaymentDurationUnit={carPaymentDurationUnit}
        selector={selector}
      />
      {spouse && (
        <Section title="配偶者の住所">
          <LabelMapper name="spouse" label="配偶者の" />
          <FormSection name="spouse">
            <CheckboxField
              name="_address_will_change"
              label="被扶養配偶者の住所も変更する"
              onChange={(e, value) => autofill('spouse.procedure_data.change_on', value ? dateOn : null)}
            />
            {spouse._address_will_change && (
              <DependentAddress
                dependent={spouse}
                autofill={(field, value) => autofill(`spouse.${field}`, value)}
                showOldAddress
              />
            )}
          </FormSection>
        </Section>
      )}
      {!_.isEmpty(otherDependentValues) &&
        otherDependentValues.map((dependent, index) => (
          <Section title={`${dependent.last_name} ${dependent.first_name}さんの住所`} key={dependent.id}>
            <LabelMapper name="other_dependents" label="被扶養者の" />
            <FormSection name={`other_dependents[${index}]`}>
              {(healthInsuranceType === 'its' && (
                <div>
                  <CheckboxField
                    name="_address_will_change"
                    label="被扶養の住所も変更する"
                    onChange={(e, value) =>
                      autofill(`other_dependents[${index}].procedure_data.change_on`, value ? dateOn : null)
                    }
                  />
                  {dependent._address_will_change && (
                    <DependentAddress
                      dependent={dependent}
                      autofill={(field, value) => autofill(`other_dependents[${index}].${field}`, value)}
                    />
                  )}
                </div>
              )) || (
                <div>
                  <RadioField name="residence_status" label="同居・別居" options={ResidenceStatusOptions} required />
                  {dependent.residence_status === 'different_address' && <Address required />}
                  {dependent.residence_status === 'different_and_foreign_address' && <AddressForeign required />}
                </div>
              )}
            </FormSection>
          </Section>
        ))}
      <div className="u-ta-c u-mt30">
        {otherButtons}
        <AsyncTaskConsumer>
          {({taskRunningProps}) => (
            <Button primary onClick={handleSubmit} disabled={submitting} {...taskRunningProps}>
              {submitText}
            </Button>
          )}
        </AsyncTaskConsumer>
      </div>
    </div>
  </form>
)

export default connect(
  (state) => ({
    otherDependentValues: selector(state, 'other_dependents'),
    editOldAddress: selector(state, '_edit_procedure_data'),
    spouse: selector(state, 'spouse'),
    oldAddress: selector(state, 'procedure_data'),
    dateOn: selector(state, 'date_on'),
    commuteType: selector(state, 'commute_type'),
    carPaymentDurationUnit: selector(state, 'commuting_expenses.car.payment_duration_unit'),
  }),
  bindActionCreators.bind(null, {autofill})
)(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })(Form)
)
