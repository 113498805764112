import {FC, useEffect} from 'react'
import styles from 'employees/form/BasicInfomation.scss'

interface PreviewIconProps {
  value: {url?: string; preview?: string}
}

export const PreviewIcon: FC<PreviewIconProps> = ({value}) => {
  useEffect(() => {
    const preview = value.preview
    if (preview) {
      return () => window.URL.revokeObjectURL(preview)
    }
    return
  }, [value.preview])

  if (!value.url && !value.preview) {
    return null
  }
  return <div style={{backgroundImage: `url("${value.url || value.preview}")`}} className={styles.preview} />
}
