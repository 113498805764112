import {actionTypes} from 'actions'
import {
  removeNodeAtPath,
  changeNodeAtPath,
  defaultGetNodeKey,
  addNodeUnderParent,
  map as _mapTree,
} from 'react-sortable-tree'
import _ from 'lodash'

const mapTree = (treeData, callback) =>
  _mapTree({treeData, getNodeKey: defaultGetNodeKey, ignoreCollapsed: false, callback})

const initialState = {initialTree: [], tree: [], editing: false}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SETTINGS.GROUPS.INITIALIZE_TREE:
      return {...state, initialTree: action.payload, tree: action.payload}
    case actionTypes.SETTINGS.GROUPS.SET_TREE:
      return {...state, tree: action.payload}
    case actionTypes.SETTINGS.GROUPS.ADD_NODE: {
      const newNode = {
        group: {name: '', group_code: null, employees_groups_count: 0},
        expanded: true,
        sequenceNumber: null,
        initialGroupName: null,
        _key: _.uniqueId(),
        canDelete: true,
      }
      return {...state, tree: [...state.tree, newNode]}
    }
    case actionTypes.SETTINGS.GROUPS.UPDATE_NODE: {
      const [path, value] = action.payload
      return {
        ...state,
        tree: changeNodeAtPath({
          treeData: state.tree,
          path,
          getNodeKey: defaultGetNodeKey,
          newNode: ({node}) => ({...node, group: {...node.group, ...value}}),
        }),
      }
    }
    case actionTypes.SETTINGS.GROUPS.DELETE_NODE:
      return {
        ...state,
        tree: removeNodeAtPath({
          treeData: state.tree,
          path: action.payload,
          getNodeKey: defaultGetNodeKey,
        }),
      }
    case actionTypes.SETTINGS.GROUPS.START_EDIT:
      return {...state, editing: true}
    case actionTypes.SETTINGS.GROUPS.END_EDIT:
      return {...state, editing: false}
    case actionTypes.SETTINGS.GROUPS.INSERT_NODE: {
      const newNode = {
        group: {name: '', group_code: null, employees_groups_count: 0},
        expanded: true,
        sequenceNumber: null,
        initialGroupName: null,
        _key: _.uniqueId(),
        canDelete: true,
      }
      return {
        ...state,
        tree: addNodeUnderParent({
          treeData: state.tree,
          newNode,
          parentKey: _.last(action.payload),
          expandParent: true,
          getNodeKey: defaultGetNodeKey,
        }).treeData,
      }
    }
    case actionTypes.SETTINGS.GROUPS.EXPAND_ALL:
      return {...state, tree: mapTree(state.tree, ({node}) => ({...node, expanded: true}))}
    case actionTypes.SETTINGS.GROUPS.COLLAPSE_ALL:
      return {...state, tree: mapTree(state.tree, ({node}) => ({...node, expanded: false}))}
    case actionTypes.SETTINGS.GROUPS.DESTROY:
      return initialState
    default:
      break
  }
  return state
}

export default reducer
