import React, {Component} from 'react'
import {connect} from 'react-redux'
import {autofill} from 'redux-form'
import _ from 'lodash'
import {MultiTagFilteringFieldRow} from 'jbc-front/components/SearchForm'
import {formName, selector} from 'employees/list/utils'
import {fetchSelector, actionCreators} from 'actions'
import api from 'api'
import {fromOpts, withNull, idToStr} from 'employees/list/addedFields/utils'
import {nationalTypes} from 'FormFields'

class NationalTypes extends Component {
  static defaultProps = {
    nations: [],
    visaTypes: [],
  }

  componentDidMount() {
    const {dispatch, token} = this.props
    const authedAPi = api.createWithAuth(token)
    dispatch(
      actionCreators.fetchData(
        'national_type_selections',
        Promise.all([authedAPi.employees.selections.visaTypes(), authedAPi.employees.selections.nations()]).then(
          ([visaTypes, nations]) =>
            _.mapValues(
              {
                visaTypes,
                nations,
              },
              (res) => res.data.map(idToStr)
            )
        )
      )
    )
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('national_type_selections'))
  }

  autofillForeigner = (e, value, prev) => {
    const {dispatch, nationalTypes} = this.props
    if (!_.isEmpty(value) && _.isEmpty(prev) && (!nationalTypes || !nationalTypes.includes('foreigner'))) {
      dispatch(autofill(formName, 'national_types', nationalTypes ? [...nationalTypes, 'foreigner'] : ['foreigner']))
    }
  }

  clearOthers = (e, value, prev) => {
    const {dispatch} = this.props
    if (!value.includes('foreigner') && prev && prev.includes('foreigner')) {
      dispatch(autofill(formName, 'visa_type_ids', []))
      dispatch(autofill(formName, 'nation_ids', []))
    }
  }

  render() {
    const {nations, visaTypes} = this.props
    return (
      <div style={{width: '100%'}}>
        <MultiTagFilteringFieldRow
          header="外国人区分"
          fieldName="national_types"
          list={withNull(fromOpts(nationalTypes))}
          onChange={this.clearOthers}
        />
        <MultiTagFilteringFieldRow
          header="国籍"
          fieldName="nation_ids"
          list={withNull(nations)}
          onChange={this.autofillForeigner}
        />
        <MultiTagFilteringFieldRow
          header="在留資格"
          fieldName="visa_type_ids"
          list={withNull(visaTypes)}
          onChange={this.autofillForeigner}
        />
      </div>
    )
  }
}
export default connect((state) => ({
  token: state.auth.token,
  nations: fetchSelector(state, 'national_type_selections').nations,
  visaTypes: fetchSelector(state, 'national_type_selections').visaTypes,
  nationalTypes: selector(state, 'national_types'),
}))(NationalTypes)
