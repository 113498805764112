import type {RouteParams} from 'types/routes'
import {WITHOUT_AUTH_PATHS} from 'consts/paths'
import {Index} from 'pages/withoutAuth/expireToken/'

export const ExprieTokenRoutes: RouteParams[] = [
  {
    key: 'expire_token',
    path: WITHOUT_AUTH_PATHS.EXPIRE_TOKENS.INDEX,
    component: Index,
    exact: true,
  },
]
