import React, {useContext} from 'react'
import _ from 'lodash'
import {get, some} from 'lodash/fp'
import {FormSection as FormSectionOrig} from 'redux-form'
import {
  RadioField as _RadioField,
  TextField as _TextField,
  DateField as _DateField,
  FileField as _FileField,
  CheckboxField as _CheckboxField,
  SelectField as _SelectField,
  TextFieldWithKana as _TextFieldWithKana,
  TextAreaField as _TextAreaField,
  NumberFields as NumberFieldsOrig,
} from 'jbc-front/components/Form'

const Context = React.createContext({
  diff: {},
})

export const DiffProvider = ({diff, fixed, children}) => (
  <Context.Provider value={{diff, fixed}}>{children}</Context.Provider>
)

const wrapField = (Field) => (props) => {
  const {diff, fixed} = useDiff()
  return <Field {...props} changed={_.get(diff, props.name)} disabled={props.disabled || fixed} />
}

export const FileField = (props) => {
  const Field = _FileField
  const {diff, fixed} = useDiff()
  const changed = diff |> get(props.name) |> (props.multiple ? some('filename') : get('filename'))
  return <Field {...props} changed={changed} disabled={props.disabled || fixed} />
}

export const useDiff = () => useContext(Context)

export const [RadioField, TextField, DateField, CheckboxField, SelectField, TextFieldWithKana, TextAreaField] = [
  _RadioField,
  _TextField,
  _DateField,
  _CheckboxField,
  _SelectField,
  _TextFieldWithKana,
  _TextAreaField,
].map(wrapField)

export const FormSection = ({children, ...props}) => {
  const {diff, fixed} = useDiff()
  return (
    <FormSectionOrig {...props}>
      <DiffProvider diff={_.get(diff, props.name)} fixed={fixed}>
        {children}
      </DiffProvider>
    </FormSectionOrig>
  )
}
export const NumberFields = ({seperateChar = '_', ...props}) => {
  const {diff, fixed} = useDiff()
  return (
    <NumberFieldsOrig
      {...props}
      seperateChar={seperateChar}
      changed={_.range(props.lengths.length).some((idx) => _.get(diff, `${props.prefix}${seperateChar}${idx}`))}
      disabled={props.disabled || fixed}
    />
  )
}
