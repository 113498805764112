import React from 'react'
import {useSelector} from 'react-redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import Select from 'react-select'
import {gql} from '@apollo/client'
import _ from 'lodash'
import {arrowRenderer, Error, Label, TextAreaField, TextField} from 'jbc-front/components/Form'
import FormErrors, {LabelMapper, onSubmitFail} from 'jbc-front/components/FormErrors'
import Button from 'jbc-front/components/Button'
import {maxLength, required} from 'validators'
import {useQuery} from 'components/Graphql'
import LoadingPage from 'components/LoadingPage'
import {addChildrenWhenParentClicked} from 'settings/roles/employeeList/AdditionalSearchFields'
import styles from 'settings/roles/Form.scss'
import {useHasBranchOffice} from 'hooks/useHasBranchOffice'

export const OPTIONS = gql`
  query options {
    client {
      id
      positions {
        id
        name
      }
      employmentTypes {
        id
        name
      }
      occupations {
        id
        name
      }
      offices {
        id
        name
        isMainOffice
      }
      groups {
        id
        name
        parentGroupId
      }
      customEmployeeFieldGroups(withFields: true) {
        id
        label
        childItems
      }
    }
    talentDisplaySettingCategories {
      id
      label
      description
    }
  }
`

const formName = 'RoleForm'
const selector = formValueSelector(formName)
const useFormValue = (...args) => useSelector((state) => selector(state, ...args))

const notificationPermissionOptions = [
  {value: 'can_send', label: '配信可'},
  {value: 'only_receive', label: '受信のみ'},
]

const employmentStatusOptions = [
  {value: 'employed', label: '在職中'},
  {value: 'vacation', label: '休職中'},
  {value: 'unemployed', label: '退職済'},
  {value: 'join', label: '内定'},
]

const groupSearchTypeOptions = [
  {value: 'get_all', label: '全従業員'},
  {value: 'get_self', label: '同じグループに所属する従業員'},
  {value: 'get_self_and_lower', label: '同じグループとその配下のグループに所属する従業員'},
  {value: 'get_lower', label: '本人＋配下のグループに所属する従業員'},
  {value: 'get_select', label: '指定したグループの従業員'},
]

const officeSearchTypeOptions = [
  {value: 'get_all', label: '全従業員'},
  {value: 'get_self', label: '同じ適用事業所に所属する従業員'},
  {value: 'get_select', label: '指定した適用事業所の従業員'},
]

const Section = ({title, children}) => (
  <section className={styles.box}>
    {title && <div className={styles.boxHead}>{title}</div>}
    <div className={styles.boxMain}>{children}</div>
  </section>
)

const renderRadioField = ({label, input, options, disabled, radioNote = null}) => (
  <div className={styles.radioField}>
    <div className={styles.radioRow}>
      <div className={styles.label}>{label}</div>
      <div>
        {options.map(({label, value}) => {
          return (
            <div style={{display: 'inline-block'}} className={'input-label'} key={value}>
              <label>
                <input
                  {..._.omit(input, 'value')}
                  type="radio"
                  value={value}
                  checked={value === input.value}
                  disabled={disabled}
                  className={'m-radio-input'}
                />
                <span className={'m-radio-parts'}>{label}</span>
              </label>
            </div>
          )
        })}
      </div>
    </div>
    {radioNote && <div className={styles.note}>{radioNote}</div>}
  </div>
)

const renderCheckboxRow = ({label, input, options}) => (
  <div className={styles.checkboxField}>
    <div className={styles.checkboxRow}>
      <div className={styles.label}>{label}</div>
      <div className={styles.checkboxBlock}>
        {options.map(({label, value}) => {
          return (
            <div className={`input-label ${styles.checkbox}`} key={value}>
              <label>
                <input
                  type="checkbox"
                  value={value}
                  checked={input.value.includes(value)}
                  className={'m-checkbox-input'}
                  onClick={() => {
                    input.onChange(
                      input.value.includes(value) ? input.value.filter((e) => e != value) : [...input.value, value]
                    )
                  }}
                />
                <span className={'m-checkbox-parts'}>{label}</span>
              </label>
            </div>
          )
        })}
      </div>
    </div>
  </div>
)

const renderCheckboxColumn = ({options, input, withBorder = false}) => (
  <div className={styles.checkboxField}>
    <div className={`${withBorder ? styles.checkboxColumnWithBorder : styles.CheckboxColumn}`}>
      {options.map(({label, value, note}) => {
        return (
          <div className={`input-label ${styles.checkboxLine}`} key={value}>
            <label>
              <input
                type="checkbox"
                value={value}
                checked={input.value.includes(value)}
                className={'m-checkbox-input'}
                onClick={() => {
                  input.onChange(
                    input.value.includes(value) ? input.value.filter((e) => e != value) : [...input.value, value]
                  )
                }}
              />
              <span className={`m-checkbox-parts ${styles.checkboxLabel}`}>{label}</span>
              <span className={styles.checkboxNote}>{note}</span>
            </label>
          </div>
        )
      })}
    </div>
  </div>
)

const renderSearchTypeSelectField = ({input, options, label, meta}) => (
  <div
    className={
      input.value === 'get_select' ? `${styles.searchTypeSelectField}` : `${styles.searchTypeSelectFieldExpand}}`
    }
  >
    <Label text={label} required={required} />
    <Select
      input
      options={options}
      closeOnSelect={true}
      clearable={false}
      simpleValue
      arrowRenderer={arrowRenderer}
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      onBlur={() => input.onBlur(input.value)}
    />
    <Error meta={meta} label={label} />
    <LabelMapper name={input.name} label={label} />
  </div>
)

const renderIdsSelectField = ({input, options, label, placeholder, meta}) => {
  const handleChange = (newValue) => {
    if (input.name === 'offices') {
      input.onChange(newValue)
    } else {
      input.onChange(addChildrenWhenParentClicked(options, input.value, newValue))
    }
  }

  return (
    <>
      <Select
        input
        multi
        options={options}
        closeOnSelect={false}
        clearableValue={false}
        clearable={false}
        simpleValue
        arrowRenderer={arrowRenderer}
        placeholder={placeholder}
        name={input.name}
        value={input.value}
        onChange={(newValue) => handleChange(newValue)}
        onBlur={() => input.onBlur(input.value)}
      />
      <Error meta={meta} label={label} />
      <LabelMapper name={input.name} label={label} />
    </>
  )
}

const createOptions = (elements) => {
  return elements.map((element) => {
    return {value: element.id, label: element.name}
  })
}

const createOptionsWithNull = (elements) => {
  const options = elements.map((element) => {
    return {value: element.id, label: element.name}
  })
  return [...options, {value: '0', label: '未入力'}]
}

const createGroupOptions = (groups) =>
  groups &&
  groups.map((group) => ({
    value: `${group.id}`,
    label: groupFullName(group, _.keyBy(groups, 'id')),
    parent_value: `${group.parentGroupId}`,
  }))

const groupFullName = ({name, parentGroupId}, groups) =>
  parentGroupId && groups[parentGroupId] ? `${groupFullName(groups[parentGroupId], groups)}->${name}` : name

export const createRoleValue = (value) => {
  if (value) {
    const searchEmploymentStatus = _.find(value?.searchTargets || [], {targetType: 'employment_status'})
    const searchPosition = _.find(value?.searchTargets || [], {targetType: 'position'})
    const searchEmploymentType = _.find(value?.searchTargets || [], {targetType: 'employment_type'})
    const searchOccupation = _.find(value?.searchTargets || [], {targetType: 'occupation'})
    const searchOffice = _.find(value?.searchTargets || [], {targetType: 'office'})
    const searchGroup = _.find(value?.searchTargets || [], {targetType: 'group'})
    return {
      name: value.name,
      description: value.description,
      notificationPermission: value.notificationPermission,
      employmentStatuses: _.map(searchEmploymentStatus.talentSearchSettings, 'employmentStatus'),
      positionIds: _.map(searchPosition.talentSearchSettings, (setting) => setting.positionId || '0'),
      employmentTypeIds: _.map(searchEmploymentType.talentSearchSettings, (setting) => setting.employmentTypeId || '0'),
      occupationIds: _.map(searchOccupation.talentSearchSettings, (setting) => setting.occupationId || '0'),
      officeSearchType: searchOffice.targetSearchType,
      officeIds:
        searchOffice.targetSearchType === 'get_select'
          ? _.map(searchOffice.talentSearchSettings, 'officeId').join(',')
          : '',
      groupSearchType: searchGroup.targetSearchType,
      groupIds:
        searchGroup.targetSearchType === 'get_select'
          ? _.map(searchGroup.talentSearchSettings, 'groupId').join(',')
          : '',
      defaultCategoryIds: _.map(value.talentDisplaySettings, 'talentDisplaySettingCategoryId'),
      customCategoryIds: _.map(value.customEmployeeFieldGroupPermissions, 'customEmployeeFieldGroupId'),
    }
  }
  return null
}

const notEmpty = (value) => (_.isEmpty(value) ? 'を入力してください' : undefined)

const Form =
  (({options, handleSubmit, submitting}) => {
    const officeSearchType = useFormValue('officeSearchType')
    const groupSearchType = useFormValue('groupSearchType')
    const groupOptions = createGroupOptions(options.client.groups)
    const hasBranchOffice = useHasBranchOffice()
    const defaultCategoryOptions = options.talentDisplaySettingCategories.map((category) => {
      return {value: category.id, label: category.label, note: category.description}
    })
    const customCategoryOptions = options.client.customEmployeeFieldGroups.map((category) => {
      return {value: category.id, label: category.label, note: category.childItems}
    })
    return (
      <div>
        <form>
          <FormErrors />
          <Section title="権限情報">
            <TextField name="name" label="権限名" validate={[maxLength(40)]} required />
            <TextAreaField name="description" label="権限説明" rows={4} validate={[maxLength(255)]} />
            <Field
              name="notificationPermission"
              component={renderRadioField}
              options={notificationPermissionOptions}
              label="従業員通知："
              radioNote="従業員通知の作成権限を付与できます。配信権限がない場合は受信のみとなります。"
              validate={[required]}
            />
          </Section>
          <Section title="権限設定">
            <Section title="表示する従業員">
              <Field
                name="employmentStatuses"
                component={renderCheckboxRow}
                label="在職状況"
                options={employmentStatusOptions}
              />
              <Field
                name="positionIds"
                component={renderCheckboxRow}
                label="役職"
                options={createOptionsWithNull(options.client.positions)}
              />
              <Field
                name="employmentTypeIds"
                component={renderCheckboxRow}
                label="雇用形態"
                options={createOptionsWithNull(options.client.employmentTypes)}
              />
              <Field
                name="occupationIds"
                component={renderCheckboxRow}
                label="職種"
                options={createOptionsWithNull(options.client.occupations)}
              />
              {hasBranchOffice && (
                <>
                  <Field
                    name="officeSearchType"
                    component={renderSearchTypeSelectField}
                    options={officeSearchTypeOptions}
                    label="適用事業所指定"
                    validate={[required]}
                  />
                  {officeSearchType === 'get_select' && (
                    <Field
                      name="officeIds"
                      component={renderIdsSelectField}
                      options={createOptions(options.client.offices)}
                      label="適用事業所"
                      placeholder="適用事業所選択"
                      validate={[required, notEmpty]}
                    />
                  )}
                  <p className={styles.selectNote}>指定した適用事業所の従業員情報に適用されます</p>
                </>
              )}
              <Field
                name="groupSearchType"
                component={renderSearchTypeSelectField}
                options={groupSearchTypeOptions}
                label="グループ指定"
                validate={[required]}
              />
              {groupSearchType === 'get_select' && (
                <Field
                  name="groupIds"
                  component={renderIdsSelectField}
                  options={groupOptions}
                  label="グループ"
                  placeholder="グループ選択"
                  validate={[required, notEmpty]}
                />
              )}
              <p className={styles.selectNote}>指定したグループの従業員情報に適用されます</p>
            </Section>
            <Section title="表示する項目">
              <Field
                name="defaultCategoryIds"
                component={renderCheckboxColumn}
                options={defaultCategoryOptions}
                withBorder={true}
              />
              <Field name="customCategoryIds" component={renderCheckboxColumn} options={customCategoryOptions} />
            </Section>
          </Section>
        </form>
        <div className={styles.sendButton}>
          <Button className={styles.saveButton} onClick={handleSubmit} disabled={submitting} primary>
            保存
          </Button>
        </div>
      </div>
    )
  })
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })

export default ({initialValues, ...rest}) => {
  const {data, loading, error} = useQuery(OPTIONS, {fetchPolicy: 'network-only'})
  if (loading) {
    return <LoadingPage />
  }
  if (error) {
    return <div />
  }

  if (initialValues) {
    return <Form {...rest} options={data} initialValues={initialValues} />
  } else {
    return (
      <Form
        {...rest}
        options={data}
        initialValues={{
          name: null,
          description: null,
          notificationPermission: 'can_send',
          employmentStatuses: ['employed'],
          positionIds: [..._.map(data.client.positions, 'id'), '0'],
          employmentTypeIds: [..._.map(data.client.employmentTypes, 'id'), '0'],
          occupationIds: [..._.map(data.client.occupations, 'id'), '0'],
          officeSearchType: 'get_all',
          officeIds: '',
          groupSearchType: 'get_all',
          groupIds: '',
          defaultCategoryIds: _.map(data.talentDisplaySettingCategories, 'id').filter((categoryId) => categoryId != 11),
          customCategoryIds: [],
        }}
      />
    )
  }
}
