import React, {Component} from 'react'
import {connect} from 'react-redux'
import {actionCreators, fetchSelector} from 'actions'
import api from 'api'
import {Redirect} from 'react-router'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import _ from 'lodash'
import {formMap} from 'procedureStatuses/ReportEdit'
import {parse} from 'query-string'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class InputDependents extends Component {
  static defaultProps = {
    tmpData: {},
  }

  constructor(props) {
    super(props)
    this.state = {
      querying: true,
      valid: null,
      error: false,
      formIndex: -1,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      token,
      loadProcedureStatus,
      loadTmpData,
      loadComments,
    } = this.props
    loadProcedureStatus(id, token)
    loadTmpData(id, token)
    loadComments(id, token)
  }

  componentDidUpdate() {
    const {
      location: {search},
    } = this.props
    const {dependent_index} = parse(search)
    const dependentIndex = dependent_index | 0
    if (+dependentIndex !== this.state.formIndex) {
      this.setState({formIndex: dependentIndex})
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  getDependents() {
    const {tmpData, procedureStatus} = this.props
    const procedureType = procedureStatus.procedure_type
    if (procedureType === 'enroll') {
      return tmpData.application_enroll.employee.employee_dependents
    }
    return tmpData.application_procedure_add_dependents.employee_dependents
  }

  handleFormSubmit = (values, comment) => {
    const {saveAndNext, location, token, procedureStatus} = this.props
    const dependentIndex = +parse(location.search).dependent_index
    const dependents = this.getDependents()
    const dependent = dependents[dependentIndex]
    const isEmployeeInput = location.pathname.includes('/mypage/')

    return saveAndNext({
      procedureStatus,
      token,
      values,
      comment: comment,
      dependentIndex,
      isFinal: dependentIndex + 1 >= dependents.length,
      dependent,
      reportType: 'R150',
      isEmployeeInput,
    })
  }

  render() {
    const {
      tmpData,
      loading,
      location: {search},
      match: {
        params: {id},
      },
      procedureStatus,
      comments,
    } = this.props
    const {dependent_index} = parse(search)
    const dependentIndex = dependent_index | 0
    if (dependentIndex !== this.state.formIndex) return null
    if (loading || (!tmpData.employee && !tmpData.application_procedure_add_dependents)) {
      return null
    }
    const dependents = this.getDependents()
    const procedureType = procedureStatus.procedure_type
    if (_.isEmpty(dependents)) {
      return <Redirect to={`/${procedureType}/employee_input/${id}`} />
    }
    if (dependentIndex > dependents.length || dependentIndex < 0) {
      return <Redirect to={`/${procedureType}/employee_input_dependents/${id}?dependent_index=0`} />
    }
    const dependent = dependents[dependentIndex]
    const reportType = 'R150'
    const formName = 'R150Report'
    const Report = formMap[reportType]
    const isFinal = dependentIndex + 1 >= dependents.length
    return (
      <CommentModal
        formName={formName}
        procedureType="changeDependents"
        onSubmit={({values, comment}) => {
          this.handleFormSubmit(values, comment)
        }}
        comments={comments || {}}
      >
        {({showModal}) => (
          <Report
            key={dependentIndex}
            onSubmit={isFinal ? showModal : this.handleFormSubmit}
            submitText={isFinal ? '完了' : '次へ'}
            skipLoad
            name={`${dependent.last_name} ${dependent.first_name}`}
            relation_type={dependent.relation_type}
            relation_other={dependent.relation_other}
            comments={comments || {}}
            initialValues={_.get(
              tmpData,
              `application_procedure_${procedureType}.report_params_sets.${
                dependent._id
              }.${reportType.toLowerCase()}_param_set`
            )}
          />
        )}
      </CommentModal>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    tmpData: state.procedureStatuses.tmpData.data,
    comments: fetchSelector(state, 'procedure_comments').data,
    procedureStatus: state.procedureStatuses.current.data,
    loading: state.procedureStatuses.current.loading || state.procedureStatuses.tmpData.loading,
  }),
  (dispatch) => ({
    loadProcedureStatus(id, token) {
      dispatch(actionCreators.procedureStatuses.current.fetchData(api.createWithAuth(token).procedureStatuses.get(id)))
    },
    destroy() {
      dispatch(actionCreators.procedureStatuses.current.destroy())
      dispatch(actionCreators.procedureStatuses.tmpData.destroy())
    },
    loadTmpData(procedureStatusId, token) {
      dispatch(
        actionCreators.procedureStatuses.tmpData.fetchData(
          api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(procedureStatusId, {type: 'application'})
        )
      )
    },
    loadComments: (procedureStatusId, token) => {
      dispatch(
        actionCreators.fetchData(
          'procedure_comments',
          api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
        )
      )
    },
    async saveAndNext({
      procedureStatus,
      token,
      values,
      comment,
      dependent,
      dependentIndex,
      isFinal,
      reportType,
      isEmployeeInput,
    }) {
      try {
        const authedApi = api.createWithAuth(token)
        await authedApi.procedureStatuses.tmpData.updateJson(
          procedureStatus.id,
          'application',
          'procedure_add_dependents',
          (data) => ({
            ...data,
            report_params_sets: {
              ...data.report_params_sets,
              [dependent._id]: {
                ...(data.report_params_sets[dependent._id] || {}),
                [`${reportType.toLowerCase()}_param_set`]: values,
              },
            },
          })
        )
        const procedureType = procedureStatus.procedure_type
        const path = `/${procedureType}/employee_input_dependents/${procedureStatus.id}?dependent_index=${
          dependentIndex + 1
        }`
        if (isFinal) {
          await authedApi.procedureStatuses.apply(procedureStatus.id, comment)
          dispatch(push('/dashboard'))
          dispatch(notifySuccess('提出しました'))
        } else {
          dispatch(push(procedureType === 'add_dependents' && isEmployeeInput ? '/mypage' + path : path))
          dispatch(notifySuccess('保存しました'))
          window.scrollTo(0, 0)
        }
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(InputDependents)
