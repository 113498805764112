import React from 'react'
import {Card, Container} from 'components/SelectCard'
import {FileImport, Edit} from 'jbc-front/components/icons'
import {connect} from 'react-redux'

const ChooseAddMethod = ({planType}) => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">従業員登録</h1>
      <p className="m-title-main-note">登録する方法を選んでください</p>
    </div>
    <Container>
      <Card icon={Edit} to="/employees/create">
        直接入力する
      </Card>
      {planType !== 'free_plan' && [
        <Card icon={FileImport} to="/employees/import" key="import">
          ファイルからインポート
        </Card>,
      ]}
    </Container>
  </div>
)

export default connect((state) => ({
  planType: state.client.current.plan_type,
}))(ChooseAddMethod)
