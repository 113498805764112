import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory, getCategoryIconName, getCategoryIconColor} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {
  Wrap,
  TitleArea,
  Title,
  Timelinedate,
  Timelinecontent,
  Ul,
  Li,
  Dl,
  Trhalf,
  Trfull,
  Dd,
  Dt,
  SubPanelarea,
} from 'tm/employees/Parts'
import * as icons from 'jbc-front/components/icons'
import {AffiliationHistory} from 'jbc-front/components/icons'
import ShowMoreBtn from './ShowMoreBtn'
import {wrapArrowText} from './Search'

class EmploymentInfomation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }
  render() {
    const {employee, userDisplaySetting} = this.props
    const icon_name = getCategoryIconName(userDisplaySetting, 'affiliation_history')
    const color =
      (!_.isNull(getCategoryIconColor(userDisplaySetting, 'affiliation_history')) &&
        getCategoryIconColor(userDisplaySetting, 'affiliation_history')) ||
      '#3498db'
    const IconName = icons[icon_name]
    const style = {color: color}
    return (
      (showCategory(userDisplaySetting, 'affiliation_history') &&
        employee.personnel_history &&
        employee.personnel_history.length > 0 && (
          <Panel>
            <Item>
              <Wrap>
                <TitleArea>
                  <Title>
                    {(!_.isNull(icon_name) && <IconName size={30} color={color} />) || (
                      <AffiliationHistory size={30} color={color} />
                    )}
                    <div style={style}>社内経歴</div>
                  </Title>
                </TitleArea>
                <SubPanelarea>
                  <Ul>
                    {employee.personnel_history &&
                      (this.state.isExpand ? employee.personnel_history : employee.personnel_history.slice(0, 2)).map(
                        (history, index) =>
                          history && (
                            <Li key={index}>
                              <Timelinedate>
                                {history && recordDisplay.date(_.get(history, 'date_on'), 'seireki')} -{' '}
                                {(_.get(history, 'end_on') &&
                                  _.get(history, 'end_on') !== '9000-12-31' &&
                                  _.get(history, 'end_on') <= recordDisplay.date(_.now(), 'YYYY-MM-DD') &&
                                  recordDisplay.date(_.get(history, 'end_on'), 'seireki')) ||
                                  '現在'}
                              </Timelinedate>
                              {!_.get(history, 'position_name') &&
                              !_.get(history, 'occupation_name') &&
                              !_.get(history, 'employment_type_name') &&
                              !_.get(history, 'office_name') &&
                              !_.get(history, 'group_0_name') &&
                              !_.get(history, 'group_1_name') ? null : (
                                <Timelinecontent>
                                  <Dl>
                                    <Trhalf>
                                      <Dt>役職</Dt>
                                      <Dd>{recordDisplay(_.get(history, 'position_name'))}</Dd>
                                    </Trhalf>
                                    <Trhalf>
                                      <React.Fragment>
                                        <Dt>職種</Dt>
                                        <Dd>{recordDisplay(_.get(history, 'occupation_name'))}</Dd>
                                      </React.Fragment>
                                    </Trhalf>
                                    <Trhalf>
                                      <Dt>雇用形態</Dt>
                                      <Dd>{recordDisplay(_.get(history, 'employment_type_name'))}</Dd>
                                    </Trhalf>
                                    <Trfull>
                                      <Dt>事業所</Dt>
                                      <Dd>{recordDisplay(_.get(history, 'office_name'))}</Dd>
                                    </Trfull>
                                    <Trfull>
                                      <Dt>グループ</Dt>
                                      <Dd>{recordDisplay(wrapArrowText(_.get(history, 'group_0_name')))}</Dd>
                                    </Trfull>
                                    {!_.isEmpty(_.get(history, 'group_1_name')) && (
                                      <Trfull>
                                        <Dt>兼務グループ1</Dt>
                                        <Dd>{wrapArrowText(_.get(history, 'group_1_name'))}</Dd>
                                      </Trfull>
                                    )}
                                    {!_.isEmpty(_.get(history, 'group_2_name')) && (
                                      <Trfull>
                                        <Dt>兼務グループ2</Dt>
                                        <Dd>{wrapArrowText(_.get(history, 'group_2_name'))}</Dd>
                                      </Trfull>
                                    )}
                                  </Dl>
                                </Timelinecontent>
                              )}
                            </Li>
                          )
                      )}
                  </Ul>
                  {employee.personnel_history && employee.personnel_history.length > 2 && (
                    <ShowMoreBtn
                      isExpand={this.state.isExpand}
                      onToggleClick={() => {
                        this.setState({isExpand: !this.state.isExpand})
                      }}
                    />
                  )}
                </SubPanelarea>
              </Wrap>
            </Item>
          </Panel>
        )) ||
      null
    )
  }
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(EmploymentInfomation)
