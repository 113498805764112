import type {RouteParams} from 'types/routes'
import {WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import {Index} from 'pages/withAuth/adminPage/egovProcedures/index'
import {Show} from 'pages/withAuth/adminPage/egovProcedures/show'

export const EgovProcedureRoutes: RouteParams[] = [
  {
    key: 'egov_procedures',
    path: WITH_AUTH_ADMIN_PATHS.EGOV_PROCEDURES.INDEX,
    component: Index,
    exact: true,
    whiteList: ['full_admin', 'office_admin', 'limited_admin'],
  },
  {
    key: 'egov_procedure',
    path: WITH_AUTH_ADMIN_PATHS.EGOV_PROCEDURES.SHOW,
    component: Show,
    exact: true,
    whiteList: ['full_admin', 'office_admin', 'limited_admin'],
  },
]
