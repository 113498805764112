import ChooseExistDependents from 'procedures/changeDependents/add/ChooseExistDependents'
import ChooseNewOrExists from 'procedures/changeDependents/add/ChooseNewOrExists'
import Detail from 'procedures/changeDependents/add/detail/Detail'
import Update from 'procedures/changeDependents/add/Update'
import Flow from 'procedures/changeDependents/add/Flow'
import EmployeeInputDependents from 'procedures/EmployeeInputDependents'
import Confirm from 'procedures/changeDependents/add/Confirm'
import ConfirmDependents from 'procedures/ConfirmDependents'
import {AddEmployeeDependents} from './addEmployeeDependents/AddEmployeeDependents'
export default {
  Detail,
  Update,
  Flow,
  EmployeeInputDependents,
  Confirm,
  ConfirmDependents,
  ChooseNewOrExists,
  ChooseExistDependents,
  AddEmployeeDependents,
}
