import {FC} from 'react'
import {Link} from 'react-router-dom'
import styles from 'NotFound.scss'

export const Index: FC = () => (
  <div className="l-flex">
    <div className={styles.contents}>
      <h2 className={styles.title}>新しいパスワードは既に発行済みです</h2>
      <div className={styles.cardWrap}>
        <p>ログイン画面からログインしてください</p>
        <Link to="/users/login" className="u-txt-link">
          ログイン画面へ
        </Link>
      </div>
    </div>
  </div>
)
