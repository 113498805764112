import React from 'react'
import {Link} from 'react-router-dom'
import {HEALTH_AND_WELFARE_PENSION, EMPLOYMENT} from 'procedureStatuses/reportList/common'
import ListGroup from 'jbc-front/components/ListGroup'

export const getReportList = function* ({
  procedureStatus,
  healthInsuranceType,
  healthInsuranceJoined,
  welfarePensionInsuranceJoined,
  employmentInsuranceJoined,
}) {
  const employee = procedureStatus.employee
  if (healthInsuranceJoined || welfarePensionInsuranceJoined) {
    if (healthInsuranceType === 'kyokai' || healthInsuranceType === 'other') {
      yield {
        num: 'r12',
        title: '健康保険厚生年金保険 被保険者氏名変更（訂正）届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    } else if (healthInsuranceType === 'its') {
      yield {
        num: 'r13',
        title: '健康保険 被保険者氏名変更（訂正）届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
    if (employee.national_type === 'foreigner' && welfarePensionInsuranceJoined) {
      yield {
        num: 'r55',
        title: '厚生年金保険被保険者ローマ字氏名届',
        editable: true,
        type: HEALTH_AND_WELFARE_PENSION,
      }
    }
  }
  if (employmentInsuranceJoined && procedureStatus.exist_r59_param_set) {
    yield {
      num: 'r59',
      title: '雇用保険被保険者 氏名変更届',
      editable: true,
      type: EMPLOYMENT,
    }
  }
}

const ProcedureInfo = ({procedureStatus}) => (
  <ListGroup>
    <ListGroup.Title>手続き情報</ListGroup.Title>
    <ListGroup.Item as={Link} to={`/change_name/update/${procedureStatus.id}`}>
      手続き情報編集
    </ListGroup.Item>
  </ListGroup>
)

export default {getReportList, ProcedureInfo}
