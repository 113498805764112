import List from 'myNumber/businessPartner/List'
import Create from 'myNumber/businessPartner/Create'
import Show from 'myNumber/businessPartner/Show'
import GuestInput from 'myNumber/businessPartner/GuestInput'
import Edit from 'myNumber/businessPartner/Edit'
import FileImport from 'myNumber/businessPartner/FileImport'
import FileImportUpdate from 'myNumber/businessPartner/FileImportUpdate'

export default {
  List,
  Create,
  Show,
  GuestInput,
  Edit,
  FileImport,
  FileImportUpdate,
}
