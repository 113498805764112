import React from 'react'
import {useSelector} from 'react-redux'
import styles from 'components/PerPage.scss'
import {getSavedDisplayEmployeeLimit, saveDisplayEmployeeLimit} from 'documentTemplates/list/utils'

const options = [10, 20, 50, 100, 200]

const PerPage = ({onChange}) => {
  const [pathname, user] = useSelector((state) => [state.router.location.pathname, state.session.currentUser])
  const limit = getSavedDisplayEmployeeLimit(pathname, user)

  const onChangeLimit = (e) => {
    onChange()
    saveDisplayEmployeeLimit(pathname, user, e.target.value)
  }

  return (
    <select type="select" onChange={onChangeLimit} className={styles.select} value={limit}>
      {options.map((perPage) => (
        <option key={perPage} value={perPage}>
          表示件数：{perPage}件
        </option>
      ))}
    </select>
  )
}

export default PerPage
