import {useAuthAxios} from 'hooks/api/useAuthAxios'

type Data = {
  trashed_by_admin: boolean
}

export const useUpdateFileInfo = () => {
  const authAxios = useAuthAxios()
  return async (fileId: string, data: Data) => {
    const path = `/api/file_infos/${fileId}`
    return await authAxios.patch(path, data)
  }
}
