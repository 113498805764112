import React from 'react'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import Button from 'jbc-front/components/Button'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import _ from 'lodash'
import {bindActionCreators} from 'redux'
import {actionCreators} from 'actions'
import {reduxForm, formValueSelector} from 'redux-form'
import {SelectField} from 'jbc-front/components/Form'
import {EditSquare} from 'jbc-front/components/icons'
import styles from 'settings/clientRoles/EmployeeList.scss'
import {OfficeSelectorField} from 'features/offices/OfficeSelectorField'

export const OFFICE_ADMIN = '3'
export const clientRoles = [
  {value: '2', label: '社員'},
  {value: '1', label: '全権管理者'},
  {value: '3', label: '事業所管理者'},
  {value: '4', label: '運用担当者'},
]

const formName = 'changeClientRolesMulti'
const selector = formValueSelector(formName)

const formatValues = ({users_client_office_office_ids, ...values}) => {
  if (users_client_office_office_ids) {
    return {...values, users_client_office_office_ids: users_client_office_office_ids.split(',')}
  }
  return values
}

const Form = compose(
  connect(
    (state) => ({
      clientRoleId: selector(state, 'client_role_id'),
      usersClientOfficeIds: selector(state, 'users_client_office_office_ids'),
      selectedUsers: state.settings.usersClients.selected,
    }),
    bindActionCreators.bind(null, actionCreators.settings.usersClients)
  ),
  reduxForm({
    form: formName,
    onSubmit(values, dispatch, {changeSelected, selectedUsers, hideModal}) {
      changeSelected([selectedUsers, formatValues(values)])
      hideModal()
    },
  })
)(({clientRoleId, usersClientOfficeIds, hideModal, selectedUsers, handleSubmit}) => (
  <React.Fragment>
    <Modal.Body className={styles.modalBody} style={{overflow: 'visible'}}>
      選択された{_.filter(selectedUsers).length}個のアカウントの権限を以下のように変更します。
      <br />
      <br />
      <SelectField label="権限" name="client_role_id" options={clientRoles} addBlankOption={false} />
      {`${clientRoleId}` === OFFICE_ADMIN && (
        <OfficeSelectorField name="users_client_office_office_ids" label="適用事業所" required multiple />
      )}
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <Button primary onClick={handleSubmit} disabled={!clientRoleId && !usersClientOfficeIds}>
          変更
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </React.Fragment>
))

Form.defaultProps = {
  offices: [],
}

const ChangeMulti = ({showModal, hideModal, isModalOpen, selectedUsers}) => (
  <React.Fragment>
    <a className={_.some(selectedUsers) ? styles.multiLink : styles.multiLinkDisabled} onClick={showModal}>
      <EditSquare className="u-mr5" />
      チェックを入れたアカウントの権限を一括変更
    </a>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      <Modal.Header hideModal={hideModal}>アカウント権限一括変更</Modal.Header>
      <Form hideModal={hideModal} />
    </Modal>
  </React.Fragment>
)

export default compose(
  connect((state) => ({
    selectedUsers: state.settings.usersClients.selected,
  })),
  withModal
)(ChangeMulti)
