import React, {useEffect, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import useReactRouter from 'use-react-router'
import {parse} from 'query-string'
import {useSelector} from 'hooks/redux'
import {setToken} from 'store/slices/auth'

const first = <T,>(value: T | T[] | null | undefined) => (Array.isArray(value) ? value[0] : value) ?? undefined

/**
 * 「入社の手続きのみ」のユーザーのアクセス時に指定されたアクセストークンを得る。
 * 入社の手続き専用のパスにアクセスされていて、なおかつアクセストークンがURL経由で指定されている時のみ有効な値を返す。
 * @param notLoginPath 呼び出し元コンポーネントに「入社の手続きのみ」のユーザーがアクセスする時のパス
 * @returns 入社の手続き専用のアクセストークン
 */
const useEnrollmentInputToken = (notLoginPath: string): string | undefined => {
  const {
    location: {search},
    match: {path},
  } = useReactRouter()
  return useMemo(
    () => (path === notLoginPath && first(parse(search)['enrollment_input_token'])) || undefined,
    [notLoginPath, search, path]
  )
}

/**
 * enrollment_input_token を Redux store の auth.token に反映する。
 * @param notLoginPath 呼び出し元コンポーネントに「入社の手続きのみ」のユーザーがアクセスする時のURL
 */
export const useReflectEnrollmentInputTokenToStore = (notLoginPath: string) => {
  const dispatch = useDispatch()
  const token = useEnrollmentInputToken(notLoginPath)

  useEffect(() => {
    if (token) {
      dispatch(setToken(token))
    }
  }, [dispatch, token])
}

/**
 * {@link useReflectEnrollmentInputTokenToStore} のHoC版
 * @param notLoginPath 「入社の手続きのみ」のユーザーがアクセスする時のURL
 */
export const reflectEnrollmentInputToken =
  (notLoginPath: string) =>
  // eslint-disable-next-line @typescript-eslint/ban-types
  <WrappedComponentProps extends {}>(WrappedComponent: React.ComponentType<WrappedComponentProps>) => {
    const ReflectEnrollmentInputToken = (props: WrappedComponentProps) => {
      useReflectEnrollmentInputTokenToStore(notLoginPath)
      const token = useSelector((state) => state.auth.token)
      if (!token) {
        return null // enrollment_input_tokenがstoreに反映されるのを待つ。待たないとtokenが無い状態でAPIリクエストが始まってしまう。
      }
      return <WrappedComponent {...props} />
    }
    ReflectEnrollmentInputToken.displayName = `ReflectEnrollmentInputToken(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`
    return ReflectEnrollmentInputToken
  }
