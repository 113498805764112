import React, {Component} from 'react'
import Form, {makeInitialValuesForUpdate} from 'procedures/maternityLeave/start/Form'
import {alreadyExistSameDependent} from 'procedures/maternityLeave/Form'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import api from 'api'
import {actionCreators} from 'actions'
import {push} from 'connected-react-router'
import {asyncError} from 'store/actions/asyncError'

class Update extends Component {
  componentDidMount() {
    const {
      loadProcedureStatus,
      match: {
        params: {id},
      },
      token,
    } = this.props
    loadProcedureStatus(id, token)
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      procedureStatus: {employee, ...procedureStatus},
    } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        initialValues={makeInitialValuesForUpdate(procedureStatus, employee)}
        submitText="保存"
        employee={employee || {}}
      />
    )
  }

  handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      procedure_maternity_child_data: values.maternity_status === 'after' ? values.procedure_maternity_child_data : [],
      mother_child_note: values.maternity_status === 'after' ? values.mother_child_note : [],
    }
    const {update, token, dependents, procedureStatus} = this.props
    if (alreadyExistSameDependent(formattedValues.procedure_maternity_child_data, dependents)) {
      throw new SubmissionError({'': 'すでに同じ被扶養者が登録されています'})
    } else {
      await update(procedureStatus, formattedValues, token)
    }
  }
}

export default connect(
  (state) => ({
    procedureStatus: state.procedureStatuses.current.data,
    dependents: state.procedureStatuses.current.data?.employee?.employee_dependents,
    token: state.auth.token,
  }),
  (dispatch) => ({
    loadProcedureStatus: (id, token) => {
      dispatch(
        actionCreators.procedureStatuses.current.fetchData(
          api
            .createWithAuth(token)
            .procedureStatuses.get(id, ['employee', 'employee_dependents', 'procedure_maternity_datum'])
        )
      )
    },
    destroy: () => {
      dispatch(actionCreators.employees.current.destroy())
    },
    update: async (procedureStatus, values, token) => {
      try {
        const authedApi = api.createWithAuth(token)
        await authedApi.procedureStatuses.update(procedureStatus.id, {date_on: values.expected_birth_date})
        await authedApi.procedureStatuses.startMaternityData.update(procedureStatus.id, values)
        if (
          values.procedure_maternity_child_data.some(
            (child_datum) => child_datum.add_support && !child_datum.skip_add_support
          )
        ) {
          dispatch(
            push(
              `/add_dependents/detail?employee_id=${procedureStatus.employee_id}&maternity_procedure_status_id=${procedureStatus.id}`
            )
          )
        } else {
          dispatch(push(`/procedure_statuses/${procedureStatus.id}`))
        }
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(Update)
