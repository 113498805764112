import React from 'react'
import {Step, useBeforeUnload} from 'components/Wizard'
import SelectTemplateStep from 'documents/create/step/SelectTemplateStep'
import SelectEmployeesStep from 'documents/create/step/SelectEmployeesStep'
import UploadCustomVariablesStep from 'documents/create/step/UploadCustomVariablesStep'
import ConfirmationStep from 'documents/create/step/ConfirmationStep'
import WizardHeader from 'documents/create/WizardHeader'
import {useValidateDynamicDocument} from 'documents/create/useValidateDynamicDocument'
import {gql} from '@apollo/client'
import {useLazyQuery} from 'components/Graphql'

const DYNAMIC_DOCUMENT = gql`
  query dynamicDocumentPreview($document: DocumentInput!) {
    client {
      id
      dynamicDocumentPreview(document: $document) {
        id
        pdf
        warnings {
          causePrefix
          cause
          causeSuffix
        }
        errors
      }
    }
  }
`

const WizardStepForms = ({handleSubmit, initialValues, setInitialValues, selected, templateOptions}) => {
  useBeforeUnload('このサイトを再読み込みしますか？行った変更が保存されない可能性があります。')
  const [getDynamicDocument, {loading, data}] = useLazyQuery(DYNAMIC_DOCUMENT, {fetchPolicy: 'network-only'})
  const {validating, validatingProgress, warnings, validate} = useValidateDynamicDocument()
  const preview = data?.client?.dynamicDocumentPreview
  const compositeWarnings = [...(preview?.warnings || []), ...(warnings || [])]

  return (
    <>
      <WizardHeader />
      <Step name="SelectTemplate">
        <SelectTemplateStep
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          templateOptions={templateOptions}
        />
      </Step>
      <Step name="SelectEmployees">
        <SelectEmployeesStep selected={selected} getDynamicDocument={getDynamicDocument} validate={validate} />
      </Step>
      <Step name="UploadCustomVariables">
        <UploadCustomVariablesStep
          initialValues={initialValues}
          setInitialValues={setInitialValues}
          getDynamicDocument={getDynamicDocument}
          preview={preview}
          loading={loading}
          validate={validate}
        />
      </Step>
      <Step name="Confirmation">
        <ConfirmationStep
          handleSubmit={handleSubmit}
          preview={preview}
          validating={validating}
          validatingProgress={validatingProgress}
          warnings={compositeWarnings}
        />
      </Step>
    </>
  )
}

export default WizardStepForms
