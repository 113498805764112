import moment from 'moment'
import Cookies from 'js-cookie'
import _ from 'lodash'
import queryString from 'query-string'
import {actionCreators} from 'actions'
import api from 'api'
import {formValueSelector, getFormValues, autofill as autofillForm} from 'redux-form'
import {groupFullName} from 'employees/form/EmploymentInfomation'

export const formName = 'documentEmployeeSearchForm'
export const selector = formValueSelector(formName)
export const getValues = getFormValues(formName)
export const autofill = autofillForm.bind(null, formName)
export const defaultQuery = {
  employment_status: 'employed',
  _page: '1',
  sort: 'staff_code',
  order: 'asc',
}
const defaultLimit = '10'

export const convertQueryToForm = (query) => {
  return Object.assign(
    {sort_type: `${query.sort}__${query.order}`},
    ...Object.entries(query)
      .map(([k, v]) => convertQueryToFormField(k, v))
      .filter(_.identity)
  )
}

export const convertQueryToFormField = (fieldName, value) => {
  switch (fieldName) {
    case 'office_ids':
      return {offices: value.join(',')}
    case 'group_ids':
      return {groups: value.join(',')}
    case 'employment_status_ids':
      return {employment_statuses: value}
    case 'position_ids':
      return {positions: value}
    case 'employment_type_ids':
      return {employment_types: value}
    case 'occupation_ids':
      return {occupations: value}
    case '_page':
      return {page: value}
    case 'sort':
    case 'order':
      return undefined
    default:
      return {[fieldName]: value}
  }
}

export const convertFormToQueryString = (data) =>
  queryString.stringify(convertFormToQuery(data), {arrayFormat: 'bracket'})

export const convertFormToQuery = (data) => {
  if (data === undefined) return {}
  return Object.assign(
    {},
    ...Object.entries(data)
      .map(([k, v]) => convertFormToQueryField(k, v))
      .filter(_.identity)
  )
}

export const convertFormToQueryField = (fieldName, value) => {
  switch (fieldName) {
    case 'offices': {
      const officesIds = value.split(',')
      return !_.isEqual(officesIds, ['']) ? {office_ids: officesIds} : null
    }
    case 'groups': {
      const groupIds = value.split(',')
      return !_.isEqual(groupIds, ['']) ? {group_ids: groupIds} : null
    }
    case 'employment_statuses':
      return !_.isEmpty(value) ? {employment_status_ids: value} : null
    case 'positions':
      return !_.isEmpty(value) ? {position_ids: value} : null
    case 'employment_types':
      return !_.isEmpty(value) ? {employment_type_ids: value} : null
    case 'occupations':
      return !_.isEmpty(value) ? {occupation_ids: value} : null
    case 'limit':
      return null
    case 'page':
      return {_page: value}
    case 'sort_type': {
      const [sort, order] = value.split('__')
      return {order, sort}
    }
    default:
      return {[fieldName]: value}
  }
}

const addedFieldsKey = (user) => `#${user.id}__added_fields`

export const addOptions = [
  {label: '生年月日', value: 'birthday', fields: ['birthday_start', 'birthday_end']},
  {label: '性別 ', value: 'genders'},
]

const makeAddedFields = (query, user) => {
  let savedQuery = []
  if (window.localStorage) {
    const saved = window.localStorage.getItem(addedFieldsKey(user))
    if (saved) {
      savedQuery = JSON.parse(saved)
    }
  }
  let hasQuery = addOptions
    .filter((opt) => (opt.fields || [opt.value]).some((key) => !_.isEmpty(query[key])))
    .map((opt) => opt.value)
  return _.union(savedQuery, hasQuery)
}

export const saveAddedFields = (user, addedField) => {
  if (window.localStorage) {
    window.localStorage.setItem(addedFieldsKey(user), JSON.stringify(_.isEmpty(addedField) ? [] : addedField))
  }
}

export const getCurrentQueryFromLocation = (location, user) => {
  const query = {
    ...defaultQuery,
    age_base_date: moment().format('YYYY/MM/DD'),
    ...queryString.parse(location.search, {arrayFormat: 'bracket'}),
    limit: getSavedDisplayEmployeeLimit(location.pathname, user),
  }
  return {...query, _added_fields: makeAddedFields(query, user)}
}

const limitKey = (pathname, user) => `${pathname.replace(/\//g, '')}#${user.id}__disp_emp_limit`

export const getSavedDisplayEmployeeLimit = (pathname, user) => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(limitKey(pathname, user))
    if (!_.isEmpty(limit)) {
      return limit
    }
  }
  const limit = Cookies.get(limitKey(pathname, user))
  return _.isEmpty(limit) ? defaultLimit : limit
}

export const saveDisplayEmployeeLimit = (pathname, user, limit) => {
  if (window.localStorage) {
    window.localStorage.setItem(limitKey(pathname, user), limit)
  } else {
    Cookies.set(limitKey(pathname, user), limit)
  }
}

export const fetchDocumentEmployees =
  (query, additionalParams = {}) =>
  (dispatch, getState) => {
    const {
      auth: {token},
    } = getState()
    const request = api.createWithAuth(token).documentEmployees.search({...query, ...additionalParams})
    dispatch(actionCreators.documentEmployees.list.fetchData(request))
  }

export const fetchSelections = (token, dispatch) => {
  const promises = [
    api.createWithAuth(token).groups.list(),
    api.createWithAuth(token).employees.selections.positions(),
    api.createWithAuth(token).employees.selections.employmentTypes(),
    api.createWithAuth(token).employees.selections.occupations(),
  ]
  dispatch(
    actionCreators.fetchData(
      'selections',
      Promise.all(promises).then((results) => {
        const [groupsResult, positionsResult, employmentTypesResult, occupationsResult] = results
        return {
          groups: groupsResult.groups.map((group) => ({
            value: `${group.id}`,
            label: groupFullName(group, _.keyBy(groupsResult.groups, 'id')),
            parent_value: `${group.parent_group_id}`,
          })),
          positions: positionsResult.data.map((item) => ({...item, id: `${item.id}`})),
          employmentTypes: employmentTypesResult.data.map((item) => ({...item, id: `${item.id}`})),
          occupations: occupationsResult.data.map((item) => ({...item, id: `${item.id}`})),
        }
      })
    )
  )
}

export const hasConditions = (formValues) => {
  return !_.isEqual(_.pickBy(_.omit(convertFormToQuery(formValues), ['_added_fields', 'age_base_date'])), defaultQuery)
}
