import React from 'react'
import {connect} from 'react-redux'
import {autofill, reduxForm, getFormValues, reset, getFormMeta} from 'redux-form'
import {push} from 'connected-react-router'
import {actionCreators} from 'actions'
import _ from 'lodash'
import compose from 'lodash/fp/compose'
import {getState} from 'utils'
import searchDetector from 'searchDetector'
import AdditionalSearchFields from './AdditionalSearchFields'
import SortingFieldsWithResult from './SortingFieldsWithResult'
import {
  formName,
  getSavedDisplayEmployeeLimit,
  saveDisplayEmployeeLimit,
  convertQueryToForm,
  getCurrentQueryFromLocation,
  fetchEmployees,
  convertFormToQueryString,
  fetchSelections,
} from 'settings/roles/employeeList/utils'
import styles from './SearchForm.scss'

class SearchForm extends React.Component {
  componentDidMount() {
    const {token, dispatch} = this.props
    fetchSelections(token, dispatch)
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('selections'))
  }

  clearForm = () => {
    const {dispatch} = this.props
    getState(dispatch).then((state) => {
      dispatch(reset(formName))
      dispatch(push({...state.router.location, search: ''}))
    })
  }

  render() {
    const {user, pathname = '', handleSubmit, buttons} = this.props
    return (
      <div className={styles.searchForm}>
        <div className={styles.details}>
          <form onSubmit={handleSubmit}>
            {<AdditionalSearchFields handleClear={this.clearForm} handleSubmit={handleSubmit} />}
          </form>
        </div>
        <div className={styles.buttons}>{buttons}</div>
        <form onSubmit={handleSubmit}>
          <SortingFieldsWithResult
            limit={getSavedDisplayEmployeeLimit(pathname, user)}
            onChangeLimit={(newLimit) => {
              saveDisplayEmployeeLimit(pathname, user, newLimit)
              handleSubmit()
            }}
            onChangeSortType={handleSubmit}
          />
        </form>
      </div>
    )
  }
}

export default compose(
  searchDetector({
    convertQueryToForm,
    convertFormToQueryString,
    getCurrentQueryFromLocation,
    fetchData(query, additionalParams, dispatch) {
      dispatch(fetchEmployees(query, additionalParams))
    },
    destroy(dispatch) {
      dispatch(actionCreators.employees.list.destroy())
    },
  }),
  connect((state) => ({
    token: state.auth.token,
    pathname: state.router.location.pathname,
    formValues: getFormValues(formName)(state),
    user: state.session.currentUser,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmit(values, dispatch, {handleSearch}) {
      getState(dispatch).then((state) => {
        const autofilled = _.get(getFormMeta(formName)(state), 'page.autofilled')
        if (!autofilled) {
          dispatch(autofill(formName, 'page', 1))
        }
        getState(dispatch).then((state) => {
          handleSearch(getFormValues(formName)(state))
        })
      })
    },
  })
)(SearchForm)
