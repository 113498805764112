import {FC, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import useReactRouter from 'use-react-router'
import _ from 'lodash'
import {Content} from 'components/layout/Content'
import {useSelector} from 'hooks/redux'
import {notifyError} from 'store/actions/notify'

import Button from 'jbc-front/components/Button'

// @ts-ignore
import EnrollEmployeesList from 'procedures/enroll/EmployeesList'
// @ts-ignore
import {Consumer as AsyncTaskConsumer} from 'AsyncTask'
// @ts-ignore
import {actionCreators} from 'actions'

const CURRENT_PATH = '/direct_request'
const REQUEST_PATH = '/direct_request/request'

interface TaskRunningProps {
  disabled: boolean
  disabledReason: string
}

interface AsyncTaskContextProps {
  taskRunningProps: TaskRunningProps
}

export const Presenter: FC = () => {
  const {
    history,
    location: {pathname},
  } = useReactRouter()
  const dispatch = useDispatch()
  const selected = useSelector((state) => state.employees.selected)

  const handleSubmit = () => {
    const _selected = _.keys(_.pickBy(selected, _.identity))
    if (_selected.length === 0) {
      dispatch(notifyError('従業員が選択されていません'))
    } else {
      history.push(REQUEST_PATH)
    }
  }

  useEffect(() => {
    dispatch(actionCreators.employees.clearSelected())

    return () => {
      if (![CURRENT_PATH, REQUEST_PATH].includes(pathname)) {
        dispatch(actionCreators.employees.clearSelected())
      }
    }
  }, [])

  return (
    <>
      <div className="l-title-wrap">
        <h1 className="m-title-main">従業員を選択してください</h1>
      </div>
      <Content size="xl-4">
        <EnrollEmployeesList isMultiple isLeaveSelected isDirectRequest />
        <div className="u-ta-c u-mt60">
          <AsyncTaskConsumer>
            {({taskRunningProps}: AsyncTaskContextProps) => (
              <Button primary onClick={handleSubmit} {...taskRunningProps}>
                項目を選択する
              </Button>
            )}
          </AsyncTaskConsumer>
        </div>
      </Content>
    </>
  )
}
