import {FC, ReactNode, createContext, useContext} from 'react'
import classNames from 'classnames'
import styles from './DefinitionList.scss'

interface DlProps {
  children: ReactNode
  className?: string
  separator?: string
}

interface DtProps {
  children: ReactNode
  className?: string
}

interface DdProps {
  children: ReactNode
  className?: string
}

const SeparatorContext = createContext<string | undefined>(undefined)

export const Dl: FC<DlProps> = ({children, className, separator}) => {
  return (
    <SeparatorContext.Provider value={separator}>
      <dl className={classNames(styles.dl, className)}>{children}</dl>
    </SeparatorContext.Provider>
  )
}

export const Dt: FC<DtProps> = ({children, className}) => {
  const separator = useContext(SeparatorContext)
  const separatorStyle = {
    [styles.withSeparator]: !!separator,
  }

  return (
    <dt className={classNames(styles.dt, className, separatorStyle)}>
      <div>{children}</div>
      <div>{separator}</div>
    </dt>
  )
}

export const Dd: FC<DdProps> = ({children, className}) => {
  return <dd className={classNames(styles.dd, className)}>{children}</dd>
}
