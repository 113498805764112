import React from 'react'
import styles from 'employees/show/Parts.scss'
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import _ from 'lodash'
import classNames from 'classnames'

export const Dl = ({children, className, forProfiles}) => {
  return (
    <dl className={[styles.dl, forProfiles && styles.EmployeeScreenSideParts, className].filter(_.identity).join(' ')}>
      {children}
    </dl>
  )
}
export const Dt = ({children, className, width}) => {
  return (
    <dt className={[styles.dt, className].filter(_.identity).join(' ')} style={width && {width: `${width}%`}}>
      {children}
    </dt>
  )
}
export const Dd = ({children, className, width}) => (
  <dd className={[styles.dd, className].filter(_.identity).join(' ')} style={width && {width: `${width}%`}}>
    {children}
  </dd>
)
export const Half = ({children}) => <div className={styles.half}>{children}</div>
export const Third = ({children}) => <div className={styles.third}>{children}</div>
export const Table = ({children}) => <table className={styles.infoTable}>{children}</table>
export const Pre = ({children}) => <span className={styles.pre}>{children}</span>

export const SubPanel = ({children}) => <Panel className={styles.subPanel}>{children}</Panel>
SubPanel.Headding = ({children, icon}) => (
  <Headding className={styles.subHeadding} icon={icon}>
    {children}
  </Headding>
)
SubPanel.Item = Item

export const ThirdPanel = ({children}) => <Panel className={styles.thirdPanel}>{children}</Panel>
ThirdPanel.Headding = ({children, icon}) => (
  <Headding className={styles.thirdHeadding} icon={icon}>
    {children}
  </Headding>
)
ThirdPanel.Item = Item

export const Wrap = ({children, forProfiles}) => {
  return <div className={classNames(forProfiles && styles.EmployeeScreenSideParts, styles.flex)}>{children}</div>
}
export const Grid = ({children, className, forProfiles, ...rest}) => (
  <div className={classNames(forProfiles && styles.EmployeeScreenSideParts, styles.grid, className)} {...rest}>
    {children}
  </div>
)

export const Tag = ({children}) => <div className={styles.tag}>{children}</div>
