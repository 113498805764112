import {FC} from 'react'
import {Table} from 'components/ui/Table'
import {generateDynamicPath, WITH_AUTH_ADMIN_PATHS} from 'consts/paths'
import useRouter from 'use-react-router'
import {DetailInputRequest} from '../../query'
import {fullNameFormat, dateFormat} from 'libs/formatter'
import {STATUS_MAP} from 'consts/updateRequestStatus'
import styles from './Row.scss'
import classnames from 'classnames'

type RowProps = {
  detailInputRequest: DetailInputRequest
}

const TYPE = {
  InitialInput: 'マイページ招待',
  DirectRequest: '管理者',
  UpdateProfile: '従業員',
}

export const Row: FC<RowProps> = ({detailInputRequest}) => {
  const {history} = useRouter()

  const handleMoveDetail = () => {
    const path = generateDynamicPath(WITH_AUTH_ADMIN_PATHS.EMPLOYEES.UPDATE_REQUESTS.SHOW, [
      {pattern: 'id', replacement: String(detailInputRequest.id)},
    ])
    history.push(path)
  }

  const {displayLastName, displayFirstName} = detailInputRequest.employee
  const fullName = fullNameFormat(displayLastName, displayFirstName)

  const groups = [
    detailInputRequest.employee.personnelHistory?.group0Name,
    detailInputRequest.employee.personnelHistory?.group1Name,
    detailInputRequest.employee.personnelHistory?.group2Name,
  ].filter((group) => !!group)

  return (
    <Table.Tr onClick={handleMoveDetail} className={classnames('table-hover', styles.updateRequestTable)}>
      <Table.Td className={styles.status}>{STATUS_MAP[detailInputRequest.status]}</Table.Td>
      <Table.Td className={styles.staffCode}>{detailInputRequest.employee.staffCode}</Table.Td>
      <Table.Td className={styles.fullName}>{fullName}</Table.Td>
      <Table.Td className={styles.group}>
        {groups.map((group, index) => (
          <p key={`key-group-${detailInputRequest.id}-${index}`}>{group}</p>
        ))}
      </Table.Td>
      <Table.Td className={styles.requester}>{TYPE[detailInputRequest.type]}</Table.Td>
      <Table.Td className={styles.requestDate}>
        {dateFormat(detailInputRequest.createdAt, 'YYYY/MM/DD')}
        <span className={styles.time}>{dateFormat(detailInputRequest.createdAt, 'HH:mm:ss')}</span>
      </Table.Td>
      <Table.Td className={styles.lastUpdate}>
        {dateFormat(detailInputRequest.updatedAt, 'YYYY/MM/DD')}
        <span className={styles.time}>{dateFormat(detailInputRequest.updatedAt, 'HH:mm:ss')}</span>
      </Table.Td>
    </Table.Tr>
  )
}
