import React, {Component} from 'react'
import {connect} from 'react-redux'
import RegistrationTrialForm from 'registration/RegistrationTrialForm'
import {push} from 'connected-react-router'
import api from 'api'
import {actionCreators} from 'actions'
import styles from 'registration/registration.scss'
import {parse} from 'query-string'
import Cookies from 'js-cookie'
import {asyncError} from 'store/actions/asyncError'

class CreateTrial extends Component {
  componentWillMount() {
    const {
      location: {search},
    } = this.props
    const {accountId: airMarketId} = parse(search)
    if (!airMarketId && !Cookies.get('StoreId')) {
      if (process.env.USE_JBC_ID === true) {
        window.location = `${process.env.JBC_ID_SERVER}/registration?app_key=lms`
      }
    }
    if (airMarketId) {
      Cookies.set('StoreId', airMarketId, {expires: 60})
    }
  }

  componentDidMount() {
    const {
      location: {search},
    } = this.props
    const {email} = parse(search)

    this.props.setInterestedEmail({email})
  }

  handleFormSubmit = (values) => {
    const {
      location: {search},
    } = this.props
    const airMarketId = parse(search).accountId || Cookies.get('StoreId')

    return this.props.handleFormSubmit({
      ...values,
      ...(airMarketId ? {air_market_id: airMarketId} : {}),
    })
  }

  render() {
    const {
      location: {search},
    } = this.props
    const {email} = parse(search)

    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">無料お試し</h1>
        </div>
        <div className={styles.columnWrap}>
          <div className={styles.columnFlow}>
            <div className={styles.overviewWrap}>
              <h2 className={styles.overwiewTitle}>ミスなくシンプル、一元管理</h2>
              <div className={styles.iconsWrap}>
                <div className={styles.iconsClm}>
                  <img src="/images/img_frretrial01.png" className={styles.icon} />
                  <p className={styles.caption}>労務手続き書類は自動作成！</p>
                </div>
                <div className={styles.iconsClm}>
                  <img src="/images/img_frretrial02.png" className={styles.icon} />
                  <p className={styles.caption}>電子申請で役所への持参も不要！</p>
                </div>
                <div className={styles.iconsClm}>
                  <img src="/images/img_frretrial03.png" className={styles.icon} />
                  <p className={styles.caption}>従業員のデータは一元管理！</p>
                </div>
              </div>
            </div>
            <h2 className="m-title-sub u-ta-c u-pt0 u-mb20">無料お試しの流れ</h2>
            <div className={styles.flowWrap}>
              <dl className={styles.flowItem}>
                <dt className={styles.flowHead}>1. 初期設定</dt>
                <dd className={styles.flowBody}>必要な情報を入力します</dd>
              </dl>
              <dl className={styles.flowItem}>
                <dt className={styles.flowHead}>2. 30日間の利用</dt>
                <dd className={styles.flowBody}>すべての機能を30日間ご利用できます</dd>
              </dl>
              <dl className={styles.flowItem}>
                <dt className={styles.flowHead}>3. 期間終了後</dt>
                <dd className={styles.flowBody}>
                  <p>
                    30日経過後は自動で無料プランに移行します。
                    <span className={styles.imp}>料金が自動発生することは一切有りません。</span>
                  </p>
                  <p className="u-mt10">引き続きすべての機能をご利用いただける場合は有料プランの申込をお願いします。</p>
                </dd>
              </dl>
            </div>
          </div>
          <div className={styles.columnForm}>
            <RegistrationTrialForm onSubmit={this.handleFormSubmit} initialValues={{user_email: email}} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, (dispatch) => ({
  async handleFormSubmit(values) {
    try {
      const email = values.user_email
      dispatch(actionCreators.registration.setEmail(email))
      await api.roumuCreateRegistration(values)
      dispatch(push('/registration/trial/completed'))
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
  async setInterestedEmail(value) {
    try {
      await api.roumuCreateRegistrationInterestedEmail(value)
    } catch (err) {
      dispatch(asyncError(err))
    }
  },
}))(CreateTrial)
