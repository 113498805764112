import React, {Component} from 'react'
import AsyncTaskLoading from 'components/AsyncTask'
import api from 'api'
import {connect} from 'react-redux'
import moment from 'moment'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import {getState, isAdminSelector} from 'utils'
import {asyncError} from 'store/actions/asyncError'

const percentage = (task) =>
  Math.min(Math.max(Math.floor(100 * (1 - Math.exp(-moment().diff(task.excuted_at, 'second') / 7))), 0), 99)

const hasError = (task) => task.status === 'failed' || task.status === 'partial_success'

const messages = {
  in_progress: '情報の一括更新を行っているため一部操作ができません',
  waiting: '情報の一括更新を行っているため一部操作ができません',
  success: '情報の一括更新が完了しました',
  failed: (
    <p>
      情報の一括更新でエラーが発生しました。
      <br />
      <Link to="/employees" className="u-txt-link">
        「従業員一覧」ページ
      </Link>
      より確認してください。
    </p>
  ),
}

const FailedMessage = ({task}) => {
  switch (task.task_type) {
    case 'my_number_csv_import':
      return (
        <p>
          情報の一括更新でエラーが発生しました。
          <br />
          <Link to="/my_number" className="u-txt-link">
            「マイナンバー一覧」ページ
          </Link>
          より確認してください。
        </p>
      )
    case 'group_csv_import':
    case 'group_csv_update':
      return (
        <p>
          情報の一括更新でエラーが発生しました。
          <br />
          <Link to="/settings/groups" className="u-txt-link">
            「グループ設定」ページ
          </Link>
          より確認してください。
        </p>
      )
    default:
      return (
        <p>
          情報の一括更新でエラーが発生しました。
          <br />
          <Link to="/employees" className="u-txt-link">
            「従業員一覧」ページ
          </Link>
          より確認してください。
        </p>
      )
  }
}

const sleep = (time) =>
  new Promise((resolve) => {
    setTimeout(resolve, time)
  })

export const {Provider, Consumer} = React.createContext({
  task: null,
  loadTasks: () => {},
  taskRunningProps: {},
})

export const withAsyncTask = (Child) => (props) => <Consumer>{(context) => <Child {...props} {...context} />}</Consumer>

class AsyncTask extends Component {
  running = true
  polling = false

  state = {
    task: null,
    percentage: 0,
  }

  loadTasks = async () => {
    const {dispatch, isAdmin} = this.props
    if (!isAdmin) {
      return
    }
    if (this.polling) {
      return
    }
    this.polling = true
    let retryCount = 3
    for (;;) {
      try {
        const {
          auth: {token},
        } = await getState(dispatch)
        const {data: tasks} = await api.createWithAuth(token).asyncTasks.list()
        const task = tasks.find((task) => ['waiting', 'in_progress'].includes(task.status)) || _.last(tasks) || null
        let percentage = 0
        if (task && task.status === 'in_progress') {
          if (this.state.task && this.state.task.id === task.id && this.state.task.status === 'in_progress') {
            percentage = Math.floor((this.state.percentage + 100) / 2)
          } else {
            percentage = 40
          }
        }
        this.setState({
          task: task,
          tasks: tasks,
          percentage: percentage,
        })
        if (!task || !this.running || !['waiting', 'in_progress'].includes(task.status)) {
          this.polling = false
          break
        }
        retryCount = 3
        await sleep(3000)
      } catch (err) {
        this.setState({task: null, tasks: []})
        if (--retryCount < 0) {
          this.polling = false
          break
        } else {
          await sleep(3000)
        }
      }
    }
  }

  componentDidMount() {
    this.loadTasks()
  }

  componentWillUnmount() {
    this.running = false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location || prevProps.isAdmin !== this.props.isAdmin) {
      this.loadTasks()
    }
  }

  confirm = async () => {
    const {token, dispatch} = this.props
    const {task} = this.state
    this.setState({task: null})
    try {
      await api.createWithAuth(token).asyncTasks.confirm(task.id)
      this.loadTasks()
    } catch (err) {
      dispatch(asyncError(err))
    }
  }

  render() {
    const {task} = this.state
    const {children} = this.props
    if (!task) {
      return (
        <Provider value={{loadTasks: this.loadTasks}}>
          {children}
          {null}
        </Provider>
      )
    }

    const text =
      task.status === 'waiting'
        ? '0%'
        : task.status === 'in_progress'
        ? `${percentage(task)}%`
        : task.status === 'success'
        ? '完了'
        : 'エラー'

    return (
      <Provider
        value={{
          loadTasks: this.loadTasks,
          task,
          confirm: this.confirm,
          taskRunningProps: ['waiting', 'in_progress'].includes(task.status)
            ? {disabled: true, disabledReason: messages[task.status]}
            : {},
        }}
      >
        {children}
        <AsyncTaskLoading
          error={hasError(task)}
          done={task.status === 'success'}
          message={hasError(task) ? <FailedMessage task={task} /> : messages[task.status]}
          text={text}
          onCloseClick={this.confirm}
        />
      </Provider>
    )
  }
}

export default connect((state) => ({
  token: state.auth.token,
  location: state.router.location,
  isAdmin: isAdminSelector(state),
}))(AsyncTask)
