import React from 'react'
import {Panel, Item} from 'components/PrimaryPanel'
import {recordDisplay} from 'utils'
import {connect} from 'react-redux'
import {remunerationTypeOptions} from 'employees/form/RemunerationInfomation'
import BankInformation from 'employees/show/BankInformation'
import CommutingAllowanceInformation from 'employees/show/CommutingAllowanceInformation'
import {Dl, Dd, Dt, Grid} from 'employees/show/Parts'
import {HeaddingWithLink} from 'employees/show/utils'

const hasBankInfo = (employee) =>
  employee &&
  ['bank_name', 'bank_type', 'bank_code', 'bank_name_kana', 'bank_branch_name', 'bank_number', 'bank_branch_code'].some(
    (key) => employee[key]
  )

const RemunerationInformation = ({employee, editLink, canEdit, isAdmin, hideDetail}) => {
  return (
    <Panel>
      <HeaddingWithLink to={`${editLink}#RemunerationInfomation`} title="給与情報" canEdit={canEdit} />
      <Item>
        <Grid>
          <Dl>
            <Dt>給与形態</Dt>
            <Dd>{recordDisplay.option(employee.remuneration_type, remunerationTypeOptions)}</Dd>
          </Dl>
          <Dl>
            <Dt>基本給</Dt>
            <Dd>{recordDisplay.yen(employee.remuneration_base)}</Dd>
          </Dl>
          <Dl>
            <Dt>報酬月額（通貨）</Dt>
            <Dd>{recordDisplay.yen(employee.monthly_remuneration)}</Dd>
          </Dl>
          <Dl>
            <Dt>報酬月額（現物）</Dt>
            <Dd>{recordDisplay.yen(employee.monthly_remuneration_in_kind)}</Dd>
          </Dl>
          <Dl>
            <Dt>標準報酬月額（健康保険）</Dt>
            <Dd>{recordDisplay.yen(employee.standard_monthly_amount_health)}</Dd>
          </Dl>
          <Dl>
            <Dt>標準報酬月額(厚生年金保険)</Dt>
            <Dd>{recordDisplay.yen(employee.standard_monthly_amount_pension)}</Dd>
          </Dl>
          <Dl>
            <Dt>今年の年間収入見積額</Dt>
            <Dd>{recordDisplay.yen(employee.estimated_annual_income)}</Dd>
          </Dl>
          <Dl>
            <Dt>今年の年間所得見積額</Dt>
            <Dd>{recordDisplay.yen(employee.estimated_annual_earnings)}</Dd>
          </Dl>
        </Grid>
        {!hideDetail && isAdmin && (
          <>
            {hasBankInfo(employee) && <BankInformation employee={employee} editLink={editLink} canEdit isAdmin />}
            {employee?.commute_type && (
              <CommutingAllowanceInformation employee={employee} editLink={editLink} canEdit isAdmin />
            )}
          </>
        )}
      </Item>
    </Panel>
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
}))(RemunerationInformation)
