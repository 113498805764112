import React, {Component} from 'react'
import Modal from 'react-modal'
import {Close} from 'jbc-front/components/icons'
import styles from 'settings/customMails/Preview.scss'
import Button from 'jbc-front/components/Button'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators} from 'actions'
import Form, {submit} from 'myNumber/MultiRequestForm'
import _ from 'lodash'
import {bindActionCreators} from 'redux'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

class Request extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      if (nextProps.isOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
    if (nextProps.employeeId !== this.props.employeeId && nextProps.employeeId) {
      const {employeeId, token, loadEmployee} = nextProps
      loadEmployee(employeeId, token)
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = ''
    this.props.destroy()
  }

  handleSubmit = (values) => {
    const {token, request, hide, selected, clearSelected} = this.props
    const employeeIds = _.keys(_.pickBy(selected))
    return request(values, employeeIds, token).then(() => {
      hide()
      clearSelected()
    })
  }

  handleSubmitClick = () => {
    this.props.submit()
  }

  render() {
    const {isOpen, hide, submitting, selected} = this.props
    return (
      <Modal className={styles.modal} isOpen={isOpen} overlayClassName={styles.overlay} contentLabel="Modal">
        <div className={styles.header}>
          <div className={styles.title}>一括でのマイナンバー提出依頼</div>
          <div className={styles.tools}>
            <Close className={styles.close} onClick={() => this.props.hide()} />
          </div>
        </div>
        {isOpen && <Form onSubmit={this.handleSubmit} employeeIds={_.keys(_.pickBy(selected))} />}
        <div className={styles.closeBottom}>
          <Button onClick={hide} disabled={submitting}>
            キャンセル
          </Button>
          <Button primary onClick={this.handleSubmitClick} disabled={submitting}>
            依頼する
          </Button>
        </div>
      </Modal>
    )
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    selected: state.employees.selected,
  }),
  (dispatch) => ({
    loadEmployee(id, token) {
      dispatch(
        actionCreators.employees.current.fetchData(api.createWithAuth(token).employees.get(id, ['employee_dependents']))
      )
    },
    destroy() {
      dispatch(actionCreators.employees.current.destroy())
    },
    submit() {
      dispatch(submit())
    },
    async request(data, employeeIds, token) {
      try {
        await api.createWithAuth(token).myNumber.requestMulti({...data, employee_ids: employeeIds})
        dispatch(notifySuccess('依頼しました'))
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
    ...bindActionCreators(_.pick(actionCreators.employees, ['clearSelected']), dispatch),
  })
)(Request)
