import {useEffect, useState} from 'react'
import {AxiosError} from 'axios'
import {useAuthAxios} from './useAuthAxios'

type FetchError = AxiosError | Error | undefined

const readAsArrayBuffer = (blob: Blob): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as ArrayBuffer)
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })

const toBase64 = (buffer: ArrayBuffer) =>
  btoa(new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), ''))

type Getter = () => Promise<void>

interface Result {
  isLoading: boolean
  resource?: string
  errors: FetchError
  getter: Getter
}

export const useFetchBase64Binary = (path: string, defaultResource?: string): Result => {
  const fetcher = useLazyFetchBase64Binary(path, defaultResource)

  useEffect(() => {
    fetcher.getter()
  }, [])

  return fetcher
}

export const useLazyFetchBase64Binary = (path: string, defaultResource?: string): Result => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [resource, setResource] = useState(defaultResource)
  const [errors, setErrors] = useState<FetchError>(undefined)
  const authAxios = useAuthAxios()

  const getter = async (): Promise<void> => {
    setIsLoading(true)

    try {
      const response = await authAxios.get(path, {responseType: 'blob'})
      const data = toBase64(await readAsArrayBuffer(response.data))
      setResource(data)
    } catch (e) {
      setErrors(e)
    }

    setIsLoading(false)
  }

  return {isLoading, resource, errors, getter}
}
