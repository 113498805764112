import {actionTypes} from 'actions'
import {combineReducers} from 'redux'
import {getQueryDiffFactory} from 'utils'
import queryReducer from 'reducers/queryReducer'

const defaultQuery = {
  page: 1,
}

export default combineReducers({
  query: queryReducer(actionTypes.OFFICES.SET_QUERY, defaultQuery),
})

export const getQueryDiff = getQueryDiffFactory(defaultQuery)
