import {combineReducers} from 'redux'
import groups from 'settings/groups/reducers'
import listEdit from 'settings/listEdit/reducers'
import auditLogs from 'settings/auditLogs/reducers'
import fetchReducer from 'reducers/fetchReducer'
import {actionTypes} from 'actions'
import usersClients from 'settings/usersClients/reducers'

const reducer = combineReducers({
  listEdit,
  groups,
  auditLogs,
  allowedIps: fetchReducer(actionTypes.SETTINGS.ALLOWED_IPS, {data: []}),
  additionalNotificationDsts: fetchReducer(actionTypes.SETTINGS.ADDITIONAL_NOTIFICATION_DSTS, {data: []}),
  laborConsultant: fetchReducer(actionTypes.SETTINGS.LABOR_CONSULTANT, {data: {}}),
  usersClients,
  talentDisplaySettings: fetchReducer(actionTypes.SETTINGS.TALENT_DISPLAY_SETTINGS, {data: []}),
  talentSearchSettings: fetchReducer(actionTypes.SETTINGS.TALENT_SEARCH_SETTINGS, {data: []}),
})

export default reducer
