import React from 'react'
import {FormSection} from 'redux-form'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {Joined} from 'FormFields'
import {connect} from 'react-redux'
import {Description, withFormSelectors, getDiffFromCheckbox, getDiffFromFile} from 'employees/form/common'
import {number, maxLength} from 'validators'

import {Section, CheckboxField, BoxDouble, DateField, FileField, TextField} from 'jbc-front/components/Form'
import {BasicPensionNumbers} from 'FormFields'
import _ from 'lodash'
import {isAdminSelector} from 'utils'
import styles from 'employees/form/BasicInfomation.scss'

const WelfarePensionInsuranceInfomation = ({
  isAdmin,
  procedureType,
  joined,
  shouldShowComponent,
  diff = {},
  healthInsuranceType,
  showInsuredPersonnelNumber,
  description,
  description_color_by_rgb,
  isInitialInput,
  retiredAt,
}) =>
  shouldShowComponent ? (
    <Section title="厚生年金保険">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <FormSection name="welfare_pension_insurance">
        <LabelMapper name="welfare_pension_insurance" label="厚生年金保険の" />
        {(isAdmin || (procedureType !== 'enroll' && !isInitialInput)) && (
          <Joined outputsNote={procedureType === 'enroll'} diff={diff} />
        )}
        {joined && (
          <div>
            <BasicPensionNumbers diff={diff} />
            {healthInsuranceType !== 'kyokai' && showInsuredPersonnelNumber && (
              <TextField
                name="insured_personnel_number"
                label="厚生年金保険被保険者整理番号"
                validate={[number, maxLength(6)]}
                diff={diff.insured_personnel_number}
              />
            )}
            <FileField name="image" label="年金手帳または基礎年金番号通知書画像" diff={getDiffFromFile(diff.image)} />
            <div className={styles.notes}>
              すでにファイルが存在する場合は、ファイルを更新した時点で上書き更新されますのでご注意ください。
            </div>
            <CheckboxField
              name="first_time_join"
              label="初めて厚生年金保険に加入する"
              diff={getDiffFromCheckbox(diff.first_time_join)}
            />
            <CheckboxField
              name="need_detail"
              label="基礎年金番号が分からない"
              diff={getDiffFromCheckbox(diff.need_detail)}
            />
            {procedureType !== 'enroll' && (
              <BoxDouble>
                {!isInitialInput && <DateField name="start_on" label="資格取得日" diff={diff.start_on} required />}
                {isAdmin && <DateField name="end_on" label="資格喪失日" required={retiredAt} />}
              </BoxDouble>
            )}
          </div>
        )}
      </FormSection>
    </Section>
  ) : null

export default WelfarePensionInsuranceInfomation
  |> connect((state, {procedureType, selector, isInitialInput, isForceEmployee}) => {
    const isAdmin = !isForceEmployee && isAdminSelector(state)
    const joined = selector(state, 'welfare_pension_insurance.joined')
    return {
      isAdmin,
      joined,
      diff: selector(state, '_diff.welfare_pension_insurance'),
      healthInsuranceType:
        _.get(state.employees.current.data, 'office.health_insurance_type') ||
        _.get(state.procedureStatuses.current.data.employee, 'office.health_insurance_type'),
      shouldShowComponent: isAdmin || joined || procedureType !== 'enroll',
      showInsuredPersonnelNumber:
        !(isInitialInput || procedureType === 'enroll') || (isAdmin && procedureType === 'enroll'),
      retiredAt: selector(state, 'retired_at'),
    }
  })
  |> withFormSelectors
