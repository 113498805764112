import toDoListStyles from 'procedures/maternityLeave/ToDoList.scss'
import showStyles from 'procedureStatuses/Show.scss'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Progress} from 'components/Progress'
import DropDownMenu from 'components/DropDownMenu'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {actionCreators} from 'actions'
import {toDateStr} from 'utils'
import api from 'api'
import {ListItem, ListGroup, ListTitle} from 'components/ListGroup'
import {Clock} from 'jbc-front/components/icons'
import {Todo} from 'procedureStatuses/Show'
import {makeMaternityDatum} from 'procedures/maternityLeave/Form'
import {asyncError} from 'store/actions/asyncError'

const styles = {...toDoListStyles, ...showStyles}

export const MaternityToDoList = ({procedureStatus}) => {
  const procedureMaternityDatum = makeMaternityDatum(procedureStatus)
  const token = useSelector((state) => state.auth.token)
  const dispatch = useDispatch()
  const updateTodo = (todoListNo, todoNo, todoId, status, procedureType) => {
    try {
      dispatch(actionCreators.procedureStatuses.updateTodo({todoListNo, todoNo, status, procedureType}))
      api.createWithAuth(token).todos.update(todoId, status)
    } catch (err) {
      dispatch(asyncError(err))
    }
  }
  const handleTodoChange = (procedureType) => (todoListNo, todoNo, todo) => {
    updateTodo(todoListNo, todoNo, todo.id, todo.status == 'todo' ? 'done' : 'todo', procedureType)
  }
  const {
    started_at: startedAt,
    expected_birth_date: expectedBirthDate,
    expected_finished_at: expectedFinishedAt,
  } = procedureMaternityDatum

  const extendIndex = _.filter(procedureStatus.todo_lists, {procedure_type: 'start_maternity'}).length
  const finishIndex = _.filter(procedureStatus.todo_lists, (todoList) =>
    ['start_maternity', 'extend_maternity'].includes(todoList.procedure_type)
  ).length

  return (
    <div className={styles.todoLeftBar}>
      <div className={styles.todoDate}>
        <div className={styles.todoLeftBarYear}>{toDateStr(startedAt, 'YYYY')}</div>
        <div className={styles.todoLeftBarDate}>{toDateStr(startedAt, 'M/D')}</div>
        <div className={styles.todoLeftBarDotLine} />
        <div className={styles.todoLeftBarDot} />
        <div className={styles.todoProcedureDate}>
          <div className={styles.todoProcedureDateText}>産休開始日</div>
        </div>
      </div>

      {procedureStatus.procedure_start_maternity_datum && (
        <div className={styles.procedure}>
          <div className={styles.todoLeftBarDotLine} />
          <div className={styles.todoLeftBarDot} />
          <ListTitle>産休開始手続き</ListTitle>
          <ListItem>
            <div className={styles.todoInnerListGroup}>
              {_.filter(procedureStatus.todo_lists, {procedure_type: 'start_maternity'}).map(
                (todoList, todoListNo) =>
                  !_.isEmpty(todoList.todos) && (
                    <ListGroup key={todoListNo}>
                      <div>
                        <ListTitle>
                          <div>
                            {todoList.title}
                            {todoList.due_on && (
                              <span className={styles.todoDate}>
                                <Clock size={16} /> {toDateStr(todoList.due_on, 'MMMDo')}
                              </span>
                            )}
                          </div>
                        </ListTitle>
                      </div>
                      {todoList.todos.map((todo, todoNo) => (
                        <ListItem key={todoNo}>
                          <Todo
                            onTodoChange={handleTodoChange('start_maternity')}
                            todoListNo={todoListNo}
                            todoNo={todoNo}
                            todo={todo}
                          />
                        </ListItem>
                      ))}
                    </ListGroup>
                  )
              )}
            </div>
          </ListItem>
        </div>
      )}

      <div className={styles.todoDate}>
        <div className={styles.todoLeftBarYear}>{toDateStr(expectedBirthDate, 'YYYY')}</div>
        <div className={styles.todoLeftBarDate}>{toDateStr(expectedBirthDate, 'M/D')}</div>
        <div className={styles.todoLeftBarDotLine} />
        <div className={styles.todoLeftBarDot} />
        <div className={styles.todoProcedureDate}>
          <div className={styles.todoProcedureDateText}>出産予定日</div>
          {!!procedureStatus.procedure_start_maternity_datum &&
            !procedureStatus.procedure_extend_maternity_datum &&
            !procedureStatus.procedure_finish_maternity_datum && (
              <Link
                className={styles.todoLinkButton}
                to={`/extend_maternity/detail?employee_id=${procedureStatus.employee_id}&procedure_id=${procedureStatus.id}`}
              >
                変更する
              </Link>
            )}
        </div>
      </div>

      {procedureStatus.procedure_extend_maternity_datum && (
        <div className={styles.procedure}>
          <div className={styles.todoLeftBarDotLine} />
          <div className={styles.todoLeftBarDot} />
          <ListTitle>産休延長手続き</ListTitle>
          <ListItem>
            <div className={styles.todoInnerListGroup}>
              {_.filter(procedureStatus.todo_lists, {procedure_type: 'extend_maternity'}).map(
                (todoList, todoListNo) =>
                  !_.isEmpty(todoList.todos) && (
                    <ListGroup key={todoListNo + extendIndex}>
                      <div>
                        <ListTitle>
                          <div>
                            {todoList.title}
                            {todoList.due_on && (
                              <span className={styles.todoDate}>
                                <Clock size={16} /> {toDateStr(todoList.due_on, 'MMMDo')}
                              </span>
                            )}
                          </div>
                        </ListTitle>
                      </div>
                      {todoList.todos.map((todo, todoNo) => (
                        <ListItem key={todoNo}>
                          <Todo
                            onTodoChange={handleTodoChange('extend_maternity')}
                            todoListNo={todoListNo + extendIndex}
                            todoNo={todoNo}
                            todo={todo}
                          />
                        </ListItem>
                      ))}
                    </ListGroup>
                  )
              )}
            </div>
          </ListItem>
        </div>
      )}

      {procedureStatus.procedure_finish_maternity_datum && (
        <div className={styles.procedure}>
          <div className={styles.todoLeftBarDotLine} />
          <div className={styles.todoLeftBarDot} />
          <ListTitle>産休終了手続き</ListTitle>
          <ListItem>
            <div className={styles.todoInnerListGroup}>
              {_.filter(procedureStatus.todo_lists, {procedure_type: 'finish_maternity'}).map(
                (todoList, todoListNo) =>
                  !_.isEmpty(todoList.todos) && (
                    <ListGroup key={todoListNo + finishIndex}>
                      <div>
                        <ListTitle>
                          <div>
                            {todoList.title}
                            {todoList.due_on && (
                              <span className={styles.todoDate}>
                                <Clock size={16} /> {toDateStr(todoList.due_on, 'MMMDo')}
                              </span>
                            )}
                          </div>
                        </ListTitle>
                      </div>
                      {todoList.todos.map((todo, todoNo) => (
                        <ListItem key={todoNo}>
                          <Todo
                            onTodoChange={handleTodoChange('finish_maternity')}
                            todoListNo={todoListNo + finishIndex}
                            todoNo={todoNo}
                            todo={todo}
                          />
                        </ListItem>
                      ))}
                    </ListGroup>
                  )
              )}
            </div>
          </ListItem>
        </div>
      )}

      {procedureStatus.procedure_finish_maternity_datum ? (
        <>
          <div className={styles.todoLeftBarYear}>
            {toDateStr(procedureStatus.procedure_finish_maternity_datum.finished_at, 'YYYY')}
          </div>
          <div className={styles.todoLeftBarDate}>
            {toDateStr(procedureStatus.procedure_finish_maternity_datum.finished_at, 'M/D')}
          </div>
        </>
      ) : (
        <>
          <div className={styles.todoLeftBarYear}>{toDateStr(expectedFinishedAt, 'YYYY')}</div>
          <div className={styles.todoLeftBarDate}>{toDateStr(expectedFinishedAt, 'M/D')}</div>
        </>
      )}
      <div className={styles.todoDate}>
        <div className={styles.todoLeftBarDotLine} />
        <div className={styles.todoLeftBarDot} />
        <div className={styles.todoProcedureDate}>
          <div className={styles.todoProcedureDateText}>産休終了日</div>
          {!!procedureStatus.procedure_finish_maternity_datum || (
            <Link
              className={styles.todoLinkButton}
              to={`/finish_maternity/detail?employee_id=${procedureStatus.employee_id}&procedure_id=${procedureStatus.id}`}
            >
              予定日前に終了する
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export const MaternityToDoProgress = () => {
  const procedureStatus = useSelector((state) => state.procedureStatuses.current.data)
  if (!procedureStatus) {
    return null
  }
  const allTodoStatuses = procedureStatus.todo_lists.flatMap(({todos}) => todos).map(({status}) => status)
  const doneTodoStatusesCount = allTodoStatuses.filter((s) => s === 'done').length
  const progress = Math.floor((doneTodoStatusesCount / allTodoStatuses.length) * 100)

  return (
    <>
      <Progress progress={progress} key="progress" className={styles.progressBar} large />
      進捗状況：{' '}
      <span className={styles.statusCount}>
        {doneTodoStatusesCount}/{allTodoStatuses.length}
      </span>
      <span className={styles.statusTag}>{doneTodoStatusesCount === allTodoStatuses.length ? '完了' : '対応中'}</span>
    </>
  )
}

export const MaternityMenuItem = ({toggle, menuItemsEmptyTodo}) => {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const procedureStatus = useSelector((state) => state.procedureStatuses.current.data)
  const handleTodoChangeAll = async (status) => {
    try {
      dispatch(actionCreators.procedureStatuses.updateTodoAll({status}))
      await api.createWithAuth(token).procedureStatuses.updateTodoAll(procedureStatus.id, status)
    } catch (err) {
      dispatch(asyncError(err))
    }
  }
  const menuItems = [
    {
      onClick: () => {
        handleTodoChangeAll('done')
      },
      label: '全て完了にする',
    },
    {
      onClick: () => {
        handleTodoChangeAll('todo')
      },
      label: '全て対応中に戻す',
    },
    ...menuItemsEmptyTodo,
  ]
  return procedureStatus && <DropDownMenu toggle={toggle} menuItems={menuItems} />
}
