import React, {Component} from 'react'
import {connect} from 'react-redux'
import {ListGroup, ListItem, LinkIcon} from 'components/ListGroup'
import {Panel, Headding, Item} from 'components/PrimaryPanel'
import {Link} from 'react-router-dom'
import {Edit} from 'jbc-front/components/icons'
import {recordDisplay} from 'utils'
import {mapDispatchToProps} from 'Layout'

class Profile extends Component {
  componentDidMount() {
    const {token, fetchCurrentUser} = this.props
    fetchCurrentUser(token)
  }
  render() {
    const {currentUser} = this.props
    return (
      <div className="l-wrap-l">
        <h2 className="m-title-sub">アカウント設定</h2>
        <div className="l-flex">
          <div className="l-flex3-sub">
            <ListGroup>
              <ListItem to="/account_settings/account">
                アカウント設定
                <LinkIcon />
              </ListItem>
            </ListGroup>
          </div>
          <div className="l-flex3-main">
            <React.Fragment>
              <Panel>
                {currentUser.email_changeable ? (
                  <Link to="/account_settings/change_email">
                    <Headding icon={<Edit icon={16} />}>メールアドレス</Headding>
                  </Link>
                ) : (
                  <Headding>メールアドレス</Headding>
                )}
                <Item>{recordDisplay(currentUser.email)}</Item>
              </Panel>
              <Panel>
                <Link to="/account_settings/change_password">
                  <Headding icon={<Edit icon={16} />}>パスワード</Headding>
                </Link>
                <Item>**********</Item>
              </Panel>
            </React.Fragment>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => ({
    currentUser: state.session.currentUser,
    token: state.auth.token,
  }),
  mapDispatchToProps
)(Profile)
