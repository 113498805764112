import React from 'react'
import {useSelector} from 'react-redux'
import Select from 'react-select'
import {Field, reduxForm, formValueSelector, reset} from 'redux-form'
import Button from 'jbc-front/components/Button'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {Label, renderTextWithAnchor} from 'jbc-front/components/Form'
import {onSubmitFail} from 'jbc-front/components/FormErrors'
import _ from 'lodash'
import api from 'api'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {toOpts} from 'tm/notifications/utils'
import styles from 'tm/notifications/SelectItem.scss'

const formName = 'tmNotificationTemplateForm'
const selector = formValueSelector(formName)

const makeInitialValues = () => ({
  talent_notification_template: undefined,
})

const TemplateSelectBox = ({input, options, label}) => (
  <div className="u-mb10">
    <p className={styles.label}>{label}</p>
    <Select
      options={options}
      closeOnSelect={true}
      clearableValue={true}
      simpleValue
      placeholder=""
      name={input.label}
      value={input.value}
      onChange={input.onChange}
      onBlur={() => input.onBlur(input.value)}
    />
  </div>
)

const Body = ({template, ...rest}) => {
  const _body = template.template?.body
  return (
    <div {...rest}>
      <p style={{whiteSpace: 'pre-line'}}>{renderTextWithAnchor(_body)}</p>
    </div>
  )
}

const PreviewTemplate = (template) => (
  <>
    <div className={styles.box}>
      <p className={styles.subject}>{template.template?.subject}</p>
      <Body className={styles.body} template={template} />
    </div>
  </>
)

const PreviewTemplateOption = ({children}) => <div className={styles.answerBox}>{children}</div>

const Form =
  (({handleSubmit, submitting, hideModal = [], notificationTemplates, id}) => {
    const template = notificationTemplates[id - 1]
    const optionTemplates = template?.talent_notification_option_templates

    return (
      <>
        <Modal.Header hideModal={hideModal}>テンプレート選択</Modal.Header>
        <Modal.Body className={styles.modalbody}>
          {(notificationTemplates && notificationTemplates.length > 0 && (
            <form onSubmit={handleSubmit}>
              <div className="u-mb20">
                <Field
                  component={TemplateSelectBox}
                  label="利用するテンプレートを選択してください"
                  name="talent_notification_template"
                  options={toOpts(notificationTemplates)}
                />
              </div>
              {(template && _.Keys(template).length > 0 && (
                <>
                  <Label text="テンプレート内容" />
                  <PreviewTemplate template={template} />
                  <PreviewTemplateOption>
                    <div className={styles.options}>
                      {optionTemplates.map((option) => (
                        <div key={option.id} className={styles.option}>
                          {option.label}
                        </div>
                      ))}
                    </div>
                  </PreviewTemplateOption>
                </>
              )) ||
                null}
            </form>
          )) || <p className={styles.substitute_text}>選択できる項目はありません</p>}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            {(template && _.Keys(template).length > 0 && (
              <>
                <Button onClick={hideModal}>キャンセル</Button>
                <Button primary onClick={handleSubmit} disabled={submitting}>
                  適用する
                </Button>
              </>
            )) || <Button onClick={hideModal}>キャンセル</Button>}
          </Modal.Buttons>
        </Modal.Footer>
      </>
    )
  })
  |> reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmitFail,
  })

const SelectItem = ({isModalOpen, showModal, hideModal = [], onSubmit}) => {
  const id = useSelector((state) => selector(state, 'talent_notification_template'))
  const [token, notificationTemplates = []] = useSelector((state) => [
    state.auth.token,
    state.tm.notificationTemplates.data,
  ])
  return (
    <>
      <div className={styles.btnarea}>
        <Button widthAuto onClick={showModal} className={styles.small}>
          テンプレートを利用する
        </Button>
      </div>
      <Modal isOpen={isModalOpen} hideModal={hideModal}>
        {isModalOpen && (
          <Form
            {...{hideModal, notificationTemplates, id}}
            initialValues={makeInitialValues()}
            onSubmit={async ({talent_notification_template}, dispatch, {hideModal}) => {
              const authApi = api.createWithAuth(token)
              try {
                const {data} = await authApi.tm.notificationTemplates.get(talent_notification_template)
                onSubmit(data)
                dispatch(notifySuccess('テンプレートを適用しました'))
                dispatch(reset(formName))
              } catch (err) {
                dispatch(asyncError(err))
              }
              hideModal()
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default withModal(SelectItem)
