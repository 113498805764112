import gql from 'graphql-tag'
import {EgovProcedureStatuses} from 'consts/egovProcedureStatuses'

export interface QueryVariables {
  egovProcedureId: string
}

export const EGOV_PROCEDURE = gql`
  query ($egovProcedureId: ID!) {
    client {
      egovProcedure(egovProcedureId: $egovProcedureId) {
        id
        status
        formName
        procedureName
        signRequired
        egovApplication {
          arriveId
          egovAccount {
            email
            egovIdp
            gbizidAccountType
          }
          officialDocuments {
            id
            allowedDate
            docDownloadExpiredDate
            docDownloadDate
          }
          notices {
            id
            issueDate
            noticeTitle
            noticeSentence
            isFileDownload
          }
          trackingLogStatus
        }
        procedureStatus {
          id
          employee {
            firstName
            lastName
          }
        }
        office {
          id
          name
        }
      }
    }
  }
`

export interface QueryResult {
  client: {
    egovProcedure: EgovProcedure
  }
}

export interface EgovProcedure {
  id: string
  status: EgovProcedureStatuses
  formName: string
  procedureName: string
  signRequired: boolean
  egovApplication: {
    arriveId: string
    egovAccount: {
      email: string
      egovIdp: string
      gbizidAccountType: string
    }
    officialDocuments: {
      id: string
      allowedDate: string
      docDownloadDate: string
      docDownloadExpiredDate: string
    }[]
    notices: {
      id: string
      issueDate: string
      noticeTitle: string
      noticeSentence: string
      isFileDownload: boolean
    }[]
    trackingLogStatus: 'SUCCESS' | 'INVALID_TOKEN' | 'ABORT' | 'TRACKING_LOG_STATUS_UNKNOWN'
  }
  procedureStatus: {
    id: string
    employee: {
      firstName: string
      lastName: string
    }
  }
  office: {
    id: string
    name: string
  }
}

export const WITHDRAW_EGOV_PROCEDURE = gql`
  mutation withdrawEgovProcedure($egovProcedureId: ID!, $pin: String, $withdrawReason: String!) {
    withdrawEgovProcedure(input: {egovProcedureId: $egovProcedureId, pin: $pin, withdrawReason: $withdrawReason}) {
      egovProcedureId
    }
  }
`

export interface WithdrawResult {
  egovProcedureId: string
}
