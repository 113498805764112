import React from 'react'
import {FormSection} from 'redux-form'
import {connect} from 'react-redux'
import _ from 'lodash'
import {LabelMapper} from 'jbc-front/components/FormErrors'
import {Description, JAPAN_VALUE, withFormSelectors} from 'employees/form/common'
import {addressNames} from 'employees/form/BasicInfomation'
import {Address} from 'FormFields'
import {Section} from 'jbc-front/components/Form'
import {Copy} from 'jbc-front/components/icons'
import ActionButton from 'jbc-front/components/ActionButton'
import {isAdminSelector} from 'utils'

const ResidentCard = ({
  country,
  nations = [],
  copyAddress,
  diff: diffEmp = {},
  description,
  description_color_by_rgb,
}) => {
  const japan = _.find(nations, (nation) => nation.value === JAPAN_VALUE)
  const japanId = japan ? '' + japan.id : null
  const diff = diffEmp.resident_card || {}
  return (
    <Section title="住民票住所">
      <Description
        {...{
          description,
          description_color_by_rgb,
        }}
      />
      <FormSection name="resident_card">
        <LabelMapper name="resident_card" label="住民票住所の" />
        <ActionButton onClick={copyAddress} disabled={country != japanId} icon={<Copy size={13} />}>
          現住所をコピーする
        </ActionButton>
        <div className="u-mt20">
          <Address diff={diff} />
        </div>
      </FormSection>
    </Section>
  )
}

export default ResidentCard
  |> connect(
    (state, {selector, isForceEmployee}) => ({
      isAdmin: !isForceEmployee && isAdminSelector(state),
      country: selector(state, 'country'),
      nations: state.master.nations,
      diff: selector(state, '_diff'),
    }),
    (dispatch, {getValues, autofill}) => ({
      copyAddress() {
        dispatch((dispatch, getState) => {
          const values = getValues(getState())
          addressNames.forEach((name) => dispatch(autofill(`resident_card.${name}`, values[name])))
        })
      },
    })
  )
  |> withFormSelectors
