import {FC} from 'react'
import classNames from 'classnames'
import {Tab} from 'components/ui/Tab'
import {useQuery} from 'hooks/graphql'
import {IS_SENT_DOCUMENT_CONTRACT} from '../../query'
import useRouter from 'use-react-router'
import styles from './LinkTab.scss'

type LinkTabProps = {
  id: string | number
}

export const LinkTab: FC<LinkTabProps> = ({id}) => {
  const {
    location: {pathname},
  } = useRouter()
  const {data} = useQuery(IS_SENT_DOCUMENT_CONTRACT, {
    fetchPolicy: 'cache-and-network',
    variables: {id},
  })
  const isSentContract = data?.client?.document?.contractSent
  const planType = data?.client?.planType
  return (
    <div className={classNames(styles.tabs, {[styles.withContract]: planType === 'paid_plan'})}>
      <Tab active={pathname === `/documents/${id}`} to={`/documents/${id}`}>
        書類プレビュー
      </Tab>
      <Tab active={pathname === `/documents/${id}/employees`} to={`/documents/${id}/employees`}>
        従業員一覧
      </Tab>
      {planType === 'paid_plan' && (
        <Tab
          active={pathname === `/documents/${id}/contracts`}
          to={`/documents/${id}/contracts`}
          disabled={!isSentContract}
        >
          電子契約一覧
        </Tab>
      )}
    </div>
  )
}
