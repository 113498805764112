import React from 'react'
import {connect} from 'react-redux'
import compose from 'lodash/fp/compose'
import Modal, {withModal} from 'jbc-front/components/CommonModal'
import {reduxForm, formValueSelector} from 'redux-form'
import {TextField, SelectField} from 'jbc-front/components/Form'
import Button from 'jbc-front/components/Button'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'
import {getState} from 'utils'
import api from 'api'
import {email, maxLength, noZenkaku} from 'validators'
import {OfficeSelectorField} from 'features/offices/OfficeSelectorField'
import styles from 'settings/clientRoles/invite.scss'

const formName = 'inviteAccount'

const roleTypes = [
  {value: 'full_admin', label: '全権管理者'},
  {value: 'office_admin', label: '事業所管理者'},
  {value: 'limited_admin', label: '運用担当者'},
]

const selector = formValueSelector(formName)

const formatValues = ({office_ids, ...values}) => {
  if (office_ids) {
    return {...values, office_ids: office_ids.split(',')}
  }
  return values
}

const Form = compose(
  connect((state) => ({
    token: state.auth.token,
    clientRoleType: selector(state, 'client_role_type'),
  })),
  reduxForm({
    form: formName,
    initialValues: {client_role_type: 'full_admin'}, // 権限カスタマイズ実装までは「管理者」固定
    onSubmit: async (values, dispatch, {hideModal}) => {
      try {
        const state = await getState(dispatch)
        await api.createWithAuth(state.auth.token).inviteUser.create(formatValues(values))
        dispatch(notifySuccess('アカウント招待メールを送信しました'))
        hideModal()
      } catch (err) {
        dispatch(asyncError(err))
      }
    },
  })
)(
  class Form extends React.Component {
    constructor(props) {
      super(props)
      this.ref = React.createRef()
    }

    handleOnOpen = () => {
      const {current} = this.ref
      if (current !== null) {
        current.scrollTop = current.scrollHeight
      }
    }

    render() {
      const {handleSubmit, submitting, hideModal, clientRoleType} = this.props
      return (
        <div>
          <Modal.Header hideModal={hideModal}>アカウント招待</Modal.Header>
          <Modal.Body className={styles.modalBody} ref={this.ref}>
            <form onSubmit={handleSubmit}>
              <TextField name="email" label="メールアドレス" validate={[email, noZenkaku]} required />
              <TextField name="name" label="アカウント名" validate={maxLength(20)} />
              <SelectField
                name="client_role_type"
                label="権限"
                options={roleTypes}
                onOpen={this.handleOnOpen}
                required
              />
              {clientRoleType === 'office_admin' && (
                <OfficeSelectorField name="office_ids" label="適用事業所" required multiple />
              )}
            </form>
            <dl className={styles.warningWrap}>
              <dt className="u-mb5">注意</dt>
              <dd>アカウント招待は社労士など従業員情報のないアカウントを作成したい場合のみに使用してください。</dd>
              <dd>
                アカウント招待よりアカウントを作成した場合、共通IDのアカウントや従業員情報は作成されず、あとから紐付けることもできませんのでご注意ください。
              </dd>
            </dl>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Buttons>
              <Button onClick={hideModal}>キャンセル</Button>
              <Button primary onClick={handleSubmit} disabled={submitting}>
                招待
              </Button>
            </Modal.Buttons>
          </Modal.Footer>
        </div>
      )
    }
  }
)

const Invite = ({isModalOpen, showModal, hideModal}) => (
  <React.Fragment>
    <Button primary onClick={showModal} widthAuto>
      アカウント招待
    </Button>
    <Modal isOpen={isModalOpen} hideModal={hideModal}>
      {isModalOpen && <Form hideModal={hideModal} />}
    </Modal>
  </React.Fragment>
)

export default withModal(Invite)
