import React, {useRef, useState, useEffect} from 'react'
import classNames from 'classnames'
import Loading from 'components/Loading'
import PreviewController from 'documents/PreviewController'
import styles from 'documents/PreviewContainer.scss'

let PDF = null
const importPDF = () =>
  import(/* webpackChunkName: "react-pdf-js" */ 'react-pdf-js').then((module) => {
    PDF = module.default
  })

const InnerPreviewContainer = ({className, pdf}) => {
  const containerRef = useRef(null)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [zoom, setZoom] = useState(100)
  const [scale, setScale] = useState(null)
  return (
    <div className={classNames(styles.previewContainer, className)} ref={containerRef}>
      <div className={styles.previewPdf}>
        {pdf ? (
          <PDF
            content={pdf}
            page={page}
            scale={scale === null ? 1 : (scale * zoom) / 100.0}
            onDocumentComplete={(total) => setTotalPages(total)}
            onPageComplete={() => {
              if (scale === null) {
                const container = containerRef.current
                const canvas = container.getElementsByTagName('canvas')[0]
                const {clientWidth: cw, clientHeight: ch} = canvas
                const {clientWidth: dw, clientHeight: dh} = container
                const scale =
                  dw > dh ? Math.max((dw / cw) * 0.5, (dh - 110) / ch) : Math.max(((dh - 110) / ch) * 0.6, dw / cw)
                setScale(scale)
              }
            }}
          />
        ) : (
          <div className={styles.loading}>
            <Loading type="spin" color="#e3e3e3" />
          </div>
        )}
      </div>
      <PreviewController
        page={page}
        totalPages={totalPages}
        zoom={zoom}
        onChangePage={setPage}
        onChangeZoom={setZoom}
      />
    </div>
  )
}

const PreviewContainer = ({className, ...rest}) => {
  const [importState, setImportState] = useState('loading')

  useEffect(() => {
    if (PDF) {
      setImportState('success')
    } else {
      importPDF()
        .then(() => setImportState('success'))
        .catch(() => setImportState('failed'))
    }
  }, [])

  switch (importState) {
    case 'loading':
      return (
        <div className={classNames(styles.previewContainer, className)}>
          <div className={styles.previewPdf}>
            <div className={styles.loading}>
              <Loading type="spin" color="#e3e3e3" />
            </div>
          </div>
        </div>
      )
    case 'success':
      return <InnerPreviewContainer className={className} {...rest} />
    case 'failed':
      return (
        <div className={classNames(styles.previewContainer, className)}>
          <div className={styles.error}>
            エラーが発生しました。
            <br />
            ブラウザをリロードして再度お試しください。
          </div>
        </div>
      )
  }
}

export default PreviewContainer
