import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import {recordDisplay} from 'utils'
import styles from 'tm/employees/Search.scss'
import {getTmGroups} from 'employees/form/EmploymentInfomation'
import {getSavedDisplayEmployeeLimit, getCurrentQueryFromLocation} from 'tm/employees/list/utils'
import PaginatorWithResult from 'tm/employees/list/PaginatorWithResult'
import SearchFormWithCardList from 'tm/employees/list/SearchFormWithCardList'
import SortableTh from 'tm/employees/list/SortableTh'
import compose from 'lodash/fp/compose'
import SelectList from 'components/SelectList'
import {useEmployeeListType} from 'hooks/useEmployeeListType'

export const wrapArrowText = (source) => {
  return _.flatMap(source.split('->'), (text, index) =>
    index === 0
      ? text
      : [
          <span className={styles.arrowText} key={index}>
            &ensp;&gt;&ensp;
          </span>,
          text,
        ]
  )
}

const TextEllipsis = ({line = 1, styles = {}, children}) => {
  const singleLine = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }
  const multiLine = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    WebkitLineClamp: `${line}`,
  }
  const ellipsisStyle = line === 1 ? singleLine : multiLine
  return React.cloneElement(children, {style: {...styles, ...ellipsisStyle}})
}

const Td = ({children, to, ...rest}) => {
  return to ? (
    <td style={{padding: 0}} {...rest}>
      <Link to={to}>{children}</Link>
    </td>
  ) : (
    <td {...rest}>{children}</td>
  )
}

const LinkIfNeeded = ({children, to, ...rest}) =>
  to ? (
    <Link className={styles.cardLink} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <div className={styles.cardLink} {...rest}>
      {children}
    </div>
  )

const createLink = ({id, location, employees, user}) => {
  const query = getCurrentQueryFromLocation(location, user)
  const index = (+query._page - 1) * +query.limit + employees.findIndex((employee) => employee.id === id)
  return `/tm/employees/${id}?query=${encodeURIComponent(
    JSON.stringify({
      ..._.omit(query, ['_page', 'limit']),
    })
  )}&index=${index}`
}

const additionalParams = {embed: ['personnel_history']}

const EmployeeList = ({listType, ...rest}) => {
  const Component = listType === 'card' ? CardList : ListTable
  return <Component {...rest} />
}

const ListTable = ({employees, user, list, th, deleteMode}) => (
  <div className="l-overflow-scroll">
    <table className={`m-table-list m-table-fixed`}>
      <thead>
        <tr className={styles.table}>
          {deleteMode && th}
          <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
            名前
          </SortableTh>
          <SortableTh field="full_name_kana" globalClassName={styles.nameColumn}>
            名前カナ
          </SortableTh>
          <SortableTh field="group" globalClassName={styles.groupColumn}>
            グループ
          </SortableTh>
        </tr>
      </thead>
      <tbody>
        {list.map(({item: employee, toggle, td}) => {
          const id = employee.id
          const to = deleteMode ? undefined : createLink({id, location, employees, user})
          const onClick = deleteMode ? toggle : undefined
          return (
            <tr className={`${styles.table} table-hover`} key={id}>
              {deleteMode && td}
              <Td onClick={onClick} to={to} className={styles.nameColumn}>
                {recordDisplay.fullName(employee)}
              </Td>
              <Td onClick={onClick} to={to} className={styles.nameColumn}>
                {(!_.get(employee, 'business_first_name') &&
                  !_.get(employee, 'business_last_name') &&
                  recordDisplay.fullName(employee, false, true)) ||
                  recordDisplay()}
              </Td>
              <Td onClick={onClick} to={to} className={styles.groupColumn}>
                {recordDisplay(
                  !_.isEmpty(getTmGroups(employee)) && <span>{wrapArrowText(getTmGroups(employee)[0])}</span>
                )}
              </Td>
            </tr>
          )
        })}
      </tbody>
    </table>
  </div>
)

const CardList = ({location, employees, user, list}) => (
  <div className={styles.cardList}>
    {list.map(({item: employee}) => {
      const id = employee.id
      const to = createLink({id, location, employees, user})
      return (
        <div key={id} className={styles.card}>
          <LinkIfNeeded to={to}>
            <div className={styles.cardBody}>
              <div className={styles.cardBodyImageWrap}>
                {employee.icon?.thumb?.url ? (
                  <div
                    style={{backgroundImage: `url("${employee.icon.thumb.url}")`}}
                    className={styles.cardBodyImage}
                  />
                ) : employee.icon && employee.icon.url ? (
                  <div style={{backgroundImage: `url("${employee.icon.url}")`}} className={styles.cardBodyImage} />
                ) : (
                  <div style={{backgroundImage: 'url(/images/card_default.png)'}} className={styles.cardBodyImage} />
                )}
              </div>
              <div className={styles.cardBodyDescription}>
                <TextEllipsis>
                  <h5 className={styles.cardBodyName}>{recordDisplay.fullName(employee)}</h5>
                </TextEllipsis>
                {!_.isEmpty(getTmGroups(employee)) && (
                  <TextEllipsis line={3}>
                    <p className={styles.cardBodyGroup}>
                      {recordDisplay(<span>{wrapArrowText(getTmGroups(employee)[0])}</span>)}
                    </p>
                  </TextEllipsis>
                )}
              </div>
            </div>
          </LinkIfNeeded>
        </div>
      )
    })}
  </div>
)

const Search = ({location, user, employees, currentTmUser}) => {
  const [listType, setListType] = useEmployeeListType(currentTmUser.id, 'card')
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">従業員検索</h1>
      </div>
      <SelectList
        isTable={listType === 'list'}
        list={employees}
        selectedInfo={(employee) => ({
          name: employee.display_full_name,
        })}
      >
        {({list}) => {
          return (
            <div className="l-contents-wrap">
              <div className={styles.widePage}>
                <SearchFormWithCardList
                  additionalParams={additionalParams}
                  listType={listType}
                  onChangeListType={setListType}
                />
                <EmployeeList {...{listType, list, location, user, employees}} />
                <PaginatorWithResult limit={getSavedDisplayEmployeeLimit(location.pathname, user)} />
              </div>
            </div>
          )
        }}
      </SelectList>
    </div>
  )
}

export default compose(
  connect((state) => ({
    employees: state.employees.list.data,
    count: state.employees.list.count,
    token: state.auth.token,
    query: state.employees.query,
    location: state.router.location,
    user: state.session.currentUser,
    currentTmUser: state.session.currentTmUser,
  }))
)(Search)
