import React from 'react'
import {Link} from 'react-router-dom'
import _ from 'lodash'
import classNames from 'classnames'
//@ts-ignore
import {Panel, Item, Headding} from 'components/PrimaryPanel'
import type {CustomEmployeeField} from 'types/api/settings/employeeFieldGroups/customEmployeeField'
import type {CustomEmployeeFieldGroup} from 'types/api/settings/employeeFieldGroups/customEmployeeFieldGroup'
import {CustomEmployeeFieldGroupPermission} from 'settings/employeeCustom/FieldGroups/CustomEmployeeFieldGroupPermission'
//@ts-ignore
import FieldView from 'settings/employeeCustom/FieldGroups/FieldView'
//@ts-ignore
import {toColor} from 'settings/employeeCustom/ColorSelect'
//@ts-ignore
import styles from 'settings/employeeCustom/FieldGroups/CustomEmployeeFieldGroupItem.scss'
import {Edit, Delete} from 'jbc-front/components/icons'
//@ts-ignore
import {renderTextWithAnchor} from 'jbc-front/components/Form'
import formStyles from 'jbc-front/components/Form.scss'

export const CustomEmployeeFieldGroupItem = ({
  handleDelete,
  group,
  fields,
  loadData,
  isEmployee,
}: {
  handleDelete: (fieldGroup: CustomEmployeeFieldGroup) => void
  group: CustomEmployeeFieldGroup
  fields: CustomEmployeeField[]
  loadData: () => Promise<void>
  isEmployee: boolean
}) => {
  const fieldsMap = _.groupBy(fields, 'custom_employee_field_group_id')
  const groupFields: CustomEmployeeField[] = fieldsMap[group.id] || []
  return (
    <Panel>
      <a
        className={isEmployee ? styles.anchorLink : styles.anchorLinkNonEmployees}
        id={`field_custom_group_${group.id}`}
      ></a>
      <Headding
        icon={
          <div>
            <Delete onClick={() => handleDelete(group)} size={20} className="u-cur-pointer" />
            <Link to={`/settings/employee_custom/custom/${group.id}`}>
              <Edit size={20} className={classNames([styles.editIcon, 'u-ml15'])} />
            </Link>
            <CustomEmployeeFieldGroupPermission
              initialCustomEmployeeFieldGroup={group}
              initialCustomEmployeeFields={groupFields}
              loadData={loadData}
            />
          </div>
        }
      >
        {group.label}
      </Headding>
      <Item>
        <div className={formStyles.boxMain}>
          {group.description && (
            <div
              className={styles.customDescription}
              style={{
                color: toColor(group.description_color_by_rgb),
              }}
            >
              {renderTextWithAnchor(group.description)}
            </div>
          )}
          {groupFields.map((groupField) => (
            <FieldView field={groupField} key={groupField.id} />
          ))}
        </div>
      </Item>
    </Panel>
  )
}
