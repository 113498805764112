import {useQuery} from 'hooks/graphql'
import {TAX_OFFICES, TaxOfficesResult, TaxOfficesVariables} from './query'

export const useTaxOffices = (prefectureId: string, skip = false) => {
  return useQuery<TaxOfficesResult, TaxOfficesVariables>(TAX_OFFICES, {
    variables: {
      prefectureId,
    },
    skip,
  })
}
