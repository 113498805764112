import React from 'react'
import {connect} from 'react-redux'
import {Field, autofill, reduxForm, getFormValues, reset, getFormMeta} from 'redux-form'
import {push} from 'connected-react-router'
import _ from 'lodash'
import {getState} from 'utils'
import {actionCreators} from 'actions'
import AdditionalSearchFields from 'employees/list/AdditionalSearchFields'
import ListTypeWithResult from 'employees/list/ListTypeWithResult'
import styles from 'employees/list/SearchForm.scss'
import searchDetector from 'searchDetector'
import {MainFormControls, SearchEmploymentStatusField} from 'jbc-front/components/SearchForm'

import {
  formName,
  getSavedDisplayEmployeeLimit,
  saveDisplayEmployeeLimit,
  convertQueryToForm,
  getCurrentQueryFromLocation,
  fetchEmployees,
  convertFormToQueryString,
  fetchSelections,
  hasConditions,
} from 'employees/list/utils'
import compose from 'lodash/fp/compose'
import moment from 'moment'

export const employmentStatuses = [
  {id: 'all', name: 'すべて'},
  {id: 'employed', name: '在職中'},
  {id: 'vacation', name: '休職中'},
  {id: 'unemployed', name: '退職済'},
  {id: 'join', name: '内定'},
]

class SearchFormWithCardList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: false,
    }
  }

  componentDidMount() {
    const {token, dispatch} = this.props
    fetchSelections(token, dispatch)
  }

  componentWillUnmount() {
    this.props.dispatch(actionCreators.fetchDestroy('selections'))
  }

  clearForm = () => {
    const {dispatch} = this.props
    getState(dispatch).then((state) => {
      dispatch(reset(formName))
      dispatch(push({...state.router.location, search: ''}))
    })
  }

  render() {
    const {
      user,
      pathname = '',
      formValues,
      handleSubmit,
      listType,
      onChangeListType,
      onChangeDisplayTypes,
      displayType,
      viewableToDisplayType,
    } = this.props
    return (
      <div className={styles.searchForm}>
        <form onSubmit={handleSubmit}>
          <div className={styles.mainSearchFields}>
            <Field
              name="employment_status"
              component={SearchEmploymentStatusField}
              employmentStatuses={employmentStatuses}
              onEmploymentStatusChange={handleSubmit}
            />
            <MainFormControls
              isExpand={this.state.isExpand}
              hasConditions={hasConditions(formValues)}
              handleClear={this.clearForm}
              onToggleClick={() => {
                this.setState({isExpand: !this.state.isExpand})
              }}
            />
          </div>
          {this.state.isExpand && <AdditionalSearchFields />}
          <ListTypeWithResult
            limit={getSavedDisplayEmployeeLimit(pathname, user)}
            listType={listType}
            displayType={displayType}
            onChangeListType={onChangeListType}
            viewableToDisplayType={viewableToDisplayType}
            onChangeLimit={(newLimit) => {
              saveDisplayEmployeeLimit(pathname, user, newLimit)
              handleSubmit()
            }}
            onChangeDisplayTypes={onChangeDisplayTypes}
            onChangeSortType={handleSubmit}
          />
        </form>
      </div>
    )
  }
}

const validate = (values) => {
  const errors = {}
  if (values.age_from && values.age_to && +values.age_from > +values.age_to) {
    errors.age_to = errors.age_from = 'の範囲が正しくありません'
  }
  ;['joined_at', 'retired_at', 'birthday'].forEach((prefix) => {
    if (
      [values[`${prefix}_start`], values[`${prefix}_end`]].every(
        (date) => date && moment(date, 'YYYY/MM/DD').isValid()
      ) &&
      moment(values[`${prefix}_start`], 'YYYY/MM/DD').diff(moment(values[`${prefix}_end`], 'YYYY/MM/DD'), 'days') > 0
    ) {
      errors[`${prefix}_start`] = errors[`${prefix}_end`] = 'の範囲が正しくありません'
    }
  })
  return errors
}

export default compose(
  searchDetector({
    convertQueryToForm,
    convertFormToQueryString,
    getCurrentQueryFromLocation,
    fetchData(query, additionalParams, dispatch) {
      dispatch(fetchEmployees(query, additionalParams))
    },
    destroy(dispatch) {
      dispatch(actionCreators.employees.list.destroy())
    },
  }),
  connect((state) => ({
    token: state.auth.token,
    pathname: state.router.location.pathname,
    formValues: getFormValues(formName)(state),
    user: state.session.currentUser,
  })),
  reduxForm({
    form: formName,
    enableReinitialize: true,
    validate,
    onSubmit(values, dispatch, {handleSearch}) {
      getState(dispatch).then((state) => {
        const autofilled = _.get(getFormMeta(formName)(state), 'page.autofilled')
        if (!autofilled) {
          dispatch(autofill(formName, 'page', 1))
        }
        getState(dispatch).then((state) => {
          handleSearch(getFormValues(formName)(state))
        })
      })
    },
  })
)(SearchFormWithCardList)
