import React, {Component} from 'react'
import Form, {makeInitialValues} from 'procedures/changeAddress/Form'
import {connect} from 'react-redux'
import api from 'api'
import {actionCreators, fetchSelector} from 'actions'
import {push} from 'connected-react-router'
import CommentModal from 'procedures/CommentModal'
import checkEmployeeInput from 'components/checkEmployeeInput'
import checkUnemployed from 'components/checkUnemployed'
import compose from 'lodash/fp/compose'
import _ from 'lodash'
import {notifySuccess} from 'store/actions/notify'
import {asyncError} from 'store/actions/asyncError'

const formName = 'changeAddress'

class Detail extends Component {
  componentDidMount() {
    const {
      match: {
        params: {id},
      },
      loadEmployee,
      token,
      loadTmpData,
      loadComments,
    } = this.props
    loadEmployee(token)
    loadComments(id, token)
    if (id) {
      loadTmpData(id, token)
    }
  }

  componentWillUnmount() {
    this.props.destroy()
  }

  render() {
    const {
      employee,
      match: {
        params: {id},
      },
      tmpData: {application_procedure_change_address: changeAddressData},
      residenceCard,
      comments,
      healthInsuranceType,
      updateAndCreateProcedure,
      token,
    } = this.props
    const changeAddressDataAndFile = {...changeAddressData, residence_card: residenceCard}
    const handleSubmit = (values, comment) =>
      updateAndCreateProcedure(id, employee.id, {...values, comment: comment}, token)
    return (
      <div>
        <CommentModal
          formName={formName}
          procedureType="changeAddress"
          onSubmit={({comment, values}) => {
            handleSubmit(values, comment)
          }}
          comments={comments || []}
        >
          {({showModal}) => (
            <Form
              onSubmit={showModal}
              initialValues={id ? changeAddressDataAndFile || {} : makeInitialValues(employee, healthInsuranceType)}
              submitText="申請する"
              healthInsuranceType={healthInsuranceType}
              comments={comments || {}}
            />
          )}
        </CommentModal>
      </div>
    )
  }
}

export default compose(
  checkEmployeeInput(),
  checkUnemployed(),
  connect(
    (state) => ({
      employee: state.employees.current.data,
      tmpData: state.procedureStatuses.tmpData.data,
      residenceCard: state.procedureStatuses.tmpData.tmpFile,
      comments: fetchSelector(state, 'procedure_comments').data,
      healthInsuranceType: _.get(state.session.currentUser, 'employee.office.health_insurance_type'),
      token: state.auth.token,
    }),
    (dispatch) => ({
      loadEmployee: (token) => {
        dispatch(
          actionCreators.employees.current.fetchData(
            api.createWithAuth(token).employees.getCurrent(['employee_dependents', 'commuting_expenses', 'office'])
          )
        )
      },
      loadTmpData: (procedureStatusId, token) => {
        dispatch(
          actionCreators.procedureStatuses.tmpData.fetchData(
            Promise.all([
              api.createWithAuth(token).procedureStatuses.tmpData.mapToJson(procedureStatusId, {
                type: 'application',
                name: 'procedure_change_address',
              }),
              api
                .createWithAuth(token)
                .procedureStatuses.tmpFiles.singleFile(procedureStatusId, 'application', 'procedure_change_address'),
            ]).then((result) => Object.assign(...result))
          )
        )
      },
      loadComments: (procedureStatusId, token) => {
        dispatch(
          actionCreators.fetchData(
            'procedure_comments',
            api.createWithAuth(token).procedureStatuses.procedureComments.list(procedureStatusId)
          )
        )
      },
      destroy: () => {
        dispatch(actionCreators.employees.current.destroy())
        dispatch(actionCreators.procedureStatuses.tmpData.destroy())
      },
      updateAndCreateProcedure: async (procedureStatusId, employeeId, values, token) => {
        const authedApi = api.createWithAuth(token)
        try {
          if (!procedureStatusId) {
            const {
              data: {id},
            } = await authedApi.procedureStatuses.start(employeeId, 'change_address', values.date_on, 'employee_draft')
            procedureStatusId = id
          }
          await authedApi.procedureStatuses.tmpData.createOrUpdate(
            procedureStatusId,
            values,
            'application',
            'procedure_change_address'
          )
          if (values.residence_card instanceof File) {
            await authedApi.procedureStatuses.tmpFiles.createOrUpdate(
              procedureStatusId,
              values.residence_card,
              'application',
              'procedure_change_address'
            )
          }
          if (!values.residence_card) {
            const tmpFileId = await authedApi.procedureStatuses.tmpFiles.getTmpFileID(
              procedureStatusId,
              'application',
              'procedure_change_address'
            )
            if (tmpFileId) await authedApi.procedureStatuses.tmpFiles.delete(procedureStatusId, tmpFileId)
          }
          await authedApi.procedureStatuses.apply(procedureStatusId, values.comment)
          dispatch(push('/dashboard'))
          dispatch(notifySuccess('申請しました'))
        } catch (err) {
          dispatch(asyncError(err))
        }
      },
    })
  )
)(Detail)
