import {FC, ReactNode, MouseEventHandler} from 'react'
import {Close} from 'jbc-front/components/icons'
import styles from './Modal.scss'

interface ModalProps {
  children: ReactNode
}

export type ModalType = FC<ModalProps> & {
  Header: FC<HeaderProps>
  Body: FC<BodyProps>
  Footer: FC<FooterProps>
}

export const Modal: ModalType = ({children}) => {
  return <div className={styles.modal}>{children}</div>
}

interface HeaderProps {
  onClose: () => void
  children: ReactNode
}

const Header: FC<HeaderProps> = ({children, onClose}) => {
  const handleClose: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    onClose()
  }

  return (
    <div className={styles.header}>
      {children}
      <div onClick={handleClose}>
        <Close className="u-cur-pointer" />
      </div>
    </div>
  )
}

interface BodyProps {
  children: ReactNode
}

const Body: FC<BodyProps> = ({children}) => {
  return <div className={styles.body}>{children}</div>
}

interface FooterProps {
  children: ReactNode
}

const Footer: FC<FooterProps> = ({children}) => {
  return <div className={styles.footer}>{children}</div>
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer
