import React from 'react'
import {Panel, Item} from 'tm/employees/PrimaryPanel'
import {recordDisplay, showCategory} from 'utils'
import _ from 'lodash'
import {connect} from 'react-redux'
import {Wrap, Tag, Dl} from 'tm/employees/Parts'

const EmploymentInfomation = ({employee, tags = [], userDisplaySetting}) => {
  return (
    (showCategory(userDisplaySetting, 'tag') && employee.employee_tag && employee.employee_tag.length && (
      <Panel>
        <Item>
          <Wrap>
            <Dl>
              {employee.employee_tag &&
                employee.employee_tag.map((employee_tag, index) => (
                  <Tag key={index}>
                    {recordDisplay.option(
                      _.get(employee_tag, 'tag_id'),
                      tags.map((t) => ({value: t.id, label: t.name}))
                    )}
                  </Tag>
                ))}
            </Dl>
          </Wrap>
        </Item>
      </Panel>
    )) ||
    null
  )
}

export default connect((state) => ({
  employee: state.employees.current.data,
  tags: state.master.tags,
}))(EmploymentInfomation)
